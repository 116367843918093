import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    PROJECT_FETCH_CUSTOMERS,
    PROJECT_FETCH_CUSTOMERS_SUCCESS,
    PROJECT_SET_CUSTOMER_PAGINATION,
    PROJECT_SAVE_CUSTOMER,
    PROJECT_SAVE_CUSTOMER_SUCCESS,
    PROJECT_DELETE_CUSTOMER,
    PROJECT_DELETE_CUSTOMER_SUCCESS,
    PROJECT_SET_EDIT_USER_TYPE_FORM_MODAL,
    PROJECT_SET_CUSTOMER_FORM_ERRORS,
    PROJECT_RESET_CUSTOMER_FORM_ERRORS,
    PROJECT_SET_ADD_CUSTOMER_FORM_MODAL
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showEditUserTypeForm: false,
    customerCompanyProjectIdSelected: null,
    errors: new Map(),
    showAddCustomerForm: false
})

export default function projectCustomersReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case PROJECT_FETCH_CUSTOMERS:
            return state.merge({ loading: true })
        case PROJECT_FETCH_CUSTOMERS_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case PROJECT_SET_CUSTOMER_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case PROJECT_SAVE_CUSTOMER:
            return state.merge({ saving: action.saving })
        case PROJECT_SAVE_CUSTOMER_SUCCESS:
            return state.merge({
                items: items.includes(result) ? items : items.push(result)
            })
        case PROJECT_DELETE_CUSTOMER:
            return state.merge({ loading: true })
        case PROJECT_DELETE_CUSTOMER_SUCCESS:
            return state.merge({
                loading: false,
                items: items.delete(items.indexOf(result))
            })
        case PROJECT_SET_EDIT_USER_TYPE_FORM_MODAL:
            return state.merge({
                showEditUserTypeForm: action.visible,
                customerCompanyProjectIdSelected: action.id
            })
        case PROJECT_SET_CUSTOMER_FORM_ERRORS:
            return state.merge({
                errors: action.formErrors
            })
        case PROJECT_RESET_CUSTOMER_FORM_ERRORS:
            return state.merge({
                errors: new Map()
            })
        case PROJECT_SET_ADD_CUSTOMER_FORM_MODAL:
            return state.merge({
                showAddCustomerForm: action.visible
            })
        default:
            return state
    }
}
