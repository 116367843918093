const actions = {
    ORDER_HISTORY_DASHBOARDS_FETCH_FAIL: 'ORDER_HISTORY_DASHBOARDS_FETCH_FAIL',
    ORDER_HISTORY_DASHBOARDS_FETCH_REQUEST: 'ORDER_HISTORY_DASHBOARDS_FETCH_REQUEST',
    ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS: 'ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS',
    ORDER_HISTORY_DASHBOARDS_FETCH: 'ORDER_HISTORY_DASHBOARDS_FETCH',
    ORDER_HISTORY_DASHBOARDS_SET_REFETCH: 'ORDER_HISTORY_DASHBOARDS_SET_REFETCH',

    fetchOrderHistoryDashboards: ({ orderId = '', onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.ORDER_HISTORY_DASHBOARDS_FETCH_REQUEST,
            payload: { orderId, onSuccess, onError }
        }
    },

    setRefetch: (refetch) => {
        return {
            type: actions.ORDER_HISTORY_DASHBOARDS_SET_REFETCH,
            refetch
        }
    }
}

export default actions
