import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ProjectUserTypeForm from '../../components/projects/projectUserTypeForm'

import actions from '../../redux/projects/customers/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveCompanyProjectCustomer } = actions
const { setNotifications } = notificationActions

class ProjectUserTypeFormContainer extends Component {
    static propTypes = {
        id: PropTypes.string,
        customerCompanyProject: PropTypes.object,
        projectId: PropTypes.string.isRequired,
        toggleForm: PropTypes.func.isRequired
    }

    handleSaveUserType = (params) => {
        const { id, projectId, saveCompanyProjectCustomer, setNotifications, toggleForm } = this.props

        saveCompanyProjectCustomer({
            projectId,
            id,
            params,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    render () {
        const { customerCompanyProject, toggleForm } = this.props
        return (
            <ProjectUserTypeForm
                customerCompanyProject={customerCompanyProject}
                onSubmitForm={this.handleSaveUserType}
                toggleForm={toggleForm}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projectCustomers: state.get('projectCustomers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCompanyProjectCustomer,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUserTypeFormContainer)
