import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Checkbox, Divider, Form, Input, Radio } from 'antd'
import AreasSelectContainer from '../../../containers/Areas/AreasSelectContainer'
import MailingAddressForm from './mailingAddressForm'

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
}

const areasSelectLayout = {
  labelCol: { md: { span: 8 }, sm: { span: 6 } },
  wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
}

const radioStyle = {
  display: 'block',
  lineHeight: '30px'
}

const BusinessAddressForm = ({
  businessAddress,
  customerNo,
  errors,
  form,
  loading,
  mailingAddress,
  showMailingAddressForm
}) => {
  const { i18n } = useTranslation('addresses')

  const renderMailingAddressForm = () => {
    if (!showMailingAddressForm) return null

    return (
      <React.Fragment>
        <Divider />
        <MailingAddressForm
          areasSelectLayout={areasSelectLayout}
          businessAddress={businessAddress}
          customerNo={customerNo}
          errors={errors}
          form={form}
          i18n={i18n}
          loading={loading}
          mailingAddress={mailingAddress}
        />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Form.Item label={i18n.t('addresses:taxId')}
        hasFeedback
        name="tax_number"
        initialValue={businessAddress.tax_number || businessAddress.taxNumber || ''}
        validateStatus={errors['tax_number'] && 'error'}
        help={errors['tax_number']}
      >
          <Input />
      </Form.Item>

      <Form.Item label={i18n.t('addresses:name')}
        hasFeedback
        name="company_name"
        initialValue={businessAddress.company_name || businessAddress.companyName}
        validateStatus={errors['company_name'] && 'error'}
        help={errors['company_name']}>
          <Input />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}
        name="business_type"
        initialValue={businessAddress.business_type || businessAddress.businessType}
        >
          <Radio.Group>
            <Radio style={radioStyle} value="corporate">{i18n.t('addresses:corporate')}</Radio>
            <Radio style={radioStyle} value="person">{i18n.t('addresses:person')}</Radio>
            <Radio style={radioStyle} value="foreign">{i18n.t('addresses:foreign')}</Radio>
          </Radio.Group>
      </Form.Item>

      <Form.Item label={i18n.t('addresses:branch')}
        hasFeedback
        name="branch"
        initialValue={businessAddress.branch}
        validateStatus={errors['branch'] && 'error'}
        help={errors['branch']}>
          <Input />
      </Form.Item>

      <Form.Item label={i18n.t('addresses:address')}
        hasFeedback
        initialValue={businessAddress.address_detail || businessAddress.addressDetail}
        name="address_detail"
        validateStatus={errors['address_detail'] && 'error'}
        help={errors['address_detail']}>
          <Input />
      </Form.Item>

      <Form.Item label={i18n.t('addresses:hideAddressInformation')}
        name="hide_detail_information" valuePropName="checked"
        initialValue={businessAddress.hide_detail_information || businessAddress.hideDetailInformation}>
          <Checkbox>{i18n.t('addresses:hideAddressInformation')}</Checkbox>
      </Form.Item>

      <AreasSelectContainer form={form}
        districtFormItemLayout={areasSelectLayout}
        provinceFormItemLayout={areasSelectLayout}
        postalCodeFormItemLayout={areasSelectLayout}
        subDistrictFormItemLayout={areasSelectLayout}
        districtCol={{ md: { span: 24 } }}
        subDistrictCol={{ md: { span: 24 } }}
        postalCodeCol={{ md: { span: 24 } }}
        provinceCol={{ md: { span: 24 } }}
        provinceFieldName="province_id"
        districtFieldName="district_id"
        showSubDistrict
        postalCodeDisabled={false}
        subDistrictFieldName="sub_district"
        postalCodeFieldName="postal_code"
        subDistrictHelp={errors['sub_district']}
        subDistrictValidateStatus={errors['sub_district'] && 'error'}
        districtHelp={errors['district']}
        districtValidateStatus={errors['district'] && 'error'}
        provinceHelp={errors['province']}
        provinceValidateStatus={errors['province'] && 'error'}
        postalCodeHelp={errors['postal_code']}
        postalCodeValidateStatus={errors['postal_code'] && 'error'}
        provinceValue={businessAddress.province && businessAddress.province.id}
        districtValue={businessAddress.province && businessAddress.district.id}
        subDistrictValue={businessAddress.sub_district || businessAddress.subDistrict}
        postalCodeValue={businessAddress.postal_code || businessAddress.postalCode}
      />

      {renderMailingAddressForm()}
    </React.Fragment>
  )
}

BusinessAddressForm.propTypes = {
  businessAddress: PropTypes.object,
  customerNo: PropTypes.string.isRequired,
  errors: PropTypes.object,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  mailingAddress: PropTypes.object.isRequired,
  showMailingAddressForm: PropTypes.bool
}

BusinessAddressForm.defaultProps = {
  businessAddress: {},
  errors: {},
  mailingAddress: {},
  showMailingAddressForm: true
}

export default BusinessAddressForm
