import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import lineLogo from '../../../public/images/base/line-logo.png'

import { isMobile } from 'react-device-detect'
import { Table, Input, Divider, Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'

import SupplierWrapper from './suppliers.style'
import SupplierExport from './Export'
import SupplierOverTranfersExport from './OverTransfers/export'

const SupplierIndex = ({
    i18n,
    getSupplierOverTransferAccount,
    loading,
    onPageChange,
    onSearch,
    pagination,
    suppliers
}) => {
    const columns = [
        {
            key: 'name',
            title: i18n.t('suppliers:supplierName'),
            render: (record) => (
                <div className="supplier-section">
                    <div>
                        <span className="label">{i18n.t('suppliers:supplierName')}</span>
                        <span>{_.get(record, 'name', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:taxNumber')}</span>
                        <span>{_.get(record, 'tax_number', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:transferForm.phoneNumber')}</span>
                        <span>{_.get(record, 'phone_number', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:transferForm.email')}</span>
                        <span>{_.get(record, 'email', '')}</span>
                    </div>
                    <div>
                        {record.line_message_url ?
                            <a href={record.line_message_url} target="_blank" rel="noreferrer">
                                <img src={lineLogo} width='24px'alt="line" />
                            </a> : null }
                    </div>
                </div>
            )
        },
        {
            key: 'bank',
            title: i18n.t('suppliers:transferForm.title'),
            render: (record) => (
                <div className="bank-section">
                    <div>
                        <span className="label">{i18n.t('suppliers:transferForm.bankName')}</span>
                        <span>{_.get(record, 'bank_name', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:transferForm.number')}</span>
                        <span>{_.get(record, 'bank_account_number', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:transferForm.name')}</span>
                        <span>{_.get(record, 'bank_account_name', '')}</span>
                    </div>
                    <div>
                        <span className="label">{i18n.t('suppliers:additionalInfo')}</span>
                        <span>{_.get(record, 'note', '')}</span>
                    </div>
                </div>
            )
        },
        {
            key: 'over-transfer',
            title: i18n.t('suppliers/overTransferAccounts:balance'),
            dataIndex: 'supplier_over_transfer_account',
            render: (dataIndex) => {
                const supplierOverTransferAccount = getSupplierOverTransferAccount(dataIndex).toJS()

                return (
                    <div>{_.get(supplierOverTransferAccount, 'balance.display', '0.00')}</div>
                )
            }
        },
        {
            key: 'actions',
            dataIndex: 'id',
            render: (dataIndex) => (
                <Link to={`/suppliers/${dataIndex}`}>
                    <Button type="link" icon={<EyeOutlined />}>View</Button>
                </Link>
            )
        }
    ]

    return (
        <SupplierWrapper>
            <Input.Search placeholder="input search text" onSearch={(value) => {onSearch(value)}} enterButton />
            <div className='export-section'>
                <SupplierOverTranfersExport />
                <div className='export-suppliers'>
                    <SupplierExport />
                </div>
            </div>
            <Divider />
            <Table
                columns={columns}
                dataSource={suppliers}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1080 }}
            />
        </SupplierWrapper>
    )
}

SupplierIndex.propTypes = {
    getSupplierOverTransferAccount: PropTypes.func,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func,
    onSearch: PropTypes.func,
    pagination: PropTypes.object,
    suppliers: PropTypes.array
}

SupplierIndex.defaultProps = {
    getSupplierOverTransferAccount: () => {},
    loading: false,
    onPageChange: () => {},
    onSearch: () => {},
    pagination: {
        current: 1,
        perSize: 20,
        total: 1
    },
    suppliers: []
}

export default withTranslation(['suppliers', 'suppliers/overTransferAccounts', 'suppliers/overTransferItems'])(
    SupplierIndex
)
