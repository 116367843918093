const actions = {
    FETCH_INQUIRY_REQUEST: 'FETCH_INQUIRY_REQUEST',
    FETCHING_INQUIRY: 'FETCHING_INQUIRY',
    FETCH_INQUIRY_SUCCESS: 'FETCH_INQUIRY_SUCCESS',
    FETCH_INQUIRY_CUSTOMER_REQUEST: 'FETCH_INQUIRY_CUSTOMER_REQUEST',
    FETCHING_INQUIRY_CUSTOMER: 'FETCHING_INQUIRY_CUSTOMER',
    FETCH_INQUIRY_CUSTOMER_SUCCESS: 'FETCH_INQUIRY_CUSTOMER_SUCCESS',
    FETCH_INQUIRY_LINE_ITEMS_REQUEST: 'FETCH_INQUIRY_LINE_ITEMS_REQUEST',
    FETCHING_INQUIRY_LINE_ITEMS: 'FETCHING_INQUIRY_LINE_ITEMS',
    FETCH_INQUIRY_LINE_ITEMS_SUCCESS: 'FETCH_INQUIRY_LINE_ITEMS_SUCCESS',
    FETCH_INQUIRY_ITEMS_REQUEST: 'FETCH_INQUIRY_ITEMS_REQUEST',
    FETCHING_INQUIRY_ITEMS: 'FETCHING_INQUIRY_ITEMS',
    FETCH_INQUIRY_ITEMS_SUCCESS: 'FETCH_INQUIRY_ITEMS_SUCCESS',
    INQUIRY_SET_ITEMS_PAGINATION: 'INQUIRY_SET_ITEMS_PAGINATION',
    SET_INQUIRY_ITEM_SEARCH_QUERY: 'SET_INQUIRY_ITEM_SEARCH_QUERY',
    SET_INQUIRY_ITEM_QUERY: 'SET_INQUIRY_ITEM_QUERY',
    SET_INQUIRY_ITEM_CATEGORY: 'SET_INQUIRY_ITEM_CATEGORY',
    SET_INQUIRY_ITEM_SUB_CATEGORY: 'SET_INQUIRY_ITEM_SUB_CATEGORY',
    SET_INQUIRY_ITEM_PRODUCT_TYPE: 'SET_INQUIRY_ITEM_PRODUCT_TYPE',
    SET_INQUIRY_SHOW_PAYMENT_FORM: 'SET_INQUIRY_SHOW_PAYMENT_FORM',
    SET_INQUIRY_SHOW_NOTE_FORM: 'SET_INQUIRY_SHOW_NOTE_FORM',
    SET_INQUIRY_SHOW_SHIPPING_ADDRESS_FORM: 'SET_SHIPPING_ADDRESS_SHOW_FORM',
    SET_INQUIRY_SHOW_SALE_FORM: 'SET_SALE_SHOW_FORM',
    SET_INQUIRY_SHOW_CS_FORM: 'SET_CS_SHOW_FORM',
    SET_INQUIRY_SHOW_PURCHASER_FORM: 'SET_INQUIRY_SHOW_PURCHASER_FORM',
    SET_INQUIRY_SHOW_SHIPPING_COST_FORM: 'SET_INQUIRY_SHOW_SHIPPING_COST_FORM',
    // SET_INQUIRY_SHOW_SHIPPING_DATE_FORM: 'SET_INQUIRY_SHOW_SHIPPING_DATE_FORM',
    SET_INQUIRY_SHOW_CONDITION_FORM: 'SET_INQUIRY_SHOW_CONDITION_FORM',
    SET_INQUIRY_SHOW_DISCOUNT_FORM: 'SET_INQUIRY_SHOW_DISCOUNT_FORM',
    FETCH_BANK_ACCOUNTS_REQUEST: 'FETCH_BANK_ACCOUNTS_REQUEST',
    FETCH_BANK_ACCOUNTS: 'FETCH_BANK_ACCOUNTS',
    FETCH_BANK_ACCOUNTS_SUCCESS: 'FETCH_BANK_ACCOUNTS_SUCCESS',
    FETCH_PAYMENT_OPTIONS_REQUEST: 'FETCH_PAYMENT_OPTIONS_REQUEST',
    FETCH_PAYMENT_OPTIONS: 'FETCH_PAYMENT_OPTIONS',
    FETCH_PAYMENT_OPTIONS_SUCCESS: 'FETCH_PAYMENT_OPTIONS_SUCCESS',
    SAVE_INQUIRY_REQUEST: 'SAVE_INQUIRY_REQUEST',
    SAVE_INQUIRY: 'SAVE_INQUIRY',
    SAVE_INQUIRY_SUCCESS: 'SAVE_INQUIRY_SUCCESS',
    SAVE_INQUIRY_ERRORS: 'SAVE_INQUIRY_ERRORS',
    SET_SELECT_LINE_ITEM: 'SET_SELECT_LINE_ITEM',
    // ADD_LINE_ITEM_REQUEST: 'ADD_LINE_ITEM_REQUEST',
    // ADD_LINE_ITEM_SAVING: 'ADD_LINE_ITEM_SAVING',
    // ADD_LINE_ITEM_SUCCESS: 'ADD_LINE_ITEM',
    CLEAR_INQUIRY_LINE_ITEMS_REQUEST: 'CLEAR_INQUIRY_LINE_ITEMS_REQUEST',
    CLEAR_INQUIRY_LINE_ITEMS_SUCCESS: 'CLEAR_INQUIRY_LINE_ITEMS_SUCCESS',
    INQUIRY_LINE_ITEMS_IMPORT_FILE_REQUEST: 'INQUIRY_LINE_ITEMS_IMPORT_FILE_REQUEST',
    INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING: 'INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING',
    SET_TRUCK_RESERVATION_MODAL: 'SET_TRUCK_RESERVATION_MODAL',

    SET_INQUIRY_SHIPPING_COST_AMOUNT: 'SET_INQUIRY_SHIPPING_COST_AMOUNT',
    FETCH_VEHICLE_TYPES_REQUEST: 'FETCH_VEHICLE_TYPES_REQUEST',
    FETCH_VEHICLE_TYPES: 'FETCH_VEHICLE_TYPES',
    FETCH_VEHICLE_TYPES_SUCCESS: 'FETCH_VEHICLE_TYPES_SUCCESS',
    SET_INQUIRY_CONDITIONS_AMOUNT: 'SET_INQUIRY_CONDITIONS_AMOUNT',
    SET_SHOW_ADD_ITEM_FORM: 'SET_SHOW_ADD_ITEM_FORM',
    SAVE_INQUIRY_LINE_ITEMS_REQUEST: 'SAVE_INQUIRY_LINE_ITEMS_REQUEST',
    SET_SAVING_INQUIRY_LINE_ITEMS: 'SET_SAVING_INQUIRY_LINE_ITEMS',
    SAVE_INQUIRY_LINE_ITEMS_SUCCESS: 'SAVE_INQUIRY_LINE_ITEMS_SUCCESS',
    SAVE_INQUIRY_LINE_ITEMS_ERRORS: 'SAVE_INQUIRY_LINE_ITEMS_ERRORS',
    DELETE_INQUIRY_LINE_ITEMS_REQUEST: 'DELETE_INQUIRY_LINE_ITEMS_REQUEST',
    DELETE_INQUIRY_LINE_ITEMS: 'DELETE_INQUIRY_LINE_ITEMS',
    DELETE_INQUIRY_LINE_ITEMS_SUCCESS: 'DELETE_INQUIRY_LINE_ITEMS_SUCCESS',
    SET_INQUIRY_REQUEST_ITEM: 'SET_INQUIRY_REQUEST_ITEM',
    SET_POSITION_INQUIRY_LINE_ITEMS_REQUEST: 'SET_POSITION_INQUIRY_LINE_ITEMS_REQUEST',
    SET_POSITION_INQUIRY_LINE_ITEMS: 'SET_POSITION_INQUIRY_LINE_ITEMS',
    SET_POSITION_INQUIRY_LINE_ITEMS_SUCCESS: 'SET_POSITION_INQUIRY_LINE_ITEMS_SUCCESS',
    SET_LOAD_INQUIRY_LINE_ITEMS: 'SET_LOAD_INQUIRY_LINE_ITEMS',
    FETCH_POSITION_INQUIRY_LINE_ITEMS: 'FETCH_POSITION_INQUIRY_LINE_ITEMS',
    SET_VAT_INCLUDED: 'SET_VAT_INCLUDED',
    SET_PRICE_UNIT_CHANGE: 'SET_PRICE_UNIT_CHANGE',
    DUPLICATE_ORDER_REQUEST: 'DUPLICATE_ORDER_REQUEST',
    DUPLICATE_ORDER_SAVING: 'DUPLICATE_ORDER_SAVING',
    DUPLICATE_ORDER_SUCCESS: 'DUPLICATE_ORDER_SUCCESS',
    SET_INQUIRY_SHOW_CATEGORY_FORM: 'SET_INQUIRY_SHOW_CATEGORY_FORM',
    SET_INQUIRY_LINE_ITEMS_PAGINATION: 'SET_INQUIRY_LINE_ITEMS_PAGINATION',
    SET_SHOW_MARGIN_FORM: 'SET_SHOW_MARGIN_FORM',
    SET_SHOW_MARGIN_PERCENT_FORM: 'SET_SHOW_MARGIN_PERCENT_FORM',
    FETCH_ITEM_BY_NAME_REQUEST: 'FETCH_ITEM_BY_NAME_REQUEST',
    FETCH_ITEM_BY_NAME_SUCCESS: 'FETCH_ITEM_BY_NAME_SUCCESS',
    SET_ITEM_LOADING: 'SET_ITEM_LOADING',
    SAVE_LINE_ITEM_CLIPBOARD_REQUEST: 'SAVE_LINE_ITEM_CLIPBOARD_REQUEST',
    // SET_VIEW_ITEM_HISTORY_MODAL: 'SET_VIEW_ITEM_HISTORY_MODAL',
    // SET_ITEM_HISTORY_ID: 'SET_ITEM_HISTORY_ID',
    // FETCH_ITEM_PRICE_HISTORY_REQUEST: 'FETCH_ITEM_PRICE_HISTORY_REQUEST',
    // FETCH_ITEM_PRICE_HISTORY: 'FETCH_ITEM_PRICE_HISTORY',
    // FETCH_ITEM_PRICE_HISTORY_SUCCESS: 'FETCH_ITEM_PRICE_HISTORY_SUCCESS',
    // FETCH_SOURCING_PROFILE_REQUEST: 'FETCH_SOURCING_PROFILE_REQUEST',
    // FETCH_SOURCING_PROFILE: 'FETCH_SOURCING_PROFILE',
    // FETCH_SOURCING_PROFILE_SUCCESS: 'FETCH_SOURCING_PROFILE_SUCCESS',
    SET_SHIPPING_COST: 'SET_SHIPPING_COST',
    SET_SHIPPING_COST_SUCCESS: 'SET_SHIPPING_COST_SUCCESS',
    SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD: 'SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD',
    SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS: 'SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS',
    SAVE_LEAD_DISTRIBUTIONS_REQUEST: 'SAVE_LEAD_DISTRIBUTIONS_REQUEST',
    SAVE_LEAD_DISTRIBUTIONS: 'SAVE_LEAD_DISTRIBUTIONS',
    // SAVE_LEAD_DISTRIBUTIONS_SUCCESS: 'SAVE_LEAD_DISTRIBUTIONS_SUCCESS',
    SET_INQUIRY_SHOW_TRANSFER_FORM: 'SET_INQUIRY_SHOW_TRANSFER_FORM',
    SET_DUPLICATE_ORDER_MODAL: 'SET_DUPLICATE_ORDER_MODAL',
    SET_INQUIRY_SHOW_ISSUE_TAX_INVOICE_FORM: 'SET_INQUIRY_SHOW_ISSUE_TAX_INVOICE_FORM',
    INQUIRY_LINE_ITEMS_EXPORT_FILE_REQUEST: 'INQUIRY_LINE_ITEMS_EXPORT_FILE_REQUEST',
    INQUIRY_LINE_ITEMS_EXPORTING: 'INQUIRY_LINE_ITEMS_EXPORTING',
    SET_INQUIRY_SHOW_PROFIT_SHARING_FORM: 'SET_INQUIRY_SHOW_PROFIT_SHARING_FORM',
    INQUIRY_SET_SHOW_PROJECT_CONTACT_FORM: 'INQUIRY_SET_SHOW_PROJECT_CONTACT_FORM',
    INQUIRY_LINE_ITEMS_EXPORT_PDF_DOCUMENT_FILE_REQUEST: 'INQUIRY_LINE_ITEMS_EXPORT_PDF_DOCUMENT_FILE_REQUEST',
    INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT: 'INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT',
    SET_INQUIRY_SHOW_CREDIT_CARD_INSTALLMENT_FORM: 'SET_INQUIRY_SHOW_CREDIT_CARD_INSTALLMENT_FORM',
    SET_SHOW_ADD_PRODUCT_GROUP_ITEM_FORM: 'SET_SHOW_ADD_PRODUCT_GROUP_ITEM_FORM',
    SET_SHOW_ITEM_AS_CARD: 'SET_SHOW_ITEM_AS_CARD',
    SET_SHOW_ADD_MEGA_HOME_ITEM_FORM: 'SET_SHOW_ADD_MEGA_HOME_ITEM_FORM',
    SET_SELECTED_MEGA_HOME_ITEM_IDS: 'SET_SELECTED_MEGA_HOME_ITEM_IDS',
    SET_MEGA_HOME_ITEM_QUANTITY_SELECTED: 'SET_MEGA_HOME_ITEM_QUANTITY_SELECTED',
    INQUIRY_TOGGLE_ITEM_PRICES_MODAL: 'INQUIRY_TOGGLE_ITEM_PRICES_MODAL',
    SET_INQUIRY_SHOW_SUPPLIER_NOTES_FORM: 'SET_INQUIRY_SHOW_SUPPLIER_NOTES_FORM',
    SET_INQUIRY_SHOW_ORDER_NOTES_FORM: 'SET_INQUIRY_SHOW_ORDER_NOTES_FORM',
    SET_INQUIRY_HEADER_CATEGORY_FORM: 'SET_INQUIRY_HEADER_CATEGORY_FORM',
    SET_TRUCK_RESERVATION_DERIVEREE_MODAL: 'SET_TRUCK_RESERVATION_DERIVEREE_MODAL',
    SET_TRUCK_RESERVATION_LALAMOVE_MODAL: 'SET_TRUCK_RESERVATION_LALAMOVE_MODAL',

    fetchInquiry: (id) => ({
        type: actions.FETCH_INQUIRY_REQUEST,
        payload: { id }
    }),
    setSearchQuery: (itemQuery, page = 1) => {
        return {
            type: actions.SET_INQUIRY_ITEM_SEARCH_QUERY,
            itemQuery: itemQuery,
            payload: { itemQuery, page }
        }
    },
    setItemQuery: (itemQuery) => {
        return {
            type: actions.SET_INQUIRY_ITEM_QUERY,
            itemQuery: itemQuery
        }
    },
    setCategoryFilter: (categoryId) => {
        return {
            type: actions.SET_INQUIRY_ITEM_CATEGORY,
            categoryId: categoryId
        }
    },
    setSubCategoryFilter: (subCategoryId) => {
        return {
            type: actions.SET_INQUIRY_ITEM_SUB_CATEGORY,
            subCategoryId: subCategoryId
        }
    },
    setProductTypeFilter: (productTypeId) => {
        return {
            type: actions.SET_INQUIRY_ITEM_PRODUCT_TYPE,
            productTypeId: productTypeId
        }
    },
    // send all for don't need the paginate for lineItems
    fetchInquiryLineItems: (orderId, page = 1, all = false, { onSuccess = () => {}, onError = () => {} } = {}) => ({
        type: actions.FETCH_INQUIRY_LINE_ITEMS_REQUEST,
        payload: { orderId, page, all, onSuccess, onError }
    }),
    fetchInquiryItems: (page = 1) => ({
        type: actions.FETCH_INQUIRY_ITEMS_REQUEST,
        payload: { page }
    }),
    fetchInquiryCustomer: (id) => ({
        type: actions.FETCH_INQUIRY_CUSTOMER_REQUEST,
        payload: { id }
    }),
    // fetchInquiryQuotationPdf: (orderId, preview, locale = 'th') => ({
    //     type: actions.FETCH_INQUIRY_QUOTATION_PDF_REQUEST,
    //     payload: { orderId, preview, locale }
    // }),
    fetchBankAccounts: (allBank = false) => ({
        type: actions.FETCH_BANK_ACCOUNTS_REQUEST,
        payload: { allBank }
    }),
    fetchPaymentOptions: () => ({
        type: actions.FETCH_PAYMENT_OPTIONS_REQUEST
    }),
    saveOrderForm: (orderId, inquiry, { onSuccess = () => {} } = {}) => ({
        type: actions.SAVE_INQUIRY_REQUEST,
        payload: { orderId, inquiry, onSuccess }
    }),
    setShowTransferForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_TRANSFER_FORM,
        value
    }),
    setShowPaymentForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_PAYMENT_FORM,
        value
    }),
    setShowNoteForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_NOTE_FORM,
        value
    }),
    setShowShippingAddressForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_SHIPPING_ADDRESS_FORM,
        value
    }),
    setShowSaleForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_SALE_FORM,
        value
    }),
    setShowCsForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_CS_FORM,
        value
    }),
    setShowShippingCostForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_SHIPPING_COST_FORM,
        value
    }),
    // setShowShippingDateForm: (value) => ({
    //     type: actions.SET_INQUIRY_SHOW_SHIPPING_DATE_FORM,
    //     value
    // }),
    setShowConditionForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_CONDITION_FORM,
        value
    }),
    setShowDiscountForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_DISCOUNT_FORM,
        value
    }),
    setShowCategoryForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_CATEGORY_FORM,
        value
    }),
    fetchVehicleTypes: () => ({
        type: actions.FETCH_VEHICLE_TYPES_REQUEST
    }),
    setShippingCostFormAmount: (amount) => ({
        type: actions.SET_INQUIRY_SHIPPING_COST_AMOUNT,
        amount
    }),
    setSelectLineItem: (value) => ({
        type: actions.SET_SELECT_LINE_ITEM,
        value
    }),
    // addLineItem: (value, router) => ({
    //     type: actions.ADD_LINE_ITEM_REQUEST,
    //     payload: { value, router }
    // }),
    setAddRequestItemForm: (value) => ({
        type: actions.SET_SHOW_ADD_ITEM_FORM,
        value
    }),
    setAddProductGroupItemForm: (value) => ({
        type: actions.SET_SHOW_ADD_PRODUCT_GROUP_ITEM_FORM,
        value
    }),
    setOrderConditionAmount: (amount) => ({
        type: actions.SET_INQUIRY_CONDITIONS_AMOUNT,
        amount
    }),
    saveOrderLineItems: (orderId, lineItems, id = null, { onSuccess = () => {} } = {}) => ({
        type: actions.SAVE_INQUIRY_LINE_ITEMS_REQUEST,
        payload: { orderId, lineItems, id, onSuccess }
    }),
    deleteOrderLineItem: (orderId, id) => ({
        type: actions.DELETE_INQUIRY_LINE_ITEMS_REQUEST,
        payload: { orderId, id }
    }),
    setRequestItemId: (itemId) => ({
        type: actions.SET_INQUIRY_REQUEST_ITEM,
        itemId
    }),
    movePositionLineItem: (orderId, id, lineItem) => ({
        type: actions.SET_POSITION_INQUIRY_LINE_ITEMS_REQUEST,
        payload: { orderId, id, lineItem }
    }),
    setVatIncluded: (value) => ({
        type: actions.SET_VAT_INCLUDED,
        value
    }),
    onUnitChange: (itemPrice, modifier) => ({
        type: actions.SET_PRICE_UNIT_CHANGE,
        entities: {
            items: {
                [itemPrice.itemId]: {
                    newPrice: itemPrice.newPrice,
                    quantityMultiplier: modifier
                }
            }
        }
    }),
    duplicateOrder: (customerId, orderId) => ({
        type: actions.DUPLICATE_ORDER_REQUEST,
        payload: { customerId, orderId }
    }),
    setDuplicateOrderModal: (value) => ({
        type: actions.SET_DUPLICATE_ORDER_MODAL,
        value
    }),
    toggleMarginForm: (value) => ({
        type: actions.SET_SHOW_MARGIN_FORM,
        value
    }),
    toggleMarginPercentForm: (value) => ({
        type: actions.SET_SHOW_MARGIN_PERCENT_FORM,
        value
    }),
    fetchItemByName: (itemName) => ({
        type: actions.FETCH_ITEM_BY_NAME_REQUEST,
        payload: { itemName }
    }),
    saveLineItemClipboard: (itemName) => ({
        type: actions.SAVE_LINE_ITEM_CLIPBOARD_REQUEST,
        payload: { itemName }
    }),
    // setViewItemHistoryModal: (value) => ({
    //     type: actions.SET_VIEW_ITEM_HISTORY_MODAL,
    //     value
    // }),
    // fetchItemPriceHistory: (itemId, postalCode) => ({
    //     type: actions.FETCH_ITEM_PRICE_HISTORY_REQUEST,
    //     payload: { itemId, postalCode }
    // }),
    // setItemHistoryId: (itemId, sourcingProfileId) => ({
    //     type: actions.SET_ITEM_HISTORY_ID,
    //     itemId,
    //     sourcingProfileId
    // }),
    // fetchSourcingProfile: (id) => ({
    //     type: actions.FETCH_SOURCING_PROFILE_REQUEST,
    //     payload: { id }
    // }),
    setShippingCost: (orderId, { onSuccess = () => {} } = {}) => ({
        type: actions.SET_SHIPPING_COST,
        payload: { orderId, onSuccess }
    }),

    setShippingCostWithLessTruckLoad: (orderId) => ({
        type: actions.SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD,
        payload: { orderId }
    }),

    saveLeadDistribution: (orderId) => ({
        type: actions.SAVE_LEAD_DISTRIBUTIONS_REQUEST,
        payload: { orderId }
    }),
    clearOrderLineItems: (orderId) => ({
        type: actions.CLEAR_INQUIRY_LINE_ITEMS_REQUEST,
        payload: { orderId }
    }),
    importLineItemsFile: (orderId, file) => ({
        type: actions.INQUIRY_LINE_ITEMS_IMPORT_FILE_REQUEST,
        payload: { orderId, file }
    }),
    setShowPurchaserForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_PURCHASER_FORM,
        value
    }),
    setTruckReservation: (value, id) => ({
        type: actions.SET_TRUCK_RESERVATION_MODAL,
        value, id
    }),
    setShowIssueTaxInvoiceForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_ISSUE_TAX_INVOICE_FORM,
        value
    }),

    setLineItemExportingRequest: (orderId) => ({
        type: actions.INQUIRY_LINE_ITEMS_EXPORT_FILE_REQUEST,
        payload: { orderId }
    }),

    setShowProfitSharingForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_PROFIT_SHARING_FORM,
        value
    }),

    toggleProjectContactForm: (value) => ({
        type: actions.INQUIRY_SET_SHOW_PROJECT_CONTACT_FORM,
        value
    }),

    exportLineItemPdfDocument: (orderId) => ({
        type: actions.INQUIRY_LINE_ITEMS_EXPORT_PDF_DOCUMENT_FILE_REQUEST,
        payload: { orderId }
    }),

    setShowCreditCardInstallmentForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_CREDIT_CARD_INSTALLMENT_FORM,
        value
    }),

    setShowItemAsCard: (value) => ({
        type: actions.SET_SHOW_ITEM_AS_CARD,
        value
    }),

    setAddMegaHomeItemForm: (value) => ({
        type: actions.SET_SHOW_ADD_MEGA_HOME_ITEM_FORM,
        value
    }),

    setSelectedMegaHomeItems: (id) => {
        return {
            type: actions.SET_SELECTED_MEGA_HOME_ITEM_IDS,
            id
        }
    },

    setMegaHomeItemQuantity: (quantity) => {
        return {
            type: actions.SET_MEGA_HOME_ITEM_QUANTITY_SELECTED,
            quantity
        }
    },
    setLineItemFormErrors: (formErrors = {}) => ({
        type: actions.SAVE_INQUIRY_LINE_ITEMS_ERRORS,
        formErrors
    }),
    toggleItemPricesModal: (itemId = null) => ({
        type: actions.INQUIRY_TOGGLE_ITEM_PRICES_MODAL,
        itemId
    }),
    setShowSupplierNotesForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_SUPPLIER_NOTES_FORM,
        value
    }),
    setShowOrderNotesForm: (value) => ({
        type: actions.SET_INQUIRY_SHOW_ORDER_NOTES_FORM,
        value
    }),
    setShowCategoryHeaderForm: (value) => ({
        type: actions.SET_INQUIRY_HEADER_CATEGORY_FORM,
        value
    }),
    setTruckReservationDeliveree: (value, id) => ({
        type: actions.SET_TRUCK_RESERVATION_DERIVEREE_MODAL,
        value, id
    }),
    setTruckReservationLalamove: (value, id) => ({
        type: actions.SET_TRUCK_RESERVATION_LALAMOVE_MODAL,
        value, id
    })
}

export default actions
