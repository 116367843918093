import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import ShippingRateIndexPage from '../containers/ShippingRates'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const ShippingRatesPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'shipping_rates') {
			dispatch(changeCurrent(['shipping_rates']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={ShippingRateIndexPage} exact />
		</Switch>
  )
}

ShippingRatesPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

ShippingRatesPage.defaultProps = {}

export default ShippingRatesPage
