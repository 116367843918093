import React, { Component } from 'react'
import { Form, Button, Input, Select, Row, Col, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

class TruckForm extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        truck: PropTypes.object.isRequired,
        vehicleTypes: PropTypes.array.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            truckDriverImage: props.imageDefault('driver'),
            truckFrontSideImage: props.imageDefault('front_side'),
            truckBackSideImage: props.imageDefault('back_side'),
            truckLeftSideImage: props.imageDefault('left_side'),
            truckRightSideImage: props.imageDefault('right_side')
        }
    }

    handleChangeImage = (e, name) => {
        this.setState({ [name]: e.fileList })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    onSubmit = (values) => {
        const { onSubmit } = this.props

        onSubmit(values)
    }

    renderFileList (images) {
        const { imageEntities } = this.props

        const fileList = images.map((file) => {
            let url = imageEntities.getIn([file.url, 'thumbBlob'])
            url = url || file.url

            if (url) {
                return {
                    uid: file.uid,
                    url: url
                }
            }

            return file
        })

        return fileList
    }

    renderUploadImage = (name) => {
        const { i18n } = this.props
        const stateImage = this.state[`truck${name}Image`]

        const uploadButton = (
            <div>
                {
                    this.state.loading ? <LoadingOutlined /> : <PlusOutlined />
                }
                <div className="ant-upload-text">Upload</div>
            </div>
        )
        return (
            <div>
                <Form.Item label={i18n.t(`trucks:${_.camelCase(name)}Image`)}
                    name={['truck_images_attributes', `${_.snakeCase(name)}`]}
                    initialValue={stateImage}
                    getValueFromEvent={this.normFile}>
                        <Upload
                            listType="picture-card"
                            fileList={this.renderFileList(stateImage)}
                            onChange={(e) => this.handleChangeImage(e, `truck${name}Image`)}
                            multiple
                            beforeUpload={() => false}>
                            {uploadButton}
                        </Upload>
                </Form.Item>
            </div>
        )
    }

    render () {
        const { onCancel, truck, vehicleTypes, provinces, error, saving, i18n } = this.props
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        return (
            <Form className="truck-form-component" onFinish={this.onSubmit}>
                <h3>{i18n.t('trucks:addTruck')}</h3>

                <Row>
                    <Col span={12}>
                        {/* Driver name input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'driver_name']}
                            initialValue={_.get(truck, 'driver_name', '')}
                            label={i18n.t('trucks:driverName')}
                            validateStatus={_.get(error, 'driver_name', undefined) && 'error'}
                            help={_.get(error, 'driver_name', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/* Vehicle type select */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'vehicle_type_id']}
                            initialValue={_.toString(_.get(truck, 'vehicle_type.id', ''))}
                            label={i18n.t('trucks:vehicleType')}
                            validateStatus={_.get(error, 'vehicle_type', undefined) && 'error'}
                            help={_.get(error, 'vehicle_type', []).join(', ')}>
                                <Select
                                    allowClear
                                    placeholder={i18n.t('trucks:vehicleType')}>
                                    {vehicleTypes.map((vehicleType, index) => {
                                        return (
                                            <Select.Option key={_.get(vehicleType, 'id', `index-${index}`)}>
                                                {_.get(vehicleType, 'name', '')}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        {/* License plate number input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'license_plate_number']}
                            initialValue={_.get(truck, 'license_plate_number', '')}
                            label={i18n.t('trucks:licensePlateNumber')}
                            validateStatus={_.get(error, 'license_plate_number', undefined) && 'error'}
                            help={_.get(error, 'license_plate_number', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/* License plate provinces input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'province_id']}
                            initialValue={_.toString(_.get(truck, 'province.id', ''))}
                            label={i18n.t('trucks:licensePlateProvinces')}
                            validateStatus={_.get(error, 'province', undefined) && 'error'}
                            help={_.get(error, 'province', []).join(', ')}>
                                <Select
                                    allowClear
                                    placeholder={i18n.t('trucks:licensePlateProvinces')}>
                                    {provinces.map((province, index) => {
                                        return (
                                            <Select.Option key={_.get(province, 'id', `index-${index}`)}>
                                                {_.get(province, 'name', '')}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        {/* Registration year input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'registration_year']}
                            initialValue={_.get(truck, 'registration_year', '')}
                            label={i18n.t('trucks:registrationYear')}
                            validateStatus={_.get(error, 'registration_year', undefined) && 'error'}
                            help={_.get(error, 'registration_year', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/* Brand input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'brand']}
                            initialValue={_.get(truck, 'brand', '')}
                            label={i18n.t('trucks:brand')}
                            validateStatus={_.get(error, 'brand', undefined) && 'error'}
                            help={_.get(error, 'brand', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        {/* weight input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'weight']}
                            initialValue={_.get(truck, 'weight', '')}
                            label={i18n.t('trucks:weight')}
                            validateStatus={_.get(error, 'weight', undefined) && 'error'}
                            help={_.get(error, 'weight', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {/* width input */}
                        <Form.Item {...formItemLayout}
                            name={['truck', 'width']}
                            initialValue={_.get(truck, 'width', '')}
                            label={i18n.t('trucks:width')}
                            validateStatus={_.get(error, 'width', undefined) && 'error'}
                            help={_.get(error, 'width', []).join(', ')}>
                                <Input />
                        </Form.Item>
                    </Col>
                </Row>

                {this.renderUploadImage('Driver')}
                <Row>
                    <Col span={6}>
                        {this.renderUploadImage('FrontSide')}
                    </Col>
                    <Col span={6}>
                        {this.renderUploadImage('BackSide')}
                    </Col>
                    <Col span={6}>
                        {this.renderUploadImage('LeftSide')}
                    </Col>
                    <Col span={6}>
                        {this.renderUploadImage('RightSide')}
                    </Col>
                </Row>


                {/* Buttons */}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={saving} style={{ marginRight: '8px' }}>
                        {i18n.t('trucks:submit')}
                    </Button>
                    <Button htmlType="button" onClick={onCancel}>
                        {i18n.t('trucks:cancel')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default withTranslation(['trucks'])(TruckForm)
