import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    Select,
    Spin
} from 'antd'

import { withTranslation } from 'react-i18next'

const SelectSupplier = ({
    i18n,
    defaultValue,
    loading,
    onChange,
    onSearch,
    suppliers,
    value,
    isShowHaveNoSupplier,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('suppliers:searchSupplier')}
            showArrow={false}
            showSearch
            style={restSelectProps.style || { width: '200px' }}
            value={value}
            {...restSelectProps}
        >
            {suppliers.map((supplier, index) => {
                return (
                    <Select.Option
                        key={_.get(supplier, 'id', `index-${index}`)}
                        value={_.get(supplier, 'id', undefined)}
                    >
                        {_.get(supplier, 'display_name', '')}
                    </Select.Option>
                )
            })}

            {isShowHaveNoSupplier ?
                <Select.Option key={'have-no-supplier'} value={'nil'}>
                    {i18n.t('suppliers:haveNoSupplier')}
                </Select.Option> : null
            }
        </Select>
    )
}

SelectSupplier.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    suppliers: PropTypes.array,
    value: PropTypes.string,
    isShowHaveNoSupplier: PropTypes.bool
}

SelectSupplier.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    suppliers: [],
    value: undefined
}

export default withTranslation(['suppliers'])(SelectSupplier)

