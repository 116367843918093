import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Spin } from 'antd'
import D3TimelineChart from '../d3TimelineChart'
import HistoryDashboardWrapper from './historyDashboard.style'

class HistoryDashboard extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    }

    componentDidUpdate () {
        const { loading } = this.props

        if (loading) return null

        this.renderChart()
    }

    renderChart () {
        const { data } = this.props
        const { d3 } = window
        const element = document.getElementById('chart')

        if (!d3 || !element) { return null }

        new D3TimelineChart(element, data, {
            height: (data.length * 50) + 50,
            tip: (d) => {
                return `${d.stateLabel}<br/>${moment(d.createdAt).format('LLL')}`
            }
        }).onVizChange()
    }

    render () {
        const { loading } = this.props

        if (loading) {
            return (
                <HistoryDashboardWrapper>
                    <div className="history-dashboard-loading">
                        <Spin size="large" />
                    </div>
                </HistoryDashboardWrapper>
            )
        }

        return (
            <HistoryDashboardWrapper>
                <div className="history-dashboard-component">
                    <div id="chart" />
                </div>
            </HistoryDashboardWrapper>
        )
    }
}

export default HistoryDashboard
