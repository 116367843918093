import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import TruckImageIndex from '../../../components/customers/truckImage/truckImageIndex'

import actions from '../../../redux/customers/truckImages/actions'

const { fetchCustomerTruckImages } = actions

class TruckImageIndexContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        const { fetchCustomerTruckImages, customerId } = this.props

        fetchCustomerTruckImages({ customerId })
    }

    getTruckImages () {
        const { truckImages, truckImageEntities } = this.props
        return truckImages.get('items', new List()).map((truckImageId) => {
            return truckImageEntities.get(truckImageId) || new Map()
        })
    }

    handlePageChange = (pagination) => {
        const { fetchCustomerTruckImages, customerId } = this.props

        fetchCustomerTruckImages({ customerId, page: pagination })
    }


    render () {
        const { customerId, truckImages } = this.props

        return (
            <TruckImageIndex
                customerId={customerId}
                loading={truckImages.get('loading')}
                onPageChange={this.handlePageChange}
                truckImages={this.getTruckImages().toJS()}
                pagination={{
                    ...truckImages.get('pagination').toJS(),
                    showSizeChanger: false
                }}
            />
        )
    }
}

const mapStateToProps = state => ({
    truckImages: state.get('customerTruckImages'),
    truckImageEntities: state.getIn(['Entities', 'itemTruckImages'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerTruckImages,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckImageIndexContainer)
