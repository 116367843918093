import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button, Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'

import SelectCustomerCompanyContainer from '../../containers/SelectCustomerCompany/SelectCustomerCompanyContainer'
import ProjectWrapper from './project.style'
import AreasSelectContainer from '../../containers/Areas/AreasSelectContainer'

const CompanyProjectForm = ({
    i18n,
    companyProject,
    formErrors,
    hideSelectCompany,
    onSubmitForm,
    saving,
    toggleForm
}) => {
    const [form] = Form.useForm()

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    }

    const addressLayout = {
        labelCol: { xs: { span: 24 }, sm: { span: 12 } },
        wrapperCol: { xs: { span: 24 }, sm: { span: 12 } }
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    const provinceValue = _.get(companyProject, 'province.id', null)
    const districtValue = _.get(companyProject, 'district.id', null)
    const postalCodeValue = _.get(companyProject, 'postal_code', null)

    return (
        <ProjectWrapper>
            <h3>{i18n.t('projects:formTitle')}</h3>
            <Form onFinish={handleSubmitForm} {...formItemLayout} form={form}>
                <Form.Item
                    label={i18n.t('projects:name')}
                    name='name'
                    initialValue={_.get(companyProject, 'name')}
                    validateStatus={formErrors.name && 'error'}
                    help={formErrors.name}
                >
                    <Input placeholder={i18n.t('projects:project')} />
                </Form.Item>

                { !hideSelectCompany ?
                    <Form.Item label={i18n.t('projects:company')}
                    name='customer_company_id'
                    initialValue={_.get(companyProject, 'customer_company.id', undefined)}>
                        <SelectCustomerCompanyContainer />
                    </Form.Item> : null }

                <Form.Item>
                    <AreasSelectContainer form={form}
                        districtFormItemLayout={addressLayout}
                        provinceFormItemLayout={addressLayout}
                        postalCodeFormItemLayout={addressLayout}
                        districtCol={{ md: { span: 24 } }}
                        postalCodeCol={{ md: { span: 24 } }}
                        provinceCol={{ md: { span: 24 } }}
                        districtValue={districtValue}
                        postalCodeValue={postalCodeValue}
                        provinceValue={provinceValue}
                    />
                </Form.Item>

                <Form.Item className="project-form-action-btn" {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                    <Button htmlType="button" onClick={toggleForm}>{i18n.t('shared:close')}</Button>
                </Form.Item>
            </Form>
        </ProjectWrapper>
    )
}

CompanyProjectForm.propTypes = {
    companyProject: PropTypes.object,
    formErrors: PropTypes.object,
    hideSelectCompany: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired
}

CompanyProjectForm.defaultProps = {
    hideSelectCompany: false
}

export default withTranslation(['shared', 'projects'])(CompanyProjectForm)
