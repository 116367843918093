import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'immutable'
import { gql, useQuery } from '@apollo/client'

import EventLogsIndex from '../../components/eventLogs/eventLogsIndex'

import actions from '../../redux/eventLogs/actions'

const { setFilters } = actions

const EVENT_LOGS_QUERY = gql`
	query CustomerEventLogs($createdDate: String, $sortBy: String) {
		customerEventLogs(createdDate: $createdDate, sortBy: $sortBy) {
			id
			resourceName
			activityType
			sourceUrl
			order {
				id
				number
			}
			customer {
				id
				customerNo
				fullName
			}
			whodunit {
				id
				fullName
			}
			createdAt {
				display
			}
		}
	}
`

const EventLogsIndexContainer = () => {
	const dispatch = useDispatch()
	const filters = useSelector(state => state.getIn(['eventLogs', 'filters'], new Map())).toJS()
	const { sortBy } = filters

	const { loading, data } = useQuery(EVENT_LOGS_QUERY, {
		variables: {
			...filters,
			sortBy: sortBy.order ? `${sortBy.field} ${sortBy.order.replace('end', '')}` : ''
		}
	})

	const onChangeFilters = (args = {}) => {
		dispatch(setFilters({ ...filters, ...args }))
	}

	const onChangeTable = (pagination, filters, { columnKey, order }) => {
		onChangeFilters({ sortBy: { field: columnKey, order } })
	}

	return (
		<EventLogsIndex
			eventLogs={(data && data.customerEventLogs) || []}
			filters={filters}
			loading={loading}
			onChangeFilters={onChangeFilters}
			onChangeTable={onChangeTable}
		/>
	)
}

EventLogsIndexContainer.propTypes = {}

EventLogsIndexContainer.defaultProps = {}

export default EventLogsIndexContainer
