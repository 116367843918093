import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Form, DatePicker, Button, Card } from 'antd'

import vatDocumentActions from '../../redux/vatDocuments/actions'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

const { fetchTransferBackPVReport } = vatDocumentActions
const { RangePicker } = DatePicker

class ExportTransferBackReport extends Component {
    constructor (props) {
        super(props)


        this.formRef = createRef()
    }

    handleSubmit = (e) => {
        const { fetchTransferBackPVReport } = this.props
        e.preventDefault()

        this.formRef.current.validateFields().then(fieldValue => {
            let startDate = null
            let endDate = null
            if (fieldValue['range_picker']) {
                startDate = fieldValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldValue['range_picker'][1].format('YYYY-MM-DD')
            }

            const values = {
                start_date: startDate,
                end_date: endDate
            }

            fetchTransferBackPVReport(values)
        })
    }

    renderCardHead = () => {
        const { i18n } = this.props

        return (
            <Card.Meta
                title={i18n.t('documents:transferBackReport.download')}
                description={i18n.t('documents:transferBackReport.description')} />
        )
    }

    render () {
        const { form, vatDocuments, i18n } = this.props
        const loading = vatDocuments.get('transferBackPVLoading')
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 }
        }
        return (
            <Card title={this.renderCardHead()}
                style={{ margin: '20px 0' }}>
                <Form layout="inline"
                    ref={this.formRef}
                    labelAlign="left"
                    hideRequiredMark>
                    <Form.Item key="search_by_date" label={i18n.t('documents:searchByDate')}
                        name='range_picker'
                        rules={[{
                            required: true,
                            message: i18n.t('documents:pleaseInputDate')
                        }]}
                        {...formItemLayout}>
                            <RangePicker />
                    </Form.Item>
                    <Form.Item key="action_btn" {...buttonItemLayout}>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('documents:transferBackReport.download')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    vatDocuments: state.get('vatDocuments')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTransferBackPVReport
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('documents')(ExportTransferBackReport))
