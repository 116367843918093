import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import { Select } from 'antd'

import actions from '../../redux/productGroupItems/actions'

const { fetchProductGroupItems } = actions

const SelectProductGroupItem = ({
    onChange,
    productGroupId,
    value
}) => {
    const { i18n } = useTranslation('productGroups')
    const dispatch = useDispatch()
    const items = useSelector(state => state.getIn(['productGroupItems', 'items']) || new List()).toJS()
    const loading = useSelector(state => state.getIn(['productGroupItems', 'loading']) || false)
    const productGroupItemEntities = useSelector(state => state.getIn(['Entities', 'productGroupItems']) || new Map())
    const [innerValue, setInnerValue] = useState(undefined)

    useEffect(() => {
        if (productGroupId) {
            dispatch(fetchProductGroupItems({ productGroupId }))
        }
    }, [productGroupId])

    useEffect(() => {
        if (value !== innerValue) { setInnerValue(value) }
    }, [value])

    const handleChange = (newValue) => {
        setInnerValue(newValue)
        onChange(newValue)
    }

    const onFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const renderOptions = () => {
        return items.map((id) => {
            const productGroupItem = productGroupItemEntities.get(id) || new Map()
            return (
                <Select.Option value={productGroupItem.get('id')}>
                    {productGroupItem.get(`name_${i18n.language}`)}
                </Select.Option>
            )
        })
    }

    return (
        <Select disabled={!productGroupId}
            filterOption={onFilterOption}
            loading={loading}
            onChange={handleChange}
            placeholder={i18n.t('productGroups:selectProductGroupItem')}
            value={innerValue}
            style={{ width: '100%' }}
            showSearch>
            {renderOptions()}
        </Select>
    )
}

SelectProductGroupItem.propTypes = {
    onChange: PropTypes.func,
    productGroupId: PropTypes.string,
    value: PropTypes.string
}

SelectProductGroupItem.defaultProps = {
    onChange: () => {}
}

export default SelectProductGroupItem
