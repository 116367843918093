import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_YIELD_FETCH_SUCCESS,
    ORDER_YIELD_SET_LOADING,
    ORDER_YIELDS_FETCH_FAIL,
    ORDER_YIELDS_FETCH_SUCCESS,
    ORDER_YIELDS_FETCHING,
    ORDER_YIELDS_INVOICE_FETCHING,
    ORDER_YIELDS_INVOICE_FETCH_SUCCESS,
    ORDER_YIELDS_INVOICE_SET_PAGINATION,
    ORDER_YIELDS_INVOICE_SET_SORTER
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    page: 1,
    per: 20,
    order: 'updated_at desc',
    startDate: '',
    endDate: '',
    saleId: '',
    isCompleteYet: '',
    isRvEqualToInvoices: '',
    totalPages: 0,
    orderBusinessAddressItems: new List(),
    orderBusinessAddressesLoading: false,
    orderBusinessAddressesPage: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    sorter: new Map(),
    invStartDate: undefined,
    invEndDate: undefined
})

export default function orderYieldsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_YIELD_SET_LOADING:
            return state.merge({
                loading: action.loading
            })
        case ORDER_YIELD_FETCH_SUCCESS:
            return state.merge({
                items: [].concat(action.result)
            })
        case ORDER_YIELDS_FETCHING:
            return state.merge({ loading: true })
        case ORDER_YIELDS_FETCH_FAIL:
            return state.merge({ loading: false })
        case ORDER_YIELDS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false,
                page: action.page,
                per: action.per,
                order: action.order,
                startDate: action.startDate,
                endDate: action.endDate,
                saleId: action.saleId,
                isCompleteYet: action.isCompleteYet,
                isRvEqualToInvoices: action.isRvEqualToInvoices,
                totalPages: action.totalPages
            })
        case ORDER_YIELDS_INVOICE_FETCHING:
            return state.merge({ orderBusinessAddressesLoading: true })
        case ORDER_YIELDS_INVOICE_FETCH_SUCCESS:
            return state.merge({
                orderBusinessAddressItems: new List(result),
                orderBusinessAddressesLoading: false,
                page: action.page,
                totalPages: action.totalPages,
                invStartDate: action.invStartDate,
                invEndDate: action.invEndDate
            })
        case ORDER_YIELDS_INVOICE_SET_PAGINATION:
            return state.mergeDeep({
                orderBusinessAddressesPage: action.pagination
            })
        case ORDER_YIELDS_INVOICE_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        default:
            return state
    }
}
