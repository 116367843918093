import { takeEvery, call, all, put } from 'redux-saga/effects'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'


function* fetchCustomerLabels() {
  yield takeEvery('CUSTOMER_LABELS_FETCH_REQUEST', function *() {
    const data = yield call(apiFetch, '/customer_labels')
    const formattedData = deserialize(data)
    yield put({
      type: 'CUSTOMER_LABELS_FETCH_SUCCESS',
      result: formattedData
    })
  })
}

export default function *rootSaga() {
  yield all([
    fetchCustomerLabels()
  ])
}