import styled from 'styled-components'

const ProfitSharingWrapper = styled.div`
  .profit-sharing-header {
    margin-bottom: 12px;
    text-align: right;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
  }

  .image-thumbnail {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin: 2px;
    padding: 2px;
  }
`

export default ProfitSharingWrapper
