import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Table, Popconfirm, Modal, Button } from 'antd'
import { EditTwoTone, DeleteOutlined } from '@ant-design/icons'

import ChartOfAccountFormContainer from '../../containers/ChartOfAccounts/ChartOfAccountFormContainer'
import ChartOfAccountsListWrapper from './ChartOfAccountsList.style'

const ChartOfAccountsList = ({
    onTableChange,
    loading,
    pagination,
    chartOfAccounts,
    onDeleteChartOfAccount,
    toggleChartOfAccountForm,
    showChartOfAccountForm,
    chartOfAccountId,
    i18n
}) => {
    const columns = [{
        key: 'account_name',
        title: i18n.t('chartOfAccounts:accountName'),
        dataIndex: 'account_name'
      }, {
        key: 'account_number',
        title: i18n.t('chartOfAccounts:accountNumber'),
        dataIndex: 'account_number'
      }, {
        key: 'chart_of_account_type',
        title: i18n.t('chartOfAccounts:type'),
        dataIndex: 'chart_of_account_type',
        render: (record) => i18n.t(`chartOfAccounts:types.${_.camelCase(record)}`)
      }, {
        key: 'actions',
        title: 'Actions',
        render: (record) => {
          return (
            <div>
                <a onClick={() => toggleChartOfAccountForm({ selected: record.id })}>
                    <EditTwoTone />
                </a>
                <Popconfirm title={i18n.t('chartOfAccounts:confirmDelete')}
                    onConfirm={() => onDeleteChartOfAccount(record.id)}>
                    <a className="icon" style={{ marginLeft: '10px' }}>
                        <DeleteOutlined />
                    </a>
                </Popconfirm>
            </div>
          )
        }
      }
    ]

    return (
      <ChartOfAccountsListWrapper>
        <div className="charge-of-accounts-list-header">
          <h2>{i18n.t('chartOfAccounts:title')}</h2>
          <Button type="primary"
            onClick={toggleChartOfAccountForm}>
              {i18n.t('shared:add')}
            </Button>
        </div>

          <Table
              columns={columns}
              dataSource={chartOfAccounts}
              loading={loading}
              onChange={onTableChange}
              pagination={pagination}
              rowKey="id"
          />
            <Modal
                visible={showChartOfAccountForm}
                centered
                onCancel={toggleChartOfAccountForm}
                footer={null}
                destroyOnClose>
              <ChartOfAccountFormContainer
                toggleForm={toggleChartOfAccountForm}
                chartOfAccountId={chartOfAccountId} />
            </Modal>
      </ChartOfAccountsListWrapper>
    )
}

ChartOfAccountsList.propTypes = {
    columns: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    chartOfAccounts: PropTypes.array
}

ChartOfAccountsList.defaultProps = {
    columns: [],
    onTableChange: () => {},
    pagination: false
}

export default withTranslation('chartOfAccounts')(ChartOfAccountsList)
