import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import actions from '../../../redux/potentialLeads/actions'

import PotentialLeadForm from '../../../components/customers/potentialLeadForm'

const {
    saveCustomerPotentialLead,
    setCustomerPotentialLeadForm
} = actions

class PotentialLeadFormContainer extends Component {
    getPotentialLead () {
        const { id, potentialLeadEntities } = this.props
        if (!id) return (new Map())

        return (
            potentialLeadEntities.get(`${id}`)
        )
    }

    handleSubmitForm = ({ values }) => {
        const { saveCustomerPotentialLead, customerId, setCustomerPotentialLeadForm, id } = this.props

        saveCustomerPotentialLead({
            customerId,
            id,
            params: values,
            onSuccess: () => {
                setCustomerPotentialLeadForm(false)
            }
        })
    }

    render () {
        const { potentialLeads } = this.props
        return (
            <PotentialLeadForm onSubmit={this.handleSubmitForm}
                potentialLead={this.getPotentialLead()}
                saving={potentialLeads.get('saving')}/>
        )
    }
}

const mapStateToProps = state => ({
    potentialLeads: state.get('potentialLeads'),
    potentialLeadEntities: state.getIn(['Entities', 'potentialLeads'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCustomerPotentialLead,
        setCustomerPotentialLeadForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PotentialLeadFormContainer)
