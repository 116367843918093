import React, { Component } from 'react'
import { Form, Button, Input } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

class TruckOwnerBankAccountForm extends Component {
    static propTypes = {
        bankAccount: PropTypes.object.isRequired,
        error: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    }

    onSubmit = (values) => {
        const { onSubmit } = this.props

        onSubmit(values)
    }

    render () {
        const { onCancel, bankAccount, error, i18n } = this.props

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        const formButtonLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 }
            }
        }

        return (
            <Form className="truck-form-component" onFinish={this.onSubmit}>
                <h3>{i18n.t('trucks:addBankAccount')}</h3>

                {/* bank name input */}
                <Form.Item {...formItemLayout}
                    name={['truck_owner_bank_account', 'bank_name']}
                    initialValue={_.get(bankAccount, 'bank_name', '')}
                    label={i18n.t('trucks:bankAccount.bankName')}
                    validateStatus={_.get(error, 'bank_name', undefined) && 'error'}
                    help={_.get(error, 'bank_name', []).join(', ')}>
                        <Input />
                </Form.Item>

                {/* bank account name input */}
                <Form.Item {...formItemLayout}
                    name={['truck_owner_bank_account', 'name']}
                    initialValue={_.get(bankAccount, 'name', '')}
                    label={i18n.t('trucks:bankAccount.name')}
                    validateStatus={_.get(error, 'name', undefined) && 'error'}
                    help={_.get(error, 'name', []).join(', ')}>
                        <Input />
                </Form.Item>

                {/* bank account number input */}
                <Form.Item {...formItemLayout}
                    name={['truck_owner_bank_account', 'number']}
                    initialValue={_.get(bankAccount, 'number', '')}
                    label={i18n.t('trucks:bankAccount.number')}
                    validateStatus={_.get(error, 'number', undefined) && 'error'}
                    help={_.get(error, 'number', []).join(', ')}>
                        <Input />
                </Form.Item>

                {/* branch code input */}
                <Form.Item {...formItemLayout}
                    name={['truck_owner_bank_account', 'branch_code']}
                    initialValue={_.get(bankAccount, 'branch_code', '')}
                    label={i18n.t('trucks:bankAccount.branchCode')}
                    validateStatus={_.get(error, 'branch_code', undefined) && 'error'}
                    help={_.get(error, 'branch_code', []).join(', ')}>
                        <Input />
                </Form.Item>

                {/* Buttons */}
                <Form.Item {...formButtonLayout}>
                    <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
                        {i18n.t('trucks:submit')}
                    </Button>
                    <Button htmlType="button" onClick={onCancel}>
                        {i18n.t('trucks:cancel')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default withTranslation(['trucks'])(TruckOwnerBankAccountForm)
