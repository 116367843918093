import React, {useEffect} from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import SupplierInvoicesListContainer from './SupplierInvoicesContainer'

const SupplierInvoiceIndexPage = () => {
    useEffect(() => {
        document.title = 'Supplier Invoices | OSH CRM'
    }, [])

    return (
        <Layout>
            <LayoutContentWrapper breadcrumbs={[
                { path: '/supplier_invoices', name: 'Supplier Invoices' }
            ]}>
                <ContentLayout>
                    <SupplierInvoicesListContainer />
                </ContentLayout>
            </LayoutContentWrapper>
        </Layout>
    )
}

export default SupplierInvoiceIndexPage
