import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import moment from 'moment'
import _ from 'lodash'

import PaymentVouchersIndex from '../../components/paymentVouchers/paymentVouchersIndex'

import actions from '../../redux/paymentVouchers/actions'

const { fetchPaymentVouchers, savePaymentVoucher } = actions

const PaymentVouchersIndexContainer = ({ location, history }) => {
    const dispatch = useDispatch()
    const bankStatementEntities = useSelector(state => state.getIn(['Entities', 'bankStatements'], new Map()))
    const paymentVoucherEntities = useSelector(state => state.getIn(['Entities', 'paymentVouchers'], new Map()))
    const items = useSelector(state => state.getIn(['paymentVouchers', 'items'], new List()))
    const pagination = useSelector(state => state.getIn(['paymentVouchers', 'pagination']))
    const loading = useSelector(state => state.getIn(['paymentVouchers', 'loading']))
    const filters = useSelector(state => state.getIn(['paymentVouchers', 'filters']))

    useEffect(() => {
        const queryStringObject = parseParams(location.search)

        history.push({ search: location.search, params: queryStringObject })
        const queryString = {}
        _.mapKeys(queryStringObject, (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        onFetchPaymentVouchers({ ...queryString })
    },  [])

    const getPaymentVouchers = () => {
        return items.map((id) => {
            const paymentVoucher = paymentVoucherEntities.get(id) || new Map()
            return new Map({
                ...paymentVoucher.toJS(),
                bank_statement: getBankStatement(paymentVoucher.get('bank_statement')).toJS()
            })
        })
    }

    const getBankStatement = (id) => {
        return bankStatementEntities.get(id) || new Map()
    }

    const handlePageChange = (pagination) => {
        onFetchPaymentVouchers({ currentPage: pagination.current })
    }

    const handleSavePVBankStatement = ({ id, value }) => {
        dispatch(savePaymentVoucher(id, { params: { bank_statement_id: value } }))
    }

    const handleSendingStateChange = (value) => {
        onFetchPaymentVouchers({ haveBankStatement: value || '' })
    }

    const handleTransferDateChange = (values) => {
        onFetchPaymentVouchers({
            transferStartDate: values.length ? values[0].format('YYYY-MM-DD') : '',
            transferEndDate: values.length ? values[1].format('YYYY-MM-DD') : ''
        })
    }

    const onFetchPaymentVouchers = ({ transferStartDate, transferEndDate, haveBankStatement, currentPage } = {}) => {
        transferStartDate = (transferStartDate === undefined) ? filters.get('transferStartDate') : transferStartDate
        transferEndDate = (transferEndDate === undefined) ? filters.get('transferEndDate') : transferEndDate
        haveBankStatement = (haveBankStatement === undefined) ? filters.get('haveBankStatement') : haveBankStatement

        dispatch(fetchPaymentVouchers({
            page: currentPage,
            transferStartDate,
            transferEndDate,
            haveBankStatement
        }))

        setQueryStringUrl({ transferStartDate, transferEndDate, haveBankStatement })
    }

    const parseParams = (params = '') => {
        if(!params) { return {} }

        const rawParams = params.replace('?', '').split('&')
        const extractedParams = {}

        rawParams.forEach((item) => {
            item = item.split('=');
            extractedParams[item[0]] = item[1]
        })

        return extractedParams
    }

    const setQueryStringUrl = ({ transferStartDate, transferEndDate, haveBankStatement } = {}) => {
        const newRouterQuery = {
            transfer_start_date: _.toString(transferStartDate),
            transfer_end_date: _.toString(transferEndDate),
            have_bank_statement: haveBankStatement
        }

        const newParams = _.pickBy(newRouterQuery, (value) => value)
        const queryString = Object.keys(newParams).map(key => key + '=' + newParams[key]).join('&')

        history.push({ search: `?${queryString}`, params: newParams })
    }

    const transferStartDate = new URLSearchParams(location.search).get('transfer_start_date')
    const transferEndDate = new URLSearchParams(location.search).get('transfer_end_date')
    const defaultTransferDate = transferStartDate ? ([moment(transferStartDate), moment(transferEndDate)]) : null

    return (
        <PaymentVouchersIndex
            defaultTransferDate={defaultTransferDate}
            defaultHaveBankStatement={new URLSearchParams(location.search).get('have_bank_statement') || undefined}
            filters={filters}
            loading={loading}
            onPageChange={handlePageChange}
            onSavePVBankStatement={handleSavePVBankStatement}
            onSendingStateChange={handleSendingStateChange}
            onTransferDateChange={handleTransferDateChange}
            pagination={{
                ...pagination.toJS(),
                showSizeChanger: false
            }}
            paymentVouchers={getPaymentVouchers().toJS()}
        />
    )
}

PaymentVouchersIndexContainer.propTypes = {}

PaymentVouchersIndexContainer.defaultProps = {}

export default withRouter(PaymentVouchersIndexContainer)
