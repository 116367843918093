import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Button, Col, Row, Select, Spin, Form } from 'antd'
import { CarOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'

class TruckSelect extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        selectedTruckId: PropTypes.string,
        trucks: PropTypes.array.isRequired
    }

    renderTruckOptions () {
        const { trucks } = this.props

        return trucks.map((truck, index) => {
            let vehicleTypeName = _.get(truck, 'vehicle_type.name', null)
            vehicleTypeName = !vehicleTypeName ? '' : `[${vehicleTypeName}]`

            return (
                <Select.Option key={_.get(truck, 'id', `truck-index-${index}`)}>
                    {`${_.get(truck, 'license_plate_number', '')} ${vehicleTypeName}`}
                </Select.Option>
            )
        })
    }

    render () {
        const { i18n, loading, onChange, onSearch, selectedTruckId, filedName } = this.props

        return (
            <div className="truck-select-component" key="truck-select-component">
                <Row gutter={[8, 4]} key="select">
                    <Col span={20}>
                        <Form.Item nostyle name={filedName}>
                            <Select allowClear
                                filterOption={false}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                                onChange={onChange}
                                onSearch={onSearch}
                                placeholder={i18n.t('trucks:selectTruck')}
                                showSearch
                                style={{ width: '100%' }}
                                value={selectedTruckId}>
                                {this.renderTruckOptions()}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Link to="/trucks">
                            <Button icon={<CarOutlined />} style={{ width: '100%' }} />
                        </Link>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withTranslation('trucks')(TruckSelect)
