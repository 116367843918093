import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

import { Button, Col, Descriptions, Modal, PageHeader, Row, Spin, Statistic } from 'antd'
import SupplierOverTransferItemList from './SupplierOverTransferItemList'
import SupplierOverTransferItemFormContainer from '../../containers/Suppliers/SupplierOverTransferItemFormContainer'
import SupplierInfoFormContainer from '../../containers/Suppliers/SupplierInfoFormContainer'
import { PlusOutlined } from '@ant-design/icons'
const SupplierOverTransferAccountList = ({
    i18n,
    dataSourceFrom,
    formData,
    loading,
    onDeleteClick,
    onPageChange,
    orderId,
    pagination,
    supplierId,
    supplierOverTransferAccounts,
    toggleVisibleForm,
    visibleForm,
    toggleInfoForm,
    visibleInfoForm
}) => {
    const renderOrderHeader = () => {
        if (dataSourceFrom === 'suppliers') {
            const supplierOverTransferAccount = _.get(supplierOverTransferAccounts, '0')
            const supplier = _.get(supplierOverTransferAccount, 'supplier')

            return (
                <PageHeader
                    style={{ padding: '0' }}
                    onBack={() => window.history.back()}
                    title={_.get(supplier, 'name', '-')}
                    extra={[
                        <Button key="2" onClick={toggleInfoForm}>{i18n.t('shared:edit')}</Button>,
                        <Button type="primary" icon={<PlusOutlined />}
                            onClick={() => toggleVisibleForm({ supplierId: _.get(supplier, 'id') })}>
                            {i18n.t('suppliers/overTransferItems:add')}
                        </Button>,
                    ]}
                >
                    <Row>
                        <Col span={20}>
                            <Descriptions size="small" column={1}>
                                <Descriptions.Item label={i18n.t('suppliers:keyContact')}>
                                    {_.get(supplier, 'key_contact', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={i18n.t('suppliers:salesContact')}>
                                    {_.get(supplier, 'sales_contact', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={i18n.t('suppliers:accountContact')}>
                                    {_.get(supplier, 'account_contact', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={i18n.t('suppliers:startRating')}>
                                    {_.get(supplier, 'start_rating', '-')}
                                </Descriptions.Item>
                                <Descriptions.Item label={i18n.t('suppliers:other')}>
                                    {_.get(supplier, 'other', '-')}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={4}>
                            <Statistic
                                title={i18n.t('suppliers/overTransferAccounts:balance')}
                                value={_.get(supplierOverTransferAccount, 'balance.display', '0.00')}
                            />
                        </Col>
                    </Row>
                </PageHeader>
            )
        }


        return (
            <Row style={{ paddingTop: '8px' }}>
                <Col span={20}>
                    <h2>{i18n.t('suppliers/overTransferItems:overTransferList')}</h2>
                </Col>
                <Col span={4} style={{ textAlign: 'end' }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleVisibleForm({ orderId })}>
                        {i18n.t('suppliers/overTransferItems:add')}
                    </Button>
                </Col>
            </Row>
        )
    }

    const renderEachTable = () => {
        return _.keys(supplierOverTransferAccounts).map((index) => {
            return (
                <div key={`fragment-${index}`}>
                    <SupplierOverTransferItemList
                        key={index}
                        keyIndex={index}
                        dataSourceFrom={dataSourceFrom}
                        onDeleteClick={onDeleteClick}
                        onPageChange={onPageChange}
                        pagination={pagination}
                        supplierOverTransferAccount={supplierOverTransferAccounts[index]}
                        toggleVisibleForm={toggleVisibleForm}
                    />
                </div>
            )
        })
    }

    return (
        <div key="supplier-over-transfer-accounts-list-container">
            {renderOrderHeader()}

            <Spin spinning={loading}>
                {renderEachTable()}
            </Spin>

            <Modal
                destroyOnClose
                footer={null}
                onCancel={toggleVisibleForm}
                visible={visibleForm}
                width="50%">
                <SupplierOverTransferItemFormContainer
                    formData={formData}
                    onSaveSuccess={toggleVisibleForm} />
            </Modal>

            <Modal
                destroyOnClose
                footer={null}
                onCancel={toggleInfoForm}
                visible={visibleInfoForm}
                width="50%">
                <SupplierInfoFormContainer
                    onSuccess={toggleInfoForm}
                    supplierId={supplierId}
                />
            </Modal>
        </div>
    )
}

SupplierOverTransferAccountList.propTypes = {
    dataSourceFrom: PropTypes.string,
    formData: PropTypes.object,
    loading: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    onPageChange: PropTypes.func,
    pagination: PropTypes.object,
    supplierId: PropTypes.string,
    supplierOverTransferAccounts: PropTypes.array,
    toggleVisibleForm: PropTypes.func,
    visibleForm: PropTypes.bool,
    toggleInfoForm: PropTypes.func,
    visibleInfoForm: PropTypes.bool
}

SupplierOverTransferAccountList.defaultProps = {
    dataSourceFrom: '',
    formData: {},
    loading: false,
    onDeleteClick: () => {},
    onPageChange: () => {},
    pagination: {},
    supplierOverTransferAccounts: [],
    toggleVisibleForm: () => {},
    visibleForm: false
}

export default withTranslation(['suppliers/overTransferItems', 'suppliers'])(SupplierOverTransferAccountList)
