import styled from 'styled-components'
const PaymentVoucherWrapper = styled.div`
  .detail-line {
    display: flex;

    .detail-label {
      display: contents;

      &:after {
        content: ':';
        margin-right: 4px;
      }
    }
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }
`

export default PaymentVoucherWrapper
