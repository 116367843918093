import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import SelectInvoiceShippingCosts from '../../components/Invoices/SelectInvoiceShippingCosts'
import orderActions from '../../redux/orders/actions'

const { fetchOrderShippingCost } = orderActions

class SelectInvoiceShippingCostsContainer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        orderId: PropTypes.string.isRequired,
        value: PropTypes.array,

        fetchOrderShippingCost: PropTypes.func.isRequired,
        orders: ImmutablePropTypes.map.isRequired,
        shippingCostEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: []
    }

    getDefaultShippingCosts () {
        const { value } = this.props

        return value.map((invoiceShippingCost) => {
            return {
                id: _.toString(_.get(invoiceShippingCost, 'item_id', '')),
                item_type: 'ShippingCost',
                name: _.get(invoiceShippingCost, 'name', ''),
                quantity: _.get(invoiceShippingCost, 'quantity', 0),
                unit: _.get(invoiceShippingCost, 'unit', ''),
                single_price: _.get(invoiceShippingCost, 'money_display.single_price', '0'),
                empty_line_below: _.get(invoiceShippingCost, 'empty_line_below', '')
            }
        })
    }

    getShippingCosts () {
        const { orders, shippingCostEntities } = this.props

        return orders.get('shippingCostItems', new List()).map((shippingCostId) => {
            const shippingCost = shippingCostEntities.get(_.toString(shippingCostId), new Map()).toJS()

            return {
                id: _.toString(_.get(shippingCost, 'id', '')),
                name: _.get(shippingCost, 'vehicle_type.name', ''),
                quantity: _.get(shippingCost, 'quantity', 0),
                single_price: _.get(shippingCost, 'money_display.single_price', '0'),
                empty_line_below: _.get(shippingCost, 'empty_line_below', '')
            }
        })
    }

    handleFetchInitialData = ({ onSuccess } = {}) => {
        const { fetchOrderShippingCost, orderId } = this.props

        fetchOrderShippingCost(orderId, { onSuccess })
    }

    onSelectShippingCostsChange = (selectedShippingCosts) => {
        const { onChange, value } = this.props

        const selectedInvoiceShippingCosts = selectedShippingCosts.map((shippingCost) => {
            const invoiceShippingCost = _.find(value, (invoiceShippingCost) => {
                return _.toString(_.get(invoiceShippingCost, 'item_id', '')) === shippingCost.id
            })
            const price = _.get(shippingCost, 'single_price', '0')

            return {
                id: _.get(invoiceShippingCost, 'id', ''),
                item_id: _.get(shippingCost, 'id', ''),
                item_type: 'ShippingCost',
                name: _.get(shippingCost, 'name', ''),
                quantity: _.get(shippingCost, 'quantity', 0),
                unit: _.get(shippingCost, 'unit', ''),
                empty_line_below: _.get(shippingCost, 'empty_line_below', ''),
                single_price: price ? price : '0',
                _destroy: _.get(shippingCost, '_destroy', false)
            }
        })

        onChange(selectedInvoiceShippingCosts)
    }

    render () {
        const { orders, orderId } = this.props

        return (
            <SelectInvoiceShippingCosts
                defaultShippingCosts={this.getDefaultShippingCosts()}
                initialData={this.getShippingCosts().toJS()}
                loading={orders.get('shippingLoading', false)}
                onFetchInitialData={this.handleFetchInitialData}
                onSelectShippingCostsChange={this.onSelectShippingCostsChange}
                orderId={orderId}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.get('orders'),
        shippingCostEntities: state.getIn(['Entities', 'shippingCosts'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderShippingCost
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInvoiceShippingCostsContainer)
