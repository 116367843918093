import styled from 'styled-components'
const SupplierOrderReviewWrapper = styled.div`

  .attach-file:not(:last-child) {
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 4px;
  }

  .truck-dispatch:not(:last-child) {
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 4px;
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .chat-button {
    background: #00A800;

    i {
      color: #FFF;
    }
  }

  .icon-button {
    width: 40px;
    margin-right: 8px;
  }
`
export default SupplierOrderReviewWrapper