import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Map, List } from 'immutable'

import { Row, Col, Divider, Table, Tabs, Button, Spin, Modal } from 'antd'
import { MessageOutlined, MailOutlined } from '@ant-design/icons'

import companyActions from '../../redux/customers/companies/actions'
import orderDocumentAction from '../../redux/orders/documents/actions'
import { isMobile } from 'react-device-detect'

const {
    fetchCompany,
    fetchCompanyOrders
} = companyActions

const { fetchTaxInvoiceDocumentRequest } = orderDocumentAction

class CompanyInfoContainer extends Component {
    componentDidMount () {
        const { fetchCompany, fetchCompanyOrders, companyId } = this.props
        fetchCompany(companyId)
        fetchCompanyOrders(companyId)
    }

    getPhoneNumbers = (customer) => {
        const { entities } = this.props

        const phoneNumbers = customer.get('customer_phone_numbers') || new List()

        return phoneNumbers.map((phoneNumberId, index) => {
            const phoneNumber = entities.getIn(['customerPhoneNumbers', phoneNumberId])
            return (
                <div key={index}>
                    <div>{phoneNumber.get('body')}</div>
                </div>
            )
        })
    }

    getEmails = (customer) => {
        const { entities } = this.props

        const emails = customer.get('customer_emails') || new List()

        return emails.map((emailId, index) => {
            const email = entities.getIn(['customerEmails', emailId])
            return (
                <div key={index}>
                    <div>{email.get('body')}</div>
                </div>
            )
        })
    }

    getLines = (customer) => {
        const { entities } = this.props

        const lines = customer.get('customer_lines') || new List()

        return lines.map((linesId, index) => {
            const line = entities.getIn(['customerLines', linesId])
            return (
                <div key={index}>
                    <div>{line.get('body')}</div>
                </div>
            )
        })
    }

    getCustomers = (customers) => {
        const { customersEntity } = this.props

        if (!customers) { return null }

        return customers.map((customerId) => {
            const customer = customersEntity.get(customerId.toString())
            return customer || new Map()
        })
    }

    getOrders = () => {
        const { companies, entities } = this.props
        const orders = companies.get('orderItems')

        return orders.map((orderId) => {
            const order = entities.getIn(['orders', orderId.toString()])
            return order || new Map()
        })
    }

    fetchInvoiceDocument = (orderId, invoiceId) => {
        const { fetchTaxInvoiceDocumentRequest } = this.props
        fetchTaxInvoiceDocumentRequest(orderId, invoiceId)
    }

    handleOrdersTableChange = (pagination) => {
        const { fetchCompanyOrders, companyId } = this.props

        fetchCompanyOrders(companyId, pagination.current)
    }

    renderTaxInvoiceLoading = () => {
        const taxInvoiceLoading = this.props.orderDocuments.get('taxInvoiceDocumentLoading')
        if (taxInvoiceLoading) {
            return (
                <Modal
                    destroyOnClose
                    footer={false}
                    visible={taxInvoiceLoading}
                    width={'10%'}
                    closable={false}
                    centered
                >
                    <div style={{ textAlign: 'center' }}><Spin /></div>
                </Modal>
            )
        }
    }

    render () {
        const { companiesEntity, companyId, companies, i18n } = this.props
        const getCompany = companiesEntity.get(companyId) || new Map()
        const company = getCompany.toJS()

        const customerColumns = [
            {
                title: i18n.t('companies:customerName'),
                render: (customer) => {
                    return (
                        <div>{customer.get('first_name')} {customer.get('last_name')}</div>
                    )
                }
            },
            {
                title: i18n.t('companies:phoneNumber'),
                render: (customer) => {
                    return (
                        <div>{this.getPhoneNumbers(customer)}</div>
                    )
                }
            },
            {
                title: i18n.t('companies:email'),
                render: (customer) => {
                    return (
                        <div>{this.getEmails(customer)}</div>
                    )
                }
            },
            {
                title: i18n.t('companies:line'),
                render: (customer) => {
                    return (
                        <div>{this.getLines(customer)}</div>
                    )
                }
            }
        ]

        const orderColumns = [
            {
                key: 'number',
                title: i18n.t('orders/tables:orderNumber'),
                dataIndex: 'id',
                render: (id) => {
                    return (
                        <Link to={`/inquiries/${id}`}>
                            {id}
                        </Link>
                    )
                }
            },
            {
                title: i18n.t('orders/tables:grandTotal'),
                dataIndex: ['money_display', 'grand_total']
            },
            {
                key: 'detail',
                title: i18n.t('orders/tables:detail'),
                dataIndex: 'detail'
            },
            {
                key: 'invoice',
                title: i18n.t('companies:taxInvoice'),
                render: (order) => {
                    const invoices = order.invoice_histories
                    if (!invoices.length) return null
                    return invoices.map((invoice, index) => {
                        const invoiceId = invoice.id
                        return (
                            <div style={{ marginBottom: 5 }} key={invoiceId}>
                                <Button size="small"
                                    onClick={() => this.fetchInvoiceDocument(order.id, invoiceId)}
                                >
                                    {`${i18n.t('companies:taxInvoice')} #${index + 1 }`}
                                </Button>
                            </div>
                        )
                    })
                }

            },
            {
                key: 'updateAt',
                title: i18n.t('orders/tables:updatedAt'),
                dataIndex: ['localized_time', 'updated_at']
            },
            {
                key: 'state',
                title: i18n.t('orders/tables:state'),
                dataIndex: 'state_label'
            }
        ]

        return (
            <div>
                <h2>{company.name}</h2>
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={5}>
                                <div>{i18n.t('companies:addressDetail')} : </div>
                            </Col>
                            <Col>
                                <div>
                                    {`${company.address_detail || ''} `}
                                    {`${company.district ? company.district.name : ''} `}
                                    {`${company.province ? company.province.name : ''} `}
                                    {company.postal_code || ''}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <div>{i18n.t('companies:phoneNumber')} : </div>
                            </Col>
                            <Col>
                                <div>{company.phone_number}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <div><MailOutlined /> {i18n.t('companies:email')} :</div>
                            </Col>
                            <Col>
                                <div>{company.email || '-'}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={5}>
                                <div><MessageOutlined /> {i18n.t('companies:line')} : </div>
                            </Col>
                            <Col>
                                <div>{company.line_id || '-'}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />
                <Tabs defaultActiveKey="order">
                    <Tabs.TabPane tab={i18n.t('companies:orders')} key="orders">
                        <Table columns={orderColumns}
                            rowKey={order => order.id}
                            loading={companies.get('ordersloading')}
                            dataSource={this.getOrders().toJS()}
                            onChange={this.handleOrdersTableChange}
                            scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                            pagination={{
                                ...companies.get('ordersPagination').toJS(),
                                showSizeChanger: false
                            }}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t('companies:customerInfo')} key="customers">
                        <Table columns={customerColumns}
                            rowKey={customers => customers.id}
                            scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                            dataSource={this.getCustomers(company.customers)}
                            pagination={false}/>
                    </Tabs.TabPane>
                </Tabs>
                {this.renderTaxInvoiceLoading()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    companiesEntity: state.getIn(['Entities', 'customerCompanies']),
    customersEntity: state.getIn(['Entities', 'customers']),
    entities: state.get('Entities'),
    companies: state.get('customerCompanies'),
    orderDocuments: state.get('orderDocuments')
})

const mapDispatchToprops = (dispatch) => {
    return bindActionCreators({
        fetchCompany,
        fetchCompanyOrders,
        fetchTaxInvoiceDocumentRequest
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToprops)(
    withTranslation(['companies', 'orders/tables'])(CompanyInfoContainer)
)
