import { PAGE_SIZE } from "../../constants/orders"

const actions = {
    ORDERS_SUPPLIER_ORDERS_FETCH_REQUEST: 'ORDERS_SUPPLIER_ORDERS_FETCH_REQUEST',
    ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS: 'ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS',
    ORDERS_SUPPLIER_ORDERS_FETCHING: 'ORDERS_SUPPLIER_ORDERS_FETCHING',
    PICKUP_DETAIL_SAVE_REQUEST: 'PICKUP_DETAIL_SAVE_REQUEST',
    PICKUP_DETAIL_SAVE_SUCCESS: 'PICKUP_DETAIL_SAVE_SUCCESS',
    PICKUP_DETAIL_SAVE: 'PICKUP_DETAIL_SAVE',
    PICKUP_DETAIL_SET_FORM_ERRORS: 'PICKUP_DETAIL_SET_FORM_ERRORS',
    SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL: 'SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL',
    SUPPLIER_ORDER_CANCEL_REQUEST: 'SUPPLIER_ORDER_CANCEL_REQUEST',
    SUPPLIER_ORDER_CANCELED: 'SUPPLIER_ORDER_CANCELED',
    SUPPLIER_ORDER_FETCH_REQUEST: 'SUPPLIER_ORDER_FETCH_REQUEST',
    SUPPLIER_ORDER_FETCH_SUCCESS: 'SUPPLIER_ORDER_FETCH_SUCCESS',
    SUPPLIER_ORDER_FETCH: 'SUPPLIER_ORDER_FETCH',
    SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_REQUEST: 'SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_REQUEST',
    SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING: 'SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING',
    SUPPLIER_ORDER_STATE_CHANGE_FAIL: 'SUPPLIER_ORDER_STATE_CHANGE_FAIL',
    SUPPLIER_ORDER_STATE_CHANGE_REQUEST: 'SUPPLIER_ORDER_STATE_CHANGE_REQUEST',
    SUPPLIER_ORDER_STATE_CHANGE_SUCCESS: 'SUPPLIER_ORDER_STATE_CHANGE_SUCCESS',
    SUPPLIER_ORDER_STATE_CHANGING: 'SUPPLIER_ORDER_STATE_CHANGING',
    SUPPLIER_ORDERS_FETCH_REQUEST: 'SUPPLIER_ORDERS_FETCH_REQUEST',
    SUPPLIER_ORDERS_FETCH_SUCCESS: 'SUPPLIER_ORDERS_FETCH_SUCCESS',
    SUPPLIER_ORDERS_FETCH: 'SUPPLIER_ORDERS_FETCH',
    SUPPLIER_ORDERS_FILTER_BY_CURRENT_STAFF: 'SUPPLIER_ORDERS_FILTER_BY_CURRENT_STAFF',
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH_REQUEST: 'SUPPLIER_ORDERS_LINE_ITEMS_FETCH_REQUEST',
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS: 'SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS',
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH: 'SUPPLIER_ORDERS_LINE_ITEMS_FETCH',
    SUPPLIER_ORDERS_PDF_FETCH_REQUEST: 'SUPPLIER_ORDERS_PDF_FETCH_REQUEST',
    SUPPLIER_ORDERS_PDF_FETCH_SUCCESS: 'SUPPLIER_ORDERS_PDF_FETCH_SUCCESS',
    SUPPLIER_ORDERS_PDF_FETCH: 'SUPPLIER_ORDERS_PDF_FETCH',
    SUPPLIER_ORDERS_SAVE_REQUEST: 'SUPPLIER_ORDERS_SAVE_REQUEST',
    SUPPLIER_ORDERS_SAVE_SUCCESS: 'SUPPLIER_ORDERS_SAVE_SUCCESS',
    SUPPLIER_ORDERS_SAVE: 'SUPPLIER_ORDERS_SAVE',
    SUPPLIER_ORDERS_SET_FILTER_DATE: 'SUPPLIER_ORDERS_SET_FILTER_DATE',
    SUPPLIER_ORDERS_SET_PAGINATION: 'SUPPLIER_ORDERS_SET_PAGINATION',
    SUPPLIER_ORDERS_SET_SORTER: 'SUPPLIER_ORDERS_SET_SORTER',
    SUPPLIER_ORDERS_SET_SUB_OFFER_STATE: 'SUPPLIER_ORDERS_SET_SUB_OFFER_STATE',
    SUPPLIER_ORDERS_SHOW_FORM: 'SUPPLIER_ORDERS_SHOW_FORM',
    SUPPLIER_ORDERS_STATES_FETCH_REQUEST: 'SUPPLIER_ORDERS_STATES_FETCH_REQUEST',
    SUPPLIER_ORDERS_STATES_FETCH_SUCCESS: 'SUPPLIER_ORDERS_STATES_FETCH_SUCCESS',
    SUPPLIER_ORDERS_STATES_FETCH: 'SUPPLIER_ORDERS_STATES_FETCH',
    SUPPLIER_SHIPPING_COST_FETCH_FILE_REQUEST: 'SUPPLIER_SHIPPING_COST_FETCH_FILE_REQUEST',
    SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS: 'SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS',
    SUPPLIER_SHIPPING_COST_FETCH_FILE: 'SUPPLIER_SHIPPING_COST_FETCH_FILE',
    SUPPLIER_SHIPPING_COST_IMPORT_FILE_REQUEST: 'SUPPLIER_SHIPPING_COST_IMPORT_FILE_REQUEST',
    SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING: 'SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING',
    SUPPLIER_SHIPPING_COST_SET_FORM_ERRORS: 'SUPPLIER_SHIPPING_COST_SET_FORM_ERRORS',
    SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE_SUCCESS: 'SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE_SUCCESS',
    SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE: 'SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE',
    SUPPLIER_SHIPPING_DRIVER_INFO_FILE_REQUEST: 'SUPPLIER_SHIPPING_DRIVER_INFO_FILE_REQUEST',
    SUPPLIERS_ORDER_PAYMENT_EXPORT_REQUEST: 'SUPPLIERS_ORDER_PAYMENT_EXPORT_REQUEST',
    SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING: 'SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING',
    SUPPLIER_ORDER_QUERY_FETCH_REQUEST: 'SUPPLIER_ORDER_QUERY_FETCH_REQUEST',
    SUPPLIER_ORDER_QUERY_LOADING: 'SUPPLIER_ORDER_QUERY_LOADING',
    SUPPLIER_ORDER_QUERY_FETCH_SUCCESS: 'SUPPLIER_ORDER_QUERY_FETCH_SUCCESS',
    EXPORT_SUPPLIER_ORDERS_REQUEST: 'EXPORT_SUPPLIER_ORDERS_REQUEST',
    IMPORT_SUPPLIER_ORDER_REQUEST: 'IMPORT_SUPPLIER_ORDER_REQUEST',
    IMPORT_SUPPLIER_ORDER_SUCCESS: 'IMPORT_SUPPLIER_ORDER_SUCCESS',
    IMPORT_SUPPLIER_ORDER_MODAL: 'IMPORT_SUPPLIER_ORDER_MODAL',
    IMPORT_SUPPLIER_ORDER_UPLOADING: 'IMPORT_SUPPLIER_ORDER_UPLOADING',
    DOWNLOAD_SHIPPING_INVOICE_REQUEST: 'DOWNLOAD_SHIPPING_INVOICE_REQUEST',
    DOWNLOAD_SHIPPING_INVOICE_SET_LOADING: 'DOWNLOAD_SHIPPING_INVOICE_SET_LOADING',
    SET_SUPPLIER_OVER_TRANSFER_FORM_MODAL: 'SET_SUPPLIER_OVER_TRANSFER_FORM_MODAL',
    EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_REQUEST: 'EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_REQUEST',
    EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING: 'EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING',
    PV_TRANSFER_BACKS_FETCH_REQUEST: 'PV_TRANSFER_BACKS_FETCH_REQUEST',
    PV_TRANSFER_BACKS_FETCH_SUCCESS: 'PV_TRANSFER_BACKS_FETCH_SUCCESS',
    PV_TRANSFER_BACKS_FETCH: 'PV_TRANSFER_BACKS_FETCH',
    PV_TRANSFER_BACKS_SET_PAGINATION: 'PV_TRANSFER_BACKS_SET_PAGINATION',
    PV_TRANSFER_BACKS_SET_FILTERS: 'PV_TRANSFER_BACKS_SET_FILTERS',
    SET_PAYMENT_VOUCHER_EDITING: 'SET_PAYMENT_VOUCHER_EDITING',
    SET_SUPPILER_ORDER_FORM_SELECTED: 'SET_SUPPILER_ORDER_FORM_SELECTED',

    changeStateTo: ({ state = '', id = '', onSuccess = () => {}, onError = () => {} } = {}) => {
        if (!state || !id) return null

        return {
            type: actions.SUPPLIER_ORDER_STATE_CHANGE_REQUEST,
            payload: { state, id, onSuccess, onError }
        }
    },

    fetchSupplierOrders: ({
        queryString = '',
        withoutTransferBack = false,
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_ORDERS_FETCH_REQUEST,
            payload: { queryString, withoutTransferBack, onSuccess, onError }
        }
    },

    fetchSupplierOrderPDF: (id) => ({
        type: actions.SUPPLIER_ORDERS_PDF_FETCH_REQUEST,
        payload: { id }
    }),

    setFilterDate: (fieldname) => {
        return { type: actions.SUPPLIER_ORDERS_SET_FILTER_DATE, fieldname }
    },

    setPagination: (pagination) => {
        return { type: actions.SUPPLIER_ORDERS_SET_PAGINATION, pagination }
    },

    setSorter: (sorter) => {
        return { type: actions.SUPPLIER_ORDERS_SET_SORTER, sorter }
    },

    fetchSupplierStates: () => ({
        type: actions.SUPPLIER_ORDERS_STATES_FETCH_REQUEST
    }),

    saveSupplierOrder: (values, orderNumber, id, queryString = '') => ({
        type: actions.SUPPLIER_ORDERS_SAVE_REQUEST,
        payload: { values, orderNumber, id, queryString }
    }),


    saveSupplierShippingCost: (queryString, supplierOrderId, value, id) => ({
        type: actions.SUPPLIER_SHIPPING_COST_SAVE_REQUEST,
        payload: { queryString, supplierOrderId, value, id }
    }),

    importShippingCostFile: (file) => ({
        type: actions.SUPPLIER_SHIPPING_COST_IMPORT_FILE_REQUEST,
        payload: { file }
    }),

    fetchSupplierOrderShippingCost: (value) => ({
        type: actions.SUPPLIER_SHIPPING_COST_FETCH_FILE_REQUEST,
        payload: { value }
    }),

    exportSupplierOrderPaymentPDF: (date) => ({
        type: actions.SUPPLIERS_ORDER_PAYMENT_EXPORT_REQUEST,
        date
    }),

    cancelSupplierOrder: (supplierOrderId, queryString) => ({
        type: actions.SUPPLIER_ORDER_CANCEL_REQUEST,
        payload: { supplierOrderId, queryString }
    }),
    exportSupplierOrderPaymentVouchers: (paymentVoucherIds, newFormat = false) => ({
        type: actions.SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_REQUEST,
        payload: { paymentVoucherIds, newFormat }
    }),
    fetchOrdersSupplierOrders: (query = '', page = '1') => ({
        type: actions.ORDERS_SUPPLIER_ORDERS_FETCH_REQUEST,
        payload: { query, page }
    }),
    fetchSupplierOrder: (refCode) => ({
        type: actions.SUPPLIER_ORDER_FETCH_REQUEST,
        payload: { refCode }
    }),
    setFilterByCurrentStaff: (filterCurrentStaff) => ({
        type: actions.SUPPLIER_ORDERS_FILTER_BY_CURRENT_STAFF,
        filterCurrentStaff
    }),
    setSubOfferState: (subOfferState) => ({
        type: actions.SUPPLIER_ORDERS_SET_SUB_OFFER_STATE,
        subOfferState
    }),
    fetchSupplierOrderLineItems: (refCode) => ({
        type: actions.SUPPLIER_ORDERS_LINE_ITEMS_FETCH_REQUEST,
        payload: { refCode }
    }),

    fetchDriverShippingInfos: (value) => ({
        type: actions.SUPPLIER_SHIPPING_DRIVER_INFO_FILE_REQUEST,
        payload: { value }
    }),
    setOrderSupplierNoteFormModal: (value) => ({
        type: actions.SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL,
        value
    }),
    showSupplierOrderForm: (value) => ({
        type: actions.SUPPLIER_ORDERS_SHOW_FORM,
        value
    }),

    fetchSupplierOrderThroughQuery: (query) => ({
        type: actions.SUPPLIER_ORDER_QUERY_FETCH_REQUEST,
        query
    }),

    exportSupplierOrders: (queryString, newFormat = false) => ({
        type: actions.EXPORT_SUPPLIER_ORDERS_REQUEST,
        payload: { queryString, newFormat }
    }),

    setSupplierOrderImportModal: (value) => ({
        type: actions.IMPORT_SUPPLIER_ORDER_MODAL,
        value
    }),

    importSupplierOrders: (file) => ({
        type: actions.IMPORT_SUPPLIER_ORDER_REQUEST,
        payload: { file }
    }),

    downloadShippingInvoicePDF: (supplierOrderId) => ({
        type: actions.DOWNLOAD_SHIPPING_INVOICE_REQUEST,
        payload: { supplierOrderId }
    }),

    setSupplierOverTransferFormModal: (visible) => ({
        type: actions.SET_SUPPLIER_OVER_TRANSFER_FORM_MODAL,
        visible
    }),

    exportPvTotalAmountMarginType: ({ newFormat = false } = {}) => ({
        type: actions.EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_REQUEST,
        payload: { newFormat }
    }),

    fetchPVTransferBacks: ({ page = 1, per = PAGE_SIZE, filters = {} } = {}) => ({
        type: actions.PV_TRANSFER_BACKS_FETCH_REQUEST,
        payload: { page, per, filters }
    }),

    setPVTransferBackFilters: (filters) => ({
        type: actions.PV_TRANSFER_BACKS_SET_FILTERS,
        filters
    }),

    setPvEditing: (id) => ({
        type: actions.SET_PAYMENT_VOUCHER_EDITING,
        id
    }),

    setSupplierOrderSelected: ({ id, orderId, supplierOrderType } = {}) => ({
        type: actions.SET_SUPPILER_ORDER_FORM_SELECTED,
        id, orderId, supplierOrderType
    }),
}

export default actions
