import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button, Modal, Table, Input } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CompanyProjectFormContainer from '../../containers/Projects/CompanyProjectFormContainer'
import ProjectWrapper from './project.style'

const ProjectsIndex = ({
    i18n,
    activeProject,
    companyProjects,
    loading,
    onPageChange,
    onSearch,
    pagination,
    showCompanyProjectForm,
    toggleCompanyProjectForm
}) => {
    const columns = [
        {
            title: i18n.t('projects:project'),
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={`/projects/${record.id}`}>
                    {name}
                </Link>
            )
        },
        {
            title: i18n.t('projects:company'),
            dataIndex: ['customer_company', 'name']
        },
        {
            title: i18n.t('projects:categories'),
            dataIndex: 'name_categories',
            render: (names) => (
                <div>{names.join(', ')}</div>
            )
        },
        {
            title: i18n.t('projects:conversion'),
            render: (row) => {
                const completedOrdersCount = row.completed_orders_count || 0
                const ordersCount = row.orders_count || 0
                return (
                    <div>
                        {`${row.conversion_percentage || 0}%`} | { completedOrdersCount } | { ordersCount }
                    </div>
                )
            }
        },
        {
            title: i18n.t('projects:province'),
            dataIndex: ['province', 'name']
        },
        {
            title: i18n.t('projects:district'),
            dataIndex: ['district', 'name']
        },
        {
            render: (record) => {
                return (
                    <div>
                        <a onClick={() => toggleCompanyProjectForm({ id: record.id })}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            }
        }
    ]

    return (
        <ProjectWrapper>
            <div className="projects-index-header">
                <Input.Search style={{ marginBottom: 12 }} placeholder={i18n.t('projects:searchProject')} onSearch={(value) => {onSearch(value)}} enterButton />
                <Button type="primary" onClick={toggleCompanyProjectForm}>{i18n.t('projects:addProject')}</Button>
            </div>
            <Table
                columns={columns}
                dataSource={companyProjects}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />

            <Modal
                visible={showCompanyProjectForm}
                onCancel={toggleCompanyProjectForm}
                footer={null}
                destroyOnClose
            >
                <CompanyProjectFormContainer
                    companyProject={_.find(companyProjects, ['id', activeProject])}
                    id={activeProject}
                    toggleForm={toggleCompanyProjectForm}
                />
            </Modal>
        </ProjectWrapper>
    )
}

ProjectsIndex.propTypes = {
    activeProject: PropTypes.string,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    pagination: PropTypes.object,
    companyProjects: PropTypes.array,
    showCompanyProjectForm: PropTypes.bool,
    toggleCompanyProjectForm: PropTypes.func.isRequired
}

ProjectsIndex.defaultProps = {
    loading: false,
    companyProjects: [],
    showCompanyProjectForm: false
}

export default withTranslation('projects')(ProjectsIndex)
