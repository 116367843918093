import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Button, Col, Input, Row } from 'antd'
import { EditTwoTone } from '@ant-design/icons'

const ScmNoteForm = ({
    formVisible,
    i18n,
    onChangeScmNote,
    onSubmitScmNote,
    onToggleFormVisible,
    scmNote,
    orderState
}) => {
    const renderForm = () => {
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col sm={24}>
                        <label className="title">
                            {i18n.t('inquiries:scmNoteForOrderTracking')}
                        </label>
                    </Col>

                    <Col sm={24}>
                        <Input
                            defaultValue={scmNote}
                            onChange={onChangeScmNote}
                        />
                    </Col>
                </Row>

                <Row gutter={[16, 16]} type="flex" justify="center">
                    <Col sm={8} style={{ textAlign: 'end' }}>
                        <Button type="primary" size="small" onClick={onSubmitScmNote}>
                            {i18n.t('inquiries:edit')}
                        </Button>
                    </Col>

                    <Col sm={8} style={{ textAlign: 'start' }}>
                        <Button size="small" onClick={onToggleFormVisible}>
                            {i18n.t('inquiries:cancel')}
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    const renderScmNoteText = () => {
        return (
            <>
                <span className="title">
                    {i18n.t('inquiries:scmNoteForOrderTracking')} : &nbsp;
                    {!scmNote ? '-' : null}
                    { ((orderState === 'payment') || (orderState === 'complete')) ?
                        <EditTwoTone className="edit-icon" onClick={onToggleFormVisible} /> :
                        null}
                </span>
                <p>
                    {scmNote}
                </p>
            </>
        )
    }

    return formVisible ? renderForm() : renderScmNoteText()
}

ScmNoteForm.propTypes = {
    formVisible: PropTypes.bool,
    onChangeScmNote: PropTypes.func,
    onSubmitScmNote: PropTypes.func,
    onToggleFormVisible: PropTypes.func,
    scmNote: PropTypes.string,
    orderState: PropTypes.string
}

ScmNoteForm.defaultProps = {
    formVisible: false,
    onChangeScmNote: () => {},
    onSubmitScmNote: () => {},
    onToggleFormVisible: () => {},
    scmNote: '',
    orderState: ''

}

export default withTranslation('inquiries')(ScmNoteForm)
