import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import { companyProjectSchema, companyProjectsSchema } from '../schema'
import actions from './actions'

const {
    PROJECTS_FETCH_COMPANY_PROJECTS_REQUEST,
    PROJECTS_FETCH_COMPANY_PROJECTS,
    PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS,
    PROJECTS_SET_COMPANY_PROJECT_PAGINATION,
    PROJECTS_SAVE_COMPANY_PROJECT_REQUEST,
    PROJECTS_SAVE_COMPANY_PROJECT,
    PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS,
    PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS,
    PROJECTS_FETCH_COMPANY_PROJECT_REQUEST,
    PROJECTS_FETCH_COMPANY_PROJECT,
    PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS,
} = actions

export function *fetchCompanyProjects () {
    yield takeEvery(PROJECTS_FETCH_COMPANY_PROJECTS_REQUEST, function *({ payload }) {
        yield put({ type: PROJECTS_FETCH_COMPANY_PROJECTS })

        const { page, per, query } = payload
        const queryString = `page=${page}&per=${per}&query=${query}`

        const data = yield call(apiFetch, `/company_projects?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, companyProjectsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: PROJECTS_SET_COMPANY_PROJECT_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveCompanyProject () {
    yield takeLatest(PROJECTS_SAVE_COMPANY_PROJECT_REQUEST, function *({ payload }) {
        yield put({ type: PROJECTS_SAVE_COMPANY_PROJECT, saving: true })

        const { id, params, onSuccess, onError } = payload

        const basePath = `/company_projects`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ company_project: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, companyProjectSchema)

                yield put({
                    type: PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: PROJECTS_SAVE_COMPANY_PROJECT, saving: false })
        }
    })
}

export function *fetchCompanyProject () {
    yield takeEvery(PROJECTS_FETCH_COMPANY_PROJECT_REQUEST, function *({ payload }) {
        yield put({ type: PROJECTS_FETCH_COMPANY_PROJECT, loading: true })

        const { id } = payload
        const path = `/company_projects/${id}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, companyProjectSchema)

        yield put({
            type: PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCompanyProjects),
        fork(saveCompanyProject),
        fork(fetchCompanyProject)
    ])
}
