import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import actions from '../../redux/walletSummaries/actions'
import CustomerWalletsList from '../../components/walletSummaries/CustomerWalletsList'
const { searchCustomers } = actions

class CustomerWalletsListContainer extends Component {
    static propTypes = {
        searchCustomers: PropTypes.func.isRequired,
        showSearch: PropTypes.bool,

        customerEntities: ImmutablePropTypes.map.isRequired,
        walletSummaries: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        showSearch: false
    }

    componentDidMount () {
        this.props.searchCustomers()
    }

    getColumns () {
        const { i18n } = this.props
        return [
            {
                key: 'customer',
                title: i18n.t('walletSummaries:customers.title'),
                render: (record) => {
                    const customerNo = _.get(record, 'id', '')
                    return (
                        <div className="customer-info">
                            <div className="customer-detail">
                                <span>{i18n.t('walletSummaries:customers.ืnumber')}</span>
                                <Link to={`/customers/${customerNo}`}>
                                    {customerNo}
                                </Link>
                            </div>
                            <div className="customer-detail">
                                <span>{i18n.t('walletSummaries:customers.name')}</span>
                                {`${_.get(record, 'first_name', '')} ${_.get(record, 'last_name', '')}`}
                            </div>
                            <div className="customer-detail">
                                <span>{i18n.t('walletSummaries:customers.company')}</span>
                                {_.get(record, 'company_name', '')}
                            </div>
                        </div>
                    )
                }
            },
            {
                key: 'sumOfWallets',
                title: i18n.t('walletSummaries:sumOfWallets'),
                dataIndex: ['sum_of_wallets', 'display']
            }
        ]
    }

    getCustomers () {
        const { walletSummaries, customerEntities } = this.props

        return walletSummaries.getIn(['customer', 'items'], new List()).map((id) => {
            return customerEntities.get(id) || new Map()
        })
    }

    handleSearch = (query) => {
        this.props.searchCustomers({ page: 1, query })
    }

    handleTableChange = (pagination) => {
        const { searchCustomers, walletSummaries } = this.props
        const page = pagination.current

        searchCustomers({ page, query: walletSummaries.getIn(['customer', 'query']) })
    }

    render () {
        const { showSearch, walletSummaries } = this.props

        return (
            <CustomerWalletsList
                columns={this.getColumns()}
                loading={walletSummaries.getIn(['customer', 'loading'])}
                onSearch={this.handleSearch}
                onTableChange={this.handleTableChange}
                pagination={{
                    ...walletSummaries.getIn(['customer', 'pagination']).toJS(),
                    showSizeChanger: false
                }}
                customers={this.getCustomers().toJS()}
                showSearch={showSearch}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    walletSummaries: state.get('walletSummaries')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchCustomers
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('walletSummaries')(CustomerWalletsListContainer)
)
