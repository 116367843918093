import styled from 'styled-components'
const LineItemsWrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  }

  .icon-edit {
    margin-right: 10px;
  }

  .hide {
    display: none;
  }

  .text-grey {
    color: gray;
  }
`
export default LineItemsWrapper