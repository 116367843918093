import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, mergeDeep } from 'immutable'

import ProductGroupSelectedItemsList from '../../components/productGroups/selectedItemsList'

import actions from '../../redux/productGroups/actions'

const { setItemQuantity } = actions

const ProductGroupSelectedItemsListContainer = ({
    itemsQtySelected,
    onSelectItem,
    selectedItemIds
}) => {
    const dispatch = useDispatch()
    const itemEntities = useSelector(state => state.getIn(['Entities', 'itemWithProductGroups']) || new Map())

    const onChangeItemQuantity = (qty, itemId) => {
        const added = new Map({[itemId]: { quantity: qty }})
        const itemsQty = mergeDeep(itemsQtySelected, added)

        dispatch(setItemQuantity(itemsQty))
    }

    const items = selectedItemIds.map(id => itemEntities.get(`${id}`) || new Map())

    return (
        <ProductGroupSelectedItemsList
            items={items.toJS()}
            onChangeItemQuantity={onChangeItemQuantity}
            onSelectItem={onSelectItem}
            selectedItemIds={selectedItemIds.toJS()} />
    )
}

ProductGroupSelectedItemsListContainer.propTypes = {
    itemsQtySelected: ImmutablePropTypes.map,
    onSelectItem: PropTypes.func.isRequired,
    selectedItemIds: ImmutablePropTypes.list
}

ProductGroupSelectedItemsListContainer.defaultProps = {}

export default ProductGroupSelectedItemsListContainer
