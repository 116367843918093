import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Form } from 'antd'
import Render from './render'

import notificationActions from '../../../../redux/notifications/actions'

const { setNotifications } = notificationActions

const CREATE_CUSTOMER_TICKET_MUTATION = gql`
    mutation CreateCustomerTicket($customerId: String!, $ticketParams: AdminTicketInput!){
        createCustomerTicket(customerNo: $customerId, ticketParams: $ticketParams){
            customer{
                id
            }
        }
    }
`

const TicketForm = ({ customerId, onClose, refetch }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [createTicket, { loading: saving }] = useMutation(CREATE_CUSTOMER_TICKET_MUTATION, {
        onCompleted: () => {
            refetch()
            dispatch(setNotifications('success', 'success', 'success'))
            handleClose()
        }
    })

    const handleClose = () => {
        form.resetFields()
        onClose()
    }

    const onSubmitForm = (values) => {
        createTicket({
            variables: {
                customerId,
                ticketParams: {
                    ...values,
                    orderIds: values.orderIds.map(({ value }) => value)
                }
            }
        })
    }

    return (
        <Render
            customerId={customerId}
            form={form}
            onClose={handleClose}
            onSubmitForm={onSubmitForm}
            saving={saving}
        />
    )
}

TicketForm.propTypes = {
    customerId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    refetch: PropTypes.func
}

TicketForm.defaultProps = {
    onClose: () => {},
    refetch: () => {}
}

export default TicketForm
