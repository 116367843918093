import { List, Map } from 'immutable'
import { PAGE_SIZE } from '../../constants/orders'

import actions from './actions'

const {
    SALES_SUMMARY_FETCH_FAIL,
    SALES_SUMMARY_FETCH_SUCCESS,
    SALES_SUMMARY_FETCHING,
    SALES_SUMMARY_FETCH_PROSPECTS_FAIL,
    SALES_SUMMARY_FETCH_PROSPECTS,
    SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS,
    SALES_SUMMARY_SET_SELECTED_MONTH,
    SALES_SUMMARY_PROSPECTS_SET_PAGINATION
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    currentSale: null,
    incomingBalancePerDate: null,
    prospectPerMonth: null,
    prospectPerMonthCount: 0,
    allQuotationsPerMonth: null,
    allQuotationsPerMonthCount: 0,
    incomingBalancePerMonth: null,
    incomingBalancePerMonthCount: 0,
    transferBackAmount: 0,
    returnPercentage: null,
    profit: null,
    selectedMonth: '',
    prospectItems: new List(),
    prospectLoading: false,
    prospectPagination: new Map({
        current: 1,
        pageSize: PAGE_SIZE,
        total: 1
    })
})

export default function salesSummaryReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case SALES_SUMMARY_FETCH_FAIL:
            return state.merge({ loading: false })
        case SALES_SUMMARY_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false,
                currentSale: action.currentSale,
                incomingBalancePerDate: action.incomingBalancePerDate,
                prospectPerMonth: action.prospectPerMonth,
                prospectPerMonthCount: action.prospectPerMonthCount,
                allQuotationsPerMonth: action.allQuotationsPerMonth,
                allQuotationsPerMonthCount: action.allQuotationsPerMonthCount,
                incomingBalancePerMonth: action.incomingBalancePerMonth,
                incomingBalancePerMonthCount: action.incomingBalancePerMonthCount,
                transferBackAmount: action.transferBackAmount,
                returnPercentage: action.returnPercentage,
                profit: action.profit
            })
        case SALES_SUMMARY_FETCHING:
            return state.merge({ prospectLoading: true })
        case SALES_SUMMARY_FETCH_PROSPECTS_FAIL:
            return state.merge({ prospectLoading: false })
        case SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS:
            return state.merge({
                prospectItems: new List(result),
                prospectLoading: false
            })
        case SALES_SUMMARY_FETCH_PROSPECTS:
            return state.merge({ loading: true })
        case SALES_SUMMARY_SET_SELECTED_MONTH:
            return state.merge({
                selectedMonth: action.selectedMonth
            })
        case SALES_SUMMARY_PROSPECTS_SET_PAGINATION:
            return state.mergeDeep({
                prospectPagination: action.pagination
            })
        default:
            return state
    }
}
