import styled from 'styled-components'
import WithDirection from '../../../config/withDirection'

const InquiryItemsWrapper = styled.div`
  margin-top: 20px;
  .doc-container {
    display:-webkit-flex;
    display:flex;
    flex-wrap: wrap;
    list-style-type:none;
    padding:0;
    justify-content:flex-end;
  }

  .left { margin-right: auto; }

  .edit-icon { margin-left: 10px;}

  .title { font-weight: bold;}

  .btn-edit {
    width: 60px;
    margin-right: 10px;
  }
  
  .calculation-button-section {
    float: right;
    padding-bottom: 4px
  }
  
  .space-between-button {
    margin-right: 10px
  }
  
  .small-between-button {
    margin-left: 3px
  }

  .btn-center {
    text-align: center
  }

  .card-margin {
    margin: 0 0 12px 0;
  }

  .line-items-btn {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .icon-add-item {
      margin: 0 10px 10px 0;
      display: inline-block;
    }

    .icon-clear-line-item {
      display: inline-block;
    }

    .select-file-line-item-btn {
      margin-right: 10px;

      span {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .upload-submit-btn {
      margin-right: 10px;
    }
  }
`

export default WithDirection(InquiryItemsWrapper)
