import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List } from 'immutable'

import SelectBrand from '../../components/selectBrand'

import actions from '../../redux/brands/actions'

const {
    fetchBrand,
    searchBrand
} = actions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectBrandContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner props
        fetchBrand: PropTypes.func.isRequired,
        searchBrand: PropTypes.func.isRequired,
        brandEntities: ImmutablePropTypes.map.isRequired,
        brands: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { value, fetchBrand } = this.props

        if (!value || value === VALUE_FROM_STATE) return ''
        fetchBrand({ id: value })
    }

    componentDidUpdate (prevProps) {
        const { value: prevValue } = prevProps
        const { value: currValue, fetchBrand } = this.props

        if (prevValue === currValue || currValue === VALUE_FROM_STATE) return null

        fetchBrand({ id: currValue })
    }

    getBrands () {
        const { brandEntities, brands } = this.props

        return brands.get('items', new List()).map((brandId) => {
            return brandEntities.get(_.toString(brandId))
        })
    }

    onChange = (brandId) => {
        const { onChange } = this.props

        onChange(brandId)
        this.setState({ value: brandId })
    }

    onSearch = (query) => {
        const { searchBrand } = this.props
        searchBrand({ query })
    }

    render () {
        const {
            onChange,
            brands,
            value: propValue,
            ...restProps
        } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectBrand
                loading={brands.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                brands={this.getBrands().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        brandEntities: state.getIn(['Entities', 'brands']),
        brands: state.get('brands')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBrand,
        searchBrand
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectBrandContainer)

