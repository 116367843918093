import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Col, Row, Statistic } from 'antd'

import { STATUS_TEXT_PROPS } from '../../../constants/salePipelines'

const getValue = value => {
    const { display } = value || {}

    if (!display || display === '0.00') return '-'

    return display
}

const getValueStyle = (status = null) => {
    return { marginBottom: '16px', ...STATUS_TEXT_PROPS[status] }
}

const SalePipeLinesMarginSummary = ({ marginSummary }) => {
    const { i18n } = useTranslation('salePipelines')
    const { sumOfGrandTotal, sumOfMargin, sumOfPotentialMargin, sumOfSuccessMargin } = marginSummary || {}

    return (
        <Row>
            <Col sm={6} xs={24}>
                <Statistic
                    title={i18n.t('order.grandTotal')}
                    prefix="฿"
                    value={getValue(sumOfGrandTotal)}
                    valueStyle={getValueStyle('other')} />
            </Col>
            <Col sm={6} xs={24}>
                <Statistic
                    title={i18n.t('salePipelines:totalProfit')}
                    prefix="฿"
                    value={getValue(sumOfMargin)}
                    valueStyle={getValueStyle('other')} />
            </Col>
            <Col sm={6} xs={24}>
                <Statistic
                    title={`${i18n.t('salePipelines:profitAmount')} (${i18n.t('salePipelines:isPotential')})`}
                    prefix="฿"
                    value={getValue(sumOfPotentialMargin)}
                    valueStyle={getValueStyle('potential')} />
            </Col>
            <Col sm={6} xs={24}>
                <Statistic
                    title={`${i18n.t('salePipelines:profitAmount')} (${i18n.t('salePipelines:statuses.success')})`}
                    prefix="฿"
                    value={getValue(sumOfSuccessMargin)}
                    valueStyle={getValueStyle('success')} />
            </Col>
        </Row>
    )
}

SalePipeLinesMarginSummary.propTypes = {
    statusSummary: PropTypes.object
}

SalePipeLinesMarginSummary.defaultProps = {}

export default SalePipeLinesMarginSummary
