import { Map, List } from 'immutable'

import { PAGE_SIZE } from '../../constants/orders'
import actions from './actions'

const {
    JOURNAL_ENTRY_DATES_FETCH,
    JOURNAL_ENTRY_DATES_FETCH_SUCCESS,
    JOURNAL_ENTRIES_FETCH,
    JOURNAL_ENTRIES_FETCH_SUCCESS,

    JOURNAL_ENTRY_DATES_SET_PAGINATION,
    JOURNAL_ENTRIES_SET_PAGINATION,
    JOURNAL_ENTRIES_SET_FILTERS,

    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE,
    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS
} = actions

const initState = new Map({
    dateItems: new List(),
    items: new List(),
    loading: false,
    filters: new Map({
        startDate: null,
        endDate: null
    }),
    datePagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    exporting: false,
})

export default function journalEntriesReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case JOURNAL_ENTRY_DATES_FETCH:
            return state.merge({ loading: true })
        case JOURNAL_ENTRY_DATES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                dateItems: new List(result)
            })
        case JOURNAL_ENTRY_DATES_SET_PAGINATION:
            return state.mergeDeep({
                datePagination: new Map(action.pagination)
            })
        case JOURNAL_ENTRIES_FETCH:
            return state.merge({ loading: true })
        case JOURNAL_ENTRIES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case JOURNAL_ENTRIES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case JOURNAL_ENTRIES_SET_FILTERS:
            return state.mergeDeep({
                filters: new Map(action.filters)
            })
        case FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE:
            return state.merge({
                exporting: true
            })
        case FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS:
            return state.merge({
                exporting: false
            })
        default:
            return state
    }
}
