import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import {apiFetch, apiPostForm} from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import { supplierOverTransferItemsSchema, supplierOverTransferItemSchema } from '../../schema'

import actions from './actions'
const {
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_REQUEST,
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEM_DELETING,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_REQUEST,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVING,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_REQUEST,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING,
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_REQUEST,
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH
} = actions

const fetchPath = (scope, { orderId }) => {
    switch (scope) {
        case 'order':
            return `/orders/${orderId}/supplier_over_transfer_items`
        default:
            return ''
    }
}

const savePath = (scope, { id, supplierId }) => {
    switch (scope) {
        case 'supplier': {
            const base = `/suppliers/${supplierId}/supplier_over_transfer_items`
            return id ? `${base}/${id}` : base
        }
        default:
            return ''
    }
}

export function *fetchSupplierOverTransferItemsSaga () {
    yield takeLatest(SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_REQUEST, function *({ payload, scope }) {
        const { orderId, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING })

        try {
            const path = fetchPath(scope, { orderId })
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierOverTransferItemsSchema)

            yield put({
                type: SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *fetchSupplierWithOverTransferItemsSaga () {
    yield takeLatest(SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH, loading: true })

        const { supplierId, page, per, onSuccess, onError } = payload
        const query = `page=${page}&per=${per}&order=transaction_date DESC`

        try {
            const data = yield call(apiFetch, `/suppliers/${supplierId}/supplier_over_transfer_items?${query}`, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierOverTransferItemsSchema)
            const total = data.meta.total_pages * per

            yield put({
                type: SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
                pagination: { current: page, pageSize: per, total },
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH, loading: false })
        }
    })
}

export function *saveSupplierOverTransferItemSaga () {
    yield takeLatest(SUPPLIER_OVER_TRANSFER_ITEM_SAVE_REQUEST, function *({ payload, scope }) {
        const { supplierId, id, supplierOverTransferItemParams, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_OVER_TRANSFER_ITEM_SAVING })

        try {
            const path = savePath(scope, { id, supplierId })
            const method = id ? 'PATCH' : 'POST'
            const data = yield call(apiPostForm, path, method, { supplier_over_transfer_item: supplierOverTransferItemParams })
            if ('error' in data) throw (data)

            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierOverTransferItemSchema)

            yield put({
                type: SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL })
            onError(error)
        }
    })
}

export function *deleteSupplierOverTransferItemSaga () {
    yield takeLatest(SUPPLIER_OVER_TRANSFER_ITEM_DELETE_REQUEST, function *({ payload, scope }) {
        const { supplierId, id, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_OVER_TRANSFER_ITEM_DELETING })

        try {
            const path = savePath(scope, { id, supplierId })
            const data = yield call(apiFetch, path, 'DELETE')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierOverTransferItemSchema)

            yield put({
                type: SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(deleteSupplierOverTransferItemSaga),
        fork(fetchSupplierOverTransferItemsSaga),
        fork(fetchSupplierWithOverTransferItemsSaga),
        fork(saveSupplierOverTransferItemSaga)
    ])
}
