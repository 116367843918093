import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InquiryPage from '../containers/Inquiry'
import InquiryItemsPage from '../containers/Items'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const InquiriesPage = ({ location, match }) => {
  const dispatch = useDispatch()
  const current = useSelector((state) => state.getIn(['App', 'current'])).toJS()
  const { pathname } = location

  useEffect(() => {
    if (current[0] !== 'order_summaries') {
      dispatch(changeCurrent(['order_summaries']))
    }
  }, [pathname])

  const path = match.url

  return (
    <Switch>
      <Route path={`${path}/:id`} component={InquiryPage} exact />
      <Route path={`${path}/:id/items`} component={InquiryItemsPage} />
    </Switch>
  )
}

InquiriesPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

InquiriesPage.defaultProps = {}

export default InquiriesPage
