import React from 'react'
import jsCookie from 'js-cookie'
import { DirectUpload } from '@rails/activestorage'

import apiConfig from '../../config/apiConfig'

export const BASE_URL = apiConfig.apiBase
const DIRECT_UPLOAD_URL = `${BASE_URL}/api/v1/active_storages/direct_uploads`
const TOKEN = jsCookie.get('token')

export const getBlobUrl = blob => {
  return [BASE_URL, 'rails', 'active_storage', 'blobs', 'redirect', blob.signed_id, blob.filename].join('/')
}

export const initActiveStorageBlobs = (
  acceptedFiles= [],
  { 
    setBlobs = () => {},
    onSuccess = () => {}, 
    onError = () => {}
  } = {}
) => {
  acceptedFiles.forEach((file) => {
    const upload = new DirectUpload(file, DIRECT_UPLOAD_URL, TOKEN)

    upload.create((error, blob) => {
      if (error) {
        console.error(error)
        onError()
      } else {
        setBlobs(value => (value || []).concat({ ...blob, file }))
        onSuccess(blob)
      }
    })
  })
}
