const actions = {
    STAFFS_FETCH_REQUEST: 'STAFFS_FETCH_REQUEST',
    STAFFS_FETCH_ROLE_REQUEST: 'STAFFS_FETCH_ROLE_REQUEST',
    STAFFS_FETCH_ROLE_SUCCESS: 'STAFFS_FETCH_ROLE_SUCCESS',
    STAFFS_FETCH_ROLE: 'STAFFS_FETCH_ROLE',
    STAFFS_FETCH_SUCCESS: 'STAFFS_FETCH_SUCCESS',
    STAFFS_FETCH: 'STAFFS_FETCH',
    STAFFS_SAVE_REQUEST: 'STAFFS_SAVE_REQUEST',
    STAFFS_SAVE_SUCCESS: 'STAFFS_SAVE_SUCCESS',
    STAFFS_SET_FORM_ERRORS: 'STAFFS_SET_FORM_ERRORS',
    STAFFS_SET_LOADING: 'STAFFS_SET_LOADING',
    STAFFS_SET_ROLE_LOADING: 'STAFFS_SET_ROLE_LOADING',
    STAFFS_SET_SAVING: 'STAFFS_SET_SAVING',
    STAFFS_SET_PAGINATION: 'STAFFS_SET_PAGINATION',
    STAFFS_SET_QUERY: 'STAFFS_SET_QUERY',
    STAFFS_FETCH_STAFF_REQUEST: 'STAFFS_FETCH_STAFF_REQUEST',
    STAFFS_FETCH_STAFF: 'STAFFS_FETCH_STAFF',
    STAFFS_FETCH_STAFF_SUCCESS: 'STAFFS_FETCH_STAFF_SUCCESS',

    fetchStaffs: ({ page = 1, query= '' } = {}) => ({
        type: actions.STAFFS_FETCH_REQUEST,
        payload: { page, query }
    }),
    fetchStaff: (id) => ({
        type: actions.STAFFS_FETCH_STAFF_REQUEST,
        payload: { id }
    }),
    fetchStaffRoles: () => ({
        type: actions.STAFFS_FETCH_ROLE_REQUEST
    }),
    saveStaff: (staff, router = '', id) => ({
        type: actions.STAFFS_SAVE_REQUEST,
        payload: { staff, router, id }
    })
}

export default actions
