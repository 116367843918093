import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import categoriesActions from '../../redux/categories/actions'

import { Select, Form } from 'antd'

const { fetchCategories } = categoriesActions

const Option = Select.Option

class OrderCategoriesForm extends Component {
    static propTypes = {
        form: PropTypes.object,
        categoryValue: PropTypes.string,
        categoryFieldName: PropTypes.string
    }

    componentDidMount () {
        const { fetchCategories } = this.props
        fetchCategories()
    }

    getCategories () {
        const { entities, categories } = this.props

        const categoriesList = categories.get('items').map((category) => {
            return entities.getIn(['categories', category.toString()])
        })

        return categoriesList
    }

    selectCategories = () => {
        const categories = this.getCategories()
        return categories.map((category) => {
            const categoryId = category.get('id')

            return (
                <Option key={categoryId} value={categoryId}>
                    {category.get(`name_${this.props.i18n.language}`)}
                </Option>
            )
        })
    }

    render () {
        const { i18n, categoryValue, categoryFieldName } = this.props

        return (
            <Form.Item name={categoryFieldName} initialValue={categoryValue}>
                <Select showSearch
                    allowClear
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    style={{ width: '80%' }}
                    placeholder={i18n.t('orders:selectCategory')}>
                    {this.selectCategories()}
                </Select>
            </Form.Item>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    categories: state.get('categories')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCategories
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('orders')(OrderCategoriesForm))
