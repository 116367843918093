import React, {useEffect} from 'react'
import { useQuery, gql} from '@apollo/client'

import MessageContainerForm from './Form'

import MessageContainerWrapper from './MessageContainer.style'

const LINE_CHAT_CHANNEL_QUERY = gql`
  query ClientLineChannel($id: ID!) {
    clientLineChannel(id: $id) {
      id
      displayName
      pictureUrl
      clientLineMessages {
        id
        source
        messageType
        imageUrl
        mainKeyword {
          id
          name
        }
        messageBody {
          text
        }
      }
    }
  }
`

const renderMessage = ({ imageUrl, mainKeyword, messageType, messageBody }) => {
  switch (messageType) {
    case 'sticker':
      return <div>send a sticker {mainKeyword ? `(${mainKeyword.name})` : null}</div>
    case 'image':
      if (!imageUrl) return 'no-img'

      return (
        <a className='image-section' href={imageUrl} target='_blank' rel="noreferrer">
          <img src={imageUrl} alt={imageUrl} />
        </a>
      )
    default:
      return <div>{messageBody && messageBody.text}</div>
  }
}

const renderLineMessages = (clientLineMessages) => {
  return clientLineMessages.map((clientLineMessage) => {
    let alignment = 'left'
    if(clientLineMessage.source === 'staff') {alignment = 'right'}

    return (
      <div key={`message-${clientLineMessage.id}`} style={{marginBottom: 16, textAlign: alignment}}>
        {renderMessage(clientLineMessage)}
      </div>
    )
  })
}

const MessageContainer = ({currentChannelId, pusherChannel}) => {
  const {data, error, refetch} = useQuery(LINE_CHAT_CHANNEL_QUERY, {variables: {
    id: currentChannelId,
    skip: !currentChannelId
  }})

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind(`line-message-channel_${currentChannelId}`)
      pusherChannel.bind(`line-message-channel_${currentChannelId}`, function(data) {
        refetch()
        return data
      });
    }
  }, [pusherChannel, refetch, currentChannelId])

  if (!currentChannelId) return (<div>เลือก channel ซ้ายมือ </div>)

  if (error) return(console.error(error))
  if (!data) return( null )

  return (
    <MessageContainerWrapper>
      {renderLineMessages(data.clientLineChannel.clientLineMessages)}
      <MessageContainerForm id={currentChannelId} />
    </MessageContainerWrapper>
  )
}

export default MessageContainer
