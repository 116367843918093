import { Map, List } from 'immutable'

import actions from './actions'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const {
    ORDERS_APPROVER_WAIT_STATE,
    ORDERS_APPROVER_WAIT_STATE_SUCCESS,
    ORDERS_SET_PAGINATION,
    SAVE_ORDERS_APPROVER_WAIT_STATE,
    PURCHASE_ORDERS_SET_SORTER,
    PURCHASER_SET
} = actions

const initState = new Map({
    orderApproverWaitItems: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: DEFAULT_CURRENT_PAGE,
        pageSize: PAGE_SIZE,
        total: PAGE_SIZE
    }),
    sorter: new Map(),
    filterPurchaser: null
})

export default function purchaseOrdersReducer (state = initState, action) {
    switch (action.type) {
        case ORDERS_APPROVER_WAIT_STATE:
            return state.merge({ loading: action.loading })
        case ORDERS_APPROVER_WAIT_STATE_SUCCESS:
            return state.merge({
                orderApproverWaitItems: new List(action.result),
                loading: false
            })
        case ORDERS_SET_PAGINATION:
            return state.mergeDeep({ pagination: action.pagination })
        case SAVE_ORDERS_APPROVER_WAIT_STATE:
            return state.merge({
                saving: action.saving
            })
        case PURCHASE_ORDERS_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        case PURCHASER_SET:
            return state.merge({ filterPurchaser: action.filterPurchaser })
        default:
            return state
    }
}
