const actions = {
    CHART_OF_ACCOUNTS_FETCH_REQUEST: 'CHART_OF_ACCOUNTS_FETCH_REQUEST',
    CHART_OF_ACCOUNTS_FETCH: 'CHART_OF_ACCOUNTS_FETCH',
    CHART_OF_ACCOUNTS_FETCH_SUCCESS: 'CHART_OF_ACCOUNTS_FETCH_SUCCESS',
    CHART_OF_ACCOUNTS_SET_PAGINATION: 'CHART_OF_ACCOUNTS_SET_PAGINATION',

    CHART_OF_ACCOUNTS_SAVE_REQUEST: 'CHART_OF_ACCOUNTS_SAVE_REQUEST',
    CHART_OF_ACCOUNTS_SAVING: 'CHART_OF_ACCOUNTS_SAVING',
    CHART_OF_ACCOUNTS_SET_FORM_ERRORS: 'CHART_OF_ACCOUNTS_SET_FORM_ERRORS',
    CHART_OF_ACCOUNTS_SAVE_SUCCESS: 'CHART_OF_ACCOUNTS_SAVE_SUCCESS',

    CHART_OF_ACCOUNTS_SET_FORM_MODAL: 'CHART_OF_ACCOUNTS_SET_FORM_MODAL',

    CHART_OF_ACCOUNT_DELETE_REQUEST: 'CHART_OF_ACCOUNT_DELETE_REQUEST',
    CHART_OF_ACCOUNT_DELETE: 'CHART_OF_ACCOUNT_DELETE',
    CHART_OF_ACCOUNT_DELETE_SUCCESS: 'CHART_OF_ACCOUNT_DELETE_SUCCESS',

    CHART_OF_ACCOUNT_FETCH_REQUEST: 'CHART_OF_ACCOUNT_FETCH_REQUEST',
    CHART_OF_ACCOUNT_FETCH: 'CHART_OF_ACCOUNT_FETCH',
    CHART_OF_ACCOUNT_FETCH_SUCCESS: 'CHART_OF_ACCOUNT_FETCH_SUCCESS',

    CHART_OF_ACCOUNT_TYPES_FETCH_REQUEST: 'CHART_OF_ACCOUNT_TYPES_FETCH_REQUEST',
    CHART_OF_ACCOUNT_TYPES_FETCHING: 'CHART_OF_ACCOUNT_TYPES_FETCHING',
    CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS: 'CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS',

    fetchChartOfAccounts: ({
        page = 1,
        per = 20,
        query = ''
    } = {}) => ({
        type: actions.CHART_OF_ACCOUNTS_FETCH_REQUEST,
        payload: { page, per, query }
    }),


    fetchChartOfAccount: ({ id } = {}) => ({
        type: actions.CHART_OF_ACCOUNT_FETCH_REQUEST,
        payload: { id }
    }),

    saveChartOfAccount: ({
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.CHART_OF_ACCOUNTS_SAVE_REQUEST,
            payload: { id, params, onSuccess, onError }
        }
    },

    setChartOfAccountModal: ({ visible, selected } = {}) => ({
        type: actions.CHART_OF_ACCOUNTS_SET_FORM_MODAL,
        visible, selected
    }),

    deleteChartOfAccount: ({ id, onSuccess = () => {} } = {}) => ({
        type: actions.CHART_OF_ACCOUNT_DELETE_REQUEST,
        payload: { id, onSuccess }
    }),

    fetchChartOfAccountTypes: () => ({
        type: actions.CHART_OF_ACCOUNT_TYPES_FETCH_REQUEST
    })
}

export default actions
