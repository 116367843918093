import { List, Map } from 'immutable'

import actions from './actions'

const {
    PRODUCT_GROUPS_FETCH_FAIL,
    PRODUCT_GROUPS_FETCH_SUCCESS,
    PRODUCT_GROUPS_FETCHING,
    PRODUCT_GROUPS_FETCH_ITEMS_FAIL,
    PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS,
    PRODUCT_GROUPS_FETCHING_ITEMS,

    PRODUCT_GROUPS_SET_ITEMS_PAGINATION,
    PRODUCT_GROUPS_SET_PRODUCT_IMAGE,
    PRODUCT_GROUPS_SET_PRODUCT_GROUP,
    PRODUCT_GROUPS_SET_PRODUCT_GROUP_ITEM,
    PRODUCT_GROUPS_SET_ROOT_ITEM_ATTRIBUTE,
    PRODUCT_GROUPS_SET_SELECTED_ITEM_IDS,
    PRODUCT_GROUPS_SET_ITEM_ATTRIBUTE_QUANTITY_SELECTED,
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    itemIds: new List(),
    itemsLoading: false,
    itemsPagination: new Map({
        current: 1,
        pageSize: 10,
        total: 10
    }),
    productImage: null,
    selectedProductGroup: undefined,
    selectedProductGroupItem: undefined,
    selectedRootItemAttribute: undefined,
    selectedItemIds: new List(),
    itemsQtySelected: new Map(),
})

export default function productGroupsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case PRODUCT_GROUPS_FETCH_FAIL:
            return state.merge({ loading: false })
        case PRODUCT_GROUPS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case PRODUCT_GROUPS_FETCHING:
            return state.merge({ loading: true })
        case PRODUCT_GROUPS_FETCH_ITEMS_FAIL:
            return state.merge({ itemsLoading: false })
        case PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS:
            const newData =  [...new Set(result)]
            return state.merge({
                itemIds: new List(newData),
                itemsLoading: false
            })
        case PRODUCT_GROUPS_FETCHING_ITEMS:
            return state.merge({ itemsLoading: true })
        case PRODUCT_GROUPS_SET_ITEMS_PAGINATION:
            return state.mergeDeep({ itemsPagination: new Map(action.pagination) })
        case PRODUCT_GROUPS_SET_PRODUCT_IMAGE:
            return state.merge({ productImage: action.productImage ? new Map(action.productImage) : null})
        case PRODUCT_GROUPS_SET_PRODUCT_GROUP:
            return state.merge({ selectedProductGroup: action.value })
        case PRODUCT_GROUPS_SET_PRODUCT_GROUP_ITEM:
            return state.merge({ selectedProductGroupItem: action.value })
        case PRODUCT_GROUPS_SET_ROOT_ITEM_ATTRIBUTE:
            return state.merge({ selectedRootItemAttribute: action.value })
        case PRODUCT_GROUPS_SET_SELECTED_ITEM_IDS:
            return state.merge({ selectedItemIds: new List(action.value) })
        case PRODUCT_GROUPS_SET_ITEM_ATTRIBUTE_QUANTITY_SELECTED:
            return state.mergeDeep({ itemsQtySelected: new Map(action.quantity) })
        default:
            return state
    }
}
