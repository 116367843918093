import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Divider, Form, Input, Radio, Spin, Typography } from 'antd'
import WithholdingTaxWrapper from './withholdingTax.style'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
}

const WithholdingTaxForm = ({
    errors,
    i18n,
    loading,
    onSubmitForm,
    saving,
    toggleForm,
    withholdingTax
}) => {
    const handleSubmitForm = (values) => {
        values['issue_date'] = values['issue_date'] ? values['issue_date'].format('YYYY-MM-DD') : null

        onSubmitForm(values)
    }

    const issueDate = _.get(withholdingTax, 'issue_date', null)

    return (
        <WithholdingTaxWrapper>
            <Spin spinning={loading}>
                <Form onFinish={handleSubmitForm} {...formItemLayout}>
                    <Typography.Title level={4}>{i18n.t('withholdingTaxes:formTitle')}</Typography.Title>

                    <Form.Item
                        name='number'
                        initialValue={_.get(withholdingTax, 'number', null)}
                        label={i18n.t('withholdingTaxes:number')}
                        validateStatus={_.get(errors, 'number') && 'error'}
                        help={_.get(errors, 'number')}
                    >
                            <Input />
                    </Form.Item>

                    <Divider orientation="left">{i18n.t('withholdingTaxes:taxpayer')}</Divider>
                    <Form.Item label={i18n.t('withholdingTaxes:name')}
                        name='name'
                        initialValue={_.get(withholdingTax, 'name', null)}>
                            <Input />
                    </Form.Item>
                    <Form.Item
                        name='tax_id'
                        initialValue={_.get(withholdingTax, 'tax_id', null)}
                        label={i18n.t('withholdingTaxes:taxId')}
                        validateStatus={_.get(errors, 'tax_id') && 'error'}
                        help={_.get(errors, 'tax_id')}
                    >
                            <Input />
                    </Form.Item>
                    <Form.Item label={i18n.t('withholdingTaxes:type')}
                        name='corporate'
                        initialValue={_.get(withholdingTax, 'corporate')}>
                            <Radio.Group>
                                <Radio value>{i18n.t('withholdingTaxes:corporate')}</Radio>
                                <Radio value={false}>{i18n.t('withholdingTaxes:person')}</Radio>
                            </Radio.Group>
                    </Form.Item>
                    <Form.Item label={i18n.t('withholdingTaxes:address')}
                        name='address'
                        initialValue={_.get(withholdingTax, 'address', null)}>
                            <Input.TextArea />
                    </Form.Item>

                    <Divider orientation="left">{i18n.t('withholdingTaxes:details')}</Divider>
                    <Form.Item label={i18n.t('withholdingTaxes:issueDate')}
                        name='issue_date'
                        initialValue={issueDate ? moment(issueDate, 'YYYY/MM/DD') : null}>
                            <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name='amount'
                        initialValue={_.get(withholdingTax, 'amount.raw', null)}
                        label={i18n.t('withholdingTaxes:amount')}
                        validateStatus={_.get(errors, 'amount') && 'error'}
                        help={_.get(errors, 'amount')}
                    >
                            <Input prefix="฿" />
                    </Form.Item>
                    <Form.Item
                        name='percentage'
                        initialValue={_.get(withholdingTax, 'percentage', null)}
                        label={i18n.t('withholdingTaxes:percentage')}
                        validateStatus={_.get(errors, 'percentage') && 'error'}
                        help={_.get(errors, 'percentage')}
                    >
                            <Input />
                    </Form.Item>
                    <Form.Item label={i18n.t('withholdingTaxes:others')}
                        name='others'
                        initialValue={_.get(withholdingTax, 'others', null)}>
                            <Input />
                    </Form.Item>

                    <Form.Item className="withholding-tax-form-action-btn" {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                        <Button htmlType="button" onClick={toggleForm}>{i18n.t('shared:close')}</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </WithholdingTaxWrapper>
    )
}

WithholdingTaxForm.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired,
    withholdingTax: PropTypes.object
}

WithholdingTaxForm.defaultProps = {}

export default withTranslation('withholdingTaxes')(WithholdingTaxForm)

