import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

export const withBank = (WrappedComponent) => {
    const newComponent = class App extends Component {
        bankCodeToBankName = (bankCode = '') => {
            return this.props.i18n.t([`banks:${_.toString(bankCode)}`, 'banks:other'])
        }

        render () {
            return <WrappedComponent bankCodeToBankName={this.bankCodeToBankName} {...this.props} />
        }
    }

    return withTranslation(['banks'])(newComponent)
}
