import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'

import BankStatementImport from '../../components/BankStatements/Import'

import actions from '../../redux/bankStatements/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    setImportAdditionalUpdateVisibleModal,
    uploadBankStatements
} = actions

const { setNotifications } = notificationActions

class BankStatementImportAdditionalUpdateContainer extends Component {
    static propTypes = {
        // Inner Props
        bankStatements: ImmutablePropTypes.map.isRequired,
        setImportAdditionalUpdateVisibleModal: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        uploadBankStatements: PropTypes.func.isRequired
    }

    static defaultProps = {}

    onSubmit = ({ params = {} } = {}) => {
        const { i18n, uploadBankStatements, setNotifications } = this.props

        uploadBankStatements({
            params,
            onSuccess: () => {
                this.onToggleVisibleModal()
                setNotifications(
                    i18n.t('bankStatements:uploadSuccess'),
                    i18n.t('bankStatements:uploadSuccessDescription'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    onToggleVisibleModal = () => {
        const { bankStatements, setImportAdditionalUpdateVisibleModal } = this.props

        setImportAdditionalUpdateVisibleModal({ visibleModal: !bankStatements.getIn(['importAdditionalUpdate', 'visibleModal'], true) })
    }

    render () {
        const { bankStatements } = this.props

        return (
            <BankStatementImport
                additionalUpdate={true}
                onSubmit={this.onSubmit}
                onToggleVisibleModal={this.onToggleVisibleModal}
                saving={bankStatements.get('saving', false)}
                hideBankSelect={true}
                textButton='importAdditionalUpdate'
                visibleModal={bankStatements.getIn(['importAdditionalUpdate', 'visibleModal'], false)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setImportAdditionalUpdateVisibleModal,
        setNotifications,
        uploadBankStatements
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['bankStatements'])(BankStatementImportAdditionalUpdateContainer)
)
