import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List } from 'immutable'

import OrderInvoiceItemsForm from '../../components/Invoices/orderInvoiceItemsForm'
import accountingActions from '../../redux/orders/accounting/actions'

const {
    fetchOrderBusinessAddresses,
    saveOrderBusinessAddress
} = accountingActions

class OrderInvoiceItemsFormContainer extends Component {
    static propTypes = {
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        orderId: PropTypes.string.isRequired,
        invoiceId: PropTypes.string.isRequired,
        orderBusinessAddressEntities: ImmutablePropTypes.map.isRequired,
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        onSaveSuccess: PropTypes.func
    }

    static defaultProps = {
        onSaveSuccess: () => {}
    }

    getOrderBusinessAddressItems () {
        const { orderBusinessAddressEntities, invoiceId } = this.props

        return orderBusinessAddressEntities.getIn([invoiceId, 'items']) || new List()
    }

    handleSubmit = (values) => {
        const {
            invoiceId,
            orderId,
            saveOrderBusinessAddress,
            onSaveSuccess
        } = this.props

        saveOrderBusinessAddress({
            address: {
                items_attributes: [
                    ...values['line_items'],
                    ...values['shipping_costs']
                ]
            },
            id: invoiceId,
            orderId,
            onSuccess: () => { onSaveSuccess() }
        })
    }

    render () {
        const { orderId } = this.props

        return (
            <OrderInvoiceItemsForm
                onSubmit={this.handleSubmit}
                orderBusinessAddressItems={this.getOrderBusinessAddressItems().toJS()}
                orderId={orderId} />
        )
    }
}

const mapStateToProps = state => ({
    orderBusinessAddressEntities: state.getIn(['Entities', 'orderBusinessAddresses'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchOrderBusinessAddresses,
        saveOrderBusinessAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInvoiceItemsFormContainer)
