import { Map, List } from 'immutable'
import actions from './actions'

const {
    FETCH_TRUCK_RESERVATION_SUCCESS,
    FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS,
    FETCH_TRUCK_RESERVATIONS_NOT_PAID,
    SAVE_TRUCK_RESERVATION_SUCCESS,
    SET_TRUCK_RESERVATION_FORM_ERROR,
    SET_TRUCK_RESERVATION_LOADING,
    SET_TRUCK_RESERVATION_SAVING,
    TRUCK_RESERVATIONS_SET_UPDATING_ID
} = actions

const initState = new Map({
    formErrors: new Map(),
    items: new List(),
    loading: false,
    notPaidItems: new List(),
    notPaidLoading: false,
    saving: false,
    updatingId: ''
})

export default function truckReservationReducer (state = initState, action) {
    const result = action.result
    const items = state.get('items')

    switch (action.type) {
        case FETCH_TRUCK_RESERVATION_SUCCESS:
            return state.merge({
                items: new List(result)
            })
        case FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS:
            return state.merge({
                notPaidItems: new List(result)
            })
        case FETCH_TRUCK_RESERVATIONS_NOT_PAID:
            return state.merge({
                notPaidLoading: actions.loading
            })
        case SAVE_TRUCK_RESERVATION_SUCCESS:
            return state.merge({
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case SET_TRUCK_RESERVATION_FORM_ERROR:
            return state.merge({
                formErrors: new Map(action.error)
            })
        case SET_TRUCK_RESERVATION_LOADING:
            return state.merge({
                loading: actions.loading
            })
        case SET_TRUCK_RESERVATION_SAVING:
            return state.merge({
                saving: action.saving
            })
        case TRUCK_RESERVATIONS_SET_UPDATING_ID:
            return state.merge({ updatingId: action.updatingId })
        default:
            return state
    }
}
