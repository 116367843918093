import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import bankStatementActions from '../../redux/bankStatements/actions'
import SelectSearchPaymentVoucher from '../../components/SelectSearchPaymentVoucher'

const {
    fetchPaymentVoucherThroughQuery
} = bankStatementActions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectSearchPaymentVoucherContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(undefined)]),

        // Inner props
        fetchPaymentVoucherThroughQuery: PropTypes.func.isRequired,
        orderPaymentDetailsEntities: ImmutablePropTypes.map.isRequired,
        orderPaymentDetails: ImmutablePropTypes.map.isRequired,
        bankStatements: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    getPaymentVouchers () {
        const { paymentVoucherEntities, vatDocuments } = this.props

        return vatDocuments.get('items', new List()).map((id) => {
            return paymentVoucherEntities.get(_.toString(id), new Map())
        })
    }

    onChange = (orderId) => {
        const { onChange } = this.props

        onChange(orderId)
        this.setState({ value: orderId })
    }

    onSearch = (query) => {
        const { fetchPaymentVoucherThroughQuery } = this.props

        fetchPaymentVoucherThroughQuery(query)
    }

    render () {
        const {
            onChange,
            vatDocuments,
            value: propValue,
            defaultSelect,
            ...restProps
        } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectSearchPaymentVoucher
                loading={vatDocuments.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                defaultValue={defaultSelect}
                paymentVouchers={this.getPaymentVouchers().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
        vatDocuments: state.get('vatDocuments'),
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPaymentVoucherThroughQuery
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSearchPaymentVoucherContainer)
