import { Map } from 'immutable'
import _ from 'lodash'

const actions = {
    TRUCK_FETCH_FAIL: 'TRUCK_FETCH_FAIL',
    TRUCK_FETCH_REQUEST: 'TRUCK_FETCH_REQUEST',
    TRUCK_FETCH_SUCCESS: 'TRUCK_FETCH_SUCCESS',
    TRUCK_FETCH: 'TRUCK_FETCH',

    TRUCK_FORM_RESET_ERROR: 'TRUCK_FORM_RESET_ERROR',
    TRUCK_FORM_SET_TRUCK_ID: 'TRUCK_FORM_SET_TRUCK_ID',

    TRUCK_OWNERS_FETCH_FAIL: 'TRUCK_OWNERS_FETCH_FAIL',
    TRUCK_OWNERS_FETCH_REQUEST: 'TRUCK_OWNERS_FETCH_REQUEST',
    TRUCK_OWNERS_FETCH_SUCCESS: 'TRUCK_OWNERS_FETCH_SUCCESS',
    TRUCK_OWNERS_FETCH: 'TRUCK_OWNERS_FETCH',

    TRUCK_OWNER_FETCH_FAIL: 'TRUCK_OWNER_FETCH_FAIL',
    TRUCK_OWNER_FETCH_REQUEST: 'TRUCK_OWNER_FETCH_REQUEST',
    TRUCK_OWNER_FETCH_SUCCESS: 'TRUCK_OWNER_FETCH_SUCCESS',
    TRUCK_OWNER_FETCH: 'TRUCK_OWNER_FETCH',

    TRUCK_OWNER_BANK_ACCOUNT_FORM_RESET_ERROR: 'TRUCK_OWNER_BANK_ACCOUNT_FORM_RESET_ERROR',
    TRUCK_OWNER_BANK_ACCOUNT_FORM_SET_BANK_ACCOUNT_ID: 'TRUCK_OWNER_BANK_ACCOUNT_FORM_SET_BANK_ACCOUNT_ID',

    TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL: 'TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL',
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_REQUEST: 'TRUCK_OWNER_BANK_ACCOUNT_SAVE_REQUEST',
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS: 'TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS',
    TRUCK_OWNER_BANK_ACCOUNT_SAVE: 'TRUCK_OWNER_BANK_ACCOUNT_SAVE',

    TRUCK_OWNER_FORM_RESET_ERROR: 'TRUCK_OWNER_FORM_RESET_ERROR',
    TRUCK_OWNER_FORM_SET_OWNER_ID: 'TRUCK_OWNER_FORM_SET_OWNER_ID',

    TRUCK_OWNER_SAVE_FAIL: 'TRUCK_OWNER_SAVE_FAIL',
    TRUCK_OWNER_SAVE_REQUEST: 'TRUCK_OWNER_SAVE_REQUEST',
    TRUCK_OWNER_SAVE_SUCCESS: 'TRUCK_OWNER_SAVE_SUCCESS',
    TRUCK_OWNER_SAVE: 'TRUCK_OWNER_SAVE',

    TRUCK_OWNER_STAFF_SAVE_FAIL: 'TRUCK_OWNER_STAFF_SAVE_FAIL',
    TRUCK_OWNER_STAFF_SAVE_REQUEST: 'TRUCK_OWNER_STAFF_SAVE_REQUEST',
    TRUCK_OWNER_STAFF_SAVE_SUCCESS: 'TRUCK_OWNER_STAFF_SAVE_SUCCESS',
    TRUCK_OWNER_STAFF_SAVING: 'TRUCK_OWNER_STAFF_SAVING',
    TRUCK_OWNER_STAFF_SET_FORM: 'TRUCK_OWNER_STAFF_SET_FORM',

    TRUCK_OWNER_TABLE_SET_OWNER_TYPE: 'TRUCK_OWNER_TABLE_SET_OWNER_TYPE',
    TRUCK_OWNER_TABLE_SET_VISIBLE_MODAL: 'TRUCK_OWNER_TABLE_SET_VISIBLE_MODAL',

    TRUCK_SAVE_FAIL: 'TRUCK_SAVE_FAIL',
    TRUCK_SAVE_REQUEST: 'TRUCK_SAVE_REQUEST',
    TRUCK_SAVE_SUCCESS: 'TRUCK_SAVE_SUCCESS',
    TRUCK_SAVE: 'TRUCK_SAVE',

    TRUCKS_FETCH_FAIL: 'TRUCKS_FETCH_FAIL',
    TRUCKS_FETCH_REQUEST: 'TRUCKS_FETCH_REQUEST',
    TRUCKS_FETCH_SUCCESS: 'TRUCKS_FETCH_SUCCESS',
    TRUCKS_FETCH: 'TRUCKS_FETCH',

    TRUCKS_RESET_SELECTED_TRUCK_OWNER: 'TRUCKS_RESET_SELECTED_TRUCK_OWNER',
    TRUCKS_RESET_SELECTED_TRUCK: 'TRUCKS_RESET_SELECTED_TRUCK',

    TRUCKS_SET_SELECTED_TRUCK_ID: 'TRUCKS_SET_SELECTED_TRUCK_ID',
    TRUCKS_SET_SELECTED_TRUCK_OWNER: 'TRUCKS_SET_SELECTED_TRUCK_OWNER',
    TRUCKS_SET_TRUCK_IDS: 'TRUCKS_SET_TRUCK_IDS',

    fetchTruckOwners: ({ type, query = '', page = 1, per = 20, onlyVerified = false } = {}) => {
        return {
            type: actions.TRUCK_OWNERS_FETCH_REQUEST,
            payload: { type, query, page, per, onlyVerified }
        }
    },

    fetchTruckOwner: ({ id } = {}) => {
        return {
            type: actions.TRUCK_OWNER_FETCH_REQUEST,
            payload: { id }
        }
    },

    resetTruckOwnerFormError: () => {
        return {
            type: actions.TRUCK_OWNER_FORM_RESET_ERROR
        }
    },

    resetTruckFormError: () => {
        return {
            type: actions.TRUCK_FORM_RESET_ERROR,
            error: new Map()
        }
    },

    resetTruckOwnerBankAccountFormError: () => {
        return {
            type: actions.TRUCK_OWNER_BANK_ACCOUNT_FORM_RESET_ERROR
        }
    },

    saveTruck: ({ type, id, params = { truck: {} } } = {}) => {
        return {
            type: actions.TRUCK_SAVE_REQUEST,
            payload: { type, id, params }
        }
    },

    saveTruckOwner: ({ query = '', id, params = { truck_owner: {} } } = {}) => {
        return {
            type: actions.TRUCK_OWNER_SAVE_REQUEST,
            payload: { query, id, params }
        }
    },

    saveTruckOwnerStaff: ({
        truckOwnerId = '',
        staffId = '',
        // Example params: { email: 'example@osh.com', ... }
        staffParams = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!truckOwnerId || _.isEmpty(staffParams)) return null
        return {
            type: actions.TRUCK_OWNER_STAFF_SAVE_REQUEST,
            payload: { truckOwnerId, staffId, staffParams, onSuccess, onError }
        }
    },

    saveTruckOwnerBankAccount: ({ type, query = '', ownerId, id, params = { truck_owner_bank_account: {} } } = {}) => {
        return {
            type: actions.TRUCK_OWNER_BANK_ACCOUNT_SAVE_REQUEST,
            payload: { type, query, ownerId, id, params }
        }
    },

    setBankAccountId: ({ bankAccountId, ownerId }) => {
        return {
            type: actions.TRUCK_OWNER_BANK_ACCOUNT_FORM_SET_BANK_ACCOUNT_ID,
            bankAccountId, ownerId
        }
    },

    setTruckId: ({ truckId, ownerId }) => {
        return {
            type: actions.TRUCK_FORM_SET_TRUCK_ID,
            truckId, ownerId
        }
    },

    setTruckOwnerId: (ownerId) => {
        return {
            type: actions.TRUCK_OWNER_FORM_SET_OWNER_ID,
            ownerId
        }
    },

    setTruckOwnerStaffForm: ({ ownerId, error = {} } = {}) => {
        return {
            type: actions.TRUCK_OWNER_STAFF_SET_FORM,
            truckOwnerStaffForm: { ownerId, error }
        }
    },

    setTruckOwnerType: (ownerType) => {
        return {
            type: actions.TRUCK_OWNER_TABLE_SET_OWNER_TYPE,
            ownerType
        }
    },

    setTruckOwnerVisibleModal: ({ visibleModal }) => {
        return {
            type: actions.TRUCK_OWNER_TABLE_SET_VISIBLE_MODAL,
            visibleModal
        }
    },

    fetchTruck: ({ id = '' } = {}) => {
        return {
            type: actions.TRUCK_FETCH_REQUEST,
            payload: { id }
        }
    },

    fetchTrucks: ({ query = '', page = 1, per = 20 } = {}) => {
        return {
            type: actions.TRUCKS_FETCH_REQUEST,
            payload: { query, page, per }
        }
    },

    resetSelectedTruckOwner: ({ truckOwner = undefined } = {}) => {
        return {
            type: actions.TRUCKS_RESET_SELECTED_TRUCK_OWNER,
            truckOwner
        }
    },


    resetSelectedTruck: ({ truckId = undefined, truckIds = [] } = {}) => {
        return {
            type: actions.TRUCKS_RESET_SELECTED_TRUCK,
            truckId,
            truckIds
        }
    },

    setSelectedTruckId: ({ truckId = undefined } = {}) => {
        return {
            type: actions.TRUCKS_SET_SELECTED_TRUCK_ID,
            truckId
        }
    },

    setSelectedTruckOwner: ({ truckOwner = undefined } = {}) => {
        return {
            type: actions.TRUCKS_SET_SELECTED_TRUCK_OWNER,
            truckOwner
        }
    },

    setTruckIds: ({ truckIds = [] } = {}) => {
        return {
            type: actions.TRUCKS_SET_TRUCK_IDS,
            truckIds
        }
    }
}

export default actions
