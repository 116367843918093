const actions = {
    ORDER_NOTE_IMAGES_REQUEST: 'ORDER_NOTE_IMAGES_REQUEST',
    ORDER_NOTE_IMAGES_FETCH: 'ORDER_NOTE_IMAGES_FETCH',
    ORDER_NOTE_IMAGES_FETCH_SUCCESS: 'ORDER_NOTE_IMAGES_FETCH_SUCCESS',
    ORDER_NOTE_IMAGE_SAVE_REQUEST: 'ORDER_NOTE_IMAGE_SAVE_REQUEST',
    ORDER_NOTE_IMAGE_SET_SAVING: 'ORDER_NOTE_IMAGE_SET_SAVING',
    ORDER_NOTE_IMAGE_SAVE_SUCCESS: 'ORDER_NOTE_IMAGE_SAVE_SUCCESS',
    ORDER_NOTE_IMAGE_DELETE_REQUEST: 'ORDER_NOTE_IMAGE_DELETE_REQUEST',
    ORDER_NOTE_IMAGE_DELETE: 'ORDER_NOTE_IMAGE_DELETE',
    ORDER_NOTE_IMAGE_DELETE_SUCCESS: 'ORDER_NOTE_IMAGE_DELETE_SUCCESS',

    fetchOrderNoteImages: (orderId) => ({
        type: actions.ORDER_NOTE_IMAGES_REQUEST,
        payload: { orderId }
    }),
    saveOrderNoteImage: (orderId, values, saveFromCustomerNote = false, {
        onSuccess = () => {},
        onUploadProgress = () => {}
    } = {}) => ({
        type: actions.ORDER_NOTE_IMAGE_SAVE_REQUEST,
        payload: { orderId, values, saveFromCustomerNote, onSuccess, onUploadProgress }
    }),
    deleteOrderNoteImage: (id) => ({
        type: actions.ORDER_NOTE_IMAGE_DELETE_REQUEST,
        payload: { id }
    })
}

export default actions
