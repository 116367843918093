import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Select, Spin } from 'antd'

import { withTranslation } from 'react-i18next'

const SelectProvince = ({
    i18n,
    loading,
    onChange,
    provinces,
    value
}) => {
    const filterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return (
        <Select
            allowClear
            className="select-province-component"
            filterOption={filterOption}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            placeholder={i18n.t('provinces:placeHolder')}
            showSearch
            style={{ width: '100%' }}
            value={value}
        >
            {provinces.map((province, index) => {
                return (
                    <Select.Option
                        key={_.get(province, 'id', `index-${index}`)}
                        value={_.toString(_.get(province, 'id', `index-${index}`))}
                    >
                        {_.get(province, `name_${i18n.language}`, '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectProvince.propTypes = {
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    provinces: PropTypes.array,
    value: PropTypes.string
}

SelectProvince.defaultProps = {
    loading: false,
    onChange: () => {},
    provinces: [],
    value: undefined
}

export default withTranslation(['provinces'])(SelectProvince)
