import { all, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'

import {
    orderShippingAddressSchema
} from '../../schema'

const { setNotifications } = actionsNotification
const {
    ORDER_SHIPPING_ADDRESSES_FETCH_REQUEST,
    ORDER_SHIPPING_ADDRESSES_FETCH,
    ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS,
    ORDER_SHIPPING_ADDRESSES_SET_LOADING,
    ORDER_SHIPPING_ADDRESSES_SAVE_REQUEST,
    ORDER_SHIPPING_ADDRESSES_SAVING,
    ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS
} = actions

const orderShippingAddressPath = (orderId, id) => {
    const path = `/orders/${orderId}/shipping_addresses`

    if (!id) { return path }
    return `${path}/${id}`
}

export function *fetchOrderShippingAddress () {
    yield takeLatest(ORDER_SHIPPING_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SHIPPING_ADDRESSES_FETCH })
        yield put({ type: ORDER_SHIPPING_ADDRESSES_SET_LOADING, loading: true })

        const orderId = payload.orderId
        const path = `/orders/${orderId}/shipping_addresses`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderShippingAddressSchema)

            yield put({
                type: ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            yield put({ type: ORDER_SHIPPING_ADDRESSES_SET_LOADING, loading: false })
        } finally {
            yield put({ type: ORDER_SHIPPING_ADDRESSES_SET_LOADING, loading: false })
        }
    })
}

export function *submitOrderBusinessAddress () {
    yield takeLatest(ORDER_SHIPPING_ADDRESSES_SAVE_REQUEST, function *({ payload }) {
        yield put({
            type: ORDER_SHIPPING_ADDRESSES_SAVING,
            saving: true
        })

        const { orderId, id, params, onSuccess } = payload
        const path = orderShippingAddressPath(orderId, id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ order_shipping_address: params })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, orderShippingAddressSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: ORDER_SHIPPING_ADDRESSES_SAVING,
                saving: false
            })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchOrderShippingAddress),
        fork(submitOrderBusinessAddress)
    ])
}
