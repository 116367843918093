import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import AddressFormContainer from './AddressFormContainer'

class CustomerEditShippingAddressPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Customers | OSH CRM'
    }

    onSaveSuccess = () => {
        const { customer_id: customerId } = this.props.match.params

        setTimeout(() => window.location.replace(`/customers/${customerId}`), 500)
    }

    render () {
        const { customer_id: customerId, id } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/customers', name: 'Customers' },
                    { path: `/customers/${customerId}`, name: `${customerId}` },
                    { path: `/customers/${customerId}`, name: 'Shipping Addresses' },
                    { path: `/customers/${customerId}/shipping_addresses/${id}/edit`, name: id },
                    { path: `/customers/${customerId}/shipping_addresses/${id}/edit`, name: 'Edit' }
                ]}>
                    <ContentLayout>
                        <h2>{i18n.t('customerForm.addLocation')}</h2>
                        <AddressFormContainer
                            customerId={customerId}
                            shippingAddressId={id}
                            onSuccess={this.onSaveSuccess}
                            showSubmitBtn />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerEditShippingAddressPage
