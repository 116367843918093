import React from 'react'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'

import AreasSelectContainer from '../../containers/Areas/AreasSelectContainer'

const OrderMailingAddressForm = ({
    i18n,
    orderMailingAddress,
    errors,
    saving,
    onSubmit
}) => {
    const [form] = Form.useForm()

    const onSubmitForm = (values) => {
        onSubmit(values)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }

    return (
        <Form onFinish={onSubmitForm} form={form}>
            <h2>{i18n.t('addresses:mailingAddress')}</h2>
            <Form.Item {...formItemLayout} label={i18n.t('addresses:name')}
                name='name'
                initialValue={orderMailingAddress.name}
                hasFeedback
                validateStatus={errors && errors.get('name') && 'error'}
                help={errors && errors.get('name')}>
                    <Input/>
            </Form.Item>
            <Form.Item {...formItemLayout} label={i18n.t('addresses:address')}
                name='address'
                initialValue={orderMailingAddress.address}
                hasFeedback
                validateStatus={errors && errors.get('address') && 'error'}
                help={errors && errors.get('address')}>
                    <Input/>
            </Form.Item>
            <Form.Item>
                <AreasSelectContainer form={form}
                    districtFormItemLayout={{
                        labelCol: { md: { span: 6 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    provinceFormItemLayout={{
                        labelCol: { md: { span: 6 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    postalCodeFormItemLayout={{
                        labelCol: { md: { span: 6 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    subDistrictFormItemLayout={{
                        labelCol: { md: { span: 6 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    districtCol={{ md: { span: 24 } }}
                    subDistrictCol={{ md: { span: 24 } }}
                    postalCodeCol={{ md: { span: 24 } }}
                    provinceCol={{ md: { span: 24 } }}
                    showSubDistrict
                    provinceFieldName={`province_id`}
                    districtFieldName={`district_id`}
                    subDistrictFieldName={`sub_district`}
                    postalCodeFieldName={`postal_code`}
                    districtHelp={errors && errors.get('district')}
                    districtValidateStatus={errors && errors.get('district') && 'error'}
                    provinceHelp={errors && errors.get('province')}
                    provinceValidateStatus={errors && errors.get('province') && 'error'}
                    subDistrictHelp={errors && errors.get('sub_district')}
                    subDistrictValidateStatus={errors && errors.get('sub_district') && 'error'}
                    postalCodeHelp={errors && errors.get('postal_code')}
                    postalCodeValidateStatus={errors && errors.get('postal_code') && 'error'}
                    provinceValue={_.get(orderMailingAddress, 'province.id', '')}
                    districtValue={_.get(orderMailingAddress, 'district.id', '')}
                    subDistrictValue={orderMailingAddress.sub_district}
                    postalCodeValue={orderMailingAddress.postal_code} />
            </Form.Item>
            <div style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" size="large" loading={saving}>
                    {i18n.t('addresses:addAddress')}
                </Button>
            </div>
        </Form>
    )
}

export default withTranslation(['addresses'])(OrderMailingAddressForm)

