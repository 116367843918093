import styled from 'styled-components'
const AddressFormWrapper = styled.div`
    .phone-number-input {
        margin: 15px 0;
    }
    .shipping-detail-input {
        margin: 15px 0;
    }
`

export default AddressFormWrapper
