import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Input, Popconfirm, Button, Form } from 'antd'
import { MailOutlined, LoadingOutlined } from '@ant-design/icons'

const SendETaxMailForm = ({
    defaultEmail,
    i18n,
    onSendETaxMailToCustomer,
    eTaxMailSending,
    taxInvoice,
    setEmail,
    emailInput
}) => {
    const [form] = Form.useForm()

    const onSubmit = (e) => {
        e.preventDefault()
        form.validateFields().then(values => {
            const email = values.email
            onSendETaxMailToCustomer(email)
        })
    }

    return (
        <div>
            <h4>{i18n.t('confirms:invoice.sendTaxInvoiceViaEmail')}</h4>
            <Form form={form}>
                <Form.Item style={{ marginBottom: 0 }} name='email'
                    initialValue={defaultEmail || ''}>
                        <Input
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={i18n.t('confirms:invoice.pleaseInputEmail')}/>
                </Form.Item>
                <div style={{ marginTop: '5px' }} key={`tax-invoice-${taxInvoice.id}`}>
                    <Popconfirm
                        title={i18n.t('confirms:invoice.confirmSendEmail',
                            { invoiceNo: taxInvoice.invoice_no, email: emailInput })}
                        onConfirm={onSubmit}
                        okText="Yes"
                        cancelText="No">
                        <Button size="small" type="primary"
                            icon={eTaxMailSending ? <LoadingOutlined /> : <MailOutlined />}
                            loading={eTaxMailSending}
                        >
                            {i18n.t('confirms:invoice.sendTaxInvoiceNo')} {taxInvoice.invoice_no}
                        </Button>
                    </Popconfirm>
                </div>
            </Form>
        </div>
    )
}

SendETaxMailForm.propTypes = {
    taxInvoice: PropTypes.object
}

SendETaxMailForm.defaultProps = {
    taxInvoice: {}
}

export default withTranslation('confirms')(SendETaxMailForm)
