import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {Table, Popconfirm, Button, Modal, Tabs, Avatar, Row, Col, Divider} from 'antd'
import { EditOutlined, UnorderedListOutlined, DeleteOutlined } from '@ant-design/icons'

import JournalEntryFormatsIndexWrapper from './JournalEntryFormatsIndex.style'
import JournalEntryFormatFormContainer from '../../containers/JournalEntryFormats/JournalEntryFormatFormContainer'
const { TabPane } = Tabs;

const JournalEntryFormatsIndex = ({
    onTableChange,
    loading,
    pagination,
    journalEntryFormats,
    i18n,
    toggleJournalEntryFormatForm,
    onDeleteJournalEntryFormat,
    showFormatForm,
    journalEntryFormatId,
    refetch,
    handleFetchItems,
    itemsLoading,
    journalEntryFormatItems
}) => {
    const columns = [{
        key: 'triggerTypeName',
        title: i18n.t('journalEntryFormats:triggerType'),
        dataIndex: 'triggerTypeName'
    },{
        key: 'triggerDateName',
        title: i18n.t('journalEntryFormats:triggerDate'),
        dataIndex: 'triggerDateName'
    },{
        key: 'description',
        title: i18n.t('journalEntryFormats:formatDescription'),
        dataIndex: 'description'
    },
    {
        key: 'journalEntryFormatItems',
        title: i18n.t('journalEntryFormats:formatItems'),
        render: (record) => (
            <div>
                {renderFormatItems(record.journalEntryFormatItems)}
            </div>
        )
    },
    {
        key: 'images',
        render: (record) => (
            <div className="images-container">
                {renderImages(record.images)}
            </div>
        )
    },{
        key: 'actions',
        render: (record) => (
            <div>
                <div onClick={() => toggleJournalEntryFormatForm({ selected: record.id })}>
                    <a><EditOutlined /> {i18n.t('shared:edit')}</a>
                </div>
                <div>
                    <Link to={`/journal_entry_formats/${record.id}/items`}>
                        <UnorderedListOutlined /> Items
                    </Link>
                </div>
                <Popconfirm
                    title={i18n.t('shared:confirmDelete')}
                    onConfirm={() => onDeleteJournalEntryFormat(record.id)}
                >
                    <a className="text-danger"><DeleteOutlined /> {i18n.t('shared:delete')}</a>
                </Popconfirm>
            </div>
        )
    }]

    const renderImages = (images) => {
        return images.map((image) => {
            return (
                <a key={`image_${image.id}`} className="image-thumbnail" href={image.url} target="_blank">
                    <Avatar shape="square" size="large" src={image.thumbUrl} />
                </a>
            )
        })
    }

    const renderFormatItems = (formatItems) => {
        return formatItems.map((formatItem) => {
            return (
                <Row>
                    <Col md={{ span: 4 }}>
                        {formatItem.accountType}
                    </Col>
                    <Col md={{ span: 8 }}>
                        {formatItem.formula}
                    </Col>
                    <Col md={{ span: 8 }}>
                        {formatItem.chartOfAccount && formatItem.chartOfAccount.accountName}
                    </Col>
                    <Divider />
                </Row>
            )
        })
    }

    const paramsKeyAndDescriptionData = [
        {
            key: 'bank_statement_amount',
            title: 'bank_statement_amount',
            description: 'ค่าของ bank_statement_amount จะเป็น bank_statement amount(ยอด)',
        },
        {
            key: 'supplier_order_amount',
            title: 'supplier_order_amount',
            description: 'ค่าของ supplier_order จะเป็น supplier_order amount(ยอด)',
        },
        {
            key: 'order_amount',
            title: 'order_amount',
            description: 'ค่าของ order จะเป็น ราคารวมของ order(ยอดรวม)',
        },
        {
            key: 'order_payment_detail_amount',
            title: 'order_payment_detail_amount',
            description: 'ค่าของ order_payment_detail จะเป็น ราคารวมของ receipt voucher',
        },
        {
            key: 'payment_voucher_amount',
            title: 'payment_voucher_amount',
            description: 'ค่าของ payment_voucher จะเป็น ราคารวมของ payment voucher นั้นๆ',
        },
        {
            key: 'supplier_invoice_amount',
            title: 'supplier_invoice_amount',
            description: 'ค่าของ supplier_invoice_amount จะเป็น ราคารวมของ supplier invoice นั้นๆ',
        },
        {
            key: 'order_transfer_back_amount',
            title: 'order_transfer_back_amount',
            description: 'ค่าของ order_transfer_back_amount จะเป็น ราคารวมของ การคืนของในออร์เดอร์ นั้นๆ',
        },
        {
            key: 'truck_reservation_amount',
            title: 'truck_reservation_amount',
            description: 'ค่าของ truck_reservation_amount จะเป็น cost ของ truck reservation นั้นๆ',
        },
        {
            key: 'customer_wallet_amount',
            title: 'customer_wallet_amount',
            description: 'ค่าของ customer_wallet_amount จะเป็น amount ของ wallet นั้นๆ',
        },
        {
            key: 'credit_card_rate',
            title: 'credit_card_rate',
            description: 'ค่าของ credit_card_rate จะเป็น credit_card_rate ของ ออร์เดอร์นั้นๆ',
        }
    ]

    const paramsKeyAndDescription = [{
        key: 'key',
        title: i18n.t('journalEntryFormats:key'),
        dataIndex: 'key',
    },{
        key: 'description',
        title: i18n.t('journalEntryFormats:description'),
        dataIndex: 'description',
    }]

    return (
        <JournalEntryFormatsIndexWrapper>
            <Tabs defaultActiveKey="1">
                <TabPane tab={i18n.t('journalEntryFormats:title')} key="1">
                    <div className="journal-entry-formats-index-header">
                        <h2>{i18n.t('journalEntryFormats:title')}</h2>
                        <Button type="primary"
                                onClick={toggleJournalEntryFormatForm}>
                            + {i18n.t('shared:add')}
                        </Button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={journalEntryFormats}
                        loading={loading}
                        onChange={onTableChange}
                        pagination={pagination}
                        rowKey="id"
                    />
                    <Modal
                        visible={showFormatForm}
                        onCancel={toggleJournalEntryFormatForm}
                        footer={null}
                        destroyOnClose>
                        <JournalEntryFormatFormContainer
                            onSuccess={refetch}
                            toggleForm={toggleJournalEntryFormatForm}
                            journalEntryFormatId={journalEntryFormatId} />
                    </Modal>
                </TabPane>
                <TabPane tab={i18n.t('journalEntryFormats:key')} key="2">
                    <Table
                        columns={paramsKeyAndDescription}
                        dataSource={paramsKeyAndDescriptionData}
                        rowKey="paramsId"
                    />
                </TabPane>
            </Tabs>
        </JournalEntryFormatsIndexWrapper>
    )
}

JournalEntryFormatsIndex.propTypes = {
    columns: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    journalEntryFormats: PropTypes.array
}

JournalEntryFormatsIndex.defaultProps = {
    onTableChange: () => {},
}

export default withTranslation('journalEntryFormats')(JournalEntryFormatsIndex)
