import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SupplierOverTransferAccountListContainer from '../Suppliers/SupplierOverTransferAccountListContainer'

import actions from '../../redux/suppliers/overTransferItems/actions'

const { fetchOrderSupplierOverTransferItems } = actions

class OrderSupplierOverTransferAccountListContainer extends Component {
    static propTypes = {
        // Props from another component
        orderId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchOrderSupplierOverTransferItems, orderId } = this.props

        fetchOrderSupplierOverTransferItems({ orderId })
    }

    render () {
        const { orderId } = this.props

        return (
            <SupplierOverTransferAccountListContainer
                dataSourceFrom="order"
                orderId={orderId}
            />
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderSupplierOverTransferItems
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSupplierOverTransferAccountListContainer)
