import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch, apiFileFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'
import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'

const { setNotifications } = actionsNotification

import {
    orderTransferBackSchema,
    orderTransferBacksSchema,
    orderTransferBackItemsSchema,
    shippingCostSchema,
} from '../../schema'

const {
  ORDER_TRANSFER_BACKS_FETCH_REQUEST,
  ORDER_TRANSFER_BACKS_FETCH,
  ORDER_TRANSFER_BACKS_FETCH_SUCCESS,

  ORDER_TRANSFER_BACK_FETCH_REQUEST,
  ORDER_TRANSFER_BACK_FETCH,
  ORDER_TRANSFER_BACK_FETCH_SUCCESS,

  ORDER_TRANSFER_BACK_FETCH_ITEMS_REQUEST,
  ORDER_TRANSFER_BACK_FETCH_ITEMS,
  ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS,

  ORDER_TRANSFER_BACK_SAVE_REQUEST,
  ORDER_TRANSFER_BACK_SAVE,
  ORDER_TRANSFER_BACK_SAVE_SUCCESS,
  ORDER_TRANSFER_BACK_SAVE_ERRORS,

  ORDER_TRANSFER_BACK_CALCULATE_TOTAL_REQUEST,
  ORDER_TRANSFER_BACK_CALCULATE_TOTAL,
  ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS,

  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_REQUEST,
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE,
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS,
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS,

  ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_REQUEST,
  ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING,

  ORDER_TRANSFER_BACK_DELETE_REQUEST,
  ORDER_TRANSFER_BACK_DELETE_SUCCESS,
} = actions

export function *receiveOrderTransferBacks () {
  yield takeEvery(ORDER_TRANSFER_BACKS_FETCH_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACKS_FETCH })

    const { orderId, onSuccess, onError } = payload

    try {
      const data = yield call(apiFetch, `/orders/${orderId}/transfer_backs`)

      const formattedData = deserialize(data)
      const normalizeData = normalize(formattedData, orderTransferBacksSchema)

      yield put({
          type: ORDER_TRANSFER_BACKS_FETCH_SUCCESS,
          ...normalizeData
      })

      onSuccess()
    } catch (error) {
        onError(error)
    }
  })
}

export function *receiveOrderTransferBack () {
  yield takeEvery(ORDER_TRANSFER_BACK_FETCH_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACK_FETCH })

    const { orderId, id, onSuccess, onError } = payload

    try {
      const data = yield call(apiFetch, `/orders/${orderId}/transfer_backs/${id}`)

      const formattedData = deserialize(data)
      const normalizeData = normalize(formattedData, orderTransferBackSchema)

      yield put({
          type: ORDER_TRANSFER_BACK_FETCH_SUCCESS,
          ...normalizeData
      })

      onSuccess()
  } catch (error) {
      onError(error)
  }
  })
}

export function *receiveOrderTransferBackItems () {
  yield takeEvery(ORDER_TRANSFER_BACK_FETCH_ITEMS_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACK_FETCH_ITEMS })

    const { orderId, id, onSuccess, onError } = payload

    try {
      const data = yield call(apiFetch, `/orders/${orderId}/transfer_backs/${id}/items`)

      const formattedData = deserialize(data)
      const normalizeData = normalize(formattedData, orderTransferBackItemsSchema)

      yield put({
          type: ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS,
          ...normalizeData
      })

      onSuccess()
  } catch (error) {
      onError(error)
  }
  })
}

export function *submitOrderTransferBack () {
  yield takeLatest(ORDER_TRANSFER_BACK_SAVE_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACK_SAVE })

    const { orderId, id, params, onSuccess, onError } = payload

    try {
      const path = id ? `/orders/${orderId}/transfer_backs/${id}` : `/orders/${orderId}/transfer_backs`
      const method = id ? 'PATCH' : 'POST'

      const body = JSON.stringify({ order_transfer_back: params })
      const data = yield call(apiFetch, path, method, { body })

      if ('error' in data) {
          yield put({
              type: ORDER_TRANSFER_BACK_SAVE_ERRORS,
              formErrors: data.error_description
          })
      } else {
          const formattedData = deserialize(data)

          yield put({
              type: ORDER_TRANSFER_BACK_SAVE_SUCCESS,
              ...normalize(formattedData, orderTransferBackSchema)
          })

          onSuccess()
      }
    } catch (error) {
        onError(error)
    }
  })
}

export function *culculateTransferBackTotal () {
  yield takeEvery(ORDER_TRANSFER_BACK_CALCULATE_TOTAL_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACK_CALCULATE_TOTAL })

    const { orderId, id, onSuccess, onError } = payload

    try {
      const path = `/orders/${orderId}/transfer_backs/${id}/calculate_transfer_back_total`

      const data = yield call(apiFetch, path, 'POST')
      const formattedData = deserialize(data)

      yield put({
          type: ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS,
          ...normalize(formattedData, orderTransferBackSchema)
      })

      onSuccess()
    } catch (error) {
        onError(error)
    }
  })
}

export function *submitShippingCostTransferBack () {
  yield takeLatest(ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE })

    const { orderId, transferBackId, id, params, onSuccess, onError } = payload

    try {
      const defaultPath = `/orders/${orderId}/transfer_backs/${transferBackId}/shipping_costs`
      const path = id ? `${defaultPath}/${id}` : defaultPath
      const method = id ? 'PATCH' : 'POST'

      const body = JSON.stringify({ shipping_cost: params })
      const data = yield call(apiFetch, path, method, { body })

      if ('error' in data) {
          yield put({
              type: ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS,
              formErrors: data.error_description
          })
      } else {
          const formattedData = deserialize(data)

          yield put({
              type: ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS,
              ...normalize(formattedData, shippingCostSchema)
          })

          onSuccess()
      }
    } catch (error) {
        onError(error)
    }
  })
}

export function *exportOrderTransferBacksPDFRequest () {
  yield takeLatest(ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_REQUEST, function *({ payload }) {
      yield put({ type: ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING, loading: true })
      const { orderId, id } = payload
      const path = `/orders/${orderId}/transfer_backs/${id}/documents`

      try {
          const data = yield call(apiFileFetch, path)
          const fileURL = URL.createObjectURL(data)
          window.open(fileURL)
      } catch (error) {
          const errorName = error.name
          const errorMessage = error.message
          yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
      } finally {
          yield put({ type: ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING, loading: false })
      }
  })
}

export function *deleteOrderTransferBack () {
    yield takeLatest(ORDER_TRANSFER_BACK_DELETE_REQUEST, function *({ payload }) {
        const orderId = payload.orderId
        const id = payload.id
        const path = `/orders/${orderId}/transfer_backs/${id}`
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: ORDER_TRANSFER_BACK_DELETE_SUCCESS,
                ...normalize(formattedData, orderTransferBackSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {

        }
    })
}

export default function *rootSaga () {
  yield all([
    fork(culculateTransferBackTotal),
    fork(receiveOrderTransferBack),
    fork(receiveOrderTransferBackItems),
    fork(receiveOrderTransferBacks),
    fork(submitOrderTransferBack),
    fork(submitShippingCostTransferBack),
    fork(exportOrderTransferBacksPDFRequest),
    fork(deleteOrderTransferBack)
  ])
}
