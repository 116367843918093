import React from 'react'

import Layout from '../../containers/App/layout'
import ContentLayout from '../utility/layoutContent'
import LayoutContentWrapper from '../utility/layoutWrapper'

import { Tabs } from 'antd'
import Render from './render'
import DealCategory from './Category'
import SaleMarginReports from './SaleMarginReports'

const { TabPane } = Tabs

const DealsBoard = () => {
  return (
    <Layout>
      <LayoutContentWrapper>
        <ContentLayout>
          <Tabs defaultActiveKey="dashboard">
            <TabPane tab="DashBoard" key="dashboard">
              <Render />
            </TabPane>
            <TabPane tab="Follow Up" key="follow_up">
              Content of Tab Pane 2
            </TabPane>
            <TabPane tab="Category" key="category">
              <DealCategory />
            </TabPane>
            <TabPane tab="Report" key="report">
              <SaleMarginReports />
            </TabPane>
          </Tabs>

        </ContentLayout>
      </LayoutContentWrapper>
    </Layout>
  )
}

export default DealsBoard
