import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import { Form, Button, Input, DatePicker, Select } from 'antd'
import moment from 'moment'
import _ from 'lodash'

import { TRANSFER_BACK_BANK_ACCOUNTS } from '../../constants/supplierOrders'
import { CUSTOMER_WALLET_TRANSACTION_TYPE } from '../../constants/customers'

const handleSubmit = (values, props) => {
    const { onSubmitCustomerWallet } = props

    onSubmitCustomerWallet({
        values
    })
}

const onTransactionTypeChange = (value, props) => {
    const { setShowAmountInput, setShowBankDetailInput } = props

    if (value === 'deposit') {
        setShowAmountInput(false)
        setShowBankDetailInput(false)
    } else if (value === 'transfer_back') {
        setShowBankDetailInput(true)
        setShowAmountInput(true)
    } else {
        setShowAmountInput(true)
        setShowBankDetailInput(false)
    }
}

const transctionTypes = (i18n) => {
    const { Option } = Select

    return CUSTOMER_WALLET_TRANSACTION_TYPE.map((transctionType) => {
        return (
            <Option key={transctionType.value}
                value={transctionType.value}>
                {i18n.t(`customers/wallets:transactionTypes.${transctionType.i18n}`)}
            </Option>
        )
    })
}

const renderSelectTransactionTypes = (i18n, props) => {
    return (
        <Select style={{ width: '100%' }} onChange={(value) => onTransactionTypeChange(value, props)}>
            {transctionTypes(i18n)}
        </Select>
    )
}

const handleFilterOrderOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const selectOrderNumber = (orders) => {
    if (!orders) { return null }
    return orders.map((order, index) => {
        return (
            <Select.Option key={index} value={order}>
                {order}
            </Select.Option>
        )
    })
}

const transferBackBankAccount = () => {
    const { Option } = Select

    return TRANSFER_BACK_BANK_ACCOUNTS.map((bankAccount) => {
        return (
            <Option key={bankAccount.value}
                value={bankAccount.value}>
                {bankAccount.i18n}
            </Option>
        )
    })
}

const renderBankAccountDetail = (form, props) => {
    const { customerWallet, formErrors, i18n } = props
    const errors = formErrors.toJS()
    let nestedAttributes = ''

    if (form.getFieldValue('order_id')) {
        nestedAttributes = ['supplier_order_attributes', 'bank_account_attributes']
    } else {
        nestedAttributes = ["bank_account_attributes"]
    }

    const bankAccountError = _.get(errors, 'bank_account', _.get(errors, 'supplier_order.bank_account', null))
    const nameError = bankAccountError || _.get(errors, 'bank_account.name', _.get(errors, 'supplier_order.bank_account.name', null))
    const numberError = bankAccountError || _.get(errors, 'bank_account.number', _.get(errors, 'supplier_order.bank_account.number', null))
    const bankNameError = bankAccountError || _.get(errors, 'bank_account.bank_name', _.get(errors, 'supplier_order.bank_account.bank_name', null))

    return (
        <div>
            <h3>{i18n.t('suppliers:transferForm.title')}</h3>
            <Form.Item label={i18n.t('suppliers:transferForm.bankName')}
                name={nestedAttributes.concat('bank_name')}
                initialValue={customerWallet.getIn(['supplier_order_bank_account', 'bank_name'])}
                validateStatus={bankNameError ? 'error' : null}
                help={bankNameError && bankNameError.join(', ')}>
                    <Select allowClear style={{ width: '100%' }}
                        placeholder={i18n.t('suppliers:transferForm.selectBankName')}>
                        {transferBackBankAccount()}
                    </Select>
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.name')}
                name={nestedAttributes.concat('name')}
                initialValue={customerWallet.getIn(['supplier_order_bank_account', 'name'])}
                validateStatus={nameError ? 'error' : null}
                help={nameError && nameError.join(', ')}>
                    <Input placeholder={i18n.t('suppliers:transferForm.name')} />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.number')}
                name={nestedAttributes.concat('number')}
                initialValue={customerWallet.getIn(['supplier_order_bank_account', 'number'])}
                validateStatus={numberError ? 'error' : null}
                help={numberError && numberError.join(', ')}>
                    <Input placeholder={i18n.t('suppliers:transferForm.number')} />
            </Form.Item>
        </div>
    )
}

const CustomerWalletForm = props => {
    const {
        i18n,
        saving,
        customerWallet,
        orders,
        isEdit,
        isOrderForm,
        formErrors,
        showAmountInput,
        showBankDetailInput
    } = props

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    }
    moment.locale(i18n.language)
    const date = customerWallet.get('date')
    const dateError = formErrors.get('date')
    const orderError = formErrors.get('order')
    const amountError = formErrors.get('supplier_order.grand_total')
    const transactionType = customerWallet.get('transaction_type')
    const showAmountForm = showAmountInput && transactionType !== 'deposit'

    return (
        <div>
            <h3>{i18n.t('customers/wallets:addWallet')}</h3>
            <Form onFinish={(values) => handleSubmit(values, props)} {...layout} form={form}>
                <Form.Item label={i18n.t('customers/wallets:date')}
                    name="date"
                    initialValue={date && moment(date)}
                    validateStatus={dateError ? 'error' : null}
                    help={dateError && dateError.join(', ')}>
                        <DatePicker
                            className="w-100"
                            placeholder="select date" />
                </Form.Item>

                <Form.Item label={i18n.t('customers/wallets:transactionType')}
                    name="transaction_type"
                    initialValue={customerWallet.get('transaction_type') || 'deposit'}>
                        {renderSelectTransactionTypes(i18n, props)}
                </Form.Item>
                { showAmountForm || !_.isEmpty(customerWallet.toJS()) ?
                    <Form.Item label={i18n.t('customers/wallets:amount')}
                        validateStatus={amountError ? 'error' : null}
                        name="amount"
                        initialValue={customerWallet && customerWallet.getIn(['amount', 'raw'])}
                        help={amountError && amountError.join(', ')}>
                            <Input />
                    </Form.Item> : null}
                <Form.Item label={i18n.t('customers/wallets:order')}
                    name='order_id'
                    initialValue={customerWallet.get('order_number')}
                    validateStatus={orderError ? 'error' : null}
                    help={orderError && orderError.join(', ')}>
                        <Select
                            showSearch
                            disabled={(isEdit && transactionType === 'transfer_back') || isOrderForm}
                            placeholder={i18n.t('customers/wallets:selectOrder')}
                            filterOption={(input, option) => handleFilterOrderOption(input, option)}
                        >
                            {selectOrderNumber(orders)}
                        </Select>
                </Form.Item>
                <Form.Item label={i18n.t('customers/wallets:note')}
                    name='note'
                    initialValue={customerWallet.get('note')}>
                        <Input.TextArea />
                </Form.Item>
                {(transactionType === 'transfer_back' || showBankDetailInput) && renderBankAccountDetail(form, props)}
                <div style={{ textAlign: 'center' }}>
                    <Button loading={saving} htmlType="submit" type="primary">
                        {i18n.t('shared:save')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

CustomerWalletForm.propTypes = {
    saving: PropTypes.bool,
    isEdit: PropTypes.bool,
    isOrderForm: PropTypes.bool,
    formErrors: ImmutablePropTypes.map,
    onSubmitCustomerWallet: PropTypes.func.isRequired
}

CustomerWalletForm.defaultProps = {
    onSubmitCustomerWallet: () => {},
    customerWallet: new Map(),
    formErrors: new Map(),
    isEdit: false,
    isOrderForm: false,
    orders: []
}

export default withTranslation(['shared', 'suppliers', 'customers/wallets'])(CustomerWalletForm)
