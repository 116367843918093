import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import { Button, Modal, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'

import CompanyProjectFormContainer from '../../../containers/Customers/CompanyProjects/CustomerCompanyProjectFormContainer'

const CustomerCompanyProjectsIndex = ({
    i18n,
    activeProject,
    customerId,
    loading,
    onPageChange,
    pagination,
    projects,
    showCompanyProjectForm,
    toggleCompanyProjectForm,
    onSuccess,
    onError
}) => {
    const columns = [
        {
            title: i18n.t('projects:project'),
            dataIndex: 'name'
        },
        {
            title: i18n.t('projects:province'),
            dataIndex: 'province_name'
        },
        {
            title: i18n.t('projects:district'),
            dataIndex: 'district_name'
        },
        {
            render: (record) => {
                return (
                    <div>
                        <a onClick={() => toggleCompanyProjectForm({ id: record.id })}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            }
        }
    ]

    return (
        <div>
            <div style={{ textAlign: 'end' }}>
                <Button type="primary" onClick={toggleCompanyProjectForm}>
                    {i18n.t('projects:add')}
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={projects}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
            <Modal
                visible={showCompanyProjectForm}
                onCancel={toggleCompanyProjectForm}
                footer={null}
                destroyOnClose
            >
                <CompanyProjectFormContainer
                    id={activeProject}
                    customerId={customerId}
                    hideSelectCompany
                    companyProject={_.find(projects, ['id', activeProject])}
                    onSuccess={() => onSuccess()}
                    onError={() => onError()}
                    toggleForm={toggleCompanyProjectForm}
                />
            </Modal>
        </div>
    )
}

CustomerCompanyProjectsIndex.propTypes = {
    activeProject: PropTypes.string,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    projects: PropTypes.array,
    showCompanyProjectForm: PropTypes.bool,
    toggleCompanyProjectForm: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    onError: PropTypes.func
}

CustomerCompanyProjectsIndex.defaultProps = {
    loading: false,
    projects: [],
    showCompanyProjectForm: false,
    onSuccess: () => {},
    onError: () => {}
}

export default withTranslation('projects')(CustomerCompanyProjectsIndex)
