import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'

import { Tag } from 'antd'
import { Link } from 'react-router-dom'

class CardReservation extends Component {
    static propTypes = {
        onToggle: PropTypes.func.isRequired,
        setUpdatingId: PropTypes.func.isRequired,
        truckReservations: ImmutablePropTypes.list.isRequired
    }

    static defaultProps = {
        onToggle: () => {}
    }

    renderReservationTag () {
        const { truckReservations, onToggle, setUpdatingId, i18n } = this.props

        return truckReservations.map((truckReservation) => {
            const reservationDate = truckReservation.get('date', '')
            const truckOwner = truckReservation.get('truck_owner_name', '')
            const truckOwnerPhoneNumber = truckReservation.get('truck_owner_phone_number', '')
            const licensePlate = truckReservation.get('truck_dispatch_license_plate', '')
            const allowUpdate = truckReservation.get('allow_update?', false)
            const reservationTruck = truckReservation.get('reservation_truck', '')
            const isDelivery = truckReservation.get('is_delivery', false)
            const reservationType = truckReservation.get('reservation_type', null)
            const tagColor = allowUpdate ? 'blue' : ''

            return (
                <Link key={`reservation-${truckReservation.get('id')}`} onClick={() => {
                    onToggle(allowUpdate, truckReservation.get('shipping_cost_id'))
                    setUpdatingId(truckReservation.get('id'))
                }}>
                    <Tag color={tagColor}>
                        <div>{isDelivery ? `(${i18n.t('inquiries:isDelivery')})` : null}</div>
                        <div>{reservationType === 'cancel' ? `(${i18n.t('inquiries:reservationCancel')})` : null}</div>
                        <div>{reservationTruck}</div>
                        <div>{reservationDate}</div>
                        <div>{truckOwner}</div>
                        <div>{truckOwnerPhoneNumber}</div>
                        <div>{licensePlate}</div>
                    </Tag>
                </Link>
            )
        })
    }

    render () {
        return (
            <div className="card-reservation-components">
                {this.renderReservationTag()}
            </div>
        )
    }
}

export default withTranslation('inquiries')(CardReservation)
