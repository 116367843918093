import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import SearchSale from '../../components/searchSale'

import orderActions from '../../redux/orders/actions'

const {
    searchSales
} = orderActions

const noValueProp = 'NO_VALUE_PROP'

class SearchSaleContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner Props
        orders: ImmutablePropTypes.map.isRequired,
        saleEntities: ImmutablePropTypes.map.isRequired,
        searchSales: PropTypes.func.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: noValueProp
    }

    constructor (props) {
        super(props)

        this.state = {
            value: this.hasValueProp() ? props.value : ''
        }
    }

    hasValueProp () {
        const { value } = this.props
        return !_.isEqual(value, noValueProp)
    }

    getSales () {
        const { orders, saleEntities, value } = this.props

        const sales = orders.get('saleItems', new List()).filter((id) => {
            return _.toString(id) !== _.toString(value)
        }).map((id) => {
            return saleEntities.get(_.toString(id), new Map())
        })

        return sales.push(saleEntities.get(_.toString(value), new Map()))
    }

    onChange = (value) => {
        const { onChange } = this.props

        this.setState({ value })
        onChange(value)
    }

    onSearch = (query) => {
        const { searchSales } = this.props

        searchSales(query)
    }

    render () {
        const { orders, value: propValue } = this.props
        const { value: stateValue } = this.state

        return (
            <SearchSale
                loading={orders.get('salesLoading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                sales={this.getSales().toJS()}
                value={this.hasValueProp() ? propValue : stateValue}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.get('orders'),
        saleEntities: state.getIn(['Entities', 'sales'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchSales
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchSaleContainer)
