import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import { Table } from 'antd'


const ItemList = ({
    i18n,
    items,
    loading,
    onPageChange,
    pagination,
    onSelectChange,
    selectedItemIds
}) => {
    const columns = [
        {
            key: 'name',
            title: i18n.t('inquiries:name'),
            dataIndex: 'name'
        },
        {
            key: 'price',
            title: i18n.t('price'),
            dataIndex: 'price'
        },
        {
            title: i18n.t('cost'),
            dataIndex: 'cost_of_goods_sold'
        },
        {
            key: 'unit',
            title: i18n.t('unit'),
            dataIndex: 'unit'
        },
    ]

    const rowSelection = {
        selectedRowKeys: selectedItemIds,
        onChange: onSelectChange
    }

    return (
        <Table
            columns={columns}
            size='middle'
            dataSource={items}
            loading={loading}
            onChange={onPageChange}
            pagination={pagination}
            rowKey={record => record.id}
            rowSelection={rowSelection}
        />
    )
}

ItemList.propTypes = {
    items: PropTypes.array,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    loading: PropTypes.bool,
}

ItemList.defaultProps = {
    items: [],
    loading: false,
    pagination: false
}

export default withTranslation(['inquiries'])(ItemList)
