import styled from 'styled-components'
const ShippingRateWrapper = styled.div`
  .shipping-rates-header {
    display: flex;
    justify-content: space-between;

    .shipping-rates-filters {
      display: flex;
  
      .shipping-rates-filter-location {
        width: 300px;
        display: flex;
  
        .label {
          width: 100px;
          align-self: center;
        }
      }
    }
  }

  .margin-right-12 {
    margin-right: 12px;
  }

  .margin-bottom-12 {
    margin-bottom: 12px;
  }
`

export default ShippingRateWrapper
