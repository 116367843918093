import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Button, Card, Form, Row } from 'antd'
import StyledWrapper from './Form.style'
import { PlusOutlined } from '@ant-design/icons'
import FieldForm from './FieldForm'

const MUTATION = gql`
  mutation UpdateCompanyProjectOrders($orderId: String!, $params: AdminOrderInput!) {
    updateOrder(orderId: $orderId, orderParams: $params) {
      order {
        id
        companyProjectOrders {
          id
          companyProject {
            id
            name
          }
          contact {
            id
            name
          }
        }
      }
      errors
    }
  }
`

const getInitialValues = source => {
  if (!source.length) return []

  return {
    companyProjectOrdersAttributes: source.map(({ id, companyProject, contact }) => ({
      id: id,
      companyProjectId: companyProject.id,
      contactId: contact && contact.id
    }))
  }
}

const renderProjectForm = (field, form, remove) => {
  return (
    <FieldForm
      key={field.key}
      field={field}
      form={form}
      name="companyProjectOrdersAttributes"
      remove={remove} />
  )
}

const renderProjectList = (fields, { add, remove }, form, i18n) => {
  return (
    <Fragment>
      {fields.map(field => renderProjectForm(field, form, remove))}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
          {i18n.t('projects:addProject')}
        </Button>
      </Form.Item>
    </Fragment>
  )
}

const CompanyProjectsForm = ({
  orderId,
  source,
  onClose,
}) => {
  const { i18n } = useTranslation(['inquiries', 'projects'])
  const [form] = Form.useForm()

  const [updateOrder, { loading: saving }] = useMutation(MUTATION, {
    onCompleted: ({ updateOrder: { errors } }) => {
      if (!errors) {
        onClose()
      }
    }
  })

  const onSubmitForm = values => {
    const { companyProjectOrdersAttributes: attributes } = values
    const destroyList = source.map(data => {
      if (attributes.find(value => value.id === data.id)) return null

      return { id: data.id, _destroy: true }
    }).filter(Boolean)

    updateOrder({
      variables: {
        orderId,
        params: {
          ...values,
          companyProjectOrdersAttributes: [...attributes, ...destroyList]
        }
      }
    })
  }

  return (
    <StyledWrapper>
      <Form
        form={form}
        initialValues={getInitialValues(source)}
        onFinish={onSubmitForm}>
        <Card size='small'>
          <Form.List
            children={(fields, operation) => renderProjectList(fields, operation, form, i18n)}
            name="companyProjectOrdersAttributes" />

          <Row justify="center">
            <Button style={{ width: 60, marginRight: '8px' }}
              loading={saving}
              type="primary"
              htmlType="submit"
              size="small">
                  {i18n.t('inquiries:edit')}
            </Button>
            <Button style={{ width: 60 }}
              disabled={saving}
              onClick={onClose}
              size="small">
              {i18n.t('inquiries:cancel')}
            </Button>
            </Row>
        </Card>
      </Form>
    </StyledWrapper>
  )
}

CompanyProjectsForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  source: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired
}

CompanyProjectsForm.defaultProps = {}

export default CompanyProjectsForm
