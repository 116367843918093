import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Divider } from 'antd'

import customerActions from '../../../redux/customers/actions'
import customerNoteActions from '../../../redux/customers/customerNotes/actions'

import CustomerSheet from '../../../components/customers/CustomerSheet'
import CustomerNoteForm from '../../../components/customers/customerNote/customerNoteForm'
import CustomerNoteContainer from '../../../containers/Customers/CustomerNote/CustomerNoteContainer'

const { fetchCustomerOrders } = customerActions

const { saveCustomerNote } = customerNoteActions

class CustomerNoteFormContainer extends Component {
    componentDidMount () {
        const { fetchCustomerOrders, customerId } = this.props
        fetchCustomerOrders(customerId)
    }

    getOrders () {
        const { Customers } = this.props

        return Customers.get('orders')
    }

    handleSubmitForm = (customerNote) => {
        const { saveCustomerNote, customerId } = this.props

        saveCustomerNote(customerNote, customerId)
    }

    render () {
        const { customerNotes, customerId, i18n } = this.props

        if (!customerId) { return {} }

        const stateSaving = customerNotes.get('saving')

        return (
            <div>
                <CustomerSheet id={customerId} />
                <Divider />
                <h1> {i18n.t('customerForm.orderNote')} </h1>
                <CustomerNoteForm
                    stateSaving={stateSaving}
                    loading={this.props.Customers.get('loading')}
                    handleSubmitNote={this.handleSubmitForm}
                    selectOrder="flex"
                    orders={this.getOrders().toJS()}
                />
                <CustomerNoteContainer customerId={customerId}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    Customers: state.get('customers'),
    Entities: state.get('Entities'),
    customerNotes: state.get('customerNotes')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerOrders,
        saveCustomerNote
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('common')(CustomerNoteFormContainer)
)
