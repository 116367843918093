import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'
import OrderSummariesStatisticsContainer from '../../containers/OrderSummaries/StatisticsContainer'
import OrderSummariesContainer from '../../containers/OrderSummaries/OrderSummariesContainer'

import OrderSummariesDashboardWrapper from './orderSummariesDashboard.style'

const OrderSummariesDashboard = ({
	showStatistics,
	toggleStatistics
}) => {
	const { i18n } = useTranslation(['orders', 'statistics'])

	const renderDisplayStatistics = () => {
		return (
			<div className="margin-bottom-8">
				<Button className="padding-0" type="link" onClick={toggleStatistics}>
					{i18n.t(`statistics:orderSummariesDashboard.${showStatistics ? 'hideStatistics' : 'showStatistics'}`)}
				</Button>
				{showStatistics && <OrderSummariesStatisticsContainer disableChangeType={true} />}
			</div>
		)
	}

	return (
		<OrderSummariesDashboardWrapper>
			{renderDisplayStatistics()}
			<OrderSummariesContainer />
		</OrderSummariesDashboardWrapper>
	)
}

OrderSummariesDashboard.propTypes = {
	showStatistics: PropTypes.bool,
	toggleStatistics: PropTypes.func.isRequired
}

OrderSummariesDashboard.defaultProps = {}

export default OrderSummariesDashboard
