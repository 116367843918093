import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const PICKUP_POINTS_QUERY = gql`
    query VehicleType($id: ID!) {
        vehicleType(id: $id) {
            id
            pickupPoints(limit: -1) {
                id
                province {
                    id
                    name
                }
            }
        }
    }
`

const renderOptions = options => options.map(({ id, province }) => {
    return <Select.Option value={id}>{province.name}</Select.Option>
})

const PickupPointSelector = ({ vehicleTypeId, ...restProps }) => {
    const { loading, data, error } = useQuery(PICKUP_POINTS_QUERY, {
        skip: !vehicleTypeId,
        variables: { id: vehicleTypeId }
    })

    if (error) return console.error(error)

    const options = (data && data.vehicleType && data.vehicleType.pickupPoints) || []

    return (
        <Select
            allowClear
            disabled={!vehicleTypeId}
            filterOption={false}
            loading={loading}
            {...restProps}
        >
            {renderOptions(options)}
        </Select>
    )
}

PickupPointSelector.propTypes = {
    vehicleTypeId: PropTypes.string
}

PickupPointSelector.defaultProps = {}

export default PickupPointSelector
