import { all, takeEvery, takeLatest, put, call, fork, select } from 'redux-saga/effects'
import { apiFetch, apiFileFetch, apiPostForm } from '../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../redux/notifications/actions'
import actionsInquiry from './actions'
import customerReviewActions from './reviews/actions'
// import actionsOrder from '../orders/actions'
// import Router from 'next/router'

import i18n from 'i18next'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { PAGE_SIZE } from '../../constants/orders'

import {
    orderSchema,
    customerSchema,
    lineItemSchema,
    lineItemsSchema,
    itemsSchema,
    bankAccountsSchema,
    vehicleTypesSchema,
    // ordersSchema,
    // sourcingProfileSchema,
    paymentOptionsSchema
} from '../schema'
import downLoadFile from '../../helpers/downLoadFile'

const { setNotifications } = actionsNotification
const { fetchInquiryLineItems } = actionsInquiry
const { fetchReviews } = customerReviewActions
// const { fetchOrder } = actionsOrder

const {
    FETCH_INQUIRY_REQUEST,
    FETCHING_INQUIRY,
    FETCH_INQUIRY_SUCCESS,
    FETCHING_INQUIRY_CUSTOMER,
    FETCH_INQUIRY_CUSTOMER_REQUEST,
    FETCH_INQUIRY_CUSTOMER_SUCCESS,
    FETCH_INQUIRY_LINE_ITEMS_REQUEST,
    FETCHING_INQUIRY_LINE_ITEMS,
    FETCH_INQUIRY_LINE_ITEMS_SUCCESS,
    FETCH_INQUIRY_ITEMS_REQUEST,
    FETCHING_INQUIRY_ITEMS,
    FETCH_INQUIRY_ITEMS_SUCCESS,
    INQUIRY_SET_ITEMS_PAGINATION,
    // FETCH_INQUIRY_QUOTATION_PDF_REQUEST,
    // FETCHING_INQUIRY_QUOTATION_PDF,
    // FETCH_INQUIRY_QUOTATION_PDF_SUCCESS,
    SET_INQUIRY_ITEM_SEARCH_QUERY,
    SET_INQUIRY_ITEM_CATEGORY,
    SET_INQUIRY_ITEM_SUB_CATEGORY,
    SET_INQUIRY_ITEM_PRODUCT_TYPE,
    FETCH_BANK_ACCOUNTS_REQUEST,
    FETCH_BANK_ACCOUNTS,
    FETCH_BANK_ACCOUNTS_SUCCESS,
    FETCH_PAYMENT_OPTIONS_REQUEST,
    FETCH_PAYMENT_OPTIONS,
    FETCH_PAYMENT_OPTIONS_SUCCESS,
    SAVE_INQUIRY_REQUEST,
    SAVE_INQUIRY,
    SAVE_INQUIRY_SUCCESS,
    SAVE_INQUIRY_ERRORS,
    // ADD_LINE_ITEM_REQUEST,
    // ADD_LINE_ITEM_SAVING,
    // ADD_LINE_ITEM_SUCCESS,
    FETCH_VEHICLE_TYPES_REQUEST,
    FETCH_VEHICLE_TYPES,
    FETCH_VEHICLE_TYPES_SUCCESS,
    SET_INQUIRY_SHIPPING_COST_AMOUNT,
    SET_INQUIRY_CONDITIONS_AMOUNT,
    SAVE_INQUIRY_LINE_ITEMS_REQUEST,
    SET_SAVING_INQUIRY_LINE_ITEMS,
    SAVE_INQUIRY_LINE_ITEMS_SUCCESS,
    SAVE_INQUIRY_LINE_ITEMS_ERRORS,
    SET_SHOW_ADD_ITEM_FORM,
    DELETE_INQUIRY_LINE_ITEMS_REQUEST,
    DELETE_INQUIRY_LINE_ITEMS,
    DELETE_INQUIRY_LINE_ITEMS_SUCCESS,
    SET_POSITION_INQUIRY_LINE_ITEMS_REQUEST,
    SET_POSITION_INQUIRY_LINE_ITEMS,
    SET_POSITION_INQUIRY_LINE_ITEMS_SUCCESS,
    FETCH_POSITION_INQUIRY_LINE_ITEMS,
    SET_LOAD_INQUIRY_LINE_ITEMS,
    DUPLICATE_ORDER_REQUEST,
    DUPLICATE_ORDER_SAVING,
    SET_INQUIRY_LINE_ITEMS_PAGINATION,
    FETCH_ITEM_BY_NAME_REQUEST,
    FETCH_ITEM_BY_NAME_SUCCESS,
    SET_ITEM_LOADING,
    SAVE_LINE_ITEM_CLIPBOARD_REQUEST,
    // FETCH_ITEM_PRICE_HISTORY_REQUEST,
    // FETCH_ITEM_PRICE_HISTORY,
    // FETCH_ITEM_PRICE_HISTORY_SUCCESS,
    // FETCH_SOURCING_PROFILE_REQUEST,
    // FETCH_SOURCING_PROFILE,
    // FETCH_SOURCING_PROFILE_SUCCESS,
    SET_SHIPPING_COST,
    SET_SHIPPING_COST_SUCCESS,
    SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD,
    SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS,
    SAVE_LEAD_DISTRIBUTIONS_REQUEST,
    SAVE_LEAD_DISTRIBUTIONS,
    CLEAR_INQUIRY_LINE_ITEMS_REQUEST,
    CLEAR_INQUIRY_LINE_ITEMS_SUCCESS,
    INQUIRY_LINE_ITEMS_IMPORT_FILE_REQUEST,
    INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING,
    DUPLICATE_ORDER_SUCCESS,
    INQUIRY_LINE_ITEMS_EXPORT_FILE_REQUEST,
    INQUIRY_LINE_ITEMS_EXPORTING,
    INQUIRY_LINE_ITEMS_EXPORT_PDF_DOCUMENT_FILE_REQUEST,
    INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT
} = actions

const apiLineItemsPath = (orderId, id) => {
    const basePath = `/orders/${orderId}/line_items`

    if (!id) {
        return basePath
    }
    return `${basePath}/${id}`
}

export function* receiveInquiry() {
    yield takeEvery(FETCH_INQUIRY_REQUEST, function* ({ payload }) {
        const id = payload.id

        yield put({ type: FETCHING_INQUIRY })

        const data = yield call(apiFetch, `/orders/${id}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderSchema)

        const customerId = formattedData.customer.id

        const shippingCost = formattedData.shipping_costs.map((shippingCost) => {
            return shippingCost.id
        })

        const orderCondition = formattedData.order_conditions.map((orderCondition) => {
            return orderCondition.id
        })

        yield put({
            type: SET_INQUIRY_SHIPPING_COST_AMOUNT,
            amount: shippingCost
        })

        yield put({
            type: SET_INQUIRY_CONDITIONS_AMOUNT,
            amount: orderCondition
        })

        yield put({
            type: FETCH_INQUIRY_SUCCESS,
            ...normalizeData
        })

        yield put(fetchInquiryLineItems(id))

        yield put(fetchReviews(customerId, id))
    })
}

export function* receiveInquiryCustomer() {
    yield takeEvery(FETCH_INQUIRY_CUSTOMER_REQUEST, function* ({ payload }) {
        const id = payload.id

        yield put({ type: FETCHING_INQUIRY_CUSTOMER })

        const data = yield call(apiFetch, `/customers/${id}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerSchema)

        yield put({
            type: FETCH_INQUIRY_CUSTOMER_SUCCESS,
            ...normalizeData
        })
    })
}

export function* receiveInquiryLineItems() {
    yield takeEvery(FETCH_INQUIRY_LINE_ITEMS_REQUEST, function* ({ payload }) {
        const { all, orderId, page, onSuccess, onError } = payload

        yield put({ type: FETCHING_INQUIRY_LINE_ITEMS })

        try {
            const path = `/orders/${orderId}/line_items`
            const queryStrings = all ? `?all=all` : `?page=${page}&per=${PAGE_SIZE}`
            const data = yield call(apiFetch, `${path}${queryStrings}`)

            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, lineItemsSchema)

            if (!all) {
                const total = data.meta.total_pages * PAGE_SIZE

                yield put({
                    type: SET_INQUIRY_LINE_ITEMS_PAGINATION,
                    pagination: { current: page, total }
                })
            }

            yield put({
                type: FETCH_INQUIRY_LINE_ITEMS_SUCCESS,
                ...normalizeData
            })

            onSuccess()
        } catch (error) {
            onError(error)
        }
    })
}

export function* receiveInquiryItems() {
    yield takeEvery(
        [
            FETCH_INQUIRY_ITEMS_REQUEST,
            SET_INQUIRY_ITEM_SEARCH_QUERY,
            SET_INQUIRY_ITEM_CATEGORY,
            SET_INQUIRY_ITEM_SUB_CATEGORY,
            SET_INQUIRY_ITEM_PRODUCT_TYPE
        ],
        function* ({ payload }) {
            const PAGE_SIZE = 20
            let page = 1
            if (payload) {
                page = payload.page
            }

            const state = yield select()
            const queryStrings = {}
            queryStrings['query'] = state.get('inquiry').get('itemQuery')
            queryStrings['category_id'] = state.get('inquiry').get('itemCategoryId')
            queryStrings['sub_category_id'] = state.get('inquiry').get('itemSubCategoryId')
            queryStrings['product_type_id'] = state.get('inquiry').get('itemProductTypeId')

            yield put({ type: FETCHING_INQUIRY_ITEMS })
            let itemsPath = `/search?page=${page}&per_page=${PAGE_SIZE}`

            Object.keys(queryStrings).map(function (objectKey, index) {
                var value = queryStrings[objectKey]
                if (value) {
                    itemsPath += `&${objectKey}=${value}`
                }
            })

            const data = yield call(apiFetch, itemsPath)
            const formattedData = deserialize(data)

            const normalizeData = normalize(formattedData, itemsSchema)
            const total = data.meta.total_pages * PAGE_SIZE

            yield put({
                type: INQUIRY_SET_ITEMS_PAGINATION,
                itemsPagination: { current: page, total }
            })

            yield put({
                type: FETCH_INQUIRY_ITEMS_SUCCESS,
                ...normalizeData
            })
        }
    )
}

// export function *receiveInquiryQuotationPdf () {
//     yield takeEvery(FETCH_INQUIRY_QUOTATION_PDF_REQUEST, function *({ payload }) {
//         const { orderId, preview, locale } = payload
//         const version = Math.round(new Date().getTime() / 1000)
//         yield put({ type: FETCHING_INQUIRY_QUOTATION_PDF })

//         const data = yield call(apiFileFetch,
//             `/orders/${orderId}/quotation?is_show_only=${preview}&locale=${locale}&version=${version}`)
//         if (data.type === 'application/json') {
//             yield put({ type: FETCH_INQUIRY_QUOTATION_PDF_SUCCESS })
//             yield put(setNotifications(`fail`, `${i18n.t('orders:updateStateError')}`, 'error'))
//         } else {
//             const fileURL = URL.createObjectURL(data)
//             window.open(fileURL)

//             yield put({ type: FETCH_INQUIRY_QUOTATION_PDF_SUCCESS })
//         }
//     })
// }

export function* fetchBankAccounts() {
    yield takeEvery(FETCH_BANK_ACCOUNTS_REQUEST, function* ({ payload }) {
        yield put({ type: FETCH_BANK_ACCOUNTS })
        const { allBank } = payload
        const data = yield call(apiFetch, `/bank_accounts?all_bank=${allBank}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, bankAccountsSchema)

        yield put({
            type: FETCH_BANK_ACCOUNTS_SUCCESS,
            ...normalizeData
        })
    })
}

export function* fetchPaymentOptions() {
    yield takeEvery(FETCH_PAYMENT_OPTIONS_REQUEST, function* () {
        yield put({ type: FETCH_PAYMENT_OPTIONS })

        const data = yield call(apiFetch, '/ipp_options')
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, paymentOptionsSchema)

        yield put({
            type: FETCH_PAYMENT_OPTIONS_SUCCESS,
            ...normalizeData
        })
    })
}

// export function *fetchCoupons () {
//     yield takeEvery(FETCH_COUPONS_REQUEST, function *() {
//         yield put({ type: FETCH_COUPONS })

//         const data = yield call(apiFetch, '/coupons')
//         const formattedData = deserialize(data)

//         const normalizeData = normalize(formattedData, couponsSchema)

//         yield put({
//             type: FETCH_COUPONS_SUCCESS,
//             ...normalizeData
//         })
//     })
// }

export function* fetchVehicleTypes() {
    yield takeEvery(FETCH_VEHICLE_TYPES_REQUEST, function* () {
        yield put({ type: FETCH_VEHICLE_TYPES })

        const data = yield call(apiFetch, '/vehicle_types')
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, vehicleTypesSchema)
        yield put({
            type: FETCH_VEHICLE_TYPES_SUCCESS,
            ...normalizeData
        })
    })
}

export function* submitEditInquiry() {
    yield takeEvery(SAVE_INQUIRY_REQUEST, function* ({ payload }) {
        yield put({ type: SAVE_INQUIRY })

        const { orderId, inquiry, onSuccess } = payload

        try {
            const path = `/orders/${orderId}`
            const method = 'PATCH'

            const body = { order: inquiry }
            const data = yield call(apiPostForm, path, method, body)

            if ('error' in data) {
                yield put({
                    type: SAVE_INQUIRY_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)

                const shippingCost = formattedData.shipping_costs.map((shippingCost) => {
                    return shippingCost.id
                })
                const orderCondition = formattedData.order_conditions.map((orderCondition) => {
                    return orderCondition.id
                })

                yield put({
                    type: SET_INQUIRY_SHIPPING_COST_AMOUNT,
                    amount: shippingCost
                })

                yield put({
                    type: SET_INQUIRY_CONDITIONS_AMOUNT,
                    amount: orderCondition
                })

                yield put({
                    type: SAVE_INQUIRY_SUCCESS,
                    ...normalize(formattedData, orderSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function* submitOrderLineItems() {
    yield takeEvery(SAVE_INQUIRY_LINE_ITEMS_REQUEST, function* ({ payload }) {
        yield put({
            type: SET_SAVING_INQUIRY_LINE_ITEMS,
            saving: true
        })

        const id = payload.id
        const orderId = payload.orderId
        const path = apiLineItemsPath(orderId, id)
        const method = id ? 'PATCH' : 'POST'
        const body = JSON.stringify({ line_item: payload.lineItems })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: SAVE_INQUIRY_LINE_ITEMS_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const { onSuccess } = payload
                const formattedData = deserialize(data)

                yield put({
                    type: SAVE_INQUIRY_LINE_ITEMS_SUCCESS,
                    ...normalize(formattedData, lineItemSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                yield put({
                    type: SET_SHOW_ADD_ITEM_FORM,
                    value: false
                })

                onSuccess()

                if (payload.lineItems.position) {
                    const fetchPath = apiLineItemsPath(orderId)
                    const fetchData = yield call(apiFetch, fetchPath)
                    const fetchFormattedData = deserialize(fetchData)
                    const fetchNormalizeData = normalize(fetchFormattedData, lineItemsSchema)

                    yield put({
                        type: FETCH_POSITION_INQUIRY_LINE_ITEMS,
                        ...fetchNormalizeData
                    })

                    yield put(fetchInquiryLineItems(orderId))
                }
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: SET_SAVING_INQUIRY_LINE_ITEMS,
                saving: false
            })
        }
    })
}

// export function *addLineItem () {
//     yield takeLatest(ADD_LINE_ITEM_REQUEST, function *({ payload }) {
//         yield put({ type: ADD_LINE_ITEM_SAVING })

//         const orderId = payload.router.query.id
//         const path = `/orders/${orderId}`
//         const method = 'PATCH'
//         //////// quantityAfterMultiplier attribute is new a quantity which is the real quantity
//         ///////  that we pass in to controller so I try to replace quantity with quantityAfterMultiplier value
//         const lineItemsAttributes = payload.value.line_items_attributes
//         const newLineItemsAttributes = lineItemsAttributes.map((selectItem) => {
//             const { quantityAfterMultiplier, ...rest } = selectItem
//             return {
//                 ...rest,
//                 quantity: quantityAfterMultiplier
//             }
//         })

//         const body = { order: { line_items_attributes: newLineItemsAttributes } }

//         const data = yield call(apiPostForm, path, method, body)
//         const formattedData = deserialize(data)

//         yield put({
//             type: ADD_LINE_ITEM_SUCCESS,
//             ...normalize(formattedData, ordersSchema)
//         })

//         yield put(setNotifications('success', 'saveSuccess', 'success'))
//         setTimeout(payload.router.replace(`/inquiries/${orderId}`), 500)
//     })
// }

export function* deleteOrderLineItem() {
    yield takeLatest(DELETE_INQUIRY_LINE_ITEMS_REQUEST, function* ({ payload }) {
        yield put({ type: DELETE_INQUIRY_LINE_ITEMS })
        yield put({
            type: SET_SAVING_INQUIRY_LINE_ITEMS,
            saving: true
        })

        const id = payload.id
        const orderId = payload.orderId
        const method = 'DELETE'
        const path = apiLineItemsPath(orderId, id)

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, lineItemSchema)

            yield put({
                type: DELETE_INQUIRY_LINE_ITEMS_SUCCESS,
                ...normalizeData
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
            yield put(fetchInquiryLineItems(orderId))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: SET_SAVING_INQUIRY_LINE_ITEMS,
                saving: false
            })
        }
    })
}

export function* movePositionOrderLineItem() {
    yield takeLatest(SET_POSITION_INQUIRY_LINE_ITEMS_REQUEST, function* ({ payload }) {
        yield put({ type: SET_POSITION_INQUIRY_LINE_ITEMS })
        yield put({
            type: SET_SAVING_INQUIRY_LINE_ITEMS,
            saving: true
        })

        const id = payload.id
        const orderId = payload.orderId
        const method = 'PATCH'
        const path = apiLineItemsPath(orderId, id)
        const body = JSON.stringify({ line_item: payload.lineItem })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, lineItemSchema)

            yield put({
                type: SET_POSITION_INQUIRY_LINE_ITEMS_SUCCESS,
                ...normalizeData
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
            yield put({
                type: SET_LOAD_INQUIRY_LINE_ITEMS,
                loading: true
            })

            const fetchPath = apiLineItemsPath(orderId)
            const fetchData = yield call(apiFetch, fetchPath)
            const fetchFormattedData = deserialize(fetchData)
            const fetchNormalizeData = normalize(fetchFormattedData, lineItemsSchema)

            yield put({
                type: FETCH_POSITION_INQUIRY_LINE_ITEMS,
                ...fetchNormalizeData
            })

            yield put(fetchInquiryLineItems(orderId))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: SET_SAVING_INQUIRY_LINE_ITEMS,
                saving: false
            })
        }
    })
}

export function* duplicateOrder() {
    yield takeLatest(DUPLICATE_ORDER_REQUEST, function* ({ payload }) {
        yield put({ type: DUPLICATE_ORDER_SAVING })

        const customerId = payload.customerId
        const orderId = payload.orderId

        const method = 'POST'
        const path = `/orders/${orderId}/duplicate?customer_number=${customerId}`

        const data = yield call(apiFetch, path, method)
        const formattedData = deserialize(data)
        const newOrderId = formattedData.id

        yield put(setNotifications('success', 'saveSuccess', 'success'))

        yield put({ type: DUPLICATE_ORDER_SUCCESS })

        setTimeout(() => window.location.replace(`/inquiries/${newOrderId}`), 500)
        // setTimeout(() => Router.replace(`/inquiries/${newOrderId}`), 500)
    })
}

export function* fetchItemByName() {
    yield takeLatest(FETCH_ITEM_BY_NAME_REQUEST, function* ({ payload }) {
        yield put({ type: SET_ITEM_LOADING, loading: true })

        const queryItemName = payload.itemName
        const path = `/items?item_name=${queryItemName}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, itemsSchema)

            yield put({
                type: FETCH_ITEM_BY_NAME_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SET_ITEM_LOADING, loading: false })
        }
    })
}

export function* addLineItemClipboard() {
    yield takeLatest(SAVE_LINE_ITEM_CLIPBOARD_REQUEST, function* ({ payload }) {
        yield put({ type: SET_SAVING_INQUIRY_LINE_ITEMS, saving: true })

        const state = yield select()
        const currentOrderId = state.getIn(['inquiry', 'current'])
        const itemParams = {
            item_name: payload.itemName,
            order_id: currentOrderId
        }
        const path = `/clipboards`
        const body = JSON.stringify({ line_item_clipboard: itemParams })

        try {
            const data = yield call(apiFetch, path, 'POST', { body })
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_INQUIRY_LINE_ITEMS_SUCCESS,
                ...normalize(formattedData, lineItemSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
            i18n.loadNamespaces(['inquiries'])
            yield put(
                setNotifications(i18n.t('inquiries:itemNotFound'), i18n.t('inquiries:notFoundItemToAdd'), 'warning')
            )
        } finally {
            yield put({ type: SET_SAVING_INQUIRY_LINE_ITEMS, saving: false })
        }
    })
}

// export function *fetchSourcingProfiles () {
//     yield takeEvery(FETCH_SOURCING_PROFILE_REQUEST, function *({ payload }) {
//         yield put({ type: FETCH_SOURCING_PROFILE })

//         const data = yield call(apiFetch, `/sourcing_profiles/${payload.id}`)
//         const formattedData = deserialize(data)
//         const normalizeData = normalize(formattedData, sourcingProfileSchema)

//         yield put({
//             type: FETCH_SOURCING_PROFILE_SUCCESS,
//             ...normalizeData
//         })
//     })
// }

// export function *fetchItemPriceHistory () {
//     yield takeEvery(FETCH_ITEM_PRICE_HISTORY_REQUEST, function *({ payload }) {
//         yield put({ type: FETCH_ITEM_PRICE_HISTORY })

//         const { itemId, postalCode } = payload
//         const path = `/items/${itemId}/historical_prices?postal_code=${postalCode}`

//         const data = yield call(apiFetch, path)
//         const formattedData = deserialize(data)

//         const normalizeData = normalize(formattedData, lineItemsSchema)

//         yield put({
//             type: FETCH_ITEM_PRICE_HISTORY_SUCCESS,
//             ...normalizeData
//         })
//     })
// }

export function* setShippingCost() {
    yield takeEvery(SET_SHIPPING_COST, function* ({ payload }) {
        const { orderId, onSuccess } = payload
        const path = '/cargo_profiles/shipping_cost_calculator'
        const body = JSON.stringify({ order_id: orderId })

        try {
            const data = yield call(apiFetch, path, 'POST', { body })
            const formattedData = deserialize(data)

            yield put({
                type: SET_SHIPPING_COST_SUCCESS,
                ...normalize(formattedData, lineItemSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))

            onSuccess()
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
            yield put(
                setNotifications(
                    i18n.t('inquiries:calculationFailed'),
                    i18n.t('inquiries:notFoundItemToAdd'),
                    'warning'
                )
            )
        }
    })
}

export function* setLessTruckLoadShippingCost() {
    yield takeEvery(SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD, function* ({ payload }) {
        const path = '/shipping_costs/less_truck_load_calculations'
        const body = JSON.stringify({ order_id: payload.orderId })

        try {
            const data = yield call(apiFetch, path, 'POST', { body })
            const formattedData = deserialize(data)

            yield put({
                type: SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS,
                ...normalize(formattedData, lineItemSchema)
            })
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
            yield put(
                setNotifications(
                    i18n.t('inquiries:calculationFailed'),
                    i18n.t('inquiries:notFoundItemToAdd'),
                    'warning'
                )
            )
        }
    })
}

export function* submitLeadDistribution() {
    yield takeLatest(SAVE_LEAD_DISTRIBUTIONS_REQUEST, function* ({ payload }) {
        yield put({ type: SAVE_LEAD_DISTRIBUTIONS, saving: true })
        const { orderId } = payload
        const path = `/orders/${orderId}/lead_distributions`

        try {
            const data = yield call(apiFetch, path, 'POST')
            const formattedData = deserialize(data)

            if (data.status === 'full') {
                yield put(
                    setNotifications(i18n.t('inquiries:failed'), i18n.t('inquiries:distributionsFailed'), 'error')
                )
            } else {
                yield put({
                    type: SAVE_INQUIRY_SUCCESS,
                    ...normalize(formattedData, orderSchema)
                })
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SAVE_LEAD_DISTRIBUTIONS, saving: false })
        }
    })
}

export function* clearOrderLineItems() {
    yield takeLatest(CLEAR_INQUIRY_LINE_ITEMS_REQUEST, function* ({ payload }) {
        yield put({ type: SET_SAVING_INQUIRY_LINE_ITEMS, saving: true })
        const { orderId } = payload
        const path = `/orders/${orderId}/clear_line_items`

        try {
            const data = yield call(apiFetch, path, 'DELETE')
            const formattedData = deserialize(data)

            yield put({
                type: CLEAR_INQUIRY_LINE_ITEMS_SUCCESS,
                ...normalize(formattedData, lineItemsSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
            yield put(fetchInquiryLineItems(orderId))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SET_SAVING_INQUIRY_LINE_ITEMS, saving: false })
        }
    })
}

export function* importLineItemsRequest() {
    yield takeLatest(INQUIRY_LINE_ITEMS_IMPORT_FILE_REQUEST, function* ({ payload }) {
        yield put({ type: INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING, uploading: true })

        const { orderId, file } = payload
        const path = `/orders/${orderId}/line_item_imports`
        const method = 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { file })

            if (data.status === 'ok') {
                yield put(setNotifications('success', 'saveSuccess', 'success'))
                yield put(fetchInquiryLineItems(orderId))
            } else {
                i18n.loadNamespaces(['inquiries'])
                yield put(
                    setNotifications(
                        `${i18n.t('inquiries:upload')} ${i18n.t('shared:error')}`,
                        i18n.t('inquiries:uploadError'),
                        'error'
                    )
                )
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING, uploading: false })
        }
    })
}

export function* exportLineItems() {
    yield takeLatest(INQUIRY_LINE_ITEMS_EXPORT_FILE_REQUEST, function* ({ payload }) {
        yield put({ type: INQUIRY_LINE_ITEMS_EXPORTING, loading: true })
        const { orderId } = payload
        const fileExtension = 'xlsx'
        const path = `/orders/${orderId}/line_item_exports`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `export-lineitem.${fileExtension}`
        })

        yield put({
            type: INQUIRY_LINE_ITEMS_EXPORTING,
            loading: false
        })
    })
}

export function* exportLineItemsPdf() {
    yield takeLatest(INQUIRY_LINE_ITEMS_EXPORT_PDF_DOCUMENT_FILE_REQUEST, function* ({ payload }) {
        yield put({ type: INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT, loading: true })
        const { orderId } = payload
        const fileExtension = 'pdf'
        const path = `/orders/${orderId}/line_items/documents`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `lineitem-document.${fileExtension}`
        })

        yield put({
            type: INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT,
            loading: false
        })
    })
}

export default function* rootSaga() {
    yield all([
        fork(receiveInquiry),
        fork(receiveInquiryCustomer),
        fork(receiveInquiryLineItems),
        fork(receiveInquiryItems),
        // fork(receiveInquiryQuotationPdf),
        fork(fetchBankAccounts),
        fork(fetchPaymentOptions),
        fork(submitEditInquiry),
        // fork(addLineItem),
        fork(fetchVehicleTypes),
        fork(submitOrderLineItems),
        fork(deleteOrderLineItem),
        fork(movePositionOrderLineItem),
        fork(duplicateOrder),
        fork(fetchItemByName),
        fork(addLineItemClipboard),
        // fork(fetchItemPriceHistory),
        // fork(fetchSourcingProfiles),
        fork(setShippingCost),
        fork(setLessTruckLoadShippingCost),
        fork(submitLeadDistribution),
        fork(clearOrderLineItems),
        fork(importLineItemsRequest),
        fork(exportLineItems),
        fork(exportLineItemsPdf)
    ])
}
