import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import SendETaxMailForm from '../../components/Invoices/sendETaxMailForm'

import accountingActions from '../../redux/orders/accounting/actions'
import notificationActions from '../../redux/notifications/actions'

const { sendETaxMailToCustomer } = accountingActions

const { setNotifications } = notificationActions

class SendETaxMailFormContainer extends Component {
  static propTypes = {
      id: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      taxInvoice: PropTypes.object.isRequired,
      customerId: PropTypes.string
  }

  constructor (props) {
      super(props)

      this.state = {
          emailInput: props.customerEntities.getIn([props.customerId, 'email'])
      }
  }

  handleSendETaxMailToCustomer = (email) => {
      const { i18n, sendETaxMailToCustomer, orderId, setNotifications, id } = this.props

      sendETaxMailToCustomer({
          orderId,
          id,
          email,
          onSuccess: () => {
              setNotifications(
                  i18n.t('confirms:invoice.success'),
                  `${i18n.t('confirms:invoice.sendTaxInvoiceTo')} ${email}`,
                  'info'
              )
          },
          onError: () => {
              setNotifications('error', 'error', 'error')
          }
      })
  }

  render () {
      const { accountings, customerId, customerEntities, taxInvoice } = this.props

      return (
          <SendETaxMailForm
              taxInvoice={taxInvoice}
              onSendETaxMailToCustomer={this.handleSendETaxMailToCustomer}
              eTaxMailSending={accountings.get('eTaxMailSending')}
              defaultEmail={customerEntities.getIn([customerId, 'email']) || null}
              setEmail={(currentMail) => this.setState({ emailInput: currentMail })}
              emailInput={this.state.emailInput}
          />
      )
  }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails'),
    customerEntities: state.getIn(['Entities', 'customers'])

})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        sendETaxMailToCustomer,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('confirms')(SendETaxMailFormContainer))
