import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Divider } from 'antd'
import TicketEditInfo from './ticketEditInfo'
import TicketInfoWrapper from './ticketInfo.style'
import lineLogo from '../../../../public/images/base/line-logo.png'

const renderAgent = (i18n, ticketMemberAgent) => {
    if (!ticketMemberAgent) return null

    return (
        <div className="detail-row">
            <div className="detail-label">{i18n.t('tickets:agent')}</div>
            <div className="detail-value">{ticketMemberAgent.staff.fullName}</div>
        </div>
    )
}

const renderCreated = (i18n, createdAt) => {
    return (
        <div className="detail-row">
            <div className="detail-label">{i18n.t('shared:createdAt')}</div>
            <div className="detail-value">
                {createdAt.timeAgo} ({createdAt.display})
            </div>
        </div>
    )
}
const renderLine = (customer) => {
    if (!customer.lineUrl) return null

    return (
            <a href={customer.lineUrl} target={'_blank'}>
              <img src={lineLogo} height={20} />
            </a>
    )

}

const renderCustomer = (i18n, customer) => {
    if (!customer) return null

    return (
        <div className="detail-row">
            <div className="detail-label">{i18n.t('tickets:from')}</div>
            <div className="detail-value customer-section">
                <Link to={`/customers/${customer.customerNo}`}>{customer.fullName}</Link>
                <div className='phone-number'>
                    {customer.phoneNumber}
                    { renderLine(customer) }
                </div>

            </div>


        </div>
    )
}

const TicketInfo = ({ refetch, ticket }) => {
    const { i18n } = useTranslation('tickets')

    return (
        <TicketInfoWrapper>
            <strong>{ticket.ticketNo} #{ticket.id}</strong>
            <div className="ticket-detail">
                {renderAgent(i18n, ticket.ticketMemberAgent)}
                {renderCustomer(i18n, ticket.customer)}
                {renderCreated(i18n, ticket.createdAt)}
            </div>
            <TicketEditInfo ticket={ticket} refetch={refetch} />
            <Divider style={{ margin: '12px 0' }} />
            <div className="description">{ticket.description}</div>
        </TicketInfoWrapper>
    )
}

TicketInfo.propTypes = {
    refetch: PropTypes.func,
    ticket: PropTypes.object.isRequired
}

TicketInfo.defaultProps = {
    refetch: () => {}
}

export default TicketInfo
