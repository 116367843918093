import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Form, Input, Space } from 'antd'
import TicketEnumSelector from '../TicketEnumSelector'
import OrderSelector from '../../orderSelector'
import CategorySelector from '../../../categorySelector'
import AreasSelectContainer from '../../../../containers/Areas/AreasSelectContainer'
import SelectTicketSubCategoriesType from '../../../tickets/SelectTicketSubCategoriesType'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 }
    }
}

const areasSelectLayout = {
    ...formItemLayout,
    style: { marginBottom: '0' }
}

const Render = ({ customerId, form, onClose, onSubmitForm, saving, ticket }) => {
    const { i18n } = useTranslation('tickets')
    const [categoryType, setCategoryType] = useState(ticket.categoryType)

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [ticket])

    const getOrders = () => {
        if (!ticket) {
            return []
        }

        return ticket.orders.map((order) => {
            return { value: order.id, label: order.number }
        })
    }

    const onChangeCategoryType = (value) => {
        setCategoryType(value)
        form.setFieldsValue({ subCategoryType: null })
    }

    const initialValues = {
        source: ticket.source || 'call_center',
        categoryId: ticket.category && ticket.category.id,
        ticketType: ticket.ticketType,
        orderIds: getOrders(),
        description: ticket.description,
        categoryType: ticket.categoryType,
        subCategoryType: ticket.subCategoryType
    }

    return (
        <Form form={form} initialValues={initialValues} onFinish={onSubmitForm} {...formItemLayout}>
            <h2>Ticket</h2>
            <Form.Item label={i18n.t('tickets:category')} name="categoryId">
                <CategorySelector defaultCategory={ticket.category}/>
            </Form.Item>
            <Form.Item label={i18n.t('tickets:ticketType')} name="ticketType">
                <TicketEnumSelector name="ticket_type" />
            </Form.Item>
            <Form.Item label={i18n.t('tickets:categoryType')} name="categoryType">
                <TicketEnumSelector name="category_type" onChange={onChangeCategoryType} />
            </Form.Item>
            <Form.Item label={i18n.t('tickets:subCategoryType')} name="subCategoryType">
                <SelectTicketSubCategoriesType categoryType={categoryType} disabled={!categoryType} />
            </Form.Item>
            <Form.Item label={i18n.t('tickets:source')} name="source">
                <TicketEnumSelector name="source" />
            </Form.Item>
            <Form.Item label={i18n.t('tickets:orders')} name="orderIds">
                <OrderSelector customerId={customerId} mode="multiple" />
            </Form.Item>

            <AreasSelectContainer
                form={form}
                provinceFieldName="provinceId"
                districtFieldName="districtId"
                provinceValue={ticket.province && ticket.province.id}
                districtValue={ticket.district && ticket.district.id}
                districtFormItemLayout={areasSelectLayout}
                provinceFormItemLayout={areasSelectLayout}
                districtCol={{ md: { span: 24 } }}
                provinceCol={{ md: { span: 24 } }}
                showPostalCode={false} />

            <Form.Item label={i18n.t('tickets:description')} name="description">
                <Input.TextArea />
            </Form.Item>

            <Form.Item {...formItemLayoutWithOutLabel} style={{ margin: 0 }}>
                <Space>
                    <Button loading={saving} type="primary" htmlType="submit">
                        {i18n.t('shared:save')}
                    </Button>
                    <Button onClick={onClose}>
                        {i18n.t('shared:close')}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

Render.propTypes = {
    customerId: PropTypes.string.isRequired,
    form: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    ticket: PropTypes.object
}

Render.defaultProps = {
    ticket: {
        orders: []
    }
}

export default Render
