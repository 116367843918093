import styled from 'styled-components'

const SelectAddressWrapper = styled.div`
    .select-district-component {
        margin-bottom: 15px;
    }
    .select-province-component {
        margin: 15px 0;
    }
`

export default SelectAddressWrapper
