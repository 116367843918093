import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import ContentLayout from '../../../components/utility/layoutContent'
import AccountingsContainer from './AccountingsContainer'

class OrderAccountingsPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        const { id } = this.props.match.params
        document.title = `${id} | OSH CRM`
    }

    render () {
        const { id } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={
                    [
                        { path: `/order_summaries`, name: 'Orders' },
                        { path: `/inquiries/${id}`, name: id },
                        { path: `/orders/${id}/accountings`, name: 'Accountings' }
                    ]
                }>
                    <ContentLayout>
                        <AccountingsContainer orderId={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default OrderAccountingsPage
