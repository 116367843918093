import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Menu, Dropdown } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const SALE_PIPELINE_ENUM_QUERY = gql`
    query SalePipelineEnum($name: String!){
        salePipelineEnum(enumName: $name){
            label
            value
        }
    }
`

const UPDATE_SALE_PIPELINE_MUTATION = gql`
    mutation UpdateSalePipeline($orderId: String, $salePipelineId: ID, $salePipelineParams: AdminSalePipelineInput!){
        updateSalePipeline(orderId: $orderId, salePipelineId: $salePipelineId, salePipelineParams: $salePipelineParams){
          salePipeline {
              id
              status
          }
        }
    }
`

const getStatusItems = (statusItems = [], onSave) => {
  return statusItems.map(({  label, value }) => {
    if (!value) return null
    return (
      <Menu.Item>
          <div onClick={() => onSave(value)}>{label}</div>
      </Menu.Item>
    )
  })
}

const SalePipelineStatusDropdown = ({
  content,
  orderId,
  salePipelineId,
  onSuccess
}) => {
  const { loading, data } = useQuery(SALE_PIPELINE_ENUM_QUERY, { variables: { name: 'status' } })
  const [updateSalePipeline, { loading: saving }] = useMutation(UPDATE_SALE_PIPELINE_MUTATION, {
      onCompleted: () => {
          onSuccess()
      }
  })

  const onSave = (value) => {
    updateSalePipeline({
      variables: {
          orderId: orderId || null,
          salePipelineId,
          salePipelineParams: { status: value }
      }
    })
  }

  const statusData =  data && data.salePipelineEnum

  if (loading) return null

  return (
    <div>
      <Dropdown overlay={<Menu>{getStatusItems(statusData, onSave)}</Menu>}>
        {saving ? <LoadingOutlined /> : content}
      </Dropdown>
    </div>
  )
}

SalePipelineStatusDropdown.propTypes = {
  onSuccess: PropTypes.func,
  orderId: PropTypes.string,
  salePipelineId: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

SalePipelineStatusDropdown.defaultProps = {
  onSuccess: () => {}
}

export default SalePipelineStatusDropdown
