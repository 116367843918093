import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import { Form, Select, Row, Button, Modal } from 'antd'
import moment from 'moment'
import { List as immutableList } from 'immutable'

import PaymentDetailForm from '../../../components/inquiry/paymentDetailForm'
import AccountingWrapper from './accounting.style'

import inquiryActions from '../../../redux/inquiry/actions'
import accountingActions from '../../../redux/orders/accounting/actions'

const { fetchBankAccounts } = inquiryActions
const { savePaymentDatail, deletePaymentDatail } = accountingActions

class PaymentDetailsForm extends Component {
    constructor () {
        super()

        this.formRef = React.createRef()
    }

    async componentDidMount () {
        const { fetchBankAccounts, paymentDetails, entities } = this.props
        await fetchBankAccounts()
        if (paymentDetails) {
            const imageThumb = paymentDetails.get('order_payment_images').map((thumb) => {
                return {
                    uid: thumb.get('id'),
                    url: entities.getIn(['images', thumb.getIn(['image', 'thumb']), 'thumbBlob'])
                }
            })
            this.setState({ fileList: imageThumb.toJS() })
        }
        moment.locale('th')
    }

    state = {
        fileList: []
    }

    bankAccountsList = () => {
        const { entities, inquiry } = this.props

        return inquiry.get('bankAccountItems').map((bankAccountId) => {
            const bankAccount = entities.getIn(['bankAccounts', bankAccountId])
            return (
                <Select.Option key={bankAccount.get('id')} value={bankAccount.get('id')}>
                    {bankAccount.get('full_name')}
                </Select.Option>
            )
        })
    }

    invoicesList = () => {
        const { orderPaymentDetails } = this.props
        const invoices = orderPaymentDetails.get('invoiceNumberlist')

        return invoices.map((invoice) => {
            return (
                <Select.Option key={invoice.get('id')} value={invoice.get('id')}>
                    {invoice.get('invoice_no')}
                </Select.Option>
            )
        })
    }

    handleSubmit = (fieldsValue) => {
        const { paymentDetails, entities, savePaymentDatail, match } = this.props
        const orderId = match.params.id
        const id = paymentDetails && paymentDetails.get('id')

        let image
        if (fieldsValue['image']) {
            const imageValue = fieldsValue['image']
            const paymentImages = entities.getIn(
                ['paymentDetails', id, 'order_payment_images']
            ) || immutableList()
            let destroyImage = []
            let uploadImage = imageValue
            paymentImages.map((image) => {
                if (!imageValue.filter(value => value.uid === image.get('id')).length) {
                    destroyImage.push({ id: image.get('id'), _destroy: true })
                } else {
                    uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
                }
            })
            image = uploadImage ? (
                uploadImage.map((id) => {
                    return {
                        image: id.originFileObj,
                        order_number: orderId,
                        _destroy: false
                    }
                }).concat(destroyImage)
            ) : null
        }
        const transferDate = fieldsValue['transfer_date'] ? (
            fieldsValue['transfer_date'].format('YYYY-MM-DD')
        ) : null
        const paymentTerm = fieldsValue['payment_term'] ? fieldsValue['payment_term'] : null
        const paymentMethod = fieldsValue['payment_method'] ? fieldsValue['payment_method'] : null
        const receiptVoucherDate = fieldsValue['receipt_voucher_date'] ? (
            fieldsValue['receipt_voucher_date'].format('YYYY-MM-DD')
        ) : null
        Reflect.deleteProperty(fieldsValue, 'paste_image')

        const values = {
            ...fieldsValue,
            transfer_date: transferDate,
            payment_term: paymentTerm,
            payment_method: paymentMethod,
            receipt_voucher_date: receiptVoucherDate,
            order_payment_images_attributes: image
        }
        savePaymentDatail(orderId, id, values)
    }

    handleDelete = () => {
        const { i18n, orderPaymentDetails, paymentDetails, deletePaymentDatail, match } = this.props
        const orderId = match.params.id
        const orderItems = orderPaymentDetails.toJS()
        const id = paymentDetails && paymentDetails.get('id')

        Modal.confirm({
            title: i18n.t('confirms:titleDelete'),
            content: i18n.t('confirms:contentDelete'),
            okText: 'OK',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk () {
                deletePaymentDatail(orderId, orderItems, id)
            }
        })
    }

    setFileList = (fileList) => this.setState(fileList)

    render () {
        const { i18n, paymentDetails, orderPaymentDetails, page, orderState, match } = this.props
        const { id } = match.params
        const saving = orderPaymentDetails.get('savingPaymentDetail')
        const installmentRefNo = paymentDetails && paymentDetails.get('installment_ref_no')

        return (
            <AccountingWrapper>
                <div style={{ display: 'flex'}}>
                    <h3> {id} </h3>
                    {installmentRefNo ? <div style={{ marginTop: '4px', marginLeft: '4px' }}>
                        ({i18n.t('inquiries:paymentDetailInstallment.refNo')}: {installmentRefNo})
                    </div> : null}
                </div>
                <Form labelAlign="left" onFinish={this.handleSubmit} ref={this.formRef}>
                    <PaymentDetailForm
                        orderPaymentDetails={orderPaymentDetails}
                        bankList={this.bankAccountsList()}
                        invoiceList={this.invoicesList()}
                        setFileList={this.setFileList}
                        fileList={this.state.fileList}
                        item={paymentDetails}
                        page={page}
                        form={this.formRef}
                        orderState={orderState} />
                    <Row className="btn-center">
                        <Button type="primary" htmlType="submit" loading={saving}>
                            {i18n.t('confirms:save')}
                        </Button>
                        {paymentDetails ? (
                            <span> &emsp;
                                <Button type="danger"
                                    onClick={() => this.handleDelete()}>
                                    {i18n.t('confirms:delete')}
                                </Button>
                            </span>
                        ) : null}
                    </Row>
                </Form>
            </AccountingWrapper>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    inquiry: state.get('inquiry'),
    orderPaymentDetails: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBankAccounts,
        savePaymentDatail,
        deletePaymentDatail
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('confirms')(withRouter(PaymentDetailsForm))
)
