import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'

import BankStatementWithSupplierInvoiceExport from '../../components/BankStatements/ExportWithSupplierInvoice'

import actions from '../../redux/bankStatements/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    setExportWithSupplierInvoiceVisibleModal,
    exportBankStatementWithSupplierInvoices
} = actions

const { setNotifications } = notificationActions

class BankStatementExportWithSupplierInvoiceContainer extends Component {
    static propTypes = {
        // Inner Props
        bankStatements: ImmutablePropTypes.map.isRequired,
        setExportWithSupplierInvoiceVisibleModal: PropTypes.func.isRequired,
        exportBankStatementWithSupplierInvoices: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {}

    onSubmit = ({ params = {} } = {}) => {
        const { exportBankStatementWithSupplierInvoices, i18n, setNotifications } = this.props

        exportBankStatementWithSupplierInvoices({
            params,
            onSuccess: () => {
                this.onToggleVisibleModal()
                setNotifications(
                    i18n.t('bankStatements:uploadSuccess'),
                    i18n.t('bankStatements:uploadSuccessDescription'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    onToggleVisibleModal = () => {
        const { bankStatements, setExportWithSupplierInvoiceVisibleModal } = this.props

        setExportWithSupplierInvoiceVisibleModal({ withSupplierInvoiceVisibleModal: !bankStatements.getIn(['export', 'withSupplierInvoiceVisibleModal']) })
    }

    render () {
        const { bankStatements } = this.props

        return (
            <BankStatementWithSupplierInvoiceExport
                onSubmit={this.onSubmit}
                onToggleVisibleModal={this.onToggleVisibleModal}
                saving={bankStatements.get('saving', false)}
                visibleModal={bankStatements.getIn(['export', 'withSupplierInvoiceVisibleModal'], false)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setExportWithSupplierInvoiceVisibleModal,
        setNotifications,
        exportBankStatementWithSupplierInvoices
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['bankStatements'])(BankStatementExportWithSupplierInvoiceContainer)
)
