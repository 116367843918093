import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { gql, useQuery } from '@apollo/client'

import JournalEntryFormatForm from '../../components/journalEntryFormats/JournalEntryFormatForm'

import actions from '../../redux/journalEntryFormats/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveJournalEntryFormat } = actions
const { setNotifications } = notificationActions

const JOURNAL_ENTRY_FORMAT_QUERY = gql`
    query JournalEntryFormat($id: ID!) {
        journalEntryFormat(id: $id) {
          id
          name
          description
          triggerType
          triggerTypeName
          triggerDate
          images {
            id
            uid
            thumbUrl
            url
          }
        }
    }
`

const JOURNAL_ENTRY_FORMAT_ENUM_QUERY = gql`
    query JournalEntryFormatEnums {
        journalEntryFormatEnums {
          id
          value
        }
    }
`

const JOURNAL_ENTRY_FORMAT_TRIGGER_DATE_QUERY = gql`
    query JournalEntryFormatTriggerDates {
        journalEntryFormatTriggerDates {
          id
          value
        }
    }
`

const JournalEntryFormatFormContainer = ({
  toggleForm,
  onSuccess,
  journalEntryFormatId
}) => {
  const dispatch = useDispatch()
  const saving = useSelector(state => state.getIn(['journalEntryFormats', 'saving']))
  const errors = useSelector(state => state.getIn(['journalEntryFormats', 'errors']))
  const [fileList, setFileList] = useState([])

  const { data, loading } = useQuery(JOURNAL_ENTRY_FORMAT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id: journalEntryFormatId },
    onCompleted: ({ journalEntryFormat }) => {
      const images = _.get(journalEntryFormat, 'images', [])
      if (images) {
        const newImages = _.get(journalEntryFormat, 'images', [])
        setFileList(newImages)
      }
    }
  })

  const { data: formatEnumData, loading: formatEnumLoading, errors: formatEnumErrors } = useQuery(JOURNAL_ENTRY_FORMAT_ENUM_QUERY)
  const { data: triggerDateData, loading: triggerDateLoading, errors: triggerDateError } = useQuery(JOURNAL_ENTRY_FORMAT_TRIGGER_DATE_QUERY)
  const journalEntryFormat = data ? data.journalEntryFormat : {}
  const journalEntryFormatEnums = formatEnumData ? formatEnumData.journalEntryFormatEnums : []
  const journalEntryFormatTriggerDates = triggerDateData ? triggerDateData.journalEntryFormatTriggerDates : []
  const setFileImages = ({ fileList }) => {
    setFileList(fileList)
  }

  const getImagesData = ({ fileList = [] }) => {
    let newImages = []
    _.forEach(fileList, (file) => {
      if (_.isEmpty(file.originFileObj)) { return }

      newImages = newImages.concat(file.originFileObj)
    })

    const ids = _.map(fileList, (file) => file.uid)
    const removeImages = _.reject(_.get(journalEntryFormat, 'images', []), (file) => ids.includes(file.uid))

    return {
      [`new_images`]: newImages,
      [`remove_images`]: _.map(removeImages, (file) => file.uid)
    }
  }

  const handleSaveJournalEntryFormat = (params) => {
    const images = getImagesData({ fileList: fileList })
    dispatch(saveJournalEntryFormat({
        id: journalEntryFormatId,
        params: { ...params, ...images },
        onSuccess: () => {
          dispatch(setNotifications('success', 'saveSuccess', 'success'))
            toggleForm()
            onSuccess()
        },
        onError: (error) => {
          dispatch(setNotifications(`${error.name}`, `${error.message}`, 'error'))
        }
    }))
  }

  return (
    <JournalEntryFormatForm
        errors={errors.toJS()}
        loading={loading}
        onSubmitForm={handleSaveJournalEntryFormat}
        saving={saving}
        toggleForm={toggleForm}
        setFileList={setFileImages}
        fileList={fileList}
        journalEntryFormatEnums={journalEntryFormatEnums}
        formatEnumLoading={formatEnumLoading}
        journalEntryFormatTriggerDates={journalEntryFormatTriggerDates}
        triggerDateLoading={triggerDateLoading}
        journalEntryFormat={journalEntryFormat} />
  )
}

JournalEntryFormatFormContainer.propTypes = {
  toggleForm: PropTypes.func,
  onSuccess: PropTypes.func,
  journalEntryFormatId: PropTypes.string
}

JournalEntryFormatFormContainer.defaultProps = {}

export default JournalEntryFormatFormContainer
