import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Table, Divider, Button, Tooltip, Modal, Select, Input, Row, Col, Checkbox, DatePicker } from 'antd'
import { EyeOutlined, FileTextOutlined, PlusSquareTwoTone, CloseOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import _ from 'lodash'

import SelectOrderStateContainer from '../../containers/SelectOrderState'
import SelectSupplier from '../../containers/SelectSupplier'
import PurchaseOrderFormContainer from '../../containers/PurchaseOrderForm'
import PurchaseOrderStatusSwitches from '../../containers/PurchaseOrderStatusSwitches'
import Image from '../../containers/Image'
import SupplierOrderReviewWrapper from './supplierOrderReviews.style'
import SuppliersOrdersFormContainer from '../../containers/Suppliers/Orders/SuppliersOrdersFormContainer'
import imagePreviewContainer from '../../containers/ImagePreview/ImagePreviewContainer'

import RangePickerWithDateType from '../../containers/RangePickerWithDateType'

const renderPOPreviewBtn = (supplierOrder) => {
    const isPODownloaded = supplierOrder.is_po_downloaded

    if (!isPODownloaded) { return null }

    return (
        <Button onClick={() => window.open(`/purchase_orders/${supplierOrder.ref_code}/previews`)}
            className="po-last-download-btn"
            ghost
            icon={<EyeOutlined />}
            type="primary" />
    )
}

const renderSupplierOrderBtn = (props, record) => {
    const supplierOrder = record.supplier_order

    if (!supplierOrder) { return null }

    const { loadPO, onShowSupplierOrderForm, onShowPOForm } = props
    let supplierName = null

    if (supplierOrder.state === 'get_order') {
        supplierName = (
            <a onClick={() => onShowSupplierOrderForm({ orderId: record.id, supplierOrderId: supplierOrder.id })}>
                <div>{supplierOrder.supplier_name}</div>
            </a>
        )
    } else {
        supplierName = <div>{supplierOrder.supplier_name}</div>
    }

    return (
        <div>
            {supplierName}
            <div>
                {loadPO ? <React.Fragment>
                    <Button className="icon-button" type="primary" icon={<FileTextOutlined />} ghost
                        onClick={() => onShowPOForm({
                            orderId: record.id,
                            supplierOrderId: supplierOrder.id,
                            refCode: supplierOrder.ref_code })} />
                    {renderPOPreviewBtn(supplierOrder)}
                </React.Fragment> : null}

            </div>
        </div>
    )
}

const renderPOForm = props => {
    const {
        i18n,
        activeOrder,
        activeSupplierOrder,
        onSaveSuccessPOForm,
        onShowPOForm,
        showPOFormModal
    } = props

    return (
        <Modal
            width="70%"
            destroyOnClose
            footer={false}
            onCancel={() => onShowPOForm()}
            visible={showPOFormModal}>
            <PurchaseOrderFormContainer
                onSuccess={() => onSaveSuccessPOForm()}
                orderId={activeOrder}
                supplierOrderId={activeSupplierOrder} />
        </Modal>
    )
}

const renderSupplierOrderForm = props => {
    const {
        activeOrder,
        activeSupplierOrder,
        onChangePagination,
        onShowSupplierOrderForm,
        showSupplierOrderFormModal
    } = props

    return (
        <Modal
            destroyOnClose
            footer={false}
            onCancel={() => onShowSupplierOrderForm()}
            visible={showSupplierOrderFormModal}>
            <SuppliersOrdersFormContainer
                isNewPage={!activeSupplierOrder}
                isOrderSummaryDashBoard
                isSupplierPurchase
                formType="product"
                orderId={activeOrder}
                supplierOrderId={activeSupplierOrder}
                onSaveSuccess={() => {
                    onShowSupplierOrderForm()
                    onChangePagination(1)
                }}
            />
        </Modal>
    )
}

const renderImage = (dataIndex) => {
    if (!dataIndex) {
        return
    }
    return dataIndex.map((image) => {
        const imageId = image.id
        const thumbUrl = image.file && image.file.s || ''
        const originalUrl = image.file && image.file.original || ''
        const contentType = image.file && image.file.content_type || ''

        return (
            <Image
                key={imageId}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    })
}

const renderCancelBtn = (props, supplierOrder) => {
    const { i18n, onCancelSupplierOrder } = props

    if (!supplierOrder) { return null }

    const state = supplierOrder.state

    if (state === 'get_order') {
        return (
            <Tooltip title={i18n.t('suppliers:cancel')}>
                <Button type="danger" icon={<CloseOutlined />}
                    onClick={() => {onCancelSupplierOrder(supplierOrder.id, supplierOrder.order)}} />
            </Tooltip>
        )
    } else if (state === 'cancel') {
        return <div>{supplierOrder.state_label}</div>
    }
}

const renderIsPoCompletedSelect = (props) => {
    const { i18n, defaultIsPoCompleted, handleFilterPoCreated } = props

    return (
        <Select
            allowClear
            value={defaultIsPoCompleted}
            onChange={(value) => handleFilterPoCreated(value)}
            style={{ width: '100%' }}
        >
            <Select.Option key="true" value="true">
                {i18n.t('suppliers:supplierOrderReviews:poCompleted')}
            </Select.Option>
            <Select.Option key="false" value="false">
                {i18n.t('suppliers:supplierOrderReviews:NotCreatedYet')}
            </Select.Option>
        </Select>
    )
}

const renderIsCheckedSelect = (props) => {
    const { i18n, defaultIsChecked, handleFilterIsChecked } = props

    return (
        <Select
            allowClear
            value={defaultIsChecked}
            onChange={(value) => handleFilterIsChecked(value)}
            style={{ width: '100%' }}
        >
            <Select.Option key="true" value="true">
                {i18n.t('suppliers:supplierOrderReviews:approved')}
            </Select.Option>
            <Select.Option key="false" value="false">
                {i18n.t('suppliers:supplierOrderReviews:NotApproved')}
            </Select.Option>
        </Select>
    )
}

const renderOrderState = (props, record) => {
    const { i18n } = props

    const paymentMethod = record.payment_method
    const isDeposit = record.is_deposit_payment_term
    const stateLabel = record.state_label

    if (record.state !== 'payment') { return stateLabel }

    const payByCheque = paymentMethod === 'cheque' ? `<${i18n.t('inquiries:payByCheque')}>` : ''
    const depositPaymentTerm = isDeposit ? `<${i18n.t('inquiries:stateDropdown.deposit')}>` : ''

    return (
        <div>
            <div>{stateLabel}</div>
            <div>{depositPaymentTerm}</div>
            <div>{payByCheque}</div>
        </div>
    )
}

const SupplierOrderReviewIndex = props => {
    const {
        dataSource,
        i18n,
        loading,
        onChangePagination,
        onShowSupplierOrderForm,
        pagination,
        handleFilterOrderState,
        handleSupplierChange,
        renderPurchaserFilterOptions,
        handlePeriodChange,
        handleFilterPurchaser,
        handleSearch,
        handleFilterIsNotWorkingYet,
        handleDueDate,
        defaultSearchQuery,
        defaultOrderState,
        defaultSupplier,
        defaultPurchaser,
        defaultDateType,
        defaultStartDate,
        defaultEndDate,
        defaultIsNotWorking,
        defaultDueDate
    } = props

    const columns = [
        {
            key: 'order-number',
            title: i18n.t('suppliers:supplierOrderReviews.orderNumber'),
            width: 160,
            render: (record) => (
                <div>
                    <Link to={`/inquiries/${record.number}`}>
                        {record.number}
                    </Link>
                    <div>{renderOrderState(props, record)}</div>
                    {renderSupplierOrderBtn(props, record)}
                    <Divider type="horizontal" style={{ margin: '8px 0' }} />
                </div>
            )
        },
        {
            key: 'detail',
            title: i18n.t('suppliers:supplierOrderReviews.detail'),
            dataIndex: 'detail',
            width: 250
        },
        {
            key: 'payment_time',
            title: i18n.t('suppliers:supplierOrderReviews.paymentDateTime'),
            sorter: true,
            dataIndex: 'payment_date_time',
            render: (data) => {
                return (
                    <div>
                        {data ? moment(data).format('DD MMM YYYY, h:mm:ss') : null}
                    </div>
                )
            }
        },
        {
            key: 'grand_total_satangs',
            title: i18n.t('suppliers:supplierOrderReviews.sumOfReceipt'),
            sorter: true,
            render: (record) => (
                <div>
                    <div>{record.grand_total}</div>
                    <div>({record.sum_of_receipt === '0.00' ? '-' : record.sum_of_receipt})</div>
                </div>
            )
        },
        {
            key: 'attach-files',
            title: i18n.t('suppliers:attachFile'),
            width: 150,
            render: (record) => {
                const supplierOrder = record.supplier_order
                const addSupplierOrderBtn = (
                    <a onClick={() => onShowSupplierOrderForm({ orderId: record.id })}>
                        <PlusSquareTwoTone />
                    </a>
                )

                if (!supplierOrder) { return addSupplierOrderBtn }

                const supplierOrderImages = _.get(supplierOrder, 'supplier_order_images', [])
                return (
                    <div>
                        {supplierOrderImages.length ? (
                            <div key="supplier-order-images" className="attach-file">
                                <div className="image-container">
                                    {renderImage(supplierOrder.supplier_order_images)}
                                </div>
                            </div>
                        ) : null}
                        {_.get(supplierOrder, 'order_supplier_notes', []).map((orderSupplierNote) => {
                            return (
                                <div key={`order-supplier-note-${orderSupplierNote.id}`} className="attach-file">
                                    <div>{orderSupplierNote.body}</div>
                                    <div className="image-container">
                                        {renderImage(orderSupplierNote.images)}
                                    </div>
                                </div>
                            )
                        })}
                        {
                            supplierOrder.external_note ? <div className="external-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.externalNote')}:
                                </span> {supplierOrder.external_note}
                            </div> : null
                        }
                        {
                            supplierOrder.note_with_sanitizer ? <div className="supplier-order-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.supplierOrderNote')}:
                                </span> {supplierOrder.note_with_sanitizer}
                            </div> : null
                        }
                        {addSupplierOrderBtn}
                    </div>
                )
            }
        },
        {
            key: 'supplier-order-status',
            title: i18n.t('suppliers:state'),
            render: (record) => {
                const supplierOrder = record.supplier_order
                if (!supplierOrder) { return null }

                return <PurchaseOrderStatusSwitches supplierOrder={supplierOrder} />
            }
        },
        {
            key: 'truck_reservations.date',
            sorter: true,
            title: i18n.t('suppliers:supplierOrderReviews.trucks'),
            dataIndex: 'truck_dispatch_license_plates',
            render: (dataIndex, record, index) => {
                return dataIndex.map((truck, i) => {
                    return (
                        <div key={`truck-${index + 1}-${i + 1}`} className="truck-dispatch">{truck}</div>
                    )
                })
            }
        },
        {
            key: 'due-date',
            title: i18n.t('suppliers:supplierOrder.dueDate'),
            dataIndex: ['supplier_order', 'due_date']
        },
        {
            key: 'cancel',
            title: '',
            render: (record) => (
                <div className="cancel">
                    {renderCancelBtn(props, record.supplier_order)}
                </div>
            )
        }
    ]

    return (
        <SupplierOrderReviewWrapper>
            {/* Search Input */}
            <Input.Search
                enterButton
                defaultValue={defaultSearchQuery}
                onSearch={(query) => {handleSearch(query)}}
                placeholder={i18n.t('orders/tables:searchPlaceholder')} />

            <Divider />

            <Row gutter={[16, 16]} type="flex" align="middle">
                {/* Order State Select */}
                <Col xs={24} md={6}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <label className="title">
                                {i18n.t('orders:orderSummary.status')}:
                            </label>
                        </Col>

                        <Col span={20}>
                            <SelectOrderStateContainer
                                onChange={handleFilterOrderState}
                                value={defaultOrderState || 'all'}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* Supplier Select */}
                <Col xs={24} md={7}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <label className="title">
                                {i18n.t('suppliers:supplierName')}:
                            </label>
                        </Col>

                        <Col span={20}>
                            <SelectSupplier
                                isShowHaveNoSupplier
                                onChange={handleSupplierChange}
                                value={defaultSupplier}
                            />
                        </Col>
                    </Row>
                </Col>

                {/* Purchaser Select */}
                <Col xs={24} md={7}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={9} style={{ textAlign: 'right' }}>
                            <label className="title">
                                {i18n.t('suppliers:purchaseOrders.filterByPurchaser')}:
                            </label>
                        </Col>

                        <Col span={15}>
                            <Select
                                allowClear
                                value={defaultPurchaser}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                                onChange={(value) => handleFilterPurchaser(value)}
                                placeholder={i18n.t('suppliers:purchaseOrders.filterByPurchaser')}
                                showSearch
                                style={{ width: 180 }}
                            >
                                {renderPurchaserFilterOptions()}
                            </Select>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={4}>
                    <Checkbox onChange={handleFilterIsNotWorkingYet} checked={defaultIsNotWorking}>
                        {i18n.t('suppliers:supplierOrderReviews.NotWorkingYet')}
                    </Checkbox>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                {/* Date Range Picker */}
                <Col xs={24} md={8}>
                    <RangePickerWithDateType
                        dateTypes={[
                            {
                                label: i18n.t('suppliers:supplierOrderReviews.truckReservationDate'),
                                value: 'reservation_date'
                            },
                            {
                                label: i18n.t('suppliers:supplierOrderReviews.paymentDateTime'),
                                value: 'payment_time'
                            }
                        ]}
                        onChange={handlePeriodChange}
                        value={{ date_type: defaultDateType, start_date: defaultStartDate, end_date: defaultEndDate }}
                    />
                </Col>

                <Col xs={24} md={5}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={8} style={{ textAlign: 'right' }}>
                            {i18n.t('suppliers:supplierOrder.dueDate')}:
                        </Col>

                        <Col span={16}>
                            <DatePicker onChange={handleDueDate} defaultValue={defaultDueDate ? moment(defaultDueDate) : null} />
                        </Col>
                    </Row>
                </Col>

                {/* Is PO Completed Select */}
                <Col xs={24} md={5}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={8} style={{ textAlign: 'right' }}>
                            {i18n.t('suppliers:supplierOrder.isPoCompleted')}:
                        </Col>

                        <Col span={16}>
                            {renderIsPoCompletedSelect(props)}
                        </Col>
                    </Row>
                </Col>

                {/* Is PO Checked Select */}
                <Col xs={24} md={5}>
                    <Row gutter={[8, 0]} type="flex" align="middle">
                        <Col span={8} style={{ textAlign: 'right' }}>
                            {i18n.t('suppliers:supplierOrder.isChecked')}:
                        </Col>

                        <Col span={16}>
                            {renderIsCheckedSelect(props)}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
            <Table
                columns={columns}
                dataSource={dataSource}
                loading={loading}
                onChange={onChangePagination}
                pagination={pagination}
                rowKey={record => record.supplier_order && record.supplier_order.id || record.id}
                scroll={isMobile ? { x: 1300 } : { x: 1000 }} />
            {renderSupplierOrderForm(props)}
            {renderPOForm(props)}
        </SupplierOrderReviewWrapper>
    )
}

SupplierOrderReviewIndex.propTypes = {
    activeOrder: PropTypes.string,
    activeSupplierOrder: PropTypes.string,
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    loadPO: PropTypes.bool,
    onCancelSupplierOrder: PropTypes.func.isRequired,
    onChangePagination: PropTypes.func.isRequired,
    onSaveSuccessPOForm: PropTypes.func.isRequired,
    onShowPOForm: PropTypes.func.isRequired,
    onShowSupplierOrderForm: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    showPOFormModal: PropTypes.bool,
    showSupplierOrderFormModal: PropTypes.bool
}

export default withTranslation(['common', 'suppliers', 'inquiries'])(imagePreviewContainer(SupplierOrderReviewIndex))
