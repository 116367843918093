import React from 'react'
import PropTypes from 'prop-types'
import { Table, Divider, DatePicker, Row } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const { RangePicker } = DatePicker

const OrderBusinessAddresses = ({
    i18n,
    orderBusinessAddresses,
    loading,
    onTableChange,
    onInvoiceDateChange,
    pagination,
    defaultInvStartDate,
    defaultInvEndDate
}) => {
    const columns = [
        {
            key: 'invoice_no',
            title: i18n.t('orders/yields:invoiceNo'),
            dataIndex: 'invoice_no',
            sorter: true
        },
        {
            key: 'invoice_date',
            title: i18n.t('orders/yields:invoiceDate'),
            dataIndex: 'invoice_date'
        },
        {
            key: 'grand_total',
            title: i18n.t('orders/yields:grandTotal'),
            dataIndex: 'grand_total'
        },
        {
            key: 'invoice_status',
            title: i18n.t('orders/yields:invoiceStatus'),
            dataIndex: 'sending_state',
            render: (data) => {
                return (
                    <div>
                        {i18n.t(`documents:reports.${_.camelCase(data)}`)}
                    </div>
                )
            }
        },
        {
            key: 'truck_reservation_date',
            title: i18n.t('orders/yields:truckReservationDate'),
            width: 120,
            dataIndex: 'truck_reservation_date'
        },
        {
            key: 'order_number',
            title: i18n.t('orders/yields:orderNumber'),
            dataIndex: 'order_number',
            render: (orderNumber) => {
                return (
                    <Link to={`/inquiries/${orderNumber}`} >
                        {orderNumber}
                    </Link>
                )
            }
        },
        {
            key: 'rv',
            title: i18n.t('orders/yields:rv'),
            width: 120,
            render: (data) => {
                const rvChecked = _.get(data, 'order_rv_checked', false)
                if (!rvChecked) return <CheckCircleOutlined />

                const whoCheckIt = _.compact([
                    _.get(data, 'order_rv_voucher_checked.checked_by.first_name', ''),
                    _.get(data, 'order_rv_voucher_checked.checked_by.last_name', '')
                ]).join(' ')
                moment.locale(i18n.language)
                const date = moment(_.get(data, 'order_rv_voucher_checked.updated_at', '')).format('LLL')
                return (
                    <p>
                        <CheckCircleOutlined />
                        <span> ฿{_.get(data, 'rv.display', '0.00')}</span>
                        <br />
                        <span>{date}</span>
                        {whoCheckIt &&
                            <>
                                <br />
                                <span>{i18n.t('orders/yields:by')}: {whoCheckIt}</span>
                            </>
                        }
                    </p>
                )
            }
        },
        {
            key: 'pv',
            title: i18n.t('orders/yields:pv'),
            width: 120,
            render: (data) => {
                const pvChecked = _.get(data, 'order_pv_checked', false)
                if (!pvChecked) return <CloseCircleOutlined />

                const whoCheckIt = _.compact([
                    _.get(data, 'order_pv_voucher_checked.checked_by.first_name', ''),
                    _.get(data, 'order_pv_voucher_checked.checked_by.last_name', '')
                ]).join(' ')
                moment.locale(i18n.language)
                const date = moment(_.get(data, 'order_pv_voucher_checked.updated_at', '')).format('LLL')
                return (
                    <p>
                        <CheckCircleOutlined />
                        <span> ฿{_.get(data, 'pv.display', '0.00')}</span>
                        <br />
                        <span>{date}</span>
                        {whoCheckIt &&
                            <>
                                <br />
                                <span>{i18n.t('orders/yields:by')}: {whoCheckIt}</span>
                            </>
                        }
                    </p>
                )
            }
        }
    ]
    return (
        <div>
            <Row type="flex" align="middle">
                <div style={{ marginRight: '10px' }}>
                    {i18n.t('orders/yields:searchByInvoiceDate')}:
                </div>
                <RangePicker
                    defaultValue={[defaultInvStartDate, defaultInvEndDate]}
                    onChange={onInvoiceDateChange}
                />
            </Row>
            <Divider />
            <Table
                columns={columns}
                dataSource={orderBusinessAddresses}
                loading={loading}
                onChange={onTableChange}
                pagination={pagination}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1080 }}
            />
        </div>
    )
}

OrderBusinessAddresses.propTypes = {
    orderBusinessAddresses: PropTypes.array,
    loading: PropTypes.bool,
    onTableChange: PropTypes.func,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })
}

export default withTranslation(['orders/yields', 'documents'])(OrderBusinessAddresses)
