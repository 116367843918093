import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import actions from '../../redux/chartOfAccounts/actions'
import notificationActions from '../../redux/notifications/actions'
import ChartOfAccountForm from '../../components/chrageOfAccounts/ChartOfAccountForm'

const { saveChartOfAccount, fetchChartOfAccountTypes } = actions
const { setNotifications } = notificationActions
class ChartOfAccountFormContainer extends Component {
  componentDidMount () {
    const { fetchChartOfAccountTypes } = this.props
    fetchChartOfAccountTypes()
  }

  getchartOfAccount () {
    const { chartOfAccountEntities, chartOfAccountId } = this.props

    if (!chartOfAccountId) { return new Map() }

    return chartOfAccountEntities.get(chartOfAccountId) || new Map()
  }

  handleSavechartOfAccount = (params) => {
    const { saveChartOfAccount, setNotifications, toggleForm, chartOfAccountId } = this.props

    saveChartOfAccount({
        id: chartOfAccountId,
        params,
        onSuccess: () => {
            setNotifications('success', 'saveSuccess', 'success')
            toggleForm()
        },
        onError: (error) => {
            setNotifications(`${error.name}`, `${error.message}`, 'error')
        }
    })
  }

  render() {
    const { toggleForm, chartOfAccounts } = this.props

    return (
      <ChartOfAccountForm
        errors={chartOfAccounts.get('errors').toJS()}
        loading={chartOfAccounts.get('loading')}
        onSubmitForm={this.handleSavechartOfAccount}
        saving={chartOfAccounts.get('saving')}
        toggleForm={toggleForm}
        typesLoading={chartOfAccounts.get('typeLoading')}
        chartOfAccountTypes={chartOfAccounts.get('chartOfAccountTypes')}
        chartOfAccount={this.getchartOfAccount().toJS()}
      />
    )
  }
}

const mapStateToProps = (state) => ({
    chartOfAccountEntities: state.getIn(['Entities', 'chartOfAccounts']),
    chartOfAccounts: state.get('chartOfAccounts')
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      saveChartOfAccount,
      setNotifications,
      fetchChartOfAccountTypes
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccountFormContainer)
