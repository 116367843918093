import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import WalletSummariesIndex from '../../components/walletSummaries/walletSummariesIndex'
import WalletSummariesWrapper from './walletSummaries.style'

import actions from '../../redux/walletSummaries/actions'
import customerWalletActions from '../../redux/customers/wallets/actions'
const { searchWallets } = actions
const { saveCustomerWallet } = customerWalletActions

class WalletSummariesIndexContainer extends Component {
    static propTypes = {
        customerWalletEntities: ImmutablePropTypes.map.isRequired,
        saveCustomerWallet: PropTypes.func.isRequired,
        searchWallets: PropTypes.func.isRequired,
        walletSummaries: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {}

    fetchWallets = (args) => {
        const { walletSummaries } = this.props
        const query = walletSummaries.getIn(['wallet', 'query'], '')
        const isApproved = walletSummaries.getIn(['wallet', 'isApproved'], '')
        this.props.searchWallets({
            query,
            isApproved,
            ...args
        })
    }

    handleChangeApproveWallet = ({ customerId, id, checked }) => {
        this.props.saveCustomerWallet({
            customerId,
            id,
            params: { is_approved: checked }
        })
    }

    handleChangeFilterApprove = (isApproved) => {
        this.fetchWallets({ isApproved })
    }

    render () {
        const { walletSummaries } = this.props

        return (
            <WalletSummariesWrapper>
                <WalletSummariesIndex
                    isApproved={walletSummaries.getIn(['wallet', 'isApproved'])}
                    onChangeApproveWallet={this.handleChangeApproveWallet}
                    onChangeFilterApprove={this.handleChangeFilterApprove}
                    searchWallets={this.fetchWallets}
                    walletIds={walletSummaries.getIn(['wallet', 'items'])}
                    walletLoading={walletSummaries.getIn(['wallet', 'loading'])}
                    walletPagination={{
                        ...walletSummaries.getIn(['wallet', 'pagination']).toJS(),
                        showSizeChanger: false
                    }}
                />
            </WalletSummariesWrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    customerWalletEntities: state.getIn(['Entities', 'customerWallets']),
    walletSummaries: state.get('walletSummaries')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        saveCustomerWallet,
        searchWallets
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletSummariesIndexContainer)
