import { all, takeLatest, put, fork, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'

import { deserialize } from '../../helpers/jsonApi'
import { saleSummariesSchema, saleProspectsSchema } from '../schema'

const {
    SALES_SUMMARY_FETCH_FAIL,
    SALES_SUMMARY_FETCH_REQUEST,
    SALES_SUMMARY_FETCH_SUCCESS,
    SALES_SUMMARY_FETCHING,
    SALES_SUMMARY_FETCH_PROSPECTS_FAIL,
    SALES_SUMMARY_FETCH_PROSPECTS,
    SALES_SUMMARY_FETCH_PROSPECTS_REQUEST,
    SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS,
    SALES_SUMMARY_PROSPECTS_SET_PAGINATION
} = actions

export function *fetchSalesSummarySaga () {
    yield takeLatest(SALES_SUMMARY_FETCH_REQUEST, function *({ payload }) {
        const { onSuccess, onError, selectedMonth, saleId } = payload
        yield put({ type: SALES_SUMMARY_FETCHING })

        try {
            const path = `/sales_summary?selected_month=${selectedMonth}&sale_id=${saleId}`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, saleSummariesSchema)

            yield put({
                type: SALES_SUMMARY_FETCH_SUCCESS,
                ...normalizedData,
                currentSale: saleId === "all" ? "all" : data.meta.current_sale,
                incomingBalancePerDate: data.meta.incoming_balance_per_date,
                prospectPerMonth: data.meta.prospect_per_month,
                prospectPerMonthCount: data.meta.prospect_per_month_count,
                allQuotationsPerMonth: data.meta.all_quotations_per_month,
                allQuotationsPerMonthCount: data.meta.all_quotations_per_month_count,
                incomingBalancePerMonth: data.meta.incoming_balance_per_month,
                incomingBalancePerMonthCount: data.meta.incoming_balance_per_month_count,
                transferBackAmount: data.meta.transfer_back_amount,
                returnPercentage: data.meta.return_percentage,
                profit: data.meta.profit,
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SALES_SUMMARY_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *fetchSaleProspectsSaga () {
    yield takeLatest(SALES_SUMMARY_FETCH_PROSPECTS_REQUEST, function *({ payload }) {
        const { onSuccess, onError, selectedMonth, saleId, per, page } = payload
        yield put({ type: SALES_SUMMARY_FETCH_PROSPECTS })

        try {
            const path = `/sales_summary/${saleId}/prospects?selected_month=${selectedMonth}&page=${page}&per=${per}`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, saleProspectsSchema)
            const total = data.meta.total_pages * per

            yield put({
                type: SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS,
                ...normalizedData
            })

            yield put({
                type: SALES_SUMMARY_PROSPECTS_SET_PAGINATION,
                pagination: { current: page, pageSize: per, total }
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SALES_SUMMARY_FETCH_PROSPECTS_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchSalesSummarySaga),
        fork(fetchSaleProspectsSaga)
    ])
}
