import { Map } from 'immutable'

import actions from './actions'

const {
    CUSTOMER_EVENT_LOGS_FILTERS
} = actions

const initState = new Map({
    filters: new Map({
        createdDate: null,
        sortBy: new Map({
            field: 'created_at',
            order: 'descend'
        })
    })
})

export default function eventLogsReducers (state = initState, action) {
    switch (action.type) {
        case CUSTOMER_EVENT_LOGS_FILTERS:
            return state.mergeDeep({
                filters: new Map(action.filters)
            })
        default:
            return state
    }
}
