import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import ProjectsIndex from '../../components/projects/projectsIndex'

import actions from '../../redux/projects/actions'

const { fetchCompanyProjects, setCompanyProjectFormModal } = actions

class ProjectsIndexContainer extends Component {
    static propTypes = {}

    static defaultProps = {}

    componentDidMount () {
        this.props.fetchCompanyProjects()
    }

    getCustomerCompany = (companyId) => {
        return this.props.customerCompanyEntities.get(companyId) || new Map()
    }

    getCompanyProjects = () => {
        const { companyProjectEntities, projects } = this.props

        return projects.get('items', new List()).map((projectId) => {
            const companyProject = companyProjectEntities.get(projectId) || new Map()
            return new Map({
                ...companyProject.toJS(),
                customer_company: this.getCustomerCompany(companyProject.get('customer_company')).toJS()
            })
        })
    }

    handleSearch = (query) => {
        const { fetchCompanyProjects } = this.props
        fetchCompanyProjects({ query, page: 1 })
    }

    handlePageChange = (pagination) => {
        this.props.fetchCompanyProjects({ page: pagination.current })
    }

    toggleCompanyProjectForm = ({ id = null } = {}) => {
        const { projects, setCompanyProjectFormModal } = this.props

        setCompanyProjectFormModal(!projects.get('showCompanyProjectForm'), id)
    }

    render () {
        const { projects } = this.props

        return (
            <ProjectsIndex
                activeProject={projects.get('projectIdSelected')}
                loading={projects.get('loading')}
                onPageChange={this.handlePageChange}
                onSearch={this.handleSearch}
                pagination={{
                    ...projects.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                companyProjects={this.getCompanyProjects().toJS()}
                showCompanyProjectForm={projects.get('showCompanyProjectForm')}
                toggleCompanyProjectForm={this.toggleCompanyProjectForm}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projects: state.get('projects'),
    companyProjectEntities: state.getIn(['Entities', 'companyProjects']),
    customerCompanyEntities: state.getIn(['Entities', 'customerCompanies'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCompanyProjects,
        setCompanyProjectFormModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsIndexContainer)
