import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import ProfitSharingForm from '../../../components/customers/profitSharing/profitSharingForm'
import actions from '../../../redux/customers/profitSharings/actions'
import notificationActions from '../../../redux/notifications/actions'

const { saveCustomerProfitSharing } = actions
const { setNotifications } = notificationActions

class ProfitSharingFormContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        id: PropTypes.string,
        profitSharing: PropTypes.object,
        toggleForm: PropTypes.func
    }

    static defaultProps = {
        toggleForm: () => {}
    }

    constructor (props) {
        super(props)

        this.state = {
            bookBankFileList: _.get(props, 'book_bank_images', []),
            idCardFileList: _.get(props, 'id_card_images', [])
        }
    }

    componentDidMount (prevProps) {
        const newBookBankImages = _.get(this.props, 'profitSharing.book_bank_images', [])
        if (!_.isEqual(newBookBankImages, _.get(prevProps, 'profitSharing.book_bank_images', []))) {
            this.setState({ bookBankFileList: newBookBankImages })
        }

        const newIdCardImages = _.get(this.props, 'profitSharing.id_card_images', [])
        if (!_.isEqual(newIdCardImages, _.get(prevProps, 'profitSharing.id_card_images', []))) {
            this.setState({ idCardFileList: newIdCardImages })
        }
    }

    getImagesData = ({ fileList = [], field }) => {
        let newImages = []
        _.forEach(fileList, (file) => {
            if (_.isEmpty(file.originFileObj)) { return }

            newImages = newImages.concat(file.originFileObj)
        })

        const ids = _.map(fileList, (file) => file.uid)
        const removeImages = _.reject(_.get(this.props.profitSharing, field, []), (file) => ids.includes(file.uid))

        return {
            [`new_${field}`]: newImages,
            [`remove_${field}`]: _.map(removeImages, (file) => file.uid)
        }
    }

    handleSaveProfitSharing = (params) => {
        const { customerId, id, saveCustomerProfitSharing, setNotifications, toggleForm } = this.props
        const { bookBankFileList, idCardFileList } = this.state

        const bookBankImages = this.getImagesData({ fileList: bookBankFileList, field: 'book_bank_images' })
        const idCardImages = this.getImagesData({ fileList: idCardFileList, field: 'id_card_images' })

        saveCustomerProfitSharing({
            customerId,
            id,
            params: { ...params, ...bookBankImages, ...idCardImages },
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    setFileList = ({ field, fileList }) => {
        this.setState({ [`${field}FileList`]: fileList })
    }

    render () {
        const { profitSharing, profitSharings, toggleForm } = this.props
        const { idCardFileList, bookBankFileList } = this.state

        return (
            <ProfitSharingForm
                setFileList={this.setFileList}
                bookBankFileList={bookBankFileList}
                idCardFileList={idCardFileList}
                onSubmitForm={this.handleSaveProfitSharing}
                profitSharing={profitSharing}
                saving={profitSharings.get('saving')}
                toggleForm={toggleForm}
            />
        )
    }
}

const mapStateToProps = state => ({
    profitSharings: state.get('profitSharings')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCustomerProfitSharing,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitSharingFormContainer)
