const actions = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    JWT_LOGIN_REQUEST: 'JWT_LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',
    login: (loginForm, { onSuccess = () => {} } = {}) => ({
        type: actions.LOGIN_REQUEST,
        payload: { loginForm, onSuccess }
    }),
    getToken: () => ({
        type: actions.GET_ACCESS_TOKEN
    }),
    logout: (router) => ({
        type: actions.LOGOUT,
        payload: { router }
    })
}
export default actions
