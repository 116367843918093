import { all, put, call, fork, select, takeLatest } from 'redux-saga/effects'

import { apiFetch } from '../../helpers/restRequest'
import actions, { provincesListSchema } from './actions'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

const {
    PROVINCES_FETCH,
    PROVINCES_FETCH_REQUEST,
    PROVINCES_FETCH_SUCCESS
} = actions

export function *provincesRequest () {
    yield takeLatest(PROVINCES_FETCH_REQUEST, function *() {
        const provinces = yield select((state) => state.getIn(['provinces', 'items']))

        // Not fetch again when provinces is already fetch
        if (provinces.toJS().length > 0) { return }

        yield put({ type: PROVINCES_FETCH })

        const data = yield call(apiFetch, '/provinces')
        const formattedData = deserialize(data)
        const normalizedData = normalize(formattedData, provincesListSchema)

        yield put({
            type: PROVINCES_FETCH_SUCCESS,
            ...normalizedData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(provincesRequest)
    ])
}
