import React from 'react'
import PropTypes from 'prop-types'
import {
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Switch,
    Button
} from 'antd'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

const PurchaseOrderStatusSwitches = ({
    i18n,
    creditDurationDays,
    dateOfPurchase,
    loading,
    onCreditDurationDaysChange,
    onDateOfPurchaseChange,
    onSubmitCreditForm,
    onSwitchChange,
    onToggleVisibleCreditModal,
    supplierOrder,
    visibleCreditModal
}) => {
    if (_.get(supplierOrder, 'state', '') === 'cancel') return null

    const approveBy = _.compact([
        _.get(supplierOrder, 'is_checked_history.staff.first_name', ''),
        _.get(supplierOrder, 'is_checked_history.staff.last_name', '')
    ]).join(' ')

    const transferDateHelp = dateOfPurchase && dateOfPurchase.clone().add('days', creditDurationDays).format('LL')

    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            sm: { span: 14, offset: 10 }
        }
    }

    const handleSubmitCredit = (values) => {
        const transferDate = dateOfPurchase &&
            dateOfPurchase.clone().add('days', creditDurationDays).format('YYYY-MM-DD')
        const dateOfPurchaseValue = _.get(values, 'payment_voucher.date_of_purchase', null)
        const toggleCheckedValue = !_.get(supplierOrder, 'is_checked', false)

        const newValues = _.merge(
            values,
            {
                supplier_order: {
                    is_checked: toggleCheckedValue,
                    transfer_date: transferDate
                },
                payment_voucher: {
                    date_of_purchase: dateOfPurchaseValue && dateOfPurchaseValue.format('YYYY-MM-DD'),
                    has_credit: true
                }
            }
        )

        onSubmitCreditForm(
            _.get(newValues, 'supplier_order', {}),
            _.get(newValues, 'payment_voucher', {})
        )
    }

    return (
        <div className="purchase-order-status-switches">
            <Row>
                <Col>
                    <Switch
                        disabled={_.get(supplierOrder, 'is_checked', false)}
                        checked={_.get(supplierOrder, 'is_po_completed', false)}
                        loading={loading}
                        onChange={(checked) => onSwitchChange({ is_po_completed: checked })}
                        size="small"
                    /> {i18n.t('suppliers:supplierOrder.isPoCompleted')}
                </Col>
            </Row>

            <Row>
                <Col>
                    <Divider />
                </Col>
            </Row>
            <Row>
                <Col>
                    {_.get(supplierOrder, 'transfer_date', '')}
                </Col>
            </Row>
            <Row>
                <Col>
                    {_.get(supplierOrder, 'grand_total.display', '')}
                </Col>
            </Row>
            <Row>
                <Col>

                    <Popconfirm
                        title={i18n.t('confirms:paymentVoucher.hasCredit?')}
                        onConfirm={() => {
                            onToggleVisibleCreditModal()
                        }}
                        onCancel={() => {
                            const toggleCheckedValue = !_.get(supplierOrder, 'is_checked', false)
                            onSwitchChange({ is_checked: toggleCheckedValue })
                        }}
                        okText={i18n.t('confirms:paymentVoucher.has')}
                        cancelText={i18n.t('confirms:paymentVoucher.hasNo')}
                    >
                        <Switch
                            disabled={_.get(supplierOrder, 'is_checked', false)}
                            checked={_.get(supplierOrder, 'is_checked', false)}
                            loading={loading}
                            size="small"
                        /> {i18n.t('suppliers:supplierOrder.isChecked')}
                    </Popconfirm>
                </Col>
            </Row>


            {approveBy &&
                <Row>
                    <Col>
                        ({approveBy})
                    </Col>
                </Row>
            }

            <Modal
                footer={null}
                key="credit-modal"
                onCancel={onToggleVisibleCreditModal}
                title={i18n.t('confirms:paymentVoucher.hasCredit')}
                visible={visibleCreditModal}
            >
                <Form
                    onFinish={handleSubmitCredit}
                    {...formItemLayout}
                >
                    <Form.Item
                        name={['payment_voucher', 'date_of_purchase']}
                        initialValue={dateOfPurchase}
                        label={i18n.t('confirms:paymentVoucher.dateOfPurchase')}
                    >
                        {/* transfer date of supplier order is date_of_purchase of pv */}
                        <DatePicker
                            placeholder={i18n.t('confirms:accounting.selectDate')}
                            onChange={(dateMoment) => { onDateOfPurchaseChange(dateMoment) }}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item
                        name={['payment_voucher', 'credit_duration_days']}
                        initialValue={creditDurationDays}
                        help={`${i18n.t('confirms:accounting.transferDate')}: ${transferDateHelp || ''}`}
                        label={i18n.t('confirms:paymentVoucher.creditDuration')}
                    >
                            <Input
                                onChange={(e) => { onCreditDurationDaysChange(e.target.value) }}
                                suffix={i18n.t('confirms:paymentVoucher.days')}
                                type="number"
                            />
                    </Form.Item>

                    <Form.Item
                        {...formItemLayoutWithOutLabel}
                    >
                        <Button type="primary" htmlType="submit" loading={loading}>Save</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Row>
                <Col>
                    <Switch
                        checked={_.get(supplierOrder, 'is_delivered', false)}
                        loading={loading}
                        onChange={(checked) => onSwitchChange({
                            is_delivered: checked, update_only_is_delivered: true
                        })}
                        size="small"
                    /> {i18n.t('suppliers:supplierOrder.isDelivered')}
                </Col>
            </Row>
        </div>
    )
}

PurchaseOrderStatusSwitches.propTypes = {
    // Props from another component
    creditDurationDays: PropTypes.number,
    dateOfPurchase: PropTypes.object,
    loading: PropTypes.bool,
    onCreditDurationDaysChange: PropTypes.func,
    onDateOfPurchaseChange: PropTypes.func,
    onSubmitCreditForm: PropTypes.func,
    onSwitchChange: PropTypes.func,
    onToggleVisibleCreditModal: PropTypes.func,
    supplierOrder: PropTypes.object,
    visibleCreditModal: PropTypes.bool,
}

PurchaseOrderStatusSwitches.defaultProps = {
    creditDurationDays: 0,
    dateOfPurchase: undefined,
    loading: false,
    onCreditDurationDaysChange: () => {},
    onDateOfPurchaseChange: () => {},
    onSubmitCreditForm: () => {},
    onSwitchChange: () => {},
    onToggleVisibleCreditModal: () => {},
    supplierOrder: {
        id: '',
        order: '', // OSH...
        is_po_completed: false,
        is_checked: false,
        is_checked_history: {
            staff: {
                first_name: '',
                last_name: ''
            }
        },
        is_po_sent_to_supplier: false,
        state: ''
    },
    visibleCreditModal: false
}

export default withTranslation(['suppliers', 'confirms'])(PurchaseOrderStatusSwitches)
