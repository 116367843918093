import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Map } from 'immutable'
import moment from 'moment'

import { Button, notification, Table, Modal, Popconfirm } from 'antd'
import { EditTwoTone, CalculatorTwoTone } from '@ant-design/icons'
import Image from '../Image'
import ImagePreviewContainer from '../ImagePreview/ImagePreviewContainer'
import PaymentDetailsFormContainer from '../Orders/Accountings/PaymentDetailsFormContainer'
import AccountingWrapper from '../Orders/Accountings/accounting.style'

import accountingActions from '../../redux/orders/accounting/actions'
import customerWalletActions from '../../redux/customers/wallets/actions'
import notificationActions from '../../redux/notifications/actions'

const { fetchPaymentDetails, setPaymentDetailsForm, resetPaymentDatailErrors } =
  accountingActions
const { saveOrderCustomerWallet } = customerWalletActions
const { setNotifications } = notificationActions

class PaymentDetailsContainer extends Component {
  componentDidMount() {
    const { fetchPaymentDetails, id } = this.props
    const page = 1

    fetchPaymentDetails(page, id)
  }

  paymentDetails = []

  paymentDetailData = () => {
    const { orderPaymentDetails, paymentDetailEntities } = this.props
    const paymentDetailItem = orderPaymentDetails.get('items').toJS()

    return paymentDetailItem.map((id, index) => {
      const item = paymentDetailEntities.get(id)

      return {
        key: id,
        transferDate: item.get('transfer_date'),
        amount: item.getIn(['amount', 'display']),
        image: item.get('order_payment_images')
      }
    })
  }

  showPaymentDetailsForm = (value) => {
    const {
      orderPaymentDetails,
      setPaymentDetailsForm,
      resetPaymentDatailErrors
    } = this.props
    const showPaymentDetailsForm = orderPaymentDetails.get(
      'showPaymentDetailsForm'
    )
    this.paymentDetails = value
    resetPaymentDatailErrors()
    setPaymentDetailsForm(!showPaymentDetailsForm)
  }

  handleCopy = () => {
    notification['success']({
      message: this.props.i18n.t('order.copyToClipboard')
    })
  }

  onAddWallets = () => {
    const { saveOrderCustomerWallet, setNotifications, id } = this.props

    saveOrderCustomerWallet({
      orderId: id,
      onSuccess: () => {
        setNotifications('success', 'saveSuccess', 'success')
      }
    })
  }

  renderOrderPaymentVerification = (paymentVerification) => {
    if (!paymentVerification) return null
    const { i18n } = this.props

    return (
      <div>
        <span>
          {i18n.t('order.paymentVerification.amount')}:{' '}
          {paymentVerification.get('amount')},{' '}
        </span>
        <span>
          {i18n.t('order.paymentVerification.receiverName')}:{' '}
          {paymentVerification.get('receiver_name')},{' '}
        </span>
        <span>
          {i18n.t('order.paymentVerification.senderName')}:{' '}
          {paymentVerification.get('sender_name')}
        </span>
      </div>
    )
  }

  renderImage = (dataIndex) => {
    return dataIndex.map((image) => {
      const imageId = image.get('id')
      const thumbUrl = image.getIn(['image', 'thumb']) || ''
      const originalUrl = image.getIn(['image', 'original']) || ''
      const contentType = image.getIn(['image', 'content_type']) || ''

      return (
        <React.Fragment>
          <Image
            key={imageId}
            contentType={contentType}
            originalUrl={originalUrl}
            thumbUrl={thumbUrl}
          />
          {this.renderOrderPaymentVerification(
            image.get('order_payment_verification')
          )}
        </React.Fragment>
      )
    })
  }

  renderAction = (value) => {
    const { paymentDetailEntities } = this.props
    const paymentDetails = value && paymentDetailEntities.get(value)

    return (
      <div className='action-container'>
        <a onClick={() => this.showPaymentDetailsForm(paymentDetails)}>
          <EditTwoTone />
        </a>
      </div>
    )
  }

  getPagination() {
    const { orderPaymentDetails } = this.props
    return orderPaymentDetails.get('pagination') || new Map()
  }

  handleTableChange = (pagination) => {
    const { fetchPaymentDetails, id } = this.props
    const page = pagination.current
    fetchPaymentDetails(page, id)
  }

  render() {
    const { i18n, orderPaymentDetails, disabled } = this.props
    const loading = orderPaymentDetails.get('loading')
    const showPaymentDetailsForm = orderPaymentDetails.get(
      'showPaymentDetailsForm'
    )

    const paymentDetailColumn = [
      {
        title: i18n.t('confirms:accounting.transferDate'),
        dataIndex: 'transferDate',
        key: 'transferDate',
        render: (dataIndex) => {
          if (!dataIndex) {
            return
          }
          moment.locale(i18n.language)
          return moment(dataIndex, 'YYYY-MM-DD').format('D MMMM YYYY')
        }
      },
      {
        title: i18n.t('confirms:accounting.amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (dataIndex) => {
          return `${dataIndex} ${i18n.t('confirms:accounting.bath')}`
        }
      },
      {
        title: i18n.t('confirms:accounting.slip'),
        key: 'image',
        render: (data) => {
          return (
            <AccountingWrapper>
              <div className='image-container'>
                {this.renderImage(data.image)}
              </div>
            </AccountingWrapper>
          )
        }
      },
      {
        dataIndex: 'key',
        className: !disabled ? 'show' : 'hide',
        render: (dataIndex) => this.renderAction(dataIndex)
      }
    ]

    return (
      <AccountingWrapper>
        <div className='header'>
          <h4> {i18n.t('confirms:accounting.paymentDetails')} </h4>
          <div className='buttons'>
            {disabled ? null : (
              <Popconfirm
                title={i18n.t('customers/wallets:confirmAddWallet')}
                onConfirm={this.onAddWallets}
                okText='Yes'
                cancelText='No'>
                <Button
                  type='primary'
                  ghost
                  size='small'
                  className='wallet-button'>
                  <CalculatorTwoTone />{' '}
                  {i18n.t('customers/wallets:transactionTypes.deposit')}
                </Button>
              </Popconfirm>
            )}
            {disabled ? null : (
              <Button
                type='primary'
                size='small'
                onClick={() => this.showPaymentDetailsForm()}>
                + {i18n.t('confirms:add')}
              </Button>
            )}
          </div>
        </div>
        <Modal
          visible={showPaymentDetailsForm}
          destroyOnClose
          onCancel={() => this.showPaymentDetailsForm()}
          width='700px'
          footer={null}>
          <PaymentDetailsFormContainer
            paymentDetails={this.paymentDetails}
            page='inquiries'
          />
        </Modal>
        <Table
          columns={paymentDetailColumn}
          dataSource={this.paymentDetailData()}
          pagination={{
            ...this.getPagination().toJS(),
            showSizeChanger: false
          }}
          onChange={this.handleTableChange}
          loading={loading}
          size='small'
        />
      </AccountingWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  orderPaymentDetails: state.get('orderPaymentDetails'),
  paymentDetailEntities: state.getIn(['Entities', 'paymentDetails'])
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPaymentDetailsForm,
      resetPaymentDatailErrors,
      fetchPaymentDetails,
      saveOrderCustomerWallet,
      setNotifications
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(['common', 'confirms', 'customers/wallets'])(
      ImagePreviewContainer(PaymentDetailsContainer)
    )
  )
)
