import { Map, List } from 'immutable'

import { PAGE_SIZE } from '../../constants/orders'
import actions from './actions'

const {
    SHIPPING_RATES_FETCH_SUCCESS,
    SHIPPING_RATES_FETCHING,
    SHIPPING_RATES_SET_FILTERS,
    SHIPPING_RATES_SET_PAGINATION
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    filters: new Map({
        province: '',
        district: ''
    }),
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    })
})

export default function shippingRatesReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case SHIPPING_RATES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List([].concat(result))
            })
        case SHIPPING_RATES_FETCHING:
            return state.merge({ loading: true })
        case SHIPPING_RATES_SET_FILTERS:
            return state.mergeDeep({ filters: new Map(action.filters) })
        case SHIPPING_RATES_SET_PAGINATION:
            return state.mergeDeep({ pagination: new Map(action.pagination) })
        default:
            return state
    }
}
