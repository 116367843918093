import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button, Popconfirm } from 'antd'
import moment from 'moment'

import actions from '../../redux/paymentVouchers/actions'
import notificationActions from '../../redux/notifications/actions'

const { exportPaymentVoucherWithoutInvoices } = actions
const { setNotifications } = notificationActions

const SupplierInvoicesPaymentVoucherExportContainer = ({ i18n, selectedMonth }) => {
    const dispatch = useDispatch()
    const paymentVouchers = useSelector(state => state.get('paymentVouchers'))
    const exporting = paymentVouchers.get('exporting')

    const onSubmit = () => {
        dispatch(exportPaymentVoucherWithoutInvoices({
            params: { monthly: selectedMonth },
            onSuccess: () => {
                    dispatch(setNotifications(i18n.t('supplierInvoices:uploadSuccessDescription'), 'success', 'success'))
                },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        }))
    }

    const text = `${i18n.t('supplierInvoices:downloadPvWithoutInvocies')} ${i18n.t('supplierInvoices:month')} ${moment(selectedMonth).format('MMMM YYYY')}`
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={onSubmit} okText="Yes" cancelText="No">
          <Button
              loading={exporting}
              type="primary">
                {i18n.t('supplierInvoices:downloadPvWithoutInvocies')}
        </Button>
      </Popconfirm>
    )
}

export default withTranslation(['shared', 'supplierInvoices'])(SupplierInvoicesPaymentVoucherExportContainer)
