import React from 'react'
import { Tabs } from 'antd'
import { bindActionCreators } from 'redux'

import { TAB_KEYS, COOKIE_KEY } from '../../../../constants/inquiries'

import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Map, List } from 'immutable'

import ActivityLogs from '../../ActivityLogsTab'
import HistoryDashboard from '../../../Inquiry/HistoryDashboardContainer'
import InquiryItems from '../../InquiryItems'
import NoteImageTab from '../../NoteImageTab'
import OrderNoteContainer from '../../../Orders/OrderNoteContainer'
import SupplierPurchaseList from '../../../Suppliers/Orders/SupplierPurchaseList'
import TrackingContainer from '../../TrackingContainer'
import ReviewContainer from '../../ReviewContainer'

import jsCookie from 'js-cookie'

const { TabPane } = Tabs

const handleOnTabChange = (activeTabKey) => {
    jsCookie.set(COOKIE_KEY, activeTabKey)
}

const InquiryContainerBody = ({ i18n, id, orderEntities, inquiry, customerEntities }) => {
    const currentTabKey = jsCookie.get(COOKIE_KEY)

    const orderEntity = orderEntities.get(inquiry.get('current')) || new Map()
    const customerId = orderEntity.getIn(['customer', 'id'])
    const customerCompanies = customerEntities.getIn([customerId, 'customer_companies'], new List())
    const companyId = customerCompanies.toJS()[0]
    const isLineItemsLoading = inquiry.get('lineItemsLoading')

    return (
        <Tabs
            defaultActiveKey={currentTabKey || TAB_KEYS.EDIT}
            tabBarStyle={{ margin: 0 }}
            onChange={handleOnTabChange}>
            <TabPane key={TAB_KEYS.EDIT} tab={i18n.t('order.detail')}>
                <InquiryItems id={id} orderEntity={orderEntity}
                    companyId={companyId} customerCompanyIds={customerCompanies} />
            </TabPane>
            <TabPane key={TAB_KEYS.COMMENTS} tab='Comments'>
                <OrderNoteContainer id={id} />
            </TabPane>
            <TabPane tab={i18n.t('inquiries:attachedFile')} key='noteImage'>
                <NoteImageTab id={id} />
            </TabPane>
            <TabPane tab='Activity Logs' key='activityLogs'>
                <ActivityLogs id={id} />
            </TabPane>
            <TabPane key='purchaseList' tab={i18n.t('suppliers:purchaseList')}>
                <SupplierPurchaseList orderId={id} />
            </TabPane>
            <TabPane key='history-dashboard' tab={'History Dashboard'}>
                <HistoryDashboard orderId={id} />
            </TabPane>
            <TabPane tab={i18n.t('inquiries:shipment')} key='tracking'>
                <TrackingContainer orderId={id} orderState={orderEntity.get('state')} />
            </TabPane>
            <TabPane tab={i18n.t('inquiries:review')} key='review'>
                <ReviewContainer orderId={id} order={orderEntity} isLineItemsLoading={isLineItemsLoading} />
            </TabPane>
        </Tabs>
    )
}

const mapStateToProps = (state) => ({
    inquiry: state.get('inquiry'),
    staffProfiles: state.get('staffProfiles'),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    customerEntities: state.getIn(['Entities', 'customers']),
    customerRoleEntities: state.getIn(['Entities', 'customerRoles']),
    customerReviewEntities: state.getIn(['Entities', 'customerReviews'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(['inquiries', 'suppliers'])(withRouter(InquiryContainerBody)))
