import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

import { safeGet } from '../../helpers/string'

const ORDER_TASK_STATES_QUERY = gql`
	query OrderTaskStates {
		orderTaskStates {
			label
			value
		}
	}
`

const renderTaskStateOptions = (options) => {
	return options.map(({ label, value }) => {
		return <Select.Option value={value}>{label}</Select.Option>
	})
}

const SelectOrderTaskState = ({
	defaultValue,
	onChange,
	value
}) => {
	const { i18n } = useTranslation('orders')
	const isFirstRun = useRef(true)
	const [selected, setSelected] = useState(value || defaultValue)

	const { loading, data } = useQuery(ORDER_TASK_STATES_QUERY)

	useEffect(() => {
		if (isFirstRun.current) { isFirstRun.current = false; return }
		if (value !== selected) { setSelected(value) }
	}, [value])

	const onChangeSelected = (value) => {
		setSelected(value)
		onChange(value)
	}

	return (
		<Select allowClear
			loading={loading}
			onChange={onChangeSelected}
			placeholder={i18n.t('orders:filterByTaskState')}
			value={selected}
			style={{ width: '220px' }}>
			{renderTaskStateOptions(safeGet(data, 'orderTaskStates', []))}
		</Select>
	)
}

SelectOrderTaskState.propTypes = {
	defaultValue: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string
}

SelectOrderTaskState.defaultProps = {
	onChange: () => {}
}

export default SelectOrderTaskState
