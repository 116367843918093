import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import actions from '../../redux/staffs/actions'

import StaffForm from '../../components/staffs/StaffForm'
import CurrentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

const {
    fetchStaff,
    fetchStaffRoles,
    saveStaff
} = actions

class StaffFormContainer extends Component {
  static propTypes = {
      entities: ImmutablePropTypes.map.isRequired,
      fetchStaff: PropTypes.func.isRequired,
      fetchStaffRoles: PropTypes.func.isRequired,
      id: PropTypes.string,
      router: PropTypes.object.isRequired,
      saveStaff: PropTypes.func.isRequired,
      staffs: ImmutablePropTypes.map.isRequired
  }

    componentDidMount () {
        this.fetchStaff()
    }

    componentDidUpdate (prevProps) {
        const { currentStaff } = this.props

        if (prevProps.currentStaff.size !== currentStaff.size) {
            this.fetchStaff()
        }
    }

    fetchStaff = () => {
        const { fetchStaff, fetchStaffRoles, id, isCurrentStaffA } = this.props
        const isSuperAdmin = isCurrentStaffA('super_admin')
        if (!isSuperAdmin) { return null }

        fetchStaffRoles()

        if (id) {
            fetchStaff(id)
        }
    }

  handleSubmitStaff = (staff) => {
      const { saveStaff, router, id } = this.props

      saveStaff(staff, router, id)
  }

  getStaff () {
      const { entities, id } = this.props
      const staffEntities = entities.get('staffs')

      return staffEntities.get(`${id}`) || new Map()
  }

  getStaffRoles () {
      const { entities, staffs } = this.props
      const roleEntities = entities.get('staffRoles')
      const roleId = staffs.get('roleItems')

      return roleId.map((id) => roleEntities.get(`${id}`))
  }

  getFormErrors () {
      const { staffs } = this.props
      const formErrors = staffs.get('formErrors')

      return formErrors
  }

  render () {
      const { id, staffs, currentStaff, isCurrentStaffA } = this.props
      const staffSaving = staffs.get('saving')
      const loading = staffs.get('loading')
      const isSuperAdmin = isCurrentStaffA('super_admin')

      if (currentStaff.size && !isSuperAdmin) { return <Redirect to='/' /> }

      return (
          <StaffForm staffId={id}
              staffRoles={this.getStaffRoles()}
              staffLoading={loading}
              handleSubmit={this.handleSubmitStaff}
              staff={this.getStaff()}
              staffSaving={staffSaving}
              formErrors={this.getFormErrors()} />
      )
  }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    staffs: state.get('staffs')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchStaff,
        fetchStaffRoles,
        saveStaff
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrentStaffContainer(StaffFormContainer)))
