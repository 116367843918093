import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import Render from './render'

export const LIMIT = 50
export const NODE_FIELDS = `
    {
        id
        customerPersonality
        customerType
        customerTypeLabel
        interestingProduct
        month
        year
        isPotential
        archived
        status
        statusLabel
        createdAt {
            display
        }
        order {
            id
            number
            note
            marginPercent
            margin {
                display
            }
            grandTotal {
                display
            }
            customer {
                customerNo
                fullName
                lineUrl
                phoneNumber
                customerType {
                    id
                    name
                }
            }
        }
        staff {
            id
            fullName
        }
    }
`

export const MARGIN_SUMMARY_FIELDS = `
    marginSummary {
        sumOfGrandTotal {
            display
        }
        sumOfMargin {
            display
        }
        sumOfPotentialMargin {
            display
        }
        sumOfSuccessMargin {
            display
        }
    }
`

const UPDATE_SALE_PIPELINE_MUTATION = gql`
    mutation UpdateSalePipeline($orderId: String, $salePipelineId: ID, $salePipelineParams: AdminSalePipelineInput!){
        updateSalePipeline(orderId: $orderId, salePipelineId: $salePipelineId, salePipelineParams: $salePipelineParams){
          salePipeline {
                id
            }
        }
    }
`

const onGenerateData = (prevNodes, fetchMoreResult) => {
    const salePipelinesWithSummaries = fetchMoreResult.salePipelinesWithSummaries || {}
    const salePipelinesConnection = (salePipelinesWithSummaries && salePipelinesWithSummaries.salePipelinesConnection) || {}
    const nodes = salePipelinesConnection.nodes || []

    return {
        salePipelinesWithSummaries: {
            ...salePipelinesWithSummaries,
            salePipelinesConnection: {
                ...salePipelinesConnection,
                nodes: [...prevNodes, ...nodes]
            }
        }
    }
}

const SalePipelineTable = ({
    filters,
    loading,
    onFetchMore,
    pageInfo,
    salePipelines,
    refetch
}) => {
    const [loadMore, setLoadMore] = useState(false)
    const [activeId, setActiveId] = useState(null)
    const [activeCustomerId, setActiveCustomerId] = useState(null)
    const [activeOrderId, setActiveOrderId] = useState(null)
    const [showCustomerTypeEditForm, setCustomerTypeEditFormModal] = useState(false)
    const [showEditForm, setEditFormModal] = useState(false)

    const [updateSalePipeline, { loading: saving }] = useMutation(UPDATE_SALE_PIPELINE_MUTATION, {
        onCompleted: () => {
            refetch()
        }
    })

    const onLoadMore = () => {
        if (!pageInfo.hasNextPage) return null

        setLoadMore(true)

        onFetchMore({
            variables: { first: LIMIT, after: pageInfo.endCursor, ...filters },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) { return prev }

                setLoadMore(false)

                return onGenerateData(salePipelines, fetchMoreResult)
            }
        })
    }

    const onSaveSalePipeline = (salePipelineId, params, orderId) => {
        updateSalePipeline({
            variables: {
                orderId: orderId || null,
                salePipelineId: salePipelineId,
                salePipelineParams: params
            }
        })
    }

    const onToggleCustomerTypeEditForm = (event, { customerId = null } = {}) => {
        event && event.preventDefault()

        setActiveCustomerId(customerId)
        setCustomerTypeEditFormModal(!showCustomerTypeEditForm)
    }

    const onToggleEditForm = ({ id = null, orderId = null } = {}) => {
        setActiveId(id)
        setActiveOrderId(orderId)
        setEditFormModal(!showEditForm)
    }

    return (
        <Render
            activeId={activeId}
            activeCustomerId={activeCustomerId}
            activeOrderId={activeOrderId}
            dataSource={salePipelines}
            onLoadMore={onLoadMore}
            onSaveSalePipeline={onSaveSalePipeline}
            onToggleCustomerTypeEditForm={onToggleCustomerTypeEditForm}
            onToggleEditForm={onToggleEditForm}
            pageInfo={pageInfo}
            refetch={refetch}
            saving={saving}
            showCustomerTypeEditForm={showCustomerTypeEditForm}
            showEditForm={showEditForm}
            spinning={loading || loadMore}
        />
    )
}

SalePipelineTable.propTypes = {
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onFetchMore: PropTypes.func.isRequired,
    pageInfo: PropTypes.object.isRequired,
    salePipelines: PropTypes.array.isRequired,
    refetch: PropTypes.func.isRequired
}

SalePipelineTable.defaultProps = {}

export default SalePipelineTable
