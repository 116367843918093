import { Map, List } from 'immutable'
import actions from './actions'

const {
    PROJECT_CONTACTS_FETCHING,
    PROJECT_CONTACTS_FETCH_SUCCESS,
    PROJECT_CONTACTS_SET_PAGINATION,
    PROJECT_CONTACT_SAVING,
    PROJECT_CONTACT_SAVE_SUCCESS,
    PROJECT_CONTACT_SET_FORM_ERRORS,
    PROJECT_CONTACT_SET_FORM_MODAL,
    PROJECT_CONTACT_FETCHING,
    PROJECT_CONTACT_FETCH_SUCCESS,
    PROJECT_CONTACT_DELETE,
    PROJECT_CONTACT_DELETE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    }),
    showContactForm: false,
    contactIdSelected: null,
    errors: new Map()
})

export default function projectContactsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case PROJECT_CONTACTS_FETCHING:
            return state.merge({ loading: true })
        case PROJECT_CONTACTS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case PROJECT_CONTACTS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case PROJECT_CONTACT_SAVING:
            return state.merge({ saving: action.saving })
        case PROJECT_CONTACT_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(result) ? new List(items) : new List(items.push(result))
            })
        case PROJECT_CONTACT_SET_FORM_ERRORS:
            return state.merge({
                errors: new Map(action.formErrors)
            })
        case PROJECT_CONTACT_SET_FORM_MODAL:
            return state.merge({
                showContactForm: action.visible,
                contactIdSelected: action.id
            })
        case PROJECT_CONTACT_FETCHING:
            return state.merge({
                loading: action.loading
            })
        case PROJECT_CONTACT_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? new List(items) : new List(items.push(result))
            })
        case PROJECT_CONTACT_DELETE:
            return state.merge({ loading: true })
        case PROJECT_CONTACT_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.delete(items.indexOf(result)))
            })
        default:
            return state
    }
}
