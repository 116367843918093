import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Layout } from 'antd'
import WindowResizeListener from 'react-window-size-listener'
import { Debounce } from 'react-throttle'
import { ThemeProvider } from 'styled-components'
import AlertContainer from '../Notifications/AlertContainer'
import AppHolder from './commonStyle'
import themeDefault from '../../../config/theme/themedefault'

import { siteConfig } from '../../../config/index'

import appActions from '../../redux/app/actions'
import authActions from '../../redux/auth/actions'

const { toggleAll } = appActions
const { getToken } = authActions
const { Content, Footer } = Layout

class Header extends Component {
    componentWillMount () {
        this.props.getToken()
    }

    render () {
        const { App, children, toggleAll } = this.props

        if (App.get('height') === 0) {
            return (
                <div>
                    <Debounce time="90" handler="onResize">
                        <WindowResizeListener
                            onResize={windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
                        />
                    </Debounce>
                </div>
            )
        }

        return (
            <header>
                <ThemeProvider theme={themeDefault}>
                    <AppHolder>
                        <Layout style={{ height: '100vh', marginTop: 40 }}>
                            <Debounce time="90" handler="onResize">
                                <WindowResizeListener
                                    onResize={windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
                                />
                            </Debounce>
                            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                                <AlertContainer />
                                <Layout className="" style={{ height: '100vh' }}>
                                    <Content
                                        style={{
                                            flexShrink: '0',
                                            background: '#f1f3f6',
                                            width: '100%'
                                        }}
                                    >
                                        {children}
                                    </Content>
                                    <Footer
                                        style={{
                                            background: '#ffffff',
                                            textAlign: 'center',
                                            borderTop: '1px solid #ededed'
                                        }}
                                    >
                                        {siteConfig.footerText}
                                    </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </AppHolder>
                </ThemeProvider>
            </header>
        )
    }
}

const mapStateToProps = state => ({
    App: state.get('App')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getToken,
        toggleAll
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
