import { Map, List } from 'immutable'

import actions from './actions'

const {
    ORDER_HISTORY_DASHBOARDS_FETCH_FAIL,
    ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS,
    ORDER_HISTORY_DASHBOARDS_FETCH,
    ORDER_HISTORY_DASHBOARDS_SET_REFETCH
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    refetch: false
})

export default function orderHistoryDashboardReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_HISTORY_DASHBOARDS_FETCH_FAIL:
            return state.merge({ loading: false })
        case ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS:
            return state.merge({ loading: false, items: new List([result]) })
        case ORDER_HISTORY_DASHBOARDS_FETCH:
            return state.merge({ loading: true })
        case ORDER_HISTORY_DASHBOARDS_SET_REFETCH:
            return state.merge({ refetch: action.refetch })
        default:
            return state
    }
}
