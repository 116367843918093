import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import _ from 'lodash'

import { Select, Typography } from 'antd'

import actions from '../../redux/productGroupItems/actions'

const { fetchRootItemAttributes } = actions

const SelectRootItemAttribute = ({
    onChange,
    productGroupItemId,
    value
}) => {
    const { i18n } = useTranslation('productGroups')
    const dispatch = useDispatch()
    const items = useSelector(state => state.getIn(['productGroupItems', 'rootItemAttributesItems']) || new List())
    const loading = useSelector(state => state.getIn(['productGroupItems', 'rootItemAttributesLoading']) || false)
    const itemAttributeEntities = useSelector(state => state.getIn(['Entities', 'itemAttributes']) || new Map())
    const [innerValue, setInnerValue] = useState(undefined)

    useEffect(() => {
        if (productGroupItemId) {
            dispatch(fetchRootItemAttributes({ productGroupItemId }))
        }
    }, [productGroupItemId])

    useEffect(() => {
        if (value !== innerValue) { setInnerValue(value) }
    }, [value])

    if (!productGroupItemId) { return null }

    const handleChange = (newValue) => {
        setInnerValue(newValue)
        onChange(newValue)
    }

    const onFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const renderOptions = () => {
        return itemAttributes.map((itemAttribute) => {
            return (
                <Select.Option value={itemAttribute.get('id')}>
                    {itemAttribute.get('key')}: {itemAttribute.get('value')}
                </Select.Option>
            )
        })
    }

    const renderLabels = () => {
        const keys = _.keys(_.groupBy(itemAttributes.toJS(), 'key'))

        return (
            <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }} ellipsis>
                {keys.join('/')}
            </Typography.Paragraph>
        )
    }

    const itemAttributes = items.map((id) => itemAttributeEntities.get(`${id}`) || new Map())

    return (
        <div>
            {renderLabels()}
            <Select
                filterOption={onFilterOption}
                loading={loading}
                onChange={handleChange}
                placeholder={i18n.t('productGroups:selectItemAttribute')}
                value={innerValue}
                style={{ width: '100%' }}
                showSearch>
                {renderOptions()}
            </Select>
        </div>
    )
}

SelectRootItemAttribute.propTypes = {
    onChange: PropTypes.func,
    productGroupItemId: PropTypes.string,
    value: PropTypes.string
}

SelectRootItemAttribute.defaultProps = {
    onChange: () => {}
}

export default SelectRootItemAttribute
