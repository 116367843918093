import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Map, List } from 'immutable'
import { Form, Spin, Row, Col, Input, Button, Divider, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import actions from '../../../redux/customers/addresses/shippingAddresses/actions'

import customersActions from '../../../redux/customers/actions'
import AreasSelectContainer from '../../Areas/AreasSelectContainer'
import Image from '../../Image'
import ImagePreviewContainer from '../../ImagePreview/ImagePreviewContainer'


const { saveShippingAddress, fetchShippingAddress } = actions
const { fetchCustomer } = customersActions
const { TextArea } = Input
class AddressFormContainer extends Component {
    static propTypes = {
        customerEntities: ImmutablePropTypes.map.isRequired,
        fetchCustomer: PropTypes.func.isRequired,
        fetchShippingAddresses: PropTypes.func.isRequired,
        locationParam: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        saveShippingAddress: PropTypes.func.isRequired,
        shippingAddressEntities: ImmutablePropTypes.map.isRequired,
        shippingAddresses: ImmutablePropTypes.map.isRequired,
        showSubmitBtn: PropTypes.bool,
        isAnotherForm: PropTypes.bool,
        onSuccess: PropTypes.func
    }

    static defaultProps = {
        locationParam: null,
        showSubmitBtn: false,
        isAnotherForm: false,
        onSuccess: () => {}
    }

    constructor() {
        super()
        this.formRef = createRef()

        this.state = {
            fileList: []
        }
    }

    componentDidMount () {
        const {
            customerId,
            fetchCustomer,
            fetchShippingAddress,
            shippingAddressId,
            shippingAddressEntities,
            imagesEntities
        } = this.props

        if (!customerId) { return null }

        fetchCustomer(customerId)

        if (shippingAddressId) {
            fetchShippingAddress(customerId, shippingAddressId)

            const images = shippingAddressEntities.getIn([
                shippingAddressId,
                'shipping_address_images'
            ]) || new List()

            const imageList = images.map((image) => {
                return {
                    uid: image.get('id'),
                    url: imagesEntities.getIn([image.getIn(['file', 's']), 'thumbBlob'])
                }
            })

            this.setState({ fileList: imageList.toJS() })
        }
    }

    handleSubmit = (values) => {
        const {
            customerId,
            shippingAddressId,
            saveShippingAddress,
            shippingAddressEntities,
            onSuccess
        } = this.props

            let file
            const shippingAddress = {}
            Object.entries(values).map((field) => {
                shippingAddress[field[0]] = (field[1] === undefined) ? null : field[1]
            })

            const shippingAddressImages = shippingAddressEntities.getIn([
                shippingAddressId,
                'shipping_address_images'
            ]) || new List()
            let shippingAddressParams = shippingAddress

            if (shippingAddress['shipping_address_images_attributes']) {
                const imageValue = shippingAddress['shipping_address_images_attributes']
                let destroyImage = []
                let uploadImage = imageValue

                shippingAddressImages.map((image) => {
                    if (!imageValue.filter(value => value.uid === image.get('id')).length) {
                        destroyImage.push({ id: image.get('id'), _destroy: true })
                    } else {
                        uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
                    }
                })

                file = uploadImage ? (
                    uploadImage.map((id) => {
                        return {
                            file: id.originFileObj,
                            _destroy: false
                        }
                    }).concat(destroyImage)
                ) : null

                shippingAddressParams = {
                    ...values,
                    shipping_address_images_attributes: file
                }
            }

            saveShippingAddress(
                customerId,
                shippingAddressId,
                shippingAddressParams,
                onSuccess
            )
    }

    setFileList = (fileList) => this.setState(fileList)

    renderUploadImages () {
        const { i18n } = this.props

        return (
            <Upload.Dragger
                multiple
                listType="picture-card"
                showUploadList={{ showPreviewIcon: false }}
                beforeUpload={() => false}
                fileList={this.state.fileList}
                onChange={this.setFileList}
            >
                <div>
                    <PlusOutlined />
                    <div>{i18n.t('order.image')}</div>
                </div>

            </Upload.Dragger>
        )
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImages () {
        const { shippingAddressEntities, shippingAddressId } = this.props
        const imageList = shippingAddressEntities.getIn([
            shippingAddressId,
            'shipping_address_images'
        ]) || new List()

        return imageList.map((image) => {
            const thumbUrl = image.getIn(['file', 's']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            return (
                <Image
                    key={thumbUrl}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderForm = (form) => {
        const {
            locationParam,
            shippingAddressId,
            shippingAddresses,
            shippingAddressEntities,
            showSubmitBtn,
            i18n,
            customerEntities,
            customerId,
        } = this.props

        const saving = shippingAddresses.get('saving')
        const formErrors = shippingAddresses.get('formErrors')
        const shippingAddress = shippingAddressEntities.get(shippingAddressId) || new Map()

        const provinceValue = shippingAddress.getIn(['province', 'id'])
        const districtValue = shippingAddress.getIn(['district', 'id'])
        const postalCodeValue = shippingAddress.get('postal_code')

        const contactNumberFormErrors = formErrors.get('phone_number_normalized')
        const contactNameFormErrors = formErrors.get('contact_name')
        const shippingDetailFormErrors = formErrors.get('shipping_detail')
        const additionalInformationFormErrors = formErrors.get('additional_information')
        const linkUrlFormErrors = formErrors.get('link_url')

        const provinceFormErrors = formErrors.get('province')
        const checkProvinceFormErrors = provinceFormErrors ? 'error' : null
        const provinceHelp = provinceFormErrors && provinceFormErrors.join(', ')

        const districtFormErrors = formErrors.get('district')
        const checkDistrictFormErrors = districtFormErrors ? 'error' : null
        const districtHelp = districtFormErrors && districtFormErrors.join(', ')

        const postalCodeFormErrors = formErrors.get('postal_code')
        const checkPostalCodeFormErrors = postalCodeFormErrors ? 'error' : null
        const postalCodeHelp = postalCodeFormErrors && postalCodeFormErrors.join(', ')

        const customerPhoneNumber = customerEntities.getIn([customerId, 'phone_number'])
        const customerName = customerEntities.getIn([customerId, 'first_name'])
        const linkUrl = customerEntities.getIn([customerId, 'link_url']) || ''

        return (
            <div>
                <AreasSelectContainer form={form}
                    districtFieldName={Array.isArray(locationParam) ? locationParam.concat('district_id') : 'district_id'}
                    districtHelp={districtHelp}
                    districtValidateStatus={checkDistrictFormErrors}
                    districtValue={districtValue}
                    postalCodeFieldName={Array.isArray(locationParam) ? locationParam.concat('postal_code') : 'postal_code'}
                    postalCodeHelp={postalCodeHelp}
                    postalCodeValidateStatus={checkPostalCodeFormErrors}
                    postalCodeValue={postalCodeValue}
                    provinceFieldName={Array.isArray(locationParam) ? locationParam.concat('province_id') : 'province_id'}
                    provinceHelp={provinceHelp}
                    provinceValidateStatus={checkProvinceFormErrors}
                    provinceValue={provinceValue}
                    postalCodeFormItemLayout={{ wrapperCol: { md: { span: 20 }, sm: { span: 20 } } }}
                />
                <Row span={24}>
                    <Col span={10}>
                        <Form.Item label={i18n.t('customerForm.contactName')}
                            name={Array.isArray(locationParam) ? locationParam.concat('contact_name') : 'contact_name'}
                            initialValue={shippingAddress.get('contact_name') || customerName}
                            validateStatus={contactNameFormErrors ? 'error' : null}
                            help={contactNameFormErrors && contactNameFormErrors.join(', ')}>
                            <Input placeholder={i18n.t('customerForm.contactName')} />
                        </Form.Item>
                    </Col>
                    <Col offset={4} span={10}>
                        <Form.Item label={i18n.t('customerForm.phoneOnJob')}
                            name={Array.isArray(locationParam) ? locationParam.concat('contact_number') : 'contact_number'}
                            initialValue={shippingAddress.get('contact_number') || customerPhoneNumber}
                            validateStatus={contactNumberFormErrors ? 'error' : null}
                            help={contactNumberFormErrors && contactNumberFormErrors.join(', ')}>
                            <Input placeholder={i18n.t('customerForm.phoneOnJob')} />
                        </Form.Item>
                    </Col>
                </Row>
                <Col span={24}>
                    <Form.Item label={i18n.t('customerForm.deliveryDetail')}
                        validateStatus={shippingDetailFormErrors ? 'error' : null}
                        name={Array.isArray(locationParam) ? locationParam.concat('shipping_detail') : 'shipping_detail'}
                        initialValue={shippingAddress.get('shipping_detail')}
                        help={shippingDetailFormErrors && shippingDetailFormErrors.join(', ')}>
                            <Input placeholder={i18n.t('customerForm.deliveryDetail')} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={i18n.t('customerForm.additionalInformation')}
                        validateStatus={additionalInformationFormErrors ? 'error' : null}
                        name={Array.isArray(locationParam) ? locationParam.concat('additional_information') : 'additional_information'}
                        initialValue={shippingAddress.get('additional_information') || null}
                        help={additionalInformationFormErrors && additionalInformationFormErrors.join(', ')}>
                            <TextArea placeholder={i18n.t('customerForm.additionalInformation')} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={i18n.t('addresses:linkUrl')}
                        validateStatus={linkUrlFormErrors ? 'error' : null}
                        name={Array.isArray(locationParam) ? locationParam.concat('link_url') : 'link_url'}
                        initialValue={shippingAddress.get('link_url') || linkUrl}
                        help={linkUrlFormErrors && linkUrlFormErrors.join(', ')}>
                            <Input placeholder="url" />
                    </Form.Item>
                </Col>
                <Row>
                    <Col span={24}>
                        <Form.Item label={i18n.t('order.image')}
                            name={Array.isArray(locationParam) ? locationParam.concat('shipping_address_images_attributes')
                                : 'shipping_address_images_attributes'}
                            getValueFromEvent={this.normFile}>
                            {this.renderUploadImages()}
                        </Form.Item>
                    </Col>
                </Row>
                {showSubmitBtn ?
                    <Button type="primary"  htmlType="submit" loading={saving}>
                        {i18n.t('customerForm.save')}
                    </Button> : null}
                <Divider />
                <div>{this.renderImages()}</div>
            </div>
        )
    }

    render () {
        const {
            shippingAddresses,
            isAnotherForm,
            layout,
            formProps
        } = this.props

        const loading = shippingAddresses.get('loading')

        if (loading) { return <Spin size="small" /> }

        if (isAnotherForm) {
            return this.renderForm(formProps)
        } else {
            return (
                <Form onFinish={this.handleSubmit} ref={this.formRef} layout={layout || 'vertical'}>
                    {this.renderForm(this.formRef)}
                </Form>
            )
        }

    }
}

const mapStateToProps = state => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    imagesEntities: state.getIn(['Entities', 'images']),
    shippingAddressEntities: state.getIn(['Entities', 'shippingAddresses']),
    shippingAddresses: state.get('shippingAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomer,
        fetchShippingAddress,
        saveShippingAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['common', 'addresses'])(ImagePreviewContainer(AddressFormContainer))
)
