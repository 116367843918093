import React from 'react'
import { Link } from 'react-router-dom'

import { Breadcrumb } from 'antd'
import { LayoutContentWrapper } from './layoutWrapper.style'

const renderBreadCrumb = (props) => {
    if (!props.breadcrumbs) {
        return null
    }
    const breadcrumbsDom = props.breadcrumbs.map((breadcrumb, index) => {
        return (
            <Breadcrumb.Item key={index}>
                <Link to={breadcrumb.path}>
                    {breadcrumb.name}
                </Link>
            </Breadcrumb.Item>
        )
    })
    return (
        <Breadcrumb className="breadcrumbContentWrapper">
            <Breadcrumb.Item>
                <Link to={`/`}>
                    Home
                </Link>
            </Breadcrumb.Item>
            { breadcrumbsDom }
        </Breadcrumb>
    )
}

export default props => (
    <LayoutContentWrapper
        className={
            props.className != null ?
                `${props.className} isoLayoutContentWrapper` :
                'isoLayoutContentWrapper'
        }
        {...props}
    >
        {renderBreadCrumb(props)}
        {props.children}
    </LayoutContentWrapper>
)
