import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'

import ImmutablePropTypes from 'react-immutable-proptypes'

import Image from '../../Image'
import ImagePreviewContainer from '../../ImagePreview/ImagePreviewContainer'
import ImagesWrapper from '../../../components/images/imagesWrapper.style'

import _ from 'lodash'

class OrderSupplierNoteImage extends Component {
    static propTypes = {
        // inner props
        orderSupplierNoteImageEntities: ImmutablePropTypes.map.isRequired,

        // outer props
        OrderSupplierNoteImageIds: ImmutablePropTypes.list.isRequired
    }

    static defaultProps = {
        OrderSupplierNoteImageIds: new List()
    }

    getOrderSupplierNoteImages () {
        const { OrderSupplierNoteImageIds, orderSupplierNoteImageEntities } = this.props

        const imageList = OrderSupplierNoteImageIds.map((OrderSupplierNoteImageId) => {
            return orderSupplierNoteImageEntities.get(OrderSupplierNoteImageId.toString()) || new Map()
        }) || new List()

        return imageList.toJS()
    }

    renderImage () {
        const images = this.getOrderSupplierNoteImages()
        if (images.size === 0) return null

        return images.map((image) => {
            const imageId = image.id
            const thumbUrl = _.get(image, 'file.s', '')
            const originalUrl = _.get(image, 'file.original', '')
            const contentType = _.get(image, 'file.content_type', '')

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    render () {
        return (
            <ImagesWrapper>
                <div className="order-supplier-note-images-container image-container">
                    {this.renderImage()}
                </div>
            </ImagesWrapper>
        )
    }
}


const mapStateToProps = state => ({
    orderSupplierNoteImageEntities: state.getIn(['Entities', 'orderSupplierNoteImages'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({

    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ImagePreviewContainer(OrderSupplierNoteImage))
