import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import actions from '../../redux/trucks/actions'

import TruckOwnerBankAccountForm from '../../components/trucks/truckOwnerBankAccountForm'

const {
    saveTruckOwnerBankAccount,
    resetTruckOwnerBankAccountFormError
} = actions

class TruckOwnerBankAccountFormContainer extends Component {
    static propTypes = {
        // Props from another component
        id: PropTypes.string,
        onCancel: PropTypes.func,
        ownerId: PropTypes.string.isRequired,

        // Inner props
        bankAccountEntities: ImmutablePropTypes.map.isRequired,
        resetTruckOwnerBankAccountFormError: PropTypes.func.isRequired,
        saveTruckOwnerBankAccount: PropTypes.func.isRequired,
        trucks: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        id: '',
        onCancel: () => {}
    }

    componentWillUnmount () {
        const { resetTruckOwnerBankAccountFormError } = this.props

        resetTruckOwnerBankAccountFormError()
    }

    getBankAccount () {
        const { bankAccountEntities, id } = this.props

        if (!id) return new Map()

        const bankAccount = bankAccountEntities.get(id.toString())

        return bankAccount || new Map()
    }

    onSubmit = (truckOwnerBankAccount) => {
        const { id, ownerId, trucks, saveTruckOwnerBankAccount } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])
        const query = trucks.getIn(['truckOwnerTable', 'query'])

        saveTruckOwnerBankAccount({ type: ownerType, query, ownerId, id, params: truckOwnerBankAccount })
    }

    render () {
        const { onCancel, trucks } = this.props

        return (
            <TruckOwnerBankAccountForm
                bankAccount={this.getBankAccount().toJS()}
                error={trucks.getIn(['truckForm', 'error'], new Map()).toJS()}
                onCancel={onCancel}
                onSubmit= {this.onSubmit} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountEntities: state.getIn(['Entities', 'truckOwnerBankAccounts']),
        trucks: state.get('trucks')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetTruckOwnerBankAccountFormError,
        saveTruckOwnerBankAccount
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckOwnerBankAccountFormContainer)