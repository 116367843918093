import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Upload, Button, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

import SupplierOrderActions from '../../redux/supplierOrders/actions'

const { importShippingCostFile } = SupplierOrderActions

class ImportSupplierShippingCost extends Component {
    state = {
        fileList: []
    }

    handleSelectFile = (info) => {
        let fileList = info.fileList
        fileList = fileList.slice(-1)

        this.setState({ fileList })
    }

    handleUpload = () => {
        const { fileList } = this.state
        const { importShippingCostFile } = this.props

        const file = fileList[0].originFileObj
        importShippingCostFile(file)

        this.setState({
            fileList: []
        })
    }

    render () {
        const { i18n, supplierOrders } = this.props
        const { fileList } = this.state
        const uploading = supplierOrders.get('shippingCostUploading')

        const props = {
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }))
                return false
            },
            fileList
        }

        return (
            <Card
                title={i18n.t('suppliers:shippingCost.upload')}
                style={{ margin: '20px 0' }}
            >
                <Upload {...props}
                    onChange={this.handleSelectFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/vnd.ms-excel"
                >
                    <Button style={{ marginBottom: '5px' }}>
                        <UploadOutlined />
                        {i18n.t('suppliers:shippingCost.selectFile')}
                    </Button>
                </Upload>
                <Button
                    type="primary"
                    onClick={this.handleUpload}
                    style={{ marginTop: '10px' }}
                    disabled={fileList.length === 0}
                    loading={uploading}
                >
                    {i18n.t('suppliers:shippingCost.upload')}
                </Button>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    supplierOrders: state.get('supplierOrders')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        importShippingCostFile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('suppliers')(ImportSupplierShippingCost))
