import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import SupplierOrderReviewIndex from '../../components/supplierOrderReviews/supplierOrderReviewIndex'
import supplierOrderReviewActions from '../../redux/supplierOrderReviews/actions'
import supplierOrderActions from '../../redux/supplierOrders/actions'
import purchaseOrderActions from '../../redux/purchaseOrders/actions'

import { Select } from 'antd'
import { isEmpty } from '../../helpers/objects'

const {
    fetchSupplierOrderReviews,
    setSupplierOrderFormModal,
    togglePOForm,
    setPagination,
    setSorter,
    setQuery
} = supplierOrderReviewActions
const { cancelSupplierOrder } = supplierOrderActions

const { fetchPurchaser } = purchaseOrderActions

class SupplierOrderReviewIndexContainer extends Component {
    static propTypes = {
        fetchSupplierOrderReviews: PropTypes.func.isRequired,
        orderEntities: ImmutablePropTypes.map.isRequired,
        orderSupplierNoteEntities: ImmutablePropTypes.map.isRequired,
        orderSupplierNoteImageEntities: ImmutablePropTypes.map.isRequired,
        paymentVoucherEntities: ImmutablePropTypes.map.isRequired,
        purchaseOrderStatusHistoryEntities: ImmutablePropTypes.map.isRequired,
        setSupplierOrderFormModal: PropTypes.func.isRequired,
        staffEntities: ImmutablePropTypes.map.isRequired,
        staffProfiles: ImmutablePropTypes.map.isRequired,
        staffRoleEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderReviews: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const {
            fetchPurchaser,
            location
        } = this.props
        const queryStringObject = this.parseParams(location.search)

        const {
            query,
            state,
            by_purchaser: purchaser,
            supplier_id_is: supplier,
            date_type: dateType,
            start_date: startDate,
            end_date: endDate,
            is_po_completed_are: isPoCompleted,
            is_po_checked_are: isChecked,
            is_not_working_yet: isNotWorking,
            due_date: dueDate
        } = queryStringObject

        this.fetchSupplierOrderReviews({
            currentPage: 1,
            query,
            orderState: state,
            supplier,
            purchaser,
            dateType,
            startDate,
            endDate,
            isChecked,
            isPoCompleted,
            isNotWorking,
            dueDate
        })
        fetchPurchaser('all')
    }

    parseParams = (params = '') => {
        if(!params) return {}
        const rawParams = params.replace("?", "").split("&")
        const extractedParams = {}
        rawParams.forEach((item) => {
            item = item.split("=")
            extractedParams[item[0]] = item[1]
        })
        return extractedParams;
    }

    getDataSouce = () => {
        const {
            supplierOrderReviewEntities,
            paymentVoucherEntities,
            purchaseOrderStatusHistoryEntities,
            staffEntities,
            supplierOrderEntities,
            supplierOrderReviews
        } = this.props

        const dataSource = []
        const orderIds = supplierOrderReviews.get('items') || new List()

        orderIds.map((orderId) => {
            const order = supplierOrderReviewEntities.get(orderId) || new Map()

            if (!order.get('supplier_orders').size) { dataSource.push({ ...order.toJS() }) }

            order.get('supplier_orders').map((id) => {
                let supplierOrder = supplierOrderEntities.get(id) || new Map()
                const paymentVoucherId = supplierOrder.get('payment_voucher')
                const orderSupplierNoteIds = supplierOrder.get('order_supplier_notes') || new List()
                const isCheckedHistory = purchaseOrderStatusHistoryEntities.get(
                    _.toString(supplierOrder.get('is_checked_history')),
                    new Map()
                )

                supplierOrder = supplierOrder.mergeDeep({
                    order: orderId,
                    is_checked_history: isCheckedHistory.merge({
                        staff: staffEntities.get(_.toString(isCheckedHistory.get('staff')), new Map())
                    })
                })

                if (paymentVoucherId) {
                    supplierOrder = supplierOrder.merge({
                        payment_voucher: paymentVoucherEntities.get(paymentVoucherId)
                    })
                }

                if (orderSupplierNoteIds.size) {
                    supplierOrder = this.getOrderSupplierNoteImages(supplierOrder, orderSupplierNoteIds)
                }

                dataSource.push({ ...order.toJS(), supplier_order: supplierOrder.toJS() })
            })
        })

        return dataSource
    }

    getOrderSupplierNoteImages = (supplierOrder, ids) => {
        const { orderSupplierNoteEntities, orderSupplierNoteImageEntities } = this.props

        return supplierOrder.merge({
            order_supplier_notes: new List(ids.map((orderSupplierNoteId) => {
                const orderSupplierNote = orderSupplierNoteEntities.get(`${orderSupplierNoteId}`) || new Map()

                return new Map({
                    ...orderSupplierNote.toJS(),
                    images: new List(orderSupplierNote.get('images').map((imageId) => {
                        return orderSupplierNoteImageEntities.get(imageId)
                    }))
                })
            }))
        })
    }

    handleCancelSupplierOrder = (supplierOrderId, orderId) => {
        const { cancelSupplierOrder } = this.props

        cancelSupplierOrder(supplierOrderId, orderId)
    }

    handleChangePagination = (page, filter, sorter) => {
        const { setSorter } = this.props

        const sorterQuery = this.getOrderQueryObject(sorter)

        setSorter(sorterQuery)
        this.fetchSupplierOrderReviews({ currentPage: page.current, sorter: sorterQuery })
    }

    handleSaveSuccessPOForm = () => {
        const { supplierOrderReviews } = this.props
        const refCode = supplierOrderReviews.get('activeRefCode')

        window.open(`/purchase_orders/${refCode}/previews`)
        this.handleShowPOForm()
    }

    handleShowPOForm = ({ orderId, supplierOrderId, refCode } = {}) => {
        const { togglePOForm } = this.props

        togglePOForm({ orderId, supplierOrderId, refCode })
    }

    handleShowSupplierOrderForm = ({ orderId, supplierOrderId } = {}) => {
        const { setSupplierOrderFormModal } = this.props

        setSupplierOrderFormModal({ orderId, supplierOrderId })
    }

    getQueryQueryObject (query) {
        if (!query) { return {} }

        return { key: 'query', value: query }
    }

    getDateType (dateType) {
        if (!dateType) { return {} }

        return { key: 'date_type', value: dateType }
    }

    getPeriodQueryArray (period) {
        if (period.length !== 2 || !period[0]) { return [] }

        return [
            { key: 'start_date', value: period[0] },
            { key: 'end_date', value: period[1] }
        ]
    }

    getSupplierIdIs (supplierId) {
        if (!supplierId) return {}

        return {
            key: 'supplier_id_is',
            value: supplierId
        }
    }

    getOrderQueryObject (sorter) {
        if (_.isEmpty(sorter)) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: `${sorter.columnKey} ${sortDirection}`
        }
    }

    getStateQueryObject (state) {
        if (!state) { return {} }

        return { key: 'state', value: state }
    }

    getPurchaser (purchaser) {
        if (!purchaser) { return {} }

        return { key: 'by_purchaser', value: purchaser }
    }

    getIsPoCompleted (value) {
        if (!value) { return {} }

        return { key: 'is_po_completed_are', value: value }
    }

    getIsChecked (value) {
        if (!value) { return {} }

        return { key: 'is_po_checked_are', value: value }
    }


    getIsNotWorkingYet (value) {
        if (!value) { return {} }

        return { key: 'is_not_working_yet', value: value }
    }

    getDueDate (value) {
        if (!value) { return {} }

        return { key: 'due_date', value: value }
    }

    fetchSupplierOrderReviews = async ({
        currentPage,
        query,
        orderState,
        supplier,
        purchaser,
        dateType,
        startDate,
        endDate,
        sorter,
        isChecked,
        isPoCompleted,
        isNotWorking,
        dueDate
    }) => {
        const { supplierOrderReviews, fetchSupplierOrderReviews } = this.props

        query = (query === undefined) ? supplierOrderReviews.get('query') : query
        orderState = (orderState === undefined) ? supplierOrderReviews.get('orderState') : orderState
        supplier = (supplier === undefined) ? supplierOrderReviews.get('supplier') : supplier
        purchaser = (purchaser === undefined) ? supplierOrderReviews.get('purchaser') : purchaser
        dateType = (dateType === undefined) ? supplierOrderReviews.get('dateType') : dateType
        startDate = (startDate === undefined) ? supplierOrderReviews.get('startDate') : startDate
        endDate = (endDate === undefined) ? supplierOrderReviews.get('endDate') : endDate
        isPoCompleted = (isPoCompleted === undefined) ? supplierOrderReviews.get('isPoCompleted') : isPoCompleted
        isChecked = (isChecked === undefined) ? supplierOrderReviews.get('isChecked') : isChecked
        isNotWorking = (isNotWorking === undefined) ? supplierOrderReviews.get('isNotWorking') : isNotWorking
        dueDate = (dueDate === undefined) ? supplierOrderReviews.get('dueDate') : dueDate

        fetchSupplierOrderReviews({
            page: currentPage,
            query,
            orderState,
            supplier,
            purchaser,
            dateType,
            startDate,
            endDate,
            sorter,
            isChecked,
            isPoCompleted,
            isNotWorking,
            dueDate
        })

        this.setQueryStringUrl({
            endDate,
            isChecked,
            isPoCompleted,
            orderState,
            purchaser,
            query,
            dateType,
            startDate,
            supplier,
            isNotWorking,
            dueDate
        })
    }

    setQueryStringUrl ({
        endDate,
        isChecked,
        isPoCompleted,
        orderState,
        purchaser,
        query,
        dateType,
        startDate,
        supplier,
        isNotWorking,
        dueDate
    } = {}) {
        const { history } = this.props

        const queries = [].concat(
            this.getQueryQueryObject(query),
            this.getStateQueryObject(orderState),
            this.getDateType(dateType),
            this.getPeriodQueryArray([startDate, endDate]),
            this.getSupplierIdIs(supplier),
            this.getPurchaser(purchaser),
            this.getIsPoCompleted(isPoCompleted),
            this.getIsChecked(isChecked),
            this.getIsNotWorkingYet(isNotWorking),
            this.getDueDate(dueDate)
        )

        const queryString = queries.filter((query) => { return !isEmpty(query) }).map((query) => {
            return `${query.key}=${query.value}`
        }).join('&')

        let objQuery = {}
        let queryShow = []
        let arrQueryString = queryString.split('&')
        const acceptQuery = [
            'query',
            'state',
            'date_type',
            'start_date',
            'end_date',
            'state',
            'by_purchaser',
            'supplier_id_is',
            'is_po_completed_are',
            'is_po_checked_are',
            'is_not_working_yet',
            'due_date'
        ]
        arrQueryString.forEach((str) => {
            const arrString = str.split('=')
            if (acceptQuery.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, { [`${arrString[0]}`]: arrString[1] })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            }
        })

        const searchQueries = queryShow.length ? '?' + queryShow.join('&') : ''
        history.push({
            search: searchQueries,
            params: objQuery
        })
    }

    handleFilterOrderState = (state) => {
        const getState = (state === 'all') ? '' : state

        this.fetchSupplierOrderReviews({ orderState: getState, currentPage: 1 })
    }

    handleSearch = (query) => {
        const { setQuery } = this.props

        setQuery(query)
        this.fetchSupplierOrderReviews({ query: query, currentPage: 1 })
    }

    handleSupplierChange = (value) => {
        this.fetchSupplierOrderReviews({ supplier: value || '', currentPage: 1 })
    }

    handlePeriodChange = (values) => {
        this.fetchSupplierOrderReviews({
            dateType: _.get(values, 'date_type', ''),
            startDate: _.get(values, 'start_date', ''),
            endDate: _.get(values, 'end_date', '')
        })
    }

    handleFilterPurchaser = (value) => {
        this.fetchSupplierOrderReviews({ purchaser: value || '', currentPage: 1 })
    }

    handleFilterPoCreated = (value) => {
        this.fetchSupplierOrderReviews({ isPoCompleted: value || '', currentPage: 1 })
    }

    handleFilterIsChecked = (value) => {
        this.fetchSupplierOrderReviews({ isChecked: value || '', currentPage: 1 })
    }

    handleFilterIsNotWorkingYet = (value) => {
        this.fetchSupplierOrderReviews({ isNotWorking: value.target.checked || '', currentPage: 1 })
    }

    handleDueDate = (date, dateString) => {
        this.fetchSupplierOrderReviews({ dueDate: dateString || '', currentPage: 1 })
    }

    renderPurchaserFilterOptions = () => {
        const { entities, orders, i18n } = this.props
        const purchaseItems = orders.get('purchaseItems')
        const purchaserEntities = entities.get('purchasers') || new Map()
        const purchaserOptions = [
            {
                key: i18n.t('suppliers:purchaseOrders.haveNoPurchaser'),
                value: 'nil'
            },
            {
                key: i18n.t('suppliers:purchaseOrders.havePurchaser'),
                value: 'not_nil'
            }
        ]

        const purchaserList = purchaseItems.map((saleId) => {
            const purchaser = purchaserEntities.get(saleId)
            return (
                {
                    key: purchaser.get('name'),
                    value: purchaser.get('id')
                }
            )
        })

        return purchaserOptions.concat(purchaserList.toJS()).map((purchaserOption) => {
            return (
                <Select.Option key={purchaserOption.key} value={purchaserOption.value}>
                    {purchaserOption.key}
                </Select.Option>
            )
        })
    }

    render () {
        const { staffEntities, staffProfiles, staffRoleEntities, supplierOrderReviews, location } = this.props
        const staffId = staffProfiles.get('currentStaffId')
        const staffRoleIds = _.get(staffEntities.toJS(), `${staffId}.staff_roles`, [])
        const staffRoles = staffRoleIds.map((id) => {
            return _.get(staffRoleEntities.toJS(), `${id}.name`, '')
        })
        const loadPO = staffRoles.filter(role => ['super_admin', 'purchase_supervisor', 'purchase'].includes(role))

        return (
            <SupplierOrderReviewIndex
                activeOrder={supplierOrderReviews.get('activeOrder')}
                activeSupplierOrder={supplierOrderReviews.get('activeSupplierOrder')}
                dataSource={this.getDataSouce()}
                loading={supplierOrderReviews.get('loading')}
                loadPO={loadPO.length > 0}
                onCancelSupplierOrder={this.handleCancelSupplierOrder}
                onChangePagination={this.handleChangePagination}
                onSaveSuccessPOForm={this.handleSaveSuccessPOForm}
                onShowPOForm={this.handleShowPOForm}
                onShowSupplierOrderForm={this.handleShowSupplierOrderForm}
                pagination={{
                    ...supplierOrderReviews.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                showPOFormModal={supplierOrderReviews.get('showPOForm')}
                showSupplierOrderFormModal={supplierOrderReviews.get('showSupplierOrderForm')}
                handleFilterPurchaser={this.handleFilterPurchaser}
                handleSearch={this.handleSearch}
                handleFilterOrderState={this.handleFilterOrderState}
                handleSupplierChange={this.handleSupplierChange}
                handlePeriodChange={this.handlePeriodChange}
                handleFilterPoCreated={this.handleFilterPoCreated}
                handleFilterIsChecked={this.handleFilterIsChecked}
                handleFilterIsNotWorkingYet={this.handleFilterIsNotWorkingYet}
                handleDueDate={this.handleDueDate}
                renderPurchaserFilterOptions={this.renderPurchaserFilterOptions}
                defaultSearchQuery={_.get(location, 'params.query', '')}
                defaultOrderState={supplierOrderReviews.get('orderState')}
                defaultSupplier={_.get(location, 'params.supplier_id_is', undefined)}
                defaultPurchaser={supplierOrderReviews.get('purchaser')}
                defaultDateType={supplierOrderReviews.get('dateType')}
                defaultStartDate={supplierOrderReviews.get('startDate')}
                defaultEndDate={supplierOrderReviews.get('endDate')}
                defaultIsChecked={supplierOrderReviews.get('isChecked')}
                defaultIsPoCompleted={supplierOrderReviews.get('isPoCompleted')}
                defaultIsNotWorking={supplierOrderReviews.get('isNotWorking')}
                defaultDueDate={supplierOrderReviews.get('dueDate')}
            />
        )
    }
}

const mapStateToProps = state => ({
    supplierOrderReviewEntities: state.getIn(['Entities', 'supplierOrderReviews']),
    orderSupplierNoteEntities: state.getIn(['Entities', 'orderSupplierNotes']),
    orderSupplierNoteImageEntities: state.getIn(['Entities', 'orderSupplierNoteImages']),
    paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
    purchaseOrderStatusHistoryEntities: state.getIn(['Entities', 'purchaseOrderStatusHistories']),
    staffEntities: state.getIn(['Entities', 'staffs']),
    staffProfiles: state.get('staffProfiles'),
    staffRoleEntities: state.getIn(['Entities', 'staffRoles']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierOrderReviews: state.get('supplierOrderReviews'),
    orders: state.get('orders'),
    entities: state.get('Entities'),
    purchaseOrders: state.get('purchaseOrders')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        cancelSupplierOrder,
        fetchSupplierOrderReviews,
        setSupplierOrderFormModal,
        togglePOForm,
        setPagination,
        fetchPurchaser,
        setSorter,
        setQuery
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['common', 'orders/tables', 'suppliers', 'orders'])(withRouter(SupplierOrderReviewIndexContainer))
)
