import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import { journalEntryFormatItemSchema, journalEntryFormatItemsSchema } from '../../schema'

const {
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_REQUEST,
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH,
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS,
    JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION,

    JOURNAL_ENTRY_FORMAT_ITEM_DELETE_REQUEST,
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE,
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS,

    JOURNAL_ENTRY_FORMAT_ITEM_SAVE_REQUEST,
    JOURNAL_ENTRY_FORMAT_ITEM_SAVING,
    JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS,
    JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS

} = actions

export function *fetchJournalEntryFormatItems () {
    yield takeEvery(JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: JOURNAL_ENTRY_FORMAT_ITEMS_FETCH })

        const { page, per, formatId } = payload
        const queryString = `&page=${page}&per=${per}`

        const path = `/journal_entry_formats/${formatId}/items?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, journalEntryFormatItemsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *deleteJournalEntryFormatItem () {
    yield takeLatest(JOURNAL_ENTRY_FORMAT_ITEM_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: JOURNAL_ENTRY_FORMAT_ITEM_DELETE })

        const { formatId, id, onSuccess } = payload
        const path = `/journal_entry_formats/${formatId}/items/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, journalEntryFormatItemSchema)

        yield put({
            type: JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export function *saveJournalEntryFormatItem () {
    yield takeLatest(JOURNAL_ENTRY_FORMAT_ITEM_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: JOURNAL_ENTRY_FORMAT_ITEM_SAVING, saving: true })

        const { id, params, onSuccess, onError, journalEntryFormatId } = payload
        const submitParams = { ...params, journal_entry_format_id: journalEntryFormatId }
        const basePath = `/journal_entry_formats/${journalEntryFormatId}/items`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ journal_entry_format_item: submitParams })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, journalEntryFormatItemSchema)

                yield put({
                    type: JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: JOURNAL_ENTRY_FORMAT_ITEM_SAVING, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchJournalEntryFormatItems),
        fork(deleteJournalEntryFormatItem),
        fork(saveJournalEntryFormatItem)
    ])
}
