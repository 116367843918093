const actions = {
    ITEM_TRUCK_IMAGE_REMOVE_FAIL: 'ITEM_TRUCK_IMAGE_REMOVE_FAIL',
    ITEM_TRUCK_IMAGE_REMOVE_REQUEST: 'ITEM_TRUCK_IMAGE_REMOVE_REQUEST',
    ITEM_TRUCK_IMAGE_REMOVE_SUCCESS: 'ITEM_TRUCK_IMAGE_REMOVE_SUCCESS',
    ITEM_TRUCK_IMAGE_REMOVING: 'ITEM_TRUCK_IMAGE_REMOVING',

    ITEM_TRUCK_IMAGE_SAVE_FAIL: 'ITEM_TRUCK_IMAGE_SAVE_FAIL',
    ITEM_TRUCK_IMAGE_SAVE_REQUEST: 'ITEM_TRUCK_IMAGE_SAVE_REQUEST',
    ITEM_TRUCK_IMAGE_SAVE_SUCCESS: 'ITEM_TRUCK_IMAGE_SAVE_SUCCESS',
    ITEM_TRUCK_IMAGE_SAVING: 'ITEM_TRUCK_IMAGE_SAVING',

    ITEM_TRUCK_IMAGES_FETCH_FAIL: 'ITEM_TRUCK_IMAGES_FETCH_FAIL',
    ITEM_TRUCK_IMAGES_FETCH_REQUEST: 'ITEM_TRUCK_IMAGES_FETCH_REQUEST',
    ITEM_TRUCK_IMAGES_FETCH_SUCCESS: 'ITEM_TRUCK_IMAGES_FETCH_SUCCESS',
    ITEM_TRUCK_IMAGES_FETCHING: 'ITEM_TRUCK_IMAGES_FETCHING',

    fetchOrderItemTruckImages: ({
        orderId = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!orderId) return null

        return {
            type: actions.ITEM_TRUCK_IMAGES_FETCH_REQUEST,
            payload: { orderId, onSuccess, onError },
            scope: 'order'
        }
    },

    removeItemTruckImage: ({
        itemId = '',
        id = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!itemId || !id) return null

        return {
            type: actions.ITEM_TRUCK_IMAGE_REMOVE_REQUEST,
            payload: { itemId, id, onSuccess, onError }
        }
    },

    saveItemTruckImage: ({
        itemId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {},
        onUploadProgress = () => {}
    } = {}) => {
        if (!itemId) return null

        return {
            type: actions.ITEM_TRUCK_IMAGE_SAVE_REQUEST,
            payload: { itemId, id, params, onSuccess, onError, onUploadProgress }
        }
    }
}

export default actions
