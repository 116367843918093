import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, DatePicker, Form, Input, Radio } from 'antd'
import BusinessAddressForm from '../../customers/businessAddresses/businessAddressForm'

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

export const renderFooter = (i18n, saving, onClose) => {
  const btnStyle = { margin: '0 4px' }
  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        loading={saving}
        htmlType='submit'
        type='primary'
        style={btnStyle}>
        {i18n.t('shared:save')}
      </Button>
      {onClose && (
        <Button onClick={onClose} style={btnStyle}>
          {i18n.t('shared:close')}
        </Button>
      )}
    </div>
  )
}

const ReissueInvoiceForm = ({
  businessAddress,
  customerNo,
  errors,
  loading,
  onClose,
  onSubmitForm,
  saving
}) => {
  const { i18n } = useTranslation(['confirms'])
  const [form] = Form.useForm()

  const handleHiddenInput = (e) => {
    const inputWrap = document.getElementsByClassName(
      'ant-calendar-input-wrap'
    )[0]
    if (inputWrap) {
      document.getElementsByClassName('ant-calendar-date-panel')[0].focus()
      inputWrap.style.display = 'none'
    }
  }

  const handleSubmitForm = (values) => {
    onSubmitForm({
      ...values,
      invoice_date: values['invoice_date'].format('YYYY-MM-DD')
    })
  }

  return (
    <Form form={form} onFinish={handleSubmitForm} {...formItemLayout}>
      <h2>{i18n.t('confirms:invoice.reissueInvoice')}</h2>

      <Form.Item
        label={i18n.t('confirms:invoice.invoiceNo')}
        name='invoice_no'
        validateStatus={errors['invoice_no'] && 'error'}
        help={errors['invoice_no'] && errors['invoice_no'].join(', ')}>
        <Input />
      </Form.Item>
      <Form.Item
        label={i18n.t('confirms:invoice.invoiceDate')}
        name='invoice_date'
        rules={[
          {
            required: true,
            message: i18n.t('confirms:invoice.pleaseInputDate')
          }
        ]}>
        <DatePicker onFocus={handleHiddenInput} />
      </Form.Item>

      <BusinessAddressForm
        businessAddress={businessAddress}
        customerNo={customerNo}
        errors={errors}
        form={form}
        loading={loading}
        showMailingAddressForm={false}
      />

      <Form.Item
        label={i18n.t('confirms:invoice.purpose')}
        name={['previous_re_invoice_attributes', 'purpose_code']}
        initialValue='TIVC99'
        rules={[
          {
            required: true,
            message: i18n.t('confirms:invoice.pleaseChoosePurpose')
          }
        ]}>
        <Radio.Group>
          <Radio value='TIVC01'>
            {i18n.t('confirms:invoice.purposes.name')}
          </Radio>
          <Radio value='TIVC02'>
            {i18n.t('confirms:invoice.purposes.address')}
          </Radio>
          <Radio value='TIVC99'>
            {i18n.t('confirms:invoice.purposes.other')}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={i18n.t('confirms:invoice.note')}
        name={['previous_re_invoice_attributes', 'note']}
        hasFeedback
        validateStatus={errors['previous_re_invoice.note'] && 'error'}
        help={errors['previous_re_invoice.note']}>
        <Input.TextArea />
      </Form.Item>

      {renderFooter(i18n, saving, onClose)}
    </Form>
  )
}

ReissueInvoiceForm.propTypes = {
  businessAddress: PropTypes.object,
  customerNo: PropTypes.string.isRequired,
  errors: PropTypes.object,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  saving: PropTypes.bool
}

ReissueInvoiceForm.defaultProps = {
  businessAddress: {},
  errors: {}
}

export default ReissueInvoiceForm
