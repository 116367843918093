import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Button, Col, DatePicker, Divider, Row, Table } from 'antd'
import { Link } from 'react-router-dom'

const JournalEntriesIndex = ({
    filters,
    journalEntryDates,
    loading,
    onChangeFilters,
    onPageChange,
    pagination,
    recalculate
}) => {
    const { i18n } = useTranslation('journalEntries')

    const columns = [
        {
            title: i18n.t('journalEntries:date'),
            dataIndex: 'date'
        },
        {
            title: i18n.t('journalEntries:debitAmount'),
            dataIndex: ['debit_amount', 'display']
        },
        {
            title: i18n.t('journalEntries:creditAmount'),
            dataIndex: ['credit_amount', 'display']
        },
        {
            title: i18n.t('journalEntries:diffAmount'),
            dataIndex: ['diff_amount', 'display']
        },
        {
            title: i18n.t('shared:actions'),
            render: (record) => (
                <div>
                    <Link to={`/journal_entries/${record.date}?type=debit`}>
                        {i18n.t('journalEntries:seeDebitList')}
                    </Link>
                    <Divider type="vertical" />
                    <Link to={`/journal_entries/${record.date}?type=credit`}>
                        {i18n.t('journalEntries:seeCreditList')}
                    </Link>
                    <Divider type="vertical" />
                    <Link to={`/journal_entries/${record.date}`}>
                        {i18n.t('journalEntries:seeFullList')}
                    </Link>
                </div>
            )
        }
    ]

    const onChangeDateRange = (dates, dateStrings) => {
        onChangeFilters({ startDate: dateStrings[0], endDate: dateStrings[1] })
    }

    const renderDatePicker = () => {
        const { startDate, endDate } = filters

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <DatePicker.RangePicker
                    onChange={onChangeDateRange}
                    value={[startDate && moment(startDate), endDate && moment(endDate)]} />
                <Button type="primary" onClick={recalculate} style={{ marginLeft: '8px' }} disabled={!startDate} ghost>
                    {i18n.t('journalEntries:recalculate')}
                </Button>
            </div>
        )
    }

    return (
        <div>
            <h2>{i18n.t('journalEntries:title')}</h2>

            <Row>
                <Col offset="12" span="12">
                    {renderDatePicker()}
                </Col>
            </Row>

            <Divider />

            <Table
                columns={columns}
                dataSource={journalEntryDates}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
        </div>
    )
}

JournalEntriesIndex.propTypes = {
    filters: PropTypes.object,
    journalEntryDates: PropTypes.array,
    loading: PropTypes.bool,
    onChangeFilters: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })]),
    recalculate: PropTypes.func
}

JournalEntriesIndex.defaultProps = {
    filters: {},
    pagination: false,
    recalculate: () => {}
}

export default JournalEntriesIndex
