import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import LalamoveFormRender from './render'
import { Spin } from 'antd'

const ORDER_QUERY = gql`
    query Order($number: String!) {
      order(number: $number) {
            id
            number
            orderShippingAddress {
              fullAddress
            }
        }
    }
`

const GET_QUOTATION_MUTATION = gql`
    mutation LalamoveGetQuotation($orderNumber: String!, $params: AdminQuotationInput!){
        lalamoveGetQuotation(orderNumber: $orderNumber, params: $params) {
          errors {
            message
            key
          }
        }
    }
`

const LalamoveForm = ({
  orderId,
  onToggleForm,
  fetchOrder
}) => {
  const [errorsMessage, setErrors] = useState(null)

  const { loading, data } = useQuery(ORDER_QUERY, {
    variables: { number: orderId }
  })

  const [getQuotation, { loading: saving }] = useMutation(GET_QUOTATION_MUTATION, {
    onCompleted: ({ lalamoveGetQuotation }) => {
      if (lalamoveGetQuotation.errors) {
        setErrors(lalamoveGetQuotation.errors.message)
      } else {
        setErrors(null)
        onToggleForm()
        fetchOrder()
      }
    }
  })


  const handleSubmit = (params) => {
    getQuotation({
      variables: { params: params, orderNumber: orderId }
    })
  }

  const getInitalValues = () => {
    const orderData = data && data.order
    const { orderShippingAddress } = orderData

    return {
      locations: [
        orderShippingAddress ? orderShippingAddress.fullAddress : null
      ]
    }
  }

  if (loading) return <Spin />

  return (
    <LalamoveFormRender initialValues={getInitalValues()}
      handleSubmit={handleSubmit}
      saving={saving}
      errorsMessage={errorsMessage}
      onCancel={onToggleForm} />
  )
}

LalamoveForm.propTypes = {}

LalamoveForm.defaultProps = {}

export default LalamoveForm
