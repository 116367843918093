import Immutable, { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    BANK_RECONCILE_PRODUCT_SET_UPLOADING,
    BANK_RECONCILE_TRUCK_SET_UPLOADING,
    DELETE_INVOICE_ADJUSTMENT_SUCCESS,
    DELETE_INVOICE_ADJUSTMENT,
    FETCH_INVOICE_ADJUSTMENT_SUCCESS,
    FETCH_INVOICE_ADJUSTMENT,
    FETCH_INVOICE_ADJUSTMENTS_SUCCESS,
    FETCH_INVOICE_ADJUSTMENTS,
    // FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS,
    // FETCH_INVOICE_DELIVERY_ADDRESS_PDF,
    FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS,
    FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS,
    FETCH_ORDER_BUSINESS_ADDRESSES,
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS,
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES,
    FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS,
    FETCH_PAYMENT_VOUCHERS_FILE,
    FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS,
    FETCH_SALE_TAX_INVOICE_REPORT_FILE,
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS,
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE,
    // FETCH_TAX_INVOICE_INFO_SUCCESS,
    FETCH_TAX_INVOICE_PDF_SUCCESS,
    FETCH_TAX_INVOICE_PDF,
    FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS,
    FETCH_TAX_INVOICE_REPORT_FILE,
    FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS,
    FETCH_TEMPORARY_RECEIPT_PDF,
    FETCH_VAT_DOCUMENT_FILE_SUCCESS,
    FETCH_VAT_DOCUMENT_FILE,
    FETCH_YIELD_REPORT_FILE_SUCCESS,
    FETCH_YIELD_REPORT_FILE,
    INVOICE_ADJUSTMENT_SET_FORM_ERRORS,
    INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING,
    ORDER_BUSINESSES_DELETE_SUCCESS,
    ORDER_BUSINESSES_SAVE_SUCCESS,
    ORDER_BUSINESSES_SET_FORM_ERRORS,
    ORDER_BUSINESSES_SET_SAVING,
    PAYMENT_DETAILS_FETCH_SUCCESS,
    PAYMENT_DETAILS_FETCH,
    PAYMENT_DETAILS_SET_FORM_ERRORS,
    PAYMENT_DETAILS_SET_PAGINATION,
    PAYMENT_VOUCHER_DELETE_SUCCESS,
    PAYMENT_VOUCHER_DELETE,
    PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING,
    PAYMENT_VOUCHER_SET_FORM_ERRORS,
    SAVE_PAYMENT_VOUCHER_SUCCESS,
    ORDER_FETCH_PAYMENT_VOUCHERS_SUCCESS,
    ORDER_FETCH_PAYMENT_VOUCHERS,
    SAVE_INVOICE_ADJUSTMENT,
    SAVE_PAYMENT_DETAILS_SUCCESS,
    SAVE_PAYMENT_DETAILS,
    SAVING_PAYMENT_VOUCHER,
    // SEND_TAX_INVOICE_EMAIL_SUCCESS,
    // SEND_TAX_INVOICE_EMAIL,
    SET_ACTIVE_INVOICE_ADJUSTMENT,
    SET_ACTIVE_INVOICE,
    // SET_CLEAR_TAX_INVOICE_FORM,
    SET_ORDER_BUSINESS_ADDRESS_ACTIVE,
    SET_SELECT_INVOICE_TYPE,
    SET_SHOW_INVOICE_ADJUSTMENT_FORM,
    SET_SHOW_INVOICE_MAILING_ADDRESS_FORM,
    SET_SHOW_PAYMENT_DETAIL,
    SET_SHOW_PAYMENT_VOUCHER,
    SET_SHOW_TRACKING_INVOICE_FORM,
    SHOW_ADD_BUSINESS_ADDRESS_MODAL,
    SHOW_ADD_MAILING_ADDRESS_MODAL,
    SHOW_TAX_INVOICE_BUSINESS_ITEMS_MODAL,
    SHOW_TAX_INVOICE_BUSINESS_DEPOSITS_MODAL,
    SHOW_TAX_INVOICE_BUSINESS_MODAL,
    // TAX_INVOICE_IMPORT_FILE_UPLOADING,
    TRANSFER_BACK_FETCH_SUCCESS,
    TRANSFER_BACK_FETCH,
    DOWNLOAD_INVOICE_ADJUSTMENT,
    DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS,
    SEND_E_TAX_REQUESTING,
    SEND_E_TAX_SUCCESS,
    SEND_ELECTRONIC_CREDIT_NOTE,
    SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS,
    SEND_E_TAX_MAIL_TO_CUSTOMER,
    SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS,
    FETCH_DEBIT_NOTE_PURPOSES,
    FETCH_DEBIT_NOTE_PURPOSES_SUCCESS,
    FETCH_CREDIT_NOTE_PURPOSES,
    FETCH_CREDIT_NOTE_PURPOSES_SUCCESS
} = actions

const initState = new Map({
    activeEditShipment: null,
    activeInvoice: null,
    activeInvoiceAdjustment: null,
    addBusinessAddressModal: false,
    addMailingAddressModal: false,
    errors: new Map(),
    invoiceAdjustmentFormErrors: new Map(),
    invoiceAdjustmentFormModal: false,
    invoiceAdjustmentFormLoading: false,
    invoiceAdjustmentFormSaving: false,
    invoiceAdjustmentItems: new Map(),
    invoiceAdjustmentLoadingIds: new List(),
    // invoiceDeliveryAddressLoading: false,
    invoiceEmsTrackingUploading: false,
    invoiceLoading: false,
    items: new List(),
    loading: false,
    orderBusinessAddressActive: null,
    orderBusinessAddressesLoading: false,
    orderBusinessAddressFormErrors: new Map(),
    // orderBusinessAddressFormLoading: false,
    // orderBusinessAddressFormSaving: false,
    orderBusinessAddressItems: new List(),
    pagination: new Map({
        current: null,
        defaultPageSize: PAGE_SIZE,
        total: null
    }),
    paymentVoucherFormErrors: new Map(),
    productBankReconcileUploading: false,
    pvItems: new List(),
    pvLoading: false,
    receiptVoucherCSVLoading: false,
    saleTaxInvoiceReportLoading: false,
    saving: false,
    savingPaymentDetail: false,
    savingPaymentVoucher: false,
    scbPaymentConfirmReportLoading: false,
    showMailingAddressForm: false,
    showPaymentDetailsForm: false,
    showPaymentVoucherForm: false,
    taxInvoiceBusinessAddressItemsModal: false,
    taxInvoiceBusinessAddressDepositsModal: false,
    taxInvoiceBusinessAddressModal: false,
    // taxInvoiceInfo: null,
    // taxInvoiceInfoLoading: false,
    taxInvoiceReportLoading: false,
    // taxInvoiceUploading: false,
    temporaryReceiptLoading: false,
    transferBackItems: new List(),
    transferBackLoading: false,
    truckBankReconcileUploading: false,
    uploading: false,
    vatDocumentsLoading: false,
    yieldReportCSVLoading: false,
    invoiceType: null,
    // mailSending: false,
    invoiceEmailHistoryLoading: false,
    invoiceEmailHistoryItems: new List(),
    invoiceNumberlist: new List(),
    invoiceAdjustmentLoading: false,
    sendETaxSaving: false,
    sendECreditNoteSaving: false,
    eTaxMailSending: false,
    debitNotePurposesList: new List(),
    debitNotePurposeLoading: false,
    creditNotePurposesList: new List(),
    creditNotePurposeLoading: false
})

export default function orderStateReducer (state = initState, action) {
    const invoiceAdjustmentItems = state.get('invoiceAdjustmentItems')
    const invoiceAdjustmentLoadingIds = state.get('invoiceAdjustmentLoadingIds')
    const items = state.get('items')
    const orderBusinessAddressItems = state.get('orderBusinessAddressItems')
    const pvItems = state.get('pvItems')
    const result = action.result

    switch (action.type) {
        case PAYMENT_DETAILS_FETCH:
            return state.merge({
                loading: true
            })
        case PAYMENT_DETAILS_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result),
                loading: false,
                invoiceNumberlist: Immutable.fromJS(action.invoiceNumberlist)
            })
        case ORDER_FETCH_PAYMENT_VOUCHERS:
            return state.merge({
                pvLoading: true
            })
        case ORDER_FETCH_PAYMENT_VOUCHERS_SUCCESS:
            return state.merge({
                pvItems: new List(action.result),
                pvLoading: false
            })
        case PAYMENT_DETAILS_SET_PAGINATION:
            return state.merge({
                pagination: new Map(action.pagination)
            })
        case SET_SHOW_PAYMENT_DETAIL:
            return state.merge({
                showPaymentDetailsForm: action.value
            })
        case SET_SHOW_PAYMENT_VOUCHER:
            return state.merge({
                showPaymentVoucherForm: !state.get('showPaymentVoucherForm')
            })
        case PAYMENT_DETAILS_SET_FORM_ERRORS:
            return state.merge({
                savingPaymentDetail: false,
                errors: new Map(action.formErrors)
            })
        case PAYMENT_VOUCHER_SET_FORM_ERRORS:
            return state.merge({
                paymentVoucherFormErrors: new Map(action.paymentVoucherFormErrors)
            })
        case SAVING_PAYMENT_VOUCHER:
            return state.merge({
                savingPaymentVoucher: action.saving
            })
        case SAVE_PAYMENT_VOUCHER_SUCCESS:
            return state.merge({
                savingPaymentVoucher: false
            })
        case SAVE_PAYMENT_DETAILS:
            return state.merge({
                savingPaymentDetail: true
            })
        case SAVE_PAYMENT_DETAILS_SUCCESS:
            return state.merge({
                items: items.includes(action.result) ? new List(items) : new List(items.push(action.result))
            })
        case FETCH_PAYMENT_VOUCHERS_FILE:
            return state.merge({
                receiptVoucherCSVLoading: true
            })
        case FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS:
            return state.merge({
                receiptVoucherCSVLoading: false
            })
        case FETCH_TAX_INVOICE_PDF:
            return state.merge({
                invoiceLoading: true
            })
        case FETCH_TAX_INVOICE_PDF_SUCCESS:
            return state.merge({
                invoiceLoading: false
            })
        case FETCH_VAT_DOCUMENT_FILE:
            return state.merge({
                vatDocumentsLoading: true
            })
        case FETCH_VAT_DOCUMENT_FILE_SUCCESS:
            return state.merge({
                vatDocumentsLoading: false
            })
        case FETCH_YIELD_REPORT_FILE:
            return state.merge({
                yieldReportCSVLoading: true
            })
        case FETCH_YIELD_REPORT_FILE_SUCCESS:
            return state.merge({
                yieldReportCSVLoading: false
            })
        case SET_SHOW_INVOICE_MAILING_ADDRESS_FORM:
            return state.merge({
                showMailingAddressForm: action.value
            })
        case PAYMENT_VOUCHER_DELETE:
            return state.merge({
                saving: true
            })
        case PAYMENT_VOUCHER_DELETE_SUCCESS:
            return state.merge({
                saving: false,
                pvItems: new List(pvItems.delete(pvItems.indexOf(action.result)))
            })
        case SHOW_ADD_BUSINESS_ADDRESS_MODAL:
            return state.merge({
                addBusinessAddressModal: action.value
            })
        case SHOW_ADD_MAILING_ADDRESS_MODAL:
            return state.merge({
                addMailingAddressModal: action.value
            })
        // case FETCH_TAX_INVOICE_INFO_SUCCESS:
        //     return state.merge({
        //         taxInvoiceInfo: action.result
        //     })
        case PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING:
            return state.merge({
                uploading: action.uploading
            })
        // case SET_CLEAR_TAX_INVOICE_FORM:
        //     return state.merge({
        //         taxInvoiceInfo: action.value
        //     })
        case TRANSFER_BACK_FETCH:
            return state.merge({
                transferBackLoading: true
            })
        case TRANSFER_BACK_FETCH_SUCCESS:
            return state.merge({
                transferBackItems: new List(action.result),
                transferBackLoading: false
            })
        case FETCH_TEMPORARY_RECEIPT_PDF:
            return state.merge({
                temporaryReceiptLoading: true
            })
        case FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS:
            return state.merge({
                temporaryReceiptLoading: false
            })
        case BANK_RECONCILE_PRODUCT_SET_UPLOADING:
            return state.merge({
                productBankReconcileUploading: action.uploading
            })
        case BANK_RECONCILE_TRUCK_SET_UPLOADING:
            return state.merge({
                truckBankReconcileUploading: action.uploading
            })
        case SHOW_TAX_INVOICE_BUSINESS_MODAL:
            return state.merge({
                taxInvoiceBusinessAddressModal: action.value
            })
        case FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS:
            return state.merge({
                orderBusinessAddressItem: action.result
            })
        case FETCH_ORDER_BUSINESS_ADDRESSES:
            return state.merge({
                orderBusinessAddressesLoading: true
            })
        case FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS:
            return state.merge({
                orderBusinessAddressesLoading: false,
                orderBusinessAddressItems: new List(action.result)
            })
        case ORDER_BUSINESSES_SET_FORM_ERRORS:
            return state.merge({
                orderBusinessAddressFormErrors: new Map(action.errors)
            })
        case FETCH_SALE_TAX_INVOICE_REPORT_FILE:
            return state.merge({
                saleTaxInvoiceReportLoading: true
            })
        case FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS:
            return state.merge({
                saleTaxInvoiceReportLoading: false
            })
        case FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE:
            return state.merge({
                scbPaymentConfirmReportLoading: true
            })
        case FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS:
            return state.merge({
                scbPaymentConfirmReportLoading: false
            })
        case ORDER_BUSINESSES_SAVE_SUCCESS:
            return state.merge({
                orderBusinessAddressItems: new List(orderBusinessAddressItems.includes(result) ?
                    orderBusinessAddressItems : orderBusinessAddressItems.concat(result)),
                orderBusinessAddressFormErrors: new Map()
            })
        case ORDER_BUSINESSES_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        case SET_ORDER_BUSINESS_ADDRESS_ACTIVE:
            return state.merge({
                orderBusinessAddressActive: action.id
            })
        case ORDER_BUSINESSES_DELETE_SUCCESS:
            return state.merge({
                orderBusinessAddressItems: new List(orderBusinessAddressItems.delete(
                    orderBusinessAddressItems.indexOf(action.result)
                ))
            })
        case SHOW_TAX_INVOICE_BUSINESS_ITEMS_MODAL:
            return state.merge({
                taxInvoiceBusinessAddressItemsModal: action.value
            })
        case SHOW_TAX_INVOICE_BUSINESS_DEPOSITS_MODAL:
            return state.merge({
                taxInvoiceBusinessAddressDepositsModal: action.value
            })
        case SET_SELECT_INVOICE_TYPE:
            return state.merge({
                invoiceType: action.value
            })
        // case SEND_TAX_INVOICE_EMAIL:
        //     return state.merge({
        //         mailSending: action.mailSending
        //     })
        // case SEND_TAX_INVOICE_EMAIL_SUCCESS:
        //     return state.merge({
        //         mailSending: action.mailSending
        //     })
        case FETCH_ORDER_INVOICE_EMAIL_HISTORIES:
            return state.merge({
                invoiceEmailHistoryLoading: true
            })
        case FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS:
            return state.merge({
                invoiceEmailHistoryLoading: false,
                invoiceEmailHistoryItems: new List(action.result)
            })
        case FETCH_TAX_INVOICE_REPORT_FILE:
            return state.merge({
                taxInvoiceReportLoading: true
            })
        case FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS:
            return state.merge({
                taxInvoiceReportLoading: false
            })
        // case TAX_INVOICE_IMPORT_FILE_UPLOADING:
        //     return state.merge({
        //         taxInvoiceUploading: action.uploading
        //     })
        case INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING:
            return state.merge({
                invoiceEmsTrackingUploading: action.uploading
            })
        case SET_SHOW_TRACKING_INVOICE_FORM:
            return state.merge({
                activeEditShipment: action.id
            })
        // case FETCH_INVOICE_DELIVERY_ADDRESS_PDF:
        //     return state.merge({
        //         invoiceDeliveryAddressLoading: true
        //     })
        // case FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS:
        //     return state.merge({
        //         invoiceDeliveryAddressLoading: false
        //     })
        case FETCH_INVOICE_ADJUSTMENTS:
            return state.merge({
                invoiceAdjustmentLoadingIds: new List(invoiceAdjustmentLoadingIds.push(action.invoiceId))
            })
        case FETCH_INVOICE_ADJUSTMENTS_SUCCESS:
            return state.merge({
                invoiceAdjustmentLoadingIds: new List(invoiceAdjustmentLoadingIds.delete(
                    invoiceAdjustmentLoadingIds.indexOf(action.invoiceId)
                )),
                invoiceAdjustmentItems: new Map({
                    ...invoiceAdjustmentItems.toJS(),
                    [action.invoiceId]: result
                })
            })
        case FETCH_INVOICE_ADJUSTMENT:
            return state.merge({
                invoiceAdjustmentFormLoading: true
            })
        case FETCH_INVOICE_ADJUSTMENT_SUCCESS:
            return state.merge({
                invoiceAdjustmentFormLoading: false
            })
        case SAVE_INVOICE_ADJUSTMENT:
            return state.merge({ invoiceAdjustmentFormSaving: action.saving })
        case INVOICE_ADJUSTMENT_SET_FORM_ERRORS:
            return state.merge({ invoiceAdjustmentFormErrors: new Map(action.errors) })
        case DELETE_INVOICE_ADJUSTMENT:
            return state.merge({ invoiceAdjustmentFormSaving: true })
        case DELETE_INVOICE_ADJUSTMENT_SUCCESS:
            return state.merge({
                invoiceAdjustmentFormSaving: false
            })
        case SET_ACTIVE_INVOICE:
            return state.merge({
                activeInvoice: action.id
            })
        case SET_ACTIVE_INVOICE_ADJUSTMENT:
            return state.merge({
                activeInvoiceAdjustment: action.id
            })
        case SET_SHOW_INVOICE_ADJUSTMENT_FORM:
            return state.merge({
                invoiceAdjustmentFormModal: action.visible
            })
        case DOWNLOAD_INVOICE_ADJUSTMENT:
            return state.merge({
                invoiceAdjustmentLoading: true
            })
        case DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS:
            return state.merge({
                invoiceAdjustmentLoading: false
            })
        case SEND_E_TAX_REQUESTING:
            return state.merge({
                sendETaxSaving: action.saving
            })
        case SEND_E_TAX_SUCCESS:
            return state.merge({
                orderBusinessAddressItems: new List(orderBusinessAddressItems.includes(result) ?
                    orderBusinessAddressItems : orderBusinessAddressItems.concat(result))
            })
        case SEND_ELECTRONIC_CREDIT_NOTE:
            return state.merge({
                sendECreditNoteSaving: action.saving
            })
        case SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS:
            const invoiceAdjustment = invoiceAdjustmentItems.get(action.orderBusinessAddressId)
            return state.merge({
                invoiceAdjustmentItems: new Map({
                    ...invoiceAdjustmentItems.toJS(),
                    [action.orderBusinessAddressId]: invoiceAdjustment.includes(result) ?
                        invoiceAdjustment : invoiceAdjustment.concat(result)
                })
            })
        case SEND_E_TAX_MAIL_TO_CUSTOMER:
            return state.merge({
                eTaxMailSending: action.eTaxMailSending
            })
        case SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS:
            return state.merge({
                eTaxMailSending: action.eTaxMailSending
            })
        case FETCH_DEBIT_NOTE_PURPOSES:
            return state.merge({
                debitNotePurposeLoading: action.loading
            })
        case FETCH_DEBIT_NOTE_PURPOSES_SUCCESS:
            return state.merge({
                debitNotePurposeLoading: action.loading,
                debitNotePurposesList: Immutable.fromJS(action.data)
            })
        case FETCH_CREDIT_NOTE_PURPOSES:
            return state.merge({
                creditNotePurposeLoading: action.loading
            })
        case FETCH_CREDIT_NOTE_PURPOSES_SUCCESS:
            return state.merge({
                creditNotePurposeLoading: action.loading,
                creditNotePurposesList: Immutable.fromJS(action.data)
            })
        default:
            return state
    }
}
