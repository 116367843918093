const actions = {
    DISTRICTS_FETCH: 'DISTRICTS_FETCH',
    DISTRICTS_FETCH_REQUEST: 'DISTRICTS_FETCH_REQUEST',
    DISTRICTS_FETCH_SUCCESS: 'DISTRICTS_FETCH_SUCCESS',

    fetchDistricts: (provinceId) => {
        return {
            type: actions.DISTRICTS_FETCH_REQUEST,
            payload: { provinceId }
        }
    }
}

export default actions
