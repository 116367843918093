import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Modal, Spin } from 'antd'

import imageActions from '../../redux/images/actions'

const { closePreviewImageModal, setPreviewImageThumbUrl } = imageActions

const ImagePreviewContainer = (ChildComponent) => {
    const App = class App extends Component {
        handleOnCancelModal = () => {
            const { closePreviewImageModal, setPreviewImageThumbUrl } = this.props

            closePreviewImageModal()
            setPreviewImageThumbUrl('')
        }

        handleOnOk = () => {
            const { imageEntities, images } = this.props
            const previewImageThumbUrl = images.get('previewImageThumbUrl')
            const originalLoading = imageEntities.getIn([previewImageThumbUrl, 'originalLoading'])

            if (originalLoading) { return null }

            const originalBlob = imageEntities.getIn([previewImageThumbUrl, 'originalBlob'])
            window.open(originalBlob)
        }

        renderPreviewImage () {
            const { imageEntities, images } = this.props
            const previewImageThumbUrl = images.get('previewImageThumbUrl')
            const originalLoading = imageEntities.getIn([previewImageThumbUrl, 'originalLoading'])

            if (originalLoading) { return <Spin size="large" /> }

            const originalBlob = imageEntities.getIn([previewImageThumbUrl, 'originalBlob'])

            return <img src={originalBlob} style={{ width: '100%' }} />
        }

        renderArrayOfNoticeText () {
            const { images } = this.props
            const noticeTexts = images.get('noticeText')
            if (!noticeTexts) return null
            return noticeTexts.map((noticeText) => {
                return <div style={{ color: 'red' }}>{noticeText}</div>
            })
        }

        render () {
            const { images, i18n, ...restProps } = this.props
            const showPreviewImage = images.get('showPreviewImage')

            return (
                <div>
                    <ChildComponent i18n={i18n} key='child_component' {...restProps} />

                    <Modal
                        cancelText={i18n.t('shared:close')}
                        closable={false}
                        key='preview_modal'
                        okText={i18n.t('shared:viewImage')}
                        onCancel={this.handleOnCancelModal}
                        onOk={this.handleOnOk}
                        visible={showPreviewImage}
                    >
                        {this.renderArrayOfNoticeText()}
                        {this.renderPreviewImage()}
                    </Modal>
                </div>
            )
        }
    }

    const mapStateToProps = (state) => ({
        imageEntities: state.getIn(['Entities', 'images']),
        images: state.get('images')
    })

    const mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            closePreviewImageModal,
            setPreviewImageThumbUrl
        }, dispatch)
    }

    return connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))
}

export default ImagePreviewContainer
