import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { isMobile } from 'react-device-detect'
import { Row, Col } from 'antd'

import { withTranslation } from 'react-i18next'

import { Authorization } from '../Authorization'

import BankStatementImportContainer from './ImportContainer'
import BankStatementImportAdditionalUpdateContainer from './ImportAdditionalUpdateContainer'
import BankStatementExportContainer from './ExportContainer'
import BankStatementExportWithSupplierInvoiceContainer from './ExportWithSupplierInvoiceContainer'

const BankStatementImportAndExportContainer = () => {
    return (
        <Row type="flex" align="middle">
            <Col span={12}>
                <Authorization
                    allowedRoles={['super_admin', 'accounting']}
                    redirectWhenNotAllowed={false}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '12px'}}>
                          <BankStatementImportContainer />
                        </div>
                        <div>
                          <BankStatementImportAdditionalUpdateContainer />
                        </div>
                      </div>
                </Authorization>
            </Col>

            <Col span={12}>
                <Authorization
                    allowedRoles={['super_admin', 'accounting']}
                    redirectWhenNotAllowed={false}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: '12px'}}>
                          <BankStatementExportContainer />
                        </div>
                        <div>
                          <BankStatementExportWithSupplierInvoiceContainer />
                        </div>
                      </div>
                </Authorization>
            </Col>
        </Row>
    )
}

BankStatementImportAndExportContainer.propTypes = {
}

BankStatementImportAndExportContainer.defaultProps = {
}

export default withTranslation()(BankStatementImportAndExportContainer)
