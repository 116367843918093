import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import ContentLayout from '../../../components/utility/layoutContent'
import JournalEntryFormatItemsIndexContainer from './JournalEntryFormatItemsIndexContainer'

class JournalEntryFormatItemIndexPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Journal Entry Format Items | OSH CRM'
    }

    render () {
        const { match } = this.props
        const { journal_entry_format_id: id } = match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/journal_entry_formats', name: 'Journal Entry Formats' },
                    { path: `/journal_entry_formats/${id}/items`, name: id },
                    { path: `/journal_entry_formats/${id}/items`, name: 'Items' }
                ]}>
                    <ContentLayout>
                      <JournalEntryFormatItemsIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default JournalEntryFormatItemIndexPage
