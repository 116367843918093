import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import makeRootReducer from './reducers'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

export let storeExported
const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension')
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
}

export function configureStore (initialState) {
    const store = createStore(
        makeRootReducer(),
        initialState,
        bindMiddleware([sagaMiddleware]))

    store.runSagaTask = () => {
        store.sagaTask = sagaMiddleware.run(rootSaga)
    }

    store.runSagaTask()

    storeExported = store
    return store
}
