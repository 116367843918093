import { schema } from 'normalizr'

export const provincesSchema = new schema.Entity('provinces')
export const provincesListSchema = [provincesSchema]

const actions = {
    PROVINCES_FETCH: 'PROVINCES_FETCH',
    PROVINCES_FETCH_REQUEST: 'PROVINCES_FETCH_REQUEST',
    PROVINCES_FETCH_SUCCESS: 'PROVINCES_FETCH_SUCCESS',

    fetchProvinces: () => {
        return {
            type: actions.PROVINCES_FETCH_REQUEST
        }
    }
}

export default actions
