import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'

import { Col, DatePicker, Form, Input, Row, Select } from 'antd'
import SelectSupplierContainer from '../../containers/SelectSupplier'
import SupplierInvoicesImportContainer from '../../containers/SupplierInvoices/ImportContainer'
import SupplierInvoicesExportContainer from '../../containers/SupplierInvoices/ExportContainer'
import SupplierInvoicesWrapper from './supplierInvoices.style'

const span6Layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const span12Layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
}

const SupplierInvoicesHeader = ({
  filters,
  onChangeFilters
}) => {
    const { i18n } = useTranslation('supplierInvoices')

    const onChangeHasInvoiceRefCode = (value) => {
        onChangeFilters({ invoiceRefCode: value })
    }

    const onChangeDate = (date, dateString) => {
        onChangeFilters({ startDate: dateString[0], endDate: dateString[1] })
    }

    const onChangeDateType = (value) => {
        onChangeFilters({ dateType: value })
    }

    const onChangeSearchQuery = (query) => {
        onChangeFilters({ query })
    }

    const onChangeSupplier = (value) => {
        if (value) {
            onChangeFilters({ supplierId: value })
        } else {
            onChangeFilters({ supplierId: '' })
        }
    }

    const renderDateInput = () => {
        const startDate = filters.get('startDate')
        const endDate = filters.get('endDate')

        return (
            <Form.Item label={i18n.t('supplierInvoices:date')} {...span12Layout}>
                <Input.Group compact>
                    <Select
                        className="w-30"
                        onChange={onChangeDateType}
                        value={filters.get('dateType')}
                    >
                        <Select.Option value="issue_date">{i18n.t('supplierInvoices:invoiceDate')}</Select.Option>
                        <Select.Option value="payment_date">{i18n.t('supplierInvoices:paymentDate')}</Select.Option>
                    </Select>
                    <DatePicker.RangePicker
                        className="w-70"
                        onChange={onChangeDate}
                        value={[startDate && moment(startDate), endDate && moment(endDate)]}
                    />
                </Input.Group>
            </Form.Item>
        )
    }

    const renderHasInvoiceRefCodeSelector = () => {
        return (
            <Form.Item label={i18n.t('supplierInvoices:invoice')} {...span6Layout}>
                <Select
                    className="w-100"
                    onChange={onChangeHasInvoiceRefCode}
                    value={filters.get('invoiceRefCode')}
                >
                    <Select.Option value="">{i18n.t('shared:hasAnyLabels.all')}</Select.Option>
                    <Select.Option value="true">{i18n.t('shared:hasAnyLabels.isExists')}</Select.Option>
                    <Select.Option value="false">{i18n.t('shared:hasAnyLabels.notExists')}</Select.Option>
                </Select>
            </Form.Item>
        )
    }

    const renderSearchInput = () => {
        return (
            <Form.Item label={i18n.t('supplierInvoices:search')} {...span12Layout}>
                <Input.Search
                    className="w-100"
                    placeholder="input search text"
                    onSearch={onChangeSearchQuery}
                    enterButton
                />
            </Form.Item>
        )
    }

    const renderSupplierSelector = () => {
        const supplierId = filters.get('supplierId')

        return (
            <Form.Item label={i18n.t('supplierInvoices:company')} {...span6Layout}>
                <SelectSupplierContainer
                    className="w-100"
                    onChange={onChangeSupplier}
                    value={supplierId === "" ? undefined : filters.get('supplierId')}
                />
            </Form.Item>
        )
    }

    return (
        <SupplierInvoicesWrapper>
                <h2>{i18n.t('supplierInvoices:supplierInvoices')}</h2>

                <Row>
                    <Col span={12}>
                        {renderSearchInput()}
                    </Col>
                    <Col span={6}>
                        {renderSupplierSelector()}
                    </Col>
                    <Col className="text-right" span={6}>
                        <SupplierInvoicesImportContainer />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        {renderHasInvoiceRefCodeSelector()}
                    </Col>
                    <Col span={12}>
                        {renderDateInput()}
                    </Col>
                    <Col className="text-right" span={6}>
                        <SupplierInvoicesExportContainer />
                    </Col>
                </Row>
            </SupplierInvoicesWrapper>
    )
}

SupplierInvoicesHeader.propTypes = {
  filters: ImmutablePropTypes.map.isRequired,
  onChangeFilters: PropTypes.func.isRequired
}

export default SupplierInvoicesHeader
