import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button,Popconfirm } from 'antd'
import { gql, useMutation } from "@apollo/client"

import notificationActions from '../../../redux/notifications/actions'

const { setNotifications } = notificationActions

const EXPORT_SUPPLIERS_MUTATION = gql`
	mutation exportSuppliers {
		exportSuppliers {
			errors
		}
	}
`

const SupplierExport = () => {
    const { i18n } = useTranslation('suppliers')
    const dispatch = useDispatch()

    const [exportTickets, { loading }] = useMutation(EXPORT_SUPPLIERS_MUTATION, {
        onCompleted: ({ exportSuppliers }) => {
            if (exportSuppliers.errors) {
                dispatch(setNotifications('error', 'error', 'error'))
            } else {
                dispatch(setNotifications(
                    i18n.t('suppliers:export'),
                    i18n.t('suppliers:exportSuccess'),
                    'info'
                ))
            }
        }
    })

    return (
        <Popconfirm
            title={i18n.t('suppliers:confirmExport')}
            onConfirm={exportTickets}>
            <Button type="primary" loading={loading}>{i18n.t('suppliers:export')}</Button>
        </Popconfirm>
    )
}

SupplierExport.propTypes = {}

SupplierExport.defaultProps = {}

export default SupplierExport
