import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import ProfitSharingForm from '../../components/supplierOrders/profitSharingForm'

import supplierActions from '../../redux/suppliers/actions'
import profitSharingActions from '../../redux/customers/profitSharings/actions'

const { fetchSupplierOrder, saveSupplierOrders } = supplierActions
const { fetchCustomerProfitSharings } = profitSharingActions

class ProfitSharingFormContainer extends Component {
    static propsTypes = {
        orderId: PropTypes.string,
        customerId: PropTypes.string,
        supplierOrderId: PropTypes.string,
        onSaveSuccess: PropTypes.func
    }

    static defaultProps = {
        onSaveSuccess: () => {}
    }

    componentDidMount () {
        const {
            fetchSupplierOrder,
            supplierOrderId,
            orderId,
            fetchCustomerProfitSharings,
            customerId
        } = this.props
        fetchCustomerProfitSharings({ customerId, all: 'true' })

        if (supplierOrderId) {
            fetchSupplierOrder(orderId, supplierOrderId)
        }
    }

    getProfitSharings () {
        const { profitSharings, profitSharingEntities } = this.props

        return profitSharings.get('items', new List()).map((profitSharingId, index) => {
            const profitSharing = profitSharingEntities.get(profitSharingId) || new Map()
            return {
                value: profitSharingId,
                label: profitSharing.get('detail') || `PS${index + 1}`
            }
        })
    }

    handleSubmit = (params) => {
        const {
            orderId,
            onSaveSuccess,
            saveSupplierOrders,
            supplierOrderId
        } = this.props

        const supplierOrder = {
            ...params.values,
            supplier_order_type: 'profit_sharing'
        }

        saveSupplierOrders({
            orderId,
            id: supplierOrderId,
            supplierOrder,
            onSuccess: () => { onSaveSuccess() }
        })
    }

    getFieldErrors = (fieldName) => {
        const { suppliers } = this.props
        const formErrors = suppliers.get('formErrors') || new Map()
        const errors = formErrors.get(fieldName) || new List()

        return {
            help: errors.join(', '),
            status: errors.size ? 'error' : null
        }
    }

    render () {
        const { supplierOrdersEntity, supplierOrderId, suppliers } = this.props

        const supplierOrder = supplierOrdersEntity.get(supplierOrderId) || new Map()

        return (
            <ProfitSharingForm supplierOrder={supplierOrder.toJS()}
                profitSharingOptions={this.getProfitSharings()}
                saving={suppliers.get('saving')}
                getFieldErrors={this.getFieldErrors}
                handleSubmit={this.handleSubmit}/>
        )
    }
}

const mapStateToProps = state => ({
    suppliers: state.get('suppliers'),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrdersEntity: state.getIn(['Entities', 'supplierOrders']),
    profitSharings: state.get('profitSharings'),
    profitSharingEntities: state.getIn(['Entities', 'profitSharings'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplierOrder,
        saveSupplierOrders,
        fetchCustomerProfitSharings
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitSharingFormContainer)
