import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { List, Map}  from "immutable"

import { Divider } from 'antd'
import SupplierInvoicesHeader from '../../components/supplierInvoices/SupplierInvoicesHeader'
import SupplierInvoices from '../../components/supplierInvoices/supplierInvoicesIndex'

import actions from '../../redux/supplierInvoices/actions'

const { fetchSupplierInvoices, setFilters } = actions

const SupplierInvoicesListContainer = ({ i18n }) => {
    const dispatch = useDispatch()
    const supplierInvoices = useSelector(state => state.get('supplierInvoices'))
    const supplierInvoiceEntities = useSelector(state => state.getIn(['Entities', 'supplierInvoices']))
    const loading = supplierInvoices.get('loading')
    const filters = useSelector(state => state.getIn(['supplierInvoices', 'filters']))

    useEffect(() => {
        dispatch(fetchSupplierInvoices({ page: 1, filters: filters.toJS() }))
    }, [filters])

    const getSupplierInvoiceList = () => {
        return supplierInvoices.get('items', new List()).map((id) => {
            const supplierInvoice = supplierInvoiceEntities.get(id) || new Map()

            return new Map({
                ...supplierInvoice.toJS()
            })
        })
    }

    const prepareFetchSupplierInvoices = ({ currentPage } = {}) => {
        dispatch(fetchSupplierInvoices({
            page: currentPage,
            filters: filters.toJS()
        }))
    }

    const handleTableChange = (pagination) => {
        prepareFetchSupplierInvoices({
            currentPage: pagination.current
        })
    }

    const onChangeFilters = (args) => {
        dispatch(setFilters({ ...filters.toJS(), ...args }))
    }

    return (
        <div>
            <SupplierInvoicesHeader
                filters={filters}
                onChangeFilters={onChangeFilters}
            />

            <Divider />

            <SupplierInvoices
               filters={filters}
               loading={loading}
               onChangeFilters={onChangeFilters}
               onTableChange={handleTableChange}
               pagination={{
                   ...supplierInvoices.get('pagination').toJS(),
                   showSizeChanger: false
                }}
               supplierInvoices={getSupplierInvoiceList().toJS()}
            />
        </div>
    )
}

export default withTranslation(['orderPaymentDetails', 'confirms', 'scbPaymentConfirms'])(withRouter(SupplierInvoicesListContainer))
