import { PAGE_SIZE } from '../../constants/orders'

const actions = {
    SHIPPING_RATES_FETCH_REQUEST: "SHIPPING_RATES_FETCH_REQUEST",
    SHIPPING_RATES_FETCHING: "SHIPPING_RATES_FETCHING",
    SHIPPING_RATES_FETCH_SUCCESS: "SHIPPING_RATES_FETCH_SUCCESS",
    SHIPPING_RATES_SET_FILTERS: "SHIPPING_RATES_SET_FILTERS",
    SHIPPING_RATES_SET_PAGINATION: "SHIPPING_RATES_SET_PAGINATION",

    fetchShippingRates: ({ province = '', district = '', page = 1, perPage = PAGE_SIZE } = {}) => ({
        type: actions.SHIPPING_RATES_FETCH_REQUEST,
        payload: { province, district, page, perPage }
    }),
}

export default actions
