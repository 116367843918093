import { PAGE_SIZE } from '../../../constants/customers'

const actions = {
    PROJECT_FETCH_CUSTOMERS_REQUEST: 'PROJECT_FETCH_CUSTOMERS_REQUEST',
    PROJECT_FETCH_CUSTOMERS: 'PROJECT_FETCH_CUSTOMERS',
    PROJECT_FETCH_CUSTOMERS_SUCCESS: 'PROJECT_FETCH_CUSTOMERS_SUCCESS',

    PROJECT_SAVE_CUSTOMER_REQUEST: 'PROJECT_SAVE_CUSTOMER_REQUEST',
    PROJECT_SAVE_CUSTOMER: 'PROJECT_SAVE_CUSTOMER',
    PROJECT_SAVE_CUSTOMER_SUCCESS: 'PROJECT_SAVE_CUSTOMER_SUCCESS',
    PROJECT_SET_CUSTOMER_FORM_ERRORS: 'PROJECT_SET_CUSTOMER_FORM_ERRORS',
    PROJECT_RESET_CUSTOMER_FORM_ERRORS: 'PROJECT_RESET_CUSTOMER_FORM_ERRORS',

    PROJECT_DELETE_CUSTOMER_REQUEST: 'PROJECT_DELETE_CUSTOMER_REQUEST',
    PROJECT_DELETE_CUSTOMER: 'PROJECT_DELETE_CUSTOMER',
    PROJECT_DELETE_CUSTOMER_SUCCESS: 'PROJECT_DELETE_CUSTOMER_SUCCESS',

    PROJECT_SET_CUSTOMER_PAGINATION: 'PROJECTS_SET_CUSTOMER_PAGINATION',
    PROJECT_SET_EDIT_USER_TYPE_FORM_MODAL: 'PROJECT_SET_EDIT_USER_TYPE_FORM_MODAL',
    PROJECT_SET_ADD_CUSTOMER_FORM_MODAL: 'PROJECT_SET_ADD_CUSTOMER_FORM_MODAL',

    fetchCompanyProjectCustomers: ({ id, page = 1, per = PAGE_SIZE } = {}) => ({
        type: actions.PROJECT_FETCH_CUSTOMERS_REQUEST,
        payload: { id, page, per }
    }),

    saveCompanyProjectCustomer: ({
        projectId,
        id,
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.PROJECT_SAVE_CUSTOMER_REQUEST,
        payload: { projectId, id, params, onSuccess, onError }
    }),

    deleteCompanyProjectCustomer: ({ projectId, id, onSuccess = () => {} } = {}) => ({
        type: actions.PROJECT_DELETE_CUSTOMER_REQUEST,
        payload: { projectId, id, onSuccess }
    }),

    setEditUserTypeFormModal: (visible, id) => ({
        type: actions.PROJECT_SET_EDIT_USER_TYPE_FORM_MODAL,
        visible, id
    }),

    resetCompanyProjectCustomerFormErrors: () => ({
        type: actions.PROJECT_RESET_CUSTOMER_FORM_ERRORS
    }),

    setAddCustomerFormModal: (visible) => ({
        type: actions.PROJECT_SET_ADD_CUSTOMER_FORM_MODAL,
        visible
    })
}

export default actions
