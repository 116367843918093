import styled from 'styled-components'

const StyledWrapper = styled.div`
  .spacing-form {
    display: flex;

    .ant-space-item:first-child {
      flex: 1;
    }
  }
`

export default StyledWrapper
