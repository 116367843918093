import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import ImagesWrapper from '../images/imagesWrapper.style'

import ImageWithActions from '../../containers/Image/ImageWithActions'

const TrackingImage = ({
    onDeleteTrackingImage,
    orderBusinessAddress,
    saving
}) => {
    const originalUrl = _.get(orderBusinessAddress, 'tracking_image.original', null)
    const thumbUrl = _.get(orderBusinessAddress, 'tracking_image.small', null)

    if (!originalUrl && !thumbUrl) return <></>

    return (
        <ImagesWrapper>
            <div className="image-container">
                <ImageWithActions
                    actions={[
                        <Button
                            icon={<DeleteOutlined />}
                            key="delete-tracking-image-btn"
                            loading={saving}
                            onClick={onDeleteTrackingImage}
                            size="small"
                            type="link"
                        />
                    ]}
                    contentType="image/png"
                    isImageBlob={false}
                    key="tracking-image"
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            </div>
        </ImagesWrapper>
    )
}

TrackingImage.propTypes = {
    onDeleteTrackingImage: PropTypes.func,
    orderBusinessAddress: PropTypes.object,
    saving: PropTypes.bool
}

TrackingImage.defaultProps = {
    onDeleteTrackingImage: () => {},
    orderBusinessAddress: {},
    saving: false
}

export default withImagePreview(TrackingImage)
