const actions = {
    BUSINESSES_FETCH_REQUEST: 'BUSINESSES_FETCH_REQUEST',
    BUSINESSES_FETCH: 'BUSINESSES_FETCH',
    BUSINESSES_FETCH_SUCCESS: 'BUSINESSES_FETCH_SUCCESS',
    BUSINESSES_SET_PAGINATION: 'BUSINESSES_SET_PAGINATION',
    BUSINESSES_SAVE_REQUEST: 'BUSINESSES_SAVE_REQUEST',
    BUSINESSES_SET_SAVING: 'BUSINESSES_SET_SAVING',
    BUSINESSES_SET_FORM_ERRORS: 'BUSINESSES_SET_FORM_ERRORS',
    BUSINESSES_SAVE_SUCCESS: 'BUSINESSES_SAVE_SUCCESS',
    BUSINESSES_ITEM_FETCH_REQUEST: 'BUSINESSES_ITEM_FETCH_REQUEST',
    BUSINESSES_ITEM_FETCH: 'BUSINESSES_ITEM_FETCH',
    BUSINESSES_ITEM_FETCH_SUCCESS: 'BUSINESSES_ITEM_FETCH_SUCCESS',
    BUSINESSES_DELETE_REQUEST: 'BUSINESSES_DELETE_REQUEST',
    BUSINESSES_DELETE_SUCCESS: 'BUSINESSES_DELETE_SUCCESS',


    fetchBusinessAddresses: (id, page = 1) => ({
        type: actions.BUSINESSES_FETCH_REQUEST,
        payload: { id, page }
    }),

    saveAddress: (address, customerId, id, redirect = true, isFromInvoiceFormContainer) => ({
        type: actions.BUSINESSES_SAVE_REQUEST,
        payload: { address, customerId, id, redirect, isFromInvoiceFormContainer }
    }),

    fetchBusinessAddress: (customerId, addressId) => ({
        type: actions.BUSINESSES_ITEM_FETCH_REQUEST,
        payload: { customerId, addressId }
    }),

    deleteBusinessAddress: (customerId, businessAddressId) => ({
        type: actions.BUSINESSES_DELETE_REQUEST,
        payload: { customerId, businessAddressId }
    })
}

export default actions
