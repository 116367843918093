import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List } from 'immutable'

import SelectSupplier from '../../components/SelectSupplier'

import actions from '../../redux/suppliers/actions'

const {
    fetchSupplier,
    searchSupplier
} = actions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectSupplierContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,
        isShowHaveNoSupplier: PropTypes.bool,

        // Inner props
        fetchSupplier: PropTypes.func.isRequired,
        searchSupplier: PropTypes.func.isRequired,
        supplierEntities: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE,
        isShowHaveNoSupplier: false
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { value, fetchSupplier } = this.props

        if (!value || value === VALUE_FROM_STATE) return null
        fetchSupplier({ id: value })
    }

    componentDidUpdate (prevProps) {
        const { value: prevValue } = prevProps
        const { value: currValue, fetchSupplier } = this.props

        if (prevValue === currValue || currValue === VALUE_FROM_STATE) return null

        fetchSupplier({ id: currValue })
    }

    getSuppliers () {
        const { supplierEntities, suppliers } = this.props

        return suppliers.get('supplierItems', new List()).map((supplierId) => {
            return supplierEntities.get(_.toString(supplierId))
        })
    }

    onChange = (supplierId) => {
        const { onChange } = this.props

        onChange(supplierId)
        this.setState({ value: supplierId })
    }

    onSearch = (query) => {
        const { searchSupplier } = this.props
        searchSupplier({ query })
    }

    render () {
        const {
            onChange,
            suppliers,
            isShowHaveNoSupplier,
            value: propValue,
            ...restProps
        } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectSupplier
                loading={suppliers.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                suppliers={this.getSuppliers().toJS()}
                value={value}
                isShowHaveNoSupplier={isShowHaveNoSupplier}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        supplierEntities: state.getIn(['Entities', 'suppliers']),
        suppliers: state.get('suppliers')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplier,
        searchSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSupplierContainer)

