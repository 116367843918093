import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Render from './render'
import { gql, useMutation } from '@apollo/client'

const CREATE_SHIPPING_COST_MUTATION = gql`
    mutation CreateShippingCostWithPickupPoint($orderId: String!, $vehicleTypeId: ID, $sendingPointId: ID) {
        createShippingCostWithPickupPoint(
            orderId: $orderId,
            vehicleTypeId: $vehicleTypeId,
            sendingPointId: $sendingPointId
        ) {
            errors
        }
    }
`

const PickupPointForm = ({ onClose, orderId, refetch }) => {
    const [formErrors, setFormErrors] = useState({})
    const [createShippingCost, { loading: saving }] = useMutation(CREATE_SHIPPING_COST_MUTATION, {
        onCompleted: ({ createShippingCostWithPickupPoint }) => {
            if (createShippingCostWithPickupPoint.errors) {
                setFormErrors(JSON.parse(createShippingCostWithPickupPoint.errors))
            } else {
                setFormErrors({})
                refetch()
                onClose()
            }
        }
    })

    const handleSubmitForm = params => {
        createShippingCost({ variables: { orderId, ...params } })
    }

    return (
        <Render
            formErrors={formErrors}
            onClose={onClose}
            onSubmitForm={handleSubmitForm}
            saving={saving}
        />
    )
}

PickupPointForm.propTypes = {
    onClose: PropTypes.func,
    orderId: PropTypes.string.isRequired,
    refetch: PropTypes.func
}

PickupPointForm.defaultProps = {
    onClose: () => {},
    refetch: () => {}
}

export default PickupPointForm
