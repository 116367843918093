import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import customerActions from '../../redux/customers/actions'
import customerTicketsActions from '../../redux/customers/tickets/actions'
import orderStateActions from '../../redux/inquiry/orderStates/actions'
import potentialLeadActions from '../../redux/potentialLeads/actions'

import CustomerSheet from '../../components/customers/CustomerSheet'
import OrderSummariesContainer from '../OrderSummaries/OrderSummariesContainer'
import PotentialLeadFormContainer from './PotentialLeads/PotentialLeadFormContainer'
import TicketForm from '../../components/customers/tickets/TicketForm'
import TicketsList from '../../components/customers/tickets/TicketsList'

import CustomerSheetWrapper from '../../components/customers/customerSheet.style'
import { Map, List } from 'immutable'
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
const { Option } = Select

const {
    mergeCustomer,
    showPhoneNumbers,
    showEmails,
    showLines,
    saveCustomer
} = customerActions

const { setShowForm: setShowTicketForm } = customerTicketsActions
const { recoverOrder } = orderStateActions
const { setCustomerPotentialLeadForm } = potentialLeadActions

class CustomerSheetContainer extends Component {
    constructor (props) {
        super(props)

        this.ticketsList = createRef()
    }

    handleMergingCustomer = (value) => {
        if (!value) { return null }

        const { mergeCustomer, customerId } = this.props
        const trimValue = value && value.trim()
        const mergingParam = {
            id: customerId,
            minor_customer_id: trimValue
        }

        mergeCustomer(mergingParam)
    }

    renderCustomerMergingInput () {
        const { customers, i18n } = this.props
        const saving = customers.get('saving')

        return (
            <div className={`mg-bottom merging`}>
                <Input.Search
                    placeholder={i18n.t('orders:placeHolderMerging')}
                    enterButton={i18n.t('orders:merging')}
                    onSearch={this.handleMergingCustomer}
                    disabled={saving}
                />
            </div>
        )
    }


    handleAddPotentialLeadForm (value, id) {
        const { setCustomerPotentialLeadForm } = this.props
        setCustomerPotentialLeadForm(value, id)
    }

    getCustomerTags = () => {
        const { entities, customerId } = this.props
        const customerTags = entities.getIn(['customers', `${customerId}`, 'customer_tags']) || new Map()
        const customerTagEntities = entities.getIn(['customerTags'])

        return customerTags.map((customerTagId) => {
            const tag = customerTagEntities.get(customerTagId).toJS()

            return (
                <Option key={tag.id}
                    value={tag.id}>
                    {tag.body}
                </Option>
            )
        })
    }

    getArrayOfKeyCustomerTag = () => {
        const { entities, customerId } = this.props
        const customerTags = entities.getIn(['customers', `${customerId}`, 'customer_tags']) || new List()
        const customerTagEntities = entities.getIn(['customerTags'])
        if (customerTags.toJS().length < 1) {
            return new List()
        }
        return customerTags.map((customerTagId) => {
            const tag = customerTagEntities.get(customerTagId).toJS()

            return { key: tag.id, label: tag.body }
        })
    }

    getArrayOfTag = (values) => {
        return values['customer_tags'].map((tag) => {
            return tag.label
        })
    }

    handleSubmitCustomer = (values) => {
        const { customerId, saveCustomer } = this.props

        const arrayOfTag = this.getArrayOfTag(values)
        Reflect.deleteProperty(values, 'customer_tags')
        let customerValues = {
            id: customerId,
            customer_tags: arrayOfTag
        }

        saveCustomer(customerValues)
    }

    toggleTicketForm = () => {
        const { customerTickets, setShowTicketForm } = this.props

        setShowTicketForm(!customerTickets.get('showForm'))
    }

    render () {
        const { customerId, customerTickets, router, potentialLeads, customers, i18n } = this.props
        const savingStatus = customers.get('saving')

        const formLongLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 21 }
        }

        return (
            <CustomerSheetWrapper>
                <CustomerSheet
                    id={customerId}
                    customerInfo />
                <Divider />

                <Form onFinish={this.handleSubmitCustomer}>
                    <Row>
                        <Col md={{ span: 17 }}>
                            <Form.Item label={i18n.t('customerForm.tag')} {...formLongLayout}
                                name='customer_tags'
                                initialValue={this.getArrayOfKeyCustomerTag().toJS()}>
                                <Select mode="multiple" labelInValue>
                                    {this.getCustomerTags()}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={{ span: 5 }}>
                            <Button type="primary" size="large"
                                htmlType="submit" loading={savingStatus}>
                                {i18n.t('shared:save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <div>
                    {this.renderCustomerMergingInput()}
                    <div className={`right-btn mg-bottom`} >
                        <Space className='right-btn mg-bottom'>
                            <Button type="primary" icon={<PlusOutlined />}
                                onClick={() => this.handleAddPotentialLeadForm(true, null)}>
                                เพิ่ม potential lead
                            </Button>
                            <Button type="primary" icon={<PlusOutlined />}
                                onClick={this.toggleTicketForm}>
                                เพิ่ม Ticket
                            </Button>
                            <Link to={`/customers/${customerId}/orders/new`}>
                                <Button type="primary" icon={<PlusOutlined />}>{i18n.t('orders:addOrder')}</Button>
                            </Link>
                        </Space>
                    </div>
                    <Modal
                        destroyOnClose
                        footer={null}
                        onCancel={() => this.handleAddPotentialLeadForm(false, null)}
                        visible={potentialLeads.get('showForm')}
                        width="60%">
                        <PotentialLeadFormContainer customerId={customerId} />
                    </Modal>
                    <Modal
                        destroyOnClose
                        footer={null}
                        width="50%"
                        onCancel={this.toggleTicketForm}
                        visible={customerTickets.get('showForm')}
                    >
                        <TicketForm
                            customerId={customerId}
                            onClose={this.toggleTicketForm}
                            refetch={() => this.ticketsList.current.refetch()}
                        />
                    </Modal>
                </div>
                <h3>{i18n.t('tickets')}</h3>
                <TicketsList customerId={customerId} ref={this.ticketsList} />
                <OrderSummariesContainer router={router}
                    hideFilter
                    filterBy={{ customer_id: customerId }} />
            </CustomerSheetWrapper>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    customerTickets: state.get('customerTickets'),
    entities: state.get('Entities'),
    orderEntities: state.getIn(['Entities', 'orders']),
    potentialLeads: state.get('potentialLeads')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        mergeCustomer,
        showPhoneNumbers,
        showEmails,
        showLines,
        recoverOrder,
        setCustomerPotentialLeadForm,
        saveCustomer,
        setShowTicketForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['orders', 'orders/tables', 'shared'])(
        CustomerSheetContainer))
