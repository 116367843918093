import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import ShippingRatesIndex from '../../components/shippingRates/shippingRatesIndex'

import actions from '../../redux/shippingRates/actions'

const { fetchShippingRates } = actions

class ShippingRatesIndexContainer extends Component {
    static propTypes = {
        fetchShippingRates: PropTypes.func.isRequired,
        shippingRateEntities: ImmutablePropTypes.map.isRequired,
        shippingRates: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        this.props.fetchShippingRates()
    }

    getShippingRates () {
        const { shippingRateEntities, shippingRates } = this.props
        return shippingRates.get('items', new List()).map((id) => {
            return shippingRateEntities.get(_.toString(id)) || new Map()
        })
    }

    handleChangeProvince = (province) => {
        const { fetchShippingRates, shippingRates } = this.props

        fetchShippingRates({ province, perPage: shippingRates.get('pageSize') })
    }

    handleChangeDistrict = (district) => {
        const { fetchShippingRates, shippingRates } = this.props

        fetchShippingRates({
            ...shippingRates.get('filters').toJS(),
            district,
            perPage: shippingRates.get('pageSize')
        })
    }

    handlePageChange = (pagination) => {
        const { fetchShippingRates, shippingRates } = this.props

        fetchShippingRates({ page: pagination.current, perPage: shippingRates.get('pageSize') })
    }

    render () {
        const { shippingRates } = this.props

        return (
            <ShippingRatesIndex
                districtId={shippingRates.getIn(['filters', 'district'])}
                loading={shippingRates.get('loading')}
                onChangeDistrict={this.handleChangeDistrict}
                onChangeProvince={this.handleChangeProvince}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...shippingRates.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                provinceId={shippingRates.getIn(['filters', 'province'])}
                shippingRates={this.getShippingRates().toJS()}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    shippingRateEntities: state.getIn(['Entities', 'shippingRates']),
    shippingRates: state.get('shippingRates')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingRates
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingRatesIndexContainer)
