import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import Icon from '@ant-design/icons'

import {
    Button,
    Card,
    Dropdown,
    List,
    Menu,
    Modal,
    Popconfirm,
    Spin,
    Table
} from 'antd'
import {
    DeleteTwoTone,
    EditTwoTone,
    FileTwoTone,
    ProfileTwoTone,
    MailOutlined,
    PrinterOutlined,
    DownOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../containers/Image'
import DropdownPdfPreview from '../../containers/Orders/DropdownPdfPreview'
import OrderBusinessAddressFormContainer from '../../containers/Invoices/OrderBusinessAddressFormContainer'
import OrderInvoiceAdjustmentFormContainer from '../../containers/Invoices/OrderInvoiceAdjustmentFormContainer'
import OrderInvoiceItemsFormContainer from '../../containers/Invoices/OrderInvoiceItemsFormContainer'
import SendETaxMailFormContainer from '../../containers/Invoices/SendETaxMailFormContainer'
import TrackingImageContainer from '../../containers/Invoices/TrackingImageContainer'
import TrackingInvoiceForm from '../../containers/Invoices/TrackingInvoiceForm'
import UploadInvoiceTrackingImageContainer from '../../containers/Invoices/UploadInvoiceTrackingImageContainer'
import DepositInvoiceFormContainer from '../../containers/Invoices/DepositInvoiceFormContainer'
import ReissueInvoice from '../../containers/Invoices/ReissueInvoice'
import OrderInvoicesWrapper from './orderInvoice.style'

const renderInvoiceImages = (images) => {
    return images.map((image) => {
        const imageId = image.id
        const thumbUrl = (image.file && image.file.s) || ''
        const originalUrl = (image.file && image.file.original) || ''
        const contentType = (image.file && image.file.content_type) || ''

        return (
            <Image
                key={imageId}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    })
}

const OrderInvoices = ({
    activeInvoiceAdjustmentId,
    activeInvoiceId,
    customerId,
    fetchInvoiceAdjustments,
    handleSendETax,
    i18n,
    invoiceAdjustmentItems,
    invoiceAdjustmentLoading,
    invoiceAdjustmentLoadingIds,
    invoiceAdjustments,
    invoiceEmailHistories,
    invoiceLoading,
    loading,
    onDeleteInvoiceAdjustment,
    onDeleteOrderBusinessAddress,
    onDownloadInvoice,
    onDownloadInvoiceAdjustment,
    onEditOrderBusinessAddress,
    onEditOrderBusinessAddressDeposits,
    onEditOrderBusinessAddressItems,
    onIssueNewInvoiceSuccess,
    onSaveInvoiceError,
    onSaveInvoiceItemsSuccess,
    onSaveInvoiceSuccess,
    onSaveOrderBusinessAddress,
    onSendElectronicCreditNote,
    onToggleInvoiceAdjustmentForm,
    orderBusinessAddressActive,
    orderBusinessAddresses,
    orderId,
    sendECreditNoteSaving,
    sendETaxSaving,
    showBusinessAddressDepositsForm,
    showBusinessAddressForm,
    showBusinessAddressItemsForm,
    showInvoiceAdjustmentForm
}) => {
    const renderDropDownPdfPreview = (text, id, type) => {
        return (
            <DropdownPdfPreview
                icon={<PrinterOutlined />}
                onClick={(locale) => onDownloadInvoice(id, type, locale)}
                size='small'
                style={{ margin: '2px' }}
                text={text}
            />
        )
    }

    const handleExpandRow = (expanded, record) => {
        if (expanded) {
            fetchInvoiceAdjustments(record.id)
        }
    }

    const renderSendETaxButton = (id, type) => {
        return (
            <Popconfirm
                title={i18n.t(
                    'confirms:invoice.confirmSendElectronicCreditNote'
                )}
                onConfirm={() => handleSendETax(id, type)}>
                <Button block size='small' loading={sendETaxSaving}>
                    <MailOutlined />
                    {i18n.t('confirms:invoice.sendETax')}
                </Button>
            </Popconfirm>
        )
    }

    const renderSendElectronicCreditNote = (record, row) => {
        if (record.is_sent_e_tax) {
            return (
                <Button
                    block
                    size='small'
                    target='_blank'
                    href={record.e_invoice_adjustment_document_url}>
                    <PrinterOutlined />{' '}
                    {i18n.t('confirms:invoice.downloadElectronicCreditNote')}
                </Button>
            )
        } else {
            return (
                <Popconfirm
                    title={i18n.t(
                        'confirms:invoice.confirmSendElectronicCreditNote'
                    )}
                    onConfirm={() =>
                        onSendElectronicCreditNote(row.id, record.id)
                    }>
                    <Button
                        block
                        size='small'
                        icon={
                            sendECreditNoteSaving ? (
                                <LoadingOutlined />
                            ) : (
                                <MailOutlined />
                            )
                        }>
                        {' '}
                        {i18n.t('confirms:invoice.sendElectronicCreditNote')}
                    </Button>
                </Popconfirm>
            )
        }
    }

    const renderSendTaxInvoiceHistories = (items) => {
        if (items.length <= 0) return null
        return (
            <List
                size='small'
                style={{ marginTop: '8px' }}
                header={`${i18n.t('confirms:invoice.mailDeliveryHistories')}`}
                bordered
                dataSource={items}
                renderItem={(item) => (
                    <List.Item>
                        <span>
                            {item.staff_name}{' '}
                            {i18n.t('confirms:invoice.sendTo', {
                                invoiceNo: item.invoice_no
                            })}
                            <strong> {item.email} </strong>
                            {i18n.t('confirms:invoice.date')}{' '}
                            {moment(item.created_at).format('DD MMM YYYY')}
                        </span>
                    </List.Item>
                )}
            />
        )
    }

    const renderSendETaxMailSection = (row) => {
        const items = _.filter(
            invoiceEmailHistories.toJS(),
            (item) => `${item.order_business_address_id}` === row.id
        )
        return (
            <Card style={{ width: 230 }} size='small'>
                <SendETaxMailFormContainer
                    customerId={customerId}
                    orderId={orderId}
                    id={row.id}
                    taxInvoice={row}
                />
                {renderSendTaxInvoiceHistories(items)}
            </Card>
        )
    }

    const expandedRowRender = (row) => {
        const invoiceAdjustmentList = _.get(
            invoiceAdjustmentItems,
            row.id,
            []
        ).map((id) => {
            return _.get(invoiceAdjustments, id, {})
        })

        const columns = [
            {
                key: 'adjustment_type',
                title: i18n.t('confirms:invoice.adjustmentType'),
                dataIndex: 'adjustment_type',
                render: (dataIndex) =>
                    i18n.t(
                        `confirms:invoice.adjustmentTypes.${_.camelCase(
                            dataIndex
                        )}`
                    )
            },
            {
                key: 'grand_total',
                title: i18n.t('confirms:invoice.grandTotal'),
                render: (record) => {
                    const discount = _.get(record, 'money_display.discount')
                    return (
                        <div>
                            <div>
                                {_.get(record, 'money_display.grand_total')}
                            </div>
                            {discount !== '0.00' ? (
                                <div>
                                    ( {i18n.t('confirms:invoice.discount')}:{' '}
                                    {discount} )
                                </div>
                            ) : null}
                        </div>
                    )
                }
            },
            {
                key: 'adjustment_date',
                title: i18n.t('confirms:invoice.adjustmentDate'),
                dataIndex: 'adjustment_date',
                render: (dataIndex) => {
                    const adjustmentDate = dataIndex
                        ? moment(dataIndex).format('DD MMM YYYY')
                        : null

                    return adjustmentDate
                }
            },
            {
                key: 'ref_no',
                title: i18n.t('confirms:invoice.refNo'),
                dataIndex: 'ref_no'
            },
            {
                key: 'note',
                title: i18n.t('confirms:invoice.note'),
                dataIndex: 'note'
            },
            {
                key: 'actions',
                title: i18n.t('confirms:invoice.edit'),
                render: (record) => {
                    return (
                        <>
                            {record.adjustment_type === 'credit_note' &&
                                renderSendElectronicCreditNote(record, row)}
                            <DropdownPdfPreview
                                icon={<PrinterOutlined />}
                                onClick={(e) =>
                                    onDownloadInvoiceAdjustment({
                                        orderId,
                                        invoiceId: row.id,
                                        id: record.id,
                                        locale: e.key
                                    })
                                }
                                size='small'
                                style={{ margin: '2px' }}
                                text={`${i18n.t(
                                    `confirms:invoice.adjustmentTypes.${_.camelCase(
                                        record.adjustment_type
                                    )}`
                                )}`}
                                loading={invoiceAdjustmentLoading}
                            />
                            <Link
                                onClick={() =>
                                    onToggleInvoiceAdjustmentForm({
                                        invoiceId: row.id,
                                        id: record.id
                                    })
                                }>
                                <div>
                                    <EditTwoTone />{' '}
                                    {i18n.t('confirms:invoice.edit')}
                                </div>
                            </Link>
                            <Popconfirm
                                title={i18n.t('confirms:deleteConfirm')}
                                onConfirm={() =>
                                    onDeleteInvoiceAdjustment({
                                        invoiceId: row.id,
                                        id: record.id
                                    })
                                }>
                                <a style={{ color: 'red' }}>
                                    <DeleteTwoTone twoToneColor='red' />{' '}
                                    {i18n.t('confirms:delete')}
                                </a>
                            </Popconfirm>
                        </>
                    )
                }
            }
        ]

        return (
            <Table
                columns={columns}
                dataSource={invoiceAdjustmentList}
                loading={invoiceAdjustmentLoadingIds.includes(
                    row.id.toString()
                )}
                rowKey='id'
                pagination={false}
            />
        )
    }

    const columns = [
        {
            key: 'rowIndex',
            width: '4%',
            render: (row, record, index) => <div>{index + 1}</div>
        },
        {
            key: 'address_detail',
            title: i18n.t('addresses:businessAddress'),
            width: '320px',
            render: (row) => {
                if (row.invoice_type === 'outside') {
                    return (
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.type')}:{' '}
                            </span>
                            {i18n.t('confirms:invoice.createFormOutside')}
                        </div>
                    )
                } else if (row.invoice_type === 'abb') {
                    return (
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.type')}:{' '}
                            </span>
                            {i18n.t('confirms:invoice.taxInvoiceAbb')}
                        </div>
                    )
                }

                const address = [
                    _.get(row, 'address_detail', ''),
                    _.get(row, 'district.name', ''),
                    _.get(row, 'sub_district', ''),
                    _.get(row, 'province.name', ''),
                    _.get(row, 'postal_code', '')
                ]
                    .filter(Boolean)
                    .join(' ')

                return (
                    <div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.taxId')}:{' '}
                            </span>
                            {row.tax_number}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('addresses:name')}:{' '}
                            </span>
                            {row.company_name}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('addresses:branch')}:{' '}
                            </span>
                            {row.branch || ''}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('addresses:address')}:{' '}
                            </span>
                            {address}
                        </div>
                    </div>
                )
            }
        },
        {
            key: 'invoice_detail',
            title: i18n.t('confirms:invoice.taxInvoice'),
            render: (row) => {
                const invoiceDate = row.invoice_date
                    ? moment(row.invoice_date).format('DD MMM YYYY')
                    : null
                const paymentDate = row.payment_date
                    ? moment(row.payment_date).format('DD MMM YYYY')
                    : null

                return (
                    <div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.invoiceNo')}:{' '}
                            </span>
                            {row.invoice_no}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.invoiceDate')}:{' '}
                            </span>
                            {invoiceDate}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.paymentDate')}:{' '}
                            </span>
                            {paymentDate}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('confirms:invoice.paymentAccount')}:{' '}
                            </span>
                            {row.payment_account}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {i18n.t('addresses:trackingDetail')}:{' '}
                            </span>
                            {row.sending_date || ''} :{' '}
                            {row.ems_tracking_number || ''}
                        </div>
                    </div>
                )
            }
        },
        {
            key: 'grand_total',
            title: i18n.t('confirms:invoice.grandTotal'),
            dataIndex: 'grand_total'
        },
        {
            key: 'invoice_tracking',
            title: i18n.t('confirms:invoice.invoiceTracking'),
            dataIndex: 'invoice_tracking',
            render: (dataIndex, record) => {
                const previousInvoiceNo = record.previous_invoice_no
                const currentInvoiceNo = record.current_invoice_no
                const invoiceTrackingNMenu = (
                    <Menu
                        onClick={({ key }) => {
                            onSaveOrderBusinessAddress({
                                id: record.id,
                                address: { invoice_tracking: key }
                            })
                        }}>
                        <Menu.Item key='pending'>
                            {i18n.t(
                                'confirms:invoice.invoiceTrackings.pending'
                            )}
                        </Menu.Item>
                        <Menu.Item key='follow_up'>
                            {i18n.t(
                                'confirms:invoice.invoiceTrackings.followUp'
                            )}
                        </Menu.Item>
                        <Menu.Item key='sent'>
                            {i18n.t('confirms:invoice.invoiceTrackings.sent')}
                        </Menu.Item>
                    </Menu>
                )

                return (
                    <React.Fragment>
                        <Dropdown overlay={invoiceTrackingNMenu}>
                            <a
                                className='ant-dropdown-link'
                                onClick={(e) => e.preventDefault()}>
                                {i18n.t(
                                    `confirms:invoice.invoiceTrackings.${_.camelCase(
                                        dataIndex
                                    )}`
                                )}{' '}
                                <DownOutlined />
                            </a>
                        </Dropdown>
                        {previousInvoiceNo && (
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('confirms:invoice.referenceNo')}:{' '}
                                </span>
                                {previousInvoiceNo}
                            </div>
                        )}
                        {currentInvoiceNo && (
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('confirms:invoice.reInvoiceNo')}:{' '}
                                </span>
                                {currentInvoiceNo}
                            </div>
                        )}
                    </React.Fragment>
                )
            }
        },
        {
            key: 'actions',
            title: i18n.t('confirms:invoice.edit'),
            render: (row) => (
                <div>
                    {!row.is_sent_e_tax && (
                        <a onClick={() => onEditOrderBusinessAddress(row.id)}>
                            <div>
                                <EditTwoTone />{' '}
                                {i18n.t('confirms:invoice.edit')}
                            </div>
                        </a>
                    )}
                    <a
                        onClick={() =>
                            onToggleInvoiceAdjustmentForm({ invoiceId: row.id })
                        }>
                        <div>
                            <FileTwoTone />{' '}
                            {i18n.t('confirms:invoice.addInvoiceAdjustment')}
                        </div>
                    </a>
                    <TrackingInvoiceForm invoice={row} orderId={orderId} />
                    {row.invoice_type !== 'outside' && !row.is_sent_e_tax ? (
                        <a
                            onClick={() =>
                                onEditOrderBusinessAddressItems(row.id)
                            }>
                            <div>
                                <ProfileTwoTone />{' '}
                                {i18n.t('confirms:invoice.lineItems')}
                            </div>
                        </a>
                    ) : null}
                    {row.invoice_type !== 'outside' && !row.is_sent_e_tax && (
                        <a
                            onClick={() =>
                                onEditOrderBusinessAddressDeposits(row.id)
                            }>
                            <div>
                                <FileTwoTone />{' '}
                                {i18n.t('confirms:accounting.deposit')}
                            </div>
                        </a>
                    )}
                    <ReissueInvoice
                        customerNo={row.customer_no}
                        currentInvoiceNo={row.current_invoice_no}
                        invoiceId={row.id}
                        invoiceType={row.invoice_type}
                        onSuccess={onIssueNewInvoiceSuccess}
                    />
                    {!row.is_sent_e_tax && (
                        <Popconfirm
                            title={i18n.t('addresses:deleteConfirm')}
                            onConfirm={() =>
                                onDeleteOrderBusinessAddress(row.id)
                            }>
                            <a style={{ color: 'red' }}>
                                <DeleteTwoTone twoToneColor='red' />{' '}
                                {i18n.t('confirms:delete')}
                            </a>
                        </Popconfirm>
                    )}
                </div>
            )
        },
        {
            key: 'download',
            title: i18n.t('confirms:invoice.loadTaxInvoice'),
            render: (row) => {
                if (row.invoice_type === 'outside') {
                    return (
                        <OrderInvoicesWrapper>
                            <div className='image-container'>
                                {renderInvoiceImages(row.images)}
                            </div>
                        </OrderInvoicesWrapper>
                    )
                }

                return (
                    <div>
                        {renderDropDownPdfPreview(
                            i18n.t('confirms:invoice.taxInvoiceExample'),
                            row.id,
                            'tax_invoice'
                        )}
                        {row.is_sent_e_tax ? (
                            <Button
                                icon={<PrinterOutlined />}
                                target='_blank'
                                href={row.e_tax_document_url}>
                                {i18n.t('confirms:invoice.downloadEtag')}
                            </Button>
                        ) : (
                            renderSendETaxButton(row.id, 'tax_invoice')
                        )}
                        <div>
                            {row.is_sent_e_tax
                                ? renderSendETaxMailSection(row)
                                : null}
                        </div>
                        <TrackingImageContainer orderBusinessAddress={row} />
                        <UploadInvoiceTrackingImageContainer
                            orderBusinessAddressId={_.get(row, 'id', null)}
                            orderId={orderId}
                        />
                    </div>
                )
            }
        }
    ]

    return (
        <div>
            <h3 style={{ marginTop: '16px' }}>
                {i18n.t('confirms:invoice.taxInvoiceList')}
            </h3>
            <div>(+- ยังไม่มีที่อยู่ส่งเอกสาร, ** ที่อยู่ตรงกัน)</div>
            <Table
                columns={columns}
                dataSource={orderBusinessAddresses}
                onExpand={handleExpandRow}
                expandedRowRender={expandedRowRender}
                loading={loading}
                pagination={false}
                rowKey={(row) => row.id}
                scroll={{ x: 1300 }}
            />
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderBusinessAddress(null)}
                visible={showBusinessAddressForm}
                width='60%'>
                <OrderBusinessAddressFormContainer
                    addressId={orderBusinessAddressActive}
                    orderId={orderId}
                    onSaveSuccess={onSaveInvoiceSuccess}
                    onSaveError={onSaveInvoiceError}
                />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderBusinessAddressItems(null)}
                visible={showBusinessAddressItemsForm}
                width='60%'>
                <OrderInvoiceItemsFormContainer
                    orderId={orderId}
                    invoiceId={orderBusinessAddressActive}
                    onSaveSuccess={onSaveInvoiceItemsSuccess}
                />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={onToggleInvoiceAdjustmentForm}
                visible={showInvoiceAdjustmentForm}
                width='60%'>
                <OrderInvoiceAdjustmentFormContainer
                    id={activeInvoiceAdjustmentId}
                    invoiceId={activeInvoiceId}
                    orderId={orderId}
                    onSaveSuccess={onToggleInvoiceAdjustmentForm}
                />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderBusinessAddressDeposits()}
                visible={showBusinessAddressDepositsForm}
                width='60%'>
                <DepositInvoiceFormContainer
                    invoiceId={orderBusinessAddressActive}
                    orderId={orderId}
                    toggleForm={onEditOrderBusinessAddressDeposits}
                />
            </Modal>
            <Modal
                style={{
                    height: '100px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                width='100px'
                visible={invoiceLoading}
                closable={false}
                footer={null}>
                <Spin spinning={invoiceLoading} />
            </Modal>
        </div>
    )
}

OrderInvoices.propTypes = {
    activeInvoiceAdjustmentId: PropTypes.string,
    activeInvoiceId: PropTypes.string,
    fetchInvoiceAdjustments: PropTypes.func.isRequired,
    invoiceAdjustmentItems: PropTypes.object.isRequired,
    invoiceAdjustmentLoadingIds: PropTypes.array.isRequired,
    invoiceAdjustments: PropTypes.object.isRequired,
    invoiceLoading: PropTypes.bool,
    loading: PropTypes.bool,
    onDeleteInvoiceAdjustment: PropTypes.func.isRequired,
    handleSendETax: PropTypes.func.isRequired,
    onDeleteOrderBusinessAddress: PropTypes.func.isRequired,
    onDownloadInvoice: PropTypes.func.isRequired,
    onEditOrderBusinessAddress: PropTypes.func.isRequired,
    onEditOrderBusinessAddressItems: PropTypes.func.isRequired,
    onIssueNewInvoiceSuccess: PropTypes.func.isRequired,
    onSaveInvoiceError: PropTypes.func,
    onSaveInvoiceItemsSuccess: PropTypes.func,
    onSaveInvoiceSuccess: PropTypes.func,
    onSaveOrderBusinessAddress: PropTypes.func,
    onToggleInvoiceAdjustmentForm: PropTypes.func,
    orderBusinessAddressActive: PropTypes.string,
    orderBusinessAddresses: PropTypes.array.isRequired,
    orderId: PropTypes.string,
    showBusinessAddressForm: PropTypes.bool,
    showBusinessAddressItemsForm: PropTypes.bool,
    showInvoiceAdjustmentForm: PropTypes.bool
}

export default withTranslation(['confirms', 'addresses'])(
    withImagePreview(OrderInvoices)
)
