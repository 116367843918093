import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const VEHICLE_TYPES_QUERY = gql`
    query DelivereeVehicleTypes {
        delivereeVehicleTypes {
            id
            name
        }
    }
`

const renderOptions = (options = []) => {
  return options.map(({ name, id }) => {
      return <Select.Option value={id}>{name}</Select.Option>
  })
}

const SelectDelivereeVehicleTypes = ({ ...restProps }) => {
  const { loading, data } = useQuery(VEHICLE_TYPES_QUERY)

  return (
    <Select loading={loading} {...restProps}>
        {renderOptions(data && data.delivereeVehicleTypes)}
    </Select>
  )
}

SelectDelivereeVehicleTypes.propTypes = {}

SelectDelivereeVehicleTypes.defaultProps = {}

export default SelectDelivereeVehicleTypes
