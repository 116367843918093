import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Table } from 'antd'

import { withBank } from '../../helpers/bank'

const OrderScbPaymentConfirmList = ({
    // props from another component
    i18n,
    loading,
    scbPaymentConfirms,

    // inner props
    bankCodeToBankName
}) => {
    if (!loading && _.isEmpty(scbPaymentConfirms)) return null

    moment.locale(i18n.language)

    const columns = [{
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
        key: 'transaction_id'
    }, {
        title: i18n.t('scbPaymentConfirms:payer'),
        key: 'payer',
        render: (scbPaymentConfirm) => {
            const message = _.compact([
                _.get(scbPaymentConfirm, 'payer_account_name', ''),
                _.get(scbPaymentConfirm, 'payer_account_number'),
                bankCodeToBankName(_.get(scbPaymentConfirm, 'sending_bank_code', ''))
            ]).join(' ')

            return (
                <p>{message}</p>
            )
        }
    }, {
        title: i18n.t('scbPaymentConfirms:payee'),
        key: 'payee',
        render: (scbPaymentConfirm) => {
            const message = _.compact([
                _.get(scbPaymentConfirm, 'payee_name', ''),
                _.get(scbPaymentConfirm, 'payee_account_number', ''),
                bankCodeToBankName(_.get(scbPaymentConfirm, 'receiving_bank_code', ''))
            ]).join(' ')

            return (
                <p>{message}</p>
            )
        }
    }, {
        title: i18n.t('scbPaymentConfirms:transactionDateTime'),
        key: 'transaction_time',
        render: (scbPaymentConfirm) => {
            const transactionTime = _.get(scbPaymentConfirm, 'transaction_date_and_time', '')

            return <p>{transactionTime && moment(transactionTime).format('LLLL')}</p>
        }
    }, {
        title: i18n.t('scbPaymentConfirms:amount'),
        key: 'amount',
        render: (scbPaymentConfirm) => {
            return <p>{`${_.get(scbPaymentConfirm, 'amount', 0.00)} ${i18n.t('scbPaymentConfirms:baht')}`}</p>
        }
    }]

    return (
        <div className="order-scb-payment-confirm-list">
            <h4>{i18n.t('scbPaymentConfirms:scbPaymentDetail')}</h4>

            <Table
                columns={columns}
                dataSource={scbPaymentConfirms}
                loading={loading}
                pagination={false}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1080 }}
            />
        </div>
    )
}

OrderScbPaymentConfirmList.propTypes = {
    loading: PropTypes.bool,
    scbPaymentConfirms: PropTypes.array,

    bankCodeToBankName: PropTypes.func.isRequired
}

OrderScbPaymentConfirmList.defaultProps = {
    loading: false,
    scbPaymentConfirms: []
}

export default withTranslation(['scbPaymentConfirms'])(withBank(OrderScbPaymentConfirmList))
