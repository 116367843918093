import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Form, Row, Col, Button } from 'antd'

import SelectShippingAddresses from '../../../../Customers/ShippingAddresses/SelectShippingAddresses'

import inquiryActions from '../../../../../redux/inquiry/actions'
import orderShippingAddressActions from '../../../../../redux/orders/shippingAddresses/actions'

const { fetchInquiry } = inquiryActions
const {
  saveOrderShippingAddress
} = orderShippingAddressActions

const SelectOldShippingAddressForm = ({
  orderId,
  orderShippingAddressId,
  customerId,
  onCancel
}) => {
  const { i18n } = useTranslation('inquiries')

  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const saving = useSelector(state => state.getIn(['orderShippingAddresses', 'saving']))

  const onSelectAddressChange = (id) => {
    form.setFieldsValue({ ['shipping_address_id']: id })
  }

  const handleSubmitSelectShippingAddress = (values) => {
    dispatch(saveOrderShippingAddress({
        orderId,
        params: values,
        id: orderShippingAddressId,
        onSuccess: () => { dispatch(fetchInquiry(orderId)) }
    }))
  }

  return (
    <Form onFinish={handleSubmitSelectShippingAddress} form={form}>
      <Form.Item name='shipping_address_id'>
          <SelectShippingAddresses customerId={customerId}
              onChange={(value) => {
                  onSelectAddressChange(value)
              }} />
      </Form.Item>
      <Row justify="center" type="flex">
          <Col span={2}>
              <Button
                  type="primary"
                  loading={saving}
                  htmlType="submit"> {i18n.t('inquiries:edit')}
              </Button>
          </Col>
          <Col span={2}>
              <Button
                  onClick={onCancel}>
                  {i18n.t('inquiries:cancel')}
              </Button>
          </Col>
      </Row>
  </Form>
  )
}

SelectOldShippingAddressForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderShippingAddressId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customerId: PropTypes.string.isRequired,
  onCancel: PropTypes.func
}

SelectOldShippingAddressForm.defaultProps = {
  onCancel: () => {}
}

export default SelectOldShippingAddressForm
