import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import actions from '../../../redux/orders/summaryDashBoards/actions'
import { Table } from 'antd'

const { fetchOrderSummariesDashboardStatistics, fetchOrderSummarySales, setStatisticsFilters } = actions

const OrderSummariesSalesListContainer = ({
    onRefetch
}) => {
    const { i18n } = useTranslation('statistics')
    const dispatch = useDispatch()
    const filters = useSelector(state => state.getIn(['orderSummaryDashBoards', 'statistics', 'filters'])).toJS()
    const items = useSelector(state => state.getIn(['orderSummaryDashBoards', 'saleItems'])) || new List()
    const orderSummarySaleEntities = useSelector(state => state.getIn(['Entities', 'orderSummarySales'])) || new List()

    useEffect(() => {
        dispatch(fetchOrderSummarySales(filters))
    }, [])

    const getSales = () => {
        return items.map(id => orderSummarySaleEntities.get(id) || new Map())
    }

    const onChangeSale = (id) => {
        const newFilters = filters

        if (id === 'empty') {
            newFilters['bySale'] = 'nil'
        } else {
            newFilters['bySale'] = id
        }

        onRefetch(newFilters)
        dispatch(fetchOrderSummariesDashboardStatistics(newFilters))
        dispatch(setStatisticsFilters(newFilters))
    }

    const columns = [
        {
            title: i18n.t('statistics:sales.name'),
            dataIndex: 'sale_name',
            render: (name) => name ? name : i18n.t('statistics:noSale')
        },
        {
            title: i18n.t('statistics:sales.count'),
            dataIndex: 'count'
        },
        {
            title: i18n.t('statistics:sales.percentage'),
            dataIndex: 'percentage',
            render: (percentage) => `${percentage} %`
        },
        {
            render: (record) => (
                <div onClick={() => onChangeSale(record.id)}>
                    <a>{i18n.t('customerForm.seeMore')}</a>
                </div>
            )
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={getSales().toJS()}
            pagination={false}
            rowKey="id"
        />
    )
}

OrderSummariesSalesListContainer.propTypes = {
    onRefetch: PropTypes.func
}

OrderSummariesSalesListContainer.defaultProps = {
    onRefetch: () => {}
}

export default OrderSummariesSalesListContainer
