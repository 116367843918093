import { Map, List } from 'immutable'

import orderTableActions from './actions'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../../constants/orders'

const {
    ORDER_TABLES_RESET,
    ORDER_TABLES_SET_FILTER,
    ORDER_TABLES_SET_PAGINATION,
    ORDER_TABLES_SET_PERIOD,
    ORDER_TABLES_SET_QUERY,
    ORDER_TABLES_SET_SORTER,
    ORDER_TABLES_SET_STATE,
    ORDER_TABLES_SET_SALE,
    ORDER_TABLES_SET_CS,
    ORDER_TABLES_SET_LEAD_SOURCE,
    ORDER_TABLES_SALE_FETCH_SUCCESS,
    ORDER_TABLES_CS_FETCH_SUCCESS,
    ORDER_TABLES_SET_RESERVATION_DATE,
    ORDER_TABLES_PURCHASES_FETCH_SUCCESS
} = orderTableActions

const initialState = new Map({
    filter: new Map(),
    pagination: new Map({
        current: DEFAULT_CURRENT_PAGE,
        pageSize: PAGE_SIZE,
        total: PAGE_SIZE
    }),
    period: new List(),
    query: '',
    sorter: new Map(),
    state: '',
    filterSale: false,
    filterCs: false,
    filterSaleKey: false,
    filterCsKey: false,
    filterLeadSource: false,
    saleItems: new List(),
    csItems: new List(),
    purchaseItems: new List(),
    reservationDate: ''
})

export default function orderTablesReducer (state = initialState, action) {
    switch (action.type) {
        case ORDER_TABLES_RESET:
            return initialState
        case ORDER_TABLES_SET_FILTER:
            return state.mergeDeep({ filter: action.filter })
        case ORDER_TABLES_SET_PAGINATION:
            return state.mergeDeep({ pagination: action.pagination })
        case ORDER_TABLES_SET_PERIOD:
            return state.merge({ period: new List(action.period) })
        case ORDER_TABLES_SET_QUERY:
            return state.merge({ query: action.query })
        case ORDER_TABLES_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        case ORDER_TABLES_SET_STATE:
            return state.merge({ state: action.state })
        case ORDER_TABLES_SET_SALE:
            return state.merge({
                filterSale: action.filterSale,
                filterSaleKey: action.filterSaleKey
            })
        case ORDER_TABLES_SET_CS:
            return state.merge({
                filterCs: action.filterCs,
                filterCsKey: action.filterCsKey
            })
        case ORDER_TABLES_SET_LEAD_SOURCE:
            return state.merge({ filterLeadSource: action.filterLeadSource })
        case ORDER_TABLES_SALE_FETCH_SUCCESS:
            return state.merge({ saleItems: new List(action.result) })
        case ORDER_TABLES_CS_FETCH_SUCCESS:
            return state.merge({ csItems: new List(action.result) })
        case ORDER_TABLES_PURCHASES_FETCH_SUCCESS:
            return state.merge({ purchaseItems: new List(action.result) })
        case ORDER_TABLES_SET_RESERVATION_DATE:
            return state.merge({ reservationDate: action.reservationDate })
        default:
            return state
    }
}
