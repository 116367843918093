import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import { customerCompanyProjectSchema, customerCompanyProjectsSchema } from '../../schema'
import actions from './actions'

const {
    PROJECT_FETCH_CUSTOMERS_REQUEST,
    PROJECT_FETCH_CUSTOMERS,
    PROJECT_FETCH_CUSTOMERS_SUCCESS,
    PROJECT_SET_CUSTOMER_PAGINATION,
    PROJECT_SAVE_CUSTOMER_REQUEST,
    PROJECT_SAVE_CUSTOMER,
    PROJECT_SAVE_CUSTOMER_SUCCESS,
    PROJECT_SET_CUSTOMER_FORM_ERRORS,
    PROJECT_DELETE_CUSTOMER_REQUEST,
    PROJECT_DELETE_CUSTOMER,
    PROJECT_DELETE_CUSTOMER_SUCCESS
} = actions

export function *fetchCompanyProjectCustomers () {
    yield takeEvery(PROJECT_FETCH_CUSTOMERS_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_FETCH_CUSTOMERS })

        const { id, page, per } = payload
        const queryString = `page=${page}&per=${per}`

        const data = yield call(apiFetch, `/company_projects/${id}/customers?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerCompanyProjectsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: PROJECT_SET_CUSTOMER_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PROJECT_FETCH_CUSTOMERS_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveCompanyProjectCustomer () {
    yield takeLatest(PROJECT_SAVE_CUSTOMER_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_SAVE_CUSTOMER, saving: true })

        const { projectId, id, params, onSuccess, onError } = payload

        const basePath = `/company_projects/${projectId}/customers`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ customer_company_project: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: PROJECT_SET_CUSTOMER_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, customerCompanyProjectSchema)

                yield put({
                    type: PROJECT_SAVE_CUSTOMER_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: PROJECT_SET_CUSTOMER_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: PROJECT_SAVE_CUSTOMER, saving: false })
        }
    })
}

export function *deleteCompanyProjectCustomer () {
    yield takeLatest(PROJECT_DELETE_CUSTOMER_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_DELETE_CUSTOMER })

        const { projectId, id, onSuccess } = payload
        const path = `/company_projects/${projectId}/customers/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerCompanyProjectSchema)

        yield put({
            type: PROJECT_DELETE_CUSTOMER_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCompanyProjectCustomers),
        fork(saveCompanyProjectCustomer),
        fork(deleteCompanyProjectCustomer)
    ])
}
