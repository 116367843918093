import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch, apiPostFormWithProgress } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import { itemTruckImagesSchema, itemTruckImageSchema } from '../../schema'

const {
    ITEM_TRUCK_IMAGE_REMOVE_FAIL,
    ITEM_TRUCK_IMAGE_REMOVE_REQUEST,
    ITEM_TRUCK_IMAGE_REMOVE_SUCCESS,
    ITEM_TRUCK_IMAGE_REMOVING,
    ITEM_TRUCK_IMAGE_SAVE_FAIL,
    ITEM_TRUCK_IMAGE_SAVE_REQUEST,
    ITEM_TRUCK_IMAGE_SAVE_SUCCESS,
    ITEM_TRUCK_IMAGE_SAVING,
    ITEM_TRUCK_IMAGES_FETCH_FAIL,
    ITEM_TRUCK_IMAGES_FETCH_REQUEST,
    ITEM_TRUCK_IMAGES_FETCH_SUCCESS,
    ITEM_TRUCK_IMAGES_FETCHING
} = actions

export function *fetchItemTruckImagesSaga () {
    yield takeLatest(ITEM_TRUCK_IMAGES_FETCH_REQUEST, function *({ payload, scope }) {
        yield put({ type: ITEM_TRUCK_IMAGES_FETCHING })

        const { orderId, onSuccess, onError } = payload
        let path = ''
        switch (scope) {
            case 'order':
                path = `/orders/${orderId}/line_items/truck_images`
                break
            default:
                path = ''
        }

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemTruckImagesSchema)

            yield put({
                type: ITEM_TRUCK_IMAGES_FETCH_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: ITEM_TRUCK_IMAGES_FETCH_FAIL })
            onError()
        }
    })
}

export function *removeItemTruckImageSaga () {
    yield takeLatest(ITEM_TRUCK_IMAGE_REMOVE_REQUEST, function *({ payload }) {
        yield put({ type: ITEM_TRUCK_IMAGE_REMOVING })

        const { itemId, id, onSuccess, onError } = payload
        const path = `/items/${itemId}/truck_images/${id}`

        try {
            const data = yield call(apiFetch, path, 'DELETE')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemTruckImageSchema)

            yield put({
                type: ITEM_TRUCK_IMAGE_REMOVE_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: ITEM_TRUCK_IMAGE_REMOVE_FAIL })
            onError()
        }
    })
}

export function *saveItemTruckImageSaga () {
    yield takeEvery(ITEM_TRUCK_IMAGE_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ITEM_TRUCK_IMAGE_SAVING })

        const { itemId, id, params, onSuccess, onError, onUploadProgress } = payload
        const basePath = `/items/${itemId}/truck_images`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'
        try {
            const data = yield call(apiPostFormWithProgress, {
                path,
                method,
                params,
                onUploadProgress
            })
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemTruckImageSchema)
            yield put({
                type: ITEM_TRUCK_IMAGE_SAVE_SUCCESS,
                ...normalizedData
            })
            onSuccess()
        } catch (error) {
            yield put({ type: ITEM_TRUCK_IMAGE_SAVE_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchItemTruckImagesSaga),
        fork(removeItemTruckImageSaga),
        fork(saveItemTruckImageSaga)
    ])
}
