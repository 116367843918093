import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import i18n from 'i18next'

import { apiFetch, apiPostForm } from '../../../helpers/restRequest'
import { customerRoleSchema, customerRolesSchema } from '../../schema'
import actions from './actions'

import actionsNotification from '../../../redux/notifications/actions'

const { setNotifications } = actionsNotification

const {
    CUSTOMER_ROLES_FETCH_REQUEST,
    CUSTOMER_ROLES_FETCHING,
    CUSTOMER_ROLES_FETCH_SUCCESS,
    CUSTOMER_ROLES_SAVE_REQUEST,
    CUSTOMER_ROLES_SAVING,
    CUSTOMER_ROLES_SAVE_SUCCESS,
    CUSTOMER_ROLE_SET_FORM,
    CUSTOMER_ROLES_IMPORT_FILE_REQUEST,
    CUSTOMER_ROLES_IMPORT_FILE_UPLOADING
} = actions

const apiPath = (id) => {
    const basePath = '/customers/roles'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *customerRolesRequest () {
    yield takeEvery(CUSTOMER_ROLES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_ROLES_FETCHING, loading: true })

        const path = apiPath()

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerRolesSchema)

        yield put({ type: CUSTOMER_ROLES_FETCH_SUCCESS, ...normalizeData })
    })
}

export function *saveCustomerRole () {
    yield takeLatest(CUSTOMER_ROLES_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_ROLES_SAVING, saving: true })

        const id = payload.id
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ role: payload.values })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, customerRoleSchema)

            yield put({
                type: CUSTOMER_ROLES_SAVE_SUCCESS,
                ...normalizeData
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))

            yield put({ type: CUSTOMER_ROLE_SET_FORM, showRoleForm: false })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMER_ROLES_SAVING, saving: false })
        }
    })
}

export function *importCustomerRoles () {
    yield takeLatest(CUSTOMER_ROLES_IMPORT_FILE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_ROLES_IMPORT_FILE_UPLOADING, uploading: true })

        const { file } = payload
        const path = '/customers/roles/imports'
        const method = 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { file })
            i18n.loadNamespaces(['customers/roles', 'shared'])

            if (data.status === 'ok') {
                yield put(
                    setNotifications(
                        `${i18n.t('customers/roles:upload')} ${i18n.t('shared:successful')}`,
                        i18n.t('customers/roles:uploadSuccess'),
                        'info',
                        10000
                    )
                )
            } else {
                yield put(
                    setNotifications(
                        `${i18n.t('customers/roles:upload')} ${i18n.t('shared:error')}`,
                        i18n.t('customers/roles:uploadError'),
                        'error'
                    )
                )
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMER_ROLES_IMPORT_FILE_UPLOADING, uploading: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(customerRolesRequest),
        fork(saveCustomerRole),
        fork(importCustomerRoles)
    ])
}
