import styled from 'styled-components'

const TicketInfoWrapper = styled.div`
  .ticket-detail {
    width: 100%;
    position: relative;
    left: -4px;

    .detail-row {
      display: flex;

      .detail-label {
        color: rgba(0, 0, 0, 0.5);

        &:after { content: ':'; }
      }

      .detail-label,
      .detail-value {
        padding: 0 4px;
      }
    }

    .customer-section {
      display: flex;
      .phone-number {
        margin-left: 8px;
      }
    }
  }

  .order-numbers:not(:last-child) {
    &:after { content: ', '; }
  }

  .ticket-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    white-space: pre;
  }

  .flex-box { display: flex; }
  .width-50 { width: 50%; }
`

export default TicketInfoWrapper
