import styled from 'styled-components'

export const StyledTable = styled.table`
  // custom css goes here
`

export const THead = styled.thead`
  th {
    padding: 16px;
    text-align: left;
    background-color: #fafafa;
    border-bottom: 1px solid #f0f0f0;
  }
`

export const TBody = styled.tbody`
  tr {
    &:last-child { border: none; }
  }
`

export const TFoot = styled.tfoot`
  // custom css goes here
`

export const TR = styled.tr`
  &.border-bottom {
    border-bottom: 1px solid #e1e1e1;
  }

  &.vertical-align-top {
    vertical-align: top;
  }

  &.potential {
    background-color: #fffbe6;
  }

  &.fail {
    background-color: #fff1f0;
  }

  &.success {
    background-color: #e6fffb;
  }
`

export const TH = styled.th`
  // custom css goes here
`

export const TD = styled.td`
  padding: 16px;

  .detail-row {
    display: flex;

    .detail-label {
      color: #8a8a8a;
      margin-right: 4px;
      white-space: nowrap;

      &:after {
        content: ':';
      }
    }
  }

  &.column-long-text { min-width: 120px; }
`

const StyleWrapper = styled.div`
  .load-more-actions {
    border-top: 1px solid #e1e1e1;
    padding-top: 16px;
    text-align: center;
  }

  .table-responsive { overflow-x: auto; }
  .width-100 { width: 100%; }
  .padding-bottom-0 { padding-bottom: 0; }
`

export default StyleWrapper
