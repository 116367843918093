import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const TICKET_SOURCES_FOR_FILTER_QUERY = gql`
    query TicketSourcesForFilter {
        ticketSourcesForFilter {
            label
            value
        }
    }
`

const renderOptions = (options = []) => options.map(({ label, value }) => {
    return <Select.Option value={value}>{label}</Select.Option>
})

const TicketSourcesForFilterSelector = ({ ...restProps }) => {
    const { loading, data } = useQuery(TICKET_SOURCES_FOR_FILTER_QUERY)

    return (
        <Select loading={loading} {...restProps}>
            {renderOptions(data && data.ticketSourcesForFilter)}
        </Select>
    )
}

TicketSourcesForFilterSelector.propTypes = {}

TicketSourcesForFilterSelector.defaultProps = {}

export default TicketSourcesForFilterSelector
