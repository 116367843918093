import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Button, Col, Divider, Input, Row, Table } from 'antd'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

const StaffIndex = ({ loading, onPageChange, onSearch, pagination, staffs }) => {
    const { i18n } = useTranslation('staffs')

    const columns = [{
        title: i18n.t('staffs:no'),
        width: isMobile ? '70px' : '100px',
        render: (value, record, index) => index + 1
    }, {
        title: `${i18n.t('staffs:form.firstName')} - ${i18n.t('staffs:form.lastName')}`,
        render: (record) => `${record.first_name} ${record.last_name || ''}`
    }, {
        key: 'action',
        render: (record) => (
            <div>
                <Link to={`/staffs/${record.id}`}><EyeOutlined /></Link>
                <Divider type="vertical" />
                <Link to={`/staffs/${record.id}/edit`}><EditOutlined /></Link>
            </div>
        )
    }]

    return (
        <div>
            <Row>
                <Col md={{ span: 12 }}>
                    <h3>{i18n.t('staffs:staffInfo')}</h3>
                </Col>
                <Col md={{ span: 7, offset: 5 }} style={{ textAlign: 'right' }}>
                    <Link to='/staffs/new'>
                        <Button size="large" type="primary">{i18n.t('staffs:form.addStaff')}</Button>
                    </Link>
                </Col>
            </Row>

            <Input.Search
                enterButton="Search"
                onSearch={value => onSearch({ query: value })}
                placeholder="input search text"
                style={{ marginTop: '16px' }} />

            <Divider />

            <Table
                columns={columns}
                dataSource={staffs}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id" />
        </div>
    )
}

StaffIndex.propTypes = {
    loading: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    staffs: PropTypes.array.isRequired
}

export default StaffIndex
