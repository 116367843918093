import styled from 'styled-components'
const ImagesWrapper = styled.div`
  .images-container {
    padding: 10px
  }
  
  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
  }
  
  .image {
    padding-top: 15px
  }
  
  .pagination {
    float: right;
    padding: 30px;
  }
`
export default ImagesWrapper
