import styled from 'styled-components'

const ActivityLogsTabWrapper = styled.div`
  .activity-log-order-container, 
  .activity-log-line-items-container, 
  h3 {
    margin: 15px
  }
`

export default ActivityLogsTabWrapper