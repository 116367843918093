import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'

import { Button, Modal, Space } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import TicketForm from '../../ticketForm'
import TicketInfoWrapper from '../ticketInfo.style'
import LeadGenerationContainer from '../../../../containers/LeadGeneration/LeadGenerationContainer'

const CREATE_TICKET_ORDER_MUTATION = gql`
    mutation CreateTicketOrder($ticketId: ID!, $orderId: String!){
        createTicketOrder(ticketId: $ticketId, orderId: $orderId){
            ticket {
                id
            }
            errors
        }
    }
`


const renderOrders = (orders) => {
    return orders.map((order) => {
        return (
            <span className='order-numbers'>
                <Link to={`/inquiries/${order.number}`}>
                        {order.number}
                </Link>
            </span>
        )
    })
}

const TicketEditInfo = ({ refetch, ticket }) => {
    const { i18n } = useTranslation(['tickets', 'addresses'])
    const [showOrderForm, setShowOrderForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const { category, customer, district, orders, province, sourceLabel, ticketTypeLabel } = ticket
    const { customerNo } = customer || {}

    const [createTicketOrder, { loading: saving }] = useMutation(CREATE_TICKET_ORDER_MUTATION, {
        onCompleted: () => refetch()
    })

    const onCreateOrderSuccess = ({ orderId }) => {
        toggleOrderForm()
        createTicketOrder({ variables: { ticketId: ticket.id, orderId } })
    }

    const toggleEditFormModal = () => {
        setShowEditForm(!showEditForm)
    }

    const toggleOrderForm = () => {
        setShowOrderForm(!showOrderForm)
    }

    return (
        <TicketInfoWrapper>
            <div className="ticket-info">
                <strong>{i18n.t('tickets:info')}</strong>

                <Space>
                    <Button loading={saving} icon={<PlusOutlined />} onClick={toggleOrderForm} size="small" type="primary" ghost>
                        {i18n.t('order.createNewOrder')}
                    </Button>
                    <Button icon={<EditOutlined />} onClick={toggleEditFormModal} size="small" type="primary" ghost>
                        {i18n.t('shared:edit')}
                    </Button>
                </Space>
            </div>

            <div className="ticket-detail">
                <div className="detail-row">
                    <div className="detail-label">{i18n.t('tickets:category')}</div>
                    <div className="detail-value">{category && category.name}</div>
                </div>

                <div className="flex-box">
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('tickets:ticketType')}</div>
                        <div className="detail-value">{ticketTypeLabel}</div>
                    </div>
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('tickets:source')}</div>
                        <div className="detail-value">{sourceLabel}</div>
                    </div>
                </div>

                <div className="flex-box">
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('tickets:categoryType')}</div>
                        <div className="detail-value">{ticket.categoryTypeLabel || '-'}</div>
                    </div>
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('tickets:subCategoryType')}</div>
                        <div className="detail-value">{ticket.subCategoryTypeLabel || '-'}</div>
                    </div>
                </div>

                <div className="detail-row">
                    <div className="detail-label">{i18n.t('tickets:orders')}</div>
                    <div className="detail-value">{renderOrders(orders)}</div>
                </div>

                <div className="flex-box">
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('addresses:province')}</div>
                        <div className="detail-value">{province && province.name}</div>
                    </div>
                    <div className="detail-row width-50">
                        <div className="detail-label">{i18n.t('addresses:district')}</div>
                        <div className="detail-value">{district && district.name}</div>
                    </div>
                </div>
            </div>

            <Modal
                footer={null}
                onCancel={toggleEditFormModal}
                visible={showEditForm}
                width="50%"
            >
                <TicketForm
                    customerId={customerNo}
                    onClose={toggleEditFormModal}
                    onSuccess={refetch}
                    ticket={ticket} />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={toggleOrderForm}
                visible={showOrderForm}
                width="65%">
                <LeadGenerationContainer
                    customerId={customerNo}
                    redirectToInquiryPage={false}
                    onSuccess={onCreateOrderSuccess}
                    ticketDescription={ticket.description} />
            </Modal>
        </TicketInfoWrapper>
    )
}

TicketEditInfo.propTypes = {
    refetch: PropTypes.func.isRequired,
    ticket: PropTypes.object.isRequired
}

TicketEditInfo.defaultProps = {}

export default TicketEditInfo
