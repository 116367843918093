import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Spin } from 'antd'
import ListOptionsRender from './ListOptionsRender'

const CUSTOMER_QUERY = gql`
  query Customer($customerNo: String!, $perPage: Int) {
    customer(customerNo: $customerNo) {
      id
      shippingAddresses(perPage: $perPage) {
        id
        fullAddress
        contactName
        contactNumber
      }
    }
  }
`

const ShippingAddressListOptions = ({
  customerId,
  onChange,
  defaultShippingAddressId,
  refetchShippingAddress,
  setRefetchShippingAddress
}) => {
  const { loading, data, refetch } = useQuery(CUSTOMER_QUERY, {
    variables: { customerNo: customerId, perPage: 50 }
  })

  useEffect(() => {
    if (refetchShippingAddress) {
      refetch()
    }
  }, [refetchShippingAddress])

  if (loading) return <Spin />

  const { customer } = data
  const shippingAddresses = customer.shippingAddresses || []

  const handleChange = (value) => {
    onChange(value)
  }

  return (
    <ListOptionsRender shippingAddresses={shippingAddresses} onChange={handleChange}
      customerId={customerId}
      shippingAddressId={defaultShippingAddressId}
      setRefetchShippingAddress={setRefetchShippingAddress} />
  )
}

ShippingAddressListOptions.propTypes = {
  customerId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultShippingAddressId: PropTypes.string,
  setRefetchShippingAddress: PropTypes.func
}

ShippingAddressListOptions.defaultProps = {
  onChange: () => {},
  setRefetchShippingAddress: () => {}
}

export default ShippingAddressListOptions
