import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Button, Form, Input } from 'antd'

const SupplierInfoForm = ({
    onSubmitForm,
    saving,
    supplier
}) => {
    const { i18n } = useTranslation('suppliers')

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    return (
        <Form onFinish={handleSubmitForm} layout='vertical'>
            <Form.Item label={i18n.t('suppliers:keyContact')}
                name='key_contact'
                initialValue={supplier.get('key_contact')}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:salesContact')}
                name='sales_contact'
                initialValue={supplier.get('sales_contact')}
                >
                <Input.TextArea />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:accountContact')}
                name='account_contact'
                initialValue={supplier.get('account_contact')}>
                    <Input.TextArea />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:startRating')}
                name='start_rating'
                initialValue={supplier.get('start_rating')}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:other')}
                name='other'
                initialValue={supplier.get('other')}
                >
                <Input.TextArea />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                <Button style={{ marginLeft: '8px' }}>{i18n.t('shared:close')}</Button>
            </Form.Item>
        </Form>
    )
}

SupplierInfoForm.propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    supplier: ImmutablePropTypes.map.isRequired
}

SupplierInfoForm.defaultProps = {
    saving: false
}

export default SupplierInfoForm
