import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import actions from '../../redux/customers/wallets/actions'
import notificationActions from '../../redux/notifications/actions'
import customerActions from '../../redux/customers/actions'
import CustomerWalletForm from '../../components/customerWallets/CustomerWalletForm'
import { Spin } from 'antd'

const {
    saveCustomerWallet,
    setCustomerWalletFormModal,
    fetchCustomerWallet,
    resetCustomerWalletFormErrors,
    setShowAmountInput,
    setShowBankDetailInput
} = actions
const { setNotifications } = notificationActions
const { fetchCustomerOrders } = customerActions

class CustomerWalletFormContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,

        customerWalletEntities: ImmutablePropTypes.map.isRequired,
        customerWallets: ImmutablePropTypes.map.isRequired,
        onSuccess: PropTypes.func,
        resetCustomerWalletFormErrors: PropTypes.func.isRequired,
        saveCustomerWallet: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        setShowAmountInput: PropTypes.func
    }

    static defaultProps = {
        onSuccess: () => {}
    }

    async componentDidMount () {
        const {
            fetchCustomerOrders,
            customerId,
            customerWalletId,
            fetchCustomerWallet
        } = this.props
        fetchCustomerOrders(customerId)
        if (customerWalletId) {
            await fetchCustomerWallet(customerId, customerWalletId)
        }
    }

    componentWillUnmount () {
        this.props.resetCustomerWalletFormErrors()
    }

    getOrders () {
        const { customers } = this.props

        return customers.get('orders')
    }

    handleNoticeError = (error) => {
        const { setNotifications } = this.props
        const { name, errorMessage } = error.name

        setNotifications(`${name}`, `${errorMessage}`, 'error')
    }

    onSubmitCustomerWallet = ({ values }) => {
        const { customerId, saveCustomerWallet, setNotifications, onSuccess, customerWalletId } = this.props
        const date = values['date'] ? values['date'].format('YYYY-MM-DD') : null
        if (values['supplier_order_attributes']) {
            Object.assign(values['supplier_order_attributes'], {
                payment_type: 'fully_paid',
                transfer_date: date,
                grand_total: values['amount'],
                note: values['note'],
                supplier_order_type: 'transfer_back'
            })
        }

        saveCustomerWallet({
            customerId,
            id: customerWalletId,
            params: { ...values, date },
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
                onSuccess()
            },
            onError: (error) => this.handleNoticeError(error)
        })
    }

    render () {
        const { customerWalletEntities, customerWalletId, isEdit, customerWallets, setShowAmountInput, setShowBankDetailInput } = this.props
        const customerWallet = customerWalletEntities.get(customerWalletId) || new Map()
        const errors = customerWallets.get('errors')

        if (customerWallets.get('loading')) return <Spin />
        return (
            <CustomerWalletForm isEdit={isEdit}
                onSubmitCustomerWallet={this.onSubmitCustomerWallet}
                customerWallet={customerWallet}
                setShowAmountInput={setShowAmountInput}
                setShowBankDetailInput={setShowBankDetailInput}
                showAmountInput={customerWallets.get('showAmountInput')}
                showBankDetailInput={customerWallets.get('showBankDetailInput')}
                formErrors={typeof errors === 'object' ? errors : new Map()}
                orders={this.getOrders()} />
        )
    }
}

const mapStateToProps = (state) => ({
    customerWalletEntities: state.getIn(['Entities', 'customerWallets']),
    customerWallets: state.get('customerWallets'),
    customers: state.get('customers')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchCustomerOrders,
        fetchCustomerWallet,
        resetCustomerWalletFormErrors,
        saveCustomerWallet,
        setCustomerWalletFormModal,
        setNotifications,
        setShowAmountInput,
        setShowBankDetailInput
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerWalletFormContainer)
