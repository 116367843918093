import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

import {
    Input,
    Divider,
    Select,
    DatePicker,
    Button,
    Spin,
    Layout,
    Checkbox,
    Modal,
    Upload,
    Popconfirm,
    Dropdown,
    Menu
} from 'antd'

import { UploadOutlined } from '@ant-design/icons'

import SelectOrderStateContainer from '../SelectOrderState'

import orderTableActions from '../../redux/supplierOrders/actions'
import supplierActions from '../../redux/suppliers/actions'
import supplierOrderActions from '../../redux/supplierOrders/actions'
import supplierAccountingGroupActions from '../../redux/suppliers/accountingGroups/actions'

const {
    exportSupplierOrderPaymentPDF
} = orderTableActions

const { searchSupplier } = supplierActions
const {
	exportSupplierOrders,
	setSupplierOrderImportModal,
	importSupplierOrders,
	exportPvTotalAmountMarginType,
	exportSupplierOrderPaymentVouchers
} = supplierOrderActions
const { fetchSupplierAccountingGroups } = supplierAccountingGroupActions

const { Sider, Content } = Layout

const SupplierOrderFilters = ({
	filters,
	setFilters,
	setLocationUrl,
	supplierOrdersData,
	selectedRows
}) => {
	const { i18n } = useTranslation(['suppliers', 'orders/tables'])
	const [supplierOrderPaymentDate, setSupplierOrderPaymentDate] = useState('')
	const [fileList, setFileList] = useState([])

	const dispatch = useDispatch()
    const suppliers = useSelector(state => state.get('suppliers'))
	const supplierOrders = useSelector(state => state.get('supplierOrders'))
	const supplierAccountingGroups = useSelector(state => state.get('supplierAccountingGroups'))
	const supplierEntities = useSelector(state => state.getIn(['Entities', 'suppliers']))
	const supplierAccountingGroupEntities = useSelector(state => state.getIn(['Entities', 'supplierAccountingGroups']))
	const supplierStateEntities = useSelector(state => state.getIn(['Entities', 'supplierOrderStates']))


	const handleFilter = (params) => {
		const newFilters = { ...filters, ...params }
		setFilters(newFilters)

		setLocationUrl(getQueryString(newFilters))
	}

	const getQueryQueryObject = (query) => {
        if (!query) { return {} }

        return { key: 'query', value: query }
    }

	const getStateQueryObject = (state) => {
        if (!state) { return {} }

        return { key: 'order_state', value: state }
    }

	const getSupplierStateQueryObject = (state) => {
        if (!state) { return {} }

        return { key: 'state', value: state }
    }

	const getSupplierQueryObject = (supplier) => {
        if (!supplier) { return {} }

        return { key: 'supplier', value: supplier }
    }

	const getAccountingGroupObject = (accountingGroup) => {
        if (!accountingGroup) { return {} }

        return { key: 'accounting_group', value: accountingGroup }
    }

	const getExcludeAccountingGroupObject = (excludeAccountingGroup) => {
        if (!excludeAccountingGroup) { return {} }

        return { key: 'exclude_accounting_group', value: excludeAccountingGroup }
    }

	const getIsCreditQueryObject = (credit) => {
        if (!credit) { return {} }

        return { key: 'is_credit', value: credit }
    }

    const getIsAvailableQueryObject = (available) => {
        if (!available) { return {} }

        return { key: 'is_available', value: available }
    }

    const getIsVerifiedQueryObject = (verified) => {
        if (!verified) { return {} }

        return { key: 'is_verified', value: verified }
    }

    const getFilterBySlip = (bySlip) => {
        if (!bySlip) { return {} }

        return { key: 'by_slip', value: bySlip }
    }

	const getPeriodQueryArray = (period, dateType) => {
        if (period.length !== 2 || !period[0]) { return [] }

        return [
            { key: 'start_date', value: period[0] },
            { key: 'end_date', value: period[1] },
            { key: 'date_type', value: dateType }
        ]
    }

	const getQueryString = (args = {}) => {
        const supplier = ('supplier' in args) ? args.supplier : ''
        const accountingGroup = ('accounting_group' in args) ? args.accounting_group : ''
        const excludeAccountingGroup = ('exclude_accounting_group' in args) ? args.exclude_accounting_group : ''
        const dateType = ('date_type' in args) ? args.date_type : 'transfer_date'
        const startDate = ('start_date' in args) ? args.start_date : ''
        const endDate = ('end_date' in args) ? args.end_date : ''
        const state = ('order_state' in args) ? args.order_state : ''
        const supplierState = ('state' in args) ? args.state : ''
        const query = ('query' in args) ? args.query : ''
        const isCredit = ('is_credit' in args) ? args.is_credit : ''
        const isAvailable = ('is_available' in args) ? args.is_available : ''
        const isVerified = ('is_verified' in args) ? args.is_verified : ''
        const bySlip = ('by_slip' in args) ? args.by_slip : ''

        const queries = [].concat(
            getQueryQueryObject(query),
            getSupplierQueryObject(supplier),
            getAccountingGroupObject(accountingGroup),
            getExcludeAccountingGroupObject(excludeAccountingGroup),
            getPeriodQueryArray([startDate, endDate], dateType),
            getStateQueryObject(state),
            getSupplierStateQueryObject(supplierState),
            getIsCreditQueryObject(isCredit),
            getIsAvailableQueryObject(isAvailable),
            getIsVerifiedQueryObject(isVerified),
            getFilterBySlip(bySlip)
        )

        return queries.filter((query) => { return !_.isEmpty(query) }).map((query) => {
            if (typeof query.value === 'object') {
                return query.value.map((value) => {
                    return `${query.key}[]=${value}`
                }).join('&')
            }
            return `${query.key}=${query.value}`
        }).join('&')
    }

	const onSearchSupplier = (query) => {
		dispatch(searchSupplier({ query }))
    }

	const onSearchSupplierAccountingGroup = (query) => {
		dispatch(fetchSupplierAccountingGroups({ query }))
    }

	const handleExportSupplierOrder = ({ newFormat = false } = {}) => {
        const queryString = getQueryString({ ...filters })

		dispatch(exportSupplierOrders(queryString, newFormat))
    }

	const handleSupplierOrderImportModal = () => {
        const isShowSupplierOrderImportModal = supplierOrders.get('isShowSupplierOrderImportModal')
        dispatch(setSupplierOrderImportModal(!isShowSupplierOrderImportModal))
    }

	const handleSelectFile = (info) => {
        let newFileList = info.fileList
		newFileList.slice(-1)

        setFileList(newFileList)
    }

    const handleUploadFile = () => {
        const file = fileList[0].originFileObj
        dispatch(importSupplierOrders(file))

		setFileList([])
    }

	const handleExportPvTotalAmountMarginType = ({ newFormat = false } = {}) => {
        dispatch(exportPvTotalAmountMarginType({ newFormat }))
    }

	const handleExportPaymentVoucher = ({ newFormat = false } = {}) => {
        const paymentVoucherIds = selectedRows.map((supplierOrder) => {
            return _.get(supplierOrder, 'paymentVoucher.id')
        })

        dispatch(exportSupplierOrderPaymentVouchers(paymentVoucherIds, newFormat))
    }

    const handlePeriodChange = (date, dateString) => {
        handleFilter({
            date_type:  _.get(filters, 'date_type') || 'transfer_date',
            start_date: dateString[0],
            end_date: dateString[1]
        })
    }

	const activeAllSupplier = (state) => {
        if (state === 'all') {
            return 'supplier active'
        } else { return 'supplier' }
    }


    const checkActiveSupplierState = (state, stateValue) => {
        if (stateValue === state) {
            return 'supplier active'
        } else { return 'supplier' }
    }

	const handleSupplierStateChange = (state) => {
		handleFilter({
            state: (!state) ? '' : state
        })
    }

	const handleSortingSupplierState = (e, state) => {
        const oldActive = document.getElementsByClassName('supplier active')
        if (oldActive.length !== 0) {
            oldActive[0].classList.remove('active')
        }

        const btn = e.target
        btn.classList.add('active')
        handleSupplierStateChange(state)
    }

	const renderSupplier = () => {
        return suppliers.get('supplierItems').map((supplierId) => {
            const supplier = supplierEntities.get(supplierId.toString())

            return (
                <Select.Option key={supplier.get('id')} value={supplier.get('id')}>
                    {supplier.get('name')}
                </Select.Option>
            )
        })
    }

	const renderSupplierAccountingGroup = () => {
        return supplierAccountingGroups.get('items').map((id) => {
            const accountingGroup = supplierAccountingGroupEntities.get(id.toString())

            return (
                <Select.Option key={accountingGroup.get('id')} value={accountingGroup.get('id')}>
                    {accountingGroup.get('name')}
                </Select.Option>
            )
        })
    }

	const renderDateSupplierOrderPayment = (exportPDFLoading) => {
        return (
            <div style={{ margin: '8px 0' }}>
                <DatePicker
                    onChange={(date, dateString) => setSupplierOrderPaymentDate(dateString)} />
                <Button style={{ marginLeft: '10px' }}
                    onClick={() => dispatch(exportSupplierOrderPaymentPDF(supplierOrderPaymentDate))}
                    loading={exportPDFLoading} >
                    {i18n.t('suppliers:supplierOrder.exportPayment')}
                </Button>
            </div>
        )
    }

	const renderExportSupplierOrderFollowingFilter = (supplierOrders) => {
        const exportedPvs = supplierOrders.map((supplierOrder) => {
            if (_.get(supplierOrder, 'paymentVoucher.isExported', null)) {
                return supplierOrder.refCode
            }
            return null
        }).filter(Boolean)

        const menu = (
            <Menu>
                <Menu.Item key="new_format">
                    <Popconfirm title={i18n.t('suppliers:exportedAlreadyPv', { exportedPvs: exportedPvs.join(', ') })}
                        onConfirm={() => handleExportSupplierOrder({ newFormat: true })}>
                            {i18n.t('suppliers:exportNewFormat')}
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        )

        if (exportedPvs.length > 0) {
            return (
                <Popconfirm title={i18n.t('suppliers:exportedAlreadyPv', { exportedPvs: exportedPvs.join(', ') })}
                    onConfirm={handleExportSupplierOrder}>
                    <Dropdown.Button
                        overlay={menu}
                        style={{ marginLeft: '10px' }}>
                        {i18n.t('suppliers:exportSupplierOrderFollowingFilter')}
                    </Dropdown.Button>
                </Popconfirm>
            )
        }

        return (
            <Dropdown.Button
                overlay={menu}
                style={{ marginLeft: '10px' }}
                onClick={handleExportSupplierOrder}>
                {i18n.t('suppliers:exportSupplierOrderFollowingFilter')}
            </Dropdown.Button>
        )
    }

    const renderExportPaymentVoucherBtn = () => {
        const countSelectOrders = selectedRows.length

        const exportedPvs = selectedRows.map((supplierOrder) => {
            if (_.get(supplierOrder, 'paymentVoucher.isExported', null)) {
                return supplierOrder.refCode
            }
            return null
        }).filter(Boolean)

		const exportExcelPaymentLoading = supplierOrders.get('exportExcelPaymentLoading')
        const defaultExportBtn = countSelectOrders === 0 ? i18n.t('suppliers:selectExportPv') :
            `${countSelectOrders} ${i18n.t('suppliers:exportPv')}`

        const menu = (
            <Menu>
                <Menu.Item key="new_format">
                    <Popconfirm title={i18n.t('suppliers:exportedAlreadyPv', { exportedPvs: exportedPvs.join(', ') })}
                        onConfirm={() => handleExportPaymentVoucher({ newFormat: true })}>
                            {i18n.t('suppliers:exportNewFormat')}
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        )

        if (exportedPvs.length > 0) {
            return (
                <Popconfirm title={i18n.t('suppliers:exportedAlreadyPv', { exportedPvs: exportedPvs.join(', ') })}
                    onConfirm={handleExportPaymentVoucher}>
                    <Dropdown.Button className={'export-pv-btn'}
                        overlay={menu}
                        loading={exportExcelPaymentLoading}>
                        {defaultExportBtn}
                    </Dropdown.Button>
                </Popconfirm>
            )
        }

        return (
            <Dropdown.Button className={'export-pv-btn'}
                loading={exportExcelPaymentLoading}
                overlay={menu}
                onClick={handleExportPaymentVoucher}>
                {defaultExportBtn}
            </Dropdown.Button>
        )
    }

	const renderSupplierOrderImportForm = () => {
		const uploading = supplierOrders.get('uploading')
		const isShowSupplierOrderImportModal = supplierOrders.get('isShowSupplierOrderImportModal')

        return (
            <Modal
                style={{
                    height: '600px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                width="500px"
                visible={isShowSupplierOrderImportModal}
                onCancel={handleSupplierOrderImportModal}
                closable={false}
                footer={null}
            >
                <h2>{i18n.t('suppliers:uploadSupplierOrders')}</h2>
                <Divider />
                <Upload
                    onChange={handleSelectFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/vnd.ms-excel">
                    <Button>
                        {i18n.t('suppliers:uploadFile')}
                    </Button>
                </Upload>
                <Button
                    className="upload-submit-btn"
                    style={{ marginTop: '10px' }}
                    type="primary"
                    onClick={handleUploadFile}
                    disabled={fileList.length === 0 || uploading}>
                    <UploadOutlined />
                </Button>
                <Spin spinning={uploading} />
            </Modal>
        )
    }

	const renderExportPvTotalAmountMarginType = () => {
        const menu = (
            <Menu>
                <Menu.Item key="new_format">
                    <Popconfirm title={i18n.t('suppliers:exportByNgentongmaType')}
                        onConfirm={() => handleExportPvTotalAmountMarginType({ newFormat: true })}>
                            {i18n.t('suppliers:exportNewFormat')}
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        )

        return (
            <Popconfirm title={i18n.t('suppliers:exportByNgentongmaType')}
                onConfirm={handleExportPvTotalAmountMarginType}>
                <Dropdown.Button style={{ marginLeft: '10px' }}
                    overlay={menu}
                    loading={supplierOrders.get('pvTotalAmountTypeLoading')}>
                    {i18n.t('suppliers:exportByNgentongmaType')}
                </Dropdown.Button>
            </Popconfirm>
        )
    }

	const renderSupplierStates = (stateDefault) => {
		const supplierStateItems = supplierOrders.get('states')
        return supplierStateItems.map((id) => {
            const state = supplierStateEntities.get(id.toString())
            const stateBtn = state.get('label')
            const stateValue = state.get('name')
            const stateSelect = checkActiveSupplierState(stateDefault, stateValue)

            return (
                <Button style={{ marginLeft: '10px' }}
                    className={stateSelect}
                    onClick={(e) => handleSortingSupplierState(e, stateValue)}
                    key={stateValue}>
                    {stateBtn}
                </Button>
            )
        })
    }

	const supplierLoading = suppliers.get('loading')
	const exportPDFLoading = supplierOrders.get('supplierOrderPaymentExportPDFLoading')

	const isCreditDefault = _.get(filters, 'is_credit') === 'true'
	const isAvailableDefault = _.get(filters, 'is_available') === 'true'
	const isVerifiedDefault = _.get(filters, 'is_verified') === 'true'
	const isFilterBySlip = _.get(filters, 'by_slip') === 'true'
	const dateType = _.get(filters, 'date_type') || 'transfer_date'
    const defaultDate = _.get(filters, 'start_date') ? (
		[moment(_.get(filters, 'start_date')), moment(_.get(filters, 'end_date'))]
	) : null
	const today = moment(new Date()).format('YYYY-MM-DD')
	const previousDate = moment().subtract(5, 'd').format('YYYY-MM-DD')
	const stateDefault = _.get(filters, 'order_state') || 'all'
	const supplierStateDefault = _.get(filters, 'state')|| 'all'

	return (
		<div>
			<Input.Search enterButton
				defaultValue={_.get(filters, 'query') || undefined}
				onSearch={(query) => { handleFilter({ query })}}
				placeholder={i18n.t('orders/tables:searchPlaceholder')} />
			<Divider />
			<Layout>
                <Sider width="fit-content">
					<Layout>
						<Sider width="fit-content">
							{i18n.t('suppliers:supplierName')}
						</Sider>
						<Layout>
							<Content style={{ textAlign: 'left' }}>
								<Select allowClear showSearch
									style={{ width: isMobile ? '140px' : '240px' }}
									mode="multiple"
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									defaultValue={_.get(filters, 'supplier') || undefined}
									notFoundContent={supplierLoading ? <Spin size="small" /> : null}
									onSearch={(value) => onSearchSupplier(value)}
									onChange={(vaule) => handleFilter({ supplier: vaule})}
									placeholder={i18n.t('suppliers:selectSupplierName')}>
									{renderSupplier()}
								</Select>
							</Content>
						</Layout>
					</Layout>
					<Layout>
						<Sider width="fit-content">
							{i18n.t('suppliers:supplierAccountingGroup')}
						</Sider>
						<Layout>
							<Content style={{ textAlign: 'left' }}>
								<Select allowClear showSearch
									style={{ width: isMobile ? '140px' : '90%' }}
									mode="multiple"
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									defaultValue={_.get(filters, 'accounting_group') || undefined}
									notFoundContent={supplierLoading ? <Spin size="small" /> : null}
									onSearch={(value) => onSearchSupplierAccountingGroup(value)}
									onChange={(vaule) => handleFilter({ accounting_group: vaule})}
									placeholder={i18n.t('suppliers:supplierAccountingGroup')}>
									{renderSupplierAccountingGroup()}
								</Select>
							</Content>
						</Layout>
						<Sider width="fit-content">
							{i18n.t('suppliers:filterSupplierAccountingGroupOut')}
						</Sider>
						<Layout>
							<Content style={{ textAlign: 'left' }}>
								<Select allowClear showSearch
									style={{ width: isMobile ? '140px' : '90%' }}
									mode="multiple"
									defaultActiveFirstOption={false}
									showArrow={false}
									filterOption={false}
									defaultValue={_.get(filters, 'exclude_accounting_group') || undefined}
									notFoundContent={supplierLoading ? <Spin size="small" /> : null}
									onSearch={(value) => onSearchSupplierAccountingGroup(value)}
									onChange={(vaule) => handleFilter({ exclude_accounting_group: vaule})}
									placeholder={i18n.t('suppliers:filterSupplierAccountingGroupOut')}>
									{renderSupplierAccountingGroup()}
								</Select>
							</Content>
						</Layout>
					</Layout>
					<Checkbox
						onChange={(e) => handleFilter({ is_credit: e.target.checked ? 'true' : '' })}
						defaultChecked={isCreditDefault}>
						{i18n.t('suppliers:haveCredit')}
					</Checkbox>
					<Checkbox
						onChange={(e) => handleFilter({ is_available: e.target.checked ? 'true' : '' })}
						defaultChecked={isAvailableDefault}>
						{i18n.t('suppliers:quickTransfer')}
					</Checkbox>
					<Checkbox defaultChecked={isVerifiedDefault}
						onChange={(e) => handleFilter({ is_verified: e.target.checked ? 'true' : '' })}>
						{i18n.t('suppliers:supplierIsVerified')}
					</Checkbox>
					<Checkbox
						onChange={(e) => handleFilter({ by_slip: e.target.checked ? 'true' : '' })}
						defaultChecked={isFilterBySlip}>
						{i18n.t('suppliers:haveSlip')}
					</Checkbox>
					{renderDateSupplierOrderPayment(exportPDFLoading)}
					{renderExportPaymentVoucherBtn()}
					{renderExportSupplierOrderFollowingFilter(supplierOrdersData)}
					<Button
						style={{ marginLeft: '10px' }}
						onClick={() => handleSupplierOrderImportModal()}>
						{i18n.t('suppliers:uploadSupplierOrders')}
					</Button>
					{renderSupplierOrderImportForm()}
					{renderExportPvTotalAmountMarginType()}
				</Sider>
				<Content>
					<Content>
						<span>
							<Select style={{ width: 200 }}
								onChange={(dateType) => handleFilter({ date_type: dateType })}
								value={dateType}>
								<Select.Option value="transfer_date">
									{i18n.t('suppliers:searchByTransferDate')}
								</Select.Option>
								<Select.Option value="shipping_date">
									{i18n.t('suppliers:searchByShippingDate')}
								</Select.Option>
								<Select.Option value="date_of_purchase">
									{i18n.t('suppliers:searchByPurchaseDate')}
								</Select.Option>
							</Select>
						</span>
						<span>
							<DatePicker.RangePicker allowClear
								value={defaultDate}
								onChange={handlePeriodChange} />
						</span>
						<Button onClick={() => handlePeriodChange('', [previousDate, today])}>
							{i18n.t('suppliers:today')}
						</Button>
					</Content>
					<Content>
						<SelectOrderStateContainer
							value={stateDefault}
							onChange={(state) => handleFilter({ order_state: (!state) ? '' : state })}
							style={{ width: '200px' }}
						/>
					</Content>
					<Content>
						{supplierOrders.get('statesLoading') ? <Spin /> : (
							<div>
								<Button
									className={activeAllSupplier(supplierStateDefault)}
									onClick={(e) => handleSortingSupplierState(e)}>
									{i18n.t('suppliers:all')}
								</Button>
								{renderSupplierStates(supplierStateDefault)}
							</div>
						)}
					</Content>
				</Content>
			</Layout>
		</div>
	)
}

SupplierOrderFilters.propTypes = {
	filters: PropTypes.object,
	setLocationUrl: PropTypes.func,
	setFilters: PropTypes.func.isRequired,
	supplierOrdersData: PropTypes.array,
	selectedRows: PropTypes.array
}

SupplierOrderFilters.defaultProps = {
	filters: {},
	supplierOrdersData: [],
	setLocationUrl: () => {},
	selectedRows: []
}

export default SupplierOrderFilters
