import React from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { gql, useQuery, useMutation } from '@apollo/client'

import { Dropdown, Menu } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'

import notificationActions from '../../../redux/notifications/actions'

const { setNotifications } = notificationActions

const TICKET_STATES_QUERY = gql`
  query TicketStates {
    ticketStates {
      id
      name
      label
      nextStates
    }
  }
`

const UPDATE_TICKET_STATE_MUTATION = gql`
  mutation UpdateTicketState($state: String!, $id: ID!) {
    updateTicketState(state: $state, id: $id) {
      ticket {
        id
        state
        stateLabel
      }
    }
  }
`

const renderMenuStates = (currentStateName, ticketStates, onSelectState) => {
  const currentState = ticketStates.find((value) => value.name === currentStateName)

  if (!currentState) { return }

  return ticketStates.filter((value) => {
      return currentState.nextStates.indexOf(value.id) !== -1
  }).map((value, index) => {
      return (
          <Menu.Item key={`state-${index}`}>
              <a onClick={() => onSelectState(value.name)}>{value.label}</a>
          </Menu.Item>
      )
  })
}

const TicketStatesDropdown = ({ ticketId, state, stateLabel, refetchTicket }) => {
  const dispatch = useDispatch()

  const { loading, data } = useQuery(TICKET_STATES_QUERY)

  const [updateTicketState, { loading: saving }] = useMutation(UPDATE_TICKET_STATE_MUTATION, {
    onCompleted: ({ updateTicketState }) => {
      if (updateTicketState.errors) {
        dispatch(setNotifications('error', 'error', 'error'))
      } else {
        refetchTicket()
        dispatch(setNotifications('success', 'success', 'success'))
      }
    }
})

  const onSelectState = (state) => {
    updateTicketState({
      variables: { id: ticketId, state }
    })
  }

  if (loading) return null

  const ticketStates = data ? data.ticketStates : []

  return (
    <Dropdown key='ticket_state_dropdown'
      overlay={
        <Menu>{renderMenuStates(state, ticketStates, onSelectState)
        }</Menu>
      }>
        <a className="ant-dropdown-link">
          {stateLabel || state} { saving ? <LoadingOutlined /> : <DownOutlined /> }
        </a>
    </Dropdown>
  )
}

TicketStatesDropdown.propTypes = {
  ticketId: PropTypes.string.isRequired,
  state: PropTypes.string,
  stateLabel: PropTypes.string,
  refetchTicket: PropTypes.func
}

TicketStatesDropdown.defaultProps = {
  refetchTicket: () => {}
}

export default TicketStatesDropdown
