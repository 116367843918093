import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {Form, DatePicker, Button, Card, Select, Spin} from 'antd'

import accountingAction from '../../redux/orders/accounting/actions'
import {isMobile} from "react-device-detect";
import supplierActions from "../../redux/suppliers/actions";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
const { searchSupplier } = supplierActions

const { fetchPaymentVouchersFile } = accountingAction

const { RangePicker } = DatePicker

class ReceiptVoucherExport extends Component {
    constructor (props) {
        super(props)


        this.formRef = createRef()
    }

    handleSubmit = (e) => {
        const { fetchPaymentVouchersFile } = this.props
        e.preventDefault()

        this.formRef.current.validateFields().then(fieldsValue => {
            let startDate = null
            let endDate = null
            if (fieldsValue['range_picker']) {
                startDate = fieldsValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldsValue['range_picker'][1].format('YYYY-MM-DD')
            }
            const { supplierId } = fieldsValue
            const values = {
                start_date: startDate,
                end_date: endDate,
                supplier_id: supplierId,
            }
            fetchPaymentVouchersFile(values)
        })
    }

    renderCardHead = () => {
        const { i18n } = this.props

        return (
            <Card.Meta
                title={i18n.t('documents:paymentVoucherReport.download')}
                description={i18n.t('documents:paymentVoucherReport.downloadDescription')} />
        )
    }

    onSearchSupplier = (query) => {
        const { searchSupplier } = this.props

        searchSupplier({ query })
    }

    renderSupplier = () => {
        const { suppliers, supplierEntities } = this.props

        return suppliers.get('supplierItems').map((supplierId) => {
            const supplier = supplierEntities.get(supplierId.toString())

            return (
                <Select.Option key={supplier.get('id')} value={supplier.get('id')}>
                    {supplier.get('name')}
                </Select.Option>
            )
        })
    }

    render () {
        const { orderPaymentDetails, i18n } = this.props
        const loading = orderPaymentDetails.get('receiptVoucherCSVLoading')
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 }
        }

        return (
            <Card title={this.renderCardHead()}>
                <Form layout="inline"
                    ref={this.formRef}
                    labelAlign="left"
                    hideRequiredMark>
                    <Form.Item key="search_by_date" label={i18n.t('documents:searchByDate')} {...formItemLayout}
                        name='range_picker'
                        rules={[{
                            required: true,
                            message: i18n.t('documents:pleaseInputDate')
                        }]}>
                            <RangePicker />
                    </Form.Item>
                    <Form.Item key="supplier_select" label={i18n.t('documents:selectSupplier')} {...formItemLayout}
                        name='supplierId'>
                        <Select allowClear showSearch
                                style={{ width: isMobile ? '140px' : '240px' }}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                                onSearch={(value) => this.onSearchSupplier(value)}
                                placeholder={i18n.t('suppliers:selectSupplierName')}>
                            {this.renderSupplier()}
                        </Select>
                    </Form.Item>
                    <Form.Item key="action_btn" {...buttonItemLayout}>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('documents:paymentVoucherReport.downloadPV')}
                        </Button>
                    </Form.Item>

                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    orderPaymentDetails: state.get('orderPaymentDetails'),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPaymentVouchersFile,
        searchSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('documents')(ReceiptVoucherExport))
