import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { Select, Input, Form, Button, Row, Col } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import ShippingCostWrapper from './shippingCost.style'

import inquiryActions from '../../redux/inquiry/actions'
import orderActions from '../../redux/orders/actions'

const { fetchVehicleTypes, setShippingCostFormAmount, saveOrderForm } = inquiryActions
const { deleteShippingCost, fetchOrderShippingRate, setShippingRatePrice } = orderActions

const { Option } = Select

class ShippingCostForm extends Component {
    constructor (props) {
        super(props)

        this.formRef = createRef()
    }

    componentDidMount () {
        this.props.fetchVehicleTypes()
    }

    onAddShippingCost = (formAmount) => {
        const { setShippingCostFormAmount } = this.props
        const arrayShippingCost = formAmount.concat('0')

        setShippingCostFormAmount(arrayShippingCost)
    }


    getShippingCostAttribute = (value) => {
        const { inquiry } = this.props
        const amount = inquiry.get('shippingCostAmount') || new List()
        const shippingCostAttributes = value.shipping_costs_attributes
        if (!shippingCostAttributes) return null

        let attributes = []
        amount.map((id) => {
            const data = _.find(shippingCostAttributes, ['id', `${id}`])

            if (!data) {
                attributes.push ({
                    id: id,
                    _destroy: 1
                })
            }
        })

        value.shipping_costs_attributes = [...attributes, ...shippingCostAttributes]
    }

    onSelectVehicleType = (id, key) => {
        const { fetchOrderShippingRate, orderId, setShippingRatePrice } = this.props
        const fields = this.formRef.current.getFieldsValue()
        const { shipping_costs_attributes } = fields

        if (id) {
            fetchOrderShippingRate({
                orderId,
                vehicleTypeId: id,
                onSuccess: (price) => {
                    setShippingRatePrice(price)

                    Object.assign(shipping_costs_attributes[key], { cost: price })
                    this.formRef.current.setFieldsValue({ shipping_costs_attributes })
                }
            })
        } else {
            setShippingRatePrice(null)

            Object.assign(shipping_costs_attributes[key], { cost: null })
            this.formRef.current.setFieldsValue({ shipping_costs_attributes })
        }
    }

    onSubmitForm = (values, { onSuccess = () => {} } = {}) => {
        const { saveOrderForm, orderId } = this.props

        this.getShippingCostAttribute(values, 'values')

        saveOrderForm(orderId, values, { onSuccess })
    }

    shippingform = () => {
        const { vehicleTypeEntities, inquiry, i18n, errors } = this.props
        const vehicleTypes = inquiry.get('vehicleTypeItems').map((vehicleId) => {
            const vehicleType = vehicleTypeEntities.get(`${vehicleId}`)
            return (
                <Option key={vehicleType.get('id')} value={vehicleType.get('id')}>
                    {vehicleType.get('name')}
                </Option>
            )
        })

        const formItemLayout = {
            marginRight: '10px'
        }

        return (
            <Form.List name="shipping_costs_attributes">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div key={key}>
                            <Row type="flex">
                                <Form.Item noStyle
                                    name={[name, 'id']}
                                    initialValue=''
                                    fieldKey={[fieldKey, 'id']}>
                                        <input type="hidden" />
                                </Form.Item>
                                <Form.Item name={[name, 'shipping_type']}
                                    initialValue='added_by_sale'
                                    fieldKey={[fieldKey, 'shipping_type']}>
                                        <input type="hidden" />
                                </Form.Item>
                                <Col span={22}>
                                    <Form.Item label={i18n.t('inquiries:shippingCost.vehicleType')} style={formItemLayout}
                                        name={[name, 'vehicle_type_id']}
                                        fieldKey={[fieldKey, 'vehicle_type_id']}>
                                            <Select
                                                placeholder={i18n.t('inquiries:shippingCost.vehicleType')}
                                                allowClear
                                                showSearch
                                                onChange={(value) => this.onSelectVehicleType(value, key)}
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {vehicleTypes}
                                            </Select>
                                    </Form.Item>
                                </Col>
                                <CloseCircleOutlined
                                    onClick={() => {
                                        remove(name)
                                    }
                                } style={{ marginTop: '14px' }} />
                            </Row>
                            <Row type="flex">
                                <Col span={9}>
                                    <Form.Item label={i18n.t('inquiries:shippingCost.price')} style={formItemLayout}
                                        name={[name, 'single_price']}
                                        fieldKey={[fieldKey, 'single_price']}
                                        validateStatus={errors && errors.get('shipping_costs_attributes') && 'error'}
                                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput')}]}>
                                            <Input placeholder={i18n.t('inquiries:shippingCost.price')} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label={i18n.t('inquiries:shippingCost.numberOfTrips')} style={formItemLayout}
                                        name={[name, 'quantity']}
                                        fieldKey={[fieldKey, 'quantity']}
                                        validateStatus={errors && errors.get('shipping_costs_attributes') && 'error'}
                                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput')}]}
                                        validateStatus={errors && errors.get('shipping_costs_attributes') && 'error'}>
                                            <Input placeholder={i18n.t('inquiries:shippingCost.numberOfTrips')} />
                                    </Form.Item>
                                </Col>
                                <Col span={9}>
                                    <Form.Item label={i18n.t('inquiries:shippingCost.cost')} style={formItemLayout}
                                        name={[name, 'cost']}
                                        fieldKey={[fieldKey, 'cost']}
                                        validateStatus={errors && errors.get('shipping_costs_attributes') && 'error'}
                                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput')}]}
                                        validateStatus={errors && errors.get('shipping_costs_attributes') && 'error'}>
                                            <Input placeholder={i18n.t('inquiries:shippingCost.cost')} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Form.Item>
                        <Button onClick={() => add()} size="small" type="primary" ghost>
                            {i18n.t('inquiries:add')}
                        </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        )
    }

    getDefaultShippingCosts = () => {
        const { inquiry, shippingCostEntities, orders } = this.props

        const amount = inquiry.get('shippingCostAmount') || new List()
        if (amount.size > 0) {
            return amount.map((id, index) => {
                const vehicleType = shippingCostEntities.getIn([id, 'vehicle_type']) || new Map()
                const moneyDisplay = shippingCostEntities.getIn([id, 'money_display']) || new Map()
                const quantity = shippingCostEntities.getIn([id, 'quantity'])
                const defaultId = id !== '0' ? id : ''

                return {
                    id: defaultId,
                    vehicle_type_id: vehicleType.get('id'),
                    single_price: moneyDisplay.get('single_price'),
                    quantity: quantity,
                    cost: moneyDisplay.get('cost') || orders.get('shippingRatePrice'),
                }
            })
        } else {
            return new List([undefined])
        }
    }

    shippingCostForm () {
        const { inquiry, handleEditShippingCost, showShippingCostForm, i18n } = this.props

        return (
            <div>
                {this.shippingform()}
                <Row className="btn-center">
                    <Button style={{ width: 60 }}
                        className="btn-edit"
                        type="primary"
                        size="small"
                        htmlType="submit"
                        loading={inquiry.get('saveOrderLoading')}>
                        {i18n.t('inquiries:edit')}
                    </Button>
                    <Button style={{ width: 60 }}
                        className="btn-edit"
                        size="small"
                        onClick={() => handleEditShippingCost(showShippingCostForm)}>{i18n.t('inquiries:cancel')}</Button>
                </Row>
            </div>
        )
    }

    render () {
        const { i18n, handleEditShippingCost, showShippingCostForm } = this.props
        return (
            <ShippingCostWrapper>
                <Form onFinish={(values) => this.onSubmitForm(values, { onSuccess: () => handleEditShippingCost(showShippingCostForm) })}
                    ref={this.formRef}
                    layout='vertical'
                    initialValues={{ shipping_costs_attributes: this.getDefaultShippingCosts().toJS()}}>
                    <span className="title margin-text">{i18n.t('inquiries:shippingCosts')}</span>
                    {this.shippingCostForm()}
                </Form>
            </ShippingCostWrapper>
        )
    }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    orders: state.get('orders'),
    shippingCostEntities: state.getIn(['Entities', 'shippingCosts']),
    vehicleTypeEntities: state.getIn(['Entities', 'vehicleTypes'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteShippingCost,
        fetchVehicleTypes,
        setShippingCostFormAmount,
        saveOrderForm,
        fetchOrderShippingRate,
        setShippingRatePrice
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(ShippingCostForm))
