const actions = {
    SHIPPING_ADDRESSES_FETCH: 'SHIPPING_ADDRESSES_FETCH',
    SHIPPING_ADDRESSES_FETCH_REQUEST: 'SHIPPING_ADDRESSES_FETCH_REQUEST',
    SHIPPING_ADDRESSES_FETCH_SUCCESS: 'SHIPPING_ADDRESSES_FETCH_SUCCESS',
    SHIPPING_ADDRESSES_SAVE_SUCCESS: 'SHIPPING_ADDRESSES_SAVE_SUCCESS',
    SHIPPING_ADDRESSES_SAVE_REQUEST: 'SHIPPING_ADDRESSES_SAVE_REQUEST',
    SHIPPING_ADDRESSES_SET_PAGINATION: 'SHIPPING_ADDRESSES_SET_PAGINATION',
    SHIPPING_ADDRESSES_SET_SAVE: 'SHIPPING_ADDRESSES_SET_SAVE',
    SHIPPING_ADDRESSES_SET_FORM_ERRORS: 'SHIPPING_ADDRESSES_SET_FORM_ERRORS',
    SHIPPING_ADDRESSES_DELETE_REQUEST: 'SHIPPING_ADDRESSES_DELETE_REQUEST',
    SHIPPING_ADDRESSES_DELETE_SUCCESS: 'SHIPPING_ADDRESSES_DELETE_SUCCESS',
    SHIPPING_ADDRESS_FETCH_REQUEST: 'SHIPPING_ADDRESS_FETCH_REQUEST',
    SHIPPING_ADDRESS_FETCH: 'SHIPPING_ADDRESS_FETCH',
    SHIPPING_ADDRESS_FETCH_SUCCESS: 'SHIPPING_ADDRESS_FETCH_SUCCESS',

    fetchShippingAddresses: (customerPath, page = 1) => ({
        type: actions.SHIPPING_ADDRESSES_FETCH_REQUEST,
        payload: { customerPath, page }
    }),

    fetchShippingAddress: (customerId, id) => ({
        type: actions.SHIPPING_ADDRESS_FETCH_REQUEST,
        payload: { customerId, id }
    }),

    saveShippingAddress: (customerId, shippingAddressId, shippingAddress, onSuccess = () => {}) => ({
        type: actions.SHIPPING_ADDRESSES_SAVE_REQUEST,
        payload: { customerId, shippingAddressId, shippingAddress, onSuccess }
    }),

    deleteShippingAddress: (customerId, shippingAddressId) => ({
        type: actions.SHIPPING_ADDRESSES_DELETE_REQUEST,
        payload: { customerId, shippingAddressId }
    })
}

export default actions
