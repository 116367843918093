const actions = {
    TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL: 'TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL',
    TRUCK_ORIGIN_ADDRESSES_FETCH_REQUEST: 'TRUCK_ORIGIN_ADDRESSES_FETCH_REQUEST',
    TRUCK_ORIGIN_ADDRESSES_FETCHING: 'TRUCK_ORIGIN_ADDRESSES_FETCHING',
    TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS: 'TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS',
    TRUCK_ORIGIN_ADDRESS_FETCH_FAIL: 'TRUCK_ORIGIN_ADDRESS_FETCH_FAIL',
    TRUCK_ORIGIN_ADDRESS_FETCH_REQUEST: 'TRUCK_ORIGIN_ADDRESS_FETCH_REQUEST',
    TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS: 'TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS',
    TRUCK_ORIGIN_ADDRESS_FETCH: 'TRUCK_ORIGIN_ADDRESS_FETCH',
    INIT_TRUCK_ORIGIN_ADDRESSES: 'INIT_TRUCK_ORIGIN_ADDRESSES',

    fetchTruckOriginAddresses: ({ query = '', page = 1, per = 20, onSuccess = () => {}, onError = () => {} } = {}) => ({
        type: actions.TRUCK_ORIGIN_ADDRESSES_FETCH_REQUEST,
        payload: { query, page, per, onSuccess, onError }
    }),

    initTruckOriginAddresses: ({ ids }) => ({
        type: actions.INIT_TRUCK_ORIGIN_ADDRESSES,
        ids
    }),

    fetchTruckOriginAddress: ({ id, onSuccess = () => {}, onError = () => {} } = {}) => ({
        type: actions.TRUCK_ORIGIN_ADDRESS_FETCH_REQUEST,
        payload: { id, onSuccess, onError }
    })
}

export default actions
