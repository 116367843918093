import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Link } from 'react-router-dom'
import { Button, Divider, Space, Tag } from 'antd'
import { DownOutlined, EditOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons'
import { Table } from './component'
import SalePipelineStatusDropdown from '../SalePipelineStatusDropdown'

import lineLogoImage from '../../../../public/images/base/line-logo.png'

import { STATUS_TAG_PROPS } from '../../../constants/salePipelines'

const { TR, TD } = Table

const renderArchivedBtn = (item, saving, onSaveSalePipeline) => {
    const { id, archived, order } = item
    const params = { archived: !archived }

    return (
        <Button size='small' onClick={() => onSaveSalePipeline(id, params, order.number)}>
            {saving ? <LoadingOutlined /> : <InboxOutlined /> }
            {archived ? <span>Send To Inbox</span> : <span>Archived</span> }
        </Button>
    )
}

const renderCustomer = (customer = {}) => {
    return (
        <Fragment>
            <div>
                <Link to={`/customers/${customer.customerNo}`}>{customer.fullName}</Link>
                {customer.lineUrl && (
                    <a href={customer.lineUrl} target='_blank' rel='noreferrer'
                        style={{ marginLeft: '6px' }}>
                            <img src={lineLogoImage} height={16} alt='line-logo' />
                    </a>
                )}
            </div>
            <div>{customer.phoneNumber}</div>
        </Fragment>
    )
}

const renderCustomerType = (customer, toggleForm) => {
    const { customerType } = customer || {}

    return (
        <Space wrap>
            <span>
                {customerType ? customerType.name : '-'}
            </span>
            <a href='#!' onClick={e => toggleForm(e, { customerId: customer.customerNo })}>
                <EditOutlined />
            </a>
        </Space>
    )
}

const renderOrder = (i18n, record) => {
    const { number } = record.order

    return (
        <Space direction="vertical">
            <Link to={`/inquiries/${number}`} target="_blank">
                {number}
            </Link>
            {record.isPotential && <Tag color="gold">{i18n.t('salePipelines:isPotential')}</Tag>}
            {record.archived && <Tag>{i18n.t('salePipelines:archived')}</Tag>}
        </Space>
    )
}

const renderStatus = (item, refetch) => {
    const { status, statusLabel, order } = item

    return (
        <SalePipelineStatusDropdown
            orderId={order.number}
            salePipelineId={item.id}
            onSuccess={refetch}
            content={(
                <Tag {...STATUS_TAG_PROPS[status]}>
                    {statusLabel} <DownOutlined style={{ fontSize: '8px' }}/>
                </Tag>
            )}
        />
    )
}

const SalePipelineRow = ({
    record,
    onSaveSalePipeline,
    onToggleCustomerTypeEditForm,
    onToggleEditForm,
    refetch,
    saving
}) => {
    const { i18n } = useTranslation('salePipelines')
    const { order } = record
    const { customer, grandTotal, margin } = order
    const trClassNames = {
        potential: record.isPotential,
        fail: record.status === 'fail',
        success: record.status === 'success'
    }

    const onClickEdit = event => {
        event.preventDefault()

        onToggleEditForm({ id: record.id, orderId: order.number })
    }

    return (
        <React.Fragment>
            <TR className={classNames('vertical-align-top', 'border-bottom', trClassNames)}>
                <TD>{renderOrder(i18n, record)}</TD>
                <TD>{grandTotal ? grandTotal.display : '-'}</TD>
                <TD>{order.marginPercent || '-'}</TD>
                <TD>{margin.display === '0.00' ? '-' : margin.display}</TD>
                <TD>{renderCustomer(customer)}</TD>
                <TD>{renderCustomerType(customer, onToggleCustomerTypeEditForm)}</TD>
                <TD>{record.customerTypeLabel}</TD>
                <TD className="column-long-text">{record.customerPersonality}</TD>
                <TD className="column-long-text">{order.note}</TD>
                <TD className="column-long-text">{record.interestingProduct}</TD>
                <TD>{record.staff.fullName}</TD>
                <TD>{renderStatus(record, refetch)}</TD>
                <TD>{`${record.month || '-'} / ${record.year || '-'}`}</TD>
                <TD>{record.createdAt.display}</TD>
                <TD>
                    <Space split={<Divider type="vertical" />}>
                        <a href="#!" onClick={onClickEdit}>{i18n.t('shared:edit')}</a>
                        {renderArchivedBtn(record, saving, onSaveSalePipeline)}
                    </Space>
                </TD>
            </TR>
        </React.Fragment>
    )
}

SalePipelineRow.propTypes = {
    record: PropTypes.object.isRequired,
    onSaveSalePipeline: PropTypes.func.isRequired,
    onToggleCustomerTypeEditForm: PropTypes.func,
    onToggleEditForm: PropTypes.func,
    refetch: PropTypes.func.isRequired,
    saving: PropTypes.bool
}

SalePipelineRow.defaultProps = {
    onToggleCustomerTypeEditForm: () => {},
    onToggleEditForm: () => {}
}

export default SalePipelineRow
