import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Modal, Typography } from 'antd'
import { SendOutlined } from '@ant-design/icons'

import DelivereeForm from '../../deliveree/form'


const SendDeliveree = ({ orderId, refetch }) => {
    const { i18n } = useTranslation('inquiries')
    const [showForm, setShowForm] = useState(false)

    const toggleForm = () => {
        setShowForm(!showForm)
    }

    return (
        <div>
            <Typography.Link onClick={toggleForm}>
                <SendOutlined /> {i18n.t('inquiries:deliveree.sendDeliveree')}
            </Typography.Link>
            <Modal
                title={i18n.t('inquiries:deliveree.sendDeliveree')}
                visible={showForm}
                onCancel={toggleForm}
                destroyOnClose
                footer={null}
            >
                <DelivereeForm orderId={orderId}
                    fetchOrder={refetch}
                    onToggleForm={toggleForm} />
            </Modal>
        </div>
    )
}

SendDeliveree.propTypes = {
    orderId: PropTypes.string.isRequired,
    refetch: PropTypes.func
}

SendDeliveree.defaultProps = {
    refetch: () => {}
}

export default SendDeliveree
