import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'

import TruckTypeSelect from '../../../components/truckReservationForm/truckTypeSelect'

import action from '../../../redux/reservationTruckTypes/actions'

const { fetchReservationTruckTypes, setReservationTruckTypeSelected } = action

class TruckTypeContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner Props
        fetchReservationTruckTypes: PropTypes.func.isRequired,
        reservationTruckType: ImmutablePropTypes.map.isRequired,
        setReservationTruckTypeSelected: PropTypes.func.isRequired,
        truckTypeEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: ''
    }

    componentDidMount () {
        const { fetchReservationTruckTypes, setReservationTruckTypeSelected, value } = this.props

        fetchReservationTruckTypes()

        if (!value) return null
        setReservationTruckTypeSelected({ reservationTruckTypeSelected: value })
    }

    handleChange = (reservationTruckTypeId) => {
        const { onChange, setReservationTruckTypeSelected } = this.props
        setReservationTruckTypeSelected({ reservationTruckTypeSelected: reservationTruckTypeId })
        onChange(reservationTruckTypeId)
    }

    getReservationTruckTypes () {
        const { reservationTruckType, truckTypeEntities } = this.props
        const truckTypeIds = reservationTruckType.get('items') || new List()

        return truckTypeIds.map((id) => truckTypeEntities.get(id.toString())) || new List()
    }

    render () {
        const { reservationTruckType } = this.props
        const loading = reservationTruckType.get('loading')
        return (
            <div>
                <TruckTypeSelect
                    onChange={this.handleChange}
                    loading={loading}
                    reservationTruckTypes={this.getReservationTruckTypes()}
                    value={reservationTruckType.get('reservationTruckTypeSelected', undefined)}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    reservationTruckType: state.get('reservationTruckTypes'),
    truckTypeEntities: state.getIn(['Entities', 'reservationTruckTypes'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchReservationTruckTypes,
        setReservationTruckTypeSelected
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckTypeContainer)
