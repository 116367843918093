import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'

import OrderInvoices from '../../components/Invoices/orderInvoices'

import accountingActions from '../../redux/orders/accounting/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    deleteInvoiceAdjustment,
    deleteOrderBusinessAddress,
    fetchInvoiceAdjustments,
    fetchOrderBusinessAddresses,
    fetchTaxInvoicePdf,
    saveOrderBusinessAddress,
    setActiveInvoice,
    setActiveInvoiceAdjustment,
    setInvoiceAdjustmentErrors,
    setOrderBusinessAddressActive,
    setShowInvoiceAdjustmentForm,
    setShowTaxInvoiceBusinessItemsModal,
    setShowTaxInvoiceBusinessDepositsModal,
    setShowTaxInvoiceBusinessModal,
    setTaxInvoiceBusinessErrors,
    downloadInvoiceAdjustment,
    sendETax,
    sendElectronicCreditNote,
    fetchOrderInvoiceEmailHistroies
} = accountingActions

const { setNotifications } = notificationActions

class OrderInvoiceIndexContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        customerId: PropTypes.string,

        accountings: ImmutablePropTypes.map.isRequired,
        deleteInvoiceAdjustment: PropTypes.func.isRequired,
        deleteOrderBusinessAddress: PropTypes.func.isRequired,
        fetchInvoiceAdjustments: PropTypes.func.isRequired,
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        fetchTaxInvoicePdf: PropTypes.func.isRequired,
        invoiceAdjustmentEntities: ImmutablePropTypes.map.isRequired,
        orderBusinessAddressEntities: ImmutablePropTypes.map.isRequired,
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        setActiveInvoiceAdjustment: PropTypes.func.isRequired,
        setActiveInvoice: PropTypes.func.isRequired,
        setInvoiceAdjustmentErrors: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        setOrderBusinessAddressActive: PropTypes.func.isRequired,
        setShowInvoiceAdjustmentForm: PropTypes.func.isRequired,
        setShowTaxInvoiceBusinessItemsModal: PropTypes.func.isRequired,
        setShowTaxInvoiceBusinessModal: PropTypes.func.isRequired
    }

    componentDidMount() {
        const {
            orderId,
            fetchOrderBusinessAddresses,
            fetchOrderInvoiceEmailHistroies
        } = this.props

        fetchOrderBusinessAddresses(orderId)
        fetchOrderInvoiceEmailHistroies(orderId)
    }

    fetchInvoiceAdjustments = (invoiceId) => {
        const { fetchInvoiceAdjustments, orderId } = this.props

        fetchInvoiceAdjustments(orderId, invoiceId)
    }

    getOrderBusinessAddresses = () => {
        const { accountings, orderBusinessAddressEntities } = this.props
        const orderBusinessAddressIds = accountings.get(
            'orderBusinessAddressItems'
        )

        const dataSource = orderBusinessAddressIds.map((id) => {
            return orderBusinessAddressEntities.get(id)
        })

        return dataSource.toJS()
    }

    getInvoiceEmailHistories = () => {
        const { accountings, invoiceEmailHistoryEntities } = this.props
        const invoiceEmailHistoryIds = accountings.get(
            'invoiceEmailHistoryItems'
        )

        return invoiceEmailHistoryIds.map((id) => {
            return invoiceEmailHistoryEntities.get(`${id}`)
        })
    }

    handleEditOrderBusinessAddress = (id) => {
        const {
            setShowTaxInvoiceBusinessModal,
            accountings,
            setOrderBusinessAddressActive
        } = this.props
        const showAddBusinessAddress = accountings.get(
            'taxInvoiceBusinessAddressModal'
        )

        setOrderBusinessAddressActive(id)
        setShowTaxInvoiceBusinessModal(!showAddBusinessAddress)
    }

    handleEditOrderBusinessAddressItems = (id) => {
        const {
            setShowTaxInvoiceBusinessItemsModal,
            accountings,
            setOrderBusinessAddressActive
        } = this.props
        const visible = accountings.get('taxInvoiceBusinessAddressItemsModal')

        setOrderBusinessAddressActive(id)
        setShowTaxInvoiceBusinessItemsModal(!visible)
    }

    handleEditOrderBusinessAddressDeposits = (id = null) => {
        const {
            setShowTaxInvoiceBusinessDepositsModal,
            accountings,
            setOrderBusinessAddressActive
        } = this.props
        const visible = accountings.get(
            'taxInvoiceBusinessAddressDepositsModal'
        )

        setOrderBusinessAddressActive(id)
        setShowTaxInvoiceBusinessDepositsModal(!visible)
    }

    handleDeleteInvoiceAdjustment = ({ invoiceId, id }) => {
        const { deleteInvoiceAdjustment, fetchInvoiceAdjustments, orderId } =
            this.props

        deleteInvoiceAdjustment({
            orderId,
            invoiceId,
            id,
            onSuccess: () => {
                fetchInvoiceAdjustments(orderId, invoiceId)
            }
        })
    }

    handleDeleteOrderBusinessAddress = (id) => {
        const { deleteOrderBusinessAddress, orderId } = this.props

        deleteOrderBusinessAddress(orderId, id)
    }

    handleDownloadInvoice = (id, taxInvoiceType, locale) => {
        const { fetchTaxInvoicePdf, orderId } = this.props

        fetchTaxInvoicePdf(orderId, id, taxInvoiceType, locale.key)
    }

    handleSendETax = (id, taxInvoiceType) => {
        const { sendETax, orderId } = this.props

        sendETax({ orderId, id, taxInvoiceType })
    }

    handleSendElectronicCreditNote = (taxInvoiceId, adjustmentId) => {
        const { i18n, orderId, sendElectronicCreditNote, setNotifications } =
            this.props

        sendElectronicCreditNote({
            orderId: orderId,
            orderBusinessAddressId: taxInvoiceId,
            adjustmentId: adjustmentId,
            onSuccess: () => {
                setNotifications(
                    i18n.t('confirms:invoice.success'),
                    i18n.t('confirms:invoice.sendElectronicCreditNote'),
                    'success'
                )
            }
        })
    }

    handleShowInvoiceAdjustmentForm = ({
        invoiceId = null,
        id = null
    } = {}) => {
        const {
            setActiveInvoice,
            setActiveInvoiceAdjustment,
            setShowInvoiceAdjustmentForm,
            setInvoiceAdjustmentErrors,
            accountings
        } = this.props
        const showForm = accountings.get('invoiceAdjustmentFormModal')

        setActiveInvoice(invoiceId)
        setActiveInvoiceAdjustment(id)
        setInvoiceAdjustmentErrors()
        setShowInvoiceAdjustmentForm(!showForm)
    }

    handleDownloadInvoiceAdjustment = ({ orderId, invoiceId, id, locale }) => {
        const { downloadInvoiceAdjustment } = this.props

        downloadInvoiceAdjustment({ orderId, invoiceId, id, locale })
    }

    handleSaveOrderBusinessAddress = ({ id, address }) => {
        const { orderId, saveOrderBusinessAddress } = this.props

        saveOrderBusinessAddress({ address, id, orderId })
    }

    onSaveInvoiceSuccess = () => {
        const { setShowTaxInvoiceBusinessModal } = this.props

        setShowTaxInvoiceBusinessModal(false)
    }

    onSaveInvoiceError = (errors) => {
        const { setTaxInvoiceBusinessErrors, setShowTaxInvoiceBusinessModal } =
            this.props

        setShowTaxInvoiceBusinessModal(true)
        setTaxInvoiceBusinessErrors(errors)
    }

    onSaveInvoiceItemsSuccess = () => {
        const {
            accountings,
            orderId,
            setOrderBusinessAddressActive,
            setShowTaxInvoiceBusinessItemsModal,
            fetchOrderBusinessAddresses
        } = this.props
        const visible = accountings.get('taxInvoiceBusinessAddressItemsModal')

        setOrderBusinessAddressActive(null)
        setShowTaxInvoiceBusinessItemsModal(!visible)
        fetchOrderBusinessAddresses(orderId)
    }

    onIssueNewInvoiceSuccess = () => {
        const { fetchOrderBusinessAddresses, orderId } = this.props

        fetchOrderBusinessAddresses(orderId)
    }

    render() {
        const { accountings, invoiceAdjustmentEntities, orderId, customerId } =
            this.props

        return (
            <OrderInvoices
                activeInvoiceAdjustmentId={accountings.get(
                    'activeInvoiceAdjustment'
                )}
                activeInvoiceId={accountings.get('activeInvoice')}
                customerId={customerId}
                fetchInvoiceAdjustments={this.fetchInvoiceAdjustments}
                handleSendETax={this.handleSendETax}
                invoiceAdjustmentItems={accountings
                    .get('invoiceAdjustmentItems')
                    .toJS()}
                invoiceAdjustmentLoading={accountings.get(
                    'invoiceAdjustmentLoading'
                )}
                invoiceAdjustmentLoadingIds={accountings
                    .get('invoiceAdjustmentLoadingIds')
                    .toJS()}
                invoiceAdjustments={invoiceAdjustmentEntities.toJS()}
                invoiceEmailHistories={this.getInvoiceEmailHistories()}
                invoiceLoading={accountings.get('invoiceLoading')}
                loading={accountings.get('orderBusinessAddressesLoading')}
                onDeleteInvoiceAdjustment={this.handleDeleteInvoiceAdjustment}
                onDeleteOrderBusinessAddress={
                    this.handleDeleteOrderBusinessAddress
                }
                onDownloadInvoice={this.handleDownloadInvoice}
                onDownloadInvoiceAdjustment={
                    this.handleDownloadInvoiceAdjustment
                }
                onEditOrderBusinessAddress={this.handleEditOrderBusinessAddress}
                onEditOrderBusinessAddressDeposits={
                    this.handleEditOrderBusinessAddressDeposits
                }
                onEditOrderBusinessAddressItems={
                    this.handleEditOrderBusinessAddressItems
                }
                onIssueNewInvoiceSuccess={this.onIssueNewInvoiceSuccess}
                onSaveInvoiceError={this.onSaveInvoiceError}
                onSaveInvoiceItemsSuccess={this.onSaveInvoiceItemsSuccess}
                onSaveInvoiceSuccess={this.onSaveInvoiceSuccess}
                onSaveOrderBusinessAddress={this.handleSaveOrderBusinessAddress}
                onSendElectronicCreditNote={this.handleSendElectronicCreditNote}
                onToggleInvoiceAdjustmentForm={
                    this.handleShowInvoiceAdjustmentForm
                }
                orderBusinessAddressActive={accountings.get(
                    'orderBusinessAddressActive'
                )}
                orderBusinessAddresses={this.getOrderBusinessAddresses()}
                orderId={orderId}
                sendECreditNoteSaving={accountings.get('sendECreditNoteSaving')}
                sendETaxSaving={accountings.get('sendETaxSaving')}
                showBusinessAddressDepositsForm={accountings.get(
                    'taxInvoiceBusinessAddressDepositsModal'
                )}
                showBusinessAddressForm={accountings.get(
                    'taxInvoiceBusinessAddressModal'
                )}
                showBusinessAddressItemsForm={accountings.get(
                    'taxInvoiceBusinessAddressItemsModal'
                )}
                showInvoiceAdjustmentForm={accountings.get(
                    'invoiceAdjustmentFormModal'
                )}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    accountings: state.get('orderPaymentDetails'),
    invoiceAdjustmentEntities: state.getIn(['Entities', 'invoiceAdjustments']),
    orderBusinessAddressEntities: state.getIn([
        'Entities',
        'orderBusinessAddresses'
    ]),
    invoiceEmailHistoryEntities: state.getIn([
        'Entities',
        'invoiceEmailHistories'
    ])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            deleteInvoiceAdjustment,
            deleteOrderBusinessAddress,
            fetchInvoiceAdjustments,
            fetchOrderBusinessAddresses,
            fetchTaxInvoicePdf,
            saveOrderBusinessAddress,
            setActiveInvoice,
            setActiveInvoiceAdjustment,
            setInvoiceAdjustmentErrors,
            setOrderBusinessAddressActive,
            setShowInvoiceAdjustmentForm,
            setShowTaxInvoiceBusinessItemsModal,
            setShowTaxInvoiceBusinessDepositsModal,
            setShowTaxInvoiceBusinessModal,
            setTaxInvoiceBusinessErrors,
            downloadInvoiceAdjustment,
            sendETax,
            sendElectronicCreditNote,
            setNotifications,
            fetchOrderInvoiceEmailHistroies
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('confirms')(OrderInvoiceIndexContainer))
