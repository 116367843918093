import { Map, List } from 'immutable'

import actions from './actions'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const {
  PAYMENT_VOUCHERS_FETCH,
  PAYMENT_VOUCHERS_FETCH_SUCCESS,

  PAYMENT_VOUCHER_SAVE,
  PAYMENT_VOUCHER_SAVE_SUCCESS,

  PAYMENT_VOUCHER_SET_ERRORS,
  PAYMENT_VOUCHERS_SET_FILTERS,
  PAYMENT_VOUCHERS_SET_PAGINATION,

  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING,
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS,
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL
} = actions

const initState = new Map({
  filters: new Map({
    haveBankStatement: '',
    transferStartDate: '',
    transferEndDate: '',
  }),
  formErrors: new Map(),
  items: new List(),
  loading: false,
  pagination: new Map({
    current: DEFAULT_CURRENT_PAGE,
    pageSize: PAGE_SIZE,
    total: PAGE_SIZE
  }),
  saving: false,
  exporting: false
})

export default function paymentVouchersReducer (state = initState, action) {
  const result = action.result
  const items = state.get('items')

  switch (action.type) {
    case PAYMENT_VOUCHERS_FETCH:
      return state.merge({ loading: true })
    case PAYMENT_VOUCHERS_FETCH_SUCCESS:
      return state.merge({
        loading: false,
        items: new List(result)
      })
    case PAYMENT_VOUCHER_SAVE:
      return state.merge({
        saving: true
      })
    case PAYMENT_VOUCHER_SAVE_SUCCESS:
      return state.merge({
        saving: false,
        items: new List(items.includes(result) ? items : items.push(result))
      })
    case PAYMENT_VOUCHER_SET_ERRORS:
      return state.merge({
        formErrors: new Map(action.formErrors),
        saving: false
      })
    case PAYMENT_VOUCHERS_SET_FILTERS:
      return state.mergeDeep({
        filters: action.filters
      })
    case PAYMENT_VOUCHERS_SET_PAGINATION:
      return state.mergeDeep({
        pagination: action.pagination
      })
    case PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING:
        return state.merge({ exporting: true })
    case PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL:
        return state.merge({ exporting: false })
    case PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS:
        return state.merge({ exporting: false })
    default:
      return state
  }
}
