import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useLazyQuery } from '@apollo/client'

import { Spin } from 'antd'

import SelectCountryRender from './render'

const COUNTRIES_QUERY = gql`
  query Countries($query: String) {
    countries(query: $query) {
      id
      name
    }
  }
`

const COUNTRY_QUERY = gql`
  query Country($id: ID!) {
    country(id: $id) {
      id
      name
    }
  }
`

const SelectCountry = ({
  onChange,
  defaultValue
}) => {
  const [query, setQuery] = useState(null)
  const [countryId, setCountryId] = useState(defaultValue)
  const [fetchCountries, { loading, data }] = useLazyQuery(
    COUNTRIES_QUERY
  )

  const [fetchCountry, { loading: countryLoading, data: countryData }] = useLazyQuery(
    COUNTRY_QUERY
  )

  useEffect(() => {
    if (defaultValue) {
      fetchCountry({ variables: { id: defaultValue } })
    }

    if (query) {
      fetchCountries({
        variables: { query },
      })
    }
  }, [query])

  const countries = data && data.countries ? data.countries : []
  const country = countryData && countryData.country ? countryData.country : null

  const onSearch = query => {
    if (!query) {
      return
    }

    setQuery(query)
  }

  const setSelectedCountry = value => {
    setCountryId(value)
    onChange(value)
  }

  if (countryLoading) return <Spin />

  const defaultOptions = country ? countries.concat(country) : []

  return (
    <SelectCountryRender
      countries={countries.length > 0 ? countries : defaultOptions}
      loading={loading}
      onSearch={onSearch}
      onChange={setSelectedCountry}
      value={countryId}
    />
  )
}

SelectCountry.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectCountry.defaultProps = {
  onChange: () => {},
  defaultValue: null
}

export default SelectCountry
