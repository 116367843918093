const actions = {
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL: 'ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL',
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_REQUEST: 'ORDER_SCB_PAYMENT_CONFIRMS_FETCH_REQUEST',
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS: 'ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS',
    ORDER_SCB_PAYMENT_CONFIRMS_FETCHING: 'ORDER_SCB_PAYMENT_CONFIRMS_FETCHING',

    fetchOrderScbPaymentConfirms: ({ orderId = '', onSuccess = () => {}, onError = () => {} } = {}) => {
        if (!orderId) return null

        return {
            type: actions.ORDER_SCB_PAYMENT_CONFIRMS_FETCH_REQUEST,
            payload: { orderId, onSuccess, onError }
        }
    }
}

export default actions
