import React from 'react'
import { gql, useQuery } from '@apollo/client'

import { Spin, Space, Button } from 'antd'
import Scrollbar from 'react-smooth-scrollbar'
import Card from '../Card'

const LANES_QUERY = gql`
  query Deals(
    $state: String!
    $staffId: String
    $first: Int
    $filterOption: String!
    $sortOption: String!
    $startDate: String
    $endDate: String
    $categoryId: ID
    $after: String
  ) {
    deals(
      state: $state
      staffId: $staffId
      first: $first
      sortOption: $sortOption
      filterOption: $filterOption
      startDate: $startDate
      endDate: $endDate
      categoryId: $categoryId
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        assignDate
        customerNotesCount
        orderSupplierNotesCount

        order {
          id
          number
          grandTotal {
            display
          }
          note
          paymentMethod
          state
          stateLabel
          lineItemsCount
          orderShippingAddress {
            id
            province {
              name
            }
            district {
              name
            }
          }
          orderMembers {
            id
            role
            orderMemberId
            firstName
            fullName
            staffId
          }
          customer {
            fullName
            customerNo
            lineUrl
            phoneNumber
          }
          supplierOrders {
            id
          }
        }
      }
    }
  }
`

const renderCards = (deals, refetch) =>
  deals.map((deal) => <Card key={deal.id} deal={deal} refetch={refetch} />)

const renderLoadMoreButton = (deals, handleLoadMore, loading) => {
  if (!deals.pageInfo.hasNextPage) return null
  if (loading) {
    return (
      <Space size='middle'>
        <Spin size='large' />
      </Space>
    )
  }
  return (
    <Space className='middle' align='center'>
      <Button type='primary' size='large' onClick={handleLoadMore}>
        Load More
      </Button>
    </Space>
  )
}

const CardsContainer = ({
  state,
  setTotalCount,
  filterOption,
  sortOption,
  otherFilters
}) => {
  const { data, error, loading, fetchMore, refetch } = useQuery(LANES_QUERY, {
    variables: {
      state: state,
      first: 20,
      filterOption: filterOption,
      sortOption: sortOption,
      ...otherFilters
    },
    onCompleted: (data) => {
      setTotalCount(data.deals.totalCount)
    }
  })

  const handleLoadMore = () => {
    fetchMore({
      variables: { state: state, after: data.deals.pageInfo.endCursor },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          deals: {
            ...fetchMoreResult.deals,
            nodes: [...prev.deals.nodes, ...fetchMoreResult.deals.nodes],
            pageInfo: fetchMoreResult.deals.pageInfo
          }
        }
      }
    })
  }

  if (error) console.error(error)
  if (!data)
    return (
      <Space size='middle'>
        <Spin size='large' />
      </Space>
    )

  return (
    <div>
      <Scrollbar className={'scroll-content'}>
        {renderCards(data.deals.nodes, refetch)}
        {renderLoadMoreButton(data.deals, handleLoadMore, loading)}
      </Scrollbar>
    </div>
  )
}

export default CardsContainer
