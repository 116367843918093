import { Map, List } from 'immutable'

import actions from './actions'

const {
    SUPPLIER_ACCOUNTING_GROUPS_FETCH,
    SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS,
    SELECT_EXCLUDE_ITEM
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    excludeItems: new List()
})

export default function supplierOrdersReducer (state = initState, action) {
    const excludeItems = state.get('excludeItems')

    switch (action.type) {
        case SUPPLIER_ACCOUNTING_GROUPS_FETCH:
            return state.merge({
                loading: action.loading
            })
        case SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result)
            })
        case SELECT_EXCLUDE_ITEM:
            return state.merge({
                excludeItems: excludeItems.merge(action.id)
            })
        default:
            return state
    }
}
