import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Input, Row, Col, Form, Card } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import DropdownPdfPreview from '../Orders/DropdownPdfPreview'

import accountingActions from '../../redux/orders/accounting/actions'

const { fetchTemporaryReceiptPdf } = accountingActions

class TemporaryReceiptsFormContainer extends Component {
    constructor (props) {
        super(props)

        this.formRef = createRef()
    }

  static propTypes = {
      orderId: PropTypes.string.isRequired
  }

  handleSubmit = (e) => {
        e.preventDefault()
      const { fetchTemporaryReceiptPdf, orderId } = this.props
      this.formRef.current.validateFields().then(values => {
            fetchTemporaryReceiptPdf(orderId, values, e.key)
      })
  }

  render () {
      const { accountings, i18n } = this.props
      const loading = accountings.get('temporaryReceiptLoading')

      return (
          <Card title={i18n.t('confirms:temporaryReceipt.downloadTemporaryReceipt')}
              style={{ margin: '20px 0' }}>
              <Form ref={this.formRef}>
                  <Row gutter={24}>
                      <Col span={5}>
                          <Form.Item name='receipt_no'
                            rules={[{
                                required: true,
                                message: i18n.t('confirms:temporaryReceipt.pleaseInput') }]}>
                                  <Input placeholder={i18n.t('confirms:temporaryReceipt.receiptNo')}/>
                          </Form.Item>
                      </Col>
                      <Col span={5}>
                          <Form.Item name='amount'
                            rules={[{
                                required: true,
                                message: i18n.t('confirms:temporaryReceipt.pleaseInput') }]}>
                                  <Input placeholder={i18n.t('confirms:temporaryReceipt.amount')}/>
                          </Form.Item>
                      </Col>
                      <Col span={8}>
                          <Form.Item name='note'>
                                  <Input placeholder={i18n.t('confirms:temporaryReceipt.note')}/>
                          </Form.Item>
                      </Col>
                      <Col span={4}>
                          <Form.Item>
                              <DropdownPdfPreview
                                  onClick={this.handleSubmit}
                                  loading={loading}
                                  text={i18n.t('confirms:temporaryReceipt.downloadTemporaryReceipt')}
                                  icon={<DownloadOutlined />} />
                          </Form.Item>
                      </Col>
                  </Row>
              </Form>
          </Card>
      )
  }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTemporaryReceiptPdf
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('confirms')(TemporaryReceiptsFormContainer))
