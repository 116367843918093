import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import actions from '../../../redux/customers/companyProjects/actions'
import CustomerCompanyProjectsIndex from '../../../components/customers/companyProjects/customerCompanyProjectsIndex'
import notificationActions from '../../../redux/notifications/actions'

const { fetchCustomerCompanyProjects, setCompanyProjectFormModal } = actions
const { setNotifications } = notificationActions

class CustomerCompanyProjectsContainer extends Component {
    componentDidMount () {
        const { customerId, fetchCustomerCompanyProjects } = this.props
        fetchCustomerCompanyProjects({ customerId })
    }

    getCompanyProjects = () => {
        const { companyProjectEntities, projects } = this.props

        return projects.get('items', new List()).map((projectId) => {
            return companyProjectEntities.get(projectId) || new Map()
        })
    }

    handlePageChange = (pagination) => {
        const { customerId, fetchCustomerCompanyProjects } = this.props
        fetchCustomerCompanyProjects({ customerId, page: pagination.current })
    }

    toggleCompanyProjectForm = ({ id = null } = {}) => {
        const { projects, setCompanyProjectFormModal } = this.props

        setCompanyProjectFormModal(!projects.get('showCompanyProjectForm'), id)
    }

    onSuccess = () => {
        const { setNotifications } = this.props
        this.toggleCompanyProjectForm()
        setNotifications('success', 'success', 'success')
    }

    onError = () => {
        const { setNotifications } = this.props
        setNotifications('error', 'error', 'error')
    }

    render () {
        const { projects, customerId } = this.props
        return (
            <CustomerCompanyProjectsIndex
                activeProject={projects.get('projectIdSelected')}
                customerId={customerId}
                loading={projects.get('loading')}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...projects.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                projects={this.getCompanyProjects().toJS()}
                showCompanyProjectForm={projects.get('showCompanyProjectForm')}
                onSuccess={this.onSuccess}
                onError={this.onError}
                toggleCompanyProjectForm={this.toggleCompanyProjectForm} />
        )
    }
}

const mapStateToProps = (state) => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    projects: state.get('customerCompanyProjects'),
    companyProjectEntities: state.getIn(['Entities', 'companyProjects']),
    customerCompanyEntities: state.getIn(['Entities', 'customerCompanies'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerCompanyProjects,
        setCompanyProjectFormModal,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCompanyProjectsContainer)
