import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import LinePinsIndex from '../../components/linePins/linePinsIndex'
import CurrentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

const LINE_PINS_QUERY = gql`
    query LinePins($startDate: String,  $endDate: String, $staffId: ID, $page: Int, $per: Int) {
        linePins(startDate: $startDate, endDate: $endDate, staffId: $staffId, page: $page, per: $per) {
          totalPages
          linePins {
            id
            customer {
                id
                customerNo
                fullName
                roleName
                phoneNumber
                lineUrl
                companies {
                  name
                }
                bio
                email
            }
            staff {
              fullName
            }
          }
        }
    }
`

const pageSize = 20

const LinePinsIndexContainer = ({ currentStaff }) => {
    const [page, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState({})
    const { data, loading } = useQuery(LINE_PINS_QUERY, {
      variables: { page, per: pageSize, ...filters }
    })

    const handleTableChange = (pagination) => {
      setCurrentPage(pagination.current)
    }

    const handleChangeFilter = args => {
      setFilters({ ...filters, ...args })
      setCurrentPage(1)
    }

    const item = data ? data.linePins : {}

    return (
      <LinePinsIndex
        currentStaff={currentStaff.toJS()}
        linePins={item.linePins || []}
        loading={loading}
        onChangeFilter={handleChangeFilter}
        onTableChange={handleTableChange}
        pagination={{
          showSizeChanger: false,
          current: page,
          pageSize: pageSize,
          total: pageSize * (item.totalPages || 1)
        }}
      />
    )
}

LinePinsIndexContainer.propTypes = {}

LinePinsIndexContainer.defaultProps = {}

export default CurrentStaffContainer(LinePinsIndexContainer)
