import styled from 'styled-components'
const SupplierPurchaseListWrapper = styled.div`
  .btn-center {
    text-align: center
  }

  .text-right {
    text-align: right
  }

  .header {
    margin: 5px;
  }

  .link-form {
    color: #1890ff;
    cursor:pointer;
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .chat-button {
    background: #00A800;

    i {
      color: #FFF;
    }
  }

  .icon-button {
    width: 40px;
    margin-right: 8px;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .detail-container > span {
    color: #000;
  }

  .btn-margin > button {
    margin-left: 10px;
  }

  .table-row-light {
    background-color: #e1e1e1;
    color: gray;
  }
`
export default SupplierPurchaseListWrapper