import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import moment from 'moment'

import SalePipeLinesStatusSummary from './statusSummary'
import Filter, { getInitialFilters } from './filter'
import SalePipeLinesMarginSummary from './marginSummary'
import SalePipelineTable, { LIMIT, MARGIN_SUMMARY_FIELDS, NODE_FIELDS } from './table'

const SALE_PIPELINES_QUERY = gql`
    query SalePipelinesWithSummaries(
        $isPotential: Boolean,
        $month: String,
        $year: String,
        $staffId: ID,
        $status: String,
        $startDate: String,
        $endDate: String,
        $first: Int,
        $after: String
    ) {
        salePipelinesWithSummaries(
            onlyNotArchived: false,
            isPotential: $isPotential,
            month: $month,
            year: $year,
            staffId: $staffId,
            status: $status,
            startDate: $startDate,
            endDate: $endDate
        ) {
            salePipelinesConnection(first: $first, after: $after) {
                nodes ${NODE_FIELDS}
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
            statusSummary {
                noStatusCount
                successStatusCount
                failStatusCount
            }
            ${MARGIN_SUMMARY_FIELDS}
        }
    }
`

const INITIAL_FILTERS = getInitialFilters({
    month: moment().format('M'),
    year: moment().format('YYYY')
})

const SalePipeLinesReport = ({ currentStaffId }) => {
    const [filters, setFilters] = useState({ ...INITIAL_FILTERS, staffId: currentStaffId })
    const { loading, data, error, fetchMore, refetch } = useQuery(SALE_PIPELINES_QUERY, {
        variables: { first: LIMIT, ...filters }
    })

    if (error) console.error(error)

    const salePipelinesWithSummaries = (data && data.salePipelinesWithSummaries) || {}
    const { salePipelinesConnection, statusSummary, marginSummary } = salePipelinesWithSummaries || {}
    const { nodes, pageInfo } = salePipelinesConnection || {}

    const onChangeFilters = (args = {}) => {
        setFilters({ ...filters, ...args })
    }

    return (
        <div>
            <SalePipeLinesStatusSummary statusSummary={statusSummary} />
            <Filter
                filters={filters}
                defaultStaffId={currentStaffId}
                onChangeFilters={onChangeFilters} />
            <SalePipeLinesMarginSummary marginSummary={marginSummary} />
            <SalePipelineTable
                filters={filters}
                loading={loading}
                onFetchMore={fetchMore}
                pageInfo={pageInfo || {}}
                salePipelines={nodes || []}
                refetch={refetch}
            />
        </div>
    )
}

SalePipeLinesReport.propTypes = {}

SalePipeLinesReport.defaultProps = {}

export default SalePipeLinesReport
