import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map } from 'immutable'

import StaffForm from '../../components/trucks/StaffForm'

import truckActions from '../../redux/trucks/actions'

const {
    saveTruckOwnerStaff,
    setTruckOwnerStaffForm
} = truckActions

class StaffFormContainer extends Component {
    static propTypes = {
        // Props from another component
        onError: PropTypes.func,
        onSuccess: PropTypes.func,
        truckOwner: PropTypes.object,

        // Inner props
        saveTruckOwnerStaff: PropTypes.func.isRequired,
        setTruckOwnerStaffForm: PropTypes.func.isRequired,
        trucks: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onError: () => {},
        onSuccess: () => {},
        truckOwner: {}
    }

    componentWillUnmount () {
        this.props.setTruckOwnerStaffForm({ error: {} })
    }

    handleSubmit = ({ params }) => {
        const { onError, onSuccess, saveTruckOwnerStaff, truckOwner } = this.props

        saveTruckOwnerStaff({
            truckOwnerId: truckOwner.id,
            staffParams: params,
            onError: onError,
            onSuccess: onSuccess
        })
    }

    render () {
        const { truckOwner, trucks } = this.props

        if (_.isEmpty(truckOwner)) {
            return (
                <div>
                    No data
                </div>
            )
        }

        return (
            <StaffForm
                errors={trucks.getIn(['truckOwnerStaffForm', 'error'], new Map()).toJS()}
                onSubmit={this.handleSubmit}
                value={truckOwner} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trucks: state.get('trucks')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveTruckOwnerStaff,
        setTruckOwnerStaffForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffFormContainer)
