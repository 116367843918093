import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../../../redux/notifications/actions'
import actionsAccounting from '../../../../redux/orders/accounting/actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../../../helpers/jsonApi'

import { businessAddressSchema, businessAddressesSchema } from '../../../schema'

const { setNotifications } = actionsNotification

const { SHOW_ADD_BUSINESS_ADDRESS_MODAL, SHOW_TAX_INVOICE_BUSINESS_MODAL } = actionsAccounting

const {
    BUSINESSES_FETCH_REQUEST,
    BUSINESSES_FETCH,
    BUSINESSES_SET_PAGINATION,
    BUSINESSES_SAVE_REQUEST,
    BUSINESSES_SET_SAVING,
    BUSINESSES_SET_FORM_ERRORS,
    BUSINESSES_SAVE_SUCCESS,
    BUSINESSES_ITEM_FETCH_REQUEST,
    BUSINESSES_ITEM_FETCH,
    BUSINESSES_DELETE_REQUEST,
    BUSINESSES_DELETE_SUCCESS
} = actions

const apiPath = (customerNo, id) => {
    const basePath = `/customers/${customerNo}/business_addresses`

    if (!id) { return basePath }
    return `/${basePath}/${id}`
}

export function *fetchBusinessAddresses () {
    yield takeEvery(BUSINESSES_FETCH_REQUEST, function *({ payload }) {
        const PAGE_SIZE = 20
        const customerNo = payload.id
        const page = payload.page

        yield put({ type: BUSINESSES_FETCH })

        const data = yield call(apiFetch, `/customers/${customerNo}/business_addresses?page=${page}&per=${PAGE_SIZE}`)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, businessAddressesSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: BUSINESSES_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: actions.BUSINESSES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveBusinessAddress () {
    yield takeLatest(BUSINESSES_SAVE_REQUEST, function *({ payload }) {
        yield put({
            type: BUSINESSES_SET_SAVING,
            saving: true })
        const id = payload.id
        const method = id ? 'PATCH' : 'POST'
        const customerId = payload.customerId
        const isFromInvoiceFormContainer = payload.isFromInvoiceFormContainer

        const path = apiPath(customerId, id)

        const body = JSON.stringify({ business_address: payload.address })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: BUSINESSES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put({
                    type: SHOW_ADD_BUSINESS_ADDRESS_MODAL,
                    value: true
                })
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: BUSINESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, businessAddressSchema)
                })

                yield put({
                    type: SHOW_ADD_BUSINESS_ADDRESS_MODAL,
                    value: false
                })
                yield put({
                    type: SHOW_TAX_INVOICE_BUSINESS_MODAL,
                    value: false
                })
                yield put(setNotifications('success', 'saveSuccess', 'success'))
                if (payload.redirect && !isFromInvoiceFormContainer) return window.history.back()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: BUSINESSES_SET_SAVING,
                saving: false
            })
        }
    })
}

export function *businessAddressRequest () {
    yield takeLatest(BUSINESSES_ITEM_FETCH_REQUEST, function *({ payload }) {
        const customerId = payload.customerId
        const id = payload.addressId

        yield put({ type: BUSINESSES_ITEM_FETCH })

        const data = yield call(apiFetch, `/customers/${customerId}/business_addresses/${id}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, businessAddressSchema)

        yield put({
            type: actions.BUSINESSES_ITEM_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *deleteBusinessAddress () {
    yield takeLatest(BUSINESSES_DELETE_REQUEST, function *({ payload }) {
        const customerId = payload.customerId
        const businessAddressId = payload.businessAddressId
        const path = `/customers/${customerId}/business_addresses/${businessAddressId}`
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: BUSINESSES_DELETE_SUCCESS,
                ...normalize(formattedData, businessAddressSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchBusinessAddresses),
        fork(saveBusinessAddress),
        fork(businessAddressRequest),
        fork(deleteBusinessAddress)
    ])
}
