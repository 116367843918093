import React from 'react'
import moment from 'moment'
import _ from 'lodash'

import { Col, DatePicker, Divider, Row, Select, Table } from 'antd'
import Image from '../../containers/Image'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import EditText from '../../containers/EditText'
import OrderPaymentDetailsWrapper from './orderPaymentDetails.style'

import { withBank } from '../../helpers/bank'

const { RangePicker } = DatePicker

const OrderPaymentDetailList = ({
    orderPaymentDetailList,
    loading,
    i18n,
    handleSendingStateChange,
    defaultHaveBankStatement,
    defaultTransferDate,
    handleTransferDateChange,
    pagination,
    onTableChange,
    onSaveRVBankStatement,
    bankCodeToBankName
}) => {
    const columns = [
        {
            title: i18n.t('orderPaymentDetails:orderNumber'),
            dataIndex: 'order_number'
        },
        {
            title: i18n.t('orderPaymentDetails:transferDate'),
            dataIndex: 'transfer_date'
        },
        {
            title: i18n.t('orderPaymentDetails:careTaker'),
            render: (record) => (
                <div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('orderPaymentDetails:sale')}</span>
                        {record.sales.map(sale => <div>{sale}</div>)}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('orderPaymentDetails:cs')}</span>
                        {record.cses.map(cs => <div>{cs}</div>)}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('orderPaymentDetails:purchaser')}</span>
                        {record.purchasers.map(purchaser => <div>{purchaser}</div>)}
                    </div>
                </div>
            )
        },
        {
            title: i18n.t('orderPaymentDetails:detail'),
            render: (value, row) => renderDetail(row)
        },
        {
            title: i18n.t('orderPaymentDetails:amount'),
            dataIndex: ['amount', 'display']
        },
        {
            title: i18n.t('orderPaymentDetails:slipImages'),
            render: (record) => (
                <div className="image-container">
                    {renderImage(record.order_payment_images)}
                </div>
            )
        },
        {
            title: i18n.t('orderPaymentDetails:bankStatement'),
            render: ({ bank_statement: bankStatement }, record) => {
                if (_.isEmpty(bankStatement)) { return renderEditBankStatement(record) }
                
                return (
                    <div>
                        {renderEditBankStatement(record)}
                        <div className="detail-line">
                            <span className="detail-label">{i18n.t('orderPaymentDetails:accountNumber')}</span>
                            {bankStatement.account_number}
                        </div>
                        <div className="detail-line">
                            <span className="detail-label">{i18n.t('orderPaymentDetails:date')}</span>
                            {_.get(bankStatement, 'date.display')}
                        </div>
                        <div className="detail-line">
                            <span className="detail-label">{i18n.t('orderPaymentDetails:transactionCode')}</span>
                            {bankStatement.transaction_code}
                        </div>
                        <div className="detail-line">
                            <span className="detail-label">{i18n.t('orderPaymentDetails:channel')}</span>
                            {bankStatement.channel}
                        </div>
                    </div>
                )
            }
        },
        {
            title: i18n.t('orderPaymentDetails:bankStatementAmount'),
            render: ({ bank_statement: bankStatement }) => {
                if (_.isEmpty(bankStatement)) { return null }

                return _.get(bankStatement, 'credit_amount.display')
            }
        },
        {
            title: i18n.t('scbPaymentConfirms:scbPaymentDetail'),
            dataIndex: 'scb_payment_confirms',
            render: (scbPaymentConfirms) => renderScbPaymentConfirms(scbPaymentConfirms)
        }
    ]

    const renderScbPaymentConfirms = (scbPaymentConfirms) => {
        const count = scbPaymentConfirms.length
        moment.locale(i18n.language)

        return scbPaymentConfirms.map((scbPaymentConfirm, index) => {
            const payer = _.compact([
                _.get(scbPaymentConfirm, 'payer_account_name', ''),
                _.get(scbPaymentConfirm, 'payer_account_number'),
                bankCodeToBankName(_.get(scbPaymentConfirm, 'sending_bank_code', ''))
            ]).join(' ')

            const payee = _.compact([
                _.get(scbPaymentConfirm, 'payee_name', ''),
                _.get(scbPaymentConfirm, 'payee_account_number', ''),
                bankCodeToBankName(_.get(scbPaymentConfirm, 'receiving_bank_code', ''))
            ]).join(' ')

            const transactionTime = _.get(scbPaymentConfirm, 'transaction_date_and_time', '')
            
            return (
                <div>
                    <div className="detail-line">
                        <span className="detail-label">Transaction ID</span>{scbPaymentConfirm.transaction_id}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('scbPaymentConfirms:payer')}</span>{payer}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('scbPaymentConfirms:payee')}</span>{payee}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('scbPaymentConfirms:transactionDateTime')}</span>
                        {transactionTime && moment(transactionTime).format('LLLL')}
                    </div>
                    <div className="detail-line">
                        <span className="detail-label">{i18n.t('scbPaymentConfirms:amount')}</span>
                        {_.get(scbPaymentConfirm, 'amount', 0.00)} {i18n.t('shared:baht')}
                    </div>
                    {index + 1 < count && <Divider className="scb-payment-confirm-divider" />}
                </div>
            )
        })
    }

    const renderEditBankStatement = (record) => {
        return (
            <EditText
                initialParams={{ id: record.id }}
                value={_.get(record, 'bank_statement.number', '')}
                onSave={onSaveRVBankStatement}
            />
        )
    }

    const renderFilterSection = () => {
        return (
            <div>
                <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
                    <Col span={12}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orderPaymentDetails:filterByTransferDate')}:
                        </span>
                        <RangePicker
                            defaultValue={defaultTransferDate}
                            onChange={handleTransferDateChange}
                        />
                    </Col>
                    <Col span={12}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orderPaymentDetails:filterWithBankStatement')}:
                        </span>
                        <Select style={{ width: '200px' }} allowClear
                                defaultValue={defaultHaveBankStatement}
                                onChange={handleSendingStateChange}
                                placeholder={i18n.t('orderPaymentDetails:filterWithBankStatement')}>
                            <Select.Option value="true">
                                {i18n.t('orderPaymentDetails:have')}
                            </Select.Option>
                            <Select.Option value="false">
                                {i18n.t('orderPaymentDetails:dontHave')}
                            </Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Divider />
            </div>
        )
    }

    const renderImage = (images) => {
        return images.map((image) => {
            const imageId = image.id
            const thumbUrl = image.image.thumb || ''
            const originalUrl = image.image.original
            const contentType = image.image.content_type

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    const renderDetail = (row) => {
        return (
            <div>
                {row.payment_term ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.paymentTerm`)}: &nbsp; </span>
                        {i18n.t(`confirms:accounting.${row.payment_term}`)}
                    </div>) : null}
                {row.amount.display ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.amount`)}: &nbsp; </span>
                        {row.amount.display} {i18n.t(`confirms:accounting.bath`)}
                    </div>) : null}
                {row.payment_method ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.paymentMethod`)}: &nbsp; </span>
                        {i18n.t(`confirms:accounting.${row.payment_method}`)}
                    </div>) : null}
                {row.bank_full_name ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.bank`)}: &nbsp; </span>
                        {row.bank_full_name}
                    </div>) : null}
            </div>
        )
    }

    return (
        <OrderPaymentDetailsWrapper>
            {renderFilterSection()}
            <Table
                columns={columns}
                dataSource={orderPaymentDetailList}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
                rowKey="id"
            />
        </OrderPaymentDetailsWrapper>
    )
}

export default withBank(withImagePreview(OrderPaymentDetailList))
