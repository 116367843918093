import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'

import SalePipelinesWrapper from './salePipelines.style'

const renderSalePipelineInfo = (label, value) => {
  return (
    <div className="detail-row">
      <div className="detail-label">{label}</div>
      <div className="detail-value">
          {value}
      </div>
    </div>
  )
}

const SalePipelineInfoRender = ({
  salePipeline
}) => {
  const { i18n } = useTranslation('salePipelines')

  if (!salePipeline) return null

  return (
    <SalePipelinesWrapper>
      {renderSalePipelineInfo(i18n.t('salePipelines:status'), salePipeline.statusLabel || '-')}
      {renderSalePipelineInfo(i18n.t('salePipelines:customerPersonality'), salePipeline.customerPersonality || '-')}
      {renderSalePipelineInfo(i18n.t('salePipelines:customerType'), salePipeline.customerTypeLabel || '-')}
      {renderSalePipelineInfo(i18n.t('salePipelines:interestingProduct'), salePipeline.interestingProduct || '-')}
      {renderSalePipelineInfo(i18n.t('salePipelines:staff'), salePipeline.staff ?  salePipeline.staff.fullName : '-')}
      {renderSalePipelineInfo(
        i18n.t('salePipelines:useMonth/Year'),
        `${salePipeline.month || '-'} / ${salePipeline.year || '-'}`
      )}
      {renderSalePipelineInfo(
        i18n.t('salePipelines:isPotential'),
        salePipeline.isPotential ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone />
      )}
      {renderSalePipelineInfo(
        i18n.t('salePipelines:archived'),
        salePipeline.archived ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone />
      )}
    </SalePipelinesWrapper>
  )
}

SalePipelineInfoRender.propTypes = {
  salePipeline: PropTypes.object,
}

SalePipelineInfoRender.defaultProps = {
}

export default SalePipelineInfoRender
