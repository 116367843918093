import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { withTranslation } from 'react-i18next'
import { Layout, Form, Select, Spin, DatePicker, Button, Table, Input, Divider, Row, Col } from 'antd'
import { isEmpty } from '../../helpers/objects'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import supplierActions from '../../redux/suppliers/actions'
import vatDocumentActions from '../../redux/vatDocuments/actions'
import VatDocumentsFilterWrapper from './vatDocumentsFilter.style'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const { searchSupplier } = supplierActions
const { setFilterDate, setPagination } = vatDocumentActions

const { Sider, Content } = Layout
const { MonthPicker } = DatePicker

class VatDocumentsFilter extends Component {
    componentDidMount () {
        const { setFilterDate, location } = this.props

        setFilterDate(new URLSearchParams(location.search).get("date_type") || 'date_of_purchase')
    }

	handleOnSearch = (query) => {
	    const { vatDocuments, setPagination, fetchItems, location } = this.props

	    const pagination = vatDocuments.get('pagination').toJS()

	    setPagination({ current: 1 })

	    const queryString = this.getQueryString({
	        ...location.params,
	        query: query,
	        pagination: { ...pagination, current: 1 }
	    })

	    fetchItems(queryString)
	}
	handleSupplierChange = (value) => {
	    const { vatDocuments, setPagination, fetchItems, location } = this.props

	    const pagination = vatDocuments.get('pagination').toJS()

	    setPagination({ current: 1 })

	    const queryString = this.getQueryString({
	        ...location.params,
	        supplier_ids: value,
	        pagination: { ...pagination, current: 1 }
	    })

	    fetchItems(queryString)
	}

    handleDateTypeChange = (dateType) => {
        const { vatDocuments, setFilterDate, setPagination, fetchItems, location } = this.props

        const pagination = vatDocuments.get('pagination').toJS()

        setFilterDate(dateType)
        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            date_type: dateType,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handleDateChange = (date, dateString) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const dateType = vatDocuments.get('filterDate')
        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            date_type: dateType,
            date_of_purchase: dateString,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handlePeriodChange = (date, dateString) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            apply_date: date && date.format('YYYY-MM'),
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handleFilterAmountMin = (value) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            amount_min: value,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handleFilterAmountMax = (value) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            amount_max: value,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handleUploadInvoiceChange = (state) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const getState = (!state) ? '' : state
        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            upload_invoice: getState,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    handleApplyDateChange = (state) => {
        const { vatDocuments, setPagination, fetchItems, location } = this.props

        const getState = (!state) ? '' : state
        const pagination = vatDocuments.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            is_apply_date: getState,
            pagination: { ...pagination, current: 1 }
        })

        fetchItems(queryString)
    }

    getQueryString (args = {}) {
	    const { vatDocuments } = this.props
	    const pagination = ('pagination' in args) ? args.pagination : vatDocuments.get('pagination').toJS()
        const supplier = ('supplier_ids' in args) ? args.supplier_ids : ''
        const dateType = ('date_type' in args) ? args.date_type : ''
        const date = ('date_of_purchase' in args) ? args.date_of_purchase : ''
        const period = ('apply_date' in args) ? args.apply_date : ''
        const isUploadInvoice = ('upload_invoice' in args) ? args.upload_invoice : ''
        const isApplyDate = ('is_apply_date' in args) ? args.is_apply_date : ''
        const query = ('query' in args) ? args.query : ''
        const amountMin = ('amount_min' in args) ? args.amount_min : ''
        const amountMax = ('amount_max' in args) ? args.amount_max : ''

	    const queries = [].concat(
	        this.getPageQueryObject(pagination),
	        this.getPerPageQueryObject(pagination),
            this.getSupplierQueryObject(supplier),
            this.getDateQueryArray(!date.length ? [args.start_date, args.end_date] : date, dateType),
            this.getPeriodQueryArray(period),
            this.getSortUploadInvoiceQueryObject(isUploadInvoice),
            this.getSortApplyDateQueryObject(isApplyDate),
            this.getQueryQueryObject(query),
            this.getAmountMinObject(amountMin),
            this.getAmountMaxObject(amountMax)
	    )

	    return queries.filter((query) => { return !isEmpty(query) }).map((query) => {
	        if (typeof query.value === 'object') {
	            return query.value.map((value) => {
	                return `${query.key}[]=${value}`
	            }).join('&')
	        }
	        return `${query.key}=${query.value}`
	    }).join('&')
    }

    getPageQueryObject (pagination) {
	    if (isEmpty(pagination)) { return {} }

	    return {
	        key: 'page',
	        value: pagination.current || DEFAULT_CURRENT_PAGE
	    }
    }

    getPerPageQueryObject (pagination) {
	    if (isEmpty(pagination)) { return {} }

	    return {
	        key: 'per',
	        value: pagination.pageSize || PAGE_SIZE
	    }
    }

    getSupplierQueryObject (supplier) {
	    if (!supplier) { return {} }

	    return { key: 'supplier_ids', value: supplier }
    }

    getDateQueryArray (date, dateType) {
        if (date.length !== 2 || !date[0]) { return [] }

        return [
            { key: 'start_date', value: date[0] },
            { key: 'end_date', value: date[1] },
            { key: 'date_type', value: dateType }
        ]
    }

    getPeriodQueryArray (period) {
        if (!period) { return {} }

	    return { key: 'apply_date', value: period }
    }

    getSortUploadInvoiceQueryObject (sort) {
        if (!sort) { return {} }

	    return { key: 'upload_invoice', value: sort }
    }

    getSortApplyDateQueryObject (sort) {
        if (!sort) { return {} }

	    return { key: 'is_apply_date', value: sort }
    }

    getQueryQueryObject (query) {
        if (!query) { return {} }

        return { key: 'query', value: query }
    }

    getAmountMinObject (sort) {
        if (!sort) { return {} }

	    return { key: 'amount_min', value: sort }
    }

    getAmountMaxObject (sort) {
        if (!sort) { return {} }

	    return { key: 'amount_max', value: sort }
    }

	renderSupplier = () => {
	    const { suppliers, supplierEntities } = this.props

	    return suppliers.get('supplierItems').map((supplierId) => {
	        const supplier = supplierEntities.get(supplierId.toString())

	        return (
	            <Select.Option key={supplier.get('id')} value={supplier.get('id')}>
	                {supplier.get('name')}
	            </Select.Option>
	        )
	    })
	}

	onSearchSupplier = (query) => {
	    const { searchSupplier } = this.props

	    searchSupplier({ query })
	}

	activeAllUploadInvoice (state) {
	    if (state === 'all') {
	        return 'upload-invoice active'
	    } else { return 'upload-invoice' }
	}

    activeAllApplyDate = (state) => {
        if (state === 'all') {
            return 'apply-date active'
        } else { return 'apply-date' }
    }

    checkActiveUploadInvoice = (state, stateValue) => {
        if (stateValue === state) {
            return 'upload-invoice active'
        } else { return 'upload-invoice' }
    }

    checkActiveApplyDate = (state, stateValue) => {
        if (stateValue === state) {
            return 'apply-date active'
        } else { return 'apply-date' }
    }

    getOrderQueryObject (sorter) {
        if (isEmpty(sorter)) {
            return {}
        }

        if (!sorter.columnKey) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: { order: sorter.columnKey, direction: sortDirection }
        }
    }

    handleSortingUploadInvoice = (e, state) => {
        const oldActive = document.getElementsByClassName('upload-invoice active')
        if (oldActive.length !== 0) {
            oldActive[0].classList.remove('active')
        }

        const btn = e.target
        btn.classList.add('active')
        this.handleUploadInvoiceChange(state)
    }

    handleSortingApplyDate = (e, state) => {
        const oldActive = document.getElementsByClassName('apply-date active')
        if (oldActive.length !== 0) {
            oldActive[0].classList.remove('active')
        }

        const btn = e.target
        btn.classList.add('active')
        this.handleApplyDateChange(state)
    }

    handleTableChange = (pagination, filter, sorter) => {
        const { setPagination, fetchItems, location } = this.props
        setPagination(pagination)

        const sorterQuery = this.getOrderQueryObject(sorter)

        const queryString = this.getQueryString({
            ...location.params,
            pagination,
            sorter
        })

        fetchItems(queryString, sorterQuery)
    }

    render () {
        const { suppliers, vatDocuments, location, columns, expandedRowRender, dataSource, i18n } = this.props
        const defaultSupplier = new URLSearchParams(location.search).getAll("supplier_ids") || undefined
        const defaultDateOfPurchase = new URLSearchParams(location.search).get("start_date") ? (
            [moment(new URLSearchParams(location.search).get("start_date")), moment(new URLSearchParams(location.search).get("end_date"))]
        ) : null
        const dateType = vatDocuments.get('filterDate')
        const defaultApplyDate = new URLSearchParams(location.search).get("apply_date") ? moment(new URLSearchParams(location.search).get("apply_date")) : null
        const defaultSortUploadInvoice = new URLSearchParams(location.search).get("upload_invoice") || 'all'
        const defaultSortApplyDate = new URLSearchParams(location.search).get("is_apply_date") || 'all'
        const defaultAmountMin = new URLSearchParams(location.search).get("amount_min") || null
        const defaultAmountMax = new URLSearchParams(location.search).get("amount_max") || null
        const loading = vatDocuments.get('loading')
        const suppliersLoading = suppliers.get('loading')
        const tablePagination = vatDocuments.get('pagination').toJS()

	    return (
	        <VatDocumentsFilterWrapper>
                <div>
                    <Input.Search enterButton
                        defaultValue={new URLSearchParams(location.search).get("query") || undefined}
                        onSearch={(query) => {this.handleOnSearch(query)}}
                        placeholder={i18n.t('suppliers:searchPlaceholder')} />
                    <Divider />
                </div>
	            <Layout>
	                <Sider width="fit-content">
	                    <Layout>
	                        <Content>
	                            <Form.Item label={i18n.t('suppliers:supplierName')}>
                                    <Select allowClear
	                                    defaultActiveFirstOption={false}
	                                    defaultValue={defaultSupplier}
	                                    filterOption={false}
	                                    mode="multiple"
	                                    notFoundContent={suppliersLoading ? <Spin size="small" /> : null}
	                                    onChange={this.handleSupplierChange}
	                                    onSearch={(value) => this.onSearchSupplier(value)}
	                                    placeholder={i18n.t('suppliers:selectSupplierName')}
	                                    showArrow={false}
	                                    style={{ width: '240px' }}
                                        showSearch>
	                                    {this.renderSupplier()}
	                                </Select>
	                            </Form.Item>
	                        </Content>
	                        <Content>
                                <Button key="all"
                                    className={this.activeAllUploadInvoice(defaultSortUploadInvoice)}
                                    onClick={(e) => this.handleSortingUploadInvoice(e)}>
									ALL
                                </Button>
                                <Button key="pending"
                                    className={this.checkActiveUploadInvoice(defaultSortUploadInvoice, "pending")}
                                    onClick={(e) => this.handleSortingUploadInvoice(e, "pending")}>
									PV PENDING
                                </Button>
                                <Button key="in_progress"
                                    className={this.checkActiveUploadInvoice(defaultSortUploadInvoice, "in_progress")}
                                    onClick={(e) => this.handleSortingUploadInvoice(e, "in_progress")}>
									PV IN PROGRESS
                                </Button>
                                <Button key="received_inv"
                                    className={this.checkActiveUploadInvoice(defaultSortUploadInvoice, "received_inv")}
                                    onClick={(e) => this.handleSortingUploadInvoice(e, "received_inv")}>
									PV RECEIVED INV
                                </Button>
	                        </Content>
	                    </Layout>
	                </Sider>
	                <Layout className="text-right">
	                    <Content>
                            <Form.Item label={
                                <Select style={{ width: 200 }}
                                    onChange={(value) => this.handleDateTypeChange(value)}
                                    value={dateType}>
                                    <Select.Option value="date_of_purchase">
                                        {i18n.t('suppliers:searchByPurchaseDate')}
                                    </Select.Option>
                                    <Select.Option value="issue_date">
                                        {i18n.t('suppliers:searchByIssueDate')}
                                    </Select.Option>
                                </Select>
                            }>
                                <DatePicker.RangePicker allowClear
                                    value={defaultDateOfPurchase}
                                    onChange={this.handleDateChange}
                                    style={{ textAlign: 'center' }} />
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item label={i18n.t('vatDocuments:pvAmount')}>
                                        <Input prefix="฿" style={{ width: '100px' }}
                                            allowClear
                                            defaultValue={defaultAmountMin}
                                            formatter={value => `${value}฿`}
                                            parser={value => value.replace('฿', '')}
                                            onBlur={(e) => this.handleFilterAmountMin(e.target.value)}
                                            />
                                        <span> - </span>
                                        <Input prefix="฿" style={{ width: '100px' }}
                                            allowClear
                                            defaultValue={defaultAmountMax}
                                            formatter={value => `${value}฿`}
                                            parser={value => value.replace('฿', '')}
                                            onBlur={(e) => this.handleFilterAmountMax(e.target.value)}
                                            />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                <Form.Item label={i18n.t('vatDocuments:applyDate')} style={{ marginLeft: '10px' }}>
                                    <MonthPicker allowClear
                                        format={'MMMM YYYY'}
                                        defaultValue={defaultApplyDate}
                                        placeholder="Select month"
                                        onChange={this.handlePeriodChange} />
                                </Form.Item>
                                </Col>
                            </Row>
	                    </Content>
	                    <Content>
	                        <Button className={this.activeAllApplyDate(defaultSortApplyDate)}
                                onClick={(e) => this.handleSortingApplyDate(e)}>
                                ALL
                            </Button>
	                        <Button className={this.checkActiveApplyDate(defaultSortApplyDate, "true")}
                                onClick={(e) => this.handleSortingApplyDate(e, "true")}
                                key="true">
                                USE
                            </Button>
	                        <Button className={this.checkActiveApplyDate(defaultSortApplyDate, "false")}
                                onClick={(e) => this.handleSortingApplyDate(e, "false")}
                                key="false">
                                NOT USE
                            </Button>
	                    </Content>
	                </Layout>
	            </Layout>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    expandedRowRender={expandedRowRender}
                    loading={loading}
                    onChange={this.handleTableChange}
                    pagination={{ ...tablePagination, showSizeChanger: false }}
                    rowKey={dataSource => dataSource.id}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }} />
	        </VatDocumentsFilterWrapper>
	    )
    }
}

const mapStateToProps = (state) => ({
    vatDocuments: state.get('vatDocuments'),
    paymentVouchersEntities: state.getIn(['Entities', 'paymentVouchers']),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchSupplier,
        setFilterDate,
        setPagination
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['suppliers', 'vatDocuments'])(withRouter(VatDocumentsFilter))
)
