const actions = {
    STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_REQUEST: 'STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_REQUEST',
    STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS: 'STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS',
    STAFF_PROFILES_FETCH_NOTIFICATIONS_REQUEST: 'STAFF_PROFILES_FETCH_NOTIFICATIONS_REQUEST',
    STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS: 'STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS',
    STAFF_PROFILES_FETCH_REQUEST: 'STAFF_PROFILES_FETCH_REQUEST',
    STAFF_PROFILES_FETCH_SUCCESS: 'STAFF_PROFILES_FETCH_SUCCESS',
    STAFF_PROFILES_RECEIVE_NOTIFICATION_REQUEST: 'STAFF_PROFILES_RECEIVE_NOTIFICATION_REQUEST',
    STAFF_PROFILES_RESET: 'STAFF_PROFILES_RESET',
    STAFF_PROFILES_SET_LOADING: 'STAFF_PROFILES_SET_LOADING',
    STAFF_PROFILES_SET_NOTIFICATIONS_LOADING: 'STAFF_PROFILES_SET_NOTIFICATIONS_LOADING',
    STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION: 'STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION',
    STAFF_PROFILES_SET_NOTIFICATIONS_SAVING: 'STAFF_PROFILES_SET_NOTIFICATIONS_SAVING',
    STAFF_PROFILES_SET_SAVING: 'STAFF_PROFILES_SET_SAVING',
    STAFF_PROFILES_UPDATE_NOTIFICATIONS_REQUEST: 'STAFF_PROFILES_UPDATE_NOTIFICATIONS_REQUEST',
    STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS: 'STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS',
    STAFF_PROFILES_UPDATE_REQUEST: 'STAFF_PROFILES_UPDATE_REQUEST',
    STAFF_PROFILES_UPDATE_SUCCESS: 'STAFF_PROFILES_UPDATE_SUCCESS',

    defaultFetchNotificationsParams: () => ({ page: 1, per: 20, order: 'created_at DESC', isRead: '' }),

    fetchCurrentStaffNotifications: (args = {}) => ({
        type: actions.STAFF_PROFILES_FETCH_NOTIFICATIONS_REQUEST,
        payload: { ...actions.defaultFetchNotificationsParams(), ...args }
    }),

    fetchMoreCurrentStaffNotifications: (args = {}) => ({
        type: actions.STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_REQUEST,
        payload: { ...actions.defaultFetchNotificationsParams(), ...args }
    }),

    receiveNotification: (notification) => ({
        type: actions.STAFF_PROFILES_RECEIVE_NOTIFICATION_REQUEST,
        payload: { notification }
    }),

    updateNotification: (notificationId, notificationParams) => ({
        type: actions.STAFF_PROFILES_UPDATE_NOTIFICATIONS_REQUEST,
        payload: { notificationId, notificationParams }
    }),

    fetchCurrentStaffProfile: () => ({
        type: actions.STAFF_PROFILES_FETCH_REQUEST
    }),

    updateCurrentStaffProfile: (staffParams) => ({
        type: actions.STAFF_PROFILES_UPDATE_REQUEST,
        payload: { staffParams }
    })
}

export default actions
