import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Button, Form, Input, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const RequestItemImport = ({
    i18n,
    onSubmit
}) => {
    const [form] = Form.useForm()

    const handleSubmit = values => {
        const newValues = {
            ...values,
            file: _.get(values, 'file', []).map((file) => _.get(file, 'originFileObj', null))[0]
        }
        onSubmit({ params: { request_item_index: newValues } })
    }

    const formItemLayout = {
        labelCol: {
            span: 2
        },
        wrapperCol: {
            span: 16
        }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 6, offset: 0 },
            sm: { span: 4, offset: 2 }
        }
    }

    const normFile = e => {
        let fileList = [...e.fileList]
        fileList = fileList.slice(-1)
        return fileList
    }

    return (
        <div>
            <h2>{i18n.t('confirms:requestItem.importRequestItem')}</h2>
            <Form onFinish={handleSubmit} {...formItemLayout}>
                <Form.Item key="note" label={i18n.t('confirms:requestItem.note')}
                    name='note'>
                        <Input.TextArea placeholder="" />
                </Form.Item>

                <Form.Item
                    name='file'
                    getValueFromEvent={normFile}
                    valuePropName='fileList'
                    label={i18n.t('confirms:requestItem.selectFile')}
                    extra="excel or csv file"
                >
                        <Upload
                            beforeUpload={() => { return false }}
                            listType="text"
                        >
                            <Button>
                                <UploadOutlined /> Click to upload
                            </Button>
                        </Upload>
                </Form.Item>

                <Form.Item
                    key="actions"
                    {...formItemLayoutWithOutLabel}
                >
                    <Button
                        htmlType="submit"
                        type="primary"
                        disabled= {(form.isFieldTouched('file') === undefined) && (form.isFieldTouched('note') === undefined)}
                    >
                        {i18n.t('confirms:requestItem.upload')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

RequestItemImport.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    saving: PropTypes.bool
}

RequestItemImport.defaultProps = {
    onSubmit: () => {}
}

export default withTranslation('confirms')(RequestItemImport)
