import React, { Component, createRef } from 'react'
import { withTranslation } from 'react-i18next'

import { Form, Button, Upload, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import EditorContainer from '../../containers/Editor/EditorContainer'

class OrderSupplierNoteForm extends Component {
    constructor (props) {
        super(props)

        this.state = {
            fileList: props.imageDefault(),
            supplierOrderSelected: props.supplierOrderIdDefault
        }

        this.formRef = createRef()
    }

    onSubmit = (values) => {
        const { handleSubmit, isShowSupplierOrders } = this.props
        const { supplierOrderSelected } = this.state

        if (isShowSupplierOrders) {
            const params = { ...values, supplier_order_ids: supplierOrderSelected }
            handleSubmit(params)
        } else {
            handleSubmit(values)
        }
    }

    handleChangeImage = (e) => {
        this.setState({ fileList: e.fileList })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    imageHandler = (file, imgBase64) => {
        const { fileList } = this.state
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }
        this.formRef.current.setFieldsValue({ images_attributes: fileList.concat(newItemOfFileList) })
        this.handleChangeImage({ fileList: fileList.concat(newItemOfFileList), File: newItemOfFileList })
    }

    renderFileList (images) {
        const { imageEntities } = this.props

        const fileList = images.map((file) => {
            let url = imageEntities.getIn([file.url, 'thumbBlob'])
            url = url || file.url

            if (url) {
                return {
                    uid: file.uid,
                    url: url
                }
            }

            return file
        })

        return fileList
    }

    renderSupplierOrders () {
        const { i18n, supplierOrderList } = this.props
        const { supplierOrderSelected } = this.state

        const columns = [{
            title: i18n.t('orders:orderSupplierNote.refCode'),
            dataIndex: 'ref_code'
        }, {
            title: i18n.t('orders:orderSupplierNote.supplier'),
            dataIndex: 'supplier_name'
        }, {
            title: i18n.t('orders:orderSupplierNote.status'),
            dataIndex: 'state_label'
        }, {
            title: i18n.t('orders:orderSupplierNote.grandTotal'),
            dataIndex: ['grand_total', 'display']
        }]

        const rowSelection = {
            onChange: (selectedRowKeys) => {
                this.setState({
                    supplierOrderSelected: selectedRowKeys
                })
            },
            selectedRowKeys: supplierOrderSelected
        }

        return (
            <div style={{ marginBottom: '16px' }}>
                <Table columns={columns}
                    dataSource={supplierOrderList.toJS()}
                    rowSelection={rowSelection}
                    pagination={false}
                    rowKey="id" />
            </div>
        )
    }

    render () {
        const { i18n, saving, orderSupplierNote, isShowSupplierOrders } = this.props
        const { fileList } = this.state

        return (
            <Form onFinish={this.onSubmit} ref={this.formRef}>
                <h3>{i18n.t('orders:orderSupplierNote.title')}</h3>

                <Form.Item label={i18n.t('orders:orderSupplierNote.note')}
                    name='body'
                    initialValue={orderSupplierNote.get('body')}>
                        <EditorContainer theme="snow"
                            placeholder="พิมพ์ข้อความ, วางไฟล์ภาพ เพื่ออัพโหลดรูป"
                            showToolbar={false}
                            imageHandler={this.imageHandler} />
                </Form.Item>

                <Form.Item label={i18n.t('orders:orderSupplierNote.uploadTitle')}
                    name='images_attributes'
                    initialValue={fileList}
                    getValueFromEvent={this.normFile}>
                        <Upload
                            listType="picture-card"
                            showUploadList={{ showPreviewIcon: false }}
                            fileList={this.renderFileList(fileList)}
                            onChange={this.handleChangeImage}
                            multiple
                            beforeUpload={() => false}>
                            <div>
                                <PlusOutlined />
                                <div className="ant-upload-text">Upload</div>
                            </div>
                        </Upload>
                </Form.Item>

                {isShowSupplierOrders ? this.renderSupplierOrders() : null }

                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={saving}>
                        {i18n.t('shared:save')}
                    </Button>
                </div>
            </Form>
        )
    }
}

export default withTranslation('orders')(OrderSupplierNoteForm)
