import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import _ from 'lodash'

import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import actionsNotification from '../../notifications/actions'
import { orderPaymentDetailInstallmentSchema, orderPaymentDetailInstallmentsSchema } from '../../schema'

const { setNotifications } = actionsNotification
const {
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_REQUEST,
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING,
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_REQUEST,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL,
} = actions

const orderPaymentDetailInstallmentPath = (orderId, id) => {
    const basePath = `/orders/${orderId}/order_payment_detail_installments`

    if (!id) {
        return basePath
    }
    return `${basePath}/${id}`
}

export function *saveOrderPaymentDetailInstallmentSaga () {
    yield takeLatest(ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING })

        const { orderId, id, params, onSuccess, onError } = payload

        const path = orderPaymentDetailInstallmentPath( orderId, id)
        const method = id ? 'PATCH' : 'POST'
        const body = JSON.stringify({ order_payment_detail_installment: params })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS,
                    ...normalize(formattedData, orderPaymentDetailInstallmentSchema)
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        }
    })
}


export function *fetchOrderPaymentDetailInstallmentSaga () {
    yield takeEvery(ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING })

        const { orderId } = payload
        const path = orderPaymentDetailInstallmentPath(orderId)

        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderPaymentDetailInstallmentsSchema)

            yield put({
                type: ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS,
                ...normalizedData,
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchOrderPaymentDetailInstallmentSaga),
        fork(saveOrderPaymentDetailInstallmentSaga)
    ])
}
