import { Map, List } from 'immutable'
import actions from './actions'

const {
    NOTIFICATIONS_PUSH_NOTIFICATION,
    NOTIFICATIONS_SHIFT_NOTIFICATION
} = actions

const initState = new Map({
    // notifications is an list of notification object that has structure look like below
    // {
    //      header: 'Title message of notification'
    //      description: 'Description of notification'
    //      typeAlert: 'type of notification can be success, info, warning or error'
    // }
    notifications: new List()
})

export default function notificationsReducer (state = initState, action) {
    const notifications = state.get('notifications')

    switch (action.type) {
        case NOTIFICATIONS_PUSH_NOTIFICATION:
            return state.merge({
                notifications: notifications.push({
                    header: action.header,
                    description: action.description,
                    typeAlert: action.typeAlert
                })
            })
        case NOTIFICATIONS_SHIFT_NOTIFICATION:
            return state.merge({ notifications: notifications.shift() })
        default:
            return state
    }
}
