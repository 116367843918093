import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImageContainer from './ImageContainer'
import ImageWithActionsComponent from '../../components/images/ImageWithActions'

const ImageRenderer = ImageContainer(ImageWithActionsComponent)

class ImageWithActions extends Component {
    static propTypes = {
        actions: PropTypes.arrayOf(PropTypes.node),
        contentType: PropTypes.string,
        isImageBlob: PropTypes.bool,
        noticeText: PropTypes.array,
        originalUrl: PropTypes.string,
        thumbUrl: PropTypes.string.isRequired
    }

    static defaultProps = {
        isImageBlob: true
    }

    render () {
        return (
            <ImageRenderer {...this.props} />
        )
    }
}

export default ImageWithActions
