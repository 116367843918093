import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import ProfitSharingsIndexContainer from './ProfitSharingsIndexContainer'

class ProfitSharingIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'PS | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/profit_sharings', name: 'PS' }
                    ]}>
                    <ContentLayout>
                      <ProfitSharingsIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default ProfitSharingIndexPage
