import React, { useRef, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { DirectUpload } from '@rails/activestorage'
import jsCookie from 'js-cookie'

import { Upload, Button, Form, Row, Col } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import TextInput from './TextInput'

import apiConfig from '../../../../config/apiConfig'

const DIRECT_UPLOAD_URL = `${apiConfig.apiBase}/api/v1/active_storages/direct_uploads`
const TOKEN = jsCookie.get('token')

const UPDATE_MESSAGE_QUERY = gql`
  mutation CreateLineMessageChannel($channelId: ID!, $message: String, $imageParams: AdminClientLineMessageImageInput) {
    createLineMessageChannel(channelId: $channelId, message: $message, imageParams: $imageParams) {
      clientLineMessage {
        id
      }
    }
  }
`

const DELETE_ACTIVE_STORAGE_BLOBS = gql`
  mutation DeleteActiveStorageBlob ($blobIds: [ID!]) {
    deleteActiveStorageBlob (blobIds: $blobIds) {
      deleteBlobIds
    }
  }
`

const MessageContainerForm = ({id}) => {
  const textInputRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [blobs, setBlobs] = useState([])
  const [form] = Form.useForm()

  const [createLineMessageChannel] = useMutation(UPDATE_MESSAGE_QUERY, {
		onCompleted: () => {
      form.resetFields()
      setFileList([])
      setBlobs([])
		}
	})

  const [deleteActiveStorageBlob] = useMutation(DELETE_ACTIVE_STORAGE_BLOBS, {
    onCompleted: ({ deleteActiveStorageBlob })=> {
      const { deleteBlobIds } = deleteActiveStorageBlob
      setBlobs(blobs.filter(blob => !deleteBlobIds.includes(`${blob.id}`) ))
    }
  })

  const onUploadImageCustomRequest = (args) => {
    const { file, onSuccess, onError = () => {} } = args

    initActiveStorageBlobs([file], {
      onSuccess: () => onSuccess('Ok'),
      onError: () => onError()
    })

    return {
        abort () {}
    }
  }

  const initActiveStorageBlobs = async (acceptedFiles, { onSuccess, onError }) => {
    const activeStorageBlobs = []

    acceptedFiles.map((file) => {
      const upload = new DirectUpload(file, DIRECT_UPLOAD_URL, TOKEN)

      upload.create((error, blob) => {
        if (error) {
          console.error(error)
          onError()
        } else {
          activeStorageBlobs.push({ ...blob, file })
          setBlobs([...blobs, ...activeStorageBlobs])
          onSuccess()
        }
      })
    })
  }

  const onDeleteImage = (file) => {
    const fileList = form.getFieldValue('image_attachment')

    const index = fileList.findIndex((fileItem) => fileItem.uid === file.uid)

    if (blobs[index]) {
      deleteActiveStorageBlob({ variables: { blobIds: [blobs[index].id] } })
    }
  }

  const normFile = e => {
    if (Array.isArray(e)) return e

    return e && e.fileList
  }

  const handleSubmitForm = () => {
    const values = form.getFieldsValue()
    if (!values.message && blobs.length < 1) return null

    const params = {
      message: values.message || null,
      imageParams: {
        blobIds: blobs.map(b => b.id)
      }
    }

    createLineMessageChannel({ variables: { channelId: id, ...params } })
  }

  return (
    <div>
      <Form form={form} onFinish={handleSubmitForm}>
        <TextInput ref={textInputRef}
          setBlobs={setBlobs}
          blobs={blobs}
          form={form}
          textAreaFieldName='message'
          attachmentFieldName={'image_attachment'}
          onUploadImages={initActiveStorageBlobs}
          fileList={fileList}
          setFileList={setFileList} />
          <Row>
            <Col span={16}>
              <Form.Item>
                <Button htmlType='submit'>กดส่ง</Button>
              </Form.Item>
            </Col>
            <Col span={8} style={{ textAlign: 'end' }}>
              <Form.Item
                name='image_attachment'
                getValueFromEvent={normFile}
                valuePropName='fileList'>
                  <Upload
                    onChange={(e) => setFileList( e.fileList)}
                    listType="picture"
                    multiple
                    accept="image/*"
                    onRemove={onDeleteImage}
                    customRequest={onUploadImageCustomRequest}
                    fileList={fileList}
                  >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
              </Form.Item>
            </Col>
          </Row>
      </Form>
    </div>
  )
}

export default MessageContainerForm
