import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SelectAddress from '../../containers/SelectAddress'
import { Input } from 'antd'
import { withTranslation } from 'react-i18next'

import AddressFormWrapper from './addressFormWrapper.style'
const PurchaseOrderAddressForm = ({
    i18n,
    onPhoneNumberChange,
    onSelectAddressChange,
    onShippingDetailChange,
    value
}) => {
    return (
        <AddressFormWrapper>
            <div className="purchase_order_form_component">
                <SelectAddress
                    onChange={onSelectAddressChange}
                    postalCodeVisible
                    value={{
                        province_id: _.get(value, 'purchase_order_address_attributes.province_id', ''),
                        district_id: _.get(value, 'purchase_order_address_attributes.district_id', ''),
                        postal_code: _.get(value, 'purchase_order_address_attributes.postal_code', '')
                    }}
                />

                {/* Phone Number */}
                <div className="phone-number-input">
                    <label className="phone_number">{i18n.t('order.phoneNumber')} :</label>
                    <Input
                        onChange={onPhoneNumberChange}
                        placeholder={i18n.t('order.phoneNumber')}
                        value={_.get(value, 'purchase_order_address_attributes.phone_number', '')}
                    />
                </div>

                {/* Shipping Detail */}
                <div className="shipping-detail-input">
                    <label className="shipping_detail">{i18n.t('order.shippingDetail')} :</label>
                    <Input
                        onChange={onShippingDetailChange}
                        placeholder={i18n.t('order.shippingDetail')}
                        value={_.get(value, 'purchase_order_address_attributes.shipping_detail', '')}
                    />
                </div>
            </div>
        </AddressFormWrapper>
    )
}

PurchaseOrderAddressForm.propTypes = {
    onPhoneNumberChange: PropTypes.func,
    onSelectAddressChange: PropTypes.func,
    onShippingDetailChange: PropTypes.func,
    value: PropTypes.object
}

PurchaseOrderAddressForm.defaultProps = {
    onPhoneNumberChange: () => {},
    onSelectAddressChange: () => {},
    onShippingDetailChange: () => {},
    value: {
        send_by_supplier: false,
        purchase_order_address_attributes: {
            id: '',
            district_id: '',
            province_id: '',
            phone_number: '',
            postal_code: '',
            shipping_detail: '',
            _destroy: false
        }
    }
}

export default withTranslation(['common'])(PurchaseOrderAddressForm)

