import { Map, List } from 'immutable'
import actions from './actions'
import concatNoDup from '../../../helpers/concatNoDup'

const {
    STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_FETCH_SUCCESS,
    STAFF_PROFILES_RESET,
    STAFF_PROFILES_SET_LOADING,
    STAFF_PROFILES_SET_NOTIFICATIONS_LOADING,
    STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION,
    STAFF_PROFILES_SET_NOTIFICATIONS_SAVING,
    STAFF_PROFILES_SET_SAVING,
    STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_UPDATE_SUCCESS
} = actions

const initState = new Map({
    currentStaffId: '',
    loading: false,
    notifications: new Map({
        items: new List(),
        loading: false,
        pagination: new Map({
            totalPages: 0
        }),
        saving: false
    }),
    saving: false
})

export default function userReducer (state = initState, action) {
    const result = action.result
    const notificationItems = state.getIn(['notifications', 'items'])


    switch (action.type) {
        case STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS:
            return state.merge({
                notifications: new Map({
                    items: concatNoDup(notificationItems, result)
                })
            })
        case STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS:
            return state.merge({ notifications: new Map({ items: new List(action.result) }) })
        case STAFF_PROFILES_FETCH_SUCCESS:
            return state.mergeDeep({ currentStaffId: result })
        case STAFF_PROFILES_RESET:
            return initState
        case STAFF_PROFILES_SET_LOADING:
            return state.merge({ loading: action.loading })
        case STAFF_PROFILES_SET_NOTIFICATIONS_LOADING:
            return state.mergeDeep({ notifications: { loading: action.loading } })
        case STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION:
            return state.mergeDeep({ notifications: { pagination: action.pagination } })
        case STAFF_PROFILES_SET_NOTIFICATIONS_SAVING:
            return state.mergeDeep({ notifications: { saving: action.saving } })
        case STAFF_PROFILES_SET_SAVING:
            return state.merge({ saving: action.saving })
        case STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS:
            return state.mergeDeep({
                notifications: new Map({
                    items: new List(notificationItems.includes(result) ? notificationItems : notificationItems.unshift(result))
                })
            })
        case STAFF_PROFILES_UPDATE_SUCCESS:
            return state.mergeDeep({ currentStaffId: result })
        default:
            return state
    }
}
