import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'

import { Tabs, Row, Col, Input, Select, Divider } from 'antd'

import CustomerWalletsListContainer from '../../containers/WalletSummaries/CustomerWalletsListContainer'
import WalletsListContainer from '../../containers/WalletSummaries/WalletsListContainer'

const WalletSummariesIndex = ({
    isApproved,
    onChangeApproveWallet,
    onChangeFilterApprove,
    searchWallets,
    walletIds,
    walletLoading,
    walletPagination,
    i18n
}) => {
    return (
        <Tabs defaultActiveKey="customer">
            <Tabs.TabPane tab={i18n.t('walletSummaries:customers.title')} key="customer">
                <CustomerWalletsListContainer showSearch />
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t('walletSummaries:wallets.title')} key="customerWallet">
                <Row>
                    <Col span={16}>
                        <Input.Search
                            placeholder="input search text"
                            onSearch={((query) => searchWallets({ query }))}
                            enterButton />
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        {`${i18n.t('walletSummaries:wallets.filterApproved')}: `}
                        <Select
                            value={isApproved}
                            onChange={onChangeFilterApprove}
                            style={{ width: '50%' }}
                        >
                            <Select.Option value="">
                                {i18n.t('walletSummaries:wallets.all')}
                            </Select.Option>
                            <Select.Option value="true">
                                {i18n.t('walletSummaries:wallets.approved')}
                            </Select.Option>
                            <Select.Option value="false">
                                {i18n.t('walletSummaries:wallets.notApproved')}
                            </Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Divider />
                <WalletsListContainer
                    hiddenColumns={['actions']}
                    loading={walletLoading}
                    onChangeApprove={onChangeApproveWallet}
                    pagination={walletPagination}
                    searchWallets={searchWallets}
                    walletIds={walletIds}
                />
            </Tabs.TabPane>
        </Tabs>
    )
}

WalletSummariesIndex.propTypes = {
    isApproved: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    onChangeApproveWallet: PropTypes.func,
    onChangeFilterApprove: PropTypes.func,
    searchWallets: PropTypes.func,
    walletIds: ImmutablePropTypes.list,
    walletLoading: PropTypes.bool,
    walletPagination: PropTypes.object
}

export default withTranslation('walletSummaries')(WalletSummariesIndex)
