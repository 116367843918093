import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { Table } from 'antd'

const CustomerOrderPaymentDetailsList = ({
    i18n,
    loading,
    onPageChange,
    pagination,
    orderPaymentDetails
}) => {
    const columns = [
        {
            title: i18n.t('orderPaymentDetails:transferDate'),
            dataIndex: 'transfer_date',
            render: (transferDate) => transferDate ? moment(transferDate).format('DD-MM-YYYY') : null
        },
        {
            title: i18n.t('orderPaymentDetails:amount'),
            dataIndex: ['amount', 'display']
        },
        {
            title: i18n.t('orderPaymentDetails:bankStatementNumber'),
            dataIndex: 'bank_statement_number'
        },
        {
          title: i18n.t('orderPaymentDetails:bank'),
          dataIndex: ['bank_account', 'bank_name']
        },
        {
          title: i18n.t('orderPaymentDetails:accountNumber'),
          dataIndex: ['bank_account', 'number']
        },
        {
          title: i18n.t('orderPaymentDetails:branch'),
          dataIndex: ['bank_account', 'branch']
        },
        {
          title: i18n.t('orderPaymentDetails:paymentMethod'),
          dataIndex: 'payment_method',
          render: (paymentMethod) => {
            if (!paymentMethod) return null
            return (
              <div>{i18n.t(`orderPaymentDetails:${paymentMethod}`)}</div>
            )
          }
        }
      ]

    return (
        <Table
            columns={columns}
            dataSource={orderPaymentDetails}
            loading={loading}
            onChange={onPageChange}
            pagination={pagination}
            rowKey="id"
        />
    )
}

CustomerOrderPaymentDetailsList.propTypes = {
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    orderPaymentDetails: PropTypes.array,
}

CustomerOrderPaymentDetailsList.defaultProps = {
    loading: false,
    orderPaymentDetails: [],
}

export default withTranslation('orderPaymentDetails')(CustomerOrderPaymentDetailsList)
