import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import {
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Table,
    Typography,
    Button
} from 'antd'

import OrderInvoicesWrapper from './orderInvoices.style'

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        }
                    ]}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    )
}

const DepositInvoiceForm = ({
    i18n,
    invoiceId,
    invoices,
    loading,
    onSubmitForm,
    selectedInvoices,
    setSelectedInvoices,
    toggleForm,
    setOrderBusinessAddressInvoices
}) => {
    const [form] = Form.useForm()
    const [data, setData] = useState(invoices)
    const [editingKey, setEditingKey] = useState('')
    const isEditing = (record) => record.id === editingKey

    const edit = (record) => {
        form.setFieldsValue({
            grandTotal: '',
            ...record
        })
        setEditingKey(record.id)
    }
    const cancel = () => {
        setEditingKey('')
    }
    const save = async (key) => {
        try {
            const row = await form.validateFields()
            const newData = [...data]
            const index = newData.findIndex((item) => key === item.id)
            if (index > -1) {
                const item = newData[index]
                newData.splice(index, 1, {
                    ...item,
                    ...row
                })
                setData(newData)
                setEditingKey('')
            } else {
                newData.push(row)
                setData(newData)
                setEditingKey('')
            }
            setOrderBusinessAddressInvoices(newData)
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const columns = [
        {
            key: 'invoice-no',
            dataIndex: 'invoiceNo',
            editable: false
        },
        {
            key: 'grand-total',
            dataIndex: 'grandTotal',
            editable: true
        },
        {
            key: 'operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record.id)}
                            style={{
                                marginRight: 8
                            }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link
                        disabled={editingKey !== ''}
                        onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                )
            }
        }
    ]

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                editing: isEditing(record)
            })
        }
    })

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedInvoices(selectedRowKeys)
        },
        selectedRowKeys: selectedInvoices,
        getCheckboxProps: (record) => ({
            disabled: record.id === invoiceId
        })
    }

    return (
        <OrderInvoicesWrapper>
            <h4>{i18n.t('confirms:accounting.deposit')}</h4>

            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell
                        }
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName='editable-row'
                    pagination={null}
                    rowSelection={rowSelection}
                    rowKey='id'
                    loading={loading}
                />

                <div className='action-btn-section'>
                    <Button type='primary' onClick={onSubmitForm}>
                        {i18n.t('shared:save')}
                    </Button>
                    <Button onClick={() => toggleForm()}>
                        {i18n.t('shared:close')}
                    </Button>
                </div>
            </Form>
        </OrderInvoicesWrapper>
    )
}

DepositInvoiceForm.propTypes = {
    invoiceId: PropTypes.string,
    invoices: PropTypes.array,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    selectedInvoices: PropTypes.array,
    setSelectedInvoices: PropTypes.func.isRequired,
    setOrderBusinessAddressInvoices: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired
}

export default withTranslation('confirms')(DepositInvoiceForm)
