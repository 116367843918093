import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    CUSTOMER_TRUCK_IMAGES_FETCH,
    CUSTOMER_TRUCK_IMAGES_FETCH_SUCCESS,
    CUSTOMER_TRUCK_IMAGES_SET_PAGINATION,
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    errors: new Map()
})

export default function customerProfitSharingsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case CUSTOMER_TRUCK_IMAGES_FETCH:
            return state.merge({ loading: true })
        case CUSTOMER_TRUCK_IMAGES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CUSTOMER_TRUCK_IMAGES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        default:
            return state
    }
}
