import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Avatar, Button, Popconfirm, Table } from 'antd'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import StaffsWithCallCenterSelector from '../../../staffsWithCallCenterSelector'

import { MEMBER_AVATAR_PROPS } from '../../../../constants/tickets'

const ADD_TICKET_MEMBER = gql`
    mutation AddTicketMember($id: ID!, $staffId: ID!, $role: String!){
        addTicketMember(id: $id, staffId: $staffId, role: $role){
            ticket {
                id
            }
            errors
        }
    }
`

const REMOVE_TICKET_MEMBER = gql`
    mutation RemoveTicketMember($id: ID!, $staffId: ID!, $role: String!){
        removeTicketMember(id: $id, staffId: $staffId, role: $role){
            ticket {
                id
            }
            errors
        }
    }
`

const getColumns = (i18n, onRemove, role) => [{
    key: 'avatar',
    width: '50px',
    render: record => <Avatar style={MEMBER_AVATAR_PROPS['member']}>{record.firstName}</Avatar>
}, {
    key: 'name',
    dataIndex: 'fullName'
}, {
    key: 'remove',
    width: '40px',
    render: record => (
        <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title={i18n.t('shared:confirmDelete')}
            okText={i18n.t('shared:delete')}
            cancelText={i18n.t('shared:close')}
            onConfirm={() => onRemove(record, role)}
        >
            <Button icon={<DeleteOutlined />} type="link" danger />
        </Popconfirm>
    )
}]

const TicketMembersForm = ({ id, memberStaffs, onClose, onSuccess }) => {
    const { i18n } = useTranslation('tickets')
    const memberSelector = useRef()
    const [addTicketMember, { loading: saving }] = useMutation(ADD_TICKET_MEMBER, {
        onCompleted: () => {
            onSuccess()
            memberSelector.current.clear()
        }
    })
    const [removeTicketMember, { loading: deleting }] = useMutation(REMOVE_TICKET_MEMBER, {
        onCompleted: () => onSuccess()
    })

    const onAddStaff = ({ value } = {}, role) => {
        if (!value) return null

        addTicketMember({ variables: { id, staffId: value, role } })
    }

    const onRemoveStaff = (memberStaff, role) => {
        removeTicketMember({ variables: { id, staffId: memberStaff.id, role } })
    }

    return (
        <div>
            <h3>{i18n.t('tickets:edit')}</h3>
            <StaffsWithCallCenterSelector
                onChange={value => onAddStaff(value, 'member')}
                ref={memberSelector}
                style={{ width: '100%' }} />
            <Table
                columns={getColumns(i18n, onRemoveStaff, 'member')}
                dataSource={memberStaffs}
                loading={saving || deleting}
                pagination={false}
                rowKey="id"
                showHeader={false}
                size="small"
                tableLayout="fixed" />
            <div style={{ marginTop: '16px', textAlign: 'center' }}>
                <Button onClick={onClose}>{i18n.t('shared:close')}</Button>
            </div>
        </div>
    )
}

TicketMembersForm.propTypes = {
    id: PropTypes.string.isRequired,
    memberStaffs: PropTypes.array,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
}

TicketMembersForm.defaultProps = {
    memberStaffs: [],
    onClose: () => {},
    onSuccess: () => {}
}

export default TicketMembersForm
