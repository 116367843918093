import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Authorization } from '../../containers/Authorization'
import _ from 'lodash'

import {
    Table,
    Input,
    Modal,
    Form,
    Button
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import SelectOrderContainer from '../../containers/SelectOrder'
import SelectSearchSupplierOrderContainer from '../../containers/SelectSearchSupplierOrder'
import SelectSearchOrderPaymentDetailContainer from '../../containers/SelectSearchOrderPaymentDetail'
import SelectSearchPaymentVoucherContainer from '../../containers/SelectSearchPaymentVoucher'
import TruckOwnerSelectContainer from '../../containers/Trucks/TruckOwnerSelectContainer'

const BankStatementsTable = ({
    bankStatements,
    onShowModal,
    onShowRvPvModal,
    onShowJournalEntryModal,
    isShowModal,
    isShowRvPvModal,
    isShowJournalEntryModal,
    modalRecordType,
    loading,
    onChange,
    pagination,
    form,
    changingOrder,
    onSubmitBankStatement,
    currentBankStatementId,
    transactionType,
    defaultSelect,
    defaultJournalEntry,
    i18n,
    isShowTruckOwnerModal,
    onShowTruckOwnerModal,
    currentTruckOwnerId,
    onChangeTruckOwnerId,
    truckOwnerId
}) => {
    const columns = [{
        key: 'id',
        title: i18n.t('bankStatements:id'),
        dataIndex: 'number'
    },{
        key: 'account-number',
        title: i18n.t('bankStatements:accountNumber'),
        dataIndex: 'account_number'
    },
    {
        key: 'date',
        title: i18n.t('bankStatements:date'),
        dataIndex: ['date', 'display']
    }, {
        key: 'transaction-code',
        title: i18n.t('bankStatements:transactionCode'),
        dataIndex: 'transaction_code'
    }, {
        key: 'channel',
        title: i18n.t('bankStatements:channel'),
        dataIndex: 'channel'
    }, {
        key: 'cheque-number',
        title: i18n.t('bankStatements:chequeNumber'),
        dataIndex: 'cheque_number'
    }, {
        key: 'debit-amount',
        title: i18n.t('bankStatements:debitAmount'),
        dataIndex: ['debit_amount', 'display']
    }, {
        key: 'credit-amount',
        title: i18n.t('bankStatements:creditAmount'),
        dataIndex: ['credit_amount', 'display']
    }, {
        key: 'balance-sign',
        title: i18n.t('bankStatements:balanceSign'),
        dataIndex: 'balance_sign'
    }, {
        key: 'balance',
        title: i18n.t('bankStatements:balance'),
        render: (value) => {
            return (
                <div>
                    <Authorization
                        allowedRoles={['super_admin']}
                        redirectWhenNotAllowed={false}
                    >
                        {value.balance_amount.display}
                    </Authorization>
                </div>
            )
        }
    }, {
        key: 'description',
        title: i18n.t('bankStatements:description'),
        dataIndex: 'description'
    },
    {
        key: 'order-number',
        title: i18n.t('bankStatements:orderNumber'),
        render: (record) => {
            const type = record.type
            if (type === "order_type") {
                const orderNumber = record.order_number
                return (
                    <div>
                        <Link href={`/inquiries/${record.order_number}`}>
                            {orderNumber}
                        </Link>
                        <a style={{ marginLeft: '12px' }}
                            onClick={() => onShowModal(!isShowModal, record.id, orderNumber, null, type)}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            } else if (type === "supplier_order_type") {
                const supplierOrderRefCode = record.supplier_order_ref_code
                return (
                    <div>
                        <div>{supplierOrderRefCode}</div>
                        <a style={{ marginLeft: '12px' }}
                            onClick={() => onShowModal(!isShowModal, record.id, supplierOrderRefCode, null, type)}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            } else {
                return (
                    <a style={{ marginLeft: '12px' }}
                        onClick={() => onShowModal(!isShowModal, record.id, null, record.credit_amount.raw, type)}>
                        <EditOutlined />
                    </a>
                )
            }
        }
    },
    {
        key: 'rv-pv-number',
        title: i18n.t('bankStatements:rv/pvNumber'),
        render: (record) => {
            const type = record.type
            const orderNumber = record.order_number
            if (type === "order_type") {
                const receiptVouchers = record.receipt_vouchers
                return (
                    <div>
                        <div>
                            {receiptVouchers.map((receiptVoucher) => {
                                return (
                                    <div key={`rv_${receiptVoucher.external_id}`}>
                                        {receiptVoucher.external_id}
                                    </div>
                                )
                            })}
                        </div>
                        <a style={{ marginLeft: '12px' }}
                            onClick={() => onShowRvPvModal(!isShowRvPvModal, record.id, orderNumber, record.credit_amount.raw, type, record.receipt_vouchers)}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            } else if (type === "supplier_order_type") {
                const supplierOrderRefCode = record.supplier_order_ref_code
                const paymentVouchers = record.payment_vouchers
                return (
                    <div>
                        {paymentVouchers.map((paymentVoucher) => {
                            return (
                                <div key={`pv_${paymentVoucher.external_id}`}>
                                    {paymentVoucher.external_id}
                                </div>
                            )
                        })}
                        <a style={{ marginLeft: '12px' }}
                            onClick={() => onShowRvPvModal(!isShowRvPvModal, record.id, supplierOrderRefCode, record.credit_amount.raw, type, record.payment_vouchers)}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            } else {
                return (
                    <a style={{ marginLeft: '12px' }}
                        onClick={() => onShowRvPvModal(!isShowRvPvModal, record.id, null, record.credit_amount.raw, type, [])}>
                        <EditOutlined />
                    </a>
                )
            }
        }
    },{
            key: 'chart_of_account',
            title: i18n.t('bankStatements:chartOfAccount'),
            dataIndex: 'chart_of_account'
    },{
            key: 'journal_entry_format_name',
            title: i18n.t('bankStatements:journalEntry'),
            dataIndex: 'journal_entry_format_name',
            render: (name, record) => {
                return (
                    <div>
                        <div>{name}</div>
                        <a style={{ marginLeft: '8px' }}
                            onClick={() => onShowJournalEntryModal(!isShowJournalEntryModal, record.id)}>
                            <EditOutlined />
                        </a>
                    </div>
                )
            }
    }, {
            key: 'truck-owner',
            title: i18n.t('bankStatements:truckOwner'),
            render: (record) => {
                const type = record.type
                if (type === "supplier_order_type") {
                    const truckOwnerName = _.get(record, 'truck_owner_name', null)
                    return (
                        <div>
                            {truckOwnerName}
                            <a style={{ marginLeft: '12px' }}
                               onClick={() => onShowTruckOwnerModal(!isShowTruckOwnerModal, record.id, _.get(record, 'truck_owner.id', null))}>
                                <EditOutlined />
                            </a>
                        </div>
                    )
                }
            }
    }]

    const onSubmit = (values) => {
        const params = {
            order_id: _.get(values, 'order_id', null),
            supplier_order_id: _.get(values, 'supplier_order_id', null),
            id: currentBankStatementId,
            journal_entry_format_name: _.get(values, 'journal_entry_format_name', '')
        }
        onSubmitBankStatement(params)
    }

    const onSubmitRvPv = (values) => {
        const params = {
            order_payment_detail_ids: _.get(values, 'order_payment_detail_ids', []),
            payment_voucher_ids: _.get(values, 'payment_voucher_ids', []),
            id: currentBankStatementId
        }
        onSubmitBankStatement(params)
    }

    const renderOrderSelectForm = () => {
        return (
            <Form onFinish={onSubmit}>
                <Form.Item
                    name='order_id'
                    initialValue={changingOrder || ''}
                    label={i18n.t('bankStatements:fillOrderNumber')}
                >
                        <SelectOrderContainer
                            style={{ width: '100%' }}
                        />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const renderJournalEntryEditForm = () => {
        return (
            <Form onFinish={onSubmit}>
                <Form.Item
                    name='journal_entry_format_name'
                    initialValue={defaultJournalEntry || ''}
                    label={i18n.t('bankStatements:journalEntry')}
                >
                       <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const onSubmitTruckOwner = (e) => {
        e.preventDefault()

        const params = {
            truck_owner_id: truckOwnerId || null,
            id: currentBankStatementId
        }
        onSubmitBankStatement(params)
    }


    const renderSelectTruckOwner = () => {
        return (
            <div>
                <div>{i18n.t('bankStatements:addTruckOwner')}</div>
                <TruckOwnerSelectContainer
                    onChange={(truckOwnerId) => onChangeTruckOwnerId(truckOwnerId)}
                    value={{
                        truck_owner_id: currentTruckOwnerId
                    }}
                />
            </div>
        )
    }

    const renderSupplierOrderSelectForm = () => {
        return (
            <Form onFinish={onSubmit}>
                <Form.Item
                    name='supplier_order_id'
                    initialValue={changingOrder || ''}
                    label={i18n.t('bankStatements:fillSupplierOrderRedCord')}
                >
                        <SelectSearchSupplierOrderContainer
                            style={{ width: '100%' }}
                        />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const renderRvSelectForm = () => {
        return (
            <Form onFinish={onSubmitRvPv}>
                <Form.Item
                    name='order_payment_detail_ids'
                    initialValue={_.map(defaultSelect, 'id') || ''}
                    label={i18n.t('bankStatements:addRv')}
                >
                        <SelectSearchOrderPaymentDetailContainer
                            defaultSelect={defaultSelect}
                            style={{ width: '100%' }}
                        />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const renderPvSelectForm = () => {
        return (
            <Form onFinish={onSubmitRvPv}>
                <Form.Item
                    name='payment_voucher_ids'
                    initialValue={_.map(defaultSelect, 'id') || ''}
                    label={i18n.t('bankStatements:addPv')}
                >
                        <SelectSearchPaymentVoucherContainer
                            defaultSelect={defaultSelect}
                            style={{ width: '100%' }}
                        />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                </Form.Item>
            </Form>
        )
    }

    const editBankStatementOrderModal = () => {
        if (modalRecordType === "order_type") {
            return (
                renderOrderSelectForm()
            )
        }

        if (modalRecordType === "supplier_order_type") {
            return (
                renderSupplierOrderSelectForm()
            )
        }
    }

    const editBankStatementRvAndPvModal = () => {
        if (modalRecordType === "order_type") {
            return (
                renderRvSelectForm()
            )
        }

        if (modalRecordType === "supplier_order_type") {
            return (
                renderPvSelectForm()
            )
        }
    }

    return (
        <div>
            <Table
                columns={columns}
                dataSource={bankStatements}
                loading={loading}
                onChange={onChange}
                pagination={pagination}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1080 }}
            />
            <Modal
                style={{
                    height: '100px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                visible={isShowModal}
                onCancel={() => onShowModal(false, null, null, null, null)}
                closable={false}
                footer={null}
            >
                {editBankStatementOrderModal()}
            </Modal>
            <Modal
                style={{
                    height: '100px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                visible={isShowRvPvModal}
                onCancel={() => onShowRvPvModal(false, null, null, null, null, [])}
                closable={false}
                footer={null}
            >
                {editBankStatementRvAndPvModal()}
            </Modal>
            <Modal
                style={{
                    height: '100px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                visible={isShowJournalEntryModal}
                onCancel={() => onShowJournalEntryModal(false, null)}
                closable={false}
                footer={null}
            >
                {renderJournalEntryEditForm()}
            </Modal>

            <Modal
                style={{
                    height: '100px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                visible={isShowTruckOwnerModal}
                onCancel={() => onShowTruckOwnerModal(false)}
                closable={false}
                footer={[
                    <Button
                        onClick={(e) => onSubmitTruckOwner(e)}
                        type="primary" htmlType="submit">
                        {i18n.t('bankStatements:submit')}
                    </Button>
                ]}
                destroyOnClose
            >
                {renderSelectTruckOwner()}
            </Modal>
        </div>
    )
}

BankStatementsTable.propTypes = {
    bankStatements: PropTypes.array,
    onShowModal: PropTypes.func,
    isShowModal: PropTypes.bool,
    isShowRvPvModal: PropTypes.bool,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })
}

BankStatementsTable.defaultProps = {
    bankStatements: [],
    onShowModal: () => {},
    isShowModal: false,
    isShowRvPvModal: false,
    loading: false,
    onChange: () => {},
    pagination: {
        current: 1,
        pageSize: 20,
        total: 0,
        position: 'both'
    }
}

export default withTranslation(['bankStatements'])(BankStatementsTable)
