import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload
} from 'antd'
import {
  DeleteOutlined,
  CalculatorOutlined,
  PlusSquareTwoTone,
  EditOutlined,
  SaveOutlined,
  UploadOutlined,
  EditTwoTone
} from '@ant-design/icons'
import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'
import AddItemsForm from '../Items/AddItemsForm'
import DiscountForm from './DiscountForm'
import InquiryLineItems from '../../components/inquiry/lineItems'
import IssueTaxInvoiceForm from './IssueTaxInvoiceForm'
import OrderConditionsForm from './OrderConditionsForm'
import OrderScbPaymentConfirmListContainer from '../ScbPaymentConfirms/OrderScbPaymentConfirmListContainer'
import OrderSupplierNotesContainer from './OrderSupplierNotes'
import PaymentDetailsContainer from './PaymentDetailsContainer'
import PaymentForm from './PaymentForm'
import ProfitSharings from './ProfitSharings'
import ReservationCard from '../TruckReservations/ReservationContainer'
import ReservationForm from './TruckReservations/TruckReservationForm'
import ScmNoteFormContainer from '../OrderTracking/ScmNoteForm'
import SendTaxInvoiceMailsContainer from './SendTaxInvoiceMails'
import ShippingCostForm from './ShippingCostForm'
import TransferForm from './TransferForm'
import OrderTransferBacks from './OrderTransferBacks'
import ProductGroupItemFormContainer from '../ProductGroups/ItemFormContainer'
import MegaHomeItemFormContainer from '../MegaHomeItems/MegaHomeItemFormContainer'
import InquiryItemsWrapper from './inquiryItems.style'

import inquiryActions from '../../redux/inquiry/actions'
import truckReservationActions from '../../redux/truckReservations/actions'
import itemTruckImageActions from '../../redux/items/truckImages/actions'
import productGroupActions from '../../redux/productGroups/actions'

import OrderCustomerWalletsList from './OrderCustomerWallets/OrderCustomerWalletsList'

import PickupPoint from '../../components/inquiry/pickupPoint'
import RequotationLalamove from '../../components/inquiry/requotationLalamove'
import SendDeliveree from '../../components/inquiry/sendDeliveree'
import SendLalamove from '../../components/inquiry/sendLalamove'
import TruckReservationDeliveree from '../../components/inquiry/truckReservationDeliveree'
import TruckReservationLalamove from '../../components/inquiry/truckReservationLalamove'
import HideShippingCost from './ShippingCosts/HideShippingCost'
import SelectCustomerCompanies from './SelectCustomerCompanies'

const {
  clearOrderLineItems,
  exportLineItemPdfDocument,
  fetchInquiry,
  fetchInquiryLineItems,
  importLineItemsFile,
  saveLeadDistribution,
  saveLineItemClipboard,
  saveOrderForm,
  saveOrderLineItems,
  setAddRequestItemForm,
  setLineItemExportingRequest,
  setRequestItemId,
  setShippingCost,
  setShippingCostWithLessTruckLoad,
  setShowConditionForm,
  setShowShippingCostForm,
  setTruckReservation,
  setVatIncluded,
  toggleMarginForm,
  toggleMarginPercentForm,
  setAddProductGroupItemForm,
  setAddMegaHomeItemForm,
  setLineItemFormErrors,
  setTruckReservationDeliveree,
  setTruckReservationLalamove
} = inquiryActions

const { fetchTruckReservations, setUpdatingId } = truckReservationActions
const { fetchOrderItemTruckImages } = itemTruckImageActions
const { setSelectedItemIds } = productGroupActions

const { Link, Text } = Typography

class InquiryItems extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    companyId: PropTypes.string,
    orderEntity: ImmutablePropTypes.map.isRequired
  }

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {
      fileList: []
    }

    this.addItemFormRef = createRef()
  }

  async componentDidMount() {
    const {
      fetchInquiryLineItems,
      fetchOrderItemTruckImages,
      fetchTruckReservations,
      id
    } = this.props
    await fetchInquiryLineItems(id)
    await fetchTruckReservations({ orderId: id })
    await fetchOrderItemTruckImages({ orderId: id })
  }

  getLineItem(id) {
    if (!id) return new Map()

    const { lineItemEntities, orderEntity } = this.props
    const lineItem = lineItemEntities.get(_.toString(id), new Map())
    return lineItem.merge({
      order: orderEntity
    })
  }

  getLineItems() {
    const { inquiry } = this.props

    return inquiry.get('lineItems', new List()).map((lineItemId) => {
      return this.getLineItem(lineItemId)
    })
  }

  handleAddLineItemByPasteNameClipboard = () => {
    const { saveLineItemClipboard } = this.props
    const clipBoard = navigator.clipboard

    clipBoard.readText().then((value) => {
      saveLineItemClipboard(value)
    })
  }

  handleCancelModal = () => {
    const { setAddRequestItemForm, setLineItemFormErrors } = this.props
    setAddRequestItemForm(false)
    setLineItemFormErrors()
  }

  handleChangePagination = (page) => {
    const { fetchInquiryLineItems, id } = this.props

    fetchInquiryLineItems(id, page.current)
  }

  handleClearOrderLineItem = () => {
    const { clearOrderLineItems, id } = this.props

    clearOrderLineItems(id)
  }

  handleCreateRequestItem = (values) => {
    const { saveOrderLineItems, id } = this.props

    values.cost = values.cost ? values.cost : 0
    saveOrderLineItems(id, values, null, {
      onSuccess: () => this.addItemFormRef.current.resetFields()
    })
  }

  handleLeadDistribution = () => {
    const { saveLeadDistribution, id } = this.props
    saveLeadDistribution(id)
  }

  handleEditCondition = (value) => {
    const { setShowConditionForm } = this.props

    setShowConditionForm(!value)
  }

  handleEditShippingCost = (value) => {
    const { setShowShippingCostForm } = this.props

    setShowShippingCostForm(!value)
  }

  handleRequestItemModal = () => {
    const { setAddRequestItemForm } = this.props

    setAddRequestItemForm(true)
  }

  handleProductGroupItemModal = () => {
    const { setAddProductGroupItemForm, inquiry } = this.props

    setAddProductGroupItemForm(!inquiry.get('showProductGroupItemForm'))
  }

  handleMegaHomeItemModal = () => {
    const { setAddMegaHomeItemForm, inquiry } = this.props

    setAddMegaHomeItemForm(!inquiry.get('showMegaHomeItemForm'))
  }

  handleSelectFile = (info) => {
    let fileList = info.fileList
    fileList = fileList.slice(-1)

    this.setState({ fileList })
  }

  handleSubmitShippingCost = () => {
    const { id, fetchInquiry, setShippingCost } = this.props

    setShippingCost(id, {
      onSuccess: () => fetchInquiry(id)
    })
  }

  handleSubmitShippingCostWithLessTruckLoad = () => {
    const { id, setShippingCostWithLessTruckLoad } = this.props
    setShippingCostWithLessTruckLoad(id)
  }

  handleSubmitMargin = (margin) => {
    const {
      toggleMarginPercentForm,
      toggleMarginForm,
      saveOrderForm,
      fetchInquiryLineItems
    } = this.props

    toggleMarginPercentForm(false)
    toggleMarginForm(false)

    const orderId = this.props.id
    saveOrderForm(orderId, margin, {
      onSuccess: () => {
        fetchInquiryLineItems(orderId)
      }
    })
  }

  handleToggleMarginForm = (value) => {
    this.props.toggleMarginForm(!value)
  }

  handleToggleMarginPercentForm = (value) => {
    this.props.toggleMarginPercentForm(!value)
  }

  handleUploadItemFile = () => {
    const { fileList } = this.state
    const { importLineItemsFile } = this.props
    const orderId = this.props.id

    const file = fileList[0].originFileObj
    importLineItemsFile(orderId, file)

    this.setState({
      fileList: []
    })
  }

  onChangeIncludeVat = (value) => {
    const { setVatIncluded, saveOrderForm, id } = this.props

    setVatIncluded(value.target.checked)

    const values = { vat_included: value.target.checked }
    saveOrderForm(id, values)
  }

  onEditPurchaseAsCompany = (e) => {
    const { saveOrderForm, id } = this.props
    const values = { purchase_as_company: e.target.checked }

    saveOrderForm(id, values)
  }

  toSelectID = (str) => {
    let parsed = parseInt(str, 10)
    if (isNaN(parsed) || str.length !== parsed.toString().length) {
      parsed = 0
    }
    return parsed === 0 ? null : parsed
  }

  onChangeNeedTaxInvAbb = (value) => {
    const { saveOrderForm, id } = this.props

    const values = { need_tax_inv_abb: value.target.checked }
    saveOrderForm(id, values)
  }

  onSubmitForm = (values, { onSuccess = () => {} } = {}) => {
    const { saveOrderForm, id, orderEntity } = this.props

    let file
    let valuesParams = {
      ...values,
      cs_id: this.toSelectID(values.cs_id),
      sale_id: this.toSelectID(values.sale_id),
      purchaser_id: this.toSelectID(values.purchaser_id)
    }
    Reflect.deleteProperty(valuesParams, 'paste_image')

    if (values['pickup_date']) {
      valuesParams['pickup_date'] = values['pickup_date'].format('YYYY-MM-DD')
    }

    if (
      _.has(values, 'credit_card_bank') ||
      _.has(values, 'installment_period')
    ) {
      valuesParams['credit_card_bank'] =
        values['credit_card_bank'] === undefined
          ? null
          : values['credit_card_bank']
      valuesParams['installment_period'] =
        values['installment_period'] === undefined
          ? null
          : values['installment_period']
    }

    if (values['order_shipping_address_attributes']) {
      if (
        values['order_shipping_address_attributes'][
          'order_shipping_address_images_attributes'
        ]
      ) {
        const imageValue =
          values['order_shipping_address_attributes'][
            'order_shipping_address_images_attributes'
          ]
        const orderShippingAddressImages =
          orderEntity.getIn([
            'order_shipping_address',
            'order_shipping_address_images'
          ]) || new List()
        let destroyImage = []
        let uploadImage = imageValue

        orderShippingAddressImages.map((image) => {
          if (
            !imageValue.filter((value) => value.uid === image.get('id')).length
          ) {
            destroyImage.push({ id: image.get('id'), _destroy: true })
          } else {
            uploadImage = uploadImage.filter(
              (value) => value.uid !== image.get('id')
            )
          }
        })

        file = uploadImage
          ? uploadImage
              .map((id) => {
                return {
                  file: id.originFileObj,
                  _destroy: false
                }
              })
              .concat(destroyImage)
          : null
        const imageParams = { order_shipping_address_images_attributes: file }
        valuesParams = {
          ...valuesParams,
          ...{
            order_shipping_address_attributes: {
              ...values['order_shipping_address_attributes'],
              ...imageParams
            }
          }
        }
      }

      saveOrderForm(id, valuesParams, { onSuccess })
    } else {
      saveOrderForm(id, valuesParams, { onSuccess })
    }
  }

  orderEditable = () => {
    const { orderBusinessAddressEntities, orderEntity, isCurrentStaffA } =
      this.props
    const orderBusinessAddresses = orderEntity.get(
      'order_business_addresses',
      new List()
    )
    const hasInvoiceNo = orderBusinessAddresses.filter(
      (orderBusinessAddress) => {
        const isEmptyInvoiceNo1 = _.isEmpty(
          orderBusinessAddress.get('invoice_no', '')
        )
        const isEmptyInvoiceNo2 = _.isEmpty(
          orderBusinessAddressEntities.getIn(
            [orderBusinessAddress.get('id'), 'invoice_no'],
            ''
          )
        )
        return !isEmptyInvoiceNo1 || !isEmptyInvoiceNo2
      }
    ).size

    if (!hasInvoiceNo || isCurrentStaffA('super_sale')) {
      return true
    }

    return false
  }

  toggleTruckReservationModal = (id = null) => {
    const { setTruckReservation, inquiry } = this.props
    const showTruckReservationModal = inquiry.get('showTruckReservationModal')

    setTruckReservation(!showTruckReservationModal, id)
  }

  toggleTruckReservationDelivereeModal = (id = null) => {
    const { setTruckReservationDeliveree, inquiry } = this.props
    const showTruckReservationDeliveree = inquiry.get(
      'showTruckReservationDeliveree'
    )

    setTruckReservationDeliveree(!showTruckReservationDeliveree, id)
  }

  toggleTruckReservationLalamoveModal = (id = null) => {
    const { setTruckReservationLalamove, inquiry } = this.props
    const showTruckReservationLalamove = inquiry.get(
      'showTruckReservationLalamove'
    )

    setTruckReservationLalamove(!showTruckReservationLalamove, id)
  }

  renderCondition = (orderEntity, editable) => {
    const { i18n, inquiry, orderConditionEntities } = this.props
    const showConditionForm = inquiry.get('showConditionForm')
    const conditions = orderEntity.get('order_conditions').toJS()

    if (conditions.length <= 0) {
      return (
        <div className='title'>
          {i18n.t('inquiries:orderConditions')} : -
          {this.renderEditIcon(
            this.handleEditCondition,
            editable,
            showConditionForm
          )}
          {showConditionForm
            ? this.editConditionForm(showConditionForm, orderEntity)
            : null}
        </div>
      )
    }

    const orderConditionsList = conditions.map((conditionId, index) => {
      const orderCondition = orderConditionEntities.get(`${conditionId}`)
      return {
        key: orderCondition.get('id'),
        body: orderCondition.get('body')
      }
    })

    const columns = [
      {
        title: i18n.t('inquiries:orderConditions'),
        dataIndex: 'body'
      }
    ]

    return (
      <div>
        <span className='title'>{i18n.t('inquiries:orderConditions')} </span>
        <span>
          {this.renderEditIcon(
            this.handleEditCondition,
            editable,
            showConditionForm
          )}
          {showConditionForm
            ? this.editConditionForm(showConditionForm, orderEntity)
            : null}
        </span>
        <Table
          size='small'
          columns={columns}
          dataSource={orderConditionsList}
          pagination={false}
          showHeader={false}
        />
      </div>
    )
  }

  conditionForm = (orderEntity, editable) => {
    const { inquiry } = this.props
    const showConditionForm = inquiry.get('showConditionForm')

    if (!showConditionForm) {
      return this.renderCondition(orderEntity, editable)
    } else {
      return this.editConditionForm(showConditionForm, orderEntity)
    }
  }

  editConditionForm = (showConditionForm, orderEntity) => {
    const { inquiry } = this.props
    const saving = inquiry.get('saveOrderLoading')

    return (
      <Card>
        <OrderConditionsForm
          orderEntity={orderEntity}
          orderId={orderEntity.get('id')}
          showConditionForm={showConditionForm}
          handleEditCondition={this.handleEditCondition}
          saving={saving}
        />
      </Card>
    )
  }

  renderTruckReservationSection = (id, truckReservations) => {
    const { i18n } = this.props

    if (truckReservations.length) {
      return (
        <div>
          จองแล้ว
          <span>
            <a onClick={() => this.toggleTruckReservationModal(id)}>
              <PlusSquareTwoTone />
            </a>
          </span>
        </div>
      )
    }
    return <a onClick={() => this.toggleTruckReservationModal(id)}>{i18n.t('inquiries:reserveTruck')}</a>
  }

  renderTruckReservationDeliveree = (id, trackingUrl) => {
    const { i18n } = this.props

    if (trackingUrl) {
      return (
        <a href={trackingUrl} target='_blank'>
          {this.props.i18n.t('inquiries:deliveree.trackingStatus')}
        </a>
      )
    }
    return (
      <a onClick={() => this.toggleTruckReservationDelivereeModal(id)}>{i18n.t('inquiries:reserveTruck')}</a>
    )
  }

  renderTruckReservationLalamove = (id, shareLink) => {
    const { i18n, inquiry, orderEntity, fetchInquiry } = this.props
    const orderId = orderEntity.get('id')
    const showShippingCostForm = inquiry.get('showShippingCostForm')

    if (shareLink) {
      return (
        <a href={shareLink} target='_blank'>
          {this.props.i18n.t('inquiries:lalamove.trackingStatus')}
        </a>
      )
    }

    return (
      <span>
        {this.renderEditIcon(
          this.handleEditShippingCost,
          true,
          showShippingCostForm
        )}
        {showShippingCostForm
          ? this.editShippingCostForm(showShippingCostForm, orderEntity)
          : null}
        <RequotationLalamove
          shippingCostId={id}
          orderId={orderId}
          fetchOrder={() => fetchInquiry(orderId)} />
        <a onClick={() => this.toggleTruckReservationLalamoveModal(id)}>{i18n.t('inquiries:reserveTruck')}</a>
      </span>
    )
  }

  renderShippingCostCalculateBtn = (orderEntity) => {
    const { i18n, fetchInquiry } = this.props
    const orderId = orderEntity.get('id')
    const editable = this.orderEditable()

    return (
      <Space className='calculation-button-section'>
        <SendDeliveree
          orderId={orderId}
          refetch={() => fetchInquiry(orderId)}
        />
        <SendLalamove orderId={orderId}
          refetch={() => fetchInquiry(orderId)}
        />
        <PickupPoint orderId={orderId} refetch={() => fetchInquiry(orderId)} />
        <Link
        onClick={this.handleSubmitShippingCost}
        disabled={!editable}
        >
          <CalculatorOutlined /> {i18n.t('inquiries:fullTruckLoadCalculation')}
        </Link>
        <Link
        onClick={this.handleSubmitShippingCostWithLessTruckLoad}
        disabled={!editable}
        >
          <CalculatorOutlined /> {i18n.t('inquiries:lessTruckLoadCalculation')}
        </Link>
      </Space>
    )
  }

  renderShippingCost = (orderEntity, editable) => {
    const { i18n, id, inquiry } = this.props
    const showShippingCostForm = inquiry.get('showShippingCostForm')
    const shippingCosts = orderEntity.get('shipping_costs').toJS()

    if (shippingCosts.length <= 0) {
      return (
        <div className='title'>
          {this.renderShippingCostCalculateBtn(orderEntity)}
          {i18n.t('inquiries:shippingCosts')} : -
          {orderEntity.get('shipping_type') === 'self_pickup' ||
          !orderEntity.get('order_shipping_address') ? (
            <div style={{ float: 'left' }}>{`(${i18n.t(
              'inquiries:pleaseInputShippingAddress'
            )})`}</div>
          ) : (
            this.renderEditIcon(
              this.handleEditShippingCost,
              editable,
              showShippingCostForm
            )
          )}
          {showShippingCostForm
            ? this.editShippingCostForm(showShippingCostForm, orderEntity)
            : null}
        </div>
      )
    }
    const columns = [
      {
        title: i18n.t('inquiries:shippingCost.vehicleType'),
        dataIndex: ['vehicle_type', 'name'],
        render: (dataIndex, record) => {
          return (
            <div>
              {i18n.t(`inquiries:${_.camelCase(dataIndex)}`)}
              {record.have_transfer_backs ? (
                <div>({i18n.t('inquiries:shippingCost.transferBack')})</div>
              ) : null}
            </div>
          )
        }
      },
      {
        title: i18n.t('inquiries:shippingCost.price'),
        dataIndex: ['money_display', 'single_price']
      },
      {
        title: i18n.t('inquiries:shippingCost.numberOfTrips'),
        dataIndex: 'quantity',
        render: (dataIndex, record) => {
          if (record.quantity_after_adjustment) {
            return (
              <>
                <Text delete>{dataIndex}</Text>
                <Tag
                  color={
                    dataIndex > record.quantity_after_adjustment
                      ? 'red'
                      : 'green'
                  }>
                  {record.quantity_after_adjustment}
                </Tag>
              </>
            )
          }

          return dataIndex
        }
      },
      {
        title: i18n.t('inquiries:shippingCost.cost'),
        dataIndex: ['money_display', 'cost']
      },
      {
        title: i18n.t('inquiries:shippingType'),
        dataIndex: 'shipping_type',
        render: (dataIndex) => {
          return <div>{i18n.t(`inquiries:${_.camelCase(dataIndex)}`)}</div>
        }
      },
      {
        title: i18n.t('inquiries:hide'),
        dataIndex: 'hide',
        render: (hide, record) => <HideShippingCost id={record.id} hide={hide} orderId={id} />
      },
      {
        key: 'action',
        dataIndex: 'id',
        render: (dataIndex, record) => {
          if (record.vendor_type === 'deliveree') {
            return this.renderTruckReservationDeliveree(
              dataIndex,
              record.deliveree_tracking_url
            )
          }

          if (record.vendor_type === 'lalamove') {
            return this.renderTruckReservationLalamove(
              dataIndex,
              record.lalamove_share_link
            )
          }

          return this.renderTruckReservationSection(
            dataIndex,
            record.truck_reservations
          )
        }
      }
    ]
    return (
      <div>
        <div>
          <span className='title'>{i18n.t('inquiries:shippingCosts')}</span>
          <span>
            {orderEntity.get('shipping_type') === 'self_pickup' ||
            !orderEntity.get('order_shipping_address') ? (
              <div>{`(${i18n.t('inquiries:pleaseInputShippingAddress')})`}</div>
            ) : (
              this.renderEditIcon(
                this.handleEditShippingCost,
                editable,
                showShippingCostForm
              )
            )}
            {showShippingCostForm
              ? this.editShippingCostForm(showShippingCostForm, orderEntity)
              : null}
          </span>
          {this.renderShippingCostCalculateBtn(orderEntity)}
        </div>
        <Table
          columns={columns}
          expandedRowRender={(record) =>
            this.renderTruckReservations(record.id, record.truck_reservations)
          }
          dataSource={this.getShippingCosts(orderEntity).toJS()}
          pagination={false}
          rowKey='id'
          size='small'
        />
      </div>
    )
  }

  getShippingCosts = (orderEntity) => {
    const { shippingCostEntities } = this.props
    const shippingCosts = orderEntity.get('shipping_costs', new List())
    return shippingCosts.map((shippingCostId) => {
      return shippingCostEntities.get(`${shippingCostId}`, new Map())
    })
  }

  renderTruckReservations = (id, truckReservations) => {
    const { i18n, setUpdatingId } = this.props

    return truckReservations.map((truckReservation) => {
      return (
        <div>
          <div>
            {i18n.t('truckReservation:truckType')}:{' '}
            {_.get(truckReservation, 'reservation_truck')}
          </div>
          <div>
            {i18n.t('truckReservation:shippingDate')}:{' '}
            {_.get(truckReservation, 'date')}
          </div>
          <div>
            {i18n.t('truckReservation:truckOwner')}:{' '}
            {_.get(truckReservation, 'truck_owner_name')}
          </div>
          <div>
            {i18n.t('truckReservation:truckOwnerPhoneNumber')}:{' '}
            {_.get(truckReservation, 'truck_owner_phone_number')}
          </div>
          <div>
            {i18n.t('truckReservation:licensePlateNumber')}:{' '}
            {_.get(truckReservation, 'truck_dispatch_license_plate')}
          </div>
          <div>
            {_.get(truckReservation, 'allow_update?', false) ? (
              <a
                onClick={() => {
                  this.toggleTruckReservationModal(id)
                  setUpdatingId({ updatingId: truckReservation.id })
                }}>
                <EditOutlined /> {i18n.t('customerForm.edit')}
              </a>
            ) : null}
          </div>
          <Divider style={{ margin: '4px 0' }} />
        </div>
      )
    })
  }

  shippingCostForm = (orderEntity, editable) => {
    const { inquiry } = this.props
    const showShippingCostForm = inquiry.get('showShippingCostForm')

    if (!showShippingCostForm) {
      return this.renderShippingCost(orderEntity, editable)
    } else {
      return this.editShippingCostForm(showShippingCostForm, orderEntity)
    }
  }

  editShippingCostForm = (showShippingCostForm, orderEntity) => {
    const { inquiry } = this.props

    return (
      <Card>
        <ShippingCostForm
          orderEntity={orderEntity}
          orderId={orderEntity.get('id')}
          showShippingCostForm={showShippingCostForm}
          handleEditShippingCost={this.handleEditShippingCost}
          errors={inquiry.get('formErrors')}
        />
      </Card>
    )
  }

  renderAddItemIcon(editable) {
    const { inquiry } = this.props
    const lineItemsLoading = inquiry.get('lineItemsLoading')
    const lineItemsSaving = inquiry.get('lineItemsSaving')
    const stateLoading = lineItemsLoading || lineItemsSaving

    const iconBtn = stateLoading ? <Spin size='small' /> : <SaveOutlined />

    return (
      <div className={'icon-add-item'}>
        <Button
          disabled={!editable || stateLoading}
          onClick={this.handleAddLineItemByPasteNameClipboard}>
          {iconBtn}
        </Button>
      </div>
    )
  }

  renderAddRequestItemForm = () => {
    const { inquiry } = this.props
    const showAddItemForm = inquiry.get('showAddItemForm')
    const formErrors = inquiry.get('lineItemformErrors')

    return (
      <Modal
        destroyOnClose
        onCancel={this.handleCancelModal}
        visible={showAddItemForm}
        width='70%'
        footer={null}>
        <Form onFinish={this.handleCreateRequestItem} ref={this.addItemFormRef}>
          <AddItemsForm
            handleCancelModal={this.handleCancelModal}
            form={this.addItemFormRef}
            errors={formErrors.toJS()}
          />
        </Form>
      </Modal>
    )
  }

  onCloseProductGroupItemForm = () => {
    const { setSelectedItemIds } = this.props

    this.handleProductGroupItemModal()
    setSelectedItemIds(new List())
  }

  renderProductGroupItemForm = () => {
    const { inquiry, id } = this.props
    const showProductGroupItemForm = inquiry.get('showProductGroupItemForm')

    return (
      <Modal
        destroyOnClose
        onCancel={this.onCloseProductGroupItemForm}
        footer={null}
        visible={showProductGroupItemForm}
        width='80%'>
        <ProductGroupItemFormContainer
          orderId={id}
          onSuccess={this.onCloseProductGroupItemForm}
        />
      </Modal>
    )
  }

  renderMegaHomeItemsForm = () => {
    const { inquiry, id } = this.props
    const showMegaHomeItemForm = inquiry.get('showMegaHomeItemForm')

    return (
      <Modal
        destroyOnClose
        onCancel={this.handleMegaHomeItemModal}
        footer={null}
        visible={showMegaHomeItemForm}
        width='80%'>
        <MegaHomeItemFormContainer orderId={id} />
      </Modal>
    )
  }

  renderBlockEditMargin(showMarginForm) {
    const { inquiry, orderEntity, i18n } = this.props
    const marginDisplay = orderEntity.getIn(['money_display', 'margin'])
    const loading = inquiry.get('saveOrderLoading', false)

    if (loading)
      return (
        <td>
          <Spin size='small' />
        </td>
      )

    if (!showMarginForm) {
      return (
        <td>
          {`${marginDisplay} ${i18n.t('baht')}`}
          {this.orderEditable() ? (
            <a
              style={{ marginLeft: '12px' }}
              onClick={() => this.handleToggleMarginForm(showMarginForm)}>
              <EditOutlined />
            </a>
          ) : null}
        </td>
      )
    } else {
      return (
        <td>
          <Input
            onBlur={() =>
              this.handleSubmitMargin({ margin: this.margin.state.value })
            }
            onPressEnter={() =>
              this.handleSubmitMargin({ margin: this.margin.state.value })
            }
            defaultValue={marginDisplay}
            ref={(margin) => {
              this.margin = margin
            }}
          />
        </td>
      )
    }
  }

  renderBlockPercentMargin(showMarginPercentForm) {
    const { inquiry, orderEntity } = this.props
    const marginPercent = orderEntity.get('margin_percent')
    const loading = inquiry.get('saveOrderLoading', false)

    if (loading)
      return (
        <td>
          <Spin size='small' />
        </td>
      )

    if (!showMarginPercentForm) {
      return (
        <td>
          {`${marginPercent} %`}
          {this.orderEditable() ? (
            <a
              style={{ marginLeft: '12px' }}
              onClick={() =>
                this.handleToggleMarginPercentForm(showMarginPercentForm)
              }>
              <EditOutlined />
            </a>
          ) : null}
        </td>
      )
    } else {
      return (
        <td>
          <Input
            onBlur={() =>
              this.handleSubmitMargin({
                margin_percent: this.marginPercent.state.value
              })
            }
            onPressEnter={() =>
              this.handleSubmitMargin({
                margin_percent: this.marginPercent.state.value
              })
            }
            defaultValue={marginPercent}
            ref={(marginPercent) => {
              this.marginPercent = marginPercent
            }}
          />
        </td>
      )
    }
  }

  renderClearLineItemsBtn(editable) {
    const { i18n, inquiry } = this.props
    const lineItemsLoading = inquiry.get('lineItemsLoading')
    const lineItemsSaving = inquiry.get('lineItemsSaving')
    const stateLoading = lineItemsLoading || lineItemsSaving

    const iconBtn = stateLoading ? <Spin size='small' /> : <DeleteOutlined />

    return (
      <div className={'icon-clear-line-item'}>
        <Tooltip title={i18n.t('inquiries:deleteOrderLineItems')}>
          <Popconfirm
            placement='bottomRight'
            disabled={!editable}
            title={i18n.t('inquiries:confirmDeleteLineItems')}
            onConfirm={this.handleClearOrderLineItem}>
            <Button type='danger' disabled={!editable || stateLoading}>
              {iconBtn}
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
    )
  }

  renderEditIcon = (onClick, editable, value) => {
    if (!editable) {
      return
    }
    return <EditTwoTone className='edit-icon' onClick={() => onClick(value)} />
  }

  renderExportLineItemBtn() {
    const { setLineItemExportingRequest, i18n, id, inquiry } = this.props
    const exporting = inquiry.get('lineItemExporting')

    return (
      <React.Fragment>
        <Button
          className='upload-submit-btn'
          onClick={() => setLineItemExportingRequest(id)}
          disabled={exporting}
          loading={exporting}>
          {i18n.t('inquiries:exportLineItem')}
        </Button>
      </React.Fragment>
    )
  }

  renderExportLineItemPdfBtn() {
    const { exportLineItemPdfDocument, i18n, id, inquiry } = this.props
    const exporting = inquiry.get('lineItemPdfExporting')

    return (
      <React.Fragment>
        <Button
          className='upload-submit-btn'
          onClick={() => exportLineItemPdfDocument(id)}
          disabled={exporting}
          loading={exporting}>
          {i18n.t('inquiries:exportLineItemPdf')}
        </Button>
      </React.Fragment>
    )
  }

  renderImportLineItemsBtn(editable) {
    const { fileList } = this.state
    const { i18n, inquiry } = this.props
    const uploading = inquiry.get('lineItemsUploading')
    const props = {
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file]
        }))
        return false
      },
      fileList,
      disabled: !editable
    }

    return (
      <React.Fragment>
        <div className={'select-file-line-item-btn'}>
          <Upload
            {...props}
            onChange={this.handleSelectFile}
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-excel'>
            <Tooltip title={i18n.t('inquiries:importOrderLineItems')}>
              <Button disabled={!editable}>
                {i18n.t('inquiries:selectFile')}
              </Button>
            </Tooltip>
          </Upload>
        </div>
        <Button
          className='upload-submit-btn'
          type='primary'
          onClick={this.handleUploadItemFile}
          disabled={!editable || fileList.length === 0}
          loading={uploading}>
          <UploadOutlined />
        </Button>
      </React.Fragment>
    )
  }

  renderIncludeVat = (orderEntity, editable) => {
    return (
      <Checkbox
        disabled={!editable}
        onChange={this.onChangeIncludeVat}
        defaultChecked={orderEntity.get('vat_included')}>
        Include Vat
      </Checkbox>
    )
  }

  renderPurchaseAsCompany = (orderEntity, editable) => {
    const { companyId, i18n } = this.props

    if (!companyId) {
      return null
    } else {
      return (
        <Checkbox
          disabled={!editable}
          defaultChecked={orderEntity.get('purchase_as_company')}
          onChange={(e) => this.onEditPurchaseAsCompany(e)}>
          {i18n.t('orders:purchaseAsCompany')}
        </Checkbox>
      )
    }
  }

  renderSelectCustomerCompanies = (orderEntity) => {
    const { companyId, customerCompanyIds, companyEntities, id } = this.props

    if (!companyId || !orderEntity.get('purchase_as_company')) {
      return null
    } else {
      const companies = customerCompanyIds.map((companyId) => {
        return companyEntities.get(companyId)
      }) || new List()

      return (
        <SelectCustomerCompanies companies={companies.toJS()}
          orderId={id} />
      )
    }
  }

  renderTruckReservationModal() {
    const { inquiry, id, fetchInquiry } = this.props

    return (
      <Modal
        title='Truck Reservation'
        className='truck-reservation-form-modal'
        footer={null}
        onCancel={() => this.toggleTruckReservationModal(null)}
        visible={inquiry.get('showTruckReservationModal')}
        destroyOnClose>
        <ReservationForm
          onSuccess={() => fetchInquiry(id)}
          orderId={id}
          shippingCostId={inquiry.get('shippingCostId')}
        />
      </Modal>
    )
  }

  renderTruckReservationDelivereeModal() {
    const { inquiry, id, fetchInquiry } = this.props

    return (
      <TruckReservationDeliveree
        showForm={inquiry.get('showTruckReservationDeliveree')}
        onClose={() => this.toggleTruckReservationDelivereeModal(null)}
        orderId={id}
        refetch={() => fetchInquiry(id)}
        shippingCostId={inquiry.get('shippingCostId')}
      />
    )
  }

  renderTruckReservationLalamoveModal() {
    const { inquiry, id, fetchInquiry } = this.props

    return (
      <TruckReservationLalamove
        showForm={inquiry.get('showTruckReservationLalamove')}
        onClose={() => this.toggleTruckReservationLalamoveModal(null)}
        orderId={id}
        refetch={() => fetchInquiry(id)}
        shippingCostId={inquiry.get('shippingCostId')}
      />
    )
  }

  renderNeedTaxInvoiceAbbCheck = () => {
    const { orderEntity, i18n } = this.props
    return (
      <Checkbox
        onChange={this.onChangeNeedTaxInvAbb}
        defaultChecked={orderEntity.get('need_tax_inv_abb')}>
        {i18n.t('inquiries:needTaxInvoiceAbb')}
      </Checkbox>
    )
  }

  render() {
    const { customerEntities, form, i18n, id, inquiry, orderEntity, fetchInquiry } =
      this.props
    const customerEntity =
      customerEntities.getIn([inquiry.get('currentCustomer')]) || new Map()
    const saving = inquiry.get('saveOrderLoading')
    const editable = this.orderEditable()

    return (
      <InquiryItemsWrapper>
        <nav>
          <ul className='doc-container'>
            <li>
              <Button
                style={{ marginLeft: '10px' }}
                type='primary'
                onClick={() => this.handleRequestItemModal()}
                disabled={!editable}
                size='small'>
                {i18n.t('inquiries:addNewItem')}
              </Button>
              {this.renderAddRequestItemForm()}
            </li>
            <li>
              <Button
                key='add_items'
                style={{ marginLeft: '10px' }}
                href={`/inquiries/${id}/items`}
                disabled={!editable}
                size='small'>
                {i18n.t('inquiries:addItemsPage')}
              </Button>
            </li>
            <li>
              <Button
                key='add_product_group_items'
                style={{ marginLeft: '10px' }}
                onClick={() => this.handleProductGroupItemModal()}
                disabled={!editable}
                size='small'>
                {i18n.t('inquiries:addProductGroupItem')}
              </Button>
              {this.renderProductGroupItemForm()}
            </li>
            <li className='left'>
              <Button
                key='add_mega_home_items'
                style={{ marginLeft: '10px' }}
                onClick={() => this.handleMegaHomeItemModal()}
                disabled={!editable}
                size='small'>
                {i18n.t('inquiries:addMegaHomeItems')}
              </Button>
              {this.renderMegaHomeItemsForm()}
            </li>
          </ul>
        </nav>
        <div className='line-items-btn'>
          {this.renderExportLineItemPdfBtn()}
          {this.renderExportLineItemBtn()}
          {this.renderImportLineItemsBtn(editable)}
          {this.renderAddItemIcon(editable, orderEntity)}
          {this.renderClearLineItemsBtn(editable)}
        </div>
        <InquiryLineItems
          orderId={id}
          lineItems={this.getLineItems()}
          editable={editable}
          refetchOrder={() => fetchInquiry(id)}
          handleChangePagination={this.handleChangePagination}
        />
        <Row gutter={8}>
          <Col xs={24} md={17}>
            {this.renderPurchaseAsCompany(orderEntity, editable)}
            {this.renderSelectCustomerCompanies(orderEntity)}
            <Card
              type='inner'
              bodyStyle={{ padding: 8 }}
              style={{ marginTop: 12 }}>
              <PaymentDetailsContainer id={id} disabled={!editable} />
              <OrderCustomerWalletsList orderId={id} />
              <OrderScbPaymentConfirmListContainer orderId={id} />
            </Card>
            <Card
              type='inner'
              bodyStyle={{ padding: 8 }}
              style={{ marginTop: 12 }}>
              <div style={{ fontWeight: 'bold' }}>
                {i18n.t('inquiries:truckingReservationDate')} :
              </div>
              <ReservationCard
                onToggle={this.toggleTruckReservationModal}
                orderNumber={id}
              />
              {orderEntity.get('shipping_type') === 'self_pickup' && (
                <a onClick={() => this.toggleTruckReservationModal()}>จองรถ</a>
              )}
              <div className='order-supplier-note-container'>
                <span style={{ fontWeight: 'bold' }}>
                  {i18n.t('orders:orderSupplierNote.title')} :
                </span>
                <OrderSupplierNotesContainer orderId={id} />
              </div>
            </Card>
            <Row gutter={8} style={{ marginTop: 12 }}>
              <Col xs={24} md={14}>
                <Card type='inner' bodyStyle={{ padding: 8 }}>
                  {this.shippingCostForm(orderEntity, editable)}
                  {this.renderTruckReservationModal()}
                  {this.renderTruckReservationDelivereeModal()}
                  {this.renderTruckReservationLalamoveModal()}
                </Card>
              </Col>
              <Col xs={24} md={10}>
                <Card type='inner' bodyStyle={{ padding: 8 }}>
                  {this.conditionForm(orderEntity, editable)}
                </Card>
                <Card type='inner' bodyStyle={{ padding: 8 }}>
                  <ScmNoteFormContainer
                    orderId={id}
                    orderState={orderEntity.get('state')}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={7}>
            <Card type='inner' bodyStyle={{ padding: 8 }}>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      {this.renderIncludeVat(orderEntity, editable)}
                    </td>
                  </tr>
                  <tr>
                    <td>{`${i18n.t('order.totalWeight')}:`}</td>
                    <td>{`${orderEntity.get('total_weight')}`}</td>
                  </tr>
                  <DiscountForm
                    orderDiscount={orderEntity.get('discount')}
                    displayDiscount={orderEntity.getIn([
                      'money_display',
                      'discount'
                    ])}
                    editable={editable}
                    onSubmitForm={this.onSubmitForm}
                  />
                  <tr>
                    <td>{`${i18n.t('order.subTotal')}:`}</td>
                    <td>
                      {`${orderEntity.getIn([
                        'money_display',
                        'subtotal'
                      ])} ${i18n.t('baht')}`}
                    </td>
                  </tr>
                  <tr>
                    <td>{`${i18n.t('order.vat')}:`}</td>
                    <td>
                      {`${orderEntity.getIn(['money_display', 'vat'])} ${i18n.t(
                        'baht'
                      )}`}
                    </td>
                  </tr>
                  <tr>
                    <td>{`${i18n.t('order.grandTotal')}:`}</td>
                    <td>
                      <div>{`${orderEntity.getIn([
                        'money_display',
                        'grand_total'
                      ])} ${i18n.t('baht')}`}</div>
                      {orderEntity.getIn([
                        'money_display',
                        'transfer_back_total'
                      ]) !== '0.00'
                        ? `(${orderEntity.getIn([
                            'money_display',
                            'transfer_back_total'
                          ])})`
                        : null}
                    </td>
                  </tr>
                  {orderEntity.get('payment_method') === 'credit_card' ? (
                    <React.Fragment>
                      <tr>
                        <td>{`${i18n.t('order.creditCardTotal', {
                          rate: orderEntity.get('credit_card_rate')
                        })}:`}</td>
                        <td>
                          {`${orderEntity.getIn([
                            'money_display',
                            'credit_card_total'
                          ])} ${i18n.t('baht')}`}
                        </td>
                      </tr>
                      <tr>
                        <td>{`${i18n.t(
                          'order.grandTotalWithCreditCard'
                        )}:`}</td>
                        <td>
                          {`${orderEntity.getIn([
                            'money_display',
                            'grand_total_with_credit_card'
                          ])} ${i18n.t('baht')}`}
                        </td>
                      </tr>
                    </React.Fragment>
                  ) : null}
                </tbody>
              </table>
            </Card>
            <Card bodyStyle={{ padding: '8px' }}>
              <div style={{ fontWeight: 'bold' }}>
                {i18n.t('inquiries:margin')}
              </div>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>{i18n.t('order.grandTotal')}:</td>
                    {this.renderBlockEditMargin(inquiry.get('showMarginForm'))}
                  </tr>
                  <tr>
                    <td>{i18n.t('inquiries:percent')}:</td>
                    {this.renderBlockPercentMargin(
                      inquiry.get('showMarginPercentForm')
                    )}
                  </tr>
                </tbody>
              </table>
            </Card>
            <OrderTransferBacks orderId={id} />
            <PaymentForm
              editable={true}
              id={id}
              orderEntity={orderEntity}
              onSubmitForm={this.onSubmitForm}
              form={form}
              payment={orderEntity.get('payment_method')}
              saving={saving}
            />
            <TransferForm orderId={id} />
            <ProfitSharings
              orderId={id}
              customerId={customerEntity.get('id')}
            />
            <Card bodyStyle={{ padding: '8px' }}>
              {this.renderNeedTaxInvoiceAbbCheck()}
              <IssueTaxInvoiceForm
                form={form}
                orderId={id}
                orderEntity={orderEntity}
                onSubmitForm={this.onSubmitForm}
                editable={editable}
                saving={saving}
                showForm={inquiry.get('showIssueTaxInvoiceForm')}
              />
              <SendTaxInvoiceMailsContainer
                defaultEmail={customerEntity.get('email')}
                orderId={id}
              />
            </Card>
          </Col>
        </Row>
      </InquiryItemsWrapper>
    )
  }
}
const mapStateToProps = (state) => ({
  inquiry: state.get('inquiry'),
  customerEntities: state.getIn(['Entities', 'customers']),
  lineItemEntities: state.getIn(['Entities', 'lineItems']),
  orderBusinessAddressEntities: state.getIn([
    'Entities',
    'orderBusinessAddresses'
  ]),
  orderConditionEntities: state.getIn(['Entities', 'orderConditions']),
  shippingCostEntities: state.getIn(['Entities', 'shippingCosts']),
  companyEntities: state.getIn(['Entities', 'customerCompanies']),
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearOrderLineItems,
      exportLineItemPdfDocument,
      fetchInquiry,
      fetchInquiryLineItems,
      fetchOrderItemTruckImages,
      fetchTruckReservations,
      importLineItemsFile,
      saveLeadDistribution,
      saveLineItemClipboard,
      saveOrderForm,
      saveOrderLineItems,
      setAddRequestItemForm,
      setLineItemExportingRequest,
      setRequestItemId,
      setShippingCost,
      setShippingCostWithLessTruckLoad,
      setShowConditionForm,
      setShowShippingCostForm,
      setTruckReservation,
      setUpdatingId,
      setVatIncluded,
      toggleMarginForm,
      toggleMarginPercentForm,
      setAddProductGroupItemForm,
      setAddMegaHomeItemForm,
      setLineItemFormErrors,
      setTruckReservationDeliveree,
      setSelectedItemIds,
      setTruckReservationLalamove
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(['inquiries', 'orders', 'truckReservation'])(
    currentStaffContainer(InquiryItems)
  )
)
