import { all, takeEvery, put, call, fork, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'
import actions from './actions'
import { staffSchema, notificationsSchema, notificationSchema } from '../../schema'
import notificationActions from '../../notifications/actions'

const {
    STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_REQUEST,
    STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_FETCH_NOTIFICATIONS_REQUEST,
    STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_FETCH_REQUEST,
    STAFF_PROFILES_FETCH_SUCCESS,
    STAFF_PROFILES_RECEIVE_NOTIFICATION_REQUEST,
    STAFF_PROFILES_SET_LOADING,
    STAFF_PROFILES_SET_NOTIFICATIONS_LOADING,
    STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION,
    STAFF_PROFILES_SET_NOTIFICATIONS_SAVING,
    STAFF_PROFILES_SET_SAVING,
    STAFF_PROFILES_UPDATE_NOTIFICATIONS_REQUEST,
    STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS,
    STAFF_PROFILES_UPDATE_REQUEST,
    STAFF_PROFILES_UPDATE_SUCCESS
} = actions

const {
    setNotifications
} = notificationActions

const staffProfilePath = '/staffs/me/profile'
const staffNotificationsPath = '/staffs/me/notifications'

function *fetchNotifications ({ payload }, fetchSuccessType) {
    yield put({ type: STAFF_PROFILES_SET_NOTIFICATIONS_LOADING, loading: true })

    try {
        const { page, per, order, isRead } = payload
        const path = staffNotificationsPath + `?page=${page}&per=${per}&order=${order}&isRead=${isRead}`

        const data = yield call(apiFetch, path)
        const deserializedData = deserialize(data)
        const normalizedData = normalize(deserializedData, notificationsSchema)

        yield put({ type: fetchSuccessType, ...normalizedData })
        yield put({ type: STAFF_PROFILES_SET_NOTIFICATIONS_PAGINATION, pagination: {
            totalPages: data.meta.total_pages
        } })
    } catch {
        return null
    } finally {
        yield put({ type: STAFF_PROFILES_SET_NOTIFICATIONS_LOADING, loading: false })
    }
}

export function *fetchCurrentStaffNotificationsRequest () {
    yield takeLatest(STAFF_PROFILES_FETCH_NOTIFICATIONS_REQUEST, function *(args) {
        yield fetchNotifications(args, STAFF_PROFILES_FETCH_NOTIFICATIONS_SUCCESS)
    })
}

export function *fetchMoreCurrentStaffNotificationRequest () {
    yield takeLatest(STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_REQUEST, function *(args) {
        yield fetchNotifications(args, STAFF_PROFILES_FETCH_MORE_NOTIFICATIONS_SUCCESS)
    })
}

export function *receiveNotification () {
    yield takeLatest(STAFF_PROFILES_RECEIVE_NOTIFICATION_REQUEST, function *({ payload }) {
        try {
            const { notification } = payload
            const deserializedData = deserialize(notification)
            const normalizedData = normalize(deserializedData, notificationSchema)

            yield put({ type: STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS, ...normalizedData })
        } catch (error) {
            yield put(setNotifications('Error Occurred', error, 'error'))
        }
    })
}

export function *updateCurrentStaffNotification () {
    yield takeLatest(STAFF_PROFILES_UPDATE_NOTIFICATIONS_REQUEST, function *({ payload }) {
        yield put({ type: STAFF_PROFILES_SET_NOTIFICATIONS_SAVING, saving: true })

        try {
            const { notificationId, notificationParams } = payload
            const path = staffNotificationsPath + `/${notificationId}`
            const body = JSON.stringify({ notification: notificationParams })

            const data = yield call(apiFetch, path, 'PATCH', { body })
            const deserializedData = deserialize(data)
            const normalizedData = normalize(deserializedData, notificationSchema)

            yield put({ type: STAFF_PROFILES_UPDATE_NOTIFICATIONS_SUCCESS, ...normalizedData })
        } catch (error) {
            yield put(setNotifications(error.name, error.message, 'error'))
        } finally {
            yield put({ type: STAFF_PROFILES_SET_NOTIFICATIONS_SAVING, saving: false })
        }
    })
}

export function *fetchCurrentStaffProfileRequest () {
    yield takeEvery(STAFF_PROFILES_FETCH_REQUEST, function *() {
        yield put({ type: STAFF_PROFILES_SET_LOADING, loading: true })

        try {
            const data = yield call(apiFetch, staffProfilePath)
            const deserializeData = deserialize(data)
            const normalizeData = normalize(deserializeData, staffSchema)

            yield put({ type: STAFF_PROFILES_FETCH_SUCCESS, ...normalizeData })
        } catch(error) {
          console.error('error:', error)
        } finally {
            yield put({ type: STAFF_PROFILES_SET_LOADING, loading: false })
        }
    })
}

export function *updateCurrentStaffProfileRequest () {
    yield takeLatest(STAFF_PROFILES_UPDATE_REQUEST, function *({ payload }) {
        yield put({ type: STAFF_PROFILES_SET_SAVING, saving: true })

        try {
            const body = JSON.stringify({ staff: payload.staffParams })
            const data = yield call(apiFetch, staffProfilePath, 'PATCH', { body })
            const deserializeData = deserialize(data)
            const normalizeData = normalize(deserializeData, staffSchema)

            yield put({ type: STAFF_PROFILES_UPDATE_SUCCESS, ...normalizeData })
        } catch {
            return null
        } finally {
            yield put({ type: STAFF_PROFILES_SET_SAVING, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCurrentStaffNotificationsRequest),
        fork(fetchCurrentStaffProfileRequest),
        fork(fetchMoreCurrentStaffNotificationRequest),
        fork(receiveNotification),
        fork(updateCurrentStaffNotification),
        fork(updateCurrentStaffProfileRequest)
    ])
}
