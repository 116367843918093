import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import moment from 'moment'

import i18n from 'i18next'

import supplierActions from '../../redux/suppliers/actions'

import PurchaseOrderStatusSwitches from '../../components/PurchaseOrderStatusSwitches'

const {
    saveSupplierOrders
} = supplierActions

class PurchaseOrderStatusSwitchesContainer extends Component {
    static propTypes = {
        // Props from another component
        supplierOrder: PropTypes.object.isRequired,

        // Inner Props
        saveSupplierOrders: PropTypes.func.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        // Object's keys are used
        supplierOrder: {
            id: '',
            order: '', // OSH...
            is_po_completed: false,
            is_checked: false,
            is_checked_history: {
                staff: {
                    first_name: '',
                    last_name: ''
                }
            },
            is_po_sent_to_supplier: false,
            state: '',
            transfer_date: undefined
        }
    }

    constructor (props) {
        super(props)

        moment.locale(i18n.language)

        const transferDate = _.get(props, 'supplierOrder.transfer_date', undefined)

        this.state = {
            creditDurationDays: 0,
            // transfer_date of supplier order is date_of_purchase of pv
            dateOfPurchase: (transferDate && moment(transferDate, 'YYYY-MM-DD')) || moment(new Date()),
            visibleCreditModal: false
        }
    }

    onCreditDurationDaysChange = (days) => {
        this.setState({ creditDurationDays: _.toNumber(days) || 0 })
    }

    onSwitchChange = (supplierOrderParams) => {
        const { saveSupplierOrders, supplierOrder } = this.props
        const orderId = _.get(supplierOrder, 'order', '')
        const id = _.get(supplierOrder, 'id', '')

        saveSupplierOrders({
            orderId,
            supplierOrder: supplierOrderParams,
            id,
            bankAccount: null
        })
    }

    onSubmitCreditForm = (supplierOrderParams, paymentVoucherParams) => {
        const { saveSupplierOrders, supplierOrder } = this.props
        const orderId = _.get(supplierOrder, 'order', '')
        const id = _.get(supplierOrder, 'id', '')

        saveSupplierOrders({
            orderId,
            supplierOrder: supplierOrderParams,
            id,
            paymentVoucher: paymentVoucherParams,
            onSuccess: () => {
                this.setState({ visibleCreditModal: false })
            }
        })
    }

    onToggleVisibleCreditModal = () => {
        const { visibleCreditModal } = this.state

        this.setState({ visibleCreditModal: !visibleCreditModal })
    }

    onDateOfPurchaseChange = (dateOfPurchaseMoment) => {
        this.setState({ dateOfPurchase: dateOfPurchaseMoment })
    }

    render () {
        const { suppliers, supplierOrder } = this.props
        const {
            creditDurationDays,
            dateOfPurchase,
            visibleCreditModal
        } = this.state

        return (
            <PurchaseOrderStatusSwitches
                creditDurationDays={creditDurationDays}
                dateOfPurchase={dateOfPurchase}
                loading={suppliers.get('saving', false)}
                onCreditDurationDaysChange={this.onCreditDurationDaysChange}
                onDateOfPurchaseChange={this.onDateOfPurchaseChange}
                onSubmitCreditForm={this.onSubmitCreditForm}
                onSwitchChange={this.onSwitchChange}
                onToggleVisibleCreditModal={this.onToggleVisibleCreditModal}
                supplierOrder={supplierOrder}
                visibleCreditModal={visibleCreditModal}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        suppliers: state.get('suppliers')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveSupplierOrders
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderStatusSwitchesContainer)
