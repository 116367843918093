import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isEmpty } from '../../helpers/objects'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { Button, Checkbox, Col, DatePicker, Divider, Input, Modal, Row, Select, Table } from 'antd'
import { PlusSquareTwoTone, PhoneOutlined,MessageOutlined } from '@ant-design/icons'

import { Authorization } from '../Authorization'
import SelectOrderStateContainer from '../SelectOrderState'
import SuppliersOrdersFormContainer from '../Suppliers/Orders/SuppliersOrdersFormContainer'
import SelectOrderTaskState from './SelectOrderTaskState'
import OrderSummariesWrapper from './OrderSummaries.style'

import lineLogo from '../../../public/images/base/line-logo.png'

import { LEAD_SOURCES } from '../../constants/orders'
import { parseParams } from '../../helpers/queryString'
import orderSummaryDashBoardActions from '../../redux/orders/summaryDashBoards/actions'
import orderTableActions from '../../redux/orders/tables/actions'
import provinceActions from '../../redux/provinces/actions'


const {
    fetchOrderSummaryDashboards,
    setDefaultDateTimeType,
    setOrderId,
    setSorter,
    setDateType,
    setSupplierOrderFormModal,
    setDefaultTimeRange
} = orderSummaryDashBoardActions
const { fetchSales, fetchCses, fetchPurchasers } = orderTableActions
const { fetchProvinces } = provinceActions

class OrderSummariesContainer extends Component {
    static defaultProps = {
        forceQuery: {},
        filterBy: {
            customer_id: null,
            company_project_id: null
        },
        hideDivider: false,
        hideFilter: false
    }

    componentDidMount () {
        const {
            forceQuery,
            history,
            location,
            fetchSales,
            fetchCses,
            fetchPurchasers,
            filterBy,
            fetchProvinces,
            fetchOrderSummaryDashboards
        } = this.props
        const queryStringObject = parseParams(location.search)

        history.push({
          search: location.search,
          params: queryStringObject
        })

        const queryString = {}
        _.mapKeys(queryStringObject, (value, key) => {
            queryString[_.camelCase(key)] = _.toString(value)
        })

        fetchOrderSummaryDashboards({
            ...queryString,
            ...forceQuery,
            filterBy
        })

        fetchProvinces()
        fetchSales('all')
        fetchCses('all')
        fetchPurchasers('all')
    }

    fetchOrderSummaries = ({
        page,
        query,
        state,
        bySale,
        byCs,
        byPurchaser,
        startDate,
        endDate,
        startReservationDate,
        endReservationDate,
        byLeadSource,
        sorter,
        isBigSales,
        isWaitingToShip,
        isWaitingForProduction,
        byPotentialLead,
        isMyOrders,
        isOnlinePaid,
        dateType,
        taskState,
        provinceId,
        startPaymentDate,
        endPaymentDate
    }) => {
        const { forceQuery, fetchOrderSummaryDashboards, orderSummaryDashBoards, filterBy } = this.props

        query = (query === undefined) ? orderSummaryDashBoards.get('query') : query
        state = (state === undefined) ? orderSummaryDashBoards.get('state') : state
        bySale = (bySale === undefined) ? orderSummaryDashBoards.get('bySale') : bySale
        byCs = (byCs === undefined) ? orderSummaryDashBoards.get('byCs') : byCs
        byPurchaser = (byPurchaser === undefined) ? orderSummaryDashBoards.get('byPurchaser') : byPurchaser
        byLeadSource = (byLeadSource === undefined) ? orderSummaryDashBoards.get('byLeadSource') : byLeadSource
        startDate = (startDate === undefined) ? orderSummaryDashBoards.get('startDate') : startDate
        endDate = (endDate === undefined) ? orderSummaryDashBoards.get('endDate') : endDate
        startReservationDate = (startReservationDate === undefined) ?
            orderSummaryDashBoards.get('startReservationDate') : startReservationDate
        endReservationDate = (endReservationDate === undefined) ?
            orderSummaryDashBoards.get('endReservationDate') : endReservationDate
        isBigSales = (isBigSales === undefined) ? orderSummaryDashBoards.get('isBigSales') : isBigSales
        byPotentialLead = (byPotentialLead === undefined) ? orderSummaryDashBoards.get('byPotentialLead') : byPotentialLead
        isWaitingToShip = (isWaitingToShip === undefined) ? orderSummaryDashBoards.get('isWaitingToShip') : isWaitingToShip
        isWaitingForProduction = (isWaitingForProduction === undefined) ? orderSummaryDashBoards.get('isWaitingForProduction') : isWaitingForProduction
        isMyOrders = (isMyOrders === undefined) ? orderSummaryDashBoards.get('isMyOrders') : isMyOrders
        isOnlinePaid = (isOnlinePaid === undefined) ? orderSummaryDashBoards.get('isOnlinePaid') : isOnlinePaid
        dateType = (dateType === undefined) ? orderSummaryDashBoards.get('dateType') : dateType
        taskState = (taskState === undefined) ? orderSummaryDashBoards.get('taskState') : taskState
        startPaymentDate = (startPaymentDate === undefined) ?
            orderSummaryDashBoards.get('startPaymentDate') : startPaymentDate
        endPaymentDate = (endPaymentDate === undefined) ?
            orderSummaryDashBoards.get('endPaymentDate') : endPaymentDate

        fetchOrderSummaryDashboards({
            page,
            query,
            state,
            bySale,
            byCs,
            provinceId,
            byPurchaser,
            startDate,
            endDate,
            startReservationDate,
            endReservationDate,
            byLeadSource,
            sorter,
            filterBy,
            isBigSales,
            byPotentialLead,
            isWaitingToShip,
            isWaitingForProduction,
            isMyOrders,
            isOnlinePaid,
            dateType,
            taskState,
            startPaymentDate,
            endPaymentDate,
            ...forceQuery
        })

        this.setQueryStringUrl({
            query,
            state,
            bySale,
            byCs,
            byPurchaser,
            startDate,
            endDate,
            startReservationDate,
            endReservationDate,
            byLeadSource,
            isBigSales,
            byPotentialLead,
            isWaitingToShip,
            isWaitingForProduction,
            isMyOrders,
            isOnlinePaid,
            taskState,
            startPaymentDate,
            endPaymentDate
        })
    }

    setQueryStringUrl ({
        query,
        state,
        bySale,
        byCs,
        byPurchaser,
        startDate,
        endDate,
        startReservationDate,
        endReservationDate,
        byLeadSource,
        isBigSales,
        byPotentialLead,
        isWaitingToShip,
        isWaitingForProduction,
        isMyOrders,
        isOnlinePaid,
        taskState,
        startPaymentDate,
        endPaymentDate
    } = {}) {
        const { forceQuery, history, match } = this.props

        const newForceQuery = _.mapKeys(forceQuery, (value, key) => _.snakeCase(key))

        const newRouterQuery = _.omit({
            query: query,
            state: state,
            start_date: startDate,
            end_date: endDate,
            start_reservation_date: startReservationDate,
            end_reservation_date: endReservationDate,
            by_sale: bySale,
            by_cs: byCs,
            by_purchaser: byPurchaser,
            by_potential_lead: byPotentialLead,
            by_lead_source: byLeadSource,
            is_big_sales: isBigSales,
            is_waiting_to_ship: isWaitingToShip,
            is_waiting_for_production: isWaitingForProduction,
            is_my_orders: isMyOrders,
            is_online_paid: isOnlinePaid,
            task_state: taskState,
            start_payment_date: startPaymentDate,
            end_payment_date: endPaymentDate,
            ...newForceQuery
        }, _.keys(match.params))

        const newParams = _.pickBy(newRouterQuery, (value) => value)

        const queryString = Object.keys(newParams).map(key => key + '=' + newParams[key]).join('&')

        history.push({
          search: `?${queryString}`,
          params: newParams
        })
    }

    getOrderQueryObject (sorter) {
        if (isEmpty(sorter)) {
            return {}
        }

        if (!sorter.columnKey) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: `${sorter.columnKey} ${sortDirection}`
        }
    }

    handleTableChange = (pagination, filter, sorter) => {
        const sorterQuery = this.getOrderQueryObject(sorter)

        setSorter(sorterQuery)

        this.fetchOrderSummaries({ sorter: sorterQuery, page: pagination.current })
    }

    handleOnSearch = (query) => {
        this.fetchOrderSummaries({ query: query, page: 1 })
    }

    handleOnPeriodChange = (dateString, date) => {
        this.props.setDefaultDateTimeType(null)
        this.props.setDefaultTimeRange(['00:00', '23:59'])
        this.fetchOrderSummaries({
            startDate: date[0] || '',
            endDate: date[1] || ''
        })
    }

    handleOnReservationDateChange = (dateString, date) => {
        this.fetchOrderSummaries({
            startReservationDate: date[0] || '',
            endReservationDate: date[1] || ''
        })
    }

    handleOnPaymentDateChange = (dateString, date) => {
        this.fetchOrderSummaries({
            startPaymentDate: date[0] || '',
            endPaymentDate: date[1] || ''
        })
    }

    handleOnDateTypeChange = (dateType) => {
        this.props.setDateType(dateType)
        this.fetchOrderSummaries({ dateType })
    }

    handleFilterByState = (state) => {
        const getState = (state === 'all') ? '' : state

        this.fetchOrderSummaries({ state: getState, page: 1 })
    }

    handleFilterBySale = (saleId) => {
        this.fetchOrderSummaries({ bySale: saleId || '', page: 1 })
    }

    handleFilterByCs = (csId) => {
        this.fetchOrderSummaries({ byCs: csId || '', page: 1 })
    }

    handleFilterByScm = (purchaserId) => {
        this.fetchOrderSummaries({ byPurchaser: purchaserId || '', page: 1 })
    }

    handleFilterByPotentialLead = (plId) => {
        this.fetchOrderSummaries({ byPotentialLead: plId || '', page: 1 })
    }

    handleFilterByLeadSource = (source) => {
        this.fetchOrderSummaries({ byLeadSource: source || '', page: 1 })
    }

    handleFilterByTaskState = (taskState) => {
        this.fetchOrderSummaries({ taskState: taskState || '', page: 1 })
    }

    hadnleOnProvinceChanged = (provinceId) => {
        this.fetchOrderSummaries({ provinceId: provinceId, page: 1 })
    }

    getOrderSummaries = () => {
        const { orderSummaryEntities, orderSummaryDashBoards } = this.props
        const items = orderSummaryDashBoards.get('items')
        return items.map((id) => {
            return orderSummaryEntities.get(id)
        })
    }

    handleCreatePurchase = (orderId = '') => {
        const { setSupplierOrderFormModal, setOrderId, orderSummaryDashBoards } = this.props
        const showForm = orderSummaryDashBoards.get('showSupplierForm')

        setSupplierOrderFormModal(!showForm)
        setOrderId(orderId)
    }

    getDefaultDateTimeRange = (type) => {
        switch (type) {
            case 'today':
                const today = moment().format('YYYY-MM-DD')
                return [today, today]
            case 'yesterday':
                const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
                return [yesterday, yesterday]
            case 'thisWeek':
                const date = moment()
                return [date.weekday(0).format('YYYY-MM-DD'), date.weekday(6).format('YYYY-MM-DD')]
            default:
                return null
        }
    }

    handleChangeDefaultDateTimeRange = (type) => {
        const { setDefaultDateTimeType, orderSummaryDashBoards } = this.props
        const defaultDateTimeType = orderSummaryDashBoards.get('defaultDateTimeType')
        const defaultTimeRange = orderSummaryDashBoards.get('timeRange')

        let value = (type === defaultDateTimeType) ? null : this.getDefaultDateTimeRange(type)
        value = value && [value[0] + ' ' + defaultTimeRange[0] || '00:00',  value[1] + ' ' + defaultTimeRange[1] || '23:59']

        if (!_.includes(['today', 'yesterday'], type)) {
            this.props.setDefaultTimeRange(['00:00', '23:59'])
        }
        setDefaultDateTimeType(value ? type : null)
        this.fetchOrderSummaries({ startDate: value && value[0], endDate: value && value[1], page: 1 })
    }

    handleChangeDefaultTimeRange = (timeRange) => {
        const { orderSummaryDashBoards, setDefaultTimeRange, setDefaultDateTimeType } = this.props
        const defaultDateTimeType = orderSummaryDashBoards.get('defaultDateTimeType')
        const activeDateCheck = _.includes(['today', 'yesterday'], defaultDateTimeType)

        let value = this.getDefaultDateTimeRange(activeDateCheck ?  defaultDateTimeType : 'today')
        value = value && [value[0] + ' ' + timeRange[0],  value[1] + ' ' + timeRange[1]]

        setDefaultDateTimeType(activeDateCheck ?  defaultDateTimeType : 'today')
        setDefaultTimeRange(timeRange)
        this.fetchOrderSummaries({ startDate: value && value[0], endDate: value && value[1], page: 1 })
    }

    renderColumns = () => {
      const { i18n } = this.props
        const columns = [
            {
                title: i18n.t('orders:orderSummary.orderNumber'),
                key: 'number',
                sorter: true,
                render: (row) => {
                    const customer = row.customer
                    let customerDom
                    if (customer) {
                        const customerLineUrl = customer.line_url

                        customerDom = (<div>
                            <Link to={`/customers/${customer.customer_no}`}>
                                {customer.first_name} {customer.last_name}
                            </Link>
                            <div><PhoneOutlined /> {customer.phone_number || ''}</div>
                            <div>
                                <MessageOutlined />{customer.line_id || ''}
                                {customerLineUrl && (
                                    <a href={customerLineUrl} target="_blank" style={{ marginLeft: '4px'}}>
                                        <img src={lineLogo} width='24px' />
                                    </a>
                                )}
                            </div>
                        </div>)
                    }
                    return (
                        <div>
                            <Link to={`/inquiries/${row.number}`}>
                              {row.number}
                            </Link>
                            { customerDom }
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderSummary.detail'),
                dataIndex: 'detail'
            },
            {
                title: i18n.t('orders:orderSummary.status'),
                dataIndex: 'state_label',
                render: (dataIndex, record) => {
                    const paymentMethod = record.payment_method
                    const isDeposit = record.is_deposit_payment_term

                    if (record.state !== 'payment') { return dataIndex }

                    const payByCheque = paymentMethod === 'cheque' ? `<${i18n.t('inquiries:payByCheque')}>` : ''
                    const depositPaymentTerm = isDeposit ? `<${i18n.t('inquiries:stateDropdown.deposit')}>` : ''

                    return (
                        <div>
                            <div>{dataIndex}</div>
                            <div>{depositPaymentTerm}</div>
                            <div>{payByCheque}</div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderSummary.sumOfReceipt'),
                key: 'grand_total_satangs',
                sorter: true,
                render: (row) => {
                    return (
                        <div>
                            <div>{row.grand_total}</div>
                            <div>({row.sum_of_receipt === '0.00' ? '-' : row.sum_of_receipt })</div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderSummary.addPuchaseOrder'),
                render: (row) => {
                    return (
                        <div>
                            <span>
                                { row.suppliers_list }
                                <a style={{ marginLeft: '4px' }} onClick={() => this.handleCreatePurchase(row.number)}>
                                    <PlusSquareTwoTone />
                                </a>
                            </span>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderSummary.trucks'),
                dataIndex: 'truck_dispatch_license_plates',
                render: (licensePlates) => {
                    if (licensePlates.length === 0) { return '-' }
                    return (
                        <div>{licensePlates.join(', ')}</div>
                    )
                }
            },
            {
                title: i18n.t('shared:createdAt'),
                key: 'created_at',
                dataIndex: ['localized_time', 'created_at'],
                sorter: true,
            },
            {
                title: i18n.t('orders:orderSummary.updatedAt'),
                key: 'updated_at',
                dataIndex: ['localized_time', 'updated_at'],
                sorter: true
            }
        ]

        return columns
    }

    renderSaleFilterOptions = () => {
        const { orderTables, entities, i18n } = this.props
        const saleEntities = entities.get('sales')
        const saleItems = orderTables.get('saleItems')
        const saleOptions = [
            {
                key: `${i18n.t('orders/tables:haveNoSale')}`,
                value: 'nil'
            },
            {
                key: `${i18n.t('orders/tables:haveSale')}`,
                value: 'not_nil'
            }
        ]

        if (saleItems.size === 0) {return null}
        const salesList = saleItems.map((saleId) => {
            const sale = saleEntities.get(saleId)
            return (
                {
                    key: sale.get('name'),
                    value: sale.get('id')
                }
            )
        })

        return saleOptions.concat(salesList.toJS()).map((saleOption) => {
            return (
                <Select.Option key={saleOption.key} value={saleOption.value}>
                    {saleOption.key}
                </Select.Option>
            )
        })
    }

    renderCsFilterOptions = () => {
        const { orderTables, entities, i18n } = this.props
        const csEntities = entities.get('customerServices')
        const csItems = orderTables.get('csItems')
        const csOptions = [
            {
                key: `${i18n.t('orders/tables:haveNoCs')}`,
                value: 'nil'
            },
            {
                key: `${i18n.t('orders/tables:haveCs')}`,
                value: 'not_nil'
            }
        ]

        if (csItems.size === 0) {return null}
        const csesList = csItems.map((csId) => {
            const cs = csEntities.get(csId)
            return (
                {
                    key: cs.get('name'),
                    value: cs.get('id')
                }
            )
        })

        return csOptions.concat(csesList.toJS()).map((csOption) => {
            return (
                <Select.Option key={csOption.key} value={csOption.value}>
                    {csOption.key}
                </Select.Option>
            )
        })
    }

    renderScmFilterOptions = () => {
        const { orderTables, entities,i18n } = this.props
        const purchaserEntities = entities.get('purchasers')
        const purchaserItems = orderTables.get('purchaseItems')
        const scmOptions = [
            {
                key: `${i18n.t('orders/tables:haveNoScm')}`,
                value: 'nil'
            },
            {
                key: `${i18n.t('orders/tables:haveScm')}`,
                value: 'not_nil'
            }
        ]

        if (purchaserItems.size === 0) {return null}
        const purchasersList = purchaserItems.map((purchaserId) => {
            const scm = purchaserEntities.get(purchaserId)
            return (
                {
                    key: scm.get('name'),
                    value: scm.get('id')
                }
            )
        })

        return scmOptions.concat(purchasersList.toJS()).map((scmOption) => {
            return (
                <Select.Option key={scmOption.key} value={scmOption.value}>
                    {scmOption.key}
                </Select.Option>
            )
        })
    }

    renderPotentialLeadFilterOptions = () => {
      const { i18n } = this.props
        const potentialLeadOptions = [
            {
                key: `${i18n.t('orders/tables:allPotentialLead')}`,
                value: ''
            },
            {
                key: `${i18n.t('orders/tables:haveNoPotentialLead')}`,
                value: 'false'
            },
            {
                key: `${i18n.t('orders/tables:havePotentialLead')}`,
                value: 'true'
            }
        ]
        return potentialLeadOptions.map((potentialLeadOption) => {
            return (
                <Select.Option key={potentialLeadOption.key} value={potentialLeadOption.value}>
                    {potentialLeadOption.key}
                </Select.Option>
            )
        })
    }

    renderStaffFilter = () => {
        const { location, i18n } = this.props
        const defaultBySale = new URLSearchParams(location.search).get("by_sale")
        const defaultByCs = new URLSearchParams(location.search).get("by_cs")
        const defaultByPurchaser = new URLSearchParams(location.search).get("by_purchaser")
        const defaultByPotentialLead = new URLSearchParams(location.search).get("by_potential_lead")

        return (
            <div>
                <Row>
                    <Col span={7}>
                        <div style={{ marginRight: '8px' }}>
                            <span>
                                {i18n.t('orders/tables:filterBySale')}
                            </span>
                            <Select style={{ width: '100%' }} allowClear
                                defaultValue={defaultBySale || undefined}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                onChange={(value) => this.handleFilterBySale(value)}
                                placeholder={i18n.t('orders/tables:filterWithSale')}>
                                {this.renderSaleFilterOptions()}
                            </Select>
                        </div>
                    </Col>
                    <Col span={7}>
                        <div style={{ marginRight: '8px' }}>
                            <span>
                                {i18n.t('orders/tables:filterByCs')}
                            </span>
                            <Select style={{ width: '100%' }} allowClear
                                defaultValue={defaultByCs || undefined}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                onChange={(value) => this.handleFilterByCs(value)}
                                placeholder={i18n.t('orders/tables:filterWithCs')}>
                                {this.renderCsFilterOptions()}
                            </Select>
                        </div>
                    </Col>
                    <Col span={7}>
                        <span>
                            {i18n.t('orders/tables:filterByScm')}
                        </span>
                        <Select style={{ width: '100%' }} allowClear
                            defaultValue={defaultByPurchaser || undefined}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            onChange={(value) => this.handleFilterByScm(value)}
                            placeholder={i18n.t('orders/tables:filterWithScm')}>
                            {this.renderScmFilterOptions()}
                        </Select>
                    </Col>
                </Row>
                <div style={{ marginTop: '8px' }}>
                    <span style={{ margin: '5px' }}>
                        <span>
                            <span style={{ marginRight: '10px' }}>
                                {i18n.t('orders/tables:filterByPl')}
                            </span>
                            <Select style={{ width: '150px' }}
                                defaultValue={defaultByPotentialLead || ''}
                                onChange={(value) => this.handleFilterByPotentialLead(value)}
                                placeholder={i18n.t('orders/tables:filterWithPl')}>
                                {this.renderPotentialLeadFilterOptions()}
                            </Select>
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    renderCreatePurchase = () => {
        const { forceQuery, fetchOrderSummaryDashboards, orderSummaryDashBoards, location, setSupplierOrderFormModal } = this.props
        const showForm = orderSummaryDashBoards.get('showSupplierForm')
        const orderId = orderSummaryDashBoards.get('orderId')

        return (
            <Modal
                footer={false}
                visible={showForm}
                destroyOnClose
                onCancel={() => this.handleCreatePurchase()}>
                <SuppliersOrdersFormContainer
                    isNewPage
                    isOrderSummaryDashBoard
                    isSupplierPurchase
                    formType="product"
                    orderId={orderId}
                    onSaveSuccess={() => {
                        setSupplierOrderFormModal(false)
                        fetchOrderSummaryDashboards({ ...location.params, ...forceQuery })
                    }} />
            </Modal>
        )
    }

    leadSourceOptions = () => {
        return LEAD_SOURCES.map((leadSource) => {
            return (
                <Select.Option key={leadSource}>
                    {leadSource}
                </Select.Option>
            )
        })
    }

    renderLeadSourceSelect () {
        const { location, i18n } = this.props
        const defaultByLeadSource = new URLSearchParams(location.search).get("by_lead_source")|| undefined
        return (
            <Select style={{ width: '220px' }} allowClear
                value={defaultByLeadSource}
                onChange={(value) => this.handleFilterByLeadSource(value)}
                placeholder={i18n.t('orders/tables:filterByLeadSource')}>
                {this.leadSourceOptions()}
            </Select>
        )
    }

    renderDefaultBtn = ({ active, label, type, onChange }) => {
		return (
			<Button
				className="focus-animation-none margin-right-8"
				ghost={active}
				onClick={() => onChange(type)}
				type={active ? 'primary' : 'default'}>
				{label}
			</Button>
		)
	}

	renderDefaultLeadSource = () => {
        const { location, i18n } = this.props
        const defaultLeadSource = new URLSearchParams(location.search).get("by_lead_source") || null

		return ['line'].map(type => {
            return this.renderDefaultBtn({
                active: defaultLeadSource === type,
                label: i18n.t(`orders:orderSummary.defaultLeadSources.${type}`),
                type,
                onChange: (value) => this.handleFilterByLeadSource(defaultLeadSource === value ? null : value)
            })
        })
	}

	renderMyOrderFilter = () => {
        const { location, i18n } = this.props
        const isMyOrders = new URLSearchParams(location.search).get("is_my_orders") || null

		return this.renderDefaultBtn({
            active: isMyOrders,
            label: i18n.t(`orders:orderSummary.myOrders`),
            type: 'myOrders',
            onChange: () => this.fetchOrderSummaries({ isMyOrders: isMyOrders === "true" ? null : true, page: 1 })
        })
	}

    renderIsOnlinePaidFilter = () => {
        const { location, i18n } = this.props
        const isOnlinePaid = new URLSearchParams(location.search).get("is_online_paid") || null

		return this.renderDefaultBtn({
            active: isOnlinePaid,
            label: i18n.t(`orders:orderSummary.isOnlinePaid`),
            type: 'isOnlinePaid',
            onChange: () => this.fetchOrderSummaries({ isOnlinePaid: isOnlinePaid === "true" ? null : true, page: 1 })
        })
	}

	renderDefaultDateTimeRange = () => {
        const { orderSummaryDashBoards, i18n } = this.props
        const defaultDateTimeType = orderSummaryDashBoards.get('defaultDateTimeType')

        return ['today', 'yesterday', 'thisWeek'].map(type => {
            return this.renderDefaultBtn({
                active: defaultDateTimeType === type,
                label: i18n.t(`orders:orderSummary.defaultDates.${type}`),
                type,
                onChange: this.handleChangeDefaultDateTimeRange
            })
        })
	}

    renderDefaultTimeRange = () => {
        const { orderSummaryDashBoards, i18n } = this.props
        const defaultTimeRange = orderSummaryDashBoards.get('timeRange')
        const timeRanges = [
            { start: '08:00', end: '10:00' },
            { start: '10:00', end: '12:00' },
            { start: '12:00', end: '14:00' },
            { start: '14:00', end: '16:00' },
            { start: '16:00', end: '18:00' },
        ]

        return timeRanges.map(timeRange => {
            return this.renderDefaultBtn({
                active: _.isEqual(defaultTimeRange, [timeRange.start, timeRange.end]),
                label: i18n.t('orders:orderSummary.timeRanges', { start: timeRange.start, end: timeRange.end }),
                type: [timeRange.start, timeRange.end],
                onChange: this.handleChangeDefaultTimeRange
            })
        })
	}

    renderDateType = () => {
        const { i18n, orderSummaryDashBoards } = this.props
        return (
            <Select
                onChange={this.handleOnDateTypeChange}
                style={{ width: '150px' }}
                value={orderSummaryDashBoards.get('dateType')}
                >
                    <Select.Option key='created_at' value='created_at'>
                        {i18n.t('shared:createdAt')}
                    </Select.Option>
                    <Select.Option key='updated_at' value='updated_at'>
                        {i18n.t('orders:orderSummary.updatedAt')}
                    </Select.Option>
            </Select>
        )
    }

    renderProvinces = () => {
        const {provinces, provincesEntities} = this.props
        return (
            <Select style={{ width: '150px' }} 
                onChange={this.hadnleOnProvinceChanged} >
                {
                    provinces.get('items').map((provinceId) => {
                        const provinceEntity = provincesEntities.get(provinceId)
                        return (
                            <Select.Option key={provinceEntity.get('name')} value={provinceEntity.get('id')}>
                                {provinceEntity.get('name')}
                            </Select.Option>
                        )

                    })
                }
            </Select>
        )
    }

    renderFilterSection = () => {
        const { location, hideFilter, orderSummaryDashBoards, i18n } = this.props
        if (hideFilter) return null

        const defaultQuery = new URLSearchParams(location.search).get("query")
        const defaultDate = new URLSearchParams(location.search).get("start_date") ? (
            [moment(new URLSearchParams(location.search).get("start_date")), moment(new URLSearchParams(location.search).get("end_date"))]
        ) : null
        const defaultReservationDate = new URLSearchParams(location.search).get("start_reservation_date") ? (
            [moment (new URLSearchParams(location.search).get("start_reservation_date")), moment(new URLSearchParams(location.search).get("end_reservation_date"))]
        ) : null
        const defaultTaskState = new URLSearchParams(location.search).get("task_state") || undefined
        const defaultPaymentDate = new URLSearchParams(location.search).get("start_payment_date") ? (
            [moment (new URLSearchParams(location.search).get("start_payment_date")), moment(new URLSearchParams(location.search).get("end_payment_date"))]
        ) : null

        return (
            <div className="filter-section margin-bottom-12">
                <div className="filter-item margin-bottom-12">
                    <div className="label">{i18n.t('orders:orderSummary.filter')}</div>
                    {this.renderDefaultLeadSource()}
                    {this.renderMyOrderFilter()}
                    {this.renderIsOnlinePaidFilter()}
                </div>
                <Input.Search placeholder={i18n.t('orders:orderSummary.searchOrder')}
                    defaultValue={defaultQuery || undefined}
                    onSearch={(query) => {this.handleOnSearch(query)}}
                    enterButton />
                <Divider />
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={12}>
                            <span style={{ marginLeft: '5px', marginBottom: '10px' }}>
                                <span style={{ marginRight: '10px' }}>
                                    {i18n.t('orders:orderSummary.state')}
                                </span>

                                <SelectOrderStateContainer
                                    onChange={this.handleFilterByState}
                                    style={{ width: '300px' }}
                                    value={_.get(location, 'params.state', 'all')}
                                />
                            </span>
                            </Col>
                            <Col span={12}>
                            จังหวัด
                            { this.renderProvinces() }
                            </Col>
                        </Row>

                    </Col>
                    <Col span={12}>
                        <div className="filter-item justify-content-end padding-4">
                            <div className="label">{i18n.t('orders:orderSummary.defaultDate')}</div>
                            {this.renderDefaultDateTimeRange()}
                        </div>
                    </Col>
                </Row>

                <Row className="margin-bottom-10">
                    <Col span={12}>
                        <div>{this.renderStaffFilter()}</div>
                        <div style={{ marginTop: '8px' }}>{this.renderLeadSourceSelect()}</div>
                    </Col>
                    <Col span={12}>
                        <Row style={{ marginBottom: '10px', marginRight: '10px' }}
                            type="flex" justify="end" align="middle">
                            <div style={{ marginRight: '10px' }}>
                                {this.renderDateType()}
                            </div>
                            <DatePicker.RangePicker allowClear
                                value={defaultDate}
                                format="YYYY-MM-DD HH:mm"
                                onChange={this.handleOnPeriodChange}
                                showTime={{ format: 'HH:mm' }} />
                        </Row>
                        <Row style={{ marginRight: '10px' }}
                            type="flex" justify="end" align="middle">
                            <div style={{ marginRight: '10px' }}>
                                {i18n.t('orders:orderSummary.reservationDate')}
                            </div>
                            <DatePicker.RangePicker allowClear
                                defaultValue={defaultReservationDate}
                                onChange={this.handleOnReservationDateChange} />
                        </Row>
                        <Row style={{ marginRight: '10px', marginTop: '8px' }}
                            type="flex" justify="end" align="middle">
                            <div style={{ marginRight: '10px' }}>
                                {i18n.t('orders:orderSummary.paymentDate')}
                            </div>
                            <DatePicker.RangePicker allowClear
                                defaultValue={defaultPaymentDate}
                                onChange={this.handleOnPaymentDateChange} />
                        </Row>
                        <div style={{ marginTop: '4px' }}
                            className="filter-item justify-content-end padding-4">
                            {this.renderDefaultTimeRange()}
                        </div>
                    </Col>
                </Row>

                <Row className="margin-bottom-10">
                    <Col span={5}>
                        {this.renderLeadSourceSelect()}
                    </Col>
                    <Col span={5}>
                        <SelectOrderTaskState
                            defaultValue={defaultTaskState}
                            onChange={this.handleFilterByTaskState} />
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Authorization allowedRoles={['g8fu', 'super_admin']}>
                        <Col md={6}>
                            <Checkbox
                                onChange={(e) => {
                                    this.fetchOrderSummaries({ isBigSales: e.target.checked, page: 1 })
                                }}
                                checked={orderSummaryDashBoards.get('isBigSales', false)}
                            >
                                {i18n.t('orders:orderSummary.onlyBigSales')}
                            </Checkbox>
                        </Col>
                    </Authorization>
                    <Col md={6}>
                        <Checkbox
                            onChange={(e) => {
                                this.fetchOrderSummaries({ isWaitingToShip: e.target.checked, page: 1 })
                            }}
                            checked={Boolean(orderSummaryDashBoards.get('isWaitingToShip', false))}
                        >
                            {i18n.t('orders:orderSummary.watingToship')}
                        </Checkbox>
                    </Col>
                    <Col md={6}>
                        <Checkbox
                            onChange={(e) => {
                                this.fetchOrderSummaries({ isWaitingForProduction: e.target.checked, page: 1 })
                            }}
                            checked={Boolean(orderSummaryDashBoards.get('isWaitingForProduction', false))}
                        >
                            {i18n.t('orders:orderSummary.filterWaitingForProduction')}
                        </Checkbox>
                    </Col>
                </Row>
            </div>
        )
    }

    render () {
        const { hideDivider, orderSummaryDashBoards } = this.props

        return (
            <OrderSummariesWrapper>
                {this.renderFilterSection()}
                {!hideDivider && <Divider />}
                <Table columns={this.renderColumns()}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    rowKey={orderSummary => orderSummary.id}
                    dataSource={this.getOrderSummaries().toJS()}
                    loading={orderSummaryDashBoards.get('loading')}
                    onChange={this.handleTableChange}
                    pagination={{
                        ...orderSummaryDashBoards.get('pagination').toJS(),
                        showSizeChanger: false
                    }}
                />
                {this.renderCreatePurchase()}
            </OrderSummariesWrapper>
        )
    }
}

const mapStateToProps = state => ({
    orderSummaryEntities: state.getIn(['Entities', 'orderSummaries']),
    provincesEntities: state.getIn(['Entities', 'provinces']),
    entities: state.get('Entities'),
    provinces: state.get('provinces'),
    orderSummaryDashBoards: state.get('orderSummaryDashBoards'),
    orderTables: state.get('orderTables')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCses,
        fetchOrderSummaryDashboards,
        fetchPurchasers,
        fetchSales,
        setDefaultDateTimeType,
        setOrderId,
        setSorter,
        setSupplierOrderFormModal,
        setDateType,
        setDefaultTimeRange,
        fetchProvinces
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['orders', 'orders/tables', 'suppliers', 'inquiries'])(withRouter(OrderSummariesContainer))
)
