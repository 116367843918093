import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Divider, Modal, Select, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import CustomerTypeForm from './form'

const CUSTOMER_TYPES_QUERY = gql`
    query CustomerTypes {
        customerTypes {
            id
            name
        }
    }
`

const DropDown = ({ creatable, options, toggleForm }) => {
    const { i18n } = useTranslation('customerTypes')

    if (!creatable) return options

    return (
        <Fragment>
            {options}
            <Divider key="divider" style={{ margin: '4px 0' }} />
            <Space
                key="add_option"
                onClick={toggleForm}
                style={{ padding: '4px 8px', cursor: 'pointer' }}>
                <PlusOutlined />
                {i18n.t('customerTypes:addCustomerTypes')}
            </Space>
        </Fragment>
    )
}

const renderOptions = (items = []) => items.map(({ id, name }) => {
    return <Select.Option value={id}>{name}</Select.Option>
})

const CustomerTypeSelector = ({ creatable, ...restProps }) => {
    const [showForm, setShowForm] = useState(false)
    const { loading, data, error, refetch } = useQuery(CUSTOMER_TYPES_QUERY)

    if (error) return console.error(error)

    const toggleForm = () => {
        setShowForm(!showForm)
    }

    return (
        <Fragment>
            <Select
                key="selector"
                dropdownRender={options => (
                    <DropDown
                        creatable={creatable}
                        options={options}
                        toggleForm={toggleForm}
                    />
                )}
                loading={loading}
                {...restProps}
            >
                {renderOptions(data && data.customerTypes)}
            </Select>
            <Modal
                key="form_modal"
                footer={null}
                onCancel={toggleForm}
                visible={showForm}
                width="600px"
                destroyOnClose
            >
                <CustomerTypeForm
                    onSuccess={() => {
                        refetch()
                        toggleForm()
                    }}
                />
            </Modal>
        </Fragment>
    )
}

CustomerTypeSelector.propTypes = {
    creatable: PropTypes.bool
}

CustomerTypeSelector.defaultProps = {
    creatable: false
}

export default CustomerTypeSelector
