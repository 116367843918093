import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'

import { Button, Form, Spin } from 'antd'
import BusinessAddressForm from '../../../components/customers/businessAddresses/businessAddressForm'

import businessAddressesActions from '../../../redux/customers/addresses/businesses/actions'
import mailingActions from '../../../redux/customers/addresses/mailing/actions'

const { fetchBusinessAddress, saveAddress } = businessAddressesActions
const { fetchMailingAddresses } = mailingActions

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

class BusinessAddressFormContainer extends Component {
    constructor() {
        super()
        this.formRef = createRef()
    }


    componentDidMount () {
        const { fetchBusinessAddress, fetchMailingAddresses, customerId, addressId } = this.props

        fetchMailingAddresses(customerId)

        if (!addressId) { return null }

        fetchBusinessAddress(customerId, addressId)
    }

    saveBusinessAddress = (values) => {
        values['mailing_address_attributes']['id'] = values['mailing_address_id']

        this.onSubmitForm(values)
    }

    onSubmitForm = (address) => {
        const { saveAddress, customerId, addressId, isModalForm } = this.props

        const redirect = !isModalForm
        if (isModalForm) {
            const { customerId } = this.props
            saveAddress(address, customerId, undefined, redirect)
        } else {
            saveAddress(address, customerId, addressId, redirect)
        }
    }

    render () {
        const {
            addressId,
            businessAddressEntities,
            businessAddresses,
            customerId,
            i18n,
            mailingAddressEntities
        } = this.props

        const errors = businessAddresses.get('formErrors')
        const businessAddress = businessAddressEntities.get(addressId) || new Map()

        const saving = businessAddresses.get('saving')
        const loading = businessAddresses.get('loading')

        if (loading) { return <Spin /> }

        const mailingAddress = mailingAddressEntities.get(businessAddress.get('mailing_address')) || new Map()

        return (
            <Form onFinish={this.saveBusinessAddress} {...formItemLayout}  ref={this.formRef}>
                <h2>{i18n.t('addresses:businessAddress')}</h2>

                <BusinessAddressForm
                    businessAddress={businessAddress.toJS()}
                    customerNo={customerId}
                    errors={errors.toJS()}
                    form={this.formRef}
                    mailingAddress={mailingAddress.toJS()}
                />

                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" size="large" loading={saving}>
                        {i18n.t('addresses:addAddress')}
                    </Button>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = state => ({
    businessAddresses: state.get('businessAddresses'),
    businessAddressEntities: state.getIn(['Entities', 'businessAddresses']),
    mailingAddressEntities: state.getIn(['Entities', 'mailingAddresses']),
    mailingAddresses: state.get('mailingAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBusinessAddress,
        fetchMailingAddresses,
        saveAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(('addresses'))(BusinessAddressFormContainer))
