import React, { Component } from 'react'
import { connect } from 'react-redux'
import WindowResizeListener from 'react-window-size-listener'
import { Debounce } from 'react-throttle'
import { Layout, Button } from 'antd'
import Icon from '@ant-design/icons'
import { ThemeProvider } from 'styled-components'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'
import themeDefault from '../../../config/theme/themedefault'
import { siteConfig } from '../../../config/index'
import appActions from '../../redux/app/actions'

import AppHolder from './commonStyle'
import AlertContainer from '../../containers/Notifications/AlertContainer'

const { toggleAll } = appActions

const { Content, Footer } = Layout

class Header extends Component {
    render () {
        if (this.props.App.get('height') === 0) {
            return (
                <div>
                    <Debounce time="90" handler="onResize">
                        <WindowResizeListener
                            onResize={windowSize =>
                                this.props.dispatch(toggleAll(
                                    windowSize.windowWidth,
                                    windowSize.windowHeight
                                ))}
                        />
                    </Debounce>
                </div>
            )
        }
        return (
            <header>
                <ThemeProvider theme={themeDefault}>
                    <AppHolder>
                        <Layout style={{ height: '100vh' }}>
                            <Debounce time="90" handler="onResize">
                                <WindowResizeListener
                                    onResize={windowSize =>
                                        this.props.dispatch(toggleAll(
                                            windowSize.windowWidth,
                                            windowSize.windowHeight
                                        ))}
                                />
                            </Debounce>
                            <Topbar />
                            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                                <Sidebar />
                                <AlertContainer />
                                <Layout
                                    className="isoContentMainLayout"
                                    style={{
                                        height: '100vh'
                                    }}
                                >
                                    <Content
                                        className="isomorphicContent"
                                        style={{
                                            padding: '70px 0 0',
                                            flexShrink: '0',
                                            background: '#f1f3f6',
                                            width: '100%'
                                        }}
                                    >
                                        {this.props.children}
                                    </Content>
                                    <Footer
                                        style={{
                                            background: '#ffffff',
                                            textAlign: 'center',
                                            borderTop: '1px solid #ededed'
                                        }}
                                    >
                                        {siteConfig.footerText}
                                    </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </AppHolder>
                </ThemeProvider>
            </header>
        )
    }
}
const mapStateToProps = state => {
    return {
        App: state.get('App')
    }
}

export default connect(mapStateToProps)(Header)
