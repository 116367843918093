import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

import { Button, Form, Radio } from 'antd'

import inquiryActions from '../../../../../redux/inquiry/actions'
import ShippingAddressForm from './Form'
import SelfPickupForm from './SelfPickupForm'
import ExportForm from './ExportForm'

const { saveOrderForm } = inquiryActions

const ShippingAddressFormRender = ({
    shippingAddress,
    onCancel,
    onSuccess,
    orderId,
    pickupDate,
    pickupLicensePlateNumber,
    defaultShippingType,
    exportShipment
}) => {
    const { i18n } = useTranslation('inquiries')
    const [form] = Form.useForm()
    const [shippingType, setShippingType] = useState(defaultShippingType)

    const dispatch = useDispatch()
    const saving = useSelector(state => state.getIn(['inquiry', 'saveOrderLoading']))
    const formErrors = useSelector(state => state.getIn(['inquiry', 'formErrors']))
    const inquiry = useSelector(state => state.get('inquiry'))
    const customerEntities = useSelector(state => state.getIn(['Entities', 'customers']))


    const onSelectShippingType = (value) => {
      setShippingType(value)
    }

    const onSubmitForm = (values) => {
      let file
      Reflect.deleteProperty(values, 'paste_image')

      if (values['pickup_date']) {
        values['pickup_date'] = values['pickup_date'].format('YYYY-MM-DD')
      }

      const shippingAddressParams = values['order_shipping_address_attributes']
      if (shippingAddressParams && shippingAddressParams['order_shipping_address_images_attributes']) {
        const imageValue = shippingAddressParams['order_shipping_address_images_attributes']
        const orderShippingAddressImages = shippingAddress ? shippingAddress.get('order_shipping_address_images') : new List()
        let destroyImage = []
        let uploadImage = imageValue

        orderShippingAddressImages.map((image) => {
            if (!imageValue.filter(value => value.uid === image.get('id')).length) {
                destroyImage.push({ id: image.get('id'), _destroy: true })
            } else {
                uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
            }
        })

        file = uploadImage ? (
            uploadImage.map((id) => {
                return {
                    file: id.originFileObj,
                    _destroy: false
                }
            }).concat(destroyImage)
        ) : null
        const imageParams = { order_shipping_address_images_attributes: file }
        values = {
          ...values,
          ...{ order_shipping_address_attributes: {
              ...values['order_shipping_address_attributes'], ...imageParams }
          }
        }
      }

      dispatch(saveOrderForm(orderId, values, {
          onSuccess: () => { onSuccess() }
      }))
    }

    const renderForm = () => {
      if (shippingType === 'self_pickup') {
        return <SelfPickupForm pickupDate={pickupDate} licensePlateNumber={pickupLicensePlateNumber} />
      }

      if (shippingType === 'by_osh') {
        return <ShippingAddressForm
          shippingAddress={shippingAddress || new Map()}
          customerPhoneNumber={customer.get('phone_number')}
          customerName={`${customer.get('full_name')}`}
          formErrors={formErrors}
          form={form}/>
      }

      if (shippingType === 'export') {
        return <ExportForm errors={formErrors}
          defaultCountryId={exportShipment ? exportShipment.get('country_id') : null}/>
      }

      return null
    }

  const customer = customerEntities.getIn([inquiry.get('currentCustomer')]) || new Map()

  return (
    <Form onFinish={(values) => onSubmitForm(values)} form={form}>
        <label className="title">ประเภทการจัดส่ง</label>
        <Form.Item key="shipping_type" name='shipping_type' initialValue={shippingType}>
            <Radio.Group onChange={(e) => onSelectShippingType(e.target.value)}>
                <Radio value='by_osh'>เราจัดส่ง</Radio>
                <Radio value='self_pickup'>{i18n.t('orders:selfPickup')}</Radio>
                <Radio value='not_specified_address'>{i18n.t('orders:unspecifiedAddress')}</Radio>
                <Radio value='export'>ส่งออก</Radio>
            </Radio.Group>
        </Form.Item>

        {renderForm()}
        <div style={{ textAlign: 'center' }}>
            <Button style={{ marginRight: '8px' }}
                type="primary"
                loading={saving}
                htmlType="submit"> {i18n.t('inquiries:edit')}</Button>
            <Button
                disabled={saving}
                onClick={onCancel}>
                {i18n.t('inquiries:cancel')}
            </Button>
        </div>
    </Form>
  )
}

ShippingAddressFormRender.propTypes = {
  shippingAddress: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  orderId: PropTypes.string.isRequired,
  pickupDate: PropTypes.string,
  pickupLicensePlateNumber: PropTypes.string,
  defaultShippingType: PropTypes.string
}

ShippingAddressFormRender.defaultProps = {}

export default ShippingAddressFormRender
