import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import oshApiAuthentication from '../../helpers/oshApiAuthentication'
import jsCookie from 'js-cookie'

import actions from './actions'

export function *loginRequest () {
    yield takeEvery(actions.LOGIN_REQUEST, function *({ payload }) {
        const { onSuccess } = payload
        
        const result = yield call(oshApiAuthentication.login, payload.loginForm)

        if (result.error) {
            yield put({
                type: actions.LOGIN_ERROR,
                errorMessage: 'Email/Password is incorrect'
            })
        } else {
            for (let key in result) {
                jsCookie.set(key, result[key])
            }
            yield put({
                type: actions.LOGIN_SUCCESS,
                accessToken: result
            })
            onSuccess()
        }
    })
}

export function *getToken () {
    yield takeEvery(actions.GET_ACCESS_TOKEN, function *({ payload }) {
        const token = jsCookie.get('token')
        if (token) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                accessToken: { token }
            })
        }
    })
}

export function *logout () {
    yield takeEvery(actions.LOGOUT, function *({ payload }) {
        jsCookie.remove('token')
        location.reload()
    })
}

export default function *rootSaga () {
    yield all([
        fork(loginRequest),
        fork(getToken),
        fork(logout)
    ])
}
