import React from 'react'
import PropTypes from 'prop-types'

import { Select, Spin } from 'antd'

const SelectCountryRender = ({
    i18n,
    defaultValue,
    loading,
    onChange,
    onSearch,
    countries,
    value,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder='ค้นหาประเทศ'
            showArrow={false}
            showSearch
            style={restSelectProps.style || { width: '100%' }}
            value={value}
            {...restSelectProps}
        >
            {countries.map((country, index) => {
                return (
                    <Select.Option
                        key={`index-${index}`}
                        value={country.id || undefined}
                    >
                        {country.name || ''}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectCountryRender.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    countries: PropTypes.array,
    value: PropTypes.string
}

SelectCountryRender.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    countries: [],
    value: undefined
}

export default SelectCountryRender

