import React, { Component, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import jsCookie from 'js-cookie'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { Button, Dropdown, PageHeader, Statistic, DatePicker, Modal, Tag } from 'antd'
import { CreditCardOutlined, CopyOutlined, CheckOutlined, EditTwoTone, MenuOutlined } from '@ant-design/icons'
import { Link, withRouter } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { formatNumberWithDash, formatNumberWithDashSatangs } from '../../../../utilties/number'

import { COOKIE_KEY } from '../../../../constants/inquiries'
import inquiryActions from '../../../../redux/inquiry/actions'
import historyDashboardsActions from '../../../../redux/orders/historyDashboards/actions'

import CustomerCard from './CustomerCard'
import HeaderWrapper from './Header.style'
import DuplicateOrder from './DuplicatOrder'
import MoveCustomer from './MoveCustomer'
import OrderSummaryDetail  from './OrderSummaryDetail'
import CategoryForm from '../Header/CategoryForm'
import OrderStateDropdown from '../../../../components/orders/stateDropdown'
import ShippingDate from '../../../../components/orders/shipingDate'
import CopyMenu from './CopyMenu'

const {
    fetchInquiryCustomer,
    duplicateOrder,
    saveOrderForm,
    setDuplicateOrderModal,
    setShowCategoryHeaderForm
} = inquiryActions

const { setRefetch: setRefetchHistoryDashboard } = historyDashboardsActions

class InquiryHeader extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        fetchOrder: PropTypes.func.isRequired,
        page: PropTypes.string,

        fetchInquiryCustomer: PropTypes.func.isRequired,
        inquiry: ImmutablePropTypes.map.isRequired,
        orderEntities: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const { fetchInquiryCustomer } = this.props
        const customerId = this.getOrder().getIn(['customer', 'id'])

        if (!customerId) { return null }

        fetchInquiryCustomer(customerId)
    }

    componentWillUnmount () {
        jsCookie.remove(COOKIE_KEY)
    }

    getOrder = () => {
        const { inquiry, orderEntities } = this.props

        return orderEntities.get(inquiry.get('current')) || new Map()
    }


    renderCopyNoteMenu (orderEntity) {
        return (
            <CopyMenu orderEntity={orderEntity}  />
        )
    }

    renderCopyCustomerQuotationLink (orderEntity) {
        const quotationUrl = orderEntity.get('me_quotation_history_temporary_url')

        return (
            <CopyToClipboard text={quotationUrl}>
                <Button type="dashed" icon={<CopyOutlined />} shape="round" size="small" style={{marginLeft: 8}}> ลิงค์ลูกค้า </Button>
            </CopyToClipboard>
        )
    }

    handleChangePotentialLead (value) {
        const { id, saveOrderForm } = this.props

        saveOrderForm(id, { potential_lead: value })
    }

    handleChangeUsedDate (date) {
        const { id, saveOrderForm } = this.props

        saveOrderForm(id, { used_date: date.format('YYYY-MM-DD') })
    }

    handleCreateNewOrder = (customerId) => {
        const { duplicateOrder, id } = this.props

        duplicateOrder(customerId, id)
    }

    handleEditCategoryForm = () => {
        const { inquiry, setShowCategoryHeaderForm } = this.props
        const visible = inquiry.get('showCategoryForm')
        setShowCategoryHeaderForm(!visible)
    }

    renderPotentialBtn (orderEntity) {
        const potentialLead = orderEntity.get('potential_lead')

        return (
            <Button icon={potentialLead ? <CheckOutlined /> : null}
                style={{ marginRight: '10px' }}
                onClick={() => this.handleChangePotentialLead(!potentialLead)}>
                {this.props.i18n.t('inquiries:potentialLead')}
            </Button>
        )
    }

    renderUsedDatePicker (orderEntity) {
        const usedDate = orderEntity.get('used_date')

        return (
            <DatePicker allowClear={false}
                value={usedDate ? moment(usedDate) : null}
                style={{ width: '160px' }}
                placeholder='วันที่ใช้งาน'
                onChange={(date) => this.handleChangeUsedDate(date)} />
        )
    }

    renderCategoryForm = (orderEntity) => {
        const { inquiry } = this.props
        const visible = inquiry.get('showCategoryForm')

        return  (
            <Modal visible={visible}
                footer={null}
                onCancel={this.handleEditCategoryForm}>
                <CategoryForm defaultCategoryId={orderEntity.getIn(['category', 'id'])}
                    orderId={orderEntity.get('id')}
                    onCancel={this.handleEditCategoryForm}
                    onSuccess={this.handleEditCategoryForm} />
            </Modal>
        )
    }

    renderCategoryTitle = () => {
        return (
            <div>
                <span style={{ marginRight: '4px'}}>หมวดหมู่สินค้า</span>
                <EditTwoTone onClick={this.handleEditCategoryForm} />
            </div>
        )
    }

    renderActiveMenu = () => {
        const orderEntity = this.getOrder()
        const { page } = this.props
        const id = orderEntity.get('id')

        if (page === 'inquiry') {
            return [
                <span>
                    <MenuOutlined style={{ margin: '0 3px' }} /> รายละเอียดสินค้า
                </span>,
                <Link to={`/orders/${id}/accountings`}>
                    <CreditCardOutlined style={{ margin: '0 3px' }} /> Accounting
                </Link>,
            ]
        } else if (page === 'account') {
            return [
                <Link to={`/inquiries/${id}`}>
                    <MenuOutlined style={{ margin: '0 3px' }} /> รายละเอียดสินค้า
                </Link>,
                <span>
                    <CreditCardOutlined style={{ margin: '0 3px' }} /> Accounting
                </span>,
            ]
        }
        return [
            <Link to={`/inquiries/${id}`}>
                <MenuOutlined style={{ margin: '0 3px' }} /> รายละเอียดสินค้า
            </Link>,
            <Link to={`/orders/${id}/accountings`}>
                <CreditCardOutlined style={{ margin: '0 3px' }} /> Accounting
            </Link>,
        ]
    }


    renderLossTag (orderEntity) {
        const receipt = orderEntity.getIn(['sum_of_receipt']) / 100.00
        const payment = orderEntity.getIn(['sum_of_payment']) / 100.00

        if (payment <= receipt) { return null }

        return (
            <Tag color={'red'} key="loss" >
                {`ขาดทุน ฿${(receipt - payment).toFixed(2)}`}
            </Tag>
        )
    }

    renderMissingPaymentTag (orderEntity) {
        const receipt = orderEntity.getIn(['sum_of_receipt']) / 100.00
        const grandTotal = orderEntity.get('grand_total')

        if (grandTotal <= receipt) { return null }

        return (
            <Tag color={'red'} key="missingPayment" >
                {`จ่ายเงินขาด ฿${(grandTotal - receipt).toFixed(2)}`}
            </Tag>
        )
    }
    
    renderOrderState = (orderEntity) => {
        const { i18n } = this.props
        const state = orderEntity.get('state')
        const paymentMethod = orderEntity.get('payment_method')
        const isDeposit = orderEntity.get('is_deposit_payment_term')
        const stateLabel = orderEntity.get('state_label')

        if (state !== 'payment') { return stateLabel }

        const payByCheque = paymentMethod === 'cheque' ? `<${i18n.t('inquiries:payByCheque')}>` : ''
        const depositPaymentTerm = isDeposit ? `<${i18n.t('inquiries:stateDropdown.deposit')}>` : ''

        return `${stateLabel} ${depositPaymentTerm} ${payByCheque}`.trim()
    }

    renderTags = (orderEntity) => {
        const {page} = this.props
        if(page !== 'account') return ([])
        return ([
            <Tag key={'state_label'} color={orderEntity.get('state') === 'payment' ? 'green' : 'red'}>
                {this.renderOrderState(orderEntity)}
            </Tag>,
            this.renderMissingPaymentTag(orderEntity),
            this.renderLossTag(orderEntity)
        ])
    }

    render () {
        const { customerEntities, inquiry, customerRoleEntities, i18n, fetchOrder, supplierOrderEntities } = this.props
        const orderEntity = this.getOrder()
        const id = orderEntity.get('id')

        const valueStyle = {}
        if (orderEntity.getIn(['sum_of_receipt']) <= orderEntity.getIn(['sum_of_payment'])) {
            valueStyle['color'] = '#cf1322'
        }
        

        return (
            <React.Fragment>
                <PageHeader
                    style={{padding: 0}}
                    onBack={() => window.history.back()}
                    title={<div>
                        {id}
                        <CopyToClipboard text={id}>
                            <Button type="dashed" icon={<CopyOutlined />} shape="round" size="small" style={{marginLeft: 8}}>id</Button>
                        </CopyToClipboard>
                        <Dropdown overlay={this.renderCopyNoteMenu(orderEntity)} placement="bottomCenter">
                            <Button type="dashed" icon={<CopyOutlined />} shape="round" size="small" style={{marginLeft: 8}}>พร้อม Note</Button>
                        </Dropdown>
                        { this.renderCopyCustomerQuotationLink(orderEntity) }
                    </div>}
                    tags={this.renderTags(orderEntity)}
                    extra={
                        this.renderActiveMenu().concat(
                            [
                                <OrderStateDropdown
                                    key="order_state"
                                    orderId={orderEntity.get('id')}
                                    paymentMethod={orderEntity.get('payment_method')}
                                    state={orderEntity.get('state')}
                                    stateLabel={orderEntity.get('state_label')}
                                    refetch={fetchOrder}
                                />,
                                <ShippingDate key="shipping_date" orderEntity={orderEntity} />
                            ]
                        )
}
                >

                <div>
                    <HeaderWrapper>
                    <div className='description'>
                        <CustomerCard orderEntity={orderEntity}
                            customerEntities={customerEntities}
                            inquiry={inquiry}
                            customerRoleEntities={customerRoleEntities}/>
                        <div className='ml-auto text-align-right order-container' >
                            <div className={'actions'}>
                                <div style={{marginBottom: 16}}>
                                    { <MoveCustomer orderEntity={orderEntity} />}
                                    { <DuplicateOrder orderEntity={orderEntity}
                                        inquiry={inquiry}
                                        i18n={i18n}
                                        handleCreateNewOrder={this.handleCreateNewOrder}/>}
                                    { this.renderPotentialBtn(orderEntity) }
                                    { this.renderUsedDatePicker(orderEntity) }
                                </div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Statistic title={this.renderCategoryTitle()}
                                value={orderEntity.getIn(['category', 'name'], '-')}
                                style={{
                                    margin: '0 32px',
                                }}
                                />
                                <Statistic
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    title="ยอดรวมเสนอลูกค้า"
                                    prefix="฿"
                                    value={formatNumberWithDash(orderEntity.get('grand_total'))}
                                    valueStyle={{ color: '#c8c8c8' }}
                                    />
                                <Statistic
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    title="ยอดรวมรับเงิน"
                                    valueStyle={valueStyle}
                                    prefix="฿"
                                    value={formatNumberWithDashSatangs(orderEntity.get('sum_of_receipt'))}
                                    />
                                <Statistic
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    title="ประมาณยอดรวมจ่ายเงิน"
                                    prefix="฿"
                                    valueStyle={{ color: '#c8c8c8' }} 
                                    value={formatNumberWithDashSatangs(orderEntity.get('sum_of_payment_voucher'))}
                                    />
                                <Statistic
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    title="ยอดรวมจ่ายเงิน"
                                    prefix="฿"
                                    valueStyle={valueStyle}
                                    value={formatNumberWithDashSatangs(orderEntity.get('sum_of_payment'))}
                                />
                                <Statistic
                                    style={{
                                        margin: '0 16px',
                                    }}
                                    title="ยอดรวมจ่ายแล้ว"
                                    prefix="฿"
                                    valueStyle={valueStyle}
                                    value={formatNumberWithDashSatangs(orderEntity.get('sum_of_paid_payment_vouchers'))}
                                />
                                {this.renderCategoryForm(orderEntity)}
                            </div>
                            <div style={{marginTop: 16, marginLeft: 48, textAlign: 'left'}}>
                                <OrderSummaryDetail orderEntity={orderEntity} supplierOrderEntities={supplierOrderEntities} />
                            </div>
                        </div>
                    </div>
                    </HeaderWrapper>
                </div>
                </PageHeader>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    staffProfiles: state.get('staffProfiles'),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    customerEntities: state.getIn(['Entities', 'customers']),
    customerRoleEntities: state.getIn(['Entities', 'customerRoles'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        duplicateOrder,
        fetchInquiryCustomer,
        saveOrderForm,
        setDuplicateOrderModal,
        setRefetchHistoryDashboard,
        setShowCategoryHeaderForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['inquiries', 'suppliers'])(withRouter(InquiryHeader)))
