import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Select } from 'antd'

const renderOptions = options => options.map(({ id, fullName }) => {
  return <Select.Option value={id}>{fullName}</Select.Option>
})

const SelectCustomerServiceRender = ({
  fullWidth,
  items,
  loading,
  onChange,
  selected
}) => {
  const { i18n } = useTranslation('staffs')

  return (
    <Select allowClear
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      loading={loading}
      onChange={onChange}
      placeholder={i18n.t('staffs:searchStaff')}
      showSearch
      style={{ width: fullWidth ? '100%' : '240px' }}
      value={selected}
    >
      {renderOptions(items || [])}
    </Select>
  )
}

SelectCustomerServiceRender.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectCustomerServiceRender.defaultProps = {}

export default SelectCustomerServiceRender
