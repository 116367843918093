import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Map, List } from 'immutable'

import customerNotesActions from '../../../redux/customers/customerNotes/actions'
import CustomerNote from '../../../components/customers/customerNote/customerNote'

const {
    fetchCustomerNote
} = customerNotesActions

class CustomerNoteContainer extends Component {
    componentDidMount () {
        this.noteRequest()
    }

    noteRequest = () => {
        const { fetchCustomerNote, customerId } = this.props
        fetchCustomerNote(customerId)
    }

    getNotes () {
        const {
            customerNotes,
            Entities
        } = this.props

        return customerNotes.get('items').map((customerNoteId) => {
            const note = Entities.getIn(['customerNotes', customerNoteId.toString()]) || new Map()
            const noteAttachmentIds = note.get('note_attachments') || new List()
            const orderNoteImageIds = note.get('order_note_images') || new List()

            return note.merge({
                note_attachments: noteAttachmentIds.map((noteAttachmentId) => {
                    return Entities.getIn(['noteAttachments', noteAttachmentId.toString()]) || new Map()
                }) || new List(),

                order_note_images: orderNoteImageIds.map((orderNoteImageId) => {
                    return Entities.getIn(['orderNoteImages', orderNoteImageId.toString()]) || new Map()
                }) || new List()
            })
        }) || new List()
    }

    handleChange = (pagination) => {
        const { fetchCustomerNote, customerId } = this.props
        fetchCustomerNote(customerId, pagination)
    }

    getPagination () {
        const { customerNotes } = this.props
        return customerNotes.get('pagination') || new Map()
    }

    render () {
        const {
            customerNotes
        } = this.props

        const loading = customerNotes.get('loading')

        return (
            <div>
                <CustomerNote
                    handlePageChange={this.handleChange}
                    loading={loading}
                    notes={this.getNotes()}
                    pagination={this.getPagination()}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    Entities: state.get('Entities'),
    customerNotes: state.get('customerNotes')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerNote
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNoteContainer)
