import React from 'react'
import { Avatar } from 'antd'

import ChannelWrapper from './Channel.style'

const clientCardClass = (clientLineChannel, currentChannel) => {
  if(clientLineChannel.id === currentChannel) {return 'card active'}
  return 'card'
}

const renderClientLineChannels = (clientLineChannels, currentChannel) => {
  return clientLineChannels.map((clientLineChannel) => {
    return (
      <div className={clientCardClass(clientLineChannel, currentChannel)} key={clientLineChannel.id}>
        <a href={`/support/${clientLineChannel.id}`}>
          <Avatar src={clientLineChannel.pictureUrl} alt={clientLineChannel.displayName} style={{marginRight: 8}}/>
          { clientLineChannel.displayName }
          </a>
      </div>
    )
  })
}

const Channel = ({clientLineChannels, currentChannel}) => {
  return (
    <div>
      <ChannelWrapper>
        {renderClientLineChannels(clientLineChannels, currentChannel) }
      </ChannelWrapper>
    </div>
  )
}

export default Channel
