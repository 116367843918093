import React from 'react'
import PropTypes from 'prop-types'

import { Button, Popover, Spin } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

const ImageWithActions = ({
    actions,
    handleOnClickImage,
    thumbBlob,
    thumbLoading
}) => {
    if (thumbLoading) { return <Spin size="small" /> }

    const hoverContent = (
        <div>
            <Button key="watch-image" icon={<EyeOutlined />} onClick={handleOnClickImage} size="small" type="link" />

            {actions.map((action) => {
                return action
            })}
        </div>
    )

    return (
        <Popover content={hoverContent} trigger="hover" >
            <img key="image" onClick={handleOnClickImage} src={thumbBlob} />
        </Popover>
    )
}

ImageWithActions.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.node),
    handleOnClickImage: PropTypes.func,
    thumbBlob: PropTypes.string,
    thumbLoading: PropTypes.bool
}

ImageWithActions.defaultProps = {
    actions: [],
    handleOnClickImage: () => {},
    thumbBlob: '',
    thumbLoading: false
}

export default ImageWithActions
