import React from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import StaffFormContainer from './StaffFormContainer'

class StaffEditPage extends React.Component {
    componentDidMount() {
        document.title = 'Staff | OSH CRM'
    }


    render () {
        const { id } = this.props.match.params

        return (
            <Layout>
                    <LayoutContentWrapper breadcrumbs={[
                        { path: '/staffs', name: 'Staffs' },
                        { path: `/staffs/${id}`, name: id },
                        { path: `/staffs/${id}/edit`, name: 'Edit' }
                    ]}>
                        <ContentLayout>
                            <StaffFormContainer id={id} />
                        </ContentLayout>
                    </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withRouter(withTranslation('common')(StaffEditPage))
