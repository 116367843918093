import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import CustomerOrderPaymentDetailsList from '../../../components/customers/orderPaymentDetails/orderPaymentDetailsList'

import actions from '../../../redux/customers/orderPaymentDetails/actions'

const { fetchCustomerOrderPaymentDetails } = actions

class CustomerOrderPaymentDetailsListConatiner extends Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired
  }

  componentDidMount () {
      const { fetchCustomerOrderPaymentDetails, customerId } = this.props

      fetchCustomerOrderPaymentDetails({ customerId })
  }

  getOrderPaymentDetails () {
    const { customerOrderPaymentDetails, orderPaymentDetailEntities } = this.props

    return customerOrderPaymentDetails.get('items', new List()).map((id) => {
        return orderPaymentDetailEntities.get(id) || new Map()
    })
  }

  handlePageChange = (pagination) => {
    const { fetchCustomerOrderPaymentDetails, customerId } = this.props

    fetchCustomerOrderPaymentDetails({ customerId, page: pagination.current })
  }


  render() {
    const { customerOrderPaymentDetails } = this.props

    return (
      <CustomerOrderPaymentDetailsList
        loading={customerOrderPaymentDetails.get('loading')}
        onPageChange={this.handlePageChange}
        orderPaymentDetails={this.getOrderPaymentDetails().toJS()}
        pagination={{
          ...customerOrderPaymentDetails.get('pagination').toJS(),
          showSizeChanger: false
        }}/>
    )
  }
}

const mapStateToProps = state => ({
  customerOrderPaymentDetails: state.get('customerOrderPaymentDetails'),
  orderPaymentDetailEntities: state.getIn(['Entities', 'orderPaymentDetails'])
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      fetchCustomerOrderPaymentDetails,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerOrderPaymentDetailsListConatiner)