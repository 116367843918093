import React from 'react'

import { Divider, Space, Typography } from 'antd'
import { LinkOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons'
import ActivityDetail from './ActivityDetail'
import CardWarper from './card.style'

import {
  formatNumber,
  diffInDate,
  formatDate
} from '../../../../utilties/number'
import lineLogoImage from '../../../../../public/images/base/line-logo.png'
import OrderStateDropdown from '../../../orders/stateDropdown'

const { Text, Link } = Typography

const renderPrice = (grandTotal) => {
  if (grandTotal) {
    return <strong>฿ {grandTotal.display}</strong>
  }

  return <span className={'no-price'}>ยังไม่มีราคา</span>
}

const dateDistanceClass = (dateDistance) => {
  if (dateDistance > 4) {
    return 'danger'
  } else if (dateDistance > 2) {
    return 'warning'
  } else {
    return 'success'
  }
}

const renderDateDistance = (date) => {
  const dateDistance = diffInDate(date)
  return (
    <Text type={dateDistanceClass(dateDistance)}>
      {`(${dateDistance} วัน) `}
    </Text>
  )
}

const renderLineMessageUrl = (customer) => {
  if (!customer) return null
  if (!customer.lineUrl) return null

  return (
    <Link href={customer.lineUrl} target='_blank' style={{ marginRight: 8 }}>
      <img src={lineLogoImage} height={16} />
    </Link>
  )
}

const Card = ({ deal, refetch }) => {
  const {
    customer,
    grandTotal,
    note,
    number,
    paymentMethod,
    state,
    stateLabel
  } = deal.order

  if (!customer) return null

  const { customerNo, fullName } = customer

  return (
    <CardWarper>
      <div>
        <div className='space-align-block'>
          <div>
            <div>
              <Link
                href={`/inquiries/${number}`}
                target='_blank'
                style={{ marginRight: 8 }}>
                <strong>{number} </strong>
              </Link>
            </div>
            <div className='text-align-left'>
              <Link
                href={`/customers/${customerNo}`}
                target='_blank'
                style={{ marginRight: 8 }}>
                {fullName}
              </Link>
            </div>
          </div>

          <div className='ml-auto'>
            <div className='text-align-right'>
              <OrderStateDropdown
                orderId={number}
                paymentMethod={paymentMethod}
                state={state}
                stateLabel={stateLabel}
              />
            </div>
            <div className='text-align-right'>
              {renderLineMessageUrl(customer)}
              <Link
                href={`/customers/${customerNo}`}
                target='_blank'
                style={{ marginRight: 8 }}>
                <UserOutlined /> {customer.phoneNumber}
              </Link>
              <Link href={`/inquiries/${number}`} target='_blank'>
                <LinkOutlined />
              </Link>
            </div>
          </div>
        </div>
        <Divider className='card-divider' />
        <div>{note}</div>
        <Divider className='card-divider' />
        <div className='space-align-block'>
          <Space align='start'>
            <strong>{renderPrice(grandTotal)}</strong>
          </Space>

          <div className='ml-auto'>
            {renderDateDistance(deal.assignDate)}
            {formatDate(deal.assignDate)}
          </div>
        </div>

        <ActivityDetail
          deal={deal}
          customerNo={customerNo}
          orderId={number}
          refetch={refetch}
        />
      </div>
    </CardWarper>
  )
}

export default Card
