import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import { Modal } from 'antd'

import CustomerWalletsIndex from '../../components/customerWallets/CustomerWalletsIndex'
import CustomerWalletFormContainer from '../../containers/CustomerWallets/CustomerWalletFormContainer'

import actions from '../../redux/customers/wallets/actions'
import customerActions from '../../redux/customers/actions'
const { deleteCustomerWallet, fetchCustomerWallets, saveCustomerWallet, setCustomerWalletFormModal } = actions
const { fetchCustomer } = customerActions

class CustomerWalletsIndexContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,

        customerEntities: ImmutablePropTypes.map.isRequired,
        customers: ImmutablePropTypes.map.isRequired,
        customerWalletEntities: ImmutablePropTypes.map.isRequired,
        customerWallets: ImmutablePropTypes.map.isRequired,
        deleteCustomerWallet: PropTypes.func.isRequired,
        setCustomerWalletFormModal: PropTypes.func.isRequired,
        fetchCustomer: PropTypes.func.isRequired,
        fetchCustomerWallets: PropTypes.func.isRequired,
        saveCustomerWallet: PropTypes.func.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        const { customerId, fetchCustomer } = this.props

        fetchCustomer(customerId)
    }

    fetchCustomerWallets = (args) => {
        const { customerId, fetchCustomerWallets } = this.props

        fetchCustomerWallets({ ...args, customerId })
    }

    getCustomer () {
        const { customerEntities, customerId } = this.props
        return customerEntities.get(customerId) || new Map()
    }

    handleChangeApproveWallet = ({ customerId, id, checked }) => {
        const { fetchCustomer, saveCustomerWallet } = this.props

        saveCustomerWallet({
            customerId,
            id,
            params: { is_approved: checked },
            onSuccess: () => {
                fetchCustomer(customerId)
            }
        })
    }

    handleDelete = (args) => {
        const { customerId, deleteCustomerWallet, fetchCustomer } = this.props

        deleteCustomerWallet({
            ...args,
            customerId,
            onSuccess: () => {
                fetchCustomer(customerId)
            }
        })
    }

    onSaveSuccess = () => {
        const { setCustomerWalletFormModal, fetchCustomer, customerId } = this.props
        setCustomerWalletFormModal(false, null)
        fetchCustomer(customerId)
    }

    render () {
        const { customerId, customers, customerWallets, setCustomerWalletFormModal } = this.props
        const customerWalletId = customerWallets.get('walletIdSelected')

        return (
            <div>
                <CustomerWalletsIndex
                    customer={this.getCustomer().toJS()}
                    customerLoading={customers.get('loading')}
                    fetchCustomerWallets={this.fetchCustomerWallets}
                    loading={customerWallets.get('loading')}
                    pagination={{
                        ...customerWallets.get('pagination').toJS(),
                        showSizeChanger: false
                    }}
                    onChangeApprove={this.handleChangeApproveWallet}
                    onDelete={this.handleDelete}
                    setCustomerWalletFormModal={setCustomerWalletFormModal}
                    walletIds={customerWallets.get('items')}
                />
                <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={() => setCustomerWalletFormModal(false)}
                    visible={customerWallets.get('showWalletForm')}>
                    <CustomerWalletFormContainer isEdit={!!customerWalletId}
                        customerId={customerId}
                        onSuccess={this.onSaveSuccess}
                        customerWalletId={customerWallets.get('walletIdSelected')} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    customers: state.get('customers'),
    customerWalletEntities: state.getIn(['Entities', 'customerWallets']),
    customerWallets: state.get('customerWallets')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteCustomerWallet,
        setCustomerWalletFormModal,
        fetchCustomer,
        fetchCustomerWallets,
        saveCustomerWallet
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerWalletsIndexContainer)
