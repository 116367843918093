import React, { Component } from 'react'

import TaxInvoicesIndexContainer from '../../TaxInvoices/TaxInvoicesIndexContainer'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

class OrderBusinessAddressesContainer extends Component {
    render () {
        const { customerId, orderBusinessAddresses, i18n } = this.props
        return (
            <div>
                <div>{i18n.t('confirms:accounting.amount')} : {orderBusinessAddresses.getIn(['additionalData', 'sumOfOrderBusinessAddresses'])} ฿</div>
                <div>{i18n.t('confirms:sizeList')} : {orderBusinessAddresses.getIn(['additionalData', 'sizeOfOrderBusinessAddresses'])}</div>
                <TaxInvoicesIndexContainer
                    customerId={customerId}
                    showFilterSection={false}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderBusinessAddresses: state.get('orderBusinessAddresses'),
    }
}

export default connect(mapStateToProps)(withTranslation(['confirms'])(OrderBusinessAddressesContainer))
