import styled from 'styled-components'

const StyledWrapper = styled.div`
  margin: 12px 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .flex-box {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .title {
    font-weight: 600;
  }

  .ant-table-row {
    .ant-table-cell {
      padding: 4px 8px;

      .label {
        color: #969696;
        font-weight: 400;
      }
    }

    &:last-child {
      .ant-table-cell { border: none; }
    }
  }
`

export default StyledWrapper
