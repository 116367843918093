import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Col, DatePicker, Row, Spin, Statistic } from 'antd'

import { displayNumberWithSymbol } from '../../helpers/number'

const BankStatementSummary = ({
    bankStatementSummary,
    loading,
    onChangeMonth
}) => {
    const {
        sumOfNotReconciledDebitAmounts,
        sumOfNotReconciledCreditAmounts,
        sumOfReconciledDebitAmounts,
        sumOfReconciledCreditAmounts
    } = bankStatementSummary

    return (
        <Spin spinning={loading}>
            <Row gutter={16}>
                <Col span={5}>
                    <Statistic title="Sum Of Not Reconciled Debit Amounts"
                        value={displayNumberWithSymbol(sumOfNotReconciledDebitAmounts)}
                        prefix="฿" />
                </Col>
                <Col span={5}>
                    <Statistic title="Sum Of Not Reconciled Credit Amounts"
                        value={displayNumberWithSymbol(sumOfNotReconciledCreditAmounts)}
                        prefix="฿" />
                </Col>
                <Col span={5}>
                    <Statistic title="Sum Of Reconciled Debit Amounts"
                        value={displayNumberWithSymbol(sumOfReconciledDebitAmounts)}
                        prefix="฿" />
                </Col>
                <Col span={5}>
                    <Statistic title="Sum Of Reconciled Credit Amounts"
                        value={displayNumberWithSymbol(sumOfReconciledCreditAmounts)}
                        prefix="฿" />
                </Col>
                <Col span={4}>
                    <DatePicker.MonthPicker
                        defaultValue={moment()}
                        onChange={onChangeMonth} />
                </Col>
            </Row>
        </Spin>
    )
}

BankStatementSummary.propTypes = {
    bankStatementSummary: PropTypes.object,
    loading: PropTypes.bool,
    onChangeMonth: PropTypes.func.isRequired
}

BankStatementSummary.defaultProps = {
    bankStatementSummary:{}
}

export default BankStatementSummary
