import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

export const Authorization = currentStaffContainer(class App extends Component {
    static propTypes = {
        // Props from another component
        allowedRoles: PropTypes.array,
        notAllowedRoles: PropTypes.array,
        redirectWhenNotAllowed: PropTypes.bool,

        // Inner Props
        currentStaff: ImmutablePropTypes.map.isRequired,
        currentStaffRoles: ImmutablePropTypes.list.isRequired
    }

    static defaultProps = {
        allowedRoles: [],
        notAllowedRoles: [],
        redirectWhenNotAllowed: false
    }

    render () {
        const {
            allowedRoles,
            children,
            currentStaff,
            notAllowedRoles,
            redirectWhenNotAllowed,
            rolesMatched
        } = this.props

        if (!currentStaff.size) return null

        const matchAllowed = allowedRoles.length ? rolesMatched(allowedRoles) : true
        const matchNotAllowed = notAllowedRoles.length ? rolesMatched(notAllowedRoles) : false

        if (matchAllowed && !matchNotAllowed) return children
        if (redirectWhenNotAllowed) return <Redirect to="/" />

        return null
    }
})

export const withAuthorization = (
    WrappedComponent,
    allowedRoles,
    notAllowedRoles,
    {
        redirectWhenNotAllowed = true
    } = {}
) => {
    return class WithAuthorization extends Component {
        render () {
            return (
                <Authorization
                    allowedRoles={allowedRoles}
                    notAllowedRoles={notAllowedRoles}
                    redirectWhenNotAllowed={redirectWhenNotAllowed}
                >
                    <WrappedComponent {...this.props} />
                </Authorization>
            )
        }
    }
}
