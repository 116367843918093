import { PAGE_SIZE } from '../../../constants/customers'

const actions = {
    CUSTOMER_WALLETS_FETCH_REQUEST: 'CUSTOMER_WALLETS_FETCH_REQUEST',
    CUSTOMER_WALLETS_FETCH: 'CUSTOMER_WALLETS_FETCH',
    CUSTOMER_WALLETS_FETCH_SUCCESS: 'CUSTOMER_WALLETS_FETCH_SUCCESS',

    CUSTOMER_WALLET_SAVE_REQUEST: 'CUSTOMER_WALLET_SAVE_REQUEST',
    CUSTOMER_WALLET_SAVING: 'CUSTOMER_WALLET_SAVING',
    CUSTOMER_WALLET_SET_FORM_ERRORS: 'CUSTOMER_WALLET_SET_FORM_ERRORS',
    CUSTOMER_WALLET_SAVE_SUCCESS: 'CUSTOMER_WALLET_SAVE_SUCCESS',

    CUSTOMER_WALLET_DELETE_REQUEST: 'CUSTOMER_WALLET_DELETE_REQUEST',
    CUSTOMER_WALLET_DELETE: 'CUSTOMER_WALLET_DELETE',
    CUSTOMER_WALLET_DELETE_SUCCESS: 'CUSTOMER_WALLET_DELETE_SUCCESS',

    CUSTOMER_WALLETS_SET_PAGINATION: 'CUSTOMER_WALLETS_SET_PAGINATION',

    CUSTOMERS_WALLET_SET_FORM_MODAL: 'CUSTOMERS_WALLET_SET_FORM_MODAL',

    CUSTOMER_WALLET_FETCH_REQUEST: 'CUSTOMER_WALLET_FETCH_REQUEST',
    CUSTOMER_WALLET_FETCH: 'CUSTOMER_WALLET_FETCH',
    CUSTOMER_WALLET_FETCH_SUCCESS: 'CUSTOMER_WALLET_FETCH_SUCCESS',

    CUSTOMER_WALLET_SET_SHOW_AMOUNT_FORM: 'CUSTOMER_WALLET_SET_SHOW_AMOUNT_FORM',

    ORDER_CUSTOMER_WALLET_SAVE_REQUEST: 'ORDER_CUSTOMER_WALLET_SAVE_REQUEST',
    ORDER_CUSTOMER_WALLET_SAVING: 'ORDER_CUSTOMER_WALLET_SAVING',
    ORDER_CUSTOMER_WALLET_SAVE_SUCCESS: 'ORDER_CUSTOMER_WALLET_SAVE_SUCCESS',
    ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS: 'ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS',

    ORDER_CUSTOMER_WALLETS_FETCH_REQUEST: 'ORDER_CUSTOMER_WALLETS_FETCH_REQUEST',
    ORDER_CUSTOMER_WALLETS_FETCH: 'ORDER_CUSTOMER_WALLETS_FETCH',
    ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS: 'ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS',

    ORDER_CUSTOMER_WALLET_DELETE_REQUEST: 'ORDER_CUSTOMER_WALLET_DELETE_REQUEST',
    ORDER_CUSTOMER_WALLET_DELETE: 'ORDER_CUSTOMER_WALLET_DELETE',
    ORDER_CUSTOMER_WALLET_DELETE_SUCCESS: 'ORDER_CUSTOMER_WALLET_DELETE_SUCCESS',

    CUSTOMER_WALLET_SET_SHOW_BANK_DETAIL_FORM: 'CUSTOMER_WALLET_SET_SHOW_BANK_DETAIL_FORM',

    fetchCustomerWallets: ({ customerId, page = 1, per = PAGE_SIZE } = {}) => ({
        type: actions.CUSTOMER_WALLETS_FETCH_REQUEST,
        payload: { customerId, page, per }
    }),

    saveCustomerWallet: ({
        customerId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.CUSTOMER_WALLET_SAVE_REQUEST,
            payload: { customerId, id, params, onSuccess, onError }
        }
    },

    deleteCustomerWallet: ({ customerId, id, onSuccess = () => {} } = {}) => ({
        type: actions.CUSTOMER_WALLET_DELETE_REQUEST,
        payload: { customerId, id, onSuccess }
    }),

    setCustomerWalletFormModal: (visible, id) => ({
        type: actions.CUSTOMERS_WALLET_SET_FORM_MODAL,
        visible, id
    }),

    fetchCustomerWallet: (customerId, id) => ({
        type: actions.CUSTOMER_WALLET_FETCH_REQUEST,
        payload: { customerId, id }
    }),

    resetCustomerWalletFormErrors: (formErrors = {}) => ({
        type: actions.CUSTOMER_WALLET_SET_FORM_ERRORS,
        formErrors
    }),

    saveOrderCustomerWallet: ({
        orderId,
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.ORDER_CUSTOMER_WALLET_SAVE_REQUEST,
            payload: { orderId, id, params, onSuccess, onError }
        }
    },

    fetchOrderCustomerWallets: (orderId) => ({
        type: actions.ORDER_CUSTOMER_WALLETS_FETCH_REQUEST,
        payload: { orderId }
    }),

    deleteOrderCustomerWallet: ({ orderId, id, onSuccess = () => {} } = {}) => ({
        type: actions.ORDER_CUSTOMER_WALLET_DELETE_REQUEST,
        payload: { orderId, id, onSuccess }
    }),

    setShowAmountInput: (value) => ({
        type: actions.CUSTOMER_WALLET_SET_SHOW_AMOUNT_FORM,
        value
    }),

    setShowBankDetailInput: (value) => ({
        type: actions.CUSTOMER_WALLET_SET_SHOW_BANK_DETAIL_FORM,
        value
    }),
}

export default actions
