const actions = {
    OCCUPATIONS_FETCH: 'OCCUPATIONS_FETCH',
    OCCUPATIONS_FETCH_REQUEST: 'OCCUPATIONS_FETCH_REQUEST',
    OCCUPATIONS_FETCH_SUCCESS: 'OCCUPATIONS_FETCH_SUCCESS',
    OCCUPATIONS_SAVE_REQUEST: 'OCCUPATIONS_SAVE_REQUEST',
    OCCUPATIONS_SAVE: 'OCCUPATIONS_SAVE',
    OCCUPATIONS_SAVE_SUCCESS: 'OCCUPATIONS_SAVE_SUCCESS',
    OCCUPATIONS_SET_FORM_ERRORS: 'OCCUPATIONS_SET_FORM_ERRORS',
    OCCUPATIONS_CLEAR_FORM_ERRORS: 'OCCUPATIONS_CLEAR_FORM_ERRORS',

    saveOccupation: (occupation) => ({
        type: actions.OCCUPATIONS_SAVE_REQUEST,
        payload: { occupation }
    }),

    fetchOccupations: () => ({
        type: actions.OCCUPATIONS_FETCH_REQUEST
    }),

    clearOccupationsErrors: () => ({
        type: actions.OCCUPATIONS_CLEAR_FORM_ERRORS
    })
}

export default actions
