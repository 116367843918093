import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { apiFetch } from '../../helpers/restRequest'
import _ from 'lodash'

import actions from './actions'

import { PAGE_SIZE } from '../../constants/orders'
import { supplierOrderReviewsSchema, supplierOrderReviewSchema } from '../schema'

const {
    SUPPLIER_ORDER_REVIEWS_FETCH_REQUEST,
    SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS,
    SUPPLIER_ORDER_REVIEWS_FETCH,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_REQUEST,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING,
    SUPPLIER_ORDER_REVIEWS_SET_PAGINATION
} = actions

export function *supplierOrderReviewsRequest () {
    yield takeEvery(SUPPLIER_ORDER_REVIEWS_FETCH_REQUEST, function *({ payload }) {
        const {
            query,
            page,
            orderState,
            purchaser,
            supplier,
            dateType,
            startDate,
            endDate,
            sorter,
            isChecked,
            isPoCompleted,
            isNotWorking,
            dueDate
        } = payload

        const params = [
            `query=${_.toString(query)}`,
            `page=${_.toString(page)}`,
            `per=${PAGE_SIZE}`,
            `state=${_.toString(orderState)}`,
            `by_purchaser=${_.toString(purchaser)}`,
            `supplier_id_is=${_.toString(supplier)}`,
            `date_type=${_.toString(dateType)}`,
            `start_date=${_.toString(startDate)}`,
            `end_date=${_.toString(endDate)}`,
            `order=${_.get(sorter, 'value', '')}`,
            `is_po_checked_are=${_.toString(isChecked)}`,
            `is_po_completed_are=${_.toString(isPoCompleted)}`,
            `is_not_working_yet=${_.toString(isNotWorking)}`,
            `supplier_order_due_date=${_.toString(dueDate)}`
        ].join('&')

        const path = `/supplier_order_reviews?${params}`

        yield put({ type: SUPPLIER_ORDER_REVIEWS_FETCH })

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierOrderReviewsSchema)

        const pageSize = data.meta.page_size
        const total = data.meta.total_pages * pageSize

        yield put({
            type: SUPPLIER_ORDER_REVIEWS_SET_PAGINATION,
            pagination: { current: page, total, pageSize }
        })

        yield put({
            type: SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS,
            ...normalizeData,
            query,
            orderState,
            purchaser,
            supplier,
            dateType,
            startDate,
            endDate,
            isChecked,
            isPoCompleted,
            isNotWorking,
            dueDate
        })
    })
}

export function *supplierOrderReviewsUpdateOrderSaga () {
    yield takeLatest(SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_REQUEST, function *({ payload }) {
        const { orderId, orderParams, onSuccess, onError } = payload
        yield put({ type: SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING })

        try {
            const path = `/supplier_order_reviews/${orderId}`
            const body = JSON.stringify({ order: orderParams })
            const data = yield call(apiFetch, path, 'PATCH', { body })

            if ('error' in data) {
                throw data
            } else {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, supplierOrderReviewSchema)

                yield put({
                    type: SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS,
                    ...normalizedData
                })

                onSuccess()
            }
        } catch (error) {
            yield put({ type: SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(supplierOrderReviewsRequest),
        fork(supplierOrderReviewsUpdateOrderSaga)
    ])
}
