import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import supplierOrderActions from '../../redux/supplierOrders/actions'
import SelectSearchSupplierOrder from '../../components/SelectSearchSupplierOrder'

const {
    fetchSupplierOrderThroughQuery
} = supplierOrderActions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectSearchSupplierOrderContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner props
        fetchSupplierOrderThroughQuery: PropTypes.func.isRequired,
        supplierOrdersEntities: ImmutablePropTypes.map.isRequired,
        supplierOrders: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    getSupplierOrders () {
        const { supplierOrdersEntities, supplierOrders } = this.props

        return supplierOrders.get('items', new List()).map((id) => {
            return supplierOrdersEntities.get(_.toString(id), new Map())
        })
    }

    onChange = (orderId) => {
        const { onChange } = this.props

        onChange(orderId)
        this.setState({ value: orderId })
    }

    onSearch = (query) => {
        const { fetchSupplierOrderThroughQuery } = this.props

        fetchSupplierOrderThroughQuery(query)
    }

    render () {
        const {
            onChange,
            supplierOrders,
            value: propValue,
            ...restProps
        } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue
        return (
            <SelectSearchSupplierOrder
                loading={supplierOrders.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                supplierOrders={this.getSupplierOrders().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        supplierOrdersEntities: state.getIn(['Entities', 'supplierOrders']),
        supplierOrders: state.get('supplierOrders')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplierOrderThroughQuery
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSearchSupplierOrderContainer)
