import React from 'react'
import { useTranslation } from 'react-i18next'
import ImmutablePropTypes from 'react-immutable-proptypes'

import moment from 'moment'

import { Divider, Popover } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'

const renderSupplierOrderDetail = (supplierOrderIds) => {
    const { i18n, supplierOrderEntities } = this.props
    const supplierOrderList = supplierOrderIds.map((supplierOrderId) => {
        return supplierOrderEntities.get(supplierOrderId) || new Map()
    })

    return supplierOrderList.map((supplierOrder) => {
        const transferDate = supplierOrder.get('transfer_date')
        return (
            <div>
                <div style={{ fontWeight: 'bold' }}>{supplierOrder.get('supplier_name')}</div>
                <div>
                    <span>{i18n.t('inquiries:transferDate')} : </span>
                    {transferDate ? moment(transferDate, 'YYYY-MM-DD').format('D MMMM YYYY') : '-'}
                </div>
                <Divider style={{ margin: '4px 0' }}/>
            </div>
        )
    })
}

const renderShippingDate = (i18n, orderEntity) => {
    const shippingDate = orderEntity.getIn(['time', 'shipping_date'])
    moment.locale(i18n.language)
    if (!shippingDate) {
        return <div>{i18n.t('inquiries:pleaseInputShippingDate')}</div>
    }

    return (
        <div>
            <div>
                <span style={{ fontWeight: 'bold' }}>{i18n.t('inquiries:shippingDate')} </span>
                {shippingDate ? moment(shippingDate, 'YYYY-MM-DD').format('D MMMM YYYY') : ''}
                <div>
                    {orderEntity.get('shipping_time') && i18n.t(`inquiries:shippingTime.${orderEntity.get('shipping_time')}`)}
                </div>
            </div>
            {orderEntity.get('supplier_orders') ? <Divider style={{ margin: '4px 0' }}/> : ''}
            <div>{renderSupplierOrderDetail(orderEntity.get('supplier_orders'))}</div>
        </div>
    )
}

const ShippingDate = ({ orderEntity }) => {
    const { i18n } = useTranslation('inquiries')

    if (orderEntity.get('state') !== 'payment') return null

    return (
        <Popover key='info_popover' placement="left" content={renderShippingDate(i18n, orderEntity)}>
            <InfoCircleTwoTone style={{ marginLeft: '4px' }}/>
        </Popover>
    )
}

ShippingDate.propTypes = {
    orderEntity: ImmutablePropTypes.map.isRequired
}

ShippingDate.defaultProps = {}

export default ShippingDate
