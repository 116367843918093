import React, { useState} from 'react'
import { gql, useQuery } from '@apollo/client'

import LoadingSpinner from '../../components/loadingSpinner'
import Ticket from '../../components/tickets/ticket'

import { safeGet } from '../../helpers/string'

const TICKET_QUERY = gql`
	query Ticket($id: ID!, $first: Int, $after: String) {
		ticket(id: $id) {
            id
            ticketNo
            assignToOsh
            description
            source
            sourceLabel
            state
            stateLabel
            ticketType
            ticketTypeLabel
            categoryType
            categoryTypeLabel
            subCategoryType
            subCategoryTypeLabel
            createdAt {
                display
                timeAgo
            }
            category {
                id
                name
            }
            customer {
                id
                customerNo
                phoneNumber
                fullName
                lineUrl
            }
            province {
                id
                name
            }
            district {
                id
                name
            }
            orders {
                id
                number
            }
            ticketMemberAgent {
                id
                staff {
                    id
                    firstName
                    fullName
                }
            }
            memberStaffs {
                id
                firstName
                fullName
            }
            ticketNotes(first: $first, after: $after) {
                nodes {
                    id
                    note
                    noteType
                    staff {
                        id
                        firstName
                        fullName
                    }
                    createdAt {
                        display
                    }
                    images {
                        id
                        url
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
		}
	}
`

const LIMIT = 10

const TicketContainer = ({ id }) => {
    const [loadMore, setLoadMore] = useState(false)
    const { loading, data, error, refetch, fetchMore } = useQuery(TICKET_QUERY, {
        variables: { id: id, first: LIMIT, answerFirst: LIMIT }
    })

    const onFetchMoreNote = (pageInfo, prevTicketNotes) => {
        if (loadMore || !pageInfo.hasNextPage) { return null }

        setLoadMore(true)
        fetchMore({
            variables: { id: id, first: LIMIT, after: pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }) => {
                setLoadMore(false)
                if (!fetchMoreResult) { return prev }
                const result = fetchMoreResult.ticket

                return {
                    ticket: {
                        ...result,
                        ticketNotes: {
                            nodes: [...prevTicketNotes, ...(result.ticketNotes.nodes || [])],
                            pageInfo: result.ticketNotes.pageInfo
                        }
                    }
                }
            }
        })
    }

    if (loading) return <LoadingSpinner />
    if (error) return console.error(error)

    return (
        <Ticket
            refetchTicket={refetch}
            ticket={safeGet(data, 'ticket', {})}
            loading={loading}
            onFetchMoreNote={onFetchMoreNote}
        />
    )
}

TicketContainer.propTypes = {}

TicketContainer.defaultProps = {}

export default TicketContainer
