import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Button, Form, Input, Spin, Select } from 'antd'

const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
}

const ChartOfAccountForm = ({
    errors,
    i18n,
    loading,
    onSubmitForm,
    saving,
    toggleForm,
    chartOfAccount,
    chartOfAccountTypes,
    typesLoading
}) => {
    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    const chartOfAccountOptions = () => {
      return chartOfAccountTypes.map((type, index) => {
          return (
              <Option key={index} value={type}>
                {i18n.t(`chartOfAccounts:types.${_.camelCase(type)}`)}
              </Option>
          )
      })
  }

    return (
        <div>
            <h2>{i18n.t('chartOfAccounts:chartOfAccount')}</h2>
            <Spin spinning={loading}>
                <Form onFinish={handleSubmitForm} {...formItemLayout}>
                    <Form.Item
                        name='account_number'
                        initialValue={_.get(chartOfAccount, 'account_number', null)}
                        label={i18n.t('chartOfAccounts:accountNumber')}
                        validateStatus={_.get(errors, 'account_number') && 'error'}
                        help={_.get(errors, 'account_number')}
                    >
                            <Input />
                    </Form.Item>

                    <Form.Item
                        name='account_name'
                        initialValue={_.get(chartOfAccount, 'account_name', null)}
                        label={i18n.t('chartOfAccounts:accountName')}
                        validateStatus={_.get(errors, 'account_name') && 'error'}
                        help={_.get(errors, 'account_name')}
                    >
                            <Input />
                    </Form.Item>

                    <Form.Item
                        name='chart_of_account_type'
                        initialValue={_.get(chartOfAccount, 'chart_of_account_type', null)}
                        label={i18n.t('chartOfAccounts:type')}
                        validateStatus={_.get(errors, 'chart_of_account_type') && 'error'}
                        help={_.get(errors, 'chart_of_account_type')}
                    >
                          <Select style={{ width: '100%' }} allowClear loading={typesLoading}>
                              {chartOfAccountOptions()}
                          </Select>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                        <Button htmlType="button"
                          style={{ marginLeft: '12px'}}
                          onClick={toggleForm}>
                            {i18n.t('shared:close')}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

ChartOfAccountForm.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired,
    withholdingTax: PropTypes.object
}

ChartOfAccountForm.defaultProps = {}

export default withTranslation('chartOfAccounts')(ChartOfAccountForm)

