import styled from 'styled-components'

export default styled.div`
  background-color: #ebecf0;
  padding: 8px;
  .scroll-content {
    height: calc(100vh - 240px);
  }
  .header {
    margin-bottom: 4px;
  }

  .filter-container {
    display: flex;
    cursor: pointer;
    margin: 0 0 8px 0;
    text-align: right;
    margin-left: auto;
  }
`
