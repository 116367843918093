import React, { Component } from 'react'
import {connect, useSelector} from 'react-redux'
import { bindActionCreators } from 'redux'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import {Avatar, Button, Card, Divider, Form, Modal, Select, Table, Tag, Tooltip, Typography} from 'antd'
import {
    CarOutlined,
    CloseOutlined,
    EditTwoTone,
    EyeOutlined,
    PlusSquareTwoTone,
    LoadingOutlined,
    CalculatorOutlined,
    EditOutlined,
    FileTextOutlined,
    CopyOutlined,
    PrinterOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import SuppliersOrdersFormContainer from '../../Suppliers/Orders/SuppliersOrdersFormContainer'
import Image from '../../../containers/Image'
import ImagePreviewContainer from '../../../containers/ImagePreview/ImagePreviewContainer'
import OrderSupplierNoteFormContainer from '../../Orders/SupplierNoteFormContainer'
import SupplierPurchaseListWrapper from './supplierPurchaseList.style'
import OrderSupplierNoteImage from '../../../containers/PurchaseOrders/OrderSupplierNote/Image'
import currentStaffContainer from '../../CurrentStaff/CurrentStaffContainer'

import PurchaseOrderForm from '../../PurchaseOrderForm'
import PurchaseOrderStatusSwitches from '../../PurchaseOrderStatusSwitches'
import SupplierOverTransferItemFormContainer from '../SupplierOverTransferItemFormContainer'

import lineLogo from '../../../../public/images/base/line-logo.png'
import { PO_DOCUMENT_HEADERS } from '../../../constants/supplierOrders'
import supplierActions from '../../../redux/suppliers/actions'
import supplierOrderActions from '../../../redux/supplierOrders/actions'
import orderActions from '../../../redux/orders/actions'
import inquiryActions from '../../../redux/inquiry/actions'
import transferBackActions from '../../../redux/inquiry/transferBacks/actions'
import notificationActions from "../../../redux/notifications/actions"
import OrderTransferBackForm from "../../Inquiry/OrderTransferBackForm";
import { CopyToClipboard } from 'react-copy-to-clipboard'
const { setNotifications } = notificationActions
const { fetchInquiry } = inquiryActions

const {
    fetchSupplierOrders,
    saveSupplierOrders,
    toggleCreatePurchase,
    togglePODocumentHeader,
    togglePOForm
} = supplierActions

const {
    cancelSupplierOrder,
    downloadShippingInvoicePDF,
    setOrderSupplierNoteFormModal,
    setSupplierOverTransferFormModal
} = supplierOrderActions

const {
    culculateTransferBackTotal,
    setShowShippingCostForm,
    setShowTransferBackForm,
    resetTransferBackItemsSelected,
    saveOrderTransferBack,
    fetchOrderTransferBacks
} = transferBackActions

const { fetchOrderSupplierNotes } = orderActions

class SuppliersOrdersIndexContainer extends Component {
    static propTypes = {
        purchaseOrderStatusHistoryEntities: ImmutablePropTypes.map.isRequired,
        staffEntities: ImmutablePropTypes.map.isRequired,
        supplierEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        supplierOrders: ImmutablePropTypes.map.isRequired,
        supplierOverTransferAccountEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferItemsEntitles: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired,
        truckEntities: ImmutablePropTypes.map.isRequired,

        fetchSupplierOrders: PropTypes.func.isRequired,
        saveSupplierOrders: PropTypes.func.isRequired,
        toggleCreatePurchase: PropTypes.func.isRequired,
        togglePODocumentHeader: PropTypes.func.isRequired
    }

    async componentDidMount () {
        const { fetchSupplierOrders, fetchOrderSupplierNotes, fetchOrderTransferBacks, orderId } = this.props

        await fetchSupplierOrders(orderId)
        await fetchOrderSupplierNotes(orderId)
        await fetchOrderTransferBacks(orderId)
    }

    renderCancelButton = (supplierOrder) => {
        const transferDate = supplierOrder.transfer_date
        const today = moment().format("YYYY-MM-DD")

        var dateDiff = Math.ceil((Date.parse(transferDate) - Date.parse(today)) / 86400000)

        const { cancelSupplierOrder, i18n } = this.props
        const state = supplierOrder.state

        if ((dateDiff > 0 && state === 'chosen_by_osh') || state === 'get_order') {
            return (
                <Tooltip title={i18n.t('suppliers:cancel')}>
                    <Button
                        type="danger"
                        icon={<CloseOutlined />}
                        onClick={() => {cancelSupplierOrder(supplierOrder.id, supplierOrder.order)}}
                    />
                </Tooltip>
            )
        } else { return }
    }

    renderCreatePurchase = async (supplierOrder) => {
        const { fetchSupplierOrders, toggleCreatePurchase, suppliers, orderId, supplierOrderEntities } = this.props
        const isOpenCreatePurchase = suppliers.get('isOpenCreatePurchase')

        if (supplierOrder) {
            const isNotGetOrder = supplierOrderEntities.getIn([supplierOrder.id, 'state']) !== "get_order"
            this.supplierOrderItem = await (
                <SuppliersOrdersFormContainer
                    orderId={orderId}
                    disabled={isNotGetOrder}
                    supplierOrderId={supplierOrder.id}
                    isSupplierPurchase
                    isNewPage={false}
                    formType={supplierOrder.supplier_order_type}
                    onSaveSuccess={() => {
                        toggleCreatePurchase(false)
                        fetchSupplierOrders(orderId)
                    }} />
            )
        } else {
            this.supplierOrderItem = await (
                <SuppliersOrdersFormContainer
                    orderId={orderId}
                    disabled={false}
                    isSupplierPurchase
                    isNewPage
                    formType="product"
                    onSaveSuccess={() => {
                        toggleCreatePurchase(false)
                        fetchSupplierOrders(orderId)
                    }} />
            )
        }

        toggleCreatePurchase(!isOpenCreatePurchase)
    }

    renderOrderSupplierNoteForm = async (id) => {
        const { setOrderSupplierNoteFormModal, supplierOrders, orderId } = this.props
        const isOpenOrderSupplierNoteForm = supplierOrders.get('isOpenOrderSupplierNoteForm')

        if (id) {
            this.orderSupplierNote = await (
                <OrderSupplierNoteFormContainer id={id}
                    isShowSupplierOrders
                    supplierOrders={this.getSupplierOrder()}
                    orderId={orderId}
                    onSuccess={this.handleSaveSuccessOrderSupplierNote} />
            )
        } else {
            this.orderSupplierNote = await (
                <OrderSupplierNoteFormContainer
                    isShowSupplierOrders
                    supplierOrders={this.getSupplierOrder()}
                    orderId={orderId}
                    onSuccess={this.handleSaveSuccessOrderSupplierNote} />
            )
        }

        setOrderSupplierNoteFormModal(!isOpenOrderSupplierNoteForm)
    }

    getPODocumentHeaders () {
        return PO_DOCUMENT_HEADERS.map((poDocumentHeader) => {
            return (
                <Select.Option key={poDocumentHeader.value}
                    value={poDocumentHeader.value}>
                    {poDocumentHeader.i18n}
                </Select.Option>
            )
        })
    }

    handleSavePODocumentForm = (values, supplierOrderId) => {
        const { orderId, togglePODocumentHeader, saveSupplierOrders } = this.props

        saveSupplierOrders({
            orderId,
            id: supplierOrderId,
            supplierOrder: values,
            onSuccess: () => {
                togglePODocumentHeader(null)
            }
        })
    }

    renderDocumentHeaderForm = () => {
        const { i18n, supplierOrderEntities, suppliers, form } = this.props
        const supplierOrderId = suppliers.get('showDocumentSupplierOrderId')
        const supplierOrder = supplierOrderEntities.get(supplierOrderId) || new Map()

        return (
            <Form onFinish={(values) => this.handleSavePODocumentForm(values, supplierOrderId)}>
                <h4>{i18n.t('suppliers:supplierOrder.selectDocumentHeader')}</h4>
                <Form.Item name='po_document_header'
                    initialValue={supplierOrder.get('po_document_header')}>
                        <Select style={{ width: '100%' }}>
                            {this.getPODocumentHeaders()}
                        </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit"> Submit </Button>
            </Form>
        )
    }

    getSupplierOrder = () => {
        const {
            paymentVoucherEntities,
            purchaseOrderStatusHistoryEntities,
            staffEntities,
            supplierEntities,
            supplierOrderEntities,
            suppliers
        } = this.props

        return suppliers.get('supplierOrders').map((supplierOrderId) => {
            const supplierOrder = supplierOrderEntities.get(_.toString(supplierOrderId))

            if (!supplierOrder) return new Map()

            const supplier = supplierEntities.get(_.toString(supplierOrder.get('supplier')), new Map())
            const paymentVoucher = paymentVoucherEntities.get(supplierOrder.get('payment_voucher'), new Map())
            const isCheckedHistory = purchaseOrderStatusHistoryEntities.get(
                _.toString(supplierOrder.get('is_checked_history')),
                new Map()
            )

            return supplierOrder.mergeDeep(new Map({
                supplier: supplier,
                payment_voucher: paymentVoucher,
                is_checked_history: isCheckedHistory.merge({
                    staff: staffEntities.get(_.toString(isCheckedHistory.get('staff')), new Map())
                })
            }))
        }) || new List()
    }

    getOrderSupplierNotes = () => {
        const { orders, orderSupplierNoteEntities } = this.props
        const items = orders.get('orderSupplierNoteItems')

        if (items.size === 0) { return new List() }

        return items.map((itemId) => {
            return orderSupplierNoteEntities.get(itemId.toString())
        })
    }

    renderImage = (id) => {
        const { supplierOrderEntities } = this.props
        if (!id) { return null }
        const supplierOrderImages = supplierOrderEntities.getIn([id, 'supplier_order_images']) || new List()

        return supplierOrderImages.map((image, index) => {
            const imageUrl = image.getIn(['file', 's'])
            const originalUrl = image.getIn(['file', 'original'])
            const contentType = image.getIn(['file', 'content_type'])
            return (
                <Image key={index}
                    thumbUrl={imageUrl}
                    originalUrl={originalUrl}
                    contentType={contentType}
                />
            )
        })
    }

    renderPsCustomerIdCard = (supplierOrder) => {
        const { profitSharingEntities } = this.props
        const profitSharingIdCardImages = profitSharingEntities.getIn([_.toString(supplierOrder.profit_sharing_id), 'id_card_images']) || new List()

        return profitSharingIdCardImages.map((image) => {
            const psImage = image.toJS()
            return (
                <Link key={`image_${psImage.id}`} className="image-thumbnail" to={psImage.url} target="_blank">
                    <Avatar shape="square" size="large" src={psImage.thumb_url} />
                </Link>
            )
        })
    }

    handleSaveSuccessOrderSupplierNote = () => {
        const { fetchSupplierOrders, orderId } = this.props

        fetchSupplierOrders(orderId)
    }

    renderSupplierNoteImage = (imageIds) => {
        if (!imageIds.length) return null

        return imageIds.map((imageId) => {
            const { orderSupplierNoteImageEntities } = this.props
            const image = orderSupplierNoteImageEntities.get(`${imageId}`)
            const thumbUrl = image.getIn(['file', 's']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''
            return (
                <Image
                    key={thumbUrl}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderOrderSupplierNote (orderSupplierNoteIds) {
        const { orderSupplierNoteEntities } = this.props
        if (orderSupplierNoteIds.length === 0) { return null }

        return orderSupplierNoteIds.map((orderSupplierNoteId) => {
            const note = orderSupplierNoteEntities.getIn([orderSupplierNoteId.toString(), 'body'])
            const imageIds = orderSupplierNoteEntities.getIn([orderSupplierNoteId.toString(), 'images'])

            return (
                <div className="order-supplier-note-component" key={orderSupplierNoteId}>
                    {note}
                    <OrderSupplierNoteImage OrderSupplierNoteImageIds={imageIds} />
                    <Divider style={{ margin: '4px 0' }} />
                </div>
            )
        })
    }

    renderOrderSupplierNoteList = () => {
        const { i18n, orders } = this.props

        const columns = [
            {
                key: 'note',
                title: i18n.t('suppliers:note'),
                dataIndex: 'body'
            }, {
                key: 'image',
                title: i18n.t('suppliers:supplierOrder.attachment'),
                dataIndex: 'images',
                render: (row) => (
                    <div className="image-container">
                        {this.renderSupplierNoteImage(row)}
                    </div>
                )
            }, {
                key: 'action',
                title: i18n.t('shared:edit'),
                render: (value) => {
                    return (
                        <Link onClick={() => this.renderOrderSupplierNoteForm(`${value.id}`)}>
                            <EditTwoTone />
                        </Link>
                    )
                }
            }
        ]
        return (
            <div>
                <h3>{i18n.t('orders:orderSupplierNote.title')}</h3>
                <Table
                    columns={columns}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    dataSource={this.getOrderSupplierNotes().toJS()}
                    pagination={false}
                    loading={orders.get('orderSupplierNoteLoading')}
                    rowKey="id"
                />
            </div>
        )
    }

    renderPVImages = (imageIds) => {
        const { paymentVoucherImageEntities } = this.props

        if (!imageIds.length) return null

        return imageIds.map((imageId) => {
            const image = paymentVoucherImageEntities.get(`${imageId}`)
            const thumbUrl = image.getIn(['file', 's']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''
            return (
                <Image
                    key={thumbUrl}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderPaymentVoucherSlip = (supplierOrder) => {
        const { paymentVoucherEntities } = this.props
        const paymentVoucherId = _.get(supplierOrder, 'payment_voucher_id', '')
        const paymentVoucherImageIds = _.get(supplierOrder, 'payment_voucher_images', [])

        if (!paymentVoucherEntities) return null
        const paymentVoucher = paymentVoucherEntities.get(`${paymentVoucherId}`) || new Map()
        if (!paymentVoucher.get('is_paid')) { return null }
        return (
            <div>
                <div className="image-container">
                    {this.renderPVImages(paymentVoucherImageIds)}
                </div>
            </div>
        )
    }

    renderPODownloadBtn (supplierOrder) {
        const refCode = _.get(supplierOrder, 'ref_code', '')
        const isPODownloaded = _.get(supplierOrder, 'is_po_downloaded', false)
        if (!isPODownloaded) { return null }

        return (
            <Button onClick={() => window.open(`/purchase_orders/${refCode}/previews`)}
                className="po-last-download-btn icon-button"
                ghost
                icon={<EyeOutlined />}
                type="primary" />
        )
    }

    renderSupplierOverTransferList = (supplierOrder) => {
        const { i18n, supplierOverTransferItemsEntitles } = this.props

        return (
            <div>
                <div>{i18n.t('suppliers:supplierOrder.supplierOverTransferList')}</div>
                {_.get(supplierOrder, 'supplier_deposit_items', []).map((id) => {
                    const item = supplierOverTransferItemsEntitles.get(id)
                    return (
                        <div key={`deposit-${id}`}
                            onClick={() => this.handleShowSupplierOverTransferForm(supplierOrder, id)}>
                            <Link>
                                [{i18n.t('suppliers:supplierOrder.deposit')} {_.get(item.toJS(), 'amount.display')}]
                            </Link>
                        </div>
                    )
                })}
                {_.get(supplierOrder, 'supplier_withdraw_items', []).map((id) => {
                    const item = supplierOverTransferItemsEntitles.get(id)
                    return (
                        <Link key={`withdraw-${id}`}
                            onClick={() => this.handleShowSupplierOverTransferForm(supplierOrder, id)}>
                            [{i18n.t('suppliers:supplierOrder.withdraw')} {_.get(item.toJS(), 'amount.display')}]
                        </Link>
                    )
                })}
                <PlusSquareTwoTone
                    onClick={() => this.handleShowSupplierOverTransferForm(supplierOrder)} />
            </div>
        )
    }

    handleShowPOForm = (supplierOrderId) => {
        const { togglePOForm } = this.props

        togglePOForm(supplierOrderId)
    }

    handleShowSupplierOverTransferForm = (supplierOrder, id = null) => {
        const { orderId, setSupplierOverTransferFormModal, supplierOrders } = this.props
        this.formData = {
            id,
            orderId,
            supplierId: _.get(supplierOrder, 'supplier.id', undefined),
            supplierOrderId: _.get(supplierOrder, 'id', undefined)
        }

        setSupplierOverTransferFormModal(!supplierOrders.get('showSupplierOverTransferForm'))
    }

    handleSaveSuccessPOForm = (supplierOrderId) => {
        const { togglePOForm, supplierOrderEntities } = this.props
        const refCode = supplierOrderEntities.getIn([supplierOrderId, 'ref_code'])

        window.open(`/purchase_orders/${refCode}/previews`)
        togglePOForm(null)
    }

    handleShowPODocumentHeaderForm = (supplierOrderId) => {
        const { togglePODocumentHeader } = this.props

        togglePODocumentHeader(supplierOrderId)
    }

    renderSupplierNewTag = (supplier = {}) => {
        if (_.isEmpty(supplier) || supplier.bank_name && supplier.bank_account_number) { return null }

        return <Tag color="gold">NEW</Tag>
    }

    handleSaveOrderTransferBack = (params) => {
        const { orderId, saveOrderTransferBack, inquiryTransferBacks } = this.props
        const transferBackId = inquiryTransferBacks.get('transferBackId')

        saveOrderTransferBack(orderId, transferBackId, {
            params,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                this.toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    handlShowShippingCostForm = (id) => {
        const { setShowShippingCostForm, inquiryTransferBacks } = this.props
        const showShippingCostForm = inquiryTransferBacks.get('showShippingCostForm')

        setShowShippingCostForm(!showShippingCostForm, id)
    }

    toggleForm = ({ id = null } = {}) => {
        const { setShowTransferBackForm, resetTransferBackItemsSelected, inquiryTransferBacks } = this.props
        const showForm = inquiryTransferBacks.get('showTransferBackForm')
        setShowTransferBackForm(!showForm, id)
        if (!id) {
            resetTransferBackItemsSelected()
        }
    }

    handleCalculateGrandTotal = (id) => {
        const { culculateTransferBackTotal, setNotifications, fetchInquiry, orderId } = this.props
        culculateTransferBackTotal(orderId, id, {
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                fetchInquiry(orderId)
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    getOrderTransferBacks = () => {
        const { orderTransferBackEntities, inquiryTransferBacks } = this.props
        const items = inquiryTransferBacks.get('items')

        return items.map((id) => {
            return orderTransferBackEntities.get(id) || new Map()
        })
    }

    renderTransferBackSection = () => {
        const { i18n, inquiryTransferBacks } = this.props
        const calculatingTotal = inquiryTransferBacks.get('calculatingTotal')

        const columns = [
            {
                title: i18n.t('inquiries:transferBack.type'),
                dataIndex: 'order_transfer_back_type',
                render: (data) => {
                    return(
                        <div>{i18n.t(`inquiries:transferBack.types.${_.camelCase(data)}`)}</div>
                    )
                }
            }, {
                title: i18n.t('inquiries:transferBack.amount'),
                render: (record) => {
                    const grandTotal = record.grand_total.display
                    return (
                        <div>
                            <div>{grandTotal}</div>
                            <div>{record.shipping_cost > '0.00' ? <div>({record.shipping_cost})</div> : null}</div>
                        </div>
                    )
                }
            }, {
                key: 'calculate',
                render: (record) => {
                    const isCalculated = record.is_calculated
                    if (isCalculated) return null
                    return (
                        <a>
                            {calculatingTotal ? <LoadingOutlined /> : <CalculatorOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.handleCalculateGrandTotal(record.id)}
                            />}
                        </a>
                    )
                }
            }, {
                key: 'shipping-cost',
                render: (record) => {
                    return (
                        <a>
                            <CarOutlined onClick={() => this.handlShowShippingCostForm(record.id)} />
                        </a>
                    )
                }
            }, {
                key: 'actions',
                render: (record) => <Button onClick={() => this.toggleForm({ id: record.id })} type="link" icon={<EditOutlined />} />
            }
        ]

        return (
            <div>
                <h3>{i18n.t('inquiries:transferBack.title')}</h3>
                <Table
                    columns={columns}
                    dataSource={this.getOrderTransferBacks().toJS()}
                    pagination={false}
                    rowKey="id" />
                <Button type="primary" onClick={this.toggleForm}>
                    {i18n.t('shared:add')}
                </Button>
            </div>
        )
    }

    getColumns = () => {
        const { i18n, rolesMatched, supplierOverTransferAccountEntities, truckEntities } = this.props

        return [{
            key: 'supplierRefCode',
            title: i18n.t('suppliers:refCode'),
            width: '200px',
            render: (supplierOrder) => {
                const type = supplierOrder.supplier_order_type
                return (
                    <div>
                        <div style={{ display: 'flex' }}>
                            {supplierOrder.ref_code}
                        </div>

                        {['transfer_back', 'miscellaneous_transfer'].includes(type) ? (
                            <div>({i18n.t(`suppliers:${_.camelCase(type)}`)})</div>
                        ) : null}

                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('supplierOrder:note')} : </span>
                            {supplierOrder.supplier_note}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers:sentNote')} : </span>
                            {supplierOrder.sent_note}
                        </div>
                        <div>
                            <Link onClick={() => {this.handleShowPODocumentHeaderForm(supplierOrder.id)}}>
                                {i18n.t('suppliers:supplierOrder.selectDocumentHeader')}
                            </Link>
                        </div>
                        <div>
                            {rolesMatched(['super_admin', 'purchase_supervisor', 'purchase']) && (
                                <React.Fragment>
                                    <Button className="icon-button" type="primary" icon={<FileTextOutlined />} ghost
                                        onClick={() => this.handleShowPOForm(supplierOrder.id)} />
                                    {this.renderPODownloadBtn(supplierOrder)}
                                </React.Fragment>
                            )}
                            <CopyToClipboard text={`https://vendor.onestockhome.com/supplier_orders/${supplierOrder.ref_code}?uuid=${supplierOrder.uuid}`}>
                                <Button className="icon-button" type="primary" icon={<CopyOutlined />} ghost />
                            </CopyToClipboard>
                        </div>
                            {_.get(supplierOrder, 'supplier.line_message_url', null) ?
                                <div style={{ marginTop: '8px' }}>
                                    <a href={supplierOrder.supplier.line_message_url} target="_blank" rel="noreferrer">
                                        <img src={lineLogo} width='20px'alt="line" />
                                    </a>
                                </div>: null }
                    </div>
                )
            }
        }, {
            key: 'supplierName',
            title: i18n.t('suppliers:supplierName'),
            render: (supplierOrder) => {
                const plateNumberList = _.get(supplierOrder, 'trucks', []).map((id) => {
                    return truckEntities.getIn([id, 'license_plate_number'])
                }).join(', ')
                const accountId = _.get(supplierOrder, 'supplier.supplier_over_transfer_account')
                const account = supplierOverTransferAccountEntities.get(accountId, new Map()).toJS()
                const balance = (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers/overTransferAccounts:balance')}: </span>
                        <span>
                            {_.get(account, 'balance.display', '0.00')}
                        </span>
                    </div>
                )
                const pv = (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>
                            {i18n.t('suppliers:supplierOrder.withHoldingTax3Per')}:
                        </span>
                        <span>
                            {_.get(supplierOrder, 'payment_voucher.amount.display', '0.00')}
                        </span>
                    </div>
                )
                return (
                    <div>
                        <Link onClick={() => this.renderCreatePurchase(supplierOrder)}>
                            {_.get(supplierOrder, 'supplier.display_name', '')}
                        </Link>
                        {this.renderSupplierNewTag(_.get(supplierOrder, 'supplier', {}))}
                        <div>{plateNumberList ? `[${plateNumberList}]` : null}</div>
                        {balance}
                        {supplierOrder.supplier_order_type === 'profit_sharing' && !_.isEmpty(supplierOrder.payment_voucher) && pv}
                    </div>
                )
            }
        }, {
            key: 'stateLabel',
            title: i18n.t('suppliers:state'),
            dataIndex: 'state_label'
        }, {
            key: 'grandTotal',
            title: i18n.t('suppliers:grandTotal'),
            dataIndex: ['grand_total', 'display'],
            render: (dataIndex, supplierOrder) => {
                const grandTotalAfterWithdraw = _.get(supplierOrder, 'grand_total_after_withdraw.raw')
                const grandTotal = _.toNumber(_.get(supplierOrder, 'grand_total.raw'))
                return (
                    <div>
                        <Typography.Text
                            delete={supplierOrder.state === 'cancel' || supplierOrder.state === 'canceled_by_customer'}
                            disabled={grandTotalAfterWithdraw}
                        >
                            {dataIndex}
                        </Typography.Text>
                        {grandTotalAfterWithdraw ? (
                            <Tag color={grandTotal > _.toNumber(grandTotalAfterWithdraw) ? 'red' : 'green'}>
                                {_.get(supplierOrder, 'grand_total_after_withdraw.display')}
                            </Tag>
                        ) : null}

                        {this.renderSupplierOverTransferList(supplierOrder)}
                    </div>
                )
            }
        }, {
            key: 'image',
            title: i18n.t('suppliers:supplierOrder.attachment'),
            render: (supplierOrder) => {
                return (
                    <div className="attached-file">
                        <div className="image-container">
                            {this.renderPsCustomerIdCard(supplierOrder)}
                        </div>
                        <div className="image-container">
                            {this.renderImage(supplierOrder.id)}
                        </div>
                        <div className="image-container">
                            {this.renderOrderSupplierNote(_.get(supplierOrder, 'order_supplier_notes', []))}
                        </div>
                        {
                            supplierOrder.external_note ? <div className="external-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.externalNote')}:
                                </span> {supplierOrder.external_note}
                            </div> : null
                        }
                        {
                            supplierOrder.note_with_sanitizer ? <div className="supplier-order-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.supplierOrderNote')}:
                                </span> {supplierOrder.note_with_sanitizer}
                            </div> : null
                        }

                    </div>
                )
            }
        }, {
            key: 'po-status',
            title: i18n.t('suppliers:state'),
            width: 200,
            render: (supplierOrder) => <PurchaseOrderStatusSwitches supplierOrder={supplierOrder} />
        }, {
            key: 'shipping-invoice',
            title: i18n.t('suppliers:supplierOrder.shippingInvoice'),
            render: (supplierOrder) => {
                const { downloadShippingInvoicePDF, supplierOrders } = this.props
                return (
                    <Button size="small"
                        icon={<PrinterOutlined />}
                        loading={supplierOrders.get('shippingInvoiceLoading')}
                        onClick={() => downloadShippingInvoicePDF(supplierOrder.id)}>
                        {i18n.t('suppliers:download')}
                    </Button>
                )
            }
        }, {
            key: 'slip',
            title: i18n.t('suppliers:supplierOrder.slip'),
            render: (supplierOrder) => (
                <div>
                    {this.renderPaymentVoucherSlip(supplierOrder)}
                </div>
            )
        }, {
            key: 'cancel',
            title: i18n.t('suppliers:supplierOrder.orderState.cancel'),
            render: (supplierOrder) => (
                <div className="cancel">
                    {this.renderCancelButton(supplierOrder)}
                </div>
            )
        }]
    }

    render () {
        const {
            fetchSupplierOrders,
            i18n,
            orderId,
            rolesMatched,
            setOrderSupplierNoteFormModal,
            supplierOrders,
            suppliers,
            togglePODocumentHeader,
            togglePOForm,
            inquiryTransferBacks
        } = this.props
        const showForm = inquiryTransferBacks.get('showTransferBackForm')
        const transferBackId = inquiryTransferBacks.get('transferBackId')
        const saving = inquiryTransferBacks.get('saving')

        return (
            <SupplierPurchaseListWrapper>
                <Table
                    columns={this.getColumns()}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    dataSource={this.getSupplierOrder().toJS()}
                    loading={suppliers.get('supplierOrdersLoading')}
                    rowKey="id"
                    rowClassName={(record) => record.state === 'cancel' ? 'table-row-light' : ''}
                />
                <Modal
                    visible={suppliers.get('isOpenCreatePurchase')}
                    onCancel={() => this.renderCreatePurchase()}
                    footer={false}
                    destroyOnClose>
                    {this.supplierOrderItem}
                </Modal>
                <div className="btn-margin">
                    <Button type="primary"
                        disabled={!rolesMatched(['purchase', 'purchase_supervisor', 'super_admin'])}
                        onClick={() => this.renderCreatePurchase()}>
                        {i18n.t('suppliers:supplierPurchase')}
                    </Button>
                </div>
                <Divider />
                {this.renderOrderSupplierNoteList()}
                <Button type="primary" onClick={() => this.renderOrderSupplierNoteForm()}>
                    {i18n.t('orders:orderSupplierNote.add')}
                </Button>
                <Divider />
                {this.renderTransferBackSection()}
                <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={this.toggleForm}
                    visible={showForm}
                    width="60%">
                    <OrderTransferBackForm
                        id={transferBackId}
                        orderId={orderId}
                        onSubmitForm={this.handleSaveOrderTransferBack}
                        saving={saving}
                        toggleForm={this.toggleForm} />
                </Modal>
                <Modal
                    width="70%"
                    visible={supplierOrders.get('isOpenOrderSupplierNoteForm')}
                    onCancel={() => setOrderSupplierNoteFormModal(false)}
                    destroyOnClose
                    footer={null}>
                    {this.orderSupplierNote}
                </Modal>
                <Modal
                    width="70%"
                    visible={suppliers.get('isShowingPOForm')}
                    onCancel={() => togglePOForm(null)}
                    destroyOnClose
                    footer={null}>
                    <PurchaseOrderForm
                        onSuccess={() => this.handleSaveSuccessPOForm(suppliers.get('showingPOFormSupplierId'))}
                        orderId={orderId}
                        supplierOrderId={suppliers.get('showingPOFormSupplierId')} />
                </Modal>
                <Modal
                    visible={suppliers.get('isShowPODocumentForm')}
                    onCancel={() => togglePODocumentHeader(null)}
                    destroyOnClose
                    footer={null}>
                    {this.renderDocumentHeaderForm()}
                </Modal>
                <Modal
                    width="50%"
                    visible={supplierOrders.get('showSupplierOverTransferForm')}
                    onCancel={this.handleShowSupplierOverTransferForm}
                    destroyOnClose
                    footer={null}>
                    <SupplierOverTransferItemFormContainer
                        formData={this.formData}
                        loading={suppliers.get('lineItemsLoading')}
                        onSaveSuccess={() => {
                            fetchSupplierOrders(orderId)
                            this.handleShowSupplierOverTransferForm()
                        }} />
                </Modal>
            </SupplierPurchaseListWrapper>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.get('orders'),
    orderSupplierNoteEntities: state.getIn(['Entities', 'orderSupplierNotes']),
    orderSupplierNoteImageEntities: state.getIn(['Entities', 'orderSupplierNoteImages']),
    paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
    paymentVoucherImageEntities: state.getIn(['Entities', 'paymentVoucherImages']),
    purchaseOrderStatusHistoryEntities: state.getIn(['Entities', 'purchaseOrderStatusHistories']),
    staffEntities: state.getIn(['Entities', 'staffs']),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierOrders: state.get('supplierOrders'),
    suppliers: state.get('suppliers'),
    supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts']),
    supplierOverTransferItemsEntitles: state.getIn(['Entities', 'supplierOverTransferItems']),
    truckEntities: state.getIn(['Entities', 'trucks']),
    orderTransferBackEntities: state.getIn(['Entities', 'orderTransferBacks']),
    inquiryTransferBacks: state.get('inquiryTransferBacks'),
    profitSharingEntities: state.getIn(['Entities', 'profitSharings'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        cancelSupplierOrder,
        culculateTransferBackTotal,
        setNotifications,
        fetchInquiry,
        setShowShippingCostForm,
        setShowTransferBackForm,
        resetTransferBackItemsSelected,
        saveOrderTransferBack,
        fetchOrderTransferBacks,
        downloadShippingInvoicePDF,
        fetchOrderSupplierNotes,
        fetchSupplierOrders,
        saveSupplierOrders,
        setOrderSupplierNoteFormModal,
        setSupplierOverTransferFormModal,
        toggleCreatePurchase,
        togglePODocumentHeader,
        togglePOForm
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['suppliers', 'inquiries', 'suppliers/overTransferAccounts'])(
        ImagePreviewContainer(currentStaffContainer(SuppliersOrdersIndexContainer))
    )
)
