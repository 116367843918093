import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List as ImmutableList } from 'immutable'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import {
    Avatar,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    List,
    Radio,
    Row,
    Select,
    Spin,
    Table,
    Tooltip,
} from 'antd'
import { CopyOutlined, PictureOutlined } from '@ant-design/icons'
import Filter from './filter'
import AddItemFormWrapper from './addItemForm.style'

import inquiryActions from '../../redux/inquiry/actions'
import ImagesWrapper from "../../components/images/imagesWrapper.style";

const {
    fetchInquiryItems,
    fetchItemByName,
    setShowItemAsCard
} = inquiryActions

class AddItemsForm extends Component {
    constructor (props) {
        super(props)

        this.state = {
            itemId: null,
            itemNameTh: null,
            itemNameEn: null,
            itemCost: 0,
            itemPrice: null,
            itemUnit: null,
            itemUnitOptions: null,
            itemQuantity: null,
            itemWeight: null,
            disableInput: true,
            itemNotFound: false,
            itemNameClipboard: null,
            itemRowKeySelected: null,
            supplierItemCosts: []
        }
    }

    componentDidUpdate (prevProps) {
        const { inquiry } = this.props
        const prevInquiry = prevProps.inquiry

        const prevPasteNameLoading = prevInquiry.get('itemPasteNameLoading')
        const itemPasteNameLoading = inquiry.get('itemPasteNameLoading')

        if (prevPasteNameLoading !== itemPasteNameLoading) {
            this.setFillItemProperty()
        }
    }

    selectItem = (item) => {
        const { form } = this.props

        const itemUnitOptions = _.get(item, 'unit_options', null)

        const unitOptions = itemUnitOptions ? [{
            name: item.unit,
            quantity_default: 1,
            quantity_alternative: 1
        }, ...itemUnitOptions] : itemUnitOptions

        const { supplier_items = [] } = this.getItem(item.id).toJS()
        const supplierCostOptions = supplier_items.map((supplier) => {
            // Filter out-of-stock and cancelled suppliers
            if (supplier.stock_status === 'out_of_stock' || supplier.stock_status === 'cancelled') {
                return null
            }
            return {
                value: supplier.price,
                label: `${supplier.price} - ${supplier.supplier_name}`
            }
        }).filter((supplier) => supplier !== null)

        this.setState({
            itemId: item.id,
            itemNameEn: _.get(item, 'name_en', ''),
            itemNameTh: _.get(item, 'name_th', ''),
            itemCost: supplierCostOptions[0] ? supplierCostOptions[0].value : 0,
            itemPrice: item.price,
            itemUnit: item.unit,
            itemUnitOptions: unitOptions,
            itemQuantity: '1',
            itemWeight: item.weight,
            disableInput: false,
            supplierItemCosts: supplierCostOptions
        })

        const formRef = form.current ? form.current : form

        formRef.setFieldsValue({
            name_th: _.get(item, 'name_th', ''),
            name_en: _.get(item, 'name_en', ''),
            weight: item.weight,
            quantity: '1',
            single_price: item.price,
            unit: item.unit,
            cost: supplierCostOptions[0] ? supplierCostOptions[0].value : 0,
            item_external_id: item.id,
        })
        document.getElementsByClassName('ant-modal-wrap')[0].scrollTo(0, 0)
    }


    handleItemsPagination = (page) => {
        this.props.fetchInquiryItems(page)
    }

    getItem (id) {
        if (!id) return new Map()

        return this.props.itemEntities.get(_.toString(id), new Map())
    }

    getItems = () => {
        const { inquiry } = this.props

        return inquiry.get('items', new ImmutableList()).map((id) => {
            return this.getItem(id)
        })
    }

    itemUnitOption = () => {
        const { itemUnitOptions } = this.state

        return itemUnitOptions.map((unit, index) => {
            return (
                <Select.Option key={index} value={unit.name}>
                    {unit.name}
                </Select.Option>
            )
        })
    }

    calculatePrice = (value) => {
        const { itemPrice, itemUnitOptions } = this.state
        const { form } = this.props

        const getValue = itemUnitOptions.find(itemUnitOptions => itemUnitOptions.name === value)

        const modifier = getValue.quantity_default / getValue.quantity_alternative
        const newPrice = itemPrice * modifier

        const formRef = form.current ? form.current : form

        formRef.setFieldsValue({
            quantity: '1',
            single_price: newPrice,
            unit: value.name
        })
    }

    renderItemUnit = () => {
        const { itemUnitOptions } = this.state
        if (itemUnitOptions) {
            return (
                <Select placeholder="หน่วย"
                    onChange={(e) => this.calculatePrice(e)}>
                    {this.itemUnitOption()}
                </Select>
            )
        } else {
            return (
                <Input placeholder="หน่วย" />
            )
        }
    }

    renderItemDetail = (item) => {
        const { i18n } = this.props
        const imageUrl = _.get(item, 'image.small', '')
        const displaySize = _.get(item, 'display_size', null) ? `(${_.get(item, 'display_size', '')})` : ''

        return (
            <div onClick={() => this.selectItem(item)}>
                <Card hoverable>
                    <Tooltip title={`${item.name} ${displaySize}`}>
                        <div style={{ fontWeight: 'bold',
                            marginBottom: '8px',
                            overflow: 'hidden',
                            height: '60px',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical' }}>
                            {item.name} {displaySize}
                        </div>
                    </Tooltip>
                    <Row>
                        <Col span={8}>
                            <Avatar shape="square" size="large" icon={<PictureOutlined />} src={imageUrl} />
                        </Col>
                        <Col span={16}>
                            <div>
                                <span className={'bold'}>{i18n.t('inquiries:cost')}:</span> {item.cost}
                            </div>
                            <div><span className={'bold'}>{i18n.t('inquiries:price')}:</span> {item.price}</div>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

    renderItemsResult = () => {
        const { inquiry } = this.props
        const data = this.getItems()
        const pagination = inquiry.get('itemsPagination').toJS()

        return (
            <List grid={{ gutter: 16, column: 4, xs: 1, md: 4 }}
                dataSource={data.toJS()}
                loading={inquiry.get('itemsLoading')}
                pagination={{
                    onChange: this.handleItemsPagination,
                    pageSize: pagination.defaultPageSize,
                    total: pagination.total,
                    showSizeChanger: false
                }}
                renderItem={item => (
                    <List.Item>
                        {this.renderItemDetail(item)}
                    </List.Item>
                )} />
        )
    }

    renderImage = (image) => {
        if (!image) {
            return null
        }

        const thumbUrl = _.get(image, 'small', '')
        const originalUrl = _.get(image, 'original', '')
        return (
            <a href={originalUrl} target="_blank" rel="noreferrer">
                <img src={thumbUrl} alt="item_image" />
            </a>
        )
    }

    onSelectItemChange = (id) => {
        const { itemEntities } = this.props
        const itemId = id[0]
        const item = itemEntities.get(itemId) || new Map()

        this.setState({
            itemRowKeySelected: itemId
        })
        this.selectItem(item.toJS())
    }

    renderItemsResultAsList = () => {
        const { inquiry, i18n } = this.props
        const { itemRowKeySelected } = this.state
        const data = this.getItems()
        const pagination = inquiry.get('itemsPagination').toJS()

        const columns = [
            {
                key: 'name',
                title: i18n.t('inquiries:itemDetail.itemName'),
                dataIndex: 'name'
            },
            {
                key: 'image',
                dataIndex: 'image',
                title: i18n.t('inquiries:image'),
                render: (image) => {
                    return (
                        <ImagesWrapper>
                            <div className="image-container">
                                {this.renderImage(image)}
                            </div>
                        </ImagesWrapper>
                    )
                }
            },
            {
                key: 'cost',
                title: i18n.t('inquiries:cost'),
                dataIndex: 'cost'
            },
            {
                key: 'price',
                title: i18n.t('price'),
                dataIndex: 'price'
            },
            {
                key: 'unit',
                title: i18n.t('inquiries:unit'),
                dataIndex: 'unit'
            }
        ]
        const rowSelection = {
            selectedRowKeys: itemRowKeySelected,
            onChange: this.onSelectItemChange,
            type: 'radio'
        }

        return (
            <Table
                onRow={(record) => ({
                    onClick: () => {
                        this.onSelectItemChange([record.id]);
                    },
                })}
                columns={columns}
                dataSource={data.toJS()}
                loading={inquiry.get('itemsLoading')}
                pagination={{
                    onChange: this.handleItemsPagination,
                    pageSize: pagination.defaultPageSize,
                    total: pagination.total,
                    showSizeChanger: false
                }}
                rowKey="id"
                rowSelection={rowSelection}
            />
        )
    }

    handleClipboard = () => {
        const { fetchItemByName } = this.props
        const clipBoard = navigator.clipboard

        clipBoard.readText().then(value => {
            this.setState({ itemNameClipboard: value })
            fetchItemByName(value)
        })
    }

    setFillItemProperty = () => {
        const { inquiry, itemEntities } = this.props
        const itemPasteNameLoading = inquiry.get('itemPasteNameLoading')
        const itemId = inquiry.get('item')
        const itemEntity = itemEntities.get(itemId)

        if (!itemPasteNameLoading && itemEntity) {
            this.setState({
                itemId: itemEntity.get('id'),
                itemNameEn: itemEntity.get('name_en', ''),
                itemNameTh: itemEntity.get('name_th', ''),
                itemCost: itemEntity.get('cost'),
                itemPrice: itemEntity.get('price'),
                itemUnit: itemEntity.get('unit'),
                itemQuantity: 1,
                itemUnitOptions: itemEntity.get('unit_options'),
                itemWeight: itemEntity.get('weight'),
                itemNotFound: false
            })
        }

        if (!itemPasteNameLoading && !itemEntity) {
            this.setState({
                itemId: null,
                itemNameEn: null,
                itemNameTh: null,
                itemCost: 0,
                itemPrice: null,
                itemUnit: null,
                itemQuantity: null,
                itemUnitOptions: null,
                itemWeight: null,
                itemNotFound: true
            })
        }
    }

    renderShowItemResult () {
        const { inquiry } = this.props
        const showItemAsCard = inquiry.get('showItemAsCard')

        if (showItemAsCard === 'card') {
            return this.renderItemsResult()
        } else if (showItemAsCard === 'list') {
            return this.renderItemsResultAsList()
        } else {
            return null
        }
    }

    renderPasteBtn () {
        const { inquiry } = this.props
        const itemPasteNameLoading = inquiry.get('itemPasteNameLoading')

        if (itemPasteNameLoading) { return <Spin className={'paste-btn'} size="small" /> }

        return (
            <a onClick={this.handleClipboard}>
                <CopyOutlined className={'paste-btn'} />
            </a>
        )
    }

    render () {
        const { i18n, errors, inquiry, setShowItemAsCard, handleCancelModal } = this.props
        const { itemNotFound, itemNameClipboard } = this.state
        const alertItemNotFound = itemNotFound ? `${itemNameClipboard} ${i18n.t('inquiries:itemNotFound')}` : ''
        const showItemAsCard = inquiry.get('showItemAsCard')

        return (
            <AddItemFormWrapper>
                <h2>{i18n.t('inquiries:addItem')}{this.renderPasteBtn()}</h2>
                <div className={'alert-not-found'}>{alertItemNotFound}</div>
                <Row type="flex" gutter={24}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='name_th'
                            initialValue={this.state.itemNameTh}
                            validateStatus={errors && errors.item && 'error'}
                            help={errors && errors.item}>
                                <Input.TextArea
                                    autoSize
                                    placeholder={i18n.t('inquiries:lineItemNameTh')}
                                />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item
                            name='name_en'
                            initialValue={this.state.itemNameEn}
                            validateStatus={errors && errors.item && 'error'}
                            help={errors && errors.item}>
                                <Input.TextArea
                                    autoSize
                                    placeholder={i18n.t('inquiries:lineItemNameEn')}
                                />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item
                            name='single_price'
                            initialValue={this.state.itemPrice}
                            validateStatus={errors && errors.item && 'error'}
                            help={errors && errors.item}>
                                <Input
                                    placeholder={i18n.t('price')}
                                    suffix={i18n.t('baht')}
                                />
                        </Form.Item>
                    </Col>

                    <Col xs={12} md={4}>
                        <Form.Item
                            name='quantity'
                            initialValue={this.state.itemQuantity}
                            validateStatus={errors && errors.quantity && 'error'}
                            help={errors && errors.quantity}>
                                <Input placeholder={i18n.t('amount')} />
                        </Form.Item>
                    </Col>

                    <Col xs={12} md={4}>
                        <Form.Item
                            name='unit'
                            initialValue={this.state.itemUnit}
                            validateStatus={errors && errors.item && 'error'}
                            help={errors && errors.item}>
                                {this.renderItemUnit()}
                        </Form.Item>
                    </Col>

                    <Form.Item name='item_external_id' initialValue={this.state.itemId || ''}>
                            <input hidden />
                    </Form.Item>

                    <Col xs={24} md={4}>
                        <Form.Item
                            name='weight'
                            initialValue={this.state.itemWeight}
                            validateStatus={errors && errors.item && 'error'}
                            help={errors && errors.item}>
                                <Input
                                    placeholder={i18n.t('weight')}
                                    suffix={i18n.t('inquiries:kg')}
                                />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={6}>
                        <Form.Item
                            name='cost'
                            label={`${i18n.t('cost')} (${i18n.t('baht')})`}
                            labelCol={{ xs: { span: 6 }, md: { span: 6 } }}
                            wrapperCol={{ xs: { span: 18 }, md: { span: 18 } }}
                        >
                            { this.state.itemId ? (
                                <Select
                                    options={this.state.supplierItemCosts}
                                    placeholder={i18n.t('cost')}
                                />
                            ) : (
                                <Input
                                    placeholder={i18n.t('cost')}
                                    suffix={i18n.t('baht')}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6} offset={18}>
                        <div className={'stay-right'}>
                            <Button className={'back-button'} key="back" onClick={handleCancelModal}> {i18n.t('inquiries:cancel')}</Button>
                            <Button key="submit" type="primary" htmlType="submit">
                                {i18n.t('inquiries:addItem')}
                            </Button>
                        </div>
                    </Col>
                </Row>
                <h2>{ i18n.t('inquiries:searchItem') }</h2>
                <Filter />
                <Divider />
                <div className={'list-action'}>
                    <Radio.Group size="small"
                                 onChange={(e) => setShowItemAsCard(e.target.value)}
                                 defaultValue={showItemAsCard}
                    >
                        <Radio.Button value="card"> {i18n.t('inquiries:showItemAsCard')} </Radio.Button>
                        <Radio.Button value="list"> {i18n.t('inquiries:showItemAsList')} </Radio.Button>
                    </Radio.Group>
                </div>
                { this.renderShowItemResult() }
            </AddItemFormWrapper>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.get('categories'),
    inquiry: state.get('inquiry'),
    itemEntities: state.getIn(['Entities', 'items'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInquiryItems,
        fetchItemByName,
        setShowItemAsCard
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(AddItemsForm))
