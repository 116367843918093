import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'

import { Input, Button, Popconfirm, List } from 'antd'
import Icon from '@ant-design/icons'

import accountingActions from '../../redux/orders/accounting/actions'
import notificationActions from '../../redux/notifications/actions'
import { withTranslation } from 'react-i18next'

const { fetchOrderInvoiceEmailHistroies, sendETaxMailToCustomer, fetchOrderBusinessAddresses } = accountingActions
const { setNotifications } = notificationActions

class SendTaxInvoiceMailsContainer extends Component {
    static propTypes = {
        defaultEmail: PropTypes.string,
        orderId: PropTypes.string.isRequired,

        accountings: ImmutablePropTypes.map.isRequired,
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        fetchOrderInvoiceEmailHistroies: PropTypes.func.isRequired,
        orderBusinessAddressEntities: ImmutablePropTypes.map.isRequired,
        invoiceEmailHistoryEntities: ImmutablePropTypes.map.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            email: props.defaultEmail
        }
    }

    componentDidMount () {
        const { orderId, fetchOrderInvoiceEmailHistroies, fetchOrderBusinessAddresses } = this.props

        fetchOrderBusinessAddresses(orderId)
        fetchOrderInvoiceEmailHistroies(orderId)
    }

    componentDidUpdate () {
        if (this.props.defaultEmail && !this.state.email) {
            this.setState({
                email: this.props.defaultEmail
            })
        }
    }

    sendEmail = (taxInvoiceId) => {
        const { i18n, sendETaxMailToCustomer, orderId, setNotifications } = this.props
        const { email } = this.state

        sendETaxMailToCustomer({
            orderId,
            id: taxInvoiceId,
            email,
            onSuccess: () => {
                setNotifications(
                    i18n.t('confirms:invoice.success'),
                    `${i18n.t('confirms:invoice.sendTaxInvoiceTo')} ${email}`,
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    getInvoiceEmailHistories = () => {
        const { accountings, invoiceEmailHistoryEntities } = this.props
        const invoiceEmailHistoryIds = accountings.get('invoiceEmailHistoryItems')

        return invoiceEmailHistoryIds.map((id) => {
            return invoiceEmailHistoryEntities.get(`${id}`)
        })
    }

    renderSendTaxInvoiceHistories = () => {
        const { accountings, i18n } = this.props
        const invoiceEmailHistoryIds = accountings.get('invoiceEmailHistoryItems')

        if (!invoiceEmailHistoryIds.size) return null
        return (
            <List
                size="small"
                style={{ marginTop: '8px' }}
                header={`${i18n.t('confirms:invoice.mailDeliveryHistories')}`}
                bordered
                dataSource={this.getInvoiceEmailHistories()}
                renderItem={item => (
                    <List.Item>
                        <span>
                            {item.get('staff_name')} {i18n.t('confirms:invoice.sendTo', { invoiceNo: item.get('invoice_no') })}
                            <strong> {item.get('email')} </strong>
                            {i18n.t('confirms:invoice.date')} {moment(item.get('created_at')).format('DD MMM YYYY')}
                        </span>
                    </List.Item>
                )}/>
        )
    }

    renderTaxInvoices = () => {
        const { accountings, i18n, orderBusinessAddressEntities } = this.props
        const orderBusinessAddressIds = accountings.get('orderBusinessAddressItems')

        if (!orderBusinessAddressIds.size) { return null }

        return (
            <List
                size="small"
                style={{ marginTop: '8px' }}
                header={i18n.t('confirms:invoice.taxInvoice')}
                bordered
                dataSource={orderBusinessAddressIds.map(id => orderBusinessAddressEntities.get(id))}
                renderItem={taxInvoice => (
                    <List.Item>
                        <List.Item.Meta
                            title={`${i18n.t('confirms:invoice.invoiceNo')} ${taxInvoice.get('invoice_no') || '-'}`}
                            description={taxInvoice.get('full_address')}
                        />
                    </List.Item>
                )}/>
        )
    }

    render () {
        const { i18n } = this.props

        return (
            <div>
                <h4>{i18n.t('confirms:invoice.sendTaxInvoiceViaEmail')}</h4>
                <div>
                    <Input value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                        placeholder={i18n.t('confirms:invoice.pleaseInputEmail')}/>
                    {this.renderSendTaxInvoiceHistories()}
                    {this.renderTaxInvoices()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails'),
    orderBusinessAddressEntities: state.getIn(['Entities', 'orderBusinessAddresses']),
    invoiceEmailHistoryEntities: state.getIn(['Entities', 'invoiceEmailHistories'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchOrderBusinessAddresses,
        fetchOrderInvoiceEmailHistroies,
        sendETaxMailToCustomer,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('confirms')(SendTaxInvoiceMailsContainer))
