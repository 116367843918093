import styled from 'styled-components'
const PurchaseOrderPreviewWrapper = styled.div`
  .purchase-order-preview-loading {
    text-align: center;
    border-radius: 4px;
    padding: 30px 50px;
    margin: 40px 15%;
  }

  .signature-image {
    width: 100%;
  }

  .purchase-order-preview-container {
    width: 100%;
    color: black;

    .purchase-order-preview-page {
      background-color: #FFF;
      padding: 24px;
      width: 820px;
      margin: 80px auto;
    }

    .preview-header {
      display: flex;
      margin: 12px 0;

      .header-logo {
        padding: 0 20px;
        height: 80px;
        width: 150px;

        .header-logo-image {
          height: 100%;
          width: 100%;
        }
      }

      .header-details {
        padding: 0 20px;
      }

      .ngentongma-header {
        margin: auto;
        text-decoration: underline;
      }
    }

    .items-section {
      border: 1px solid #323232;
      margin: 12px 0;
      width: 100%;

      th {
        border: 1px solid #323232;
        text-align: center;
      }

      td {
        border-right: 1px solid #323232;
        padding: 4px;
      }

      tfoot {
        border: 1px solid #323232;

        .title {
          width: 70%;
          text-align: end;
        }

        .price {
          width: 30%;
          font-size: 16px;
        }
      }

      .number {
        width: 60px;
      }

      .list-name {
        max-width: 400px;
        word-wrap: break-word;
      }
    }

    .shipping-section {
      margin: 12px 0;
      width: 100%;

      td {
        border: 1px solid #323232;
        padding: 4px;
        vertical-align: baseline;
        width: 50%;
      }
    }

    .preview-footer {
      .logo-border {
        border: 1px solid #323232;
        border-radius: 50%;
        margin: auto 8px;
        padding: 12px;

        .footer-logo-image {
          height: 100%;
          width: 100%;
        }
      }

      .footer-details {
        padding: 0 20px;
      }
    }
  }

  .display-flex {
    display: flex;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-center {
    text-align: center;
  }

  .text-red {
    color: red;
  }

  .text-right {
    text-align: right;
  }

  .text-bold {
    font-weight: bold;
  }

  .osh-watermark {
    position: relative;
    overflow: hidden;
  }

  .osh-watermark::before {
    position: absolute;
    top: -75%;
    left: -75%;

    display: block;
    width: 200%;
    height: 150%;

    transform: rotate(-35deg);
    content: attr(data-watermark);

    opacity: 0.075;
    line-height: 6em;
    letter-spacing: 2px;
    word-spacing: 20px;
    color: gray;
    font-size: 18px;
  }
`
export default PurchaseOrderPreviewWrapper
