import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import OrderSummariesDashboardContainer from './OrderSummariesDashboardContainer'

class OrderSummaryIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Order Summary | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/order_summaries', name: 'Orders' }
                    ]}>
                    <ContentLayout>
                        <OrderSummariesDashboardContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default OrderSummaryIndexPage
