const actions = {
    CUSTOMER_POTENTAIL_LEADS_FETCH_REQUEST: 'CUSTOMER_POTENTAIL_LEADS_FETCH_REQUEST',
    CUSTOMER_POTENTAIL_LEADS_FETCHING: 'CUSTOMER_POTENTAIL_LEADS_FETCHING',
    CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION: 'CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION',
    CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS: 'CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS',
    SET_CUSTOMER_POTENTAIL_LEADS_FORM_MODAL: 'SET_CUSTOMER_POTENTAIL_LEADS_FORM_MODAL',
    CUSTOMER_POTENTAIL_LEADS_SAVE_REQUEST: 'CUSTOMER_POTENTAIL_LEADS_SAVE_REQUEST',
    CUSTOMER_POTENTAIL_LEADS_SAVING: 'CUSTOMER_POTENTAIL_LEADS_SAVING',
    CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS: 'CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS',
    POTENTAIL_LEADS_DELETE_REQUEST: 'POTENTAIL_LEADS_DELETE_REQUEST',
    POTENTAIL_LEADS_DELETE_SUCCESS: 'POTENTAIL_LEADS_DELETE_SUCCESS',

    fetchCustomerPotentailLeads: (customerId = '', page = 1) => ({
        type: actions.CUSTOMER_POTENTAIL_LEADS_FETCH_REQUEST,
        payload: { customerId, page }
    }),

    setCustomerPotentialLeadForm: (value, id) => ({
        type: actions.SET_CUSTOMER_POTENTAIL_LEADS_FORM_MODAL,
        value, id
    }),

    saveCustomerPotentialLead: ({
        customerId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.CUSTOMER_POTENTAIL_LEADS_SAVE_REQUEST,
            payload: { customerId, id, params, onSuccess, onError }
        }
    },

    deletePotentialLead: (customerId, id) => ({
        type: actions.POTENTAIL_LEADS_DELETE_REQUEST,
        payload: { customerId, id }
    })

}

export default actions
