const actions = {
    MAILING_ADDRESSES_FETCH_REQUEST: 'MAILING_ADDRESSES_FETCH_REQUEST',
    MAILING_ADDRESSES_FETCH: 'MAILING_ADDRESSES_FETCH',
    MAILING_ADDRESSES_FETCH_SUCCESS: 'MAILING_ADDRESSES_FETCH_SUCCESS',
    MAILING_ADDRESSES_SAVE_REQUEST: 'MAILING_ADDRESSES_SAVE_REQUEST',
    MAILING_ADDRESSES_SAVING: 'MAILING_ADDRESSES_SAVING',
    MAILING_ADDRESSES_SET_FORM_ERRORS: 'MAILING_ADDRESSES_SET_FORM_ERRORS',
    MAILING_ADDRESSES_SAVE_SUCCESS: 'MAILING_ADDRESSES_SAVE_SUCCESS',
    MAILING_ADDRESSES_SET_DEFAULT: 'MAILING_ADDRESSES_SET_DEFAULT',
    MAILING_ADDRESSES_SET_PAGINATION: 'MAILING_ADDRESSES_SET_PAGINATION',

    fetchMailingAddresses: (id, page) => ({
        type: actions.MAILING_ADDRESSES_FETCH_REQUEST,
        payload: { id, page }
    }),

    saveMailingAddress: ({ address, customerId, id, onSuccess = () => {} }) => ({
        type: actions.MAILING_ADDRESSES_SAVE_REQUEST,
        payload: { address, customerId, id, onSuccess }
    }),

    setDefaultMailingAddress: ({
        mailingAddressType,
        name,
        address,
        province,
        district,
        subDistrict,
        postalCode
    } = {}) => ({
        type: actions.MAILING_ADDRESSES_SET_DEFAULT,
        mailingAddressType, name, address, province, district, subDistrict, postalCode
    })
}

export default actions
