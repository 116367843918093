import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import CompaniesIndexContainer from './CompaniesIndexContainer'

class CompanyIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Companies | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/companies', name: 'Companies' }
                    ]}>
                    <ContentLayout>
                        <CompaniesIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CompanyIndexPage
