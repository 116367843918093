import styled from 'styled-components'

export default styled.div`
  background-color: rgb(241, 243, 246);
  .card {
    padding: 8px 16px;
    a {
      display: block;
    } 
  }

  .active {
    background-color: #fff;
  }
`
