import React, { useContext, useEffect, useRef, useState, Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Form, Select, Divider, Input, Rate, Table, InputNumber } from 'antd'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

const { Option } = Select

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 7 }
    }
}

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 14, offset: 10 }
    }
}

class ReviewForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedRowKeys: [],
            bodyData: [],
            ratingData: [],
            titleData: []
        }

        this.formRef = createRef()
    }

    componentDidMount() {
        this.setDefaultState()
    }

    setDefaultState = () => {
        const { lineItems } = this.props
        const lineItemsArray = lineItems.toJS()
        const bodyData = lineItemsArray.map((data) => {
            return { itemId: data.item_id, value: data.customerReview.body }
        })
        const titleData = lineItemsArray.map((data) => {
            return { itemId: data.item_id, value: data.customerReview.title }
        })
        const ratingData = lineItemsArray.map((data) => {
            return { itemId: data.item_id, value: data.customerReview.rating_score }
        })
        this.setState({ bodyData, titleData, ratingData })
    }

    handleFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    selectConstants = (selectOption) => {
        if (!selectOption) {
            return null
        }
        return selectOption.map((option, index) => {
            return (
                <Option key={index} value={option.get('id')}>
                    {option.get('name')}
                </Option>
            )
        })
    }

    handleSubmitForm = (values) => {
        const params = this.state.selectedRowKeys.map((selectedRowKey) => {
            const bodyDataParam = this.state.bodyData.find((e) => e.itemId === selectedRowKey)
            const ratingDataParam = this.state.ratingData.find((e) => e.itemId === selectedRowKey)
            const titleDataParam = this.state.titleData.find((e) => e.itemId === selectedRowKey)

            return {
                reviewable_type: 'Item',
                reviewable_id: selectedRowKey,
                title: titleDataParam.value,
                rating: ratingDataParam.value,
                body: bodyDataParam.value
            }
        })

        const { handleSubmitReview } = this.props

        handleSubmitReview({ reviewable_type: 'Item', reviews: params })

        this.formRef.current.resetFields()
    }

    getColumns() {
        const { i18n } = this.props

        const arrayUniqueByKeyData = (data, record, value) => {
            data.push({ itemId: record['item_id'], value: value })
            return [...new Map(data.map((item) => [item['itemId'], item])).values()]
        }

        const onChangeBody = (value, record) => {
            const arrayUniqueByKey = arrayUniqueByKeyData(this.state.bodyData, record, value)

            this.setState({ bodyData: arrayUniqueByKey })
        }

        const onChangeTitle = (value, record) => {
            const arrayUniqueByKey = arrayUniqueByKeyData(this.state.titleData, record, value)

            this.setState({ titleData: arrayUniqueByKey })
        }

        const onChangeRating = (value, record) => {
            const arrayUniqueByKey = arrayUniqueByKeyData(this.state.ratingData, record, value)

            this.setState({ ratingData: arrayUniqueByKey })
        }

        return [
            {
                title: i18n.t('inquiries:lineItemNameTh'),
                dataIndex: 'name_th',
                width: '220px'
            },
            {
                title: i18n.t('inquiries:lineItemNameEn'),
                dataIndex: 'name_en',
                width: '220px'
            },
            {
                title: i18n.t('inquiries:title'),
                dataIndex: 'title',
                key: 'title',
                width: '180px',
                render: (dataIndex, record) => {
                    return (
                        <Input
                            placeholder='Input title'
                            defaultValue={record.customerReview.title}
                            onChange={(e) => {
                                onChangeTitle(e.target.value, record)
                            }}
                        />
                    )
                }
            },
            {
                title: i18n.t('inquiries:rating'),
                dataIndex: 'rating',
                key: 'rating',
                width: '80px',
                render: (dataIndex, record) => {
                    return (
                        <InputNumber
                            min={1}
                            max={5}
                            defaultValue={record.customerReview.rating_score}
                            onChange={(e) => {
                                onChangeRating(e, record)
                            }}
                        />
                    )
                }
            },
            {
                title: i18n.t('inquiries:body'),
                dataIndex: 'body',
                key: 'body',
                width: '180px',
                render: (dataIndex, record) => {
                    return (
                        <Input
                            placeholder='Input body'
                            defaultValue={record.customerReview.body}
                            onChange={(e) => {
                                onChangeBody(e.target.value, record)
                            }}
                        />
                    )
                }
            }
        ]
    }

    getCustomColumns() {
        return this.getColumns().map((col) => {
            return col
        })
    }

    render() {
        const { i18n, stateSaving, items, lineItems, customerReviewEntities, customerReviews } = this.props

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys: selectedRowKeys })
            },
            selectedRowKeys: this.state.selectedRowKeys
        }

        return (
            <div>
                <Form onFinish={this.handleSubmitForm} {...formItemLayout} ref={this.formRef}>
                    <Table
                        pagination={false}
                        columns={this.getCustomColumns()}
                        dataSource={lineItems.toJS()}
                        rowKey={(record) => record.item_id}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection
                        }}
                    />

                    <Form.Item {...formItemLayoutWithOutLabel} label=''>
                        <Button htmlType='submit' type='primary' loading={stateSaving}>
                            {i18n.t('shared:save')}
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
            </div>
        )
    }
}

ReviewForm.propTypes = {
    handleSubmitReview: PropTypes.func.isRequired,
    orderId: PropTypes.string,
    lineItems: ImmutablePropTypes.list,
    stateSaving: PropTypes.bool
}

const mapStateToProps = (state) => ({
    inquiry: state.get('inquiry'),
    customerReviews: state.get('customerReviews'),
    lineItemEntities: state.getIn(['Entities', 'lineItems']),
    customerReviewEntities: state.getIn(['Entities', 'customerReviews'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('customers/notes')(ReviewForm))
