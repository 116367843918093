import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button, Col, Descriptions, Modal, PageHeader, Row, Spin, Tabs } from 'antd'
import { withTranslation } from 'react-i18next'

import CompanyProjectFormContainer from '../../containers/Projects/CompanyProjectFormContainer'
import OrderSummariesContainer from '../../containers/OrderSummaries/OrderSummariesContainer'
import ProjectCustomerListContainer from '../../containers/Projects/ProjectCustomerListContainer'
import ProjectContactsListContainer from '../../containers/Projects/ProjectContactsListContainer'
import ProjectProfitSharingListContainer from '../../containers/Projects/ProjectProfitSharingListContainer'
import ProjectWrapper from './project.style'

const Content = ({ children, extra, loading }) => {
    return (
        <Spin spinning={loading}>
            <Row>
                <Col sm={20}>{children}</Col>
                <Col sm={4} className="extra-content">{extra}</Col>
            </Row>
        </Spin>
    )
}

const Project = ({
    i18n,
    companyProject,
    loading,
    projectId,
    showCompanyProjectForm,
    toggleCompanyProjectForm
}) => {
    return (
        <ProjectWrapper>
            <PageHeader
                className="project-show-header"
                footer={
                    <Tabs defaultActiveKey="orders">
                        <Tabs.TabPane tab={i18n.t('orders')} key="orders">
                            <OrderSummariesContainer
                                filterBy={{ company_project_id: projectId }}
                                hideFilter />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('customer')} key="customers">
                            <ProjectCustomerListContainer projectId={projectId} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('projects:contacts.contacts')} key="contacts">
                            <ProjectContactsListContainer projectId={projectId} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={i18n.t('profitSharing:profitSharing')} key="profitSharing">
                            <ProjectProfitSharingListContainer projectId={projectId} />
                        </Tabs.TabPane>
                    </Tabs>
                }
            >
                <Content
                    loading={loading}
                    extra={
                        <Button type="primary" onClick={() => toggleCompanyProjectForm({ id: projectId })}>
                            {i18n.t('projects:editProject')}
                        </Button>
                    }
                >
                    <Descriptions size="small" column={1} colon={false}>
                        <Descriptions.Item label={i18n.t('projects:name')}>
                            {_.get(companyProject, 'name', '-')}
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('projects:company')}>
                            {_.get(companyProject, 'customer_company.name', '-')}
                        </Descriptions.Item>
                    </Descriptions>
                </Content>
            </PageHeader>

            <Modal
                visible={showCompanyProjectForm}
                onCancel={toggleCompanyProjectForm}
                footer={null}
                destroyOnClose
            >
                <CompanyProjectFormContainer
                    companyProject={companyProject}
                    id={projectId}
                    toggleForm={toggleCompanyProjectForm}
                />
            </Modal>
        </ProjectWrapper>
    )
}

Project.propTypes = {
    companyProject: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    showCompanyProjectForm: PropTypes.bool,
    toggleCompanyProjectForm: PropTypes.func.isRequired
}

Project.defaultProps = {
    loading: false,
    showCompanyProjectForm: false
}

export default withTranslation(['projects', 'profitSharing'])(Project)
