import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'immutable'

import SupplierInfoForm from '../../components/Suppliers/supplierInfoForm'

import actions from '../../redux/suppliers/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveSupplier, setFormErrors } = actions
const { setNotifications } = notificationActions

const SupplierInfoFormContainer = ({
    onSuccess,
    supplierId
}) => {
    const dispatch = useDispatch()
    const supplierEntities = useSelector(state => state.getIn(['Entities', 'suppliers']))
    const saving = useSelector(state => state.getIn(['suppliers', 'saving']))

    useEffect(() => {
        // component-will-unmount
        return () => {
            dispatch(setFormErrors())
        }
    }, [])

    const getSupplier = () => {
        return supplierEntities.get(supplierId) || new Map()
    }

    const handleSaveSupplier = (params) => {
        dispatch(saveSupplier(supplierId,  {
            params,
            onSuccess: () => {
                dispatch(setNotifications('success', 'success', 'success'))
                onSuccess()
            }
        }))
    }

    return (
        <SupplierInfoForm
            onSubmitForm={handleSaveSupplier}
            saving={saving}
            supplier={getSupplier()}
        />
    )
}

SupplierInfoFormContainer.propTypes = {
  onSuccess: PropTypes.func,
  supplierId: PropTypes.string.isRequired
}

SupplierInfoFormContainer.defaultProps = {
  onSuccess: () => {}
}

export default SupplierInfoFormContainer
