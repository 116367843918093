import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import actions from '../../redux/projects/contacts/actions'
import notificationActions from '../../redux/notifications/actions'
import ProjectContactForm from '../../components/projects/projectContactForm'

const { saveProjectContact, fetchProjectContact } = actions
const { setNotifications } = notificationActions

class ProjectContactFormContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired
    }

    static defaultProps = {
        toggleForm: () => {}
    }


    componentDidMount () {
        const { projectId, id, fetchProjectContact } = this.props
        if (id) {
            fetchProjectContact({ projectId, id })
        }
    }

    handleSaveProjectContact = (params) => {
        const { projectId, id, saveProjectContact, setNotifications, toggleForm } = this.props

        saveProjectContact({
            projectId,
            id,
            params,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    render () {
        const { projectContactEntities, projectContacts, id, toggleForm } = this.props
        const projectContact = id ? projectContactEntities.get(id) : new Map()
        return (
            <ProjectContactForm
                onSubmitForm={this.handleSaveProjectContact}
                formErrors={projectContacts.get('errors').toJS()}
                saving={projectContacts.get('saving')}
                toggleForm={toggleForm}
                projectContact={projectContact || new Map()}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projectContacts: state.get('projectContacts'),
    projectContactEntities: state.getIn(['Entities', 'projectContacts'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProjectContact,
        saveProjectContact,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContactFormContainer)
