import React from 'react'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import DocumentsIndexContainer from './DocumentsIndexContainer'

class OrdersDocumentsPage extends React.Component {
    componentDidMount() {
        document.title = 'Order Documents | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/orders/documents', name: 'Documents' }
                    ]}>
                    <ContentLayout>
                      <DocumentsIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default OrdersDocumentsPage
