import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { Table, Popconfirm, Button, Modal, Spin } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import _ from 'lodash'

import JournalEntryFormatsIndexWrapper from '../JournalEntryFormatsIndex.style'
import JournalEntryFormatItemFormContainer
    from '../../../containers/JournalEntryFormats/items/JournalEntryFormatItemFormContainer'

const JournalEntryFormatItemsIndex = ({
    onTableChange,
    loading,
    pagination,
    journalEntryFormatItems,
    i18n,
    toggleFormatItemForm,
    onDeleteFormatItem,
    showFormatItemForm,
    journalEntryFormatId,
    formatItemId,
    journalEntryFormat,
    formatLoading
}) => {
    const columns = [{
        key: 'account-type',
        title: i18n.t('journalEntryFormats:items.accountType'),
        dataIndex: 'account_type'
    }, {
      key: 'formula',
      title: i18n.t('journalEntryFormats:items.formula'),
      dataIndex: 'formula'
    }, {
      key: 'account-name',
      title: i18n.t('journalEntryFormats:items.accountName'),
      dataIndex: 'account_name'
    }, {
        key: 'actions',
        render: (record) => (
            <div>
                <div onClick={() => toggleFormatItemForm({ selected: record.id })}>
                    <a><EditOutlined /> {i18n.t('shared:edit')}</a>
                </div>
                <Popconfirm
                    title={i18n.t('shared:confirmDelete')}
                    onConfirm={() => onDeleteFormatItem(record.id)}
                >
                    <a className="text-danger"><DeleteOutlined /> {i18n.t('shared:delete')}</a>
                </Popconfirm>
            </div>
        )
    }]

    return (
        <JournalEntryFormatsIndexWrapper>
            <div className="journal-entry-formats-index-header">
                <h2>
                    {formatLoading ? <Spin /> : _.get(journalEntryFormat, 'name')}
                </h2>
                <Button type="primary"
                    onClick={toggleFormatItemForm}>
                    + {i18n.t('shared:add')}
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={journalEntryFormatItems}
                loading={loading}
                onChange={onTableChange}
                pagination={pagination}
                rowKey="id"
            />

            <Modal
                visible={showFormatItemForm}
                onCancel={toggleFormatItemForm}
                footer={null}
                destroyOnClose>
                <JournalEntryFormatItemFormContainer
                    toggleForm={toggleFormatItemForm}
                    id={formatItemId}
                    journalEntryFormatId={journalEntryFormatId}
                    journalEntryFormatItemId={formatItemId}
                />
            </Modal>
        </JournalEntryFormatsIndexWrapper>
    )
}

JournalEntryFormatItemsIndex.propTypes = {
    columns: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    journalEntryFormatItems: PropTypes.array
}

JournalEntryFormatItemsIndex.defaultProps = {
    onTableChange: () => {},
}

export default withTranslation('journalEntryFormats')(withRouter(JournalEntryFormatItemsIndex))
