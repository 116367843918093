import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Form, Input } from 'antd'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const Render = ({ formErrors, onSaveCustomerType, saving }) => {
    const { i18n } = useTranslation('customerTypes')

    const nameEnError = formErrors['name_en']
    const nameThError = formErrors['name_th']

    const onSubmitForm = values => {
        onSaveCustomerType(values)
    }

    return (
        <Form onFinish={onSubmitForm} {...formItemLayout}>
            <h3>{i18n.t('customerTypes:addCustomerTypes')}</h3>

            <Form.Item
                label={`${i18n.t('customerTypes:customerTypes')} ${i18n.t('th')}`}
                validateStatus={nameThError && 'error'}
                name='nameTh'
                help={nameThError}
                hasFeedback
            >
                <Input placeholder={i18n.t('customerTypes:addCustomerTypes')} />
            </Form.Item>
            <Form.Item
                label={`${i18n.t('customerTypes:customerTypes')} ${i18n.t('en')}`}
                validateStatus={nameEnError && 'error'}
                name='nameEn'
                help={nameEnError}
                hasFeedback
            >
                <Input placeholder={i18n.t('customerTypes:addCustomerTypes')} />
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
                <Button htmlType="submit" type="primary" disabled={saving}>
                    {i18n.t('shared:save')}
                </Button>
            </div>
        </Form>
    )
}

Render.propTypes = {
    formErrors: PropTypes.object.isRequired,
    onSaveCustomerType: PropTypes.func.isRequired,
    saving: PropTypes.bool
}

Render.defaultProps = {}

export default Render
