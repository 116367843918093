import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../App/layout'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import ContentLayout from '../../components/utility/layoutContent'
import InquiryContainer from './InquiryContainer'

class InquiryPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        const { id } = this.props.match.params
        document.title = `${id} | OSH CRM`
    }

    render() {
        const { id } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper
                    breadcrumbs={[
                        { path: '/order_summaries', name: 'Orders' },
                        { path: `/inquiries/${id}`, name: id }
                    ]}>
                    <ContentLayout>
                        <InquiryContainer id={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default InquiryPage
