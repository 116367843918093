import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Modal } from 'antd'
import { Table } from './component'
import SalePipelineRow from './row'
import SalePipelineForm from '../Form'
import CustomerTypeForm from '../customerTypeForm'
import StyleWrapper from './table.style'

const { TR, TH } = Table

const renderLoadMore = (i18n, spinning, onLoadMore, pageInfo) => {
    if (spinning || !pageInfo.hasNextPage) return null

    return (
        <div className="load-more-actions">
            <Button onClick={onLoadMore}>{i18n.t('shared:loadMore')}</Button>
        </div>
    )
}

const renderHeader = (i18n) => {
    return (
        <TR>
            <TH>{i18n.t('salePipelines:order')}</TH>
            <TH>{i18n.t('salePipelines:grandTotal')} ({i18n.t('baht')})</TH>
            <TH>{i18n.t('salePipelines:marginPercent')}</TH>
            <TH>{i18n.t('salePipelines:profitAmount')} ({i18n.t('baht')})</TH>
            <TH>{i18n.t('salePipelines:customer')}</TH>
            <TH>{i18n.t('salePipelines:position')}</TH>
            <TH>{i18n.t('salePipelines:customerType')}</TH>
            <TH>{i18n.t('salePipelines:customerPersonality')}</TH>
            <TH>{i18n.t('salePipelines:orderNote')}</TH>
            <TH>{i18n.t('salePipelines:interestingProduct')}</TH>
            <TH>{i18n.t('salePipelines:staff')}</TH>
            <TH>{i18n.t('salePipelines:status')}</TH>
            <TH>{i18n.t('salePipelines:useMonth/Year')}</TH>
            <TH>{i18n.t('shared:createdAt')}</TH>
            <TH></TH>
        </TR>
    )
}

const Rows = ({ dataSource, onSaveSalePipeline, onToggleCustomerTypeEditForm, onToggleEditForm, refetch, saving }) => {
    return dataSource.map(data => {
        return (
            <SalePipelineRow
                key={data.id}
                record={data}
                onSaveSalePipeline={onSaveSalePipeline}
                onToggleCustomerTypeEditForm={onToggleCustomerTypeEditForm}
                onToggleEditForm={onToggleEditForm}
                refetch={refetch}
                saving={saving}
            />
        )
    })
}

const Render = ({
    activeId,
    activeCustomerId,
    activeOrderId,
    dataSource,
    onLoadMore,
    onSaveSalePipeline,
    onToggleCustomerTypeEditForm,
    onToggleEditForm,
    pageInfo,
    refetch,
    saving,
    showCustomerTypeEditForm,
    showEditForm,
    spinning,
}) => {
    const { i18n } = useTranslation('salePipelines')

    return (
        <StyleWrapper>
            <div className='table-responsive'>
                <Table className="width-100">
                    <Table.Head>
                        {renderHeader(i18n)}
                    </Table.Head>
                    <Table.Body>
                        <Rows
                            dataSource={dataSource}
                            onSaveSalePipeline={onSaveSalePipeline}
                            onToggleCustomerTypeEditForm={onToggleCustomerTypeEditForm}
                            onToggleEditForm={onToggleEditForm}
                            refetch={refetch}
                            saving={saving}
                        />
                    </Table.Body>
                </Table>
            </div>
            {renderLoadMore(i18n, spinning, onLoadMore, pageInfo)}
            <Modal
                width="50%"
                visible={showEditForm}
                onCancel={onToggleEditForm}
                footer={null}
                destroyOnClose
            >
                <SalePipelineForm
                    salePipelineId={activeId}
                    orderId={activeOrderId}
                    onCancel={onToggleEditForm}
                    onSuccess={() => {
                        onToggleEditForm()
                        refetch()
                    }}
                />
            </Modal>
            <Modal
                visible={showCustomerTypeEditForm}
                onCancel={onToggleCustomerTypeEditForm}
                footer={null}
                destroyOnClose
            >
                <CustomerTypeForm
                    customerId={activeCustomerId}
                    onClose={onToggleCustomerTypeEditForm}
                    onSuccess={() => {
                        onToggleCustomerTypeEditForm()
                        refetch()
                    }}
                />
            </Modal>
        </StyleWrapper>
    )
}

Render.propTypes = {
    activeId: PropTypes.string,
    activeCustomerId: PropTypes.string,
    activeOrderId: PropTypes.string,
    dataSource: PropTypes.array.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    onSaveSalePipeline: PropTypes.func.isRequired,
    onToggleCustomerTypeEditForm: PropTypes.func.isRequired,
    onToggleEditForm: PropTypes.func.isRequired,
    pageInfo: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    showCustomerTypeEditForm: PropTypes.bool,
    showEditForm: PropTypes.bool,
    spinning: PropTypes.bool
}

Render.defaultProps = {}

export default Render
