import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CompanyProjectForm from '../../components/projects/companyProjectForm'

import actions from '../../redux/projects/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveCompanyProject, resetCompanyProjectFormErrors } = actions
const { setNotifications } = notificationActions

class CompanyProjectFormContainer extends Component {
    static propTypes = {
        companyProject: PropTypes.object,
        id: PropTypes.string,
        toggleForm: PropTypes.func.isRequired
    }

    static defaultProps = {
        companyProject: {}
    }

    componentWillUnmount () {
        this.props.resetCompanyProjectFormErrors()
    }

    handleSaveCompanyProject = (params) => {
        const { id, saveCompanyProject, setNotifications, toggleForm } = this.props

        saveCompanyProject({
            id,
            params,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    render () {
        const { companyProject, projects, toggleForm } = this.props

        return (
            <CompanyProjectForm
                companyProject={companyProject}
                formErrors={projects.get('errors').toJS()}
                onSubmitForm={this.handleSaveCompanyProject}
                saving={projects.get('saving')}
                toggleForm={toggleForm}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projects: state.get('projects')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCompanyProject,
        setNotifications,
        resetCompanyProjectFormErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProjectFormContainer)
