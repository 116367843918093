import { all, takeLatest, fork, put, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../../helpers/jsonApi'
import { apiFetch, apiPostForm } from '../../../../helpers/restRequest'
import { shippingAddressesSchema, shippingAddressSchema } from '../../../schema'
import actions from './actions'
import actionsNotification from '../../../notifications/actions'

const { setNotifications } = actionsNotification

const {
    SHIPPING_ADDRESSES_FETCH,
    SHIPPING_ADDRESSES_FETCH_REQUEST,
    SHIPPING_ADDRESSES_FETCH_SUCCESS,
    SHIPPING_ADDRESSES_SET_FORM_ERRORS,
    SHIPPING_ADDRESSES_SET_SAVE,
    SHIPPING_ADDRESSES_SAVE_SUCCESS,
    SHIPPING_ADDRESSES_DELETE_REQUEST,
    SHIPPING_ADDRESSES_DELETE_SUCCESS,
    SHIPPING_ADDRESSES_SAVE_REQUEST,
    SHIPPING_ADDRESS_FETCH_REQUEST,
    SHIPPING_ADDRESS_FETCH,
    SHIPPING_ADDRESS_FETCH_SUCCESS
} = actions

const apiPath = (id) => {
    const basePath = '/shipping_addresses'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *shippingAddressesRequest () {
    yield takeLatest(SHIPPING_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SHIPPING_ADDRESSES_FETCH })
        const customerId = payload.customerPath
        const pageSize = 20
        const page = payload.page
        const path = `/customers/${customerId}` + apiPath() + `?page=${page}&per=${pageSize}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, shippingAddressesSchema)

        yield put({
            type: SHIPPING_ADDRESSES_FETCH_SUCCESS,
            ...normalizeData
        })


    })
}

export function *shippingAddressRequest () {
    yield takeLatest(SHIPPING_ADDRESS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SHIPPING_ADDRESS_FETCH })
        const path = `/customers/${payload.customerId}` + apiPath(payload.id)

        const data = yield call(apiFetch, path)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, shippingAddressSchema)

        yield put({
            type: SHIPPING_ADDRESS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}


export function *submitShippingAddress () {
    yield takeLatest(SHIPPING_ADDRESSES_SAVE_REQUEST, function *({ payload }) {
        yield put({
            type: SHIPPING_ADDRESSES_SET_SAVE,
            saving: true
        })

        const customerId = payload.customerId
        const shippingAddressId = payload.shippingAddressId
        const path = `/customers/${customerId}` + apiPath(shippingAddressId)
        const method = shippingAddressId ? 'PATCH' : 'POST'

        const body = { shipping_address: payload.shippingAddress }
        try {
            const data = yield call(apiPostForm, path, method, body)
            if ('error' in data) {
                yield put({
                    type: SHIPPING_ADDRESSES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: SHIPPING_ADDRESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, shippingAddressSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                const { onSuccess } = payload
                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: SHIPPING_ADDRESSES_SET_SAVE,
                saving: false
            })
        }
    })
}

export function *deleteShippingAddress () {
    yield takeLatest(SHIPPING_ADDRESSES_DELETE_REQUEST, function *({ payload }) {
        yield put({
            type: SHIPPING_ADDRESSES_SET_SAVE,
            saving: true
        })

        const customerId = payload.customerId
        const shippingAddressId = payload.shippingAddressId
        const path = `/customers/${customerId}` + apiPath(shippingAddressId)
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: SHIPPING_ADDRESSES_DELETE_SUCCESS,
                ...normalize(formattedData, shippingAddressSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
            setTimeout(() => location.replace(`/customers/${customerId}`), 500)
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: SHIPPING_ADDRESSES_SET_SAVE,
                saving: false
            })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(shippingAddressesRequest),
        fork(shippingAddressRequest),
        fork(submitShippingAddress),
        fork(deleteShippingAddress)
    ])
}
