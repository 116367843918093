import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import JournalEntryFormatIndexPage from '../containers/JournalEntryFormats'
import JournalEntryFormatItemIndexPage from '../containers/JournalEntryFormats/items'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const JournalEntryFormatsPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'journal_entry_formats') {
			dispatch(changeCurrent(['journal_entry_formats']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={JournalEntryFormatIndexPage} exact />
      <Route path={`${path}/:journal_entry_format_id/items`} component={JournalEntryFormatItemIndexPage} />
		</Switch>
  )
}

JournalEntryFormatsPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

JournalEntryFormatsPage.defaultProps = {}

export default JournalEntryFormatsPage
