import styled from 'styled-components'
const TicketsListWrapper = styled.div`
  .description {
    width: calc(700px - 32px);
    color: inherit;
    margin: 0;
  }

  .ticket-number { padding-left: 8px; }

  .ticket-detail > .detail-row {
    margin-bottom: 4px;

    .detail-label {
      color: rgba(0, 0, 0, .5);
      margin-right: 4px;
    }
  }

  .load-more {
    margin-top: 16px;
    text-align: center;
  }
`

export default TicketsListWrapper
