const actions = {
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL: 'ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL',
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_REQUEST: 'ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_REQUEST',
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS: 'ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS',
    ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE: 'ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE',

    ORDER_BUSINESS_ADDRESS_SAVE_FAIL: 'ORDER_BUSINESS_ADDRESS_SAVE_FAIL',
    ORDER_BUSINESS_ADDRESS_SAVE_REQUEST: 'ORDER_BUSINESS_ADDRESS_SAVE_REQUEST',
    ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS: 'ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS',
    ORDER_BUSINESS_ADDRESS_SAVING: 'ORDER_BUSINESS_ADDRESS_SAVING',

    ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST: 'ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST',
    ORDER_BUSINESS_ADDRESSES_FETCHING: 'ORDER_BUSINESS_ADDRESSES_FETCHING',
    ORDER_BUSINESS_ADDRESSES_SET_PAGINATION: 'ORDER_BUSINESS_ADDRESSES_SET_PAGINATION',
    ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS: 'ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS',
    ORDER_BUSINESS_ADDRESSES_SET_SORTER: 'ORDER_BUSINESS_ADDRESSES_SET_SORTER',

    SHOW_ORDER_BUSINESS_ADDRESS_MODAL: 'SHOW_ORDER_BUSINESS_ADDRESS_MODAL',
    SHOW_ORDER_BUSINESS_ADDRESS_ITEMS_MODAL: 'SHOW_ORDER_BUSINESS_ADDRESS_ITEMS_MODAL',
    SET_SHOW_ORDER_BUSINESS_ADDRESS_ACTIVE: 'SET_SHOW_ORDER_BUSINESS_ADDRESS_ACTIVE',
    ORDER_BUSINESS_ADDRESSES_REPORT_FETCH_REQUEST: 'ORDER_BUSINESS_ADDRESSES_REPORT_FETCH_REQUEST',
    ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING: 'ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING',
    ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS: 'ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS',

    SET_SHOW_SENDING_STATE_FORM: 'SET_SHOW_SENDING_STATE_FORM',
    SHOW_ORDER_MAILING_ADDRESS_MODAL: 'SHOW_ORDER_MAILING_ADDRESS_MODAL',

    ORDER_MAILING_ADDRESS_FETCH_REQUEST: 'ORDER_MAILING_ADDRESS_FETCH_REQUEST',
    ORDER_MAILING_ADDRESS_FETCHING: 'ORDER_MAILING_ADDRESS_FETCHING',
    ORDER_MAILING_ADDRESS_FETCH_SUCCESS: 'ORDER_MAILING_ADDRESS_FETCH_SUCCESS',

    ORDER_MAILING_ADDRESSES_SAVE_REQUEST: 'ORDER_MAILING_ADDRESSES_SAVE_REQUEST',
    ORDER_MAILING_ADDRESSES_SAVING: 'ORDER_MAILING_ADDRESSES_SAVING',
    ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS: 'ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS',
    ORDER_MAILING_ADDRESSES_SAVE_SUCCESS: 'ORDER_MAILING_ADDRESSES_SAVE_SUCCESS',
    SET_ORDER_MAILING_ADDRESS_ACTIVE: 'SET_ORDER_MAILING_ADDRESS_ACTIVE',

    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST: 'CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST',
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING: 'CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING',
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS: 'CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS',

    SEND_TAX_INVOICES_MAIL_BY_FILTER_REQUEST: 'SEND_TAX_INVOICES_MAIL_BY_FILTER_REQUEST',
    SEND_TAX_INVOICES_MAIL_BY_FILTER: 'SEND_TAX_INVOICES_MAIL_BY_FILTER',
    SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS: 'SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS',

    deleteTrackingImage: ({
        orderBusinessAddressId = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!orderBusinessAddressId) return null

        return {
            type: actions.ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_REQUEST,
            payload: { orderBusinessAddressId, onSuccess, onError }
        }
    },

    saveOrderBusinessAddress: ({
        orderId = '',
        id = '',
        params = '',
        onSuccess = () => {},
        onError = () => {},
        onUploadProgress = () => {}
    } = {}) => {
        if (!orderId) return null

        return {
            type: actions.ORDER_BUSINESS_ADDRESS_SAVE_REQUEST,
            payload: { orderId, id, params, onSuccess, onError, onUploadProgress }
        }
    },

    fetchOrderBusinessAddresses: ({
        page = 1,
        sorter = {},
        invStartDate = '',
        invEndDate = '',
        sendingState = '',
        haveTrackingNumber = ''
    } = {}) => {
        return {
            type: actions.ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST,
            payload: { page, sorter, invStartDate, invEndDate, sendingState, haveTrackingNumber }
        }
    },

    fetchCustomerOrderBusinessAddresses: ({ customerId, page = 1 } = {}) => {
        return {
            type: actions.CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST,
            payload: { customerId, page }
        }
    },

    setPagination: (pagination) => {
        return {
            type: actions.ORDER_BUSINESS_ADDRESSES_SET_PAGINATION,
            pagination
        }
    },

    setSorter: (sorter) => {
        return { type: actions.ORDER_BUSINESS_ADDRESSES_SET_SORTER, sorter }
    },

    setOrderBusinessAddressModal: (value) => ({
        type: actions.SHOW_ORDER_BUSINESS_ADDRESS_MODAL,
        value
    }),

    setOrderBusinessAddressItemsModal: (value) => ({
        type: actions.SHOW_ORDER_BUSINESS_ADDRESS_ITEMS_MODAL,
        value
    }),

    setOrderBusinessAddressActive: (id, orderId) => ({
        type: actions.SET_SHOW_ORDER_BUSINESS_ADDRESS_ACTIVE,
        id, orderId
    }),

    exportTaxInvoiceReportFile: ({
        invStartDate = '',
        invEndDate = '',
        sendingState = '',
        haveTrackingNumber = ''
    }) => ({
        type: actions.ORDER_BUSINESS_ADDRESSES_REPORT_FETCH_REQUEST,
        payload: { invStartDate, invEndDate, sendingState, haveTrackingNumber }
    }),

    setShowSendingStateForm: ({ id = null } = {}) => ({
        type: actions.SET_SHOW_SENDING_STATE_FORM,
        id
    }),

    setShowMailingAddressForm: (value) => ({
        type: actions.SHOW_ORDER_MAILING_ADDRESS_MODAL,
        value
    }),

    saveOrderMailingAddress: ({
        orderId = '',
        id = '',
        params = '',
        onSuccess = () => {}
    } = {}) => {
        return {
            type: actions.ORDER_MAILING_ADDRESSES_SAVE_REQUEST,
            payload: { orderId, id, params, onSuccess }
        }
    },

    fetchOrderMailingAddress: ({ orderId, id }) => {
        return {
            type: actions.ORDER_MAILING_ADDRESS_FETCH_REQUEST,
            payload: { orderId, id }
        }
    },

    setOrderMailingAddressActive: (id, orderId) => ({
        type: actions.SET_ORDER_MAILING_ADDRESS_ACTIVE,
        id, orderId
    }),

    sendTaxInvoicesMailByFilter: ({
        invStartDate = '',
        invEndDate = '',
        sendingState = '',
        haveTrackingNumber = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SEND_TAX_INVOICES_MAIL_BY_FILTER_REQUEST,
            payload: {
                invStartDate,
                invEndDate,
                sendingState,
                haveTrackingNumber,
                onSuccess,
                onError
            }
        }
    }
}

export default actions
