import React, {useState, useEffect} from 'react'
import { gql, useQuery } from '@apollo/client'

import Pusher from 'pusher-js/with-encryption'
import Channel from './Channel'
import MessageContainer from './MessageContainer'


import SupportContainerWrapper from './SupportContainer.style'


const LINE_CHAT_CHANNEL_QUERY = gql`
  query ClientLineChannels {
    clientLineChannels {
      id
      displayName
      pictureUrl
    }
  }
`

const SupportContainer = ({ match }) => {
  const {id} =  match.params 

  const [pusherChannel, setPusherChannel] = useState(null);

  const {data, error, loading, refetch}= useQuery(LINE_CHAT_CHANNEL_QUERY)

  useEffect(() => {
    const pusher = new Pusher('cd4846fb97629b2f839c', {
      cluster: 'ap1'
    })

    const channel = pusher.subscribe('crm-line-message-channel');
    setPusherChannel(channel)
  }, [])

  useEffect(() => {
    if (pusherChannel && pusherChannel.bind) {
      pusherChannel.unbind('new-channel')
      pusherChannel.bind('new-channel', function(data) {
        refetch()
        return data
      });
    }
  }, [pusherChannel, refetch])

  if (loading) {return null}
  if (error) {console.error(error)}

  return (
    <div>
      <SupportContainerWrapper>
        <div className='left-container'>
          <Channel clientLineChannels={data.clientLineChannels} currentChannel={id} />
        </div>
        <div className='right-container'>
          <MessageContainer currentChannelId={id} pusherChannel={pusherChannel}/>
        </div>
      </SupportContainerWrapper>
    </div>
  )
}

export default SupportContainer