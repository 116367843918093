import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { isMobile } from 'react-device-detect'
import { Statistic, Row, Col, Table, Button, Divider, Popconfirm } from 'antd'
import { withTranslation } from 'react-i18next'
import Image from '../../containers/Image'
import ImagesWrapper from '../images/imagesWrapper.style'
import imagePreviewContainer from '../../containers/ImagePreview/ImagePreviewContainer'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const SupplierOverTransferItemList = ({
    i18n,
    keyIndex,
    dataSourceFrom,
    onDeleteClick,
    onPageChange,
    pagination,
    supplierOverTransferAccount,
    toggleVisibleForm
}) => {
    const renderImages = ImageList => {
        if (!ImageList) {
            return null
        }
        return ImageList.map((image) => {
            const thumbUrl = _.get(image, 'image.s', '')
            const originalUrl = _.get(image, 'image.original', '')
            const contentType = _.get(image, 'image.content_type', '')

            return (
                <Image
                    key={thumbUrl}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    const columns = [{
        key: 'transactionDate',
        title: i18n.t('suppliers/overTransferItems:transactionDate'),
        dataIndex: ['transaction_date', 'display']
    }, {
        key: 'amount',
        title: i18n.t('suppliers/overTransferItems:amount'),
        dataIndex: ['amount', 'display']
    }, {
        key: 'transactionDetail',
        title: i18n.t('suppliers/overTransferItems:transactionDetail'),
        dataIndex: 'transaction_detail',
        render: (dataIndex) => (
            <div key="transactionDetailText">
                {i18n.t(`suppliers/overTransferItems:${_.camelCase(dataIndex)}`)}
            </div>
        )
    }, {
        key: 'detail',
        title: i18n.t('suppliers/overTransferItems:detail'),
        width: 280,
        render: (supplierOverTransactionItem) => {
            const transactionDetail = _.get(supplierOverTransactionItem, 'transaction_detail', '')

            switch (transactionDetail) {
                case 'credit_note':
                    return (
                        <div key="creditNote">
                            <span>
                                {i18n.t('suppliers/overTransferItems:creditNoteWith')}
                            </span>
                            <span style={{ padding: '0 4px' }}>
                                {_.get(supplierOverTransactionItem, 'supplier_over_transfer_destination.order', '')}
                            </span>
                            <span>
                              ({_.get(supplierOverTransactionItem, 'supplier_over_transfer_destination.supplier_order.ref_code', '')})
                            </span>
                        </div>
                    )
                case 'over_transfer':
                    return (
                        <div key="overTransfer">
                            <span>
                                {i18n.t('suppliers/overTransferItems:overTransferFrom')}
                            </span>
                            <span style={{ padding: '0 4px' }}>
                                {_.get(supplierOverTransactionItem, 'order', '')}
                            </span>
                            <span>
                            ({_.get(supplierOverTransactionItem, 'supplier_order.ref_code', '')})
                            </span>
                        </div>
                    )
                default:
                    return <React.Fragment key="nothing" />
            }
        }
    }, {
        key: 'note',
        title: i18n.t('suppliers/overTransferItems:note'),
        dataIndex: 'note'
    }, {
        key: 'images',
        title: i18n.t('suppliers/overTransferItems:slipImages'),
        render: (dataIndex) => {
            return (
                <ImagesWrapper>
                    <div className="image-container">
                        {renderImages(dataIndex.images)}
                    </div>
                </ImagesWrapper>
            )
        }
    }, {
        key: 'actions',
        render: (supplierOverTransactionItem) => {
            const id = _.get(supplierOverTransactionItem, 'id', '')
            const supplierId = _.get(supplierOverTransferAccount, 'supplier.id', '')

            return (
                <React.Fragment>
                    <Button icon={<EditOutlined />} type="link"
                        onClick={() => { toggleVisibleForm({ supplierOverTransactionItem, supplierId }) }}
                    />

                    <Divider type="vertical" />

                    <Popconfirm
                        title="Are you sure delete this?"
                        onConfirm={() => { onDeleteClick({ id, supplierId }) }}
                    >
                        <Button icon={<DeleteOutlined />} type="link" />
                    </Popconfirm>
                </React.Fragment>
            )
        }
    }]

    const renderAddBtn = () => {
        if (dataSourceFrom !== 'suppliers') { return undefined }
        const supplierId = _.get(supplierOverTransferAccount, 'supplier.id', '')

        return (
            <div style={{ textAlign: 'end' }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => toggleVisibleForm({ supplierId })}>
                    {i18n.t('suppliers/overTransferItems:add')}
                </Button>
            </div>
        )
    }

    const renderHeader = () => {
        if (dataSourceFrom === 'suppliers') { return null }

        return (
            <Row>
                <Col span={10}>
                    <Statistic
                        title={i18n.t('suppliers:suppliers')}
                        value={_.get(supplierOverTransferAccount, 'supplier.name', '')}
                    />
                </Col>

                <Col span={10}>
                    <Statistic
                        title={i18n.t('suppliers/overTransferAccounts:balance')}
                        value={_.get(supplierOverTransferAccount, 'balance.display', '0.00')}
                    />
                </Col>

                <Col span={4}></Col>
            </Row>
        )
    }

    return (
        <div key={`supplier-over-transfer-items-list-${keyIndex}`} className="supplier-over-transfer-items-list">
            {renderHeader()}

            <Row gutter={16} style={{ paddingTop: '8px' }}>
                <Table
                    columns={columns}
                    dataSource={_.get(supplierOverTransferAccount, 'supplier_over_transfer_items', [])}
                    onChange={onPageChange}
                    pagination={_.isEmpty(pagination) ? false : pagination}
                    rowKey="id"
                    scroll={isMobile ? { x: 1300 } : { x: 1080 }}
                />
            </Row>
        </div>
    )
}

SupplierOverTransferItemList.propTypes = {
    keyIndex: PropTypes.string,
    dataSourceFrom: PropTypes.string,
    onDeleteClick: PropTypes.func,
    onPageChange: PropTypes.func,
    pagination: PropTypes.object,
    supplierOverAccount: PropTypes.object,
    toggleVisibleForm: PropTypes.func,
    renderSupplierOverTransferItemSlip: PropTypes.func
}

SupplierOverTransferItemList.defaultProps = {
    key: '',
    dataSourceFrom: '',
    onDeleteClick: () => {},
    onPageChange: () => {},
    pagination: {},
    supplierOverAccount: {},
    toggleVisibleForm: () => {},
    renderSupplierOverTransferItemSlip: () => {},
}

export default withTranslation(['suppliers', 'suppliers/overTransferAccounts', 'suppliers/overTransferItems'])(
    imagePreviewContainer(SupplierOverTransferItemList)
)
