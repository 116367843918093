import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Form, Space, Button, Input, Checkbox, Select, Row, Col } from 'antd'

import SalePipelineEnumSelector from '../SalePipelineEnumSelector'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
}

const renderMonthOptions = () => {
  let selectOptions = []

  for (let i = 1; i <= 12; i++) {
      selectOptions.push(
          <Select.Option key={`month-${i}`} value={i.toString()}>{i}</Select.Option>
      )
  }

  return selectOptions
}

const renderYearOptions = () => {
  let selectOptions = []
  const currentYear = moment().year()

  for (let i = currentYear - 2; i <= currentYear + 2; i++) {
      selectOptions.push(
          <Select.Option key={`month-${i}`} value={i.toString()}>{i}</Select.Option>
      )
  }

  return selectOptions
}

const SalePipelineFormRender = ({
  onSubmit,
  onCancel,
  saving,
  initialValues
}) => {
  const { i18n } = useTranslation('salePipelines')

  return (
    <Form onFinish={onSubmit} {...formItemLayout} initialValues={initialValues}>
        <h2>Sale Pipeline</h2>
        <Form.Item label={i18n.t('salePipelines:customerPersonality')} name="customerPersonality">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label={i18n.t('salePipelines:customerType')} name="customerType">
          <SalePipelineEnumSelector name='customer_type'/>
        </Form.Item>
        <Form.Item label={i18n.t('salePipelines:interestingProduct')} name="interestingProduct">
            <Input />
        </Form.Item>
        <Form.Item label={i18n.t('salePipelines:status')} name="status">
          <SalePipelineEnumSelector name='status' allowClear isForm />
        </Form.Item>
        <Row>
          <Col offset={4} span={8}>
            <Form.Item label={i18n.t('salePipelines:month')} name="month">
              <Select>
                  {renderMonthOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={i18n.t('salePipelines:year')} name="year">
              <Select>
                {renderYearOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={6} span={8}>
            <Form.Item name="archived" valuePropName='checked'>
                <Checkbox>{i18n.t('salePipelines:archived')}</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="isPotential" valuePropName='checked'>
              <Checkbox>{i18n.t('salePipelines:isPotential')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      <div style={{ textAlign: 'center' }}>
        <Space>
            <Button loading={saving} type="primary" htmlType="submit">
                {i18n.t('shared:save')}
            </Button>
            <Button onClick={onCancel}>
                {i18n.t('shared:close')}
            </Button>
        </Space>
      </div>
    </Form>
  )
}

SalePipelineFormRender.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  saving: PropTypes.bool
}

SalePipelineFormRender.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {}
}

export default SalePipelineFormRender
