import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Card, Input, Row, Button } from 'antd'

import inquiryActions from '../../../../redux/inquiry/actions'

const { saveOrderForm } = inquiryActions

const NoteForm = ({
  defaultNote,
  onSuccess,
  orderId,
  onCancel
}) => {
  const { i18n } = useTranslation('inquiries')

  const dispatch = useDispatch()
  const saving = useSelector(state => state.getIn(['inquiry', 'saveOrderLoading']))

  const onSubmitForm = (values) => {
    dispatch(saveOrderForm(orderId, values, {
      onSuccess: () => { onSuccess() }
    }))
  }

  return (
    <Form onFinish={onSubmitForm} style={{ width: '50%' }}>
      <Card size='small'>
          <Form.Item name='note' initialValue={defaultNote}>
              <Input.TextArea placeholder="กรอกรายละเอียด" rows={4} />
          </Form.Item>
          <Row style={{ justifyContent: 'center' }}>
              <Button style={{ width: 60, marginRight: '8px' }}
                  type="primary"
                  size="small"
                  htmlType="submit"
                  loading={saving}> {i18n.t('inquiries:edit')}</Button>
              <Button style={{ width: 60 }}
                  size="small"
                  disabled={saving}
                  onClick={onCancel}>
                  {i18n.t('inquiries:cancel')}
              </Button>
          </Row>
      </Card>
  </Form>
  )
}

NoteForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  defaultNote: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
}

NoteForm.defaultProps = {}

export default NoteForm
