import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'

import TruckSelect from '../../components/trucks/truckSelect'

import truckActions from '../../redux/trucks/actions'

const {
    fetchTruck,
    fetchTrucks,
    resetSelectedTruck,
    setSelectedTruckId
} = truckActions

class TruckSelectContainer extends Component {
    static propTypes = {
        // Props for another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Props for inner component
        fetchTruck: PropTypes.func.isRequired,
        fetchTrucks: PropTypes.func.isRequired,
        resetSelectedTruck: PropTypes.func.isRequired,
        setSelectedTruckId: PropTypes.func.isRequired,
        truckEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired,
        vehicleTypeEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: undefined
    }

    componentDidMount () {
        const { value, setSelectedTruckId, truckEntities, trucks, fetchTruck } = this.props

        if (!value) return null

        setSelectedTruckId({
            truckId: value
        })

        if (truckEntities.get(value.toString()) && trucks.get('items').find((id) => id === value)) {
            return null
        }

        fetchTruck({ id: value })
    }

    componentWillUnmount () {
        const { resetSelectedTruck } = this.props

        resetSelectedTruck()
    }

    onChange = (truckId) => {
        const { setSelectedTruckId, onChange } = this.props

        setSelectedTruckId({ truckId })

        if (!onChange) return null
        onChange(truckId || '')
    }

    onSearch = (query) => {
        const { fetchTrucks } = this.props

        fetchTrucks({ query })
    }

    getTrucks () {
        const { truckEntities, trucks, vehicleTypeEntities } = this.props

        return trucks.get('items', new List()).map((truckId) => {
            const truck = truckEntities.get(truckId.toString())

            if (!truck) return new Map()

            return truck.mergeDeep({
                truck_owner_id: truck.get('truck_owner', ''),
                vehicle_type: vehicleTypeEntities.get((truck.get('vehicle_type', '') || '').toString())
            })
        }) || new List()
    }

    render () {
        const { trucks, filedName } = this.props

        return (
            <TruckSelect
                loading={trucks.get('loading')}
                onChange={this.onChange}
                onSearch={this.onSearch}
                selectedTruckId={trucks.get('selectedTruckId')}
                trucks={this.getTrucks().toJS()}
                filedName={filedName}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        truckEntities: state.getIn(['Entities', 'trucks']),
        trucks: state.get('trucks'),
        vehicleTypeEntities: state.getIn(['Entities', 'vehicleTypes'])
    }
}

const mapDispatcherToProps = (dispatch) => {
    return bindActionCreators({
        fetchTruck,
        fetchTrucks,
        resetSelectedTruck,
        setSelectedTruckId
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatcherToProps)(TruckSelectContainer)
