import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Tabs } from 'antd'
import OrderSummariesStatisticsContainer from '../../../containers/OrderSummaries/StatisticsContainer'
import OrderSummariesContainer from '../../../containers/OrderSummaries/OrderSummariesContainer'
import OrderSummariesSalesListContainer from '../../../containers/OrderSummaries/State/SalesListContainer'

const OrdersListByState = ({
    activeKey,
    defaultFilters,
    onChangeTab,
    onRefetch
}) => {
    const { i18n } = useTranslation('statistics')
    const { state, bySale, noPrice, createdStartDate, createdEndDate } = defaultFilters

    return (
        <div>
            <OrderSummariesStatisticsContainer
                defaultFilters={{
                    state,
                    bySale,
                    noPrice: noPrice || '',
                    startDate: createdStartDate,
                    endDate: createdEndDate
                }}
                onRefetch={onRefetch}
            />
            <Tabs activeKey={activeKey} onChange={onChangeTab}>
                <Tabs.TabPane tab={i18n.t('statistics:orders.title')} key="orders">
                    <OrderSummariesContainer
                        forceQuery={{
                            state,
                            bySale,
                            noPrice,
                            createdStartDate,
                            createdEndDate
                        }}
                        hideDivider
                        hideFilter />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t('statistics:sales.title')} key="sales">
                    <OrderSummariesSalesListContainer
                        onRefetch={onRefetch}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

OrdersListByState.propTypes = {
    defaultFilters: PropTypes.shape({
        state: PropTypes.string.isRequired,
        bySale: PropTypes.string,
        noPrice: PropTypes.string,
        createdStartDate: PropTypes.string,
        createdEndDate: PropTypes.string,
    }),
    onChangeTab: PropTypes.func.isRequired,
    onRefetch: PropTypes.func.isRequired
}

OrdersListByState.defaultProps = {}

export default OrdersListByState
