import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withRouter } from 'react-router'
import { Map } from 'immutable'
import _ from 'lodash'

import { Divider, Tabs } from 'antd'

import { Authorization } from '../Authorization'

import BankStatementsFiltersContainer from './FiltersContainer'
import BankStatementsTableContainer from './TableContainer'

import BankStatementSummaryContainer from './BankStatementSummaryContainer'
import BankStatementImportAndExportContainer from './ImportAndExportContainer'

import actions from '../../redux/bankStatements/actions'
import BankStatementsSummary from './BankStatementsSummary'

const {
    // when you want to add a new filter
    // you can add to BANK_STATEMENTS_FETCH_DEFAULT_FILTERS single place
    BANK_STATEMENTS_FETCH_DEFAULT_FILTERS,
    fetchBankStatements,
    setActiveTab
} = actions

class BankStatementsListContainer extends Component {
    static propTypes = {
        // Props from another component
        stateScope: PropTypes.string,

        // Inner Props
        bankStatements: ImmutablePropTypes.map.isRequired,
        fetchBankStatements: PropTypes.func.isRequired
    }

    static defaultProps = {
        stateScope: 'indexPage'
    }

    componentDidMount () {
        this.initFetch()
    }

    initFetch = () => {
        const { location } = this.props
        const filters = {}
        const queryStringObject = this.parseParams(location.search)

        _.mapKeys(queryStringObject, (value, key) => {
            key = _.camelCase(key)
            filters[key] = _.toString(value)
        })

        this.fetchBankStatements({ newFilters: filters })
    }

    fetchBankStatements = ({
                               newFilters = _.mapValues(BANK_STATEMENTS_FETCH_DEFAULT_FILTERS, () => undefined)
                           } = {}) => {
        const { fetchBankStatements, bankStatements, stateScope } = this.props

        const currentFilters = bankStatements.getIn([stateScope, 'filters'], new Map())

        const filters = _.merge(
            _.isEmpty(currentFilters) ? BANK_STATEMENTS_FETCH_DEFAULT_FILTERS : currentFilters,
            newFilters
        )

        fetchBankStatements({
            filters: filters,
            onSuccess: () => {
                this.setPathname({
                    filters: filters
                })
            }
        })
    }

    parseParams = (params = "") => {
        if(!params) return {}
        const rawParams = params.replace("?", "").split("&");
        const extractedParams = {}
        rawParams.forEach((item) => {
            item = item.split("=");
            extractedParams[item[0]] = item[1];
        })
        return extractedParams;
    }

    setPathname ({
                     filters = {}
                 } = {}) {
        const { history } = this.props
        const newQuery = _.mapValues(
            _.mapKeys(filters, (value, key) => _.snakeCase(key)),
            (value) => _.toString(value)
        )
        const query = _.pickBy(newQuery, item => item)
        const queryString = Object.keys(query).map(key => key + '=' + query[key]).join('&')

        history.push({
            search: `?${queryString}`,
            params: newQuery
        })
    }

    onFiltersChange = (values) => {
        this.fetchBankStatements({ newFilters: { ...values } })
    }

    onTableChange = (pagination) => {
        this.fetchBankStatements({
            newFilters: {
                page: _.toNumber(_.get(pagination, 'current', 1)),
                per: _.toNumber(_.get(pagination, 'pageSize', 20))
            }
        })
    }

    render () {
        const { bankStatements, stateScope, setActiveTab } = this.props
        const per = bankStatements.getIn([stateScope, 'filters', 'per'], 20)
        const totalPages = bankStatements.getIn([stateScope, 'totalPages'], 0)

        return (
            <>
                <BankStatementImportAndExportContainer />

                <Divider />

                <BankStatementSummaryContainer />

                <Authorization
                    allowedRoles={['super_admin', 'accounting']}
                    redirectWhenNotAllowed={false}
                >
                    <Tabs defaultActiveKey="bank_statement_summary" onTabClick={setActiveTab}>
                        <Tabs.TabPane tab='Summary' key="bank_statement_summary">
                            <BankStatementsSummary />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab='Bank Statements' key="bank_statement_list">
                            <BankStatementsFiltersContainer
                                onChange={this.onFiltersChange}
                                value={{
                                    bankAccountId: bankStatements.getIn([stateScope, 'filters', 'bankAccountId'], undefined),
                                    hasOrder: bankStatements.getIn([stateScope, 'filters', 'hasOrder'], undefined),
                                    startDate: bankStatements.getIn([stateScope, 'filters', 'startDate'], undefined),
                                    endDate: bankStatements.getIn([stateScope, 'filters', 'endDate'], undefined),
                                    isReconciled: bankStatements.getIn([stateScope, 'filters', 'isReconciled'], undefined),
                                    bankStatementType: bankStatements.getIn([stateScope, 'filters', 'bankStatementType'], undefined)
                                }}
                            />
                            <BankStatementsTableContainer
                                pagination={{
                                    current: bankStatements.getIn([stateScope, 'filters', 'page'], 1),
                                    pageSize: per,
                                    total: _.toNumber(per) * _.toNumber(totalPages),
                                    position: 'both',
                                    showSizeChanger: false
                                }}
                                onChange={this.onTableChange}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Authorization>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBankStatements,
        setActiveTab
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(BankStatementsListContainer)
)
