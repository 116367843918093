import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch, apiFileFetch, apiPostForm } from '../../helpers/restRequest'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { objectToQueryString } from '../../helpers/queryString'
import i18n from 'i18next'
import moment from 'moment'
import _ from 'lodash'

import actions from './actions'
import supplierActions from '../suppliers/actions'
import actionsNotification from '../../redux/notifications/actions'

import { PAGE_SIZE } from '../../constants/orders'
import downLoadFile from '../../helpers/downLoadFile'

import {
    paymentVouchersSchema,
    supplierLineItemsSchema,
    supplierOrderSchema,
    supplierOrdersSchema,
    supplierOrderStatesSchema
} from '../schema'

const {
    fetchOrdersSupplierOrders: refetchSupplierOrders,
    ORDERS_SUPPLIER_ORDERS_FETCH_REQUEST,
    ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS,
    ORDERS_SUPPLIER_ORDERS_FETCHING,
    SUPPLIER_ORDER_CANCEL_REQUEST,
    SUPPLIER_ORDER_CANCELED,
    SUPPLIER_ORDER_FETCH_REQUEST,
    SUPPLIER_ORDER_FETCH_SUCCESS,
    SUPPLIER_ORDER_FETCH,
    SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_REQUEST,
    SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING,
    SUPPLIER_ORDER_STATE_CHANGE_FAIL,
    SUPPLIER_ORDER_STATE_CHANGE_REQUEST,
    SUPPLIER_ORDER_STATE_CHANGE_SUCCESS,
    SUPPLIER_ORDER_STATE_CHANGING,
    SUPPLIER_ORDERS_FETCH_REQUEST,
    SUPPLIER_ORDERS_FETCH_SUCCESS,
    SUPPLIER_ORDERS_FETCH,
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH_REQUEST,
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH,
    SUPPLIER_ORDERS_PDF_FETCH_REQUEST,
    SUPPLIER_ORDERS_PDF_FETCH_SUCCESS,
    SUPPLIER_ORDERS_PDF_FETCH,
    SUPPLIER_ORDERS_SAVE_REQUEST,
    SUPPLIER_ORDERS_SAVE,
    SUPPLIER_ORDERS_SET_PAGINATION,
    SUPPLIER_ORDERS_SHOW_FORM,
    SUPPLIER_ORDERS_STATES_FETCH_REQUEST,
    SUPPLIER_ORDERS_STATES_FETCH_SUCCESS,
    SUPPLIER_ORDERS_STATES_FETCH,
    SUPPLIER_SHIPPING_COST_FETCH_FILE_REQUEST,
    SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS,
    SUPPLIER_SHIPPING_COST_FETCH_FILE,
    SUPPLIER_SHIPPING_COST_IMPORT_FILE_REQUEST,
    SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING,
    SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE_SUCCESS,
    SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE,
    SUPPLIER_SHIPPING_DRIVER_INFO_FILE_REQUEST,
    SUPPLIERS_ORDER_PAYMENT_EXPORT_REQUEST,
    SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING,
    SUPPLIER_ORDER_QUERY_FETCH_REQUEST,
    SUPPLIER_ORDER_QUERY_LOADING,
    SUPPLIER_ORDER_QUERY_FETCH_SUCCESS,
    EXPORT_SUPPLIER_ORDERS_REQUEST,
    IMPORT_SUPPLIER_ORDER_UPLOADING,
    IMPORT_SUPPLIER_ORDER_REQUEST,
    IMPORT_SUPPLIER_ORDER_MODAL,
    DOWNLOAD_SHIPPING_INVOICE_REQUEST,
    DOWNLOAD_SHIPPING_INVOICE_SET_LOADING,
    EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_REQUEST,
    EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING,
    PV_TRANSFER_BACKS_FETCH_REQUEST,
    PV_TRANSFER_BACKS_FETCH_SUCCESS,
    PV_TRANSFER_BACKS_FETCH,
    PV_TRANSFER_BACKS_SET_PAGINATION
} = actions

const { setNotifications } = actionsNotification

export function *changeStateToSaga () {
    yield takeLatest(SUPPLIER_ORDER_STATE_CHANGE_REQUEST, function *({ payload }) {
        const { state, id, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_ORDER_STATE_CHANGING })

        try {
            const path = `/suppliers/orders/${id}/states/${state}`
            const data = yield call(apiFetch, path, 'PATCH')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierOrderSchema)

            yield put({
                type: SUPPLIER_ORDER_STATE_CHANGE_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_ORDER_STATE_CHANGE_FAIL })
            onError(error)
        }
    })
}

export function *exportSupplierOrderSaga () {
    yield takeLatest(EXPORT_SUPPLIER_ORDERS_REQUEST, function *({ payload }) {
        const { queryString, newFormat } = payload

        const path = `/suppliers/orders/exports?${queryString}&new_format=${newFormat}`
        yield call(apiFetch, path, 'GET')
        i18n.loadNamespaces('suppliers')

        yield put(setNotifications(`${i18n.t('suppliers:supplierOrder.success')}`,
            i18n.t('suppliers:supplierOrder.sendToEmail'),
            'info'))
    })
}

export function *importSupplierOrderSaga () {
    yield takeLatest(IMPORT_SUPPLIER_ORDER_REQUEST, function *({ payload }) {
        yield put({ type: IMPORT_SUPPLIER_ORDER_UPLOADING, uploading: true })
        const { file } = payload
        const path = `/suppliers/orders/imports`
        const method = 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { file })
            i18n.loadNamespaces('suppliers')

            if (data.status === 'ok') {
                yield put(
                    setNotifications(
                        `${i18n.t('suppliers:uploadSupplierOrders')} ${i18n.t('shared:successful')}`,
                        i18n.t('suppliers:shippingCost.uploadSuccess'),
                        'info',
                        10000
                    )
                )
            } else {
                yield put(
                    setNotifications(
                        `${i18n.t('suppliers:uploadSupplierOrders')} ${i18n.t('shared:error')}`,
                        i18n.t('suppliers:shippingCost.uploadError'),
                        'error'
                    )
                )
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: IMPORT_SUPPLIER_ORDER_UPLOADING, uploading: false })
            yield put({ type: IMPORT_SUPPLIER_ORDER_MODAL, isShowSupplierOrderImportModal: false })
        }
    })
}

export function *receiveSupplierOrders () {
    yield takeLatest(SUPPLIER_ORDERS_FETCH_REQUEST, function *({ payload }) {
        const { queryString, withoutTransferBack, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_ORDERS_FETCH })

        try {
            let genQueryString = payload.queryString
            if (_.isObject(queryString)) {
                const state = queryString.order_state ? `order_state=${queryString.order_state}` : ''
                const extraState = queryString.state ? `state=${queryString.state}` : ''
                const query = queryString.query ? `query=${queryString.query}` : ''
                let supplier = queryString.supplier
                if (typeof supplier === 'object') {
                    supplier = supplier.map((value) => {
                        return `supplier[]=${value}`
                    }).join('&')
                } else {
                    supplier = supplier ? `supplier[]=${supplier}` : ''
                }
                let accountingGroup = queryString.accounting_group
                if (typeof accountingGroup === 'object') {
                    accountingGroup = accountingGroup.map((value) => {
                        return `accounting_group[]=${value}`
                    }).join('&')
                } else {
                    accountingGroup = accountingGroup ? `accounting_group[]=${accountingGroup}` : ''
                }
                const dateType = queryString.date_type ? `date_type=${queryString.date_type}` : ''
                const startDate = queryString.start_date ? `start_date=${queryString.start_date}` : ''
                const endDate = queryString.end_date ? `end_date=${queryString.end_date}` : ''
                const isCredit = queryString.is_credit ? `is_credit=${queryString.is_credit}` : ''
                const isAvailable = queryString.is_available ? `is_credit=${queryString.is_available}` : ''
                const isVerified = queryString.is_verified ? `is_verified=${queryString.is_verified}` : ''
                const bySlip = queryString.by_slip ? `by_slip=${queryString.by_slip}` : ''
                const per = `per=${PAGE_SIZE}`

                genQueryString = [
                    state,
                    extraState,
                    query,
                    supplier,
                    accountingGroup,
                    dateType,
                    startDate,
                    endDate,
                    isCredit,
                    isAvailable,
                    isVerified,
                    bySlip,
                    per
                ].filter(Boolean).join('&')
            }

            const path = `/suppliers/orders?${_.isObject(queryString) ? genQueryString : queryString}&without_transfer_back=${withoutTransferBack}`
            const data = yield call(apiFetch, path, 'GET')

            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierOrdersSchema)
            const total = data.meta.total_pages * PAGE_SIZE

            yield put({
                type: SUPPLIER_ORDERS_SET_PAGINATION,
                pagination: {
                    pageSize: PAGE_SIZE,
                    total
                }
            })

            yield put({
                type: SUPPLIER_ORDERS_FETCH_SUCCESS,
                ...normalizeData
            })

            onSuccess()
        } catch (error) {
            onError(error)
        }
    })
}

export function *orderReceiveSupplierOrderState () {
    yield takeEvery(SUPPLIER_ORDERS_STATES_FETCH_REQUEST, function *() {
        yield put({ type: SUPPLIER_ORDERS_STATES_FETCH })

        const data = yield call(apiFetch, '/suppliers/orders/states')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierOrderStatesSchema)

        yield put({
            type: SUPPLIER_ORDERS_STATES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *cancelSupplierOrder () {
    yield takeEvery(SUPPLIER_ORDER_CANCEL_REQUEST, function *({ payload }) {
        const { supplierOrderId, queryString } = payload

        const data = yield call(apiFetch, `/suppliers/orders/${supplierOrderId}/states/cancels`, 'PATCH')
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, supplierOrderStatesSchema)

        yield put({
            type: SUPPLIER_ORDER_CANCELED,
            ...normalizeData
        })

        if (typeof queryString === 'string') {
            yield put(supplierActions.fetchSupplierOrders(queryString))
        } else {
            yield put(actions.fetchSupplierOrders(queryString))
        }
    })
}

export function *receiveSupplierOrderPDF () {
    yield takeLatest(SUPPLIER_ORDERS_PDF_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_ORDERS_PDF_FETCH, loading: true })

        const { id } = payload
        const path = `/suppliers/orders/${id}/documents`
        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({ type: SUPPLIER_ORDERS_PDF_FETCH_SUCCESS })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIER_ORDERS_PDF_FETCH, loading: false })
        }
    })
}


export function *importShippingCost () {
    yield takeLatest(SUPPLIER_SHIPPING_COST_IMPORT_FILE_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING, uploading: true })

        const { file } = payload
        const path = '/suppliers/shipping_costs/imports'
        const method = 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { file })
            i18n.loadNamespaces('suppliers')

            if (data.status === 'ok') {
                yield put(
                    setNotifications(
                        `${i18n.t('suppliers:shippingCost.upload')} ${i18n.t('shared:successful')}`,
                        i18n.t('suppliers:shippingCost.uploadSuccess'),
                        'info',
                        10000
                    )
                )
            } else {
                yield put(
                    setNotifications(
                        `${i18n.t('suppliers:shippingCost.upload')} ${i18n.t('shared:error')}`,
                        i18n.t('suppliers:shippingCost.uploadError'),
                        'error'
                    )
                )
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING, uploading: false })
        }
    })
}

export function *receiveShippingCostFile () {
    yield takeLatest(SUPPLIER_SHIPPING_COST_FETCH_FILE_REQUEST, function *({ payload }) {
        const { value } = payload
        yield put({ type: SUPPLIER_SHIPPING_COST_FETCH_FILE })

        const fileExtension = 'xlsx'
        const startDate = `start_date=${value['start_date']}`
        const endDate = `end_date=${value['end_date']}`
        const queryString = `?${startDate}&${endDate}`

        const path = `/suppliers/shipping_costs/exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)
        const timestamp = moment().format('x')

        downLoadFile({
            fileURL: fileURL,
            filename: `supplier-order-shipping-costs-${value.start_date}-to-${value.end_date}-${timestamp}.${fileExtension}`
        })

        yield put({
            type: SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS
        })
    })
}

export function *receiveDriverInfoFile () {
    yield takeLatest(SUPPLIER_SHIPPING_DRIVER_INFO_FILE_REQUEST, function *({ payload }) {
        const { value } = payload
        yield put({ type: SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE })

        const fileExtension = 'xlsx'
        const startDate = `start_date=${value['start_date']}`
        const endDate = `end_date=${value['end_date']}`
        const queryString = `?${startDate}&${endDate}`

        const path = `/truck_owners/delivered/exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `driver-info-${value.start_date}-to-${value.end_date}.${fileExtension}`
        })

        yield put({
            type: SUPPLIER_SHIPPING_DRIVER_INFO_FETCH_FILE_SUCCESS
        })
    })
}

export function *exportSupplierOrderPaymentPDFRequest () {
    yield takeLatest(SUPPLIERS_ORDER_PAYMENT_EXPORT_REQUEST, function *({ date }) {
        yield put({ type: SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING, loading: true })
        const path = `/suppliers/orders/payment_completed_documents?date_of_purchase=${date}`

        try {
            yield call(apiFileFetch, path)
            i18n.loadNamespaces('suppliers')
            yield put(setNotifications(`${i18n.t('suppliers:supplierOrder.success')}`,
                i18n.t('suppliers:supplierOrder.sendToEmail'),
                'info'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING, loading: false })
        }
    })
}

export function *exportSupplierOrderPaymentVoucherRequest () {
    yield takeLatest(SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING, loading: true })

        const fileExtension = 'xlsx'
        const paymentVoucherObj = { payment_voucher_ids: payload.paymentVoucherIds }
        const newFormat = payload.newFormat ? payload.newFormat : 'false'

        const queryString = objectToQueryString(paymentVoucherObj)
        const path = `/payment_vouchers/exports.${fileExtension}?${queryString}&new_format=${newFormat}`

        try {
            yield call(apiFileFetch, path)
            i18n.loadNamespaces('suppliers')
            yield put(setNotifications(`${i18n.t('suppliers:supplierOrder.success')}`,
                i18n.t('suppliers:supplierOrder.sendToEmail'),
                'info'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING, loading: false })
        }
    })
}

export function *ordersSupplierOrdersRequest () {
    yield takeEvery(ORDERS_SUPPLIER_ORDERS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDERS_SUPPLIER_ORDERS_FETCHING })

        let queryString = payload.query

        if (typeof queryString === 'object') {
            const state = queryString.state ? `state=${queryString.state}` : ''
            const query = queryString.query ? `query=${queryString.query}` : ''
            const bySale = queryString.by_sale ? `by_sale=${queryString.by_sale}` : ''
            const byCs = queryString.by_cs ? `by_cs=${queryString.by_cs}` : ''
            const byStaff = queryString.by_staff ? `by_staff=${queryString.by_staff}` : ''
            const per = `per=${PAGE_SIZE}`
            const page = `page=${payload.page}`
            let supplier = queryString.supplier
            if (typeof supplier === 'object') {
                supplier = supplier.map((value) => {
                    return `supplier[]=${value}`
                }).join('&')
            } else {
                supplier = supplier ? `supplier[]=${supplier}` : ''
            }

            queryString = [state, query, bySale, byCs, byStaff, per, page, supplier].filter(Boolean).join('&')
        }

        try {
            const data = yield call(apiFetch, `/orders/supplier_orders?${queryString}`)
            const deserializeData = deserialize(data)
            const normalizeData = normalize(deserializeData, supplierOrdersSchema)
            const total = data.meta.total_pages * PAGE_SIZE
            yield put({
                type: SUPPLIER_ORDERS_SET_PAGINATION,
                pagination: {
                    pageSize: PAGE_SIZE,
                    total
                }
            })

            yield put({
                type: ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch {
            return null
        } finally {
            return null
        }
    })
}

export function *supplierOrderRequest () {
    yield takeEvery(SUPPLIER_ORDER_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_ORDER_FETCH })

        const { refCode } = payload
        try {
            const data = yield call(apiFetch, `/orders/supplier_orders/${refCode}`)
            const deserializeData = deserialize(data)
            const normalizeData = normalize(deserializeData, supplierOrderSchema)

            yield put({
                type: SUPPLIER_ORDER_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch {
            return null
        } finally {
            return null
        }
    })
}

export function *saveSupplierOrderRequest () {
    yield takeLatest(SUPPLIER_ORDERS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_ORDERS_SAVE, saving: true })
        const { values, id, queryString } = payload

        const path = `/orders/supplier_orders/${id}`
        const body = { supplier_order: values }

        try {
            yield call(apiPostForm, path, 'PATCH', body)
            yield put({ type: SUPPLIER_ORDERS_SHOW_FORM, value: false })
            yield put(refetchSupplierOrders(queryString))
        } catch (error) {
            console.log(error, 'errors')
        } finally {
            yield put({ type: SUPPLIER_ORDERS_SAVE, saving: false })
        }
    })
}

export function *fetchSupplierOrderLineItems () {
    yield takeEvery(SUPPLIER_ORDERS_LINE_ITEMS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_ORDERS_LINE_ITEMS_FETCH, loading: true })

        const path = `/suppliers/orders/${payload.refCode}/line_items?find_by_ref_code=true`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierLineItemsSchema)

        yield put({
            type: SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
            ...normalizeData
        })

        yield put({ type: SUPPLIER_ORDERS_LINE_ITEMS_FETCH, loading: false })
    })
}

export function *fetchSupplierOrderThroughQuery () {
    yield takeLatest(SUPPLIER_ORDER_QUERY_FETCH_REQUEST, function *({ query }) {
        yield put({ type: SUPPLIER_ORDER_QUERY_LOADING, loading: true })
        const path = `/search/supplier_orders?query=${query}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierOrdersSchema)
            yield put({
                type: SUPPLIER_ORDER_QUERY_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIER_ORDER_QUERY_LOADING, loading: false })
        }
    })
}

export function *exportShippingInvoicePDFRequest () {
    yield takeLatest(DOWNLOAD_SHIPPING_INVOICE_REQUEST, function *({ payload }) {
        yield put({ type: DOWNLOAD_SHIPPING_INVOICE_SET_LOADING, loading: true })
        const { supplierOrderId } = payload
        const path = `/suppliers/orders/${supplierOrderId}/shipping_invoice_documents`

        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: DOWNLOAD_SHIPPING_INVOICE_SET_LOADING, loading: false })
        }
    })
}

export function *exportPvTotalAmountMarginTypeRequest () {
    yield takeLatest(EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_REQUEST, function *({ payload }) {
        yield put({ type: EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING, loading: true })
        const path = `/payment_vouchers/total_amount_margin_type/exports?new_format=${payload.newFormat}`

        try {
            yield call(apiFileFetch, path)
            i18n.loadNamespaces('suppliers')
            yield put(setNotifications(`${i18n.t('suppliers:supplierOrder.success')}`,
                i18n.t('suppliers:supplierOrder.sendToEmail'),
                'info'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING, loading: false })
        }
    })
}

export function *fetchPVTransferBacks () {
    yield takeEvery(PV_TRANSFER_BACKS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: PV_TRANSFER_BACKS_FETCH })

        const { page, per, filters } = payload
        let queryArray = [`page=${page}`, `per=${per}`, 'only_transfer_back=true']

        _.each(filters, (value, key) => {
            queryArray.push(`${_.snakeCase(key)}=${value}`)
        })

        const path = `/payment_vouchers?${queryArray.join('&')}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, paymentVouchersSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: PV_TRANSFER_BACKS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PV_TRANSFER_BACKS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(cancelSupplierOrder),
        fork(changeStateToSaga),
        fork(exportSupplierOrderPaymentPDFRequest),
        fork(exportSupplierOrderPaymentVoucherRequest),
        fork(receiveSupplierOrders),
        fork(fetchSupplierOrderLineItems),
        fork(importShippingCost),
        fork(orderReceiveSupplierOrderState),
        fork(ordersSupplierOrdersRequest),
        fork(receiveDriverInfoFile),
        fork(receiveShippingCostFile),
        fork(receiveSupplierOrderPDF),
        fork(saveSupplierOrderRequest),
        fork(supplierOrderRequest),
        fork(fetchSupplierOrderThroughQuery),
        fork(exportSupplierOrderSaga),
        fork(importSupplierOrderSaga),
        fork(exportShippingInvoicePDFRequest),
        fork(exportPvTotalAmountMarginTypeRequest),
        fork(fetchPVTransferBacks)
    ])
}
