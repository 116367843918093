import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../constants/orders'

const {
    SET_PURCHASE_ORDER_FORM_MODAL,
    SET_SUPPLIER_ORDER_FORM_MODAL,
    SET_SUPPLIER_ORDER_QUERY,
    SET_SUPPLIER_ORDER_SORTER,
    SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS,
    SUPPLIER_ORDER_REVIEWS_FETCH,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS,
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING,
    SUPPLIER_ORDER_REVIEWS_REMOVE_ITEM_IN_ITEMS,
    SUPPLIER_ORDER_REVIEWS_SET_PAGINATION
} = actions

const initState = new Map({
    activeOrder: null,
    activeRefCode: null,
    activeSupplierOrder: null,
    dateType: 'reservation_date',
    endDate: undefined,
    isChecked: '',
    isPoCompleted: '',
    isNotWorking: '',
    item: undefined,
    items: new List(),
    loading: false,
    orderState: 'payment',
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    purchaser: '',
    query: undefined,
    saving: false,
    showPOForm: false,
    showSupplierOrderForm: false,
    sorter: new Map(),
    startDate: undefined,
    supplier: '',
    dueDate: undefined
})

export default function supplierOrdersReviewsReducer (state = initState, action) {
    const result = action.result
    const items = state.get('items', new List())

    switch (action.type) {
        case SUPPLIER_ORDER_REVIEWS_FETCH:
            return state.merge({ loading: true })
        case SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: result,
                query: action.query,
                orderState: action.orderState,
                purchaser: action.purchaser,
                supplier: action.supplier,
                dateType: action.dateType,
                startDate: action.startDate,
                endDate: action.endDate,
                isChecked: action.isChecked,
                isPoCompleted: action.isPoCompleted,
                isNotWorking: action.isNotWorking,
                dueDate: action.dueDate
            })
        case SUPPLIER_ORDER_REVIEWS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case SET_SUPPLIER_ORDER_FORM_MODAL:
            return state.merge({
                activeOrder: action.orderId,
                activeSupplierOrder: action.supplierOrderId,
                showSupplierOrderForm: !state.get('showSupplierOrderForm')
            })
        case SET_PURCHASE_ORDER_FORM_MODAL:
            return state.merge({
                showPOForm: !state.get('showPOForm'),
                activeSupplierOrder: action.supplierOrderId,
                activeOrder: action.orderId,
                activeRefCode: action.refCode
            })
        case SET_SUPPLIER_ORDER_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        case SET_SUPPLIER_ORDER_QUERY:
            return state.merge({ query: action.value })
        case SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL:
            return state.merge({ saving: false })
        case SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS:
            return state.merge({
                item: result,
                items: items.includes(result) ? items : items.push(result),
                saving: false
            })
        case SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING:
            return state.merge({ saving: true })
        case SUPPLIER_ORDER_REVIEWS_REMOVE_ITEM_IN_ITEMS:
            return state.merge({
                items: !items.includes(action.orderId) ? items : items.delete(items.indexOf(action.orderId))
            })
        default:
            return state
    }
}
