import { DEFAULT_CURRENT_PAGE, PAGE_SIZE } from '../../constants/orders'

const actions = {
  PAYMENT_VOUCHERS_FETCH_REQUEST: 'PAYMENT_VOUCHERS_FETCH_REQUEST',
  PAYMENT_VOUCHERS_FETCH: 'PAYMENT_VOUCHERS_FETCH',
  PAYMENT_VOUCHERS_FETCH_SUCCESS: 'PAYMENT_VOUCHERS_FETCH_SUCCESS',

  PAYMENT_VOUCHER_SAVE_REQUEST: 'PAYMENT_VOUCHER_SAVE_REQUEST',
  PAYMENT_VOUCHER_SAVE: 'PAYMENT_VOUCHER_SAVE',
  PAYMENT_VOUCHER_SAVE_SUCCESS: 'PAYMENT_VOUCHER_SAVE_SUCCESS',

  PAYMENT_VOUCHER_SET_ERRORS: 'PAYMENT_VOUCHER_SET_ERRORS',
  PAYMENT_VOUCHERS_SET_FILTERS: 'PAYMENT_VOUCHERS_SET_FILTERS',
  PAYMENT_VOUCHERS_SET_PAGINATION: 'PAYMENT_VOUCHERS_SET_PAGINATION',

  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_REQUEST: 'PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_REQUEST',
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING: 'PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING',
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS: 'PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS',
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL: 'PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL',

  fetchPaymentVouchers: ({
    page = DEFAULT_CURRENT_PAGE,
    per = PAGE_SIZE,
    transferStartDate = '',
    transferEndDate = '',
    haveBankStatement = '',
    onSuccess = () => {},
    onError = () => {}
  } = {}) => ({
    type: actions.PAYMENT_VOUCHERS_FETCH_REQUEST,
    payload: { page, per, transferStartDate, transferEndDate, haveBankStatement, onSuccess, onError }
  }),

  savePaymentVoucher: (id = null, { params, list = true, onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.PAYMENT_VOUCHER_SAVE_REQUEST,
    payload: { id, params, list, onSuccess, onError }
  }),

  exportPaymentVoucherWithoutInvoices: ({
    params = {},
    onSuccess = () => {},
    onError = () => {}
} = {}) => {
    return {
        type: actions.PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_REQUEST,
        payload: { params, onSuccess, onError }
    }
},
}

export default actions
