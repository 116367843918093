import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import AddCustomerForm from '../../components/projects/addCustomerForm'

import actions from '../../redux/projects/customers/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveCompanyProjectCustomer, setAddCustomerFormModal } = actions
const { setNotifications } = notificationActions

class AddCustomerFormContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        toggleForm: PropTypes.func
    }

    handleSubmitForm = (params) => {
        const { projectId, saveCompanyProjectCustomer, toggleForm, setNotifications } = this.props

        saveCompanyProjectCustomer({
            projectId,
            params,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
                toggleForm()
            },
            onError: (error) => {
                setNotifications(`${error.name}`, `${error.message}`, 'error')
            }
        })
    }

    toggleAddCustomerForm = () => {
        const { projectCustomers, setAddCustomerFormModal } = this.props

        setAddCustomerFormModal(!projectCustomers.get('showAddCustomerForm'))
    }

    render () {
        const { projectCustomers } = this.props

        return (
            <AddCustomerForm onSubmitForm={this.handleSubmitForm}
                toggleForm={this.toggleAddCustomerForm}
                saving={projectCustomers.get('saving')} />
        )
    }
}

const mapStateToProps = (state) => ({
    projectCustomers: state.get('projectCustomers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCompanyProjectCustomer,
        setAddCustomerFormModal,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerFormContainer)
