import React from 'react'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'

import OrdersListByStateContainer from './OrdersListByStateContainer'

import { titleize } from '../../../helpers/string'

class OrderSummaryStatesPage extends React.Component {
    componentDidMount() {
        document.title = 'Order Summary | OSH CRM'
    }

    render () {
        const { history, location, match } = this.props
        const { state } = match.params

        return (
            <Layout>
                <LayoutContentWrapper 
                    breadcrumbs={[
                        { path: '/order_summaries', name: 'Orders' },
                        { path: `/order_summaries/${state}/state`, name: titleize(state) },
                    ]}
                >
                    <ContentLayout>
                        <OrdersListByStateContainer
                            history={history}
                            location={location}
                            state={state} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default OrderSummaryStatesPage
