import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { List as immutableList } from 'immutable'
import { Form, Input, Button, Upload, Modal, DatePicker, Tag, Space } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import moment from 'moment'
import EditorContainer from '../Editor/EditorContainer'

import vatDocumentActions from '../../redux/vatDocuments/actions'

const { saveSupplierInvoice, deleteSupplierInvoices, setSupplierOrderRefCode } = vatDocumentActions

class SupplierInvoicesFormContainer extends Component {
    constructor (props) {
        super(props)

        this.state = {
            fileList: []
        }

        this.formRef = createRef()
    }

    componentDidMount () {
        const { supplierInvoiceEntities, imageEntities, supplierInvoiceId, setSupplierOrderRefCode } = this.props

        const supplierInvoiceImages = supplierInvoiceEntities.getIn([supplierInvoiceId, 'supplier_invoice_images'])
	    if (supplierInvoiceImages) {
            const imageThumb = supplierInvoiceImages.map((thumb) => {
                return {
                    uid: thumb.get('id'),
                    url: imageEntities.getIn([thumb.getIn(['file', 's']), 'thumbBlob'])
                }
            })
            this.setState({ fileList: imageThumb.toJS() })
        }

        const supplierOrderRefCodes = supplierInvoiceEntities.getIn([supplierInvoiceId, 'supplier_order_ref_codes_with_pv_external_ids'])
        if (supplierOrderRefCodes) {
            if (supplierOrderRefCodes.length !== 0) {
                setSupplierOrderRefCode(supplierOrderRefCodes)
            }
        }
    }

    handleSetFileList = (fileList) => {
	    this.setState(fileList)
    }

    setImageParams (imageFiles, supplierInvoiceId) {
        if (!imageFiles) { return }
        const { supplierInvoiceEntities } = this.props
        const supplierInvoiceImages = supplierInvoiceEntities.getIn(
            [supplierInvoiceId, 'supplier_invoice_images']
        ) || immutableList()
        let destroyImage = []
        let uploadImage = imageFiles
        supplierInvoiceImages.map((image) => {
            if (!imageFiles.filter(value => value.uid === image.get('id')).length) {
                destroyImage.push({ id: image.get('id'), _destroy: true })
            } else {
                uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
            }
        })

        return uploadImage.map((id) => {
            return {
                file: id.originFileObj,
                _destroy: false
            }
        }).concat(destroyImage)
    }

    handleSubmitInvoiceForm = (values) => {
        const { saveSupplierInvoice, supplierInvoiceId, paymentVoucherId, queryString } = this.props

        const imageFiles = values.supplier_invoice_images_attributes
        const imageParams = this.setImageParams(imageFiles, supplierInvoiceId)
        const issueDate = values['issue_date'] && values['issue_date'].format('YYYY/MM/DD')
        const applyDate = values['apply_date'] && values['apply_date'].format('YYYY/MM/DD')
        const params = {
            ...values,
            issue_date: issueDate,
            apply_date: applyDate,
            payment_voucher_id: paymentVoucherId,
            supplier_invoice_images_attributes: imageParams
        }

        saveSupplierInvoice(params, queryString, supplierInvoiceId)
    }


    handleDeleteSupplierInvoices = () => {
        const { supplierInvoiceId, deleteSupplierInvoices, paymentVoucherId, queryString, i18n } = this.props

        Modal.confirm({
            title: i18n.t('vatDocuments:titleDelete'),
            content: i18n.t('vatDocuments:contentDelete'),
            okText: 'OK',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk () {
                deleteSupplierInvoices(supplierInvoiceId, paymentVoucherId, queryString)
            }
        })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    imageHandler = (file, imgBase64) => {
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        this.formRef.current.setFieldsValue({ supplier_invoice_images_attributes: this.state.fileList.concat(newItemOfFileList) })
        this.setState({ fileList: this.state.fileList.concat(newItemOfFileList), File: newItemOfFileList })
    }

    handleAddRefCode = (refCodeAmount) => {
        const { setSupplierOrderRefCode } = this.props
        const arrayRefCode = refCodeAmount.concat('0')

        setSupplierOrderRefCode(arrayRefCode)
    }

    handleDeleteRefCode = (index) => {
        const { vatDocuments, setSupplierOrderRefCode } = this.props
        const formAmount = vatDocuments.get('supplierOrderRefCode')
        const arrayRefCode = formAmount.remove(index)

        setSupplierOrderRefCode(arrayRefCode)
    }

    renderSupplierRefCodesForm = () => {
        const { i18n } = this.props

        return (
            <Form.List name='supplier_order_ref_codes'>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                <Form.Item {...restField}
                                    name={[name]}
                                    fieldKey={[fieldKey]}>
                                        <Input placeholder="ref code" />
                                </Form.Item>
                                <CloseCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}
                        <Button onClick={() => add()} type="primary" ghost size="small" >
                            {i18n.t('vatDocuments:addRefCode')}
                        </Button>
                    </>
                )}
            </Form.List>
        )
    }

    supplierOrderRefCode = () => {
        return (
            <Form.Item label="Ref Code">
                {this.renderSupplierRefCodesForm()}
            </Form.Item>
        )
    }

    render () {
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 12 }
        }
        const formLongItemLayout = {
            wrapperCol: { span: 24 }
        }

        const formPasteImageLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        }
        const { supplierInvoiceEntities, supplierInvoiceId, vatDocuments, i18n, paymentVoucherEntities, paymentVoucherId } = this.props

        const supplierInvoice = supplierInvoiceEntities.get(supplierInvoiceId) || new Map()
        const paymentVoucher = paymentVoucherEntities.get(paymentVoucherId)
        const refCodes = vatDocuments.get('supplierOrderRefCode')

        let issueDate = supplierInvoice.get('issue_date')
        issueDate = issueDate && moment(issueDate, 'YYYY/MM/DD') || null
        let applyDate = supplierInvoice.get('apply_date')
        applyDate = applyDate && moment(applyDate, 'YYYY/MM/DD') || null

        return (
            <Form onFinish={this.handleSubmitInvoiceForm} {...formItemLayout}
                initialValues={{ supplier_order_ref_codes: refCodes || [] }}
                ref={this.formRef}>
                <Form.Item label={i18n.t('vatDocuments:invoiceNumber')}
                    name='invoice_ref_code'
                    initialValue={supplierInvoice.get('invoice_ref_code') || null}>
                        <Input />
                </Form.Item>
                <Form.Item label={i18n.t('vatDocuments:amount')}
                    name='amount'
                    initialValue={supplierInvoice.get('amount') || paymentVoucher.getIn(['amount', 'raw'])}>
                        <Input />
                </Form.Item>
                <Form.Item label={i18n.t('vatDocuments:branch')}
                    name='branch'
                    initialValue={supplierInvoice.get('branch') || '0000'}>
                        <Input />
                </Form.Item>
                <Form.Item label={i18n.t('vatDocuments:issueDate')}
                    name='issue_date'
                    initialValue={issueDate}>
                        <DatePicker
                            style={{ width: '100%' }}
                            format={'Do MMMM YYYY'} />
                </Form.Item>
                <Form.Item label={i18n.t('vatDocuments:applyDate')}
                    name='apply_date'
                    initialValue={applyDate}>
                        <DatePicker.MonthPicker
                            placeholder="Select month"
                            style={{ width: '100%' }}
                            format={'MMMM YYYY'} />
                </Form.Item>
                {
                    paymentVoucher.get('bank_statement_id') ?
                    <Form.Item label={i18n.t('vatDocuments:bankStatementId')}>
                        <div>
                            {paymentVoucher.get('bank_statement_id')} <Tag color='green'>{i18n.t('vatDocuments:reconciled')}</Tag>
                        </div>
                    </Form.Item> : null
                }
                {this.supplierOrderRefCode(refCodes)}
                <Form.Item label={i18n.t('vatDocuments:pasteImage')} {...formPasteImageLayout}>
                    <EditorContainer theme="snow"
                        placeholder={i18n.t('vatDocuments:pasteImageToUpload')}
                        showToolbar={false}
                        imageHandler={this.imageHandler}
                    />
                </Form.Item>
                <Form.Item {...formLongItemLayout} name='supplier_invoice_images_attributes'
                    getValueFromEvent={this.normFile}>
                        <Upload.Dragger
                            multiple
                            listType="picture-card"
                            showUploadList={{ showPreviewIcon: false }}
                            beforeUpload={() => false}
                            fileList={this.state.fileList}
                            onChange={(e) => this.handleSetFileList(e)}>
                            <div>
                                <PlusOutlined />
                                <div>image</div>
                            </div>
                        </Upload.Dragger>
                </Form.Item>
                <Form.Item label={i18n.t('vatDocuments:note')} name='note'
                    initialValue={supplierInvoice.get('note') || null}>
                        <Input />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit"
                        loading={vatDocuments.get('supplierInvoiceSaving')}>
                        {i18n.t('vatDocuments:submit')}
                    </Button>
                    <Button type="danger"
                        style={{ marginLeft: '10px' }}
                        htmlType="button" onClick={() => this.handleDeleteSupplierInvoices()}>
                        {i18n.t('vatDocuments:delete')}
                    </Button>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = (state) => ({
    paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
    imageEntities: state.getIn(['Entities', 'images']),
    vatDocuments: state.get('vatDocuments'),
    supplierInvoiceEntities: state.getIn(['Entities', 'supplierInvoices'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteSupplierInvoices,
        saveSupplierInvoice,
        setSupplierOrderRefCode
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('vatDocuments')(SupplierInvoicesFormContainer)
)
