import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import { withRouter } from 'react-router'

import JournalEntriesIndexByDate from '../../components/journalEntries/JournalEntriesIndexBydate'

import actions from '../../redux/journalEntries/actions'

const { fetchJournalEntries } = actions

const JournalEntriesIndexByDateContainer = ({ match, location }) => {
    const dispatch = useDispatch()
    const journalEntryEntities = useSelector(state => state.getIn(['Entities', 'journalEntries']))
    const items = useSelector(state => state.getIn(['journalEntries', 'items'], new List()))
    const pagination = useSelector(state => state.getIn(['journalEntries', 'pagination']))
    const loading = useSelector(state => state.getIn(['journalEntries', 'loading'], false))
    const date = match.params.date
    const amountType = new URLSearchParams(location.search).get("type") || ''

    useEffect(() => {
        dispatch(fetchJournalEntries({ date, amountType }))
    }, [])

    const onPageChange = (pagination) => {
        dispatch(fetchJournalEntries({ page: pagination.current, date, amountType }))
    }

    const getJournalEntries = () => {
        return items.map((date) => journalEntryEntities.get(date) || new Map())
    }

    return (
        <JournalEntriesIndexByDate
            journalEntries={getJournalEntries().toJS()}
            date={date}
            loading={loading}
            onPageChange={onPageChange}
            pagination={pagination.toJS()}
        />
    )
}

JournalEntriesIndexByDateContainer.propTypes = {}

JournalEntriesIndexByDateContainer.defaultProps = {}

export default withRouter(JournalEntriesIndexByDateContainer)
