import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import actions from '../../redux/customers/actions'
import SelectCustomer from '../../components/SelectCustomer/SelectCustomer'

const { search, fetchCustomer } = actions

class SelectCustomerContainer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }

    componentDidMount () {
        const { value, fetchCustomer } = this.props

        if (value) { fetchCustomer(value) }
    }

    getCustomers = () => {
        const { customers, customerEntities } = this.props

        return customers.get('items', new List()).map((customerId) => {
            return customerEntities.get(customerId) || new Map()
        })
    }


    handleSearchCustomer = (query = '') => {
        const { search } = this.props

        search(query)
    }

    render () {
        const { value, onChange } = this.props

        return (
            <SelectCustomer
                customers={this.getCustomers().toJS()}
                onChange={onChange}
                onSearch={this.handleSearchCustomer}
                value={value}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    customers: state.get('customers'),
    customerEntities: state.getIn(['Entities', 'customers'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        search,
        fetchCustomer
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCustomerContainer)
