import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { List, Map } from 'immutable'
import { isMobile } from 'react-device-detect'

import { Link } from 'react-router-dom'

import { Button, Popconfirm, Table } from 'antd'
import { EditOutlined,  DeleteOutlined} from '@ant-design/icons'
import withImagePreview from '../../ImagePreview/ImagePreviewContainer'
import Image from '../../Image'
import ImagesWrapper from '../../../components/images/imagesWrapper.style'

import actionShippingAddress from '../../../redux/customers/addresses/shippingAddresses/actions'

const { deleteShippingAddress } = actionShippingAddress

class ShippingAddressesIndexContainer extends Component {
    static propTypes = {
        customers: ImmutablePropTypes.map.isRequired,
        customerEntities: ImmutablePropTypes.map.isRequired,
        shippingAddressEntities: ImmutablePropTypes.map.isRequired
    }

    getShippingAddressColumns = () => {
        const { i18n } = this.props

        return [{
            title: i18n.t('addresses:province'),
            dataIndex: ['province', 'name']
        }, {
            title: i18n.t('addresses:district'),
            dataIndex: ['district', 'name']
        }, {
            title: i18n.t('addresses:phoneOnJob'),
            dataIndex: 'contact_number'
        }, {
            title: i18n.t('addresses:deliveryDetail'),
            dataIndex: 'shipping_detail'
        }, {
            title: i18n.t('addresses:postalCode'),
            dataIndex: 'postal_code'
        }, {
            title: i18n.t('order.image'),
            key: 'image',
            render: (row) => (
                <ImagesWrapper>
                    <div className="image-container">
                        {this.renderImages(row.id)}
                    </div>
                </ImagesWrapper>
            )
        }, {
            title: i18n.t('addresses:actions'),
            dataIndex: 'id',
            render: (id) => this.renderTableAction(id)
        }]
    }

    handleDelete = (customerId, shippingAddressId) => {
        const { deleteShippingAddress } = this.props

        deleteShippingAddress(customerId, shippingAddressId)
    }

    getShippingAddresses () {
        const { customerEntities, customerId, shippingAddressEntities } = this.props
        const shippingAddressIds = customerEntities.getIn([customerId, 'shipping_addresses']) || new List()

        return shippingAddressIds.map((shippingAddressId) => {
            return shippingAddressEntities.get(shippingAddressId) || new Map()
        }) || new List()
    }

    renderImages (shippingAddressId) {
        const { shippingAddressEntities } = this.props
        const imageList = shippingAddressEntities.getIn([shippingAddressId, 'shipping_address_images'])

        return imageList.map((image) => {
            const thumbUrl = image.getIn(['file', 's']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            return (
                <Image
                    key={thumbUrl}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderTableAction (shippingAddressId) {
        const { i18n, customerId } = this.props
        return (
            <div>
                <Link className="icon" to={`/customers/${customerId}/shipping_addresses/${shippingAddressId}/edit`}>
                    <EditOutlined />
                </Link>
                <Popconfirm title={i18n.t('addresses:deleteConfirm')}
                    onConfirm={() => this.handleDelete(customerId, shippingAddressId)}>
                    <a className="icon" style={{ marginLeft: '10px' }}>
                        <DeleteOutlined />
                    </a>
                </Popconfirm>
            </div>
        )
    }

    render () {
        const { customers, customerId, i18n } = this.props
        const customerLoading = customers.get('loading')

        return (
            <div>
                <Link to={`/customers/${customerId}/shipping_addresses/new`}>
                    <Button type="primary" style={{ marginBottom: '20px', float: 'right' }}>
                        {i18n.t('addresses:addAddress')}
                    </Button>
                </Link>
                <Table
                    columns={this.getShippingAddressColumns()}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    dataSource={this.getShippingAddresses().toJS()}
                    loading={customerLoading}
                    pagination={false}
                    rowKey="id"
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    customers: state.get('customers'),
    shippingAddressEntities: state.getIn(['Entities', 'shippingAddresses'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteShippingAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('addresses')(withImagePreview(ShippingAddressesIndexContainer))
)
