import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import SelectBankAccount from '../../components/SelectBankAccount'

import inquiryActions from '../../redux/inquiry/actions'

const {
    fetchBankAccounts
} = inquiryActions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectBankAccountContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,
        allBank: PropTypes.string,

        // Inner Props
        bankAccountEntities: ImmutablePropTypes.map.isRequired,
        fetchBankAccounts: PropTypes.func.isRequired,
        inquiry: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { fetchBankAccounts, allBank } = this.props

        fetchBankAccounts(allBank)
    }

    getBankAccount (id) {
        if (!id) return new Map()

        const { bankAccountEntities } = this.props
        return bankAccountEntities.get(_.toString(id), new Map())
    }

    getBankAccounts () {
        const { inquiry } = this.props

        return inquiry.get('bankAccountItems', new List()).map((id) => {
            return this.getBankAccount(id)
        })
    }

    onChange = (bankAccountId) => {
        const { onChange } = this.props

        onChange(bankAccountId)
        this.setState({ value: bankAccountId })
    }

    render () {
        const {
            onChange,
            inquiry,
            value: propValue,
            ...restProps
        } = this.props

        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectBankAccount
                bankAccounts={this.getBankAccounts().toJS()}
                loading={inquiry.get('bankAccountsLoading', false)}
                onChange={this.onChange}
                value={value}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountEntities: state.getIn(['Entities', 'bankAccounts']),
        inquiry: state.get('inquiry')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBankAccounts
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectBankAccountContainer)
