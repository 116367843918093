const actions = {
    ORDER_TRACKING_FETCH_REQUEST: 'ORDER_TRACKING_FETCH_REQUEST',
    ORDER_TRACKING_FETCH_SUCCESS: 'ORDER_TRACKING_FETCH_SUCCESS',
    ORDER_TRACKING_FETCH: 'ORDER_TRACKING_FETCH',
    ORDER_TRACKING_SAVE_FAIL: 'ORDER_TRACKING_SAVE_FAIL',
    ORDER_TRACKING_SAVE_REQUEST: 'ORDER_TRACKING_SAVE_REQUEST',
    ORDER_TRACKING_SAVE_SUCCESS: 'ORDER_TRACKING_SAVE_SUCCESS',
    ORDER_TRACKING_SAVING: 'ORDER_TRACKING_SAVING',

    fetchOrderTracking: (
        orderId,
        {
            onSuccess = () => {},
            onError = () => {}
        } = {}
    ) => ({
        type: actions.ORDER_TRACKING_FETCH_REQUEST,
        payload: { orderId, onSuccess, onError }
    }),

    saveOrderTracking: ({
        orderId = '',
        // example: orderTrackingParams: { scm_note: 'content here' }
        orderTrackingParams = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!orderId) return null

        return {
            type: actions.ORDER_TRACKING_SAVE_REQUEST,
            payload: { orderId, orderTrackingParams, onSuccess, onError }
        }
    }
}

export default actions
