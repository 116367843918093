import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Table } from 'antd'

const JournalEntriesIndexByDate = ({
    journalEntries,
    loading,
    onPageChange,
    pagination,
    date
}) => {
    const { i18n } = useTranslation('journalEntries')

    const columns = [
        {
          title: i18n.t('journalEntries:accountName'),
          dataIndex: 'account_name'
        },
        {
          title: i18n.t('journalEntries:accountNumber'),
          dataIndex: 'account_number'
        },
        {
            title: i18n.t('journalEntries:creditAccountName'),
            dataIndex: 'credit_account_name'
        },
        {
            title: i18n.t('journalEntries:type'),
            dataIndex: 'amount_type'
        },
        {
            title: i18n.t('journalEntries:amount'),
            dataIndex: ['amount', 'display']
        },
    ]

    return (
        <div>
            <h2>{date}</h2>
            <Table
                columns={columns}
                dataSource={journalEntries}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
        </div>
    )
}

JournalEntriesIndexByDate.propTypes = {
    journalEntries: PropTypes.array,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })])
}

JournalEntriesIndexByDate.defaultProps = {}

export default JournalEntriesIndexByDate
