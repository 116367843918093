import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { Table } from 'antd'

const TruckReservationCostList = props => {
    const { truckReservations, i18n } = props
    const columns = [
        {
            title: i18n.t('truckReservation:shippingDate'),
            dataIndex: 'date',
            render: (dataIndex) => {
                moment.locale(i18n.language)
                const date = dataIndex ? moment(dataIndex).format('Do MMMM YYYY') : null
                return date
            }
        }, {
            title: i18n.t('truckReservation:cost'),
            dataIndex: ['cost', 'display']
        }, {
            title: i18n.t('truckReservation:licensePlateNumber'),
            render: (record) => (
                <div>
                    {record.truck_owner_name} ({record.truck_dispatch_license_plate || '-'})
                </div>
            )
        }
    ]

    return (
        <div>
            <h3>{i18n.t('truckReservation:truckReservationCost')}</h3>
            <Table
                columns={columns}
                dataSource={truckReservations.toJS()}
                pagination={false}
                rowKey={record => record.id} />
        </div>
    )
}

TruckReservationCostList.propTypes = {
    truckReservations: ImmutablePropTypes.list
}

export default withTranslation('truckReservation')(TruckReservationCostList)
