import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_ACTIVITY_LOG_ORDER_FETCH,
    ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS,
    ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION,
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH,
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS
} = actions

const initState = new Map({
    orderItems: new List(),
    orderLoading: false,
    orderPagination: new Map({
        current: null,
        defaultPageSize: PAGE_SIZE,
        total: null
    }),
    lineItemItems: new List(),
    lineItemLoading: false
})

export default function orderActivityLogsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_ACTIVITY_LOG_ORDER_FETCH:
            return state.merge({
                orderLoading: true
            })
        case ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS:
            return state.merge({
                orderLoading: false,
                orderItems: new List(result)
            })
        case ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION:
            return state.mergeDeep({
                orderPagination: new Map(action.pagination)
            })
        case ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH:
            return state.merge({
                lineItemLoading: true
            })
        case ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS:
            return state.merge({
                lineItemLoading: false,
                lineItemItems: new List(result)
            })
        default:
            return state
    }
}
