import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import ProjectContainer from './ProjectContainer'

class ProjectShowPage extends React.Component {
    componentDidMount() {
        document.title = 'Projects | OSH CRM'
    }

    render () {
      const { match } = this.props
      const { id } = match.params
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/projects', name: 'Projects' },
                        { path: `/projects/${id}`, name: id }
                    ]}>
                    <ContentLayout>
                      <ProjectContainer projectId={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default ProjectShowPage
