import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const TICKET_ENUM_QUERY = gql`
    query TicketEnum($name: String!){
        ticketEnum(enumName: $name){
            label
            value
        }
    }
`

const renderOptions = (options = []) => options.map(({ label, value }) => {
    return <Select.Option value={value}>{label}</Select.Option>
})

const TicketEnumSelector = ({ name, ...restProps }) => {
    const { loading, data } = useQuery(TICKET_ENUM_QUERY, { variables: { name } })

    return (
        <Select loading={loading} {...restProps}>
            {renderOptions(data && data.ticketEnum)}
        </Select>
    )
}

TicketEnumSelector.propTypes = {
    name: PropTypes.string.isRequired
}

TicketEnumSelector.defaultProps = {}

export default TicketEnumSelector
