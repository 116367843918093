import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { Form, Spin, Row, Col, Input, Select, Divider, Button, Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import CustomerFormWrapper from './customerForm.style'
import ShippingAddressFormContainer from './ShippingAddresses/ShippingAddressFormContainer'

import CustomerTypesFormContainer from './CustomerTypes/CustomerTypesFormContainer'
import OccupationFormContainer from './Occupations/OccupationFormContainer'
import CompanyFormContainer from './Companies/CompanyFormContainer'
import ContactsFormContainer from './Contacts/ContactsFormContainer'
import { CustomerLabelFormContainer } from './CustomerLabels/CustomerLabelFormContainer'

import actionCustomer from '../../redux/customers/actions'
import actionCustomerTypes from '../../redux/customers/customerTypes/actions'
import actionOccupation from '../../redux/customers/occupations/actions'
import actionCompany from '../../redux/customers/companies/actions'
import actionCustomerRole from '../../redux/customers/roles/actions'
import { fetchCustomerLabels } from '../../redux/customers/customerLabels/actions'

import {
    GENDERS,
    LEAD_SOURCES
} from '../../constants/customers'

const {
    clearCustomerInfo,
    fetchCustomer,
    saveCustomer,
    setCustomerTypes,
    setOccupationModal
} = actionCustomer

const {
    setAddCompanyModal,
    searchCompany,
    setCompanyOption
} = actionCompany

const { fetchCustomerTypes } = actionCustomerTypes
const { fetchOccupations } = actionOccupation
const { fetchCustomerRoles } = actionCustomerRole

const { Option } = Select
const { TabPane } = Tabs

class CustomerFormContainer extends Component {
    constructor () {
        super()

        this.formRef = createRef()
        this.state = {
            customerLabelModal: false
        }
    }

    static propTypes = {
        id: PropTypes.string
    }

    componentDidMount () {
        const {
            fetchCustomerTypes,
            fetchOccupations,
            fetchCustomerRoles,
            id,
            fetchCustomer,
            fetchCustomerLabels
        } = this.props || undefined
        fetchCustomerTypes()
        fetchOccupations()
        fetchCustomerRoles()
        fetchCustomerLabels()
        if (!id) { return null }

        fetchCustomer(id)
    }

    componentWillUnmount () {
        const { clearCustomerInfo } = this.props
        clearCustomerInfo()
    }

    handleSubmitCustomer = (values) => {
        const { id, saveCustomer } = this.props
        const roleIds = [values.role_ids]
        this.getPhoneAttribute(values)
        this.getLineAttribute(values)
        this.getEmailAttributes(values)
        let customerValues = {
            id: id,
            ...values,
            role_ids: roleIds
        }

        saveCustomer(customerValues)
    }

    getCustomerRole = (customer) => {
        const customerRole = customer.getIn(['roles', '0'])
        return customerRole && customerRole.toString()
    }

    getPhoneAttribute = (value) => {
        const { id } = this.props
        const { entities } = this.props
        const fetchcustomer = entities.getIn(['customers', `${id}`]) || new Map()
        const phoneNumbers = fetchcustomer.get('customer_phone_numbers') || new List()
        const phoneNumberAttributes = value.customer_phone_numbers_attributes
        if (!phoneNumberAttributes) return null

        let attributes = []
        phoneNumbers.map((numberId) => {
            const data = _.find(phoneNumberAttributes, ['id', `${numberId}`])

            if (!data) {
                attributes.push ({
                    id: numberId,
                    _destroy: 1
                })
            }
        })

        value.customer_phone_numbers_attributes = [...attributes, ...phoneNumberAttributes]
    }

    getLineAttribute = (value) => {
        const { id } = this.props
        const { entities } = this.props
        const fetchcustomer = entities.getIn(['customers', `${id}`]) || new Map()
        const lines = fetchcustomer.get('customer_lines') || new List()
        const linesAttributes = value.customer_lines_attributes
        if (!linesAttributes) return null

        let attributes = []
        lines.map((numberId) => {
            const data = _.find(linesAttributes, ['id', `${numberId}`])

            if (!data) {
                attributes.push ({
                    id: numberId,
                    _destroy: 1
                })
            }
        })

        value.customer_lines_attributes = [...attributes, ...linesAttributes]
    }

    getEmailAttributes = (value) => {
        const { id } = this.props
        const { entities } = this.props
        const fetchcustomer = entities.getIn(['customers', `${id}`]) || new Map()
        const emails = fetchcustomer.get('customer_emails') || new List()
        const emailAttributes = value.customer_emails_attributes
        if (!emailAttributes) return null

        let attributes = []
        emails.map((numberId) => {
            const data = _.find(emailAttributes, ['id', `${numberId}`])

            if (!data) {
                attributes.push ({
                    id: numberId,
                    _destroy: 1
                })
            }
        })

        value.customer_emails_attributes = [...attributes, ...emailAttributes]
    }

    setCustomerTypesModal = (value) => {
        const { setCustomerTypes } = this.props
        setCustomerTypes(!value)
    }

    setOccupationModal = (value) => {
        const { setOccupationModal } = this.props
        setOccupationModal(!value)
    }

    setCompanyModal = (value) => {
        const { setAddCompanyModal } = this.props
        setAddCompanyModal(!value)
    }

    triggerCustomerLabelModal = () => {
        this.setState({ customerLabelModal: !this.state.customerLabelModal })
    }

    handleSearchCompany = (value) => {
        const { searchCompany } = this.props

        searchCompany(value)
    }

    handleSelectCompany = (value) => {
        const { setCompanyOption } = this.props

        setCompanyOption(value)
    }

    customerTypeOption = () => {
        const { entities, customerTypes } = this.props
        const customerTypeItems = entities.get('customerTypes')
        const customerTypeId = customerTypes.get('items')

        return customerTypeId.map((id) => {
            const item = customerTypeItems.getIn([`${id}`, 'name'])
            return (
                <Option value={id} key={`${item}`}>
                    {item}
                </Option>
            )
        })
    }

    occupationOption = () => {
        const { entities, customerOccupations } = this.props
        const customerOccupationItems = entities.get('customerOccupations')
        const customerOccupationId = customerOccupations.get('items')

        return customerOccupationId.map((id) => {
            const occupation = customerOccupationItems.getIn([`${id}`, 'name'])
            return (
                <Option value={id} key={`${occupation}`}>
                    {occupation}
                </Option>
            )
        })
    }

    customerLabelOptions = () => {
        const { customerLabels } = this.props
        return customerLabels.get('labels').map((label) => {
            const { id: labelId, label: labelName } = label
            return (
                <Select.Option key={labelId} value={labelId}>
                    {labelName}
                </Select.Option>
            )
        })
    }


    genderOption = () => {
        return GENDERS.map((gender, index) => {
            return (
                <Select.Option key={index} value={gender.value}>
                    {this.props.i18n.t(`customerForm.${gender.i18n}`)}
                </Select.Option>
            )
        })
    }

    leadSourceTypeOption = () => {
        return LEAD_SOURCES.map((source, index) => {
            return (
                <Option key={index} value={source}>
                    {source}
                </Option>
            )
        })
    }

    companyOption = () => {
        const { customerCompanies, entities } = this.props
        const companiesEntities = entities.get('customerCompanies')

        return customerCompanies.get('items').map((companyId) => {
            const company = companiesEntities.getIn([companyId, 'name'])

            return (
                <Option key={companyId} value={companyId}>{company}</Option>
            )
        })
    }

    customerRoleTypeOption = () => {
        const { customerRoles, entities } = this.props
        const customerRolesEntities = entities.get('customerRoles')

        return customerRoles.get('items').map((roleId) => {
            const role = customerRolesEntities.getIn([roleId, 'name'])

            return (
                <Option key={roleId} value={roleId}>{role}</Option>
            )
        })
    }

    getDefaultPhoneNumbers = () => {
        const { entities, customers } = this.props
        const phoneAmounts = customers.get('phoneAmount') || new List()
        if(_.isEmpty(phoneAmounts)) return new List()

        const customerPhoneNumbers = entities.get('customerPhoneNumbers')
        return phoneAmounts.map((id) => {
            const data = customerPhoneNumbers.get(id) || new Map()
            return {
                id: data.get('id'),
                body: data.get('body'),
                body_type: data.get('body_type')
            }
        })
    }

    getDefaultLines = () => {
        const { entities, customers } = this.props
        const lineAmount = customers.get('lineAmount') || new List()
        if(_.isEmpty(lineAmount)) return new List()

        const customerLines = entities.get('customerLines')
        return lineAmount.map((id) => {
            const data = customerLines.get(id) || new Map()
            return {
                id: data.get('id'),
                body: data.get('body'),
                body_type: data.get('body_type')
            }
        })
    }

    getDefaultEmails = () => {
        const { entities, customers } = this.props
        const emailAmount = customers.get('emailAmount') || new List()
        if(_.isEmpty(emailAmount)) return new List()

        const customerEmails = entities.get('customerEmails')
        return emailAmount.map((id) => {
            const data = customerEmails.get(id) || new Map()
            return {
                id: data.get('id'),
                body: data.get('body'),
                body_type: data.get('body_type')
            }
        })
    }

    customerFormInitialValues = () => {
        return {
                customer_phone_numbers_attributes: this.getDefaultPhoneNumbers().toJS(),
                customer_emails_attributes: this.getDefaultEmails().toJS(),
                customer_lines_attributes: this.getDefaultLines().toJS()
            }
    }

    onSuccessCreateCompany = (id) => {
        const form = this.formRef.current
        const values = form.getFieldValue('customer_company_ids')

        form.setFieldsValue({ customer_company_ids: values.concat(id) })
    }

    render () {
        const {
            customers,
            entities,
            customerCompanies,
            id,
            i18n,
            location,
        } = this.props
        const customerTypesModal = customers.get('customerTypesModal')
        const occupationModal = customers.get('occupationModal')
        const companyModal = customerCompanies.get('openAddcompanyModal')
        const savingStatus = customers.get('saving')
        const customer = entities.getIn(['customers', `${id}`]) || new Map()
        const loading = customers.get('loading')
        const formErrors = customers.get('formErrors')

        const firstNameErrors = formErrors.get('first_name')
        const lastNameErrors = formErrors.get('last_name')
        const phoneNumberErrors = formErrors.get('phone_number')
        const emailErrors = formErrors.get('email')
        const lineErrors = formErrors.get('line_id')
        const lineUrlErrors = formErrors.get('line_url')

        const customerTypeId = customer.get('customer_type')
        const occupationId = customer.get('customer_occupation')
        const companiesLoading = customerCompanies.get('loading')
        const companyIds = _.get(customer.toJS(), 'customer_companies', [])
        const customerLabels = customer.get('customer_labels')
        const customerLabelIds = customerLabels ? customerLabels.toJS().map((label) => label.id) : []

        const defaultPhoneNumberQuery = new URLSearchParams(location.search).get("phone_number")

        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        }

        const formLongLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 22 }
        }

        const searchQuery = customers.get('query')

        const stringPattern = /^[a-zA-Zก-๙]+$/
        const numberPattern = /^\d+$/
        const emailPattern = /^\S+@\S+$/

        let queryType = ''

        if (stringPattern.test(searchQuery)) {
            queryType = 1
        } else if (numberPattern.test(searchQuery)) {
            queryType = 2
        } else if (emailPattern.test(searchQuery)) {
            queryType = 3
        }

        let queryString = ''
        let queryNumber = ''
        let queryEmail = ''

        switch (queryType) {
            case 1:
                queryString = searchQuery
                break
            case 2:
                queryNumber = searchQuery
                break
            case 3:
                queryEmail = searchQuery
                break
            default: queryString = searchQuery
        }

        if (loading) return <Spin />

        return (
            <CustomerFormWrapper>
                <Tabs defaultActiveKey="editCustomer">
                    <TabPane tab={i18n.t('customerForm.customerInfo')} key="editCustomer">
                        <Form onFinish={this.handleSubmitCustomer} layout="horizontal" ref={this.formRef}
                            initialValues={this.customerFormInitialValues()}>
                            <h1>{i18n.t('customerForm.addCustomerSheet')}</h1>
                            <Spin spinning={loading}>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item {...formItemLayout}
                                            label={i18n.t('customerForm.name')}
                                            name='first_name'
                                            initialValue={customer.get('first_name') || queryString}
                                            validateStatus={firstNameErrors ? 'error' : null}
                                            help={firstNameErrors && firstNameErrors.join(', ')}
                                            hasFeedback>
                                                <Input placeholder={i18n.t('customerForm.name')} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item {...formItemLayout}
                                            label={i18n.t('customerForm.surname')}
                                            name='last_name'
                                            initialValue={customer.get('last_name')}
                                            validateStatus={lastNameErrors ? 'error' : null}
                                            help={lastNameErrors && lastNameErrors.join(', ')}
                                            hasFeedback>
                                                <Input placeholder={i18n.t('customerForm.surname')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.phone')}
                                            validateStatus={phoneNumberErrors ? 'error' : null}
                                            name='phone_number'
                                            initialValue={customer.get('phone_number') || queryNumber || defaultPhoneNumberQuery}
                                            help={phoneNumberErrors && phoneNumberErrors.join(', ')}
                                            hasFeedback {...formItemLayout}>
                                                <Input placeholder={i18n.t('customerForm.phone')} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.email')}
                                            validateStatus={emailErrors ? 'error' : null}
                                            name='email'
                                            initialValue={customer.get('email') || queryEmail}
                                            help={emailErrors && emailErrors.join(', ')}
                                            hasFeedback {...formItemLayout}>
                                                <Input placeholder={i18n.t('customerForm.email')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.lineId')}
                                            name='line_id'
                                            initialValue={customer.get('line_id')}
                                            validateStatus={lineErrors ? 'error' : null}
                                            help={lineErrors && lineErrors.join(', ')}
                                            {...formItemLayout} hasFeedback>
                                                <Input placeholder={i18n.t('customerForm.lineId')} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.lineUrl')}
                                            validateStatus={lineUrlErrors ? 'error' : null}
                                            name='line_url'
                                            initialValue={customer.get('line_url')}
                                            help={lineUrlErrors && lineUrlErrors.join(', ')}
                                            {...formItemLayout} hasFeedback>
                                                <Input placeholder={i18n.t('customerForm.lineUrl')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <div onMouseDown={(e) => { e.preventDefault() }}>
                                            <Form.Item label={i18n.t('customerTypes:type')} {...formItemLayout}
                                                name='customer_type_id'
                                                initialValue={customerTypeId || undefined}>
                                                    <Select style={{ width: '100%' }}
                                                        placeholder={i18n.t('customerTypes:customerTypes')}
                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ padding: '8px', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        this.setCustomerTypesModal(customerTypesModal)
                                                                    }}>
                                                                    <PlusOutlined />
                                                                    {i18n.t('customerTypes:addCustomerTypes')}
                                                                </div>
                                                            </div>
                                                        )}>
                                                        { this.customerTypeOption() }
                                                    </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.source')} {...formItemLayout}
                                            name='lead_source'
                                            initialValue={customer.get('lead_source') || undefined}>
                                                <Select style={{ width: '100%' }} allowClear
                                                    placeholder={i18n.t('customerForm.leadSource')}>
                                                    {this.leadSourceTypeOption()}
                                                </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('customerForm.gender')} {...formItemLayout}
                                            name='gender'
                                            initialValue={customer.get('gender') || undefined}>
                                                <Select showSearch
                                                    placeholder={i18n.t('customerForm.gender')}
                                                    style={{ width: '100%' }}
                                                    allowClear>
                                                    {this.genderOption()}
                                                </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={{ span: 12 }}>
                                        <Form.Item label={i18n.t('occupations:occupation')} {...{...formItemLayout}}
                                            name='customer_occupation_id'
                                            initialValue={occupationId || undefined}>
                                            <div onMouseDown={(e) => {e.preventDefault()}}>
                                                    <Select style={{ width: '100%' }} allowClear
                                                        placeholder={i18n.t('occupations:occupation')}
                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ padding: '8px', cursor: 'pointer' }}
                                                                    onClick={() => this.setOccupationModal(occupationModal)}>
                                                                    <PlusOutlined />
                                                                    {i18n.t('occupations:addOccupation')}
                                                                </div>
                                                            </div>
                                                        )}>
                                                        {this.occupationOption()}
                                                    </Select>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 24 }}>
                                        <div onMouseDown={(e) => { e.preventDefault() }}>
                                            <Form.Item label={i18n.t('customerForm.companyName')} {...formLongLayout}
                                                name='customer_company_ids'
                                                initialValue={companyIds}>
                                                    <Select
                                                        placeholder={i18n.t('companies:searchCompany')}
                                                        mode="multiple"
                                                        showSearch
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        notFoundContent={companiesLoading ? <Spin size="small" /> : null}
                                                        onSearch={(search) => this.handleSearchCompany(search)}
                                                        onChange={this.handleSelectCompany}
                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ padding: '8px', cursor: 'pointer' }}
                                                                    onClick={() => this.setCompanyModal(companyModal)} >
                                                                    <PlusOutlined />  {i18n.t('companies:addCompany')}
                                                                </div>
                                                            </div>
                                                        )} >
                                                        {this.companyOption()}
                                                    </Select>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 24 }}>
                                        <Form.Item label={i18n.t('customerForm.detail')} {...formLongLayout}
                                            name='bio'
                                            initialValue={customer.get('bio')}>
                                                <Input.TextArea placeholder={i18n.t('customerForm.customerDetail')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 24 }}>
                                        {/* TODO: Thai label? */}
                                        <Form.Item label='Labels' {...formLongLayout}
                                            name='customer_label_ids'
                                            initialValue={customerLabelIds}>
                                            <Select
                                                style={{ width: '100%' }}
                                                mode="multiple"
                                                allowClear
                                                placeholder={i18n.t('customerForm.addLabel')}
                                                dropdownRender={menu => (
                                                    <div>
                                                        {menu}
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <div style={{ padding: '8px', cursor: 'pointer' }}
                                                            onClick={() => this.triggerCustomerLabelModal()}>
                                                            <PlusOutlined />
                                                            {i18n.t('customerForm.addLabel')}
                                                        </div>
                                                    </div>
                                                )}>
                                                {this.customerLabelOptions()}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <ContactsFormContainer
                                    form={this.formRef} />
                            </Spin>
                            <ShippingAddressFormContainer form={this.formRef}
                                paramFieldName="shipping_addresses_attributes"
                                params={id} />
                            <Button type="primary" size="large"
                                htmlType="submit" loading={savingStatus}>
                                {i18n.t('customerForm.save')}
                            </Button>
                        </Form>
                        <CustomerTypesFormContainer visible={customerTypesModal} />
                        <OccupationFormContainer visible={occupationModal} />
                        <CompanyFormContainer
                            visible={companyModal}
                            title={i18n.t('companies:addCompany')}
                            onSaveSuccess={this.onSuccessCreateCompany}
                        />
                        <CustomerLabelFormContainer
                            visible={this.state.customerLabelModal}
                            onClose={() => this.triggerCustomerLabelModal()}
                        />
                    </TabPane>
                    <TabPane tab={i18n.t('customerForm.customerRole')} key="editCustomerRole">
                        <Form onFinish={this.handleSubmitCustomer}>
                            <Form.Item label={i18n.t('customerForm.role')} {...formItemLayout}
                                name='role_ids'
                                initialValue={this.getCustomerRole(customer)}>
                                <Select style={{ width: '100%' }} allowClear
                                    placeholder={i18n.t('customerForm.customerRole')}>
                                    {this.customerRoleTypeOption()}
                                </Select>
                            </Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="primary" size="large"
                                    htmlType="submit" loading={savingStatus}>
                                    {i18n.t('customerForm.edit')}
                                </Button>
                            </div>
                        </Form>
                    </TabPane>
                </Tabs>
            </CustomerFormWrapper>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    customerCompanies: state.get('customerCompanies'),
    customerOccupations: state.get('customerOccupations'),
    customerRoles: state.get('customerRoles'),
    customerTypes: state.get('customerTypes'),
    customerLabels: state.get('customerLabels'),
    entities: state.get('Entities')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        clearCustomerInfo,
        fetchCustomer,
        fetchCustomerTypes,
        fetchOccupations,
        fetchCustomerRoles,
        fetchCustomerLabels,
        saveCustomer,
        searchCompany,
        setAddCompanyModal,
        setCompanyOption,
        setCustomerTypes,
        setOccupationModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['common', 'customerTypes', 'occupations', 'companies'])(withRouter(CustomerFormContainer))
)
