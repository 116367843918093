import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_TRACKING_FETCH_SUCCESS,
    ORDER_TRACKING_FETCH,
    ORDER_TRACKING_SAVE_FAIL,
    ORDER_TRACKING_SAVE_SUCCESS,
    ORDER_TRACKING_SAVING
} = actions

const initState = new Map({
    item: null,
    items: new List(),
    loading: false,
    saving: false
})

export default function orderTrackingsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case ORDER_TRACKING_FETCH:
            return state.merge({
                loading: action.loading
            })
        case ORDER_TRACKING_FETCH_SUCCESS:
            return state.merge({
                item: result,
                items: new List(items.includes(result) ? items : items.concat(result))
            })
        case ORDER_TRACKING_SAVE_FAIL:
            return state.merge({ saving: false })
        case ORDER_TRACKING_SAVE_SUCCESS:
            return state.merge({
                item: result,
                items: new List(items.includes(result) ? items : items.concat(result)),
                saving: false
            })
        case ORDER_TRACKING_SAVING:
            return state.merge({ saving: true })
        default:
            return state
    }
}
