import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SalePipelinesIndexPage from '../containers/SalePipelines'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const SalePipelinesPage = ({ location, match }) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

  useEffect(() => {
		if (current[0] !== 'sale_pipelines') {
			dispatch(changeCurrent(['sale_pipelines']))
		}
	}, [pathname])

	const path = match.url

  return (
    <Switch>
      <Route path={`${path}/`} component={SalePipelinesIndexPage} exact />
    </Switch>
  )
}

SalePipelinesPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

SalePipelinesPage.defaultProps = {}

export default SalePipelinesPage
