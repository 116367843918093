import styled from 'styled-components'
const OrderSummaryDetailWrapper = styled.div`
  .shipping-image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .shipping-image-container > img,
  .shipping-image-container > a > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 60px;
  }

  .text-end {
    text-align: end;
  }

  .sale-pipeline-section {
    margin-top: 4px;
    display: flex;
    justify-content: end;
    .sale-pipeline-card {
      width: 70%;
      text-align: end;
    }
  }
`
export default OrderSummaryDetailWrapper