import React, { forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import Render from './render'

const TICKET_IMAGES_QUERY = gql`
    query Ticket($id: ID!) {
        ticket(id: $id) {
            id
            images {
                id
                url
            }
        }
    }
`

const DELETE_TICKET_IMAGE_MUTATION = gql`
    mutation DeleteTicketImage($ticketId: ID!, $imageId: ID!) {
        deleteTicketImage(ticketId: $ticketId, imageId: $imageId) {
            ticket {
                id
            }
        }
    }
`

const TicketImages = forwardRef(({ ticketId }, ref) => {
    const { loading, data, error, refetch } = useQuery(TICKET_IMAGES_QUERY, { variables: { id: ticketId } })
    const [deleteTicketImage, { loading: deleting }] = useMutation(DELETE_TICKET_IMAGE_MUTATION, {
        onCompleted: () => refetch()
    })

    useImperativeHandle(ref, () => ({
        refetch () {
            refetch()
        }
    }))


    if (error) return console.error(error)

    const onRemoveImage = imageId => {
        deleteTicketImage({ variables: { ticketId, imageId } })
    }

    return (
        <Render
            imagesData={data}
            deleting={deleting}
            loading={loading}
            onRemoveImage={onRemoveImage}
            refetchImage={refetch}
            ticketId={ticketId} />
    )
})

TicketImages.propTypes = {
    ticketId: PropTypes.string.isRequired
}

TicketImages.defaultProps = {}

export default TicketImages
