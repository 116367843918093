import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { Row, Dropdown, Menu, Radio, Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import documentAction from '../../redux/orders/documents/actions'
import supplierAction from '../../redux/suppliers/actions'
import { withTranslation } from 'react-i18next'

const { setDashboardDocumentPDFLanguage } = documentAction
const { setSupplierOrdersPdfLanguage } = supplierAction


class DropdownPdfPreview extends Component {
    static propTypes = {
        icon: PropTypes.object,
        loading: PropTypes.bool,
        onClick: PropTypes.func,
        setLanguage: PropTypes.func,
        size: PropTypes.string,
        style: PropTypes.object,
        text: PropTypes.string,
        type: PropTypes.string,

        setDashboardDocumentPDFLanguage: PropTypes.func.isRequired
    }

    componentDidMount () {
        const { setDashboardDocumentPDFLanguage } = this.props

        setDashboardDocumentPDFLanguage('th')
    }

    handleChange = (e) => {
        const { setDashboardDocumentPDFLanguage } = this.props
        const { value } = e.target

        setDashboardDocumentPDFLanguage(value)
    }

    render () {
        const { i18n, icon, loading, onClick, orderDocuments, size, style, text, type } = this.props
        const menu = (
            <Menu onClick={onClick}>
                <Menu.Item key="th">
                    {i18n.t('orders:th')}
                </Menu.Item>
                <Menu.Item key="en">
                    {i18n.t('orders:en')}
                </Menu.Item>
            </Menu>
        )

        if (text) {
            return (
                <div style={style}>
                    <Dropdown.Button size={size} onClick={onClick} overlay={menu}>
                        {loading ? <LoadingOutlined /> : icon } {text}
                    </Dropdown.Button>
                </div>
            )
        } else if (type === "dashboard") {
            return (
                <Row>
                    <div style={{ float: 'right', ...style }}>
                        <Radio.Group
                            defaultValue={orderDocuments.get('dashboardDocumentPDFLanguage')}
                            onChange={this.handleChange}>
                            <Radio.Button value="th"> {i18n.t('orders:th')} </Radio.Button>
                            <Radio.Button value="en"> {i18n.t('orders:en')} </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Modal
                        style={{
                            height: '100px',
                            textAlign: 'center',
                            verticalAlign: 'middle'
                        }}
                        centered
                        width="100px"
                        visible={orderDocuments.get('dashboardDocumentPDFLoading')}
                        closable={false}
                        footer={null}
                    >
                        <Spin spinning={orderDocuments.get('dashboardDocumentPDFLoading')} />
                    </Modal>
                </Row>
            )
        }

        return null
    }
}

const mapStateToProps = state => ({
    orderDocuments: state.get('orderDocuments'),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setDashboardDocumentPDFLanguage,
        setSupplierOrdersPdfLanguage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('orders')(DropdownPdfPreview))
