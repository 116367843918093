import React from 'react'
import { Input, Select, Spin, Form, Col } from 'antd'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

const Option = Select.Option

const getDistrictNameLocale = (district, props) => {
    return district[`name_${props.i18n.language}`]
}

const handleFilterOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const handleSelectChange = (props, value) => {
    const { onSelectDistrictChange } = props

    onSelectDistrictChange(value)
}

const renderDistrictOptions = (props) => {
    const { districts } = props

    const districtOptions = districts.map((district) => {
        const districtId = district.id

        return (
            <Option key={districtId}
                value={districtId.toString()} >
                {getDistrictNameLocale(district, props)}
            </Option>
        )
    })

    return districtOptions
}

const renderPostalCodeInput = (props) => {
    const { i18n } = props

    return <Input placeholder={i18n.t('districts:postalCode')} />
}

const renderSubDistrictInput = (props) => {
    const { i18n } = props

    return <Input placeholder={i18n.t('districts:subDistrict')} />
}

const DistrictSelect = (props) => {
    const {
        i18n,
        districtCol,
        districtFormItemLayout,
        districtHelp,
        districtValidateStatus,
        districtValue,
        fieldName,
        loading,
        postalCodeCol,
        postalCodeFormItemLayout,
        postalCodeHelp,
        postalCodeValidateStatus,
        showPostalCode,
        showSubDistrict,
        subDistrictCol,
        subDistrictFormItemLayout,
        subDistrictHelp,
        subDistrictValidateStatus,
        postalCodeFieldName,
        subDistrictFieldName,
        subDistrictValue,
        postalCode
    } = props

    return (
        <>
            <Col key="district" {...districtCol}>
                <Form.Item {...districtFormItemLayout}
                    hasFeedback
                    name={fieldName}
                    initialValue={districtValue || undefined}
                    help={districtHelp}
                    label={i18n.t('districts:label')}
                    validateStatus={districtValidateStatus} >
                        <Select allowClear
                            filterOption={(input, option) => handleFilterOption(input, option)}
                            loading={loading}
                            notFoundContent={loading ? <Spin size="small" /> : null}
                            onChange={(value) => handleSelectChange(props, value)}
                            placeholder={i18n.t('districts:placeHolder')}
                            showSearch
                            style={{ width: '100%' }} >
                            {renderDistrictOptions(props)}
                        </Select>
                </Form.Item>
            </Col>

            {showSubDistrict ?
                <Col key="sub_district" {...subDistrictCol}>
                        <Form.Item {...subDistrictFormItemLayout}
                            hasFeedback
                            name={subDistrictFieldName}
                            initialValue={subDistrictValue}
                            help={subDistrictHelp}
                            label={i18n.t('districts:subDistrict')}
                            validateStatus={subDistrictValidateStatus} >
                            {renderSubDistrictInput(props)}
                        </Form.Item>
                </Col> : null }

            <Col key="postal_code" {...postalCodeCol}>
                {showPostalCode &&
                    <Form.Item {...postalCodeFormItemLayout}
                        hasFeedback
                        name={postalCodeFieldName}
                        help={postalCodeHelp}
                        initialValue={postalCode}
                        label={i18n.t('districts:postalCode')}
                        validateStatus={postalCodeValidateStatus} >
                        {renderPostalCodeInput(props)}
                    </Form.Item>
                }
            </Col>
        </>
    )
}

DistrictSelect.propTypes = {
    districtCol: PropTypes.object,
    districtFormItemLayout: PropTypes.object,
    districtHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    districts: PropTypes.array.isRequired,
    districtValidateStatus: PropTypes.string,
    districtValue: PropTypes.string,
    fieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onSelectDistrictChange: PropTypes.func.isRequired,
    postalCode: PropTypes.string,
    postalCodeCol: PropTypes.object,
    postalCodeDisabled: PropTypes.bool,
    postalCodeFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    postalCodeFormItemLayout: PropTypes.object,
    postalCodeHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    postalCodeValidateStatus: PropTypes.string,
    showPostalCode: PropTypes.bool,
    showSubDistrict: PropTypes.bool,
    subDistrictCol: PropTypes.object,
    subDistrictFieldName: PropTypes.string,
    subDistrictFormItemLayout: PropTypes.object,
    subDistrictHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    subDistrictValidateStatus: PropTypes.string,
    subDistrictValue: PropTypes.string
}

DistrictSelect.defaultProps = {
    districtCol: { md: { span: 9 } },
    districtFormItemLayout: {
        labelCol: { md: { span: 8 }, sm: { span: 4 } },
        wrapperCol: { md: { span: 16 }, sm: { span: 20 } }
    },
    districtHelp: '',
    districtValidateStatus: '',
    districtValue: undefined,
    postalCodeCol: { md: { span: 6 } },
    postalCodeDisabled: true,
    postalCodeFieldName: 'postal_code',
    postalCodeFormItemLayout: {
        labelCol: { md: { span: 12 }, sm: { span: 4 } },
        wrapperCol: { md: { span: 12 }, sm: { span: 20 } }
    },
    postalCodeHelp: '',
    postalCodeValidateStatus: '',
    showPostalCode: true,
    showSubDistrict: false,
    subDistrictCol: { md: { span: 6 } },
    subDistrictFieldName: 'sub_district',
    subDistrictFormItemLayout: {
        labelCol: { md: { span: 8 }, sm: { span: 4 } },
        wrapperCol: { md: { span: 16 }, sm: { span: 20 } }
    },
    subDistrictHelp: '',
    subDistrictValidateStatus: '',
    subDistrictValue: ''
}

export default withTranslation('districts')(DistrictSelect)
