import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import OrderSummaryIndexPage from '../containers/OrderSummaries'
import OrderSummaryStatesPage from '../containers/OrderSummaries/State'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const OrderSummariesPage = ({
	location,
	match
}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'order_summaries') {
			dispatch(changeCurrent(['order_summaries']))
		}
	}, [pathname])

	const path = match.url

	return (
		<Switch>
			<Route path={`${path}/`} component={OrderSummaryIndexPage} exact />
      <Route path={`${path}/:state/state`} component={OrderSummaryStatesPage} />
		</Switch>
	)
}

OrderSummariesPage.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

OrderSummariesPage.defaultProps = {}

export default OrderSummariesPage
