import React from 'react'
import { Alert } from 'antd'
import { withTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

const AlertForm = props => {
    const { type, message, description, i18n } = props
    // types: success, info, warning, error

    return (
        <Alert
            message={i18n.t(`alerts:${message}`)}
            description={i18n.t(`alerts:${description}`)}
            type={type}
            closable
            showIcon />
    )
}

AlertForm.propTypes = {
    message: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default withTranslation('alerts')(AlertForm)
