import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { Row, Col, Statistic, Spin, Button } from 'antd'

import WalletsListContainer from '../../containers/WalletSummaries/WalletsListContainer'

const CustomerWalletsIndex = ({
    i18n,
    customer,
    customerLoading,
    fetchCustomerWallets,
    loading,
    onChangeApprove,
    onDelete,
    pagination,
    setCustomerWalletFormModal,
    walletIds
}) => {
    const renderCustomer = () => {
        return (
            <Spin spinning={customerLoading}>
                <Row gutter={16}>
                    <Col span={10}>
                        <Statistic
                            title={i18n.t('customers/wallets:customer')}
                            value={`${_.get(customer, 'first_name', '')} ${_.get(customer, 'last_name', '')}`}
                        />
                    </Col>
                    <Col span={10}>
                        <Statistic
                            title={i18n.t('customers/wallets:sumOfWallets')}
                            value={_.get(customer, 'sum_of_wallets.display', '0.00')} />
                    </Col>
                    <Col span={4} style={{ textAlign: 'end' }}>
                        <Button type="primary" onClick={() => setCustomerWalletFormModal(true, null)}>
                            {i18n.t('customers/wallets:addWallet')}
                        </Button>
                    </Col>
                </Row>
            </Spin>
        )
    }

    return (
        <div>
            {renderCustomer()}
            <WalletsListContainer
                hiddenColumns={['customer']}
                loading={loading}
                onChangeApprove={onChangeApprove}
                onDelete={onDelete}
                pagination={pagination}
                searchWallets={fetchCustomerWallets}
                setCustomerWalletFormModal={setCustomerWalletFormModal}
                walletIds={walletIds}
            />
        </div>
    )
}

CustomerWalletsIndex.propTypes = {
    customer: PropTypes.object,
    customerLoading: PropTypes.bool,
    fetchCustomerWallets: PropTypes.func,
    loading: PropTypes.bool,
    onChangeApprove: PropTypes.func,
    onDelete: PropTypes.func,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    setCustomerWalletFormModal: PropTypes.func,
    walletIds: ImmutablePropTypes.list
}

CustomerWalletsIndex.defaultProps = {
    customer: {}
}

export default withTranslation('customers/wallets')(CustomerWalletsIndex)
