const actions = {
    PROJECT_CONTACTS_FETCH_REQUEST: 'PROJECT_CONTACTS_FETCH_REQUEST',
    PROJECT_CONTACTS_FETCHING: 'PROJECT_CONTACTS_FETCHING',
    PROJECT_CONTACTS_FETCH_SUCCESS: 'PROJECT_CONTACTS_FETCH_SUCCESS',
    PROJECT_CONTACTS_SET_PAGINATION: 'PROJECT_CONTACTS_SET_PAGINATION',
    PROJECT_CONTACT_SAVE_REQUEST: 'PROJECT_CONTACT_SAVE_REQUEST',
    PROJECT_CONTACT_SAVING: 'PROJECT_CONTACT_SAVING',
    PROJECT_CONTACT_SET_FORM_ERRORS: 'PROJECT_CONTACT_SET_FORM_ERRORS',
    PROJECT_CONTACT_SAVE_SUCCESS: 'PROJECT_CONTACT_SAVE_SUCCESS',
    PROJECT_CONTACT_FETCH_REQUEST: 'PROJECT_CONTACT_FETCH_REQUEST',
    PROJECT_CONTACT_FETCHING: 'PROJECT_CONTACT_FETCHING',
    PROJECT_CONTACT_FETCH_SUCCESS: 'PROJECT_CONTACT_FETCH_SUCCESS',
    PROJECT_CONTACT_SET_FORM_MODAL: 'PROJECT_CONTACT_SET_FORM_MODAL',
    PROJECT_CONTACT_DELETE_REQUEST: 'PROJECT_CONTACT_DELETE_REQUEST',
    PROJECT_CONTACT_DELETE: 'PROJECT_CONTACT_DELETE',
    PROJECT_CONTACT_DELETE_SUCCESS: 'PROJECT_CONTACT_DELETE_SUCCESS',

    fetchProjectContacts: ({ projectId, id = null, page = 1, per = 20, all = false } = {}) => ({
        type: actions.PROJECT_CONTACTS_FETCH_REQUEST,
        payload: { projectId, id, page, per, all }
    }),

    saveProjectContact: ({
        projectId,
        id,
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.PROJECT_CONTACT_SAVE_REQUEST,
        payload: { projectId, id, params, onSuccess, onError }
    }),

    setProjectContactFormModal: (visible, id) => ({
        type: actions.PROJECT_CONTACT_SET_FORM_MODAL,
        visible, id
    }),

    fetchProjectContact: ({ projectId, id = null }) => ({
        type: actions.PROJECT_CONTACT_FETCH_REQUEST,
        payload: { projectId, id }
    }),

    deleteProjectContact: ({ projectId, id, onSuccess = () => {} } = {}) => ({
        type: actions.PROJECT_CONTACT_DELETE_REQUEST,
        payload: { projectId, id, onSuccess }
    })
}


export default actions
