import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { isEmpty } from '../../helpers/objects'
import actions from '../../redux/orders/businessAddresses/actions'
import accountingActions from '../../redux/orders/accounting/actions'
import notificationActions from '../../redux/notifications/actions'

import TaxInvoices from '../../components/taxInvoices'

const {
    fetchOrderBusinessAddresses,
    setPagination,
    setSorter,
    setOrderBusinessAddressModal,
    setShowMailingAddressForm,
    setOrderBusinessAddressItemsModal,
    setOrderBusinessAddressActive,
    exportTaxInvoiceReportFile,
    setOrderMailingAddressActive,
    fetchCustomerOrderBusinessAddresses,
    sendTaxInvoicesMailByFilter
} = actions
const { saveOrderBusinessAddress, fetchTaxInvoicePdf, sendETax } = accountingActions
const { setNotifications } = notificationActions

class TaxInvoicesIndexContainer extends Component {
    static propTypes = {
        // Inner Props
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        fetchCustomerOrderBusinessAddresses: PropTypes.func.isRequired,
        orderBusinessAddresses: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        showFilterSection: true,
        showPaginate: true
    }

    componentDidMount () {
        const { fetchOrderBusinessAddresses, fetchCustomerOrderBusinessAddresses, location, history, customerId } = this.props

        const queryStringObject = this.parseParams(location.search)

        history.push({
          search: location.search,
          params: queryStringObject
        })

        const queryString = {}

        _.mapKeys(queryStringObject, (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        if (customerId) {
            fetchCustomerOrderBusinessAddresses({ customerId: customerId })
        } else {
            fetchOrderBusinessAddresses({ ...queryString })
        }
    }

    parseParams = (params = '') => {
      if(!params) return {}
      const rawParams = params.replace('?', '').split('&')
      const extractedParams = {}
      rawParams.forEach((item) => {
          item = item.split('=');
          extractedParams[item[0]] = item[1]
      })
      return extractedParams
    }

    fetchItems () {
        const { fetchOrderBusinessAddresses, location } = this.props

        const queryString = {}

        _.mapKeys(_.get(location, 'params', {}), (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        fetchOrderBusinessAddresses({ ...queryString })
    }

    getOrderTaxInvoices = () => {
        const { orderBusinessAddressEntities, orderBusinessAddresses } = this.props
        const items = orderBusinessAddresses.get('items')
        return items.map((id) => {
            return orderBusinessAddressEntities.get(id)
        })
    }

    handleEditOrderBusinessAddress = (id, orderNumber) => {
        const { setOrderBusinessAddressModal, orderBusinessAddresses, setOrderBusinessAddressActive } = this.props
        const visible = orderBusinessAddresses.get('orderBusinessAddressFormModal')

        setOrderBusinessAddressActive(id, orderNumber)
        setOrderBusinessAddressModal(!visible)
    }

    handleEditOrderMailingAddress = (id, orderNumber) => {
        const { setShowMailingAddressForm, orderBusinessAddresses, setOrderMailingAddressActive } = this.props
        const visible = orderBusinessAddresses.get('orderMailingAddressFormModal')

        setOrderMailingAddressActive(id, orderNumber)
        setShowMailingAddressForm(!visible)
    }

    handleEditOrderBusinessAddressItems = (id, orderNumber) => {
        const { setOrderBusinessAddressItemsModal, orderBusinessAddresses, setOrderBusinessAddressActive } = this.props
        const visible = orderBusinessAddresses.get('orderBusinessAddressItemsFormModal')

        setOrderBusinessAddressActive(id, orderNumber)
        setOrderBusinessAddressItemsModal(!visible)
    }

    handleSendTaxInvoiceEmails = () => {
        const { sendTaxInvoicesMailByFilter, setNotifications, location, i18n } = this.props
        const queryString = {}

        _.mapKeys(_.get(location, 'params', {}), (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        sendTaxInvoicesMailByFilter({
            ...queryString,
            onSuccess: () => {
                setNotifications(
                    i18n.t('confirms:invoice.success'),
                    i18n.t('confirms:invoice.sendTaxInvoiceToCustomers'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    handleSaveOrderBusinessAddress = ({ id, orderId, address }) => {
        const { saveOrderBusinessAddress } = this.props

        saveOrderBusinessAddress({ address, id, orderId })
    }

    getOrderQueryObject (sorter) {
        if (isEmpty(sorter)) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: `${sorter.columnKey} ${sortDirection}`
        }
    }

    setQueryStringUrl ({
        invStartDate,
        invEndDate,
        sendingState,
        haveTrackingNumber
    } = {}) {
        const { history } = this.props

        const newRouterQuery = {
            inv_start_date: _.toString(invStartDate),
            inv_end_date: _.toString(invEndDate),
            sending_state: _.toString(sendingState),
            have_tracking_number: haveTrackingNumber
        }
        const newParams = _.pickBy(newRouterQuery, (value) => value)

        const queryString = Object.keys(newParams).map(key => key + '=' + newParams[key]).join('&')

        history.push({
            search: `?${queryString}`,
            params: newParams
        })
    }

    fetchInvoices = ({ currentPage, sorter, invStartDate, invEndDate, sendingState, haveTrackingNumber } = {}) => {
        const { fetchOrderBusinessAddresses, orderBusinessAddresses } = this.props

        invStartDate = (invStartDate === undefined) ? orderBusinessAddresses.get('invStartDate') : invStartDate
        invEndDate = (invEndDate === undefined) ? orderBusinessAddresses.get('invEndDate') : invEndDate
        sendingState = (sendingState === undefined) ? orderBusinessAddresses.get('sendingState') : sendingState
        haveTrackingNumber = (haveTrackingNumber === undefined) ?
            orderBusinessAddresses.get('haveTrackingNumber') : haveTrackingNumber

        fetchOrderBusinessAddresses({
            page: currentPage,
            sorter: sorter,
            invStartDate,
            invEndDate,
            sendingState,
            haveTrackingNumber
        })

        this.setQueryStringUrl({
            invStartDate,
            invEndDate,
            sendingState,
            haveTrackingNumber
        })
    }

    handleInvoiceDateChange = (values) => {
        this.fetchInvoices({
            invStartDate: values.length ? values[0].format('YYYY-MM-DD') : '',
            invEndDate: values.length ? values[1].format('YYYY-MM-DD') : ''
        })
    }

    handleSendingStateChange = (value) => {
        this.fetchInvoices({ sendingState: value || '' })
    }

    handleSelectTruckingNumber = (value) => {
        this.fetchInvoices({ haveTrackingNumber: value || '' })
    }

    handleTableChange = (pagination, filter, sorter) => {
        const { setPagination, setSorter, customerId, fetchCustomerOrderBusinessAddresses } = this.props

        const sorterQuery = this.getOrderQueryObject(sorter)

        setSorter(sorter)
        setPagination(pagination)


        if (customerId) {
            fetchCustomerOrderBusinessAddresses({ customerId: customerId, page: pagination.current })
        } else {
            this.fetchInvoices({
                currentPage: pagination.current,
                sorter: sorterQuery
            })
        }
    }

    handleDownloadInvoice = (orderId, id, taxInvoiceType, locale) => {
        const { fetchTaxInvoicePdf } = this.props

        fetchTaxInvoicePdf(orderId, id, taxInvoiceType, locale.key)
    }

    handleExportTaxInvoiceReport = () => {
        const { exportTaxInvoiceReportFile, location } = this.props
        const queryString = {}

        _.mapKeys(_.get(location, 'params', {}), (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        exportTaxInvoiceReportFile({ ...queryString })
    }

    handleSendETax = (id, taxInvoiceType, orderId) => {
        const { sendETax } = this.props
        sendETax({
            orderId,
            id,
            taxInvoiceType
        })
    }

    onSaveInvoiceItemsSuccess = () => {
        const { orderBusinessAddresses } = this.props
        this.fetchInvoices({ currentPage: orderBusinessAddresses.getIn(['pagination', 'current']) })
        this.handleEditOrderBusinessAddressItems(null, null)
    }

    render () {
        const { orderBusinessAddresses, accountings, location, showFilterSection, showPaginate } = this.props

        const invStartDate = new URLSearchParams(location.search).get('inv_start_date')
        const invEndDate = new URLSearchParams(location.search).get('inv_end_date')

        const defaultInvoiceDate = invStartDate ? ([moment(invStartDate), moment(invEndDate)]) : null

        return (
            <TaxInvoices
                defaultHaveTrackingNumber={new URLSearchParams(location.search).get('have_tracking_number') || undefined}
                defaultInvoiceDate={defaultInvoiceDate}
                defaultSendingState={new URLSearchParams(location.search).get('sending_state') || undefined}
                invoiceReportLoading={orderBusinessAddresses.get('invoiceReportLoading')}
                loading={orderBusinessAddresses.get('loading')}
                mailsSending={orderBusinessAddresses.get('mailsSending')}
                onDownloadInvoice={this.handleDownloadInvoice}
                onEditOrderBusinessAddress={this.handleEditOrderBusinessAddress}
                onEditOrderBusinessAddressItems={this.handleEditOrderBusinessAddressItems}
                onEditOrderMailingAddress={this.handleEditOrderMailingAddress}
                onExportTaxInvoiceReport={this.handleExportTaxInvoiceReport}
                onInvoiceDateChange={this.handleInvoiceDateChange}
                onSaveInvoiceItemsSuccess={this.onSaveInvoiceItemsSuccess}
                onSaveOrderBusinessAddress={this.handleSaveOrderBusinessAddress}
                onSelectTruckingNumber={this.handleSelectTruckingNumber}
                onSendingStateChange={this.handleSendingStateChange}
                onSendTaxInvoiceEmails={this.handleSendTaxInvoiceEmails}
                onTableChange={this.handleTableChange}
                orderBusinessAddressIdActive={orderBusinessAddresses.get('orderBusinessAddressIdActive')}
                orderMailingAddressIdActive={orderBusinessAddresses.get('orderMailingAddressIdActive')}
                orderNumberActive={orderBusinessAddresses.get('orderNumberActive')}
                pagination={{
                    ...orderBusinessAddresses.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                queryString={location.params}
                showFilterSection={showFilterSection}
                showInvoiceItemsFormModal={orderBusinessAddresses.get('orderBusinessAddressItemsFormModal')}
                showInvoicesFormModal={orderBusinessAddresses.get('orderBusinessAddressFormModal')}
                showMailingAddressFormModal={orderBusinessAddresses.get('orderMailingAddressFormModal')}
                showPaginate={showPaginate}
                taxInvoiceLoading={accountings.get('invoiceLoading')}
                taxInvoices={this.getOrderTaxInvoices().toJS()}
                handleSendETax={this.handleSendETax}
                sendETaxSaving={accountings.get('sendETaxSaving')}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        accountings: state.get('orderPaymentDetails'),
        orderBusinessAddresses: state.get('orderBusinessAddresses'),
        orderBusinessAddressEntities: state.getIn(['Entities', 'orderBusinessAddresses'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderBusinessAddresses,
        setPagination,
        setSorter,
        setOrderBusinessAddressModal,
        setShowMailingAddressForm,
        setOrderBusinessAddressItemsModal,
        setOrderBusinessAddressActive,
        saveOrderBusinessAddress,
        fetchTaxInvoicePdf,
        exportTaxInvoiceReportFile,
        setOrderMailingAddressActive,
        fetchCustomerOrderBusinessAddresses,
        setNotifications,
        sendTaxInvoicesMailByFilter,
        sendETax
    }, dispatch)
}

export default withTranslation(['confirms'])(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxInvoicesIndexContainer))
)
