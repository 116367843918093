import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Table } from 'antd'
import SelectProvinceContainer from '../../containers/SelectProvince'
import SelectDistrictContainer from '../../containers/SelectDistrict'
import ShippingRateWrapper from './shippingRates.style'

const ShippingRatesIndex = ({
    districtId,
    i18n,
    loading,
    onChangeDistrict,
    onChangeProvince,
    onPageChange,
    pagination,
    provinceId,
    shippingRates
}) => {
    const columns = [
        {
            key: 'province',
            title: i18n.t('shippingRates:province'),
            dataIndex: ['province', `name_${i18n.language}`]
        },
        {
            key: 'district',
            title: i18n.t('shippingRates:district'),
            dataIndex: ['district', `name_${i18n.language}`]
        },
        {
            key: 'vehicle_type',
            title: i18n.t('shippingRates:vehicleType'),
            dataIndex: ['vehicle_type', `name_${i18n.language}`]
        },
        {
            key: 'price',
            title: i18n.t('shippingRates:price'),
            dataIndex: 'display_price'
        }
    ]

    return (
        <ShippingRateWrapper>
            <div className="shipping-rates-header margin-bottom-12">
                <div className="shipping-rates-filters">
                    <div className="shipping-rates-filter-location margin-right-12">
                        <div className="label">{i18n.t('addresses:province')}</div>
                        <SelectProvinceContainer
                            value={provinceId}
                            onChange={onChangeProvince}
                        />
                    </div>

                    <div className="shipping-rates-filter-location">
                        <div className="label">{i18n.t('addresses:district')}</div>
                        <SelectDistrictContainer
                            value={districtId}
                            provinceId={provinceId}
                            onChange={onChangeDistrict}
                        />
                    </div>
                </div>
            </div>

            <Table
                columns={columns}
                dataSource={shippingRates}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
        </ShippingRateWrapper>
    )
}

ShippingRatesIndex.propTypes = {
    districtId: PropTypes.string,
    loading: PropTypes.bool,
    onChangeDistrict: PropTypes.func.isRequired,
    onChangeProvince: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    provinceId: PropTypes.string,
    shippingRates: PropTypes.array
}

ShippingRatesIndex.defaultProps = {
    loading: false,
    pagination: false,
    shippingRates: []
}

export default withTranslation(['shippingRates', 'addresses'])(ShippingRatesIndex)
