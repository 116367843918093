import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { DatePicker, Form, Modal, Checkbox, Row, Input, Button, Table, Tag } from 'antd'
import { PlusSquareOutlined, EditOutlined } from '@ant-design/icons'
import Image from '../Image'
import ImagePreviewContainer from '../ImagePreview/ImagePreviewContainer'
import VatDocumentsFilter from './VatDocumentsFilter'
import SupplierInvoicesFormContainer from './SupplierInvoicesFormContainer'

import vatDocumentActions from '../../redux/vatDocuments/actions'
import supplierActions from '../../redux/suppliers/actions'

import ImagesWrapper from '../../components/images/imagesWrapper.style'

const { fetchSuppliers } = supplierActions
const {
    fetchPaymentVouchers,
    savePaymentVoucher,
    saveSupplierInvoice,
    setInvoiceForm,
    setPaymentNoteForm,
    setSupplierOrderRefCode
} = vatDocumentActions

class VatDocumentsIndexContainer extends Component {
    async componentDidMount () {
        const { fetchSuppliers, fetchPaymentVouchers, history, location } = this.props
        await fetchSuppliers()

        const queryStringObject = this.parseParams(location.search)

        history.push({
            search: location.search,
            params: queryStringObject
        })

        await fetchPaymentVouchers(queryStringObject)
    }

    parseParams = (params = '') => {
        if(!params) return {}
        const rawParams = params.replace('?', '').split('&')
        const extractedParams = {}
        let supplierIds = []
        rawParams.forEach((item) => {
            item = item.split('=')
            if(item[0] === 'supplier_ids') {
                supplierIds.push(item[1])
                extractedParams[item[0]] = supplierIds
            } else {
                extractedParams[item[0]] = item[1]
            }
        })
        return extractedParams;
    }

    fetchPaymentVouchers = (queryString, sorter) => {
        const { history, fetchPaymentVouchers } = this.props
        let arrQueryString = queryString.split('&')
        let objQuery = { supplier_ids: [] }
        let queryShow = []
        const acceptQuery = [
            'start_date',
            'end_date',
            'apply_date',
            'upload_invoice',
            'is_apply_date',
            'date_type',
            'query',
            'page',
            'amount_min',
            'amount_max'
        ]

        arrQueryString.forEach((str) => {
            const arrString = str.split('=')
            if (acceptQuery.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, { [`${arrString[0]}`]: arrString[1] })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            } else if (arrString[0] === 'supplier_ids[]' || arrString[0] === 'supplier_ids') {
                objQuery = Object.assign(objQuery, {
                    supplier_ids: objQuery.supplier_ids.concat(arrString[1])
                })
                queryShow = queryShow.concat(`supplier_ids=${arrString[1]}`)
            }
        })

        const queries = queryShow.length ? '?' + queryShow.join('&') : ''

        history.push({
            search: queries,
            params: objQuery
        })

        fetchPaymentVouchers(objQuery, sorter)
    }

    getPaymentVoucher = () => {
        const { paymentVoucherEntities, vatDocuments } = this.props
        const items = vatDocuments.get('items')

        return items.map((id) => paymentVoucherEntities.get(id))
    }

    handleInvoiceForm = (paymentVoucherId, supplierInvoiceId) => {
        this.props.setInvoiceForm(true)
        this.paymentVoucherId = paymentVoucherId
        this.supplierInvoiceId = supplierInvoiceId
    }

    handleEditNoteForm = (paymentVoucherId) => {
        this.props.setPaymentNoteForm(true)
        this.editPaymentNoteId = paymentVoucherId
    }

    handleNoteSubmit = (values) => {
        const { savePaymentVoucher } = this.props

        savePaymentVoucher(values, this.editPaymentNoteId)
    }

    paymentVoucherNoteForm = () => {
        const { vatDocuments, setPaymentNoteForm, paymentVoucherEntities } = this.props
        const paymentVoucher = paymentVoucherEntities.get(this.editPaymentNoteId) || new Map()

        return (
            <Modal destroyOnClose
                footer={null}
                onCancel={() => setPaymentNoteForm(false)}
                visible={vatDocuments.get('isOpenNoteForm')} >
                <Form onFinish={this.handleNoteSubmit}>
                    <Form.Item label="note" name='note'
                        initialValue={paymentVoucher.get('note') || null}>
                            <Input.TextArea />
                    </Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </div>
                </Form>
            </Modal>
        )
    }

    handleCheck = (e, id) => {
        const { savePaymentVoucher } = this.props
        const params = { is_upload_invoice: e.target.checked }

        savePaymentVoucher(params, id)
    }

    handleChangeIssueDate = (date, dateString, id) => {
        const { saveSupplierInvoice, match } = this.props
        const params = { issue_date: date && date.format('YYYY/MM/DD') }

        saveSupplierInvoice(params, match.params, id)
    }

    handleChangeApplyDate = (date, dateString, id) => {
        const { saveSupplierInvoice, match } = this.props
        const params = { apply_date: date && date.format('YYYY/MM/DD') }

        saveSupplierInvoice(params, match.params, id)
    }

    handleChangeVatMonthUse = (date, dateString, id) => {
        const { savePaymentVoucher } = this.props
        const params = { vat_month_use: date && date.format('YYYY/MM/DD') }

        savePaymentVoucher(params, id)
    }

    getSupplierName = (supplierOrder) => {
        const { supplierOrderEntities } = this.props
        const supplierOrderId = supplierOrder[0]
        return (
            <div>
                {supplierOrderEntities.getIn([supplierOrderId, 'supplier_name'])}
            </div>
        )
    }

    renderInvoiceImage = (images) => {
        if (images.length === 0) return null

        return images.map((image) => {
            const imageId = image.id
            const thumbUrl = image.file.s || ''
            const originalUrl = image.file.original || ''
            const contentType = image.file.content_type || ''

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    getSupplierInvoices = (supplierInvoices) => {
        const { supplierInvoiceEntities } = this.props

        return supplierInvoices.map((id) => {
            return supplierInvoiceEntities.get(_.toString(id), new Map())
        })
    }

    renderPVNumber = (paymentVoucher) => {
        if (paymentVoucher.payment_type === 'product') {
            return (
                <div>
                    {paymentVoucher.number ? 'pv no: ' + paymentVoucher.number : null }
                </div>
            )
        }
    }

    getColumns = () => {
        const { supplierEntities, i18n } = this.props

        const columns = [
            {
                title: i18n.t('vatDocuments:date'),
                dataIndex: 'date_of_purchase',
                render: (dataIndex) => {
                    if (!dataIndex) { return }
                    return moment(dataIndex, 'YYYY-MM-DD').format('YYYY-MM-DD')
                }
            },
            {
                title: i18n.t('vatDocuments:paymentVoucherNo'),
                render: (paymentVoucher) => {
                    return (
                        <div>
                            <div>
                                <a href={`/inquiries/${paymentVoucher.order_number}`}> {paymentVoucher.order_number}</a>
                            </div>
                            {this.renderPVNumber(paymentVoucher)}
                        </div>
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:supplierName'),
                dataIndex: 'supplier',
                render: (dataIndex) => {
                    if (!dataIndex) return null
                    const supplier = supplierEntities.get(dataIndex)
                    const supplierName = supplier.get('name')
                    return <Link to={`/suppliers/${dataIndex}`}>{!supplierName ? null : supplierName}</Link>
                }
            },
            {
                title: i18n.t('vatDocuments:bankStatementId'),
                dataIndex: 'bank_statement_id',
                render: (dataIndex) => {
                    if (!dataIndex) return null
                    return (
                        <div>
                            <div>id: {dataIndex}</div>
                            <Tag color='green'>{i18n.t('vatDocuments:reconciled')}</Tag>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:invoiceNumber'),
                render: (paymentVoucher) => {
                    return (
                        <div>
                            <Row>
                                <Checkbox defaultChecked={paymentVoucher.is_upload_invoice}
                                    onChange={(e) => this.handleCheck(e, paymentVoucher.id)}>
                                    {i18n.t('vatDocuments:success')}
                                </Checkbox>
                                <a onClick={() => this.handleInvoiceForm(paymentVoucher.id)}>
                                    <PlusSquareOutlined />
                                </a>
                            </Row>
                            {this.getSupplierInvoices(paymentVoucher.supplier_invoices).map((supplierInvoice, index) => {
                                return (
                                    <div key={supplierInvoice.get('id', `key-${index}`)}>
                                        <a onClick={() => this.handleInvoiceForm(paymentVoucher.id, supplierInvoice.get('id'))}>
                                            {supplierInvoice.get('invoice_ref_code')}
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }
            }, {
                title: i18n.t('vatDocuments:pvAmount'),
                key: 'amount_satangs',
                sorter: true,
                dataIndex: ['amount', 'display']
            }, {
                title: i18n.t('vatDocuments:usedNotUsed'),
                dataIndex: 'vat_month_use',
                width: 170,
                render: (dataIndex, record) => {
                    return (
                        <DatePicker.MonthPicker
                            format={'MMMM YYYY'}
                            placeholder="Select month"
                            onChange={(date, dateString) => this.handleChangeVatMonthUse(date, dateString, record.id)}
                            value={dataIndex && moment(dataIndex)} />
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:images'),
                render: (paymentVoucher) => {
                    const images = _.get(paymentVoucher, 'supplier_invoice_images', [])
                    const supplierInvoices = this.getSupplierInvoices(_.get(paymentVoucher, 'supplier_invoices', []))
                    const issueDates = _.compact(supplierInvoices.map((supplierInvoice) => {
                        return supplierInvoice.get('issue_date', '')
                    })).map((issueDate) => {
                        return moment(issueDate).format('l')
                    }).join(', ')

                    return (
                        <ImagesWrapper>
                            <div key="issue-date">
                                {i18n.t('vatDocuments:issueDate')}: {issueDates}
                            </div>

                            <div className="image-container" key="image">
                                {this.renderInvoiceImage(images)}
                            </div>
                        </ImagesWrapper>
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:note'),
                render: (paymentVoucher) => {
                    return (
                        <div>
                            {paymentVoucher.note}
                            <a onClick={() => this.handleEditNoteForm(paymentVoucher.id)}>
                                <EditOutlined />
                            </a>
                        </div>
                    )
                }
            }
        ]

        return columns
    }

    getExpandedRowRender = (row) => {
        const { supplierInvoiceEntities, i18n } = this.props
        const columns = [
            {
                title: i18n.t('vatDocuments:invoiceNumber'),
                dataIndex: 'invoice_ref_code'
            },
            {
                title: i18n.t('vatDocuments:amount'),
                dataIndex: 'amount'
            },
            {
                title: i18n.t('vatDocuments:branch'),
                dataIndex: 'branch'
            },
            {
                title: i18n.t('vatDocuments:images'),
                dataIndex: 'supplier_invoice_images',
                render: (dataIndex) => (
                    <ImagesWrapper>
                        <div className="image-container">
                            {this.renderInvoiceImage(dataIndex)}
                        </div>
                    </ImagesWrapper>
                )
            },
            {
                title: i18n.t('vatDocuments:issueDate'),
                dataIndex: 'issue_date',
                render: (dataIndex, record) => {
                    return (
                        <DatePicker
                            format={'Do MMMM YYYY'}
                            placeholder="Select date"
                            onChange={(date, dateString) => this.handleChangeIssueDate(date, dateString, record.id)}
                            value={dataIndex && moment(dataIndex)} />
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:usedNotUsed'),
                dataIndex: 'apply_date',
                render: (dataIndex, record) => {
                    return (
                        <DatePicker.MonthPicker
                            format={'MMMM YYYY'}
                            placeholder="Select month"
                            onChange={(date, dateString) => this.handleChangeApplyDate(date, dateString, record.id)}
                            value={dataIndex && moment(dataIndex)} />
                    )
                }
            },
            {
                title: i18n.t('vatDocuments:note'),
                dataIndex: 'note'
            },
            {
                title: i18n.t('vatDocuments:action'),
                render: (record) => {
                    return (
                        <a onClick={() => this.handleInvoiceForm(row.id, record.id)}>
                            <EditOutlined />
                        </a>
                    )
                }
            }
        ]
        const invoiceIds = row.supplier_invoices
        const data = invoiceIds.map((id) => {
            return supplierInvoiceEntities.get(id).toJS()
        })

        return (
            <Table
                columns={columns}
                scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                dataSource={data}
                rowKey={data => data.id}
                pagination={false} />
        )
    }

    onCloseSupplierInvoicesForm = () => {
        const { setSupplierOrderRefCode, setInvoiceForm } = this.props
        setInvoiceForm(false)
        setSupplierOrderRefCode([])
    }

    render () {
        const { vatDocuments, i18n, form, location } = this.props
        moment.locale(i18n.language)

        return (
            <div className="vat-documents-index-container" key="vat-documents-index-container">
                <VatDocumentsFilter
                    columns={this.getColumns()}
                    dataSource={this.getPaymentVoucher().toJS()}
                    expandedRowRender={row => this.getExpandedRowRender(row)}
                    fetchItems={this.fetchPaymentVouchers} />
                <Modal destroyOnClose
                    footer={null}
                    onCancel={this.onCloseSupplierInvoicesForm}
                    visible={vatDocuments.get('isOpenInvoiceForm')} >
                    <SupplierInvoicesFormContainer form={form}
                        paymentVoucherId={this.paymentVoucherId}
                        supplierInvoiceId={this.supplierInvoiceId}
                        queryString={location.params} />
                </Modal>
                {this.paymentVoucherNoteForm()}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
    vatDocuments: state.get('vatDocuments'),
    supplierInvoiceEntities: state.getIn(['Entities', 'supplierInvoices']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierEntities: state.getIn(['Entities', 'suppliers'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPaymentVouchers,
        fetchSuppliers,
        savePaymentVoucher,
        saveSupplierInvoice,
        setInvoiceForm,
        setPaymentNoteForm,
        setSupplierOrderRefCode
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ImagePreviewContainer(withTranslation('vatDocuments')(withRouter(VatDocumentsIndexContainer)))
)
