import React from 'react'
import PropTypes from 'prop-types'

import { Select } from 'antd'
import { withTranslation } from 'react-i18next'

const { Option } = Select

const SelectCustomer = ({
    i18n,
    customers,
    onChange,
    onSearch,
    value
}) => {
    const renderCustomerOptions = () => {
        return customers.map((customer) => {
            const fullName = `${customer.first_name} ${customer.last_name ? customer.last_name : ''}`.trim()
            return <Option key={`customer-${customer.customer_id}`} value={customer.customer_id}>{fullName}</Option>
        })
    }

    return (
        <Select
            filterOption={false}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('customerForm.selectCustomer')}
            showSearch
            value={value}
        >
            {renderCustomerOptions()}
        </Select>
    )
}

SelectCustomer.propTypes = {
    customers: PropTypes.array,
    onChange: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectCustomer.defaultProps = {
    customers: [],
    onChange: () => {}
}

export default withTranslation('common')(SelectCustomer)
