import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import Render from './render'

const UPDATE_CUSTOMER_MUTATION = gql`
    mutation CreateCustomerType($customerTypeParams: AdminCustomerTypeInput!) {
        createCustomerType(customerTypeParams: $customerTypeParams) {
            errors
        }
    }
`

const CustomerTypeForm = ({ onSuccess }) => {
    const [formErrors, setFormErrors] = useState({})
    const [createCustomerType, { loading: saving }] = useMutation(UPDATE_CUSTOMER_MUTATION, {
        onCompleted: ({ createCustomerType }) => {
            if (createCustomerType.errors) {
                setFormErrors(JSON.parse(createCustomerType.errors))
            } else {
                onSuccess()
            }
        }
    })

    const onSaveCustomerType = values => {
        createCustomerType({ variables: { customerTypeParams: values } })
    }

    return (
        <Render
            formErrors={formErrors}
            onSaveCustomerType={onSaveCustomerType}
            saving={saving}
        />
    )
}

CustomerTypeForm.propTypes = {
    onSuccess: PropTypes.func
}

CustomerTypeForm.defaultProps = {
    onSuccess: () => {}
}

export default CustomerTypeForm
