import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery, useMutation } from '@apollo/client'

import { Spin } from 'antd'

import TruckReservationDelivereeFormRender from './render'

const SHIPPING_COST_QUERY = gql`
    query ShippingCost($id: ID!){
        shippingCost(id: $id) {
            id
            order {
              id
              customer {
                fullName
                phoneNumber
              }
              orderShippingAddress {
                contactName
                phoneNumber
              }
            }
            sendDeliveree {
              id
              vehicleTypeId
              timeType
              pickupLocation
              shippingAddresses {
                id
                address
              }
            }
        }
    }
`

const CREATE_DELIVERY__MUTATION = gql`
    mutation CreateDelivery($shippingCostId: ID!, $deliveryParams: AdminDeliveryInput!){
        createDelivery(shippingCostId: $shippingCostId, deliveryParams: $deliveryParams) {
          data
          errors {
            message
            key
          }
        }
    }
`

const TruckReservationDelivereeForm = ({
  shippingCostId,
  onSuccess
}) => {
  const [errorsMessage, setErrors] = useState(null)

  const { loading, data } = useQuery(SHIPPING_COST_QUERY, { variables: { id: shippingCostId } })

  const [createDelivery, { loading: saving }] = useMutation(CREATE_DELIVERY__MUTATION, {
    onCompleted: ({ createDelivery }) => {
      if (createDelivery.errors) {
        setErrors(createDelivery.errors.message)
      } else {
        setErrors(null)
        onSuccess()
      }
    }
  })

  const onSubmit = (params) => {
    createDelivery({
      variables: {
        shippingCostId,
        deliveryParams: params
      }
    })
  }

  if (loading) return <Spin />

  const shippingCost = (data && data.shippingCost) || {}

  const getShippingAddresses = (shippingAddresses) => {
    if (shippingAddresses.length < 1) return []
    return shippingAddresses.map((shippingAddress) => {
      return {
        address: shippingAddress.address
      }
    })
  }

  const getInitialValues = () => {
    const { sendDeliveree } = shippingCost
    return {
      vehicleTypeId: sendDeliveree.vehicleTypeId,
      timeType: sendDeliveree.timeType,
      pickupLocation: { address: sendDeliveree.pickupLocation },
      locations: getShippingAddresses(sendDeliveree.shippingAddresses)
    }
  }

  const { order } = shippingCost

  return (
    <TruckReservationDelivereeFormRender initialValues={getInitialValues()}
      onSubmit={onSubmit}
      customer={order.customer}
      orderShippingAddress={order.orderShippingAddress}
      errorsMessage={errorsMessage}
      saving={saving} />
  )
}

TruckReservationDelivereeForm.propTypes = {
  shippingCostId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
}

TruckReservationDelivereeForm.defaultProps = {
  onSuccess: () => {}
}

export default TruckReservationDelivereeForm
