import { Map, List } from 'immutable'
import actions from './actions'

const {
    BUSINESSES_FETCH,
    BUSINESSES_FETCH_SUCCESS,
    BUSINESSES_SET_PAGINATION,
    BUSINESSES_SET_FORM_ERRORS,
    BUSINESSES_SAVE_SUCCESS,
    BUSINESSES_SET_SAVING,
    BUSINESSES_ITEM_FETCH,
    BUSINESSES_ITEM_FETCH_SUCCESS,
    BUSINESSES_DELETE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    formErrors: new Map(),
    defaultCorporate: 'true',
    businessAddress: null,
    saving: false
})

export default function businessAddressesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case BUSINESSES_FETCH:
            return state.merge({
                loading: true
            })
        case BUSINESSES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case BUSINESSES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case BUSINESSES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case BUSINESSES_SAVE_SUCCESS:
            return state.merge({
                formErrors: new Map(),
                businessAddress: result,
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case BUSINESSES_ITEM_FETCH:
            return state.merge({
                loading: true
            })
        case BUSINESSES_ITEM_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case BUSINESSES_DELETE_SUCCESS:
            return state.merge({
                items: new List(items.delete(items.indexOf(result)))
            })
        case BUSINESSES_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        default:
            return state
    }
}
