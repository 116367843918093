import styled from 'styled-components'

const TransferWrapper = styled.div`

  .ant-card-body {
    padding: 12px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .title { font-weight: bold; }
    .customer-button {margin-right: 12px;}
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .btn-edit {
    width: 60px;
    margin-right: 10px;
  }

  .btn-center {
    text-align: center
  }

  .card-margin {
    margin: 0 0 12px 0;
  }
`

export default TransferWrapper
