import React, { Component, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Skeleton } from 'antd'
import InquiryHeader from './Header'
import InquiryBody from './Body'

import inquiryActions from '../../../redux/inquiry/actions'

const { fetchInquiry } = inquiryActions

const InquiryContainer = ({ id, fetchInquiry, inquiry }) => {
    useEffect(() => {
        if (id !== inquiry.get('current')) {
            fetchOrder()
        }
    }, [id, inquiry])

    const fetchOrder = () => {
        fetchInquiry(id)
    }

    if (inquiry.get('loading')) {
        return <Skeleton active />
    }

    return (
        <React.Fragment>
            <InquiryHeader id={id} fetchOrder={fetchOrder} page='inquiry' />
            <div style={{ marginTop: 24 }}>
                <InquiryBody id={id} />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    inquiry: state.get('inquiry')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchInquiry
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(InquiryContainer)
