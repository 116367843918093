import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { Input, Row, Col, Button, Form } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import inquiryActions from '../../redux/inquiry/actions'
import { withTranslation } from 'react-i18next'

const { saveOrderForm, setOrderConditionAmount } = inquiryActions

class OrderConditionsForm extends Component {
  onAddOrderCondition = (formAmount) => {
      const { setOrderConditionAmount } = this.props
      const arrayOrderCondition = formAmount.concat('0')

      setOrderConditionAmount(arrayOrderCondition)
  }

  onDeleteOrderCondition = (index) => {
      const { inquiry, setOrderConditionAmount } = this.props
      const formAmount = inquiry.get('orderConditionAmount')
      const arrayOrderCondition = formAmount.remove(index)

      setOrderConditionAmount(arrayOrderCondition)
  }

  onSubmitForm = (values, { onSuccess = () => {} } = {}) => {
      const { saveOrderForm, orderId } = this.props

      this.getOrderConditionAttributes(values)
      saveOrderForm(orderId, values, { onSuccess })
  }

  getOrderConditionAttributes (value) {
      const { orderEntity } = this.props
      const orderConditions = orderEntity.get('order_conditions') || new Map()

      const orderConditionsAttributes = value.order_conditions_attributes

      const oldOrderCondition = orderConditions.map((orderConditionId) => {
          let preOrderCondition = {
              id: orderConditionId,
              _destroy: 1
          }
          return preOrderCondition
      })

      if (orderConditionsAttributes) {
          oldOrderCondition.map((orderCondition) => {
              orderConditionsAttributes.push(orderCondition)
          })
      } else {
          value.order_conditions_attributes = oldOrderCondition
      }

      return value
  }

  renderConditionForm = () => {
      const { i18n } = this.props
    return (
        <Form.List name="order_conditions_attributes">
            {(fields, { add, remove }) => (
            <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row key={key}>
                        <Col span={20}>
                            <Form.Item {...restField}
                                name={[name, 'body']}
                                fieldKey={[fieldKey, 'body']}>
                                    <Input />
                            </Form.Item>
                        </Col>
                        <Col span={2} >
                            <CloseCircleOutlined onClick={() => remove(name)}
                                style={{ marginLeft: '14px' }} />
                        </Col>
                    </Row>
                ))}
                <Form.Item>
                <Button onClick={() => add()} size="small" type="primary" ghost>
                    {i18n.t('inquiries:add')}
                </Button>
                </Form.Item>
            </>
            )}
        </Form.List>
    )
  }

  orderConditionForm () {
      const { showConditionForm, handleEditCondition, i18n, saving } = this.props

      return (
          <div>
              {this.renderConditionForm()}
              <Row className="btn-center">
                  <Button style={{ width: 60 }}
                      className="btn-edit"
                      type="primary"
                      size="small"
                      loading={saving}
                      htmlType="submit">
                      {i18n.t('inquiries:edit')}
                  </Button>
                  <Button style={{ width: 60 }}
                      className="btn-edit"
                      size="small"
                      onClick={() => handleEditCondition(showConditionForm)}>{i18n.t('inquiries:cancel')}</Button>
              </Row>
          </div>
      )
  }


  getDefaultEmails = () => {
    const { orderConditionEntities, inquiry } = this.props
    const amount = inquiry.get('orderConditionAmount')

    return amount.map((id) => {
        const condition = orderConditionEntities.getIn([id, 'body'])
        return {
            body: condition
        }
    })
}

  render () {
      const { i18n, showConditionForm, handleEditCondition } = this.props

      return (
          <div>
              <Form onFinish={(values) => this.onSubmitForm(values, { onSuccess: () => handleEditCondition(showConditionForm) })}
                initialValues={{ order_conditions_attributes: this.getDefaultEmails().toJS() }}>
                  <span style={{ fontWeight: 'bold' }}>{i18n.t('inquiries:orderConditions')}</span>
                  {this.orderConditionForm()}
              </Form>
          </div>
      )
  }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    orderConditionEntities: state.getIn(['Entities', 'orderConditions'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderForm,
        setOrderConditionAmount
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(OrderConditionsForm))
