import React from 'react'
import { Form, Button, Row, Col, Input, Select, Checkbox, Spin } from 'antd'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'
import { List } from 'immutable'

const { Option } = Select

const roleOptions = (props) => {
    const { staffRoles } = props
    return staffRoles.map((role) => {
        const id = role.get('id')
        return (
            <Option key={`${id}`} value={id}>
                {role.get('name')}
            </Option>
        )
    })
}

const handleSubmitStaff = (values, props) => {
    const { handleSubmit } = props
    handleSubmit(values)
}

const StaffForm = props => {
    const { i18n } = props
    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 }
    }
    const { staff, staffId, staffSaving, formErrors, staffLoading } = props
    const roleIds = staff.get('staff_roles') || new List()

    const firstNameError = formErrors.get('first_name')
    const lastNameError = formErrors.get('last_name')
    const emailError = formErrors.get('email')
    const phoneNumberError = formErrors.get('phone_number')
    const roleIdsError = formErrors.get('role_ids')

    return (
        <div>
            <h1>{i18n.t('staffs:form.addStaff')}</h1>
            <Spin spinning={staffLoading}>
                <Form onFinish={(values) => handleSubmitStaff(values, props)}>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Form.Item {...formItemLayout}
                                name='first_name'
                                initialValue={staff.get('first_name')}
                                label={i18n.t('staffs:form.firstName')}
                                validateStatus={firstNameError ? 'error' : null}
                                help={firstNameError}>
                                    <Input placeholder={i18n.t('staffs:form.firstName')} />
                            </Form.Item>
                        </Col>
                        <Col md={{ span: 12 }}>
                            <Form.Item {...formItemLayout}
                                name='last_name'
                                initialValue={staff.get('last_name')}
                                label={i18n.t('staffs:form.lastName')}
                                validateStatus={lastNameError ? 'error' : null}
                                help={lastNameError}>
                                    <Input placeholder={i18n.t('staffs:form.lastName')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Form.Item {...formItemLayout}
                                name='email'
                                initialValue={staff.get('email')}
                                label={i18n.t('staffs:form.email')}
                                validateStatus={emailError ? 'error' : null}
                                help={emailError}>
                                    <Input placeholder={i18n.t('staffs:form.email')} />
                            </Form.Item>
                        </Col>
                        <Col md={{ span: 12 }}>
                            <Form.Item {...formItemLayout}
                                name='phone_number'
                                initialValue={staff.get('phone_number')}
                                label={i18n.t('staffs:form.phoneNumber')}
                                validateStatus={phoneNumberError ? 'error' : null}
                                help={phoneNumberError}>
                                    <Input placeholder={i18n.t('staffs:form.phoneNumber')} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Form.Item {...formItemLayout}
                                name='role_ids'
                                initialValue={roleIds.toJS()}
                                label={i18n.t('staffs:form.role')}
                                validateStatus={roleIdsError ? 'error' : null}
                                help={roleIdsError}>
                                    <Select mode="multiple"
                                        placeholder={i18n.t('staffs:form.role')}
                                        style={{ width: '100%' }}>
                                        {roleOptions(props)}
                                    </Select>
                            </Form.Item>
                        </Col>
                        { staffId ?
                            <Col md={{ span: 12 }}>
                                <Form.Item {...formItemLayout}
                                    name='deactivate'
                                    initialValue={staff.get('deactivate')}
                                    label={i18n.t('staffs:deactivate')}>
                                        <Checkbox />
                                </Form.Item>
                            </Col> : null }
                    </Row>
                    <Row type="flex" justify="center">
                        <Button type="primary" size="large"
                            htmlType="submit" loading={staffSaving}>
                            {i18n.t('staffs:form.save')}
                        </Button>
                    </Row>
                </Form>
            </Spin>
        </div>
    )
}

StaffForm.propTypes = {
    formErrors: ImmutablePropTypes.map,
    handleSubmit: PropTypes.func.isRequired,
    staff: ImmutablePropTypes.map,
    staffId: PropTypes.string,
    staffLoading: PropTypes.bool.isRequired,
    staffRoles: ImmutablePropTypes.list.isRequired,
    staffSaving: PropTypes.bool.isRequired
}

export default withTranslation('staffs')(StaffForm)
