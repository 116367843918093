import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button } from 'antd'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

const StaffForm = ({
    errors,
    onSubmit,
    value,
    i18n
}) => {
    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    }

    const handleSubmit = values => {
        onSubmit({ params: values })
    }

    return (
        <Form onFinish={handleSubmit}>
            <Form.Item {...formItemLayout}
                name='first_name'
                initialValue={_.get(value, 'name', null)}
                label={i18n.t('trucks:personal.firstName')}
                help={_.get(errors, 'first_name', []).join(', ')}
                validateStatus={_.get(errors, 'first_name', null) ? 'error' : null}>
                    <Input />
            </Form.Item>
            <Form.Item {...formItemLayout}
                name='last_name'
                initialValue={_.get(value, 'last_name', null)}
                label={i18n.t('trucks:personal.lastName')}
                help={_.get(errors, 'last_name', []).join(', ')}
                validateStatus={_.get(errors, 'last_name', null) ? 'error' : null}>
                    <Input />
            </Form.Item>
            <Form.Item {...formItemLayout}
                name='email'
                initialValue={_.get(value, 'email', null)}
                label={i18n.t('trucks:email')}
                help={_.get(errors, 'email', []).join(', ')}
                validateStatus={_.get(errors, 'email', null) ? 'error' : null}>
                    <Input />
            </Form.Item>
            <Form.Item {...formItemLayout}
                name='phone_number'
                initialValue={_.get(value, 'phone_number', null)}
                label={i18n.t('trucks:phoneNumber')}
                help={_.get(errors, 'phone_number', []).join(', ')}
                validateStatus={_.get(errors, 'phone_number', null) ? 'error' : null}>
                    <Input />
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit">
                    {i18n.t('shared:save')}
                </Button>
            </div>
        </Form>
    )
}

StaffForm.propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    value: PropTypes.object
}

StaffForm.defaultProps = {
    form: {},
    onSubmit: () => {},
    value: {}
}

export default withTranslation(['shared', 'trucks'])(StaffForm)
