import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { gql, useMutation } from '@apollo/client'

import { Button, Form, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import NotificationActions from '../../redux/notifications/actions'

const { setNotifications } = NotificationActions

const MOVE_ORDER_CUSTOMER = gql`
	mutation MoveOrderCustomer($orderId: String!, $customerNo: String!) {
		moveOrderCustomer(orderId: $orderId, customerNo: $customerNo) {
			errors
		}
	}
`

const OrderMoveCustomer = ({
	customerId,
	orderId
}) => {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()
	const [form] = Form.useForm()

	const [moveOrderCustomer, { loading: saving }] = useMutation(MOVE_ORDER_CUSTOMER, {
		onCompleted: ({ moveOrderCustomer }) => {
			if (moveOrderCustomer.errors) {
				dispatch(setNotifications('saveFail', moveOrderCustomer.errors, 'error'))
			} else {
				window.location.reload()
			}
		}
	})

	const onMoveCustomer = (values) => {
		moveOrderCustomer({ variables: { orderId, customerNo: values['customerNo'] } })
  }

	return (
		<Form onFinish={onMoveCustomer} form={form}>
			<Input.Group compact>
					<Form.Item name='customerNo' initialValue={customerId} noStyle>
							<Input style={{ width: 'calc(100% - 130px)' }} />
					</Form.Item>
					<Button htmlType="submit"
						icon={saving && <LoadingOutlined />}
						style={{ width: '130px', padding: '4px' }}>
						{i18n.t('order.moveCustomer')}
					</Button>
			</Input.Group>
		</Form>
	)
}

OrderMoveCustomer.propTypes = {
	customerId: PropTypes.string,
	orderId: PropTypes.string.isRequired,

	form: PropTypes.object.isRequired
}

OrderMoveCustomer.defaultProps = {}

export default OrderMoveCustomer
