import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const TICKET_SUB_CATEGORIES_TYPE_QUERY = gql`
    query TicketSubCategoriesType($categoryType: String!){
        ticketSubCategoriesType(categoryType: $categoryType){
            label
            name
        }
    }
`

const renderOptions = (options = []) => options.map(({ label, name }) => {
  return <Select.Option value={name}>{label}</Select.Option>
})

const SelectTicketSubCategoriesType = ({ categoryType, ...restProps }) => {
  const { loading, data } = useQuery(TICKET_SUB_CATEGORIES_TYPE_QUERY, { variables: { categoryType } })

  return (
    <Select loading={loading} {...restProps}>
        {renderOptions(data && data.ticketSubCategoriesType)}
    </Select>
)
}

SelectTicketSubCategoriesType.propTypes = {
  categoryType: PropTypes.string
}

SelectTicketSubCategoriesType.defaultProps = {}

export default SelectTicketSubCategoriesType
