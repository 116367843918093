import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import ChartOfAccountsListContainer from './ChartOfAccountsListContainer'

class ChartOfAccountIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Chart Of Accounts | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/chart_of_accounts', name: 'Chart Of Accounts' }
                ]}>
                    <ContentLayout>
                      <ChartOfAccountsListContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default ChartOfAccountIndexPage
