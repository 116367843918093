import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import SelectStaffRender from './render'

const SALES_QUERY = gql`
  query Staffs($onlySales: Boolean, $limit: Int) {
    staffs(onlySales: $onlySales, limit: $limit) {
      id
      fullName
    }
  }
`

const SelectStaff = ({
  defaultValue,
  fullWidth,
  onChange,
  onlySales,
  outerLoading,
  value
}) => {
  const initValueRef = useRef(false)
  const [selected, setSelected] = useState(undefined)
  const { loading: innerLoading, data, error } = useQuery(SALES_QUERY, { variables: { onlySales, limit: -1 } })

  useEffect(() => initialValue(), [defaultValue])
  useEffect(() => updateValue(), [value])

  if (error) { console.error(error) }

  const initialValue = () => {
    if (initValueRef.current || outerLoading || selected) return null

    initValueRef.current = true
    handleChange(defaultValue)
  }

  const updateValue = () => {
    if (value === selected) return null

    handleChange(value)
  }

  const handleChange = value => {
    setSelected(value)
    onChange(value)
  }

  return (
    <SelectStaffRender
      fullWidth={fullWidth}
      items={data && data.staffs}
      loading={outerLoading || innerLoading}
      onChange={handleChange}
      selected={selected}
    />
  )
}

SelectStaff.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onlySales: PropTypes.bool,
  outerLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectStaff.defaultProps = {
  fullWidth: false,
  onChange: () => {},
  onlySales: false
}

export default SelectStaff
