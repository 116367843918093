import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Map } from "immutable"
import moment from 'moment'
import _ from 'lodash'

import { Col, DatePicker, Divider, Row, Spin, Statistic, Table } from 'antd'
import SupplierInvoicesWrapper from './supplierInvoices.style'
import SupplierInvoicesVatExportContainer from '../../containers/SupplierInvoices/VatExportContainer'
import SupplierInvoicesPaymentVoucherExportContainer from '../../containers/SupplierInvoices/PaymentVoucherExportContainer'

import { displayNumberWithSymbol } from '../../helpers/number'

const SupplierInvoicesIndex = ({
    filters,
    loading,
    onChangeFilters,
    onTableChange,
    pagination,
    supplierInvoices,
}) => {
    const { i18n } = useTranslation('supplierInvoices')
    const summaryData = useSelector(state => state.getIn(['supplierInvoices', 'summaryData'], new Map()))

    moment.locale(i18n.language)

    const onChangeSummaryMonth = (date) => {
        if (!date) { onChangeFilters({ summaryMonth: null }) }

        onChangeFilters({ summaryMonth: date.format('YYYY-MM') })
    }

    const columns = [
        {
            title: i18n.t('supplierInvoices:invoiceDate'),
            dataIndex: 'issue_date',
            render: (date) => date && moment(date).format('LL')
        },
        {
            title: i18n.t('supplierInvoices:paymentDate'),
            render: (record) => {
                const list = _.get(record, 'payment_vouchers').map((paymentVoucher) => {
                    const date = _.get(paymentVoucher, 'date_of_purchase')

                    return date && moment(date).format('LL')
                })

                return list.join(', ')
            }
        },
        {
            title: i18n.t('supplierInvoices:invoiceRefCode'),
            dataIndex: 'invoice_ref_code'
        },
        {
            title: i18n.t('supplierInvoices:internalDocument'),
            render: (record) => {
                const list = _.get(record, 'payment_vouchers').map((paymentVoucher) => {
                    return _.get(paymentVoucher, 'order_number')
                })

                return list.join(', ')
            }
        },
        {
            title: i18n.t('supplierInvoices:company'),
            render: (record) => {
                const list = _.get(record, 'payment_vouchers').map((paymentVoucher) => {
                    return _.get(paymentVoucher, 'supplier_name')
                })

                return list.join(', ')
            }
        },
        {
            title: i18n.t('supplierInvoices:amount'),
            dataIndex: 'amount'
        },
        {
            title: i18n.t('supplierInvoices:applyDate'),
            dataIndex: 'apply_date',
            render: (date) => date && moment(date).format('LL')
        }
    ]

    const renderSumOfReconciledAmount = () => {
        const totalAmount = summaryData.getIn(['sum_of_reconciled_amounts'], 0)

        return (
            <Statistic
                title={i18n.t('supplierInvoices:sumOfReconciledAmount')}
                value={displayNumberWithSymbol(totalAmount)}
                prefix="฿" />
        )
    }

    const renderAllPaymentVouchers = () => {
        const totalAmount = summaryData.getIn(['payment_vouchers', 'total_amount'], 0)
        const count = summaryData.getIn(['payment_vouchers', 'count'], 0)

        return (
            <Statistic
                title={i18n.t('supplierInvoices:allPaymentVouchers')}
                value={displayNumberWithSymbol(totalAmount)}
                suffix={`(${count} ${i18n.t('supplierInvoices:items')})`}
                prefix="฿" />
        )
    }

    const renderAllSupplierInvoices = () => {
        const totalAmount = summaryData.getIn(['supplier_invoices', 'total_amount'], 0)
        const count = summaryData.getIn(['supplier_invoices', 'count'], 0)

        return (
            <Statistic
                title={i18n.t('supplierInvoices:allSupplierInvoices')}
                value={displayNumberWithSymbol(totalAmount)}
                suffix={`(${count} ${i18n.t('supplierInvoices:items')})`}
                prefix="฿" />
        )
    }

    const renderSupplierInvoicesApplyInThisMonth = () => {
        const totalAmount = summaryData.getIn(['supplier_invoices', 'total_amount_by_apply_month'], 0)
        const count = summaryData.getIn(['supplier_invoices', 'count_by_apply_month'], 0)

        return (
            <Statistic
                title={i18n.t('supplierInvoices:supplierInvoicesApplyInThisMonth')}
                value={displayNumberWithSymbol(totalAmount)}
                suffix={`(${count} ${i18n.t('supplierInvoices:items')})`}
                prefix="฿" />
        )
    }

    return (
        <SupplierInvoicesWrapper>
            <Spin spinning={loading}>
                <Row gutter={16}>
                    <Col span={5}>
                        {renderSumOfReconciledAmount()}
                    </Col>
                    <Col span={5}>
                        {renderAllPaymentVouchers()}
                    </Col>
                    <Col span={5}>
                        {renderAllSupplierInvoices()}
                    </Col>
                    <Col span={5}>
                        {renderSupplierInvoicesApplyInThisMonth()}
                    </Col>
                    <Col span={4} className="text-right">
                        <DatePicker.MonthPicker
                            format="MMMM YYYY"
                            onChange={onChangeSummaryMonth}
                            value={filters.get('summaryMonth') && moment(filters.get('summaryMonth'))}
                        />
                        <div style={{ marginTop: '12px'}}>
                            <SupplierInvoicesVatExportContainer selectedMonth={filters.get('summaryMonth')} />
                        </div>
                        <div style={{ marginTop: '8px'}}>
                            <SupplierInvoicesPaymentVoucherExportContainer
                                selectedMonth={filters.get('summaryMonth')}/>
                        </div>
                    </Col>
                </Row>
            </Spin>

            <Divider />

            <Table
                columns={columns}
                dataSource={supplierInvoices}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
                rowKey="id"
            />
        </SupplierInvoicesWrapper>
    )
}

export default SupplierInvoicesIndex
