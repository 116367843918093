import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import PurchaseOrderForm from '../../components/purchaseOrderForm'
import supplierActions from '../../redux/suppliers/actions'

const {
    fetchSuppilerOrderLineItems,
    saveSupplierOrders
} = supplierActions

class PurchaseOrderFormContainer extends Component {
    static propTypes = {
        // Props from another
        orderId: PropTypes.string.isRequired,
        supplierOrderId: PropTypes.string.isRequired,

        // Inner props
        fetchSuppilerOrderLineItems: PropTypes.func.isRequired,
        purchaseOrderAddressEntities: ImmutablePropTypes.map.isRequired,
        saveSupplierOrders: PropTypes.func.isRequired,
        supplierLineItemEntities: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {}


    constructor (props) {
        super(props)

        this.state = {
            addressErrors: false
        }
    }

    componentDidMount () {
        const {
            fetchSuppilerOrderLineItems,
            supplierOrderId
        } = this.props

        fetchSuppilerOrderLineItems(supplierOrderId)
    }

    handleSaveForm = (supplierOrder) => {
        const { orderId, saveSupplierOrders, supplierOrderId, onSuccess } = this.props

        saveSupplierOrders({ orderId, supplierOrder, id: supplierOrderId, bankAccount: null, onSuccess })
    }

    getSupplierLineItems () {
        const { suppliers, supplierLineItemEntities } = this.props

        return suppliers.get('supplierOrderLineItems', new List()).map((supplierLineItemId) => {
            const supplierLineItem = supplierLineItemEntities.get(_.toString(supplierLineItemId), new Map())
            const lineItemId = supplierLineItem.get('line_item')
            return supplierLineItem.merge({
                line_item_id: lineItemId,
                is_add_item: !lineItemId
            })
        })
    }

    getSupplierOrder () {
        const { purchaseOrderAddressEntities, supplierOrderEntities, supplierOrderId } = this.props
        const supplierOrder = supplierOrderEntities.get(_.toString(supplierOrderId), new Map())

        return supplierOrder.mergeDeep({
            purchase_order_address: purchaseOrderAddressEntities.get(supplierOrder.get('purchase_order_address'), new Map())
        })
    }

    setAddressErrors = (value) => {
        this.setState({
            addressErrors: value
        })
    }

    render () {
        const { orderId, supplierOrderEntities, supplierOrderId, orderEntities, suppliers } = this.props
        const supplierId = supplierOrderEntities.getIn([supplierOrderId, 'supplier'])
        const orderShippingAddress = orderEntities.getIn([orderId, 'order_shipping_address']) || new Map()

        return (
            <PurchaseOrderForm
                onSaveForm={this.handleSaveForm}
                orderId={orderId}
                supplierId={supplierId}
                lineItemsLoading={suppliers.get('lineItemsLoading')}
                supplierLineItems={this.getSupplierLineItems().toJS()}
                supplierOrder={this.getSupplierOrder().toJS()}
                orderShippingAddress={orderShippingAddress}
                addressErrors={this.state.addressErrors}
                setAddressErrors={this.setAddressErrors}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        supplierLineItemEntities: state.getIn(['Entities', 'supplierLineItems']),
        suppliers: state.get('suppliers'),
        supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
        purchaseOrderAddressEntities: state.getIn(['Entities', 'purchaseOrderAddresses']),
        orderEntities: state.getIn(['Entities', 'orders'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSuppilerOrderLineItems,
        saveSupplierOrders
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderFormContainer)
