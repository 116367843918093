import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { withRouter } from 'react-router'

import Filter, { getInitialFilters } from './filter'
import SalePipeLinesMarginSummary from './marginSummary'
import SalePipelineTable, { LIMIT, MARGIN_SUMMARY_FIELDS, NODE_FIELDS } from './table'

import { parseParams } from '../../helpers/queryString'

const SALE_PIPELINES_QUERY = gql`
    query SalePipelinesWithSummaries(
        $isPotential: Boolean,
        $month: String,
        $year: String,
        $staffId: ID,
        $status: String,
        $startDate: String,
        $endDate: String,
        $first: Int,
        $after: String
    ) {
        salePipelinesWithSummaries(
            onlyNotArchived: true,
            isPotential: $isPotential,
            month: $month,
            year: $year,
            staffId: $staffId,
            status: $status,
            startDate: $startDate,
            endDate: $endDate
        ) {
            salePipelinesConnection(first: $first, after: $after) {
                nodes ${NODE_FIELDS}
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
            ${MARGIN_SUMMARY_FIELDS}
        }
    }
`

const INITIAL_FILTERS = getInitialFilters()

const convertFromString = (value) => {
    switch (value) {
        case 'true':
            return true
        case 'false':
            return false
        case 'null':
            return null
        default:
            return value
    }
}

const initialFilters = (currentStaffId, location) => {
    const locationParams = parseParams(location.search)

    return {
        ...INITIAL_FILTERS,
        staffId: currentStaffId,
        ...locationParams,
        status: convertFromString(locationParams.status),
        isPotential: convertFromString(locationParams.isPotential)
    }
}

const getQueryString = (args = {}) => {
    const queryString = Object.keys(args).map((key) => {
        if (key === 'status') {
            if (args[key] === null) return key + '=null'
            return !args[key] ? null : key + '=' + args[key]
        } else if (typeof args[key] === 'boolean') {
            return key + '=' + args[key]
        } else if (!args[key]) {
            return null
        } else {
            return key + '=' + args[key]
        }
    })

    return queryString.filter(Boolean).join('&')
}

const CurrentSalePipeLines = ({ currentStaffId, history, location }) => {
    const [filters, setFilters] = useState(initialFilters(currentStaffId, location))
    const { loading, data, error, fetchMore, refetch } = useQuery(SALE_PIPELINES_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: { first: LIMIT, ...filters }
    })

    if (error) console.error(error)

    const salePipelinesWithSummaries = (data && data.salePipelinesWithSummaries) || {}
    const { salePipelinesConnection, marginSummary } = salePipelinesWithSummaries || {}
    const { nodes, pageInfo } = salePipelinesConnection || {}

    const onChangeFilters = (args = {}) => {
        const newFilters = { ...filters, ...args }
        setFilters(newFilters)

        const queryString = getQueryString(newFilters)
        history.push({
            search: `?${queryString}`
        })
    }

    return (
        <div>
            <Filter
                filters={filters}
                onChangeFilters={onChangeFilters} />
            <SalePipeLinesMarginSummary marginSummary={marginSummary} />
            <SalePipelineTable
                filters={filters}
                loading={loading}
                onFetchMore={fetchMore}
                pageInfo={pageInfo || {}}
                salePipelines={nodes || []}
                refetch={refetch}
            />
        </div>
    )
}

CurrentSalePipeLines.propTypes = {}

CurrentSalePipeLines.defaultProps = {}

export default withRouter(CurrentSalePipeLines)
