import React from 'react'
import { List } from 'immutable'
import { Card, Typography } from 'antd'

import lineLogo from '../../../../../public/images/base/line-logo.png'

import {
  UserOutlined,
  PhoneOutlined,
  ShopOutlined,
  MailOutlined,
  EditOutlined,
  TagOutlined,
  ManOutlined,
  ContactsOutlined
} from '@ant-design/icons'

const { Text } = Typography

const renderLineIcon = (lineUrl) => {
  if (!lineUrl) return (<div>-</div>)
  
  return (
    <div>
      <a href={lineUrl} rel='noreferrer' target='_blank'>
        <img src={lineLogo} height={16} alt='line icon'/>
      </a>
    </div>
  ) 
}

const renderPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return (
    <div style={{marginTop: 4}}>
      <PhoneOutlined rotate={90}/> - 
    </div>
  )
  
  return (
    <div style={{marginTop: 4}}>
      <a href={`tel:${phoneNumber}`} rel='noreferrer' target='_blank'>
        <PhoneOutlined rotate={90} />
        {` ${phoneNumber}`}
      </a>
    </div>
  ) 
}

const renderInformation = (value, icon) => {
  if (!value) return (<div style={{marginTop: 4}}>
      {icon} - 
    </div>)
  
  return (
    <div style={{marginTop: 4}}>
        { icon }
        {` ${value}`}
    </div>
  ) 
}

const renderCompany = (companyName) => {
  if (!companyName) return (<div style={{marginTop: 4}}>
      <ShopOutlined/> - 
    </div>)
  return (
    <div style={{marginTop: 4}}>
      <ShopOutlined />{` ${companyName}`}
    </div>
  )
}

const renderCustomerRole = (customerRoleEntities, roles) => {
  if (!roles) return null
  return roles.map((role) => {
      return (
        <span style={{margin: '0 12px'}}>
            <Text underline>
              { customerRoleEntities.get(role).get('name') }
            </Text>
        </span>
      )
  })

}

const CustomerLabels = ({ labels }) => {
  if (!labels || labels.size === 0) {
    return (
      <div>
        <TagOutlined /> -
      </div>
    )
  }

  return (
    <div>
      <TagOutlined /> {labels.map((label) => label.get('label')).join(', ')}
    </div>
  )
}

const CustomerGender = ({ gender }) => {
  return (
    <div>
      <ManOutlined /> {gender || '-'}
    </div>
  )
}

// TODO: Now customer_type has only ID, need to get the name
const CustomerType = ({ type }) => {
  return (
    <div>
      <ContactsOutlined /> {type || '-'}
    </div>
  )
}

const CustomerCard = ({
  orderEntity, 
  customerEntities,
  customerRoleEntities
}) => {

  const customerId = orderEntity.getIn(['customer', 'id'])
  const customerEntity = customerEntities.get(customerId)


  if (!customerId || !customerEntity) {
    return null
  }

  return (
    <Card
      style={{ width: 344 }}
      title={
        <div style={{display: 'flex'}}>
          <div style={{  }}>
            ข้อมูลลูกค้า
          </div>
          <div style={{ marginLeft: 'auto' }}>
            {renderCustomerRole(customerRoleEntities, customerEntity.get('roles') || new List())}
          </div>
        </div>
      }
      actions={[
        <a href={`/customers/${customerEntity.get('id')}`} rel='noreferrer' target='_blank'>
          <UserOutlined /> {` ${customerEntity.get('id')}`}
        </a>,
        renderLineIcon(customerEntity.get('line_url')),
        <a href={`/customers/${customerEntity.get('id')}/orders/new`} rel='noreferrer' target='_blank'>
          สร้างออเดอร์ใหม่
        </a>
      ]}
    > 
      <div>
        <strong>
          <a href={`/customers/${customerEntity.get('id')}`} rel='noreferrer' target='_blank'>
            {`${customerEntity.get('first_name')} ${customerEntity.get('last_name') || ''}`}
          </a>
        </strong>
        <a href={`/customers/${customerEntity.get('id')}/edit`} rel='noreferrer' target='_blank'>
          {' '}<EditOutlined />
        </a>
        {renderCustomerRole(customerRoleEntities, customerEntity.get('roles') || new List())}
      </div>
      { renderPhoneNumber(customerEntity.get('phone_number')) }
      <CustomerGender gender={customerEntity.get('gender')} />
      { renderInformation(customerEntity.get('email'), <MailOutlined />) }
      { renderCompany(customerEntity.get('company_name')) }
      <CustomerLabels labels={customerEntity.get('customer_labels')} />
      <div style={{marginTop: 4}}>
        { customerEntity.get('customer_note') }
      </div>
    </Card>
  )
}

export default CustomerCard