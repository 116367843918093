import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import SelectChartOfAccountContainer from '../../../containers/SelectChartOfAccount/SelectChartOfAccountContainer'
import {Button, Form, Input, Select, Spin} from 'antd'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
}

const JournalEntryFormatItemForm = ({
    errors,
    i18n,
    loading,
    onSubmitForm,
    saving,
    toggleForm,
    journalEntryFormatItem
}) => {
    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    return (
        <div>
            <h2>{i18n.t('journalEntryFormats:items.journalEntryFormatItem')}</h2>
            <Spin spinning={loading}>
                <Form onFinish={handleSubmitForm} {...formItemLayout}>
                    <Form.Item
                        name='formula'
                        initialValue={_.get(journalEntryFormatItem, 'formula', null)}
                        label={i18n.t('journalEntryFormats:items.formula')}
                        validateStatus={_.get(errors, 'formula') && 'error'}
                        help={_.get(errors, 'name')}
                    >
                            <Input />
                    </Form.Item>


                    <Form.Item
                        name='chart_of_account_id'
                        initialValue={_.toString(_.get(journalEntryFormatItem, 'chart_of_account_id', ''))}
                        label={i18n.t('journalEntryFormats:items.chartOfAccount')}
                        validateStatus={_.get(errors, 'chargeOfAccount') && 'error'}
                        help={_.get(errors, 'chargeOfAccount')}
                    >
                            <SelectChartOfAccountContainer style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name='account_type'
                        initialValue={_.get(journalEntryFormatItem, 'account_type', null)}
                        label={i18n.t('journalEntryFormats:items.accountType')}
                        validateStatus={_.get(errors, 'accountType') && 'error'}
                        help={_.get(errors, 'accountType')}
                    >
                            <Select style={{ width: 200 }}>
                                <Select.Option value="debit"> Debit </Select.Option>
                                <Select.Option value="credit"> Credit </Select.Option>
                            </Select>
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                        <Button htmlType="button"
                          style={{ marginLeft: '12px'}}
                          onClick={toggleForm}>
                            {i18n.t('shared:close')}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

JournalEntryFormatItemForm.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired,
    journalEntryFormatItem: PropTypes.object
}

JournalEntryFormatItemForm.defaultProps = {}

export default withTranslation('journalEntryFormats')(JournalEntryFormatItemForm)
