import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

import {
    Button,
    Col,
    PageHeader,
    Popconfirm,
    Row,
    Skeleton,
    Statistic,
    Table,
    Tabs,
    Tag
} from 'antd'
import { Link } from 'react-router-dom'
import currentStaffContainer from '../../CurrentStaff/CurrentStaffContainer'
import CompleteNoteForm from './CompleteNoteForm'
import CustomerInfoContainer from '../CustomerInfoContainer'
import DoNotTransferForm from './DoNotTransferForm'
import OrderInvoiceFormContainer from '../../Invoices/OrderInvoiceFormContainer'
import OrderInvoiceIndexContainer from '../../Invoices/OrderInvoiceIndexContainer'
import OrderSupplierOverTransferAccountListContainer from '../OrderSupplierOverTransferAccountListContainer'
import PaymentVouchers from './PaymentVouchers'
import ReceiptVoucher from './ReceiptVoucher'
import SupplierPurchaseList from '../../Suppliers/Orders/SupplierPurchaseList'
import TemporaryReceiptsFormContainer from '../../TemporaryReceipts/TemporaryReceiptsFormContainer'
import TruckReservationCostListContainer from '../../TruckReservations/TruckReservationCostListContainer'
import TruckReservationInfoContainer from '../TruckReservationInfoContainer'

import inquiryActions from '../../../redux/inquiry/actions'
import orderStateActions from '../../../redux/inquiry/orderStates/actions'
import orderActions from '../../../redux/orders/actions'
import InquiryHeader from '../../../containers/Inquiry/InquiryContainer/Header'

const { fetchInquiry } = inquiryActions
const { saveOrderState } = orderStateActions
const { checkOrderVouchers } = orderActions

const { TabPane } = Tabs

class AccountingsContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props)

        this.state = {
            undo: false,
            activeTab: 'paymentVouchers'
        }
    }

    componentDidMount() {
        const { fetchInquiry, orderId } = this.props

        fetchInquiry(orderId)
    }

    getCustomerWalletColumns = () => {
        const { i18n } = this.props
        const walletColumns = [
            {
                title: i18n.t(`customers/wallets:date`),
                dataIndex: 'date'
            },
            {
                title: i18n.t(`customers/wallets:amount`),
                dataIndex: 'amount',
                render: (amount) => {
                    return `${amount.display}`
                }
            },
            {
                title: i18n.t(`customers/wallets:transactionType`),
                dataIndex: 'transaction_type',
                render: (type) => {
                    return `${i18n.t(
                        `customers/wallets:transactionTypes.${type}`
                    )}`
                }
            }
        ]
        return walletColumns
    }

    getCustomerWallets = (items) => {
        const wallets = items.filter(
            (customerWallet) =>
                customerWallet.get('transaction_type') !== 'transfer_back'
        )

        if (wallets.length === 0) return new List()

        return wallets.map((wallet) => {
            return wallet
        })
    }

    handle1688Click = (newState) => {
        const { fetchInquiry, orderId, saveOrderState } = this.props

        this.setState({ undo: true })
        saveOrderState(orderId, newState)
        fetchInquiry(orderId)
    }

    handleChangeTab = (value) => {
        this.setState({ activeTab: value })
    }

    handleFullTransferBackClick = (newState) => {
        const { fetchInquiry, orderId, saveOrderState } = this.props

        saveOrderState(orderId, newState)
        fetchInquiry(orderId)
    }

    render1688(orderEntity) {
        if (orderEntity.get('state') === 'payment') {
            return (
                <Button
                    type='primary'
                    onClick={() => this.handle1688Click('complete')}>
                    {' '}
                    1688{' '}
                </Button>
            )
        } else if (orderEntity.get('state') === 'complete') {
            if (this.state.undo) {
                return (
                    <Button
                        type='primary'
                        ghost
                        onClick={() => this.handle1688Click('payment')}>
                        {' '}
                        undo{' '}
                    </Button>
                )
            }
        } else {
            return 'ไม่สามารถ 1688 ได้'
        }
    }

    renderCompleteNote(orderEntity) {
        const { orderId } = this.props

        if (
            orderEntity.get('state') === 'complete' ||
            orderEntity.get('state') === 'payment'
        ) {
            const completeNoteId = orderEntity.get('complete_note')

            return (
                <CompleteNoteForm
                    orderId={orderId}
                    completeNoteId={completeNoteId}
                />
            )
        }
    }

    renderDoNotTransfer(orderEntity) {
        const completeNoteId = orderEntity.get('complete_note')

        return (
            <DoNotTransferForm
                orderId={this.props.orderId}
                completeNoteId={completeNoteId}
                order={orderEntity}
            />
        )
    }

    renderFullTransferBack(orderEntity) {
        const { i18n } = this.props

        if (orderEntity.get('state') === 'complete') {
            return
        }

        if (orderEntity.get('state') === 'full_transferring_back') {
            return (
                <div style={{ color: 'green' }}>
                    {i18n.t('confirms:accounting.fullTransferringBack')}
                </div>
            )
        }

        return (
            <Popconfirm
                placement='leftBottom'
                title={i18n.t(
                    'confirms:accounting.confirmFullTransferringBack'
                )}
                onConfirm={() => {
                    this.handleFullTransferBackClick('full_transferring_back')
                }}
                okText='Yes'
                cancelText='No'>
                {i18n.t('confirms:accounting.fullTransferringBack')}
            </Popconfirm>
        )
    }

    renderPvCheck(order) {
        const { checkOrderVouchers, i18n, orderId, orders } = this.props
        const style = { style: { marginRight: '10px' } }
        const styleSuccess = {
            style: { marginRight: '10px', color: 'mediumseagreen' }
        }
        const styleSuccessBy = { style: { color: '#646464', fontSize: '12px' } }

        if (!['payment', 'complete'].includes(order.get('state'))) {
            return null
        }

        if (order.get('pv_checked')) {
            return (
                <div {...styleSuccess}>
                    <div>{i18n.t('confirms:accounting.PVChecked')}</div>
                    <div {...styleSuccessBy}>
                        {i18n.t('confirms:accounting.by')}{' '}
                        {order.getIn(['voucher_checked', 'who_check_pv'])}
                    </div>
                </div>
            )
        } else if (!order.get('pv_checked')) {
            return (
                <Button
                    type='primary'
                    ghost
                    {...style}
                    loading={orders.get('saving')}
                    onClick={() =>
                        checkOrderVouchers({
                            orderId,
                            value: {
                                voucher_field: 'pv_checked',
                                is_checked: true
                            }
                        })
                    }>
                    {i18n.t('confirms:accounting.checkPV')}
                </Button>
            )
        }
    }

    renderRvCheck(order) {
        const { checkOrderVouchers, i18n, orderId, orders } = this.props
        const style = { style: { marginRight: '10px' } }
        const styleSuccess = {
            style: { marginRight: '10px', color: 'mediumseagreen' }
        }
        const styleSuccessBy = { style: { color: '#646464', fontSize: '12px' } }

        if (!['payment', 'complete'].includes(order.get('state'))) {
            return null
        }

        if (order.get('rv_checked')) {
            return (
                <div {...styleSuccess}>
                    <div>{i18n.t('confirms:accounting.RVChecked')}</div>
                    <div {...styleSuccessBy}>
                        {i18n.t('confirms:accounting.by')}{' '}
                        {order.getIn(['voucher_checked', 'who_check_rv'])}
                    </div>
                </div>
            )
        } else if (!order.get('rv_checked')) {
            return (
                <Button
                    type='primary'
                    ghost
                    {...style}
                    loading={orders.get('saving')}
                    onClick={() =>
                        checkOrderVouchers({
                            orderId,
                            value: {
                                voucher_field: 'rv_checked',
                                is_checked: true
                            }
                        })
                    }>
                    {i18n.t('confirms:accounting.checkRV')}
                </Button>
            )
        }
    }

    renderShippingCosts(orderEntity) {
        const { shippingCostEntities } = this.props

        if (orderEntity.get('shipping_costs') === undefined) {
            return null
        }

        return orderEntity.get('shipping_costs').map((shippingCostId) => {
            const shippingCostEntity = shippingCostEntities.get(shippingCostId)
            return (
                <div key={shippingCostId}>
                    {`${shippingCostEntity.getIn(['vehicle_type', 'name_th'])}
                    ${shippingCostEntity.getIn([
                        'money_display',
                        'single_price'
                    ])}
                    ทุนเสนอลูกค้า ${shippingCostEntity.getIn([
                        'money_display',
                        'cost'
                    ])}
                    จำนวน ${shippingCostEntity.get('quantity')}`}
                </div>
            )
        })
    }

    renderSupplierLineItemStatus(order) {
        const supplierOrders = order.get('supplier_orders')

        if (!['payment', 'complete'].includes(order.get('state'))) {
            return null
        }
        if (supplierOrders.size <= 0) {
            return null
        }

        return (
            <div>
                {order.get('check_supplier_line_items_to_ship') && (
                    <div>
                        {this.props.i18n.t(
                            'confirms:accounting.showSupplierLineItemStatus'
                        )}
                    </div>
                )}
            </div>
        )
    }

    fetchOrder = () => {
        const { fetchInquiry } = this.props
        fetchInquiry(id)
    }

    render() {
        const { i18n, inquiry, orderEntities, orderId, rolesMatched } =
            this.props
        const orderEntity =
            orderEntities.get(inquiry.get('current')) || new Map()
        const customerId = orderEntity.getIn(['customer', 'id'])
        const wallets = orderEntity.get('approved_customer_wallets')

        if (inquiry.get('loading')) {
            return <Skeleton active />
        }

        return (
            <React.Fragment>
                <InquiryHeader
                    id={orderId}
                    fetchOrder={this.fetchOrder}
                    page='account'
                />
                <div className='wrap'>
                    <Row gutter={24}>
                        <Col xs={24} md={12}>
                            {this.renderShippingCosts(orderEntity)}
                            <TruckReservationInfoContainer
                                order={orderEntity}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            {this.renderCompleteNote(orderEntity)}
                            <Row gutter={[8, 8]}>
                                <Col xs={24}>
                                    <Row
                                        type='flex'
                                        justify='end'
                                        align='middle'>
                                        <Col
                                            xs={24}
                                            style={{ textAlign: 'center' }}>
                                            <Row>
                                                <Col xs={12}>
                                                    {this.renderRvCheck(
                                                        orderEntity
                                                    )}
                                                </Col>
                                                <Col xs={12}>
                                                    {this.renderPvCheck(
                                                        orderEntity
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={24}>
                                    {this.renderSupplierLineItemStatus(
                                        orderEntity
                                    )}
                                </Col>

                                <Col xs={24} style={{ float: 'right' }}>
                                    {this.render1688(orderEntity)}
                                </Col>

                                <Col xs={24}>
                                    <Row
                                        type='flex'
                                        justify='end'
                                        align='middle'>
                                        {this.renderFullTransferBack(
                                            orderEntity
                                        )}
                                    </Row>
                                </Col>

                                <Col xs={24}>
                                    <Row
                                        type='flex'
                                        justify='end'
                                        align='middle'>
                                        {this.renderDoNotTransfer(orderEntity)}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <Tabs
                    activeKey={this.state.activeTab}
                    onChange={this.handleChangeTab}
                    tabBarStyle={{ margin: 0 }}>
                    <TabPane
                        key='receiptVouchers'
                        tab={i18n.t('confirms:accounting.paymentDetails')}>
                        <ReceiptVoucher
                            orderId={orderId}
                            rvChecked={orderEntity.get('rv_checked')}
                            orderState={orderEntity.get('state')}
                        />
                        <div style={{ marginTop: '16px' }}>
                            <h3>{i18n.t('customers/wallets:sumOfWallets')}</h3>
                            <Table
                                rowKey='id'
                                columns={this.getCustomerWalletColumns()}
                                dataSource={this.getCustomerWallets(
                                    wallets
                                ).toJS()}
                            />
                        </div>
                    </TabPane>
                    <TabPane
                        key='paymentVouchers'
                        tab={i18n.t('confirms:accounting.paymentVoucher')}>
                        <PaymentVouchers
                            isOrderComplete={
                                orderEntity.get('state') === 'complete'
                            }
                            orderId={orderId}
                            pvChecked={orderEntity.get('pv_checked')}
                        />
                        <TruckReservationCostListContainer orderId={orderId} />
                        <h3 style={{ marginTop: 8 }}>
                            {i18n.t('confirms:accounting.supplierPurchaseList')}
                        </h3>
                        <SupplierPurchaseList orderId={orderId} />
                    </TabPane>
                    {rolesMatched(['super_admin', 'accounting']) && (
                        <TabPane
                            key='invoices'
                            tab={i18n.t('confirms:invoice.taxInvoice')}>
                            <div>
                                <OrderInvoiceFormContainer
                                    customerId={customerId}
                                    orderId={orderId}
                                />
                                <OrderInvoiceIndexContainer
                                    orderId={orderId}
                                    customerId={customerId}
                                />
                            </div>
                        </TabPane>
                    )}
                    <TabPane
                        key='temporaryReceipts'
                        tab={i18n.t(
                            'confirms:temporaryReceipt.temporaryReceipt'
                        )}>
                        <div>
                            <TemporaryReceiptsFormContainer orderId={orderId} />
                        </div>
                    </TabPane>
                    <TabPane key='supplierOverTransfer' tab='โอนเกิน'>
                        <div>
                            <OrderSupplierOverTransferAccountListContainer
                                orderId={orderId}
                            />
                        </div>
                    </TabPane>
                </Tabs>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    inquiry: state.get('inquiry'),
    orders: state.get('orders'),
    orderEntities: state.getIn(['Entities', 'orders']),
    shippingCostEntities: state.getIn(['Entities', 'shippingCosts'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            checkOrderVouchers,
            fetchInquiry,
            saveOrderState
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withTranslation(['confirms', 'inquiries', 'customers/wallets'])(
        currentStaffContainer(AccountingsContainer)
    )
)
