import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import { Card, Col, Row, Table } from 'antd'
import { Link } from 'react-router-dom'
import withPreviewImage from '../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../containers/Image'
import ImagesWrapper from '../images/imagesWrapper.style'

const renderImages = ImageList => {
    return ImageList.map((image) => {
        const thumbUrl = _.get(image, 'file.s', '')
        const originalUrl = _.get(image, 'file.original', '')
        const contentType = _.get(image, 'file.content_type', '')

        return (
            <Image
                key={thumbUrl}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    })
}

const renderShippingAddress = props => {
    const { i18n, order } = props

    if (_.get(order, 'shipping_type') === 'self_pickup') { return i18n.t('order.selfPickup') }
    if (_.isEmpty(_.get(order, 'order_shipping_address'))) { return '-' }

    const additionalInformation = _.get(order, 'order_shipping_address.additional_information', null)
    const phoneNumber = _.get(order, 'order_shipping_address.phone_number', null)
    const ImageList = _.get(order, 'order_shipping_address.order_shipping_address_images', [])

    return (
        <div>
            <div>
                <span>{_.get(order, 'order_shipping_address.full_address', '')}</span>
                <span style={{ paddingLeft: '4px' }}>
                    {additionalInformation ? ` (${additionalInformation})` : null}
                </span>
            </div>
            {phoneNumber ? (
                <div>
                    <span>{i18n.t('order.phoneNumber')}:</span>
                    <span style={{ paddingLeft: '4px' }}>
                        {phoneNumber}
                    </span>
                </div>
            ) : null}
            <ImagesWrapper>
                <div className="image-container">
                    {renderImages(ImageList)}
                </div>
            </ImagesWrapper>
        </div>
    )
}

const renderShippingCosts = props => {
    const { i18n, order } = props
    const shippingCosts = _.get(order, 'shipping_costs', [])
    const columns = [
        {
            key: 'vehicleType',
            title: i18n.t('inquiries:shippingCost.vehicleType'),
            dataIndex: ['vehicle_type', 'name']
        }, {
            key: 'price',
            title: i18n.t('inquiries:shippingCost.price'),
            dataIndex: ['money_display', 'single_price']
        }, {
            key: 'numberOfTrips',
            title: i18n.t('inquiries:shippingCost.numberOfTrips'),
            dataIndex: 'quantity'
        }, {
            key: 'cost',
            title: i18n.t('inquiries:shippingCost.cost'),
            dataIndex: ['money_display', 'cost']
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={shippingCosts}
            pagination={false}
            rowKey={record => record.id}
            size="small" />
    )
}

const renderSupplierNotes = props => {
    const { i18n, orderSupplierNotes } = props
    const columns = [
        {
            key: 'note',
            title: 'Note',
            dataIndex: 'body'
        }, {
            key: 'image',
            title: i18n.t('suppliers:supplierOrder.attachment'),
            dataIndex: 'images',
            render: (dataIndex) => {
                return (
                    <ImagesWrapper>
                        <div className="image-container">
                            {renderImages(dataIndex)}
                        </div>
                    </ImagesWrapper>
                )
            }
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={orderSupplierNotes}
            pagination={false}
            rowKey={record => record.id}
            size="small" />
    )
}

const renderTruckReservationList = props => {
    const { i18n, truckReservations } = props
    const columns = [
        {
            title: i18n.t('truckReservation:shippingDate'),
            dataIndex: 'date',
            render: (dataIndex) => {
                moment.locale(i18n.language)
                const date = dataIndex ? moment(dataIndex).format('Do MMMM YYYY') : null
                return date
            }
        }, {
            title: i18n.t('truckReservation:cost'),
            dataIndex: ['cost', 'display']
        }, {
            title: i18n.t('truckReservation:licensePlateNumber'),
            render: (record) => (
                <div>
                    {record.truck_owner_name} ({record.truck_dispatch_license_plate || '-'})
                </div>
            )
        }, {
            title: i18n.t('truckReservation:truckType'),
            dataIndex: 'reservation_truck'
        }, {
            title: i18n.t('truckReservation:supplierOrders'),
            dataIndex: 'supplier_orders',
            render: (dataIndex) => {
                return dataIndex.map((supplierOrder) => {
                    return (
                        <div key={`supplier-order-${_.get(supplierOrder, 'id')}`}>
                            {_.get(supplierOrder, 'ref_code', '')}
                            {_.get(supplierOrder, 'send_by_supplier', false) ?
                                ` (${i18n.t('suppliers:supplierOrder.sendBySupplier')})` : null}
                        </div>
                    )
                })
            }
        }, {
            title: 'Note',
            dataIndex: 'dispatch_note'
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={truckReservations}
            pagination={false}
            rowKey={record => record.id}
            size="small" />
    )
}

const renderTruckReservationInfo = props => {
    const { i18n } = props

    return (
        <Card bodyStyle={{ padding: '8px' }}>
            <Row gutter={24}>
                <Col span={6}>{i18n.t('order.shippingAddress')}:</Col>
                <Col span={18}>
                    {renderShippingAddress(props)}
                </Col>

                <Col>{i18n.t('inquiries:truckingReservationDate')}:</Col>
                <Col>
                    {renderTruckReservationList(props)}
                </Col>

                <Col>{i18n.t('orders:orderSupplierNote.title')}:</Col>
                <Col>
                    {renderSupplierNotes(props)}
                </Col>

                <Col>{i18n.t('inquiries:shippingCosts')}:</Col>
                <Col>
                    {renderShippingCosts(props)}
                </Col>
            </Row>
        </Card>
    )
}

const TruckReservationInfo = props => {
    const { expand, i18n, toggleExpand } = props

    return (
        <div>
            <div style={{ padding: '4px' }}>
                <Link onClick={toggleExpand}>
                    {expand ?
                        i18n.t('confirms:accounting.hideTruckReservationInfo') :
                        i18n.t('confirms:accounting.showTruckReservationInfo')
                    }
                </Link>
            </div>
            {expand ? renderTruckReservationInfo(props) : null}
        </div>
    )
}

TruckReservationInfo.propTypes = {
    expand: PropTypes.bool,
    order: PropTypes.object,
    orderSupplierNotes: PropTypes.array,
    truckReservations: PropTypes.array,
    toggleExpand: PropTypes.func
}

export default withTranslation(['confirms', 'inquiries', 'orders', 'suppliers'])(
    withPreviewImage(TruckReservationInfo)
)
