const actions = {
    SUPPLIER_FETCH_FAIL: 'SUPPLIER_FETCH_FAIL',
    SUPPLIER_FETCH_REQUEST: 'SUPPLIER_FETCH_REQUEST',
    SUPPLIER_FETCH_SUCCESS: 'SUPPLIER_FETCH_SUCCESS',
    SUPPLIER_FETCHING: 'SUPPLIER_FETCHING',

    ORDER_FETCH_SUPPLIER_ORDERS_REQUEST: 'ORDER_FETCH_SUPPLIER_ORDERS_REQUEST',
    ORDER_FETCH_SUPPLIER_ORDERS_SUCCESS: 'ORDER_FETCH_SUPPLIER_ORDERS_SUCCESS',
    ORDER_FETCH_SUPPLIER_ORDERS: 'ORDER_FETCH_SUPPLIER_ORDERS',

    SUPPLIERS_FETCH_REQUEST: 'SUPPLIERS_FETCH_REQUEST',
    SUPPLIERS_FETCH_SUCCESS: 'SUPPLIERS_FETCH_SUCCESS',
    SUPPLIERS_FETCH: 'SUPPLIERS_FETCH',

    SUPPLIER_SAVE_REQUEST: 'SUPPLIER_SAVE_REQUEST',
    SUPPLIER_SAVE: 'SUPPLIER_SAVE',
    SUPPLIER_SAVE_SUCCESS: 'SUPPLIER_SAVE_SUCCESS',
    SUPPLIER_SAVE_ERRORS: 'SUPPLIER_SAVE_ERRORS',

    // SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_REQUEST: 'SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_REQUEST',
    // SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_SUCCESS: 'SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_SUCCESS',
    // SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF: 'SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF',

    SUPPLIER_FETCH_SUPPLIER_ORDERS_REQUEST: 'SUPPLIER_FETCH_SUPPLIER_ORDERS_REQUEST',
    SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS: 'SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS',
    SUPPLIER_FETCH_SUPPLIER_ORDERS: 'SUPPLIER_FETCH_SUPPLIER_ORDERS',

    // SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_REQUEST: 'SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_REQUEST',
    // SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS: 'SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS',
    // SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS: 'SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS',
    // SUPPLIERS_LINE_ITEM_SET_LOADING: 'SUPPLIERS_LINE_ITEM_SET_LOADING',

    // SUPPLIERS_ORDER_CLEAR_EXPAND_LINE_ITEM: 'SUPPLIERS_ORDER_CLEAR_EXPAND_LINE_ITEM',
    // SUPPLIERS_ORDER_PAY_BY_CREDIT_CARD: 'SUPPLIERS_ORDER_PAY_BY_CREDIT_CARD',

    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_REQUEST: 'SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_REQUEST',
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS: 'SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS',
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER: 'SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER',

    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_REQUEST: 'SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_REQUEST',
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS: 'SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS',
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH: 'SUPPLIERS_ORDERS_LINE_ITEMS_FETCH',

    SUPPLIERS_ORDERS_SAVE_FAIL: 'SUPPLIERS_ORDERS_SAVE_FAIL',
    SUPPLIERS_ORDERS_SAVE_REQUEST: 'SUPPLIERS_ORDERS_SAVE_REQUEST',
    SUPPLIERS_ORDERS_SAVE_SUCCESS: 'SUPPLIERS_ORDERS_SAVE_SUCCESS',
    SUPPLIERS_ORDERS_SAVE: 'SUPPLIERS_ORDERS_SAVE',

    // SUPPLIERS_ORDERS_SET_EXPAND_LINE_ITEM: 'SUPPLIERS_ORDERS_SET_EXPAND_LINE_ITEM',
    // SUPPLIERS_ORDERS_SET_EXPAND: 'SUPPLIERS_ORDERS_SET_EXPAND',
    SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING: 'SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING',

    SUPPLIERS_SEARCH_REQUEST: 'SUPPLIERS_SEARCH_REQUEST',

    // SUPPLIERS_SELECTED_LINE_ITEMS: 'SUPPLIERS_SELECTED_LINE_ITEMS',

    SUPPLIERS_SET_DEFAULT: 'SUPPLIERS_SET_DEFAULT',
    SUPPLIERS_SET_PAGINATION: 'SUPPLIERS_SET_PAGINATION',
    SUPPLIERS_SET_PDF_LANGUAGE: 'SUPPLIERS_SET_PDF_LANGUAGE',
    SUPPLIERS_SET_QUERY: 'SUPPLIERS_SET_QUERY',
    // SUPPLIERS_SET_SAVING: 'SUPPLIERS_SET_SAVING',
    SUPPLIERS_SET_SELECTED: 'SUPPLIERS_SET_SELECTED',
    // SUPPLIERS_SET_SUPPLIER_LOADING: 'SUPPLIERS_SET_SUPPLIER_LOADING',
    // SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING: 'SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING',
    SUPPLIERS_TOGGLE_CREATE_PURCHASE: 'SUPPLIERS_TOGGLE_CREATE_PURCHASE',
    // TOGGLE_PURCHASE_FORM: 'TOGGLE_PURCHASE_FORM',
    TOGGLE_PO_FORM: 'TOGGLE_PO_FORM',
    TOGGLE_PO_DOCUMENT_FORM: 'TOGGLE_PO_DOCUMENT_FORM',
    PO_TYPE_SER_DEFAULT: 'PO_TYPE_SER_DEFAULT',

    TOGGLE_SUPPLIER_OVER_TRANSFER_FORM: 'TOGGLE_SUPPLIER_OVER_TRANSFER_FORM',
    TOGGLE_SUPPLIER_INFO_FORM: 'TOGGLE_SUPPLIER_INFO_FORM',

    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_REQUEST: 'SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_REQUEST',
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS: 'SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS',
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS: 'SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS',
    SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE: 'SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE',

    SUPPLIER_MEGA_HOME_FETCH_ITEMS_REQUEST: 'SUPPLIER_MEGA_HOME_FETCH_ITEMS_REQUEST',
    SUPPLIER_MEGA_HOME_FETCH_ITEMS: 'SUPPLIER_MEGA_HOME_FETCH_ITEMS',
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE: 'SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE',
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS: 'SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS',

    fetchSuppliers: ({ page = 1, per = 20 } = {}) => ({
        type: actions.SUPPLIERS_FETCH_REQUEST,
        payload: { page, per }
    }),

    fetchSupplier: ({ id = null, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.SUPPLIER_FETCH_REQUEST,
            payload: { id, onSuccess, onError }
        }
    },

    saveSupplier: (id, { params, onSuccess = () => {}, onError = () => {} } = {}) => ({
        type: actions.SUPPLIER_SAVE_REQUEST,
        payload: { id, params, onSuccess, onError }
      }),

    searchSupplier: ({ query = '', page = 1, per = 20 } = {}) => {
        return {
            type: actions.SUPPLIERS_SEARCH_REQUEST,
            payload: { query, page, per }
        }
    },

    setSupplierOrdersPdfLanguage: (value) => ({
        type: actions.SUPPLIERS_SET_PDF_LANGUAGE,
        value
    }),

    // fetchSupplierLineItems: (lineItemId) => ({
    //     type: actions.SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_REQUEST,
    //     payload: { lineItemId }
    // }),

    fetchSupplierOrders: (orderId) => ({
        type: actions.ORDER_FETCH_SUPPLIER_ORDERS_REQUEST,
        payload: { orderId }
    }),

    fetchSupplierOrdersBySupplier: ({ supplierId, query = '' } = {}) => ({
        type: actions.SUPPLIER_FETCH_SUPPLIER_ORDERS_REQUEST,
        payload: { supplierId, query }
    }),

    // togglePurchaseForm: (supplierOrderId) => ({
    //     type: actions.TOGGLE_PURCHASE_FORM,
    //     supplierOrderId
    // }),

    // setLineItemExpand: (isExpand) => ({
    //     type: actions.SUPPLIERS_ORDERS_SET_EXPAND,
    //     isExpand
    // }),

    // setLineItemExpandKey: (lineItemId) => ({
    //     type: actions.SUPPLIERS_ORDERS_SET_EXPAND_LINE_ITEM,
    //     lineItemId
    // }),

    saveSupplierOrders: ({
        orderId,
        supplierOrder,
        id,
        paymentVoucher = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIERS_ORDERS_SAVE_REQUEST,
            payload: { orderId, supplierOrder, id, paymentVoucher, onSuccess, onError }
        }
    },

    setSelectedSupplier: (value) => ({
        type: actions.SUPPLIERS_SET_SELECTED,
        value
    }),

    setDefaultSupplier: (value) => ({
        type: actions.SUPPLIERS_SET_DEFAULT,
        value
    }),

    setDefaultPOType: (value) => ({
        type: actions.PO_TYPE_SER_DEFAULT,
        value
    }),

    // selectedLineItems: (selectItems) => ({
    //     type: actions.SUPPLIERS_SELECTED_LINE_ITEMS,
    //     selectItems
    // }),

    fetchSuppilerOrderLineItems: (id) => ({
        type: actions.SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_REQUEST,
        payload: { id }
    }),

    fetchSupplierOrder: (orderId, id) => ({
        type: actions.SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_REQUEST,
        payload: { orderId, id }
    }),

    // fetchSupplierOrderPDF: (orderId, supplierOrderId, locale = 'th') => ({
    //     type: actions.SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_REQUEST,
    //     payload: { orderId, supplierOrderId, locale }
    // }),

    // clearLineItemExpand: () => ({
    //     type: actions.SUPPLIERS_ORDER_CLEAR_EXPAND_LINE_ITEM
    // }),

    toggleCreatePurchase: (isOpen) => ({
        type: actions.SUPPLIERS_TOGGLE_CREATE_PURCHASE,
        isOpen
    }),

    togglePOForm: (supplierOrderId) => ({
        type: actions.TOGGLE_PO_FORM,
        supplierOrderId
    }),

    togglePODocumentHeader: (supplierOrderId) => ({
        type: actions.TOGGLE_PO_DOCUMENT_FORM,
        supplierOrderId
    }),

    // selectedCredit: (credit) => ({
    //     type: actions.SUPPLIERS_ORDER_PAY_BY_CREDIT_CARD,
    //     credit
    // }),

    setShowSupplierOverTransferForm: (value) => ({
        type: actions.TOGGLE_SUPPLIER_OVER_TRANSFER_FORM,
        value
    }),

    setShowSupplierInfoForm: (value) => ({
        type: actions.TOGGLE_SUPPLIER_INFO_FORM,
        value
    }),

    setFormErrors: (formErrors = {}) => ({
        type: actions.SUPPLIER_SAVE_ERRORS,
        formErrors
    }),

    fetchSupplierOrdersList: ({ supplierId, page = 1, per = 20 } = {}) => ({
        type: actions.SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_REQUEST,
        payload: { supplierId, page, per }
    }),

    fetchSupplierMegaHomeItems: ({  page = 1, per = 10, supplierCategoryId = '', query = '' } = {}) => ({
        type: actions.SUPPLIER_MEGA_HOME_FETCH_ITEMS_REQUEST,
        payload: { page, per, supplierCategoryId, query }
    })
}

export default actions
