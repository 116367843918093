import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import TicketContainer from './TicketContainer'

class TicketPage extends React.Component {
    componentDidMount() {
        document.title = 'Tickets | OSH CRM'
    }

    render () {
        const { match } = this.props
        const { id } = match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/tickets', name: 'Ticket' },
                    { path: `/tickets/${id}`, name: id }
                ]}>
                    <ContentLayout>
                        <TicketContainer id={id}/>
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default TicketPage
