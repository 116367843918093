import React from 'react'
import _ from 'lodash'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import ProspectIndexContainer from './ProspectIndexContainer'

class SaleSummaryProspectsPage extends React.Component {
    componentDidMount() {
        document.title = 'Sales Summary | OSH CRM'
    }

    render () {
      const { match, location } = this.props
      const saleId = match.params.id

      let link = `/sales_summary/${saleId}/prospects`

      const selectedMonth = new URLSearchParams(location.search).get("selected_month")
      if (!_.isEmpty(selectedMonth)) { link += `?selected_month=${selectedMonth}` }

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/sales_summary', name: 'Sales' },
                        { path: link, name: 'Prospects' }
                    ]}>
                    <ContentLayout>
                      <ProspectIndexContainer saleId={saleId}
                        selectedMonth={selectedMonth} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default SaleSummaryProspectsPage
