import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import _ from 'lodash'

import UploadItemTruckImage from '../../components/lineItems/uploadItemTruckImage'

import actions from '../../redux/items/truckImages/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveItemTruckImage } = actions
const { setNotifications } = notificationActions

class UploadItemTruckImageContainer extends Component {
    constructor () {
        super()

        this.formRef = createRef()
    }

    static propTypes = {
        // Props from another component
        lineItem: PropTypes.object,

        // Inner Props
        saveItemTruckImage: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {
        lineItem: {
            order: {
                order_shipping_address: {
                    province: {
                        id: ''
                    }
                }
            }
        }
    }

    getProvinceId () {
        const { lineItem } = this.props
        return _.get(lineItem, 'order.order_shipping_address.province.id', '')
    }

    customRequest = async (args) => {
        const { onSuccess, onError, file, onProgress } = args
        const { lineItem, saveItemTruckImage, setNotifications } = this.props

        const form = this.formRef.current
        const itemId = form.getFieldValue('item_id')
        const params = {
            item_truck_image: {
                file,
                line_item_id: _.get(lineItem, 'id', ''),
                province_id: this.getProvinceId()
            }
        }

        if (!itemId) {
            return form.setFields([{ name: 'item_id', errors: ['กรุณากรอก'] }])
        }
        saveItemTruckImage({
            itemId,
            params,
            onSuccess: () => {
                form.setFields([{ name: 'item_id', errors: null } ])
                onSuccess('Ok')
                setNotifications('success', 'success', 'success')
            },
            onError: () => {
                onError()
                setNotifications('error', 'error', 'error')
            },
            onUploadProgress: (e) => {
                onProgress({ percent: (e.loaded / e.total) * 100 })
            }
        })
    }

    render () {
        const { lineItem, itemTruckImages } = this.props
        return (
            <Form ref={this.formRef}>
                <Form.Item name='item_id' initialValue={_.get(lineItem, 'item_external_id', '')}>
                    <Input placeholder="Item External ID" disabled={!!_.get(lineItem, 'item_external_id')} />
                </Form.Item>
                <UploadItemTruckImage
                    customRequest={this.customRequest}
                    uploading={itemTruckImages.get('saving')}
                />
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        itemTruckImages: state.get('itemTruckImages')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveItemTruckImage,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadItemTruckImageContainer)
