import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Modal, Table, Button, Divider, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { withTranslation } from 'react-i18next'

import ProjectWrapper from './project.style'
import ProjectContactFormContainer from '../../containers/Projects/ProjectContactFormContainer'


const ProjectContactsList = ({
    i18n,
    contacts,
    projectId,
    loading,
    onPageChange,
    pagination,
    activeContact,
    toggleProjectContactForm,
    showContactForm,
    deleteProjectContact
}) => {
    const columns = [
        {
            key: 'name',
            title: i18n.t('projects:contacts.name'),
            dataIndex: 'name'
        },
        {
            key: 'detail',
            title: i18n.t('projects:contacts.detail'),
            dataIndex: 'detail'
        },
        {
            key: 'actions',
            render: (record) => {
                return (
                    <div>
                        <div onClick={() => toggleProjectContactForm({ id: record.id })}>
                            <a><EditOutlined />{i18n.t('shared:edit')}</a>
                        </div>
                        <Popconfirm
                            title={i18n.t('shared:confirmDelete')}
                            onConfirm={() => deleteProjectContact(record.id)}
                        >
                            <a className="text-danger"><DeleteOutlined /> {i18n.t('shared:delete')}</a>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    return (
        <ProjectWrapper>
            <Divider />
            <div className="add-customer-section">
                <Button type="primary"
                    onClick={toggleProjectContactForm}
                >
                    {i18n.t('projects:add')}
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={contacts}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />

            <Modal
                visible={showContactForm}
                onCancel={toggleProjectContactForm}
                footer={null}
                destroyOnClose
            >
                <ProjectContactFormContainer projectId={projectId}
                    toggleForm={toggleProjectContactForm}
                    id={activeContact} />
            </Modal>
        </ProjectWrapper>
    )
}

ProjectContactsList.propTypes = {
    contacts: PropTypes.array,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    projectId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    deleteProjectContact: PropTypes.func.isRequired
}

ProjectContactsList.defaultProps = {
    contacts: [],
    loading: false,
    pagination: false
}

export default withTranslation(['shared', 'projects'])(ProjectContactsList)
