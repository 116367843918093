import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { List } from 'immutable'

import { Button, Card, Modal, Table } from 'antd'
import ProfitSharingFormContainer from '../../SupplierOrders/ProfitSharingFormContainer'
import ProfitSharingWrapper from './profitSharing.style'

import inquiryActions from '../../../redux/inquiry/actions'
import orderActions from '../../../redux/orders/actions'

const { setShowProfitSharingForm } = inquiryActions
const { fetchOrder } = orderActions

class ProfitSharings extends Component {
    getColumns () {
        const { i18n } = this.props

        return [
            {
                title: i18n.t('suppliers:supplierOrder.refCode'),
                dataIndex: 'ref_code',
                render: (dataIndex, row) => {
                    return <a onClick={() => this.handleShowProfitSharingForm(row.id)}>{dataIndex}</a>
                }
            },
            {
                title: i18n.t('suppliers:supplierOrder.percent'),
                dataIndex: 'profit_sharing_percent',
                render: (percent) => {
                    return (
                        <div>{percent ? `${percent}%` : null }</div>
                    )
                }
            },
            {
                title: i18n.t('suppliers:transferForm.amount'),
                dataIndex: ['grand_total', 'display']
            }
        ]
    }

    handleShowProfitSharingForm = (id = null) => {
        const { inquiry, setShowProfitSharingForm } = this.props
        const visible = inquiry.get('showProfitSharingForm')
        this.supplierOrderId = id
        setShowProfitSharingForm(!visible)
    }

    getProfitSharingList () {
        const { orderEntities, orderId, supplierOrderEntities } = this.props
        const supplierOrderIds = orderEntities.getIn([orderId, 'supplier_orders']) || new List()
        return supplierOrderIds.map((id) => {
            return supplierOrderEntities.get(id)
        }).filter(supplierOrder => supplierOrder.get('supplier_order_type') === "profit_sharing")
    }

    renderProfitSharingForm = () => {
        const { orderId, fetchOrder, customerId } = this.props

        return (
            <ProfitSharingFormContainer
                orderId={orderId}
                customerId={customerId}
                supplierOrderId={this.supplierOrderId}
                onSaveSuccess={() => {
                    this.handleShowProfitSharingForm()
                    fetchOrder(orderId)
                }}
            />
        )
    }

    render () {
        const { i18n, inquiry } = this.props

        return (
            <ProfitSharingWrapper>
                <Card>
                    <div className="header">
                        <div className="title">{i18n.t('profitSharing:profitSharing')}</div>
                        <Button type="primary" size="small"
                            onClick={() => this.handleShowProfitSharingForm()}>
                            {i18n.t('inquiries:add')}
                        </Button>
                    </div>
                    <Table
                        columns={this.getColumns()}
                        dataSource={this.getProfitSharingList().toJS()}
                        pagination={false}
                        rowKey={record => record.id}
                        size="small"
                        scroll={{ x: 500 }} />
                    <Modal
                        centered
                        destroyOnClose
                        footer={null}
                        onCancel={this.handleShowProfitSharingForm}
                        visible={inquiry.get('showProfitSharingForm')}>
                        {this.renderProfitSharingForm()}
                    </Modal>
                </Card>
            </ProfitSharingWrapper>
        )
    }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrder,
        setShowProfitSharingForm
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('profitSharing')(ProfitSharings))
