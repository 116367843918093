import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import TicketsListRender from '../../../tickets/ticketsList/render'

const CUSTOMER_TICKETS_QUERY = gql`
    query CustomerTickets($customerId: String!, $first: Int, $after: String, $order: String) {
        customer(customerNo: $customerId){
            id
            ticketsConnection(first: $first, after: $after, order: $order) {
                nodes {
                    id
                    ticketNo
                    description
                    state
                    stateLabel
                    ticketTypeLabel
                    assignToOsh
                    createdAt {
                        display
                    }
                    updatedAt {
                        display
                    }
                    buildTime {
                        hours
                        minutes
                    }
                    runTime {
                        hours
                        minutes
                    }
                    latestUpdatedDate {
                        display
                        raw
                    }
                    lastTicketNoteAnswer {
                        note
                        images {
                            id
                            url
                        }
                    }
                    customer {
                        id
                        customerNo
                        fullName
                    }
                    orders {
                        id
                        number
                    }
                    province {
                        id
                        name
                    }
                    district {
                        id
                        name
                    }
                    images {
                        id
                        url
                    }
                    ticketMemberAgent {
                        id
                        staff {
                            id
                            fullName
                        }
                    }
                    memberStaffs {
                        id
                        firstName
                        fullName
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`

const LIMIT = 10

const onLoadMore = (fetchMore, customerId, setLoadMore, { prevNodes, pageInfo }) => {
    if (!pageInfo.hasNextPage) { return null }

    setLoadMore(true)
    fetchMore({
        variables: { customerId, first: LIMIT, after: pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) { return prev }

            const customer = fetchMoreResult.customer || {}
            const ticketsConnection = customer.ticketsConnection || {}

            setLoadMore(false)

            return {
                customer: {
                    ...customer,
                    ticketsConnection: {
                        ...ticketsConnection,
                        nodes: [...prevNodes, ...ticketsConnection.nodes]
                    }
                }
            }
        }
    })
}

const TicketsList = forwardRef(({ customerId }, ref) => {
    const [filters, setFilters] = useState({})
    const [loadMore, setLoadMore] = useState(false)
    const { loading, data, refetch, fetchMore, error } = useQuery(CUSTOMER_TICKETS_QUERY, {
        variables: { customerId, first: LIMIT, ...filters }
    })

    useImperativeHandle(ref, () => ({
        refetch () {
            refetch()
        }
    }))

    const onChangeFilter = args => {
        setFilters({ ...filters, ...args })
    }

    const onChangeTable = (pagination, filters, { columnKey, order }) => {
		onChangeFilter({ order: order ? order.replace('end', '') : 'desc' })
	}

    const { nodes, pageInfo } = (data && data.customer && data.customer.ticketsConnection) || {}

    return (
        <TicketsListRender
            dataSource={nodes || []}
            loading={loading || loadMore}
            onLoadMore={args => onLoadMore(fetchMore, customerId, setLoadMore, args)}
            onChangeTable={onChangeTable}
            pageInfo={pageInfo || {}}
        />
    )
})

TicketsList.propTypes = {
    customerId: PropTypes.string.isRequired
}

TicketsList.defaultProps = {}

export default TicketsList
