import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Button, Col, Divider, Row } from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import ProductGroupSelectedItemsListContainer from '../../containers/ProductGroups/SelectedItemsListContainer'
import SelectProductGroup from '../../containers/SelectProductGroup'
import SelectProductGroupItem from '../../containers/SelectProductGroupItem'
import SelectRootItemAttribute from '../../containers/ProductGroups/SelectRootItemAttribute'
import ProductGroupItemsListContainer from '../../containers/ProductGroups/ItemsListContainer'
import ProductGroupItemFormWrapper from './itemForm.style'

const ProductGroupItemForm = ({
    itemsQtySelected,
    lineItemsLoading,
    onChangeProductGroup,
    onChangeProductGroupItem,
    onChangeRootItemAttribute,
    onSelectItem,
    onSubmitFormItem,
    productGroupId,
    productGroupItemId,
    productImage,
    rootItemAttributeId,
    saveOrderLoading,
    selectedItemIds
}) => {
    const { i18n } = useTranslation('inquiries')

    const renderProductImage = () => {
      if(!productGroupItemId) { return null }

      if (productImage) {
        return (
          <div className="product-image-container">
            <img className="image" src={productImage.get('medium')} alt="Product" />
          </div>
        )
      }

      return (
        <div className="no-image-container">
            <PictureOutlined className="image" />
        </div>
      )
    }

    return (
        <ProductGroupItemFormWrapper>
            <Row>
                <Col span={10} className="padding-right-12">
                    <ProductGroupSelectedItemsListContainer
                        itemsQtySelected={itemsQtySelected}
                        onSelectItem={onSelectItem}
                        selectedItemIds={selectedItemIds} />
                    <Button onClick={onSubmitFormItem} type='primary'
                        style={{ float: 'right', marginTop: '2rem' }}
                        loading={saveOrderLoading || lineItemsLoading}>
                        {i18n.t('inquiries:addItem')}
                    </Button>
                </Col>
                <Col span={14} className="border-left padding-left-12">
                    <h3>{i18n.t('inquiries:searchItem')}</h3>
                    <Row>
                        <Col span={14}>
                            <div className="form-group">
                                <SelectProductGroup
                                    onChange={onChangeProductGroup}
                                    value={productGroupId} />
                            </div>
                            <div className="form-group">
                                <SelectProductGroupItem
                                    productGroupId={productGroupId}
                                    onChange={onChangeProductGroupItem}
                                    value={productGroupItemId} />
                            </div>
                            <div className="form-group">
                                <SelectRootItemAttribute
                                    productGroupItemId={productGroupItemId}
                                    onChange={onChangeRootItemAttribute}
                                    value={rootItemAttributeId} />
                            </div>
                        </Col>
                        <Col span={10}>
                            {renderProductImage()}
                        </Col>
                    </Row>
                    <Divider className="margin-top-12 margin-bottom-12" />
                    <ProductGroupItemsListContainer
                        onSelectItem={onSelectItem}
                        parentId={rootItemAttributeId}
                        productGroupId={productGroupId}
                        productGroupItemId={productGroupItemId}
                        selectedItemIds={selectedItemIds.toJS()}
                    />
                </Col>
            </Row>
        </ProductGroupItemFormWrapper>
    )
}

ProductGroupItemForm.propTypes = {
    itemsQtySelected: ImmutablePropTypes.map,
    lineItemsLoading: PropTypes.bool,
    onChangeProductGroup: PropTypes.func.isRequired,
    onChangeProductGroupItem: PropTypes.func.isRequired,
    onChangeRootItemAttribute: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    onSubmitFormItem: PropTypes.func.isRequired,
    productGroupId: PropTypes.string,
    productGroupItemId: PropTypes.string,
    productImage: ImmutablePropTypes.map,
    rootItemAttributeId: PropTypes.string,
    saveOrderLoading: PropTypes.bool,
    selectedItemIds: ImmutablePropTypes.list
}

ProductGroupItemForm.defaultProps = {}

export default ProductGroupItemForm
