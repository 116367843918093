import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'

import { orderTrackingSchema } from '../../schema'
import actions from './actions'

const {
    ORDER_TRACKING_FETCH_REQUEST,
    ORDER_TRACKING_FETCH_SUCCESS,
    ORDER_TRACKING_FETCH,
    ORDER_TRACKING_SAVE_FAIL,
    ORDER_TRACKING_SAVE_REQUEST,
    ORDER_TRACKING_SAVE_SUCCESS,
    ORDER_TRACKING_SAVING
} = actions

export function *OrderTrackingRequest () {
    yield takeEvery(ORDER_TRACKING_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_TRACKING_FETCH, loading: true })

        const { orderId, onSuccess, onError } = payload
        const path = `/orders/${orderId}/tracking`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)

            yield put({
                type: ORDER_TRACKING_FETCH_SUCCESS,
                ...normalize(formattedData, orderTrackingSchema)
            })

            onSuccess(formattedData)
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: ORDER_TRACKING_FETCH, loading: false })
        }
    })
}

export function *saveOrderTrackingSaga () {
    yield takeLatest(ORDER_TRACKING_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_TRACKING_SAVING })

        const { orderId, orderTrackingParams, onSuccess, onError } = payload

        try {
            const path = `/orders/${orderId}/tracking`
            const body = JSON.stringify({ order_tracking: orderTrackingParams })

            const data = yield call(apiFetch, path, 'PATCH', { body })
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderTrackingSchema)

            yield put({
                type: ORDER_TRACKING_SAVE_SUCCESS,
                ...normalizedData
            })

            onSuccess(formattedData)
        } catch (error) {
            yield put({ type: ORDER_TRACKING_SAVE_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(OrderTrackingRequest),
        fork(saveOrderTrackingSaga)
    ])
}
