import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

import { apiFetch } from '../../helpers/restRequest'
import { potentialLeadSchema, potentialLeadsSchema } from '../schema'
import actions from './actions'
import actionsNotification from '../../redux/notifications/actions'

const { setNotifications } = actionsNotification

const {
    CUSTOMER_POTENTAIL_LEADS_FETCH_REQUEST,
    CUSTOMER_POTENTAIL_LEADS_FETCHING,
    CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS,
    CUSTOMER_POTENTAIL_LEADS_SAVE_REQUEST,
    CUSTOMER_POTENTAIL_LEADS_SAVING,
    CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS,
    CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION,
    POTENTAIL_LEADS_DELETE_REQUEST,
    POTENTAIL_LEADS_DELETE_SUCCESS
} = actions

export function *customerPotentialLeadsRequest () {
    yield takeEvery(CUSTOMER_POTENTAIL_LEADS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_POTENTAIL_LEADS_FETCHING, loading: true })
        const { customerId, page } = payload
        const PAGE_SIZE = 20

        const path = `/customers/${customerId}/potential_leads?page=${page}&per=${PAGE_SIZE}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, potentialLeadsSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({ type: CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS, ...normalizeData })
    })
}

export function *savePotentialLead () {
    yield takeLatest(CUSTOMER_POTENTAIL_LEADS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_POTENTAIL_LEADS_SAVING, saving: true })

        const { customerId, id, params, onSuccess, onError } = payload

        const basePath = `/customers/${customerId}/potential_leads`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ potential_lead: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, potentialLeadSchema)

            yield put({
                type: CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS,
                ...normalizeData
            })

            onSuccess()
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage
            onError(errorMessage)
        } finally {
            yield put({ type: CUSTOMER_POTENTAIL_LEADS_SAVING, saving: false })
        }
    })
}

export function *deletePotentialLead () {
    yield takeLatest(POTENTAIL_LEADS_DELETE_REQUEST, function *({ payload }) {
        const { customerId, id } = payload
        const path = `/customers/${customerId}/potential_leads/${id}`
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: POTENTAIL_LEADS_DELETE_SUCCESS,
                ...normalize(formattedData, potentialLeadSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}


export default function *rootSaga () {
    yield all([
        fork(customerPotentialLeadsRequest),
        fork(savePotentialLead),
        fork(deletePotentialLead)
    ])
}
