import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import SaleActions from '../../redux/sales/actions'

const {
    downloadSalesReportFile
} = SaleActions

const SalesContainer = (ChildComponent) => {
    const klass = class SalesContainer extends Component {
        static propTypes = {
            downloadSalesReportFile: PropTypes.func.isRequired,
            sales: ImmutablePropTypes.map.isRequired
        }

        handleDownloadSalesReportFile = (startDate, endDate) => {
            const { downloadSalesReportFile } = this.props
            downloadSalesReportFile({ startDate, endDate })
        }

        render () {
            const { sales } = this.props

            return (
                <ChildComponent
                    onDownload={this.handleDownloadSalesReportFile}
                    salesReportLoading={sales.get('salesReportLoading')}
                />
            )
        }
    }

    const mapStateToProps = (state) => ({
        sales: state.get('sales')
    })

    const mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            downloadSalesReportFile
        }, dispatch)
    }

    return connect(mapStateToProps, mapDispatchToProps)(klass)
}

export default SalesContainer
