import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import SupplierOrdersList from '../../components/Suppliers/supplierOrdersList'

import actions from '../../redux/suppliers/actions'

const { fetchSupplierOrdersList } = actions

class SupplierOrdersListContainer extends Component {
  static propTypes = {
    supplierId: PropTypes.string.isRequired
  }

  componentDidMount () {
    const { fetchSupplierOrdersList, supplierId } = this.props

    fetchSupplierOrdersList({ supplierId })
  }

  getSupplierOrders () {
    const { suppliers, supplierOrderEntities } = this.props
    return suppliers.get('supplierOrders', new List()).map((id) => {
        return supplierOrderEntities.get(id) || new Map()
    })
  }

  handlePageChange = (pagination) => {
    const { fetchSupplierOrdersList, supplierId } = this.props

    fetchSupplierOrdersList({ supplierId, page: pagination.current })
  }

  render() {
    const { suppliers, supplierOrderEntities } = this.props

    return (
      <SupplierOrdersList
        loading={suppliers.get('supplierOrdersLoading')}
        onPageChange={this.handlePageChange}
        supplierOrders={this.getSupplierOrders().toJS()}
        pagination={{
          ...suppliers.get('supplierOrderPagination').toJS(),
          showSizeChanger: false
        }}
        supplierOrderEntities={supplierOrderEntities}
      />
    );
  }
}

const mapStateToProps = state => ({
  suppliers: state.get('suppliers'),
  supplierOrderEntities: state.getIn(['Entities', 'supplierOrders'])
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchSupplierOrdersList,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOrdersListContainer)