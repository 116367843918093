import React from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Select, Form, Input, Button } from 'antd'

const ProfitSharingForm = props => {
    const { getFieldErrors, supplierOrder, handleSubmit, saving, i18n, profitSharingOptions } = props
    const onSubmit = (values) => {
        handleSubmit({ values })
    }

    const renderProfitSharingOptions = () => {
        const { Option } = Select

        return profitSharingOptions.map((profitSharing) => {
            return (
                <Option key={profitSharing.value}
                    value={profitSharing.value}>
                    {profitSharing.label}
                </Option>
            )
        })
    }

    const selectProfitSharings = (onSelected) => {
        return (
            <Select
                allowClear
                onChange={onSelected}
                style={{ width: '100%' }}>
                {renderProfitSharingOptions()}
            </Select>
        )
    }
    const profitSharingId = _.get(supplierOrder, 'profit_sharing_id')
    const percentErrors = getFieldErrors('profit_sharing_percent')

    return (
        <Form onFinish={onSubmit} layout={'vertical'}>
            <h2>{i18n.t('profitSharing:profitSharing')}</h2>
            <Form.Item label={i18n.t('profitSharing:profitSharing')}
                name='profit_sharing_id'
                initialValue={profitSharingId ? profitSharingId.toString() : undefined}>
                    {selectProfitSharings()}
            </Form.Item>
            <Form.Item
                name='grand_total'
                initialValue={_.get(supplierOrder, 'grand_total.raw', null)}
                label={i18n.t('suppliers:supplierOrder.supplierOrderForm.grandTotal')}
                validateStatus={percentErrors.status}
                help={percentErrors.help}
            >
                    <Input />
            </Form.Item>

            <div style={{ textAlign: 'center' }}>
                <Button loading={saving} htmlType="submit" type="primary">
                    {i18n.t('shared:save')}
                </Button>
            </div>
        </Form>
    )
}

export default withTranslation(['profitSharing', 'suppliers'])(ProfitSharingForm)
