import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import OrderSummariesStatistics from '../../components/orderSummaries/statistics'

import actions from '../../redux/orders/summaryDashBoards/actions'
import { initState } from '../../redux/orders/summaryDashBoards/reducers'

const { fetchOrderSummariesDashboardStatistics, setStatisticsFilters } = actions

const OrderSummariesStatisticsContainer = ({
    defaultFilters,
    disableChangeType,
    onRefetch
}) => {
    const dispatch = useDispatch()
    const statistics = useSelector(state => state.getIn(['orderSummaryDashBoards', 'statistics'])).toJS()
    const { filters } = statistics

    useEffect(() => {
        const initFilters = initState.getIn(['statistics', 'filters']).toJS()
        const newFilters = {}
        _.map(initFilters, (value, key) => {
            _.set(newFilters, key, defaultFilters[key] || value)
        })

        dispatch(setStatisticsFilters(newFilters))
        dispatch(fetchOrderSummariesDashboardStatistics(newFilters))
    }, [])

    const onChangeFilter = (args) => {
        const newFilters = { ...filters, ...args }

        onRefetch(newFilters)
        dispatch(setStatisticsFilters(newFilters))
        dispatch(fetchOrderSummariesDashboardStatistics(newFilters))
    }

    const onChangeSelectType = (args) => {
        if (disableChangeType) { return null }

        const newFilters = { ...filters, ...args }

        onRefetch(newFilters)
        dispatch(setStatisticsFilters(newFilters))
    }

    return (
        <OrderSummariesStatistics
            disableChangeType={disableChangeType}
            onChangeFilter={onChangeFilter}
            onChangeSelectType={onChangeSelectType}
            statistics={statistics}
        />
    )
}

OrderSummariesStatisticsContainer.propTypes = {
    defaultFilters: PropTypes.object,
    disableChangeType: PropTypes.bool,
    onRefetch: PropTypes.func,
}

OrderSummariesStatisticsContainer.defaultProps = {
    defaultFilters: {},
    disableChangeType: false,
    onRefetch: () => {}
}

export default OrderSummariesStatisticsContainer
