import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, from } from "@apollo/client"
import apiConfig from '../apiConfig'
import jsCookie from 'js-cookie'

const GRAPHQL_API_URL = `${apiConfig.apiBase}/api/client_crm_api/v1/graphql`
const TOKEN = jsCookie.get('token')

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${TOKEN}`
    }
  })

  return forward(operation)
})

export const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: from([
    authMiddleware,
    httpLink
  ]),
  credentials: 'omit',
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      return console.error('GraphQL Errors:', graphQLErrors)
    }

    if (networkError) {
      return console.error('Network Error: ', networkError)
    }
  }
})
