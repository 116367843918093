export const objectToQueryString = (object) => {
    return Object.keys(object).map((key) => {
        if (!Array.isArray(object[key])) return `${key}=${object[key]}`

        return object[key].map((value) => {
            return `${key}[]=${value}`
        }).join('&')
    }).join('&')
}

export const parseParams = (params = "") => {
    if(!params) return {}

    const rawParams = params.replace("?", "").split("&")
    const extractedParams = {}

    rawParams.forEach((item) => {
      item = item.split("=")
      extractedParams[item[0]] = item[1]
    })

    return extractedParams
}
