import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'

import BankStatementExport from '../../components/BankStatements/Export'

import actions from '../../redux/bankStatements/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    setExportVisibleModal,
    exportBankStatements
} = actions

const { setNotifications } = notificationActions

class BankStatementExportContainer extends Component {
    static propTypes = {
        // Inner Props
        bankStatements: ImmutablePropTypes.map.isRequired,
        setExportVisibleModal: PropTypes.func.isRequired,
        exportBankStatements: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {}

    onSubmit = ({ params = {} } = {}) => {
        const { exportBankStatements, i18n, setNotifications } = this.props

        exportBankStatements({
            params,
            onSuccess: () => {
                this.onToggleVisibleModal()
                setNotifications(
                    i18n.t('bankStatements:uploadSuccess'),
                    i18n.t('bankStatements:uploadSuccessDescription'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    onToggleVisibleModal = () => {
        const { bankStatements, setExportVisibleModal } = this.props

        setExportVisibleModal({ visibleModal: !bankStatements.getIn(['export', 'visibleModal']) })
    }

    render () {
        const { bankStatements } = this.props

        return (
            <BankStatementExport
                onSubmit={this.onSubmit}
                onToggleVisibleModal={this.onToggleVisibleModal}
                saving={bankStatements.get('saving', false)}
                visibleModal={bankStatements.getIn(['export', 'visibleModal'], false)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setExportVisibleModal,
        setNotifications,
        exportBankStatements
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['bankStatements'])(BankStatementExportContainer)
)
