import styled from 'styled-components'

const ProductGroupItemFormWrapper = styled.div`
  .form-group {
    margin-bottom: 12px;
  }

  .product-image-container {
    text-align: center;

    .image {
      padding: 8px;
      border-radius: 4%;
      width: 160px;
      border: 1px solid #e1e1e1;
      height: 160px;
    }
  }

  .no-image-container {
    padding: 8px;
    border-radius: 4%;
    width: 160px;
    border: 1px solid #e1e1e1;
    height: 160px;
    margin: auto;

    i {
      padding: 48px;
      color: #e1e1e1;
      font-size: 40px;
    }
  }

  .border-left { border-left: 1px solid #E1E1E1; }
  .padding-left-12 { padding-left: 12px; }
  .padding-right-12 { padding-right: 12px; }
  .margin-top-12 { margin-top: 12px; }
  .margin-bottom-12 { margin-bottom: 12px; }
`

export default ProductGroupItemFormWrapper
