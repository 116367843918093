import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

import CustomerNoteForm from '../../components/customers/customerNote/customerNoteForm'
import CustomerNote from '../../components/customers/customerNote/customerNote'

import customerNoteActions from '../../redux/customers/customerNotes/actions'

const { fetchOrderNote, saveCustomerNote } = customerNoteActions

class OrderNoteContainer extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired
    }

    componentDidMount () {
        this.noteRequest()
    }

    noteRequest = () => {
        const { fetchOrderNote, id } = this.props

        fetchOrderNote(id)
    }

    handleSubmitForm = (customerNote) => {
        const { saveCustomerNote, orderEntities, id } = this.props
        const customerId = orderEntities.getIn([id, 'customer', 'id'])

        saveCustomerNote(customerNote, customerId)
    }

    getNotes () {
        const { customerNotes, customerNoteEntities, noteAttachmentEntities, orderNoteImageEntities } = this.props

        return customerNotes.get('items').map((customerNoteId) => {
            const note = customerNoteEntities.get(customerNoteId.toString()) || new Map()
            const noteAttachmentIds = note.get('note_attachments') || new List()
            const orderNoteImageIds = note.get('order_note_images') || new List()

            return new Map({
                ...note.toJS(),
                note_attachments: noteAttachmentIds.map((noteAttachmentId) => {
                    return noteAttachmentEntities.get(noteAttachmentId.toString()) || new Map()
                }) || new List(),

                order_note_images: orderNoteImageIds.map((orderNoteImageId) => {
                    return orderNoteImageEntities.get(orderNoteImageId.toString()) || new Map()
                }) || new List()
            })
        }) || new List()
    }

    handleChange = (pagination) => {
        const { fetchOrderNote, id } = this.props

        fetchOrderNote(id, pagination)
    }

    getPagination () {
        const { customerNotes } = this.props
        return customerNotes.get('pagination') || new Map()
    }

    render () {
        const { customerNotes, id } = this.props
        const loading = customerNotes.get('loading')
        const stateSaving = customerNotes.get('saving')

        return (
            <div>
                <CustomerNoteForm
                    handleSubmitNote={this.handleSubmitForm}
                    selectOrder="none"
                    orderId={id}
                    stateSaving={stateSaving}
                />

                <CustomerNote
                    handlePageChange={this.handleChange}
                    loading={loading}
                    notes={this.getNotes()}
                    pagination={this.getPagination()}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orderEntities: state.getIn(['Entities', 'orders']),
    customerNoteEntities: state.getIn(['Entities', 'customerNotes']),
    noteAttachmentEntities: state.getIn(['Entities', 'noteAttachments']),
    orderNoteImageEntities: state.getIn(['Entities', 'orderNoteImages']),
    customerNotes: state.get('customerNotes')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderNote,
        saveCustomerNote
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderNoteContainer))
