import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch, apiFileFetch } from '../../helpers/restRequest'
import actions from './actions'
import { journalEntryDatesSchema, journalEntriesSchema } from '../schema'
import { deserialize } from '../../helpers/jsonApi'
import _ from 'lodash'
import downLoadFile from '../../helpers/downLoadFile'

const {
    JOURNAL_ENTRY_DATES_FETCH_REQUEST,
    JOURNAL_ENTRY_DATES_FETCH,
    JOURNAL_ENTRY_DATES_FETCH_SUCCESS,
    JOURNAL_ENTRIES_FETCH_REQUEST,
    JOURNAL_ENTRIES_FETCH,
    JOURNAL_ENTRIES_FETCH_SUCCESS,

    JOURNAL_ENTRY_DATES_SET_PAGINATION,
    JOURNAL_ENTRIES_SET_PAGINATION,

    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_REQUEST,
    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE,
    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS
} = actions

export function *fetchJournalEntryDates () {
    yield takeEvery(JOURNAL_ENTRY_DATES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: JOURNAL_ENTRY_DATES_FETCH })

        const { page, per, filters, isCalculate } = payload
        let queryArray = [`page=${page}`, `per=${per}`, `is_calculate=${isCalculate}`]

        _.each(filters, (value, key) => {
            queryArray.push(`${_.snakeCase(key)}=${value || ''}`)
        })

        const path = `/journal_entry_dates?${queryArray.join('&')}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, journalEntryDatesSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: JOURNAL_ENTRY_DATES_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: JOURNAL_ENTRY_DATES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchJournalEntries () {
    yield takeEvery(JOURNAL_ENTRIES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: JOURNAL_ENTRIES_FETCH })

        const { page, per, date, amountType } = payload
        const queryString = `page=${page}&per_page=${per}&date=${date}&amount_type=${amountType}`

        const path = `/journal_entries?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, journalEntriesSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: JOURNAL_ENTRIES_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: JOURNAL_ENTRIES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *receiveJounalEntryStatementsFile () {
    yield takeLatest(FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE })

        const { params } = payload

        const fileExtension = 'xlsx'
        const queryString = `?monthly=${params.monthly}`
        const path = `/journal_entries/export_statements.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `statement-of-financial-position-${params.monthly}.${fileExtension}`
        })

        yield put({
            type: FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchJournalEntryDates),
        fork(fetchJournalEntries),
        fork(receiveJounalEntryStatementsFile)
    ])
}
