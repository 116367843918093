const actions = {
    SALE_FETCH_FAIL: 'SALE_FETCH_FAIL',
    SALE_FETCH_REQUEST: 'SALE_FETCH_REQUEST',
    SALE_FETCH_SUCCESS: 'SALE_FETCH_SUCCESS',
    SALE_FETCHING: 'SALE_FETCHING',

    SALES_FETCH_REQUEST: 'SALES_FETCH_REQUEST',
    SALES_FETCH: 'SALES_FETCH',
    SALES_FETCH_SUCCESS: 'SALES_FETCH_SUCCESS',

    SALES_REPORT_FETCH_FILE_REQUEST: 'SALES_FETCH_FILE_REQUEST',
    SALES_REPORT_SET_LOADING: 'SALES_REPORT_SET_LOADING',

    downloadSalesReportFile: (args = { startDate: '', endDate: '' }) => ({
        type: actions.SALES_REPORT_FETCH_FILE_REQUEST,
        payload: args
    }),

    fetchSales: (query = '') => ({
        type: actions.SALES_FETCH_REQUEST,
        payload: { query }
    }),

    fetchSale: ({
        id = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!id) return null

        return {
            type: actions.SALE_FETCH_REQUEST,
            payload: { id, onSuccess, onError }
        }
    }
}

export default actions
