import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import WithholdingTaxForm from '../../components/withholdingTaxes/withholdingTaxForm'

import actions from '../../redux/withholdingTaxes/actions'
import notificationActions from '../../redux/notifications/actions'

const { fetchWithholdingTax, resetWithholdingTaxFormErrors, saveWithholdingTax } = actions
const { setNotifications } = notificationActions

class WithholdingTaxFormContainer extends Component {
  static propTypes = {
      toggleForm: PropTypes.func,
      withholdingTaxId: PropTypes.string
  }

  static defaultProps = {
      toggleForm: () => {}
  }

  componentDidMount () {
      const { fetchWithholdingTax, withholdingTaxId } = this.props

      if (withholdingTaxId) { fetchWithholdingTax({ id: withholdingTaxId }) }
  }

  componentWillUnmount () {
      this.props.resetWithholdingTaxFormErrors()
  }

  getWithholdingTax () {
      const { withholdingTaxEntities, withholdingTaxId } = this.props

      if (!withholdingTaxId) { return new Map() }

      return withholdingTaxEntities.get(withholdingTaxId) || new Map()
  }

  handleSaveWithholdingTax = (params) => {
      const { saveWithholdingTax, setNotifications, toggleForm, withholdingTaxId } = this.props

      saveWithholdingTax({
          id: withholdingTaxId,
          params,
          onSuccess: () => {
              setNotifications('success', 'saveSuccess', 'success')
              toggleForm()
          },
          onError: (error) => {
              setNotifications(`${error.name}`, `${error.message}`, 'error')
          }
      })
  }

  render () {
      const { toggleForm, withholdingTaxes } = this.props

      return (
          <WithholdingTaxForm
              errors={withholdingTaxes.get('errors').toJS()}
              loading={withholdingTaxes.get('loading')}
              onSubmitForm={this.handleSaveWithholdingTax}
              saving={withholdingTaxes.get('saving')}
              toggleForm={toggleForm}
              withholdingTax={this.getWithholdingTax().toJS()}
          />
      )
  }
}

const mapStateToProps = (state) => ({
    withholdingTaxEntities: state.getIn(['Entities', 'withholdingTaxes']),
    withholdingTaxes: state.get('withholdingTaxes')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchWithholdingTax,
        resetWithholdingTaxFormErrors,
        saveWithholdingTax,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WithholdingTaxFormContainer)
