import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Modal, Space } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import LoadingSpinner from '../../loadingSpinner'
import ImageWithActions from '../../../containers/Image/ImageWithActions'
import UploadForm from './form'
import withImagePreview from '../../../containers/ImagePreview/ImagePreviewContainer'
import TicketImagesWrapper from './ticketImages.style'

import apiConfig from '../../../../config/apiConfig'

const renderImages = (data, loading, onRemove, deleting) => {
    if (loading) return <LoadingSpinner />

    return ((data && data.ticket.images) || []).map(({ id, url }) => {
        const originalUrl = `${apiConfig.apiBase}${url}`
        return (
            <ImageWithActions
                actions={[
                    <Button
                        icon={<DeleteOutlined />}
                        key="delete-image-btn"
                        loading={deleting}
                        onClick={() => onRemove(id)}
                        size="small"
                        type="link"
                        danger />
                ]}
                contentType="image/png"
                isImageBlob={false}
                originalUrl={originalUrl}
                thumbUrl={originalUrl}
            />
        )
    })
}

const Render = ({ deleting, imagesData, loading, onRemoveImage, refetchImage, ticketId }) => {
    const { i18n } = useTranslation('tickets')
    const [showUploadForm, setShowUploadForm] = useState(false)

    const toggleUploadForm = () => {
        setShowUploadForm(!showUploadForm)
    }

    return (
        <TicketImagesWrapper>
            <h4>{i18n.t('tickets:images')}</h4>
            <Space>
                <div className="image-container">
                    {renderImages(imagesData, loading, onRemoveImage, deleting)}
                </div>

                <Button onClick={toggleUploadForm}>{i18n.t('shared:upload')}</Button>
            </Space>

            <Modal
                footer={null}
                onCancel={toggleUploadForm}
                visible={showUploadForm}
                width="400px"
            >
                <UploadForm
                    ticketId={ticketId}
                    onClose={toggleUploadForm}
                    refetchImage={refetchImage} />
            </Modal>
        </TicketImagesWrapper>
    )
}

Render.propTypes = {
    deleting: PropTypes.bool,
    imagesData: PropTypes.object,
    loading: PropTypes.bool,
    onRemoveImage: PropTypes.func.isRequired,
    refetchImage: PropTypes.func.isRequired,
    ticketId: PropTypes.string.isRequired
}

Render.defaultProps = {}

export default withImagePreview(Render)
