import { List, Map } from 'immutable'

import actions from './actions'
import { mergeDeepReplaceArrays } from '../entities/reducers'

const {
    TRUCK_FETCH_FAIL,
    TRUCK_FETCH_SUCCESS,
    TRUCK_FETCH,
    TRUCK_FORM_RESET_ERROR,
    TRUCK_FORM_SET_TRUCK_ID,
    TRUCK_OWNER_BANK_ACCOUNT_FORM_RESET_ERROR,
    TRUCK_OWNER_BANK_ACCOUNT_FORM_SET_BANK_ACCOUNT_ID,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE,
    TRUCK_OWNER_FETCH_FAIL,
    TRUCK_OWNER_FETCH_SUCCESS,
    TRUCK_OWNER_FETCH,
    TRUCK_OWNER_FORM_RESET_ERROR,
    TRUCK_OWNER_FORM_SET_OWNER_ID,
    TRUCK_OWNER_SAVE_FAIL,
    TRUCK_OWNER_SAVE_SUCCESS,
    TRUCK_OWNER_SAVE,
    TRUCK_OWNER_STAFF_SAVE_FAIL,
    TRUCK_OWNER_STAFF_SAVE_SUCCESS,
    TRUCK_OWNER_STAFF_SAVING,
    TRUCK_OWNER_STAFF_SET_FORM,
    TRUCK_OWNER_TABLE_SET_OWNER_TYPE,
    TRUCK_OWNER_TABLE_SET_VISIBLE_MODAL,
    TRUCK_OWNERS_FETCH_FAIL,
    TRUCK_OWNERS_FETCH_SUCCESS,
    TRUCK_OWNERS_FETCH,
    TRUCK_SAVE_FAIL,
    TRUCK_SAVE_SUCCESS,
    TRUCK_SAVE,
    TRUCKS_FETCH_FAIL,
    TRUCKS_FETCH_SUCCESS,
    TRUCKS_FETCH,
    TRUCKS_RESET_SELECTED_TRUCK_OWNER,
    TRUCKS_RESET_SELECTED_TRUCK,
    TRUCKS_SET_SELECTED_TRUCK_ID,
    TRUCKS_SET_SELECTED_TRUCK_OWNER,
    TRUCKS_SET_TRUCK_IDS
} = actions

const initState = new Map({
    item: undefined,
    items: new List(),
    loading: false,
    selectedTruckId: undefined,
    selectedTruckOwner: undefined,
    truckOwnerBankAccountForm: new Map({
        error: new Map(),
        bankAccountId: null,
        ownerId: null,
        saving: false
    }),
    truckForm: new Map({
        error: new Map(),
        truckId: null,
        ownerId: null,
        saving: false
    }),
    truckOwnerForm: new Map({
        error: new Map(),
        ownerId: null,
        saving: false
    }),
    truckOwnerStaffForm: new Map({
        error: new Map(),
        ownerId: null,
        saving: false
    }),
    truckOwnerTable: new Map({
        items: new List(),
        loading: false,
        ownerType: 'personal',
        pagination: new Map({
            current: 1,
            pageSize: 20,
            position: 'both',
            total: 1
        }),
        query: '',
        visibleModal: null
    })
})

export default function truckReducers (state = initState, action) {
    const result = action.result
    const items = state.get('items')

    switch (action.type) {
        case TRUCK_OWNERS_FETCH_FAIL:
            return state.mergeDeep({ truckOwnerTable: { loading: false } })
        case TRUCK_OWNERS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                truckOwnerTable: {
                    items: result,
                    loading: false,
                    pagination: action.pagination,
                    query: action.query
                }
            })
        case TRUCK_OWNERS_FETCH:
            return state.mergeDeep({ truckOwnerTable: { loading: true } })
        case TRUCK_OWNER_SAVE_FAIL:
            return state.mergeDeep({
                truckOwnerForm: {
                    error: action.error,
                    saving: false
                }
            })
        case TRUCK_OWNER_SAVE_SUCCESS:
            const tableItems = state.getIn(['truckOwnerTable', 'items'])

            return state.mergeDeep({
                truckOwnerForm: {
                    error: {},
                    saving: false
                },
                truckOwnerTable: {
                    items: tableItems.includes(result) ? tableItems : new List([result]).concat(tableItems)
                }
            })
        case TRUCK_OWNER_SAVE:
            return state.mergeDeep({ truckOwnerForm: { saving: true } })
        case TRUCK_OWNER_STAFF_SAVE_FAIL:
            return mergeDeepReplaceArrays(state, {
                truckOwnerStaffForm: {
                    saving: false,
                    error: action.error
                }
            })
        case TRUCK_OWNER_STAFF_SAVE_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                truckOwnerStaffForm: {
                    saving: false,
                    error: {}
                }
            })
        case TRUCK_OWNER_STAFF_SAVING:
            return state.mergeDeep({ truckOwnerStaffForm: { saving: true } })
        case TRUCK_OWNER_FETCH_SUCCESS:
            const truckOwnerItems = state.getIn(['truckOwnerTable', 'items'])
            return mergeDeepReplaceArrays(state, {
                truckOwnerTable: {
                    items: truckOwnerItems.includes(result) ? truckOwnerItems : truckOwnerItems.push(result),
                    loading: false
                }
            })
        case TRUCK_OWNER_FETCH:
            return state.mergeDeep({ truckOwnerTable: { loading: true } })
        case TRUCK_OWNER_FETCH_FAIL:
            return state.mergeDeep({ truckOwnerTable: { loading: false } })
        case TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL:
            return state.mergeDeep({
                truckOwnerBankAccountForm: {
                    error: action.error,
                    saving: false
                }
            })
        case TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS:
            return state.mergeDeep({
                truckOwnerBankAccountForm: {
                    error: {},
                    saving: false
                }
            })
        case TRUCK_OWNER_BANK_ACCOUNT_SAVE:
            return state.mergeDeep({ truckOwnerBankAccountForm: { saving: true } })
        case TRUCK_OWNER_BANK_ACCOUNT_FORM_SET_BANK_ACCOUNT_ID:
            return state.mergeDeep({
                truckOwnerBankAccountForm: {
                    bankAccountId: action.bankAccountId,
                    ownerId: action.ownerId
                }
            })
        case TRUCK_FORM_SET_TRUCK_ID:
            return state.mergeDeep({
                truckForm: {
                    truckId: action.truckId,
                    ownerId: action.ownerId
                }
            })
        case TRUCK_OWNER_BANK_ACCOUNT_FORM_RESET_ERROR:
            return state.setIn(['truckOwnerBankAccountForm', 'error'], new Map())
        case TRUCK_OWNER_FORM_RESET_ERROR:
            return state.setIn(['truckOwnerForm', 'error'], new Map())
        case TRUCK_OWNER_FORM_SET_OWNER_ID:
            return state.mergeDeep({ truckOwnerForm: { ownerId: action.ownerId } })
        case TRUCK_OWNER_STAFF_SET_FORM:
            return state.merge({
                truckOwnerStaffForm: { ownerId: action.truckOwnerStaffForm.ownerId }
            })
        case TRUCK_OWNER_TABLE_SET_OWNER_TYPE:
            return state.mergeDeep({ truckOwnerTable: { ownerType: action.ownerType } })
        case TRUCK_OWNER_TABLE_SET_VISIBLE_MODAL:
            return state.mergeDeep({ truckOwnerTable: { visibleModal: action.visibleModal } })
        case TRUCK_FETCH_FAIL:
            return state.merge({ loading: false })
        case TRUCK_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? items : items.concat(result)
            })
        case TRUCK_FETCH:
            return state.merge({ loading: true })
        case TRUCK_FORM_RESET_ERROR:
            return state.setIn(['truckForm', 'error'], action.error)
        case TRUCK_SAVE_FAIL:
            return state.mergeDeep({
                truckForm: {
                    error: action.error,
                    saving: false
                }
            })
        case TRUCK_SAVE_SUCCESS:
            return state.mergeDeep({
                truckForm: {
                    error: {},
                    saving: false
                }
            })
        case TRUCK_SAVE:
            return state.mergeDeep({ truckForm: { saving: true } })
        case TRUCKS_FETCH_FAIL:
            return state.merge({ loading: false })
        case TRUCKS_FETCH_SUCCESS:
            return state.merge({ loading: false, items: result })
        case TRUCKS_FETCH:
            return state.merge({ loading: true })
        case TRUCKS_RESET_SELECTED_TRUCK_OWNER:
            return mergeDeepReplaceArrays(state, {
                selectedTruckOwner: action.truckOwner,
                truckOwnerTable: {
                    items: [action.truckOwner]
                }
            })
        case TRUCKS_RESET_SELECTED_TRUCK:
            return mergeDeepReplaceArrays(state, {
                selectedTruckId: action.truckId,
                items: action.truckIds
            })
        case TRUCKS_SET_SELECTED_TRUCK_ID:
            return state.merge({ selectedTruckId: action.truckId })
        case TRUCKS_SET_SELECTED_TRUCK_OWNER:
            return state.merge({ selectedTruckOwner: action.truckOwner })
        case TRUCKS_SET_TRUCK_IDS:
            return mergeDeepReplaceArrays(state, {
                items: action.truckIds
            })
        default:
            return state
    }
}
