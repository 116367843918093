const actions = {
    SUPPLIER_ORDER_REVIEWS_FETCH_REQUEST: 'SUPPLIER_ORDER_REVIEWS_FETCH_REQUEST',
    SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS: 'SUPPLIER_ORDER_REVIEWS_FETCH_SUCCESS',
    SUPPLIER_ORDER_REVIEWS_FETCH: 'SUPPLIER_ORDER_REVIEWS_FETCH',
    SUPPLIER_ORDER_REVIEWS_REMOVE_ITEM_IN_ITEMS: 'SUPPLIER_ORDER_REVIEWS_REMOVE_ITEM_IN_ITEMS',
    SUPPLIER_ORDER_REVIEWS_SET_PAGINATION: 'SUPPLIER_ORDER_REVIEWS_SET_PAGINATION',

    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL: 'SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_FAIL',
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_REQUEST: 'SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_REQUEST',
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS: 'SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_SUCCESS',
    SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING: 'SUPPLIER_ORDER_REVIEWS_ORDER_UPDATING',

    SET_PURCHASE_ORDER_FORM_MODAL: 'SET_PURCHASE_ORDER_FORM_MODAL',
    SET_SUPPLIER_ORDER_SORTER: 'SET_SUPPLIER_ORDER_SORTER',
    SET_SUPPLIER_ORDER_FORM_MODAL: 'SET_SUPPLIER_ORDER_FORM_MODAL',
    SET_SUPPLIER_ORDER_QUERY: 'SET_SUPPLIER_ORDER_QUERY',

    fetchSupplierOrderReviews: ({
        query = '',
        page = 1,
        orderState = '',
        purchaser = '',
        supplier = '',
        dateType = '',
        startDate = '',
        endDate = '',
        sorter = {},
        isChecked = false,
        isPoCompleted = false,
        isNotWorking = false,
        dueDate = ''
    } = {}) => {
        return {
            type: actions.SUPPLIER_ORDER_REVIEWS_FETCH_REQUEST,
            payload: {
                query,
                page,
                orderState,
                purchaser,
                supplier,
                dateType,
                startDate,
                endDate,
                sorter,
                isChecked,
                isPoCompleted,
                isNotWorking,
                dueDate
            }
        }
    },

    setPagination: (pagination) => ({
        type: actions.SUPPLIER_ORDER_REVIEWS_SET_PAGINATION, pagination
    }),

    setSupplierOrderFormModal: ({ orderId = null, supplierOrderId = null }) => ({
        type: actions.SET_SUPPLIER_ORDER_FORM_MODAL,
        orderId, supplierOrderId
    }),

    togglePOForm: ({ orderId = null, supplierOrderId = null, refCode = null }) => {
        return {
            type: actions.SET_PURCHASE_ORDER_FORM_MODAL,
            supplierOrderId, orderId, refCode
        }
    },
    setSorter: (sorter) => {
        return { type: actions.SET_SUPPLIER_ORDER_SORTER, sorter }
    },
    setQuery: (value) => {
        return { type: actions.SET_SUPPLIER_ORDER_QUERY, value }
    },

    updateOrder: ({ orderId = '', orderParams = {}, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.SUPPLIER_ORDER_REVIEWS_ORDER_UPDATE_REQUEST,
            payload: { orderId, orderParams, onSuccess, onError }
        }
    },

    removeItemInItems: ({ orderId = '' } = {}) => {
        return { type: actions.SUPPLIER_ORDER_REVIEWS_REMOVE_ITEM_IN_ITEMS, orderId }
    }
}

export default actions
