import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SupportContainer from '../components/SupportContainer'
import { Route, Switch } from 'react-router-dom'

const SupportPage = ({
	match
}) => {

  const path = match.url

  return (
    <Switch>
      <Route path={`${path}/`} component={SupportContainer} exact />
      <Route path={`${path}/:id`} component={SupportContainer} />
    </Switch>

  )
}

SupportPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

SupportPage.defaultProps = {}

export default SupportPage
