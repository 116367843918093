import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import actions from '../../../redux/customers/wallets/actions'
import notificationActions from '../../../redux/notifications/actions'
import CustomerWalletForm from '../../../components/customerWallets/CustomerWalletForm'
import { Spin } from 'antd'

const {
    saveOrderCustomerWallet,
    setCustomerWalletFormModal,
    resetCustomerWalletFormErrors,
    setShowAmountInput
} = actions
const { setNotifications } = notificationActions

class OrderCustomerWalletFormContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        customerWalletId: PropTypes.string,

        customerWalletEntities: ImmutablePropTypes.map.isRequired,
        customerWallets: ImmutablePropTypes.map.isRequired,
        resetCustomerWalletFormErrors: PropTypes.func.isRequired,
        saveOrderCustomerWallet: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        setShowAmountInput: PropTypes.func
    }

    static defaultProps = {
        onSuccess: () => {}
    }

    componentWillUnmount () {
        this.props.resetCustomerWalletFormErrors()
    }

    handleNoticeError = (error) => {
        const { setNotifications } = this.props
        const { name, errorMessage } = error.name

        setNotifications(`${name}`, `${errorMessage}`, 'error')
    }

    onSubmitCustomerWallet = ({ values }) => {
        const { orderId, saveOrderCustomerWallet, setNotifications, setCustomerWalletFormModal, customerWalletId } = this.props
        const date = values['date'] ? values['date'].format('YYYY-MM-DD') : null
        if (values['supplier_order_attributes']) {
            Object.assign(values['supplier_order_attributes'], {
                payment_type: 'fully_paid',
                transfer_date: date,
                grand_total: values['amount'],
                note: values['note'],
                supplier_order_type: 'transfer_back'
            })
        }
        delete values['order_id']

        saveOrderCustomerWallet({
            orderId,
            id: customerWalletId,
            params: { ...values, date },
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
                setCustomerWalletFormModal(false, null)
            },
            onError: (error) => this.handleNoticeError(error)
        })
    }

    render () {
        const { customerWalletEntities, customerWalletId, customerWallets, setShowAmountInput } = this.props
        const customerWallet = customerWalletEntities.get(customerWalletId) || new Map()
        const errors = customerWallets.get('errors')

        if (customerWallets.get('loading')) return <Spin />
        return (
            <CustomerWalletForm isEdit={true}
                isOrderForm={true}
                onSubmitCustomerWallet={this.onSubmitCustomerWallet}
                customerWallet={customerWallet}
                setShowAmountInput={setShowAmountInput}
                showAmountInput={customerWallets.get('showAmountInput')}
                formErrors={typeof errors === 'object' ? errors : new Map()} />
        )
    }
}

const mapStateToProps = (state) => ({
    customerWalletEntities: state.getIn(['Entities', 'customerWallets']),
    customerWallets: state.get('customerWallets')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        resetCustomerWalletFormErrors,
        saveOrderCustomerWallet,
        setCustomerWalletFormModal,
        setNotifications,
        setShowAmountInput
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCustomerWalletFormContainer)
