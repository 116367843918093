import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import { Table, Switch, Popconfirm, Divider, Modal } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'

import { withTranslation } from 'react-i18next'

import actions from '../../../redux/customers/wallets/actions'
import notificationActions from '../../../redux/notifications/actions'
import inquiryActions from '../../../redux/inquiry/actions'
import OrderCustomerWalletFormContainer from './OrderCustomerWalletFormContainer'

const {
  fetchOrderCustomerWallets,
  deleteOrderCustomerWallet,
  saveOrderCustomerWallet,
  setCustomerWalletFormModal
} = actions
const { setNotifications } = notificationActions
const { fetchInquiry } = inquiryActions

const OrderCustomerWalletsList = ({
    orderId
}) => {
    const { i18n } = useTranslation('inquiries')
    const dispatch = useDispatch()
    const walletsEntities = useSelector(state => state.getIn(['Entities', 'customerWallets']))
    const loading = useSelector(state => state.getIn(['customerWallets', 'loading']))
    const saving = useSelector(state => state.getIn(['customerWallets', 'saving']))
    const items = useSelector(state => state.getIn(['customerWallets', 'items'], new List()))
    const showWalletForm = useSelector(state => state.getIn(['customerWallets', 'showWalletForm']))
    const customerWalletId = useSelector(state => state.getIn(['customerWallets', 'walletIdSelected']))

    useEffect(() => {
        dispatch(fetchOrderCustomerWallets(orderId))
    }, [])

    const getWallets = () => {
        return items.map((id) => {
            return walletsEntities.get(`${id}`) || new Map()
        })
    }

    const onChangeApprove = ({ id, checked }) => {
      dispatch(saveOrderCustomerWallet({
        orderId,
          id,
          params: { is_approved: checked },
          onSuccess: () => {
            dispatch(setNotifications('success', 'saveSuccess', 'success'))
            dispatch(fetchInquiry(orderId))
          }
      }))
    }

    const handleCustomerWalletFormModal = (visible, id = null) => {
      dispatch(setCustomerWalletFormModal(visible, id))
    }

    const onWalletDelete = (id) => {
      dispatch(deleteOrderCustomerWallet({
        orderId,
        id,
        onSuccess: () => {
          dispatch(setNotifications('success', 'saveSuccess', 'success'))
          dispatch(fetchInquiry(orderId))
        }
      }))
    }

    const columns = [
        {
            title: i18n.t('customers/wallets:date'),
            dataIndex: 'date',
            render: (date) => date ? moment(date).locale(i18n.language).format('LL') : ''
        }, {
            title: i18n.t('customers/wallets:transactionType'),
            dataIndex: 'transaction_type',
            render: (transactionType) => (
              i18n.t(`customers/wallets:transactionTypes.${_.camelCase(transactionType)}`)
          )
        }, {
            title: i18n.t('customers/wallets:amount'),
            dataIndex: ['amount', 'display']
        }, {
          key: 'approve',
          dataIndex: 'is_approved',
          render: (isApproved, record) => (
              <>
                  <Switch
                      checked={isApproved}
                      disabled={isApproved}
                      onChange={(checked) => {
                          onChangeApprove({ id: record.id, checked })
                      }}
                      size="small"
                  />
                  <span style={{ marginLeft: '4px' }}>
                      {i18n.t('customers/wallets:approve')}
                  </span>
              </>
          )
      }, {
          key: 'actions',
          render: (record) => {
              return (
                <div>
                    <a onClick={() => handleCustomerWalletFormModal(true, record.id)}>
                      <EditOutlined />
                    </a>
                    <Divider type="vertical" />
                    <Popconfirm
                        title={i18n.t('customers/wallets:deleteConfirm')}
                        onConfirm={() => {
                            onWalletDelete(record.id)
                        }}
                    >
                        <a href="#"><DeleteOutlined /></a>
                    </Popconfirm>
                </div>
              )
          }
      }
    ]

    if (!loading && _.isEmpty(items.toJS())) return null

    return (
      <div>
        <h4>{i18n.t('customers/wallets:sumOfWallets')}</h4>
        <Table
          columns={columns}
          loading={loading || saving}
          dataSource={getWallets().toJS()}
          pagination={false}
          rowKey="id"
          size="small" />
              <Modal
                  destroyOnClose
                  footer={null}
                  onCancel={() => handleCustomerWalletFormModal(false)}
                  visible={showWalletForm}>
                    <OrderCustomerWalletFormContainer
                      orderId={orderId}
                      customerWalletId={customerWalletId}
                    />
              </Modal>
      </div>
    )
}

export default  withTranslation('customers/wallets')(OrderCustomerWalletsList)
