import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import UploadInvoiceTrackingImage from '../../components/Invoices/uploadInvoiceTrackingImage'

import actions from '../../redux/orders/businessAddresses/actions'
import notificationActions from '../../redux/notifications/actions'

const { saveOrderBusinessAddress } = actions
const { setNotifications } = notificationActions

class UploadInvoiceTrackingImageContainer extends Component {
    static propTypes = {
        // Props from another component
        orderBusinessAddressId: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,

        // Inner Props
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {}

    customRequest = (args) => {
        const { onSuccess, onError, file, onProgress } = args
        const {
            orderBusinessAddressId,
            orderId,
            saveOrderBusinessAddress,
            setNotifications
        } = this.props

        const params = {
            order_business_address: {
                tracking_image: file
            }
        }

        saveOrderBusinessAddress({
            orderId,
            id: orderBusinessAddressId,
            params,
            onSuccess: () => {
                onSuccess('Ok')
                setNotifications('success', 'success', 'success')
            },
            onError: () => {
                onError()
                setNotifications('error', 'error', 'error')
            },
            onUploadProgress: (e) => {
                onProgress({ percent: (e.loaded / e.total) * 100 })
            }
        })
    }

    render () {
        return (
            <UploadInvoiceTrackingImage
                customRequest={this.customRequest}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderBusinessAddress,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadInvoiceTrackingImageContainer)
