import styled from 'styled-components'

export default styled.div`
  .space-justify-content-between {
    width: 100%;
    justify-content: space-between;
  }

  .margin-top-8 { margin-top: 8px; }
`
