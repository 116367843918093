import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import TicketIndexPage from '../containers/Tickets'
import TicketPage from '../containers/Tickets/show'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const TicketsPage = ({ location, match }) => {
    const dispatch = useDispatch()
    const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
    const { pathname } = location

    useEffect(() => {
        if (current[0] !== 'tickets') {
            dispatch(changeCurrent(['tickets']))
        }
    }, [pathname])

    const path = match.url

    return (
        <Switch>
            <Route path={`${path}/`} component={TicketIndexPage} exact />
            <Route path={`${path}/:id`} component={TicketPage} exact />
        </Switch>
    )
}

TicketsPage.propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}

TicketsPage.defaultProps = {}

export default TicketsPage
