import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import { Table, Select, Divider } from 'antd'
import { StarOutlined } from '@ant-design/icons'

import { Link } from 'react-router-dom'

import { isEmpty } from '../../helpers/objects'
import orderReviewActions from '../../redux/orders/reviews/actions'
import orderTableActions from '../../redux/orders/tables/actions'

const { fetchOrderReviews, setPagination, setSale, setCs } = orderReviewActions
const { fetchSales, fetchCses } = orderTableActions

class OrderReviewsIndexContainer extends Component {
    componentDidMount () {
        const { fetchOrderReviews, fetchSales, fetchCses, location } = this.props

        const queryStringObject = this.parseParams(location.search)

        fetchOrderReviews(queryStringObject)
        fetchSales('all')
        fetchCses('all')
    }

    fetchOrderReviews = (queryString, page = 1) => {
        const { history, fetchOrderReviews } = this.props
        let arrQueryString = queryString.split('&')
        let objQuery = {}
        let queryShow = []
        const acceptQuery = ['by_sale', 'by_cs']
        arrQueryString.forEach((str) => {
            const arrString = str.split('=')
            if (acceptQuery.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, { [`${arrString[0]}`]: arrString[1] })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            }
        })

        const queries = queryShow.length ? '?' + queryShow.join('&') : ''
        history.push({
            search: queries,
            params: objQuery
        })

        fetchOrderReviews(queryString, page)
    }

    getQueryString (args = {}) {
        const { orderReviews } = this.props

        const onSaleFilter = ('by_sale' in args) ? args.by_sale : orderReviews.get('filterSale')
        const onCsFilter = ('by_cs' in args) ? args.by_cs : orderReviews.get('filterCs')

        const queries = [].concat(
            this.getFilterSale(onSaleFilter),
            this.getFilterCs(onCsFilter),
        )

        return queries.filter((query) => { return !isEmpty(query) }).map((query) => {
            return `${query.key}=${query.value}`
        }).join('&')
    }

    getFilterSale (onSaleFilter) {
        if (!onSaleFilter) { return {} }

        return { key: 'by_sale', value: onSaleFilter }
    }

    getFilterCs (onCsFilter) {
        if (!onCsFilter) { return {} }

        return { key: 'by_cs', value: onCsFilter }
    }

    handleTableChange = (pagination) => {
        const { setPagination, location } = this.props
        setPagination(pagination)

        const queryString = this.getQueryString({
            ...this.parseParams(location.search),
            pagination
        })

        this.fetchOrderReviews(queryString, pagination.current)
    }

    handleFilterBySale = (saleId) => {
        const { setPagination, location, orderReviews, setSale } = this.props
        const pagination = orderReviews.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...this.parseParams(location.search),
            pagination: { ...pagination, current: 1 },
            by_sale: saleId
        })
        setSale(saleId)

        this.fetchOrderReviews(queryString)
    }

    handleFilterByCs = (csId) => {
        const { setPagination, location, orderReviews, setCs } = this.props
        const pagination = orderReviews.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...this.parseParams(location.search),
            pagination: { ...pagination, current: 1 },
            by_cs: csId
        })
        setCs(csId)

        this.fetchOrderReviews(queryString)
    }

    getOrderReviews = () => {
        const { orderReviewsEntity, orderReviews } = this.props
        const items = orderReviews.get('items')
        return items.map((id) => {
            return orderReviewsEntity.get(id)
        })
    }

    parseParams = (params = '') => {
      if(!params) return {}
      const rawParams = params.replace('?', '').split('&')
      const extractedParams = {}
      rawParams.forEach((item) => {
          item = item.split('=');
          extractedParams[item[0]] = item[1]
      })
      return extractedParams
    }

    renderSaleFilterOptions = () => {
        const { orderTables, entities } = this.props
        const saleEntities = entities.get('sales')
        const saleItems = orderTables.get('saleItems')

        if (saleItems.size === 0) {return null}
        const saleOptions = saleItems.map((saleId) => {
            const sale = saleEntities.get(saleId)
            return (
                {
                    key: sale.get('name'),
                    value: sale.get('id')
                }
            )
        })

        return saleOptions.map((saleOption) => {
            return (
                <Select.Option key={saleOption.key} value={saleOption.value}>
                    {saleOption.key}
                </Select.Option>
            )
        })
    }

    renderCsFilterOptions = () => {
        const { orderTables, entities } = this.props
        const csEntities = entities.get('customerServices')
        const csItems = orderTables.get('csItems')

        if (csItems.size === 0) {return null}
        const csOptions = csItems.map((csId) => {
            const cs = csEntities.get(csId)
            return (
                {
                    key: cs.get('name'),
                    value: cs.get('id')
                }
            )
        })

        return csOptions.map((csOption) => {
            return (
                <Select.Option key={csOption.key} value={csOption.value}>
                    {csOption.key}
                </Select.Option>
            )
        })
    }

    renderRatingStars = (length, score) => {
        return Array.from({ length }, (value, index) => index).map((index) => {
            return (
                <StarOutlined style={{ color: score >= index + 1 ? '#fec309' : '#e1e1e1' }}/>
            )
        })
    }

    render () {
        const { i18n, orderReviews, location } = this.props

        const columns = [
            {
                title: i18n.t('orders:orderReview.orderNumber'),
                dataIndex: 'order_number',
                render: (orderNumber) => {
                    return (
                        <Link to={`/inquiries/${orderNumber}`}>
                            {orderNumber}
                        </Link>
                    )
                }
            },
            {
                title: i18n.t('orders:orderReview.reviewType'),
                render: (row) => {
                    return (
                        <div>
                            <div>{row.review_type}</div>
                            <div style={{ display: 'flex' }}>
                                {this.renderRatingStars(5, row.rating_score)}
                            </div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderReview.staff'),
                render: (row) => {
                    return (
                        <div>
                            <div>{i18n.t('orders:orderReview.sale')} : {row.sale_name}</div>
                            <div>{i18n.t('orders:orderReview.cs')} : {row.cs_name}</div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orders:orderReview.comment'),
                dataIndex: 'body'
            }
        ]

        const cs = new URLSearchParams(location.search).get('by_cs')
        const sale = new URLSearchParams(location.search).get('by_sale')

        return (
            <div>
                <span style={{ margin: '5px' }}>
                    <span style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orders/tables:filterBySale')}
                        </span>
                        <Select style={{ width: '150px' }} allowClear
                            defaultValue={sale || undefined}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            onChange={(value) => this.handleFilterBySale(value)}
                            placeholder={i18n.t('orders/tables:filterWithSale')}>
                            {this.renderSaleFilterOptions()}
                        </Select>
                    </span>
                </span>
                <span style={{ margin: '5px' }}>
                    <span style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orders/tables:filterByCs')}
                        </span>
                        <Select style={{ width: '150px' }} allowClear
                            defaultValue={cs || undefined}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            onChange={(value) => this.handleFilterByCs(value)}
                            placeholder={i18n.t('orders/tables:filterWithCs')}>
                            {this.renderCsFilterOptions()}
                        </Select>
                    </span>
                </span>
                <Divider />
                <Table columns={columns}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    rowKey={orderReview => orderReview.id}
                    loading={orderReviews.get('loading')}
                    dataSource={this.getOrderReviews().toJS()}
                    onChange={this.handleTableChange}
                    pagination={{
                        ...orderReviews.get('pagination').toJS(),
                        showSizeChanger: false
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orderReviewsEntity: state.getIn(['Entities', 'orderReviews']),
    entities: state.get('Entities'),
    orderReviews: state.get('orderReviews'),
    orderTables: state.get('orderTables')
})

const mapDispatchToprops = (dispatch) => {
    return bindActionCreators({
        fetchOrderReviews,
        fetchSales,
        fetchCses,
        setPagination,
        setSale,
        setCs
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToprops)(
    withTranslation(['orders', 'orders/tables'])(withRouter(OrderReviewsIndexContainer))
)
