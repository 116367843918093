import styled from 'styled-components'

export default styled.div`
  .description {
    display: flex;
  }

  .ml-auto {
    margin-left: auto;
  }
  
  .text-align-right {
    text-align: right;
  }

  .order-container {
    width: calc(100% - 344px)
  }

  .ant-card-actions {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    width: 100%;
    a {
      color: #1890ff;
    }
  }
`
