import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import ItemTruckImages from '../../components/lineItems/itemTruckImages'

import itemTruckImageActions from '../../redux/items/truckImages/actions'
import notificationActions from '../../redux/notifications/actions'

const { removeItemTruckImage } = itemTruckImageActions
const { setNotifications } = notificationActions

class LineItemItemTruckImagesContainer extends Component {
    static propTypes = {
        // Props from another component
        lineItem: PropTypes.object,

        // Inner Props
        itemTruckImageEntities: ImmutablePropTypes.map.isRequired,
        itemTruckImages: ImmutablePropTypes.map.isRequired,
        removeItemTruckImage: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {
        lineItem: {}
    }

    getItemTruckImage (id) {
        if (!id) return new Map()

        const { itemTruckImageEntities } = this.props
        return itemTruckImageEntities.get(_.toString(id), new Map())
    }

    getItemTruckImages () {
        const { itemTruckImages } = this.props
        return itemTruckImages.get('items', new List()).map((id) => {
            return this.getItemTruckImage(id)
        })
    }

    getLineItemItemTruckImages () {
        const { lineItem } = this.props

        return this.getItemTruckImages().filter((truckImage) => {
            return _.toString(truckImage.get('line_item_id')) === _.toString(_.get(lineItem, 'id', null))
        })
    }

    onRemoveItemTruckImage = (itemTruckImage) => {
        if (!itemTruckImage) return null
        const { removeItemTruckImage, setNotifications } = this.props

        removeItemTruckImage({
            itemId: _.get(itemTruckImage, 'item_id', ''),
            id: _.get(itemTruckImage, 'id', ''),
            onSuccess: () => { setNotifications('success', 'success', 'success') },
            onError: () => { setNotifications('error', 'error', 'error') }
        })
    }

    render () {
        const { itemTruckImages } = this.props

        return (
            <ItemTruckImages
                itemTruckImages={this.getLineItemItemTruckImages().toJS()}
                loading={itemTruckImages.get('loading', false)}
                onRemoveItemTruckImage={this.onRemoveItemTruckImage}
                saving={itemTruckImages.get('saving', false)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        itemTruckImageEntities: state.getIn(['Entities', 'itemTruckImages']),
        itemTruckImages: state.get('itemTruckImages')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        removeItemTruckImage,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LineItemItemTruckImagesContainer)
