import styled from 'styled-components'
const ProjectWrapper = styled.div`
  .projects-index-header {
    margin-bottom: 12px;
    text-align: right;
  }

  .project-show-header {
    padding: 0;

    .extra-content {
      text-align: right;
    }

    .ant-page-header-footer {
      margin: 0;
    }
  }

  .add-customer-section {
    text-align: right;
    margin-bottom: 12px;
  }

  .project-form-action-btn {
    .ant-form-item-children {
      .ant-btn {
        margin: 0 4px;
      }
    }
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
  }

  .text-danger {
    color: #F5222D;
  }
`

export default ProjectWrapper
