import { Map, List } from 'immutable'

import actions from './actions'

const {
    CHART_OF_ACCOUNTS_FETCH,
    CHART_OF_ACCOUNTS_FETCH_SUCCESS,
    CHART_OF_ACCOUNTS_SET_PAGINATION,
    CHART_OF_ACCOUNTS_SAVING,
    CHART_OF_ACCOUNTS_SET_FORM_ERRORS,
    CHART_OF_ACCOUNTS_SAVE_SUCCESS,
    CHART_OF_ACCOUNTS_SET_FORM_MODAL,
    CHART_OF_ACCOUNT_DELETE,
    CHART_OF_ACCOUNT_DELETE_SUCCESS,
    CHART_OF_ACCOUNT_FETCH,
    CHART_OF_ACCOUNT_FETCH_SUCCESS,
    CHART_OF_ACCOUNT_TYPES_FETCHING,
    CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    }),
    errors: new Map(),
    chartOfAccountSelected: null,
    showChartOfAccountForm: false,
    chartOfAccountTypes: []
})

export default function chartOfAccountsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CHART_OF_ACCOUNTS_FETCH:
            return state.merge({ loading: true })
        case CHART_OF_ACCOUNTS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CHART_OF_ACCOUNTS_SET_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        case CHART_OF_ACCOUNTS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case CHART_OF_ACCOUNTS_SAVING:
            return state.merge({ saving: action.saving })
        case CHART_OF_ACCOUNTS_SAVE_SUCCESS:
            return state.merge({
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case CHART_OF_ACCOUNTS_SET_FORM_MODAL:
            return state.merge({
                showChartOfAccountForm: action.visible,
                chartOfAccountSelected: action.selected
            })
        case CHART_OF_ACCOUNT_DELETE:
            return state.merge({ loading: true })
        case CHART_OF_ACCOUNT_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.delete(items.indexOf(result)))
            })
        case CHART_OF_ACCOUNT_FETCH:
            return state.merge({
                loading: true
            })
        case CHART_OF_ACCOUNT_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case CHART_OF_ACCOUNT_TYPES_FETCHING:
            return state.merge({
                typeLoading: true
            })
        case CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS:
            return state.merge({
                chartOfAccountTypes: action.chartOfAccountTypes,
                typeLoading: false
            })
        default:
            return state
    }
}
