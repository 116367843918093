import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import CustomerIndexPage from '../containers/Customers'
import CustomerNewPage from '../containers/Customers/new'

import CustomerShowPage from '../containers/Customers/show'
import CustomerEditPage from '../containers/Customers/edit'
import CustomerNewOrderPage from '../containers/Customers/Orders/new'

import CustomerNewShippingAddressPage from '../containers/Customers/ShippingAddresses/new'
import CustomerEditShippingAddressPage from '../containers/Customers/ShippingAddresses/edit'

import CustomerNewBusinessAddressPage from '../containers/Customers/BusinessAddresses/new'
import CustomerEditBusinessAddressPage from '../containers/Customers/BusinessAddresses/edit'

import CustomerNewMailingAddressPage from '../containers/Customers/MailingAddresses/new'
import CustomerEditMailingAddressPage from '../containers/Customers/MailingAddresses/edit'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const CustomersPage = ({
	location,
	match
}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'customers') {
			dispatch(changeCurrent(['customers']))
		}
	}, [pathname])

	const path = match.url
	const shippingAddressesPath = `${path}/:customer_id/shipping_addresses`
	const businessAddressesPath = `${path}/:customer_id/business_addresses`
	const mailingAddressesPath = `${path}/:customer_id/mailing_addresses`

	return (
		<Switch>
			<Route path={`${path}`} component={CustomerIndexPage} exact />
			<Route path={`${path}/new`} component={CustomerNewPage} />

			<Route path={`${path}/:id`} component={CustomerShowPage} exact />
			<Route path={`${path}/:id/edit`} component={CustomerEditPage} />
			<Route path={`${path}/:id/orders/new`} component={CustomerNewOrderPage} />

			<Route path={`${shippingAddressesPath}/new`} component={CustomerNewShippingAddressPage} />
			<Route path={`${shippingAddressesPath}/:id/edit`} component={CustomerEditShippingAddressPage} />

			<Route path={`${businessAddressesPath}/new`} component={CustomerNewBusinessAddressPage} />
			<Route path={`${businessAddressesPath}/:id/edit`} component={CustomerEditBusinessAddressPage} />

			<Route path={`${mailingAddressesPath}/new`} component={CustomerNewMailingAddressPage} />
			<Route path={`${mailingAddressesPath}/:id/edit`} component={CustomerEditMailingAddressPage} />
		</Switch>
	)
}

CustomersPage.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

CustomersPage.defaultProps = {}

export default CustomersPage
