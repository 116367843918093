import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import { Spin, Alert } from 'antd'
import DelivereeFormRender from './render'

const GET_DELIVERY_QUOTE_MUTATION = gql`
    mutation GetDeliveryQuote($orderNumber: String!, $deliveryParams: AdminDeliveryInput!){
        getDeliveryQuote(orderNumber: $orderNumber, deliveryParams: $deliveryParams) {
          data
          errors {
            message
            key
          }
        }
    }
`

const ORDER_QUERY = gql`
    query Order($number: String!) {
      order(number: $number) {
            id
            number
            orderShippingAddress {
              fullAddress
            }
        }
    }
`

const DelivereeForm = ({
  orderId,
  onToggleForm,
  fetchOrder
}) => {
  const [errorsMessage, setErrors] = useState(null)
  const [deliveryQuoteData, setDeliveryQuoteData] = useState(null)
  const { loading, data } = useQuery(ORDER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { number: orderId }
  })
  const [getDeliveryQuote, { loading: saving }] = useMutation(GET_DELIVERY_QUOTE_MUTATION, {
    onCompleted: ({ getDeliveryQuote }) => {
      if (getDeliveryQuote.errors) {
        setErrors(getDeliveryQuote.errors.message)
      } else {
        setDeliveryQuoteData(JSON.parse(getDeliveryQuote.data))
        setErrors(null)
        onToggleForm()
        fetchOrder()
      }
    }
  })
  const getInitalValues = () => {
    const orderData = data && data.order
    const { orderShippingAddress } = orderData

    return {
      locations: [
        { address: orderShippingAddress ? orderShippingAddress.fullAddress : null }
      ]
    }
  }

  const handleSubmit = (params) => {
    getDeliveryQuote({
      variables: {
        deliveryParams: params,
        orderNumber: orderId
      }
    })
  }

  const renderDeliveryQuoteInfo = () => {
    return <Alert type="success" showIcon
      message="Success"
      description={<pre>{JSON.stringify(deliveryQuoteData, null, 2)}</pre>} />
  }

  if (loading) return <Spin />

  return (
    <div>
      <DelivereeFormRender initialValues={getInitalValues()}
          onCancel={onToggleForm}
          errorsMessage={errorsMessage}
          saving={saving}
          handleSubmit={handleSubmit}
        />
        { deliveryQuoteData ?
          <div style={{ marginTop: '12px' }}>
            {renderDeliveryQuoteInfo()}
          </div>
        : null }
    </div>
  )
}

DelivereeForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  onToggleForm: PropTypes.func,
  fetchOrder: PropTypes.func
}

DelivereeForm.defaultProps = {
  fetchOrder: () => {}
}

export default DelivereeForm
