import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import StaffIndexPage from '../containers/Staffs'
import StaffNewPage from '../containers/Staffs/new'
import StaffShowPage from '../containers/Staffs/show'
import StaffEditPage from '../containers/Staffs/edit'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const StaffsPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'staffs') {
			dispatch(changeCurrent(['staffs']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={StaffIndexPage} exact />
      <Route path={`${path}/new`} component={StaffNewPage} />

      <Route path={`${path}/:id`} component={StaffShowPage} exact />
      <Route path={`${path}/:id/edit`} component={StaffEditPage} />
		</Switch>
  )
}

StaffsPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

StaffsPage.defaultProps = {}

export default StaffsPage
