import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../constants/customers'

const {
    PROJECTS_FETCH_COMPANY_PROJECTS,
    PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS,
    PROJECTS_SET_COMPANY_PROJECT_PAGINATION,
    PROJECTS_SAVE_COMPANY_PROJECT,
    PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS,
    PROJECTS_FETCH_COMPANY_PROJECT,
    PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS,
    PROJECTS_SET_COMPANY_PROJECT_FORM_MODAL,
    PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS,
    PROJECTS_RESET_COMPANY_PROJECT_FORM_ERRORS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showCompanyProjectForm: false,
    projectIdSelected: null,
    errors: new Map()
})

export default function projectsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case PROJECTS_FETCH_COMPANY_PROJECTS:
            return state.merge({ loading: true })
        case PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case PROJECTS_SET_COMPANY_PROJECT_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case PROJECTS_SAVE_COMPANY_PROJECT:
            return state.merge({ saving: action.saving })
        case PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS:
            return state.merge({
                items: items.includes(result) ? new List(items) : new List(items.push(result))
            })
        case PROJECTS_FETCH_COMPANY_PROJECT:
            return state.merge({
                loading: action.loading
            })
        case PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? new List(items) : new List(items.push(result))
            })
        case PROJECTS_SET_COMPANY_PROJECT_FORM_MODAL:
            return state.merge({
                showCompanyProjectForm: action.visible,
                projectIdSelected: action.id
            })
        case PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS:
            return state.merge({
                errors: new Map(action.formErrors)
            })
        case PROJECTS_RESET_COMPANY_PROJECT_FORM_ERRORS:
            return state.merge({
                errors: new Map()
            })
        default:
            return state
    }
}
