const actions = {
    ORDER_REVIWES_FETCH_REQUEST: 'ORDER_REVIWES_FETCH_REQUEST',
    ORDER_REVIWES_FETCH: 'ORDER_REVIWES_FETCH',
    ORDER_REVIWES_FETCH_SUCCESS: 'ORDER_REVIWES_FETCH_SUCCESS',
    ORDER_REVIEWS_SET_PAGINATION: 'ORDER_REVIEWS_SET_PAGINATION',
    ORDER_REVIEWS_SET_SALE: 'ORDER_REVIEWS_SET_SALE',
    ORDER_REVIEWS_SET_CS: 'ORDER_REVIEWS_SET_CS',

    fetchOrderReviews: (queryString, page = 1) => ({
        type: actions.ORDER_REVIWES_FETCH_REQUEST,
        payload: { queryString, page }
    }),

    setPagination: (pagination) => {
        return { type: actions.ORDER_REVIEWS_SET_PAGINATION, pagination }
    },

    setSale: (filterSale) => ({
        type: actions.ORDER_REVIEWS_SET_SALE,
        filterSale
    }),

    setCs: (filterCs) => ({
        type: actions.ORDER_REVIEWS_SET_CS,
        filterCs
    })

}

export default actions
