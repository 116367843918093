import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import ItemPrices from '../../../components/inquiry/itemPrices'

import { safeGet } from '../../../helpers/string'

const ITEM_PRICES_QUERY = gql`
    query ItemPrices ($itemId: String!, $first: Int, $after: String) {
        item (itemId: $itemId) {
            id
            name
            supplierItemCounterCaches (limit: 3) {
                id
                displayCount
                displayTotalPrice
                supplier {
                    id
                    name
                    phoneNumber
                    note
                }
            }
            onlyPaidLineItemConnections (first: $first, after: $after) {
                nodes {
                    id
                    singlePrice
                    quantity
                    cost
                    order {
                        id
                        number
                    }
                    suppliers {
                        id
                        name
                    }
                    supplierLineItems {
                        id
                        purchaseDate {
                            display
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`

const LIMIT = 10

const ItemPricesContainer = ({
    itemId
}) => {
    const [loadMore, setLoadMore] = useState(false)
    const { loading, data, error, fetchMore } = useQuery(ITEM_PRICES_QUERY, {
        skip: !itemId,
        variables: { itemId, first: LIMIT }
    })

    if (error) {  console.error(error) }

    const onFetchMore = (pageInfo, prevLineItems) => {
        if (loadMore || !pageInfo.hasNextPage) { return null }

        setLoadMore(true)
        fetchMore({
            variables: { itemId, first: LIMIT, after: pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }) => {
                setLoadMore(false)
                if (!fetchMoreResult) { return prev }

                const result = fetchMoreResult.item

                return {
                    item: {
                        ...result,
                        onlyPaidLineItemConnections: {
                            nodes: [...prevLineItems, ...(result.onlyPaidLineItemConnections.nodes || [])],
                            pageInfo: result.onlyPaidLineItemConnections.pageInfo
                        }
                    }
                }
            }
        })
    }

    const item = safeGet(data, 'item', {})

    return (
        <ItemPrices
            itemName={item.name}
            lineItems={safeGet(item, 'onlyPaidLineItemConnections.nodes', [])}
            loading={loading}
            loadMore={loadMore}
            onFetchMore={onFetchMore}
            pageInfo={safeGet(item, 'onlyPaidLineItemConnections.pageInfo', {})}
            suppliers={safeGet(item, 'supplierItemCounterCaches', [])}
        />
    )
}

ItemPricesContainer.propTypes = {
    itemId: PropTypes.string
}

ItemPricesContainer.defaultProps = {}

export default ItemPricesContainer
