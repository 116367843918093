import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { base64StringToBlob } from 'blob-util'

import ReactQuill, { Quill } from 'react-quill'
import QuillImageDropAndPaste from './QuillImageDropAndPaste'
import EditorThemeSnowWrapper from './editorThemeSnow.style'
import EditorThemeBubbleWrapper from './editorThemeBubble.style'

class EditorContainer extends Component {
  static propTypes = {
      addDefaultFileList: PropTypes.func,
      imageHandler: PropTypes.func,
      isMinHeight100: PropTypes.bool,
      placeholder: PropTypes.string,
      theme: PropTypes.string.isRequired,
      toolbarEditor: PropTypes.array
  }

  static defaultProps = {
      isMinHeight100: false,
      placeholder: '',
      insertImage: false,
      // you can choose the theme as "snow" or "bubble"
      theme: 'snow',
      toolbarEditor: [
          'header', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'video', 'image'
      ],
      showToolbar: true
  }

  constructor (props) {
      super(props)
      if (typeof window !== 'undefined') {
          Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
      }
  }

  moduleEditor = () => {
      const toolBar = this.props.showToolbar ? {
          container: [
              [{ header: '1' }, { header: '2' }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              ['link', 'video'],
              ['clean']
          ] } : false

      return ({
          imageDropAndPaste: {
              handler: this.imageHandler
          },
          toolbar: toolBar,
          clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false
          }
      })
  }

  handleReceiveMessages = (messages) => {
      const { onChange } = this.props

      if (onChange) {
          onChange(messages)
      }
  }

  imageHandler = (imgBase64, type) => {
      const { imageHandler, insertImage } = this.props

      if (!imageHandler) { return null }
      if (!type) type = 'image/png'

      // base64 to blob
      var blob = base64StringToBlob(imgBase64.replace(/^data:image\/\w+;base64,/, ''), type)
      var filename = ['MyImages', '-', Math.floor(Math.random() * 1e12), '-', new Date().getTime(), '.', type.match(/^image\/(\w+)$/i)[1]].join('')
      const file = new File([blob], filename, { type, lastModified: Date.now() })

      // generate a form data
      imageHandler(file, imgBase64, (url) => {
          if (!insertImage) return null

          const editor = this.reactQuillRef.getEditor()
          const range = editor.getSelection()

          if (url) {
            editor.insertEmbed(range.index, 'image', url, Quill.sources.USER)
          }
      })
  }

  render () {
      const { isMinHeight100, theme, toolbarEditor, placeholder, value } = this.props
      let Theme

      if (theme === 'snow') {
          Theme = EditorThemeSnowWrapper
      } else if (theme === 'bubble') {
          Theme = EditorThemeBubbleWrapper
      }

      if (typeof window !== 'undefined' && ReactQuill) {
          return (
              <Theme>
                  <ReactQuill theme={theme}
                      modules={this.moduleEditor()}
                      formats={toolbarEditor}
                      onChange={this.handleReceiveMessages}
                      value={value || ''}
                      placeholder={placeholder}
                      className={(isMinHeight100 && 'min-height-100')  || ''}
                      ref={(el) => { this.reactQuillRef = el }}
                  />
              </Theme>
          )
      } else {
          return <textarea />
      }
  }
}

export default EditorContainer
