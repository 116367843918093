import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_NOTE_IMAGES_FETCH,
    ORDER_NOTE_IMAGES_FETCH_SUCCESS,
    ORDER_NOTE_IMAGE_SET_SAVING,
    ORDER_NOTE_IMAGE_SAVE_SUCCESS,
    ORDER_NOTE_IMAGE_DELETE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false
})

export default function orderNoteImagesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case ORDER_NOTE_IMAGES_FETCH:
            return state.merge({
                loading: true
            })
        case ORDER_NOTE_IMAGES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case ORDER_NOTE_IMAGE_SAVE_SUCCESS:
            return state.merge({
                items: new List(items.unshift(result))
            })
        case ORDER_NOTE_IMAGE_DELETE_SUCCESS:
            return state.merge({
                items: new List(items.delete(items.indexOf(result)))
            })
        case ORDER_NOTE_IMAGE_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        default:
            return state
    }
}
