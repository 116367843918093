import { all, takeEvery, put, call, fork, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'
import { occupationSchema, occupationsSchema } from '../../schema'
import { deserialize } from '../../../helpers/jsonApi'

import actionsCustomer from '../../../redux/customers/actions'

const { setNotifications } = actionsNotification

const { setOccupationModal } = actionsCustomer

const {
    OCCUPATIONS_FETCH,
    OCCUPATIONS_FETCH_REQUEST,
    OCCUPATIONS_FETCH_SUCCESS,
    OCCUPATIONS_SAVE,
    OCCUPATIONS_SAVE_SUCCESS,
    OCCUPATIONS_SAVE_REQUEST,
    OCCUPATIONS_SET_FORM_ERRORS
} = actions

const apiPath = (id) => {
    const basePath = '/occupations'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *submitOccupation () {
    yield takeLatest(OCCUPATIONS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: OCCUPATIONS_SAVE })

        const id = payload.occupation.id
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify(payload.occupation)

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: OCCUPATIONS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: OCCUPATIONS_SAVE_SUCCESS,
                    ...normalize(formattedData, occupationSchema)
                })

                yield put(setOccupationModal(false))

                yield put({
                    type: OCCUPATIONS_FETCH_REQUEST
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: OCCUPATIONS_SAVE,
                saving: false
            })
        }
    })
}

export function *OccupationsRequest () {
    yield takeEvery(OCCUPATIONS_FETCH_REQUEST, function *() {
        yield put({ type: OCCUPATIONS_FETCH })
        const path = apiPath() + `?per=50`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, occupationsSchema)

        yield put({
            type: OCCUPATIONS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(submitOccupation),
        fork(OccupationsRequest)
    ])
}
