import React, { Component } from 'react'
import salesContainer from './SalesContainer'
import exportSalesReportComponent from '../../components/sales/ExportSalesReport'

const ExportSalesReportRenderer = salesContainer(exportSalesReportComponent)

class ExportSalesReport extends Component {
    render () {
        return (
            <ExportSalesReportRenderer />
        )
    }
}

export default ExportSalesReport
