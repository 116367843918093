import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Input, Form, Card, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import orderActions from '../../../redux/orders/actions'

const { saveOrderCompleteNote } = orderActions

class CompleteNoteForm extends Component {
    constructor (props) {
        super(props)

        this.state = {
            editable: false
        }
    }

    handleShowForm = (visible) => {
        this.setState({ editable: visible })
    }

    handleSubmit = (values) => {
        const { orderId, saveOrderCompleteNote } = this.props
        saveOrderCompleteNote({
            orderId,
            values,
            onSuccess: () => {
                this.handleShowForm(false)
            }
        })
    }

    renderCompleteNote (completeNote) {
        const { i18n } = this.props
        return (
            <div style={{ padding: '8px' }}>
                <div>
                    <span style={{ marginRight: '4px' }}>{i18n.t('confirms:accounting.completeNote')}</span>
                    <Link onClick={() => this.handleShowForm(true)}><EditOutlined /></Link>
                </div>
                <div>{completeNote}</div>
            </div>
        )
    }

    renderForm (completeNote) {
        const { orders, i18n } = this.props

        return (
            <Card>
                <Form onFinish={this.handleSubmit}>
                    <Form.Item label={i18n.t('confirms:accounting.completeNote')}
                        name={['complete_note_attributes', 'text']}
                        initialValue={completeNote}>
                            <Input.TextArea />
                    </Form.Item>

                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}
                            loading={orders.get('orderCompleteNoteSaving')}>
                            {i18n.t('shared:save')}
                        </Button>
                        <Button onClick={() => this.handleShowForm(false)}>
                            {i18n.t('shared:close')}
                        </Button>
                    </div>
                </Form>
            </Card>
        )
    }

    render () {
        const { completeNoteId, orderCompleteNotes } = this.props
        const { editable } = this.state
        const completeNote = completeNoteId && orderCompleteNotes.getIn([completeNoteId, 'text']) || null

        return editable ? this.renderForm(completeNote) : this.renderCompleteNote(completeNote)
    }
}

const mapStateToProps = state => ({
    orderCompleteNotes: state.getIn(['Entities', 'orderCompleteNotes']),
    orders: state.get('orders')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderCompleteNote
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['shared', 'confirms'])(CompleteNoteForm)
)
