import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Table, Input } from 'antd'
import { MailOutlined, MessageOutlined, EditTwoTone } from '@ant-design/icons'

import companyActions from '../../redux/customers/companies/actions'
import CompanyFormContainer from '../Customers/Companies/CompanyFormContainer'
import { isMobile } from "react-device-detect"

const {
    fetchCompanies,
    setAddCompanyModal
} = companyActions

class CompaniesIndexContainer extends Component {
    componentDidMount () {
        const { fetchCompanies } = this.props
        fetchCompanies()
    }

    fetchCompanies (query) {
        const { fetchCompanies } = this.props
        fetchCompanies(query)
    }

    searchCompanies () {
        const { Search } = Input
        return (
            <Search
                style={{ marginBottom: '24px' }}
                placeholder={this.props.i18n.t('companies:searchCompany')}
                enterButton
                onSearch={(e) => this.fetchCompanies(e)} />
        )
    }

    handleTableChange = (pagination) => {
        const { fetchCompanies, companies } = this.props
        const query = companies.get('query')

        fetchCompanies(query, pagination.current)
    }

    getCompanies = () => {
        const { companiesEntity, companies } = this.props
        const items = companies.get('items')

        return items.map((id) => {
            return companiesEntity.get(id)
        })
    }


    setCompanyModal = (companyModal, row) => {
        const { setAddCompanyModal } = this.props
        this.companyValue = row

        setAddCompanyModal(!companyModal)
    }

    getCompaniesColumn = () => {
        const { i18n } = this.props

        const companiesColumns = [
            {
                title: i18n.t('companies:name'),
                render: (rows) => {
                    return (
                        <Link to={{ pathname: `/companies/${rows.id}`,
                                params: { id: rows.id, number: rows.number } }}>
                            <div>{rows.name}</div>
                        </Link>
                    )
                }
            },
            {
                title: i18n.t('companies:addressDetail'),
                render: (rows) => {
                    return (
                        <div>
                            {`${rows.address_detail || ''} `}
                            {`${rows.district ? rows.district.name : ''} `}
                            {`${rows.province ? rows.province.name : ''} `}
                            {rows.postal_code || ''}
                        </div>
                    )
                }
            },
            {
                title: i18n.t('companies:phoneNumber'),
                dataIndex: 'phone_number'
            },
            {
                title: `${i18n.t('companies:email')} / ${i18n.t('companies:line')}`,
                render: (rows) => {
                    return (
                        <div>
                            <div><MailOutlined /> {rows.email || '-'}</div>
                            <div><MessageOutlined /> Line: {rows.line_id || '-'}</div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('companies:editCompany'),
                render: (rows) => {
                    const companyModal = this.props.companies.get('openAddcompanyModal')
                    return (
                        <a onClick={() => this.setCompanyModal(companyModal, rows)}>
                            <EditTwoTone />
                        </a>
                    )
                }
            }
        ]
        return companiesColumns
    }

    render () {
        const { companies, i18n } = this.props
        const companyModal = companies.get('openAddcompanyModal')
        const loading = companies.get('loading')

        return (
            <div>
                {this.searchCompanies()}
                <Table dataSource={this.getCompanies().toJS()}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    rowKey={companyItem => companyItem.id}
                    columns={this.getCompaniesColumn()}
                    loading={loading}
                    onChange={this.handleTableChange}
                    pagination={{
                        ...this.props.companies.get('pagination').toJS(),
                        showSizeChanger: false
                    }} />
                <CompanyFormContainer visible={companyModal}
                    defaultValue={this.companyValue}
                    title={i18n.t('companies:editCompany')} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    companiesEntity: state.getIn(['Entities', 'customerCompanies']),
    companies: state.get('customerCompanies')
})

const mapDispatchToprops = (dispatch) => {
    return bindActionCreators({
        fetchCompanies,
        setAddCompanyModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToprops)(
    withTranslation('companies')(CompaniesIndexContainer)
)
