import { Map, List } from 'immutable'
import moment from 'moment'

import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_SUMMARY_DASHBOARDS_FETCH,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION,
    ORDER_SUMMARY_DASHBOARDS_SET_SUPPLIER_ORDER_FORM,
    ORDER_SUMMARY_DASHBOARDS_SET_ORDER_ID,
    ORDER_SUMMARY_DASHBOARDS_SET_SORTER,
    ORDER_SUMMARY_DASHBOARDS_SET_STATISTICS_FILTERS,
    ORDER_SUMMARY_DASHBOARDS_SET_DEFAULT_DATE_TIME_TYPE,
    ORDER_SUMMARY_DASHBOARDS_SET_DATE_TYPE,
    ORDER_SUMMARY_DASHBOARDS_SET_TIME_RANGE
} = actions

export const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    statistics: new Map({
        filters: new Map({
            state: null,
            bySale: null,
            noPrice: '',
            startDate: moment().subtract(7, 'days').format('YYYY-MM-D'),
            endDate: moment().add(7, 'days').format('YYYY-MM-D'),
        }),
        loading: false
    }),
    saleItems: new List(),
    showSupplierForm: false,
    orderId: null,
    sorter: new Map(),
    query: undefined,
    state: '',
    bySale: '',
    byCs: '',
    byPurchaser: '',
    startDate: undefined,
    endDate: undefined,
    startReservationDate: undefined,
    endReservationDate: undefined,
    startPaymentDate: undefined,
    endPaymentDate: undefined,
    byLeadSource: '',
    isBigSales: false,
    byPotentialLead: '',
    isWaitingToShip: false,
    isWaitingForProduction: false,
    defaultDateTimeType: null,
    isMyOrders: null,
    isOnlinePaid: null,
    dateType: 'created_at',
    timeRange: ['00:00', '23:59'],
    taskState: undefined
})

export default function orderSummaryDashBoardReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_SUMMARY_DASHBOARDS_FETCH:
            return state.merge({ loading: true })
        case ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result),
                query: action.query,
                state: action.state,
                page: action.page,
                bySale: action.bySale,
                byCs: action.byCs,
                byPurchaser: action.byPurchaser,
                startDate: action.startDate,
                endDate: action.endDate,
                startReservationDate: action.startReservationDate,
                endReservationDate: action.endReservationDate,
                byLeadSource: action.byLeadSource,
                isBigSales: action.isBigSales,
                byPotentialLead: action.byPotentialLead,
                isWaitingToShip: action.isWaitingToShip,
                isWaitingForProduction: action.isWaitingForProduction,
                isMyOrders: action.isMyOrders,
                isOnlinePaid: action.isOnlinePaid,
                taskState: action.taskState,
                startPaymentDate: action.startPaymentDate,
                endPaymentDate: action.endPaymentDate
            })
        case ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS:
            return state.mergeDeep({
                statistics: new Map({
                    ...state.get('statistics').toJS(),
                    loading: true
                })
            })
        case ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS:
            return state.mergeDeep({
                statistics: new Map({
                    ...state.get('statistics').toJS(),
                    ...action.statistics,
                    loading: false
                })
            })
        case ORDER_SUMMARY_DASHBOARDS_FETCH_SALES:
            return state.merge({ loading: true })
        case ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS:
            return state.merge({
                loading: false,
                saleItems: new List(result)
            })
        case ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case ORDER_SUMMARY_DASHBOARDS_SET_SUPPLIER_ORDER_FORM:
            return state.merge({ showSupplierForm: action.value })
        case ORDER_SUMMARY_DASHBOARDS_SET_ORDER_ID:
            return state.merge({ orderId: action.orderId })
        case ORDER_SUMMARY_DASHBOARDS_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        case ORDER_SUMMARY_DASHBOARDS_SET_STATISTICS_FILTERS:
            return state.mergeDeep({
                statistics: new Map({
                    ...state.get('statistics').toJS(),
                    filters: new Map(action.filters)
                })
            })
        case ORDER_SUMMARY_DASHBOARDS_SET_DEFAULT_DATE_TIME_TYPE:
            return state.merge({ defaultDateTimeType: action.value })
        case ORDER_SUMMARY_DASHBOARDS_SET_DATE_TYPE:
            return state.merge({ dateType: action.value })
        case ORDER_SUMMARY_DASHBOARDS_SET_TIME_RANGE:
            return state.merge({ timeRange: action.timeRange })
        default:
            return state
    }
}
