import actions from './actions'
import Immutable, { List, Map } from "immutable"
import moment from 'moment'

const {
    SUPPLIER_INVOICES_REQUESTING,
    SUPPLIER_INVOICES_SET_PAGINATION,
    SUPPLIER_INVOICES_SET_SUMMARY_DATA,
    SUPPLIER_INVOICES_SET_FILTERS,
    SUPPLIER_INVOICES_REQUEST_SUCCESS,
    SUPPLIER_INVOICES_SET_IMPORT_MODAL,
    SUPPLIER_INVOICES_SET_EXPORT_MODAL,
    SUPPLIER_INVOICES_IMPORT_SUCCESS,
    SUPPLIER_INVOICES_IMPORT_FAIL,
    SUPPLIER_INVOICES_IMPORTING,
    SUPPLIER_INVOICES_EXPORTING,
    SUPPLIER_INVOICES_EXPORT_FAIL,
    SUPPLIER_INVOICES_EXPORT_SUCCESS,
    SUPPLIER_INVOICES_VAT_EXPORTING,
    SUPPLIER_INVOICES_VAT_EXPORT_FAIL,
    SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    formErrors: new Map(),
    pagination: new Map({
        current: '',
        pageSize: 20,
        total: null
    }),
    filters: new Map({
        dateType: 'issue_date',
        startDate: null,
        endDate: null,
        invoiceRefCode: '',
        query: '',
        supplierId: null,
        summaryMonth: moment().format('YYYY-MM'),
    }),
    summaryData: new Map(),
    saving: false,
    showImportModal: false,
    showExportModal: false,
    importing: false,
    exporting: false,
    vatExporting: false
})

export default function supplierInvoicesReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case SUPPLIER_INVOICES_REQUESTING:
            return state.merge({
                loading: true
            })
        case SUPPLIER_INVOICES_REQUEST_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case SUPPLIER_INVOICES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case SUPPLIER_INVOICES_SET_SUMMARY_DATA:
            return state.mergeDeep({
                summaryData: Immutable.fromJS(action.summaryData)
            })
        case SUPPLIER_INVOICES_SET_FILTERS:
            return state.mergeDeep({
                filters: new Map(action.filters)
            })
        case SUPPLIER_INVOICES_SET_IMPORT_MODAL:
            return state.merge({
                showImportModal: action.value
            })
        case SUPPLIER_INVOICES_SET_EXPORT_MODAL:
            return state.merge({
                showExportModal: action.value
            })
        case SUPPLIER_INVOICES_IMPORT_FAIL:
            return state.merge({ importing: false })
        case SUPPLIER_INVOICES_IMPORT_SUCCESS:
            return state.merge({ importing: false })
        case SUPPLIER_INVOICES_IMPORTING:
            return state.merge({ importing: true })
        case SUPPLIER_INVOICES_EXPORTING:
            return state.merge({ exporting: true })
        case SUPPLIER_INVOICES_EXPORT_FAIL:
            return state.merge({ exporting: false })
        case SUPPLIER_INVOICES_EXPORT_SUCCESS:
            return state.merge({ exporting: false })
        case SUPPLIER_INVOICES_VAT_EXPORTING:
            return state.merge({ vatExporting: true })
        case SUPPLIER_INVOICES_VAT_EXPORT_FAIL:
            return state.merge({ vatExporting: false })
        case SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS:
            return state.merge({ vatExporting: false })
        default:
            return state
    }
}
