import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import WithholdingTaxesIndex from '../../components/withholdingTaxes/withholdingTaxesIndex'

import actions from '../../redux/withholdingTaxes/actions'

const {
    deleteWithholdingTax,
    downloadWithholdingTaxPDF,
    downloadWithholdingTaxesTXT,
    fetchWithholdingTaxes,
    setWithholdingTaxModal
} = actions

class WithholdingTaxesIndexContainer extends Component {
    componentDidMount () {
        this.filterWithholdingTaxes()
    }

    exportWithholdingTaxesTXT = () => {
        const { downloadWithholdingTaxesTXT, withholdingTaxes } = this.props
        const selectedMonth = withholdingTaxes.get('selectedMonth')
        const selectedType = withholdingTaxes.get('selectedType')

        downloadWithholdingTaxesTXT({ selectedMonth, selectedType })
    }

    filterWithholdingTaxes = ({ month, type, page = 1, perPage } = {}) => {
        const { fetchWithholdingTaxes, withholdingTaxes } = this.props
        const per = perPage || withholdingTaxes.getIn(['pagination', 'pageSize'])
        const selectedMonth = typeof month === 'string' ? month : withholdingTaxes.get('selectedMonth')
        const selectedType = type || withholdingTaxes.get('selectedType')

        fetchWithholdingTaxes({ selectedMonth, selectedType, page, per })
    }

    getWithholdingTaxes () {
        const { withholdingTaxEntities, withholdingTaxes } = this.props
        return withholdingTaxes.get('items', new List()).map((withholdingTaxId) => {
            return withholdingTaxEntities.get(withholdingTaxId) || new Map()
        })
    }

    handleDeleteWithholdingTax = (id) => {
        this.props.deleteWithholdingTax({ id })
    }

    handlePageChange = (pagination) => {
        this.filterWithholdingTaxes({ page: pagination.current, perPage: pagination.pageSize })
    }

    toggleWithholdingTaxForm = ({ selected = null } = {}) => {
        const { setWithholdingTaxModal, withholdingTaxes } = this.props

        setWithholdingTaxModal({ visible: !withholdingTaxes.get('showWithholdingTaxForm'), selected })
    }

    render () {
        const { downloadWithholdingTaxPDF, withholdingTaxes } = this.props

        return (
            <WithholdingTaxesIndex
                activeWithholdingTax={withholdingTaxes.get('withholdingTaxSelected')}
                exportWithholdingTaxesTXT={this.exportWithholdingTaxesTXT}
                filterWithholdingTaxes={this.filterWithholdingTaxes}
                loading={withholdingTaxes.get('loading')}
                onDeleteWithholdingTax={this.handleDeleteWithholdingTax}
                onDownloadWithholdingTaxPDF={downloadWithholdingTaxPDF}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...withholdingTaxes.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                pdfLoading={withholdingTaxes.get('pdfLoading')}
                selectedMonth={withholdingTaxes.get('selectedMonth')}
                selectedType={withholdingTaxes.get('selectedType')}
                showWithholdingTaxForm={withholdingTaxes.get('showWithholdingTaxForm')}
                toggleWithholdingTaxForm={this.toggleWithholdingTaxForm}
                txtLoading={withholdingTaxes.get('txtLoading')}
                withholdingTaxes={this.getWithholdingTaxes().toJS()}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    withholdingTaxEntities: state.getIn(['Entities', 'withholdingTaxes']),
    withholdingTaxes: state.get('withholdingTaxes')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteWithholdingTax,
        downloadWithholdingTaxPDF,
        downloadWithholdingTaxesTXT,
        fetchWithholdingTaxes,
        setWithholdingTaxModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(WithholdingTaxesIndexContainer)

