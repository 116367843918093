import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { Table, Button, Upload } from 'antd'
import { UploadOutlined, EditTwoTone } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

import customerRolesAction from '../../../redux/customers/roles/actions'
import CustomerRoleFormContainer from './CustomerRoleFormContainer'
import { isMobile } from 'react-device-detect'

const { fetchCustomerRoles, setCustomerRoleForm, importCustomerRoles } = customerRolesAction

class CustomerRolesIndexContainer extends Component {
    componentDidMount () {
        this.props.fetchCustomerRoles()
    }

    state = {
        fileList: []
    }

    getCustomerRoles = () => {
        const { customerRoles, customerRoleEntities } = this.props
        const customerRoleItems = customerRoles.get('items')

        return customerRoleItems.map((id, index) => {
            const customerRole = customerRoleEntities.get(id)
            return (
                {
                    key: customerRole.get('id'),
                    No: index + 1,
                    name: `${customerRole.get('name')}`,
                    action: customerRole.toJS()
                }
            )
        })
    }

    renderColumns = () => {
        const { i18n } = this.props

        const columns = [
            {
                title: i18n.t('customers/roles:no'),
                dataIndex: 'No',
                key: 'no.',
                width: isMobile ? '10px' : '100px'
            },
            {
                title: i18n.t('customers/roles:name'),
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: i18n.t('customers/roles:edit'),
                dataIndex: 'action',
                key: 'action',
                render: (value) => {
                    const visible = this.props.customerRoles.get('showRoleForm')
                    return (
                        <a onClick={() => this.setCustomerRoleForm(visible, value)}>
                            <EditTwoTone />
                        </a>
                    )
                }
            }
        ]

        return columns
    }

    setCustomerRoleForm = (visible, value) => {
        const { setCustomerRoleForm } = this.props
        this.roleValue = value

        setCustomerRoleForm(!visible)
    }

    handleSelectFile = (info) => {
        let fileList = info.fileList
        fileList = fileList.slice(-1)

        this.setState({ fileList })
    }

    handleUpload = () => {
        const { fileList } = this.state
        const { importCustomerRoles } = this.props

        const file = fileList[0].originFileObj
        importCustomerRoles(file)

        this.setState({
            fileList: []
        })
    }

    render () {
        const { customerRoles, i18n } = this.props
        const { fileList } = this.state
        const visible = customerRoles.get('showRoleForm')

        const props = {
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }))
                return false
            },
            fileList
        }

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Upload {...props}
                            onChange={this.handleSelectFile}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-excel">
                            <Button>
                                <UploadOutlined />
                                {i18n.t('customers/roles:import')}
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            style={{ margin: '8px 0' }}
                            onClick={this.handleUpload}
                            disabled={fileList.length === 0}
                            loading={customerRoles.get('uploading')}>
                            {i18n.t('customers/roles:upload')}
                        </Button>
                    </div>
                    <Button type="primary" size="large"
                        style={{ margin: '0 12px 12px' }}
                        onClick={() => this.setCustomerRoleForm(visible)}>
                        {i18n.t('customers/roles:addUserRole')}
                    </Button>
                </div>
                <Table columns={this.renderColumns()}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    dataSource={this.getCustomerRoles().toJS()}
                    loading={customerRoles.get('loading')}
                />
                <CustomerRoleFormContainer visible={visible} roleValue={this.roleValue} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerRoles: state.get('customerRoles'),
    customerRoleEntities: state.getIn(['Entities', 'customerRoles']) || new Map()
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerRoles,
        setCustomerRoleForm,
        importCustomerRoles
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('customers/roles')(CustomerRolesIndexContainer)
)
