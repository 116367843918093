import { all, takeLatest, takeEvery, put, fork, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFileFetch, apiFetch } from '../../helpers/restRequest'
import actions from './actions'
import downLoadFile from '../../helpers/downLoadFile'
import { deserialize } from '../../helpers/jsonApi'
import { salesSchema, saleSchema } from '../schema'

const {
    SALE_FETCH_FAIL,
    SALE_FETCH_REQUEST,
    SALE_FETCH_SUCCESS,
    SALE_FETCHING,
    SALES_FETCH_REQUEST,
    SALES_FETCH,
    SALES_FETCH_SUCCESS,
    SALES_REPORT_FETCH_FILE_REQUEST,
    SALES_REPORT_SET_LOADING
} = actions

export function *fetchSalesRequest () {
    yield takeEvery(SALES_FETCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const queryString = `query=${query}`

        if (query === '') { return null }

        yield put({ type: SALES_FETCH })

        const data = yield call(apiFetch, `/sales?only_admin=true&${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, salesSchema)

        yield put({
            type: SALES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchSaleSaga () {
    yield takeLatest(SALE_FETCH_REQUEST, function *({ payload }) {
        const { id, onSuccess, onError } = payload
        yield put({ type: SALE_FETCHING })

        try {
            const path = `/sales/${id}`
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, saleSchema)

            yield put({
                type: SALE_FETCH_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SALE_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *requestDownloadSalesReportFile () {
    yield takeLatest(SALES_REPORT_FETCH_FILE_REQUEST, function *({ payload }) {
        const { startDate, endDate } = payload
        yield put({ type: SALES_REPORT_SET_LOADING, loading: true })

        const path = `/orders/sales/exports.xlsx?start_date=${startDate}&end_date=${endDate}`
        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `sales-report-from-${startDate}-to-${endDate}.xlsx`
        })

        yield put({ type: SALES_REPORT_SET_LOADING, loading: false })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchSalesRequest),
        fork(fetchSaleSaga),
        fork(requestDownloadSalesReportFile)
    ])
}
