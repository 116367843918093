import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import jsCookie from 'js-cookie'
import moment from 'moment'

import Login from '../containers/Login/Login'
import PurchaseOrderPreviews from '../containers/PurchaseOrders/Previews/show'

import { firebaseConfig } from '../../config/firebase'
import { initializePushNotification } from '../../config/initializePushNotification'
import authActions from '../redux/auth/actions'
import profileActions from '../redux/staffs/profiles/actions'
import notificationActions from '../redux/notifications/actions'

import CurrentStaffContainer from '../containers/CurrentStaff/CurrentStaffContainer'
import { Authorization } from '../containers/Authorization'

import DealsPage from './DealsPage'
import CustomersPage from './CustomersPage'
import OrderSummariesPage from './OrderSummariesPage'
import InquiriesPage from './InquiriesPage'
import OrdersPage from './OrdersPage'
import SalePipelinesPage from './SalePipelinesPage'
import SalesSummaryPage from './SalesSummaryPage'
import WalletSummariesPage from './WalletSummariesPage'
import CompaniesPage from './CompaniesPage'
import ProjectsPage from './ProjectsPage'
import ProfitSharingsPage from './ProfitSharingsPage'
import EventLogsPage from './EventLogsPage'
import LinePinsPage from './LinePinsPage'

import SupplierOrderReviewsPage from './SupplierOrderReviewsPage'
import SupplierOrdersPage from './SupplierOrdersPage'
import TrucksPage from './TrucksPage'
import SuppliersPage from './SuppliersPage'

import SupplierInvoicesPage from './SupplierInvoicesPage'
import OrderPaymentDetailsPage from './OrderPaymentDetailsPage'
import PaymentVouchersPage from './PaymentVouchersPage'
import TaxInvoicesPage from './TaxInvoicesPage'
import VatDocumentsPage from './VatDocumentsPage'
import BankStatementsPage from './BankStatementsPage'
import WithholdingTaxesPage from './WithholdingTaxesPage'
import ChartOfAccountsPage from './ChartOfAccountsPage'
import JournalEntryFormatsPage from './JournalEntryFormatsPage'
import JournalEntriesPage from './JournalEntriesPage'
import SupportPage from './SupportPage'

import OrderReviewsPage from './OrderReviewsPage'
import RequestItemsPage from './RequestItemsPage'

import ShippingRatesPage from './ShippingRatesPage'
import StaffsPage from './StaffsPage'
import TicketsPage from './TicketsPage'

const { getToken } = authActions
const { fetchCurrentStaffProfile, receiveNotification, updateCurrentStaffProfile } = profileActions
const { setNotifications } = notificationActions

class RootContainer extends Component {
    componentDidMount () {
        const { fetchCurrentStaffProfile, getToken, receiveNotification } = this.props

        getToken()
        fetchCurrentStaffProfile()

        const firebase = window.firebase
        if (!firebase) { return }

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig)
            initializePushNotification(
                firebase,
                this.updateFcmToken,
                this.showNotification,
                this.warningForOpenNotification,
                receiveNotification
            )
        }
    }

    updateFcmToken = (token) => {
        this.props.updateCurrentStaffProfile({ fcm_token: token })
    }

    showNotification = (notification) => {
        const { i18n, setNotifications } = this.props
        setNotifications(
            notification.data.attributes.note,
            moment(notification.data.attributes.created_at).locale(i18n.language).fromNow(),
            'info'
        )
    }

    warningForOpenNotification = () => {
        const { i18n, setNotifications } = this.props
        setNotifications(i18n.t('notifications:warning.title'), i18n.t('notifications:warning.body'), 'warning')
    }

    renderHome = () =>{
        const { currentStaff, isCurrentStaffA } = this.props

        if (!currentStaff.size) return null

        if (isCurrentStaffA('call_center')) return <Redirect to="/customers" />

        return <Redirect to="/deals" />
    }

    renderContent () {
      const { match } = this.props
      const path = match.params[0] ? `${match.url}` : ''

      return (
        <div id='main_content'>
          <Switch>
            <Route path={`${path}/`} exact>
                {this.renderHome()}
            </Route>

            {/* Customer & Sale */}
            <Route path={`${path}/customers`} component={CustomersPage} />
            <Route path={`${path}/projects`} component={ProjectsPage} />
            <Route path={`${path}/tickets`} component={TicketsPage} />
            <Route path={`${path}/shipping_rates`} component={ShippingRatesPage} />

            <Authorization notAllowedRoles={['call_center']}>
                {/* Customer & Sale */}
                <Route path={`${path}/support`} component={SupportPage} />
                <Route path={`${path}/deals`} component={DealsPage} />
                <Route path={`${path}/order_summaries`} component={OrderSummariesPage} />
                <Route path={`${path}/inquiries`} component={InquiriesPage} />
                <Route path={`${path}/orders`} component={OrdersPage} />
                <Route path={`${path}/sale_pipelines`} component={SalePipelinesPage} />
                <Route path={`${path}/sales_summary`} component={SalesSummaryPage} />
                <Route path={`${path}/wallet_summaries`} component={WalletSummariesPage} />
                <Route path={`${path}/companies`} component={CompaniesPage} />
                <Route path={`${path}/profit_sharings`} component={ProfitSharingsPage} />
                <Route path={`${path}/event_logs`} component={EventLogsPage} />
                <Route path={`${path}/line_pins`} component={LinePinsPage} />

                {/* Vendor */}
                <Route path={`${path}/supplier_order_reviews`} component={SupplierOrderReviewsPage} />
                <Route path={`${path}/supplier_orders`} component={SupplierOrdersPage} />
                <Route path={`${path}/trucks`} component={TrucksPage} />
                <Route path={`${path}/suppliers`} component={SuppliersPage} />

                {/* Account */}
                <Route path={`${path}/supplier_invoices`} component={SupplierInvoicesPage} />
                <Route path={`${path}/order_payment_details`} component={OrderPaymentDetailsPage} />
                <Route path={`${path}/payment_vouchers`} component={PaymentVouchersPage} />
                <Route path={`${path}/tax_invoices`} component={TaxInvoicesPage} />
                <Route path={`${path}/vat_documents`} component={VatDocumentsPage} />
                <Route path={`${path}/bank_statements`} component={BankStatementsPage} />
                <Route path={`${path}/withholding_taxes`} component={WithholdingTaxesPage} />
                <Route path={`${path}/chart_of_accounts`} component={ChartOfAccountsPage} />
                <Route path={`${path}/journal_entry_formats`} component={JournalEntryFormatsPage} />
                <Route path={`${path}/journal_entries`} component={JournalEntriesPage} />

                {/* Feedback */}
                <Route path={`${path}/order_reviews`} component={OrderReviewsPage} />
                <Route path={`${path}/request_items`} component={RequestItemsPage} />

                {/* Setting */}
                <Route path={`${path}/staffs`} component={StaffsPage} />

                {/* Extra */}
                <Route path={`${path}/purchase_orders/:ref_code/previews`} component={PurchaseOrderPreviews} />
            </Authorization>
          </Switch>
        </div>
      )
    }

    render () {
        return jsCookie.get('token') ? this.renderContent() : <Login />
    }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchCurrentStaffProfile,
        getToken,
        receiveNotification,
        setNotifications,
        updateCurrentStaffProfile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('notifications')(CurrentStaffContainer(RootContainer)))
