import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Button, Col, DatePicker, Row } from 'antd'
import SelectStaff from '../../SelectStaff'
import CategorySelector from '../../categorySelector'
import DealSummary from '../DealSummary'
import SaleSummary from '../SaleSummary'
import FilterWrapper from './filter.style'

const { RangePicker } = DatePicker

const Filter = ({ categorySelectorProps, onChangeFilters }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openSaleSummaryModal, setOpenSaleSummaryModal] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(10,'d').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const { i18n } = useTranslation('sales', 'deals')

  const onChangePicker = (value) => {
    setStartDate(value[0].format('YYYY-MM-DD'))
    setEndDate(value[1].format('YYYY-MM-DD'))

    onChangeFilters({ startDate: value[0].format('YYYY-MM-DD') || '', endDate: value[1].format('YYYY-MM-DD') })
  }

  const onChangeCategory = value => {
    onChangeFilters({ categoryId: value })
  }

  return (
    <FilterWrapper>
      <Row className="row-style">
        <Col span={8} className="col-style">
          <Button type="primary"
            onClick={() => setOpenModal(!openModal)}>
            {i18n.t('deals:dealSummary')}
          </Button>
          <Button type="primary"
            onClick={() => setOpenSaleSummaryModal(!openSaleSummaryModal)}>
            {i18n.t('sales:salesSummary.summary')}
          </Button>
        </Col>
        <Col span={16} className="col-style align-right">
          {openModal && (
            <DealSummary
              openModal={openModal}
              setOpenModal={setOpenModal} />
          )}
          {openSaleSummaryModal && (
            <SaleSummary
              openModal={openSaleSummaryModal}
              setOpenModal={setOpenSaleSummaryModal} />
          )}
          <SelectStaff onChange={value => onChangeFilters({ staffId: value || '' })} />
          <CategorySelector
            onChange={onChangeCategory}
            style={{ width: '220px' }}
            {...categorySelectorProps} />
          <RangePicker
              style={{ width: '250px' }}
              onChange={onChangePicker}
              defaultValue={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
          />
        </Col>
      </Row>
    </FilterWrapper>
  )
}

Filter.propTypes = {
  categorySelectorProps: PropTypes.object,
  onChangeFilters: PropTypes.func
}

Filter.defaultProps = {
  categorySelectorProps: {},
  onChangeFilters: () => {}
}

export default Filter
