import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { withRouter } from 'react-router'

import JournalEntryFormatItemsIndex from '../../../components/journalEntryFormats/items/JournalEntryFormatItemsIndex'

import actions from '../../../redux/journalEntryFormats/items/actions'
import journalEntryFormatActions from '../../../redux/journalEntryFormats/actions'

const {
  fetchJournalEntryFormatItems,
  setFormatItemForm,
  deleteJournalEntryFormatItem
} = actions

const { fetchJournalEntryFormat } = journalEntryFormatActions
class JournalEntryFormatsIndexContainer extends Component {
    componentDidMount () {
      const { fetchJournalEntryFormatItems, match, fetchJournalEntryFormat } = this.props
      const { journal_entry_format_id: formatId } = match.params

      fetchJournalEntryFormat({ id: formatId })
      fetchJournalEntryFormatItems({ formatId })
    }

    getJournalEntryFormat () {
        const { match, journalEntryFormatEntities } = this.props
        const { journal_entry_format_id: formatId } = match.params

        return journalEntryFormatEntities.get(`${formatId}`) || new Map()
    }

    getJournalEntryFormatItems () {
        const { journalEntryFormatItemEntities, journalEntryFormatItems } = this.props
        return journalEntryFormatItems.get('items', new List()).map((id) => {
            return journalEntryFormatItemEntities.get(id) || new Map()
        })
    }

    handleDeleteJournalEnTryFormatItem = (id) => {
        const { deleteJournalEntryFormatItem, match } = this.props
        const { journal_entry_format_id: formatId } = match.params

        deleteJournalEntryFormatItem({ formatId, id })
    }

    toggleJournalEnTryFormatItemForm = ({ selected = null } = {}) => {
        const { setFormatItemForm, journalEntryFormatItems } = this.props

        setFormatItemForm({ visible: !journalEntryFormatItems.get('showFormatItemForm'), selected })
    }

    handlePageChange = (pagination) => {
        const { fetchJournalEntryFormatItems, match } = this.props
        const { journal_entry_format_id: formatId } = match.params

        fetchJournalEntryFormatItems({ page: pagination.current, perPage: pagination.pageSize, formatId: formatId })
    }

    render() {
      const { journalEntryFormatItems, match, journalEntryFormats } = this.props
      const { journal_entry_format_id: formatId } = match.params

      return (
        <JournalEntryFormatItemsIndex
          onTableChange={this.handlePageChange}
          journalEntryFormatId={formatId}
          loading={journalEntryFormatItems.get('loading')}
          formatLoading={journalEntryFormats.get('loading')}
          pagination={journalEntryFormatItems.get('pagination').toJS()}
          formatItemId={journalEntryFormatItems.get('formatItemSelected')}
          showFormatItemForm={journalEntryFormatItems.get('showFormatItemForm')}
          onDeleteFormatItem={this.handleDeleteJournalEnTryFormatItem}
          toggleFormatItemForm={this.toggleJournalEnTryFormatItemForm}
          journalEntryFormat={this.getJournalEntryFormat().toJS()}
          journalEntryFormatItems={this.getJournalEntryFormatItems().toJS()}
        />
      )
    }
}

const mapStateToProps = (state) => ({
  journalEntryFormatItemEntities: state.getIn(['Entities', 'journalEntryFormatItems']),
  journalEntryFormatItems: state.get('journalEntryFormatItems'),
  journalEntryFormatEntities: state.getIn(['Entities', 'journalEntryFormats']),
  journalEntryFormats: state.get('journalEntryFormats')
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    fetchJournalEntryFormatItems,
    setFormatItemForm,
    deleteJournalEntryFormatItem,
    fetchJournalEntryFormat
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JournalEntryFormatsIndexContainer))
