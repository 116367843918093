import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Table, Typography } from 'antd'

const ProductGroupItemsList = ({
    items,
    loading,
    onPageChange,
    onSelectItem,
    pagination,
    selectedItemIds
}) => {
    const { i18n } = useTranslation('inquiries')

    const renderLabels = (path) => {
        const keys = _.keys(_.groupBy(items, path))

        return (
            <Typography.Paragraph style={{ marginBottom: 0 }} ellipsis>
                {keys.join('/')}
            </Typography.Paragraph>
        )
    }

    const columns = [
        {
            title: i18n.t('inquiries:name'),
            dataIndex: 'name'
        },
        {
            title: i18n.t('price'),
            dataIndex: ['price', 'display']
        },
        {
            title: i18n.t('cost'),
            dataIndex: ['cost', 'display']
        },
        {
            title: i18n.t('unit'),
            dataIndex: 'unit'
        },
    ]

    const rowSelection = {
        selectedRowKeys: selectedItemIds,
        preserveSelectedRowKeys: true,
        onChange: onSelectItem
    }

    return (
        <Table
            columns={columns}
            dataSource={items}
            loading={loading}
            onChange={onPageChange}
            pagination={pagination}
            rowKey="id"
            rowSelection={rowSelection}
            size='middle'
        />
    )
}

ProductGroupItemsList.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  selectedItemIds: PropTypes.array
}

ProductGroupItemsList.defaultProps = {
  items: [],
  selectedItemIds: []
}

export default ProductGroupItemsList
