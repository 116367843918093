import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Button, Modal, Popconfirm, Table, Tag, Typography } from 'antd'
import { LinkOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons'

import { DndProvider, DragSource, DropTarget } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import LineItemItemTruckImagesContainer from '../../containers/LineItems/ItemTruckImages'
import UploadItemTruckImageContainer from '../../containers/LineItems/UploadItemTruckImage'
import EditableCell from '../../containers/Inquiry/EditableCell'
import ItemPricesContainer from '../../containers/Inquiry/ItemPrices'
import LineItemsWrapper from './lineItems.style'

import apiConfig from '../../../config/apiConfig'
import inquiryActions from '../../redux/inquiry/actions'
import ItemState from './itemState'

const {
    deleteOrderLineItem,
    movePositionLineItem,
    toggleItemPricesModal
} = inquiryActions

const EditableContext = React.createContext()
let dragingIndex = -1


class BodyRow extends Component {
    render() {
        const { isOver, connectDragSource, connectDropTarget, moveRow, form, ...restProps } = this.props
        const style = { ...restProps.style, cursor: 'move' }
        let { className } = restProps

        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward'
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward'
            }
        }

        const dragSource = connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />)
        )

        return (
            <EditableContext.Provider value={form}>
                {dragSource}
            </EditableContext.Provider>
        )
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index
        return {
            index: props.index,
        }
    },
}

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index
        const hoverIndex = props.index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) { return }

        // Time to actually perform the action

        // props.moveRow(dragIndex, hoverIndex);
        if (props.moveRow) {
            props.moveRow(dragIndex, hoverIndex)
        }

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex
    },
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, connect => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow),
)

class DragSortingTable extends Component {
    static propTypes = {}

    static defaultProps = {}

    components = {
        body: {
            row: DragableBodyRow,
            cell: EditableCell
        },
    }

    findSpecialInstructionByPosition = (pos) => {
        const { lineItems, specialInstructionEntities } = this.props
        const lineItemSpecialInstruction = lineItems.map((lineItem) => {
            const specialInstructionId = lineItem.get('special_instruction')
            if (lineItem.get('position') === pos) {
                return specialInstructionEntities.getIn([`${specialInstructionId}`, 'special_instruction'])
            }

            return null
        })

        return _.compact(lineItemSpecialInstruction.toJS())[0]
    }

    getColumns () {
        const { editable, i18n, refetchOrder } = this.props

        return [{
            title: i18n.t('inquiries:number'),
            key: 'No',
            width: '180px',
            dataIndex: 'position',
            editable: editable,
            render: (position) => (
                <div>
                    {position}
                    {this.findSpecialInstructionByPosition(position) && (
                        <span style={{ marginLeft: '3px' }}>(มี)</span>
                    )}
                </div>
            )
        }, {
            title: i18n.t('inquiries:lineItemNameTh'),
            dataIndex: 'name_th',
            editable: editable,
            width: '220px'
        }, {
            title: i18n.t('inquiries:lineItemNameEn'),
            dataIndex: 'name_en',
            editable: editable,
            width: '220px'
        }, {
            key: 'copyName',
            render: (lineItem) => {
                return (
                    <CopyToClipboard text={lineItem.name + ' = ' + lineItem.quantity + ' ' + lineItem.unit}>
                        <Button type="dashed" icon={<CopyOutlined />} shape="round" size="small" />
                    </CopyToClipboard>
                )
            },
            width: '50px'
        }, {
            title: i18n.t('inquiries:quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            editable: editable,
            width: '100px',
            render: (dataIndex, record) => {
                return (
                    <div>
                        <div>{dataIndex}</div>
                        <div>
                            {record.transfer_back_quantity ? <Tag>{record.transfer_back_quantity}</Tag> : null }
                        </div>
                    </div>
                )
            }
        }, {
            title: i18n.t('inquiries:unit'),
            dataIndex: 'unit',
            editable: editable,
            width: '120px'
        }, {
            title: i18n.t('inquiries:cost'),
            dataIndex: 'cost',
            editable: 'true',
            width: '140px'
        }, {
            title: i18n.t('inquiries:weightPerUnit'),
            dataIndex: 'weight',
            editable: editable,
            width: '140px'
        }, {
            title: i18n.t('inquiries:totalWeight'),
            dataIndex: 'total_weight',
            key: 'total_weight',
            width: '140px',
            render: (dataIndex, record) => {
                if (!record.total_weight_after_adjustment) { return dataIndex }

                return (
                    <div className="editable-cell-value-wrap"
                        style={{ paddingRight: 24 }}
                        onClick={this.handleToggleEdit}
                    >
                        <Typography.Text delete>{dataIndex}</Typography.Text>
                        <Tag color={dataIndex > record.total_weight_after_adjustment ? 'red' : 'green'}>
                            {record.total_weight_after_adjustment}
                        </Tag>
                    </div>
                )
            }
        }, {
            title: i18n.t('inquiries:unitPrice'),
            dataIndex: 'single_price',
            key: 'single_price',
            editable: editable,
            width: '110px'
        }, {
            title: i18n.t('inquiries:totalPrice'),
            dataIndex: 'total_price',
            key: 'totalPrice',
            width: '110px',
            render: (dataIndex, record) => {
                if (!record.total_price_after_adjustment) { return dataIndex }

                return (
                    <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }}>
                        <Typography.Text delete>{dataIndex}</Typography.Text>
                        <Tag color={_.toInteger(dataIndex) > record.total_price_after_adjustment ? 'red' : 'green'}>
                            {record.total_price_after_adjustment}
                        </Tag>
                    </div>
                )
            }
        }, {
            title: i18n.t('inquiries:itemState'),
            key: 'item_state',
            width: '150px',
            render: (record) => {
                return (
                    <ItemState itemState={record.item_state}
                        refetchOrder={refetchOrder}
                        itemExternalId={record.item_external_id} />
                )
            }
        }, {
            title: i18n.t('inquiries:action'),
            key: 'action',
            className: editable ? "show" : "hide",
            render: (record) => (
                <Popconfirm title={i18n.t('inquiries:deleteConfirm')}
                    onConfirm={() => this.handleDelete(record.id)}>
                    <Button type="link" icon={<DeleteOutlined />} danger />
                </Popconfirm>
            ),
            width: '100px'
        }]
    }

    getCustomColumns () {
        return this.getColumns().map((col) => {
            if (!col.editable) { return col }

            if (col.dataIndex === "cost" || col.dataIndex === "single_price" || col.dataIndex === "weight") {
                col.editable = true

                return {
                    ...col,
                    onCell: record => {
                        return {
                            record,
                            editable: col.editable,
                            dataIndex: col.dataIndex,
                            title: col.title
                        }
                    }
                }
            }

            return {
                ...col,
                onCell: record => {
                    return {
                        record,
                        editable: record.has_any_supplier_line_item ? false : col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title
                    }
                }
            }
        })
    }

    handleDelete = (id) => {
        const { deleteOrderLineItem, orderId } = this.props

        deleteOrderLineItem(orderId, id)
    }

    handleMovePosition = (id, position) => {
        const { movePositionLineItem, orderId } = this.props
        const lineItem = {
            id: id,
            position: position
        }

        movePositionLineItem(orderId, id, lineItem)
    }

    moveRow = (dragIndex, hoverIndex) => {
        const data = this.props.lineItems.toJS()
        const dragRow = data[dragIndex]
        const lineItemId = dragRow.id
        const newPosition = hoverIndex + 1

        this.handleMovePosition(lineItemId, newPosition)
    }

    renderItemSuppliers = (record) => {
        return _.get(record, 'item.supplier_items', []).map((supplier) => {
            const stockStatus = _.get(supplier, 'stock_status')
            return (
                <Tag color="blue" key={supplier.id} style={{ verticalAlign: 'middle' }}>
                    <div style={{ textAlign: 'center' }}>{_.get(supplier, 'supplier_name', '')}</div>
                    <div style={{ textAlign: 'center' }}>{_.get(supplier, 'price', '')}</div>
                    <div style={{ textAlign: 'center' }}>{stockStatus}</div>
                    <div style={{ textAlign: 'center' }}>{_.get(supplier, 'supplier_line_group', '')}</div>
                    <div style={{ textAlign: 'center' }}>{_.get(supplier, 'full_remark', '')}</div>
                </Tag>
            )
        })
    }

    renderProductLink = (record) => {
        return _.get(record, 'item_product_paths', []).map((itemProduct) => {
            const url = _.get(itemProduct, 'url')

            return (
                <div key={url}>
                    <LinkOutlined style={{ paddingRight: '4px' }} />
                    <a target="_blank" href={`${apiConfig.apiBase}${url}`} rel="noreferrer">
                        {_.get(itemProduct, 'name', 'url')}
                    </a>
                </div>
            )
        })
    }

    renderSpecialInstruction = (record) => {
        const { i18n, inquiry, specialInstructionEntities } = this.props
        const lineItemsSaving = inquiry.get('lineItemsSaving')
        const columns = [{
            key: 'line-item-item-truck-image',
            dataIndex: 'lineItem',
            width: 180,
            render: (lineItem) => {
                return (
                    <>
                        <LineItemItemTruckImagesContainer
                            key="item-truck-images"
                            lineItem={lineItem}
                        />

                        <UploadItemTruckImageContainer
                            key="upload"
                            lineItem={lineItem}
                        />
                    </>
                )
            }
        }, {
            title: i18n.t('inquiries:specialInstruction'),
            editable: true,
            dataIndex: 'special_instruction',
            render: (specialInstruction) => <div>{specialInstruction.detail}</div>
        }]

        const dataSource = [
            {
                key: 'special_instruction',
                special_instruction: {
                    id: specialInstructionEntities.getIn([`${record.special_instruction}`, 'id']),
                    detail: specialInstructionEntities.getIn([`${record.special_instruction}`, 'special_instruction']),
                    lineItemId: record.id
                },
                lineItem: record
            }
        ]

        const customColumns = columns.map((col) => {
            if (!col.editable) { return col }

            return {
                ...col,
                onCell: record => {
                    return {
                        record,
                        editable: col.editable,
                        dataIndex: col.dataIndex,
                        title: col.title
                    }
                }
            }
        })

        return (
            <div>
                <Table
                    loading={lineItemsSaving}
                    columns={customColumns}
                    components={this.components}
                    dataSource={dataSource}
                    pagination={false}
                    rowClassName={() => 'editable-row'}
                    scroll={{ x: 1300 }}
                />
                <div>
                    {i18n.t('inquiries:suppliers')} {this.renderItemSuppliers(record)}
                </div>
                { record.item_external_id && (
                    <div>
                        <a
                            href={`https://www.onestockhome.com/th/admin/items/${record.item_external_id}`}
                            target="_blank" rel="noopener noreferrer"
                        >
                            Admin item page
                        </a>
                    </div>
                )}
                <div>
                    {i18n.t('inquiries:truckOriginAddresses')} {this.renderTruckOriginAddresses(record)}
                </div>
                <div>
                    {this.renderProductLink(record)}
                </div>
            </div>
        )
    }

    renderTruckOriginAddresses = (record) => {
        return _.get(record, 'item.truck_origin_addresses', []).map(truck_origin_address => {
            return (
                <Tag color="blue" key={truck_origin_address.id}>
                    {truck_origin_address.name}
                </Tag>
            )
        })
    }

    render () {
        const { editable, handleChangePagination, i18n, inquiry, lineItems, toggleItemPricesModal } = this.props

        return (
            <DndProvider backend={HTML5Backend}>
                <LineItemsWrapper>
                    <Table
                        columns={this.getCustomColumns()}
                        components={this.components}
                        dataSource={lineItems.toJS()}
                        loading={inquiry.get('lineItemsLoading')}
                        onRow={(record, index) => ({
                            index,
                            moveRow: editable ? this.moveRow : null
                        })}
                        expandedRowRender={this.renderSpecialInstruction}
                        rowClassName={() => 'editable-row'}
                        rowKey="id"
                        onChange={handleChangePagination}
                        pagination={{
                            ...inquiry.get('lineItemsPagination').toJS(),
                            showSizeChanger: false
                        }}
                        style={{ marginBottom: 24 }}
                        scroll={{ x: 1300 }}
                    />
                    <Modal centered
                        footer={[
                            <Button key="back" onClick={toggleItemPricesModal}>
                                {i18n.t('shared:close')}
                            </Button>,
                        ]}
                        onCancel={toggleItemPricesModal}
                        style={{ top: 10 }}
                        visible={inquiry.get('showItemPricesModal')}
                        width={900}>
                        <ItemPricesContainer itemId={inquiry.get('itemId')} />
                    </Modal>
                </LineItemsWrapper>
            </DndProvider>
        )
    }
}

DragSortingTable.propTypes = {
    orderId: PropTypes.string.isRequired,
    lineItems: ImmutablePropTypes.list,
    editable: PropTypes.bool,
    handleChangePagination: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    lineItemEntities: state.getIn(['Entities', 'lineItems']),
    specialInstructionEntities: state.getIn(['Entities', 'specialInstructions']),
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteOrderLineItem,
        movePositionLineItem,
        toggleItemPricesModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(DragSortingTable))
