import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { EditTwoTone } from '@ant-design/icons'
import { Spin, Modal, Card } from 'antd'

import SalePipelineForm from '../../../../../components/salePipelines/Form'
import SalePipelineInfoRender from './render'

const SALE_PIPELINE_QUERY = gql`
    query SalePipelineQuery($number: String!){
      salePipelineQuery(number: $number) {
          id
          customerPersonality
          customerType
          customerTypeLabel
          interestingProduct
          status
          statusLabel
          month
          year
          isPotential
          archived
          staff {
            fullName
          }
      }
    }
`

const SalePipelineInfo = ({ orderId }) => {
  const [showSalePipelineForm, setShowSalePipelineForm] = useState(false)
  const [refetchSalePipeline, setRefetchSalePipeline] = useState(false)


  const { loading, data, refetch } = useQuery(SALE_PIPELINE_QUERY, {
      variables: { number: orderId },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        setRefetchSalePipeline(false)
      }
  })

  useEffect(() => {
    if (refetchSalePipeline) {
      refetch()
    }
  }, [refetchSalePipeline])

  const handleSalePipelineForm = () => {
    setShowSalePipelineForm(!showSalePipelineForm)
  }

  const onSaveSalePipelineSuccess = () => {
    setRefetchSalePipeline(true)
    handleSalePipelineForm()
  }

  if (loading) return <Spin />
  const salePipeline = data && data.salePipelineQuery

  return (
    <div className='sale-pipeline-section'>
      {!salePipeline ?
        <a onClick={handleSalePipelineForm}>
          <div>โยนเข้า Sale pipeline</div>
        </a> :
        <Card size='small' className='sale-pipeline-card'>
          <a onClick={handleSalePipelineForm}>
              <div>Sale pipeline <EditTwoTone /></div>
          </a>
          <SalePipelineInfoRender salePipeline={salePipeline} />
        </Card>
        }
        <Modal visible={showSalePipelineForm}
              footer={null}
              width={'50%'}
              onCancel={handleSalePipelineForm}>
              <SalePipelineForm
                onSuccess={onSaveSalePipelineSuccess}
                onCancel={handleSalePipelineForm}
                salePipelineId={salePipeline ? salePipeline.id : null}
                orderId={orderId} />
        </Modal>
    </div>
  )
}

SalePipelineInfo.propTypes = {
  orderId: PropTypes.string.isRequired,
  setRefetchSalePipeline: PropTypes.func,
  refetchSalePipeline: PropTypes.bool
}

SalePipelineInfo.defaultProps = {
  setRefetchSalePipeline: () => {}
}

export default SalePipelineInfo
