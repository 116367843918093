import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { Table } from 'antd'

import actions from '../../redux/bankStatements/actions'
import inquiryActions from '../../redux/inquiry/actions'

const {
  fetchBankStatementSummaries
} = actions

const { fetchBankAccounts } = inquiryActions

const { Column, ColumnGroup } = Table

class BankStatementsSummary extends Component {
    static propTypes = {
        bankAccountEntities: ImmutablePropTypes.map.isRequired,
        bankStatementEntities: ImmutablePropTypes.map.isRequired,
        bankStatements: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
      const { fetchBankAccounts, fetchBankStatementSummaries } = this.props

      fetchBankAccounts(true)
      fetchBankStatementSummaries(1)
    }

    handleTableChange = (pagination) => {
      this.props.fetchBankStatementSummaries(pagination.current)
    }

    getDisplayPrice = (priceSatangs) => {
      if (priceSatangs === undefined) return null

      const price = parseFloat(priceSatangs) / 100.00
      return Number((price).toFixed(2)).toLocaleString()
    }

    getBankAccountColumnGroups () {
      const { inquiry, bankAccountEntities } = this.props

        return inquiry.get('bankAccountItems', new List()).map((id) => {
          const bankAccount = bankAccountEntities.get(_.toString(id), new Map())
          const bankNameWithNumber = bankAccount.get('bank_short_name_with_number')
          const bankNameLowerCase = _.toLower(bankAccount.get('bank_short_name'))

            return (
              <ColumnGroup title={bankNameWithNumber}>
                <Column title="All" width={130} dataIndex={[`${bankNameLowerCase}`, 'count']} key={`${bankNameLowerCase}_all`} />
                <Column title="Reconclied" width={130} dataIndex={[`${bankNameLowerCase}`, 'reconciled_total']}  key={`${bankNameLowerCase}_reconclied`} />
                <Column title="Credit Amount"
                  width={160}
                  dataIndex={[`${bankNameLowerCase}`, 'credit_amount']}
                  key={`${bankNameLowerCase}_credit_amount`}
                  render={(price) => this.getDisplayPrice(price)} />
                <Column title="Debit Amount" width={160}
                  render={(price) => this.getDisplayPrice(price)}
                  dataIndex={[`${bankNameLowerCase}`, 'debit_amount']}
                  key={`${bankNameLowerCase}_debit_amount`} />
                <Column title="Credit Reconciled Amount" width={160}
                  render={(price) => this.getDisplayPrice(price)}
                  dataIndex={[`${bankNameLowerCase}`, 'credit_reconciled_amount']}
                  key={`${bankNameLowerCase}_credit_reconciled_amount`} />
                <Column title="Debit Reconciled Amount" width={160}
                  render={(price) => this.getDisplayPrice(price)}
                  dataIndex={[`${bankNameLowerCase}`, 'debit_reconciled_amount']}
                  key={`${bankNameLowerCase}_debit_reconciled_amount`} />
              </ColumnGroup>
            )
        })
    }

    getBankStatementDetail = (items) =>{
      const { bankAccountEntities } = this.props
      let bankDetail = {}
      items.map((item) => {
        const bankAccountId = item.get('bank_account_id')
        const bankAccount = bankAccountEntities.get(_.toString(bankAccountId), new Map())
        const bankName = _.toLower(bankAccount.get('bank_short_name'))

        bankDetail[bankName] = {
          count: item.get('count'),
          reconciled_total: item.get('reconciled_total'),
          credit_amount: item.get('credit_amount_satangs'),
          debit_amount: item.get('debit_amount_satangs'),
          credit_reconciled_amount: item.get('credit_reconciled_amount_satangs'),
          debit_reconciled_amount: item.get('debit_reconciled_amount_satangs')
        }
      })

      return bankDetail
    }

    getItems = () => {
      const { bankStatements, bankStatementCachesEntities } = this.props
      return bankStatements.get('bankStatementCacheItems', new List()).map((dateId) => {
        const bankStatementCache = bankStatementCachesEntities.get(_.toString(dateId), new Map())
        const itemList = bankStatementCache.get('items')
        const bankDetail = this.getBankStatementDetail(itemList)
        const data = { key: dateId, date: dateId }
        const dataSource = Object.assign(data, bankDetail)

        return dataSource
      })
    }

    render () {
      const { inquiry, bankStatements } = this.props

        return (
          <Table dataSource={this.getItems()}
            scroll={{ x: true }}
            onChange={this.handleTableChange}
            pagination={{
              ...bankStatements.get('bankStatementCachePagination').toJS(),
              showSizeChanger: false
            }}
            loading={inquiry.get('bankAccountsLoading') || bankStatements.get('loading')}>
            <Column title="Date" dataIndex="date" key="date" fixed='left' />
            {this.getBankAccountColumnGroups()}
          </Table>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountEntities: state.getIn(['Entities', 'bankAccounts']),
        bankStatementEntities: state.getIn(['Entities', 'bankStatements']),
        bankStatementCachesEntities: state.getIn(['Entities', 'bankStatementCaches']),
        bankStatements: state.get('bankStatements'),
        inquiry: state.get('inquiry')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      fetchBankAccounts,
      fetchBankStatementSummaries
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementsSummary)
