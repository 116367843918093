import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import _ from 'lodash'

import { Spin } from 'antd'
import SelectLineItems from '../../components/selectLineItems'

class SelectLineItemsContainer extends Component {
    static propTypes = {
        // Props from another component
        columns: PropTypes.array,
        dataSource: PropTypes.array,
        isDisabled: PropTypes.bool,
        loading: PropTypes.bool,
        onChange: PropTypes.func,
        onFetch: PropTypes.func.isRequired,
        orderId: PropTypes.string.isRequired,
        // value should be array of line item object
        value: PropTypes.array
    }

    static defaultProps = {
        columns: [{
            title: i18n.t('inquiries:detail'),
            dataIndex: 'name'
        }, {
            title: i18n.t('inquiries:quantity'),
            dataIndex: 'quantity',
            editable: true
        }, {
            title: i18n.t('inquiries:unit'),
            dataIndex: 'unit'
        }, {
            title: i18n.t('inquiries:cost'),
            dataIndex: 'cost',
            editable: true
        }],
        isDisabled: false,
        onChange: () => {},
        onFetch: () => {},
        value: []
    }

    constructor (props) {
        super(props)

        this.state = {
            dataSource: [],
            selectedRowKeys: [],
            selectedRows: []
        }
    }

    componentDidMount () {
        const { onFetch } = this.props

        onFetch({ onSuccess: () => {
            const { value } = this.props

            this.setState({
                dataSource: this.getInitialDataSource(),
                selectedRowKeys: this.getInitialSelectedRowKeys(),
                selectedRows: [...value]
            })
        } })
    }

    componentDidUpdate (prevProps) {
        const { dataSource } = this.props
        const { dataSource: prevDataSource } = prevProps
        const prevCount = prevDataSource.length
        const count = dataSource.length

        if (count - prevCount > 0) {   
            if (prevCount > 0) {
                this.setState({ dataSource: [...this.state.dataSource, ..._.chunk(dataSource, prevCount)[1]] })
            } else {
                this.setState({ dataSource })
            }

        }
    }

    getInitialSelectedRowKeys () {
        const { value } = this.props
        if (_.isEmpty(value)) return []

        return value.map((lineItem) => {
            return _.get(lineItem, 'id', '')
        })
    }

    getInitialDataSource () {
        const { dataSource, value } = this.props
        const lineItems = [...dataSource]

        value.forEach(lineItem => {
            const index = lineItems.findIndex((item) => item.id === lineItem.id)
            const item = lineItems[index]

            lineItems.splice(index, 1, {
                ...item,
                ...lineItem
            })
        })

        return lineItems
    }

    handleSave = (row) => {
        const { onChange } = this.props
        const { dataSource, selectedRows, selectedRowKeys } = this.state

        // edit data for render
        const newData = [...dataSource]
        const index = newData.findIndex((item) => row.id === item.id)
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row
        })
        // update selectedRows state
        const newSelectedRows = selectedRows.map((selectedRow) => {
            const index = newData.findIndex((item) => selectedRow.id === item.id)
            return index === -1 ? selectedRow : newData[index]
        })

        // dataSource use for show each row in the table
        // whether check or not
        this.setState({ dataSource: newData, selectedRows: newSelectedRows })

        onChange([...newSelectedRows, ...this.getDestroySelectedRows(selectedRowKeys)])
    }

    onRowSelectionChange = (selectedRowKeys, selectedRows) => {
        const { onChange } = this.props
        this.setState({ selectedRowKeys, selectedRows })
        onChange([...selectedRows, ...this.getDestroySelectedRows(selectedRowKeys)])
    }

    getDestroySelectedRows (selectedRowKeys) {
        const { value } = this.props
        return _.difference(
            value.map((lineItem) => lineItem.id),
            selectedRowKeys
        ).map((result) => ({ id: result, _destroy: true }))
    }

    render () {
        const { dataSource, selectedRowKeys } = this.state
        const { columns, isDisabled, loading } = this.props

        if (loading) return <Spin />

        return (
            <SelectLineItems
                columns={columns}
                handleSave={this.handleSave}
                lineItems={dataSource}
                loading={loading}
                onRowSelectionChange={this.onRowSelectionChange}
                selectedRowKeys={selectedRowKeys}
                isDisabled={isDisabled}
            />
        )
    }
}

export default connect()(withTranslation('inquiries')(SelectLineItemsContainer))
