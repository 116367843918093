import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import OrderScbPaymentConfirmList from '../../components/scbPaymentConfirms/orderScbPaymentConfirmList'

import actions from '../../redux/scbPaymentConfirms/actions'

const { fetchOrderScbPaymentConfirms } = actions

class OrderScbPaymentConfirmListContainer extends Component {
    static propTypes = {
        // Props from another component
        orderId: PropTypes.string.isRequired,

        // Inner Props
        fetchOrderScbPaymentConfirms: PropTypes.func.isRequired,
        scbPaymentConfirmEntities: ImmutablePropTypes.map.isRequired,
        scbPaymentConfirms: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        const { orderId, fetchOrderScbPaymentConfirms } = this.props

        fetchOrderScbPaymentConfirms({ orderId })
    }

    getScbPaymentConfirms () {
        const { scbPaymentConfirmEntities, scbPaymentConfirms } = this.props

        return scbPaymentConfirms.get('items', new List()).map((id) => {
            return scbPaymentConfirmEntities.get(_.toString(id), new Map())
        })
    }

    render () {
        const { scbPaymentConfirms } = this.props

        return (
            <OrderScbPaymentConfirmList
                loading={scbPaymentConfirms.get('loading', false)}
                scbPaymentConfirms={this.getScbPaymentConfirms().toJS()}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        scbPaymentConfirmEntities: state.getIn(['Entities', 'scbPaymentConfirms']),
        scbPaymentConfirms: state.get('scbPaymentConfirms')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderScbPaymentConfirms
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderScbPaymentConfirmListContainer)
