import React, {useState} from 'react'
import PropTypes from 'prop-types'

import OrderStaffsRender from "./render";
import CurrentStaffContainer from "../../containers/CurrentStaff/CurrentStaffContainer";

const ALLOW_STAFF_ROLES = ['super_admin', 'accounting', 'purchase', 'purchase_supervisor']

const getEditable = (order, rolesMatched) => {
  if (rolesMatched(ALLOW_STAFF_ROLES)) return true;

  return !["payment", "complete"].includes(order.state);
};

const getOrderData = (order) => {
  if (order["__typename"]) return order;

  const { id, state, order_members } = order;

  return {
    number: id,
    state,
    orderMembers: order_members.map((orderMember) => {
      return getStaffData(orderMember);
    }),
  };
};

const getStaffData = (orderMember) => {
  if (!orderMember) return null;

  return {
    id: orderMember.id,
    staffId: orderMember.staff.id,
    firstName: orderMember.staff.first_name,
    fullName: [orderMember.staff.first_name, orderMember.staff.last_name]
      .filter(Boolean)
      .join(" "),
    role: orderMember.role,
    orderMemberId: orderMember.id,
  };
};

const OrderStaffs = ({ rolesMatched, order, onSaveSuccess }) => {
  const orderData = getOrderData(order)

  return (
      <OrderStaffsRender
        currentStaffRoles={{}}
        editable={getEditable(orderData, rolesMatched)}
        order={orderData}
        onSaveSuccess={onSaveSuccess}
      />
  )
}

OrderStaffs.propTypes = {
  rolesMatched: PropTypes.func.isRequired,
  order: PropTypes.object,
  onSaveSuccess: PropTypes.func,
};

OrderStaffs.defaultProps = {
  onSaveSuccess: () => {},
};

export default CurrentStaffContainer(OrderStaffs);
