import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { Row, Button, notification, Table, Modal, Col, Statistic, Divider, Tag } from 'antd'
import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import withPreviewImage from '../../ImagePreview/ImagePreviewContainer'
import Image from '../../Image'
import PaymentDetailsFormContainer from './PaymentDetailsFormContainer'
import EditText from '../../EditText'
import AccountingWrapper from './accounting.style'

import accountingActions from '../../../redux/orders/accounting/actions'

const {
    fetchPaymentDetails,
    fetchTransferBack,
    resetPaymentDatailErrors,
    savePaymentDatail,
    setPaymentDetailsForm
} = accountingActions

class ReceiptVoucher extends Component {
    componentDidMount () {
        const { fetchPaymentDetails, fetchTransferBack, orderId } = this.props

        fetchPaymentDetails(1, orderId)
        fetchTransferBack(orderId)
    }

    paymentDetails = []

    getCurrentOrder () {
        const {
            entities,
            inquiry
        } = this.props

        return entities.getIn(['orders', inquiry.get('current')], new Map())
    }

    handleSaveRVBankStatement = ({ id, value }) => {
        const { orderId, savePaymentDatail } = this.props

        savePaymentDatail(orderId, id, { bank_statement_id: value })
    }

    paymentDetailData = () => {
        const { orderPaymentDetails, entities } = this.props
        const paymentDetailItem = orderPaymentDetails.get('items').toJS()
        return paymentDetailItem.map((id, index) => {
            const item = entities.getIn(['paymentDetails', id])

            return (
                {
                    key: id,
                    transferDate: item.get('transfer_date'),
                    bank: item.getIn(['bank_account', 'full_name']),
                    paymentTerm: item.get('payment_term'),
                    amount: item.getIn(['amount', 'display']),
                    paymentMethod: item.get('payment_method'),
                    receiptVoucher: item.get('receipt_voucher'),
                    image: item.get('order_payment_images'),
                    note: item.get('note'),
                    reconciled: item.get('reconciled'),
                    externalId: item.get('external_id'),
                    bankStatementId: item.get('bank_statement_number')
                }
            )
        })
    }

    paymentVoucherData = () => {
        const { entities, orderPaymentDetails } = this.props
        const transferBackIds = orderPaymentDetails.get('transferBackItems')
        const paymentVouchers = transferBackIds.map((id) => {
            return entities.getIn(['paymentVouchers', id])
        })

        return paymentVouchers.toJS()
    }

    showPaymentDetailsForm = (value) => {
        const { orderPaymentDetails, setPaymentDetailsForm, resetPaymentDatailErrors } = this.props
        const showPaymentDetailsForm = orderPaymentDetails.get('showPaymentDetailsForm')
        this.paymentDetails = value
        resetPaymentDatailErrors()
        setPaymentDetailsForm(!showPaymentDetailsForm)
    }

    handleCopy = () => {
        notification['success']({
            message: this.props.i18n.t('order.copyToClipboard')
        })
    }

    renderDetail = (row) => {
        const { i18n } = this.props
        const paymentTerm = row.paymentTerm
        const amount = row.amount
        const paymentMethod = row.paymentMethod
        const bank = row.bank
        const reconciled = row.reconciled

        return (
            <AccountingWrapper>
                {paymentTerm ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.paymentTerm`)}: &nbsp; </span>
                        {i18n.t(`confirms:accounting.${paymentTerm}`)}
                    </div>) : null}
                {amount ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.amount`)}: &nbsp; </span>
                        {amount} {i18n.t(`confirms:accounting.bath`)}
                    </div>) : null}
                {paymentMethod ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.paymentMethod`)}: &nbsp; </span>
                        {i18n.t(`confirms:accounting.${paymentMethod}`)}
                    </div>) : null}
                {bank ? (
                    <div className="detail-container">
                        <span> {i18n.t(`confirms:accounting.bank`)}: &nbsp; </span>
                        {bank}
                    </div>) : null}
                {reconciled && <Tag color="green">{i18n.t('confirms:accounting.reconciled')}</Tag>}
            </AccountingWrapper>
        )
    }

    renderImage = (dataIndex) => {
        return dataIndex.map((image) => {
            const imageId = image.get('id')
            const thumbUrl = image.getIn(['image', 'thumb']) || ''
            const originalUrl = image.getIn(['image', 'original']) || ''
            const contentType = image.getIn(['image', 'content_type']) || ''

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderPaymentVoucherImages = (dataIndex) => {
        return dataIndex.map((image) => {
            const imageId = image.id
            const thumbUrl = image.file && image.file.s || ''
            const originalUrl = image.file && image.file.original || ''
            const contentType = image.file && image.file.content_type || ''

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl} />
            )
        })
    }

    renderAction = (value) => {
        const { entities } = this.props
        const paymentDetails = value && entities.getIn(['paymentDetails', value])

        return (
            <div className="action-contailner">
                <Link onClick={() => this.showPaymentDetailsForm(paymentDetails)}>
                    <EditOutlined />
                </Link>
            </div>
        )
    }

    renderCreditCardDetail () {
        const { i18n } = this.props
        const order = this.getCurrentOrder()

        if (order.get('payment_method') !== 'credit_card') return <></>

        return (
            <>
                <Divider />

                <Row>
                    <Col md={6}>
                        <Statistic
                            title={i18n.t('confirms:accounting.productAmount')}
                            value={order.getIn(['money_display', 'grand_total'], 0.00)}
                        />
                    </Col>

                    <Col md={6}>
                        <Statistic
                            title={`${i18n.t('confirms:accounting.creditCardFee')} (${order.getIn(['money_display', 'credit_card_rate'], '-')}%)`}
                            value={order.getIn(['money_display', 'credit_card_total'], 0.00)}
                        />
                    </Col>

                    <Col md={6}>
                        <Statistic
                            title={`${i18n.t('confirms:accounting.productAmount')} + ${i18n.t('confirms:accounting.creditCardFee')}`}
                            value={order.getIn(['money_display', 'grand_total_with_credit_card'], 0.00)}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    getPagination () {
        const { orderPaymentDetails } = this.props
        return orderPaymentDetails.get('pagination') || new Map()
    }

    handleTableChange = (pagination) => {
        const { fetchPaymentDetails, orderId } = this.props
        const page = pagination.current

        fetchPaymentDetails(page, orderId)
    }

    render () {
        const { i18n, orderPaymentDetails, orderState } = this.props
        const loading = orderPaymentDetails.get('loading')
        const transferBackLoading = orderPaymentDetails.get('transferBackLoading')
        const showPaymentDetailsForm = orderPaymentDetails.get('showPaymentDetailsForm')
        moment.locale(i18n.language)


        const paymentDetailColumn = [
            {
                title: i18n.t('confirms:accounting.transferDate'),
                dataIndex: 'transferDate',
                width: '15%',
                key: 'transferDate',
                render: (dataIndex, record) => {
                    if (!dataIndex) { return }

                    return (
                        <div>
                            <div>
                                {moment(dataIndex, 'YYYY-MM-DD').format('D MMMM YYYY')}
                            </div>
                            <div>{record.externalId}</div>
                        </div>
                    )
                }
            }, {
                title: i18n.t('confirms:accounting.bankStatementId'),
                width: '10%',
                dataIndex: 'bankStatementId',
                render: (bankStatementId, record) => (
                    <EditText
                        initialParams={{ id: record.key }}
                        value={bankStatementId}
                        onSave={this.handleSaveRVBankStatement}
                    />
                )
            }, {
                title: i18n.t('confirms:accounting.detail'),
                width: '30%',
                textWrap: 'word-break',
                key: 'detail',
                render: (value, row) => this.renderDetail(row)
            }, {
                title: i18n.t('confirms:accounting.receiptVoucher'),
                dataIndex: 'receiptVoucher',
                width: '15%',
                key: 'receiptVoucher',
                render: (value, row) => {
                    if (value) {
                        return (
                            <AccountingWrapper>
                                <div className="receipt-voucher-container">
                                    {value} &nbsp;
                                    <CopyToClipboard text={value}>
                                        <Button type="dashed" icon={<CopyOutlined />} shape="round" size="small"
                                            onClick={() => this.handleCopy()} />
                                    </CopyToClipboard>
                                </div>
                                <div className="image-container">
                                    {this.renderImage(row.image)}
                                </div>
                            </AccountingWrapper>
                        )
                    }
                    return (
                        <AccountingWrapper>
                            <div className="image-container">
                                {this.renderImage(row.image)}
                            </div>
                        </AccountingWrapper>
                    )
                }
            }, {
                title: i18n.t('confirms:accounting.note'),
                dataIndex: 'note',
                width: '25%',
                key: 'note'
            }, {
                width: '5%',
                dataIndex: 'key',
                render: (dataIndex) => this.renderAction(dataIndex)
            }
        ]

        const paymentVoucherColumn = [
            {
                title: i18n.t('confirms:accounting.transferDate'),
                dataIndex: 'date_of_purchase',
                render: (dataIndex) => {
                    if (!dataIndex) { return }
                    return moment(dataIndex, 'YYYY-MM-DD').format('D MMMM YYYY')
                }
            },
            {
                title: i18n.t('confirms:accounting.detail'),
                render: (record) => {
                    const row = {
                        amount: record.amount.display,
                        bank: record.bank_full_name
                    }
                    return this.renderDetail(row)
                }
            },
            {
                title: i18n.t('confirms:paymentVoucher.number'),
                dataIndex: 'number'
            },
            {
                title: i18n.t('confirms:accounting.images'),
                dataIndex: 'payment_voucher_images',
                render: (dataIndex) => {
                    return (
                        <AccountingWrapper>
                            <div className="image-container">
                                {this.renderPaymentVoucherImages(dataIndex)}
                            </div>
                        </AccountingWrapper>
                    )
                }
            }
        ]

        return (
            <AccountingWrapper>
                {this.renderCreditCardDetail()}

                <Row>
                    <Modal
                        visible={showPaymentDetailsForm}
                        destroyOnClose
                        onCancel={() => this.showPaymentDetailsForm()}
                        width="700px"
                        footer={null}
                    >
                        <PaymentDetailsFormContainer
                            orderState={orderState}
                            paymentDetails={this.paymentDetails} />
                    </Modal>
                    <Table
                        columns={paymentDetailColumn}
                        scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                        dataSource={this.paymentDetailData()}
                        pagination={{
                            ...this.getPagination().toJS(),
                            showSizeChanger: false
                        }}
                        onChange={this.handleTableChange}
                        loading={loading}
                    />
                </Row>
                <Row className="my-16">
                    <Col span={12}>
                        <Button
                            type="primary"
                            onClick={() => this.showPaymentDetailsForm()}>
                            {i18n.t('confirms:add')}
                        </Button>
                    </Col>
                </Row>
                <h3>{i18n.t('confirms:accounting.transferBack')}</h3>
                <Table rowKey="id"
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    columns={paymentVoucherColumn}
                    dataSource={this.paymentVoucherData()}
                    loading={transferBackLoading}
                    pagination={false} />
            </AccountingWrapper>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    inquiry: state.get('inquiry'),
    orderPaymentDetails: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchPaymentDetails,
        fetchTransferBack,
        resetPaymentDatailErrors,
        savePaymentDatail,
        setPaymentDetailsForm
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('confirms')(withPreviewImage(ReceiptVoucher))
)
