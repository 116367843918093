import React from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'

import { isMobile } from 'react-device-detect'
import _ from 'lodash'
import classNames from 'classnames'
import { Table, Divider, Tag, Button, Typography } from 'antd'
import { CreditCardOutlined, PrinterOutlined } from '@ant-design/icons'

import Image from '../Image'
import ImagePreviewContainer from '../ImagePreview/ImagePreviewContainer'

import supplierOrderActions from '../../redux/supplierOrders/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    changeStateTo,
	fetchSupplierOrderPDF
} = supplierOrderActions

const { setNotifications } = notificationActions

const SupplierOrderTable = ({
	toggleSupplierOrderForm,
	totalPages,
	supplierOrders,
	loading,
	pageSize,
	page,
	handleTableChange,
	setSelectedRows
}) => {
	const { i18n } = useTranslation(['suppliers'])
	const dispatch = useDispatch()

	const renderTruckReservationCost = (truckReservationCost, order) => {
		const isSelfPickup = order.shippingType === 'self_pickup'
		const shippingCost = truckReservationCost === '0.00' ?
			i18n.t('suppliers:supplierOrder.n/a') : truckReservationCost

		if (isSelfPickup) {
			return i18n.t('suppliers:selfPickup')
		} else {
			return shippingCost
		}
	}

	const renderRvYield = (order) => {
		const rvVat = _.get(order, 'orderYield.rv.display', null) ?
			_.get(order, 'orderYield.rv.display') :
			i18n.t('suppliers:supplierOrder.n/a')

		return rvVat
	}

	const renderSupplierNewTag = (supplier = {}) => {
        if (_.isEmpty(supplier) || supplier.bankName && supplier.bankAccountNumber) { return null }

        return <Tag color="gold">NEW</Tag>
    }

	const renderImage = (dataIndex) => {
        if (!dataIndex || !dataIndex.length) return null
        return dataIndex.map((image) => {
            return (
								<div className='image-container'>
									<Image key={`attached_file_${image.id}`}
										contentType={'image/jpeg'}
										originalUrl={_.get(image, 'file.original', null)}
										thumbUrl={_.get(image, 'file.small', null)}
										isImageBlob={false} />
								</div>
						)
				})
    }

		const renderHtmlString = (htmlString, isTransfer) => {
        const text = new ReactHtmlParser(
            htmlString
        )

        return (
            <div>
                <span style={{ fontWeight: 'bold' }}>
                    { isTransfer ? i18n.t('suppliers:transferForm.note') : 'Note' }:
                </span>
                <span>{text}</span>
            </div>
        )
    }

	const renderSlip = (dataIndex) => {
        if (!dataIndex || !dataIndex.length) return null
        return dataIndex.map((image, index) => {
            const noticeText = [
                "เอกสารใบกำกับภาษี สำเนา ส่งมาทางไลน์",
                "เอกสารใบกำกับภาษี ตัวจริง ส่งมาที่ สนง.ใหญ่สีลมเท่านั้น",
                "เอกสารให้คนรถ ต้องไม่มีราคา"
            ]

            return (
				<div className='image-container'>
					<Image key={_.get(image, 'id', `slip-${index}`)}
						contentType={'image/jpeg'}
						originalUrl={_.get(image, 'file.original', null)}
						thumbUrl={_.get(image, 'file.small', null)}
						isImageBlob={false}
						noticeText={noticeText} />
				</div>
            )
        })
    }

	const renderDownloadPDF = (rows) => {
        return (
            <a onClick={() => dispatch(fetchSupplierOrderPDF(rows.id))}>
				<PrinterOutlined />
            </a>
        )
    }

	const renderChangeStateToChosenByOshBtn = (supplierOrder) => {
        const id = _.get(supplierOrder, 'id', '')
        return (
            <Button
                onClick={() => dispatch(changeStateTo({
                    state: 'chosen_by_osh',
                    id,
                    onSuccess: () => { dispatch(setNotifications('success', 'success', 'success')) },
                    onError: () => { dispatch(setNotifications('error', 'error', 'error')) }
                }))}
                size="small"
                type="primary"
            >
                {i18n.t('suppliers:supplierOrder.orderState.chosenByOsh')}
            </Button>
        )
    }

	const supplierOrderColumns = () => {
		return (
			[
				{
					title: i18n.t('suppliers:supplierOrder.shippingCostTotal'),
					width: 100,
					key: 'truck-reservation-costs-sum',
					dataIndex: 'displayTruckReservationCostsSum',
					render: (truckReservationCostSum, row) => {
						return (
							<div>
								{renderTruckReservationCost(truckReservationCostSum, row.order)}
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierOrder.rv'),
					width: 100,
					key: 'rv-vat',
					render: (row) => {
						return (
							<div>
								{renderRvYield(row.order)}
							</div>
						)
					}
				},
				{
					title: `${i18n.t('suppliers:supplierOrder.refCode')} / Link`,
					width: 180,
					key: 'ref-code',
					render: (value, rows) => {
						const order = value.order
						return (
							<div>
								<a key={rows.id}
									onClick={() => toggleSupplierOrderForm({
										id: rows.id,
										orderId: rows.order.number,
										supplierOrderType: rows.supplierOrderType
										})}>
									{value.refCode}
								</a>
								<Divider style={{ margin: '4px' }}/>
								<div>
									<Link key={'order'} to={`/inquiries/${order.number}`}>
										{ order.number }
									</Link>
								</div>
								<div>
									<Link to={`/orders/${order.number}/accountings`} target="_blank"
										key={'accounting'}>
										<CreditCardOutlined style={{ margin: '0 3px' }} />
										Accounting
									</Link>
								</div>
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierName'),
					width: 150,
					key: 'supplier',
					render: (record) => {
						const order = record.order
						const doNotTransfer = order.doNotTransfer
						const plateNumberList = record.trucks.map((truck) => {
							return truck.licensePlateNumber
						}).join(', ')

						const supplier = record.supplier
						const pv = (
							<div>
								<span style={{ fontWeight: 'bold' }}>
									{i18n.t('suppliers:supplierOrder.withHoldingTax3Per')}:
								</span>
								<span>
									{_.get(record, 'paymentVoucher.displayAmount', '0.00')}
								</span>
							</div>
						)

						return (
							<div>
								<div style={_.get(supplier, 'isVerified') ? { color: 'red' } : {}}>
									{record.supplierName} {renderSupplierNewTag(supplier)}
								</div>
								{record.paymentType ?
									<div>({i18n.t(`suppliers:supplierOrder.${_.camelCase(record.paymentType)}`)})</div> :
									null}
								{plateNumberList ? <div>[{plateNumberList}]</div> : null}
								{record.isAvailable ? <Tag color="red">{i18n.t('suppliers:quickTransfer')}</Tag> : null}
								{doNotTransfer ? <Tag color="red">{i18n.t('suppliers:doNotTransfer')}</Tag> : null}
								{_.get(supplier, 'conditionNote') ?
									<div>
										<span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers:conditionNote')}: </span>
										<span>
											{_.get(supplier, 'conditionNote')}
										</span>
									</div> : null
								}
								<div>
									<span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers/overTransferAccounts:balance')}: </span>
									<span>
										{_.get(supplier, 'supplierOverTransferAccountBalance.display', '0.00')}
									</span>
								</div>
								{record.supplierOrderType === 'profit_sharing' && !_.isEmpty(record.paymentVoucher) && pv}
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierOrder.attachment'),
					width: 100,
					key: 'attachments',
					dataIndex: 'images',
					render: (dataIndex, row) => {
						const whoRequestForPayment = row.whoRequestForPaymentFirstName
						const type = row.supplierOrderType
						const isTransfer = ['transfer_back', 'miscellaneous_transfer'].includes(type)

						return (
							<div className="image-container">
								{renderImage(dataIndex)}
								{ whoRequestForPayment ? <div>({whoRequestForPayment})</div> : null }
								{renderHtmlString(row.note, isTransfer)}
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierOrder.slip'),
					width: 100,
					key: 'slips',
					dataIndex: ['paymentVoucher', 'images'],
					render: (dataIndex) => (
						<div className="image-container">
							{renderSlip(dataIndex)}
						</div>
					)
				},
				{
					title: i18n.t('suppliers:supplierLineItems.pvAmount'),
					width: 100,
					key: 'pv-amount',
					render: (values) => {
						const grandTotalAfterWithdraw = _.get(values, 'grandTotalAfterWithdraw.raw')
						const grandTotal = _.toNumber(_.get(values, 'grandTotal.raw'))
						const paymentVoucherAmount = _.get(values, 'paymentVoucher.amount.display', '')
						const supplierOrderGrandTotal = _.get(values, 'grandTotal.display', '')

						const order = _.get(values, 'order', '')
						const yieldValue = _.toNumber(_.get(order, 'orderYield.yield.raw', 0))

						return (
							<div className={classNames({ paymentVoucher: supplierOrderGrandTotal !== paymentVoucherAmount })}>
								{yieldValue < 0 && <span>(-) </span>}
								<Typography.Text disabled={grandTotalAfterWithdraw}>
									{paymentVoucherAmount}
								</Typography.Text>
								{grandTotalAfterWithdraw ? (
									<Tag color={grandTotal > _.toNumber(grandTotalAfterWithdraw) ? 'red' : 'green'}>
										{_.get(values, 'grandTotalAfterWithdraw.display')}
									</Tag>
								) : null}
								{_.get(values, 'sumDepositAmount.raw') === '0' ? null : (
									<div>
										[{i18n.t('suppliers:supplierOrder.deposit')} {_.get(values, 'sumDepositAmount.display')}]
									</div>
								)}
								{_.get(values, 'sumWithdrawAmount.raw') === '0' ? null : (
									<div>
										[{i18n.t('suppliers:supplierOrder.withdraw')} {_.get(values, 'sumWithdrawAmount.display')}]
									</div>
								)}
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierOrder.transferDate'),
					width: 120,
					key: 'transfer-date',
					render: (row) => {
						const transferDate = _.get(row, 'paymentVoucher.transferDate', null)
						const dateOfPurchase = _.get(row, 'paymentVoucher.dateOfPurchase', null)

						return (
							<div>
								<div>
									{transferDate ?
										moment(transferDate, 'YYYY-MM-DD').format('D MMM YYYY') : '-'}
								</div>
								<div>
									({dateOfPurchase ?
										moment(dateOfPurchase, 'YYYY-MM-DD').format('D MMM YYYY') : '-'})
								</div>
							</div>
						)
					}
				},
				{
					title: i18n.t('suppliers:supplierOrder.shippingDate'),
					width: 120,
					key: 'shipping-date',
					defaultSortOrder: 'descend',
					render: (row) => {
						return (
							<div>
								<div>
									{row.shippingDate}
								</div>
							</div>
						)
					}
				},
				{
					key: 'actions',
					width: 150,
					render: (rows) => {
						return (
							<div className="actions">
								{renderDownloadPDF(rows)}

								{(_.get(rows, 'state', '') === 'need_accounting_review') &&
									<React.Fragment>
										<Divider type="vertical" />
										{renderChangeStateToChosenByOshBtn(rows)}
									</React.Fragment>
								}
							</div>
						)
					}
				},
				{
					key: 'is-exported',
					width: 150,
					render: (row) => {
						const isExported = _.get(row, 'paymentVoucher.isExported', null)
						return (
							<div>
								{isExported ? i18n.t('suppliers:supplierOrder.isExported') : ''}
							</div>
						)
					}
				}
			]
		)
	}

	const renderRowSelection = () => {
			const rowSelection = {
					onChange: (selectedRowKeys, selectedRows) => {
						setSelectedRows(selectedRows)
					}
			}

			return rowSelection
	}

	const pagination = {
        current: page,
        pageSize: pageSize,
        total: pageSize * totalPages,
				showSizeChanger: false
    }

	return (
		<Table columns={supplierOrderColumns()}
			scroll={isMobile ? { x: 1300 } : { x: 1000 }}
			rowKey={supplierOrder => supplierOrder.id}
			dataSource={supplierOrders}
			loading={loading}
			pagination={pagination}
			onChange={handleTableChange}
			rowSelection={renderRowSelection()}
		/>
	)
}

SupplierOrderTable.propTypes = {
	toggleSupplierOrderForm: PropTypes.func.isRequired,
	handleTableChange: PropTypes.func.isRequired,
	setSelectedRows: PropTypes.func,
	totalPages: PropTypes.number,
	supplierOrders: PropTypes.array,
	loading: PropTypes.bool,
	pageSize: PropTypes.number,
	page: PropTypes.number
}

SupplierOrderTable.defaultProps = {
	totalPages: 1,
	page: 1,
	supplierOrders: [],
	pageSize: 20,
	setSelectedRows: () => {}
}

export default ImagePreviewContainer(SupplierOrderTable)
