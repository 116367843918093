import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import ChartOfAccountsList from '../../components/chrageOfAccounts/ChartOfAccountsList'

import actions from '../../redux/chartOfAccounts/actions'

const {
  fetchChartOfAccounts,
  setChartOfAccountModal,
  deleteChartOfAccount
} = actions

class ChartOfAccountsListContainer extends Component {
  componentDidMount () {
    const { fetchChartOfAccounts } = this.props
    fetchChartOfAccounts()
  }

  getChartOfAccounts () {
    const { chartOfAccountEntities, chartOfAccounts } = this.props
    return chartOfAccounts.get('items', new List()).map((id) => {
        return chartOfAccountEntities.get(id) || new Map()
    })
  }

  toggleChartOfAccountForm = ({ selected = null } = {}) => {
    const { setChartOfAccountModal, chartOfAccounts } = this.props

    setChartOfAccountModal({ visible: !chartOfAccounts.get('showChartOfAccountForm'), selected })
  }

  handleDeleteChartOfAccount = (id) => {
    this.props.deleteChartOfAccount({ id })
  }

  handlePageChange = (pagination) => {
    this.props.fetchChartOfAccounts({
      page: pagination.current
    })
  }

  render() {
    const { chartOfAccounts } = this.props

    return (
      <ChartOfAccountsList
        onTableChange={this.handlePageChange}
        loading={chartOfAccounts.get('loading')}
        pagination={{
          ...chartOfAccounts.get('pagination').toJS(),
          showSizeChanger: false
        }}
        onDeleteChartOfAccount={this.handleDeleteChartOfAccount}
        showChartOfAccountForm={chartOfAccounts.get('showChartOfAccountForm')}
        chartOfAccountId={chartOfAccounts.get('chartOfAccountSelected')}
        toggleChartOfAccountForm={this.toggleChartOfAccountForm}
        chartOfAccounts={this.getChartOfAccounts().toJS()} />
    )
  }
}

const mapStateToProps = (state) => ({
  chartOfAccountEntities: state.getIn(['Entities', 'chartOfAccounts']),
  chartOfAccounts: state.get('chartOfAccounts')
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    fetchChartOfAccounts,
    setChartOfAccountModal,
    deleteChartOfAccount
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccountsListContainer)
