import React from 'react'
import { Modal } from 'antd'
import InquiryContainer from '../../../../../containers/Inquiry/InquiryContainer'

const CardModal =  ({showModal, setShowModal, orderNumber}) => {

  return (
    <Modal title={orderNumber} visible={showModal} 
      width={1024}
      onOk={setShowModal} 
      onCancel={setShowModal}>
      <InquiryContainer id={orderNumber} />
    </Modal>
  )
}

export default CardModal