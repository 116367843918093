import React from 'react'
import PropTypes from 'prop-types'
import { Table, Divider, DatePicker, Row, Modal, Select, Button, Col, Popconfirm, Menu, Dropdown } from 'antd'
import {
    PrinterOutlined,
    LoadingOutlined,
    DownloadOutlined,
    MailOutlined,
    ProfileTwoTone,
    DownOutlined,
    EditTwoTone
} from '@ant-design/icons'
import _ from 'lodash'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SENDING_STATE } from '../../constants/taxInvoices'
import OrderBusinessAddressFormContainer from '../../containers/Invoices/OrderBusinessAddressFormContainer'
import OrderInvoiceItemsFormContainer from '../../containers/Invoices/OrderInvoiceItemsFormContainer'
import Image from '../../containers/Image'
import imagePreviewContainer from '../../containers/ImagePreview/ImagePreviewContainer'
import OrderInvoicesWrapper from '../Invoices/orderInvoices.style'
import DropdownPdfPreview from '../../containers/Orders/DropdownPdfPreview'
import TrackingInvoiceForm from '../../containers/Invoices/TrackingInvoiceForm'
import SendingStateForm from '../../containers/TaxInvoices/SendingStateForm'
import OrderMailingAddressFormContainer from '../../containers/TaxInvoices/OrderMailingAddressFormContainer'

const { RangePicker } = DatePicker

const renderInvoiceImages = (images) => {
    return images.map((image) => {
        const imageId = image.id
        const thumbUrl = image.file && image.file.s || ''
        const originalUrl = image.file && image.file.original || ''
        const contentType = image.file && image.file.content_type || ''

        return (
            <Image
                key={imageId}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl} />
        )
    })
}

const TaxInvoices = ({
    i18n,
    defaultHaveTrackingNumber,
    defaultInvoiceDate,
    defaultSendingState,
    invoiceReportLoading,
    loading,
    mailsSending,
    onDownloadInvoice,
    onEditOrderBusinessAddress,
    onEditOrderBusinessAddressItems,
    onEditOrderMailingAddress,
    onExportTaxInvoiceReport,
    onInvoiceDateChange,
    onSaveInvoiceItemsSuccess,
    onSaveOrderBusinessAddress,
    onSelectTruckingNumber,
    onSendingStateChange,
    onSendTaxInvoiceEmails,
    onTableChange,
    orderBusinessAddressIdActive,
    orderMailingAddressIdActive,
    orderNumberActive,
    pagination,
    queryString,
    showFilterSection,
    showInvoiceItemsFormModal,
    showInvoicesFormModal,
    showMailingAddressFormModal,
    taxInvoiceLoading,
    taxInvoices,
    handleSendETax,
    sendETaxSaving
}) => {
    const renderDropDownPdfPreview = (text, id, orderId, type) => {
        return (
            <DropdownPdfPreview
                icon={<PrinterOutlined />}
                onClick={(locale) => onDownloadInvoice(id, orderId, type, locale)}
                size="small"
                style={{ margin: '2px' }}
                text={text}
                loading={taxInvoiceLoading}
            />
        )
    }

    const sendingStateOptions = () => {
        return SENDING_STATE.map((sendingState, index) => {
            return (
                <Select.Option key={index} value={sendingState.value}>
                    {i18n.t(`documents:reports.${sendingState.i18n}`)}
                </Select.Option>
            )
        })
    }

    const renderExportTaxInvoices = () => {
        return (
            <Button
                icon={invoiceReportLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                onClick={onExportTaxInvoiceReport}>
                {i18n.t('documents:taxInvoiceReport.exportFollowingFilter')}
            </Button>
        )
    }

    const renderSendTaxInvoiceEmails = () => {
        return (
            <Popconfirm title={i18n.t('confirms:invoice.confirmSendEmailToCustomer')}
                onConfirm={onSendTaxInvoiceEmails}>
                <Button icon={mailsSending ? <LoadingOutlined /> : <MailOutlined />}>
                    {i18n.t('confirms:invoice.sendTaxInvoiceToCustomers')}
                </Button>
            </Popconfirm>
        )
    }

    const renderFilterSection = () => {
        return (
            <div>
                <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
                    <Col span={11}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orders/yields:searchByInvoiceDate')}:
                        </span>
                        <RangePicker
                            defaultValue={defaultInvoiceDate}
                            onChange={onInvoiceDateChange}
                        />
                    </Col>
                    <Col span={7}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('confirms:invoice.sendingState')}:
                        </span>
                        <Select style={{ width: '200px' }} allowClear
                            defaultValue={defaultSendingState}
                            onChange={onSendingStateChange}
                            placeholder={i18n.t('confirms:invoice.sendingState')}>
                            {sendingStateOptions()}
                        </Select>
                    </Col>
                    <Col span={6}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('confirms:invoice.trackingNumber')}:
                        </span>
                        <Select style={{ width: '100px' }} allowClear
                            defaultValue={defaultHaveTrackingNumber}
                            onChange={onSelectTruckingNumber}
                            placeholder={i18n.t('confirms:invoice.trackingNumber')}>
                            <Select.Option value="true">
                                {i18n.t('confirms:invoice.has')}
                            </Select.Option>
                            <Select.Option value="false">
                                {i18n.t('confirms:invoice.hasNo')}
                            </Select.Option>
                        </Select>
                    </Col>
                </Row>
                {renderExportTaxInvoices()}
                <span style={{ marginLeft: '16px' }}>
                    {renderSendTaxInvoiceEmails()}
                </span>
                <Divider />
            </div>
        )
    }

    const renderSendETaxButton = (row, type) => {
        return (
            <Popconfirm title={i18n.t('confirms:invoice.confirmSendElectronicCreditNote')}
                onConfirm={() => handleSendETax(row.id, type, row.order_number)}>
                <Button block size="small" loading={sendETaxSaving}><MailOutlined />{i18n.t('confirms:invoice.sendETax')}</Button>
            </Popconfirm>
        )
    }

    const columns = [
        {
            key: 'invoice_no',
            title: i18n.t('confirms:invoice.invoiceNo'),
            sorter: true,
            render: (row) => {
                return (
                    <div>
                        <div>{row.invoice_no}</div>
                        <Link to={`/customers/${row.customer_no}`}>
                            {row.customer_name}
                        </Link>
                    </div>
                )
            }
        },
        {
            key: 'invoice_detail',
            title: i18n.t('confirms:invoice.taxInvoice'),
            width: '240px',
            render: (row) => {
                const invoiceDate = row.invoice_date ? moment(row.invoice_date).format('DD MMM YYYY') : null
                const paymentDate = row.payment_date ? moment(row.payment_date).format('DD MMM YYYY') : null
                const orderNumber = row.order_number
                return (
                    <div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('confirms:invoice.invoiceDate')}: </span>
                            {invoiceDate}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('confirms:invoice.paymentDate')}: </span>
                            {paymentDate}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('confirms:invoice.paymentAccount')}: </span>
                            {row.payment_account}
                        </div>
                        <div>
                            <Link to={`/orders/${orderNumber}/accountings`} >
                                {orderNumber}
                            </Link>
                        </div>
                    </div>
                )
            }
        },
        {
            key: 'address_detail',
            title: i18n.t('confirms:invoice.businessAddress'),
            render: (row) => {
                if (row.invoice_type === 'outside') {
                    return (
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('confirms:invoice.type')}: </span>
                            {i18n.t('confirms:invoice.createFormOutside')}
                        </div>
                    )
                }

                const address = [
                    _.get(row, 'address_detail', ''),
                    _.get(row, 'district.name', ''),
                    _.get(row, 'sub_district', ''),
                    _.get(row, 'province.name', ''),
                    _.get(row, 'postal_code', '')
                ].filter(Boolean).join(' ')

                return (
                    <div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('confirms:invoice.taxId')}: </span>
                            {row.tax_number}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('addresses:name')}: </span>
                            {row.company_name}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('addresses:branch')}: </span>
                            {row.branch || ''}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('addresses:address')}: </span>
                            {address}
                        </div>
                    </div>
                )
            }
        },
        {
            key: 'mailing_address',
            title: i18n.t('confirms:invoice.mailingAddress'),
            dataIndex: 'order_mailing_address'
        },
        {
            key: 'grand_total',
            title: i18n.t('orders/yields:grandTotal'),
            dataIndex: 'grand_total'
        },
        {
            key: 'invoice_tracking',
            title: i18n.t('confirms:invoice.invoiceTracking'),
            dataIndex: 'invoice_tracking',
            render: (dataIndex, record) => {
                const invoiceTrackingNMenu = (
                    <Menu onClick={({ key }) => {
                        onSaveOrderBusinessAddress({
                            id: record.id,
                            orderId: record.order_number,
                            address: { invoice_tracking: key }
                        })
                    }}>
                        <Menu.Item key="pending">
                            {i18n.t('confirms:invoice.invoiceTrackings.pending')}
                        </Menu.Item>
                        <Menu.Item key="follow_up">
                            {i18n.t('confirms:invoice.invoiceTrackings.followUp')}
                        </Menu.Item>
                        <Menu.Item key="sent">
                            {i18n.t('confirms:invoice.invoiceTrackings.sent')}
                        </Menu.Item>
                    </Menu>
                )

                return (
                    <Dropdown overlay={invoiceTrackingNMenu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {i18n.t(`confirms:invoice.invoiceTrackings.${_.camelCase(dataIndex)}`)} <DownOutlined />
                        </a>
                    </Dropdown>
                )
            }
        },
        {
            key: 'action',
            width: '240px',
            title: i18n.t('confirms:invoice.edit'),
            render: (row) => (
                <div>
                    <a onClick={() => onEditOrderBusinessAddress(row.id, row.order_number)}>
                        <div><EditTwoTone /> {i18n.t('confirms:invoice.edit')}</div>
                    </a>
                    <a onClick={() => onEditOrderMailingAddress(row.order_mailing_address_id, row.order_number)}>
                        <div><EditTwoTone /> ที่อยู่จัดส่งใบกำกับ </div>
                    </a>
                    { row.invoice_type !== 'outside' ?
                        <a onClick={() => onEditOrderBusinessAddressItems(row.id, row.order_number)}>
                            <div><ProfileTwoTone /> {i18n.t('confirms:invoice.lineItems')}</div>
                        </a> : null
                    }

                    <TrackingInvoiceForm invoice={row} orderId={row.order_number} />
                    <SendingStateForm invoice={row} orderId={row.order_number} />
                </div>
            )
        },
        {
            key: 'download',
            title: i18n.t('confirms:invoice.loadTaxInvoice'),
            render: (row) => {
                if (row.invoice_type === 'outside') {
                    return (
                        <OrderInvoicesWrapper>
                            <div className="image-container">
                                {renderInvoiceImages(row.images)}
                            </div>
                        </OrderInvoicesWrapper>
                    )
                } else {
                    return (
                        <div>
                            {renderDropDownPdfPreview(i18n.t('confirms:invoice.taxInvoiceExample'), row.order_number, row.id, 'tax_invoice')}
                            {row.is_sent_e_tax ?
                                <Button icon={<PrinterOutlined />} target="_blank" href={row.e_tax_document_url}>
                                    {i18n.t('confirms:invoice.downloadEtag')}
                                </Button> :
                                renderSendETaxButton(row, 'tax_invoice') }
                        </div>
                    )
                }
            }
        }
    ]

    return (
        <div>
            {showFilterSection ? renderFilterSection() : null}
            <Table
                columns={columns}
                dataSource={taxInvoices}
                loading={loading}
                onChange={onTableChange}
                pagination={pagination}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1080 }}
            />
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderBusinessAddress(null, null)}
                visible={showInvoicesFormModal}
                width="60%">
                <OrderBusinessAddressFormContainer
                    addressId={orderBusinessAddressIdActive}
                    orderId={orderNumberActive}
                    onSaveSuccess={() => onEditOrderBusinessAddress(null, null)} />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderMailingAddress(null, null)}
                visible={showMailingAddressFormModal}
                width="60%">
                <OrderMailingAddressFormContainer
                    id={orderMailingAddressIdActive}
                    orderId={orderNumberActive}
                    defaultQueryString={queryString} />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => onEditOrderBusinessAddressItems(null, null)}
                visible={showInvoiceItemsFormModal}
                width="60%">
                <OrderInvoiceItemsFormContainer orderId={orderNumberActive || ""}
                    invoiceId={orderBusinessAddressIdActive || ""}
                    onSaveSuccess={onSaveInvoiceItemsSuccess} />
            </Modal>
        </div>
    )
}

TaxInvoices.propTypes = {
    loading: PropTypes.bool,
    onDownloadInvoice: PropTypes.func,
    onEditOrderBusinessAddress: PropTypes.func,
    onEditOrderBusinessAddressItems: PropTypes.func,
    onEditOrderMailingAddress: PropTypes.func,
    onExportTaxInvoiceReport: PropTypes.func,
    onSaveInvoiceItemsSuccess: PropTypes.func,
    onSaveOrderBusinessAddress: PropTypes.func,
    onSelectTruckingNumber: PropTypes.func,
    onSendingStateChange: PropTypes.func,
    onTableChange: PropTypes.func,
    orderBusinessAddressIdActive: PropTypes.string,
    orderMailingAddressIdActive: PropTypes.string,
    orderNumberActive: PropTypes.string,
    showInvoiceItemsFormModal: PropTypes.bool,
    showInvoicesFormModal: PropTypes.bool,
    taxInvoiceLoading: PropTypes.bool,
    taxInvoices: PropTypes.array,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })
}

export default withTranslation(['orders/yields', 'confirms', 'documents'])(imagePreviewContainer(TaxInvoices))
