import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

import { Button, Form, Modal, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const SupplierInvoiceImport = ({
    onSubmit,
    onToggleVisibleModal,
    importing,
    visibleModal,
    i18n
}) => {
    const [form] = Form.useForm()

    const handleSubmit = values => {
        const newValues = {
            ...values,
            file: _.get(values, 'file', []).map((file) => _.get(file, 'originFileObj', null))[0]
        }

        onSubmit({ params: newValues })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 }
        }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 12, offset: 12 }
        }
    }

    const normFile = e => {
        let fileList = [...e.fileList]
        fileList = fileList.slice(-1)
        return fileList
    }

    return (
        <div>
            <Button
                key="import-button"
                onClick={onToggleVisibleModal}
                type="primary"
            >
                {i18n.t('supplierInvoices:import')}
            </Button>

            <Modal
                footer={null}
                key="import-modal"
                onCancel={onToggleVisibleModal}
                title={i18n.t('supplierInvoices:import')}
                visible={visibleModal}
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    {...formItemLayout}
                >
                    <Form.Item
                        label={i18n.t('supplierInvoices:selectFile')}
                        extra="excel or csv file"
                        name='file'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                    >
                        <Upload
                            beforeUpload={() => { return false }}
                            listType="text"
                        >
                            <Button>
                                <UploadOutlined /> Click to upload
                            </Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        key="actions"
                        {...formItemLayoutWithOutLabel}
                    >
                        <Button
                            htmlType="submit"
                            loading={importing}
                            type="primary"
                        >
                            {i18n.t('supplierInvoices:import')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

SupplierInvoiceImport.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onToggleVisibleModal: PropTypes.func,
    importing: PropTypes.bool,
    visibleModal: PropTypes.bool
}

SupplierInvoiceImport.defaultProps = {
    onSubmit: () => {},
    onToggleVisibleModal: () => {},
    importing: false,
    visibleModal: false
}

export default withTranslation(['supplierInvoices'])(SupplierInvoiceImport)
