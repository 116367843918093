import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Input, Select } from 'antd'

const { Option } = Select

const range = (size, startAt = 1) => {
    return [...Array(size).keys()].map(i => `${i + startAt}`)
}

const renderRangeOptions = (size, startAt = 1) => {
    return range(size, startAt).map(key => (
        <Option value={key}>{key}</Option>
    ))
}


const MonthAndYearSelector = ({ month, year, onChange, style }) => {
    const { i18n } = useTranslation('salePipelines')
    const currentYear = moment().year()

    return (
        <Input.Group style={style} compact>
            <Select
                allowClear
                key="month-selector"
                onChange={value => onChange({ month: value })}
                placeholder={i18n.t('salePipelines:month')}
                style={{ width: '40%' }}
                value={month || undefined}
            >
                {renderRangeOptions(12)}
            </Select>
            <Select
                allowClear
                key="year-selector"
                onChange={value => onChange({ year: value })}
                placeholder={i18n.t('salePipelines:year')}
                style={{ width: '60%' }}
                value={year || undefined}
            >
                {renderRangeOptions(5, currentYear - 2)}
            </Select>
        </Input.Group>
    )
}

MonthAndYearSelector.propTypes = {
    month: PropTypes.string,
    year: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object
}

MonthAndYearSelector.defaultProps = {
    onChange: () => {},
    style: {}
}

export default MonthAndYearSelector
