import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ReactHtmlParser from 'react-html-parser'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { Spin, Row, Col, Comment, Tooltip, Pagination, Divider } from 'antd'
import ImagePreviewContainer from '../../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../../containers/Image'
import CustomerNoteWrapper from './customerNote.style'

const replaceImgTag = (node, index) => {
    // because old CRM user can paste image to editor directly and
    // that image is uploaded and convert to img tag automatically that
    // have src attribute format is:
    // /{locale}/admin/inquiries/{orderNumber}/order_note_images/{id}/image
    const imageSrc = node.attribs['src']
    const imageSrcArray = imageSrc.split('/')
    const locale = imageSrcArray[1]
    const orderNoteImageId = imageSrcArray[6]

    // we have to convert old src path to api path
    // example: /en/api/v1/orders/note_images/2/image?style=thumb
    const apiPath = `/${locale}/api/v1/orders/note_images/${orderNoteImageId}/image`
    const thumbUrl = apiPath + '?style=thumb'
    const originalUrl = apiPath + '?style=original'

    return (
        <Image key={index}
            thumbUrl={thumbUrl}
            originalUrl={originalUrl}
        />
    )
}

const doChildrenNodeHave = (node, tagName) => {
    if (node.type !== 'tag') { return false }

    const children = node.children
    if (!children) { return false }

    const findResult = children.find((childNode) => {
        return childNode.name === tagName
    })

    if (!findResult) { return false }
    return true
}


const addImgResponsiveToImg = (node, index) => {
    if (node.type !== 'tag') { return }

    if (
        node.name === 'a' &&
    doChildrenNodeHave(node, 'figure') &&
    doChildrenNodeHave(node.children[0], 'img')
    ) {
        const imgNode = node.children[0].children[0]
        return replaceImgTag(imgNode, index)
    }
}


const renderAuthor = (note) => {
    return (
        <a>
            {note.getIn(['staff', 'first_name'])}
        </a>
    )
}

const renderHtmlString = (htmlString) => {
    return new ReactHtmlParser(
        htmlString,
        { transform: addImgResponsiveToImg }
    )
}


const renderNoteAttachments = (note) => {
    return note.get('note_attachments').map((noteAttachment) => {
        const noteAttachmentId = noteAttachment.get('id')
        const thumbUrl = noteAttachment.getIn(['attachment', 'large'])
        const originalUrl = noteAttachment.getIn(['attachment', 'original'])
        const contentType = noteAttachment.getIn(['attachment', 'content_type'])

        return (
            <Image key={noteAttachmentId}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    })
}

const renderOrderNoteImages = (note) => {
    return note.get('order_note_images').map((orderNoteImage) => {
        const orderNoteImageId = orderNoteImage.get('id')
        const thumbUrl = orderNoteImage.getIn(['image', 'thumb']) || ''
        const originalUrl = orderNoteImage.getIn(['image', 'original']) || ''
        const contentType = orderNoteImage.getIn(['image', 'content_type']) || ''

        return (
            <Image key={orderNoteImageId}
                contentType={contentType}
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    })
}

const renderContent = (props, note) => {
    const { i18n } = props

    return (
        <div>
            <div className="order_number">
                {note.getIn(['order', 'id'])}
            </div>

            <div className="gray-topic ">
                {`${i18n.t('customers/notes:form.source')}: ${note.get('source_type') || ''}`}
            </div>

            <div className="gray-topic ">
                {`${i18n.t('customers/notes:form.typeContact')}: ${note.get('type_contact') || ''}`}
            </div>

            <div>
                {renderHtmlString(note.get('note'))}
            </div>

            <div>
                {renderNoteAttachments(note)}
                {renderOrderNoteImages(note)}
            </div>
            <Divider />
        </div>
    )
}

const listNoteCollection = (props) => {
    const { notes } = props

    return notes.map((note, index) => {
        return (
            <CustomerNoteWrapper key={index}>
                <Comment author={renderAuthor(note)}
                    content={renderContent(props, note)}
                    datetime={(
                        <Tooltip title={note.getIn(['localized_time', 'created_at'])}>
                            <span>{moment(note.get(['time', 'created_at'])).fromNow()}</span>
                        </Tooltip>
                    )} />
            </CustomerNoteWrapper>
        )
    })
}

const CustomerNote = props => {
    const { handlePageChange, loading, pagination } = props

    if (loading) { return <Spin /> }

    const current = pagination.get('current')
    const total = pagination.get('total')
    const per = pagination.get('defaultPagesSize')

    return (
        <div>
            <Row>
                <Col span={24}>
                    {listNoteCollection(props)}
                    <CustomerNoteWrapper>
                        <div className="pagination">
                            <Pagination current={current}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                pageSize={per}
                                total={total} />
                        </div>
                    </CustomerNoteWrapper>
                </Col>
            </Row>
        </div>
    )
}

CustomerNote.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    notes: ImmutablePropTypes.list.isRequired,
    pagination: PropTypes.object.isRequired
}

export default withTranslation('customers/notes')(ImagePreviewContainer(CustomerNote))
