import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'
import _ from 'lodash'

import {
    orderSummariesSchema,
    orderSummarySalesSchema
} from '../../schema'

import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_SUMMARY_DASHBOARDS_FETCH_REQUEST,
    ORDER_SUMMARY_DASHBOARDS_FETCH,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_REQUEST,
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_REQUEST,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES,
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS,
    ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION
} = actions

export function *orderSummariesRequest () {
    yield takeEvery(ORDER_SUMMARY_DASHBOARDS_FETCH_REQUEST, function *({ payload }) {
        const {
            query,
            state,
            bySale,
            byCs,
            startDate,
            endDate,
            startReservationDate,
            endReservationDate,
            byLeadSource,
            page,
            sorter,
            filterBy,
            isBigSales,
            byPotentialLead,
            byPurchaser,
            isWaitingToShip,
            isWaitingForProduction,
            noPrice,
            createdStartDate,
            createdEndDate,
            withoutCs,
            withoutSale,
            isMyOrders,
            isOnlinePaid,
            dateType,
            taskState,
            startPaymentDate,
            endPaymentDate,
            provinceId
        } = payload

        let queryString = [
            `query=${_.toString(query)}`,
            `page=${_.toString(page)}`,
            `per=${PAGE_SIZE}`,
            `state=${_.toString(state)}`,
            `by_sale=${_.toString(bySale)}`,
            `by_cs=${_.toString(byCs)}`,
            `start_date=${_.toString(startDate)}`,
            `end_date=${_.toString(endDate)}`,
            `start_reservation_date=${_.toString(startReservationDate)}`,
            `end_reservation_date=${_.toString(endReservationDate)}`,
            `by_lead_source=${_.toString(byLeadSource)}`,
            `order=${_.get(sorter, 'value', '')}`,
            `is_big_sales=${isBigSales}`,
            `by_potential_lead=${_.toString(byPotentialLead)}`,
            `by_purchaser=${_.toString(byPurchaser)}`,
            `is_waiting_to_ship=${isWaitingToShip}`,
            `is_waiting_for_production=${isWaitingForProduction}`,
            `no_price=${_.toString(noPrice)}`,
            `created_start_date=${createdStartDate}`,
            `created_end_date=${createdEndDate}`,
            `without_cs=${withoutCs}`,
            `without_sale=${withoutSale}`,
            `by_current_staff=${isMyOrders}`,
            `is_online_paid=${isOnlinePaid}`,
            `date_type=${dateType}`,
            `task_state=${taskState}`,
            `start_payment_date=${startPaymentDate}`,
            `end_payment_date=${endPaymentDate}`,
            `province_id=${provinceId}`,
        ].join('&')

        let filterOrderBy = filterBy
        if (filterBy.customer_id) {
            filterOrderBy = `&by_customer=${filterBy.customer_id}`
        } else if (filterBy.company_project_id) {
            filterOrderBy = `&by_company_project=${filterBy.company_project_id}`
        } else {
            filterOrderBy = ''
        }

        const path = `/order_summaries?${queryString}${filterOrderBy}&page=${page}&per=${PAGE_SIZE}`

        yield put({ type: ORDER_SUMMARY_DASHBOARDS_FETCH })

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, orderSummariesSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS,
            ...normalizeData,
            query,
            state,
            bySale,
            byCs,
            byPurchaser,
            startDate,
            endDate,
            startReservationDate,
            endReservationDate,
            byLeadSource,
            isBigSales,
            byPotentialLead,
            isWaitingToShip,
            isWaitingForProduction,
            isMyOrders,
            isOnlinePaid,
            dateType,
            taskState,
            startPaymentDate,
            endPaymentDate
        })
    })
}

export function *receivedOrderSummariesDashboardStatistics () {
    yield takeEvery(ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS })

        const queryString = _.map(payload, (value, key) => `${_.snakeCase(key)}=${_.toString(value)}`).join('&')
        const data = yield call(apiFetch, `/crm/order_summaries_dashboard/statistics?${queryString}`)

        const statistics = {}
        _.map(data, (value, key) => {
            _.set(statistics, _.camelCase(key), value)
        })

        yield put({
            type: ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS,
            statistics
        })
    })
}

export function *receivedOrderSummariesDashboardSales () {
    yield takeEvery(ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SUMMARY_DASHBOARDS_FETCH_SALES })

        const queryString = _.map(payload, (value, key) => `${_.snakeCase(key)}=${_.toString(value)}`).join('&')
        const data = yield call(apiFetch, `/crm/order_summaries_dashboard/sales?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderSummarySalesSchema)

        yield put({
            type: ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(orderSummariesRequest),
        fork(receivedOrderSummariesDashboardStatistics),
        fork(receivedOrderSummariesDashboardSales),
    ])
}
