import React from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import { Spin } from 'antd'
import SalePipelineFormRender from './render'

const SALE_PIPELINE_QUERY = gql`
    query SalePipelineQuery($number: String!){
      salePipelineQuery(number: $number) {
          id
          customerPersonality
          customerType
          customerTypeLabel
          interestingProduct
          status
          statusLabel
          month
          year
          isPotential
          archived
          staff {
            fullName
          }
      }
    }
`

const UPDAET_SALE_PIPELINE_MUTATION = gql`
    mutation UpdateSalePipeline($orderId: String, $salePipelineId: ID, $salePipelineParams: AdminSalePipelineInput!){
        updateSalePipeline(orderId: $orderId, salePipelineId: $salePipelineId, salePipelineParams: $salePipelineParams){
          salePipeline {
                id
            }
        }
    }
`

const SalePipelineForm = ({
  onCancel,
  onSuccess,
  orderId,
  salePipelineId
}) => {
  const { loading, data } = useQuery(SALE_PIPELINE_QUERY, {
      variables: { number: orderId }
  })

  const [updateSalePipeline, { loading: saving }] = useMutation(UPDAET_SALE_PIPELINE_MUTATION, {
      onCompleted: () => {
          onSuccess()
      }
  })

  const onSubmitForm = (values) => {
      updateSalePipeline({
          variables: {
              orderId,
              salePipelineId: salePipelineId || null,
              salePipelineParams: values
          }
      })
  }

  const salePipeline = (data && data.salePipelineQuery) || {}

  const getInitialValues = () => {
    return {
        customerPersonality: salePipeline.customerPersonality,
        customerType: salePipeline.customerType,
        interestingProduct: salePipeline.interestingProduct,
        status: salePipeline.status,
        month: salePipeline.month,
        year: salePipeline.year,
        archived: salePipeline.archived,
        isPotential: salePipeline.isPotential
    }
  }

  if(loading) return <Spin />

  return (
    <SalePipelineFormRender onCancel={onCancel}
      saving={saving}
      initialValues={getInitialValues()}
      onSubmit={onSubmitForm} />
  )
}

SalePipelineForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  salePipelineId: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
}

SalePipelineForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {}
}

export default SalePipelineForm
