import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'antd'

import TruckReservationDelivereeForm from './form'

const TruckReservationDeliveree = ({
  orderId,
  shippingCostId,
  refetch,
  onClose,
  showForm
}) => {
  const onSaveSuccess = () => {
    refetch()
    onClose()
  }

  return (
    <Modal title="จองรถผ่าน Deliveree" className="truck-reservation-form-modal"
      footer={null}
      onCancel={onClose}
      visible={showForm}
      destroyOnClose>
        <TruckReservationDelivereeForm
            onSuccess={onSaveSuccess}
            orderId={orderId}
            shippingCostId={shippingCostId} />
      </Modal>
  )
}

TruckReservationDeliveree.propTypes = {
  orderId: PropTypes.string.isRequired,
  shippingCostId: PropTypes.string.isRequired,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
  showForm:  PropTypes.bool
}

TruckReservationDeliveree.defaultProps = {
  refetch: () => {},
  onClose: () => {}
}

export default TruckReservationDeliveree
