import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Upload, Button, Input, Form } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import noteImagesAction from '../../../redux/orders/noteImages/actions'

const { saveOrderNoteImage } = noteImagesAction

class NoteImageFormContainer extends Component {
    constructor (props) {
        super(props)

        this.state = {
            fileList: []
        }

        this.formRef = createRef()
    }

  handleChange = (info) => {
      let fileList = info.fileList

      fileList = fileList.slice(-1)

      this.setState({ fileList })
  }

  handleSubmitForm = (values) => {
      const { orderId, saveOrderNoteImage } = this.props

        const image = values.image[0] === undefined ? null : values.image[0].originFileObj

        const params = {
            ...values,
            image
        }
        saveOrderNoteImage(orderId, params)
        this.formRef.current.resetFields()
        this.setState({
            fileList: []
        })
  }

  normFile = (e) => {
      return e.fileList.slice(-1)
  }


  render () {
      const { i18n, orderNoteImages } = this.props
      const saving = orderNoteImages.get('saving')

      return (
          <Form onFinish={this.handleSubmitForm} ref={this.formRef}>
              <Form.Item name='image' getValueFromEvent={this.normFile}>
                      <Upload
                          onChange={this.handleChange}
                          fileList={this.state.fileList}
                          listType="picture"
                          accept="image/*"
                          beforeUpload={() => { return false }} >
                          <Button>
                              <UploadOutlined /> {i18n.t('inquiries:orderNoteImage.selectImage')}
                          </Button>
                      </Upload>
              </Form.Item>
              <p> {i18n.t('inquiries:orderNoteImage.description')} </p>
              <Form.Item name='description'>
                      <Input.TextArea />
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={saving}>
                  {i18n.t('shared:clickToUpload')}
              </Button>
          </Form>
      )
  }
}

const mapStateToProps = state => ({
    orderNoteImages: state.get('orderNoteImages')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderNoteImage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(NoteImageFormContainer))
