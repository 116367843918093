import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import TrackingImage from '../../components/Invoices/trackingImage'

import orderBusinessAddressActions from '../../redux/orders/businessAddresses/actions'
import notificationActions from '../../redux/notifications/actions'

const { deleteTrackingImage } = orderBusinessAddressActions
const { setNotifications } = notificationActions

class TrackingImageContainer extends Component {
    static propTypes = {
        // Props from another component
        orderBusinessAddress: PropTypes.object.isRequired,

        // Inner Props
        deleteTrackingImage: PropTypes.func.isRequired,
        orderBusinessAddresses: ImmutablePropTypes.map.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    onDeleteTrackingImage = () => {
        const { deleteTrackingImage, orderBusinessAddress, setNotifications } = this.props

        deleteTrackingImage({
            orderBusinessAddressId: _.get(orderBusinessAddress, 'id', null),
            onSuccess: () => { setNotifications('success', 'success', 'success') },
            onError: () => { setNotifications('error', 'error', 'error') }
        })
    }

    render () {
        const { orderBusinessAddress, orderBusinessAddresses } = this.props

        return (
            <TrackingImage
                onDeleteTrackingImage={this.onDeleteTrackingImage}
                orderBusinessAddress={orderBusinessAddress}
                saving={orderBusinessAddresses.get('saving', false)}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    orderBusinessAddresses: state.get('orderBusinessAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteTrackingImage,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingImageContainer)
