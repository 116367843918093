import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import SelectCustomerCompany from '../../components/selectCustomerCompany/selectCustomerCompany'

import actions from '../../redux/customers/companies/actions'

const { fetchCompany, searchCompany } = actions

class SelectCustomerCompanyContainer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.string
    }

    componentDidMount () {
        const { value, fetchCompany } = this.props

        if (value) { fetchCompany(value) }
    }

    getCompanies = () => {
        const { customerCompanies, customerCompanyEntities } = this.props

        return customerCompanies.get('items', new List()).map((companyId) => {
            return customerCompanyEntities.get(companyId) || new Map()
        })
    }

    handleSearchCompanies = (query = '') => {
        const { searchCompany } = this.props

        searchCompany(query)
    }

    render () {
        const { value, onChange } = this.props

        return (
            <SelectCustomerCompany
                companies={this.getCompanies().toJS()}
                onChange={onChange}
                onSearch={this.handleSearchCompanies}
                value={value}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    customerCompanies: state.get('customerCompanies'),
    customerCompanyEntities: state.getIn(['Entities', 'customerCompanies'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCompany,
        searchCompany
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCustomerCompanyContainer)

