import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import CustomersIndexContainer from './CustomersIndexContainer'

class CustomerIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Customers | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/customers', name: 'Customers' }
                    ]}>
                    <ContentLayout>
                        <CustomersIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerIndexPage
