import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import TruckOwnerSelect from '../../components/trucks/truckOwnerSelect'

import actions from '../../redux/trucks/actions'

const { fetchTruckOwner, fetchTruckOwners, resetSelectedTruck, setSelectedTruckOwner } = actions

class TruckOwnerSelectContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.shape({
            truck_owner_id: PropTypes.string
        }),

        // Inner Props
        fetchTruckOwner: PropTypes.func.isRequired,
        fetchTruckOwners: PropTypes.func.isRequired,
        resetSelectedTruck: PropTypes.func.isRequired,
        setSelectedTruckOwner: PropTypes.func.isRequired,
        truckOwnerEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: {}
    }

    componentDidMount () {
        this.initSelectedData()
        this.initSelectedOption()
    }

    initSelectedData () {
        const { fetchTruckOwner, value } = this.props

        if (!value) { return null }

        fetchTruckOwner({ id: _.get(value, 'truck_owner_id') })
    }

    initSelectedOption () {
        const { resetSelectedTruck, setSelectedTruckOwner, truckOwnerEntities, value } = this.props

        if (!value) return null

        const truckOwnerId = _.get(value, 'truck_owner_id')
        const truckOwner = truckOwnerEntities.get(_.toString(truckOwnerId), new Map())
        const truckIds = truckOwner.get('trucks', new List())

        setSelectedTruckOwner({ truckOwner: truckOwnerId })
        resetSelectedTruck({ truckIds: truckIds.toJS() })
    }

    getTruckOwners () {
        const { truckOwnerEntities, trucks } = this.props
        const ids = trucks.getIn(['truckOwnerTable', 'items'])
        let truckDrivers = []

        ids.map((id) => {
            const truckOwner = truckOwnerEntities.get(id)

            if (!truckOwner) { return null }
            truckDrivers.push(truckOwner.toJS())
        })
        return truckDrivers
    }

    onChange = (ownerId) => {
        const { onChange, setSelectedTruckOwner } = this.props

        setSelectedTruckOwner({ truckOwner: ownerId })

        onChange({ truckOwnerId: ownerId })
    }

    onSearch = (query) => {
        const { fetchTruckOwners } = this.props

        fetchTruckOwners({ query, onlyVerified: true })
    }

    render () {
        const { trucks, filedName } = this.props
        const loading = trucks.getIn(['truckOwnerTable', 'loading'])

        return (
            <TruckOwnerSelect
                filedName={filedName}
                loading={loading}
                onChange={this.onChange}
                onSearch={this.onSearch}
                selectedTruckOwner={trucks.get('selectedTruckOwner') || undefined}
                truckOwners={this.getTruckOwners()}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    truckOwnerEntities: state.getIn(['Entities', 'truckOwners']),
    trucks: state.get('trucks')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTruckOwner,
        fetchTruckOwners,
        resetSelectedTruck,
        setSelectedTruckOwner
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckOwnerSelectContainer)
