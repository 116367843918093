import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import SelectLineItems from '../../containers/SelectLineItems'

const SelectInvoiceLineItems = ({
    i18n,
    defaultLineItems,
    initialData,
    loading,
    onFetchInitialData,
    onSelectLineItemsChange,
    orderId
}) => {
    const columns = [{
        title: i18n.t('inquiries:detail'),
        dataIndex: 'name',
        editable: true
    }, {
        title: i18n.t('inquiries:quantity'),
        dataIndex: 'quantity',
        editable: true
    }, {
        title: i18n.t('inquiries:unit'),
        dataIndex: 'unit',
        editable: true
    }, {
        title: i18n.t('inquiries:unitPrice'),
        dataIndex: 'single_price',
        editable: true
    }, {
        title: i18n.t('inquiries:emptyLineBelow'),
        dataIndex: 'empty_line_below',
        editable: true
    }]

    return (
        <SelectLineItems
            columns={columns}
            dataSource={initialData}
            loading={loading}
            onChange={onSelectLineItemsChange}
            onFetch={onFetchInitialData}
            orderId={orderId}
            value={defaultLineItems} />
    )
}

SelectInvoiceLineItems.propTypes = {
    defaultLineItems: PropTypes.array,
    initialData: PropTypes.array,
    loading: PropTypes.bool,
    onFetchInitialData: PropTypes.func,
    onSelectLineItemsChange: PropTypes.func,
    orderId: PropTypes.string.isRequired
}

SelectInvoiceLineItems.defaultProps = {
    defaultLineItems: [],
    onSelectLineItemsChange: () => {}
}

export default withTranslation(['inquiries'])(SelectInvoiceLineItems)
