import { Map, List } from 'immutable'
import actions from './actions'

const {
    STAFFS_FETCH_ROLE_SUCCESS,
    STAFFS_FETCH_SUCCESS,
    STAFFS_SAVE_SUCCESS,
    STAFFS_SET_FORM_ERRORS,
    STAFFS_SET_LOADING,
    STAFFS_SET_ROLE_LOADING,
    STAFFS_SET_SAVING,
    STAFFS_SET_PAGINATION,
    STAFFS_SET_QUERY,
    STAFFS_FETCH_STAFF_SUCCESS
} = actions

const initState = new Map({
    formErrors: new Map(),
    items: new List(),
    loading: false,
    query: '',
    pagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    roleItems: new List(),
    roleLoading: false,
    saving: false
})

export default function staffsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case STAFFS_SET_ROLE_LOADING:
            return state.merge({
                roleLoading: action.loading
            })
        case STAFFS_FETCH_ROLE_SUCCESS:
            return state.merge({
                roleItems: [].concat(result)
            })
        case STAFFS_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        case STAFFS_SAVE_SUCCESS:
            return state.merge({
                items: new List().concat(result)
            })
        case STAFFS_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case STAFFS_SET_LOADING:
            return state.merge({
                loading: action.loading
            })
        case STAFFS_FETCH_SUCCESS:
            return state.merge({
                items: new List().concat(result)
            })
        case STAFFS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case STAFFS_SET_QUERY:
            return state.merge({ query: action.query })
        case STAFFS_FETCH_STAFF_SUCCESS:
            return state.merge({
                items: new List().concat(result)
            })
        default:
            return state
    }
}
