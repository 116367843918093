import React from 'react'
import PropTypes from 'prop-types'
import { Table, Divider } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { isMobile } from 'react-device-detect'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CompleteNoteForm from '../../../containers/Orders/Accountings/CompleteNoteForm'

const renderCompleteNote = (orderYield) => {
    return (
        <CompleteNoteForm
            orderId={_.get(orderYield, 'order.id', '')}
            completeNoteId={_.get(orderYield, 'order.complete_note', '')} />
    )
}

const OrderYieldListTable = ({
    i18n,
    loading,
    onChange,
    orderYields,
    pagination
}) => {
    const columns = [{
        key: 'order-number',
        title: i18n.t('orders/yields:orderNumber'),
        width: 160,
        render: (orderYield) => {
            const orderNumber = _.get(orderYield, 'order.id', '')

            return (
                <div>
                    <Link to={`/inquiries/${orderNumber}`} >
                        {orderNumber}
                    </Link>
                    {renderCompleteNote(orderYield)}
                </div>
            )
        }
    }, {
        key: 'rv',
        title: i18n.t('orders/yields:rv'),
        width: 120,
        render: (orderYield) => {
            const rvChecked = _.get(orderYield, 'order.rv_checked', false)
            if (!rvChecked) return <CloseCircleOutlined />

            const whoCheckIt = _.compact([
                _.get(orderYield, 'order.rv_voucher_checked.checked_by.first_name', ''),
                _.get(orderYield, 'order.rv_voucher_checked.checked_by.last_name', '')
            ]).join(' ')

            return (
                <p>
                    <CheckCircleOutlined />
                    <span> ฿{_.get(orderYield, 'rv.display', '0.00')}</span>
                    <br />
                    <span>{_.get(orderYield, 'order.rv_voucher_checked.updated_at.display', '')}</span>
                    {whoCheckIt &&
                        <>
                            <br />
                            <span>{i18n.t('orders/yields:by')}: {whoCheckIt}</span>
                        </>
                    }
                </p>
            )
        }
    }, {
        key: 'rv-inv-status',
        title: i18n.t('orders/yields:rvInvoiceStatus'),
        width: 160,
        dataIndex: ['order', 'order_business_addresses'],
        render: (invoices) => {
            if (!invoices) return null
            return invoices.map((invoice) => {
                return (
                    <div key={`order-business-address-${_.get(invoice, 'id')}`}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('orders/yields:invoiceNo')}: </span>
                            {invoice.invoice_no}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('orders/yields:invoiceDate')}: </span>
                            {invoice.invoice_date}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{i18n.t('orders/yields:invoiceStatus')}: </span>
                            {i18n.t(`documents:reports.${_.camelCase(invoice.sending_state)}`)}
                        </div>
                        <Divider style={{ margin: '4px 0' }}/>
                    </div>
                )
            })
        }
    }, {
        key: 'pv',
        title: i18n.t('orders/yields:pv'),
        width: 120,
        render: (orderYield) => {
            const pvChecked = _.get(orderYield, 'order.pv_checked', false)
            if (!pvChecked) return <CloseCircleOutlined />

            const whoCheckIt = _.compact([
                _.get(orderYield, 'order.pv_voucher_checked.checked_by.first_name', ''),
                _.get(orderYield, 'order.pv_voucher_checked.checked_by.last_name', '')
            ]).join(' ')

            return (
                <p>
                    <CheckCircleOutlined />
                    <span> ฿{_.get(orderYield, 'pv.display', '0.00')}</span>
                    <br />
                    <span>{_.get(orderYield, 'order.pv_voucher_checked.updated_at.display', '')}</span>
                    {whoCheckIt &&
                        <>
                            <br />
                            <span>{i18n.t('orders/yields:by')}: {whoCheckIt}</span>
                        </>
                    }
                </p>
            )
        }
    }, {
        key: 'pv-inv-status',
        title: i18n.t('orders/yields:pvInvoiceStatus'),
        width: 160,
        dataIndex: 'order_supplier_invoices',
        render: (invoices) => {
            if (!invoices) return null
            return invoices.map((invoice, index) => {
                return (
                    <div>
                        {index + 1}.) {invoice.invoice_ref_code}
                    </div>
                )
            })
        }
    }, {
        key: 'truck-reservation-date',
        title: i18n.t('orders/yields:truckReservationDate'),
        width: 120,
        dataIndex: ['order', 'reservation_date']
    }, {
        key: 'completed-date',
        title: i18n.t('orders/yields:completedDate'),
        width: 120,
        render: (orderYield) => {
            const completeDate = _.get(orderYield, 'complete_state.date.display', '')

            if (_.isEmpty(completeDate)) return <p>-</p>

            const whoMakeItComplete = _.compact([
                _.get(orderYield, 'complete_state.staff.first_name', ''),
                _.get(orderYield, 'complete_state.staff.last_name', '')
            ]).join(' ')

            return (
                <p>
                    {completeDate}
                    <br />
                    <span>{i18n.t('orders/yields:by')}: </span>{whoMakeItComplete}
                </p>
            )
        }
    }, {
        key: 'invoice',
        title: i18n.t('orders/yields:invoice'),
        width: 120,
        render: (orderYield) => {
            const sumInvoicesRaw = _.get(orderYield, 'order.sum_of_invoices.raw', 0)
            const sumInvoicesDisplay = _.get(orderYield, 'order.sum_of_invoices.display', 0)
            const rvChecked = _.get(orderYield, 'order.rv_checked')
            const rv = _.get(orderYield, 'rv.raw')
            const isSumInvoicesEqualToRv = rvChecked && _.isEqual(sumInvoicesRaw, rv)

            return (
                <p>
                    <span style={{ color: isSumInvoicesEqualToRv ? '' : 'red' }} >
                        {`฿${sumInvoicesDisplay}`}
                    </span>
                </p>
            )
        }
    }]

    return (
        <Table
            columns={columns}
            dataSource={orderYields}
            loading={loading}
            onChange={onChange}
            pagination={pagination}
            rowKey="id"
            scroll={isMobile ? { x: 1300 } : { x: 1080 }}
        />
    )
}

OrderYieldListTable.propTypes = {
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    orderYields: PropTypes.array,
    pagination: PropTypes.shape({
        current: PropTypes.number,
        pageSize: PropTypes.number,
        total: PropTypes.number
    })
}

OrderYieldListTable.defaultProps = {
    loading: false,
    onChange: () => {},
    orderYields: [],
    pagination: {
        current: 1,
        pageSize: 20,
        total: 0
    }
}

export default withTranslation(['orders/yields', 'documents'])(OrderYieldListTable)
