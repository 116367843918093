const actions = {
    CATEGORIES_FETCH_REQUEST: 'CATEGORIES_FETCH_REQUEST',
    CATEGORIES_FETCH: 'CATEGORIES_FETCH',
    CATEGORIES_FETCH_SUCCESS: 'CATEGORIES_FETCH_SUCCESS',
    SUB_CATEGORIES_FETCH_REQUEST: 'SUB_CATEGORIES_FETCH_REQUEST',
    SUB_CATEGORIES_FETCH: 'SUB_CATEGORIES_FETCH',
    SUB_CATEGORIES_FETCH_SUCCESS: 'SUB_CATEGORIES_FETCH_SUCCESS',
    PRODUCT_TYPES_FETCH_REQUEST: 'PRODUCT_TYPES_FETCH_REQUEST',
    PRODUCT_TYPES_FETCH: 'PRODUCT_TYPES_FETCH',
    PRODUCT_TYPES_FETCH_SUCCESS: 'PRODUCT_TYPES_FETCH_SUCCESS',

    fetchCategories: () => ({
        type: actions.CATEGORIES_FETCH_REQUEST
    }),
    fetchSubCategories: (categoryId) => ({
        type: actions.SUB_CATEGORIES_FETCH_REQUEST,
        payload: { categoryId }
    }),
    fetchProductTypes: (categoryId, subCategoryId) => ({
        type: actions.PRODUCT_TYPES_FETCH_REQUEST,
        payload: { categoryId, subCategoryId }
    })
}

export default actions
