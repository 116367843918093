import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form } from 'antd'
import { withTranslation } from 'react-i18next'

import ProjectWrapper from './project.style'
import SelectCustomerContainer from '../../containers/SelectCustomer/SelectCustomerContainer'

const AddCustomerForm = ({
    i18n,
    form,
    saving,
    onSubmitForm,
    toggleForm
}) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }
    return (
        <ProjectWrapper>
            <h3>{i18n.t('customerForm.createCustomer')}</h3>
            <Form onFinish={handleSubmitForm} {...formItemLayout}>
                <Form.Item label={i18n.t('customer')}
                    name='customer_id'>
                        <SelectCustomerContainer />
                </Form.Item>

                <Form.Item className="project-form-action-btn" {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                    <Button htmlType="button" onClick={toggleForm}>{i18n.t('shared:close')}</Button>
                </Form.Item>
            </Form>
        </ProjectWrapper>
    )
}

AddCustomerForm.propTypes = {
    saving: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired
}

AddCustomerForm.defaultProps = {
    customerCompanyProject: {},
    saving: false
}

export default withTranslation(['shared'])(AddCustomerForm)

