import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import BusinessAddressFormContainer from './BusinessAddressFormContainer'

class CustomerNewBusinessAddressPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Add Business Address | OSH CRM'
    }

    render () {
        const { match } = this.props
        const { customer_id: customerId } = match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/customers', name: 'Customers' },
                    { path: `/customers/${customerId}`, name: `${customerId}` },
                    { path: `/customers/${customerId}`, name: 'Business Addresses' },
                    { path: `/customers/${customerId}/business_addresses/new`, name: 'New' }
                ]}>
                    <ContentLayout>
                        <BusinessAddressFormContainer customerId={customerId} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerNewBusinessAddressPage
