import React from 'react'
import {Menu, Button} from 'antd'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {CopyOutlined} from '@ant-design/icons'

const CopyMenu = ({orderEntity}) => {

    if (!orderEntity) {
        return null
    }

    const lineItemsText = orderEntity.get('line_items_text')
    const orderNumber = orderEntity.get('id')


    const getCurrentValue = (postfix) => {
        const textarea = document.createElement('textarea')
        let text = ''
        document.body.appendChild(textarea);
        textarea.innerHTML = '&#13;&#10;' + lineItemsText + '&#13;&#10;' + postfix
        text = textarea.value
        document.body.removeChild(textarea)
        return text
    }

    return (
        <Menu>
            <Menu.Item>
                <CopyToClipboard text={ getCurrentValue('รบกวนสอบถามสต็อกสินค้ารายการนี้ครับ มีของเลยไหมครับ ขอบคุณครับ')}>
                    <Button type="dashed" icon={<CopyOutlined/>} shape="round" size="small"> เช็คสต็อก </Button>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item>
                <CopyToClipboard text={getCurrentValue('รบกวนสอบถามราคา และสต็อกสินค้ารายการนี้ครับ รบกวนด้วยครับ ขอบคุณครับ')}>
                    <Button type="dashed" icon={<CopyOutlined/>} shape="round" size="small"> เช็คราคา+สต็อก </Button>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item>
                <CopyToClipboard text={orderNumber + ' คอนเฟิร์มคำสั่งซื้อครับ เดี๋ยวจะมาแจ้งรายละเอียดเข้ารับครับ'}>
                    <Button type="dashed" icon={<CopyOutlined/>} shape="round" size="small"> CF ยังไม่จัดรถ </Button>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item>
                <CopyToClipboard text={orderNumber + ' คอนเฟิร์มสั่งซื้อครับ ฝากโรงงานจัดส่งครับ'}>
                    <Button type="dashed" icon={<CopyOutlined/>} shape="round" size="small"> CF โรงงานส่ง </Button>
                </CopyToClipboard>
            </Menu.Item>
            <Menu.Item>
                <CopyToClipboard text={orderNumber + ' คอนเฟิร์มสั่งซื้อครับ ลูกค้าเข้ารับเองครับ'}>
                    <Button type="dashed" icon={<CopyOutlined/>} shape="round" size="small"> CF ลูกค้าเข้ารับเอง </Button>
                </CopyToClipboard>
            </Menu.Item>
        </Menu>
    )
}

export default CopyMenu
