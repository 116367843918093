import styled from 'styled-components';
const CustomerSheetWrapper = styled.div`
  .right-btn {
    text-align: right;
  }

  .merging {
    width: 25%;
    float: left;
  }

  .mg-bottom {
    margin-bottom: 10px;
  }

  .show-contact {
    font-size: 12px;
    margin-left: 5px;
  }

  .icon-arrow {
    margin-left: 3px;
  }

  .btn-summary {
    border-radius: 4px;
    border: 1px solid;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    display: table;
    padding: 6px;
    margin: 6px 10px;
  }

  .btn-sorting {
    margin-right: 10px;
  }

  .number {
    background-color: whitesmoke;
    border-radius: 50%;
    text-align: center;
    float: right;
    margin-left: 4px;
    min-height: 22px;
    min-width: 22px;
  }

  .hide {
    display: none;
  }

  .customer-info-layout {
    position: relative;
    .wallet-btn {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`;
export default CustomerSheetWrapper;
