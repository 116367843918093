import { PAGE_SIZE } from '../../../constants/customers'

const actions = {
    PROJECT_FETCH_PROFIT_SHARINGS_REQUEST: 'PROJECT_FETCH_PROFIT_SHARINGS_REQUEST',
    PROJECT_FETCH_PROFIT_SHARINGS: 'PROJECT_FETCH_PROFIT_SHARINGS',
    PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS: 'PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS',

    PROJECT_SET_PROFIT_SHARING_PAGINATION: 'PROJECTS_SET_PROFIT_SHARING_PAGINATION',
    PROJECT_SET_PROFIT_SHARING_FORM_MODAL: 'PROJECT_SET_PROFIT_SHARING_FORM_MODAL',

    fetchCompanyProjectProfitSharings: ({ id, page = 1, per = PAGE_SIZE } = {}) => ({
        type: actions.PROJECT_FETCH_PROFIT_SHARINGS_REQUEST,
        payload: { id, page, per }
    }),

    setProfitSharingFormModal: (visible, id) => ({
        type: actions.PROJECT_SET_PROFIT_SHARING_FORM_MODAL,
        visible, id
    })
}

export default actions
