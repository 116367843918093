const actions = {
    RESERVATION_TRUCK_TYPES_FETCH_FAIL: 'RESERVATION_TRUCK_TYPES_FETCH_FAIL',
    RESERVATION_TRUCK_TYPES_FETCH_REQUEST: 'RESERVATION_TRUCK_TYPES_FETCH_REQUEST',
    RESERVATION_TRUCK_TYPES_FETCH_SUCCESS: 'RESERVATION_TRUCK_TYPES_FETCH_SUCCESS',
    RESERVATION_TRUCK_TYPES_FETCHING: 'RESERVATION_TRUCK_TYPES_FETCHING',

    RESERVATION_TRUCK_TYPES_SET_SELECTED: 'RESERVATION_TRUCK_TYPES_SET_SELECTED',

    fetchReservationTruckTypes: ({ query = '', page = 1, per = 20, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.RESERVATION_TRUCK_TYPES_FETCH_REQUEST,
            payload: { query, page, per, onSuccess, onError }
        }
    },

    setReservationTruckTypeSelected: ({ reservationTruckTypeSelected = undefined } = {}) => {
        return {
            type: actions.RESERVATION_TRUCK_TYPES_SET_SELECTED,
            reservationTruckTypeSelected
        }
    }
}

export default actions
