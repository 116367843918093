import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import SupplierInvoiceExport from '../../components/supplierInvoices/export'

import actions from '../../redux/supplierInvoices/actions'
import notificationActions from '../../redux/notifications/actions'

const { exportSupplierInvoices, setExportModal } = actions
const { setNotifications } = notificationActions

const SupplierInvoicesExportContainer = ({ i18n }) => {
    const dispatch = useDispatch()
    const supplierInvoices = useSelector(state => state.get('supplierInvoices'))
    const showExportModal = supplierInvoices.get('showExportModal')
    const exporting = supplierInvoices.get('exporting')

    const onSubmit = ({ params = {} } = {}) => {
        dispatch(exportSupplierInvoices({
            params,
            onSuccess: () => {
                    onToggleExportModal()
                    dispatch(setNotifications(i18n.t('bankStatements:uploadSuccessDescription'), 'success', 'success'))
                },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        }))
    }

    const onToggleExportModal = () => {
        dispatch(setExportModal(!showExportModal))
    }

    return (
        <SupplierInvoiceExport
            onSubmit={onSubmit}
            onToggleVisibleModal={onToggleExportModal}
            visibleModal={showExportModal}
            exporting={exporting}
        />
    )
}

export default withTranslation(['shared', 'bankStatements'])(SupplierInvoicesExportContainer)
