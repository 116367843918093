import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Table, Button } from 'antd'
import { EditTwoTone, } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import customerMailingAddressActions from '../../../redux/customers/addresses/mailing/actions'

const { fetchMailingAddresses } = customerMailingAddressActions

class MailingAddressIndexContainer extends Component {
    componentDidMount () {
        const { fetchMailingAddresses, customerId } = this.props

        fetchMailingAddresses(customerId)
    }

    renderMailingAddresses () {
        const { mailingAddresses, mailingAddressEntities } = this.props

        if (!mailingAddresses) { return null }


        const mailingAddressesList = mailingAddresses.get('items').map((addressId, index) => {
            const address = mailingAddressEntities.get(addressId.toString())

            const id = address.get('id') || ''
            const name = address.get('name') || ''
            const addressDetail = address.get('address') || ''
            const subDistrict = address.get('sub_district') || ''
            const district = address.getIn(['district', 'name']) || ''
            const province = address.getIn(['province', 'name']) || ''
            const postalCode = address.get('postal_code') || ''

            return (
                {
                    key: id,
                    name: name,
                    address: `${addressDetail} ${district} ${subDistrict} ${province} ${postalCode}`
                }
            )
        })

        return mailingAddressesList.toJS()
    }

    render () {
        const { mailingAddresses, i18n, customerId } = this.props

        const columns = [{
            title: i18n.t('addresses:name'),
            dataIndex: 'name',
            key: 'name'
        }, {
            title: i18n.t('addresses:address'),
            dataIndex: 'address',
            key: 'address'
        }, {
            title: i18n.t('addresses:actions'),
            key: 'action',
            dataIndex: 'key',
            render: (dataIndex) => (
                <span>
                    <Link to={`/customers/${customerId}/mailing_addresses/${dataIndex}/edit`}>
                        <EditTwoTone style={{ marginRight: '15px' }} />
                    </Link>
                </span>
            )
        }]

        return (
            <div>
                <Table columns={columns}
                    dataSource={this.renderMailingAddresses()}
                    pagination={false}
                    onChange={this.onTableChange}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    loading={mailingAddresses.get('loading')} />
                <div style={{ marginTop: '16px' }}>
                    <Link to={`/customers/${customerId}/mailing_addresses/new`}>
                        <Button type="primary" size="large">
                            {i18n.t('addresses:addMailingAddress')}
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    mailingAddresses: state.get('mailingAddresses'),
    mailingAddressEntities: state.getIn(['Entities', 'mailingAddresses'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchMailingAddresses
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('addresses')(MailingAddressIndexContainer))
