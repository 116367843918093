import { List, Map } from 'immutable'

import actions from './actions'

const {
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL,
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS,
    ORDER_SCB_PAYMENT_CONFIRMS_FETCHING
} = actions

const initState = new Map({
    items: new List(),
    loading: false
})

export default function scbPaymentConfirmReducers (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL:
            return state.merge({ loading: false })
        case ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case ORDER_SCB_PAYMENT_CONFIRMS_FETCHING:
            return state.merge({ loading: true })
        default:
            return state
    }
}
