import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import RangePickerWithDateType from '../../components/rangePickerWithDateType'

class RangePickerWithDateTypeContainer extends Component {
    static propTypes = {
        // Props from another component

        // dateTypes is an array of object that has shape look like
        // {
        //  label: PropTypes.string,
        //  value: PropTypes.string
        // }
        dateTypes: PropTypes.array,

        onChange: PropTypes.func,
        value: PropTypes.shape({
            date_type: PropTypes.string,
            start_date: PropTypes.string,
            end_date: PropTypes.string
        })
    }

    static defaultProps = {
        dateTypes: [],
        onChange: () => {},
        value: undefined
    }

    constructor (props) {
        super(props)

        this.state = {
            value: !props.value ? { date_type: '', start_date: '', end_date: '' } : props.value
        }
    }

    onDateTypeSelectChange = (dateType) => {
        const { onChange } = this.props
        const { value } = this.state

        const newValue = { ...value, date_type: dateType }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onRangePickerChange = (dates, dateStrings) => {
        const { onChange } = this.props
        const { value } = this.state

        const newValue = { ...value, start_date: dateStrings[0], end_date: dateStrings[1] }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    render () {
        const { value: propValue, dateTypes } = this.props
        const { value: stateValue } = this.state

        const startDate = !propValue ?
            ((stateValue.start_date || undefined) && moment(stateValue.start_date)) :
            ((propValue.start_date || undefined) && moment(propValue.start_date))
        const endDate = !propValue ?
            ((stateValue.end_date || undefined) && moment(stateValue.end_date)) :
            ((propValue.end_date || undefined) && moment(propValue.end_date))

        return (
            <RangePickerWithDateType
                dateTypes={dateTypes}
                dateTypeSelectValue={!propValue ? stateValue.date_type : propValue.date_type}
                onDateTypeSelectChange={this.onDateTypeSelectChange}
                onRangePickerChange={this.onRangePickerChange}
                rangePickerValue={[startDate, endDate]}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RangePickerWithDateTypeContainer)
