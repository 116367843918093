import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import inquiryActions from '../../redux/inquiry/actions'
import actions from '../../redux/trucks/actions'
import imageActions from '../../redux/images/actions'
import provinceActions from '../../redux/provinces/actions'
import TruckForm from '../../components/trucks/truckForm'

const { fetchVehicleTypes } = inquiryActions
const { fetchProvinces } = provinceActions
const { addNewImage } = imageActions

const {
    fetchTruck,
    resetTruckFormError,
    saveTruck
} = actions

class TruckFormContainer extends Component {
    static propTypes = {
        // Props from another component
        id: PropTypes.string,
        onCancel: PropTypes.func,
        ownerId: PropTypes.string.isRequired,

        // Inner props
        fetchTruck: PropTypes.func.isRequired,
        fetchVehicleTypes: PropTypes.func.isRequired,
        inquiry: ImmutablePropTypes.map.isRequired,
        resetTruckFormError: PropTypes.func.isRequired,
        saveTruck: PropTypes.func.isRequired,
        truckEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired,
        vehicleTypeEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        id: '',
        onCancel: () => {}
    }

    async componentDidMount () {
        const { fetchVehicleTypes, fetchTruck, fetchProvinces, id } = this.props

        fetchVehicleTypes()
        fetchProvinces()

        if (!id) return null

        await fetchTruck({ id })
    }

    componentWillUnmount () {
        const { resetTruckFormError } = this.props

        resetTruckFormError()
    }

    getTruck () {
        const { id, truckEntities, vehicleTypeEntities } = this.props

        if (!id) return new Map()

        const truck = truckEntities.get(id.toString())

        if (!truck) return new Map()

        return truck.mergeDeep({
            vehicle_type: vehicleTypeEntities.get((truck.get('vehicle_type', '') || '').toString())
        })
    }

    getVehicleTypes () {
        const { inquiry, vehicleTypeEntities } = this.props

        return inquiry.get('vehicleTypeItems').map((id) => {
            return vehicleTypeEntities.get(id.toString())
        }) || new List()
    }

    getProvinces () {
        const { provinceEntities, provinces } = this.props

        const provinceData = provinces.get('items').map((provinceId) => {
            return provinceEntities.get(provinceId.toString())
        })

        return provinceData || new List()
    }

    getTruckImages = (imageType) => {
        const { addNewImage, truckImageEntities } = this.props

        const truck = this.getTruck()
        const truckImageIds = truck.get('truck_images')
        let fileList = new List()
        if (!truckImageIds) return []

        truckImageIds.map((truckImageId) => {
            const image = truckImageEntities.get(truckImageId)
            const imageId = image.get('id')
            const thumbUrl = image.getIn(['file', 'original']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            addNewImage(thumbUrl, originalUrl || '', contentType)

            if (image.get('image_type') === imageType) {
                fileList = fileList.push({
                    uid: imageId,
                    url: thumbUrl
                })
            }
        })
        return fileList.toJS()
    }

    getTruckImageParams = (truckImages) => {
        const { id, truckEntities, truckImageEntities } = this.props
        const imageIds = truckEntities.getIn([id, 'truck_images'], new List())

        let imagesList = []
        imageIds.map((id) => {
            const image = truckImageEntities.get(id)
            imagesList = imagesList.concat(image)
        })

        const driverImages = truckImages.driver || []
        const driverImageParams = driverImages.map((driverImage) => {
            return ({
                uid: driverImage.uid,
                file: driverImage.originFileObj,
                image_type: 'driver'
            })
        })
        const frontSideImages = truckImages.front_side || []
        const frontSideImageParams = frontSideImages.map((frontSideImage) => {
            return ({
                uid: frontSideImage.uid,
                file: frontSideImage.originFileObj,
                image_type: 'front_side'
            })
        })
        const backSideImages = truckImages.back_side || []
        const backSideImageParams = backSideImages.map((backSideImage) => {
            return ({
                uid: backSideImage.uid,
                file: backSideImage.originFileObj,
                image_type: 'back_side'
            })
        })
        const leftSideImages = truckImages.left_side || []
        const leftSideImageParams = leftSideImages.map((leftSideImage) => {
            return ({
                uid: leftSideImage.uid,
                file: leftSideImage.originFileObj,
                image_type: 'left_side'
            })
        })
        const rightSideImages = truckImages.right_side || []
        const rightSideImageParams = rightSideImages.map((rightSideImage) => {
            return ({
                uid: rightSideImage.uid,
                file: rightSideImage.originFileObj,
                image_type: 'right_side'
            })
        })
        let imageFiles = [
            ...driverImageParams,
            ...frontSideImageParams,
            ...backSideImageParams,
            ...leftSideImageParams,
            ...rightSideImageParams
        ]
        let uploadImage = imageFiles
        let destroyImage = []
        imagesList.map((image) => {
            if (!imageFiles.filter(value => value.uid === image.get('id')).length) {
                destroyImage.push({ id: image.get('id'), _destroy: true })
            } else {
                uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
            }
        })
        return uploadImage.map((image) => {
            return {
                file: image.file,
                image_type: image.image_type,
                _destroy: false
            }
        }).concat(destroyImage)
    }

    onSubmit = (truck) => {
        const { id, ownerId, saveTruck, trucks } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        truck['truck']['truck_owner_id'] = ownerId

        const query = trucks.getIn(['truckOwnerTable', 'query'])
        const truckImageParams = this.getTruckImageParams(truck.truck_images_attributes)

        const params = {
            truck: {
                ...truck.truck,
                truck_images_attributes: truckImageParams
            }
        }

        saveTruck({ type: ownerType, query, id, params: params })
    }

    render () {
        const { onCancel, trucks, imageEntities, truckImageEntities } = this.props

        return (
            <TruckForm
                error={trucks.getIn(['truckForm', 'error'], new Map()).toJS()}
                imageDefault={(imageType) => this.getTruckImages(imageType)}
                imageEntities={imageEntities}
                onCancel={onCancel}
                onSubmit= {this.onSubmit}
                provinces={this.getProvinces().toJS()}
                saving={trucks.getIn(['truckForm', 'saving'])}
                truck={this.getTruck().toJS()}
                truckImageEntities={truckImageEntities}
                vehicleTypes={this.getVehicleTypes().toJS()} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        imageEntities: state.getIn(['Entities', 'images']),
        inquiry: state.get('inquiry'),
        provinceEntities: state.getIn(['Entities', 'provinces']),
        provinces: state.get('provinces'),
        truckEntities: state.getIn(['Entities', 'trucks']),
        truckImageEntities: state.getIn(['Entities', 'truckImages']),
        trucks: state.get('trucks'),
        vehicleTypeEntities: state.getIn(['Entities', 'vehicleTypes'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addNewImage,
        fetchProvinces,
        fetchTruck,
        fetchVehicleTypes,
        resetTruckFormError,
        saveTruck
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckFormContainer)
