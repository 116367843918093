import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import moment from 'moment'
import { Table, Divider, Row, Col, Statistic, DatePicker, Select } from 'antd'
import { Authorization } from '../Authorization'

import actions from '../../redux/salesSummary/actions'
import saleActions from '../../redux/sales/actions'

const { fetchSalesSummary, setSelectedMonth } = actions
const { fetchSale, fetchSales } = saleActions

class SalesSummaryIndexContainer extends Component {
    componentDidMount () {
        const { fetchSalesSummary } = this.props

        fetchSalesSummary()
    }

    componentDidUpdate (prevProps) {
        const { fetchSale, saleSummaries } = this.props
        // initial sale select option
        if (!prevProps.saleSummaries.get('currentSale') && saleSummaries.get('currentSale')) {
            fetchSale({ id: saleSummaries.get('currentSale') })
        }
    }

    getSalesSummary = () => {
        const { saleSummariesEntities, saleSummaries } = this.props
        return saleSummaries.get('items').map((saleSummaryId) => {
            return saleSummariesEntities.get(saleSummaryId)
        })
    }

    handleSearch = (value) => {
        const { fetchSales } = this.props

        fetchSales(value)
    }

    onChangeFilterMonth = (date, dateString) => {
        const { setSelectedMonth, fetchSalesSummary, saleSummaries } = this.props
        setSelectedMonth(dateString)
        fetchSalesSummary({ selectedMonth: dateString, saleId: saleSummaries.get('currentSale') })
    }

    onChangeSale = (value) => {
        const { fetchSalesSummary, saleSummaries } = this.props

        fetchSalesSummary({ selectedMonth: saleSummaries.get('selectedMonth'), saleId: value })
    }

    renderSalesOptions = () => {
        const { sales, saleEntities, i18n } = this.props
        const saleItems = sales.get('items')

        let saleList = saleItems.map((sale) => {
            const firstName = saleEntities.getIn([sale, 'first_name']) || ''
            const lastName = saleEntities.getIn([sale, 'last_name']) || ''
            return (
                <Select.Option key={sale} value={sale}>{firstName} {lastName}</Select.Option>
            )
        })
        return [<Select.Option key={"all"} value={"all"}> {i18n.t('sales:salesSummary.all')} </Select.Option>].concat(saleList)
    }

    renderLinkToProspect = () => {
        const { saleSummaries, i18n } = this.props
        const prospectPerMonthCount = saleSummaries.get('prospectPerMonthCount') || 0

        if (prospectPerMonthCount === 0) {
            return <span>({prospectPerMonthCount} {i18n.t('sales:salesSummary.items')})</span>
        }

        let link = `/sales_summary/${saleSummaries.get('currentSale')}/prospects`
        const selectedMonth = saleSummaries.get('selectedMonth')
        if (!_.isEmpty(selectedMonth)) { link += `?selected_month=${selectedMonth}` }

        return (
            <span>
                <Link to={link}>
                    ({prospectPerMonthCount} {i18n.t('sales:salesSummary.items')})
                </Link>
            </span>

        )
    }

    render () {
        const { sales, saleSummaries, i18n } = this.props
        const salesLoading = sales.get('loading')

        const columns = [
            {
                title: i18n.t('sales:salesSummary.orderNumber'),
                dataIndex: 'order_number',
                render: (number) => {
                    return (
                        <Link to={`/inquiries/${number}`}>
                            {number}
                        </Link>
                    )
                }
            },
            {
                title: i18n.t('sales:salesSummary.grandTotal'),
                dataIndex: 'order_grand_total'
            },
            {
                title: i18n.t('sales:salesSummary.state'),
                dataIndex: 'order_state'
            },
            {
                title: i18n.t('sales:salesSummary.profit'),
                dataIndex: 'margin'
            },
            {
                title: i18n.t('sales:salesSummary.marginPercentage'),
                dataIndex: 'margin_percent'
            }
        ]
        const dateToday = moment()
        const allQuotationsPerMonthCount = saleSummaries.get('allQuotationsPerMonthCount') || 0

        return (
            <div>
                <Row>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.incomingBalancePerDate')}
                            prefix="฿"
                            value={saleSummaries.get('incomingBalancePerDate') || 0.00} />
                    </Col>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.prospectPerMonth')}
                            prefix="฿"
                            value={saleSummaries.get('prospectPerMonth') || 0.00}
                            suffix={this.renderLinkToProspect()}
                        />
                    </Col>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.allQuotationsPerMonth')}
                            prefix="฿"
                            value={saleSummaries.get('allQuotationsPerMonth') || 0.00}
                            suffix={`(${allQuotationsPerMonthCount}  ${i18n.t('sales:salesSummary.items')})`}
                        />
                    </Col>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.incomingBalancePerMonth')}
                            prefix="฿"
                            value={saleSummaries.get('incomingBalancePerMonth') || 0.00}
                            suffix={`(${saleSummaries.get('incomingBalancePerMonthCount')}  ${i18n.t('sales:salesSummary.items')})`}
                        />
                    </Col>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.transferฺBackPerMonth')}
                            prefix="฿"
                            value={saleSummaries.get('transferBackAmount') || 0.00}
                        />
                    </Col>
                    <Col span={4}>
                        <Statistic title={i18n.t('sales:salesSummary.profit')}
                            value={saleSummaries.get('profit') || 0.00}
                            suffix="฿" />
                    </Col>
                </Row>
                <Divider />
                <DatePicker.MonthPicker
                    allowClear={false}
                    placeholder={i18n.t('sales:salesSummary.selectMonth')}
                    defaultValue={moment(dateToday, 'YYYY-MM')}
                    onChange={this.onChangeFilterMonth} />

                <div style={{ float: 'right' }}>
                    <span style={{ marginRight: '10px' }}>{i18n.t('staffs')}</span>
                    <Select
                        showSearch
                        style={{ width: '280px' }}
                        value={saleSummaries.get('currentSale') ? `${saleSummaries.get('currentSale')}` : i18n.t('sales:salesSummary.all')}
                        placeholder={i18n.t('orders:searchSale')}
                        loading={salesLoading}
                        filterOption={false}
                        onSearch={(search) => this.handleSearch(search)}
                        onChange={(value) => this.onChangeSale(value)}
                    >
                        {this.renderSalesOptions()}
                    </Select>
                </div>

                <Divider />
                <Table dataSource={this.getSalesSummary().toJS()}
                    rowKey={row => row.order_number}
                    pagination={false}
                    columns={columns}
                    loading={saleSummaries.get('loading')} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sales: state.get('sales'),
        saleEntities: state.getIn(['Entities', 'sales']),
        saleSummariesEntities: state.getIn(['Entities', 'saleSummaries']),
        saleSummaries: state.get('saleSummaries')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSale,
        fetchSales,
        fetchSalesSummary,
        setSelectedMonth
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('sales')(SalesSummaryIndexContainer))
