import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery, useMutation } from '@apollo/client'

import { Spin } from 'antd'

import TruckReservationLalamoveFormRender from './render'

const SHIPPING_COST_QUERY = gql`
  query ShippingCost($id: ID!){
    shippingCost(id: $id) {
      id
      order {
        id
        number
        customer {
          fullName
          phoneNumber
        }
        orderShippingAddress {
          contactName
          phoneNumber
        }
      }
      sendLalamove {
        id
        serviceType
        pickupLocation
        shippingAddresses {
          id
          address
        }
      }
    }
  }
`

const LALAMOVE_PLACE_ORDER_MUTATION = gql`
    mutation LalamovePlaceOrder($shippingCostId: ID!, $contactParams: AdminOrderContactInput!, $orderNumber: String!){
        lalamovePlaceOrder(shippingCostId: $shippingCostId, contactParams: $contactParams, orderNumber: $orderNumber) {
          errors {
            message
            key
          }
        }
    }
`

const TruckReservationLalamoveForm = ({
  shippingCostId,
  onSuccess,
  orderId
}) => {
  const [errorsMessage, setErrors] = useState(null)

  const { loading, data, error } = useQuery(SHIPPING_COST_QUERY, { variables: { id: shippingCostId } })

  const [onPlaceOrder, { loading: saving }] = useMutation(LALAMOVE_PLACE_ORDER_MUTATION, {
    onCompleted: ({ lalamovePlaceOrder }) => {
      if (lalamovePlaceOrder.errors) {
        setErrors((`${lalamovePlaceOrder.errors.message} หรือ ใบเสนอราคาหมดอายุ กรุณาทำรายการใหม่`))
      } else {
        setErrors(null)
        onSuccess()
      }
    }
  })
  if (loading) return <Spin />

  const shippingCost = (data && data.shippingCost) || {}
  const { order } = shippingCost

  const onSubmit = (params) => {
    onPlaceOrder({
      variables: {
        shippingCostId,
        contactParams: params,
        orderNumber: orderId
      }
    })
  }

  const getShippingAddresses = (shippingAddresses) => {
    if (shippingAddresses.length < 1) return []
    return shippingAddresses.map((shippingAddress) => {
      return {
        sendLalamoveShippingAddressId: shippingAddress.id,
        address: shippingAddress.address
      }
    })
  }

  const getInitialValues = () => {
    const { sendLalamove } = shippingCost
    return {
      serviceType: sendLalamove.serviceType,
      pickupLocation: sendLalamove.pickupLocation,
      locations: getShippingAddresses(sendLalamove.shippingAddresses)
    }
  }

  return (
    <TruckReservationLalamoveFormRender initialValues={getInitialValues()}
      onSubmit={onSubmit}
      customer={order.customer}
      orderShippingAddress={order.orderShippingAddress}
      errorsMessage={errorsMessage}
      saving={saving} />
  )
}

TruckReservationLalamoveForm.propTypes = {
  shippingCostId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
}

TruckReservationLalamoveForm.defaultProps = {
  onSuccess: () => {}
}

export default TruckReservationLalamoveForm
