const actions = {
    // FETCH_TRUCK_RESERVATION_REQUEST: 'FETCH_TRUCK_RESERVATION_REQUEST',
    FETCH_TRUCK_RESERVATION_SUCCESS: 'FETCH_TRUCK_RESERVATION_SUCCESS',
    FETCH_TRUCK_RESERVATIONS_NOT_PAID_REQUEST: 'FETCH_TRUCK_RESERVATIONS_NOT_PAID_REQUEST',
    FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS: 'FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS',
    FETCH_TRUCK_RESERVATIONS_NOT_PAID: 'FETCH_TRUCK_RESERVATIONS_NOT_PAID',
    FETCH_TRUCK_RESERVATIONS_REQUEST: 'FETCH_TRUCK_RESERVATIONS_REQUEST',
    SAVE_TRUCK_RESERVATION_REQUEST: 'SAVE_TRUCK_RESERVATION_REQUEST',
    SAVE_TRUCK_RESERVATION_SUCCESS: 'SAVE_TRUCK_RESERVATION_SUCCESS',
    SET_TRUCK_RESERVATION_FORM_ERROR: 'SET_TRUCK_RESERVATION_FORM_ERROR',
    SET_TRUCK_RESERVATION_LOADING: 'SET_TRUCK_RESERVATION_LOADING',
    SET_TRUCK_RESERVATION_SAVING: 'SET_TRUCK_RESERVATION_SAVING',

    TRUCK_RESERVATIONS_SET_UPDATING_ID: 'TRUCK_RESERVATIONS_SET_UPDATING_ID',

    fetchTruckReservations: ({ orderId = '' } = {}) => {
        return {
            type: actions.FETCH_TRUCK_RESERVATIONS_REQUEST,
            payload: { orderId }
        }
    },

    fetchTruckReservationsNotPaid: ({ orderId = '' } = {}) => {
        return {
            type: actions.FETCH_TRUCK_RESERVATIONS_NOT_PAID_REQUEST,
            payload: { orderId }
        }
    },

    // fetchTruckReservation: ({ orderId, reservationId } = {}) => {
    //     return {
    //         type: actions.FETCH_TRUCK_RESERVATION_REQUEST,
    //         payload: { orderId, reservationId }
    //     }
    // },

    saveTruckReservation: ({ orderId = '', truckReservation = {}, id = undefined, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.SAVE_TRUCK_RESERVATION_REQUEST,
            payload: { orderId, truckReservation, id, onSuccess, onError }
        }
    },

    setTruckReservationFormError: (error = {}) => {
        return {
            type: actions.SET_TRUCK_RESERVATION_FORM_ERROR,
            error
        }
    },

    setUpdatingId: ({ updatingId = '' } = {}) => {
        return {
            type: actions.TRUCK_RESERVATIONS_SET_UPDATING_ID,
            updatingId
        }
    }
}

export default actions
