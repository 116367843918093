import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Form, Space, Input, Button } from 'antd'

import SelectDelivereeVehicleTypes from '../../../deliveree/SelectVehicleTypes'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const formLongItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const TruckReservationDelivereeFormRender = ({
  onSubmit,
  initialValues,
  errorsMessage,
  saving,
  customer,
  orderShippingAddress
}) => {
  const { i18n } = useTranslation('inquiries')

  const handleSubmit = (values) => {
    const params = {
      vehicleTypeId: values['vehicleTypeId'],
      timeType: 'now',
      locations: [
        values['pickupLocation'],
        ...values['locations']
      ]
    }

    onSubmit(params)
  }

  const contactName = orderShippingAddress ? orderShippingAddress.contactName : customer.fullName
  const phoneNumber = orderShippingAddress ? orderShippingAddress.phoneNumber : customer.phoneNumber

  return (
    <Form onFinish={handleSubmit} {...formItemLayout} initialValues={initialValues}>
      { errorsMessage ? <div style={{ color: 'red' }}>{errorsMessage}</div> : null}
      <Form.Item label={i18n.t('inquiries:deliveree.vehicleType')}  name='vehicleTypeId'
        rules={[{ required: true, message: 'Please Select Vehicle type'}]}
      >
        <SelectDelivereeVehicleTypes placeholder={i18n.t('inquiries:deliveree.selectVehicleType')} disabled />
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:deliveree.pickupAddress')}>
        <Form.Item {...formLongItemLayout}
            label={i18n.t('inquiries:deliveree.recipientName')}
            name={['pickupLocation', 'recipientName']}
            rules={[{ required: true, message: 'Please Input Recipient Name'}]}>
                <Input placeholder={i18n.t('inquiries:deliveree.recipientName')}
                  style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item {...formLongItemLayout}
            label={i18n.t('inquiries:deliveree.recipientPhone')}
            name={['pickupLocation', 'recipientPhone']}
            rules={[{ required: true, message: 'Please Input Recipient Phone'}]}>
                <Input placeholder={i18n.t('inquiries:deliveree.recipientPhone')}
                  style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name={['pickupLocation', 'address']}
          rules={[{ required: true, message: 'Please Input Pickup Address'}]}
        >
          <Input.TextArea placeholder={i18n.t('inquiries:deliveree.pickupAddress')} disabled
            rows={3} />
        </Form.Item>
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:deliveree.shippingAddress')}
        style={{ marginBottom: 0 }}>
        <Form.List name='locations'>
            {(fields) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div key={key} align="baseline" style={{ position: 'relative'}}>
                          <Form.Item {...restField} {...formLongItemLayout}
                              label={i18n.t('inquiries:deliveree.recipientName')}
                              initialValue={contactName}
                              name={[name, 'recipientName']}
                              rules={[{ required: true, message: 'Please Input Recipient Name'}]}
                              fieldKey={[fieldKey]}>
                                  <Input placeholder={i18n.t('inquiries:deliveree.recipientName')}
                                    style={{ width: '100%' }}/>
                          </Form.Item>
                          <Form.Item {...restField} {...formLongItemLayout}
                              label={i18n.t('inquiries:deliveree.recipientPhone')}
                              name={[name, 'recipientPhone']}
                              initialValue={phoneNumber}
                              rules={[{ required: true, message: 'Please Input Recipient Phone'}]}
                              fieldKey={[fieldKey]}>
                                  <Input placeholder={i18n.t('inquiries:deliveree.recipientPhone')}
                                    style={{ width: '100%' }} />
                          </Form.Item>
                          <Form.Item {...restField}
                              name={[name, 'address']}
                              rules={[{ required: true, message: 'Please Input Shipping Address'}]}
                              fieldKey={[fieldKey]}>
                                  <Input.TextArea placeholder={i18n.t('inquiries:deliveree.shippingAddress')}
                                    style={{ width: '100%' }} disabled rows={3}/>
                          </Form.Item>
                      </div>
                    ))}
                </>
            )}
        </Form.List>
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
            <Button loading={saving} type="primary" htmlType="submit">
                จองรถ
            </Button>
        </Space>
      </div>
  </Form>
  )
}

TruckReservationDelivereeFormRender.propTypes = {}

TruckReservationDelivereeFormRender.defaultProps = {}

export default TruckReservationDelivereeFormRender
