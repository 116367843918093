import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import SelectAddress from '../../components/selectAddress'

class SelectAddressContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        postalCodeVisible: PropTypes.bool,
        value: PropTypes.object,

        // Inner Props
        districtEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        postalCodeVisible: false,
        value: {
            province_id: '',
            district_id: '',
            postal_code: ''
        }
    }

    constructor (props) {
        super(props)

        this.state = {
            value: { ...props.value }
        }
    }

    getPostalCode (districtId) {
        const { districtEntities } = this.props

        return districtEntities.getIn([_.toString(districtId), 'postal_code'], '')
    }

    onProvinceChange = (provinceId) => {
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                province_id: provinceId,
                district_id: '',
                postal_code: ''
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onDistrictChange = (districtId) => {
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                district_id: districtId,
                postal_code: this.getPostalCode(districtId)
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onPostalCodeChange = (e) => {
        const postalCode = e.target.value
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                postal_code: postalCode
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    render () {
        const { postalCodeVisible } = this.props
        const { value } = this.state

        return (
            <SelectAddress
                onDistrictChange={this.onDistrictChange}
                onPostalCodeChange={this.onPostalCodeChange}
                onProvinceChange={this.onProvinceChange}
                postalCodeVisible={postalCodeVisible}
                value={value}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        districtEntities: state.getIn(['Entities', 'districts'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddressContainer)
