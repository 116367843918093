import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

import { apiFetch } from '../../helpers/restRequest'
import { staffSchema, staffsSchema, staffRolesSchema } from '../schema'
import actions from './actions'
import actionNotifications from '../notifications/actions'

const { setNotifications } = actionNotifications
const {
    STAFFS_FETCH,
    STAFFS_FETCH_REQUEST,
    STAFFS_FETCH_ROLE_REQUEST,
    STAFFS_FETCH_ROLE_SUCCESS,
    STAFFS_FETCH_ROLE,
    STAFFS_FETCH_SUCCESS,
    STAFFS_SAVE_REQUEST,
    STAFFS_SAVE_SUCCESS,
    STAFFS_SET_FORM_ERRORS,
    STAFFS_SET_LOADING,
    STAFFS_SET_ROLE_LOADING,
    STAFFS_SET_SAVING,
    STAFFS_SET_PAGINATION,
    STAFFS_SET_QUERY,
    STAFFS_FETCH_STAFF,
    STAFFS_FETCH_STAFF_REQUEST,
    STAFFS_FETCH_STAFF_SUCCESS
} = actions

const apiPath = (id) => {
    const basePath = '/staffs'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *staffRolesRequest () {
    yield takeLatest(STAFFS_FETCH_ROLE_REQUEST, function *() {
        yield put({ type: STAFFS_FETCH_ROLE })
        yield put({ type: STAFFS_SET_ROLE_LOADING, loading: true })

        const data = yield call(apiFetch, '/staffs/roles')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, staffRolesSchema)

        yield put({
            type: STAFFS_FETCH_ROLE_SUCCESS,
            ...normalizeData
        })

        yield put({ type: STAFFS_SET_ROLE_LOADING, loading: false })
    })
}

export function *fetchStaffRequest () {
    yield takeLatest(STAFFS_FETCH_STAFF_REQUEST, function *({ payload }) {
        yield put({ type: STAFFS_FETCH_STAFF })
        yield put({ type: STAFFS_SET_LOADING, loading: true })

        const id = payload.id
        const path = apiPath(id)
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, staffSchema)

        yield put({
            type: STAFFS_FETCH_STAFF_SUCCESS,
            ...normalizeData
        })

        yield put({ type: STAFFS_SET_LOADING, loading: false })
    })
}

export function *fetchStaffsRequest () {
    yield takeLatest(STAFFS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: STAFFS_FETCH })
        yield put({ type: STAFFS_SET_LOADING, loading: true })

        const { page, query } = payload
        const pageSize = 20
        const path = `${apiPath()}?page=${page}&per=${pageSize}&query=${query}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, staffsSchema)
        const total = data.meta.total_pages * pageSize

        yield put({
            type: STAFFS_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({ type: STAFFS_SET_QUERY, query })

        yield put({
            type: STAFFS_FETCH_SUCCESS,
            ...normalizeData
        })

        yield put({ type: STAFFS_SET_LOADING, loading: false })
    })
}

export function *submitStaffRequest () {
    yield takeLatest(STAFFS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: STAFFS_SET_SAVING, saving: true })

        const id = payload.id
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ staff: payload.staff })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: STAFFS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, staffSchema)

                yield put({
                    type: STAFFS_SAVE_SUCCESS,
                    ...normalizeData
                })
                yield put(setNotifications('success', 'saveSuccess', 'success'))

                setTimeout(() => location.replace('/staffs'), 200)
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: STAFFS_SET_SAVING, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(staffRolesRequest),
        fork(fetchStaffsRequest),
        fork(fetchStaffRequest),
        fork(submitStaffRequest)
    ])
}
