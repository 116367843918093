import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Form, DatePicker, Button, Card, Select } from 'antd'
import accountingActions from '../../redux/orders/accounting/actions'

const { fetchYieldReportFile } = accountingActions
const { RangePicker } = DatePicker
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons'

class ExportYieldReport extends Component {
    constructor (props) {
        super(props)


        this.formRef = createRef()
    }

  handleSubmit = (e) => {
      const { fetchYieldReportFile } = this.props
      e.preventDefault()

      this.formRef.current.validateFields().then(fieldValue => {
            let startDate = null
            let endDate = null
            if (fieldValue['range_picker']) {
                startDate = fieldValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldValue['range_picker'][1].format('YYYY-MM-DD')
            }

            const values = {
                start_date: startDate,
                end_date: endDate,
                filter_payment_date: fieldValue['filter_date_by'] === 'payment_date'
            }

            fetchYieldReportFile(values)
      })
  }

  render () {
        const { orderPaymentDetails, i18n } = this.props
        const loading = orderPaymentDetails.get('yieldReportCSVLoading')
        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 }
        }

        return (
            <Card
                title={i18n.t('confirms:yieldReport.searchYielReport')}
                style={{ margin: '20px 0' }}
            >
                <Form
                    layout="inline"
                    labelAlign="left"
                    ref={this.formRef}
                    hideRequiredMark>
                    <Form.Item key="search_by_date" label={i18n.t('orders:searchByDate')}
                        name='range_picker'
                        rules={[{
                            required: true,
                            message: i18n.t('confirms:invoice.pleaseInputDate')
                        }]}
                        {...formItemLayout}>
                            <RangePicker />
                    </Form.Item>
                    <Form.Item key="filter_by_date" label={i18n.t('confirms:yieldReport.dateType')}
                        name='filter_date_by'
                        initialValue='yield_date'
                        {...formItemLayout}>
                            <Select style={{ width: 200 }}>
                                <Select.Option value="yield_date"> {i18n.t('confirms:yieldReport.yieldDate')} </Select.Option>
                                <Select.Option value="payment_date"> {i18n.t('confirms:yieldReport.paymentDate')} </Select.Option>
                            </Select>
                    </Form.Item>
                    <Form.Item key="action_btn" {...buttonItemLayout}>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('confirms:yieldReport.downloadYieldReport')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
  }
}

const mapStateToProps = state => ({
    orderPaymentDetails: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchYieldReportFile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['confirms', 'orders'])(ExportYieldReport))
