import styled from 'styled-components'

const SalePipelinesWrapper = styled.div`
  .detail-row {
    margin: 2px 0;
    display: flex;

    .detail-label {
      color: rgba(0, 0, 0, 0.5);

      &:after { content: ':'; }
    }

    .detail-label,
    .detail-value {
      padding: 0 4px;
    }
  }
`

export default SalePipelinesWrapper
