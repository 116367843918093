import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { Divider, Table, Modal } from 'antd'
import { EditTwoTone, CreditCardOutlined, CheckOutlined } from '@ant-design/icons'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../containers/Image'
import PaymentVoucherForm from '../../containers/Orders/Accountings/PaymentVoucherForm'
import PaymentVoucherWrapper from '../paymentVouchers/paymentVouchers.style'

const TransferBackList = ({
    formErrors,
    getPaymentVoucher,
    loading,
    onPageChange,
    onSavePaymentVoucher,
    pagination,
    pvIdEditing,
    saving,
    setPvEditing,
    setSelectedPVs,
    showPaymentVoucherForm,
    togglePaymentVoucherForm,
    toggleSupplierOrderForm,
    transferBackList
}) => {
    const { i18n } = useTranslation(['confirms', 'orderPaymentDetails'])

    const onEditPv = (id) => {
        togglePaymentVoucherForm()
        setPvEditing(id)
    }

    const getColumns = () => {
        return [
            {
                title: i18n.t('orderPaymentDetails:orderNumber'),
                dataIndex: 'order_number',
                render: (orderNumber, record) => {
                    const supplierOrder = record.supplier_orders[0]

                    if (!supplierOrder) { return null }

                    return (
                        <div>
                            <a onClick={() => toggleSupplierOrderForm({
                                id: supplierOrder.id,
                                orderId: supplierOrder.order,
                                supplierOrderType: supplierOrder.supplier_order_type
                            })}>
                                {supplierOrder.ref_code}
                            </a>
                            <Divider style={{ margin: '4px' }}/>
                            <div>
                                <Link to={`/orders/${orderNumber}`}>{orderNumber}</Link>
                            </div>
                            <div>
                                <Link to={`/orders/${orderNumber}/accountings`} target="_blank">
                                    <CreditCardOutlined style={{ margin: '0 3px' }} /> Accounting
                                </Link>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: i18n.t('orderPaymentDetails:transferDate'),
                dataIndex: ['transfer_date', 'display']
            },
            {
                title: i18n.t('confirms:accounting.externalId'),
                dataIndex: 'external_id'
            },
            {
                title: i18n.t('orderPaymentDetails:amount'),
                dataIndex: ['amount', 'display']
            },
            {
                title: i18n.t('orderPaymentDetails:isPaid'),
                dataIndex: 'is_paid',
                render: (isPaid) => {
                    if (!isPaid) return null
                    return (
                        <CheckOutlined />
                    )
                }
            },
            {
                title: i18n.t('orderPaymentDetails:slipImages'),
                render: (record) => (
                    <div className="image-container">
                        {renderSlipImages(record.payment_voucher_images)}
                    </div>
                )
            },
            {
                render: (record) => (
                    <EditTwoTone onClick={() => onEditPv(record.id)}/>
                )
            }
        ]
    }

    const renderSlipImages = (images = []) => {
        return images.map((image) => {
            const imageId = image.id
            const thumbUrl = image.file.m || ''
            const originalUrl = image.file.original
            const contentType = image.file.content_type

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    const renderPVModal = () => {
        const paymentVoucher = getPaymentVoucher(pvIdEditing)

        return (
            <Modal
                visible={showPaymentVoucherForm}
                destroyOnClose
                onCancel={() => onEditPv()}
                footer={null}>
                    <PaymentVoucherForm
                        formErrors={formErrors}
                        onSubmitForm={onSavePaymentVoucher}
                        orderId={paymentVoucher.get('order_number')}
                        paymentVoucher={paymentVoucher}
                        saving={saving}
                    />
            </Modal>
        )
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedPVs(selectedRows)
        },
    }

    return (
        <PaymentVoucherWrapper>
            <Table
                columns={getColumns()}
                dataSource={transferBackList}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
                rowSelection={rowSelection}
            />
            {renderPVModal()}
        </PaymentVoucherWrapper>
    )
}

TransferBackList.propTypes = {
    formErrors: ImmutablePropTypes.map.isRequired,
    getPaymentVoucher: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    onSavePaymentVoucher: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    pvIdEditing: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(undefined)]),
    saving: PropTypes.bool,
    setPvEditing: PropTypes.func.isRequired,
    setSelectedPVs: PropTypes.func.isRequired,
    showPaymentVoucherForm: PropTypes.bool,
    togglePaymentVoucherForm: PropTypes.func.isRequired,
    toggleSupplierOrderForm: PropTypes.func.isRequired,
    transferBackList: PropTypes.array
}

export default withImagePreview(TransferBackList)
