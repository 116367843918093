import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Col, Row, Spin, Statistic } from 'antd'
import DealsRender from '../render'
import DealCategoryWrapper from './wrapper.style'

const Render = ({ category, loading, onChangeCurrent }) => {
    const { i18n } = useTranslation('deals')

    const renderStatisticValue = value => {
        return <div className="text-right">{value}</div>
    }

    return (
        <DealCategoryWrapper>
            <Spin spinning={loading}>
                <Row gutter={[16, 8]}>
                    <Col sm={6} xs={24}>
                        <Statistic
                            title={i18n.t('deals:suppliersCount')}
                            value={category.suppliersCount || '-'}
                            valueRender={renderStatisticValue} />
                    </Col>
                </Row>
            </Spin>

            <DealsRender
                categorySelectorProps={{
                    activeFirstOptionAsDefault: true,
                    allowClear: false
                }}
                onChangeCategory={onChangeCurrent} />
        </DealCategoryWrapper>
    )
}

Render.propTypes = {
    category: PropTypes.object,
    loading: PropTypes.bool,
    onChangeCurrent: PropTypes.func.isRequired
}

Render.defaultProps = {}

export default Render
