import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { List, Map } from 'immutable'
import _ from 'lodash'

import SelectSupplierLineItems from '../../components/selectSupplierLineItems'

import InquiryActions from '../../redux/inquiry/actions'

const { fetchInquiryLineItems } = InquiryActions

class SelectSupplierLineItemsContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        orderId: PropTypes.string.isRequired,
        value: PropTypes.array,
        isPoCompleted: PropTypes.bool,

        // Inner Props
        fetchInquiryLineItems: PropTypes.func.isRequired,
        inquiry: ImmutablePropTypes.map.isRequired,
        lineItemEntities: ImmutablePropTypes.map.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            additionalData: []
        }
    }

    static defaultProps = {
        onChange: () => {},
        value: []
    }

    getAvailableQuantity = (record) => {
        return _.get(record, 'available_quantity', _.get(record, 'available_quantity_for_supplier', 0))
    }

    getLineItem = (id) => {
        const { lineItemEntities } = this.props
        const lineItem = lineItemEntities.get(_.toString(id), new Map()).toJS()

        return new Map({
            ...lineItem,
            line_item_id: lineItem.id,
            line_item_name: lineItem.name,
            line_item_unit: lineItem.unit,
            quantity: this.getAvailableQuantity(lineItem),
            available_quantity: this.getAvailableQuantity(lineItem)
        })
    }

    getLineItems () {
        const { inquiry, value } = this.props

        if (inquiry.get('lineItemsLoading', false)) { return new List() }

        const lineItems = inquiry.get('lineItems', new List()).map((lineItemId) => {
            return this.getLineItem(lineItemId)
        })

        const addLineItems = value.filter(v => v.is_add_item).map((lineItem) => {
            return new Map({
                id: `add-item-${_.get(lineItem, 'id', '')}`,
                line_item_id: null,
                line_item_name: _.get(lineItem, 'line_item_name', ''),
                quantity: _.get(lineItem, 'quantity', 0),
                single_price: _.get(lineItem, 'cost', '0'),
                line_item_unit: _.get(lineItem, 'unit', ''),
                available_quantity: null,
                is_add_item: true
            })
        })

        return lineItems.concat(addLineItems)
    }

    getDefaultLineItems () {
        const { value } = this.props

        return value.map((lineItem) => {
            if (lineItem.is_add_item) {
                return {
                    id: `add-item-${_.get(lineItem, 'id', '')}`,
                    line_item_id: null,
                    line_item_name: _.get(lineItem, 'name', ''),
                    quantity: _.get(lineItem, 'quantity', 0),
                    available_quantity: _.get(lineItem, 'available_quantity', 0),
                    cost: _.get(lineItem, 'single_price.raw', _.get(lineItem, 'single_price', '')),
                    line_item_unit: _.get(lineItem, 'unit', ''),
                    is_add_item: true
                }
            }

            const lineItemId = _.get(lineItem, 'line_item', _.get(lineItem, 'line_item_id', ''))
            return {
                id: lineItemId,
                line_item_id: lineItemId,
                line_item_name: _.get(lineItem, 'name', ''),
                quantity: _.get(lineItem, 'quantity', 0),
                available_quantity: _.get(lineItem, 'available_quantity', 0),
                cost: _.get(lineItem, 'single_price.raw', _.get(lineItem, 'single_price', '')),
                line_item_unit: _.get(lineItem, 'unit', ''),
                is_add_item: false
            }
        })
    }

    handleAddItem = () => {
        const newValue = {
            id: _.now(),
            line_item_name: '',
            quantity: 0,
            available_quantity: null,
            cost: '',
            line_item_unit: ''
        }

        this.setState({ additionalData: [...this.state.additionalData, newValue] })
    }

    handleFetchInitialData = ({ onSuccess } = {}) => {
        const { fetchInquiryLineItems, orderId } = this.props

        fetchInquiryLineItems(orderId, 1, true, { onSuccess })
    }

    onSelectLineItemsChange = (selectedLineItems) => {
        const { onChange, value } = this.props

        const selectedSupplierLineItems = selectedLineItems.map((lineItem) => {
            const supplierLineItem = _.find(value, (supplierLineItem) => {
                if (supplierLineItem.is_add_item) { return `add-item-${supplierLineItem.id}`=== lineItem.id }

                return _.get(supplierLineItem, 'line_item', _.get(supplierLineItem, 'line_item_id', '')) === lineItem.id
            })

            return {
                id: _.get(supplierLineItem, 'id', ''),
                line_item_id: _.get(lineItem, 'line_item_id', ''),
                line_item_name: _.get(lineItem, 'line_item_name', ''),
                quantity: _.get(lineItem, 'quantity', 0),
                single_price: _.get(lineItem, 'cost', '0'),
                line_item_unit: _.get(lineItem, 'line_item_unit', ''),
                is_add_item: _.get(lineItem, 'is_add_item', false),
                _destroy: _.get(lineItem, '_destroy', false)
            }
        })

        onChange(selectedSupplierLineItems)
    }

    render () {
        const { i18n, inquiry, orderId, isPoCompleted, lineItemsLoading } = this.props
        let columns = [{
            title: i18n.t('inquiries:detail'),
            dataIndex: 'line_item_name',
            editable: true
        }, {
            title: i18n.t('inquiries:quantity'),
            dataIndex: 'quantity',
            editable: true
        }, {
            title: i18n.t('inquiries:availableQuantity'),
            dataIndex: 'available_quantity'
        }, {
            title: i18n.t('inquiries:unit'),
            dataIndex: 'line_item_unit',
            editable: true,
        }, {
            title: i18n.t('inquiries:cost'),
            dataIndex: 'cost',
            editable: true
        }]

        return (
            <SelectSupplierLineItems
                columns={columns}
                defaultLineItems={this.getDefaultLineItems()}
                initialData={[...this.getLineItems().toJS(), ...this.state.additionalData]}
                loading={inquiry.get('lineItemsLoading', false) || lineItemsLoading}
                onAddItem={this.handleAddItem}
                onFetchInitialData={this.handleFetchInitialData}
                onSelectLineItemsChange={this.onSelectLineItemsChange}
                orderId={orderId}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        inquiry: state.get('inquiry'),
        lineItemEntities: state.getIn(['Entities', 'lineItems'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInquiryLineItems
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('inquiries')(SelectSupplierLineItemsContainer)
)
