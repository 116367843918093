import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import CustomerInfo from '../../components/orders/customerInfo'

import inquiryActions from '../../redux/inquiry/actions'

const { fetchInquiryCustomer } = inquiryActions

class CustomerInfoContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string,
        isExpand: PropTypes.bool,
        order: ImmutablePropTypes.map.isRequired,

        fetchInquiryCustomer: PropTypes.func.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            expand: props.isExpand
        }
    }

    componentDidMount () {
        const { fetchInquiryCustomer, customerId } = this.props

        if (customerId) { fetchInquiryCustomer(customerId) }
    }

    getCustomer () {
        const { customerEntities, customerId } = this.props

        return customerEntities.get(customerId) || new Map()
    }

    getOrder () {
        const { order } = this.props

        if (!order.size) { return new Map() }

        return order
    }

    toggleExpand = () => {
        this.setState({ expand: !this.state.expand })
    }

    render () {
        const { expand } = this.state

        return (
            <CustomerInfo
                customer={this.getCustomer().toJS()}
                expand={expand}
                order={this.getOrder().toJS()}
                toggleExpand={this.toggleExpand} />
        )
    }
}

const mapStateToProps = (state) => ({
    customerEntities: state.getIn(['Entities', 'customers'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInquiryCustomer
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoContainer)
