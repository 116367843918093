import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Form, Card, Row, Button } from 'antd'

import OrderCategoriesForm from '../../OrderCategoriesForm'

import inquiryActions from '../../../../redux/inquiry/actions'

const { saveOrderForm } = inquiryActions

const CategoryForm = ({
  defaultCategoryId,
  orderId,
  onSuccess,
  onCancel
}) => {
  const { i18n } = useTranslation('orders')
  const dispatch = useDispatch()
  const saving = useSelector(state => state.getIn(['inquiry', 'saveOrderLoading']))

  const onSubmitForm = (values) => {
    dispatch(saveOrderForm(orderId, values, {
      onSuccess: () => { onSuccess() }
    }))
  }

  const categoryValue = defaultCategoryId ? `${defaultCategoryId}` : null

  return (
    <Form onFinish={onSubmitForm}>
        <div>
            <label className="title">{i18n.t('orders:productCategory')} :</label>
              <OrderCategoriesForm
                categoryValue={categoryValue}
                categoryFieldName={'category_id'}/>
            <Row className="btn-center">
                <Button style={{ width: 60, marginRight: '8px' }}
                    type="primary"
                    size="small"
                    htmlType="submit"
                    loading={saving}>{i18n.t('inquiries:edit')}</Button>
                <Button style={{ width: 60 }}
                    size="small"
                    disabled={saving}
                    onClick={onCancel}> {i18n.t('inquiries:cancel')}</Button>
            </Row>
        </div>
    </Form>
  )
}

CategoryForm.propTypes = {
  defaultCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderId: PropTypes.string,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func
}

CategoryForm.defaultProps = {
  onSuccess: () => {},
  onCancel: () => {}
}

export default CategoryForm