import React from 'react'
import PropTypes from 'prop-types'

import { Spin } from 'antd'

const LoadingSpinner = ({ align, padding, size, customStyles }) => {
    const styles = {
        padding,
        textAlign: align,
        ...customStyles
    }

    return (
        <div className="loading-spinner" style={styles}>
            <Spin size={size} />
        </div>
    )
}

LoadingSpinner.propTypes = {
    align: PropTypes.oneOf(['left', 'center', 'right']),
    customStyles: PropTypes.object,
    padding: PropTypes.string,
    size: PropTypes.oneOf(['small', 'default', 'large'])
}

LoadingSpinner.defaultProps = {
    align: 'center',
    customStyles: {},
    padding: '20px',
    size: 'default',
}

export default LoadingSpinner
