import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import SupplierInvoiceImport from '../../components/supplierInvoices/import'

import actions from '../../redux/supplierInvoices/actions'
import notificationActions from '../../redux/notifications/actions'

const { importSupplierInvoices, setImportModal } = actions
const { setNotifications } = notificationActions

const SupplierInvoicesImportContainer = ({ i18n }) => {
    const dispatch = useDispatch()
    const supplierInvoices = useSelector(state => state.get('supplierInvoices'))
    const showImportModal = supplierInvoices.get('showImportModal')
    const importing = supplierInvoices.get('importing')

    const onSubmit = ({ params = {} } = {}) => {
        dispatch(importSupplierInvoices({
              params,
              onSuccess: () => {
                  onToggleImportModal()
                    dispatch(setNotifications(
                        i18n.t('supplierInvoices:uploadSuccess'),
                        i18n.t('supplierInvoices:uploadSuccessDescription'),
                        'info'
                    ))
              },
              onError: () => {
                  dispatch(setNotifications('error', 'error', 'error'))
              }
          })
        )
    }

    const onToggleImportModal = () => {
      dispatch(setImportModal(!showImportModal))
    }

    return (
        <SupplierInvoiceImport
          onSubmit={onSubmit}
          onToggleVisibleModal={onToggleImportModal}
          visibleModal={showImportModal}
          importing={importing}
        />
    )
}

export default withTranslation(['supplierInvoices'])(SupplierInvoicesImportContainer)
