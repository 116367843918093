import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import moment from 'moment'
import _ from 'lodash'

import { Collapse, Row, Col, Divider } from 'antd'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../containers/Image'
import ImagesWrapper from '../images/imagesWrapper.style'

const renderTrackingItems = (items) => {
    return items.map((item, index) => {
        const time = _.get(item, 'created_at')
        const images = _.get(item, 'images', [])

        return (
            <Row key={`${time}-${index}`}>
                <Col span={4} style={{ textAlign: 'center' }}>{moment(time).format('HH:mm:ss')}</Col>
                <Col span={20}>
                    {_.get(item, 'message', '')}

                    {!_.isEmpty(images) &&
                        <>
                            <Divider type="vertical"/>

                            <div className="image-container">
                                {images.map((image, imageIndex) => {
                                    return (
                                        <Image
                                            contentType="image/png"
                                            isImageBlob={_.get(image, 'is_image_blob', true)}
                                            key={`image-${imageIndex}`}
                                            originalUrl={_.get(image, 'original', null)}
                                            thumbUrl={_.get(image, 'small', null)}
                                        />
                                    )
                                })}
                            </div>
                        </>
                    }
                </Col>
            </Row>
        )
    })
}

const renderTrackingPanels = (trackingList) => {
    moment.locale(i18n.language)
    return _.keys(trackingList).map((date) => {
        return (
            <Collapse.Panel header={moment(date).format('YYYY-MM-DD | dddd')} key={date}>
                {renderTrackingItems(trackingList[date])}
            </Collapse.Panel>
        )
    })
}

const TrackingListByDate = (props) => {
    const { trackingList } = props

    if (_.isEmpty(trackingList)) { return null }

    return (
        <ImagesWrapper>
            <Collapse defaultActiveKey={Object.keys(trackingList)}>
                {renderTrackingPanels(trackingList)}
            </Collapse>
        </ImagesWrapper>
    )
}

TrackingListByDate.propTypes = {
    trackingList: PropTypes.object
}

export default withImagePreview(TrackingListByDate)
