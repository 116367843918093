import { all, fork, takeLatest, put, call } from 'redux-saga/effects'
import _ from 'lodash'
import { normalize } from 'normalizr'
import { apiFetch, apiPostForm } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'
import {
    staffSchema,
    truckOwnerBankAccountSchema,
    truckOwnerSchema,
    truckOwnersSchema,
    truckSchema,
    trucksSchema
} from '../schema'

import actions from './actions'
import notificationActions from '../notifications/actions'

const {
    TRUCK_FETCH_FAIL,
    TRUCK_FETCH_REQUEST,
    TRUCK_FETCH_SUCCESS,
    TRUCK_FETCH,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_REQUEST,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS,
    TRUCK_OWNER_BANK_ACCOUNT_SAVE,
    TRUCK_OWNER_FETCH_FAIL,
    TRUCK_OWNER_FETCH_REQUEST,
    TRUCK_OWNER_FETCH_SUCCESS,
    TRUCK_OWNER_FETCH,
    TRUCK_OWNER_SAVE_FAIL,
    TRUCK_OWNER_SAVE_REQUEST,
    TRUCK_OWNER_SAVE_SUCCESS,
    TRUCK_OWNER_SAVE,
    TRUCK_OWNER_STAFF_SAVE_FAIL,
    TRUCK_OWNER_STAFF_SAVE_REQUEST,
    TRUCK_OWNER_STAFF_SAVE_SUCCESS,
    TRUCK_OWNER_STAFF_SAVING,
    TRUCK_OWNERS_FETCH_FAIL,
    TRUCK_OWNERS_FETCH_REQUEST,
    TRUCK_OWNERS_FETCH_SUCCESS,
    TRUCK_OWNERS_FETCH,
    TRUCK_SAVE_FAIL,
    TRUCK_SAVE_REQUEST,
    TRUCK_SAVE_SUCCESS,
    TRUCK_SAVE,
    TRUCKS_FETCH_FAIL,
    TRUCKS_FETCH_REQUEST,
    TRUCKS_FETCH_SUCCESS,
    TRUCKS_FETCH,

    fetchTruckOwners,
    setTruckOwnerVisibleModal
} = actions

const {
    setNotifications
} = notificationActions

export function *fetchTruckOwnersSaga () {
    yield takeLatest(TRUCK_OWNERS_FETCH_REQUEST, function *({ payload }) {
        const { type, query, page, per, onlyVerified } = payload
        let params = `query=${query}&page=${page}&per=${per}&only_verified=${onlyVerified}`
        if (type) { params += `&owner_type=${type}` }

        yield put({ type: TRUCK_OWNERS_FETCH })

        try {
            const data = yield call(apiFetch, `/truck_owners?${params}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, truckOwnersSchema)

            yield put({
                type: TRUCK_OWNERS_FETCH_SUCCESS,
                ...normalizedData,
                pagination: {
                    current: page,
                    pageSize: per,
                    total: _.get(data, 'meta.total_pages', 1) * per
                },
                query: query
            })
        } catch (error) {
            yield put({ type: TRUCK_OWNERS_FETCH_FAIL })
        }
    })
}

export function *fetchTruckOwnerSaga () {
    yield takeLatest(TRUCK_OWNER_FETCH_REQUEST, function *({ payload }) {
        const { id } = payload

        yield put({ type: TRUCK_OWNER_FETCH })

        try {
            const data = yield call(apiFetch, `/truck_owners/${id}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, truckOwnerSchema)

            yield put({
                type: TRUCK_OWNER_FETCH_SUCCESS,
                ...normalizedData
            })
        } catch (error) {
            yield put({ type: TRUCK_OWNER_FETCH_FAIL })
        }
    })
}

export function *saveTruckOwnerSaga () {
    yield takeLatest(TRUCK_OWNER_SAVE_REQUEST, function *({ payload }) {
        const { id, params } = payload

        yield put({ type: TRUCK_OWNER_SAVE })

        try {
            const method = !id ? 'POST' : 'PATCH'
            const path = !id ? '/truck_owners' : `/truck_owners/${id}`
            const body = JSON.stringify(params)
            const data = yield call(apiFetch, path, method, { body })

            if (!('error' in data)) {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, truckOwnerSchema)

                yield put({ type: TRUCK_OWNER_SAVE_SUCCESS, ...normalizedData })
                yield put(setNotifications('success', 'saveSuccess', 'success'))

                yield put(setTruckOwnerVisibleModal({ visibleModal: null }))
            } else {
                yield put({
                    type: TRUCK_OWNER_SAVE_FAIL,
                    error: _.get(data, 'error_description', {})
                })
                yield put(setNotifications('fail', 'saveFail', 'error'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put({ type: TRUCK_OWNER_SAVE_FAIL, error: {} })
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *saveTruckOwnerBankAccount () {
    yield takeLatest(TRUCK_OWNER_BANK_ACCOUNT_SAVE_REQUEST, function *({ payload }) {
        const { type, query, ownerId, id, params } = payload

        yield put({ type: TRUCK_OWNER_BANK_ACCOUNT_SAVE })

        try {
            const method = !id ? 'POST' : 'PATCH'
            const path = !id ? `/truck_owners/${ownerId}/bank_accounts` : `/truck_owners/${ownerId}/bank_accounts/${id}`
            const body = JSON.stringify(params)
            const data = yield call(apiFetch, path, method, { body })

            if (!('error' in data)) {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, truckOwnerBankAccountSchema)

                yield put({ type: TRUCK_OWNER_BANK_ACCOUNT_SAVE_SUCCESS, ...normalizedData })
                yield put(setNotifications('success', 'saveSuccess', 'success'))

                if (!id) {
                    yield put(fetchTruckOwners({ type, query, per: 10 }))
                }

                yield put(setTruckOwnerVisibleModal({ visibleModal: null }))
            } else {
                yield put({ type: TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL, error: _.get(data, 'error_description', {}) })
                yield put(setNotifications('fail', 'saveFail', 'error'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put({ type: TRUCK_OWNER_BANK_ACCOUNT_SAVE_FAIL, error: {} })
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *saveTruckOwnerStaffSaga () {
    yield takeLatest(TRUCK_OWNER_STAFF_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: TRUCK_OWNER_STAFF_SAVING })
        const { truckOwnerId, staffId, staffParams, onSuccess, onError } = payload

        try {
            const path = `/truck_owners/${truckOwnerId}/staffs`
            const method = _.isEmpty(staffId) ? 'POST' : 'PATCH'

            const data = yield call(apiPostForm, path, method, { staff: staffParams })

            if ('error' in data) {
                yield put({ type: TRUCK_OWNER_STAFF_SAVE_FAIL, error: data.error_description })
                onError(data.error_description)
            } else {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, staffSchema)

                yield put({
                    type: TRUCK_OWNER_STAFF_SAVE_SUCCESS,
                    ...normalizedData
                })

                onSuccess()
            }
        } catch (error) {
            yield put({ type: TRUCK_OWNER_STAFF_SAVE_FAIL })
            onError(error)
        }
    })
}

export function *fetchTruckSaga () {
    yield takeLatest(TRUCK_FETCH_REQUEST, function *({ payload }) {
        const { id } = payload

        yield put({ type: TRUCK_FETCH })

        try {
            const data = yield call(apiFetch, `/trucks/${id}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, truckSchema)

            yield put({ type: TRUCK_FETCH_SUCCESS, ...normalizedData })
        } catch (error) {
            yield put({ type: TRUCK_FETCH_FAIL })
        }
    })
}

export function *fetchTrucksSaga () {
    yield takeLatest(TRUCKS_FETCH_REQUEST, function *({ payload }) {
        const { query, page, per } = payload

        yield put({ type: TRUCKS_FETCH })

        try {
            const data = yield call(apiFetch, `/trucks?query=${query}&page=${page}&per=${per}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, trucksSchema)

            yield put({ type: TRUCKS_FETCH_SUCCESS, ...normalizedData })
        } catch (error) {
            yield put({ type: TRUCKS_FETCH_FAIL })
        }
    })
}

export function *saveTruck () {
    yield takeLatest(TRUCK_SAVE_REQUEST, function *({ payload }) {
        const { type, id, params, query } = payload

        yield put({ type: TRUCK_SAVE })

        try {
            const method = !id ? 'POST' : 'PATCH'
            const path = !id ? '/trucks' : `/trucks/${id}`

            const data = yield call(apiPostForm, path, method, params)

            if (!('error' in data)) {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, truckSchema)

                yield put({ type: TRUCK_SAVE_SUCCESS, ...normalizedData })
                yield put(setNotifications('success', 'saveSuccess', 'success'))

                if (!id) {
                    yield put(fetchTruckOwners({ type, query, per: 10 }))
                }

                yield put(setTruckOwnerVisibleModal({ visibleModal: null }))
            } else {
                yield put({ type: TRUCK_SAVE_FAIL, error: _.get(data, 'error_description', {}) })
                yield put(setNotifications('fail', 'saveFail', 'error'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put({ type: TRUCK_SAVE_FAIL, error: {} })
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchTruckOwnerSaga),
        fork(fetchTruckOwnersSaga),
        fork(fetchTruckSaga),
        fork(fetchTrucksSaga),
        fork(saveTruck),
        fork(saveTruckOwnerBankAccount),
        fork(saveTruckOwnerSaga),
        fork(saveTruckOwnerStaffSaga)
    ])
}
