import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Form, DatePicker, Input } from 'antd'

const inputItemLayout = {
  labelCol: { md: { span: 6 }, sm: { span: 6 } },
  wrapperCol: { md: { span: 16 }, sm: { span: 14 } }
}

const SelfPickupForm = ({
  pickupDate,
  licensePlateNumber
}) => {
  const { i18n } = useTranslation('inquiries')

  return (
    <div>
      <Form.Item key="pickup_date" label={i18n.t('order.pickupDate')} {...inputItemLayout}
          name='pickup_date'
          initialValue={pickupDate ? moment(pickupDate) : null}>
                <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item key="pickup_license_plate_number"
          name='pickup_license_plate_number'
          initialValue={licensePlateNumber}
          label={i18n.t('order.pickupLicensePlateNumber')} {...inputItemLayout}>
              <Input />
        </Form.Item>
    </div>
  )
}

SelfPickupForm.propTypes = {
  pickupDate: PropTypes.string,
  licensePlateNumber: PropTypes.string
}

SelfPickupForm.defaultProps = {}

export default SelfPickupForm
