import { all, takeLatest, put, fork, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'

import { deserialize } from '../../helpers/jsonApi'
import { itemAttributesSchema, productGroupItemsSchema } from '../schema'

const {
    PRODUCT_GROUP_ITEMS_FETCH_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_REQUEST,
    PRODUCT_GROUP_ITEMS_FETCH_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING,
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_REQUEST,
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES,
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_REQUEST,
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES,
} = actions

export function *fetchProductGroupItems () {
    yield takeLatest(PRODUCT_GROUP_ITEMS_FETCH_REQUEST, function *({ payload }) {
        const { productGroupId, onSuccess, onError } = payload
        yield put({ type: PRODUCT_GROUP_ITEMS_FETCHING })

        try {
            const path = `/product_groups/${productGroupId}/product_group_items`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, productGroupItemsSchema)

            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *fetchRootItemAttributes () {
    yield takeLatest(PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_REQUEST, function *({ payload }) {
        const { productGroupItemId, onSuccess, onError } = payload
        yield put({ type: PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES })

        try {
            const path = `/product_group_items/${productGroupItemId}/item_attributes?only_root=true`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemAttributesSchema)

            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL })
            onError(error)
        }
    })
}

export function *fetchItemAttributes () {
    yield takeLatest(PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_REQUEST, function *({ payload }) {
        const { productGroupItemId, parentId, onSuccess, onError } = payload
        yield put({ type: PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES })

        try {
            const path = `/product_group_items/${productGroupItemId}/item_attributes?parent_d=${parentId}`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemAttributesSchema)

            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchProductGroupItems),
        fork(fetchRootItemAttributes),
        fork(fetchItemAttributes)
    ])
}
