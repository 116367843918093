import React, { Component } from 'react'
import PropTypes from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import PurchaseOrderPreview from '../../../components/purchaseOrders/previews/purchaseOrderPreview'

import supplierOrderActions from '../../../redux/supplierOrders/actions'
const { fetchSupplierOrder } = supplierOrderActions

class PurchaseOrderPreviewContainer extends Component {
    static propTypes = {
        purchaseOrderAddressEntities: immutablePropTypes.map.isRequired,
        refCode: PropTypes.string.isRequired,
        staffEntities: immutablePropTypes.map.isRequired,
        staffProfiles: immutablePropTypes.map.isRequired,
        supplierOrderEntities: immutablePropTypes.map.isRequired,
        supplierLineItemEntities: immutablePropTypes.map.isRequired
    }

    componentWillMount () {
        document.body.style.backgroundColor = "#323232"
    }

    componentDidMount () {
        const { fetchSupplierOrder, refCode } = this.props

        fetchSupplierOrder(refCode)
    }

    getCurrentStaff = () => {
        const { staffProfiles, staffEntities } = this.props
        const currentStaffId = staffProfiles.get('currentStaffId')

        return staffEntities.get(currentStaffId) || new Map()
    }

    getLineItems = (ids) => {
        const { supplierLineItemEntities } = this.props

        return ids.map((id) => {
            return supplierLineItemEntities.get(id)
        }) || new List()
    }

    getPurchaseOrderAddress = (id) => {
        const { purchaseOrderAddressEntities } = this.props

        if (!id) { return new Map() }

        return purchaseOrderAddressEntities.get(id)
    }
    getSupplierOrders = () => {
        const { supplierOrderEntities, supplierOrders } = this.props
        const previewId = supplierOrders.get('previewId')

        return supplierOrderEntities.get(previewId) || new Map()
    }

    render () {
        const { supplierOrders } = this.props
        const supplierOrder = this.getSupplierOrders()
        const lineItemIds = supplierOrder.get('line_items') || new List()
        const addressId = supplierOrder.get('purchase_order_address') || null

        return (
            <PurchaseOrderPreview
                address={this.getPurchaseOrderAddress(addressId)}
                currentStaff={this.getCurrentStaff()}
                lineItems={this.getLineItems(lineItemIds)}
                loading={supplierOrders.get('loading')}
                supplierOrder={supplierOrder}
                type={supplierOrder.get('purchase_order_type')} />
        )
    }
}

const mapStateToProps = state => ({
    purchaseOrderAddressEntities: state.getIn(['Entities', 'purchaseOrderAddresses']),
    staffEntities: state.getIn(['Entities', 'staffs']),
    staffProfiles: state.get('staffProfiles'),
    supplierOrders: state.get('supplierOrders'),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierLineItemEntities: state.getIn(['Entities', 'supplierLineItems'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchSupplierOrder
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderPreviewContainer)
