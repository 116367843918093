import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Select, Spin } from 'antd'

const SelectSupplierOrder = ({
    defaultValue,
    i18n,
    loading,
    onChange,
    onSearch,
    supplierOrders,
    value,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultValue={defaultValue}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('suppliers:selectSupplierOrder')}
            showSearch
            style={restSelectProps.style || { width: '200px' }}
            value={value || undefined}
            {...restSelectProps}
        >
            {supplierOrders.map((supplierOrder, index) => {
                return (
                    <Select.Option
                        key={_.get(supplierOrder, 'id', `index-${index}`)}
                        value={_.get(supplierOrder, 'id', undefined)}
                    >
                        {_.get(supplierOrder, 'ref_code', '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectSupplierOrder.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    supplierOrders: PropTypes.array,
    value: PropTypes.string
}

SelectSupplierOrder.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    supplierOrders: [],
    value: undefined
}

export default withTranslation('suppliers')(SelectSupplierOrder)

