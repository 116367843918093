import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_STATES_FETCH,
    ORDER_STATES_FETCH_SUCCESS,
    SET_SHOW_PAYMENT_MODAL,
    SET_SHOW_CANCEL_MODAL,
    SET_NEW_ORDER_STATE,
    // SHOW_PAYMENT_DETAIL_MODAL,
    // ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS,
    // FETCH_QUOTATION_PDF_REQUEST,
    // FETCH_QUOTATION_PDF_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    showPaymentForm: false,
    showCancelForm: false,
    newState: null,
    // tranfersDate: '',
    // showPaymentModal: false,
    // paymentDetailItem: '',
    // quotationPdfLoading: false
})

export default function orderStateReducer (state = initState, action) {
    switch (action.type) {
        case ORDER_STATES_FETCH:
            return state.merge({
                loading: true
            })
        case ORDER_STATES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: action.result
            })
        case SET_SHOW_PAYMENT_MODAL:
            return state.merge({
                showPaymentForm: action.value
            })
        case SET_SHOW_CANCEL_MODAL:
            return state.merge({
                showCancelForm: action.value
            })
        case SET_NEW_ORDER_STATE:
            return state.merge({
                newState: action.newState
            })
        // case SHOW_PAYMENT_DETAIL_MODAL:
        //     return state.merge({
        //         showPaymentModal: action.value
        //     })
        // case ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS:
        //     return state.merge({
        //         paymentDetailItem: action.result
        //     })
        // case FETCH_QUOTATION_PDF_REQUEST:
        //     return state.merge({
        //         quotationPdfLoading: true
        //     })
        // case FETCH_QUOTATION_PDF_SUCCESS:
        //     return state.merge({
        //         quotationPdfLoading: false
        //     })
        default:
            return state
    }
}
