import actions from './actions'
import {List, Map} from "immutable";

const {
    ORDER_PAYMENT_DETAILS_REQUESTING,
    ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS,

    ORDER_PAYMENT_DETAIL_SAVE,
    ORDER_PAYMENT_DETAIL_SAVE_SUCCESS,

    ORDER_PAYMENT_DETAIL_SET_ERRORS,
    ORDER_PAYMENT_DETAILS_SET_PAGINATION
} = actions

const initState = new Map({
    orderPaymentDetails: new List(),
    loading: false,
    transferStartDate: '',
    transferEndDate: '',
    haveBankStatement: '',
    formErrors: new Map(),
    pagination: new Map({
        current: '',
        pageSize: 25,
        total: null
    }),
    saving: false,
})

export default function orderPaymentDetailListReducer (state = initState, action) {
    const result = action.result
    const items = state.get('orderPaymentDetails')

    switch (action.type) {
        case ORDER_PAYMENT_DETAILS_REQUESTING:
            return state.merge({
                loading: true
            })
        case ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS:
            return state.merge({
                loading: false,
                orderPaymentDetails: new List(result),
                transferStartDate: action.transferStartDate,
                transferEndDate: action.transferEndDate,
                haveBankStatement: action.haveBankStatement,
            })
        case ORDER_PAYMENT_DETAIL_SAVE:
            return state.merge({
                saving: true
            })
            case ORDER_PAYMENT_DETAIL_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                orderPaymentDetails: new List(items.includes(result) ? items : items.push(result))
            })
            case ORDER_PAYMENT_DETAIL_SET_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors),
                saving: false
            })
        case ORDER_PAYMENT_DETAILS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })

        default:
            return state
    }
}
