import { List, Map } from 'immutable'

const initState = new Map({
  labels: new List(),
})

export default function customerLabelsReducer (state = initState, action) {
  const result = action.result

  switch (action.type) {
    case 'CUSTOMER_LABELS_FETCH_SUCCESS':
      return state.merge({
        labels: new List().concat(result)
      })

    default:
      return state
  }
}