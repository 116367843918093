import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Select, Spin, Divider } from 'antd'

import { withTranslation } from 'react-i18next'

const SelectSearchSupplierOrder = ({
    defaultValue,
    loading,
    onChange,
    onSearch,
    supplierOrders,
    value,
    i18n,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            dropdownRender={(menu) => (
                <div>
                    {menu}

                    {loading &&
                    <>
                        <Divider style={{ margin: '4px 0' }} />

                        <div
                            style={{ textAlign: 'center' }}
                        >
                            <Spin size="small" />
                        </div>
                    </>
                    }
                </div>
            )}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('orders:orderSummary.searchOrder')}
            showArrow={false}
            showSearch
            style={restSelectProps.style || { width: '200px' }}
            value={value}
            {...restSelectProps}
        >
            {supplierOrders.map((supplierOrder, index) => {
                return (
                    <Select.Option
                        key={_.get(supplierOrder, 'id', `index-${index}`)}
                        value={_.get(supplierOrder, 'ref_code', undefined)}
                    >
                        {_.get(supplierOrder, 'ref_code', '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectSearchSupplierOrder.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    supplierOrders: PropTypes.array,
    value: PropTypes.string
}

SelectSearchSupplierOrder.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    supplierOrders: [],
    value: undefined
}

export default withTranslation(['supplierOrders'])(SelectSearchSupplierOrder)
