import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Card, Form, DatePicker, Input, Button } from 'antd'
import { ContainerTwoTone } from '@ant-design/icons'

import actions from '../../redux/orders/accounting/actions'

const { saveOrderBusinessAddress, setShowTrackingInvoiceForm } = actions

class TrackingInvoiceForm extends Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
        orderId: PropTypes.string.isRequired,

        accountings: ImmutablePropTypes.map.isRequired,
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        setShowTrackingInvoiceForm: PropTypes.func.isRequired
    }

    handleSubmit = (params) => {
        const { invoice, orderId, saveOrderBusinessAddress } = this.props

        params = {
            ...params,
            sending_date: params['sending_date'] ? params['sending_date'].format('YYYY-MM-DD') : null
        }

        saveOrderBusinessAddress({
            address: params,
            id: invoice.id,
            orderId,
            onSuccess: () => {
                this.handleToggleForm()
            }
        })
    }

    handleToggleForm = (id = null) => {
        this.props.setShowTrackingInvoiceForm({ id })
    }

    renderForm () {
        const { i18n, invoice } = this.props
        const sendingDate = _.get(invoice, 'sending_date')

        return (
            <Card size="small" style={{ minWidth: '150px' }}>
                <Form onFinish={this.handleSubmit}
                    layout='vertical'>
                    <Form.Item style={{ margin: '0px' }} label={i18n.t('confirms:invoice.sendingDate')}
                        name='sending_date'
                        initialValue={sendingDate ? moment(sendingDate) : null}>
                            <DatePicker />
                    </Form.Item>
                    <Form.Item style={{ margin: '0px' }} label="EMS"
                        name={'ems_tracking_number'}
                        initialValue={_.get(invoice, 'ems_tracking_number', null)}>
                            <Input />
                    </Form.Item>

                    <div style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" size="small" type="primary">
                            {i18n.t('shared:save')}
                        </Button>
                        <Button
                            onClick={() => this.handleToggleForm()}
                            size="small"
                            style={{ marginLeft: '10px' }}>
                            {i18n.t('shared:close')}
                        </Button>
                    </div>
                </Form>
            </Card>
        )
    }

    render () {
        const { i18n, invoice, accountings } = this.props
        const invoiceId = _.get(invoice, 'id', '')

        if (accountings.get('activeEditShipment') === invoiceId) {
            return this.renderForm()
        }

        return (
            <a onClick={() => this.handleToggleForm(invoiceId)}>
                <div><ContainerTwoTone /> {i18n.t('confirms:invoice.shipment')}</div>
            </a>
        )
    }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        saveOrderBusinessAddress,
        setShowTrackingInvoiceForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TrackingInvoiceForm))
