import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Select } from 'antd'

const QUERY = gql`
  query ProjectContactSelector($projectId: ID!) {
    companyProject(id: $projectId) {
      id
      contacts {
        id
        name
      }
    }
  }
`

const handleFilterOption = (input, option) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const renderOptions = contacts => {
  return contacts.map(({ id, name }) => (
    <Select.Option key={`contact-${id}`} value={id}>
      {name}
    </Select.Option>
  ))
}

const ProjectContactSelector = ({
  projectId,
  ...rest
}) => {
  const { i18n } = useTranslation('projects')
  const { data, loading, error } = useQuery(QUERY, {
    skip: !projectId,
    variables: { projectId }
  })

  if (error) console.error(error)

  const contacts = data && data.companyProject && data.companyProject.contacts || []

  return (
    <Select
      filterOption={handleFilterOption}
      loading={loading}
      disabled={!projectId}
      placeholder={i18n.t('projects:selectContact')}
      showSearch
      {...rest}>
      {renderOptions(contacts)}
    </Select>
  )
}

ProjectContactSelector.propTypes = {
  projectId: PropTypes.string
}

ProjectContactSelector.defaultProps = {}

export default ProjectContactSelector
