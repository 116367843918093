import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { Col, DatePicker, Divider, Row, Select, Table } from 'antd'
import Image from '../../containers/Image'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import EditText from '../../containers/EditText'
import PaymentVoucherWrapper from './paymentVouchers.style'

const PaymentVouchersIndex = ({
    defaultHaveBankStatement,
    defaultTransferDate,
    loading,
    onPageChange,
    onSavePVBankStatement,
    onSendingStateChange,
    onTransferDateChange,
    pagination,
    paymentVouchers
}) => {
    const { i18n } = useTranslation(['confirms', 'orderPaymentDetails'])

    const renderDetail = (record) => {
        return (
            <div>
                {record.date_of_purchase && (
                    <div className="detail-line">
                        <div className="detail-label">{i18n.t('confirms:paymentVoucher.dateOfPurchase')}</div>
                        {moment(record.date_of_purchase).format('YYY-MM-DD')}
                    </div>
                )}
                <div className="detail-line">
                    <div className="detail-label">{i18n.t('confirms:accounting.externalId')}</div>
                    {record.external_id}
                </div>
                {record.has_credit && (
                    <div className="detail-line">
                        <div className="detail-label">{i18n.t('confirms:paymentVoucher.hasCredit')}</div>
                        {_.get(record, 'credit_duration_days', 0)} {i18n.t('confirms:paymentVoucher.days')}
                    </div>
                )}
                <div className="detail-line">
                    <div className="detail-label">{i18n.t('confirms:paymentVoucher.type')}</div>
                    {_.startCase(_.get(record, 'payment_type'))}
                </div>
            </div>
        )
    }

    const renderEditBankStatement = (record) => {
        return (
            <EditText
                initialParams={{ id: record.id }}
                value={_.get(record, 'bank_statement.number', '')}
                onSave={onSavePVBankStatement}
            />
        )
    }

    const renderSlipImages = (images = []) => {
        return images.map((image) => {
            const imageId = image.id
            const thumbUrl = image.file.s || ''
            const originalUrl = image.file.original
            const contentType = image.file.content_type

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    const columns = [
        {
            title: i18n.t('orderPaymentDetails:orderNumber'),
            dataIndex: 'order_number',
            render: (orderNumber) => (
                <Link to={`/orders/${orderNumber}/accountings`}>{orderNumber}</Link>
            )
        },
        {
            title: i18n.t('orderPaymentDetails:transferDate'),
            dataIndex: ['transfer_date', 'raw'],
            render: (transferDate) => transferDate ? moment(transferDate).format('YYYY-MM-DD') : '-'
        },
        {
            title: i18n.t('orderPaymentDetails:careTaker'),
            render: (record) => (
                <div>
                    <div className="detail-line">
                        <div className="detail-label">{i18n.t('orderPaymentDetails:sale')}</div>
                        <div>{(record.sales || []).map(sale => <div>{sale}</div>)}</div>
                    </div>
                    <div className="detail-line">
                        <div className="detail-label">{i18n.t('orderPaymentDetails:cs')}</div>
                        <div>{(record.cses || []).map(cs => <div>{cs}</div>)}</div>
                    </div>
                    <div className="detail-line">
                        <div className="detail-label">{i18n.t('orderPaymentDetails:purchaser')}</div>
                        <div>{(record.purchasers || []).map(purchaser => <div>{purchaser}</div>)}</div>
                    </div>
                </div>
            )
        },
        {
            title: i18n.t('orderPaymentDetails:detail'),
            render: (record) => renderDetail(record)
        },
        {
            title: i18n.t('orderPaymentDetails:amount'),
            dataIndex: ['amount', 'display']
        },
        {
            title: i18n.t('orderPaymentDetails:slipImages'),
            render: (record) => (
                <div className="image-container">
                    {renderSlipImages(record.payment_voucher_images)}
                </div>
            )
        },
        {
            title: i18n.t('orderPaymentDetails:bankStatement'),
            render: (record) => {
                const bankStatement = record.bank_statement

                if (_.isEmpty(bankStatement)) { return renderEditBankStatement(record) }

                return (
                    <div>
                        {renderEditBankStatement(record)}
                        <div className="detail-line">
                            <div className="detail-label">{i18n.t('orderPaymentDetails:accountNumber')}</div>
                            {_.get(bankStatement, 'account_number', '-')}
                        </div>
                        <div className="detail-line">
                            <div className="detail-label">{i18n.t('orderPaymentDetails:date')}</div>
                            {_.get(bankStatement, 'date.display', '-')}
                        </div>
                        <div className="detail-line">
                            <div className="detail-label">{i18n.t('orderPaymentDetails:transactionCode')}</div>
                            {_.get(bankStatement, 'transaction_code', '-')}
                        </div>
                        <div className="detail-line">
                            <div className="detail-label">{i18n.t('orderPaymentDetails:channel')}</div>
                            {_.get(bankStatement, 'channel', '-')}
                        </div>
                    </div>
                )
            }
        },
        {
            title: i18n.t('orderPaymentDetails:bankStatementAmount'),
            render: (record) => {
                if (_.isEmpty(record.bank_statement)) { return null }

                return _.get(record, 'bank_statement.debit_amount.display', '-')
            }
        },
    ]

    return (
        <PaymentVoucherWrapper>
            <div>
                <Row type="flex" align="middle" style={{ marginBottom: '8px' }}>
                    <Col span={12}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orderPaymentDetails:filterByTransferDate')}:
                        </span>
                        <DatePicker.RangePicker
                            defaultValue={defaultTransferDate}
                            onChange={onTransferDateChange}
                        />
                    </Col>
                    <Col span={12}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orderPaymentDetails:filterWithBankStatement')}:
                        </span>
                        <Select style={{ width: '200px' }} allowClear
                                defaultValue={defaultHaveBankStatement}
                                onChange={onSendingStateChange}
                                placeholder={i18n.t('orderPaymentDetails:filterWithBankStatement')}>
                            <Select.Option value="true">
                                {i18n.t('orderPaymentDetails:have')}
                            </Select.Option>
                            <Select.Option value="false">
                                {i18n.t('orderPaymentDetails:dontHave')}
                            </Select.Option>
                        </Select>
                    </Col>
                </Row>
                <Divider />
            </div>

            <Table
                columns={columns}
                dataSource={paymentVouchers}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
        </PaymentVoucherWrapper>
    )
}

PaymentVouchersIndex.propTypes = {
    defaultHaveBankStatement: PropTypes.string,
    defaultTransferDate: PropTypes.array,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    onSavePVBankStatement: PropTypes.func.isRequired,
    onSendingStateChange: PropTypes.func.isRequired,
    onTransferDateChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    paymentVouchers: PropTypes.array.isRequired
}

PaymentVouchersIndex.defaultProps = {
    defaultHaveBankStatement: undefined,
    defaultTransferDate: null,
    loading: false,
    pagination: false,
    paymentVouchers: []
}

export default withImagePreview(PaymentVouchersIndex)
