import { schema } from 'normalizr'

export const bankAccountSchema = new schema.Entity('bankAccounts')
export const bankAccountsSchema = [bankAccountSchema]

export const bankStatementSchema = new schema.Entity('bankStatements')
export const bankStatementsSchema = [bankStatementSchema]

export const bankStatementCacheSchema = new schema.Entity('bankStatementCaches')
export const bankStatementCachesSchema = [bankStatementCacheSchema]

export const brandSchema = new schema.Entity('brands')
export const brandsSchema = [brandSchema]

export const businessAddressSchema = new schema.Entity('businessAddresses')
export const businessAddressesSchema = [businessAddressSchema]

export const categorySchema = new schema.Entity('categories')
export const categoriesSchema = [categorySchema]

export const chatRoomMemberSchema = new schema.Entity('chatRoomMembers')
export const chatRoomMembersSchema = [chatRoomMemberSchema]

export const chatRoomMessageSchema = new schema.Entity('chatRoomMessages')
export const chatRoomMessagesSchema = [chatRoomMessageSchema]

export const chatRoomSchema = new schema.Entity('chatRooms', {
    messages: chatRoomMessagesSchema
})
export const chatRoomsSchema = [chatRoomSchema]

export const chartOfAccountSchema = new schema.Entity('chartOfAccounts')
export const chartOfAccountsSchema = [chartOfAccountSchema]

export const companySchema = new schema.Entity('customerCompanies')
export const companiesSchema = [companySchema]

export const companyProjectSchema = new schema.Entity('companyProjects')
export const companyProjectsSchema = [companyProjectSchema]

export const customerCompanyProjectSchema = new schema.Entity('customerCompanyProjects')
export const customerCompanyProjectsSchema = [customerCompanyProjectSchema]

export const customerEmailSchema = new schema.Entity('customerEmails')
export const customerEmailsSchema = [customerEmailSchema]

export const customerLineSchema = new schema.Entity('customerLines')
export const customerLinesSchema = [customerLineSchema]

export const customerPhoneNumberSchema = new schema.Entity('customerPhoneNumbers')
export const customerPhoneNumbersSchema = [customerPhoneNumberSchema]

export const customerRoleSchema = new schema.Entity('customerRoles')
export const customerRolesSchema = [customerRoleSchema]

export const customerServiceSchema = new schema.Entity('customerServices')
export const customerServicesSchema = [customerServiceSchema]

export const customerReviewSchema = new schema.Entity('customerReviews')
export const customerReviewsSchema = [customerReviewSchema]

export const purchaserSchema = new schema.Entity('purchasers')
export const purchasersSchema = [purchaserSchema]

export const customerTypeSchema = new schema.Entity('customerTypes')
export const customerTypesSchema = [customerTypeSchema]

export const customerWalletSchema = new schema.Entity('customerWallets')
export const customerWalletsSchema = [customerWalletSchema]

export const districtSchema = new schema.Entity('districts')
export const districtsSchema = [districtSchema]

export const invoiceAdjustmentItemSchema = new schema.Entity('invoiceAdjustmentItems')
export const invoiceAdjustmentItemsSchema = [invoiceAdjustmentItemSchema]

export const invoiceAdjustmentSchema = new schema.Entity('invoiceAdjustments', {
    items: invoiceAdjustmentItemsSchema
})
export const invoiceAdjustmentsSchema = [invoiceAdjustmentSchema]

export const invoiceHistorySchema = new schema.Entity('invoiceHistories')
export const invoiceHistoriesSchema = [invoiceHistorySchema]

export const invoiceEmailHistorySchema = new schema.Entity('invoiceEmailHistories')
export const invoiceEmailHistoriesSchema = [invoiceEmailHistorySchema]

export const itemSchema = new schema.Entity('items')
export const itemsSchema = [itemSchema]

export const itemAttributeSchema = new schema.Entity('itemAttributes', {
    item: itemSchema,
    item_with_attribute: itemSchema
})
export const itemAttributesSchema = [itemAttributeSchema]

export const itemTruckImageSchema = new schema.Entity('itemTruckImages')
export const itemTruckImagesSchema = [itemTruckImageSchema]

export const itemWithProductGroupSchema = new schema.Entity('itemWithProductGroups')
export const itemWithProductGroupsSchema = [itemWithProductGroupSchema]

export const journalEntryFormatSchema = new schema.Entity('journalEntryFormats')
export const journalEntryFormatsSchema = [journalEntryFormatSchema]

export const journalEntrySchema = new schema.Entity('journalEntries')
export const journalEntriesSchema = [journalEntrySchema]

export const journalEntryDateSchema = new schema.Entity('journalEntryDates')
export const journalEntryDatesSchema = [journalEntryDateSchema]

export const journalEntryFormatItemSchema = new schema.Entity('journalEntryFormatItems')
export const journalEntryFormatItemsSchema = [journalEntryFormatItemSchema]

export const mailingAddressSchema = new schema.Entity('mailingAddresses')
export const mailingAddressesSchema = [mailingAddressSchema]

export const noteAttachmentSchema = new schema.Entity('noteAttachments')
export const noteAttachmentsSchema = [noteAttachmentSchema]

export const notificationSchema = new schema.Entity('notifications')
export const notificationsSchema = [notificationSchema]

export const occupationSchema = new schema.Entity('customerOccupations')
export const occupationsSchema = [occupationSchema]

export const orderActivityLogLineItemSchema = new schema.Entity('orderActivityLogLineItems')
export const orderActivityLogLineItemsSchema = [orderActivityLogLineItemSchema]

export const orderActivityLogOrderSchema = new schema.Entity('orderActivityLogOrder')
export const orderActivityLogOrdersSchema = [orderActivityLogOrderSchema]

export const orderApproverWaitSchema = new schema.Entity('orderApproverWaits')
export const orderApproverWaitsSchema = [orderApproverWaitSchema]

export const orderApproverWaitStateSchema = new schema.Entity('orderApproverWaitStates')
export const orderApproverWaitStatesSchema = [orderApproverWaitStateSchema]

export const orderBusinessAddressSchema = new schema.Entity('orderBusinessAddresses')
export const orderBusinessAddressesSchema = [orderBusinessAddressSchema]

export const orderChangeActivitySchema = new schema.Entity('orderChangeActivity')
export const orderChangeActivitiesSchema = [orderChangeActivitySchema]

export const orderCompleteNoteSchema = new schema.Entity('orderCompleteNotes')
export const orderCompleteNotesSchema = [orderCompleteNoteSchema]

export const orderConditionSchema = new schema.Entity('orderConditions')
export const orderConditionsSchema = [orderConditionSchema]

export const orderDispatchSchema = new schema.Entity('orderDispatches')
export const orderDispatchesSchema = [orderDispatchSchema]

export const orderMailingAddressSchema = new schema.Entity('orderMailingAddresses')
export const orderMailingAddressesSchema = [orderMailingAddressSchema]

export const orderNoteImageSchema = new schema.Entity('orderNoteImages')
export const orderNoteImagesSchema = [orderNoteImageSchema]

export const orderPaymentDetailSchema = new schema.Entity('orderPaymentDetails')
export const orderPaymentDetailsSchema = [orderPaymentDetailSchema]

export const orderPaymentDetailInstallmentSchema = new schema.Entity('orderPaymentDetailInstallments')
export const orderPaymentDetailInstallmentsSchema = [orderPaymentDetailInstallmentSchema]

export const orderReviewSchema = new schema.Entity('orderReviews')
export const orderReviewsSchema = [orderReviewSchema]

export const orderStateChangeActivitySchema = new schema.Entity('orderStateChangeActivity')
export const orderStateChangeActivitiesSchema = [orderStateChangeActivitySchema]

export const orderStateSchema = new schema.Entity('orderStates')
export const orderStatesSchema = [orderStateSchema]

export const orderShippingAddressSchema = new schema.Entity('orderShippingAddress')
export const orderShippingAddressesSchema = [orderShippingAddressSchema]

export const orderShippingItemSchema = new schema.Entity('orderShippingItems')
export const orderShippingItemsSchema = [orderShippingItemSchema]

export const orderSummarySchema = new schema.Entity('orderSummaries')
export const orderSummariesSchema = [orderSummarySchema]

export const orderSummarySaleSchema = new schema.Entity('orderSummarySales')
export const orderSummarySalesSchema = [orderSummarySaleSchema]

export const orderSupplierNoteImageSchema = new schema.Entity('orderSupplierNoteImages')
export const orderSupplierNoteImagesSchema = [orderSupplierNoteImageSchema]

export const orderSupplierNoteSchema = new schema.Entity('orderSupplierNotes', {
    images: orderSupplierNoteImagesSchema
})
export const orderSupplierNotesSchema = [orderSupplierNoteSchema]

export const orderTrackingSchema = new schema.Entity('orderTrackings')
export const orderTrackingsSchema = [orderTrackingSchema]

export const paymentOptionSchema = new schema.Entity('paymentOptions')
export const paymentOptionsSchema = [paymentOptionSchema]

export const paymentDetailSchema = new schema.Entity('paymentDetails')
export const paymentDetailsSchema = [paymentDetailSchema]

export const productGroupSchema = new schema.Entity('productGroups')
export const productGroupsSchema = [productGroupSchema]

export const productGroupItemSchema = new schema.Entity('productGroupItems')
export const productGroupItemsSchema = [productGroupItemSchema]

export const productTypeSchema = new schema.Entity('productTypes')
export const productTypesSchema = [productTypeSchema]

export const provinceSchema = new schema.Entity('provinces')
export const provincesSchema = [provinceSchema]

export const purchaseOrderStatusHistorySchema = new schema.Entity('purchaseOrderStatusHistories')
export const purchaseOrderStatusHistoriesSchema = [purchaseOrderStatusHistorySchema]

export const requestItemIndexSchema = new schema.Entity('requestItemIndices')
export const requestItemIndicesSchema = [requestItemIndexSchema]

export const saleSchema = new schema.Entity('sales')
export const salesSchema = [saleSchema]

export const saleSummarySchema = new schema.Entity('saleSummaries')
export const saleSummariesSchema = [saleSummarySchema]

export const saleProspectSchema = new schema.Entity('saleProspects')
export const saleProspectsSchema = [saleProspectSchema]

export const shippingAddressSchema = new schema.Entity('shippingAddresses')
export const shippingAddressesSchema = [shippingAddressSchema]

export const shippingCostSchema = new schema.Entity('shippingCosts')
export const shippingCostsSchema = [shippingCostSchema]

export const shippingRateSchema = new schema.Entity('shippingRates')
export const shippingRatesSchema = [shippingRateSchema]

export const supplierAccountingGroupSchema = new schema.Entity('supplierAccountingGroups')
export const supplierAccountingGroupsSchema = [supplierAccountingGroupSchema]

export const supplierInvoiceSchema = new schema.Entity('supplierInvoices')
export const supplierInvoicesSchema = [supplierInvoiceSchema]

export const supplierItemSchema = new schema.Entity('supplierItems')
export const supplierItemsSchema = [supplierItemSchema]

export const supplierOrderChangeActivitySchema = new schema.Entity('supplierOrderChangeActivity')
export const supplierOrderChangeActivitiesSchema = [supplierOrderChangeActivitySchema]

export const supplierOrderStateChangeActivitySchema = new schema.Entity('supplierOrderStateChangeActivity')
export const supplierOrderStateChangeActivitiesSchema = [supplierOrderStateChangeActivitySchema]

export const supplierOverTransferAccountSchema = new schema.Entity('supplierOverTransferAccounts')
export const supplierOverTransferAccountsSchema = [supplierOverTransferAccountSchema]

export const supplierOverTransferDestinationSchema = new schema.Entity('supplierOverTransferDestinations')
export const supplierOverTransferDestinationsSchema = [supplierOverTransferDestinationSchema]

export const supplierOverTransferItemSchema = new schema.Entity('supplierOverTransferItems')
export const supplierOverTransferItemsSchema = [supplierOverTransferItemSchema]

export const supplierOverTransferItemImageSchema = new schema.Entity('supplierOverTransferItemImages')
export const supplierOverTransferItemImagesSchema = [supplierOverTransferItemImageSchema]

export const paymentVoucherSchema = new schema.Entity('paymentVouchers')
export const paymentVouchersSchema = [paymentVoucherSchema]

export const staffRoleSchema = new schema.Entity('staffRoles')
export const staffRolesSchema = [staffRoleSchema]

export const subCategorySchema = new schema.Entity('subCategories')
export const subCategoriesSchema = [subCategorySchema]

export const vehicleTypeSchema = new schema.Entity('vehicleTypes')
export const vehicleTypesSchema = [vehicleTypeSchema]

export const supplierSchema = new schema.Entity('suppliers')
export const suppliersSchema = [supplierSchema]

export const taxInvoiceInfoSchema = new schema.Entity('taxInvoiceInfos')
export const taxInvoiceInfosSchema = [taxInvoiceInfoSchema]

export const customerTagSchema = new schema.Entity('customerTags')
export const customerTagsSchema = [customerTagSchema]

export const customerSchema = new schema.Entity('customers', {
    customer_type: customerTypeSchema,
    customer_occupation: occupationSchema,
    customer_companies: companiesSchema,
    customer_emails: customerEmailsSchema,
    customer_lines: customerLinesSchema,
    customer_phone_numbers: customerPhoneNumbersSchema,
    shipping_addresses: shippingAddressesSchema,
    roles: customerRolesSchema,
    customer_tags: customerTagsSchema,
})
export const customersSchema = [customerSchema]

export const customerNoteSchema = new schema.Entity('customerNotes', {
    note_attachments: noteAttachmentsSchema,
    order_note_images: orderNoteImagesSchema
})
export const customerNotesSchema = [customerNoteSchema]

export const customerCompanySchema = new schema.Entity('customerCompanies', {
    customers: customersSchema
})

export const orderCompleteStateSchema = new schema.Entity('orderCompleteStates')
export const orderCompleteStatesSchema = [orderCompleteStateSchema]

export const orderYieldSchema = new schema.Entity('orderYields')
export const orderYieldsSchema = [orderYieldSchema]

export const orderSchema = new schema.Entity('orders')
export const ordersSchema = [orderSchema]

export const supplierOrderReviewSchema = new schema.Entity('supplierOrderReviews')
export const supplierOrderReviewsSchema = [supplierOrderReviewSchema]

export const orderTransferBackItemSchema = new schema.Entity('orderTransferBackItems')
export const orderTransferBackItemsSchema = [orderTransferBackItemSchema]

export const orderTransferBackSchema = new schema.Entity('orderTransferBacks')
export const orderTransferBacksSchema = [orderTransferBackSchema]

export const reservationTruckTypeSchema = new schema.Entity('reservationTruckTypes')
export const reservationTruckTypesSchema = [reservationTruckTypeSchema]

export const sourcingProfileSchema = new schema.Entity('sourcingProfiles')
export const sourcingProfilesSchema = [sourcingProfileSchema]

export const specialInstructionSchema = new schema.Entity('specialInstructions')
export const specialInstructionsSchema = [specialInstructionSchema]

export const lineItemSchema = new schema.Entity('lineItems', {
    order: orderSchema,
    sourcing_profile: sourcingProfileSchema,
    special_instruction: specialInstructionSchema
})

export const lineItemsSchema = [lineItemSchema]

export const lineItemActivitySchema = new schema.Entity('lineItemActivities')
export const lineItemActivitiesSchema = [lineItemActivitySchema]

export const staffSchema = new schema.Entity('staffs', {
    staff_roles: staffRolesSchema
})
export const staffsSchema = [staffSchema]

export const paymentVoucherImageSchema = new schema.Entity('paymentVoucherImages')
export const paymentVoucherImagesSchema = [paymentVoucherImageSchema]

export const pickupDetailImageSchema = new schema.Entity('pickupDetailImages')
export const pickupDetailImagesSchema = [pickupDetailImageSchema]

export const pickupDetailSchema = new schema.Entity('pickupDetails', {
    pickup_detail_images: pickupDetailImagesSchema
})

export const pickupDetailsSchema = [pickupDetailSchema]

export const profitSharingSchema = new schema.Entity('profitSharings')
export const profitSharingsSchema = [profitSharingSchema]

export const projectContactSchema = new schema.Entity('projectContacts')
export const projectContactsSchema = [projectContactSchema]

export const purchaseOrderAddressSchema = new schema.Entity('purchaseOrderAddresses')
export const purchaseOrderAddressesSchema = [purchaseOrderAddressSchema]

export const potentialLeadSchema = new schema.Entity('potentialLeads', {
    supplier: supplierSchema,
    brand: brandSchema
})
export const potentialLeadsSchema = [potentialLeadSchema]

export const scbPaymentConfirmSchema = new schema.Entity('scbPaymentConfirms')
export const scbPaymentConfirmsSchema = [scbPaymentConfirmSchema]

export const supplierShippingCostSchema = new schema.Entity('supplierShippingCosts')
export const supplierShippingCostsSchema = [supplierShippingCostSchema]

export const supplierShippingTruckSchema = new schema.Entity('supplierShippingTrucks')
export const supplierShippingTrucksSchema = [supplierShippingTruckSchema]

export const supplierOrderCategorySchema = new schema.Entity('supplierOrderCategories')
export const supplierOrderCategoriesSchema = [supplierOrderCategorySchema]

export const supplierOrderSchema = new schema.Entity('supplierOrders')
export const supplierOrdersSchema = [supplierOrderSchema]

export const supplierLineItemSchema = new schema.Entity('supplierLineItems', {
    supplier: supplierSchema,
    supplier_order: supplierOrderSchema,
    line_item: lineItemSchema
})
export const supplierLineItemsSchema = [supplierLineItemSchema]

export const supplierOrderStateSchema = new schema.Entity('supplierOrderStates')
export const supplierOrderStatesSchema = [supplierOrderStateSchema]

export const truckSchema = new schema.Entity('trucks')
export const trucksSchema = [truckSchema]

export const truckImageSchema = new schema.Entity('truckImages')
export const truckImagesSchema = [truckImageSchema]

export const truckOriginAddressSchema = new schema.Entity('truckOriginAddresses')
export const truckOriginAddressesSchema = [truckOriginAddressSchema]

export const truckOwnerBankAccountSchema = new schema.Entity('truckOwnerBankAccounts')
export const truckOwnerBankAccountsSchema = [truckOwnerBankAccountSchema]

export const truckOwnerSchema = new schema.Entity('truckOwners')
export const truckOwnersSchema = [truckOwnerSchema]

export const truckReservationSchema = new schema.Entity('truckReservations')
export const truckReservationsSchema = [truckReservationSchema]

export const orderVoucherCheckSchema = new schema.Entity('orderVoucherChecks')
export const orderVoucherChecksSchema = [orderVoucherCheckSchema]

export const withholdingTaxSchema = new schema.Entity('withholdingTaxes')
export const withholdingTaxesSchema = [withholdingTaxSchema]

bankStatementSchema.define({
    bank_account: bankAccountSchema
})

businessAddressSchema.define({
    mailing_address: mailingAddressSchema
})

itemTruckImageSchema.define({
    province: provinceSchema
})

companyProjectSchema.define({
    customer_company: customerCompanySchema
})

customerCompanyProjectSchema.define({
    customer: customerSchema
})

customerWalletSchema.define({
    customer: customerSchema
})

orderBusinessAddressSchema.define({
    deposit_invoices: orderBusinessAddressesSchema
})

itemAttributeSchema.define({
    children: itemAttributesSchema
})

orderSchema.define({
    invoice_histories: invoiceHistoriesSchema,
    order_approver_waits: orderApproverWaitSchema,
    complete_note: orderCompleteNoteSchema,
    order_conditions: orderConditionsSchema,
    order_dispatch: orderDispatchSchema,
    order_transfer_backs: orderTransferBacksSchema,
    order_supplier_notes: orderSupplierNotesSchema,
    order_yield: orderYieldSchema,
    pv_voucher_checked: orderVoucherCheckSchema,
    rv_voucher_checked: orderVoucherCheckSchema,
    shipping_costs: shippingCostsSchema,
    supplier_orders: supplierOrdersSchema
})

supplierOrderReviewSchema.define({
    supplier_orders: supplierOrdersSchema
})

orderChangeActivitySchema.define({
    order_state_change_activities: orderStateChangeActivitiesSchema,
    supplier_order_change_activities: supplierOrderChangeActivitiesSchema
})

orderCompleteStateSchema.define({
    staff: staffSchema
})

orderYieldSchema.define({
    order: orderSchema,
    complete_state: orderCompleteStateSchema,
    cs: staffSchema,
    sale: staffSchema
})

paymentVoucherSchema.define({
    bank_statement: bankStatementSchema,
    supplier: supplierSchema,
    supplier_orders: supplierOrdersSchema,
    shipping_costs: shippingCostsSchema,
    supplier_invoices: supplierInvoicesSchema
})

purchaseOrderAddressSchema.define({
    supplier_order: supplierOrderSchema
})

purchaseOrderStatusHistorySchema.define({
    staff: staffSchema
})

scbPaymentConfirmSchema.define({
    customer: customerSchema,
    order: orderSchema
})

supplierOrderSchema.define({
    is_checked_history: purchaseOrderStatusHistorySchema,
    line_items: supplierLineItemsSchema,
    order_supplier_notes: orderSupplierNotesSchema,
    order: orderSchema,
    payment_voucher_images: paymentVoucherImagesSchema,
    payment_voucher: paymentVoucherSchema,
    purchase_order_address: purchaseOrderAddressSchema,
    supplier_order_category: supplierOrderCategorySchema,
    supplier_deposit_items: supplierOverTransferItemsSchema,
    supplier_withdraw_items: supplierOverTransferItemsSchema,
    supplier_shipping_costs: supplierShippingCostsSchema,
    supplier: supplierSchema,
    trucks: trucksSchema,
    profit_sharing: profitSharingSchema
})

supplierOrderChangeActivitySchema.define({
    supplier_order_state_change_activities: supplierOrderStateChangeActivitiesSchema
})

supplierOverTransferAccountSchema.define({
    supplier: supplierSchema
})

supplierOverTransferDestinationSchema.define({
    order: orderSchema,
    supplier_order: supplierOrderSchema
})

supplierOverTransferItemSchema.define({
    supplier_over_transfer_account: supplierOverTransferAccountSchema,
    order: orderSchema,
    supplier_order: supplierOrderSchema,
    supplier_over_transfer_destination: supplierOverTransferDestinationSchema
})

supplierSchema.define({
    supplier_orders: supplierOrdersSchema,
    supplier_over_transfer_account: supplierOverTransferAccountSchema,
    supplier_over_transfer_items: supplierOverTransferItemsSchema
})

supplierShippingCostSchema.define({
    supplier: supplierSchema,
    supplier_order: supplierOrderSchema,

    pickup_detail: pickupDetailSchema,
    supplier_shipping_truck: supplierShippingTruckSchema
})

supplierShippingTruckSchema.define({
    truck: truckSchema,
    truck_owner: truckOwnerSchema
})

truckOwnerSchema.define({
    staffs: staffsSchema,
    truck_owner_bank_accounts: truckOwnerBankAccountsSchema,
    trucks: trucksSchema
})

truckReservationSchema.define({
    reservation_truck_type: reservationTruckTypeSchema,
    truck_owner: truckOwnerSchema,
    truck: truckSchema,
    truck_origin_addresses: truckOriginAddressesSchema,
    supplier_orders: supplierOrdersSchema
})

truckSchema.define({
    truck_owner: truckOwnerSchema,
    vehicle_type: vehicleTypeSchema,
    truck_images: truckImagesSchema
})

orderSummarySchema.define({
    order_approver_waits: orderApproverWaitSchema
})

orderVoucherCheckSchema.define({
    checked_by: staffSchema
})

paymentDetailSchema.define({
    bank_statement: bankStatementSchema,
    scb_payment_confirms: scbPaymentConfirmsSchema
})
