import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch, apiPostForm } from '../../../helpers/restRequest'
import actions from './actions'
import { profitSharingSchema, profitSharingsSchema } from '../../schema'
import { deserialize } from '../../../helpers/jsonApi'

const {
    CUSTOMER_PROFIT_SHARINGS_FETCH_REQUEST,
    CUSTOMER_PROFIT_SHARINGS_FETCH,
    CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS,
    CUSTOMER_PROFIT_SHARING_SET_PAGINATION,
    CUSTOMER_PROFIT_SHARING_SAVE_REQUEST,
    CUSTOMER_PROFIT_SHARING_SAVE,
    CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS,
    CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS,
    CUSTOMER_PROFIT_SHARING_DELETE_REQUEST,
    CUSTOMER_PROFIT_SHARING_DELETE,
    CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS,
    PROFIT_SHARING_ORDER_FETCH_REQUEST,
    PROFIT_SHARING_ORDER_FETCH,
    PROFIT_SHARING_ORDER_SET_PAGINATION,
    PROFIT_SHARINGS_ORDER_FETCH_SUCCESS
} = actions

export function *fetchCustomerProfitSharings () {
    yield takeEvery(CUSTOMER_PROFIT_SHARINGS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_PROFIT_SHARINGS_FETCH })

        const { customerId, page, per, all } = payload
        const allData = all ? 'true' : 'false'

        const queryString = `page=${page}&per=${per}&all=${allData}`

        const path = `/customers/${customerId}/profit_sharings?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, profitSharingsSchema)
        const total = data.meta ? data.meta.total_pages * per : null

        yield put({
            type: CUSTOMER_PROFIT_SHARING_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchProfitSharingOrders () {
    yield takeEvery(PROFIT_SHARING_ORDER_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: PROFIT_SHARING_ORDER_FETCH })
        let queryString = null
        const { page, per, query } = payload
        if (query) {
            queryString = `page=${page}&per=${per}&query=${query}`
        } else {
            queryString = `page=${page}&per=${per}`
        }

        const path = `/profit_sharings?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, profitSharingsSchema)
        const total = data.meta ? data.meta.total_pages * per : null
        yield put({
            type: PROFIT_SHARING_ORDER_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PROFIT_SHARINGS_ORDER_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveCustomerProfitSharing () {
    yield takeLatest(CUSTOMER_PROFIT_SHARING_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_PROFIT_SHARING_SAVE, saving: true })

        const { customerId, id, params, onSuccess, onError } = payload

        const basePath = `/customers/${customerId}/profit_sharings`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { profit_sharing: params })
            if ('error' in data) {
                yield put({
                    type: CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, profitSharingSchema)

                yield put({
                    type: CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: CUSTOMER_PROFIT_SHARING_SAVE, saving: false })
        }
    })
}

export function *deleteCustomerProfitSharing () {
    yield takeLatest(CUSTOMER_PROFIT_SHARING_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_PROFIT_SHARING_DELETE })

        const { customerId, id, onSuccess } = payload
        const path = `/customers/${customerId}/profit_sharings/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, profitSharingSchema)

        yield put({
            type: CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCustomerProfitSharings),
        fork(fetchProfitSharingOrders),
        fork(saveCustomerProfitSharing),
        fork(deleteCustomerProfitSharing)
    ])
}
