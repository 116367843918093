import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import _ from 'lodash'

import { Spin, Tabs } from 'antd'

import SupplierOverTransferAccountListContainer from './SupplierOverTransferAccountListContainer'
import SupplierOrdersListContainer from './SupplierOrdersListContainer'

import actions from '../../redux/suppliers/overTransferItems/actions'
import supplierActions from '../../redux/suppliers/actions'

const { fetchSupplierWithOverTransferItems } = actions
const { fetchSupplier } = supplierActions

class SupplierContainer extends Component {
    static propTypes = {
        supplierId: PropTypes.string.isRequired,

        fetchSupplierWithOverTransferItems: PropTypes.func.isRequired,
        supplierOverTransferItems: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const { fetchSupplier, fetchSupplierWithOverTransferItems, supplierId } = this.props

        fetchSupplier({ id: supplierId  })
        fetchSupplierWithOverTransferItems({ supplierId, page: 1 })
    }

    handlePageChange = (pagination) => {
        const { fetchSupplierWithOverTransferItems, supplierId } = this.props

        fetchSupplierWithOverTransferItems({ supplierId, page: pagination.current })
    }

    render () {
        const { supplierId, supplierOverTransferItems, i18n, supplierEntities, suppliers } = this.props
        const supplier = supplierEntities.get(supplierId) || new Map()

        return (
            <Tabs defaultActiveKey="overTransfers">
                <Tabs.TabPane tab={i18n.t('suppliers/overTransferItems:overTransferList')} key="overTransfers">
                    <SupplierOverTransferAccountListContainer
                        dataSourceFrom="suppliers"
                        onPageChange={this.handlePageChange}
                        pagination={{
                            ...supplierOverTransferItems.get('pagination').toJS(),
                            showSizeChanger: false
                        }}
                        supplierId={supplierId}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t('suppliers:supplierOrders')} key="supplierOrders">
                    { suppliers.get('loading') ? <Spin /> : <h2>{supplier.get('name')}</h2>}
                    <SupplierOrdersListContainer supplierId={supplierId} />
                </Tabs.TabPane>
            </Tabs>
        )
    }
}

const mapStateToProps = state => ({
    supplierOverTransferItems: state.get('supplierOverTransferItems'),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplierWithOverTransferItems,
        fetchSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['suppliers', 'suppliers/overTransferItems'])(SupplierContainer))
