import React from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import Render from './render'

const CUSTOMER_QUERY = gql`
    query Customer($customerNo: String!) {
        customer(customerNo: $customerNo) {
            id
            customerType {
                id
                name
            }
        }
    }
`

const UPDATE_CUSTOMER_MUTATION = gql`
    mutation UpdateCustomer($customerNo: String!, $customerParams: AdminCustomerInput!) {
        updateCustomer(customerNo: $customerNo, customerParams: $customerParams) {
            errors
            customer {
                id
                customerType {
                    id
                    name
                }
            }
        }
    }
`

const CustomerTypeForm = ({ customerId, onClose, onSuccess }) => {
    const { loading, data, error } = useQuery(CUSTOMER_QUERY, {
        skip: !customerId,
        variables: { customerNo: customerId }
    })
    const [updateCustomer, { loading: saving }] = useMutation(UPDATE_CUSTOMER_MUTATION, {
        onCompleted: ({ updateCustomer }) => {
            if (updateCustomer.errors) {
                console.error(JSON.parse(updateCustomer.errors))
            } else {
                onSuccess()
            }
        }
    })

    if (error) return console.error(error)

    const customer = (data && data.customer) || {}

    const onSaveCustomer = values => {
        updateCustomer({ variables: { customerNo: customerId, customerParams: values } })
    }
    
    const initialValues = {
        customerTypeId: customer.customerType && customer.customerType.id
    }

    return (
        <Render
            initialValues={initialValues}
            loading={loading}
            onClose={onClose}
            onSaveCustomer={onSaveCustomer}
            saving={saving}
        />
    )
}

CustomerTypeForm.propTypes = {
    customerId: PropTypes.string,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
}

CustomerTypeForm.defaultProps = {
    onClose: () => {},
    onSuccess: () => {}
}

export default CustomerTypeForm
