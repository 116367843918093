const actions = {
    DOCUMENTS_FETCH_REQUEST: 'DOCUMENTS_FETCH_REQUEST',
    DOCUMENTS_FETCH_SUCCESS: 'DOCUMENTS_FETCH_SUCCESS',
    DOCUMENTS_FETCH: 'DOCUMENTS_FETCH',
    DOCUMENTS_SET_INVOICE_MODAL: 'DOCUMENTS_SET_INVOICE_MODAL',
    DOCUMENTS_SET_PURCHASER_LOADING: 'DOCUMENTS_SET_PURCHASER_LOADING',
    DOCUMENTS_SET_QUOTATION_LOADING: 'DOCUMENTS_SET_QUOTATION_LOADING',
    DOCUMENTS_SET_SHIPPING_ORDER_LOADING: 'DOCUMENTS_SET_SHIPPING_ORDER_LOADING',
    DOCUMENTS_SET_ORDER_ID: 'DOCUMENTS_SET_ORDER_ID',
    DOCUMENT_SET_PDF_LANGUAGE: 'DOCUMENT_SET_PDF_LANGUAGE',
    TAX_INVOICE_DOCUMENTS_FETCH_REQUEST: 'TAX_INVOICE_DOCUMENTS_FETCH_REQUEST',
    TAX_INVOICE_DOCUMENTS_FETCH: 'TAX_INVOICE_DOCUMENTS_FETCH',
    TAX_INVOICE_DOCUMENTS_FETCH_SUCCESS: 'TAX_INVOICE_DOCUMENTS_FETCH_SUCCESS',
    INVOICE_REPORTS_FETCH_REQUEST: 'INVOICE_REPORTS_FETCH_REQUEST',
    INVOICE_REPORTS_FETCH_SUCCESSS: 'INVOICE_REPORTS_FETCH_SUCCESS',
    INVOICE_REPORTS_FETCH: 'INVOICE_REPORTS_FETCH',
    INVOICE_REPORT_SAVE_REQUEST: 'INVOICE_REPORT_SAVE_REQUEST',
    INVOICE_REPORT_SAVE_SUCCESS: 'INVOICE_REPORT_SAVE_SUCCESS',
    INVOICE_REPORT_SAVE: 'INVOICE_REPORT_SAVE',
    TRUCK_RESERVATION_REPORTS_FETCH_REQUEST: 'TRUCK_RESERVATION_REPORTS_FETCH_REQUEST',
    TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS: 'TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS',
    TRUCK_RESERVATION_REPORTS_FETCH: 'TRUCK_RESERVATION_REPORTS_FETCH',
    REPORTS_FETCH_REQUEST: 'REPORTS_FETCH_REQUEST',

    setDashboardDocumentPDFLanguage: (value) => ({
        type: actions.DOCUMENT_SET_PDF_LANGUAGE,
        value
    }),

    fetchOrderDocument: (documentType, locale, orderId, typeId) => ({
        type: actions.DOCUMENTS_FETCH_REQUEST,
        payload: { documentType, locale, orderId, typeId }
    }),

    setInvoiceModal: (isShow) => ({
        type: actions.DOCUMENTS_SET_INVOICE_MODAL,
        isShow
    }),

    setOrderId: (orderId) => ({
        type: actions.DOCUMENTS_SET_ORDER_ID,
        orderId
    }),

    fetchTaxInvoiceDocumentRequest: (orderId, invoiceId) => ({
        type: actions.TAX_INVOICE_DOCUMENTS_FETCH_REQUEST,
        payload: { orderId, invoiceId }
    }),

    fecthReports: (strDate) => ({
        type: actions.REPORTS_FETCH_REQUEST,
        payload: { strDate }
    }),

    fetchInvoiceReports: (strDate) => ({
        type: actions.INVOICE_REPORTS_FETCH_REQUEST,
        payload: { strDate }
    }),

    saveInvoiceReport: ({ id, value }) => ({
        type: actions.INVOICE_REPORT_SAVE_REQUEST,
        payload: { id, value }
    }),

    fetchTruckReservationReports: (strDate) => ({
        type: actions.TRUCK_RESERVATION_REPORTS_FETCH_REQUEST,
        payload: { strDate }
    })
}

export default actions
