import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_REVIWES_FETCH,
    ORDER_REVIWES_FETCH_SUCCESS,
    ORDER_REVIEWS_SET_PAGINATION,
    ORDER_REVIEWS_SET_SALE,
    ORDER_REVIEWS_SET_CS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    filterSale: null,
    filterCs: null
})

export default function orderReviewsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case ORDER_REVIWES_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })
        case ORDER_REVIWES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case ORDER_REVIEWS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case ORDER_REVIEWS_SET_SALE:
            return state.merge({ filterSale: action.filterSale })
        case ORDER_REVIEWS_SET_CS:
            return state.merge({ filterCs: action.filterCs })
        default:
            return state
    }
}
