export const PAYMENTS_TYPE = [
    { value: 'cash', i18n: 'cash' },
    { value: 'credit_card', i18n: 'creditCard' }
]

export const CANCEL_PRICE_REASONS = [
    { value: 'item_price_too_high', i18n: 'ราคาสินค้าไม่ผ่าน' },
    { value: 'trucking_cost_too_high', i18n: 'ราคาค่าขนส่งไม่ผ่าน' }
]

export const CANCEL_ITEM_REASONS = [
    { value: 'out_of_stock', i18n: 'ไม่มีสินค้าคงคลัง' },
    { value: 'out_of_production', i18n: 'สินค้ายกเลิกการผลิต' },
    { value: 'production_time_too_long', i18n: 'ระยะเวลาผลิตนานเกินไป' },
    { value: 'minimum_order_not_met', i18n: 'ต้องมีผลิตขั้นต่ำ' },
    { value: 'cannot_find_item', i18n: 'ไม่มีสินค้าคงคลัง' }
]

export const CANCEL_PRICE_CHECKING_REASONS = [
    { value: 'cost_checking', i18n: 'ทำต้นทุน' },
    { value: 'boq', i18n: 'BOQ' }
]

export const CANCEL_OTHERS_REASONS = [
    { value: 'duplicate_order', i18n: 'รายการซ้ำ' },
    { value: 'missing_data', i18n: 'ข้อมูลไม่ครบ' },
    { value: 'bought_from_another_store', i18n: 'ซื้อจากที่อื่นแล้ว' },
    { value: 'quantity_too_low', i18n: 'จำนวนน้อยเกินไป' },
    { value: 'usage_enquiry', i18n: 'สอบถามข้อมูลการใช้งาน' },
    { value: 'lost_contact', i18n: 'ติดต่อไม่ได้' }
]

export const LEAD_SOURCES = [
    'web', 'line', 'phone', 'email', 'sales_contact', 'facebook', 'web_cs', 'out_going_call'
]

export const SHIPPING_TIMES = [
    'before_8',
    'time_8_to_10',
    'time_10_to_12',
    'time_13_to_15',
    'time_15_to_17',
    'after_17',
    'no_preference'
]

export const PAGE_SIZE = 20
export const DEFAULT_CURRENT_PAGE = 1
