import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

import { apiFetch } from '../../helpers/restRequest'
import { brandsSchema, brandSchema } from '../schema'
import actions from './actions'

const {
    BRANDS_SEARCH_REQUEST,
    BRANDS_SET_QUERY,
    BRANDS_FETCH,
    BRANDS_SET_PAGINATION,
    BRANDS_FETCH_SUCCESS,
    BRAND_FETCH_REQUEST,
    BRAND_FETCHING,
    BRAND_FETCH_SUCCESS
} = actions

export function *searchBrandsRequest () {
    yield takeEvery(BRANDS_SEARCH_REQUEST, function *({ payload }) {
        const { query, page, per } = payload
        const queryString = `query=${query}&page=${page}&per=${per}`

        yield put({ type: BRANDS_SET_QUERY, query })
        yield put({ type: BRANDS_FETCH })

        const path = `/brands`
        const data = yield call(apiFetch, `${path}?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, brandsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: BRANDS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: BRANDS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchBrand () {
    yield takeLatest(BRAND_FETCH_REQUEST, function *({ payload }) {
        const { id, onSuccess, onError } = payload

        yield put({ type: BRAND_FETCHING })

        try {
            const data = yield call(apiFetch, `/brands/${id}`)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, brandSchema)

            yield put({
                type: BRAND_FETCH_SUCCESS,
                ...normalizeData
            })

            onSuccess()
        } catch (error) {
            onError()
        }
    })
}


export default function *rootSaga () {
    yield all([
        fork(searchBrandsRequest),
        fork(fetchBrand)
    ])
}
