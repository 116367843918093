import styled from 'styled-components'

const InquiryCardWrapper = styled.div`

  .ant-card-body {
    padding: 12px;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .title { font-weight: bold; }
  }

  .margin-bottom-8 {
    margin-bottom: 8px;
  }
`

export default InquiryCardWrapper
