import _ from 'lodash'
import { Map } from 'immutable'
const actions = {
    BANK_STATEMENTS_FETCH_FAIL: 'BANK_STATEMENTS_FETCH_FAIL',
    BANK_STATEMENTS_FETCH_REQUEST: 'BANK_STATEMENTS_FETCH_REQUEST',
    BANK_STATEMENTS_FETCH_SUCCESS: 'BANK_STATEMENTS_FETCH_SUCCESS',
    BANK_STATEMENTS_FETCHING: 'BANK_STATEMENTS_FETCHING',

    BANK_STATEMENTS_SET_IMPORT_VISIBLE_MODAL: 'BANK_STATEMENTS_SET_IMPORT_VISIBLE_MODAL',
    BANK_STATEMENTS_SET_IMPORT_ADDITIONAL_UPDATE_MODAL: 'BANK_STATEMENTS_SET_IMPORT_ADDITIONAL_UPDATE_MODAL',
    BANK_STATEMENTS_SET_IMPORT_SUPPLIER_INVOICES_VISIBLE_MODAL: 'BANK_STATEMENTS_SET_IMPORT_SUPPLIER_INVOICES_VISIBLE_MODAL',

    BANK_STATEMENTS_UPLOAD_FAIL: 'BANK_STATEMENTS_UPLOAD_FAIL',
    BANK_STATEMENTS_UPLOAD_REQUEST: 'BANK_STATEMENTS_UPLOAD_REQUEST',
    BANK_STATEMENTS_UPLOAD_SUCCESS: 'BANK_STATEMENTS_UPLOAD_SUCCESS',
    BANK_STATEMENTS_UPLOADING: 'BANK_STATEMENTS_UPLOADING',

    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL: 'BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL',
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_REQUEST: 'BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_REQUEST',
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS: 'BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS',
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING: 'BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING',

    BANK_STATEMENTS_EDIT_ORDER_MODAL: 'BANK_STATEMENTS_EDIT_ORDER_MODAL',
    BANK_STATEMENTS_CURRENT_MODAL_RECORD_TYPE: 'BANK_STATEMENTS_CURRENT_MODAL_RECORD_TYPE',
    BANK_STATEMENTS_CURRENT_CHANGING_ORDER: 'BANK_STATEMENTS_CURRENT_CHANGING_ORDER',
    BANK_STATEMENTS_SAVE_REQUEST: 'BANK_STATEMENTS_SAVE_REQUEST',
    BANK_STATEMENTS_SAVING: 'BANK_STATEMENTS_SAVING',
    BANK_STATEMENTS_SAVE_SUCCESS: 'BANK_STATEMENTS_SAVE_SUCCESS',
    BANK_STATEMENTS_SAVE_FAILED: 'BANK_STATEMENTS_SAVE_FAILED',
    BANK_STATEMENT_CHECK_TRANSACTION_TYPE: 'BANK_STATEMENT_CHECK_TRANSACTION_TYPE',
    BANK_STATEMENT_SET_ID: 'BANK_STATEMENT_SET_ID',
    BANK_STATEMENTS_SET_EXPORT_VISIBLE_MODAL: 'BANK_STATEMENTS_SET_EXPORT_VISIBLE_MODAL',
    BANK_STATEMENTS_EXPORT_REQUEST: 'BANK_STATEMENTS_EXPORT_REQUEST',
    BANK_STATEMENTS_EXPORT_SUCCESS: 'BANK_STATEMENTS_EXPORT_SUCCESS',
    BANK_STATEMENTS_EXPORT_FAIL: 'BANK_STATEMENTS_EXPORT_FAIL',
    BANK_STATEMENTS_EDIT_RV_PV_MODAL: 'BANK_STATEMENTS_EDIT_RV_PV_MODAL',
    BANK_STATEMENTS_EDIT_JOURNAL_ENTRY_MODAL: 'BANK_STATEMENTS_EDIT_JOURNAL_ENTRY_MODAL',
    ORDER_PAYMENT_DETAIL_QUERY_FETCH_REQUEST: 'ORDER_PAYMENT_DETAIL_QUERY_FETCH_REQUEST',
    ORDER_PAYMENT_DETAIL_QUERY_LOADING: 'ORDER_PAYMENT_DETAIL_QUERY_LOADING',
    ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS: 'ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS',
    PAYMENT_VOUCHER_QUERY_FETCH_REQUEST: 'PAYMENT_VOUCHER_QUERY_FETCH_REQUEST',
    PAYMENT_VOUCHER_QUERY_LOADING: 'PAYMENT_VOUCHER_QUERY_LOADING',
    PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS: 'PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS',
    SET_DEFAULT_SELECT: 'SET_DEFAULT_SELECT',
    HIDE_FORM_AFTER_SELECT_ADDITIONAL_UPDATE: 'HIDE_FORM_AFTER_SELECT_ADDITIONAL_UPDATE',

    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_REQUEST: 'BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_REQUEST',
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_UPLOADING: 'BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_UPLOADING',
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_SUCCESS: 'BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_SUCCESS',
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_FAIL: 'BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_FAIL',
    BANK_STATEMENTS_SET_EXPORT_WITH_SUPPLIER_INVOICE_VISIBLE_MODAL: 'BANK_STATEMENTS_SET_EXPORT_WITH_SUPPLIER_INVOICE_VISIBLE_MODAL',

    BANK_STATEMENT_SUMMARIES_FETCH_REQUEST: 'BANK_STATEMENT_SUMMARIES_FETCH_REQUEST',
    BANK_STATEMENT_SUMMARIES_FETCH: 'BANK_STATEMENT_SUMMARIES_FETCH',
    BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS: 'BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS',
    BANK_STATEMENT_SUMMARIES_SET_PAGINATION: 'BANK_STATEMENT_SUMMARIES_SET_PAGINATION',

    BANK_STATEMENT_SUMMARY_FETCH_REQUEST: 'BANK_STATEMENT_SUMMARY_FETCH_REQUEST',
    BANK_STATEMENT_SUMMARY_FETCH: 'BANK_STATEMENT_SUMMARY_FETCH',
    BANK_STATEMENT_SUMMARY_FETCH_SUCCESS: 'BANK_STATEMENT_SUMMARY_FETCH_SUCCESS',

    BANK_STATEMENT_CURRENT_TRUCK_OWNER_ID: 'BANK_STATEMENT_CURRENT_TRUCK_OWNER_ID',
    BANK_STATEMENTS_EDIT_TRUCK_OWNER_MODAL: 'BANK_STATEMENTS_EDIT_TRUCK_OWNER_MODAL',

    // when you want to add a new filter
    // you can add it here single place
    BANK_STATEMENTS_FETCH_DEFAULT_FILTERS: {
        page: 1,
        per: 300,
        bankAccountId: undefined,
        hasOrder: undefined,
        startDate: undefined,
        endDate: undefined,
        isReconciled: undefined,
        order: 'date desc',
        bankStatementType: undefined
    },

    fetchBankStatements: ({
        filters = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_FETCH_REQUEST,
            payload: {
                filters: _.merge(actions.BANK_STATEMENTS_FETCH_DEFAULT_FILTERS, filters),
                onSuccess,
                onError
            }
        }
    },

    setImportVisibleModal: ({ visibleModal = false } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SET_IMPORT_VISIBLE_MODAL,
            visibleModal
        }
    },


    setImportAdditionalUpdateVisibleModal: ({ visibleModal = false } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SET_IMPORT_ADDITIONAL_UPDATE_MODAL,
            visibleModal
        }
    },

    setImportSupplierInvoicesVisibleModal: ({ visibleModal = false } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SET_IMPORT_SUPPLIER_INVOICES_VISIBLE_MODAL,
            visibleModal
        }
    },

    setExportVisibleModal: ({ visibleModal = false } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SET_EXPORT_VISIBLE_MODAL,
            visibleModal
        }
    },

    setExportWithSupplierInvoiceVisibleModal: ({ withSupplierInvoiceVisibleModal = false } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SET_EXPORT_WITH_SUPPLIER_INVOICE_VISIBLE_MODAL,
            withSupplierInvoiceVisibleModal
        }
    },

    setBankStatementEditOrderModal: (value) => ({
        type: actions.BANK_STATEMENTS_EDIT_ORDER_MODAL,
        value
    }),

    setBankStatementEditRvPvModal: (value) => ({
        type: actions.BANK_STATEMENTS_EDIT_RV_PV_MODAL,
        value
    }),

    setBankStatementEditJournalEntryModal: (value) => ({
        type: actions.BANK_STATEMENTS_EDIT_JOURNAL_ENTRY_MODAL,
        value
    }),

    setCurrentModalRecordType: (value) => ({
        type: actions.BANK_STATEMENTS_CURRENT_MODAL_RECORD_TYPE,
        value
    }),

    currentChangingOrder: (value) => ({
        type: actions.BANK_STATEMENTS_CURRENT_CHANGING_ORDER,
        value
    }),

    checkTransactionType: (value) => ({
        type: actions.BANK_STATEMENT_CHECK_TRANSACTION_TYPE,
        value
    }),

    setCurrentBankStatementId: (value) => ({
        type: actions.BANK_STATEMENT_SET_ID,
        value
    }),

    saveBankStatement: ({
        params = {},
        onSuccess = () => {},
        onShowModal = () => {}
    } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_SAVE_REQUEST,
            payload: {
                params,
                onSuccess,
                onShowModal
            }
        }
    },

    uploadBankStatements: ({
        // params example
        // { bank: 'scb', file: File() }
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_UPLOAD_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    uploadBankStatementSupplierInvoices: ({
        // params example
        // { bank: 'scb', file: File() }
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    exportBankStatements: ({
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_EXPORT_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    exportBankStatementWithSupplierInvoices: ({
                               params = {},
                               onSuccess = () => {},
                               onError = () => {}
                           } = {}) => {
        return {
            type: actions.BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    fetchOrderPaymentDetailThroughQuery: (query) => ({
        type: actions.ORDER_PAYMENT_DETAIL_QUERY_FETCH_REQUEST,
        query
    }),

    fetchPaymentVoucherThroughQuery: (query) => ({
        type: actions.PAYMENT_VOUCHER_QUERY_FETCH_REQUEST,
        query
    }),

    setDefaultSelect: (defaultSelect) => ({
        type: actions.SET_DEFAULT_SELECT,
        defaultSelect
    }),

    onHideBankSelectForm: (value) => ({
        type: actions.HIDE_FORM_AFTER_SELECT_ADDITIONAL_UPDATE,
        value
    }),

    fetchBankStatementSummaries: (page = 1) => {
        return {
            type: actions.BANK_STATEMENT_SUMMARIES_FETCH_REQUEST,
            payload: { page }
        }
    },

    fetchBankStatementSummary: (month = '') => ({
        type: actions.BANK_STATEMENT_SUMMARY_FETCH_REQUEST,
        payload: { month }
    }),


    setBankStatementEditTruckOwnerModal: (value) => ({
        type: actions.BANK_STATEMENTS_EDIT_TRUCK_OWNER_MODAL,
        value
    }),

    setCurrentTruckOwnerId: (value) => ({
        type: actions.BANK_STATEMENT_CURRENT_TRUCK_OWNER_ID,
        value
    })
}

export default actions
