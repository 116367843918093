import React, { Component } from 'react'
import { Spin } from 'antd'

import CurrentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

class TopbarUserName extends Component {
    render () {
        const { currentStaff, currentStaffLoading } = this.props

        if (currentStaffLoading) { return <Spin size="small" /> }

        const firstName = currentStaff.get('first_name') || ''
        const lastName = currentStaff.get('last_name') || ''

        return (
            <div>
                {firstName} {lastName}
            </div>
        )
    }
}

export default CurrentStaffContainer(TopbarUserName)
