import { List, Map } from 'immutable'
import actions, { getView } from './actions'

const preKeys = new List() //getDefaultPath();
const initState = new Map({
    collapsed: false,
    view: getView(0),
    height: 0,
    openDrawer: false,
    openKeys: preKeys,
    current: preKeys
})

export default function appReducer (state = initState, action) {
    switch (action.type) {
        case actions.COLLAPSE_CHANGE:
            return state.set('collapsed', !state.get('collapsed'))
        case actions.COLLAPSE_OPEN_DRAWER:
            return state.set('openDrawer', !state.get('openDrawer'))
        case actions.TOGGLE_ALL:
            if (state.get('view') !== action.view || action.height !== state.height) {
                const height = action.height ? action.height : state.height
                return state
                    .set('collapsed', action.collapsed)
                    .set('view', action.view)
                    .set('height', height)
            }
            break
        case actions.CHANGE_OPEN_KEYS:
            return state.set('openKeys', new List(action.openKeys))
        case actions.CHANGE_CURRENT:
            return state.set('current', new List(action.current))
        default:
            return state
    }
}
