import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import ReissueInvoiceForm from '../../../components/Invoices/reissueInvoice/form'
import { camelize } from '../../../helpers/string'

const DEFAULT_INVOICE_QUERY = gql`
  query DefaultInvoice($invoiceId: ID!) {
    orderBusinessAddress(invoiceId: $invoiceId) {
      id
      taxNumber
      companyName
      businessType
      branch
      addressDetail
      hideDetailInformation
      subDistrict
      postalCode
      province {
        id
      }
      district {
        id
      }
    }
  }
`

const REISSUE_INVOICE_MUTATION = gql`
  mutation IssueNewInvoice($invoiceId: ID!, $orderBusinessAddressParams: AdminOrderBusinessAddressInput!) {
    issueNewInvoice(invoiceId: $invoiceId, orderBusinessAddressParams: $orderBusinessAddressParams) {
      errors
    }
  }
`

const convertObjectKeyToCamelCase = object => {
  const result = {}
  for (const property in object) {
    let value = object[property]
    const valueType = typeof value

    if (!value) {
      value = null
    } else if (valueType === "object") {
      value = convertObjectKeyToCamelCase(value)
    } else if (valueType === "array") {
      value = value.map(v => convertObjectKeyToCamelCase(v))
    }

    result[camelize(property)] = value
  }

  return result
}

const ReissueInvoiceFormContainer = ({
  customerNo,
  invoiceId,
  onClose,
  onSuccess
}) => {
  const [errors, setErrors] = useState({})
  const { loading, data, error } = useQuery(DEFAULT_INVOICE_QUERY, { variables: { invoiceId } })
  const [reissueInvoice, { loading: saving }] = useMutation(REISSUE_INVOICE_MUTATION, {
    onCompleted: ({ issueNewInvoice }) => {
      if (issueNewInvoice.errors) {
        setErrors(JSON.parse(issueNewInvoice.errors))
      } else {
        onSuccess()
        onClose()
      }
    }
  })

  const handleSubmitForm = params => {
    params['previous_re_invoice_attributes']['previous_invoice_id'] = invoiceId
    const orderBusinessAddressParams = convertObjectKeyToCamelCase(params)

    reissueInvoice({ variables: { invoiceId, orderBusinessAddressParams } })
  }

  if (error) { console.error(error) }

  const businessAddress = (data && data.orderBusinessAddress) || {}

  return (
    <ReissueInvoiceForm
      businessAddress={businessAddress}
      customerNo={customerNo}
      errors={errors}
      loading={loading}
      onClose={onClose}
      onSubmitForm={handleSubmitForm}
      saving={saving}
    />
  )
}

ReissueInvoiceFormContainer.propTypes = {
  customerNo: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

ReissueInvoiceFormContainer.defaultProps = {
  onSuccess: () => {}
}

export default ReissueInvoiceFormContainer
