import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Form, DatePicker, Button, Card, Select } from 'antd'
import accountingActions from '../../redux/orders/accounting/actions'

const { fetchVatDocumentFile } = accountingActions
const { RangePicker, MonthPicker } = DatePicker
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

class ExportVatDocuments extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dateType: 'issue_date'
        }

        this.formRef = createRef()
    }

    handleDateTypeChange = (dateType) => {
        this.setState({ dateType })
    }

    handleSubmit = (e) => {
        const { fetchVatDocumentFile } = this.props
        e.preventDefault()

        this.formRef.current.validateFields().then(fieldValue => {
            let startDate = null
            let endDate = null
            if (fieldValue['range_picker']) {
                startDate = fieldValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldValue['range_picker'][1].format('YYYY-MM-DD')
            }

            if (fieldValue['month']) {
                startDate = fieldValue['month'].format('YYYY-MM')
            }

            const values = {
                date_type: this.state.dateType,
                start_date: startDate,
                end_date: endDate
            }

            fetchVatDocumentFile(values)
        })
    }

    renderIssueDateForm () {
        const { i18n } = this.props
        return (
            <Form.Item name='range_picker' noStyle
                rules={[{
                    required: true,
                    message: i18n.t('confirms:invoice.pleaseInputDate')
                }]}>
                <RangePicker allowClear />
            </Form.Item>
        )
    }

    renderApplyDateForm () {
        const { i18n } = this.props
        return (
            <Form.Item name='month' noStyle
                rules={[{
                    required: true,
                    message: i18n.t('confirms:invoice.pleaseInputDate')
                }]}>
                <MonthPicker allowClear format={'MMMM YYYY'} />
            </Form.Item>
        )
    }

    render () {
        const { orderPaymentDetails, i18n } = this.props
        const { dateType } = this.state
        const loading = orderPaymentDetails.get('vatDocumentsLoading')

        return (
            <Card
                title={i18n.t('confirms:vatDocument.searchVatDocuments')}
                style={{ margin: '20px 0' }}>
                <Form layout="inline" labelAlign="left" hideRequiredMark
                    ref={this.formRef}>
                    <Form.Item label={
                        <Select style={{ width: '180px' }}
                            onChange={(value) => this.handleDateTypeChange(value)}
                            value={dateType}>
                            <Select.Option value="issue_date">
                                {i18n.t('confirms:vatDocument.searchByIssueDate')}
                            </Select.Option>
                            <Select.Option value="apply_date">
                                {i18n.t('confirms:vatDocument.searchByApplyDate')}
                            </Select.Option>
                        </Select>
                    }>
                        {dateType === 'issue_date' ? this.renderIssueDateForm() : this.renderApplyDateForm()}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('confirms:vatDocument.downloadVatDocuments')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    orderPaymentDetails: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchVatDocumentFile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('confirms')(ExportVatDocuments))
