import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { gql, useQuery } from '@apollo/client'
import { Form, Select, InputNumber } from 'antd'

const GB_PRIME_OPTIONS = gql`
  query InstallmentOptions {
    gbpOptions {
      id
      bankName
      bankShortName
      installmentOptions {
        id
        installmentPeriod
        interestRate
      }
    }
  }
`

const paymentOptionsList = (options) => {
  return options.map((option) => {
    return (
      <Select.Option
        key={option.id}
        value={option.id}>
          [{option.bankShortName}] {option.bankName}
      </Select.Option>
    )
  })
}

const installmentOptionsList = (i18n, selectedBank, gbpOptions) => {
  if (!selectedBank) {
    return null
  }

  const currentOption = gbpOptions.find(option => option.id === selectedBank)

  return (currentOption ? currentOption.installmentOptions : []).map((option) => {
      return (
        <Select.Option
          key={option.id}
          value={option.installmentPeriod}>
          {[
            option.installmentPeriod,
            i18n.t('order.months'),
            `(${i18n.t('order.interestRate')}`,
            `${option.interestRate}%)`
          ].join(' ')}
        </Select.Option>
      )
    })
}

const InstallmentForm = ({
  onChangeBank,
  selectedBank,
  orderEntity
}) => {
  const { i18n } = useTranslation()

  const { loading, data } = useQuery(GB_PRIME_OPTIONS)

  const gbpOptions = data ? data.gbpOptions : []
  return (
    <div>
      <Form.Item
        name='credit_card_bank'
        initialValue={
          orderEntity.getIn(['credit_card_bank', 'id']) || undefined
        }>
        <Select
          allowClear
          loading={loading}
          placeholder={i18n.t('order.selectBank')}
          onChange={onChangeBank}
          >
          {paymentOptionsList(gbpOptions)}
        </Select>
      </Form.Item>
      <Form.Item
        name='installment_period'
        initialValue={orderEntity.get('installment_period')}
        >
        <Select
          allowClear
          placeholder={i18n.t('order.installmentPeriod')}>
          {installmentOptionsList(i18n, selectedBank, gbpOptions)}
        </Select>
      </Form.Item>

      <label className='title'>
            {' '}
        {i18n.t('order.creditCardRate')} 2.6% - 4%
      </label>
      <Form.Item
        name='credit_card_rate'
        initialValue={orderEntity.get('credit_card_rate')}
        >
        <InputNumber
          min={2.6}
          max={4.0}
          step={0.1}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </div>
  )
}

InstallmentForm.propTypes = {}

InstallmentForm.defaultProps = {}

export default InstallmentForm
