import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import BankStatementsTable from '../../components/BankStatements/Table'

import actions from '../../redux/bankStatements/actions'
import supplierOrderActions from '../../redux/supplierOrders/actions'
import notificationActions from '../../redux/notifications/actions'
const {
    setNotifications
} = notificationActions

const {
    setBankStatementEditOrderModal,
    setCurrentModalRecordType,
    currentChangingOrder,
    setCurrentBankStatementId,
    saveBankStatement,
    checkTransactionType,
    setBankStatementEditJournalEntryModal,
    setBankStatementEditRvPvModal,
    setDefaultSelect,
    setCurrentTruckOwnerId,
    setBankStatementEditTruckOwnerModal
} = actions

const {
    fetchSupplierOrderThroughQuery
} = supplierOrderActions


class BankStatementsTableContainer extends Component {
    static propTypes = {
        // Props from another component
        pagination: PropTypes.object,
        onChange: PropTypes.func,
        setBankStatementEditOrderModal: PropTypes.func,

        // Inner Props
        bankAccountEntities: ImmutablePropTypes.map.isRequired,
        bankStatementEntities: ImmutablePropTypes.map.isRequired,
        bankStatements: ImmutablePropTypes.map.isRequired
    }

    constructor () {
        super()

        this.state = {
            truckOwnerId: null
        }
    }

    static defaultProps = {
        pagination: {
            current: 1,
            pageSize: 20,
            total: 0
        },
        onChange: () => {}
    }


    onChangeTruckOwnerId = (truckOwnerId) => {
        this.setState({ truckOwnerId: truckOwnerId.truckOwnerId })
    }

    onShowTruckOwnerModal = (value, bankStatementId, truckOwnerId) => {
        const { setBankStatementEditTruckOwnerModal, setCurrentTruckOwnerId, setCurrentBankStatementId } = this.props
        setCurrentBankStatementId(bankStatementId)

        setBankStatementEditTruckOwnerModal(value)
        setCurrentTruckOwnerId(truckOwnerId)
    }

    getBankAccount (id) {
        if (!id) return new Map()
        const { bankAccountEntities } = this.props

        return bankAccountEntities.get(_.toString(id), new Map())
    }

    getBankStatement (id) {
        if (!id) return new Map()
        const { bankStatementEntities } = this.props
        const bankStatement = bankStatementEntities.get(_.toString(id), new Map())

        return bankStatement.merge({
            bank_account: this.getBankAccount(bankStatement.get('bank_account'))
        })
    }

    getBankStatements () {
        const { bankStatements } = this.props

        return bankStatements.get('items', new List()).map((id) => {
            return this.getBankStatement(id)
        })
    }

    onShowModal = (value, bankStatementId, recordId, isCreditRecord, type) => {
        const { setBankStatementEditOrderModal, setCurrentModalRecordType, currentChangingOrder, checkTransactionType, setCurrentBankStatementId } = this.props
        const transactionType = isCreditRecord !== 0 ? "credit" : "debit"

        setBankStatementEditOrderModal(value)
        setCurrentModalRecordType(type)
        currentChangingOrder(recordId)
        setCurrentBankStatementId(bankStatementId)
        checkTransactionType(transactionType)
    }

    onShowRvPvModal = (value, bankStatementId, recordId, isCreditRecord, type, onShowRvPvModal) => {
        const { setBankStatementEditRvPvModal, setCurrentModalRecordType, currentChangingOrder, checkTransactionType, setCurrentBankStatementId, setDefaultSelect } = this.props
        const transactionType = isCreditRecord !== 0 ? "credit" : "debit"

        setBankStatementEditRvPvModal(value)
        setCurrentModalRecordType(type)
        currentChangingOrder(recordId)
        setCurrentBankStatementId(bankStatementId)
        checkTransactionType(transactionType)
        setDefaultSelect(onShowRvPvModal)
    }

    onShowJournalEntryModal = (visible, bankStatementId) => {
        const {  setBankStatementEditJournalEntryModal, setCurrentBankStatementId } = this.props

        setBankStatementEditJournalEntryModal(visible)
        setCurrentBankStatementId(bankStatementId)
    }


    onSubmitBankStatement = (params) => {
        const { saveBankStatement, setNotifications } = this.props

        saveBankStatement({ params: params,
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
            },
            onShowModal: () => {
                this.onShowModal()
                this.onShowRvPvModal(false, null, null, null, null, [])
                this.onShowJournalEntryModal(false, null)
                this.onShowTruckOwnerModal(false)
            }
        })
    }

    render () {
        const { pagination, onChange, bankStatements, bankStatementEntities } = this.props
        const currentBankStatementId= bankStatements.get('currentBankStatementId')

        return (
            <BankStatementsTable
                bankStatements={this.getBankStatements().toJS()}
                onShowModal={this.onShowModal}
                onShowRvPvModal={this.onShowRvPvModal}
                onShowJournalEntryModal={this.onShowJournalEntryModal}
                isShowModal={bankStatements.get('editOrderModal')}
                isShowRvPvModal={bankStatements.get('editRvPvModal')}
                isShowJournalEntryModal={bankStatements.get('editJournalEntryModal')}
                modalRecordType={bankStatements.get('modalRecordType')}
                changingOrder={bankStatements.get('changingOrder')}
                transactionType={bankStatements.get('transactionType')}
                currentBankStatementId={currentBankStatementId}
                defaultSelect={bankStatements.get('defaultSelect')}
                loading={bankStatements.get('loading', false)}
                onSubmitBankStatement={this.onSubmitBankStatement}
                onChange={onChange}
                onChangeOrder={this.onChangeOrder}
                onChangeSupplierOrder={this.onChangeSupplierOrder}
                pagination={pagination}
                defaultJournalEntry={bankStatementEntities.getIn([currentBankStatementId, 'journal_entry_format_name'])}
                currentTruckOwnerId={bankStatements.get('currentTruckOwnerId')}
                onChangeTruckOwnerId={this.onChangeTruckOwnerId}
                truckOwnerId={this.state.truckOwnerId}
                isShowTruckOwnerModal={bankStatements.get('editTruckOwnerModal')}
                onShowTruckOwnerModal={this.onShowTruckOwnerModal}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountEntities: state.getIn(['Entities', 'bankAccounts']),
        bankStatementEntities: state.getIn(['Entities', 'bankStatements']),
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setBankStatementEditOrderModal,
        setCurrentModalRecordType,
        currentChangingOrder,
        setCurrentBankStatementId,
        saveBankStatement,
        checkTransactionType,
        fetchSupplierOrderThroughQuery,
        setNotifications,
        setBankStatementEditRvPvModal,
        setDefaultSelect,
        setBankStatementEditJournalEntryModal,
        setBankStatementEditTruckOwnerModal,
        setCurrentTruckOwnerId
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementsTableContainer)
