import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const UploadInvoiceTrackingImage = ({
    customRequest,
    i18n
}) => {
    return (
        <Upload.Dragger
            accept="image/*"
            customRequest={customRequest}
            showUploadList={{
                showRemoveIcon: false
            }}
        >
            <InboxOutlined />
            <p>
                {i18n.t('confirms:invoice.uploadEmsTracking')}
            </p>
        </Upload.Dragger>
    )
}

UploadInvoiceTrackingImage.propTypes = {
    customRequest: PropTypes.func
}

UploadInvoiceTrackingImage.defaultProps = {
    customRequest: () => {}
}

export default withTranslation('confirms')(UploadInvoiceTrackingImage)
