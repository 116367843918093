import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../helpers/restRequest'

import actions from './actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { shippingRatesSchema } from '../schema'

const {
    SHIPPING_RATES_FETCH_REQUEST,
    SHIPPING_RATES_FETCHING,
    SHIPPING_RATES_FETCH_SUCCESS,
    SHIPPING_RATES_SET_FILTERS,
    SHIPPING_RATES_SET_PAGINATION,
} = actions

const shippingRatesPath = (id) => {
    const basePath = '/shipping_rates'

    if (!id) { return basePath }

    return `${basePath}/${id}`
}

export function *receivedShippingRatesRequest () {
    yield takeEvery(SHIPPING_RATES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SHIPPING_RATES_FETCHING })
        const { province, district, page, perPage } = payload
        const queryString = `province_id=${province}&district_id=${district}&page=${page}&per_page=${perPage}`

        const data = yield call(apiFetch, shippingRatesPath() + '?' + queryString)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, shippingRatesSchema)
        const total = data.meta.total_pages * perPage

        yield put({
            type: SHIPPING_RATES_SET_FILTERS,
            filters: { province, district }
        })

        yield put({
            type: SHIPPING_RATES_SET_PAGINATION,
            pagination: { current: page, pageSize: perPage, total }
        })

        yield put({
            type: SHIPPING_RATES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(receivedShippingRatesRequest)
    ])
}
