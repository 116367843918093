import styled from 'styled-components'
const VatDocumentsFilterWrapper = styled.div`
  .ant-layout {
    background: white;
  }

  .ant-layout-sider {
    background: white;
  }

  .ant-layout-content {
    margin-bottom: 10px;

    .ant-btn:not(:last-child) {
      margin-right: 10px;
    }
  }

  .ant-form-item {
    display: inline-flex;
    margin: 0;
  }

  .btn-center {
    text-align: center
  }

  .text-right {
    text-align: right
  }

  .header {
    margin: 5px;
  }  
`
export default VatDocumentsFilterWrapper