import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import ProjectContactsList from '../../components/projects/projectContactsList'

import actions from '../../redux/projects/contacts/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    fetchProjectContacts,
    setProjectContactFormModal,
    deleteProjectContact
} = actions
const { setNotifications } = notificationActions

class ProjectContactsListContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchProjectContacts, projectId } = this.props

        fetchProjectContacts({ projectId })
    }

    getContacts = () => {
        const { projectContacts, projectContactEntities } = this.props

        return projectContacts.get('items', new List()).map((projectContactId) => {
            return projectContactEntities.get(projectContactId) || new Map()
        })
    }

    handleDeleteContact = (id) => {
        const { deleteProjectContact, projectId } = this.props

        deleteProjectContact({
            projectId,
            id,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
            }
        })
    }

    handlePageChange = (pagination) => {
        const { fetchProjectContacts, projectId } = this.props

        fetchProjectContacts({ projectId, page: pagination.current })
    }

    toggleProjectContactForm = ({ id = null } = {}) => {
        const { projectContacts, setProjectContactFormModal } = this.props

        setProjectContactFormModal(!projectContacts.get('showContactForm'), id)
    }

    render () {
        const { projectContacts, projectId } = this.props

        return (
            <ProjectContactsList contacts={this.getContacts().toJS()}
                projectId={projectId}
                activeContact={projectContacts.get('contactIdSelected')}
                loading={projectContacts.get('loading')}
                toggleProjectContactForm={this.toggleProjectContactForm}
                showContactForm={projectContacts.get('showContactForm')}
                deleteProjectContact={this.handleDeleteContact}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...projectContacts.get('pagination').toJS(),
                    showSizeChanger: false
                }} />
        )
    }
}

const mapStateToProps = (state) => ({
    projectContacts: state.get('projectContacts'),
    projectContactEntities: state.getIn(['Entities', 'projectContacts'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProjectContacts,
        setProjectContactFormModal,
        deleteProjectContact,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContactsListContainer)
