import { Map, List } from 'immutable'

import actions from './actions'

const {
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH,
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS,
    JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION,
    JOURNAL_ENTRY_FORMAT_ITEM_SAVING,
    JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS,
    JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS,
    JOURNAL_ENTRY_FORMAT_ITEMS_SET_FORM_MODAL,
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE,
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    }),
    formatItemSelected: null,
    showFormatItemForm: false,
    errors: new Map()
})

export default function journalEntryFormatReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case JOURNAL_ENTRY_FORMAT_ITEMS_FETCH:
            return state.merge({ loading: true })
        case JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case JOURNAL_ENTRY_FORMAT_ITEMS_SET_FORM_MODAL:
            return state.merge({
                showFormatItemForm: action.visible,
                formatItemSelected: action.selected
            })
        case JOURNAL_ENTRY_FORMAT_ITEM_DELETE:
            return state.merge({ loading: true })
        case JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.delete(items.indexOf(result)))
            })
        case JOURNAL_ENTRY_FORMAT_ITEM_SAVING:
            return state.merge({ saving: action.saving })
        case JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        case JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS:
            return state.merge({
                items: new List(items.includes(result) ? items : items.push(result))
            })
        default:
            return state
    }
}
