import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import SelectOrder from '../../components/selectOrder'

import orderActions from '../../redux/orders/actions'

const { fetchOrder, fetchOrders } = orderActions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectOrderContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner props
        fetchOrder: PropTypes.func.isRequired,
        fetchOrders: PropTypes.func.isRequired,
        orderEntities: ImmutablePropTypes.map.isRequired,
        orders: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { value, fetchOrder } = this.props

        if (!value || value === VALUE_FROM_STATE) return null
        fetchOrder(value)
    }

    componentDidUpdate (prevProps) {
        const { value: prevValue } = prevProps
        const { value: currValue, fetchOrder } = this.props

        if (prevValue === currValue || currValue === VALUE_FROM_STATE) return null

        fetchOrder(currValue)
    }

    getOrders () {
        const { orderEntities, orders } = this.props

        return orders.get('items', new List()).map((id) => {
            return orderEntities.get(_.toString(id), new Map())
        })
    }

    onChange = (orderId) => {
        const { onChange } = this.props

        onChange(orderId)
        this.setState({ value: orderId })
    }

    onSearch = (query) => {
        const { fetchOrders } = this.props
        fetchOrders('all', `page=1&per=5&query=${query}`)
    }

    render () {
        const {
            onChange,
            orders,
            value: propValue,
            ...restProps
        } = this.props

        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue
        return (
            <SelectOrder
                loading={orders.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                orders={this.getOrders().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderEntities: state.getIn(['Entities', 'orders']),
        orders: state.get('orders')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrder,
        fetchOrders
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOrderContainer)
