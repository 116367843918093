import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Divider, Typography } from 'antd'

import accountingAction from '../../../redux/orders/accounting/actions'
import supplierInvoiceActions from '../../../redux/supplierInvoices/actions'
import journalEntryActions from '../../../redux/journalEntries/actions'

import ImportPaymentVoucher from '../../../containers/Orders/ImportPaymentVoucher'
import ImportReceiptVoucher from '../../../containers/Orders/ImportReceiptVoucher'
import ReceiptVoucherExport from '../../../containers/Orders/ReceiptVoucherExport'
import ExportYieldReport from '../../../containers/Orders/ExportYieldReport'
import ExportAdjustedPvRv from '../../../containers/Orders/ExportAdjustedPvRv'
import ExportRv from '../../../containers/Orders/ExportRv'
import ExportSalesReport from '../../../containers/Sales/ExportSalesReport'
import ExportTransferBackReport from '../../../containers/PaymentVouchers/ExportTransferBackReport'
import ExportSaleTaxInvoiceReport from '../ExportSaleTaxInvoiceReport'
import ExportScbPaymentConfirmReport from '../ExportScbPaymentConfirmReport'
import ImportReport from './ImportReport'
import ExportReport from './ExportReport'
import ExportSupplierOrdersReport from '../ExportSupplierOrdersReport'
import ExportSupplierLineItems from '../../SupplierOrders/ExportSupplierLineItems'
import ExportInvoices1688 from '../../Invoices/ExportInvoices1688'

const {
    fetchInvoiceDeliveryAddressPdf,
    fetchTaxInvoiceReportFile,
    importInvoiceEmsTrackingFile,
    importTaxInvoiceFile,
    uploadProductBankReconcile,
    uploadTruckBankReconcile
} = accountingAction

const { exportSupplierInvoices, importSupplierInvoices } = supplierInvoiceActions
const { exportJounalEntryStatements } = journalEntryActions

class UploadAndDownloadReport extends Component {
    static propTypes = {
        accountings: Immutable.map.isRequired,
        exportSupplierInvoices: PropTypes.func.isRequired,
        fetchInvoiceDeliveryAddressPdf: PropTypes.func.isRequired,
        fetchTaxInvoiceReportFile: PropTypes.func.isRequired,
        importInvoiceEmsTrackingFile: PropTypes.func.isRequired,
        importSupplierInvoices: PropTypes.func.isRequired,
        importTaxInvoiceFile: PropTypes.func.isRequired,
        supplierInvoices: Immutable.map.isRequired,
        uploadProductBankReconcile: PropTypes.func.isRequired,
        uploadTruckBankReconcile: PropTypes.func.isRequired,
        exportJounalEntryStatements: PropTypes.func.isRequired
    }

    static defaultProps = {}

    render () {
        const { accountings, i18n, supplierInvoices, journalEntries } = this.props

        return (
            <div>
                <h2>{i18n.t('documents:paymentVoucherReport.title')}</h2>
                <ImportPaymentVoucher />
                <ReceiptVoucherExport />
                <Divider />
                <h2>{i18n.t('documents:yieldReport.title')}</h2>
                <ExportYieldReport />
                <h2>{i18n.t('documents:yieldReport.exportAdjusted')}</h2>
                <ExportAdjustedPvRv />
                <Divider />
                <h2>{i18n.t('sales:downloadFile.salesReport')}</h2>
                <ExportSalesReport />
                <Divider />
                <h2>{i18n.t('documents:supplierOrdersReport.title')}</h2>
                <ExportSupplierOrdersReport />
                <Divider />
                <h2>{i18n.t('documents:transferBackReport.title')}</h2>
                <ExportTransferBackReport />
                <Divider />
                <h2>{i18n.t('documents:saleTaxInvoiceReport.title')}</h2>
                <ExportSaleTaxInvoiceReport />
                <Divider />
                <h2>{i18n.t('documents:scbPaymentConfirmReport.title')}</h2>
                <ExportScbPaymentConfirmReport />
                <h2>{i18n.t('documents:rvReport.title')}</h2>
                <Typography.Paragraph type="secondary">{i18n.t('documents:rvReport.description')}</Typography.Paragraph>
                <ImportReceiptVoucher />
                <ExportRv />

                <Divider />

                <h2>{i18n.t('documents:taxInvoiceReport.title')}</h2>
                <ImportReport
                    onUpload={this.props.importTaxInvoiceFile}
                    title={i18n.t('documents:taxInvoiceReport.upload')}
                    uploading={accountings.get('taxInvoiceUploading')}
                />
                <ImportReport
                    onUpload={this.props.importInvoiceEmsTrackingFile}
                    title={i18n.t('documents:taxInvoiceReport.emsTracking')}
                    uploading={accountings.get('invoiceEmsTrackingUploading')}
                />
                <ExportReport
                    loading={accountings.get('taxInvoiceReportLoading')}
                    onLoad={this.props.fetchTaxInvoiceReportFile}
                    title={i18n.t('documents:taxInvoiceReport.search')}
                    description={i18n.t('documents:taxInvoiceReport.searchDescription')}
                />
                <ExportReport
                    loading={accountings.get('invoiceDeliveryAddressLoading')}
                    onLoad={this.props.fetchInvoiceDeliveryAddressPdf}
                    title={i18n.t('documents:taxInvoiceReport.deliveryAddress')}
                    description={i18n.t('documents:taxInvoiceReport.deliveryAddressDescription')}
                />

                <Divider />

                <h2>{i18n.t('documents:supplierInvoiceReport.title')}</h2>
                <ExportReport
                    loading={supplierInvoices.get('exporting')}
                    onLoad={(params) => this.props.exportSupplierInvoices({ params })}
                    title={i18n.t('documents:supplierInvoiceReport.searchByIssueDate')}
                    description={i18n.t('documents:supplierInvoiceReport.searchByIssueDateDescription')}
                />
                <ExportReport
                    loading={supplierInvoices.get('exporting')}
                    onLoad={(params) => this.props.exportSupplierInvoices({ params })}
                    title={i18n.t('documents:supplierInvoiceReport.searchByIssueMonth')}
                    searchType="monthly"
                />
                <ImportReport
                    onUpload={(file) => this.props.importSupplierInvoices({ params: { file } })}
                    title={i18n.t('documents:supplierInvoiceReport.upload')}
                    uploading={supplierInvoices.get('importing')}
                />

                <ExportSupplierLineItems />
                <ExportInvoices1688 />

                <ExportReport
                    loading={journalEntries.get('exporting')}
                    onLoad={(params) => this.props.exportJounalEntryStatements(params)}
                    title={i18n.t('documents:journalEntryStatementsReport.title')}
                    searchType="monthly"
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails'),
    supplierInvoices: state.get('supplierInvoices'),
    journalEntries: state.get('journalEntries')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        exportSupplierInvoices,
        fetchInvoiceDeliveryAddressPdf,
        fetchTaxInvoiceReportFile,
        importInvoiceEmsTrackingFile,
        importSupplierInvoices,
        importTaxInvoiceFile,
        uploadProductBankReconcile,
        uploadTruckBankReconcile,
        exportJounalEntryStatements
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['confirms'])(UploadAndDownloadReport)
)
