import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import MailingAddressFormContainer from '../Addresses/MailingAddressFormContainer'

class CustomerEditMailingAddressPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Edit Mailing Address | OSH CRM'
    }

    render () {
        const { customer_id: customerId, id } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/customers', name: 'Customers' },
                    { path: `/customers/${customerId}`, name: `${customerId}` },
                    { path: `/customers/${customerId}`, name: 'Mailing Addresses' },
                    { path: `/customers/${customerId}/mailing_addresses/${id}/edit`, name: id },
                    { path: `/customers/${customerId}/mailing_addresses/${id}/edit`, name: 'Edit' }
                ]}>
                    <ContentLayout>
                        <MailingAddressFormContainer
                            customerId={customerId}
                            id={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerEditMailingAddressPage
