import { List, Map } from 'immutable'

import actions from './actions'
import { mergeDeepReplaceArrays } from '../entities/reducers'

const {
    WALLET_SUMMARIES_CUSTOMERS_FETCH,
    WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS,
    WALLET_SUMMARIES_WALLETS_FETCH,
    WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS,
    WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION,
    WALLET_SUMMARIES_SET_CUSTOMER_QUERY,
    WALLET_SUMMARIES_SET_WALLET_PAGINATION,
    WALLET_SUMMARIES_SET_WALLET_QUERY,
    WALLET_SUMMARIES_SET_WALLET_IS_APPROVED
} = actions

const initState = new Map({
    customer: new Map({
        items: new List(),
        loading: false,
        pagination: new Map({
            current: 1,
            pageSize: 20,
            total: 1
        }),
        query: ''
    }),
    wallet: new Map({
        items: new List(),
        loading: false,
        pagination: new Map({
            current: 1,
            pageSize: 20,
            total: 1
        }),
        query: '',
        isApproved: ''
    })
})

export default function walletSummaryReducers (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case WALLET_SUMMARIES_CUSTOMERS_FETCH:
            return state.mergeDeep({
                customer: {
                    loading: true
                }
            })
        case WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                customer: {
                    loading: false,
                    items: result
                }
            })
        case WALLET_SUMMARIES_WALLETS_FETCH:
            return state.mergeDeep({
                wallet: {
                    loading: true
                }
            })
        case WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                wallet: {
                    loading: false,
                    items: result
                }
            })
        case WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION:
            return mergeDeepReplaceArrays(state, {
                customer: {
                    pagination: action.pagination
                }
            })
        case WALLET_SUMMARIES_SET_CUSTOMER_QUERY:
            return state.mergeDeep({
                customer: {
                    query: action.query
                }
            })
        case WALLET_SUMMARIES_SET_WALLET_PAGINATION:
            return mergeDeepReplaceArrays(state, {
                wallet: {
                    pagination: action.pagination
                }
            })
        case WALLET_SUMMARIES_SET_WALLET_QUERY:
            return state.mergeDeep({
                wallet: {
                    query: action.query
                }
            })
        case WALLET_SUMMARIES_SET_WALLET_IS_APPROVED:
            return state.mergeDeep({
                wallet: new Map({
                    isApproved: action.isApproved
                })
            })
        default:
            return state
    }
}
