const actions = {
    CUSTOMER_TRUCK_IMAGES_FETCH_REQUEST: 'CUSTOMER_TRUCK_IMAGES_FETCH_REQUEST',
    CUSTOMER_TRUCK_IMAGES_FETCH: 'CUSTOMER_TRUCK_IMAGES_FETCH',
    CUSTOMER_TRUCK_IMAGES_FETCH_SUCCESS: 'CUSTOMER_TRUCK_IMAGES_FETCH_SUCCESS',
    CUSTOMER_TRUCK_IMAGES_SET_PAGINATION: 'CUSTOMER_TRUCK_IMAGES_SET_PAGINATION',

    fetchCustomerTruckImages: ({ customerId, page = 1, per = 10 } = {}) => ({
        type: actions.CUSTOMER_TRUCK_IMAGES_FETCH_REQUEST,
        payload: { customerId, page, per }
    })
}

export default actions
