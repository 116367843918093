import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BankStatementsFilters from '../../components/BankStatements/Filters'

class BankStatementsFiltersContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.shape({
            bankAccountId: PropTypes.string
        })
    }

    static defaultProps = {
        onChange: () => {},
        value: {
            bankAccountId: undefined
        }
    }

    onBankAccountChange = (bankAccountId) => {
        const { onChange } = this.props

        onChange({
            // cannot clear with undefined value
            // use empty string instead
            bankAccountId: (bankAccountId === undefined) ? '' : bankAccountId,
            page: 1
        })
    }

    onDateRangeChange = (dates, dateStrings) => {
        const { onChange } = this.props

        onChange({
            startDate: dateStrings[0],
            endDate: dateStrings[1],
            page: 1
        })
    }

    onHasOrderChange = (hasOrder) => {
        const { onChange } = this.props

        onChange({
            hasOrder,
            page: 1
        })
    }

    onBankStatementTypeChange = (bankStatementType) => {
        const { onChange } = this.props

        onChange({
            bankStatementType,
            page: 1
        })
    }

    onReconciledSelectChange = (isReconciled) => {
        const { onChange } = this.props

        onChange({
            isReconciled,
            page: 1
        })
    }

    render () {
        const { value } = this.props

        return (
            <BankStatementsFilters
                onBankAccountChange={this.onBankAccountChange}
                onDateRangeChange={this.onDateRangeChange}
                onHasOrderChange={this.onHasOrderChange}
                onBankStatementTypeChange={this.onBankStatementTypeChange}
                onReconciledSelectChange={this.onReconciledSelectChange}
                value={value}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementsFiltersContainer)
