import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Input, Select } from 'antd'
import SelectCustomerMailingAddress from '../../../containers/SelectCustomerMailingAddress'
import AreasSelectContainer from '../../../containers/Areas/AreasSelectContainer'

const MailingAddressForm = ({
  areasSelectLayout,
  businessAddress,
  customerNo,
  errors,
  form,
  i18n,
  loading,
  mailingAddress
}) => {
  const onSelectMailingAddress = (option = {}) => {
    if (!option.id) { return null }

    const formRef = form.current ? form.current : form

    formRef.setFieldsValue({
      mailing_address_attributes: {
        name: option.name,
        mailing_address_type: 'other_address',
        address: option.address,
        province_id: option.province && option.province.id,
        district_id: option.district && option.district.id,
        sub_district: option.subDistrict,
        postal_code: option.postalCode,
      }
    })
  }

  const onUseSameAddress = () => {
    const formRef = form.current ? form.current : form

    formRef.setFieldsValue({
      mailing_address_id: undefined,
      mailing_address_attributes: {
        name: formRef.getFieldValue('company_name'),
        mailing_address_type: 'same_address',
        address: formRef.getFieldValue('address_detail'),
        province_id: formRef.getFieldValue('province_id'),
        district_id: formRef.getFieldValue('district_id'),
        sub_district: formRef.getFieldValue('sub_district'),
        postal_code: formRef.getFieldValue('postal_code')
      }
    })
  }

  const mailingAddressId = (businessAddress.mailingAddress && businessAddress.mailingAddress.id) || businessAddress.mailing_address

  return (
    <React.Fragment>
      <h3>
        <span style={{ paddingRight: '8px' }}>{i18n.t('addresses:mailingAddress')}</span>
        <Button type="dashed" onClick={onUseSameAddress}>
          {i18n.t('addresses:useSameAddress')}
        </Button>
      </h3>

      <Form.Item label={i18n.t('addresses:mailingAddressType')}
        name='mailing_address_type'
        initialValue={businessAddress.mailing_address_type}>
          <Select placeholder={i18n.t('addresses:mailingAddressType')}>
            <Select.Option key="same_address" value="same_address">
              {i18n.t('addresses:mailingAddressTypes.sameAddress')}
            </Select.Option>
            <Select.Option key="other_address" value="other_address">
              {i18n.t('addresses:mailingAddressTypes.otherAddress')}
            </Select.Option>
            <Select.Option key="no_address" value="no_address">
              {i18n.t('addresses:mailingAddressTypes.noAddress')}
            </Select.Option>
          </Select>
      </Form.Item>

      <Form.Item label={i18n.t('addresses:selectOriginalAddress')}
        name='mailing_address_id'
        initialValue={mailingAddressId || undefined}>
          <SelectCustomerMailingAddress
            customerNo={customerNo}
            onAfterChange={onSelectMailingAddress}
            outerLoading={loading} />
      </Form.Item>

      <Form.Item label={i18n.t('addresses:name')}
        hasFeedback
        name={['mailing_address_attributes', 'name']}
        initialValue={mailingAddress.name}
        validateStatus={errors['mailing_address.name'] && 'error'}
        help={errors['mailing_address.name']}>
          <Input />
      </Form.Item>

      <Form.Item label={i18n.t('addresses:address')}
        hasFeedback
        name={['mailing_address_attributes', 'address']}
        initialValue={mailingAddress.name}
        validateStatus={errors['mailing_address.address'] && 'error'}
        help={errors['mailing_address.address']}>
          <Input />
      </Form.Item>

      <AreasSelectContainer form={form}
        districtFormItemLayout={areasSelectLayout}
        provinceFormItemLayout={areasSelectLayout}
        postalCodeFormItemLayout={areasSelectLayout}
        subDistrictFormItemLayout={areasSelectLayout}
        districtCol={{ span: 24 }}
        subDistrictCol={{ span: 24 }}
        postalCodeCol={{ span: 24 }}
        provinceCol={{ span: 24 }}
        provinceFieldName={['mailing_address_attributes', 'province_id']}
        districtFieldName={['mailing_address_attributes', 'district_id']}
        showSubDistrict
        postalCodeDisabled={false}
        subDistrictFieldName={['mailing_address_attributes', 'sub_district']}
        postalCodeFieldName={['mailing_address_attributes', 'postal_code']}
        subDistrictHelp={errors['mailing_address.sub_district']}
        subDistrictValidateStatus={errors['mailing_address.sub_district'] && 'error'}
        districtHelp={errors['mailing_address.district']}
        districtValidateStatus={errors['mailing_address.district'] && 'error'}
        provinceHelp={errors['mailing_address.province']}
        provinceValidateStatus={errors['mailing_address.province'] && 'error'}
        postalCodeHelp={errors['mailing_address.postal_code']}
        postalCodeValidateStatus={errors['mailing_address.postal_code'] && 'error'}
        provinceValue={(mailingAddress.province && mailingAddress.province.id)}
        districtValue={(mailingAddress.province && mailingAddress.district.id)}
        subDistrictValue={mailingAddress.sub_district}
        postalCodeValue={mailingAddress.postal_code}
      />
    </React.Fragment>
  )
}

MailingAddressForm.propTypes = {
  areasSelectLayout: PropTypes.object.isRequired,
  businessAddress: PropTypes.object.isRequired,
  customerNo: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  mailingAddress: PropTypes.object.isRequired,
}

MailingAddressForm.defaultProps = {}

export default MailingAddressForm
