import { all, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import { customerNotesSchema, customerNoteSchema } from '../../schema'
import actionsNotification from '../../../redux/notifications/actions'
import orderNoteImageActions from '../../orders/noteImages/actions'

const { setNotifications } = actionsNotification
const {
    ORDER_NOTE_IMAGE_SET_SAVING,
    saveOrderNoteImage,
    deleteOrderNoteImage
} = orderNoteImageActions

const {
    CUSTOMER_NOTES_FETCH_REQUEST,
    CUSTOMER_NOTES_FETCH_SUCCESS,
    CUSTOMER_NOTES_FETCH,
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_REQUEST,
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS,
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_SAVE_REQUEST,
    CUSTOMER_NOTES_SAVE_REQUEST,
    CUSTOMER_NOTES_SAVE_SUCCESS,
    CUSTOMER_NOTES_SAVE,
    CUSTOMER_NOTES_SET_FORM_ERRORS,
    CUSTOMER_NOTES_SET_PAGINATION,
    ORDER_NOTES_FETCH_REQUEST,
    ORDER_NOTES_FETCH_SUCCESS,
    ORDER_NOTES_FETCH
} = actions

export function *customerNotesRequest () {
    yield takeLatest(CUSTOMER_NOTES_FETCH_REQUEST, function *({ payload }) {
        const id = payload.id
        const page = payload.page
        const PAGE_SIZE = 20

        yield put({ type: CUSTOMER_NOTES_FETCH })

        const data = yield call(apiFetch, `/customers/${id}/notes?page=${page}&per=${PAGE_SIZE}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerNotesSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: CUSTOMER_NOTES_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: CUSTOMER_NOTES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *orderNotesRequest () {
    yield takeLatest(ORDER_NOTES_FETCH_REQUEST, function *({ payload }) {
        const id = payload.id
        const page = payload.page
        const PAGE_SIZE = 20

        yield put({ type: ORDER_NOTES_FETCH })

        const data = yield call(apiFetch, `/orders/${id}/customer_notes?page=${page}&per=${PAGE_SIZE}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerNotesSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: CUSTOMER_NOTES_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: ORDER_NOTES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitCustomerNote () {
    yield takeLatest(CUSTOMER_NOTES_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_NOTES_SAVE, saving: true })

        const { customerNote, id, onSuccess } = payload
        const method = 'POST'
        const path = `/customers/${id}/notes`
        const body = JSON.stringify({ customer_note: customerNote })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: CUSTOMER_NOTES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, customerNoteSchema)

                yield put({
                    type: CUSTOMER_NOTES_SAVE_SUCCESS,
                    ...normalizeData
                })

                onSuccess()
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }

            const orderNoteImageIds = payload.customerNote.order_note_image_ids
            const orderNumber = payload.customerNote.order_number
            const orderNoteImage = { order_note_image: { orderNumber } }

            if (orderNoteImageIds.length !== 0) {
                yield all(orderNoteImageIds.map((id) => {
                    return call(updateOrderNoteImage, id, orderNoteImage)
                }))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMER_NOTES_SAVE, saving: false })
        }
    })
}

function *updateOrderNoteImage (orderNoteImageId, orderNoteImage) {
    yield put({ type: ORDER_NOTE_IMAGE_SET_SAVING, saving: true })

    const body = JSON.stringify(orderNoteImage)
    const updateApiOrderNoteImagesPath = `/orders/note_images/${orderNoteImageId.toString()}`

    yield call(apiFetch, updateApiOrderNoteImagesPath, 'PATCH', { body })
    yield put({ type: ORDER_NOTE_IMAGE_SET_SAVING, saving: false })
}

export function *submitOrderNoteImageOfCustomerNote () {
    yield takeLatest(CUSTOMER_NOTES_ORDER_NOTE_IMAGE_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_NOTES_SAVE, saving: true })

        const { orderNumber, orderNoteImage, onSuccess, onUploadProgress } = payload
        const saveFromCustomerNote = true

        try {
            yield put(saveOrderNoteImage(orderNumber, orderNoteImage, saveFromCustomerNote, {
                onSuccess, onUploadProgress
            }))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMER_NOTES_SAVE, saving: false })
        }
    })
}

export function *deleteOrderNoteImageOfCustomerNote () {
    yield takeLatest(CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_NOTES_SAVE, saving: true })

        const orderNoteImageId = payload.orderNoteImageId

        try {
            yield put(deleteOrderNoteImage(orderNoteImageId))
            yield put({
                type: CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS,
                result: orderNoteImageId
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMER_NOTES_SAVE, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(customerNotesRequest),
        fork(deleteOrderNoteImageOfCustomerNote),
        fork(orderNotesRequest),
        fork(submitCustomerNote),
        fork(submitOrderNoteImageOfCustomerNote)
    ])
}
