import { Map, List } from 'immutable'
import actions from './actions'

const {
    CUSTOMER_ROLES_FETCH_SUCCESS,
    CUSTOMER_ROLE_SET_FORM,
    CUSTOMER_ROLES_SAVE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    showRoleForm: false,
    saving: false,
    uploading: false
})

export default function customerRolesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_ROLE_SET_FORM:
            return state.merge({
                showRoleForm: action.value
            })
        case CUSTOMER_ROLES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CUSTOMER_ROLES_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(result) ? items : items.push(result)
            })
        default:
            return state
    }
}
