import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Col, Form, Row, Select, Spin } from 'antd'
import CommentFormContainer from '../../Comments/CommentFormContainer'
import CommentsList from '../../../components/Comments/List'

import { TYPE_CONTACTS, TYPE_SOURCES } from '../../../constants/customers'
import customerNoteActions from '../../../redux/customers/customerNotes/actions'

const { deleteOrderNoteImageOfCustomerNote, saveCustomerNote, saveOrderNoteImageOfCustomerNote } = customerNoteActions

const FIELD_NAMES = {
    DESCRIPTION: 'note',
    ATTACHMENTS: 'order_note_image_ids',
    SOURCE_TYPE: 'source_type',
    TYPE_CONTACT: 'type_contact'
}

const ORDER_CUSTOMER_NOTES = gql`
    query Order($number: String!) {
      order(number: $number) {
            id
            number
            customerNotes {
              note
              sourceType
              typeContact
              staff {
                firstName
              }
              createdAt {
                display
                raw
              }
              orderNoteImages {
                contentType
                image {
                  original
                  thumb
                }
              }
            }
        }
    }
`

const onFilterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

const renderSelectOptions = (selectOption) => {
    if (!selectOption) { return null }

    return selectOption.map((option, index) => {
        return <Select.Option key={index} value={option}>{option}</Select.Option>
    })
}

const OrderNotesContainer = ({
    customerNo,
    orderId
}) => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation('customers/notes')
    const orderNoteImageItems = useSelector(state => state.getIn(['customerNotes', 'orderNoteImageItems']))
    const saving = useSelector(state => state.getIn(['customerNotes', 'saving']))
    const { data, loading, refetch } = useQuery(ORDER_CUSTOMER_NOTES, { variables: { number: orderId } })

    if (loading) return <Spin />

    const order = data ? data.order : {}

    const onUploadAttachment = (file, { onProgress = () => {}, onSuccess = () => {} } = {}) => {
        dispatch(saveOrderNoteImageOfCustomerNote(orderId, { image: file }, {
            onUploadProgress: onProgress,
            onSuccess: onSuccess
        }))
    }

    const getImagesList = (images) => {
        return images.map((image) => {
            return {
                contentType: image.contentType,
                original : image.image.original,
                small : image.image.thumb
            }
        })
    }

    const getNotesList = () => {
        const notes = order.customerNotes || []
        return notes.map((note) => {
            return {
                note: note.note,
                sourceType: note.sourceType,
                typeContact: note.typeContact,
                staff: note.staff,
                createdAt: note.createdAt,
                images: getImagesList(note.orderNoteImages)
            }
        })
    }

    const onSaveComment = (values, form) => {
        dispatch(saveCustomerNote({
            ...values,
            [FIELD_NAMES.ATTACHMENTS]: orderNoteImageItems.toJS(),
            order_number: orderId
        }, customerNo, {
            onSuccess: () => {
                form.resetFields()
                refetch()
            }
        }))
    }

    const onRemoveAttachment = (index) => {
        const id = orderNoteImageItems.get(index)

        dispatch(deleteOrderNoteImageOfCustomerNote(id))
    }

    const renderFrontPart = () => (
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item label={i18n.t('customers/notes:form.source')} name={FIELD_NAMES.SOURCE_TYPE}>
                    <Select
                        filterOption={onFilterOption}
                        placeholder={i18n.t('customers/notes:form.sourcePlaceholder')}
                    >
                        {renderSelectOptions(TYPE_SOURCES)}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label={i18n.t('customers/notes:form.typeContact')} name={FIELD_NAMES.TYPE_CONTACT}>
                    <Select
                        filterOption={onFilterOption}
                        placeholder={i18n.t('customers/notes:form.sourcePlaceholder')}
                    >
                        {renderSelectOptions(TYPE_CONTACTS)}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )

    return (
        <div>
            <CommentFormContainer
                attachmentFieldName={FIELD_NAMES.ATTACHMENTS}
                descriptionFieldName={FIELD_NAMES.DESCRIPTION}
                frontPart={renderFrontPart()}
                onSaveComment={onSaveComment}
                onRemoveAttachment={onRemoveAttachment}
                onUploadAttachment={onUploadAttachment}
                saving={saving}
            />
            <CommentsList notes={getNotesList()}
                isOrderNote={true} />
        </div>
    )
}

OrderNotesContainer.propTypes = {
    orderId: PropTypes.string.isRequired
}

OrderNotesContainer.defaultProps = {}

export default OrderNotesContainer
