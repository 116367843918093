import { all, takeLatest, put, fork, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'

import { deserialize } from '../../helpers/jsonApi'
import { itemWithProductGroupsSchema, productGroupsSchema } from '../schema'

const {
    PRODUCT_GROUPS_FETCH_FAIL,
    PRODUCT_GROUPS_FETCH_REQUEST,
    PRODUCT_GROUPS_FETCH_SUCCESS,
    PRODUCT_GROUPS_FETCHING,
    PRODUCT_GROUPS_FETCH_ITEMS_FAIL,
    PRODUCT_GROUPS_FETCH_ITEMS_REQUEST,
    PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS,
    PRODUCT_GROUPS_FETCHING_ITEMS,
    PRODUCT_GROUPS_SET_ITEMS_PAGINATION,
    PRODUCT_GROUPS_SET_PRODUCT_IMAGE,
} = actions

export function *fetchProductGroups () {
    yield takeLatest(PRODUCT_GROUPS_FETCH_REQUEST, function *({ payload }) {
        const { onSuccess, onError } = payload
        yield put({ type: PRODUCT_GROUPS_FETCHING })

        try {
            const path = `/product_groups`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, productGroupsSchema)

            yield put({ type: PRODUCT_GROUPS_FETCH_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: PRODUCT_GROUPS_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *fetchItems () {
    yield takeLatest(PRODUCT_GROUPS_FETCH_ITEMS_REQUEST, function *({ payload }) {
        const { productGroupId, productGroupItemId, parentId, page, perPage, onSuccess, onError } = payload
        yield put({ type: PRODUCT_GROUPS_FETCHING_ITEMS })

        try {
            const queryString = `product_group_item_id=${productGroupItemId}&parent_id=${parentId}`
            const path = `/product_groups/${productGroupId}/items?${queryString}&page=${page}&per=${perPage}`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, itemWithProductGroupsSchema)

            const total = data.meta.total_pages * perPage
            yield put({
                type: PRODUCT_GROUPS_SET_ITEMS_PAGINATION,
                pagination: { pageSize: perPage, total, current: page }
            })

            yield put({ type: PRODUCT_GROUPS_SET_PRODUCT_IMAGE, productImage: data.meta.product_image })

            yield put({ type: PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: PRODUCT_GROUPS_FETCH_ITEMS_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchProductGroups),
        fork(fetchItems),
    ])
}
