import { Map, List } from 'immutable'
import actions from './actions'

const {
    DISTRICTS_FETCH,
    DISTRICTS_FETCH_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false
})

export default function districtsReducer (state = initState, action) {
    switch (action.type) {
        case DISTRICTS_FETCH:
            return state.merge({ loading: true })
        case DISTRICTS_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result),
                loading: false
            })
        default:
            return state
    }
}
