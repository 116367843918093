import fetch from 'isomorphic-fetch'
import jsCookie from 'js-cookie'
import axios from 'axios'

import apiConfig from '../../config/apiConfig'

const getCurrentAccessToken = () => {
    return {
        token: jsCookie.get('token'),
        type: jsCookie.get('tokenType')
    }
}

const removeAccessToken = () => {
    jsCookie.remove('token')
    jsCookie.remove('tokenType')
}

const objectToFormData = (
    obj,
    jsonFields,
    arrayFields = [],
    formData,
    namespace
) => {
    const data = formData || new FormData()
    let key

    for (let prop in obj) {
        if (namespace) {
            key = `${namespace}[${prop}]`
        } else {
            key = prop
        }

        if (jsonFields.includes(prop)) {
            // TODO: Need to find a better way to send a JSON Object via FormData,
            // otherwise, we will need to parse this string back to Ruby hash
            // before we can save it into the database
            data.append(key, JSON.stringify(obj[prop]))
        } else if (arrayFields.includes(prop)) {
            if (obj[prop].length === 0) {
                data.append(`${key}[]`, [])
                continue
            }

            for (let i = 0; i < obj[prop].length; i++) {
                data.append(`${key}[]`, obj[prop][i])
            }
        } else if (
            typeof obj[prop] === 'object' &&
            !(obj[prop] instanceof File)
        ) {
            objectToFormData(obj[prop], jsonFields, arrayFields, data, key)
        } else {
            data.append(key, obj[prop])
        }
    }

    return data
}

export const apiBase = `${apiConfig.apiBase}/api/v1`

export const apiFileFetch = async (
    path,
    method = 'GET',
    request = {},
    isIncludeApiBase = false,
    contentType = 'application/pdf'
) => {
    const accessToken = getCurrentAccessToken()
    const apiBasePath = isIncludeApiBase
        ? `${apiConfig.apiBase}${path}`
        : `${apiBase}${path}`

    const defaultRequest = {
        headers: {
            'Content-Type': contentType,
            Authorization: `Bearer ${accessToken.token}`
        },
        responseType: 'blob',
        params: {}
    }

    return await fetch(
        apiBasePath,
        Object.assign({}, defaultRequest, { method }, request)
    )
        .then((response) => {
            return response.blob()
        })
        .catch((error) => {
            return error
        })
}

export const apiFetch = async (path, method = 'GET', request = {}) => {
    const accessToken = getCurrentAccessToken()

    const defaultRequest = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: accessToken.token
                ? `Bearer ${accessToken.token}`
                : ''
        },
        params: {}
    }

    return await fetch(
        `${apiBase}${path}`,
        Object.assign({}, defaultRequest, { method }, request)
    )
        .then((response) => {
            if (response.status === 401) {
                removeAccessToken()
            }
            if (response.status === 405) {
                removeAccessToken()
                location.reload()
            }
            return response.json()
        })
        .catch((error, value) => {
            return error
        })
}

export const requestHeader = async (options = {}) => {
    const accessToken = getCurrentAccessToken()

    return {
        ...options,
        Authorization: `Bearer ${accessToken.token}`
    }
}

export const apiPostForm = async (
    path,
    method = 'POST',
    params,
    jsonFields = [],
    arrayFields = []
) => {
    const accessToken = getCurrentAccessToken()

    const data = objectToFormData(params, jsonFields, arrayFields)

    return await fetch(`${apiBase}${path}`, {
        method: method,
        headers: {
            Authorization: `Bearer ${accessToken.token}`
        },
        body: data
    })
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            return error
        })
}

export const apiPostFormWithProgress = async ({
    path,
    method = 'POST',
    params = {},
    jsonFields = [],
    arrayFields = [],
    onSuccess = () => {},
    onError = () => {},
    onUploadProgress = () => {}
} = {}) => {
    const accessToken = getCurrentAccessToken()
    const data = objectToFormData(params, jsonFields, arrayFields)

    const config = {
        url: `${apiBase}${path}`,
        method,
        data,
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken.token}`
        },
        onUploadProgress: (e) => {
            onUploadProgress(e)
        }
    }

    return await axios(config)
        .then((response) => {
            onSuccess(response)
            return response.data
        })
        .catch((error) => {
            onError(error)
            return error
        })
}

export const mockApiFetch = async (path, method = 'GET', request = {}) => {
    const defaultRequest = {
        headers: {
            'Content-Type': 'application/json'
        },
        params: {}
    }

    return await fetch(
        `http://private-2a8a2-osh1.apiary-mock.com/api/v1${path}`,
        Object.assign({}, defaultRequest, { method }, request)
    )
        .then((response) => {
            return response.json()
        })
        .catch((error) => {
            return error
        })
}
