import React from 'react'
import PropTypes from 'prop-types'
import { List as immutableList } from 'immutable'
import i18n from 'i18next'
import moment from 'moment'

import { Form, Select, Row, Col, Input, Button, DatePicker, notification, Upload } from 'antd'
import { PlusOutlined, CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import EditorContainer from '../../containers/Editor/EditorContainer'


const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 }
}

const formLongLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
}

const paymentDetailForm = ({
    orderPaymentDetails,
    bankList,
    invoiceList,
    setFileList,
    fileList,
    item,
    page,
    form,
    orderState
}) => {
    const handleCopy = () => {
        notification['success']({
            message: i18n.t('order.copyToClipboard')
        })
    }

    const handleChange = ({ fileList }) => setFileList({ fileList })

    const normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text"> Upload </div>
        </div>
    )

    const imageHandler = (file, imgBase64) => {
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        const formRef = form.current ? form.current : form

        formRef.setFieldsValue({ image: fileList.concat(newItemOfFileList) })
        setFileList({ fileList: fileList.concat(newItemOfFileList), File: newItemOfFileList })
    }

    const bankErrors = orderPaymentDetails.getIn(['errors', 'bank_account'])
    const amountErrors = orderPaymentDetails.getIn(['errors', 'amount'])
    const transferDate = item && item.get('transfer_date')
    const bank = item && item.getIn(['bank_account', 'id'])
    const paymentTerm = item && item.get('payment_term')
    const amount = item && item.getIn(['amount', 'raw'])
    const paymentMethod = item && item.get('payment_method')
    const receiptVoucher = item && item.get('receipt_voucher')
    const invoiceIds = item ? item.get('order_business_address_ids') : immutableList()
    const note = item && item.get('note')
    const reconciled = item && item.get('reconciled')

    return (
        <div>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={i18n.t('confirms:accounting.transferDate')} {...formItemLayout}
                        name='transfer_date'
                        initialValue={transferDate ? moment(transferDate, 'YYYY-MM-DD') : null}>
                            <DatePicker style={{ width: '100%' }} placeholder={i18n.t('confirms:accounting.selectDate')} disabled={reconciled} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={i18n.t('confirms:accounting.paymentTerm')} {...formItemLayout}
                        name='payment_term'
                        initialValue={paymentTerm || undefined}>
                            <Select allowClear placeholder={i18n.t('confirms:accounting.selectPaymentTerm')}>
                                <Select.Option value="full_amount"> {i18n.t('confirms:accounting.full_amount')} </Select.Option>
                                <Select.Option value="deposit"> {i18n.t('confirms:accounting.deposit')} </Select.Option>
                            </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item {...formItemLayout}
                        label={i18n.t('confirms:accounting.amount')}
                        name='amount'
                        initialValue={amount}
                        validateStatus={amountErrors ? 'error' : null}
                        help={amountErrors}
                        hasFeedback>
                            <Input className="text-right" prefix="฿" placeholder="0.00" disabled={reconciled}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={i18n.t('confirms:accounting.paymentMethod')} {...formItemLayout}
                        name='payment_method'
                        initialValue={paymentMethod || undefined}>
                            <Select allowClear placeholder={i18n.t('confirms:accounting.selectPaymentMethod')}>
                                <Select.Option value="cash"> {i18n.t('confirms:accounting.cash')} </Select.Option>
                                <Select.Option value="credit_card"> {i18n.t('confirms:accounting.credit_card')} </Select.Option>
                                <Select.Option value="cheque"> {i18n.t('confirms:accounting.cheque')} </Select.Option>
                            </Select>
                    </Form.Item>

                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item {...formLongLayout}
                        name='bank_account_id'
                        initialValue={bank || undefined}
                        label={i18n.t('confirms:accounting.bank')}
                        validateStatus={bankErrors ? 'error' : null}
                        help={bankErrors}
                        hasFeedback>
                            <Select allowClear placeholder={i18n.t('confirms:accounting.selectBank')}>
                                {bankList}
                            </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24} >
                {!page && (
                    <Col span={12}>
                        <Form.Item label={i18n.t('confirms:accounting.receiptVoucher')} {...formItemLayout}>
                            <Input.Group compact>
                                <Form.Item name='receipt_voucher' noStyle
                                    initialValue={receiptVoucher || null}
                                >
                                    <Input style={{ width: '80%' }} />
                                </Form.Item>
                                <Form.Item noStyle>
                                    <CopyToClipboard text={receiptVoucher}>
                                        <Button style={{ width: '20%' }} icon={<CopyOutlined />} disabled={!receiptVoucher} onClick={() => handleCopy()} />
                                    </CopyToClipboard>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                )}
                <Col span={12}>
                    <Form.Item label={i18n.t('confirms:invoice.taxInvoice')}
                        {...formItemLayout}
                        initialValue={invoiceIds ? invoiceIds.toJS() : []}
                        name='order_business_address_ids'>
                            <Select allowClear
                                placeholder={i18n.t('confirms:invoice.selectTaxinvoices')}
                                mode="multiple">
                                {invoiceList}
                            </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={i18n.t('confirms:accounting.slip')} {...formLongLayout}
                        name='image' getValueFromEvent={normFile}>
                            <Upload.Dragger
                                listType="picture-card"
                                showUploadList={{ showPreviewIcon: false }}
                                onChange={handleChange}
                                beforeUpload={() => false}
                                fileList={fileList}
                                multiple>
                                {uploadButton}
                            </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={i18n.t('confirms:accounting.pasteFileToUpload')} {...formLongLayout}
                        name='paste_image'>
                            <EditorContainer theme="snow"
                                placeholder={i18n.t('confirms:accounting.pasteSlip')}
                                imageHandler={imageHandler} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={i18n.t('confirms:accounting.note')} {...formLongLayout}
                        name='note'
                        initialValue={note || null}>
                            <Input.TextArea></Input.TextArea>
                    </Form.Item>
                </Col>
            </Row>
        </div>

    )
}

paymentDetailForm.propTypes = {
    orderPaymentDetails: PropTypes.object,
    bankList: PropTypes.object.isRequired,
    invoiceList: PropTypes.object.isRequired,
    setFileList: PropTypes.func.isRequired,
    fileList: PropTypes.array.isRequired,
    item: PropTypes.object,
    form: PropTypes.object.isRequired
}

export default paymentDetailForm
