const actions = {
    JOURNAL_ENTRY_FORMATS_FETCH_REQUEST: 'JOURNAL_ENTRY_FORMATS_FETCH_REQUEST',
    JOURNAL_ENTRY_FORMATS_FETCH: 'JOURNAL_ENTRY_FORMATS_FETCH',
    JOURNAL_ENTRY_FORMATS_FETCH_SUCCESS: 'JOURNAL_ENTRY_FORMATS_FETCH_SUCCESS',
    JOURNAL_ENTRY_FORMATS_SET_PAGINATION: 'JOURNAL_ENTRY_FORMATS_SET_PAGINATION',

    JOURNAL_ENTRY_FORMAT_SAVE_REQUEST: 'JOURNAL_ENTRY_FORMAT_SAVE_REQUEST',
    JOURNAL_ENTRY_FORMAT_SAVING: 'JOURNAL_ENTRY_FORMAT_SAVING',
    JOURNAL_ENTRY_FORMAT_SET_FORM_ERRORS: 'JOURNAL_ENTRY_FORMAT_SET_FORM_ERRORS',
    JOURNAL_ENTRY_FORMAT_SAVE_SUCCESS: 'JOURNAL_ENTRY_FORMAT_SAVE_SUCCESS',

    JOURNAL_ENTRY_FORMAT_DELETE_REQUEST: 'JOURNAL_ENTRY_FORMAT_DELETE_REQUEST',
    JOURNAL_ENTRY_FORMAT_DELETE: 'JOURNAL_ENTRY_FORMAT_DELETE',
    JOURNAL_ENTRY_FORMAT_DELETE_SUCCESS: 'JOURNAL_ENTRY_FORMAT_DELETE_SUCCESS',

    JOURNAL_ENTRY_FORMATS_SET_FORM_MODAL: 'JOURNAL_ENTRY_FORMATS_SET_FORM_MODAL',

    JOURNAL_ENTRY_FORMAT_FETCH_REQUEST: 'JOURNAL_ENTRY_FORMAT_FETCH_REQUEST',
    JOURNAL_ENTRY_FORMAT_FETCH: 'JOURNAL_ENTRY_FORMAT_FETCH',
    JOURNAL_ENTRY_FORMAT_FETCH_SUCCESS: 'JOURNAL_ENTRY_FORMAT_FETCH_SUCCESS',

    fetchJournalEntryFormats: ({
        page = 1,
        per = 20
    } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMATS_FETCH_REQUEST,
        payload: { page, per }
    }),

    fetchJournalEntryFormat: ({ id } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMAT_FETCH_REQUEST,
        payload: { id }
    }),

    saveJournalEntryFormat: ({
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.JOURNAL_ENTRY_FORMAT_SAVE_REQUEST,
            payload: { id, params, onSuccess, onError }
        }
    },

    deleteJournalEntryFormat: ({ id, onSuccess = () => {} } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMAT_DELETE_REQUEST,
        payload: { id, onSuccess }
    }),

    setJournalEntryFormatModal: ({ visible, selected } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMATS_SET_FORM_MODAL,
        visible, selected
    })
}

export default actions
