import React from 'react'
import PropTypes from 'prop-types'

import {Checkbox} from 'antd'

import { useTranslation } from 'react-i18next'

const ScmFinishedOrderRender = ({ isScmFinishedOrder, saveScmFinishedOrder, orderId }) => {
  const { i18n } = useTranslation()

  const onCheckScmFinishedOrder = () => {
    saveScmFinishedOrder()
  }

  return (
    <React.Fragment>
      <Checkbox onChange={() => onCheckScmFinishedOrder()}
                defaultChecked={isScmFinishedOrder}
      >
        {i18n.t('orders:scmFinishedOrder')}
      </Checkbox>
    </React.Fragment>
  )
}

ScmFinishedOrderRender.propTypes = {
  editable: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  onSaveSuccess: PropTypes.func.isRequired
}

ScmFinishedOrderRender.defaultProps = {}

export default ScmFinishedOrderRender
