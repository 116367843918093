import { all, takeLatest, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch, apiPostFormWithProgress } from '../../../helpers/restRequest'

import actions from './actions'
import { orderNoteImageSchema, orderNoteImagesSchema } from '../../schema'

import actionsNotification from '../../../redux/notifications/actions'
import customerNoteActions from '../../../redux/customers/customerNotes/actions'

const { setNotifications } = actionsNotification

const {
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS
} = customerNoteActions

const {
    ORDER_NOTE_IMAGES_REQUEST,
    ORDER_NOTE_IMAGES_FETCH,
    ORDER_NOTE_IMAGES_FETCH_SUCCESS,
    ORDER_NOTE_IMAGE_SAVE_REQUEST,
    ORDER_NOTE_IMAGE_SET_SAVING,
    ORDER_NOTE_IMAGE_SAVE_SUCCESS,
    ORDER_NOTE_IMAGE_DELETE_REQUEST,
    ORDER_NOTE_IMAGE_DELETE,
    ORDER_NOTE_IMAGE_DELETE_SUCCESS
} = actions

export function *orderNoteImagesRequest () {
    yield takeEvery(ORDER_NOTE_IMAGES_REQUEST, function *({ payload }) {
        const orderId = payload.orderId

        yield put({ type: ORDER_NOTE_IMAGES_FETCH })

        const data = yield call(apiFetch, `/orders/${orderId}/note_images`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, orderNoteImagesSchema)

        yield put({
            type: ORDER_NOTE_IMAGES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitorderNoteImage () {
    yield takeLatest(ORDER_NOTE_IMAGE_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_NOTE_IMAGE_SET_SAVING, saving: true })

        const { onSuccess, onUploadProgress, orderId, saveFromCustomerNote, values } = payload
        const method = 'POST'
        const path = `/orders/note_images`
        const orderNoteImage = { order_note_image: { ...values, order_number: orderId } }

        try {
            const data = yield call(apiPostFormWithProgress, {
                path,
                method,
                params: orderNoteImage,
                onUploadProgress
            })
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderNoteImageSchema)

            yield put({
                type: ORDER_NOTE_IMAGE_SAVE_SUCCESS,
                ...normalizeData
            })

            if (saveFromCustomerNote) {
                yield put({
                    type: CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS,
                    result: normalizeData.result
                })
            }

            yield put(setNotifications('success', 'saveSuccess', 'success'))
            onSuccess()
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: ORDER_NOTE_IMAGE_SET_SAVING, saving: false })
        }
    })
}

export function *deleteOrderNoteImage () {
    yield takeLatest(ORDER_NOTE_IMAGE_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_NOTE_IMAGE_DELETE })

        const id = payload.id
        const method = 'DELETE'
        const path = `/orders/note_images/${id}`

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderNoteImageSchema)

            yield put({
                type: ORDER_NOTE_IMAGE_DELETE_SUCCESS,
                ...normalizeData
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(orderNoteImagesRequest),
        fork(submitorderNoteImage),
        fork(deleteOrderNoteImage)
    ])
}
