import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { Spin, Col, Row, Divider } from 'antd'
import TicketInfo from './ticketInfo'
import TicketNoteForm from "./ticketNoteForm"
import TicketNoteList from "./ticketNoteList"
import TicketMembers from './ticketMembers'
import TicketStatesDropDown from './ticketStates/dropdown'
import TicketImages from './ticketImages'

const Ticket = ({ ticket, loading, refetchTicket, onFetchMoreNote }) => {
    const ticketImagesRef = useRef()
    const onCreateNoteSuccess = async () => {
        await refetchTicket()
        ticketImagesRef.current.refetch()
    }

    return (
        <div>
            <Spin spinning={loading}>
                <Row>
                    <Col span={16}>
                        <TicketInfo ticket={ticket} refetch={refetchTicket} />
                        <Divider />
                        <TicketNoteForm ticket={ticket}
                            onSuccess={onCreateNoteSuccess} />
                        <TicketNoteList ticket={ticket} onFetchMore={onFetchMoreNote} />
                    </Col>
                    <Col span={8} style={{ paddingLeft: '16px' }}>
                        <div style={{ textAlign: 'end' }}>
                            <TicketStatesDropDown
                                ticketId={ticket.id}
                                refetchTicket={refetchTicket}
                                state={ticket.state}
                                stateLabel={ticket.stateLabel} />
                        </div>
                        <TicketMembers ticket={ticket} refetchTicket={refetchTicket} />
                        <TicketImages ticketId={ticket.id} ref={ticketImagesRef} />
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

Ticket.propTypes = {
    loading: PropTypes.bool,
    ticket: PropTypes.object.isRequired,
    refetchTicket: PropTypes.func.isRequired,
    onFetchMoreNote: PropTypes.func.isRequired,
}

Ticket.defaultProps = {}

export default Ticket
