import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Spin, Empty, Button, notification, Card } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import withImagePreview from '../ImagePreview/ImagePreviewContainer'
import Image from '../Image'
import ScmNoteForm from '../OrderTracking/ScmNoteForm'
import TrackingListByCheck from '../../components/inquiry/trackingListByCheck'
import TrackingListByDate from '../../components/inquiry/trackingListByDate'

import orderTrackingActions from '../../redux/orders/trackings/actions'
import truckImageActions from '../../redux/items/truckImages/actions'

const { fetchOrderTracking } = orderTrackingActions
const { fetchOrderItemTruckImages } = truckImageActions

class TrackingContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        orderState: PropTypes.string.isRequired,

        // Inner Props
        fetchOrderItemTruckImages: PropTypes.func.isRequired,
        fetchOrderTracking: PropTypes.func.isRequired,
        itemTruckImageEntities: ImmutablePropTypes.map.isRequired,
        itemTruckImages: ImmutablePropTypes.map.isRequired,
        orderTrackingEntities: ImmutablePropTypes.map.isRequired,
        orderTrackings: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const { fetchOrderTracking, orderId, fetchOrderItemTruckImages } = this.props
        fetchOrderTracking(orderId)
        fetchOrderItemTruckImages({ orderId: orderId })
    }

    getOrderTracking () {
        const { orderTrackingEntities, orderTrackings } = this.props
        const id = orderTrackings.get('item')

        return orderTrackingEntities.get(id) || new Map()
    }

    getTrackingDateTimeItems = (orderTracking) => {
        let items = {}
        const trackingList = _.get(orderTracking, 'order_tracking_items', []).filter(
            tracking => _.get(tracking, 'item_type', '') === 'datetime'
        )

        _.orderBy(trackingList, 'created_at', 'desc').map((item) => {
            const date = moment(_.get(item, 'created_at')).format('YYYY-MM-DD')
            const prevItems = items[date] || []

            items = {
                ...items,
                [date]: prevItems.concat(item)
            }
        })

        return items
    }

    getTrackingCheckItems = (orderTracking) => {
        return _.get(orderTracking, 'order_tracking_items', []).filter(
            tracking => _.get(tracking, 'item_type', '') === 'check' && _.get(tracking, 'is_checked')
        )
    }

    getItemTruckImage (id) {
        if (!id) return new Map()

        const { itemTruckImageEntities } = this.props
        return itemTruckImageEntities.get(_.toString(id))
    }

    handleCopy () {
        notification['success']({
            message: this.props.i18n.t('order.copyToClipboard')
        })
    }

    renderTrackingList = (orderTracking) => {
        const trackingDateTimeItems = this.getTrackingDateTimeItems(orderTracking)
        const trackingCheckItems = this.getTrackingCheckItems(orderTracking)

        if (_.isEmpty(trackingDateTimeItems) && _.isEmpty(trackingCheckItems)) { return <Empty /> }

        return (
            <div>
                {this.renderTruckImages()}
                <TrackingListByDate trackingList={trackingDateTimeItems} />
                <TrackingListByCheck trackingList={trackingCheckItems} />
            </div>
        )
    }

    renderTruckImages () {
        const { itemTruckImages } = this.props

        if (itemTruckImages.get('loading', false)) return <Spin size="small" />

        return itemTruckImages.get('items').map((id, index) => {
            const image = this.getItemTruckImage(id)

            return (
                <Image
                    key={image.get('id', `index-${index}`)}
                    contentType={image.get('contentType', '')}
                    originalUrl={image.getIn(['file', 'original'], '')}
                    thumbUrl={image.getIn(['file', 'small'], '')}
                    isImageBlob={false}
                />
            )
        })
    }

    render () {
        const { i18n, orderTrackings, orderId, orderState } = this.props

        if (orderTrackings.get('loading')) { return <Spin /> }

        const orderTracking = this.getOrderTracking().toJS()
        const number = _.get(orderTracking, 'number', null)

        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <h3 style={{ margin: '8px' }}>{i18n.t('inquiries:shipment')}</h3>
                    {number ? (
                        <CopyToClipboard style={{ alignSelf: 'center' }} text={number}>
                            <Button type="dashed" shape="round" onClick={() => this.handleCopy()}>
                                {number} <CopyOutlined />
                            </Button>
                        </CopyToClipboard>
                    ) : null}
                </div>

                <Card type="inner" bodyStyle={{ padding: 8 }}>
                    <ScmNoteForm orderId={orderId}
                        initFetch={false}
                        orderState={orderState}
                    />
                </Card>

                {this.renderTrackingList(orderTracking)}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderTrackingEntities: state.getIn(['Entities', 'orderTrackings']),
    orderTrackings: state.get('orderTrackings'),
    itemTruckImages: state.get('itemTruckImages'),
    itemTruckImageEntities: state.getIn(['Entities', 'itemTruckImages'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchOrderTracking,
        fetchOrderItemTruckImages
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('inquiries')(withImagePreview(TrackingContainer))
)
