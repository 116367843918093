const actions = {
  ORDER_TRANSFER_BACKS_FETCH_REQUEST: 'ORDER_TRANSFER_BACKS_FETCH_REQUEST',
  ORDER_TRANSFER_BACKS_FETCH: 'ORDER_TRANSFER_BACKS_FETCH',
  ORDER_TRANSFER_BACKS_FETCH_SUCCESS: 'ORDER_TRANSFER_BACKS_FETCH_SUCCESS',

  ORDER_TRANSFER_BACK_FETCH_REQUEST: 'ORDER_TRANSFER_BACK_FETCH_REQUEST',
  ORDER_TRANSFER_BACK_FETCH: 'ORDER_TRANSFER_BACK_FETCH',
  ORDER_TRANSFER_BACK_FETCH_SUCCESS: 'ORDER_TRANSFER_BACK_FETCH_SUCCESS',

  ORDER_TRANSFER_BACK_FETCH_ITEMS_REQUEST: 'ORDER_TRANSFER_BACK_FETCH_ITEMS_REQUEST',
  ORDER_TRANSFER_BACK_FETCH_ITEMS: 'ORDER_TRANSFER_BACK_FETCH_ITEMS',
  ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS: 'ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS',

  ORDER_TRANSFER_BACK_SAVE_REQUEST: 'ORDER_TRANSFER_BACK_SAVE_REQUEST',
  ORDER_TRANSFER_BACK_SAVE: 'ORDER_TRANSFER_BACK_SAVE',
  ORDER_TRANSFER_BACK_SAVE_SUCCESS: 'ORDER_TRANSFER_BACK_SAVE_SUCCESS',
  ORDER_TRANSFER_BACK_SAVE_ERRORS: 'ORDER_TRANSFER_BACK_SAVE_ERRORS',

  SET_INQUIRY_SHOW_TRANSFER_BACK_FORM: 'SET_INQUIRY_SHOW_TRANSFER_BACK_FORM',
  SET_INQUIRY_SHOW_TRANSFER_BACK_SHIPPING_COST_FORM: 'SET_INQUIRY_SHOW_TRANSFER_BACK_SHIPPING_COST_FORM',

  ORDER_TRANSFER_BACK_CALCULATE_TOTAL_REQUEST: 'ORDER_TRANSFER_BACK_CALCULATE_TOTAL_REQUEST',
  ORDER_TRANSFER_BACK_CALCULATE_TOTAL: 'ORDER_TRANSFER_BACK_CALCULATE_TOTAL',
  ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS: 'ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS',

  RESET_TRANSFER_BACK_ITEMS_SELECTED: 'RESET_TRANSFER_BACK_ITEMS_SELECTED',

  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_REQUEST: 'ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_REQUEST',
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE: 'ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE',
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS: 'ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS',
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS: 'ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS',

  ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_REQUEST: 'ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_REQUEST',
  ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING: 'ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING',

  ORDER_TRANSFER_BACK_DELETE_REQUEST: 'ORDER_TRANSFER_BACK_DELETE_REQUEST',
  ORDER_TRANSFER_BACK_DELETE_SUCCESS: 'ORDER_TRANSFER_BACK_DELETE_SUCCESS',

  fetchOrderTransferBacks: (orderId, { onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACKS_FETCH_REQUEST,
    payload: { orderId, onSuccess, onError }
  }),

  fetchOrderTransferBack: (orderId, id, { onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_FETCH_REQUEST,
    payload: { orderId, id, onSuccess, onError }
  }),

  fetchOrderTransferBackItems: (orderId, id, { onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_FETCH_ITEMS_REQUEST,
    payload: { orderId, id, onSuccess, onError }
  }),

  saveOrderTransferBack: (orderId, id = null, { params, onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_SAVE_REQUEST,
    payload: { orderId, id, params, onSuccess, onError }
  }),

  saveShippingCostTansferBack: (orderId, transferBackId, { id = null, params, onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_REQUEST,
    payload: { orderId, transferBackId, id, params, onSuccess, onError }
  }),

  setOrderTransferBackFormErrors: (formErrors = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_SAVE_ERRORS,
    formErrors
  }),

  setShowTransferBackForm: (value, id) => ({
    type: actions.SET_INQUIRY_SHOW_TRANSFER_BACK_FORM,
    value, id
  }),

  setShowShippingCostForm: (value, id) => ({
    type: actions.SET_INQUIRY_SHOW_TRANSFER_BACK_SHIPPING_COST_FORM,
    value, id
  }),

  culculateTransferBackTotal: (orderId, id, { onSuccess = () => {}, onError = () => {} } = {}) => ({
    type: actions.ORDER_TRANSFER_BACK_CALCULATE_TOTAL_REQUEST,
    payload: { orderId, id, onSuccess, onError}
  }),

  resetTransferBackItemsSelected: () => ({
    type: actions.RESET_TRANSFER_BACK_ITEMS_SELECTED
  }),

  downloadOrderTransferBackDocuments: (orderId, id) => ({
    type: actions.ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_REQUEST,
    payload: { orderId, id }
  }),

  deleteOrderTransferBack: (orderId, id) => ({
    type: actions.ORDER_TRANSFER_BACK_DELETE_REQUEST,
    payload: { orderId, id }
  }),
}

export default actions
