const actions = {
    COMPANIES_SET_MODAL: 'COMPANIES_SET_MODAL',
    COMPANIES_SAVE: 'COMPANIES_SAVE',
    COMPANIES_SAVE_REQUEST: 'COMPANIES_SAVE_REQUEST',
    COMPANIES_SAVE_SUCCESS: 'COMPANIES_SAVE_SUCCESS',
    COMPANIES_SET_FORM_ERRORS: 'COMPANIES_SET_FORM_ERRORS',
    COMPANIES_CLEAR_FORM_ERRORS: 'COMPANIES_CLEAR_FORM_ERRORS',

    COMPANIES_FETCH: 'COMPANIES_FETCH',
    COMPANIES_FETCH_SUCCESS: 'COMPANIES_FETCH_SUCCESS',
    COMPANIES_SEARCH_REQUEST: 'COMPANIES_SEARCH_REQUEST',
    COMPANIES_SET_QUERY: 'COMPANIES_SET_QUERY',
    COMPANIES_SET_OPTIONS: 'COMPANIES_SET_OPTIONS',
    COMPANIES_SET_CLOSE_MODAL: 'COMPANIES_SET_CLOSE_MODAL',
    COMPANIES_FETCH_REQUEST: 'COMPANIES_FETCH_REQUEST',
    COMPANIES_FETCHING: 'COMPANIES_FETCHING',
    COMPANIES_SET_PAGINATION: 'COMPANIES_SET_PAGINATION',
    COMPANIES_LIST_FETCH_SUCCESS: 'COMPANIES_LIST_FETCH_SUCCESS',
    COMPANY_FETCH_REQUEST: 'COMPANY_FETCH_REQUEST',
    COMPANY_FETCH: 'COMPANY_FETCH',
    COMPANY_FETCH_SUCCESS: 'COMPANY_FETCH_SUCCESS',
    COMPANY_ORDERS_FETCH_REQUEST: 'COMPANY_ORDERS_FETCH_REQUEST',
    COMPANY_ORDERS_FETCH: 'COMPANY_ORDERS_FETCH',
    COMPANY_ORDERS_FETCH_SUCCESS: 'COMPANY_ORDERS_FETCH_SUCCESS',
    COMPANY_ORDERS_SET_PAGINATION: 'COMPANY_ORDERS_SET_PAGINATION',

    saveCompany: ({ company, id, onSuccess = () => {} } = {}) => {
        return {
            type: actions.COMPANIES_SAVE_REQUEST,
            payload: { company, id, onSuccess }
        }
    },

    setAddCompanyModal: (value) => ({
        type: actions.COMPANIES_SET_MODAL,
        value
    }),

    searchCompany: (query) => {
        return {
            type: actions.COMPANIES_SEARCH_REQUEST,
            payload: { query }
        }
    },

    setCompanyOption: (query) => {
        return {
            type: actions.COMPANIES_SET_OPTIONS,
            query
        }
    },

    clearCompaniesErrors: () => {
        return {
            type: actions.COMPANIES_CLEAR_FORM_ERRORS
        }
    },

    fetchCompanies: (query = '', page = 1) => {
        return {
            type: actions.COMPANIES_FETCH_REQUEST,
            payload: { query, page }
        }
    },

    fetchCompany: (id) => {
        return {
            type: actions.COMPANY_FETCH_REQUEST,
            payload: { id }
        }
    },
    fetchCompanyOrders: (companyId, page = 1) => {
        return {
            type: actions.COMPANY_ORDERS_FETCH_REQUEST,
            payload: { companyId, page }
        }
    }
}

export default actions
