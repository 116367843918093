import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { Upload, Button, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import accountingAction from '../../redux/orders/accounting/actions'

const { importPaymentVoucherFile } = accountingAction

class ImportPaymentVoucher extends Component {
    state = {
        fileList: []
    }

    handleChange = (info) => {
        let fileList = info.fileList
        fileList = fileList.slice(-1)

        this.setState({ fileList })
    }

    handleUpload = () => {
        const { fileList } = this.state
        const { importPaymentVoucherFile } = this.props

        const file = fileList[0].originFileObj
        importPaymentVoucherFile(file)

        this.setState({
            fileList: []
        })
    }

    renderCardHead = () => {
        const { i18n } = this.props

        return (
            <Card.Meta
                title={i18n.t('documents:paymentVoucherReport.upload')}
                description={i18n.t('documents:paymentVoucherReport.uploadDescription')} />
        )
    }

    render () {
        const { orderAccountings, i18n } = this.props
        const { fileList } = this.state
        const uploading = orderAccountings.get('uploading')

        const props = {
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }))
                return false
            },
            fileList
        }

        return (
            <Card title={this.renderCardHead()} style={{ marginBottom: '20px' }}>
                <Upload {...props}
                    key="upload_input"
                    onChange={this.handleChange}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                >
                    <Button>
                        <UploadOutlined />
                        {i18n.t('documents:selectFile')}
                    </Button>
                </Upload>
                <Button
                    key="upload_btn"
                    type="primary"
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }} >
                    {uploading ? i18n.t('documents:rvReport.uploadingRV') :
                        i18n.t('documents:rvReport.uploadRV')}
                </Button>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    orderAccountings: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        importPaymentVoucherFile
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('documents')(ImportPaymentVoucher))
