import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import JournalEntriesIndexByDateContainer from './JournalEntriesIndexByDateContainer'

class JournalEntryShowPage extends React.Component {
    componentDidMount() {
        document.title = 'Journal Entry | OSH CRM'
    }

    render () {
      const { match } = this.props
      const { date } = match.params
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/journal_entries', name: 'Journal Entries' },
                        { path: `/journal_entries/${date}`, name: date }
                    ]}>
                    <ContentLayout>
                      <JournalEntriesIndexByDateContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}
export default JournalEntryShowPage
