export const STATUS_TAG_PROPS = {
  null: { color: 'blue' },
  success: { color: 'cyan' },
  fail: { color: 'red' }
}

export const STATUS_TEXT_PROPS = {
  null: { color: '#1890ff' },
  success: { color: '#13c2c2' },
  fail: { color: '#f5222d' },
  potential: { color: '#faad14' },
  other: {}
}
