import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List } from 'immutable'

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import CardReservation from '../../components/TruckReservations/CardReservation'

import actions from '../../redux/truckReservations/actions'

const { fetchTruckReservations, setUpdatingId } = actions

class ReservationContainer extends Component {
    static propTypes = {
        // inner props
        fetchTruckReservations: PropTypes.func.isRequired,
        setUpdatingId: PropTypes.func.isRequired,
        truckReservationEntities: ImmutablePropTypes.map.isRequired,
        truckReservations: ImmutablePropTypes.map.isRequired,

        //  outer props
        orderNumber: PropTypes.string.isRequired,
        onToggle: PropTypes.func.isRequired
    }

    static defaultProps = {
        onToggle: () => {}
    }

    componentDidMount () {
        const { fetchTruckReservations, orderNumber } = this.props
        fetchTruckReservations({ orderId: orderNumber })
    }

    componentWillUnmount () {
        const { setUpdatingId } = this.props
        setUpdatingId()
    }

    getTruckReservations () {
        const { truckReservations, truckReservationEntities } = this.props
        const truckReservationIds = truckReservations.get('items') || new List()
        return truckReservationIds.map((id) => truckReservationEntities.get(id))
    }

    handleToggle = (isToggle, shippingCostId) => {
        if (!isToggle) return null

        this.props.onToggle(shippingCostId && shippingCostId.toString())
    }

    setUpdatingId = (updatingId) => {
        const { setUpdatingId } = this.props
        setUpdatingId({ updatingId })
    }

    render () {
        return (
            <CardReservation
                onToggle={this.handleToggle}
                setUpdatingId={this.setUpdatingId}
                truckReservations={this.getTruckReservations()}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        truckReservationEntities: state.getIn(['Entities', 'truckReservations']),
        truckReservations: state.get('truckReservations')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTruckReservations,
        setUpdatingId
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationContainer)
