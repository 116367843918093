import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Form, Select, Card, Button, Modal, Spin } from 'antd'
import { PrinterOutlined, EditTwoTone } from '@ant-design/icons'
import DropdownPdfPreview from '../../containers/Orders/DropdownPdfPreview'

import accountingActions from '../../redux/orders/accounting/actions'
import inquiryActions from '../../redux/inquiry/actions'

const { setShowIssueTaxInvoiceForm } = inquiryActions
const { fetchTaxInvoicePdf } = accountingActions

class IssueTaxInvoiceForm extends Component {
    handleEditIssueTaxInvoice = () => {
        const { setShowIssueTaxInvoiceForm, showForm } = this.props

        setShowIssueTaxInvoiceForm(!showForm)
    }

    handleDownloadInvoice = (orderId, id, taxInvoiceType, locale, isSample) => {
        const { fetchTaxInvoicePdf } = this.props

        fetchTaxInvoicePdf(orderId, id, taxInvoiceType, locale.key, isSample)
    }

    renderEditIcon = () => {
        const { editable, orderEntity } = this.props

        if (!editable) {
            return
        }
        if (!orderEntity.get('sale')) {
            return
        }

        return (
            <EditTwoTone
                className='edit-icon'
                onClick={this.handleEditIssueTaxInvoice}
            />
        )
    }

    renderIssueTaxInvoice = () => {
        const { i18n, orderEntity, showForm, orderId, accountings } = this.props
        const taxInvoice = orderEntity.get('order_business_addresses').toJS()
        const taxText = i18n.t('confirms:invoice.taxInvoice')
        const invoiceLoading = accountings.get('invoiceLoading')
        let id = ''
        if (taxInvoice.length !== 0) {
            id = orderEntity.get('order_business_addresses').toJS()[0].id
        }

        return (
            <div>
                <span className='title'>
                    {`${i18n.t('order.issueTaxInvoice')}`} : &nbsp;
                    {orderEntity.get('issue_invoice') ? '' : '-'}
                    {this.renderEditIcon()}
                </span>
                <p>
                    {i18n.t(
                        `order.${_.camelCase(orderEntity.get('issue_invoice'))}`
                    )}
                </p>
                {showForm ? this.editIssueTaxInvoice() : null}
                {taxInvoice.length === 0 ? null : (
                    <div>
                        <DropdownPdfPreview
                            icon={<PrinterOutlined />}
                            onClick={(locale) =>
                                this.handleDownloadInvoice(
                                    orderId,
                                    id,
                                    'tax_invoice',
                                    locale,
                                    true
                                )
                            }
                            size='small'
                            style={{ margin: '2px' }}
                            text={taxText}
                            loading={accountings.get('invoiceLoading')}
                        />
                        <Modal
                            style={{
                                height: '100px',
                                textAlign: 'center',
                                verticalAlign: 'middle'
                            }}
                            centered
                            width='100px'
                            visible={invoiceLoading}
                            closable={false}
                            footer={null}>
                            <Spin spinning={invoiceLoading} />
                        </Modal>
                    </div>
                )}
            </div>
        )
    }

    editIssueTaxInvoice = () => {
        const { i18n, onSubmitForm, saving, orderEntity } = this.props

        return (
            <Form
                onFinish={(values) =>
                    onSubmitForm(values, {
                        onSuccess: this.handleEditIssueTaxInvoice
                    })
                }>
                <Card
                    className='card-margin'
                    bodyStyle={{ padding: '8px' }}
                    style={{ marginTop: 12 }}>
                    <label className='title'>
                        {i18n.t('order.issueTaxInvoice')}
                    </label>

                    <Form.Item
                        name='issue_invoice'
                        initialValue={
                            orderEntity.get('issue_invoice') || undefined
                        }>
                        <Select allowClear style={{ width: '100%' }}>
                            <Select.Option
                                key='item_arrived'
                                value='item_arrived'>
                                {i18n.t('order.itemArrived')}
                            </Select.Option>
                            <Select.Option key='paid' value='paid'>
                                {i18n.t('order.paid')}
                            </Select.Option>
                            <Select.Option
                                key='do_not_specified'
                                value='do_not_specified'>
                                {i18n.t('order.doNotSpecified')}
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    <div style={{ textAlign: 'center' }}>
                        <Button
                            className='btn-edit'
                            type='primary'
                            size='small'
                            loading={saving}
                            htmlType='submit'>
                            {i18n.t('inquiries:edit')}
                        </Button>
                        <Button
                            className='btn-edit'
                            size='small'
                            onClick={this.handleEditIssueTaxInvoice}>
                            {i18n.t('inquiries:cancel')}
                        </Button>
                    </div>
                </Card>
            </Form>
        )
    }

    render() {
        const { showForm } = this.props

        return showForm
            ? this.editIssueTaxInvoice()
            : this.renderIssueTaxInvoice()
    }
}

const mapStateToProps = (state) => ({
    accountings: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setShowIssueTaxInvoiceForm,
            fetchTaxInvoicePdf
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(IssueTaxInvoiceForm))
