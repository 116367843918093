import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch, apiPostForm } from '../../helpers/restRequest'
import _ from 'lodash'
import actions from './actions'
import orderTableActions from './tables/actions'
import purchaseOrderActions from '../purchaseOrders/actions'
import actionsNotification from '../../redux/notifications/actions'
import supplierOrderActions from '../../redux/supplierOrders/actions'
import ordersActions from '../../redux/orders/actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { PAGE_SIZE } from '../../constants/orders'

import {
    orderSchema,
    ordersSchema,
    customerServicesSchema,
    salesSchema,
    orderStatesSchema,
    shippingCostSchema,
    shippingCostsSchema,
    orderSupplierNoteSchema,
    orderSupplierNotesSchema,
    purchasersSchema,
    shippingRateSchema
} from '../schema'

const orderPath = (orderType, queryString = '') => {
    if (orderType === 'all') {
        return `/orders?${queryString}`
    } else if (orderType === 'distribution') {
        return `/orders/distributions?${queryString}`
    }
}

const orderShippingCostPath = (orderId, id) => {
    const path = `/orders/${orderId}/shipping_costs`

    if (!id) { return path }
    return `/${path}/${id}`
}

const { setNotifications } = actionsNotification
const { setPagination } = purchaseOrderActions
const { setShippingRatePrice } = ordersActions

const {
    ORDER_TABLES_SALE_FETCH_REQUEST,
    ORDER_TABLES_SALE_FETCHING_REQUEST,
    ORDER_TABLES_SALE_FETCH_SUCCESS,
    ORDER_TABLES_CS_FETCH_REQUEST,
    ORDER_TABLES_CS_FETCHING_REQUEST,
    ORDER_TABLES_CS_FETCH_SUCCESS,
    ORDER_TABLES_PURCHASES_FETCH_REQUEST,
    ORDER_TABLES_PURCHASES_FETCHING,
    ORDER_TABLES_PURCHASES_FETCH_SUCCESS
} = orderTableActions

const { SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL } = supplierOrderActions

const {
    CHECK_ORDER_VOUCHERS_REQUEST,
    CHECK_ORDER_VOUCHERS_SUCCESS,
    CHECK_ORDER_VOUCHERS,
    FETCH_ORDER_SHIPPING_COSTS_REQUEST,
    FETCH_ORDER_SHIPPING_COSTS_SUCCESS,
    FETCH_ORDER_SHIPPING_COSTS,
    ORDER_FETCH_REQUEST,
    ORDER_FETCH_SUCCESS,
    ORDER_FETCH,
    ORDER_REDO_SALE,
    ORDER_SET_LOADING,
    ORDER_SHIPPING_COST_DELETE_REQUEST,
    ORDER_SHIPPING_COST_DELETE_SUCCESS,
    ORDER_SHIPPING_COST_FORM_ERRORS,
    ORDER_SHIPPING_COST_SAVE_REQUEST,
    ORDER_SHIPPING_COST_SAVE_SUCCESS,
    ORDER_SHIPPING_COST_SAVING,
    ORDER_SUPPLIER_NOTE_FETCH_REQUEST,
    ORDER_SUPPLIER_NOTE_FETCH_SUCCESS,
    ORDER_SUPPLIER_NOTE_FETCH,
    ORDER_SUPPLIER_NOTE_SAVE_REQUEST,
    ORDER_SUPPLIER_NOTE_SAVE_SUCCESS,
    ORDER_SUPPLIER_NOTE_SAVING,
    ORDER_SUPPLIER_NOTES_FETCH_REQUEST,
    ORDER_SUPPLIER_NOTES_FETCH_SUCCESS,
    ORDER_SUPPLIER_NOTES_FETCH,
    ORDER_UPDATE_SALE_DISTRIBUTION,
    ORDER_UPDATE_SALE_REQUEST,
    ORDER_UPDATE_SALE_SUCCESS,
    ORDERS_CSES_FETCH_SUCCESS,
    ORDERS_CSES_FETCH,
    ORDERS_CSES_SEARCH_REQUEST,
    ORDERS_CSES_SET_QUERY,
    ORDERS_FETCH_REQUEST,
    ORDERS_FETCH_SUCCESS,
    ORDERS_FETCHING,
    ORDERS_ORDER_STATES_FETCH_REQUEST,
    ORDERS_ORDER_STATES_FETCH_SUCCESS,
    ORDERS_ORDER_STATES_FETCH,
    ORDERS_PURCHASERS_FETCH_SUCCESS,
    ORDERS_PURCHASERS_FETCH,
    ORDERS_PURCHASERS_SET_QUERY,
    ORDERS_PURCHASES_SEARCH_REQUEST,
    ORDERS_SALES_FETCH_SUCCESS,
    ORDERS_SALES_FETCH,
    ORDERS_SALES_SEARCH_REQUEST,
    ORDERS_SALES_SET_QUERY,
    ORDERS_SAVE_REQUEST,
    ORDERS_SAVE_SUCCESS,
    ORDERS_SET_FORM_ERRORS,
    ORDERS_SET_SAVING,
    ORDERS_SET_TOTAL,
    SAVE_ORDER_COMPLETE_NOTE_REQUEST,
    SAVE_ORDER_COMPLETE_NOTE_SUCCESS,
    SAVE_ORDER_COMPLETE_NOTE,
    SAVE_ORDER_DO_NOT_TRANSFER_REQUEST,
    SAVE_ORDER_DO_NOT_TRANSFER,
    SAVE_ORDER_DO_NOT_TRANSFER_SUCCESS,
    SET_ORDER_SUPPLIER_NOTE_FORM_MODAL,
    SET_SHIPPING_COST_MODAL,
    FETCH_ORDER_SHIPPING_RATE_REQUEST,
    FETCH_ORDER_SHIPPING_RATE,
    FETCH_ORDER_SHIPPING_RATE_SUCCESS,
    FETCH_ORDER_LINE_ITEM_IDS_REQUEST,
    FETCH_ORDER_LINE_ITEM_IDS,
    FETCH_ORDER_LINE_ITEM_IDS_SUCCESS
} = actions

export function *receiveOrders () {
    yield takeLatest(ORDERS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDERS_FETCHING })

        const { queryObj, orderType } = payload
        const path = orderPath(orderType, queryObj)
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, ordersSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: ORDERS_SET_TOTAL,
            total
        })

        yield put(setPagination({ total }))

        yield put({
            type: ORDERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *searchCustomerServicesRequest () {
    yield takeEvery(ORDERS_CSES_SEARCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const queryString = `query=${query}`

        if (query === '') { return null }

        yield put({ type: ORDERS_CSES_SET_QUERY, query })

        yield put({ type: ORDERS_CSES_FETCH })

        const data = yield call(apiFetch, `/cses?only_admin=true&${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerServicesSchema)

        yield put({
            type: ORDERS_CSES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *searchPurchasersRequest () {
    yield takeEvery(ORDERS_PURCHASES_SEARCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const queryString = `query=${query}`

        if (query === '') { return null }

        yield put({ type: ORDERS_PURCHASERS_SET_QUERY, query })

        yield put({ type: ORDERS_PURCHASERS_FETCH })

        const data = yield call(apiFetch, `/purchasers?only_admin=true&${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, purchasersSchema)
        yield put({
            type: ORDERS_PURCHASERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *searchSalesRequest () {
    yield takeEvery(ORDERS_SALES_SEARCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const queryString = `query=${query}`

        if (query === '') { return null }

        yield put({ type: ORDERS_SALES_SET_QUERY, query })

        yield put({ type: ORDERS_SALES_FETCH })

        const data = yield call(apiFetch, `/sales?only_admin=true&${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, salesSchema)

        yield put({
            type: ORDERS_SALES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

// export function *updateSale () {
//     yield takeLatest(ORDER_UPDATE_SALE_REQUEST, function *({ payload }) {
//         const id = payload.orderNumber
//         const path = `/orders/${id}`
//         const method = 'PATCH'

//         const body = JSON.stringify({ order: payload.values })

//         try {
//             const data = yield call(apiFetch, path, method, { body })

//             const formattedData = deserialize(data)

//             yield put({
//                 type: ORDER_UPDATE_SALE_SUCCESS,
//                 ...normalize(formattedData, orderSchema)
//             })

//             const values = payload.values
//             if ('sale_id' in values) {
//                 const type = payload.type
//                 if (type === 'redo') {
//                     yield put({
//                         type: ORDER_REDO_SALE,
//                         orderId: payload.orderNumber
//                     })
//                 } else {
//                     yield put({
//                         type: ORDER_UPDATE_SALE_DISTRIBUTION,
//                         orderId: payload.orderNumber
//                     })
//                 }
//             }
//             yield put(setNotifications('success', 'saveSuccess', 'success'))
//         } catch (error) {
//             const errorName = error.name
//             const errorMessage = error.message
//             yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
//         }
//     })
// }

export function *submitOrder () {
    yield takeLatest(ORDERS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDERS_SET_SAVING, saving: true })

        const id = payload.id
        const method = 'POST'
        const path = `/customers/${id}/orders`

        const body = JSON.stringify({ order: payload.order })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: ORDERS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const { redirectToInquiryPage, onSuccess } = payload

                const formattedData = deserialize(data)

                yield put({
                    type: ORDERS_SAVE_SUCCESS,
                    ...normalize(formattedData, orderSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                if (redirectToInquiryPage) {
                    setTimeout(() => location.replace(`/inquiries/${formattedData.id}`), 200)
                }

                onSuccess({ orderId: formattedData.id })
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: ORDERS_SET_SAVING,
                saving: false
            })
        }
    })
}

export function *orderReceiveOrderState () {
    yield takeLatest(ORDERS_ORDER_STATES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDERS_ORDER_STATES_FETCH })

        const { allState } = payload

        const path = `/orders/states?all_state=${allState}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderStatesSchema)

        yield put({
            type: ORDERS_ORDER_STATES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchOrderShippingCost () {
    yield takeEvery(FETCH_ORDER_SHIPPING_COSTS_REQUEST, function *({ payload }) {
        const { id, onSuccess } = payload

        yield put({ type: FETCH_ORDER_SHIPPING_COSTS })

        const data = yield call(apiFetch, `/orders/${id}/shipping_costs`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, shippingCostsSchema)

        yield put({
            type: FETCH_ORDER_SHIPPING_COSTS_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export function *submitOrderShippingCost () {
    yield takeLatest(ORDER_SHIPPING_COST_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SHIPPING_COST_SAVING, saving: true })

        const { orderId, id } = payload
        const path = orderShippingCostPath(orderId, id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ shipping_cost: payload.parameters })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: ORDER_SHIPPING_COST_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: ORDER_SHIPPING_COST_SAVE_SUCCESS,
                    ...normalize(formattedData, shippingCostSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: ORDER_SHIPPING_COST_SAVING, saving: false })
        }
    })
}

export function *deleteShippingCost () {
    yield takeLatest(ORDER_SHIPPING_COST_DELETE_REQUEST, function *({ payload }) {
        const orderId = payload.orderId
        const id = payload.id
        const path = orderShippingCostPath(orderId, id)
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: ORDER_SHIPPING_COST_DELETE_SUCCESS,
                ...normalize(formattedData, shippingCostSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *receiveOrder () {
    yield takeEvery(ORDER_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_FETCH })
        yield put({ type: ORDER_SET_LOADING, loading: true })
        const { orderId } = payload
        const path = `/orders/${orderId}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderSchema)

            yield put({
                type: ORDER_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: ORDER_SET_LOADING, loading: false })
        }
    })
}

export function *fetchSales () {
    yield takeEvery(ORDER_TABLES_SALE_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_TABLES_SALE_FETCHING_REQUEST })
        const all = payload.all ? 'true' : 'false'

        const data = yield call(apiFetch, `/sales?all=${all}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, salesSchema)

        yield put({
            type: ORDER_TABLES_SALE_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchCses () {
    yield takeEvery(ORDER_TABLES_CS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_TABLES_CS_FETCHING_REQUEST })
        const all = payload.all ? 'true' : 'false'

        const data = yield call(apiFetch, `/cses?all=${all}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerServicesSchema)

        yield put({
            type: ORDER_TABLES_CS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchPurchasers () {
    yield takeEvery(ORDER_TABLES_PURCHASES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_TABLES_PURCHASES_FETCHING })
        const all = payload.all ? 'true' : 'false'


        const data = yield call(apiFetch, `/purchasers?all=${all}&only_admin=true`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, purchasersSchema)
        yield put({
            type: ORDER_TABLES_PURCHASES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitOrderSupplierNote () {
    yield takeLatest(ORDER_SUPPLIER_NOTE_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SUPPLIER_NOTE_SAVING, saving: true })

        const { orderId, values, id, onSuccess } = payload
        const method = id ? 'PATCH' : 'POST'

        let path = `/orders/${orderId}/supplier_notes`
        if (id) { path += `/${id}` }

        try {
            const data = yield call(apiPostForm, path, method, { order_supplier_note: values }, [], ['supplier_order_ids'])

            const formattedData = deserialize(data)

            yield put({
                type: ORDER_SUPPLIER_NOTE_SAVE_SUCCESS,
                ...normalize(formattedData, orderSupplierNoteSchema)
            })

            onSuccess()
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: ORDER_SUPPLIER_NOTE_SAVING,
                saving: false
            })
            yield put({
                type: SET_ORDER_SUPPLIER_NOTE_FORM_MODAL,
                value: false
            })
            yield put({
                type: SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL,
                value: false
            })
        }
    })
}

export function *fetchOrderSupplierNotes () {
    yield takeEvery(ORDER_SUPPLIER_NOTES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_SUPPLIER_NOTES_FETCH })

        const data = yield call(apiFetch, `/orders/${payload.orderId}/supplier_notes`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, orderSupplierNotesSchema)

        yield put({
            type: ORDER_SUPPLIER_NOTES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

// export function *fetchOrderSupplierNote () {
//     yield takeEvery(ORDER_SUPPLIER_NOTE_FETCH_REQUEST, function *({ payload }) {
//         yield put({ type: ORDER_SUPPLIER_NOTE_FETCH })

//         const { orderId, id } = payload
//         const data = yield call(apiFetch, `/orders/${orderId}/supplier_notes/${id}`)

//         const formattedData = deserialize(data)

//         const normalizeData = normalize(formattedData, orderSupplierNoteSchema)

//         yield put({
//             type: ORDER_SUPPLIER_NOTE_FETCH_SUCCESS,
//             ...normalizeData
//         })
//     })
// }

export function *checkOrderVouchers () {
    yield takeLatest(CHECK_ORDER_VOUCHERS_REQUEST, function *({ payload }) {
        yield put({ type: CHECK_ORDER_VOUCHERS, saving: true })

        const { orderId, value } = payload
        const path = `/orders/${orderId}/voucher_checks`
        const method = 'PATCH'

        const body = JSON.stringify({ order_voucher_check: value })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderSchema)

            yield put({
                type: CHECK_ORDER_VOUCHERS_SUCCESS,
                ...normalizeData
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CHECK_ORDER_VOUCHERS, saving: false })
        }
    })
}

export function *submitOrderCompleteNote () {
    yield takeLatest(SAVE_ORDER_COMPLETE_NOTE_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_ORDER_COMPLETE_NOTE, saving: true })

        const { orderId, values, onSuccess } = payload
        const path = `/orders/${orderId}`
        const method = 'PATCH'
        const body = JSON.stringify({ order: values })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_ORDER_COMPLETE_NOTE_SUCCESS,
                ...normalize(formattedData, orderSchema)
            })

            onSuccess()
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SAVE_ORDER_COMPLETE_NOTE, saving: false })
        }
    })
}

export function *submitDoNotTransfer () {
    yield takeLatest(SAVE_ORDER_DO_NOT_TRANSFER_REQUEST, function *({ payload }) {
        const { orderId, values, onSuccess } = payload
        const path = `/orders/${orderId}`
        const method = 'PATCH'
        const body = JSON.stringify({ order: values })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_ORDER_DO_NOT_TRANSFER_SUCCESS,
                ...normalize(formattedData, orderSchema)
            })

            onSuccess()
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchOrderShippingRate () {
    yield takeEvery(FETCH_ORDER_SHIPPING_RATE_REQUEST, function *({ payload }) {
        const { orderId, vehicleTypeId, onSuccess } = payload

        yield put({ type: FETCH_ORDER_SHIPPING_RATE })
        try {
            const data = yield call(apiFetch, `/order_shipping_rates/${orderId}?vehicle_type_id=${vehicleTypeId}`)
            if (data.status === 'not_found') {
                yield put(setShippingRatePrice(null))
            } else {
                const formattedData = deserialize(data)

                const normalizeData = normalize(formattedData, shippingRateSchema)

                yield put({
                    type: FETCH_ORDER_SHIPPING_RATE_SUCCESS,
                    ...normalizeData
                })

                onSuccess(formattedData.price)
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *getOrderLineItemIds () {
    yield takeEvery(FETCH_ORDER_LINE_ITEM_IDS_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_ORDER_LINE_ITEM_IDS })

        const data = yield call(apiFetch, `/orders/${payload.orderId}/get_line_item_ids`)

        yield put({
            type: FETCH_ORDER_LINE_ITEM_IDS_SUCCESS,
            data
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(checkOrderVouchers),
        fork(deleteShippingCost),
        fork(fetchCses),
        fork(fetchOrderShippingCost),
        // fork(fetchOrderSupplierNote),
        fork(fetchOrderSupplierNotes),
        fork(fetchSales),
        fork(orderReceiveOrderState),
        fork(receiveOrder),
        fork(receiveOrders),
        fork(searchCustomerServicesRequest),
        fork(searchPurchasersRequest),
        fork(searchSalesRequest),
        fork(submitOrder),
        fork(submitOrderCompleteNote),
        fork(submitDoNotTransfer),
        fork(submitOrderShippingCost),
        fork(submitOrderSupplierNote),
        // fork(updateSale),
        fork(fetchOrderShippingRate),
        fork(fetchPurchasers),
        fork(getOrderLineItemIds)
    ])
}
