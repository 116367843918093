import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import _ from 'lodash'

import { Select } from 'antd'
import InvoiceForm from '../../components/Invoices/invoiceForm'
import InvoiceAddressForm from '../../components/Invoices/invoiceAddressForm'
import OrderInvoiceWrapper from './orderInvoice.style'

import accountingActions from '../../redux/orders/accounting/actions'
import businessesActions from '../../redux/customers/addresses/businesses/actions'
import mailingActions from '../../redux/customers/addresses/mailing/actions'
import inquiryActions from '../../redux/inquiry/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    saveOrderBusinessAddress,
    setShowBusinessAddressModal,
    setShowMailingAddressForm,
    setShowMailingAddressModal,
    setInvoiceType,
    setTaxInvoiceBusinessErrors
} = accountingActions

const { fetchBusinessAddresses } = businessesActions
const { fetchMailingAddresses } = mailingActions
const { saveOrderForm } = inquiryActions
const { setNotifications } = notificationActions

class OrderInvoiceFormContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        orderId: PropTypes.string.isRequired,
        onlyInvoiceForm: PropTypes.bool,

        accountings: ImmutablePropTypes.map.isRequired,
        businessAddressEntities: ImmutablePropTypes.map.isRequired,
        businessAddresses: ImmutablePropTypes.map.isRequired,
        fetchBusinessAddresses: PropTypes.func.isRequired,
        fetchMailingAddresses: PropTypes.func.isRequired,
        mailingAddressEntities: ImmutablePropTypes.map.isRequired,
        mailingAddresses: ImmutablePropTypes.map.isRequired,
        orderEntities: ImmutablePropTypes.map.isRequired,
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        saveOrderForm: PropTypes.func.isRequired,
        setShowBusinessAddressModal: PropTypes.func.isRequired,
        setShowMailingAddressForm: PropTypes.func.isRequired,
        setShowMailingAddressModal: PropTypes.func.isRequired
    }

    componentDidMount () {
        const { customerId,
            fetchBusinessAddresses,
            fetchMailingAddresses,
            orderEntities,
            orderId,
            setShowMailingAddressForm
        } = this.props
        const orderMailingAddress = orderEntities.getIn([orderId, 'order_mailing_address']) || new Map()

        setShowMailingAddressForm(!_.isEmpty(orderMailingAddress.toJS()))
        fetchBusinessAddresses(customerId)
        fetchMailingAddresses(customerId)
    }

    setCloseModal = () => {
        const { setNotifications, setShowMailingAddressModal } = this.props
        setNotifications('success', 'saveSuccess', 'success')
        setShowMailingAddressModal(false)
    }

    getBusinessAddressOptions () {
        const { businessAddressEntities, businessAddresses } = this.props

        return businessAddresses.get('items').map((id) => {
            const businessAddress = (businessAddressEntities.get(id) || new Map()).toJS()
            const option = [
                _.get(businessAddress, 'tax_number', ''),
                _.get(businessAddress, 'company_name', ''),
                _.get(businessAddress, 'branch', ''),
                _.get(businessAddress, 'address_detail', ''),
                _.get(businessAddress, 'district.name', ''),
                _.get(businessAddress, 'sub_district', ''),
                _.get(businessAddress, 'province.name', ''),
                _.get(businessAddress, 'postal_code', '')
            ].filter(Boolean).join(' ')

            return (
                <Select.Option key={`business-address-${id}`} value={id}>{option}</Select.Option>
            )
        })
    }

    getOrderMailingAddressOptions () {
        const { mailingAddressEntities, mailingAddresses } = this.props

        return mailingAddresses.get('items').map((id) => {
            const mailingAddress = (mailingAddressEntities.get(id) || new Map()).toJS()
            const option = [
                _.get(mailingAddress, 'name', ''),
                _.get(mailingAddress, 'address', ''),
                _.get(mailingAddress, 'sub_district', ''),
                _.get(mailingAddress, 'district.name', ''),
                _.get(mailingAddress, 'province.name', ''),
                _.get(mailingAddress, 'postal_code', '')
            ].filter(Boolean).join(' ')

            return (
                <Select.Option key={`mailing-address-${id}`} value={id}>{option}</Select.Option>
            )
        })
    }

    getOrderMailingAddress () {
        const { orderEntities, orderId } = this.props
        const order = orderEntities.get(orderId) || new Map()

        return (order.get('order_mailing_address') || new Map()).toJS()
    }

    handleInvoiceType = (value) => {
        const { setInvoiceType } = this.props

        setInvoiceType(value)
    }

    handleChangeMailingAddress = ({ values, visible, checked }) => {
        const { setShowMailingAddressForm } = this.props

        setShowMailingAddressForm(!visible)

        if (checked) { this.handleSubmitInvoiceAddressForm(values) }
    }

    handleShowAddBusinessAddressForm = (visible) => {
        const { setShowBusinessAddressModal } = this.props

        setShowBusinessAddressModal(!visible)
    }

    handleShowAddMailingAddressForm = (visible) => {
        const { setShowMailingAddressModal } = this.props

        setShowMailingAddressModal(!visible)
    }

    handleSubmitInvoiceAddressForm = (values) => {
        const { customerId, mailingAddressEntities, orderId, saveOrderForm } = this.props
        const mailingAddressId = values['order_mailing_address_attributes']['mailing_address_id']
        const mailingAddress = (mailingAddressEntities.get(mailingAddressId) || new Map()).toJS()

        const params = {
            ...values,
            order_mailing_address_attributes: {
                name: _.get(mailingAddress, 'name', null),
                address: _.get(mailingAddress, 'address', null),
                mailing_address_id: mailingAddressId,
                province_id: _.get(mailingAddress, 'province.id', null),
                district_id: _.get(mailingAddress, 'district.id', null),
                sub_district: _.get(mailingAddress, 'sub_district', null),
                postal_code: _.get(mailingAddress, 'postal_code', null),
                customer_customer_no: customerId,
                user_id: _.get(mailingAddress, 'user.id', null)
            }
        }

        saveOrderForm(orderId, params)
    }

    getBusinessType = (invoiceType) => {
        if (invoiceType === 'abb') {
            return 'tax_inv_abb'
        } else if (invoiceType === 'outside') {
            return 'tax_inv_outside'
        } else return null

    }

    handleSubmitInvoiceForm = ({ values, onSuccess }) => {
        const { businessAddressEntities, orderId, saveOrderBusinessAddress, setTaxInvoiceBusinessErrors } = this.props
        const businessAddress = (businessAddressEntities.get(values['business_address_id']) || new Map()).toJS()

        const valueWithoutUndefined = {}
        Object.entries(values).map((field) => {
            const fieldName = field[0]
            const fieldValue = field[1]

            valueWithoutUndefined[fieldName] = (fieldValue === undefined) ? null : fieldValue
        })

        const invoiceDate = values['invoice_date'] ? values['invoice_date'].format('YYYY-MM-DD') : null
        const paymentDate = values['payment_date'] ? values['payment_date'].format('YYYY-MM-DD') : null
        const businessType = this.getBusinessType(values['invoice_type']) || _.get(businessAddress, 'business_type', null)

        const params = {
            ...valueWithoutUndefined,
            address_detail: _.get(businessAddress, 'address_detail', null),
            branch: _.get(businessAddress, 'branch', null),
            company_name: _.get(businessAddress, 'company_name', null),
            business_type: businessType,
            district_id: _.get(businessAddress, 'district.id', null),
            invoice_date: invoiceDate,
            payment_date: paymentDate,
            postal_code: _.get(businessAddress, 'postal_code', null),
            province_id: _.get(businessAddress, 'province.id', null),
            sub_district: _.get(businessAddress, 'sub_district', null),
            mailing_address_type: _.get(businessAddress, 'mailing_address_type', null),
            tax_number: _.get(businessAddress, 'tax_number', null),
            hide_detail_information: _.get(businessAddress, 'hide_detail_information', null)
        }

        saveOrderBusinessAddress({ address: params, orderId, onSuccess: onSuccess, onError: setTaxInvoiceBusinessErrors })
    }

    render () {
        const { accountings, businessAddresses, customerId, mailingAddresses, orderId, onlyInvoiceForm, orderEntities } = this.props
        const order = orderEntities.get(orderId) || new Map()

        return (
            <OrderInvoiceWrapper>
                <InvoiceForm
                    businessAddressOptions={this.getBusinessAddressOptions()}
                    customerId={customerId}
                    onShowAddBusinessAddressForm={this.handleShowAddBusinessAddressForm}
                    onSubmit={this.handleSubmitInvoiceForm}
                    optionsLoading={businessAddresses.get('loading')}
                    needTaxInvoiceAbb={order.get('need_tax_inv_abb', false)}
                    saving={accountings.get('saving')}
                    showAddBusinessAddressForm={accountings.get('addBusinessAddressModal')}
                    onCheckInvoiceType={this.handleInvoiceType}
                    invoiceType={accountings.get('invoiceType')}
                    errors={accountings.get('orderBusinessAddressFormErrors')} />
                {onlyInvoiceForm ? null : (
                    <InvoiceAddressForm
                        customerId={customerId}
                        setCloseMailingModal={this.setCloseModal}
                        mailingAddressOptions={this.getOrderMailingAddressOptions()}
                        onChangeMailingAddress={this.handleChangeMailingAddress}
                        onShowAddMailingAddressForm={this.handleShowAddMailingAddressForm}
                        onSubmit={this.handleSubmitInvoiceAddressForm}
                        optionsLoading={mailingAddresses.get('loading')}
                        orderMailingAddress={this.getOrderMailingAddress()}
                        saving={accountings.get('saving')}
                        showAddMailingAddressForm={accountings.get('addMailingAddressModal')}
                        showMailingAddressForm={accountings.get('showMailingAddressForm')} />
                )}
            </OrderInvoiceWrapper>
        )
    }
}

const mapStateToProps = state => ({
    accountings: state.get('orderPaymentDetails'),
    businessAddressEntities: state.getIn(['Entities', 'businessAddresses']),
    businessAddresses: state.get('businessAddresses'),
    mailingAddressEntities: state.getIn(['Entities', 'mailingAddresses']),
    mailingAddresses: state.get('mailingAddresses'),
    orderEntities: state.getIn(['Entities', 'orders'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBusinessAddresses,
        fetchMailingAddresses,
        saveOrderBusinessAddress,
        saveOrderForm,
        setShowBusinessAddressModal,
        setShowMailingAddressForm,
        setShowMailingAddressModal,
        setInvoiceType,
        setNotifications,
        setTaxInvoiceBusinessErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInvoiceFormContainer)
