import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { List } from 'immutable'

import { Input, Row, Col, Form, Button, Select } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import ContactsFormWrapper from './contactsForm.style'

import actionCustomer from '../../../redux/customers/actions'

import {
    EMAILS_TYPE,
    PHONE_NUMBERS_TYPE,
    LINES_TYPE
} from '../../../constants/customers'

const {
    setEmailFormAmount,
    setPhoneFormAmount,
    setLineFormAmount
} = actionCustomer

class CustomerContactSFormContainer extends Component {
    renderPhoneForm = () => {
        const { customers, i18n } = this.props
        const formErrors = customers.get('formErrors')
        const showPhoneType = PHONE_NUMBERS_TYPE.map((number, index) => {
            return (
                <Select.Option value={number.value} key={index}>
                    {i18n.t(`customerForm.${number.i18n}`)}
                </Select.Option>
            )
        })

        const customerPhoneNumberBodyErrors = formErrors.get('customer_phone_numbers.body')
        const customerPhoneNumberBodyTypeErrors = formErrors.get('customer_phone_numbers.body_type')

        return (
            <Form.List name="customer_phone_numbers_attributes">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Row key={key} type="flex" justify="space-between">
                            <Col md={{ span: 11 }}>
                                <Form.Item label={i18n.t('customerForm.phone')}
                                    {...restField}
                                    name={[name, 'body']}
                                    fieldKey={[fieldKey, 'body']}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                                    validateStatus={customerPhoneNumberBodyErrors ? 'error' : null}
                                    help={customerPhoneNumberBodyErrors && customerPhoneNumberBodyErrors.join(', ')}
                                    hasFeedback>
                                        <Input placeholder={i18n.t('customerForm.phone')} />
                                </Form.Item>
                            </Col>
                            <Col md={{ span: 12 }}>
                                <Form.Item label={i18n.t('customerForm.phoneType')}
                                    {...restField}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}
                                    name={[name, 'body_type']}
                                    fieldKey={[fieldKey, 'body_type']}
                                    validateStatus={customerPhoneNumberBodyTypeErrors ? 'error' : null}
                                    help={customerPhoneNumberBodyTypeErrors && customerPhoneNumberBodyTypeErrors.join(', ')}
                                    hasFeedback>
                                        <Select placeholder={i18n.t('customerForm.phoneType')}
                                            showSearch
                                            style={{ width: '100%' }}
                                            allowClear>
                                            {showPhoneType}
                                        </Select>
                                </Form.Item>
                            </Col>
                            <CloseCircleOutlined onClick={() => remove(name)} />
                        </Row>
                    ))}
                    <Form.Item>
                        <Button onClick={() => add()}>
                            {i18n.t('customerForm.addPhoneNumber')}
                        </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        )
    }

    renderEmailForm = () => {
        const { customers, i18n } = this.props
        const formErrors = customers.get('formErrors')
        const showEmailType = EMAILS_TYPE.map((email, index) => {
            return (
                <Select.Option value={email.value} key={index}>
                    {i18n.t(`customerForm.${email.i18n}`)}
                </Select.Option>
            )
        })

        const customerEmailErrors = formErrors.get('customer_emails.body')
        const customerEmailTypeErrors = formErrors.get('customer_emails.body_type')

        return (
            <Form.List name="customer_emails_attributes">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Row key={key} type="flex" justify="space-between">
                            <Col md={{ span: 11 }}>
                                <Form.Item label={i18n.t('customerForm.email')}
                                    {...restField}
                                    name={[name, 'body']}
                                    fieldKey={[fieldKey, 'body']}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                                    validateStatus={customerEmailErrors ? 'error' : null}
                                    help={customerEmailErrors && customerEmailErrors.join(', ')}
                                    hasFeedback>
                                        <Input placeholder={i18n.t('customerForm.email')} />
                                </Form.Item>
                            </Col>
                            <Col md={{ span: 12 }}>
                                <Form.Item label={i18n.t('customerForm.emailType')}
                                    {...restField}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}
                                    name={[name, 'body_type']}
                                    fieldKey={[fieldKey, 'body_type']}
                                    validateStatus={customerEmailTypeErrors ? 'error' : null}
                                    help={customerEmailTypeErrors && customerEmailTypeErrors.join(', ')}
                                    hasFeedback>
                                        <Select placeholder={i18n.t('customerForm.emailType')}
                                            showSearch
                                            style={{ width: '100%' }}
                                            allowClear>
                                            {showEmailType}
                                        </Select>
                                </Form.Item>
                            </Col>
                            <CloseCircleOutlined onClick={() => remove(name)} />
                        </Row>
                    ))}
                    <Form.Item>
                        <Button onClick={() => add()}>
                            {i18n.t('customerForm.addEmail')}
                        </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        )
    }

    renderLineForm = () => {
        const { i18n } = this.props
        const showLineType = LINES_TYPE.map((line, index) => {
            return (
                <Select.Option value={line.value} key={index}>
                    {i18n.t(`customerForm.${line.i18n}`)}
                </Select.Option>
            )
        })

        return (
            <Form.List name="customer_lines_attributes">
                {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Row key={key} type="flex" justify="space-between">
                            <Col md={{ span: 11 }}>
                                <Form.Item label={i18n.t('customerForm.lineId')}
                                    {...restField}
                                    name={[name, 'body']}
                                    fieldKey={[fieldKey, 'body']}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}
                                    hasFeedback>
                                        <Input placeholder={i18n.t('customerForm.lineId')} />
                                </Form.Item>
                            </Col>
                            <Col md={{ span: 12 }}>
                                <Form.Item label={i18n.t('customerForm.lineType')}
                                    {...restField}
                                    labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}
                                    name={[name, 'body_type']}
                                    fieldKey={[fieldKey, 'body_type']}
                                    hasFeedback>
                                        <Select placeholder={i18n.t('customerForm.lineType')}
                                            showSearch
                                            style={{ width: '100%' }}
                                            allowClear>
                                            {showLineType}
                                        </Select>
                                </Form.Item>
                            </Col>
                            <CloseCircleOutlined onClick={() => remove(name)} />
                        </Row>
                    ))}
                    <Form.Item>
                        <Button onClick={() => add()}>
                            {i18n.t('customerForm.addLineId')}
                        </Button>
                    </Form.Item>
                </>
                )}
            </Form.List>
        )
    }

    render () {
        return (
            <ContactsFormWrapper>
                <div className="add-btn">
                    {this.renderPhoneForm()}
                </div>
                <div className="add-btn">
                    {this.renderEmailForm()}
                </div>
                <div className="add-btn">
                    {this.renderLineForm()}
                </div>
            </ContactsFormWrapper>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    entities: state.get('Entities')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setEmailFormAmount,
        setPhoneFormAmount,
        setLineFormAmount
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(CustomerContactSFormContainer))
