import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { isEmpty } from '../../helpers/objects'

import { withTranslation } from 'react-i18next'
import ProfitSharingsIndex from '../../components/profitSharings/profitSharingsIndex'
import actions from '../../redux/customers/profitSharings/actions'
import orderTableActions from '../../redux/orders/tables/actions'
import { Select } from 'antd'
const { fetchProfitSharingOrders } = actions
const {
    fetchSales
} = orderTableActions
class ProfitSharingsIndexContainer extends Component {
    static propTypes = {}

    static defaultProps = {}

    componentDidMount () {
        const { fetchProfitSharingOrders, fetchSales } = this.props

        fetchProfitSharingOrders()
        fetchSales('all')
    }

    getCompanyProjects = () => {
        const { profitSharingEntities, profitSharings } = this.props

        return profitSharings.get('items', new List()).map((profitSharingId) => {
            return profitSharingEntities.get(profitSharingId) || new Map()
        })
    }

    getQueryString (args = {}) {
        const { profitSharings } = this.props

        const onSaleFilter = ('filterSale' in args) ? args.filterSale : profitSharings.get('filterSale')

        const queries = [].concat(
            this.getFilterSale(onSaleFilter)
        )

        return queries.filter((query) => { return !isEmpty(query) }).map((query) => {
            return `${query.key}=${query.value}`
        }).join('&')
    }

    getFilterSale (onSaleFilter) {
        if (!onSaleFilter) { return {} }

        return { key: 'by_sale', value: onSaleFilter }
    }

    handleFilterBySale = (saleId) => {
        const { fetchProfitSharingOrders } = this.props


        fetchProfitSharingOrders({ query: saleId })
    }

    renderSaleFilterOptions = () => {
        const { orderTables, entities } = this.props
        const saleEntities = entities.get('sales')
        const saleItems = orderTables.get('saleItems')

        if (saleItems.size === 0) {return null}
        const saleOptions = saleItems.map((saleId) => {
            const sale = saleEntities.get(saleId)
            return (
                {
                    key: sale.get('name'),
                    value: sale.get('id')
                }
            )
        })

        return saleOptions.map((saleOption) => {
            return (
                <Select.Option key={saleOption.key} value={saleOption.value}>
                    {saleOption.key}
                </Select.Option>
            )
        })
    }

    handlePageChange = (pagination) => {
        this.props.fetchCompanyProjects({ page: pagination.current })
    }

    render () {
        const { profitSharings, i18n } = this.props

        return (
            <div>
                <span style={{ margin: '5px' }}>
                    <span style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('profitSharing:filterBySale')}
                        </span>
                        <Select style={{ width: '200px', marginBottom: '12px' }} allowClear
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            onChange={(value) => this.handleFilterBySale(value)}
                            placeholder={i18n.t('profitSharing:filterWithSale')}>
                            {this.renderSaleFilterOptions()}
                        </Select>
                    </span>
                </span>
                <ProfitSharingsIndex
                    profitSharings={this.getCompanyProjects().toJS()}
                    onPageChange={this.handlePageChange}
                    loading={profitSharings.get('loading')}
                    pagination={{
                        ...profitSharings.get('pagination').toJS(),
                        showSizeChanger: false
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    profitSharings: state.get('profitSharings'),
    profitSharingEntities: state.getIn(['Entities', 'profitSharings']),
    entities: state.get('Entities'),
    orderTables: state.get('orderTables')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProfitSharingOrders,
        fetchSales
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('profitSharing')(ProfitSharingsIndexContainer))
