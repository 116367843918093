import { Map, List } from 'immutable'
import actions from './actions'

const {
    BRANDS_SET_QUERY,
    BRANDS_FETCH,
    BRANDS_SET_PAGINATION,
    BRANDS_FETCH_SUCCESS,
    BRAND_FETCHING,
    BRAND_FETCH_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    query: '',
    pagination: new Map({
        current: 1,
        pageSize: 20,
        total: 1
    })
})

export default function brandsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case BRANDS_SET_QUERY:
            return state.merge({
                query: action.query
            })
        case BRANDS_FETCH:
            return state.merge({
                loading: true
            })
        case BRANDS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List().concat(result)
            })
        case BRANDS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case BRAND_FETCH_SUCCESS:
            return state.merge({ items: new List([result]), loading: false })
        case BRAND_FETCHING:
            return state.merge({ loading: true })
        default:
            return state
    }
}
