import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'

import actions from '../../redux/trucks/actions'

import TruckOwnerForm from '../../components/trucks/truckOwnerForm'

const {
    fetchTruckOwners,
    resetTruckOwnerFormError,
    saveTruckOwner
} = actions

class TruckOwnerFormContainer extends Component {
    static propTypes = {
        // Props from another component
        onCancel: PropTypes.func.isRequired,

        // Inner Props
        fetchTruckOwners: PropTypes.func.isRequired,
        resetTruckOwnerFormError: PropTypes.func.isRequired,
        saveTruckOwner: PropTypes.func.isRequired,
        truckOwnerEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired
    }

    componentWillUnmount () {
        const { resetTruckOwnerFormError } = this.props

        resetTruckOwnerFormError()
    }

    getTruckOwner () {
        const { truckOwnerEntities, trucks } = this.props
        const ownerId = trucks.getIn(['truckOwnerForm', 'ownerId'])

        if (!ownerId) return new Map()

        return truckOwnerEntities.get(ownerId.toString())
    }

    onSubmit = (truckOwner) => {
        const { saveTruckOwner, trucks } = this.props
        const ownerId = trucks.getIn(['truckOwnerForm', 'ownerId'])
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])
        if (!truckOwner) return null

        saveTruckOwner({ type: ownerType, id: ownerId, params: truckOwner })
    }

    render () {
        const { onCancel, trucks } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        return (
            <TruckOwnerForm
                error={trucks.getIn(['truckOwnerForm', 'error'], new Map()).toJS()}
                onCancel={onCancel}
                onSubmit={this.onSubmit}
                ownerType={ownerType}
                truckOwner={this.getTruckOwner().toJS()} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        truckOwnerEntities: state.getIn(['Entities', 'truckOwners']),
        trucks: state.get('trucks')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTruckOwners,
        resetTruckOwnerFormError,
        saveTruckOwner
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckOwnerFormContainer)
