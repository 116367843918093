import styled from 'styled-components'

export default styled.div`
  display: flex;
  .left-container {
    width: 300px;
  }
  .right-container {
    width: calc(100% - 300px)
  }
`
