export const SUPPLIER_ORDER_DELIVERY_STATUS = [
  { value: 'delivery', i18n: 'มีค่าจัดส่ง' },
  { value: 'free_shipping', i18n: 'จัดส่งฟรี' },
  { value: 'pick_up', i18n: 'ลูกค้ารับสินค้าเอง' },
  { value: 'attach_trucking', i18n: 'พ่วงส่ง' },
  { value: 'attach_truck_pending', i18n: 'รอพ่วงส่ง' }
]

export const TRANSFER_BACK_BANK_ACCOUNTS = [
  { value: 'ธ. กรุงเทพ จำกัด (มหาชน)', i18n: 'ธ. กรุงเทพ จำกัด (มหาชน)' },
  { value: 'ธ. กสิกรไทย จำกัด (มหาชน)', i18n: 'ธ. กสิกรไทย จำกัด (มหาชน)' },
  { value: 'ธ. กรุงไทย จำกัด (มหาชน)', i18n: 'ธ. กรุงไทย จำกัด (มหาชน)' },
  { value: 'ธ. ทหารไทย จำกัด (มหาชน)', i18n: 'ธ. ทหารไทย จำกัด (มหาชน)' },
  { value: 'ธ. ไทยพาณิชย์ จำกัด (มหาชน)', i18n: 'ธ. ไทยพาณิชย์ จำกัด (มหาชน)' },
  {
    value: 'ธ. กรุงศรีอยุธยา จำกัด (มหาชน)',
    i18n: 'ธ. กรุงศรีอยุธยา จำกัด (มหาชน)'
  },
  { value: 'ธ. ธนชาต จำกัด (มหาชน)', i18n: 'ธ. ธนชาต จำกัด (มหาชน)' },
  { value: 'ธ. ออมสิน', i18n: 'ธ. ออมสิน' },
  { value: 'ธ. อาคารสงเคราะห์', i18n: 'ธ. อาคารสงเคราะห์' },
  {
    value: 'ธ. เพื่อการเกษตรและสหกรณ์การเกษตร',
    i18n: 'ธ. เพื่อการเกษตรและสหกรณ์การเกษตร'
  },
  { value: 'ธ. ยูโอบี จำกัด (มหาชน)', i18n: 'ธ. ยูโอบี จำกัด (มหาชน)' },
  {
    value: 'ธ. ไอซีบีซี (ไทย) จำกัด (มหาชน)',
    i18n: 'ธ. ไอซีบีซี (ไทย) จำกัด (มหาชน)'
  },
  {
    value: 'ธ. ซีไอเอ็มบี ไทย จำกัด (มหาชน)',
    i18n: 'ธ. ซีไอเอ็มบี ไทย จำกัด (มหาชน)'
  },

  {
    value: 'ธ. เกียรตินาคิน จำกัด (มหาชน)',
    i18n: 'ธ. เกียรตินาคิน จำกัด (มหาชน)'
  }
]

export const PO_DOCUMENT_HEADERS = [
  { value: 'onestockhome', i18n: 'onestockhome' },
  { value: 'ngentongma', i18n: 'เงินทองมาวัสดุ' },
  { value: 'korat_roum_wasadu', i18n: 'โคราชรวมวัสดุ' }
]
