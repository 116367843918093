export const GENDERS = [{ value: 'male', i18n: 'male' }, { value: 'female', i18n: 'female' }]

export const EMAILS_TYPE = [
    { value: 'personal', i18n: 'personal' },
    { value: 'work', i18n: 'work' }
]

export const PHONE_NUMBERS_TYPE = [
    { value: 'home', i18n: 'home' },
    { value: 'work', i18n: 'work' },
    { value: 'personal', i18n: 'personal' }
]

export const LINES_TYPE = [
    { value: 'line_id', i18n: 'lineId' },
    { value: 'line_name', i18n: 'lineName' }
]

export const LEAD_SOURCES = [
    'line', 'phone', 'facebook', 'email', 'sales_contact'
]

export const TYPE_SOURCES = [
    'line', 'email', 'phone'
]

export const TYPE_CONTACTS = [
    'follow_price', 'ask_information', 'small_quantity', 'have_no_item', 'complaint', 'quote', 'use_later', 'other'
]

export const CUSTOMER_WALLET_TRANSACTION_TYPE = [
    { value: 'deposit', i18n: 'deposit' },
    { value: 'withdraw', i18n: 'withdraw' },
    { value: 'transfer_back', i18n: 'transferBack' }
]

export const PAGE_SIZE = 20
