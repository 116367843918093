import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import SupplierOverTransferItemForm from '../../components/Suppliers/supplierOverTransferItemForm'

import actions from '../../redux/suppliers/overTransferItems/actions'
import notificationActions from '../../redux/notifications/actions'

const { deleteSupplierSupplierOverTransferItem, saveSupplierSupplierOverTransferItem } = actions

const { setNotifications } = notificationActions

class SupplierOverTransferItemFormContainer extends Component {
    static propTypes = {
        // Props from another component
        formData: PropTypes.object.isRequired,
        onSaveSuccess: PropTypes.func,
        onSaveError: PropTypes.func,

        // Inner props
        deleteSupplierSupplierOverTransferItem: PropTypes.func.isRequired,
        saveSupplierSupplierOverTransferItem: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        supplierEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferAccountEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferDestinationEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferItemEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        id: undefined,
        onSaveSuccess: () => {},
        onSaveError: () => {}
    }

    constructor (props) {
        super(props)

        this.state = {
            error: {},
            transactionDetail: 'over_transfer',
            fileList: [],
            overTransfer: {
                disableSelectSupplier: true
            },
            creditNote: {
                orderId: undefined
            }
        }
    }

    componentDidMount () {
        const { formData, imagesEntities } = this.props
        const supplierOverTransferItem = this.getSupplierOverTransferItem(formData.id)

        const images = supplierOverTransferItem.get(
            'images',
        ) || new List()

        const imageList = images.map((image) => {
            return {
                uid: image.get('id'),
                url: imagesEntities.getIn([image.getIn(['image', 's']), 'thumbBlob'])
            }
        })

        this.setState(_.merge(
            this.state,
            {
                creditNote: {
                    orderId: supplierOverTransferItem.getIn(['supplier_over_transfer_destination', 'order'])
                },
                fileList: imageList.toJS()
            }
        ))
    }

    getSupplier (id) {
        if (!id) return new Map()

        const { supplierEntities } = this.props
        return supplierEntities.get(_.toString(id), new Map())
    }

    getSupplierOrder = (id) => {
        if (!id) return new Map()

        const { supplierOrderEntities } = this.props
        const result = supplierOrderEntities.get(_.toString(id), new Map())
        return result.merge({
            supplier: this.getSupplier(result.get('supplier'))
        })
    }

    getSupplierOverTransferAccount (id) {
        if (!id) return new Map()

        const { supplierOverTransferAccountEntities } = this.props
        const result = supplierOverTransferAccountEntities.get(_.toString(id), new Map())
        return result.merge({
            supplier: this.getSupplier(result.get('supplier'))
        })
    }

    getSupplierOverTransferDestination (id) {
        if (!id) return new Map()

        const { supplierOverTransferDestinationEntities } = this.props
        const result = supplierOverTransferDestinationEntities.get(_.toString(id), new Map())
        return result.merge({
            supplier_order: this.getSupplierOrder(result.get('supplier_order'))
        })
    }

    getSupplierOverTransferItem (id) {
        if (!id) return new Map()

        const { supplierOverTransferItemEntities } = this.props
        const result = supplierOverTransferItemEntities.get(_.toString(id), new Map())
        return result.merge({
            supplier_over_transfer_account: this.getSupplierOverTransferAccount(
                result.get('supplier_over_transfer_account')
            ),
            supplier_over_transfer_destination: this.getSupplierOverTransferDestination(
                result.get('supplier_over_transfer_destination')
            ),
            supplier_order: this.getSupplierOrder(result.get('supplier_order'))
        })
    }

    onCreditNoteSupplierOrderChange = (form, supplierOrderId) => {
        const supplierOrder = this.getSupplierOrder(supplierOrderId)

        form.setFieldsValue({
            ['credit_note[supplier_id]']: supplierOrder.getIn(['supplier', 'id'])
        })

        this.setState(
            _.merge(
                this.state,
                {
                    creditNote: {
                        disableSelectSupplier: _.isEmpty(supplierOrderId)
                    }
                }
            )
        )
    }

    onOverTransferSupplierOrderChange = (form, supplierOrderId) => {
        const supplierOrder = this.getSupplierOrder(supplierOrderId)

        form.setFieldsValue({
            ['over_transfer[supplier_id]']: supplierOrder.getIn(['supplier', 'id'])
        })

        this.setState(
            _.merge(
                this.state,
                {
                    overTransfer: {
                        disableSelectSupplier: _.isEmpty(supplierOrderId)
                    }
                }
            )
        )
    }

    onSelectTransactionDetail = (value) => {
        this.setState({
            transactionDetail: value
        })
    }

    onSubmitForm = ({ supplierId, supplierOverTransferItemParams }) => {
        const {
            formData,
            onSaveError,
            onSaveSuccess,
            saveSupplierSupplierOverTransferItem,
            setNotifications
        } = this.props

        saveSupplierSupplierOverTransferItem({
            supplierId,
            id: formData.id,
            supplierOverTransferItemParams,
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
                onSaveSuccess()
            },
            onError: (error) => {
                setNotifications('error', 'error', 'error')
                onSaveError()
                this.setState({ error })
            }
        })
    }

    handleDelete = () => {
        const { formData, deleteSupplierSupplierOverTransferItem, onSaveSuccess } = this.props
        deleteSupplierSupplierOverTransferItem({
            supplierId: formData.supplierId,
            id: formData.id,
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
                onSaveSuccess()
            }
        })
    }

    handleSetFileList = (fileList) => {
        this.setState(fileList)
    }

    render () {
        const { formData } = this.props
        const { error, creditNote: creditNoteState, overTransfer: overTransferState } = this.state
        const supplierOverTransferItem = this.getSupplierOverTransferItem(formData.id).toJS()

        return (
            <SupplierOverTransferItemForm
                context={_.isEmpty(supplierOverTransferItem) ? 'CREATE' : 'UPDATE'}
                creditNoteState={creditNoteState}
                error={error}
                getSupplierOrder={this.getSupplierOrder}
                onCreditNoteSupplierOrderChange={this.onCreditNoteSupplierOrderChange}
                onDelete={this.handleDelete}
                onOverTransferSupplierOrderChange={this.onOverTransferSupplierOrderChange}
                onSelectTransactionDetail={this.onSelectTransactionDetail}
                onSubmitForm={this.onSubmitForm}
                orderId={formData.orderId}
                overTransferState={overTransferState}
                supplierId={formData.supplierId}
                supplierOrderId={formData.supplierOrderId}
                supplierOverTransferItem={supplierOverTransferItem}
                transactionDetail={this.state.transactionDetail}
                fileList={this.state.fileList}
                handleSetFileList={this.handleSetFileList}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        supplierEntities: state.getIn(['Entities', 'suppliers']),
        supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
        supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts']),
        supplierOverTransferDestinationEntities: state.getIn(['Entities', 'supplierOverTransferDestinations']),
        supplierOverTransferItemEntities: state.getIn(['Entities', 'supplierOverTransferItems']),
        imagesEntities: state.getIn(['Entities', 'images'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteSupplierSupplierOverTransferItem,
        saveSupplierSupplierOverTransferItem,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOverTransferItemFormContainer)

