import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import _ from 'lodash'

import ScmNoteForm from '../../components/orderTracking/scmNoteForm'

import actions from '../../redux/orders/trackings/actions'
import notificationActions from '../../redux/notifications/actions'

const { fetchOrderTracking, saveOrderTracking } = actions
const { setNotifications } = notificationActions

class ScmNoteFormContainer extends Component {
    static propTypes = {
        // Props from another component
        initFetch: PropTypes.bool,
        orderId: PropTypes.string.isRequired,
        orderState: PropTypes.string.isRequired,

        // Inner props
        fetchOrderTracking: PropTypes.func.isRequired,
        orderTrackingEntities: ImmutablePropTypes.map.isRequired,
        orderTrackings: ImmutablePropTypes.map.isRequired,
        saveOrderTracking: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired
    }

    static defaultProps = {
        initFetch: true
    }

    constructor (props) {
        super(props)
        this.state = {
            formVisible: false,
            scmNote: ''
        }
    }

    componentDidMount () {
        const { initFetch, fetchOrderTracking, orderId } = this.props

        if (!initFetch) return null

        fetchOrderTracking(orderId)
    }

    getOrderTracking () {
        const { orderTrackingEntities, orderTrackings } = this.props
        return orderTrackingEntities.get(orderTrackings.get('item'), new Map())
    }

    onChangeScmNote = (e) => {
        this.setState({ scmNote: e.target.value })
    }

    onSubmitScmNote = () => {
        const { orderId, saveOrderTracking, setNotifications } = this.props
        const { scmNote } = this.state

        saveOrderTracking({
            orderId,
            orderTrackingParams: { scm_note: scmNote },
            onSuccess: () => {
                setNotifications('success', 'success', 'success')
                this.onToggleFormVisible()
            },
            onError: () => { setNotifications('error', 'error', 'error') }
        })
    }

    onToggleFormVisible = () => {
        const { formVisible } = this.state

        this.setState({
            formVisible: !formVisible,
            scmNote: this.getOrderTracking().get('scm_note', '')
        })
    }

    render () {
        const { orderState } = this.props
        const { formVisible } = this.state
        const orderTracking = this.getOrderTracking().toJS()
        if (!orderTracking) return <></>
        const scmNote = _.get(orderTracking, 'scm_note', '')
        return (
            <ScmNoteForm
                formVisible={formVisible}
                onChangeScmNote={this.onChangeScmNote}
                onSubmitScmNote={this.onSubmitScmNote}
                onToggleFormVisible={this.onToggleFormVisible}
                scmNote={scmNote}
                orderState={orderState}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    orderTrackingEntities: state.getIn(['Entities', 'orderTrackings']),
    orderTrackings: state.get('orderTrackings')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchOrderTracking,
        saveOrderTracking,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScmNoteFormContainer)
