export const numberWithCommas = (number = 0) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const displayNumberWithSymbol = (number = 0) => {
    let newNumber = number 

    if (Math.abs(number) > 999999) {
        newNumber = Math.sign(number) * ((Math.abs(number) / 1000000).toFixed(1)) + 'M'
    } else if (Math.abs(number) > 999) {
        newNumber =  Math.sign(number) * ((Math.abs(number) / 1000).toFixed(1)) + 'k'
    } else {
        newNumber = Math.sign(number)*Math.abs(number)
    }

    return numberWithCommas(newNumber)
}
