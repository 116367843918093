import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import BankStatementIndexPage from '../containers/BankStatements'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const BankStatementsPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'bank_statements') {
			dispatch(changeCurrent(['bank_statements']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={BankStatementIndexPage} exact />
		</Switch>
  )
}

BankStatementsPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

BankStatementsPage.defaultProps = {}

export default BankStatementsPage
