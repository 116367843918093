import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import imageActions from '../../redux/images/actions'

const {
    addNewImage,
    fetchOriginalImage,
    openPreviewImageModal,
    setPreviewImageThumbUrl,
    setNoticeText
} = imageActions

const ImageContainer = (ImageComponent) => {
    const App = class App extends Component {
        constructor (props) {
            super(props)

            const { addNewImage, thumbUrl, originalUrl, contentType, isImageBlob } = props

            this.imageUrl = {
                original: originalUrl || '',
                thumb: thumbUrl
            }

            // create redux state for this component
            addNewImage(thumbUrl, originalUrl || '', contentType, isImageBlob)
        }

        componentDidUpdate (prevProps) {
            const { thumbUrl: prevThumbUrl } = prevProps
            const {
                thumbUrl: currThumbUrl,
                addNewImage,
                contentType,
                isImageBlob,
                originalUrl
            } = this.props

            if (prevThumbUrl === currThumbUrl) return null

            addNewImage(currThumbUrl, originalUrl || '', contentType, isImageBlob)
            this.imageUrl = {
                original: originalUrl || '',
                thumb: currThumbUrl
            }
        }

        getThumbBlob () {
            const { imageEntities } = this.props
            return imageEntities.getIn([this.imageUrl.thumb, 'thumbBlob']) || ''
        }

        getThumbLoading () {
            const { imageEntities } = this.props
            return imageEntities.getIn([this.imageUrl.thumb, 'thumbLoading'])
        }

        handleOnClickImage = () => {
            const {
                openPreviewImageModal,
                fetchOriginalImage,
                setPreviewImageThumbUrl,
                isImageBlob,
                setNoticeText,
                noticeText
            } = this.props
            const thumbUrl = this.imageUrl.thumb

            openPreviewImageModal()
            setNoticeText(noticeText)
            setPreviewImageThumbUrl(thumbUrl)
            fetchOriginalImage(thumbUrl, isImageBlob)
        }

        render () {
            const {
                addNewImage,
                contentType,
                fetchOriginalImage,
                imageEntities,
                images,
                isImageBlob,
                noticeText,
                openPreviewImageModal,
                originalUrl,
                setNoticeText,
                setPreviewImageThumbUrl,
                thumbUrl,
                ...restProps
            } = this.props

            return (
                <ImageComponent
                    handleOnClickImage={this.handleOnClickImage}
                    thumbBlob={this.getThumbBlob()}
                    thumbLoading={this.getThumbLoading()}
                    {...restProps}
                />
            )
        }
    }

    const mapStateToProps = (state) => ({
        imageEntities: state.getIn(['Entities', 'images']),
        images: state.get('images')
    })

    const mapDispatchToProps = (dispatch) => {
        return bindActionCreators({
            addNewImage,
            fetchOriginalImage,
            openPreviewImageModal,
            setPreviewImageThumbUrl,
            setNoticeText
        }, dispatch)
    }

    return connect(mapStateToProps, mapDispatchToProps)(App)
}

export default ImageContainer
