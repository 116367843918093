import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import TruckReservationCostList from '../../components/TruckReservations/truckReservationCostList'
import actions from '../../redux/truckReservations/actions'

const { fetchTruckReservationsNotPaid } = actions

class TruckReservationCostListContainer extends Component {
    static propTypes = {
        // inner props
        fetchTruckReservationsNotPaid: PropTypes.func.isRequired,
        truckReservationEntities: ImmutablePropTypes.map.isRequired,
        truckReservations: ImmutablePropTypes.map.isRequired,

        //  outer props
        orderId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchTruckReservationsNotPaid, orderId } = this.props

        fetchTruckReservationsNotPaid({ orderId })
    }

    getTruckReservations () {
        const { truckReservationEntities, truckReservations } = this.props

        return truckReservations.get('notPaidItems').map((id) => {
            return truckReservationEntities.get(id) || new Map()
        })
    }

    render () {
        return <TruckReservationCostList truckReservations={this.getTruckReservations()} />
    }
}

const mapStateToProps = (state) => {
    return {
        truckReservationEntities: state.getIn(['Entities', 'truckReservations']),
        truckReservations: state.get('truckReservations')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchTruckReservationsNotPaid
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckReservationCostListContainer)
