import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'
import { isMobile } from 'react-device-detect'
import moment from 'moment'
import _ from 'lodash'

import { Button, Modal, Table, Popconfirm, Row, Col, Typography, Tag } from 'antd'
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import withImagePreview from '../../ImagePreview/ImagePreviewContainer'
import Image from '../../Image'
import PaymentVouchersForm from './PaymentVoucherForm'
import EditText from '../../EditText'
import AccountingWrapper from './accounting.style'

import accountingActions from '../../../redux/orders/accounting/actions'

const {
    togglePaymentVoucherForm,
    fetchPaymentVouchers,
    deletePaymentVoucher,
    savePaymentVoucher
} = accountingActions

class PaymentVouchers extends Component {
    componentDidMount () {
        const { fetchPaymentVouchers, orderId } = this.props
        fetchPaymentVouchers(orderId)
    }

    togglePaymentVoucherForm = (id) => {
        const { togglePaymentVoucherForm } = this.props

        if (id) {
            this.getPaymentVoucher(id)
        }

        togglePaymentVoucherForm()
    }

    paymentVouchersData = () => {
        const { orderAccounting, paymentVoucherEntities } = this.props
        const paymentVoucherIds = orderAccounting.get('pvItems', new List())
        return paymentVoucherIds.map((id) => {
            return paymentVoucherEntities.get(id, new Map())
        })
    }

    renderActions (record) {
        const { i18n, orderId, pvChecked } = this.props
        const id = record.id
        const haveSlip = record.payment_voucher_images.length > 0
        if (record.is_paid || haveSlip || pvChecked) {
            return (
                <div>
                    <Link onClick={() => this.togglePaymentVoucherForm(id)}>
                        <EditOutlined />
                    </Link>
                </div>
            )
        }

        return (
            <div>
                <Link onClick={() => this.togglePaymentVoucherForm(id)}>
                    <EditOutlined />
                </Link>
                <Popconfirm title={i18n.t('confirms:accounting.deleteConfirm')}
                    onConfirm={() => this.handleDeletePaymentVoucher(orderId, id)}>
                    <a style={{ marginLeft: 20 }}><DeleteOutlined /></a>
                </Popconfirm>
            </div>
        )
    }

    handleDeletePaymentVoucher = (orderId, id) => {
        const { deletePaymentVoucher } = this.props

        deletePaymentVoucher(orderId, id)
    }

    handleSavePVBankStatement = ({ id, value }) => {
        const { orderId, savePaymentVoucher } = this.props

        savePaymentVoucher(orderId, id, { bank_statement_id: value })
    }

    handleSavePaymentVoucher = ({ id, params }) => {
        const { orderId, savePaymentVoucher } = this.props

        savePaymentVoucher(orderId, id, params, {
            onSuccess: () => {
                fetchPaymentVouchers(orderId)
                this.togglePaymentVoucherForm()
            }
        })
    }

    getPaymentVoucher (paymentVoucherId) {
        const { paymentVoucherEntities } = this.props

        this.paymentVoucher = paymentVoucherEntities.get(paymentVoucherId) || new Map()
    }

    renderSupplier = (supplierId) => {
        if (!supplierId) { return null }

        const { i18n, supplierEntities, supplierOverTransferAccountEntities } = this.props
        const supplier = supplierEntities.get(supplierId, new Map()).toJS()
        const accountId = _.get(supplier, 'supplier_over_transfer_account')
        const account = supplierOverTransferAccountEntities.get(accountId, new Map()).toJS()

        return (
            <div>
                <Link target="_blank" to={`/suppliers/${supplierId}`}>
                    {_.get(supplier, 'name', '')}
                </Link>
                <span>
                    {` (${i18n.t('suppliers/overTransferAccounts:balance')}: ${_.get(account, 'balance.display', '0.00')})`}
                </span>
            </div>
        )
    }

    renderSupplierOrders = (supplierOrderIds) => {
        const { i18n, supplierOrderEntities } = this.props
        if (supplierOrderIds.length) {
            return supplierOrderIds.map((supplierOrderId) => {
                const supplierOrder = supplierOrderEntities.get(supplierOrderId) || new Map()
                const deliveryStatus = supplierOrder.get('delivery_status')

                if (!deliveryStatus) { return null }

                return (
                    <div>
                        <div>
                            {supplierOrder.get('ref_code')}
                        </div>
                        <div>
                            {' (' + i18n.t(`suppliers:${_.camelCase(deliveryStatus)}`) + ')'}
                        </div>
                    </div>
                )
            })
        }

        return ` (${i18n.t('suppliers:noShippingData')})`
    }

    renderImage = (dataIndex) => {
        return dataIndex.map((image) => {
            const imageId = _.get(image, 'id')
            const thumbUrl = _.get(image, 'file.m', '')
            const originalUrl = _.get(image, 'file.original', '')
            const contentType = _.get(image, 'file.content_type', '')

            return (
                <Image
                    key={imageId}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })
    }

    renderSupplierOrderImages (supplierOrderIds) {
        const { supplierOrderEntities } = this.props
        return supplierOrderIds.map((id) => {
            return supplierOrderEntities.getIn([id, 'supplier_order_images']).map((image) => {
                const imageId = image.get('id')
                const thumbUrl = image.getIn(['file', 'm']) || ''
                const originalUrl = image.getIn(['file', 'original']) || ''
                const contentType = image.getIn(['file', 'content_type']) || ''

                return (
                    <Image
                        key={imageId}
                        contentType={contentType}
                        originalUrl={originalUrl}
                        thumbUrl={thumbUrl}
                    />
                )
            })
        })
    }

    renderTable = () => {
        const { i18n, orderAccounting, supplierEntities, supplierOrderEntities } = this.props
        const loading = orderAccounting.get('pvLoading')

        const paymentVoucherColumn = [
            {
                title: i18n.t('confirms:paymentVoucher.dateOfPurchase'),
                dataIndex: 'date_of_purchase',
                key: 'dateOfPurchase',
                render: (dataIndex) => {
                    if (!dataIndex) { return }
                    return(
                        <div>
                            {moment(dataIndex, 'YYYY-MM-DD').format('D MMMM YYYY')}
                        </div>

                    )
                }
            }, {
                title: i18n.t('confirms:accounting.externalId'),
                dataIndex: 'external_id'
            },
            {
                title: i18n.t('confirms:accounting.bankStatementId'),
                dataIndex: 'bank_statement_number',
                render: (bankStatementId, record) => (
                    <EditText
                        initialParams={{ id: record.id }}
                        value={bankStatementId}
                        onSave={this.handleSavePVBankStatement}
                    />
                )
            },
            {
                title: i18n.t('confirms:accounting.transferDate'),
                dataIndex: ['transfer_date', 'raw'],
                key: 'transferDate',
                render: (dataIndex) => {
                    if (!dataIndex) { return }
                    return (
                        <div>{moment(dataIndex, 'YYYY-MM-DD').format('D MMMM YYYY')}</div>
                    )
                }
            }, {
                title: i18n.t('confirms:paymentVoucher.hasCredit'),
                key: 'credit',
                render: (data) => {
                    if (!_.get(data, 'has_credit', false)) return '-'

                    return `${_.get(data, 'credit_duration_days', 0)} ${i18n.t('confirms:paymentVoucher.days')}`
                }
            }, {
                align: 'center',
                title: i18n.t('confirms:paymentVoucher.isPaid'),
                dataIndex: 'is_paid',
                key: 'isPaid',
                render: (isPaid) => {
                    if (isPaid) return <CheckOutlined />
                    return ''
                }
            }, {
                title: i18n.t('confirms:accounting.amount'),
                dataIndex: ['amount', 'display'],
                key: 'amount',
                render: (dataIndex, record) => {
                    const isTotalAmountWithMargin = supplierEntities.getIn([record.supplier, 'ngen_tong_ma_type']) === 'total_amount_with_margin'
                    const grandTotalBeforeAddMargin = supplierOrderEntities.getIn([record.supplier_orders[0], 'grand_total', 'display'])
                    const amountAfterWithdrawDisplay = _.get(record, 'amount_after_withdraw.display')
                    const amountAfterDepositDisplay = _.get(record, 'amount_after_deposit.display')
                    if (amountAfterDepositDisplay) {
                        const amountAfterDepositTotal = _.get(record, 'amount_after_deposit.total')
                        return (
                            <div>
                                {
                                    isTotalAmountWithMargin ?
                                        <Typography.Text type="secondary">
                                            {i18n.t('confirms:accounting.priceBeforeMarginAdded')}
                                            {grandTotalBeforeAddMargin}
                                            {i18n.t('confirms:accounting.bath')}
                                        </Typography.Text> :
                                        null
                                }
                                {dataIndex} {i18n.t('confirms:accounting.bath')}
                                {!amountAfterWithdrawDisplay && !amountAfterDepositDisplay ? null : (
                                    <Tag color={'green'}>
                                        {amountAfterDepositTotal} {i18n.t('confirms:accounting.bath')}
                                    </Tag>
                                ) }
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {
                                    isTotalAmountWithMargin ?
                                        <Typography.Text type="secondary">
                                            {i18n.t('confirms:accounting.priceBeforeMarginAdded')}
                                            {grandTotalBeforeAddMargin}
                                            {i18n.t('confirms:accounting.bath')}
                                        </Typography.Text> :
                                        null
                                }
                                <Typography.Text delete={amountAfterWithdrawDisplay}>
                                    {dataIndex} {i18n.t('confirms:accounting.bath')}
                                </Typography.Text>
                                {amountAfterWithdrawDisplay ?
                                    <Tag color={'red'}>
                                        {amountAfterWithdrawDisplay} {i18n.t('confirms:accounting.bath')}
                                    </Tag> : null}

                            </div>
                        )
                    }
                }
            }, {
                title: i18n.t('confirms:paymentVoucher.purchaseOrder'),
                dataIndex: 'supplier_orders',
                key: 'supplierOrders',
                render: (supplierOrderIds) => {
                    return (
                        <AccountingWrapper>
                            <div className="image-container">
                                {this.renderSupplierOrderImages(supplierOrderIds)}
                            </div>
                        </AccountingWrapper>
                    )
                }
            }, {
                title: i18n.t('confirms:paymentVoucher.type'),
                dataIndex: 'payment_type',
                key: 'type',
                render: (dataIndex, record) => {
                    let details = <div />
                    switch (dataIndex) {
                        case 'logistic':
                            details = (
                                <div>
                                    <div> {record.truck_owner_name} ({record.license_plate_number || '-' }) </div>
                                    <div> วันที่จัดส่ง: {record.pickup_date || '-'} </div>
                                    <div> {record.who_dispatched} </div>
                                    <div> {record.note} </div>
                                </div>
                            )
                            break
                        case 'product':
                            details = (
                                <div>
                                    {this.renderSupplier(record.supplier)}
                                    {this.renderSupplierOrders(record.supplier_orders)}
                                </div>
                            )
                            break

                        default:
                            break
                    }

                    if (dataIndex) {
                        return (
                            <div>
                                <div> {_.startCase(dataIndex)} </div>
                                <div>{details}</div>
                                {record.reconciled && <Tag color="green">{i18n.t('confirms:accounting.reconciled')}</Tag>}
                                {details}
                                {record.is_adjust_after_1688 && <p>({i18n.t('confirms:accounting.adjust')})</p>}
                            </div>
                        )
                    }
                }
            }, {
                title: i18n.t('confirms:accounting.slip'),
                dataIndex: 'payment_voucher_images',
                key: 'image',
                render: (dataIndex) => {
                    return (
                        <AccountingWrapper>
                            <div className="image-container">
                                {this.renderImage(dataIndex)}
                            </div>
                        </AccountingWrapper>
                    )
                }
            }, {
                key: 'actions',
                render: (record) => this.renderActions(record)
            }
        ]

        return (
            <Table
                columns={paymentVoucherColumn}
                dataSource={this.paymentVouchersData().toJS()}
                loading={loading}
                pagination={false}
                rowKey="id"
                scroll={isMobile ? { x: 1300 } : { x: 1000 }}
            />
        )
    }

    render () {
        const { i18n, isOrderComplete, orderAccounting, orderId, pvChecked } = this.props
        moment.locale(i18n.language)

        return (
            <AccountingWrapper>
                <Modal
                    visible={orderAccounting.get('showPaymentVoucherForm')}
                    destroyOnClose
                    onCancel={() => this.togglePaymentVoucherForm()}
                    footer={null}>
                    <PaymentVouchersForm
                        formErrors={orderAccounting.get('paymentVoucherFormErrors')}
                        onSubmitForm={this.handleSavePaymentVoucher}
                        orderId={orderId}
                        paymentVoucher={this.paymentVoucher}
                        saving={orderAccounting.get('savingPaymentVoucher')}
                    />
                </Modal>
                {this.renderTable()}
                <Row className="my-16">
                    <Col span={12}>
                        <Button type="primary"
                            disabled={pvChecked && !isOrderComplete}
                            onClick={this.togglePaymentVoucherForm}>
                            {i18n.t('confirms:add')}
                        </Button>
                    </Col>
                </Row>
            </AccountingWrapper>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    orderAccounting: state.get('orderPaymentDetails'),
    paymentVoucherEntities: state.getIn(['Entities', 'paymentVouchers']),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        togglePaymentVoucherForm,
        fetchPaymentVouchers,
        deletePaymentVoucher,
        savePaymentVoucher
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['confirms', 'suppliers', 'suppliers/overTransferAccounts'])(withImagePreview(PaymentVouchers))
)

