import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import _ from 'lodash'

import { Select } from 'antd'

import actions from '../../../redux/customers/addresses/shippingAddresses/actions'

const { fetchShippingAddresses } = actions

class SelectShippingAddresses extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired,
        customerEntities: ImmutablePropTypes.map.isRequired,
        shippingAddressEntities: ImmutablePropTypes.map.isRequired,
        shippingAddresses: ImmutablePropTypes.map.isRequired,
        onChange: PropTypes.func
    }

    componentDidMount () {
        const { fetchShippingAddresses, customerId } = this.props
        fetchShippingAddresses(customerId)
    }

    handleSelectChange = (id) => {
        const { onChange } = this.props
        onChange(id)
    }

    getShippingAddressOptions () {
        const { shippingAddressEntities, shippingAddresses } = this.props

        return shippingAddresses.get('items').map((id) => {
            const shippingAddress = (shippingAddressEntities.get(id) || new Map()).toJS()

            const option = [
                _.get(shippingAddress, 'shipping_detail', ''),
                _.get(shippingAddress, 'additional_information', ''),
                _.get(shippingAddress, 'district.name', ''),
                _.get(shippingAddress, 'province.name', ''),
                _.get(shippingAddress, 'postal_code', ''),
                _.get(shippingAddress, 'contact_number', '')
            ].filter(Boolean).join(' ')

            return (
                <Select.Option key={`shipping-address-${id}`} value={id}>{option}</Select.Option>
            )
        })
    }

    render () {
        const { shippingAddresses, i18n } = this.props
        return (
            <Select loading={shippingAddresses.get('loading')}
                onChange={this.handleSelectChange}
                style={{ width: '100%' }}
                allowClear
                placeholder={i18n.t('addresses:address')}>
                {this.getShippingAddressOptions()}
            </Select>
        )
    }
}

const mapStateToProps = state => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    shippingAddresses: state.get('shippingAddresses'),
    shippingAddressEntities: state.getIn(['Entities', 'shippingAddresses'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchShippingAddresses
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('addresses')(SelectShippingAddresses))
