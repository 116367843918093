import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Upload, Button, Card } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

class ImportReport extends Component {
    static propTypes = {
        onUpload: PropTypes.func,
        title: PropTypes.string,
        uploading: PropTypes.bool
    }

    static defaultProps = {
        onUpload: () => {},
        uploading: false
    }

    state = {
        fileList: []
    }

    handleChange = (info) => {
        let fileList = info.fileList
        fileList = fileList.slice(-1)

        this.setState({ fileList })
    }

    handleUpload = () => {
        const { onUpload } = this.props
        const { fileList } = this.state

        const file = fileList[0].originFileObj
        onUpload(file)

        this.setState({
            fileList: []
        })
    }

    render () {
        const { title, uploading, i18n } = this.props
        const { fileList } = this.state

        const props = {
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }))
                return false
            },
            fileList
        }

        return (
            <Card title={title} style={{ marginBottom: '20px' }}>
                <Upload key="upload_input" {...props}
                    onChange={this.handleChange}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                >
                    <Button>{i18n.t('documents:selectFile')}</Button>
                </Upload>
                <Button
                    key="upload_btn"
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={this.handleUpload}
                    disabled={fileList.length === 0}
                    loading={uploading}
                    style={{ marginTop: 16 }}
                />
            </Card>
        )
    }
}

export default withTranslation('documents')(ImportReport)
