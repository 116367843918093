import { all, takeLatest, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import { customerTypeSchema, customerTypesSchema } from '../../schema'

import actionNotifications from '../../notifications/actions'
import actionCustomer from '../../../redux/customers/actions'
import { deserialize } from '../../../helpers/jsonApi'

const { setCustomerTypes } = actionCustomer
const { setNotifications } = actionNotifications

const {
    CUSTOMER_TYPES_FETCH,
    CUSTOMER_TYPES_FETCH_REQUEST,
    CUSTOMER_TYPES_FETCH_SUCCESS,
    CUSTOMER_TYPES_SAVE_SUCCESS,
    CUSTOMER_TYPES_SAVE_REQUEST,
    CUSTOMER_TYPES_SET_SAVING,
    CUSTOMER_TYPES_SET_FORM_ERRORS
} = actions

const apiPath = (id) => {
    const basePath = '/customer_types'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *submitCustomerTypes () {
    yield takeLatest(CUSTOMER_TYPES_SAVE_REQUEST, function *({ payload }) {
        yield put({
            type: CUSTOMER_TYPES_SET_SAVING,
            saving: true
        })

        const id = payload.customerTypes.id
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ customer_type: payload.customerTypes })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: CUSTOMER_TYPES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: CUSTOMER_TYPES_SAVE_SUCCESS,
                    ...normalize(formattedData, customerTypeSchema)
                })

                yield put(setCustomerTypes(false))
                yield put({ type: CUSTOMER_TYPES_FETCH_REQUEST })
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: CUSTOMER_TYPES_SET_SAVING,
                saving: false
            })
        }
    })
}

export function *CustomerTypesRequest () {
    yield takeEvery(CUSTOMER_TYPES_FETCH_REQUEST, function *() {
        yield put({ type: CUSTOMER_TYPES_FETCH })
        const path = apiPath() + `?per=50`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerTypesSchema)

        yield put({
            type: CUSTOMER_TYPES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(submitCustomerTypes),
        fork(CustomerTypesRequest)
    ])
}
