import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Line } from 'react-chartjs-2'

import { Spin } from 'antd'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const SALE_SUMMARY_REPORTS = gql`
  query SaleSummaryReports {
    saleSummaryReports {
      sale {
        firstName
      }
      marginPercentage
    }
  }
`

const SaleMarginReports = () => {
  const { loading, data, error } = useQuery(SALE_SUMMARY_REPORTS)

  if (loading) return <Spin />
  if (error) return console.error(error)

  const saleMarginReports = data.saleSummaryReports || []

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'รายงานยอดการขายของ Sale รายสัปดาห์',
      }
    }
  }

  const reportsData = {
    labels: saleMarginReports.map(saleMarginReport => saleMarginReport.sale.firstName),
    datasets: [
      {
        label: 'กำไร (เปอร์เซ็นต์ %)',
        data: saleMarginReports.map(saleMarginReport => saleMarginReport.marginPercentage),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  }

  return (
    <Line data={reportsData} options={options} />
  )
}

SaleMarginReports.propTypes = {}

SaleMarginReports.defaultProps = {}

export default SaleMarginReports
