import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Button, Spin } from 'antd'
import { DeleteOutlined, EditTwoTone } from '@ant-design/icons'
import ImageWithActions from '../../containers/Image/ImageWithActions'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import ImagesWrapper from '../images/imagesWrapper.style'

import apiConfig from '../../../config/apiConfig'

const ItemTruckImages = ({
    itemTruckImages,
    loading,
    onRemoveItemTruckImage,
    saving
}) => {
    if (loading) return <Spin size="small"/>

    return (
        <ImagesWrapper>
            <div className="image-container">
                {itemTruckImages.map((itemTruckImage, index) => {
                    const editItemTruckImageURL = `${apiConfig.apiBase}/th/admin/item_truck_images/new?image_id=${itemTruckImage.id}&image_type=item_truck_images`
                    return (
                        <ImageWithActions
                            actions={[
                                <a href={editItemTruckImageURL} target="_blank" rel="noreferrer">
                                    <EditTwoTone />
                                </a>
                                ,
                                <Button
                                    icon={<DeleteOutlined />}
                                    key={`delete-item-truck-image-${index}`}
                                    loading={saving}
                                    onClick={() => { onRemoveItemTruckImage(itemTruckImage) }}
                                    size="small"
                                    type="link"
                                />
                            ]}
                            contentType={_.get(itemTruckImage, 'content_type', '')}
                            isImageBlob={false}
                            key={_.get(itemTruckImage, 'id', `index-${index}`)}
                            originalUrl={_.get(itemTruckImage, 'file.original', '')}
                            thumbUrl={_.get(itemTruckImage, 'file.small', '')}
                        />
                    )
                })}
            </div>
        </ImagesWrapper>
    )
}

ItemTruckImages.propTypes = {
    itemTruckImages: PropTypes.array,
    loading: PropTypes.bool,
    onRemoveItemTruckImage: PropTypes.func,
    saving: PropTypes.bool
}

ItemTruckImages.defaultProps = {
    itemTruckImages: [],
    loading: false,
    onRemoveItemTruckImage: () => {},
    saving: false
}

export default withImagePreview(ItemTruckImages)
