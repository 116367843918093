import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'
import _ from 'lodash'

import { chartOfAccountSchema, chartOfAccountsSchema } from '../schema'

import actions from './actions'

import actionsNotification from '../../redux/notifications/actions'
const { setNotifications } = actionsNotification

const {
    CHART_OF_ACCOUNTS_FETCH_REQUEST,
    CHART_OF_ACCOUNTS_FETCH,
    CHART_OF_ACCOUNTS_FETCH_SUCCESS,
    CHART_OF_ACCOUNTS_SET_PAGINATION,

    CHART_OF_ACCOUNT_FETCH_REQUEST,
    CHART_OF_ACCOUNT_FETCH,
    CHART_OF_ACCOUNT_FETCH_SUCCESS,

    CHART_OF_ACCOUNTS_SAVE_REQUEST,
    CHART_OF_ACCOUNTS_SAVING,
    CHART_OF_ACCOUNTS_SET_FORM_ERRORS,
    CHART_OF_ACCOUNTS_SAVE_SUCCESS,

    CHART_OF_ACCOUNT_DELETE_REQUEST,
    CHART_OF_ACCOUNT_DELETE,
    CHART_OF_ACCOUNT_DELETE_SUCCESS,

    CHART_OF_ACCOUNT_TYPES_FETCH_REQUEST,
    CHART_OF_ACCOUNT_TYPES_FETCHING,
    CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS
} = actions

export function *fetchChartOfAccounts () {
    yield takeEvery(CHART_OF_ACCOUNTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CHART_OF_ACCOUNTS_FETCH })
        const { page, per, query } = payload

        const queryString = `page=${page}&per=${per}&query=${query}`

        const path = `/chart_of_accounts?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, chartOfAccountsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: CHART_OF_ACCOUNTS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: CHART_OF_ACCOUNTS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchChartOfAccount () {
    yield takeEvery(CHART_OF_ACCOUNT_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CHART_OF_ACCOUNT_FETCH })

        const { id } = payload

        const path = `/chart_of_accounts/${id}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, chartOfAccountSchema)

        yield put({
            type: CHART_OF_ACCOUNT_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveChartOfAccount () {
    yield takeLatest(CHART_OF_ACCOUNTS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CHART_OF_ACCOUNTS_SAVING, saving: true })

        const { id, params, onSuccess, onError } = payload

        const basePath = '/chart_of_accounts'
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ chart_of_account: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: CHART_OF_ACCOUNTS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, chartOfAccountSchema)

                yield put({
                    type: CHART_OF_ACCOUNTS_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: CHART_OF_ACCOUNTS_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: CHART_OF_ACCOUNTS_SAVING, saving: false })
        }
    })
}

export function *deleteChartOfAccount () {
    yield takeLatest(CHART_OF_ACCOUNT_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: CHART_OF_ACCOUNT_DELETE })

        const { id, onSuccess } = payload
        const path = `/chart_of_accounts/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, chartOfAccountSchema)

        yield put({
            type: CHART_OF_ACCOUNT_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export function *fetchAllChartOfAccountTypesSaga () {
    yield takeLatest(CHART_OF_ACCOUNT_TYPES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CHART_OF_ACCOUNT_TYPES_FETCHING })

        try {
            const data = yield call(apiFetch, '/chart_of_account_types')

            yield put({
                type: CHART_OF_ACCOUNT_TYPES_FETCH_SUCCESS,
                chartOfAccountTypes:  _.get(data, 'chart_of_account_types', [])
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchChartOfAccounts),
        fork(saveChartOfAccount),
        fork(deleteChartOfAccount),
        fork(fetchChartOfAccount),
        fork(fetchAllChartOfAccountTypesSaga)
    ])
}
