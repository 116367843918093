import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'

import {
    orderReviewsSchema
} from '../../schema'

import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_REVIWES_FETCH_REQUEST,
    ORDER_REVIWES_FETCH,
    ORDER_REVIWES_FETCH_SUCCESS,
    ORDER_REVIEWS_SET_PAGINATION
} = actions

export function *OrderReviewsRequest () {
    yield takeEvery(ORDER_REVIWES_FETCH_REQUEST, function *({ payload }) {
        const { page } = payload
        let queryString = payload.queryString

        if (typeof queryString === 'object') {
            const bySale = queryString.by_sale ? `by_sale=${queryString.by_sale}` : ''
            const byCs = queryString.by_cs ? `by_cs=${queryString.by_cs}` : ''
            queryString = [bySale, byCs].filter(Boolean).join('&')
        }
        const path = `/order_reviews?${queryString}&page=${page}&per=${PAGE_SIZE}`

        yield put({ type: ORDER_REVIWES_FETCH })

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, orderReviewsSchema)

        const total = data.meta.total_pages * PAGE_SIZE
        yield put({
            type: ORDER_REVIEWS_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: ORDER_REVIWES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(OrderReviewsRequest)
    ])
}
