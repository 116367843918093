import styled from 'styled-components'
const OrderPaymentDetailsWrapper = styled.div`
  .detail-line {
    .detail-label {
      &:after {
        content: ': '
      }
    }
  }
  
  .scb-payment-confirm-divider {
    margin: 4px 0;
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }
`
export default OrderPaymentDetailsWrapper
