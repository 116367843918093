const actions = {
    SUPPLIER_ACCOUNTING_GROUPS_FETCH_REQUEST: 'SUPPLIER_ACCOUNTING_GROUPS_FETCH_REQUEST',
    SUPPLIER_ACCOUNTING_GROUPS_FETCH: 'SUPPLIER_ACCOUNTING_GROUPS_FETCH',
    SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS: 'SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS',
    SELECT_EXCLUDE_ITEM: 'SELECT_EXCLUDE_ITEM',

    fetchSupplierAccountingGroups: ({ query = '' } = {}) => ({
        type: actions.SUPPLIER_ACCOUNTING_GROUPS_FETCH_REQUEST,
        payload: { query }
    }),

    selectExcludeItem: (id) => ({
        type: actions.SELECT_EXCLUDE_ITEM,
        id
    })
}

export default actions
