import React, { Component, createRef } from 'react'
import {Form, Input, Space, Tag, Typography} from 'antd'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {withRouter} from 'react-router'
import {bindActionCreators} from 'redux'

import inquiryActions from '../../redux/inquiry/actions'

const {
  saveOrderLineItems,
  toggleItemPricesModal
} = inquiryActions

class EditableCell extends Component {
  constructor () {
    super()

    this.inputRef = createRef()
    this.formRef = createRef()
  }


  state = {
    editing: false,
  }

  handleToggleEdit = () => {
    const editing = !this.state.editing
    this.setState({ editing }, () => {
      if (editing) {
        this.inputRef.current.focus()
      }
    })
  }

  handleSave = () => {
    const { dataIndex, record, saveOrderLineItems, inquiry } = this.props
    const { id } = record

    if (dataIndex === 'special_instruction') {
      this.formRef.current.validateFields().then(values => {
        this.handleToggleEdit()

        const specialInstruction = record.special_instruction
        const params = {
          special_instruction_attributes: {
            special_instruction: values.special_instruction,
            id: specialInstruction.id
          }
        }

        saveOrderLineItems(inquiry.get('current'), params, specialInstruction.lineItemId)
      })
    } else {
      this.formRef.current.validateFields().then(values => {
        this.handleToggleEdit()
        saveOrderLineItems(inquiry.get('current'), values, id)
      })
    }
  }

  renderEditableCell = (children) => {
    return (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.handleToggleEdit}>
        {children}
      </div>
    )
  }

  renderEditColumn = () => {
    const { editing } = this.state
    const { dataIndex, record, children, i18n, toggleItemPricesModal } = this.props

    if (editing) {
      return (
        <Form ref={this.formRef}>
          <Form.Item style={{ margin: 0 }} name={dataIndex}
                     initialValue={dataIndex === 'special_instruction' ? record[dataIndex]['detail'] : record[dataIndex]}>
            <Input.TextArea
              autoSize
              ref={this.inputRef}
              onPressEnter={this.handleSave}
              onBlur={this.handleSave} />
          </Form.Item>
        </Form>
      )
    }

    if (dataIndex === 'quantity' && record.quantity_after_adjustment) {
      return (
        <div className="editable-cell-value-wrap"
             style={{ paddingRight: 24 }}
             onClick={this.handleToggleEdit}
        >
          <Typography.Text delete>{children}</Typography.Text>
          <Tag color={record.quantity > record.quantity_after_adjustment ? 'red' : 'green'}>
            {record.quantity_after_adjustment}
          </Tag>
        </div>
      )
    }

    if (dataIndex === 'position' && record.item_external_id) {
      return (
        <Space direction="vertical" size="4">
          {this.renderEditableCell(children)}
          {record.item_external_id && (
            <Typography.Link onClick={() => toggleItemPricesModal(record.item_external_id)}>
              {i18n.t('inquiries:pricesList')}
            </Typography.Link>
          )}
        </Space>
      )
    }

    if (dataIndex === 'name_th' && (record.item_ean_code || record.additional_info)) {
      return (
        <Space direction="vertical" size="4">
          {this.renderEditableCell(children)}
          <div className='text-grey'>(EAN Code: {record.item_ean_code || record.additional_info})</div>
        </Space>
      )
    }

    return this.renderEditableCell(children)
  }

  render() {
    const { editable, dataIndex, title, record, index, handleSave, children, dispatch, staticContext, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editable ? this.renderEditColumn() : children}
      </td>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    inquiry: state.get('inquiry')
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveOrderLineItems,
    toggleItemPricesModal
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(withRouter(EditableCell)))