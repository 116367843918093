import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import ImagePreviewContainer from '../../containers/ImagePreview/ImagePreviewContainer'
import Image from '../../containers/Image'
import ImagesWrapper from './imagesWrapper.style'

import { Table } from 'antd'

const SupplierOrdersList = ({
    i18n,
    loading,
    onPageChange,
    pagination,
    supplierOrders
}) => {
    const renderImage = (images) => {
        if (!images || !images.length) return null
        return images.map((image) => {
            return (
                <Image
                    key={_.get(image, 'id', '')}
                    contentType={_.get(image, 'file.content_type', '')}
                    originalUrl={_.get(image, 'file.original', '')}
                    thumbUrl={_.get(image, 'file.s', '')}
                />
            )
        })
    }

    const columns = [
        {
            title: i18n.t('suppliers:supplierOrder.refCode'),
            dataIndex: 'ref_code'
        },
        {
            title: i18n.t('suppliers:state'),
            dataIndex: 'state_label'
        },
        {
            title: i18n.t('suppliers:grandTotal'),
            dataIndex: ['grand_total', 'display']
        },
        {
            title: i18n.t('suppliers:supplierOrder.freeNote'),
            render: (row) => {
                return (
                    <div>
                        {
                            row.external_note ? <div className="external-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.externalNote')}:
                                </span> {row.external_note}
                            </div> : null
                        }
                        {
                            row.note_with_sanitizer ? <div className="supplier-order-note">
                                <span style={{ fontWeight: 'bold' }}>
                                    {i18n.t('suppliers:supplierOrder.supplierOrderNote')}:
                                </span> {row.note_with_sanitizer}
                            </div> : null
                        }
                    </div>
                )
            }
        },
        {
            title: i18n.t('suppliers:attachFile'),
            dataIndex: 'supplier_order_images',
            render: (images) => {
                return (
                    <ImagesWrapper>
                        <div className="image-container">
                            {renderImage(images)}
                        </div>
                    </ImagesWrapper>
                )
            }
        }
    ]

    return (
        <Table
            columns={columns}
            dataSource={supplierOrders}
            loading={loading}
            onChange={onPageChange}
            pagination={pagination}
            rowKey="id"
        />
    )
}

SupplierOrdersList.propTypes = {
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    supplierOrders: PropTypes.array,
}

SupplierOrdersList.defaultProps = {
    loading: false,
    supplierOrders: [],
}

export default withTranslation('suppliers')(
    ImagePreviewContainer(SupplierOrdersList)
)
