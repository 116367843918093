import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import MailingAddressFormContainer from '../Addresses/MailingAddressFormContainer'

class CustomerNewMailingAddressPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Add Mailing Address | OSH CRM'
    }

    render () {
        const { customer_id: customerId } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/customers', name: 'Customers' },
                    { path: `/customers/${customerId}`, name: `${customerId}` },
                    { path: `/customers/${customerId}`, name: 'Mailing Addresses' },
                    { path: `/customers/${customerId}/mailing_addresses/new`, name: 'New' }
                ]}>
                    <ContentLayout>
                        <MailingAddressFormContainer customerId={customerId} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerNewMailingAddressPage
