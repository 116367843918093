import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Checkbox, Spin } from 'antd'

import actions from '../../../redux/orders/actions'

const { saveOrderShippingCost } = actions

const HideShippingCost = ({ id, hide, orderId }) => {
  const dispatch = useDispatch()
  const saving = useSelector(state => state.getIn(['orders', 'shippingCostSaving'], false))

  if (saving) return <Spin spinning />

  const handleChange = event => {
    dispatch(saveOrderShippingCost({ orderId, id, parameters: { hide: event.target.checked } }))
  }

  return (
    <Checkbox
      onChange={handleChange}
      checked={hide} />
  )
}

HideShippingCost.propTypes = {
  id: PropTypes.string.isRequired,
  hide: PropTypes.bool.isRequired,
  orderId: PropTypes.string.isRequired
}

HideShippingCost.defaultProps = {}

export default HideShippingCost
