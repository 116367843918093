import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button, Popconfirm } from 'antd'
import moment from 'moment'

import actions from '../../redux/supplierInvoices/actions'
import notificationActions from '../../redux/notifications/actions'

const { exportSupplierInvoiceVats } = actions
const { setNotifications } = notificationActions

const SupplierInvoicesVatExportContainer = ({ i18n, selectedMonth }) => {
    const dispatch = useDispatch()
    const supplierInvoices = useSelector(state => state.get('supplierInvoices'))
    const exporting = supplierInvoices.get('vatExporting')

    const onSubmit = () => {
        dispatch(exportSupplierInvoiceVats({
            params: { monthly: selectedMonth },
            onSuccess: () => {
                    dispatch(setNotifications(i18n.t('supplierInvoices:uploadSuccessDescription'), 'success', 'success'))
                },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        }))
    }

    const text = `${i18n.t('supplierInvoices:downloadVatDocument')} ${i18n.t('supplierInvoices:month')} ${moment(selectedMonth).format('MMMM YYYY')}`
    return (
      <Popconfirm placement="topLeft" title={text} onConfirm={onSubmit} okText="Yes" cancelText="No">
          <Button
              loading={exporting}
              type="primary">
            {i18n.t('supplierInvoices:downloadVatDocument')}
        </Button>
      </Popconfirm>
    )
}

export default withTranslation(['shared', 'supplierInvoices'])(SupplierInvoicesVatExportContainer)
