import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Divider, Switch, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'
import WalletsList from '../../components/walletSummaries/walletsList'

class WalletsListContainer extends Component {
    static propTypes = {
        hiddenColumns: PropTypes.array,
        loading: PropTypes.bool,
        onChangeApprove: PropTypes.func,
        onDelete: PropTypes.func,
        pagination: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object
        ]),
        searchWallets: PropTypes.func,
        setCustomerWalletFormModal: PropTypes.func,
        walletIds: ImmutablePropTypes.list
    }

    static defaultProps = {
        hiddenColumns: [],
        onChangeApprove: () => {},
        onDelete: () => {},
        pagination: false,
        searchWallets: () => {},
        setCustomerWalletFormModal: () => {},
        walletIds: new List()
    }

    componentDidMount () {
        this.props.searchWallets()
    }

    getColumns (isSuperAdmin) {
        const { onChangeApprove, onDelete, setCustomerWalletFormModal, i18n } = this.props

        return [
            {
                key: 'date',
                title: i18n.t('customers/wallets:date'),
                dataIndex: 'date',
                render: (date) => date ? moment(date).locale(i18n.language).format('LL') : ''
            },
            {
                key: 'amount',
                title: i18n.t('customers/wallets:amount'),
                dataIndex: ['amount', 'display'],
                render: (amount, record) => {
                    if (_.get(record, 'transaction_type') === 'deposit') { return amount }

                    return '-' + amount
                }
            },
            {
                key: 'transactionType',
                title: i18n.t('customers/wallets:transactionType'),
                dataIndex: 'transaction_type',
                render: (transactionType) => (
                    i18n.t(`customers/wallets:transactionTypes.${_.camelCase(transactionType)}`)
                )
            },
            {
                key: 'customer',
                title: i18n.t('customers/wallets:customer'),
                dataIndex: 'customer',
                render: (customer) => (
                    <Link to={`/customers/${_.get(customer, 'id')}`}>
                        {_.get(customer, 'first_name', '') + ' ' + _.get(customer, 'last_name', '')}
                    </Link>
                )
            },
            {
                key: 'detail',
                title: i18n.t('customers/wallets:detail'),
                dataIndex: 'order_number',
                render: (orderNumber, record) => {
                    if (_.get(record, 'transaction_type') !== 'transfer_back') { return orderNumber }
                    if (!orderNumber) { return '-' }

                    return `${orderNumber} (${_.get(record, 'ref_code')})`
                }
            },
            {
                key: 'note',
                title: i18n.t('customers/wallets:note'),
                dataIndex: 'note'
            },
            {
                key: 'approve',
                dataIndex: 'is_approved',
                render: (isApproved, record) => (
                    <>
                        <Switch
                            checked={isApproved}
                            disabled={!isSuperAdmin || isApproved}
                            onChange={(checked) => {
                                onChangeApprove({ customerId: _.get(record, 'customer.id'), id: record.id, checked })
                            }}
                            size="small"
                        />
                        <span style={{ marginLeft: '4px' }}>
                            {i18n.t('customers/wallets:approve')}
                        </span>
                    </>
                )
            },
            {
                key: 'actions',
                render: (record) => {
                    return (
                      <>
                          <a onClick={() => setCustomerWalletFormModal(true, record.id)}>
                            <EditOutlined />
                          </a>
                          <Divider type="vertical" />
                          <Popconfirm
                              title={i18n.t('customers/wallets:deleteConfirm')}
                              onConfirm={() => {
                                  onDelete({ customerId: _.get(record, 'customer.id'), id: record.id })
                              }}
                          >
                              <a href="#"><DeleteOutlined /></a>
                          </Popconfirm>
                      </>
                    )
                }
            }
        ]
    }

    getWallets = () => {
        const { customerEntities, customerWalletEntities, walletIds } = this.props
        return walletIds.map((id) => {
            const customerWallets = customerWalletEntities.get(id) || new Map()
            return new Map({
                ...customerWallets.toJS(),
                customer: customerEntities.get(_.get(customerWallets.toJS(), 'customer'))
            })
        })
    }

    handleTableChange = (pagination) => {
        const page = pagination.current

        this.props.searchWallets({ page })
    }

    filterHiddenColumns () {
        const { hiddenColumns, isCurrentStaffA } = this.props
        const isSuperAdmin = isCurrentStaffA('super_admin')
        const hiddenColumnArrays = isSuperAdmin ? hiddenColumns : hiddenColumns.concat('actions')

        return _.filter(this.getColumns(isSuperAdmin), (column) => {
            return !_.includes(hiddenColumnArrays, column.key)
        })
    }

    render () {
        const { loading, pagination } = this.props

        return (
            <WalletsList
                columns={this.filterHiddenColumns()}
                loading={loading}
                onTableChange={this.handleTableChange}
                pagination={pagination}
                wallets={this.getWallets().toJS()}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    customerWalletEntities: state.getIn(['Entities', 'customerWallets'])
})

export default connect(mapStateToProps)(
    withTranslation('customers/wallets')(currentStaffContainer(WalletsListContainer))
)
