import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SupplierInvoiceIndexPage from '../containers/SupplierInvoices'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const SupplierInvoicesPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'supplier_invoices') {
			dispatch(changeCurrent(['supplier_invoices']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={SupplierInvoiceIndexPage} exact />
		</Switch>
  )
}

SupplierInvoicesPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

SupplierInvoicesPage.defaultProps = {}

export default SupplierInvoicesPage
