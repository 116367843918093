import { Map, List } from 'immutable'
import actions from './actions'

const {
    CHECK_ORDER_VOUCHERS_SUCCESS,
    CHECK_ORDER_VOUCHERS,
    FETCH_ORDER_SHIPPING_COSTS_SUCCESS,
    FETCH_ORDER_SHIPPING_COSTS,
    ORDER_FETCH_SUCCESS,
    // ORDER_REDO_SALE,
    ORDER_SET_LOADING,
    ORDER_SHIPPING_COST_DELETE_SUCCESS,
    ORDER_SHIPPING_COST_SAVING,
    ORDER_SHIPPING_COST_FORM_ERRORS,
    ORDER_SHIPPING_COST_SAVE_SUCCESS,
    // ORDER_SUPPLIER_NOTE_FETCH_SUCCESS,
    // ORDER_SUPPLIER_NOTE_FETCH,
    ORDER_SUPPLIER_NOTE_SAVE_SUCCESS,
    ORDER_SUPPLIER_NOTE_SAVING,
    ORDER_SUPPLIER_NOTES_FETCH_SUCCESS,
    ORDER_SUPPLIER_NOTES_FETCH,
    // ORDER_UPDATE_SALE_DISTRIBUTION,
    ORDERS_CSES_FETCH_SUCCESS,
    ORDERS_CSES_FETCH,
    ORDERS_CSES_SET_QUERY,
    ORDERS_FETCH_SUCCESS,
    ORDERS_FETCHING,
    ORDERS_ORDER_STATES_FETCH_SUCCESS,
    ORDERS_ORDER_STATES_FETCH,
    ORDERS_PURCHASERS_FETCH_SUCCESS,
    ORDERS_PURCHASERS_FETCH,
    ORDERS_PURCHASERS_SET_QUERY,
    ORDERS_SALES_FETCH_SUCCESS,
    ORDERS_SALES_FETCH,
    ORDERS_SALES_SET_QUERY,
    ORDERS_SAVE_SUCCESS,
    ORDERS_SET_CSES_OPTIONS,
    ORDERS_SET_DATE,
    ORDERS_SET_FORM_ERRORS,
    ORDERS_SET_PAYMENT_OPTIONS,
    ORDERS_SET_PURCHASE_COMPANY,
    // ORDERS_SET_PURCHASERS_OPTIONS,
    ORDERS_SET_SALES_OPTIONS,
    ORDERS_SET_SAVING,
    ORDERS_SET_SELF_PICKUP,
    ORDERS_SET_TOTAL,
    // PURCHASER_FETCH_SUCCESS,
    SAVE_ORDER_COMPLETE_NOTE,
    SET_ORDER_SUPPLIER_NOTE_FORM_MODAL,
    // SET_SHIPPING_COST_ID,
    // SET_SHIPPING_COST_MODAL,
    SET_SHIPPING_ADDRESS_INFO,
    FETCH_ORDER_SHIPPING_RATE,
    FETCH_ORDER_SHIPPING_RATE_SUCCESS,
    SET_SHIPPING_RATE_PRICE,
    FETCH_ORDER_LINE_ITEM_IDS_SUCCESS,
    FETCH_ORDER_LINE_ITEM_IDS,
    ORDERS_SET_NO_SHIPPING_ADDRESS,
    SET_ORDER_SHIPPING_TYPE,
    SET_SHIPPING_ADDRESS_ERROR
} = actions

const initState = new Map({
    csesLoading: false,
    csItems: new List(),
    purchasesLoading: false,
    purchaseItems: new List(),
    // distributedOrder: new List(),
    formErrors: new Map(),
    items: new List(),
    loading: false,
    // orderCompleteNoteSaving: false,
    orderStates: new List(),
    orderStatesLoading: false,
    // paymentOptions: 'cash',
    purchaseCompany: false,
    queryCs: '',
    queryPurchaser: '',
    querySale: '',
    saleItems: new List(),
    salesLoading: false,
    saving: false,
    selfPickup: false,
    shippingDate: '',
    total: 0,
    // current: null,
    shippingLoading: false,
    // showShippingCostForm: false,
    shippingCostSaving: false,
    shippingCostItems: new List(),
    // shippingCostId: null,
    shippingCostFormErrors: new Map(),
    orderSupplierNoteSaving: false,
    orderSupplierNoteItems: new List(),
    isOpenOrderSupplierNoteForm: false,
    // orderSupplierNote: null,
    orderSupplierNoteLoading: false,
    provinceId: '',
    districtId: '',
    postalCode: '',
    shippingDetail: '',
    additionalInformation: '',
    contactNumber: '',
    shippingRate: null,
    shippingRateLoading: false,
    shippingRatePrice: null,
    lineItemidsList: [],
    lineItemidsLoading: false,
    isNoShippingAddress: false,
    shippingType: 'by_osh',
    shippingAddressError: false
})

export default function ordersReducer (state = initState, action) {
    const items = state.get('items', new List())
    const shippingCostItems = state.get('shippingCostItems')
    const orderSupplierNoteItems = state.get('orderSupplierNoteItems')
    switch (action.type) {
        case ORDERS_SET_DATE:
            return state.merge({
                shippingDate: action.value
            })
        case ORDERS_SET_SELF_PICKUP:
            return state.merge({
                selfPickup: action.value
            })
        case ORDERS_SET_PURCHASE_COMPANY:
            return state.merge({
                purchaseCompany: action.value
            })
        case ORDERS_SET_PAYMENT_OPTIONS:
            return state.merge({
                paymentOptions: action.value
            })
        case ORDERS_FETCHING:
            return state.merge({
                loading: true
            })
        case ORDERS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case ORDERS_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case ORDERS_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        case ORDERS_SAVE_SUCCESS:
            return state.merge({
                items: action.result
            })
        case ORDERS_CSES_SET_QUERY:
            return state.merge({
                queryCs: action.query
            })
        case ORDERS_CSES_FETCH:
            return state.merge({
                csesLoading: true,
                csItems: new List()
            })
        case ORDERS_CSES_FETCH_SUCCESS:
            return state.merge({
                csesLoading: false,
                csItems: new List(action.result)
            })
        case ORDERS_PURCHASERS_FETCH:
            return state.merge({
                purchasesLoading: true,
                purchaseItems: new List()
            })
        case ORDERS_PURCHASERS_FETCH_SUCCESS:
            return state.merge({
                purchasesLoading: false,
                purchaseItems: new List(action.result)
            })
        case ORDERS_PURCHASERS_SET_QUERY:
            return state.merge({
                queryPurchaser: action.query
            })
        // case ORDERS_SET_PURCHASERS_OPTIONS:
        //     return state.merge({
        //         queryPurchaser: action.query
        //     })
        case ORDERS_SET_CSES_OPTIONS:
            return state.merge({
                queryCs: action.query
            })
        case ORDERS_SALES_SET_QUERY:
            return state.merge({
                querySale: action.query
            })
        case ORDERS_SALES_FETCH:
            return state.merge({
                salesLoading: true,
                saleItems: new List()
            })
        case ORDERS_SALES_FETCH_SUCCESS:
            return state.merge({
                salesLoading: false,
                saleItems: new List().concat(action.result)
            })
        // case ORDER_UPDATE_SALE_DISTRIBUTION:
        //     return state.merge({
        //         distributedOrder: state.get('distributedOrder').push(action.orderId)
        //     })
        // case ORDER_REDO_SALE:
        //     return state.merge({
        //         distributedOrder: state.get('distributedOrder').filter(item => item !== action.orderId)
        //     })
        case ORDERS_SET_SALES_OPTIONS:
            return state.merge({
                querySale: action.query
            })
        case ORDERS_ORDER_STATES_FETCH:
            return state.merge({
                orderStatesLoading: true
            })
        case ORDERS_ORDER_STATES_FETCH_SUCCESS:
            return state.merge({
                orderStates: new List(action.result),
                orderStatesLoading: false
            })
        case ORDERS_SET_TOTAL:
            return state.merge({
                total: action.total
            })
        case FETCH_ORDER_SHIPPING_COSTS:
            return state.merge({
                shippingLoading: true
            })
        case FETCH_ORDER_SHIPPING_COSTS_SUCCESS:
            return state.merge({
                shippingLoading: false,
                shippingCostItems: new List(action.result)
            })
        // case SET_SHIPPING_COST_MODAL:
        //     return state.merge({
        //         showShippingCostForm: action.value
        //     })
        case ORDER_FETCH_SUCCESS:
            return state.merge({
                items: new List(items.includes(action.result) ? items : items.push(action.result))
            })
        case ORDER_SHIPPING_COST_SAVING:
            return state.merge({
                shippingCostSaving: action.saving
            })
        case ORDER_SHIPPING_COST_SAVE_SUCCESS:
            return state.merge({
                shippingCostItems: shippingCostItems.includes(action.result) ? shippingCostItems : shippingCostItems.push(action.result),
                shippingCostFormErrors: new Map()
            })
        case ORDER_SHIPPING_COST_FORM_ERRORS:
            return state.merge({
                shippingCostFormErrors: action.formErrors
            })
        // case SET_SHIPPING_COST_ID:
        //     return state.merge({
        //         shippingCostId: action.id
        //     })
        case ORDER_SHIPPING_COST_DELETE_SUCCESS:
            return state.merge({
                shippingCostItems: new List(shippingCostItems.delete(shippingCostItems.indexOf(action.result)))
            })
        case ORDER_SET_LOADING:
            return state.merge({
                loading: action.loading
            })
        case ORDER_SUPPLIER_NOTE_SAVING:
            return state.merge({
                orderSupplierNoteSaving: action.saving
            })
        case ORDER_SUPPLIER_NOTE_SAVE_SUCCESS:
            return state.merge({
                orderSupplierNoteItems: new List(orderSupplierNoteItems.includes(action.result) ?
                    orderSupplierNoteItems : orderSupplierNoteItems.push(action.result))
            })
        case SET_ORDER_SUPPLIER_NOTE_FORM_MODAL:
            return state.merge({
                isOpenOrderSupplierNoteForm: action.value
            })
        case ORDER_SUPPLIER_NOTES_FETCH:
            return state.merge({
                orderSupplierNoteLoading: true
            })
        case ORDER_SUPPLIER_NOTES_FETCH_SUCCESS:
            return state.merge({
                orderSupplierNoteItems: new List(action.result),
                orderSupplierNoteLoading: false
            })
        // case ORDER_SUPPLIER_NOTE_FETCH:
        //     return state.merge({
        //         orderSupplierNoteLoading: true
        //     })
        // case ORDER_SUPPLIER_NOTE_FETCH_SUCCESS:
        //     return state.merge({
        //         orderSupplierNoteLoading: false,
        //         orderSupplierNote: action.result
        //     })
        // case PURCHASER_FETCH_SUCCESS:
        //     return state.merge({
        //         purchaseItems: [].concat(action.result),
        //         purchasesLoading: false
        //     })
        case CHECK_ORDER_VOUCHERS:
            return state.merge({
                saving: action.saving
            })
        case CHECK_ORDER_VOUCHERS_SUCCESS:
            return state.merge({
                items: new List(action.result)
            })
        case SAVE_ORDER_COMPLETE_NOTE:
            return state.merge({
                orderCompleteNoteSaving: action.saving
            })
        case SET_SHIPPING_ADDRESS_INFO:
            return state.merge({
                provinceId: action.provinceId,
                districtId: action.districtId,
                postalCode: action.postalCode,
                shippingDetail: action.shippingDetail,
                additionalInformation: action.additionalInformation,
                contactNumber: action.contactNumber
            })
        case FETCH_ORDER_SHIPPING_RATE:
            return state.merge({
                shippingRateLoading: true
            })
        case FETCH_ORDER_SHIPPING_RATE_SUCCESS:
            return state.merge({
                shippingRateLoading: false,
                shippingRate: action.result
            })
        case SET_SHIPPING_RATE_PRICE:
            return state.merge({
                shippingRatePrice: action.price
            })
        case FETCH_ORDER_LINE_ITEM_IDS:
            return state.merge({
                lineItemidsLoading: true
            })
        case FETCH_ORDER_LINE_ITEM_IDS_SUCCESS:
            return state.merge({
                lineItemidsList: action.data,
                lineItemidsLoading: false
            })
        case ORDERS_SET_NO_SHIPPING_ADDRESS:
            return state.merge({
                isNoShippingAddress: action.value
            })
        case SET_ORDER_SHIPPING_TYPE:
            return state.merge({
                shippingType: action.shippingType
            })
        case SET_SHIPPING_ADDRESS_ERROR:
            return state.merge({
                shippingAddressError: action.value
            })
        default:
            return state
    }
}
