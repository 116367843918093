import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const SALE_PIPELINE_ENUM_QUERY = gql`
    query SalePipelineEnum($name: String!){
        salePipelineEnum(enumName: $name){
            label
            value
        }
    }
`

const renderOptions = (options = [], isForm) => {
    return options.map(({ label, value }) => {
        if (isForm && !value) return null

        return <Select.Option value={value}>{label}</Select.Option>
    })
}

const SalePipelineEnumSelector = ({ name, isForm, ...restProps }) => {
    const { loading, data } = useQuery(SALE_PIPELINE_ENUM_QUERY, { variables: { name } })

    return (
        <Select loading={loading} {...restProps}>
            {renderOptions(data && data.salePipelineEnum, isForm)}
        </Select>
    )
}

SalePipelineEnumSelector.propTypes = {
    name: PropTypes.string.isRequired,
    isForm: PropTypes.bool
}

SalePipelineEnumSelector.defaultProps = {
    isForm: false
}

export default SalePipelineEnumSelector
