import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import { Row, Button, Col, Form } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import AddressFormContainer from '../ShippingAddresses/AddressFormContainer'

import actionCustomer from '../../../redux/customers/actions'
const { setLocationFormAmount } = actionCustomer

class ShippingAddressFormContainer extends Component {
    static propTypes = {
        customers: ImmutablePropTypes.map.isRequired,
        form: PropTypes.object.isRequired,
        paramFieldName: PropTypes.string,
        params: PropTypes.string,
        setLocationFormAmount: PropTypes.func.isRequired
    }

    renderFormInput () {
        const { paramFieldName, form, i18n } = this.props

        return (
            <Form.List name={paramFieldName}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Row key={key} type="flex" justify="space-between">
                                <Col md={{ span: 23 }}>
                                    <AddressFormContainer
                                        formProps={form}
                                        isAnotherForm={true}
                                        locationParam={[name]}
                                    />
                                </Col>
                                <CloseCircleOutlined onClick={() => remove(name)} />
                            </Row>
                        ))}
                        <Button onClick={() => add()} style={{ marginBottom: '15px' }}>
                            {i18n.t('customerForm.addLocation')}
                        </Button>
                    </>
                )}
            </Form.List>
        )
    }

    render () {
        return (
            <div>
                {this.renderFormInput()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setLocationFormAmount
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('common')(ShippingAddressFormContainer))
