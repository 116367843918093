import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import RequestItemImport from '../../components/requestItems/import'

import actions from '../../redux/requestItemIndices/actions'
import notificationActions from '../../redux/notifications/actions'

const { requestItemIndices } = actions
const { setNotifications } = notificationActions

class RequestItemsIndexContainer extends Component {
    static propTypes = {
        // Inner Props
        requestItemIndices: PropTypes.func.isRequired
    }

    static defaultProps = {}

    onSubmit = ({ params = {} } = {}) => {
        const { i18n, requestItemIndices, setNotifications } = this.props
        requestItemIndices({
            params,
            onSuccess: () => {
                setNotifications(
                    i18n.t('bankStatements:uploadSuccess'),
                    i18n.t('bankStatements:uploadSuccessDescription'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }


    render () {
        return (
            <RequestItemImport
                onSubmit={this.onSubmit}
            />
        )
    }
}

const mapStateToProps = state => ({
    orderAccountings: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        requestItemIndices,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['confirms', 'bankStatements'])(RequestItemsIndexContainer))
