import React, { useState } from 'react'

import { Modal, Space, Tooltip, Typography, Badge } from 'antd'
import ActivityDetailStyle from './ActivityDetail.style'
import {
  EyeOutlined,
  MessageOutlined,
  MenuOutlined,
  ShopOutlined
} from '@ant-design/icons'
import OrderStaffs from '../../../../OrderStaffs'
import OrderModal from '../Modal'
import OrderNotesContainer from '../../../../../containers/Inquiry/OrderNotes/OrderNotesContainer'
import SupplierNotesContainer from '../../../../../containers/Inquiry/SupplierNotes/SupplierNotesContainer'

const ActivityDetail = ({ customerNo, deal, orderId, refetch }) => {
  const [showModal, setShowModal] = useState(false)
  const [showOrderNotes, setShowOrderNotes] = useState(false)
  const [showSupplierNotes, setShowSupplierNotes] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const toggleOrderNotesModal = () => {
    setShowOrderNotes(!showOrderNotes)
  }

  const toggleSupplierNotesModal = () => {
    setShowSupplierNotes(!showSupplierNotes)
  }

  const renderProvinceName = (order) => {
    if (!order.orderShippingAddress) return null
    return (
      <div style={{ textAlign: 'right' }}>
        <span style={{ marginRight: 4 }}>
          {order.orderShippingAddress.province.name}
        </span>
        <span>{order.orderShippingAddress.district.name}</span>
      </div>
    )
  }

  return (
    <ActivityDetailStyle>
      <Space align='center' className='space-justify-content-between'>
        <div className='margin-top-8'>
          <OrderStaffs order={deal.order} onSaveSuccess={() => refetch()} />
        </div>

        <div>
          <div style={{ textAlign: 'right' }}>
            {renderProvinceName(deal.order)}
            <Tooltip title={'จำนวนโรงงาน'}>
              <ShopOutlined style={{ marginRight: 4 }} />
              {deal.order.supplierOrders.length}
            </Tooltip>
            <Tooltip title={'จำนวนรายการ'}>
              <MenuOutlined style={{ marginRight: 4, marginLeft: 16 }} />
              {deal.order.lineItemsCount}
            </Tooltip>
          </div>
          <div>
            <Space align='middle'>
              <div style={{ marginRight: 8 }}>
                <Badge
                  size='small'
                  count={deal.customerNotesCount}
                  offset={[3, -1]}>
                  <MessageOutlined />
                  <Typography.Link onClick={toggleOrderNotesModal}>
                    {' '}
                    ลูกค้า
                  </Typography.Link>
                </Badge>
              </div>
              <div style={{ marginRight: 8 }}>
                <Badge
                  size='small'
                  count={deal.orderSupplierNotesCount}
                  offset={[3, -1]}>
                  <MessageOutlined />
                  <Typography.Link onClick={toggleSupplierNotesModal}>
                    {' '}
                    supplier
                  </Typography.Link>
                </Badge>
              </div>
              <div>
                <EyeOutlined />
                <Typography.Link onClick={toggleModal}>
                  {' '}
                  ดูออเดอร์
                </Typography.Link>
              </div>
            </Space>
          </div>
        </div>
      </Space>

      <OrderModal
        showModal={showModal}
        orderNumber={orderId}
        setShowModal={toggleModal}
      />

      <Modal
        footer={null}
        onCancel={toggleOrderNotesModal}
        visible={showOrderNotes}
        width={'50%'}>
        <OrderNotesContainer customerNo={customerNo} orderId={orderId} />
      </Modal>
      <Modal
        footer={null}
        onCancel={toggleSupplierNotesModal}
        visible={showSupplierNotes}
        width={'50%'}>
        <SupplierNotesContainer orderId={orderId} />
      </Modal>
    </ActivityDetailStyle>
  )
}

export default ActivityDetail
