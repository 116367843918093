import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import JournalEntryFormatItemForm from '../../../components/journalEntryFormats/items/JournalEntryFormatItemForm'

import actions from '../../../redux/journalEntryFormats/items/actions'
import notificationActions from '../../../redux/notifications/actions'

const { saveJournalEntryFormatItem } = actions
const { setNotifications } = notificationActions

class JournalEntryFormatItemFormContainer extends Component {
  getJournalEntryFormatItem () {
    const { journalEntryFormatItemEntities, journalEntryFormatItemId } = this.props

    if (!journalEntryFormatItemId) { return new Map() }

    return journalEntryFormatItemEntities.get(journalEntryFormatItemId) || new Map()
  }

  handleSaveJournalEntryFormatItem = (params) => {
    const { saveJournalEntryFormatItem, setNotifications, toggleForm, journalEntryFormatId, journalEntryFormatItemId } = this.props

    saveJournalEntryFormatItem({
        id: journalEntryFormatItemId,
        journalEntryFormatId: journalEntryFormatId,
        params,
        onSuccess: () => {
            setNotifications('success', 'saveSuccess', 'success')
            toggleForm()
        },
        onError: (error) => {
            setNotifications(`${error.name}`, `${error.message}`, 'error')
        }
    })
  }

  render() {
    const { toggleForm, journalEntryFormatItems } = this.props

    return (
      <JournalEntryFormatItemForm
        errors={journalEntryFormatItems.get('errors').toJS()}
        loading={journalEntryFormatItems.get('loading')}
        onSubmitForm={this.handleSaveJournalEntryFormatItem}
        saving={journalEntryFormatItems.get('saving')}
        toggleForm={toggleForm}
        journalEntryFormatItem={this.getJournalEntryFormatItem().toJS()}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  journalEntryFormatItemEntities: state.getIn(['Entities', 'journalEntryFormatItems']),
  journalEntryFormatItems: state.get('journalEntryFormatItems')
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      saveJournalEntryFormatItem,
      setNotifications
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JournalEntryFormatItemFormContainer)
