import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Table } from 'antd'
import { withTranslation } from 'react-i18next'

const ProfitSharingsIndex = ({
    i18n,
    profitSharings,
    loading,
    onPageChange,
    pagination
}) => {
    const columns = [
        {
            title: i18n.t('profitSharing:orderNumber'),
            dataIndex: 'number'
        },
        {
            title: i18n.t('profitSharing:saleName'),
            dataIndex: 'sale_name'
        }
    ]

    return (
        <div>
            <Table
                columns={columns}
                dataSource={profitSharings}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
        </div>
    )
}

ProfitSharingsIndex.propTypes = {
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.object,
    profitSharings: PropTypes.object
}

ProfitSharingsIndex.defaultProps = {
    loading: false,
    pagination: 1,
    onPageChange: () => {},
    profitSharings: {}
}

export default withTranslation('profitSharing')(ProfitSharingsIndex)
