import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Form, Space, Input, Button } from 'antd'

import SelectLalamoveVehicleTypes from '../../../lalamove/selectVehicleTypes'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const formLongItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const TruckReservationLalamoveFormRender = ({
  onSubmit,
  initialValues,
  errorsMessage,
  saving,
  customer,
  orderShippingAddress
}) => {
  const { i18n } = useTranslation('inquiries')

  const handleSubmit = (values) => {
    const params = {
      senderName: values['senderName'],
      senderPhone: values['senderPhone'],
      recipientAddressWithContact: values['locations'].map((location) => {
        return {
          sendLalamoveShippingAddressId: location.sendLalamoveShippingAddressId,
          recipientName: location.recipientName,
          recipientPhone: location.recipientPhone,
          deliveryDetailRemarks: location.deliveryDetailRemarks
        }
      })
    }

    onSubmit(params)
  }

  const contactName = orderShippingAddress ? orderShippingAddress.contactName : customer.fullName
  const phoneNumber = orderShippingAddress ? orderShippingAddress.phoneNumber : customer.phoneNumber

  return (
    <Form onFinish={handleSubmit} {...formItemLayout} initialValues={initialValues}>
      { errorsMessage ? <div style={{ color: 'red' }}>{errorsMessage}</div> : null}
      <Form.Item label={i18n.t('inquiries:deliveree.vehicleType')}  name='serviceType'>
        <SelectLalamoveVehicleTypes placeholder={i18n.t('inquiries:lalamove.selectVehicleType')} disabled />
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:lalamove.pickupAddress')}>
        <Form.Item {...formLongItemLayout}
          label={i18n.t('inquiries:lalamove.senderName')}
          name={['senderName']}
          rules={[{ required: true, message: 'Please Input Sender Name'}]}>
            <Input placeholder={i18n.t('inquiries:lalamove.senderName')}
              style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item {...formLongItemLayout}
          label={i18n.t('inquiries:lalamove.senderPhone')}
          name={['senderPhone']}
          rules={[{ required: true, message: 'Please Input Sender Phone'}]}>
            <Input placeholder={i18n.t('inquiries:lalamove.senderPhone')}
              style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item>
          <Input.TextArea placeholder={i18n.t('inquiries:lalamove.pickupAddress')} disabled
            defaultValue={initialValues.pickupLocation}
            rows={3} />
        </Form.Item>
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:lalamove.shippingAddress')}
        style={{ marginBottom: 0 }}>
        <Form.List name='locations'>
          {(fields) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} align="baseline" style={{ position: 'relative'}}>
                  <Form.Item {...restField} hidden
                    name={[name, 'sendLalamoveShippingAddressId']}
                    fieldKey={[fieldKey]}>
                      <Input />
                  </Form.Item>
                  <Form.Item {...restField} {...formLongItemLayout}
                    label={i18n.t('inquiries:lalamove.recipientName')}
                    initialValue={contactName}
                    name={[name, 'recipientName']}
                    rules={[{ required: true, message: 'Please Input Recipient Name'}]}
                    fieldKey={[fieldKey]}>
                      <Input placeholder={i18n.t('inquiries:lalamove.recipientName')}
                        style={{ width: '100%' }}/>
                  </Form.Item>
                  <Form.Item {...restField} {...formLongItemLayout}
                    label={i18n.t('inquiries:lalamove.recipientPhone')}
                    name={[name, 'recipientPhone']}
                    initialValue={phoneNumber}
                    rules={[{ required: true, message: 'Please Input Recipient Phone'}]}
                    fieldKey={[fieldKey]}>
                      <Input placeholder={i18n.t('inquiries:lalamove.recipientPhone')}
                        style={{ width: '100%' }} />
                  </Form.Item>
                  <Form.Item>
                    <Input.TextArea placeholder={i18n.t('inquiries:lalamove.shippingAddress')}
                      defaultValue={initialValues.locations[name].address}
                      style={{ width: '100%' }} disabled rows={3}/>
                  </Form.Item>
                  <Form.Item {...restField}
                    name={[name, 'deliveryDetailRemarks']}
                    fieldKey={[fieldKey]}>
                      <Input.TextArea placeholder={i18n.t('inquiries:lalamove.placeholderRemarks')}
                        maxlength="1500"
                        style={{ width: '100%' }} rows={3}/>
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:lalamove.remarks')} name='remarks'>
        <Input.TextArea placeholder={'inquiries:lalamove.placeholderRemarks'} 
          style={{ width: '100%' }} rows={3} />
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button loading={saving} type="primary" htmlType="submit">
            {i18n.t('inquiries:lalamove.reserveTruck')}
          </Button>
        </Space>
      </div>
  </Form>
  )
}

TruckReservationLalamoveFormRender.propTypes = {}

TruckReservationLalamoveFormRender.defaultProps = {}

export default TruckReservationLalamoveFormRender
