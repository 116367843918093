const actions = {
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL: 'SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL',
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_REQUEST: 'SUPPLIER_OVER_TRANSFER_ITEM_DELETE_REQUEST',
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS: 'SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS',
    SUPPLIER_OVER_TRANSFER_ITEM_DELETING: 'SUPPLIER_OVER_TRANSFER_ITEM_DELETING',

    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL: 'SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL',
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_REQUEST: 'SUPPLIER_OVER_TRANSFER_ITEM_SAVE_REQUEST',
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS: 'SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS',
    SUPPLIER_OVER_TRANSFER_ITEM_SAVING: 'SUPPLIER_OVER_TRANSFER_ITEM_SAVING',

    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL: 'SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL',
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_REQUEST: 'SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_REQUEST',
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS: 'SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS',
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING: 'SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING',

    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_REQUEST: 'SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_REQUEST',
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH: 'SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH',
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS: 'SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS',

    fetchOrderSupplierOverTransferItems: ({
        orderId = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_REQUEST,
            payload: { orderId, onSuccess, onError },
            scope: 'order'
        }
    },

    fetchSupplierWithOverTransferItems: ({
        supplierId = '',
        page = 1,
        per = 20,
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_REQUEST,
        payload: { supplierId, page, per, onSuccess, onError }
    }),

    saveSupplierSupplierOverTransferItem: ({
        supplierId = '',
        id = '',
        supplierOverTransferItemParams = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_OVER_TRANSFER_ITEM_SAVE_REQUEST,
            payload: { supplierId, id, supplierOverTransferItemParams, onSuccess, onError },
            scope: 'supplier'
        }
    },

    deleteSupplierSupplierOverTransferItem: ({
        supplierId = '',
        id = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        if (!id || !supplierId) return null

        return {
            type: actions.SUPPLIER_OVER_TRANSFER_ITEM_DELETE_REQUEST,
            payload: { supplierId, id, onSuccess, onError },
            scope: 'supplier'
        }
    }
}

export default actions
