import { Map, List } from 'immutable'
import actions from './actions'

const {
    // ADD_LINE_ITEM_SAVING,
    // ADD_LINE_ITEM_SUCCESS,
    CLEAR_INQUIRY_LINE_ITEMS_SUCCESS,
    DELETE_INQUIRY_LINE_ITEMS_SUCCESS,
    DUPLICATE_ORDER_SAVING,
    DUPLICATE_ORDER_SUCCESS,
    FETCH_BANK_ACCOUNTS_SUCCESS,
    FETCH_BANK_ACCOUNTS,
    FETCH_PAYMENT_OPTIONS_SUCCESS,
    FETCH_PAYMENT_OPTIONS,
    FETCH_INQUIRY_CUSTOMER_SUCCESS,
    FETCH_INQUIRY_ITEMS_REQUEST,
    FETCH_INQUIRY_ITEMS_SUCCESS,
    FETCH_INQUIRY_LINE_ITEMS_REQUEST,
    FETCH_INQUIRY_LINE_ITEMS_SUCCESS,
    // FETCH_INQUIRY_QUOTATION_PDF_REQUEST,
    // FETCH_INQUIRY_QUOTATION_PDF_SUCCESS,
    FETCH_INQUIRY_REQUEST,
    FETCH_INQUIRY_SUCCESS,
    FETCH_ITEM_BY_NAME_SUCCESS,
    // FETCH_ITEM_PRICE_HISTORY_SUCCESS,
    // FETCH_ITEM_PRICE_HISTORY,
    // FETCH_SOURCING_PROFILE_SUCCESS,
    // FETCH_SOURCING_PROFILE,
    FETCH_VEHICLE_TYPES_SUCCESS,
    FETCH_VEHICLE_TYPES,
    FETCHING_INQUIRY_CUSTOMER,
    INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING,
    INQUIRY_LINE_ITEMS_EXPORTING,
    INQUIRY_SET_ITEMS_PAGINATION,
    SAVE_INQUIRY_ERRORS,
    SAVE_INQUIRY_LINE_ITEMS_ERRORS,
    SAVE_INQUIRY_LINE_ITEMS_SUCCESS,
    SAVE_INQUIRY_SUCCESS,
    SAVE_INQUIRY,
    SET_DUPLICATE_ORDER_MODAL,
    SET_INQUIRY_CONDITIONS_AMOUNT,
    SET_INQUIRY_ITEM_QUERY,
    SET_INQUIRY_ITEM_SEARCH_QUERY,
    SET_INQUIRY_LINE_ITEMS_PAGINATION,
    SET_INQUIRY_REQUEST_ITEM,
    SET_INQUIRY_SHIPPING_COST_AMOUNT,
    SET_INQUIRY_SHOW_ISSUE_TAX_INVOICE_FORM,
    // SET_ITEM_HISTORY_ID,
    SET_ITEM_LOADING,
    SET_SAVING_INQUIRY_LINE_ITEMS,
    SET_SELECT_LINE_ITEM,
    SET_SHIPPING_COST_SUCCESS,
    SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS,
    SET_SHOW_ADD_ITEM_FORM,
    SET_SHOW_MARGIN_FORM,
    SET_SHOW_MARGIN_PERCENT_FORM,
    SET_TRUCK_RESERVATION_MODAL,
    SET_VAT_INCLUDED,
    // SET_VIEW_ITEM_HISTORY_MODAL,
    SET_INQUIRY_SHOW_PROFIT_SHARING_FORM,
    INQUIRY_SET_SHOW_PROJECT_CONTACT_FORM,
    INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT,
    SET_INQUIRY_SHOW_CREDIT_CARD_INSTALLMENT_FORM,
    SET_SHOW_ADD_PRODUCT_GROUP_ITEM_FORM,
    SET_SHOW_ITEM_AS_CARD,
    SET_SHOW_ADD_MEGA_HOME_ITEM_FORM,
    SET_SELECTED_MEGA_HOME_ITEM_IDS,
    SET_MEGA_HOME_ITEM_QUANTITY_SELECTED,
    INQUIRY_TOGGLE_ITEM_PRICES_MODAL,
    SET_INQUIRY_SHOW_SUPPLIER_NOTES_FORM,
    SET_INQUIRY_SHOW_ORDER_NOTES_FORM,
    SET_INQUIRY_HEADER_CATEGORY_FORM,
    SET_TRUCK_RESERVATION_DERIVEREE_MODAL,
    SET_TRUCK_RESERVATION_LALAMOVE_MODAL
} = actions

const initState = new Map({
    bankAccountItems: new List(),
    bankAccountsLoading: false,
    current: null,
    currentCustomer: null,
    customerLoading: true,
    duplicateOrderSaving: false,
    formErrors: new Map(),
    item: null,
    itemCategoryId: null,
    // itemHistoryId: null,
    itemPasteNameLoading: false,
    itemProductTypeId: null,
    itemQuery: null,
    items: new List(),
    itemsLoading: false,
    itemsPagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null,
        position: 'both'
    }),
    // itemsPriceHistoryLoading: false,
    itemSubCategoryId: null,
    lineItemPdfExporting: false,
    lineItemformErrors: new Map(),
    itemId: null,
    lineItems: new List(),
    lineItemsLoading: true,
    lineItemsPagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    lineItemsSaving: false,
    lineItemsUploading: false,
    loading: true,
    orderConditionAmount: new List(),
    paymentOptionItems: new List(),
    paymentOptionLoading: false,
    // priceHirtoryItems: new List(),
    // quotationPdfLoading: false,
    // quotationPdfPreviewing: false,
    requestItemId: null,
    saveOrderLoading: false,
    selectLineItem: new List(),
    shippingCostAmount: new List(),
    shippingCostId: null,
    showAddItemForm: false,
    showCategoriesForm: false,
    showConditionForm: false,
    showCsForm: false,
    showDiscountForm: false,
    showDuplicateOrder: false,
    showIssueTaxInvoiceForm: false,
    showMarginForm: false,
    // showMarginPercentForm: false,
    showNoteForm: false,
    showPaymentForm: false,
    showPurchaserForm: false,
    showSaleForm: false,
    showShippingAddressForm: false,
    showShippingCostForm: false,
    // showShippingDateForm: false,
    showTransferForm: false,
    showTruckReservationModal: false,
    // showViewItemHistory: false,
    // sourcingProfileId: null,
    // sourcingProfileItem: null,
    // sourcingProfileLoading: true,
    vatIncluded: false,
    vehicleTypeItems: new List(),
    vehicleTypesLoading: false,
    lineItemExporting: false,
    showProfitSharingForm: false,
    showProjectContactForm: false,
    showCreditCardInstallmentForm: false,
    showProductGroupItemForm: false,
    showItemAsCard: 'card',
    showMegaHomeItemForm: false,
    selectedMegaHomeItemIds: new List(),
    megaHomeItemsQtySelected: new Map(),
    showItemPricesModal: false,
    showSupplierNotesForm: false,
    showOrderNotesForm: false,
    showCategoryForm: false,
    showTruckReservationDeliveree: false,
    showTruckReservationLalamove: false
})


export default function inquiryReducer (state = initState, action) {
    const result = action.result
    const lineItems = state.get('lineItems')

    switch (action.type) {
        case FETCH_INQUIRY_REQUEST:
            return state.merge({
                loading: true
            })
        case FETCH_INQUIRY_SUCCESS:
            return state.merge({
                loading: false,
                current: action.result
            })
        case SET_INQUIRY_ITEM_SEARCH_QUERY:
            return state.merge({
                itemQuery: action.itemQuery,
                itemsLoading: true
            })
        case SET_INQUIRY_ITEM_QUERY:
            return state.merge({
                itemQuery: action.itemQuery
            })
        case FETCH_INQUIRY_LINE_ITEMS_REQUEST:
            return state.merge({
                lineItemsLoading: true
            })
        case FETCH_INQUIRY_LINE_ITEMS_SUCCESS:
            return state.merge({
                lineItemsLoading: false,
                lineItems: new List(action.result)
            })
        case FETCHING_INQUIRY_CUSTOMER:
            return state.merge({
                customerLoading: true
            })
        case FETCH_INQUIRY_CUSTOMER_SUCCESS:
            return state.merge({
                customerLoading: false,
                currentCustomer: action.result
            })
        case FETCH_INQUIRY_ITEMS_REQUEST:
            return state.merge({
                itemsLoading: true
            })
        case FETCH_INQUIRY_ITEMS_SUCCESS:
            return state.merge({
                itemsLoading: false,
                items: new List(action.result)
            })
        case INQUIRY_SET_ITEMS_PAGINATION:
            return state.mergeDeep({
                itemsPagination: new Map(action.itemsPagination)
            })
        // case FETCH_INQUIRY_QUOTATION_PDF_REQUEST:
        //     return state.merge({
        //         quotationPdfLoading: true
        //     })
        // case FETCH_INQUIRY_QUOTATION_PDF_SUCCESS:
        //     return state.merge({
        //         quotationPdf: action.quotationPdf,
        //         quotationPdfLoading: false
        //     })
        case actions.SET_INQUIRY_ITEM_CATEGORY:
            return state.merge({
                itemsLoading: true,
                itemCategoryId: action.categoryId,
                itemSubCategoryId: null,
                itemProductTypeId: null
            })
        case actions.SET_INQUIRY_ITEM_SUB_CATEGORY:
            return state.merge({
                itemsLoading: true,
                itemSubCategoryId: action.subCategoryId,
                itemProductTypeId: null
            })
        case actions.SET_INQUIRY_ITEM_PRODUCT_TYPE:
            return state.merge({
                itemsLoading: true,
                itemProductTypeId: action.productTypeId
            })
        // case actions.SET_CURRENT_ITEM_PAGE:
        //     return state.merge
        case actions.SET_INQUIRY_SHOW_TRANSFER_FORM:
            return state.merge({
                showTransferForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_PAYMENT_FORM:
            return state.merge({
                showPaymentForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_NOTE_FORM:
            return state.merge({
                showNoteForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_SHIPPING_ADDRESS_FORM:
            return state.merge({
                showShippingAddressForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_SALE_FORM:
            return state.merge({
                showSaleForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_CS_FORM:
            return state.merge({
                showCsForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_PURCHASER_FORM:
            return state.merge({
                showPurchaserForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_SHIPPING_COST_FORM:
            return state.merge({
                showShippingCostForm: action.value
            })
        // case actions.SET_INQUIRY_SHOW_SHIPPING_DATE_FORM:
        //     return state.merge({
        //         showShippingDateForm: action.value
        //     })
        case actions.SET_INQUIRY_SHOW_CONDITION_FORM:
            return state.merge({
                showConditionForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_DISCOUNT_FORM:
            return state.merge({
                showDiscountForm: action.value
            })
        case actions.SET_INQUIRY_SHOW_CATEGORY_FORM:
            return state.merge({
                showCategoriesForm: action.value
            })
        case FETCH_BANK_ACCOUNTS:
            return state.merge({
                bankAccountsLoading: true,
                bankAccountItems: new List()
            })
        case FETCH_BANK_ACCOUNTS_SUCCESS:
            return state.merge({
                bankAccountsLoading: false,
                bankAccountItems: new List(action.result)
            })
        case FETCH_PAYMENT_OPTIONS:
            return state.merge({
                paymentOptionLoading: true,
                paymentOptionItems: new List()
            })
        case FETCH_PAYMENT_OPTIONS_SUCCESS:
            return state.merge({
                paymentOptionLoading: false,
                paymentOptionItems: new List(action.result)
            })
        case FETCH_VEHICLE_TYPES:
            return state.merge({
                vehicleTypesLoading: true,
                vehicleTypeItems: new List()
            })
        case FETCH_VEHICLE_TYPES_SUCCESS:
            return state.merge({
                vehicleTypesLoading: false,
                vehicleTypeItems: new List(action.result)
            })
        case SAVE_INQUIRY:
            return state.merge({
                saveOrderLoading: true
            })
        case SET_SELECT_LINE_ITEM:
            return state.merge({
                selectLineItem: action.value
            })
        // case ADD_LINE_ITEM_SAVING:
        //     return state.merge({
        //         lineItemsSaving: true
        //     })
        // case ADD_LINE_ITEM_SUCCESS:
        //     return state.merge({
        //         lineItemsSaving: false,
        //         lineItems: new List(action.result)
        //     })
        case SET_SHOW_ADD_ITEM_FORM:
            return state.merge({
                showAddItemForm: action.value,
                items: new List(),
                formErrors: new Map(),
                itemsPagination: new Map({
                    current: null,
                    defaultPageSize: 20,
                    total: null,
                    position: 'both'
                }),
                itemQuery: null,
                itemCategoryId: null,
                itemSubCategoryId: null,
                itemProductTypeId: null
            })
        case SET_SHOW_ADD_PRODUCT_GROUP_ITEM_FORM:
            return state.merge({
                showProductGroupItemForm: action.value,
            })
        case SET_SHOW_ADD_MEGA_HOME_ITEM_FORM:
            return state.merge({
                showMegaHomeItemForm: action.value,
            })
        case SAVE_INQUIRY_SUCCESS:
            return state.merge({
                formErrors: new Map(),
                saveOrderLoading: false
            })
        case SAVE_INQUIRY_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors),
                saveOrderLoading: false
            })
        case SET_INQUIRY_SHIPPING_COST_AMOUNT:
            return state.merge({
                shippingCostAmount: new List(action.amount)
            })
        case SET_INQUIRY_CONDITIONS_AMOUNT:
            return state.merge({
                orderConditionAmount: new List(action.amount)
            })
        case SET_SAVING_INQUIRY_LINE_ITEMS:
            return state.merge({
                lineItemsSaving: action.saving
            })
        case DELETE_INQUIRY_LINE_ITEMS_SUCCESS:
            return state.merge({
                lineItems: new List(lineItems.delete(lineItems.indexOf(action.result)))
            })
        case SAVE_INQUIRY_LINE_ITEMS_SUCCESS:
            return state.merge({
                lineItems: new List(lineItems.includes(result) ? lineItems : lineItems.push(result))
            })
        case SAVE_INQUIRY_LINE_ITEMS_ERRORS:
            return state.merge({
                lineItemformErrors: new Map(action.formErrors)
            })
        case SET_INQUIRY_REQUEST_ITEM:
            return state.merge({
                requestItemId: action.itemId
            })
        case SET_VAT_INCLUDED:
            return state.merge({
                vatIncluded: action.value
            })
        case DUPLICATE_ORDER_SAVING:
            return state.merge({
                duplicateOrderSaving: true
            })
        case DUPLICATE_ORDER_SUCCESS:
            return state.merge({
                duplicateOrderSaving: false
            })
        case SET_INQUIRY_LINE_ITEMS_PAGINATION:
            return state.mergeDeep({
                lineItemsPagination: new Map(action.pagination)
            })
        case SET_SHOW_MARGIN_FORM:
            return state.merge({
                showMarginForm: action.value
            })
        case SET_SHOW_MARGIN_PERCENT_FORM:
            return state.merge({
                showMarginPercentForm: action.value
            })
        case FETCH_ITEM_BY_NAME_SUCCESS:
            return state.merge({
                item: action.result[0] ? action.result[0] : null
            })
        case SET_ITEM_LOADING:
            return state.merge({
                itemPasteNameLoading: action.loading
            })
        // case SET_VIEW_ITEM_HISTORY_MODAL:
        //     return state.merge({
        //         showViewItemHistory: action.value
        //     })
        // case FETCH_ITEM_PRICE_HISTORY:
        //     return state.merge({
        //         itemsPriceHistoryLoading: true
        //     })
        // case FETCH_ITEM_PRICE_HISTORY_SUCCESS:
        //     return state.merge({
        //         itemsPriceHistoryLoading: false,
        //         priceHirtoryItems: action.result
        //     })
        // case SET_ITEM_HISTORY_ID:
        //     return state.merge({
        //         itemHistoryId: action.itemId,
        //         sourcingProfileId: action.sourcingProfileId
        //     })
        // case FETCH_SOURCING_PROFILE:
        //     return state.merge({
        //         sourcingProfileLoading: true
        //     })
        // case FETCH_SOURCING_PROFILE_SUCCESS:
        //     return state.merge({
        //         sourcingProfileItem: action.result,
        //         sourcingProfileLoading: false
        //     })
        case SET_SHIPPING_COST_SUCCESS:
            return state.merge({
                shippingCostAmount: new List([action.result])
            })
        case SET_SHIPPING_COST_WITH_LESS_TRUCK_LOAD_SUCCESS:
            return state.merge({
                shippingCostAmount: new List([action.result])
            })
        case CLEAR_INQUIRY_LINE_ITEMS_SUCCESS:
            return state.merge({
                lineItems: new List(action.result)
            })
        case INQUIRY_LINE_ITEMS_IMPORT_FILE_SET_UPLOADING:
            return state.merge({
                lineItemsUploading: action.uploading
            })
        case SET_TRUCK_RESERVATION_MODAL:
            return state.merge({
                showTruckReservationModal: action.value,
                shippingCostId: action.id
            })
        case SET_DUPLICATE_ORDER_MODAL:
            return state.merge({
                showDuplicateOrder: action.value
            })
        case SET_INQUIRY_SHOW_ISSUE_TAX_INVOICE_FORM:
            return state.merge({
                showIssueTaxInvoiceForm: action.value
            })
        case INQUIRY_LINE_ITEMS_EXPORTING:
            return state.merge({
                lineItemExporting: action.loading
            })
        case INQUIRY_LINE_ITEMS_EXPORTING_PDF_DOCUMENT:
            return state.merge({
                lineItemPdfExporting: action.loading
            })
        case SET_INQUIRY_SHOW_PROFIT_SHARING_FORM:
            return state.merge({
                showProfitSharingForm: action.value
            })
        case actions.INQUIRY_SET_SHOW_PROJECT_CONTACT_FORM:
            return state.merge({
                showProjectContactForm: action.value
            })
        case SET_INQUIRY_SHOW_CREDIT_CARD_INSTALLMENT_FORM:
            return state.merge({
                showCreditCardInstallmentForm: action.value
            })
        case SET_SHOW_ITEM_AS_CARD:
            return state.merge({
                showItemAsCard: action.value
            })
        case SET_SELECTED_MEGA_HOME_ITEM_IDS:
            return state.merge({
                selectedMegaHomeItemIds: new List(action.id)
            })
        case SET_MEGA_HOME_ITEM_QUANTITY_SELECTED:
            return state.mergeDeep({
                megaHomeItemsQtySelected: new Map(action.quantity)
            })
        case INQUIRY_TOGGLE_ITEM_PRICES_MODAL:
            return state.merge({
                itemId: action.itemId,
                showItemPricesModal: !state.get('showItemPricesModal')
            })
        case SET_INQUIRY_SHOW_SUPPLIER_NOTES_FORM:
            return state.merge({
                showSupplierNotesForm: action.value
            })
        case SET_INQUIRY_SHOW_ORDER_NOTES_FORM:
            return state.merge({
                showOrderNotesForm: action.value
            })
        case SET_INQUIRY_HEADER_CATEGORY_FORM:
            return state.merge({
                showCategoryForm: action.value
            })
        case SET_TRUCK_RESERVATION_DERIVEREE_MODAL:
            return state.merge({
                showTruckReservationDeliveree: action.value,
                shippingCostId: action.id
            })
        case SET_TRUCK_RESERVATION_LALAMOVE_MODAL:
            return state.merge({
                showTruckReservationLalamove: action.value,
                shippingCostId: action.id
            })
        default:
            return state
    }
}
