import styled from 'styled-components'

const TicketNoteListWrapper = styled.div`
  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img,
  .image-container > a > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    max-height: 50px;
    max-width: 50px;
  }
`

export default TicketNoteListWrapper
