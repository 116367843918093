import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'
import { isMobile } from 'react-device-detect'

import { Button, Popconfirm, Table } from 'antd'
import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import businessAddressesActions from '../../../redux/customers/addresses/businesses/actions'

const { fetchBusinessAddresses, deleteBusinessAddress } = businessAddressesActions

class BusinessAddressIndexContainer extends Component {
    componentDidMount () {
        const { fetchBusinessAddresses, customerId } = this.props

        fetchBusinessAddresses(customerId, 1)
    }

    onTableChange = (pagination) => {
        const { fetchBusinessAddresses, customerId } = this.props

        fetchBusinessAddresses(customerId, pagination.current)
    }

    getPagination () {
        const { businessAddresses } = this.props

        return businessAddresses.get('pagination') || new Map()
    }

    handleDelete = (customerId, businessAddressId) => {
        const { deleteBusinessAddress } = this.props

        deleteBusinessAddress(customerId, businessAddressId)
    }

    renderBusinessAddresses () {
        const { businessAddresses, businessAddressEntities } = this.props

        if (!businessAddresses) { return null }


        const addressesList = businessAddresses.get('items').map((addressId, index) => {
            const address = businessAddressEntities.get(addressId.toString())

            const id = address.get('id') || ''
            const number = address.get('tax_number') || ''
            const name = address.get('company_name') || ''
            const addressDetail = address.get('address_detail') || ''
            const district = address.getIn(['district', 'name']) || ''
            const subDistrict = address.get('sub_district') || ''
            const province = address.getIn(['province', 'name']) || ''
            const postalCode = address.get('postal_code') || ''

            return (
                {
                    key: id,
                    number: number,
                    companyName: name,
                    address: `${addressDetail} ${district} ${subDistrict} ${province} ${postalCode}`

                }
            )
        })

        return addressesList.toJS()
    }

    render () {
        const { businessAddresses, i18n, customerId } = this.props

        const columns = [{
            title: i18n.t('addresses:taxId'),
            dataIndex: 'number',
            key: 'number'
        }, {
            title: i18n.t('addresses:name'),
            dataIndex: 'companyName',
            key: 'companyName'
        }, {
            title: i18n.t('addresses:address'),
            dataIndex: 'address',
            key: 'address'
        }, {
            title: i18n.t('addresses:actions'),
            key: 'action',
            dataIndex: 'key',
            render: (dataIndex) => (
                <span>
                    <Link to={`/customers/${customerId}/business_addresses/${dataIndex}/edit`}>
                        <EditTwoTone  style={{ marginRight: '15px' }} />
                    </Link>
                    <Popconfirm title={i18n.t('addresses:deleteConfirm')}
                        onConfirm={() => this.handleDelete(customerId, dataIndex)}>
                        <a>
                            <DeleteTwoTone />
                        </a>
                    </Popconfirm>
                </span>
            )
        }]

        return (
            <div>
                <Table columns={columns}
                    dataSource={this.renderBusinessAddresses()}
                    pagination={{
                        ...this.getPagination().toJS(),
                        showSizeChanger: false
                    }}
                    onChange={this.onTableChange}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    loading={businessAddresses.get('loading')} />
                <div>
                    <Link to={`/customers/${customerId}/business_addresses/new`}>
                        <Button type="primary" size="large">
                            {i18n.t('addresses:addBusinessAddress')}
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    businessAddresses: state.get('businessAddresses'),
    businessAddressEntities: state.getIn(['Entities', 'businessAddresses'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchBusinessAddresses,
        deleteBusinessAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('addresses')(BusinessAddressIndexContainer)
)
