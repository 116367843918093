import { takeEvery, fork, all, put } from 'redux-saga/effects'
import actions from './actions'

const {
    NOTIFICATIONS_PUSH_NOTIFICATION,
    NOTIFICATIONS_SET_REQUEST,
    NOTIFICATIONS_SHIFT_NOTIFICATION
} = actions

const setTime = (ms) => new Promise(res => setTimeout(res, ms))

export function *setNotifications () {
    yield takeEvery(NOTIFICATIONS_SET_REQUEST, function *(value) {
        const { header, description, typeAlert } = value

        yield put({
            type: NOTIFICATIONS_PUSH_NOTIFICATION,
            header: header,
            description: description,
            typeAlert: typeAlert
        })

        yield setTime(value.delay)

        yield put({
            type: NOTIFICATIONS_SHIFT_NOTIFICATION
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(setNotifications)
    ])
}
