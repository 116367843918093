import styled from 'styled-components'

const WalletSummariesWrapper = styled.div`
  .customer-info {
    .customer-detail {
      span {
        font-weight: bold;

        &:after {
          content: ': '
        }
      }
    }
  }
`

export default WalletSummariesWrapper
