
import { PAGE_SIZE } from '../../constants/orders'

const actions = {
    PRODUCT_GROUPS_FETCH_FAIL: 'PRODUCT_GROUPS_FETCH_FAIL',
    PRODUCT_GROUPS_FETCH_REQUEST: 'PRODUCT_GROUPS_FETCH_REQUEST',
    PRODUCT_GROUPS_FETCH_SUCCESS: 'PRODUCT_GROUPS_FETCH_SUCCESS',
    PRODUCT_GROUPS_FETCHING: 'PRODUCT_GROUPS_FETCHING',
    PRODUCT_GROUPS_FETCH_ITEMS_FAIL: 'PRODUCT_GROUPS_FETCH_ITEMS_FAIL',
    PRODUCT_GROUPS_FETCH_ITEMS_REQUEST: 'PRODUCT_GROUPS_FETCH_ITEMS_REQUEST',
    PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS: 'PRODUCT_GROUPS_FETCH_ITEMS_SUCCESS',
    PRODUCT_GROUPS_FETCHING_ITEMS: 'PRODUCT_GROUPS_FETCHING_ITEMS',

    PRODUCT_GROUPS_SET_ITEMS_PAGINATION: 'PRODUCT_GROUPS_SET_ITEMS_PAGINATION',
    PRODUCT_GROUPS_SET_PRODUCT_IMAGE: 'PRODUCT_GROUPS_SET_PRODUCT_IMAGE',
    PRODUCT_GROUPS_SET_PRODUCT_GROUP: 'PRODUCT_GROUPS_SET_PRODUCT_GROUP',
    PRODUCT_GROUPS_SET_PRODUCT_GROUP_ITEM: 'PRODUCT_GROUPS_SET_PRODUCT_GROUP_ITEM',
    PRODUCT_GROUPS_SET_ROOT_ITEM_ATTRIBUTE: 'PRODUCT_GROUPS_SET_ROOT_ITEM_ATTRIBUTE',
    PRODUCT_GROUPS_SET_SELECTED_ITEM_IDS: 'PRODUCT_GROUPS_SET_SELECTED_ITEM_IDS',
    PRODUCT_GROUPS_SET_ITEM_ATTRIBUTE_QUANTITY_SELECTED: 'PRODUCT_GROUPS_SET_ITEM_ATTRIBUTE_QUANTITY_SELECTED',

    fetchProductGroups: ({ onSuccess = () => {}, onError = () => {} } = {}) => ({
        type: actions.PRODUCT_GROUPS_FETCH_REQUEST,
        payload: { onSuccess, onError }
    }),

    fetchItems: ({
        productGroupId,
        productGroupItemId = '',
        parentId = '',
        page = 1,
        perPage = 10,
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.PRODUCT_GROUPS_FETCH_ITEMS_REQUEST,
        payload: { productGroupId, productGroupItemId, parentId, page, perPage, onSuccess, onError }
    }),

    setProductGroup: (value) => ({
        type: actions.PRODUCT_GROUPS_SET_PRODUCT_GROUP,
        value
    }),

    setProductGroupItem: (value) => ({
        type: actions.PRODUCT_GROUPS_SET_PRODUCT_GROUP_ITEM,
        value
    }),

    setRootItemAttribute: (value) => ({
        type: actions.PRODUCT_GROUPS_SET_ROOT_ITEM_ATTRIBUTE,
        value
    }),

    setSelectedItemIds: (value) => ({
        type: actions.PRODUCT_GROUPS_SET_SELECTED_ITEM_IDS,
        value
    }),

    setItemQuantity: (quantity) => ({
        type: actions.PRODUCT_GROUPS_SET_ITEM_ATTRIBUTE_QUANTITY_SELECTED,
        quantity
    }),
}

export default actions
