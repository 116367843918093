import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from "@apollo/client";

import { Button, Modal, Form, DatePicker, Divider } from 'antd'

import notificationActions from '../../../redux/notifications/actions'

const { setNotifications } = notificationActions

const EXPORT_SUPPLIER_OVER_TRANSFER_ITEMS_MUTATION = gql`
	mutation ExportSupplierOverTransferItems($startDate: String!, $endDate: String!) {
		exportSupplierOverTransferItems(startDate: $startDate, endDate: $endDate) {
			errors
		}
	}
`

const SupplierOverTranfersExport = () => {
    const { i18n } = useTranslation('suppliers/overTransferItems')
    const dispatch = useDispatch()
    const [showExportForm, setShowExportForm] = useState(false)
    const [exportSupplierOverTransferItems, { loading }] = useMutation(EXPORT_SUPPLIER_OVER_TRANSFER_ITEMS_MUTATION, {
        onCompleted: ({ exportSupplierOverTransferItems }) => {
            if (exportSupplierOverTransferItems.errors) {
                dispatch(setNotifications('error', 'error', 'error'))
            } else {
                setShowExportForm(false)
                dispatch(setNotifications(
                    i18n.t('suppliers:export'),
                    i18n.t('suppliers:exportSuccess'),
                    'info'
                ))
            }
        }
    })

    const handleSubmit = values => {
        let startDate = null
        let endDate = null
        if (values['range_picker']) {
            startDate = values['range_picker'][0].format('YYYY-MM-DD')
            endDate = values['range_picker'][1].format('YYYY-MM-DD')
        }

        const date = {
            startDate: startDate,
            endDate: endDate
        }

        exportSupplierOverTransferItems({
            variables: { startDate: date.startDate, endDate: date.endDate }
        })
    }

    return (
        <>
            <Button type="primary" onClick={() => setShowExportForm(!showExportForm) }>
                {i18n.t('suppliers/overTransferItems:export')}
            </Button>
            <Modal
                style={{
                    height: '600px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                width="500px"
                visible={showExportForm}
                onCancel={() => setShowExportForm(!showExportForm)}
                closable={false}
                footer={null}
            >
                <div>{i18n.t('suppliers/overTransferItems:export')}</div>
                <Divider style={{ margin: '12px' }}/>
                <Form
                    onFinish={handleSubmit}
                >
                    <Form.Item label={i18n.t('suppliers/overTransferItems:selectDate')} name='range_picker'
                               rules={[{
                                   required: true,
                                   message: i18n.t('suppliers/overTransferItems:selectDate')
                               }]}>
                        <DatePicker.RangePicker />
                    </Form.Item>

                    <Form.Item
                        key="actions"
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Export
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

SupplierOverTranfersExport.propTypes = {}

SupplierOverTranfersExport.defaultProps = {}

export default SupplierOverTranfersExport
