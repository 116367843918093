import React from 'react'
import PropTypes from 'prop-types'
import { Form, Button } from 'antd'
import { withTranslation } from 'react-i18next'

import SelectInvoiceLineItemsContainer from '../../containers/Invoices/SelectInvoiceLineItemsContainer'
import SelectInvoiceShippingCostsContainer from '../../containers/Invoices/SelectInvoiceShippingCostsContainer'

const handleSubmit = (values, props) => {
    const { onSubmit } = props

    onSubmit(values)
}

const OrderInvoiceItemsForm = props => {
    const { orderId, orderBusinessAddressItems ,i18n } = props

    return (
        <div>
            <Form onFinish={(values) => handleSubmit(values, props)} layout='vertical'>
                <Form.Item label={i18n.t('confirms:invoice.lineItems')}
                    name='line_items'
                    initialValue={orderBusinessAddressItems.filter(item => item.item_type === 'LineItem')}>
                        <SelectInvoiceLineItemsContainer orderId={orderId} />
                </Form.Item>

                <Form.Item label={i18n.t('confirms:invoice.shippingCost')}
                    name='shipping_costs'
                    initialValue={orderBusinessAddressItems.filter(item => item.item_type === 'ShippingCost')}>
                        <SelectInvoiceShippingCostsContainer orderId={orderId} />
                </Form.Item>

                <Button htmlType="submit" type="primary">{i18n.t('shared:save')}</Button>
            </Form>
        </div>
    )
}

OrderInvoiceItemsForm.propTypes = {
    onSubmit: PropTypes.func,
    orderBusinessAddressItems: PropTypes.array,
    orderId: PropTypes.string
}

export default withTranslation(['confirms', 'shared'])(OrderInvoiceItemsForm)
