import { Map, List } from 'immutable'
import actions from './actions'

const {
    CUSTOMER_POTENTAIL_LEADS_FETCHING,
    CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS,
    SET_CUSTOMER_POTENTAIL_LEADS_FORM_MODAL,
    CUSTOMER_POTENTAIL_LEADS_SAVING,
    CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS,
    CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION,
    POTENTAIL_LEADS_DELETE_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    showForm: false,
    saving: false,
    activeFormId: null
})

export default function potentialLeadsReducer (state = initState, action) {
    const result = action.result
    const items = state.get('items')

    switch (action.type) {
        case CUSTOMER_POTENTAIL_LEADS_FETCHING:
            return state.merge({
                loading: action.loading
            })
        case CUSTOMER_POTENTAIL_LEADS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case CUSTOMER_POTENTAIL_LEADS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case SET_CUSTOMER_POTENTAIL_LEADS_FORM_MODAL:
            return state.merge({
                showForm: action.value,
                activeFormId: action.id
            })
        case CUSTOMER_POTENTAIL_LEADS_SAVING:
            return state.merge({
                saving: action.saving
            })
        case CUSTOMER_POTENTAIL_LEADS_SAVE_SUCCESS:
            return state.merge({
                saving: action.saving,
                items: items.includes(result) ? items : items.push(result)
            })
        case POTENTAIL_LEADS_DELETE_SUCCESS:
            return state.merge({
                items: items.delete(items.indexOf(result))
            })
        default:
            return state
    }
}
