import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import _ from 'lodash'

import { apiFetch } from '../../helpers/restRequest'
import {
    ordersSchema,
    customerSchema,
    customersSchema
} from '../schema'
import actions from './actions'
import actionsCompany from './companies/actions'
import { deserialize } from '../../helpers/jsonApi'
import { PAGE_SIZE } from '../../constants/customers'
import actionsNotification from '../../redux/notifications/actions'

const { setNotifications } = actionsNotification

const {
    CUSTOMER_ORDERS_FETCH_REQUEST,
    CUSTOMER_ORDERS_FETCHING,
    CUSTOMER_ORDERS_SET_TOTAL,
    CUSTOMERS_FETCH_REQUEST,
    CUSTOMERS_FETCH_SUCCESS,
    CUSTOMERS_FETCH,
    CUSTOMERS_SAVE_REQUEST,
    CUSTOMERS_SAVE_SUCCESS,
    CUSTOMERS_SEARCH_REQUEST,
    CUSTOMERS_SET_EMAIL_AMOUNT,
    CUSTOMERS_SET_FORM_ERRORS,
    CUSTOMERS_SET_LINE_AMOUNT,
    CUSTOMERS_SET_PAGINATION,
    CUSTOMERS_SET_PHONE_AMOUNT,
    CUSTOMERS_SET_QUERY,
    CUSTOMERS_SET_SAVING,
    CUSTOMERS_MERGING_REQUEST,
    CUSTOMERS_MERGING_SAVE,
    CUSTOMERS_MERGING_SAVE_SUCCESS
} = actions

const { COMPANIES_FETCH_SUCCESS } = actionsCompany

const apiPath = (id) => {
    const basePath = '/customers'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *searchRequest () {
    yield takeLatest(CUSTOMERS_SEARCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const page = payload.page
        const queryString = `query=${query}&page=${page}&per=${PAGE_SIZE}`

        yield put({ type: CUSTOMERS_SET_QUERY, query })
        yield put({ type: CUSTOMERS_FETCH })

        const data = yield call(apiFetch, `/customers?${queryString}`)
        const formattedData = deserialize(data)
        const total = data.meta.total_pages * PAGE_SIZE

        const normalizeData = normalize(formattedData, customersSchema)

        yield put({
            type: CUSTOMERS_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: CUSTOMERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *receiveCustomerOrders () {
    yield takeLatest(CUSTOMER_ORDERS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_ORDERS_FETCHING })

        const customerId = payload.customerId
        let queryString = payload.queryString
        if (typeof queryString === 'object') {
            const state = queryString.state ? `state=${queryString.state}` : ''
            const startDate = queryString.start_date ? `start_date=${queryString.start_date}` : ''
            const endDate = queryString.end_date ? `end_date=${queryString.end_date}` : ''
            queryString = [state, startDate, endDate].filter(Boolean).join('&')
        }
        const path = `/customers/${customerId}/orders?${queryString}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, ordersSchema)

        const ordersTotal = data.meta.total_pages * PAGE_SIZE

        yield put({ type: CUSTOMER_ORDERS_SET_TOTAL, ordersTotal })
        yield put({ type: actions.CUSTOMER_ORDERS_SUCCESS, ...normalizeData })
    })
}

export function *customerRequest () {
    yield takeLatest(CUSTOMERS_FETCH_REQUEST, function *({ payload }) {
        const id = payload.id

        yield put({ type: CUSTOMERS_FETCH })

        const data = yield call(apiFetch, `/customers/${id}`)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerSchema)

        const customerPhone = formattedData.customer_phone_numbers.map((phoneNumber) => { return phoneNumber.id })
        const customerLine = formattedData.customer_lines.map((line) => { return line.id})
        const customerEmail = formattedData.customer_emails.map((email) => { return email.id })

        const customerCompanyIds = _.map(formattedData.customer_companies, 'id')

        yield put({
            type: CUSTOMERS_SET_PHONE_AMOUNT,
            amount: customerPhone
        })

        yield put({
            type: CUSTOMERS_SET_EMAIL_AMOUNT,
            amount: customerEmail
        })

        yield put({
            type: CUSTOMERS_SET_LINE_AMOUNT,
            amount: customerLine
        })

        yield put({
            type: CUSTOMERS_FETCH_SUCCESS,
            ...normalizeData
        })

        yield put({
            type: COMPANIES_FETCH_SUCCESS,
            result: customerCompanyIds
        })
    })
}

export function *submitCustomer () {
    yield takeLatest(CUSTOMERS_SAVE_REQUEST, function *({ payload }) {
        yield put({
            type: CUSTOMERS_SET_SAVING,
            saving: true
        })

        const id = payload.customer.id
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ customer: payload.customer })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: CUSTOMERS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                const customerId = formattedData.id

                yield put({
                    type: CUSTOMERS_SAVE_SUCCESS,
                    ...normalize(formattedData, customersSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))
                setTimeout(() => location.replace(`/customers/${customerId}`), 500)
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: CUSTOMERS_SET_SAVING,
                saving: false
            })
        }
    })
}

export function *submitMergeCustomer () {
    yield takeLatest(CUSTOMERS_MERGING_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMERS_MERGING_SAVE })
        yield put({ type: CUSTOMERS_SET_SAVING, saving: true })

        const { id } = payload.mergeParams
        const path = `/customers/${id}/mergings`

        const body = JSON.stringify({ customer_mergings: payload.mergeParams })
        try {
            const data = yield call(apiFetch, path, 'POST', { body })
            if ('error' in data) {
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else if ('error_customer_merging' in data) {
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                const activeCustomerId = formattedData && formattedData.active_customer_no

                yield put(setNotifications('success', 'saveSuccess', 'success'))
                setTimeout(() => location.replace(`/customers/${activeCustomerId}`), 500)
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: CUSTOMERS_SET_SAVING, saving: false })
            yield put({ type: CUSTOMERS_MERGING_SAVE_SUCCESS })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(searchRequest),
        fork(submitCustomer),
        fork(receiveCustomerOrders),
        fork(customerRequest),
        fork(submitMergeCustomer)
    ])
}
