import styled from 'styled-components'

const FilterWrapper = styled.div`
  margin-bottom: 16px;

  .row-style {
    margin-bottom: 16px;

    .col-style > * {
      margin: 0 8px;

      &:first-child { margin-left: 0; }
      &:last-child { margin-right: 0; }
    }

    .align-right {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
    }
  }

  .text-nowrap { white-space: nowrap; }
`

export default FilterWrapper
