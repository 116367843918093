import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Pagination, Spin } from 'antd'
import withImagePreview from "../../../containers/ImagePreview/ImagePreviewContainer"
import Image from '../../../containers/Image'
import ImagesWrapper from './imagesWrapper.style'

const TruckImageIndex = ({
    customerId,
    loading,
    onPageChange,
    pagination,
    truckImages
}) => {
    const current = pagination.current
    const total = pagination.total
    const per = 10

    if (loading) { return <Spin size="large" /> }
    return (
        <div>
            <ImagesWrapper>
                <div className="images-container">
                    <div className="image-container">
                        {truckImages.map((image, imageIndex) => {
                            return (
                                <Image
                                    key={imageIndex}
                                    contentType={'image/jpeg'}
                                    originalUrl={_.get(image, 'file.original', null)}
                                    thumbUrl={_.get(image, 'file.small', null)}
                                    isImageBlob={false}
                                />
                            )
                        })}
                    </div>

                </div>
            </ImagesWrapper>
            <div className="pagination" style={{ float: 'right' }}>
                <Pagination current={current}
                    onChange={onPageChange}
                    pageSize={per}
                    total={total} />
            </div>
        </div>
    )
}

TruckImageIndex.propTypes = {
    customerId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    truckImages: PropTypes.array
}

TruckImageIndex.defaultProps = {
    loading: false,
    onPageChange: () => {},
    pagination: false
}

export default withImagePreview(TruckImageIndex)
