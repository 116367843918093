import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Map } from 'immutable'
import { Row, Col, Button, Spin, Modal } from 'antd'
import { UpSquareTwoTone, DownSquareTwoTone } from '@ant-design/icons'

import lineLogo from '../../../public/images/base/line-logo.png'

import customerActions from '../../redux/customers/actions'

import CustomerSheetWrapper from './customerSheet.style'
import CustomerWalletFormContainer from '../../containers/CustomerWallets/CustomerWalletFormContainer'

const {
    fetchCustomer,
    showPhoneNumbers,
    showEmails,
    showLines,
    setCustomerWalletFormModal
} = customerActions

class CustomerSheet extends Component {
    componentDidMount () {
        const { fetchCustomer, id } = this.props

        fetchCustomer(id)
    }

    onClickShowPhoneNumbers = (value) => {
        const { showPhoneNumbers } = this.props

        showPhoneNumbers(!value)
    }

    onClickShowEmails = (value) => {
        const { showEmails } = this.props

        showEmails(!value)
    }

    onClickShowLines = (value) => {
        const { showLines } = this.props

        showLines(!value)
    }

    getCustomer () {
        const { customerEntities, id } = this.props

        if (!id) { return new Map() }

        return customerEntities.get(id.toString()) || new Map()
    }

    getPhoneNumbers = (customer) => {
        const { entities } = this.props

        const phoneNumbers = customer.get('customer_phone_numbers')

        return phoneNumbers.map((phoneNumberId, index) => {
            const phoneNumber = entities.getIn(['customerPhoneNumbers', phoneNumberId])
            return (
                <div key={index}>
                    <Col span={5} />
                    <Col>
                        <div>{phoneNumber.get('body')}</div>
                    </Col>
                </div>
            )
        })
    }

    getEmails = (customer) => {
        const { entities } = this.props

        const emails = customer.get('customer_emails')

        return emails.map((emailId, index) => {
            const email = entities.getIn(['customerEmails', emailId])
            return (
                <div key={index}>
                    <Col span={5} />
                    <Col>
                        <div>{email.get('body')}</div>
                    </Col>
                </div>
            )
        })
    }

    getLines = (customer) => {
        const { entities } = this.props

        const lines = customer.get('customer_lines')

        return lines.map((linesId, index) => {
            const line = entities.getIn(['customerLines', linesId])
            return (
                <div key={index}>
                    <Col span={5} />
                    <Col>
                        <div>{line.get('body')}</div>
                    </Col>
                </div>
            )
        })
    }

    renderCustomerRole = (customer) => {
        const { entities } = this.props
        const customerRole = customer.getIn(['roles', '0'])

        if (customerRole) {
            return entities.getIn(['customerRoles', customerRole, 'name'])
        }
    }

    renderPhoneNumbers = (customer) => {
        const { customers, i18n } = this.props

        const showPhoneNumbers = customers.get('showPhoneNumbers')
        const loading = customers.get('loading')

        const phoneNumbers = customer.get('customer_phone_numbers')

        if (!phoneNumbers && !loading) { return null }

        if (phoneNumbers.toJS().length === 0) { return null }

        return (
            <span>
                <a className="show-contact" onClick={() => this.onClickShowPhoneNumbers(showPhoneNumbers)}>
                    {i18n.t('customerForm.seeMore')}
                    {showPhoneNumbers ?
                        <UpSquareTwoTone className="icon-arrow"/> :
                        <DownSquareTwoTone className="icon-arrow"/>}
                </a>
                {showPhoneNumbers ? this.getPhoneNumbers(customer) : null }
            </span>
        )
    }

    renderEmails = (customer) => {
        const { customers, i18n } = this.props

        const emails = customer.get('customer_emails')
        const showEmails = customers.get('showEmails')
        const loading = customers.get('loading')

        if (!emails && !loading) { return null }

        if (emails.toJS().length === 0) { return null }

        return (
            <span>
                <a className="show-contact" onClick={() => this.onClickShowEmails(showEmails)}>
                    {i18n.t('customerForm.seeMore')}
                    {showEmails ?
                        <UpSquareTwoTone className="icon-arrow"/> :
                        <DownSquareTwoTone className="icon-arrow"/>}
                </a>
                {showEmails ? this.getEmails(customer) : null }
            </span>
        )
    }

    renderLines = (customer) => {
        const { customers, i18n } = this.props

        const lines = customer.get('customer_lines')
        const showLines = customers.get('showLines')
        const loading = customers.get('loading')

        if (!lines && !loading) { return null }

        if (lines.toJS().length === 0) { return null }

        return (
            <span>
                <a className="show-contact" onClick={() => this.onClickShowLines(showLines)}>
                    {i18n.t('customerForm.seeMore')}
                    {showLines ?
                        <UpSquareTwoTone className="icon-arrow"/> :
                        <DownSquareTwoTone className="icon-arrow"/>}
                </a>
                {showLines ? this.getLines(customer) : null }
            </span>
        )
    }

    render () {
        const {
            i18n,
            customerInfo,
            customers,
            id,
            entities,
            setCustomerWalletFormModal
        } = this.props

        const customer = this.getCustomer()

        const customerCompanyName = _.get(customer.toJS(), 'company_name', null)
        const customerLineUrl = customer.get('line_url')
        const loading = customers.get('loading')

        if (loading) {
            return <Spin />
        }

        return (
            <div>
                <CustomerSheetWrapper>
                    <h1>{i18n.t('customerForm.customerInfo')}</h1>
                    <Row className="customer-info-layout">
                        <Col span={12}>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.customerName')}</div>
                                </Col>
                                <Col>
                                    <div>
                                        {customer.get('first_name')} {customer.get('last_name')}
                                        {customer.getIn(['sum_of_wallets', 'display']) > 0.00 ?
                                            ` (ยอด CN ${customer.getIn(['sum_of_wallets', 'display'])}฿)` : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.companyName')}</div>
                                </Col>
                                <Col>
                                    <div>{customerCompanyName}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.customerRole')}</div>
                                </Col>
                                <Col>
                                    <div>{this.renderCustomerRole(customer)}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.phone')}</div>
                                </Col>
                                <Col>
                                    <div>
                                        {customer.get('phone_number')}
                                        {this.renderPhoneNumbers(customer)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.email')}</div>
                                </Col>
                                <Col>
                                    <div>
                                        {customer.get('email')}
                                        {this.renderEmails(customer)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={5}>
                                    <div>{i18n.t('customerForm.lineId')}</div>
                                </Col>
                                <Col>
                                    <Row>
                                        {customer.get('line_id')}
                                        {customerLineUrl ?
                                            <a href={customerLineUrl} target="_blank" style={{ marginLeft: '4px'}}>
                                                <img src={lineLogo} width='24px' />
                                            </a> : null }
                                        {this.renderLines(customer)}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} className={`right-btn ${customerInfo ? "show" : "hide"}`}>
                            <Link to={`/customers/${id}/edit`}>
                                <Button type="primary">{i18n.t('customerForm.edit')}</Button>
                            </Link>
                        </Col>
                        <div className={`wallet-btn ${customerInfo ? "show" : "hide"}`}>
                            <Button type="primary" onClick={() => setCustomerWalletFormModal(true)}>
                                {i18n.t('customers/wallets:addWallet')}
                            </Button>
                        </div>
                    </Row>
                </CustomerSheetWrapper>
                <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={() => setCustomerWalletFormModal(false)}
                    visible={customers.get('showWalletForm')}>
                    <CustomerWalletFormContainer customerId={id}
                        onSuccess={() => setCustomerWalletFormModal(false)} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    customerEntities: state.getIn(['Entities', 'customers']),
    entities: state.get('Entities')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomer,
        showPhoneNumbers,
        showEmails,
        showLines,
        setCustomerWalletFormModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'customers/wallets'])(CustomerSheet))
