import React from 'react'
import { useSelector } from 'react-redux'
import { List, Map, mergeDeep } from 'immutable'
import { Row, Col } from 'antd'
import { withTranslation } from 'react-i18next'

import SelectedItemList from '../../components/megaHomeItems/SelectedItemList'

const SelectedItemListContainer = ({
    itemsQtySelected,
    setItemQuantity,
    setSelectedItem,
    i18n,
}) => {
    const items = useSelector(state => state.getIn(['inquiry', 'selectedMegaHomeItemIds'], new List()))
    const itemEntities = useSelector(state => state.getIn(['Entities', 'supplierItems']))

    const getSelectedItems = () => {
        return items.map((id) => {
            return itemEntities.get(id) || new Map()
        })
    }

    const handleSelectItems = (itemIds) => {
        setSelectedItem(itemIds)
    }

    const handleItemQuantity = (qty, itemId) => {
        const added = new Map({[itemId]: { quantity: qty }})
        const itemsQty = mergeDeep(itemsQtySelected, added)

        setItemQuantity(itemsQty)
    }

    return (
        <div>
            <Row>
                <Col span={12}><h3>{i18n.t('inquiries:lineItems')}</h3></Col>
                <Col span={12} style={{ textAlign: 'right', padding: '4px' }}>
                    (Total {items.size} item(s))
                </Col>
            </Row>
            <SelectedItemList
                selectedItemIds={items.toJS()}
                setItemQuantity={handleItemQuantity}
                onSelectChange={handleSelectItems}
                itemsList={getSelectedItems().toJS()}
            />
        </div>
    )
}

export default withTranslation(['inquiries'])(SelectedItemListContainer)
