import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import actions from './actions'
import { scbPaymentConfirmsSchema } from '../schema'

const {
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL,
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_REQUEST,
    ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS,
    ORDER_SCB_PAYMENT_CONFIRMS_FETCHING
} = actions

export function *fetchOrderScbPaymentConfirmsSaga () {
    yield takeLatest(ORDER_SCB_PAYMENT_CONFIRMS_FETCH_REQUEST, function *({ payload }) {
        const { orderId, onSuccess, onError } = payload

        yield put({ type: ORDER_SCB_PAYMENT_CONFIRMS_FETCHING })

        try {
            const path = `/orders/${orderId}/scb_payment_confirms`
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, scbPaymentConfirmsSchema)

            yield put({
                type: ORDER_SCB_PAYMENT_CONFIRMS_FETCH_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: ORDER_SCB_PAYMENT_CONFIRMS_FETCH_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchOrderScbPaymentConfirmsSaga)
    ])
}
