import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'

import { Table, Popconfirm } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import Image from '../../Image'
import ImagePreviewContainer from '../../ImagePreview/ImagePreviewContainer'

import noteImagesAction from '../../../redux/orders/noteImages/actions'

const { fetchOrderNoteImages, deleteOrderNoteImage } = noteImagesAction

class NoteImageListContainer extends Component {
    constructor (props) {
        super(props)

        const { i18n } = props

        this.orderNoteImageColumn = [
            {
                title: i18n.t('inquiries:orderNoteImage.image'),
                key: 'image',
                render: (orderNoteImage) => this.renderImageColumn(orderNoteImage)
            }, {
                title: i18n.t('inquiries:orderNoteImage.description'),
                dataIndex: 'description',
                key: 'description'
            }, {
                title: i18n.t('inquiries:orderNoteImage.uploadedBy'),
                key: 'uploadBy',
                render: (orderNoteImage) => this.renderUploadByColumn(orderNoteImage)
            }, {
                title: i18n.t('inquiries:delete'),
                key: 'action',
                render: (orderNoteImage) => (
                    <Popconfirm title={i18n.t('inquiries:deleteConfirm')}
                        onConfirm={() => this.handleDelete(orderNoteImage.id)}>
                        <a>
                            <DeleteTwoTone />
                        </a>
                    </Popconfirm>
                )
            }
        ]
    }

    componentDidMount () {
        const { orderId, fetchOrderNoteImages } = this.props

        fetchOrderNoteImages(orderId)
    }

    handleDelete = (id) => {
        const { deleteOrderNoteImage } = this.props

        deleteOrderNoteImage(id)
    }

    getOrderNoteImages () {
        const { orderNoteImageEntities, orderNoteImages } = this.props

        return orderNoteImages.get('items').map((orderNoteImageId) => {
            return orderNoteImageEntities.get(orderNoteImageId.toString()) || new Map()
        }) || new List()
    }

    renderImageColumn (orderNoteImage) {
        const thumbUrl = orderNoteImage.image.thumb
        const originalUrl = orderNoteImage.image.original

        return (
            <Image
                originalUrl={originalUrl}
                thumbUrl={thumbUrl}
            />
        )
    }

    renderUploadByColumn (orderNoteImage) {
        const staffFirstName = orderNoteImage.staff.first_name || ''
        const staffLastName = orderNoteImage.staff.last_name || ''
        const createdAt = orderNoteImage.localized_time.created_at || ''

        return (
            <div>
                {`${staffFirstName} ${staffLastName}`}
                <p style={{ color: '#ccc' }}>
                    {createdAt}
                </p>
            </div>
        )
    }

    render () {
        const { orderNoteImages } = this.props
        const loading = orderNoteImages.get('loading')
        const orderNoteImagesData = this.getOrderNoteImages().toJS()

        return (
            <div>
                <Table
                    columns={this.orderNoteImageColumn}
                    dataSource={orderNoteImagesData}
                    loading={loading}
                    rowKey={orderNoteImage => orderNoteImage.id}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orderNoteImageEntities: state.getIn(['Entities', 'orderNoteImages']),
    orderNoteImages: state.get('orderNoteImages')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderNoteImages,
        deleteOrderNoteImage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(
    ImagePreviewContainer(NoteImageListContainer)
))
