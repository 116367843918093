import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import _ from 'lodash'

import {Button, Card, Modal, Table, Popconfirm, Col} from "antd"
import {
    EditOutlined,
    LoadingOutlined,
    CalculatorOutlined,
    CarOutlined,
    DeleteTwoTone,
    FilePdfOutlined
} from '@ant-design/icons'
import OrderTransferBackForm from './OrderTransferBackForm'
import ShippingCostFormContainer from '../ShippingCosts/ShippingCostFormContainer'
import InquiryCardWrapper from './inquiryCard.style'

import actions from '../../redux/inquiry/transferBacks/actions'
import inquiryActions from '../../redux/inquiry/actions'
import notificationActions from '../../redux/notifications/actions'
import { Authorization } from '../Authorization'

const {
    fetchOrderTransferBacks,
    saveOrderTransferBack,
    setShowTransferBackForm,
    culculateTransferBackTotal,
    resetTransferBackItemsSelected,
    setShowShippingCostForm,
    saveShippingCostTansferBack,
    downloadOrderTransferBackDocuments,
    deleteOrderTransferBack
} = actions
const { fetchInquiry } = inquiryActions
const { setNotifications } = notificationActions

const OrderTransferBacks = ({
    orderId
}) => {
    const { i18n } = useTranslation('inquiries')
    const dispatch = useDispatch()
    const orderTransferBackEntities = useSelector(state => state.getIn(['Entities', 'orderTransferBacks']))
    const showForm = useSelector(state => state.getIn(['inquiryTransferBacks', 'showTransferBackForm']))
    const showShippingCostForm = useSelector(state => state.getIn(['inquiryTransferBacks', 'showShippingCostForm']))
    const saving = useSelector(state => state.getIn(['inquiryTransferBacks', 'saving']))
    const calculatingTotal = useSelector(state => state.getIn(['inquiryTransferBacks', 'calculatingTotal']))
    const transferBackId = useSelector(state => state.getIn(['inquiryTransferBacks', 'transferBackId']))
    const documentLoading = useSelector(state => state.getIn(['inquiryTransferBacks', 'documentLoading']))
    const items = useSelector(state => state.getIn(['inquiryTransferBacks', 'items'], new List()))
    const shippingCostErrors = useSelector(state => state.getIn(['inquiryTransferBacks', 'shippingCostErrors'], new Map()))

    useEffect(() => {
        dispatch(fetchOrderTransferBacks(orderId))
    }, [])

    const getOrderTransferBacks = () => {
        return items.map((id) => {
            return orderTransferBackEntities.get(id) || new Map()
        })
    }

    const handleSaveOrderTransferBack = (params) => {
        dispatch(saveOrderTransferBack(orderId, transferBackId, {
            params,
            onSuccess: () => {
                dispatch(setNotifications('success', 'saveSuccess', 'success'))
                toggleForm()
            },
            onError: (error) => {
                dispatch(setNotifications(`${error.name}`, `${error.message}`, 'error'))
            }
        }))
    }

    const handleSaveShippingCost = (params) => {
        if (!transferBackId) return null

        dispatch(saveShippingCostTansferBack(orderId, transferBackId, {
            params,
            onSuccess: () => {
                dispatch(setNotifications('success', 'saveSuccess', 'success'))
                toggleShippingCostForm()
                dispatch(fetchInquiry(orderId))
            },
            onError: (error) => {
                dispatch(setNotifications(`${error.name}`, `${error.message}`, 'error'))
            }
        }))
    }

    const handleCalculateGrandTotal = (id) => {
        dispatch(culculateTransferBackTotal(orderId, id, {
            onSuccess: () => {
                dispatch(setNotifications('success', 'saveSuccess', 'success'))
                dispatch(fetchInquiry(orderId))
            },
            onError: (error) => {
                dispatch(setNotifications(`${error.name}`, `${error.message}`, 'error'))
            }
        }))
    }

    const handlShowShippingCostForm = (id) => {
        dispatch(setShowShippingCostForm(!showShippingCostForm, id))
    }

    const setDeleteOrderTransferBack = (id) => {
        dispatch(deleteOrderTransferBack(orderId, id))
    }

    const downloadDocuments = (id) => {
        dispatch(downloadOrderTransferBackDocuments(orderId, id))
    }

    const toggleForm = ({ id = null } = {}) => {
        dispatch(setShowTransferBackForm(!showForm, id))
        if (!id) {
            dispatch(resetTransferBackItemsSelected())
        }
    }

    const toggleShippingCostForm = ({ id = null } = {}) => {
        dispatch(setShowShippingCostForm(!showShippingCostForm, id))
    }

    const columns = [
        {
            title: i18n.t('inquiries:transferBack.type'),
            dataIndex: 'order_transfer_back_type',
            render: (data) => {
                return(
                    <div>{i18n.t(`inquiries:transferBack.types.${_.camelCase(data)}`)}</div>
                )
            }
        }, {
            title: i18n.t('inquiries:transferBack.amount'),
            render: (record) => {
                const grandTotal = record.grand_total.display
                return (
                    <div>
                        <div>{grandTotal}</div>
                        <div>{record.shipping_cost > '0.00' ? <div>({record.shipping_cost})</div> : null}</div>
                    </div>
                )
            }
        }, {
            key: 'calculate',
            render: (record) => {
                const isCalculated = record.is_calculated
                if (isCalculated) return null
                return (
                    <a>
                        {
                            calculatingTotal ?
                                <LoadingOutlined style={{ cursor: 'pointer' }}
                                                 onClick={() => handleCalculateGrandTotal(record.id)} /> :
                                <CalculatorOutlined style={{ cursor: 'pointer' }}
                                                    onClick={() => handleCalculateGrandTotal(record.id)} />
                        }
                    </a>
                )
            }
        }, {
            key: 'shipping-cost',
            render: (record) => {
                return (
                    <a>
                        <CarOutlined onClick={() => handlShowShippingCostForm(record.id)} />
                    </a>
                )
            }
        }, {
            key: 'actions',
            render: (record) => {
                return (
                    <div>
                        <Button onClick={() => toggleForm({ id: record.id })} type="link" icon={<EditOutlined />} />
                        <a>
                            { documentLoading ? <LoadingOutlined /> :
                                <FilePdfOutlined onClick={() => downloadDocuments(record.id)}/>
                            }
                        </a>

                        <Authorization
                            allowedRoles={['super_admin', 'purchase', 'purchase_supervisor']}
                            redirectWhenNotAllowed={false}
                        >
                            <Popconfirm title={i18n.t('addresses:deleteConfirm')}
                                        onConfirm={() => setDeleteOrderTransferBack(record.id)}>
                                <a style={{ color: 'red', marginLeft: '8px' }}>
                                    <DeleteTwoTone twoToneColor="red" />
                                </a>
                            </Popconfirm>
                        </Authorization>
                    </div>
                )
            }
        }
    ]

    return (
        <InquiryCardWrapper>
            <Card>
                <div className="header margin-bottom-8">
                    <div className="title">{i18n.t('inquiries:transferBack.title')}</div>
                    <div className="add-btn">
                        <Button type="primary" size="small" onClick={toggleForm}>
                            {i18n.t('shared:add')}
                        </Button>
                    </div>
                </div>

                <Table
                    columns={columns}
                    dataSource={getOrderTransferBacks().toJS()}
                    pagination={false}
                    rowKey="id"
                    size="small" />
            </Card>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={toggleForm}
                visible={showForm}
                width="60%">
                <OrderTransferBackForm
                    id={transferBackId}
                    orderId={orderId}
                    onSubmitForm={handleSaveOrderTransferBack}
                    saving={saving}
                    toggleForm={toggleForm} />
            </Modal>
            <Modal
                destroyOnClose
                footer={null}
                onCancel={toggleShippingCostForm}
                visible={showShippingCostForm}>
                    <ShippingCostFormContainer toggleForm={toggleShippingCostForm}
                        onSubmitForm={handleSaveShippingCost}
                        saving={saving}
                        errors={shippingCostErrors.toJS()} />
            </Modal>
        </InquiryCardWrapper>
    )
}

export default withRouter(OrderTransferBacks)
