import React from 'react'
import { Select, Spin, Form, Col } from 'antd'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'

const Option = Select.Option

const getProvinceNameLocale = (province, props) => {
    return province.get(`name_${props.i18n.language}`)
}

const handleFilterOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const renderProvinceOptions = (props) => {
    const { provinces } = props

    const provinceOptions = provinces.map((province) => {
        const provinceId = province.get('id')
        return (
            <Option key={provinceId}
                value={provinceId.toString()} >
                {getProvinceNameLocale(province, props)}
            </Option>
        )
    })

    return provinceOptions
}

const ProvinceSelect = (props) => {
    const {
        i18n,
        col,
        fieldName,
        formItemLayout,
        help,
        loading,
        onSelectProvinceChange,
        provinceValue,
        validateStatus
    } = props

    return (
        <Col {...col}>
            <Form.Item {...formItemLayout}
                hasFeedback
                name={fieldName}
                help={help}
                initialValue={provinceValue || undefined}
                label={i18n.t('provinces:label')}
                validateStatus={validateStatus} >
                    <Select allowClear
                        filterOption={(input, option) => handleFilterOption(input, option)}
                        loading={loading}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        onChange={onSelectProvinceChange}
                        placeholder={i18n.t('provinces:placeHolder')}
                        showSearch
                        style={{ width: '100%' }} >
                        {renderProvinceOptions(props)}
                    </Select>
            </Form.Item>
        </Col>
    )
}

ProvinceSelect.propTypes = {
    col: PropTypes.object,
    fieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    form: PropTypes.object,
    formItemLayout: PropTypes.object,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    loading: PropTypes.bool.isRequired,
    onSelectProvinceChange: PropTypes.func.isRequired,
    provinceValue: PropTypes.string,
    provinces: ImmutablePropTypes.list.isRequired,
    validateStatus: PropTypes.string
}

ProvinceSelect.defaultProps = {
    col: { md: { span: 9 } },
    formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
    },
    help: '',
    provinceValue: undefined,
    validateStatus: ''
}

export default withTranslation('provinces')(ProvinceSelect)
