import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'

import Render from './render'

const CATEGORY_QUERY = gql`
    query Category($categoryId: ID!){
        category(categoryId: $categoryId){
            id
            suppliersCount
        }
    }
`

const DealCategory = () => {
    const [currentCategory, setCurrentCategory] = useState()
    const { loading, data } = useQuery(CATEGORY_QUERY, {
        skip: !currentCategory,
        variables: { categoryId: currentCategory }
    })

    const onChangeCurrent = categoryId => {
        setCurrentCategory(categoryId)
    }

    return (
        <Render
            category={(data && data.category) || {}}
            loading={loading}
            onChangeCurrent={onChangeCurrent}
        />
    )
}

DealCategory.propTypes = {}

DealCategory.defaultProps = {}

export default DealCategory
