import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Button, Form, Space } from 'antd'
import BlobUploader from '../../ActiveStorageUploader'

const CREATE_TICKET_IMAGES = gql`
    mutation CreateTicketImages($ticketId: ID!, $blobIds: [ID!]) {
        createTicketImages(ticketId: $ticketId, blobIds: $blobIds) {
            ticket {
                id
            }
        }
    }
`

const UploadForm = ({ onClose, refetchImage, ticketId }) => {
    const { i18n } = useTranslation()
    const uploadRef = useRef()

    const [createTicketImages, { loading: uploading }] = useMutation(CREATE_TICKET_IMAGES, {
        onCompleted: () => {
            uploadRef.current.clearValue()
            refetchImage()
            onClose()
        }
    })

    const handleClose = () => {
        uploadRef.current.clearBlobs()
        onClose()
    }

    const onSubmitForm = values => {
        const blobIds = uploadRef.current.getValue()

        createTicketImages({ variables: { ticketId, blobIds } })
    }

    return (
        <Form onFinish={onSubmitForm}>
            <Form.Item>
                <BlobUploader ref={uploadRef} />
            </Form.Item>
            <Space style={{ width: '100%', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit" loading={uploading}>
                    {i18n.t('shared:save')}
                </Button>
                <Button onClick={handleClose}>{i18n.t('shared:close')}</Button>
            </Space>
        </Form>
    )
}

UploadForm.propTypes = {
    onClose: PropTypes.func,
    refetchImage: PropTypes.func,
    ticketId: PropTypes.string.isRequired
}

UploadForm.defaultProps = {
    onClose: () => {},
    refetchImage: () => {}
}

export default UploadForm
