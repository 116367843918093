import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Form, Input, Select } from 'antd'

const PICKUP_POINTS_QUERY = gql`
    query PickupPoint($id: ID!) {
        pickupPoint(id: $id) {
            id
            sendingPoints(limit: -1) {
                id
                province {
                    id
                    name
                }
                district {
                    id
                    name
                }
            }
        }
    }
`

const optionsByProvince = (sendingPoints) => {
    const provinces = []
    const options = sendingPoints.reduce((result, sendingPoint) => {
        const provinceId = sendingPoint.province.id

        if (!provinces.find(province => province.id === provinceId)) {
            provinces.push(sendingPoint.province)
        }

        result[provinceId] = [...result[provinceId] || [], sendingPoint]

        return result
    }, {})

    return [provinces, options]
}

const renderProvinceOptions = options => options.map(({ id, name }) => {
    return <Select.Option value={id}>{name}</Select.Option>
})

const renderOptions = options => options.map(({ id, district }) => {
    return <Select.Option value={id}>{district.name}</Select.Option>
})

const SendingPointSelector = ({ form, formItemName, pickupPointId, ...restProps }) => {
    const [province, setProvince] = useState(undefined)
    const { loading, data, error } = useQuery(PICKUP_POINTS_QUERY, {
        skip: !pickupPointId,
        variables: { id: pickupPointId }
    })

    useEffect(() => setProvince(undefined), [pickupPointId])

    if (error) return console.error(error)

    const onChangeProvince = value => {
        setProvince(value)
        form.setFieldsValue({ [formItemName]: undefined })
    }

    const sendingPoints = (data && data.pickupPoint && data.pickupPoint.sendingPoints) || []
    const [provinces, options] = optionsByProvince(sendingPoints)

    return (
        <Input.Group compact>
            <Select
                allowClear
                disabled={!pickupPointId}
                filterOption={false}
                loading={loading}
                onChange={onChangeProvince}
                value={province}
                style={{ width: '50%' }}
            >
                {renderProvinceOptions(provinces)}
            </Select>
            <Form.Item name={formItemName} noStyle>
                <Select
                    allowClear
                    disabled={!province}
                    filterOption={false}
                    loading={loading}
                    style={{ width: '50%' }}
                    {...restProps}
                >
                    {renderOptions(options[province] || [])}
                </Select>
            </Form.Item>
        </Input.Group>
    )
}

SendingPointSelector.propTypes = {
    form: PropTypes.object.isRequired,
    formItemName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    pickupPointId: PropTypes.string
}

SendingPointSelector.defaultProps = {
    formItemName: 'sendingPointId'
}

export default SendingPointSelector
