import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import BankStatementSummary from '../../components/BankStatements/BankStatementSummary'

import actions from '../../redux/bankStatements/actions'

const { fetchBankStatementSummary } = actions

const BankStatementSummaryContainer = ({

}) => {
    const dispatch = useDispatch()
    const bankStatementSummary = useSelector(state => state.getIn(['bankStatements', 'bankStatementSummary']))

    useEffect(() => {
        dispatch(fetchBankStatementSummary())
    }, [])

    const onChangeMonth = (date, dateString) => {
        dispatch(fetchBankStatementSummary(dateString))
    }

    return (
        <BankStatementSummary
            bankStatementSummary={_.omit(bankStatementSummary.toJS(), 'loading')}
            loading={bankStatementSummary.get('loading')}
            onChangeMonth={onChangeMonth} />
    )
}

BankStatementSummaryContainer.propTypes = {}

BankStatementSummaryContainer.defaultProps = {}

export default BankStatementSummaryContainer
