import fetch from 'isomorphic-fetch'
import apiConfig from '../../config/apiConfig'

class OshAuth {
  login = async (userInfo) => {
      const data = new FormData()
      data.append('client_id', apiConfig.clientId)
      data.append('grant_type', 'password')
      data.append('username', userInfo.username)
      data.append('password', userInfo.password)

      return await fetch(`${apiConfig.apiBase}/oauth/token`, {
          method: 'POST',
          body: data
      }).then(response => response.json())
          .then(res => {
              const result = {}
              if (res.access_token) {
                  result.token = res.access_token
                  result.createdAt = res.created_at
                  result.expiresIn = res.expires_in
                  result.refreshToken = res.refresh_token
                  result.tokenType = res.token_type
                  return result
              } else {
                  return res
              }
          })
          .catch(error => ({ error }))
  };
}

export default new OshAuth()
