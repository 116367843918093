
import { PAGE_SIZE } from '../../../constants/customers'

const actions = {
    CUSTOMER_COMPANY_PROJECTS_FETCH_REQUEST: 'CUSTOMER_COMPANY_PROJECTS_FETCH_REQUEST',
    CUSTOMER_COMPANY_PROJECTS_FETCH: 'CUSTOMER_COMPANY_PROJECTS_FETCH',
    CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS: 'CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS',

    CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION: 'CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION',

    CUSTOMER_COMPANY_PROJECT_SET_FORM_MODAL: 'CUSTOMER_COMPANY_PROJECT_SET_FORM_MODAL',

    CUSTOMER_COMPANY_PROJECT_SAVE_REQUEST: 'CUSTOMER_COMPANY_PROJECT_SAVE_REQUEST',
    CUSTOMER_COMPANY_PROJECT_SAVE: 'CUSTOMER_COMPANY_PROJECT_SAVE',
    CUSTOMER_COMPANY_PROJECT_FORM_ERRORS: 'CUSTOMER_COMPANY_PROJECT_FORM_ERRORS',
    CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS: 'CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS',


    fetchCustomerCompanyProjects: ({ customerId, page = 1, per = PAGE_SIZE } = {}) => ({
        type: actions.CUSTOMER_COMPANY_PROJECTS_FETCH_REQUEST,
        payload: { customerId, page, per }
    }),

    setCompanyProjectFormModal: (visible, id) => ({
        type: actions.CUSTOMER_COMPANY_PROJECT_SET_FORM_MODAL,
        visible, id
    }),

    saveCustomerCompanyProject: ({
        customerId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.CUSTOMER_COMPANY_PROJECT_SAVE_REQUEST,
            payload: { customerId, id, params, onSuccess, onError }
        }
    }
}

export default actions
