import styled from 'styled-components'

const LoginWrapper = styled.div`
  .login-row {
    margin-top: 40px;
    text-align: center;
  }
  .logo-image {
    margin-bottom: 40px;
  }
`

export default LoginWrapper