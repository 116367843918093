import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    PROJECT_FETCH_PROFIT_SHARINGS,
    PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS,
    PROJECT_SET_PROFIT_SHARING_PAGINATION,
    PROJECT_SET_PROFIT_SHARING_FORM_MODAL
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showProfitSharingForm: false,
    profitSharingIdSelected: null
})

export default function projectProfitSharingsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case PROJECT_FETCH_PROFIT_SHARINGS:
            return state.merge({ loading: true })
        case PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case PROJECT_SET_PROFIT_SHARING_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case PROJECT_SET_PROFIT_SHARING_FORM_MODAL:
            return state.merge({
                showProfitSharingForm: action.visible,
                profitSharingIdSelected: action.id
            })
        default:
            return state
    }
}
