import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    Select
} from 'antd'

import { withTranslation } from 'react-i18next'

const SelectBankAccount = ({
    bankAccounts,
    loading,
    onChange,
    value,
    i18n,
    ...restProps
}) => {
    return (
        <Select
            allowClear
            loading={loading}
            onChange={onChange}
            placeholder={i18n.t('confirms:accounting.selectBank')}
            style={restProps.style || { width: '100%' }}
            value={value || undefined}
            {...restProps}
        >
            {bankAccounts.map((bankAccount, index) => {
                return (
                    <Select.Option
                        key={_.get(bankAccount, 'id', `index-${index}`)}
                        value={_.get(bankAccount, 'id', undefined)}
                    >
                        {_.get(bankAccount, 'full_name', '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectBankAccount.propTypes = {
    bankAccounts: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string
}

SelectBankAccount.defaultProps = {
    bankAccounts: [],
    loading: false,
    onChange: () => {},
    value: undefined
}

export default withTranslation(['confirms'])(SelectBankAccount)
