import { Map, List } from 'immutable'
import actions from './actions'

const {
    MAILING_ADDRESSES_FETCH,
    MAILING_ADDRESSES_FETCH_SUCCESS,
    MAILING_ADDRESSES_SAVING,
    MAILING_ADDRESSES_SET_FORM_ERRORS,
    MAILING_ADDRESSES_SAVE_SUCCESS,
    MAILING_ADDRESSES_SET_DEFAULT,
    MAILING_ADDRESSES_SET_PAGINATION
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    formErrors: new Map(),
    mailingAddress: null,
    pagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    saving: false,
    mailingAddressType: null,
    name: null,
    address: null,
    province: null,
    district: null,
    subDistrict: null,
    postalCode: null
})

export default function mailingAddressesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case MAILING_ADDRESSES_FETCH:
            return state.merge({
                loading: true
            })
        case MAILING_ADDRESSES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case MAILING_ADDRESSES_SAVING:
            return state.merge({
                saving: true
            })
        case MAILING_ADDRESSES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case MAILING_ADDRESSES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case MAILING_ADDRESSES_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                formErrors: new Map(),
                mailingAddress: action.result,
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case MAILING_ADDRESSES_SET_DEFAULT:
            return state.merge({
                mailingAddressType: action.mailingAddressType,
                name: action.name,
                address: action.address,
                province: action.province,
                district: action.district,
                subDistrict: action.subDistrict,
                postalCode: action.postalCode
            })
        default:
            return state
    }
}
