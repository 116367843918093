import { Map, List } from 'immutable'

import actions from './actions'
import { PAGE_SIZE } from '../../constants/customers'

const {
    WITHHOLDING_TAXES_FETCH,
    WITHHOLDING_TAXES_FETCH_SUCCESS,

    WITHHOLDING_TAX_FETCH,
    WITHHOLDING_TAX_FETCH_SUCCESS,

    WITHHOLDING_TAX_SAVING,
    WITHHOLDING_TAX_SET_FORM_ERRORS,
    WITHHOLDING_TAX_SAVE_SUCCESS,

    WITHHOLDING_TAX_DELETE,
    WITHHOLDING_TAX_DELETE_SUCCESS,

    WITHHOLDING_TAX_DOWNLOAD_PDF,
    WITHHOLDING_TAXES_DOWNLOAD_TXT,

    WITHHOLDING_TAXES_SET_PAGINATION,
    WITHHOLDING_TAXES_SET_WITHHOLDING_TAX_MODAL
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pdfLoading: false,
    txtLoading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showWithholdingTaxForm: false,
    withholdingTaxSelected: null,
    errors: new Map(),
    selectedMonth: '',
    selectedType: "all"
})

export default function withholdingTaxesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case WITHHOLDING_TAXES_FETCH:
            return state.merge({ loading: true })
        case WITHHOLDING_TAXES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result),
                selectedMonth: action.selectedMonth,
                selectedType: action.selectedType
            })
        case WITHHOLDING_TAX_FETCH:
            return state.merge({
                loading: action.loading
            })
        case WITHHOLDING_TAX_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case WITHHOLDING_TAX_SAVING:
            return state.merge({ saving: action.saving })
        case WITHHOLDING_TAX_SAVE_SUCCESS:
            return state.merge({
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case WITHHOLDING_TAX_DELETE:
            return state.merge({ loading: true })
        case WITHHOLDING_TAX_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.delete(items.indexOf(result)))
            })
        case WITHHOLDING_TAX_DOWNLOAD_PDF:
            return state.merge({ pdfLoading: action.loading })
        case WITHHOLDING_TAXES_DOWNLOAD_TXT:
            return state.merge({ txtLoading: action.loading })
        case WITHHOLDING_TAX_SET_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        case WITHHOLDING_TAXES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: new Map(action.pagination)
            })
        case WITHHOLDING_TAXES_SET_WITHHOLDING_TAX_MODAL:
            return state.merge({
                showWithholdingTaxForm: action.visible,
                withholdingTaxSelected: action.selected
            })
        default:
            return state
    }
}
