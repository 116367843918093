const actions = {
    ORDERS_STATES_FETCH_REQUEST: 'ORDERS_STATES_FETCH_REQUEST',
    ORDER_STATES_FETCH: 'ORDER_STATES_FETCH',
    ORDER_STATES_FETCH_SUCCESS: 'ORDER_STATES_FETCH_SUCCESS',
    // FETCH_QUOTATION_PDF_REQUEST: 'FETCH_QUOTATION_PDF_REQUEST',
    // FETCHING_QUOTATION_PDF: 'FETCHING_QUOTATION_PDF',
    // FETCH_QUOTATION_PDF_SUCCESS: 'FETCH_QUOTATION_PDF_SUCCESS',
    SET_SHOW_PAYMENT_MODAL: 'SET_SHOW_PAYMENT_MODAL',
    SET_SHOW_CANCEL_MODAL: 'SET_SHOW_CANCEL_MODAL',
    SAVE_ORDERS_STATES_REQUEST: 'SAVE_ORDERS_STATES_REQUEST',
    SAVE_ORDERS_STATES: 'SAVE_ORDERS_STATES',
    SAVE_ORDERS_STATE_SUCCESS: 'SAVE_ORDERS_STATE_SUCCESS',
    SET_NEW_ORDER_STATE: 'SET_NEW_ORDER_STATE',
    // SET_TRANSFER_DATE: 'SET_TRANSFER_DATE',
    // ORDERS_STATES_PAYMENT_DETAIL_FETCH_REQUEST: 'ORDERS_STATES_PAYMENT_DETAIL_FETCH_REQUEST',
    // ORDERS_STATES_PAYMENT_DETAIL_FETCH: 'ORDERS_STATES_PAYMENT_DETAIL_FETCH',
    // ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS: 'ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS',
    // SHOW_PAYMENT_DETAIL_MODAL: 'SHOW_PAYMENT_DETAIL_MODAL',
    // SAVE_PAYMENT_DETAIL_REQUEST: 'SAVE_PAYMENT_DETAIL_REQUEST',
    // SAVE_PAYMENT_DETAIL: 'SAVE_PAYMENT_DETAIL',
    // SAVE_PAYMENT_DETAIL_SUCCESS: 'SAVE_PAYMENT_DETAIL_SUCCESS',
    SAVE_RECOVER_ORDERS_REQUEST: 'SAVE_RECOVER_ORDERS_REQUEST',
    SAVE_RECOVER_ORDERS: 'SAVE_RECOVER_ORDERS',
    SAVE_RECOVER_ORDERS_SUCCESS: 'SAVE_RECOVER_ORDERS_SUCCESS',

    fetchOrderStates: ({ allState = false } = {}) => ({
        type: actions.ORDERS_STATES_FETCH_REQUEST,
        payload: { allState }
    }),
    setShowPaymentForm: (value) => ({
        type: actions.SET_SHOW_PAYMENT_MODAL,
        value
    }),
    // showPaymentDetailModal: (value) => ({
    //     type: actions.SHOW_PAYMENT_DETAIL_MODAL,
    //     value
    // }),
    setShowCancelModal: (value) => ({
        type: actions.SET_SHOW_CANCEL_MODAL,
        value
    }),
    setNewState: (newState) => ({
        type: actions.SET_NEW_ORDER_STATE,
        newState
    }),
    saveOrderState: (orderId, newState, values, { onError = () => {} } = {}) => ({
        type: actions.SAVE_ORDERS_STATES_REQUEST,
        payload: { orderId, newState, values, onError }
    }),
    recoverOrder: (orderId) => ({
        type: actions.SAVE_RECOVER_ORDERS_REQUEST,
        payload: { orderId }
    }),
    // setTransferDate: (date) => ({
    //     type: actions.SET_TRANSFER_DATE,
    //     date
    // }),
    // fetchPaymentDetail: (orderId) => ({
    //     type: actions.ORDERS_STATES_PAYMENT_DETAIL_FETCH_REQUEST,
    //     payload: { orderId }
    // }),
    // savePaymentDatail: (orderId, values) => ({
    //     type: actions.SAVE_PAYMENT_DETAIL_REQUEST,
    //     payload: { orderId, values }
    // }),
    // fetchQuotationPdfPreview: (orderId, router) => ({
    //     type: actions.FETCH_QUOTATION_PDF_REQUEST,
    //     payload: { orderId, router }
    // })
}

export default actions
