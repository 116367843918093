import styled from 'styled-components'
const WithholdingTaxWrapper = styled.div`
  .withholding-taxes-index-header {
    .header-title {
      display: flex;
      justify-content: space-between;
    }

    .header-line {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .label {
        margin: 0 8px;
      }

      .btn-action {
        margin-left: 8px;
      }
    }
  }

  .withholding-tax-details {
    .detail-line {
      display: flex;

      .label {
        font-weight: bold;
        white-space: nowrap;

        &:after {
          content: ':';
          margin-right: 4px;
        }
      }

      .unit {
        margin-left: 4px;
      }
    }
  }

  .withholding-tax-form-action-btn {
    margin: 0;

    .ant-form-item-children {
      .ant-btn {
        margin: 0 4px;
      }
    }
  }

  .ant-divider-with-text-left {
    font-size: 14px;
    margin: 12px 0;
  }

  .text-danger {
    color: #F5222D;
  }
`

export default WithholdingTaxWrapper
