import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import OrderInvoiceAdjustmentForm from '../../components/Invoices/orderInvoiceAdjustmentForm'

import accountingActions from '../../redux/orders/accounting/actions'

const {
    fetchInvoiceAdjustment,
    fetchInvoiceAdjustments,
    fetchOrderBusinessAddresses,
    saveInvoiceAdjustment,
    fetchDebitNotePurposes,
    fetchCreditNotePurposes
} = accountingActions

class OrderInvoiceAdjustmentFormContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        invoiceId: PropTypes.string,
        id: PropTypes.string,
        onSaveSuccess: PropTypes.func,

        accountings: ImmutablePropTypes.map.isRequired,
        fetchInvoiceAdjustment: PropTypes.func.isRequired,
        fetchInvoiceAdjustments: PropTypes.func.isRequired,
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        invoiceAdjustmentEntities: ImmutablePropTypes.map.isRequired,
        invoiceAdjustmentItemEntities: ImmutablePropTypes.map.isRequired,
        saveInvoiceAdjustment: PropTypes.func.isRequired
    }

    static defaultProps = {
        onSaveSuccess: () => {}
    }

    constructor(props) {
        super(props)

        this.state = {
            adjustmentTypeSelected: null
        }
    }

    componentDidMount() {
        const {
            orderId,
            invoiceId,
            id,
            fetchInvoiceAdjustment,
            fetchDebitNotePurposes,
            fetchCreditNotePurposes
        } = this.props

        fetchDebitNotePurposes()
        fetchCreditNotePurposes()
        if (id) {
            fetchInvoiceAdjustment(orderId, invoiceId, id)
        }
    }

    getInvoiceAdjustmentItems() {
        const { id, invoiceAdjustmentEntities, invoiceAdjustmentItemEntities } =
            this.props

        return invoiceAdjustmentEntities
            .getIn([id, 'items'], new List())
            .map((itemId) => {
                return invoiceAdjustmentItemEntities.get(itemId, new Map())
            })
    }

    getInvoiceAdjustment() {
        const { id, invoiceAdjustmentEntities } = this.props
        return invoiceAdjustmentEntities.get(id, new Map())
    }

    onInvoiceAdjustmentChange = (value) => {
        this.setState({
            adjustmentTypeSelected: value
        })
    }

    handleSubmitSuccess = () => {
        const {
            orderId,
            invoiceId,
            fetchOrderBusinessAddresses,
            fetchInvoiceAdjustments,
            onSaveSuccess
        } = this.props

        fetchInvoiceAdjustments(orderId, invoiceId)
        fetchOrderBusinessAddresses(orderId)
        onSaveSuccess()
    }

    handleSubmit = (values) => {
        const { saveInvoiceAdjustment, orderId, invoiceId, id } = this.props
        const adjustmentDate = values['adjustment_date']
            ? values['adjustment_date'].format('YYYY-MM-DD')
            : null

        const params = {
            adjustment_type: values['adjustment_type'] || null,
            debit_note_purpose_code: values['debit_note_purpose_code'] || null,
            discount: values['discount'] || null,
            credit_note_purpose_code:
                values['credit_note_purpose_code'] || null,
            ref_no: values['ref_no'] || null,
            note: values['note'] || null,
            items_attributes: values['items'].concat(values['shipping_costs']),
            adjustment_date: adjustmentDate
        }

        saveInvoiceAdjustment({
            orderId,
            invoiceId,
            id,
            params,
            onSuccess: this.handleSubmitSuccess
        })
    }

    render() {
        const { accountings, orderId } = this.props

        return (
            <OrderInvoiceAdjustmentForm
                errors={accountings.get('invoiceAdjustmentFormErrors').toJS()}
                invoiceAdjustment={this.getInvoiceAdjustment().toJS()}
                items={this.getInvoiceAdjustmentItems().toJS()}
                loading={accountings.get('invoiceAdjustmentFormLoading')}
                onSubmit={this.handleSubmit}
                orderId={orderId}
                saving={accountings.get('invoiceAdjustmentFormSaving')}
                onInvoiceAdjustmentChange={this.onInvoiceAdjustmentChange}
                debitNotePurposes={accountings.get('debitNotePurposesList')}
                creditNotePurposes={accountings.get('creditNotePurposesList')}
                adjustmentTypeSelected={this.state.adjustmentTypeSelected}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    accountings: state.get('orderPaymentDetails'),
    invoiceAdjustmentItemEntities: state.getIn([
        'Entities',
        'invoiceAdjustmentItems'
    ]),
    invoiceAdjustmentEntities: state.getIn(['Entities', 'invoiceAdjustments'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchInvoiceAdjustment,
            fetchInvoiceAdjustments,
            fetchOrderBusinessAddresses,
            saveInvoiceAdjustment,
            fetchDebitNotePurposes,
            fetchCreditNotePurposes
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderInvoiceAdjustmentFormContainer)
