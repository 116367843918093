import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import OrderYieldListFilters from '../../../components/orderYields/Filters'

class OrderYieldListFiltersContainer extends Component {
    static propTypes = {
        // Props from another component
        fetchOrderYields: PropTypes.func,

        // Inner Props
        orderYields: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        fetchOrderYields: () => {}
    }

    onChangeDateRangePicker = ({
        date_type: dateType,
        start_date: startDate,
        end_date: endDate
    }) => {
        const { fetchOrderYields } = this.props

        fetchOrderYields({
            page: 1,
            startDate,
            endDate
        })
    }

    onChangeIsCompleteYet = (isCompleteYet) => {
        const { fetchOrderYields } = this.props

        // You cannot reset value by undefined
        fetchOrderYields({
            page: 1,
            isCompleteYet: (isCompleteYet === undefined) ? '' : isCompleteYet
        })
    }

    onChangeIsRvEqualToInvoices = (isRvEqualToInvoices) => {
        const { fetchOrderYields } = this.props

        // You cannot reset value by undefined
        fetchOrderYields({
            page: 1,
            isRvEqualToInvoices: (isRvEqualToInvoices === undefined) ? '' : isRvEqualToInvoices
        })
    }

    onChangeSale = (saleId) => {
        const { fetchOrderYields } = this.props

        // You cannot reset value by undefined
        fetchOrderYields({
            page: 1,
            saleId: (saleId === undefined) ? '' : saleId
        })
    }

    render () {
        const { orderYields } = this.props

        return (
            <OrderYieldListFilters
                dateRangePickerValue={{
                    date_type: 'truck_reservation_date',
                    start_date: orderYields.get('startDate', ''),
                    end_date: orderYields.get('endDate', '')
                }}
                isCompleteYet={orderYields.get('isCompleteYet', '')}
                isRvEqualToInvoices={orderYields.get('isRvEqualToInvoices', '')}
                onChangeDateRangePicker={this.onChangeDateRangePicker}
                onChangeIsCompleteYet={this.onChangeIsCompleteYet}
                onChangeIsRvEqualToInvoices={this.onChangeIsRvEqualToInvoices}
                onChangeSale={this.onChangeSale}
                saleId={orderYields.get('saleId', '')}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderYields: state.get('orderYields')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderYieldListFiltersContainer)
