import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Card, Form, Button, Select, Input, DatePicker, Modal, Radio, Tag } from 'antd'
import BusinessAddressFormContainer from '../../containers/Customers/BusinessAddresses/BusinessAddressFormContainer'

const handleSubmit = (values, props, form) => {
    const { onSubmit } = props

    onSubmit({
        values,
        onSuccess: () => {
            form.resetFields()
        }
    })
}

const handleHiddenInput = e => {
    const inputWrap = document.getElementsByClassName('ant-calendar-input-wrap')[0]
    if (inputWrap) {
        document.getElementsByClassName('ant-calendar-date-panel')[0].focus()
        inputWrap.style.display = 'none'
    }
}

const renderAddBusinessAddressForm = props => {
    const { customerId, onShowAddBusinessAddressForm, showAddBusinessAddressForm } = props
    return (
        <Modal
            destroyOnClose
            footer={null}
            onCancel={() => onShowAddBusinessAddressForm(showAddBusinessAddressForm)}
            visible={showAddBusinessAddressForm}
            width="60%">
            <BusinessAddressFormContainer
                customerId={customerId}
                isModalForm />
        </Modal>
    )
}

const InvoiceForm = props => {
    const {
        businessAddressOptions,
        i18n,
        onShowAddBusinessAddressForm,
        optionsLoading,
        saving,
        showAddBusinessAddressForm,
        onCheckInvoiceType,
        invoiceType,
        errors,
        needTaxInvoiceAbb
    } = props
    const [form] = Form.useForm()

    const invNoErrors = errors && errors.get('invoice_no')

    return (
        <Card>
            <div className='invoice-header'>
                <h3>{i18n.t('confirms:invoice.createInvoice')}</h3>
                {needTaxInvoiceAbb ?
                    <div className="tax-inv-tag">
                        <Tag color='orange'>
                            {i18n.t('confirms:invoice.needTaxInvoiceAbb')}
                        </Tag>
                    </div>
                : null}
            </div>
            <Form onFinish={(values) => handleSubmit(values, props, form)} form={form}>
                <div className="form-item-has-add-btn">
                    <label>{i18n.t('confirms:invoice.businessAddress')}</label>
                    <Button type="primary" ghost
                        onClick={() => onShowAddBusinessAddressForm(showAddBusinessAddressForm)}>
                        {i18n.t('confirms:invoice.addBusinessAddress')}
                    </Button>
                </div>
                <Form.Item style={{ marginBottom: '0' }} name='invoice_type'
                    initialValue={invoiceType || 'full_tax_invoice'}>
                        <Radio.Group onChange={(e) => onCheckInvoiceType(e.target.value)}>
                            <Radio value={'full_tax_invoice'}>{i18n.t('confirms:invoice.fullTaxInvoice')}</Radio>
                            <Radio value={'abb'}>{i18n.t('confirms:invoice.taxInvoiceAbb')}</Radio>
                            <Radio value={'outside'}>{i18n.t('confirms:invoice.createFormOutside')}</Radio>
                        </Radio.Group>
                </Form.Item>
                { invoiceType === 'full_tax_invoice' || !invoiceType ?
                    <Form.Item name='business_address_id'>
                            <Select loading={optionsLoading}
                                placeholder={i18n.t('confirms:invoice.selectAddress')}>
                                {businessAddressOptions}
                            </Select>
                    </Form.Item> : null }

                <Form.Item className="form-item-inline">
                    <Form.Item name='invoice_no'
                        validateStatus={invNoErrors ? 'error' : null}
                        help={invNoErrors && invNoErrors.join(', ')}
                    >
                            <Input placeholder={i18n.t('confirms:invoice.invoiceNo')} />
                    </Form.Item>
                    <Form.Item name='invoice_date' rules={[{ required: true, message: i18n.t('confirms:invoice.pleaseInputDate') }]}>
                            <DatePicker
                                className="w-100"
                                onFocus={handleHiddenInput}
                                placeholder={i18n.t('confirms:invoice.invoiceDate')} />
                    </Form.Item>
                    <Form.Item name='payment_date'>
                            <DatePicker
                                className="w-100"
                                onFocus={handleHiddenInput}
                                placeholder={i18n.t('confirms:invoice.paymentDate')} />
                    </Form.Item>
                    <Form.Item name='payment_account'>
                            <Input placeholder={i18n.t('confirms:invoice.paymentAccount')} />
                    </Form.Item>
                </Form.Item>


                <div className="text-right">
                    <Button loading={saving} htmlType="submit" type="primary">
                        {i18n.t('shared:save')}
                    </Button>
                </div>
            </Form>

            {renderAddBusinessAddressForm(props)}
        </Card>
    )
}

InvoiceForm.propTypes = {
    businessAddressOptions: PropTypes.node,
    customerId: PropTypes.string,
    onShowAddBusinessAddressForm: PropTypes.func,
    onSubmit: PropTypes.func,
    optionsLoading: PropTypes.bool,
    needTaxInvoiceAbb: PropTypes.bool,
    saving: PropTypes.bool,
    showAddBusinessAddressForm: PropTypes.bool
}

export default withTranslation('confirms')(InvoiceForm)
