import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import _ from 'lodash'

import { apiPostForm } from '../../helpers/restRequest'

import actions from './actions'
const {
    REQUEST_ITEM_INDICES_UPLOAD_FAIL,
    REQUEST_ITEM_INDICES_UPLOAD_REQUEST,
    REQUEST_ITEM_INDICES_UPLOAD_SUCCESS,
    REQUEST_ITEM_INDICES_UPLOADING
} = actions

export function *uploadRequestItemIndicesSaga () {
    yield takeLatest(REQUEST_ITEM_INDICES_UPLOAD_REQUEST, function *({ payload }) {
        yield put({ type: REQUEST_ITEM_INDICES_UPLOADING })
        const { params, onSuccess, onError } = payload
        try {
            const path = '/request_item_indices'
            yield call(apiPostForm, path, 'POST', params)

            yield put({ type: REQUEST_ITEM_INDICES_UPLOAD_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: REQUEST_ITEM_INDICES_UPLOAD_FAIL })
            onError()
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(uploadRequestItemIndicesSaga)
    ])
}
