import { Map } from 'immutable'
import actions from './actions'

const {
    ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS,
    ORDER_SHIPPING_ADDRESSES_SET_LOADING,
    ORDER_SHIPPING_ADDRESSES_CLEAR_STATE_SHIPPING_ADDRESSES,
    SET_SHOW_SELECT_SHIPPING_ADDRESSES,
    ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS,
    ORDER_SHIPPING_ADDRESSES_SAVING
} = actions

const initState = new Map({
    orderShippingAddressItem: null,
    loading: false,
    showShippingAddress: false
})

export default function orderShippingAddresses (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case ORDER_SHIPPING_ADDRESSES_SET_LOADING:
            return state.merge({
                loading: action.loading
            })
        case ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS:
            return state.merge({
                orderShippingAddressItem: result
            })
        case ORDER_SHIPPING_ADDRESSES_CLEAR_STATE_SHIPPING_ADDRESSES:
            return state.merge({
                orderShippingAddressItem: null
            })
        case SET_SHOW_SELECT_SHIPPING_ADDRESSES:
            return state.merge({
                showShippingAddress: action.value
            })
        case ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS:
            return state.merge({
                showShippingAddress: false
            })
        case ORDER_SHIPPING_ADDRESSES_SAVING:
            return state.merge({
                saving: action.saving
            })
        default:
            return state
    }
}
