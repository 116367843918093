import styled from 'styled-components';
import { palette } from 'styled-theme';
import { isMobile } from 'react-device-detect'

const LayoutContentStyle = styled.div`
  width: 100%;
  padding: ${isMobile ? '16px' : '24px'};
  background-color: #ffffff;
  border: 1px solid ${palette('border', 0)};
  height: 100%;
`;

export default LayoutContentStyle;
