import React, { useState } from "react";
import PropTypes from "prop-types";

import { Avatar, Modal, Tooltip } from "antd";

import OrderStaffForm from "./Form";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const AVATAR_TYPES = {
  cs: { backgroundColor: "#fa8c16" },
  sale: { backgroundColor: "#13c2c2" },
  purchaser: { backgroundColor: "#c41d7f" },
  logistic: { backgroundColor: "#1e4eeb" },
  online: { backgroundColor: "#e73c2f" },
};

const renderNoStaffAvatar = (type, cursorStyle, onClick) => {
  return (
    <Tooltip title={`No ${type}`} onClick={() => onClick({}, type)}>
      <Avatar size="large" style={{ cursor: cursorStyle }}>
        ไม่มี
      </Avatar>
    </Tooltip>
  );
};

const renderStaffAvatar = ({ staff, type, cursorStyle, onClick }) => {
  if (!staff) return renderNoStaffAvatar(type, cursorStyle, onClick);

  let backgroundColor = null;
  if (AVATAR_TYPES[staff.role]) {
    backgroundColor = AVATAR_TYPES[staff.role].backgroundColor;
  }

  return (
    <Tooltip
      title={`${staff.fullName}(${staff.role})`}
      onClick={() => onClick(staff, type, staff.orderMemberId)}
    >
      <Avatar
        size="large"
        style={{
          backgroundColor: backgroundColor,
          cursor: cursorStyle,
        }}
      >
        {staff.firstName}
      </Avatar>
    </Tooltip>
  );
};

const renderOrderMembers = (
  order,
  cursorStyle,
  onClick,
  setCurrentEditMemberId
) =>
  order.orderMembers.map((orderMember) => {
    return renderStaffAvatar({
      staff: orderMember,
      type: null,
      cursorStyle,
      onClick,
      setCurrentEditMemberId,
    });
  });

const OrderStaffsRender = ({
  editable,
  order,
  onSaveSuccess,
  selfAssignSaving,
}) => {
  const [currentEditMemberId, setCurrentEditMemberId] = useState(null);
  const [activeStaff, setActiveStaff] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);

  const { i18n } = useTranslation();
  const renderOrderMembersWithNewButton = (
    order,
    cursorStyle,
    onClick,
    setCurrentEditMemberId,
    toggleFormModal
  ) => {
    return renderOrderMembers(
      order,
      cursorStyle,
      onClick,
      setCurrentEditMemberId
    ).concat(
      <Tooltip title={i18n.t("shared:add")} className="edit-ticket-members">
        <Avatar
          size="large"
          style={{ backgroundColor: "#9da6ab" }}
          onClick={() => addNewMember()}
        >
          <PlusOutlined />
        </Avatar>
      </Tooltip>
    );
  };

  const addNewMember = () => {
    toggleFormModal();
    setCurrentEditMemberId(null);
  };

  const handleClick = (data, type, orderMemberId) => {
    if (!editable) return null;

    toggleFormModal(data, type);
    setCurrentEditMemberId(orderMemberId);
  };

  const handleSaveSuccess = (number) => {
    onSaveSuccess(number);
    toggleFormModal();
  };

  const toggleFormModal = (data = {}, type = null) => {
    setActiveStaff(data);
    setShowFormModal(!showFormModal);
  };

  const onCloseModal = () => {
    toggleFormModal();
    setCurrentEditMemberId(null);
  };

  const cursorStyle = editable ? "pointer" : "default";
  const currentEditMember = order.orderMembers.find(
    (orderMember) => orderMember.orderMemberId === currentEditMemberId
  );

  return (
    <React.Fragment>
      <Avatar.Group>
        {renderOrderMembersWithNewButton(
          order,
          cursorStyle,
          handleClick,
          setCurrentEditMemberId,
          toggleFormModal
        )}
      </Avatar.Group>
      <Modal
        centered
        footer={null}
        onCancel={() => toggleFormModal()}
        visible={showFormModal}
        destroyOnClose={true}
      >
        <OrderStaffForm
          currentEditMember={currentEditMember}
          currentEditMemberId={currentEditMemberId}
          onClose={() => onCloseModal()}
          onSaveSuccess={handleSaveSuccess}
          orderId={order.number}
          staffData={activeStaff}
          selfAssignSaving={selfAssignSaving}
        />
      </Modal>
    </React.Fragment>
  );
};

OrderStaffsRender.propTypes = {
  editable: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
};

OrderStaffsRender.defaultProps = {};

export default OrderStaffsRender;
