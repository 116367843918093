const actions = {
    CUSTOMER_ROLES_FETCH_REQUEST: 'CUSTOMER_ROLES_FETCH_REQUEST',
    CUSTOMER_ROLES_FETCHING: 'CUSTOMER_ROLES_FETCHING',
    CUSTOMER_ROLES_FETCH_SUCCESS: 'CUSTOMER_ROLES_FETCH_SUCCESS',
    CUSTOMER_ROLE_SET_FORM: 'CUSTOMER_ROLE_SET_FORM',
    CUSTOMER_ROLES_SAVE_REQUEST: 'CUSTOMER_ROLES_SAVE_REQUEST',
    CUSTOMER_ROLES_SAVING: 'CUSTOMER_ROLES_SAVING',
    CUSTOMER_ROLES_SAVE_SUCCESS: 'CUSTOMER_ROLES_SAVE_SUCCESS',
    CUSTOMER_ROLES_IMPORT_FILE_REQUEST: 'CUSTOMER_ROLES_IMPORT_FILE_REQUEST',
    CUSTOMER_ROLES_IMPORT_FILE_UPLOADING: 'CUSTOMER_ROLES_IMPORT_FILE_UPLOADING',

    fetchCustomerRoles: () => ({
        type: actions.CUSTOMER_ROLES_FETCH_REQUEST
    }),
    setCustomerRoleForm: (value) => ({
        type: actions.CUSTOMER_ROLE_SET_FORM,
        value
    }),
    saveCustomerRole: (values, id) => ({
        type: actions.CUSTOMER_ROLES_SAVE_REQUEST,
        payload: { values, id }
    }),
    importCustomerRoles: (file) => ({
        type: actions.CUSTOMER_ROLES_IMPORT_FILE_REQUEST,
        payload: { file }
    })
}

export default actions
