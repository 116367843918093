import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Form, Select, Button, Spin, Input, DatePicker } from 'antd'
import SelectInvoiceLineItemsContainer from '../../containers/Invoices/SelectInvoiceLineItemsContainer'
import SelectInvoiceShippingCostsContainer from '../../containers/Invoices/SelectInvoiceShippingCostsContainer'

const OrderInvoiceAdjustmentForm = ({
    errors,
    i18n,
    invoiceAdjustment,
    items,
    loading,
    onSubmit,
    orderId,
    saving,
    onInvoiceAdjustmentChange,
    adjustmentTypeSelected,
    debitNotePurposes,
    creditNotePurposes
}) => {
    const [form] = Form.useForm()

    const handleSubmit = (values) => {
        onSubmit(values)
    }

    const renderPurposesSelect = (items) => {
        return items.map((item) => {
            return (
                <Select.Option value={item.get('name')}>
                    {item.get('label')}
                </Select.Option>
            )
        })
    }

    const renderPurposes = (selected) => {
        if (selected === 'debit_note') {
            return (
                <Form.Item
                    name='debit_note_purpose_code'
                    initialValue={_.get(
                        invoiceAdjustment,
                        'debit_note_purpose_code'
                    )}
                    label={i18n.t('confirms:invoice.purpose')}>
                    <Select
                        placeholder={i18n.t('confirms:invoice.adjustmentType')}>
                        {renderPurposesSelect(debitNotePurposes)}
                    </Select>
                </Form.Item>
            )
        } else if (selected === 'credit_note') {
            return (
                <Form.Item
                    name='credit_note_purpose_code'
                    initialValue={_.get(
                        invoiceAdjustment,
                        'credit_note_purpose_code'
                    )}
                    label={i18n.t('confirms:invoice.purpose')}>
                    <Select
                        placeholder={i18n.t('confirms:invoice.adjustmentType')}>
                        {renderPurposesSelect(creditNotePurposes)}
                    </Select>
                </Form.Item>
            )
        } else {
            return null
        }
    }

    if (loading) {
        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Spin />
            </div>
        )
    }

    moment.locale(i18n.language)
    const date = _.get(invoiceAdjustment, 'adjustment_date')

    return (
        <>
            <h2>{i18n.t('confirms:invoice.invoiceAdjustment')}</h2>
            <Form onFinish={handleSubmit} layout='vertical' form={form}>
                <Form.Item
                    name='adjustment_type'
                    initialValue={_.get(invoiceAdjustment, 'adjustment_type')}
                    label={i18n.t('confirms:invoice.adjustmentType')}
                    rules={[
                        {
                            required: true,
                            message: i18n.t(
                                'confirms:invoice.pleaseSelectAdjustmentType'
                            )
                        }
                    ]}>
                    <Select
                        placeholder={i18n.t('confirms:invoice.adjustmentType')}
                        onChange={onInvoiceAdjustmentChange}>
                        <Select.Option value='debit_note'>
                            {i18n.t(
                                'confirms:invoice.adjustmentTypes.debitNote'
                            )}
                        </Select.Option>
                        <Select.Option value='credit_note'>
                            {i18n.t(
                                'confirms:invoice.adjustmentTypes.creditNote'
                            )}
                        </Select.Option>
                    </Select>
                </Form.Item>
                {renderPurposes(
                    adjustmentTypeSelected ||
                        _.get(invoiceAdjustment, 'adjustment_type')
                )}
                <Form.Item
                    label={i18n.t('confirms:invoice.adjustmentDate')}
                    name='adjustment_date'
                    initialValue={date && moment(date)}
                    rules={[
                        {
                            required: true,
                            message: i18n.t(
                                'confirms:invoice.pleaseSelectAdjustmentDate'
                            )
                        }
                    ]}>
                    <DatePicker
                        className='w-100'
                        placeholder={i18n.t('confirms:invoice.selectDate')}
                    />
                </Form.Item>
                <Form.Item
                    label={i18n.t('confirms:invoice.refNo')}
                    name='ref_no'
                    initialValue={_.get(invoiceAdjustment, 'ref_no')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={i18n.t('confirms:invoice.discount')}
                    initialValue={_.get(
                        invoiceAdjustment,
                        'money_display.discount'
                    )}
                    name='discount'>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={i18n.t('confirms:invoice.note')}
                    name='note'
                    initialValue={_.get(invoiceAdjustment, 'note')}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    name='items'
                    initialValue={items.filter(
                        (item) => item.item_type === 'LineItem'
                    )}>
                    <SelectInvoiceLineItemsContainer orderId={orderId} />
                </Form.Item>
                <Form.Item
                    name='shipping_costs'
                    initialValue={items.filter(
                        (item) => item.item_type === 'ShippingCost'
                    )}>
                    <SelectInvoiceShippingCostsContainer orderId={orderId} />
                </Form.Item>

                <Button htmlType='submit' loading={saving} type='primary'>
                    {i18n.t('shared:save')}
                </Button>
            </Form>
        </>
    )
}

OrderInvoiceAdjustmentForm.propTypes = {
    errors: PropTypes.object,
    form: PropTypes.object,
    invoiceAdjustment: PropTypes.object,
    items: PropTypes.array,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    orderId: PropTypes.string,
    saving: PropTypes.bool
}

OrderInvoiceAdjustmentForm.defaultProps = {
    errors: {},
    invoiceAdjustment: {},
    items: [],
    loading: false,
    onSubmit: () => {},
    saving: false
}

export default withTranslation(['confirms', 'shared'])(
    OrderInvoiceAdjustmentForm
)
