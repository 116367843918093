import { Map, List } from 'immutable'
import actions from './actions'

const {
    SHIPPING_ADDRESSES_FETCH,
    SHIPPING_ADDRESSES_FETCH_SUCCESS,
    SHIPPING_ADDRESSES_SET_SAVE,
    SHIPPING_ADDRESSES_SAVE_SUCCESS,
    SHIPPING_ADDRESSES_SET_FORM_ERRORS,
    SHIPPING_ADDRESSES_SET_PAGINATION,
    SHIPPING_ADDRESS_FETCH,
    SHIPPING_ADDRESS_FETCH_SUCCESS
} = actions

const initState = new Map({
    formErrors: new Map(),
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        defaultPageSize: 20,
        total: null
    }),
    recentSaved: null
})

export default function shippingAddressesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case SHIPPING_ADDRESSES_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })

        case SHIPPING_ADDRESSES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: [].concat(result)
            })
        case SHIPPING_ADDRESS_FETCH:
            return state.merge({
                loading: true
            })
        case SHIPPING_ADDRESS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? items : items.concat(result)
            })
        case SHIPPING_ADDRESSES_SET_SAVE:
            return state.merge({ saving: action.saving })

        case SHIPPING_ADDRESSES_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(result) ? items : items.push(result),
                recentSaved: result
            })

        case SHIPPING_ADDRESSES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })

        case SHIPPING_ADDRESSES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })

        default:
            return state
    }
}
