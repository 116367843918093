import { Map, List } from 'immutable'
import actions from './actions'

const {
    OCCUPATIONS_FETCH,
    OCCUPATIONS_FETCH_SUCCESS,
    OCCUPATIONS_SAVE,
    OCCUPATIONS_SAVE_SUCCESS,
    OCCUPATIONS_SET_FORM_ERRORS,
    OCCUPATIONS_CLEAR_FORM_ERRORS
} = actions

const initState = new Map({
    formErrors: new Map(),
    items: new List(),
    loading: false,
    saving: false
})

export default function occupationsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case OCCUPATIONS_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })
        case OCCUPATIONS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List().concat(result)
            })
        case OCCUPATIONS_SAVE:
            return state.merge({
                saving: true
            })
        case OCCUPATIONS_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: items.includes(action.data) ? items : items.push(action.data)
            })
        case OCCUPATIONS_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case OCCUPATIONS_CLEAR_FORM_ERRORS:
            return state.merge({
                formErrors: new Map()
            })
        default:
            return state
    }
}
