import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import { Row, Col, Input, Table, Button, Modal, Tag } from 'antd'
import {
    CreditCardOutlined,
    UserOutlined,
    PhoneOutlined,
    UserAddOutlined,
    CarOutlined,
    PlusOutlined
} from '@ant-design/icons'

import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import TruckOwnerFormContainer from '../../containers/Trucks/TruckOwnerFormContainer'
import TruckFormContainer from '../../containers/Trucks/TruckFormContainer'
import TruckOwnerBankAccountFormContainer from '../../containers/Trucks/TruckOwnerBankAccountFormContainer'
import StaffFormContainer from '../../containers/Trucks/StaffFormContainer'

class TruckOwnerTable extends Component {
    static propTypes = {
        getTruckOwner: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        onCancelModal: PropTypes.func.isRequired,
        onChangeTruckOwnerType: PropTypes.func.isRequired,
        onCreateStaff: PropTypes.func.isRequired,
        onEditBankAccount: PropTypes.func,
        onEditTruck: PropTypes.func,
        onEditTruckOwner: PropTypes.func,
        onSaveStaffSuccess: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        onTableChange: PropTypes.func.isRequired,
        ownerType: PropTypes.string,
        pagination: PropTypes.object.isRequired,
        truckOwners: PropTypes.array.isRequired,
        trucks: ImmutablePropTypes.map.isRequired,
        visibleModal: PropTypes.string
    }

    getColumns () {
        const { onEditBankAccount, onEditTruckOwner, onEditTruck, onCreateStaff, i18n } = this.props

        return [

            {
                title: i18n.t('trucks:bankAccount.name'),
                dataIndex: 'name',
                key: 'name',
                render: (name, data) => {
                    const bankAccountDom = _.get(data, 'truck_owner_bank_accounts', []).map((bankAccount) => {
                        const bankAccountId = _.toString(_.get(bankAccount, 'id', ''))
                        const ownerId = _.toString(_.get(bankAccount, 'truck_owner_id', ''))

                        return (
                            <Tag key={`bank-accounts-${bankAccountId}`}
                                onClick={() => { onEditBankAccount({ bankAccountId, ownerId }) }}>
                                {`${_.get(bankAccount, 'bank_name', '')} ${_.get(bankAccount, 'number', '')}`}
                            </Tag>
                        )
                    })

                    return (
                        <div>
                            <div>
                                <div>
                                    <a type="link" icon="user"
                                        onClick={() => { onEditTruckOwner(_.get(data, 'id', '')) }}>
                                        <UserOutlined /> {name}
                                    </a>
                                </div>
                                <div>
                                    <PhoneOutlined /> { data.phone_number }
                                </div>
                                <div>
                                    {i18n.t('trucks:numberOfTrucks')}: { _.get(data, 'trucks', []).length }
                                </div>
                                <div style={{ color: 'green' }}>
                                    { data.is_verified ? i18n.t('trucks:verified') : null }
                                </div>
                            </div>
                            <div style={{ paddingTop: '14px' }}>
                                {i18n.t('trucks:bankAccount.bankAccount')}
                                { bankAccountDom }
                                <div style={{ paddingTop: '4px' }}>
                                    <a type="link" icon="credit-card"
                                        onClick={() => { onEditBankAccount({ ownerId: data.id }) }} >
                                        <CreditCardOutlined /> {i18n.t('trucks:addBankAccount')}
                                    </a>
                                </div>
                            </div>

                            {_.isEmpty(_.get(data, 'staffs', [])) ?
                                <Row>
                                    <Col span={24}>
                                        <a
                                            onClick={() => { onCreateStaff(data.id) }}
                                            type="link"
                                        >
                                            <UserAddOutlined /> {i18n.t('trucks:inviteToUseTheSystem')}
                                        </a>
                                    </Col>
                                </Row> :
                                <Row>
                                    <Col>
                                        {i18n.t('trucks:invitedToUseTheSystem')}
                                        <span> ({i18n.t('trucks:lineUserCode')}: {this.renderLineUserCodes(_.get(data, 'line_user_codes', []))})</span>
                                        <div>
                                            <a
                                                onClick={() => { onCreateStaff(data.id) }}
                                                type="link"
                                            >
                                                <UserAddOutlined /> {i18n.t('trucks:inviteAnotherToUseTheSystem')}
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    )
                }
            },
            {
                title: i18n.t('trucks:licensePlateNumber'),
                key: 'trucks',
                render: (truckOwner) => {
                    const truckDom = _.get(truckOwner, 'trucks', []).map((truck) => {
                        const truckId = _.toString(_.get(truck, 'id', ''))
                        const ownerId = _.toString(_.get(truck, 'truck_owner_id', ''))

                        return (<Tag onClick={() => { onEditTruck({ truckId, ownerId }) }} key={`truck-${truck.id}`}>
                            {_.get(truck, 'license_plate_number', '')}
                        </Tag>)
                    })

                    return (
                        <div>
                            { truckDom }
                            <div style={{ paddingTop: '4px' }}>
                                <a type="link" icon="car"
                                    onClick={() => { onEditTruck({ ownerId: truckOwner.id }) }} >
                                    <CarOutlined /> {i18n.t('trucks:addTruck')}
                                </a>
                            </div>

                        </div>
                    )
                }
            }
        ]
    }

    renderModal () {
        const { getTruckOwner, onCancelModal, onSaveStaffSuccess, ownerType, trucks, visibleModal } = this.props
        let modalBody

        switch (visibleModal) {
            case 'truck_owner':
                modalBody = (
                    <TruckOwnerFormContainer
                        ownerType={ownerType}
                        onCancel={() => { onCancelModal(null) }} />
                )
                break
            case 'truck':
                modalBody = (
                    <TruckFormContainer
                        id={trucks.getIn(['truckForm', 'truckId'])}
                        onCancel={() => { onCancelModal(null) }}
                        ownerId={trucks.getIn(['truckForm', 'ownerId'])}
                    />
                )
                break
            case 'bank_account':
                modalBody = (
                    <TruckOwnerBankAccountFormContainer
                        id={trucks.getIn(['truckOwnerBankAccountForm', 'bankAccountId'])}
                        onCancel={() => { onCancelModal(null) }}
                        ownerId={trucks.getIn(['truckOwnerBankAccountForm', 'ownerId'])} />
                )
                break
            case 'staff':
                modalBody = (
                    <StaffFormContainer
                        truckOwner={getTruckOwner(trucks.getIn(['truckOwnerStaffForm', 'ownerId'])).toJS()}
                        onSuccess={() => {
                            onCancelModal(null)
                            onSaveStaffSuccess(trucks.getIn(['truckOwnerStaffForm', 'ownerId']))
                        }}
                    />
                )
                break
            default:
                modalBody = null
        }

        return (
            <Modal
                destroyOnClose
                footer={null}
                onCancel={() => { onCancelModal(null) }}
                visible={visibleModal !== null}
                width={visibleModal === 'truck' ? '70%' : '40%'}>
                {modalBody}
            </Modal>
        )
    }

    renderLineUserCodes = (lineUserCodes) => {
        const length = lineUserCodes.length

        return lineUserCodes.map((lineUserCode, key) => {
            if (length === key + 1) {
                return (
                    <span key={key}>{lineUserCode} </span>
                )
            } else {
                return (
                    <span key={key}>{lineUserCode}, </span>
                )
            }
        })
    }

    render () {
        const { loading, onEditTruckOwner, onSearch, onTableChange, pagination, truckOwners, i18n } = this.props

        return (
            <div className="truck-company-table" key="truck-company-table">
                <Row gutter={[16, 16]} type="flex" justify="center">
                    <Col span={12} style={{ display: 'flex' }}>
                        <Input.Search allowClear
                            placeholder={i18n.t('trucks:search')}
                            onSearch={value => { onSearch(value) }} />
                        <Button type="primary" icon={<PlusOutlined />}
                            onClick={() => { onEditTruckOwner(null)}}
                            style={{ margin: '0 10px' }} >
                            {i18n.t('trucks:addTruckOwner')}
                        </Button>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Table
                            bordered
                            columns={this.getColumns()}
                            dataSource={truckOwners}
                            loading={loading}
                            onChange={onTableChange}
                            pagination={pagination}
                            rowKey="id"
                            scroll={isMobile ? { x: 1300 } : { x: 1000 }} />
                    </Col>
                </Row>

                {this.renderModal()}
            </div>
        )
    }
}

export default withTranslation(['trucks'])(TruckOwnerTable)
