import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import Render from './render'

import notificationActions from '../../../redux/notifications/actions'

const { setNotifications } = notificationActions

const ORDER_STATES_QUERY = gql`
    query OrderStates {
        orderStates(allStates: true) {
            id
            name
            label
            nextStates
        }
    }
`

const UPDATE_ORDER_STATE_MUTATION = gql`
    mutation UpdateOrderState($orderId: String!, $newState: String!, $orderParams: AdminOrderInput!) {
        updateOrderState(orderId: $orderId, newState: $newState, orderParams: $orderParams) {
            order {
                id
                number
                state
                stateLabel
            }
            errors
        }
    }
`

const OrderStateDropdown = ({ orderId, paymentMethod, refetch, state, stateLabel }) => {
    const dispatch = useDispatch()
    const [showCancelForm, setShowCancelForm] = useState(false)
    const { loading, data } = useQuery(ORDER_STATES_QUERY)
    const [updateOrderState, { loading: saving }] = useMutation(UPDATE_ORDER_STATE_MUTATION, {
        onCompleted: ({ updateOrderState }) => {
            if (updateOrderState.errors) {
                const error = JSON.parse(updateOrderState.errors)

                dispatch(setNotifications(error.name, error.message, 'error'))
            } else {
                refetch()
                dispatch(setNotifications('success', 'saveSuccess', 'success'))
                setShowCancelForm(false)
            }
        }
    })

    const onSaveOrderState = (newState, params = {}) => {
        updateOrderState({ variables: { orderId, newState, orderParams: params } })
    }

    const toggleCancelForm = () => {
        setShowCancelForm(!showCancelForm)
    }
    
    return (
        <Render
            items={(data && data.orderStates) || []}
            onSaveOrderState={onSaveOrderState}
            paymentMethod={paymentMethod}
            showCancelForm={showCancelForm}
            spinning={loading || saving}
            state={state}
            stateLabel={stateLabel}
            toggleCancelForm={toggleCancelForm}
        />
    )
}

OrderStateDropdown.propTypes = {
    orderId: PropTypes.string.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    refetch: PropTypes.func,
    state: PropTypes.string.isRequired,
    stateLabel: PropTypes.string.isRequired,
}

OrderStateDropdown.defaultProps = {
    refetch: () => {}
}

export default OrderStateDropdown
