import React from 'react'

import { Tabs } from 'antd'
import CurrentSalePipeLines from './current'
import SalePipeLinesReport from './report'
import withCurrentStaff from '../../containers/CurrentStaff/CurrentStaffContainer'

const { TabPane } = Tabs

const SalePipelines = ({ currentStaff }) => {
    return (
        <Tabs defaultActiveKey="current">
            <TabPane tab="Current" key="current">
                <CurrentSalePipeLines currentStaffId={currentStaff.get('id')} />
            </TabPane>
            <TabPane tab="Report" key="report">
                <SalePipeLinesReport currentStaffId={currentStaff.get('id')} />
            </TabPane>
        </Tabs>
    )
}

SalePipelines.propTypes = {}

SalePipelines.defaultProps = {}

export default withCurrentStaff(SalePipelines)
