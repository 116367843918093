const actions = {
    BRANDS_SEARCH_REQUEST: 'BRANDS_SEARCH_REQUEST',
    BRANDS_SET_QUERY: 'BRANDS_SET_QUERY',
    BRANDS_FETCH: 'BRANDS_FETCH',
    BRANDS_SET_PAGINATION: 'BRANDS_SET_PAGINATION',
    BRANDS_FETCH_SUCCESS: 'BRANDS_FETCH_SUCCESS',

    BRAND_FETCH_REQUEST: 'BRAND_FETCH_REQUEST',
    BRAND_FETCH_SUCCESS: 'BRAND_FETCH_SUCCESS',
    BRAND_FETCHING: 'BRAND_FETCHING',

    searchBrand: ({ query = '', page = 1, per = 20 } = {}) => {
        return {
            type: actions.BRANDS_SEARCH_REQUEST,
            payload: { query, page, per }
        }
    },

    fetchBrand: ({ id = null, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.BRAND_FETCH_REQUEST,
            payload: { id, onSuccess, onError }
        }
    }

}

export default actions
