import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Layout } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import defaultTheme from '../../../config/theme/themedefault'
import TopbarUser from './topbarUser'
import TopbarUserName from './topbarUserName'
import TopbarNotification from './topbarNotification'
import TopbarWrapper from './topbar.style'

import appActions from '../../redux/app/actions'

const { Header } = Layout
const { toggleCollapsed } = appActions

class Topbar extends Component {
    render () {
        const { app, i18n, toggleCollapsed } = this.props
        const locale = i18n.language
        const collapsed = app.get('collapsed') && !app.get('openDrawer')
        const styling = {
            background: defaultTheme.backgroundColor,
            position: 'fixed',
            width: '100%',
            height: 70
        }

        return (
            <TopbarWrapper>
                <Header style={styling} className={collapsed ? 'isomorphicTopbar collapsed' : 'isomorphicTopbar'}>
                    <div className="isoLeft">
                        <button
                            className={collapsed ? 'triggerBtn menuCollapsed' : 'triggerBtn menuOpen'}
                            style={{ color: defaultTheme.textColor }}
                            onClick={toggleCollapsed}
                        >
                            {
                                collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                            }
                        </button>
                    </div>

                    <ul className="isoRight">
                        <li onClick={() => i18n.changeLanguage(i18n.language === 'th' ? 'en' : 'th')}>
                            {i18n.t('change-locale')}
                        </li>
                        <li className="isoNotify" onClick={() => this.setState({ selectedItem: 'notification' })}>
                            <TopbarNotification locale={'th'} />
                        </li>
                        <li>
                            <TopbarUserName />
                        </li>
                        <li className="isoUser" onClick={() => this.setState({ selectedItem: 'user' })}>
                            <TopbarUser locale={'th'} />
                        </li>
                    </ul>
                </Header>
            </TopbarWrapper>
        )
    }
}

const mapStateToProps = state => ({
  app: state.get('App')
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    toggleCollapsed
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Topbar))

