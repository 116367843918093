import styled from 'styled-components'

const ChartOfAccountsListWrapper = styled.div`
  .charge-of-accounts-list-header {
    margin-bottom: 1rem;
    justify-content: space-between;
    display: flex;
  }
`

export default ChartOfAccountsListWrapper
