import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Col, Divider, Row, Select, Spin, Form } from 'antd'
import { UserOutlined , BankOutlined } from '@ant-design/icons'

const { Option } = Select

class TruckOwnerSelect extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        selectedTruckOwner: PropTypes.string,
        truckOwners: PropTypes.array.isRequired
    }

    renderTruckOwnerOptions () {
        return this.props.truckOwners.map((truckOwner, index) => {
            return (
                <Option
                    key={`truck-owner-${_.get(truckOwner, 'id', `000-${index}`)}`}
                    value={_.get(truckOwner, 'id', '')}>
                    {_.get(truckOwner, 'name', '')}
                </Option>
            )
        })
    }

    render () {
        const { i18n, loading, onChange, onSearch, selectedTruckOwner, filedName } = this.props

        return (
            <div className="truck-owner-select-component" key="truck-owner-select-component">
                <Row gutter={[8, 4]} key="select">
                    <Col span={20}>
                        <Form.Item noStyle name={filedName}>
                            <Select
                                allowClear
                                filterOption={false}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                                onChange={onChange}
                                onSearch={onSearch}
                                placeholder={i18n.t('trucks:selectTruckOwner')}
                                showSearch
                                style={{ width: '100%' }}
                                value={selectedTruckOwner}>
                                {this.renderTruckOwnerOptions()}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={4} style={{ textAlign: 'center' }}>
                        <span>
                            <UserOutlined />
                            <Divider type="vertical" />
                            <BankOutlined />
                        </span>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withTranslation(['trucks'])(TruckOwnerSelect)
