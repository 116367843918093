import Immutable, { Map } from 'immutable'
import _ from 'lodash'

import districtActions from '../districts/actions'
import imageActions from '../images/actions'
import { initialImageEntityState } from '../images/reducers'

const { DISTRICTS_FETCH_SUCCESS } = districtActions

const { IMAGES_ADD_NEW_IMAGE } = imageActions

const initState = new Map({
    bankAccounts: new Map(),
    bankStatements: new Map(),
    businessAddresses: new Map(),
    categories: new Map(),
    chatRoomMessages: new Map(),
    chatRooms: new Map(),
    companyProjects: new Map(),
    customerCompanies: new Map(),
    customerCompanyProjects: new Map(),
    customerEmails: new Map(),
    customerLines: new Map(),
    customerOccupations: new Map(),
    customerRoles: new Map(),
    customerPhoneNumbers: new Map(),
    customers: new Map(),
    customerTypes: new Map(),
    customerWallets: new Map(),
    customerReviews: new Map(),
    districts: new Map(),
    journalEntryFormats: new Map(),
    journalEntryFormatItems: new Map(),
    journalEntries: new Map(),
    journalEntryDates: new Map(),
    images: new Map(),
    inquiry: new Map(),
    inquiryTransferBacks: new Map(),
    invoiceAdjustmentItems: new Map(),
    invoiceAdjustments: new Map(),
    invoiceEmailHistories: new Map(),
    invoiceHistories: new Map(),
    items: new Map(),
    itemTruckImages: new Map(),
    itemWithProductGroups: new Map(),
    lineItems: new Map(),
    mailingAddresses: new Map(),
    noteAttachments: new Map(),
    notifications: new Map(),
    orderApproverWaits: new Map(),
    orderApproverWaitStates: new Map(),
    orderBusinessAddresses: new Map(),
    orderChangeActivity: new Map(),
    orderCompleteNotes: new Map(),
    orderCompleteStates: new Map(),
    orderConditions: new Map(),
    orderDispatches: new Map(),
    orderMailingAddresses: new Map(),
    orderPaymentDetailList: new Map(),
    orders: new Map(),
    orderShippingAddress: new Map(),
    orderShippingItems: new Map(),
    orderStateChangeActivity: new Map(),
    orderStates: new Map(),
    orderSummaries: new Map(),
    orderSummarySales: new Map(),
    orderSupplierNoteImages: new Map(),
    orderSupplierNotes: new Map(),
    orderTrackings: new Map(),
    orderVoucherChecks: new Map(),
    orderYields: new Map(),
    paymentDetails: new Map(),
    paymentOptions: new Map(),
    paymentVoucherImages: new Map(),
    paymentVouchers: new Map(),
    pickupDetails: new Map(),
    potentialLeads: new Map(),
    productGroupItems: new Map(),
    productGroups: new Map(),
    profitSharings: new Map(),
    projectContacts: new Map(),
    provinces: new Map(),
    purchaseOrderAddresses: new Map(),
    purchaseOrderStatusHistories: new Map(),
    reservationTruckTypes: new Map(),
    saleProspects: new Map(),
    sales: new Map(),
    scbPaymentConfirms: new Map(),
    shippingAddresses: new Map(),
    shippingCosts: new Map(),
    shippingRates: new Map(),
    specialInstructions: new Map(),
    staffRoles: new Map(),
    staffs: new Map(),
    supplierAccountingGroups: new Map(),
    supplierInvoices: new Map(),
    supplierItems: new Map(),
    supplierLineItems: new Map(),
    supplierOrderCategories: new Map(),
    supplierOrderChangeActivity: new Map(),
    supplierOrderLineItems: new Map(),
    supplierOrderReviews: new Map(),
    supplierOrders: new Map(),
    supplierOrderStateChangeActivity: new Map(),
    supplierOverTransferAccounts: new Map(),
    supplierOverTransferDestinations: new Map(),
    supplierOverTransferItems: new Map(),
    suppliers: new Map(),
    supplierShippingCosts: new Map(),
    supplierShippingTrucks: new Map(),
    truckOriginAddresses: new Map(),
    truckOwnerBankAccounts: new Map(),
    truckOwners: new Map(),
    truckReservations: new Map(),
    trucks: new Map(),
    vehicleTypes: new Map(),
    walletSummaries: new Map(),
    withholdingTaxes: new Map()
})

// mergeDeep that replaces arrays with instead of merging
export function mergeDeepReplaceArrays(state, updatedData) {
    const replaceArrays = (data, basePath = []) => {
        // When value is an array, replace current state with updated array data
        // When value is an object, do nothing
        _.forIn(data, (value, key) => {
            const path = basePath.concat([key])
            if (Array.isArray(value)) {
                const currentData = state.getIn(path)

                if (!currentData) {
                    return
                }

                state = state.setIn(path, Immutable.fromJS(value))
                // eslint-disable-next-line prefer-reflect
                delete data[key]
            } else if (_.isObject(value)) {
                replaceArrays(value, path)
            }
        })
    }
    replaceArrays(updatedData)

    // merge state with updated data(without array)
    return state.mergeDeep(Immutable.fromJS(updatedData))
}

export default function entitiesReducer(state = initState, action) {
    if (action.entities) {
        state = mergeDeepReplaceArrays(state, action.entities)
    }

    switch (action.type) {
        case IMAGES_ADD_NEW_IMAGE:
            return state.setIn(
                ['images', action.thumbUrl],
                initialImageEntityState.merge({
                    contentType: action.contentType,
                    originalUrl: action.originalUrl,
                    thumbUrl: action.thumbUrl
                })
            )

        case DISTRICTS_FETCH_SUCCESS:
            return state.setIn(['provinces', action.provinceId.toString(), 'districts'], action.result)

        default:
            return state
    }
}
