import styled from 'styled-components'
const SupplierOrderWrapper = styled.div`

  .ant-layout {
    background: white;
  }

  .ant-layout-sider {
    background: white;
    margin-right: 10px;
  }

  .ant-layout-content {
    background: white;
    padding-bottom: 10px;
    text-align: right;
  }

  .btn-center {
    text-align: center
  }

  .text-right {
    text-align: right
  }

  .text-left {
    text-align: left
  }

  .header {
    margin: 5px;
  }

  .link-form {
    color: #1890ff;
    cursor:pointer;
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .detail-container > span {
    color: #000;
  }

  div.ql-toolbar {
    display: none;
  }

  div.ql-editor {
    border-top: 1px solid #ccc
  }

  .export-pv-btn {
    margin-top: 10px;
  }

  .paymentVoucher {
    color: red;
  }
`
export default SupplierOrderWrapper