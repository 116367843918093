import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Popover } from 'antd'
import TopbarDropdownWrapper from './topbarDropdown.style'
import userpic from '../../../public/images/user-avatar.png'

import authAction from '../../redux/auth/actions'

const { logout } = authAction

class TopbarUser extends Component {
    constructor (props) {
        super(props)

        this.state = {
            visible: false
        }
    }

    handleVisibleChange = () => {
        this.setState({ visible: !this.state.visible })
    }

    render () {
      const { logout } = this.props
      const content = (
            <TopbarDropdownWrapper className="isoUserDropdown">
                <a className="isoDropdownLink">Settings</a>
                <a className="isoDropdownLink">Feedback</a>
                <a className="isoDropdownLink">Help</a>
                <a className="isoDropdownLink" onClick={logout}>Logout</a>
            </TopbarDropdownWrapper>
        )

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    <img alt="user" src={userpic} />
                    <span className="userActivity online" />
                </div>
            </Popover>
        )
    }
}

export default connect(null, { logout })(TopbarUser)
