const actions = {
    CHECK_ORDER_VOUCHERS_REQUEST: 'CHECK_ORDER_VOUCHERS_REQUEST',
    CHECK_ORDER_VOUCHERS_SUCCESS: 'CHECK_ORDER_VOUCHERS_SUCCESS',
    CHECK_ORDER_VOUCHERS: 'CHECK_ORDER_VOUCHERS',
    FETCH_ORDER_SHIPPING_COSTS_REQUEST: 'FETCH_ORDER_SHIPPING_COSTS_REQUEST',
    FETCH_ORDER_SHIPPING_COSTS_SUCCESS: 'FETCH_ORDER_SHIPPING_COSTS_SUCCESS',
    FETCH_ORDER_SHIPPING_COSTS: 'FETCH_ORDER_SHIPPING_COSTS',
    ORDER_FETCH_REQUEST: 'ORDER_FETCH_REQUEST',
    ORDER_FETCH_SUCCESS: 'ORDER_FETCH_SUCCESS',
    ORDER_FETCH: 'ORDER_FETCH',
    // ORDER_REDO_SALE: 'ORDER_REDO_SALE',
    ORDER_SET_LOADING: 'ORDER_SET_LOADING',
    ORDER_SHIPPING_COST_DELETE_REQUEST: 'ORDER_SHIPPING_COST_DELETE_REQUEST',
    ORDER_SHIPPING_COST_DELETE_SUCCESS: 'ORDER_SHIPPING_COST_DELETE_SUCCESS',
    ORDER_SHIPPING_COST_FORM_ERRORS: 'ORDER_SHIPPING_COST_FORM_ERRORS',
    ORDER_SHIPPING_COST_SAVE_REQUEST: 'ORDER_SHIPPING_COST_SAVE_REQUEST',
    ORDER_SHIPPING_COST_SAVE_SUCCESS: 'ORDER_SHIPPING_COST_SAVE_SUCCESS',
    ORDER_SHIPPING_COST_SAVING: 'ORDER_SHIPPING_COST_SAVING',
    // ORDER_SUPPLIER_NOTE_FETCH_REQUEST: 'ORDER_SUPPLIER_NOTE_FETCH_REQUEST',
    // ORDER_SUPPLIER_NOTE_FETCH_SUCCESS: 'ORDER_SUPPLIER_NOTE_FETCH_SUCCESS',
    // ORDER_SUPPLIER_NOTE_FETCH: 'ORDER_SUPPLIER_NOTE_FETCH',
    ORDER_SUPPLIER_NOTE_SAVE_REQUEST: 'ORDER_SUPPLIER_NOTE_SAVE_REQUEST',
    ORDER_SUPPLIER_NOTE_SAVE_SUCCESS: 'ORDER_SUPPLIER_NOTE_SAVE_SUCCESS',
    ORDER_SUPPLIER_NOTE_SAVING: 'ORDER_SUPPLIER_NOTE_SAVING',
    ORDER_SUPPLIER_NOTES_FETCH_REQUEST: 'ORDER_SUPPLIER_NOTES_FETCH_REQUEST',
    ORDER_SUPPLIER_NOTES_FETCH_SUCCESS: 'ORDER_SUPPLIER_NOTES_FETCH_SUCCESS',
    ORDER_SUPPLIER_NOTES_FETCH: 'ORDER_SUPPLIER_NOTES_FETCH',
    // ORDER_UPDATE_SALE_DISTRIBUTION: 'ORDER_UPDATE_SALE_DISTRIBUTION',
    // ORDER_UPDATE_SALE_REQUEST: 'ORDER_UPDATE_SALE_REQUEST',
    // ORDER_UPDATE_SALE_SUCCESS: 'ORDER_UPDATE_SALE_SUCCESS',
    ORDERS_CSES_FETCH_SUCCESS: 'ORDERS_CSES_FETCH_SUCCESS',
    ORDERS_CSES_FETCH: 'ORDERS_CSES_FETCH',
    ORDERS_CSES_SEARCH_REQUEST: 'ORDERS_CSES_SEARCH_REQUEST',
    ORDERS_CSES_SET_QUERY: 'ORDERS_CSES_SET_QUERY',
    ORDERS_FETCH_REQUEST: 'ORDERS_FETCH_REQUEST',
    ORDERS_FETCH_SUCCESS: 'ORDERS_FETCH_SUCCESS',
    ORDERS_FETCHING: 'ORDERS_FETCHING',
    ORDERS_ORDER_STATES_FETCH_REQUEST: 'ORDERS_ORDER_STATES_FETCH_REQUEST',
    ORDERS_ORDER_STATES_FETCH_SUCCESS: 'ORDERS_ORDER_STATES_FETCH_SUCCESS',
    ORDERS_ORDER_STATES_FETCH: 'ORDERS_ORDER_STATES_FETCH',
    ORDERS_PURCHASERS_FETCH_SUCCESS: 'ORDERS_PURCHASERS_FETCH_SUCCESS',
    ORDERS_PURCHASERS_FETCH: 'ORDERS_PURCHASERS_FETCH',
    ORDERS_PURCHASERS_SET_QUERY: 'ORDERS_PURCHASERS_SET_QUERY',
    ORDERS_PURCHASES_SEARCH_REQUEST: 'ORDERS_PURCHASES_SEARCH_REQUEST',
    ORDERS_SALES_FETCH_SUCCESS: 'ORDERS_SALES_FETCH_SUCCESS',
    ORDERS_SALES_FETCH: 'ORDERS_SALES_FETCH',
    ORDERS_SALES_SEARCH_REQUEST: 'ORDERS_SALES_SEARCH_REQUEST',
    ORDERS_SALES_SET_QUERY: 'ORDERS_SALES_SET_QUERY',
    ORDERS_SAVE_REQUEST: 'ORDERS_SAVE_REQUEST',
    ORDERS_SAVE_SUCCESS: 'ORDERS_SAVE_SUCCESS',
    ORDERS_SET_CSES_OPTIONS: 'ORDERS_SET_CSES_OPTIONS',
    ORDERS_SET_DATE: 'ORDERS_SET_DATE',
    ORDERS_SET_FORM_ERRORS: 'ORDERS_SET_FORM_ERRORS',
    ORDERS_SET_PAYMENT_OPTIONS: 'ORDERS_SET_PAYMENT_OPTIONS',
    ORDERS_SET_PURCHASE_COMPANY: 'ORDERS_SET_PURCHASE_COMPANY',
    ORDERS_SET_SALES_OPTIONS: 'ORDERS_SET_SALES_OPTIONS',
    ORDERS_SET_SAVING: 'ORDERS_SET_SAVING',
    ORDERS_SET_SELF_PICKUP: 'ORDERS_SET_SELF_PICKUP',
    ORDERS_SET_TOTAL: 'ORDERS_SET_TOTAL',
    ORDERS_SET_NO_SHIPPING_ADDRESS: 'ORDERS_SET_NO_SHIPPING_ADDRESS',
    SAVE_ORDER_COMPLETE_NOTE_REQUEST: 'SAVE_ORDER_COMPLETE_NOTE_REQUEST',
    SAVE_ORDER_COMPLETE_NOTE_SUCCESS: 'SAVE_ORDER_COMPLETE_NOTE_SUCCESS',
    SAVE_ORDER_COMPLETE_NOTE: 'SAVE_ORDER_COMPLETE_NOTE',
    SAVE_ORDER_DO_NOT_TRANSFER_REQUEST: 'SAVE_ORDER_DO_NOT_TRANSFER_REQUEST',
    // SAVE_ORDER_DO_NOT_TRANSFER: 'SAVE_ORDER_DO_NOT_TRANSFER',
    SAVE_ORDER_DO_NOT_TRANSFER_SUCCESS: 'SAVE_ORDER_DO_NOT_TRANSFER_SUCCESS',
    SET_ORDER_SUPPLIER_NOTE_FORM_MODAL: 'SET_ORDER_SUPPLIER_NOTE_FORM_MODAL',
    // SET_SHIPPING_COST_ID: 'SET_SHIPPING_COST_ID',
    // SET_SHIPPING_COST_MODAL: 'SET_SHIPPING_COST_MODAL',
    SET_SHIPPING_ADDRESS_INFO: 'SET_SHIPPING_ADDRESS_INFO',
    FETCH_ORDER_SHIPPING_RATE_REQUEST: 'FETCH_ORDER_SHIPPING_RATE_REQUEST',
    FETCH_ORDER_SHIPPING_RATE: 'FETCH_ORDER_SHIPPING_RATE',
    FETCH_ORDER_SHIPPING_RATE_SUCCESS: 'FETCH_ORDER_SHIPPING_RATE_SUCCESS',
    SET_SHIPPING_RATE_PRICE: 'SET_SHIPPING_RATE_PRICE',
    FETCH_ORDER_LINE_ITEM_IDS_REQUEST: 'FETCH_ORDER_LINE_ITEM_IDS_REQUEST',
    FETCH_ORDER_LINE_ITEM_IDS_SUCCESS: 'FETCH_ORDER_LINE_ITEM_IDS_SUCCESS',
    FETCH_ORDER_LINE_ITEM_IDS: 'FETCH_ORDER_LINE_ITEM_IDS',
    SET_ORDER_SHIPPING_TYPE: 'SET_ORDER_SHIPPING_TYPE',
    SET_SHIPPING_ADDRESS_ERROR: 'SET_SHIPPING_ADDRESS_ERROR',

    fetchOrders: (orderType = 'all', queryObj = {}) => {
        return {
            type: actions.ORDERS_FETCH_REQUEST,
            payload: { orderType, queryObj }
        }
    },

    fetchOrder: (orderId) => ({
        type: actions.ORDER_FETCH_REQUEST,
        payload: { orderId }
    }),

    saveOrder: (order, id, { redirectToInquiryPage = true, onSuccess = () => {}}) => ({
        type: actions.ORDERS_SAVE_REQUEST,
        payload: { order, id, redirectToInquiryPage, onSuccess }
    }),

    setDeliveryDate: (value) => ({
        type: actions.ORDERS_SET_DATE,
        value
    }),

    setSelfPickup: (value) => ({
        type: actions.ORDERS_SET_SELF_PICKUP,
        value
    }),

    setNoShippingAddress: (value) => ({
        type: actions.ORDERS_SET_NO_SHIPPING_ADDRESS,
        value
    }),

    setPurchaseCompany: (value) => ({
        type: actions.ORDERS_SET_PURCHASE_COMPANY,
        value
    }),

    setPaymentOptions: (value) => ({
        type: actions.ORDERS_SET_PAYMENT_OPTIONS,
        value
    }),

    // fetchCustomerServices: () => ({
    //     type: actions.ORDERS_CUSTOMER_SERVICE_FETCH_REQUEST
    // }),

    // fetchSales: () => ({
    //     type: actions.ORDERS_SALES_FETCH_REQUEST
    // }),

    searchCses: (query) => {
        return {
            type: actions.ORDERS_CSES_SEARCH_REQUEST,
            payload: { query }
        }
    },

    searchPurchases: (query) => {
        return {
            type: actions.ORDERS_PURCHASES_SEARCH_REQUEST,
            payload: { query }
        }
    },

    setCsOption: (query) => {
        return {
            type: actions.ORDERS_SET_CSES_OPTIONS,
            query
        }
    },
    // orderUpdate: (orderNumber, values, type = 'update') => {
    //     return {
    //         type: actions.ORDER_UPDATE_SALE_REQUEST,
    //         payload: { orderNumber, values, type }
    //     }
    // },
    searchSales: (query) => {
        return {
            type: actions.ORDERS_SALES_SEARCH_REQUEST,
            payload: { query }
        }
    },

    setSaleOption: (query) => {
        return {
            type: actions.ORDERS_SET_SALES_OPTIONS,
            query
        }
    },

    fetchOrderStates: ({ allState = false } = {}) => ({
        type: actions.ORDERS_ORDER_STATES_FETCH_REQUEST,
        payload: { allState }
    }),

    fetchOrderShippingCost: (id, { onSuccess = () => {} }) => ({
        type: actions.FETCH_ORDER_SHIPPING_COSTS_REQUEST,
        payload: { id, onSuccess }
    }),
    // setShowShippingCostForm: (value) => ({
    //     type: actions.SET_SHIPPING_COST_MODAL,
    //     value
    // }),
    saveOrderShippingCost: ({ orderId, id, parameters } = {}) => ({
        type: actions.ORDER_SHIPPING_COST_SAVE_REQUEST,
        payload: { orderId, id, parameters }
    }),
    // setShippingCostId: (id) => ({
    //     type: actions.SET_SHIPPING_COST_ID,
    //     id
    // }),
    deleteShippingCost: (orderId, id) => ({
        type: actions.ORDER_SHIPPING_COST_DELETE_REQUEST,
        payload: { orderId, id }
    }),
    setOrderSupplierNoteFormModal: (value) => ({
        type: actions.SET_ORDER_SUPPLIER_NOTE_FORM_MODAL,
        value
    }),
    fetchOrderSupplierNotes: (orderId) => ({
        type: actions.ORDER_SUPPLIER_NOTES_FETCH_REQUEST,
        payload: { orderId }
    }),
    saveOrderSupplierNote: (orderId, values, id, { onSuccess = () => {} } = {}) => ({
        type: actions.ORDER_SUPPLIER_NOTE_SAVE_REQUEST,
        payload: { orderId, values, id, onSuccess }
    }),
    // fetchOrderSupplierNote: (orderId, id) => ({
    //     type: actions.ORDER_SUPPLIER_NOTE_FETCH_REQUEST,
    //     payload: { orderId, id }
    // }),
    checkOrderVouchers: ({ orderId, value }) => ({
        type: actions.CHECK_ORDER_VOUCHERS_REQUEST,
        payload: { orderId, value }
    }),
    saveOrderCompleteNote: ({ orderId, values, onSuccess = () => {} } = {}) => ({
        type: actions.SAVE_ORDER_COMPLETE_NOTE_REQUEST,
        payload: { orderId, values, onSuccess }
    }),

    saveDoNotTransfer: ({ orderId, values, onSuccess = () => {} } = {}) => ({
        type: actions.SAVE_ORDER_DO_NOT_TRANSFER_REQUEST,
        payload: { orderId, values, onSuccess }
    }),

    setShippingAddressInfo: ({
        provinceId = '',
        districtId = '',
        postalCode = '',
        shippingDetail = '',
        additionalInformation = '',
        contactNumber = ''
    } = {}) => ({
        type: actions.SET_SHIPPING_ADDRESS_INFO,
        provinceId,
        districtId,
        postalCode,
        shippingDetail,
        additionalInformation,
        contactNumber
    }),
    fetchOrderShippingRate: ({
        orderId = '',
        vehicleTypeId = '',
        onSuccess = () => {} }) => ({
        type: actions.FETCH_ORDER_SHIPPING_RATE_REQUEST,
        payload: { orderId, vehicleTypeId, onSuccess }
    }),
    setShippingRatePrice: (price) => ({
        type: actions.SET_SHIPPING_RATE_PRICE,
        price
    }),
    fetchLineItemIds: (orderId) => ({
        type: actions.FETCH_ORDER_LINE_ITEM_IDS_REQUEST,
        payload: { orderId }
    }),
    setShippingType: (shippingType) => ({
        type: actions.SET_ORDER_SHIPPING_TYPE,
        shippingType
    }),

    setShippingAddessError: (value) => ({
        type: actions.SET_SHIPPING_ADDRESS_ERROR,
        value
    })
}

export default actions
