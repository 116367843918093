import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import TruckReservationInfo from '../../components/orders/truckReservationInfo'

import truckReservationActions from '../../redux/truckReservations/actions'
import orderActions from '../../redux/orders/actions'

const { fetchTruckReservations } = truckReservationActions
const { fetchOrderSupplierNotes } = orderActions

class TruckReservationInfoContainer extends Component {
    static propTypes = {
        isExpand: PropTypes.bool,
        order: ImmutablePropTypes.map.isRequired,

        fetchOrderSupplierNotes: PropTypes.func.isRequired,
        fetchTruckReservations: PropTypes.func.isRequired,
        orderSupplierNoteEntities: ImmutablePropTypes.map.isRequired,
        orderSupplierNoteImageEntities: ImmutablePropTypes.map.isRequired,
        shippingCostEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        truckReservationEntities: ImmutablePropTypes.map.isRequired,
        truckReservations: ImmutablePropTypes.map.isRequired
    }

    constructor (props) {
        super(props)

        this.state = {
            expand: props.isExpand
        }
    }

    componentDidMount () {
        const { fetchOrderSupplierNotes, fetchTruckReservations, order } = this.props
        const orderId = order.get('id')

        if (orderId) {
            fetchOrderSupplierNotes(orderId)
            fetchTruckReservations({ orderId })
        }
    }

    getOrder () {
        const { order, shippingCostEntities } = this.props

        if (!order.size) { return new Map() }

        const shippingCostIds = order.get('shipping_costs') || new List()

        const shippingCosts = shippingCostIds.map((shippingCostId) => {
            return shippingCostEntities.get(shippingCostId) || new Map()
        })

        return new Map({
            ...order.toJS(),
            shipping_costs: shippingCosts.toJS()
        })
    }

    getOrderSupplierNotes () {
        const { orders, orderSupplierNoteEntities, orderSupplierNoteImageEntities } = this.props
        const items = orders.get('orderSupplierNoteItems') || new List()

        return items.map((id) => {
            const orderSupplierNote = orderSupplierNoteEntities.get(_.toString(id)) || new Map()
            const images = orderSupplierNote.get('images').map((imageId) => {
                return orderSupplierNoteImageEntities.get(imageId) || new Map()
            })

            return new Map({
                ...orderSupplierNote.toJS(),
                images: images.toJS()
            })
        })
    }

    getSupplierOrders = (supplierOrderIds) => {
        const { supplierOrderEntities } = this.props
        return supplierOrderIds.map((id) => {
            return supplierOrderEntities.get(id) || new Map()
        })
    }

    getTruckReservations () {
        const { truckReservationEntities, truckReservations } = this.props
        const items = truckReservations.get('items') || new List()

        return items.map((id) => {
            const truckReservation = truckReservationEntities.get(id) || new Map()
            return new Map({
                ...truckReservation.toJS(),
                supplier_orders: this.getSupplierOrders(truckReservation.get('supplier_orders')).toJS()
            })
        })
    }

    toggleExpand = () => {
        this.setState({ expand: !this.state.expand })
    }

    render () {
        const { expand } = this.state

        return (
            <TruckReservationInfo
                expand={expand}
                order={this.getOrder().toJS()}
                orderSupplierNotes={this.getOrderSupplierNotes().toJS()}
                truckReservations={this.getTruckReservations().toJS()}
                toggleExpand={this.toggleExpand} />
        )
    }
}

const mapStateToProps = (state) => ({
    orders: state.get('orders'),
    orderSupplierNoteEntities: state.getIn(['Entities', 'orderSupplierNotes']),
    orderSupplierNoteImageEntities: state.getIn(['Entities', 'orderSupplierNoteImages']),
    shippingCostEntities: state.getIn(['Entities', 'shippingCosts']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    truckReservationEntities: state.getIn(['Entities', 'truckReservations']),
    truckReservations: state.get('truckReservations')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderSupplierNotes,
        fetchTruckReservations
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckReservationInfoContainer)
