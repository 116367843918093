import { Map, List } from 'immutable'
import actions from './actions'

const {
    COMPANIES_SET_MODAL,
    COMPANIES_SET_CLOSE_MODAL,
    COMPANIES_SAVE,
    COMPANIES_SAVE_SUCCESS,
    COMPANIES_SET_FORM_ERRORS,
    COMPANIES_CLEAR_FORM_ERRORS,

    COMPANIES_FETCH,
    COMPANIES_FETCH_SUCCESS,
    COMPANIES_SET_QUERY,
    COMPANIES_SET_OPTIONS,
    COMPANIES_FETCHING,
    COMPANIES_SET_PAGINATION,
    COMPANIES_LIST_FETCH_SUCCESS,
    COMPANY_FETCH,
    COMPANY_FETCH_SUCCESS,
    COMPANY_ORDERS_FETCH,
    COMPANY_ORDERS_FETCH_SUCCESS,
    COMPANY_ORDERS_SET_PAGINATION
} = actions

const initState = new Map({
    items: new List(),
    formErrors: new Map(),
    saving: false,
    openAddcompanyModal: false,
    query: '',
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    }),
    ordersloading: false,
    orderItems: new List(),
    ordersPagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    })
})

export default function companiesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case COMPANIES_SAVE:
            return state.merge({ saving: true })

        case COMPANIES_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: items.includes(result) ? items : items.push(result)
            })

        case COMPANIES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })

        case COMPANIES_CLEAR_FORM_ERRORS:
            return state.merge({
                formErrors: new Map()
            })

        case COMPANIES_SET_MODAL:
            return state.merge({
                openAddcompanyModal: action.value
            })

        case COMPANIES_SET_QUERY:
            return state.merge({
                query: action.query
            })

        case COMPANIES_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })

        case COMPANIES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List().concat(result)
            })

        case COMPANIES_SET_OPTIONS:
            return state.merge({
                query: action.query
            })

        case COMPANIES_SET_CLOSE_MODAL:
            return state.merge({
                openAddcompanyModal: false
            })
        case COMPANIES_FETCHING:
            return state.merge({
                loading: true
            })
        case COMPANIES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case COMPANIES_LIST_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case COMPANY_FETCH:
            return state.merge({
                loading: true
            })

        case COMPANY_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? items : items.concat(result)
            })
        case COMPANY_ORDERS_FETCH:
            return state.merge({
                ordersloading: true
            })

        case COMPANY_ORDERS_FETCH_SUCCESS:
            return state.merge({
                ordersloading: false,
                orderItems: new List(action.result)
            })
        case COMPANY_ORDERS_SET_PAGINATION:
            return state.mergeDeep({
                ordersPagination: action.ordersPagination
            })
        default:
            return state
    }
}
