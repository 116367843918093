import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Form, Space, Spin } from 'antd'
import CustomerTypeSelector from '../../customerTypeSelector'

const LoadingSpinner = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Spin />
        </div>
    )
}

const Render = ({ initialValues, loading, onClose, onSaveCustomer, saving }) => {
    const { i18n } = useTranslation('salePipelines')
    const [form] = Form.useForm()

    if (loading) return <LoadingSpinner />

    const onSubmitForm = values => {
        onSaveCustomer(values)
    }

    return (
        <Form form={form} initialValues={initialValues} onFinish={onSubmitForm}>
            <h3>{i18n.t('salePipelines:customer')}</h3>
            <Form.Item label={i18n.t('salePipelines:position')} name="customerTypeId">
                <CustomerTypeSelector creatable />
            </Form.Item>

            <Space align="center" style={{ width: '100%', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit" disabled={saving}>
                    {i18n.t('shared:save')}
                </Button>
                <Button onClick={onClose}>{i18n.t('shared:close')}</Button>
            </Space>
        </Form>
    )
}

Render.propTypes = {
    initialValues: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSaveCustomer: PropTypes.func.isRequired
}

Render.defaultProps = {}

export default Render
