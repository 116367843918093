import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import { Tabs } from 'antd'

import { withAuthorization } from '../Authorization'
import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'
import OrderYieldsList from './OrderYieldsList'
import OrderBusinessAddressIndexContainer from '../Invoices/OrderBusinessAddressIndexContainer'
import VatDocumentsIndexContainer from '../VatDocuments/VatDocumentsIndexContainer'

class OrderYieldIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Order Yields | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/orders/yields', name: 'Order Yields' }
                    ]}>
                    <ContentLayout>
                        <Tabs
                            defaultActiveKey="yieldPortal"
                            destroyInactiveTabPane
                        >
                            <Tabs.TabPane tab="Order Yields" key="yieldPortal" forceRender>
                                <OrderYieldsList />
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="RV Invoice" key="rvInvoicePortal" forceRender>
                                <OrderBusinessAddressIndexContainer />
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="PV Invoice" key="pvInvoicePortal" forceRender>
                                <VatDocumentsIndexContainer />
                            </Tabs.TabPane>
                        </Tabs>
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withAuthorization(currentStaffContainer(OrderYieldIndexPage), ['super_admin'])
