const actions = {
    FETCH_PAYMENT_VOUCHERS_REQUEST: 'FETCH_PAYMENT_VOUCHERS_REQUEST',
    FETCH_PAYMENT_VOUCHERS_SUCCESS: 'FETCH_PAYMENT_VOUCHERS_SUCCESS',
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_REQUEST: 'FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_REQUEST',
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS: 'FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS',
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE: 'FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE',
    SAVE_PAYMENT_VOUCHER_REQUEST: 'SAVE_PAYMENT_VOUCHER_REQUEST',
    SAVE_PAYMENT_VOUCHER_SUCCESS: 'SAVE_PAYMENT_VOUCHER_SUCCESS',
    SAVE_PAYMENT_VOUCHER: 'SAVE_PAYMENT_VOUCHER',
    PAYMENT_VOUCHERS_FETCHING: 'PAYMENT_VOUCHERS_FETCHING',
    PAYMENT_VOUCHERS_SET_FILTER_DATE: 'PAYMENT_VOUCHERS_SET_FILTER_DATE',
    PAYMENT_VOUCHERS_SET_PAGINATION: 'PAYMENT_VOUCHERS_SET_PAGINATION',
    SET_PAYMENT_VOUCHERS_INVOICE_FORM: 'SET_PAYMENT_VOUCHERS_INVOICE_FORM',
    SET_PAYMENT_VOUCHERS_NOTE_FORM: 'SET_PAYMENT_VOUCHERS_NOTE_FORM',
    SET_SUPPLIER_ORDERS_REF_CODE: 'SET_SUPPLIER_ORDERS_REF_CODE',
    SUPPLIER_INVOICE_DELETE_REQUEST: 'SUPPLIER_INVOICE_DELETE_REQUEST',
    SUPPLIER_INVOICE_DELETE_SUCCESS: 'SUPPLIER_INVOICE_DELETE_SUCCESS',
    SUPPLIER_INVOICE_SAVE_REQUEST: 'SUPPLIER_INVOICE_SAVE_REQUEST',
    SUPPLIER_INVOICE_SAVE_SUCCESS: 'SUPPLIER_INVOICE_SAVE_SUCCESS',
    SUPPLIER_INVOICE_SAVING: 'SUPPLIER_INVOICE_SAVING',
    FETCH_SUPPLIER_ORDERS_FILE_REQUEST: 'FETCH_SUPPLIER_ORDERS_FILE_REQUEST',
    FETCH_SUPPLIER_ORDERS_FILE: 'FETCH_SUPPLIER_ORDERS_FILE',
    FETCH_SUPPLIER_ORDERS_FILE_SUCCESS: 'FETCH_SUPPLIER_ORDERS_FILE_SUCCESS',
    FETCH_SUPPLIER_LINE_ITEMS_FILE_REQUEST: 'FETCH_SUPPLIER_LINE_ITEMS_FILE_REQUEST',
    FETCH_SUPPLIER_LINE_ITEMS_FILE: 'FETCH_SUPPLIER_LINE_ITEMS_FILE',
    FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS: 'FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS',
    FETCH_INVOICE_1688_FILE_REQUEST: 'FETCH_INVOICE_1688_FILE_REQUEST',
    FETCH_INVOICE_1688_FILE: 'FETCH_INVOICE_1688_FILE',
    FETCH_INVOICE_1688_FILE_SUCCESS: 'FETCH_INVOICE_1688_FILE_SUCCESS',

    fetchPaymentVouchers: (queryString = '', sorter = {}) => {
        return {
            type: actions.FETCH_PAYMENT_VOUCHERS_REQUEST,
            payload: { queryString, sorter }
        }
    },

    setFilterDate: (fieldname) => {
        return { type: actions.PAYMENT_VOUCHERS_SET_FILTER_DATE, fieldname }
    },

    setPagination: (pagination) => {
        return { type: actions.PAYMENT_VOUCHERS_SET_PAGINATION, pagination }
    },

    setInvoiceForm: (value) => ({
        type: actions.SET_PAYMENT_VOUCHERS_INVOICE_FORM,
        value
    }),
    setPaymentNoteForm: (value) => ({
        type: actions.SET_PAYMENT_VOUCHERS_NOTE_FORM,
        value
    }),
    savePaymentVoucher: (values, id) => ({
        type: actions.SAVE_PAYMENT_VOUCHER_REQUEST,
        payload: { values, id }
    }),
    saveSupplierInvoice: (values, queryString = '', id) => ({
        type: actions.SUPPLIER_INVOICE_SAVE_REQUEST,
        payload: { values, queryString, id }
    }),
    deleteSupplierInvoices: (supplierInvoiceId, paymentVoucherId, queryString = '') => ({
        type: actions.SUPPLIER_INVOICE_DELETE_REQUEST,
        payload: { supplierInvoiceId, paymentVoucherId, queryString }
    }),
    setSupplierOrderRefCode: (amount) => ({
        type: actions.SET_SUPPLIER_ORDERS_REF_CODE,
        amount
    }),
    fetchTransferBackPVReport: (value) => ({
        type: actions.FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_REQUEST,
        payload: { value }
    }),

    fetchSupplierOrdersReport: (params) => ({
        type: actions.FETCH_SUPPLIER_ORDERS_FILE_REQUEST,
        payload: { params }
    }),

    fetchSupplierLineItemsReport: (params) => ({
        type: actions.FETCH_SUPPLIER_LINE_ITEMS_FILE_REQUEST,
        payload: { params }
    }),

    fetchInvoices1688: (params) => ({
        type: actions.FETCH_INVOICE_1688_FILE_REQUEST,
        payload: { params }
    }),
}

export default actions
