import React, { useState} from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Form, Spin, Button, Modal } from 'antd'

import ShippingAddressListOptions from '../customers/shippingAddresses/ListOptions'
import AddressFormContainer from '../../containers/Customers/ShippingAddresses/AddressFormContainer'

const LAST_ORDER_SHIPPING_ADDRESSES_QUERY = gql`
    query Customer($customerNo: String!) {
        customer(customerNo: $customerNo) {
            id
            lastOrderShippingAddress {
                id
                shippingAddressId
            }
        }
    }
`

const SelectShippingAddressForm = ({
  customerId
}) => {
  const [showShippingAddressForm, setShippingAddressForm] = useState(false)
  const [refetchShippingAddress, setRefetchShippingAddress] = useState(false)

  const { data, loading } = useQuery(LAST_ORDER_SHIPPING_ADDRESSES_QUERY, {
    variables: { customerNo: customerId }
  })

  if (loading) return <Spin />

  const { customer } = data
  const lastShippingAddress = customer.lastOrderShippingAddress

  const handleShowShippingAddress = () => {
    setShippingAddressForm(!showShippingAddressForm)
  }

  const onSaveSuccess = () => {
    setRefetchShippingAddress(true)
    handleShowShippingAddress()
  }

  const handleAddShippingAddress = () => {
    if (refetchShippingAddress) {
      setRefetchShippingAddress(false)
    }
    handleShowShippingAddress()
  }

  return (
    <div>
      <Button onClick={handleAddShippingAddress} style={{ marginBottom: '8px' }}>เพิ่มที่อยู่</Button>
      <Form.Item key="shipping_address_id"
          name='shipping_address_id'
          initialValue={ lastShippingAddress ? lastShippingAddress.shippingAddressId : -1 }>
            <ShippingAddressListOptions customerId={customerId}
              refetchShippingAddress={refetchShippingAddress}
              setRefetchShippingAddress={setRefetchShippingAddress}
              defaultShippingAddressId={lastShippingAddress ? lastShippingAddress.shippingAddressId : '-1'} />
      </Form.Item>
      <Modal visible={showShippingAddressForm}
        footer={null}
        width='65%'
        onCancel={handleShowShippingAddress}>
          <h2>เพิ่มที่อยู่จัดส่งของลูกค้า</h2>
          <AddressFormContainer customerId={customerId} showSubmitBtn
            onSuccess={onSaveSuccess} />
      </Modal>
    </div>
  )
}

SelectShippingAddressForm.propTypes = {}

SelectShippingAddressForm.defaultProps = {}

export default SelectShippingAddressForm
