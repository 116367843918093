import React from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import SelectLineItems from '../../containers/SelectLineItems'

const SelectInvoiceShippingCosts = ({
    i18n,
    defaultShippingCosts,
    initialData,
    loading,
    onFetchInitialData,
    onSelectShippingCostsChange,
    orderId
}) => {
    const columns = [{
        title: i18n.t('inquiries:shippingCost.vehicleType'),
        dataIndex: 'name',
        editable: true
    }, {
        title: i18n.t('inquiries:shippingCost.numberOfTrips'),
        dataIndex: 'quantity',
        editable: true
    }, {
        title: i18n.t('inquiries:shippingCost.price'),
        dataIndex: 'single_price'
    }, {
        title: i18n.t('inquiries:emptyLineBelow'),
        dataIndex: 'empty_line_below',
        editable: true
    }]

    return (
        <SelectLineItems
            columns={columns}
            dataSource={initialData}
            loading={loading}
            onChange={onSelectShippingCostsChange}
            onFetch={onFetchInitialData}
            orderId={orderId}
            value={defaultShippingCosts}
        />
    )
}

SelectInvoiceShippingCosts.propTypes = {
    defaultShippingCosts: PropTypes.array,
    initialData: PropTypes.array,
    loading: PropTypes.bool,
    onFetchInitialData: PropTypes.func,
    onSelectShippingCostsChange: PropTypes.func,
    orderId: PropTypes.string.isRequired
}

SelectInvoiceShippingCosts.defaultProps = {
    defaultShippingCosts: [],
    onSelectShippingCostsChange: () => {}
}

export default withTranslation(['inquiries'])(SelectInvoiceShippingCosts)
