import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { EditOutlined } from '@ant-design/icons'
import { Card, Row, Button, Form, Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'

import SelectCustomerCompaniesRender from './render'

const ORDER_QUERY = gql`
    query Order($number: String!) {
      order(number: $number) {
            id
            number
            purchaseOrderCompanyId
        }
    }
`

const UPDATE_COMPANY_ORDER_MUTATION = gql`
    mutation UpdateCompanyPurchaseOrder($orderId: String!, $customerCompanyId: String!) {
        updateCompanyPurchaseOrder(orderId: $orderId, customerCompanyId: $customerCompanyId) {
            errors
        }
    }
`

const SelectCustomerCompanies = ({
  companies = [],
  i18n,
  orderId
}) => {
  const [editing, setEditing] = useState(false)
  const [selectCompanyId, setSelectCompanyId] = useState(null)
  const [formErrors, setFormErrors] = useState({})

  const { loading, refetch } = useQuery(ORDER_QUERY, {
    variables: { number: orderId },
    onCompleted: ({ order }) => {
      if (order.purchaseOrderCompanyId) {
        setSelectCompanyId(order.purchaseOrderCompanyId)
      }
    }
  })

  const [updateCompanyOrder, { loading: saving }] = useMutation(UPDATE_COMPANY_ORDER_MUTATION, {
    onCompleted: ({ updateCompanyPurchaseOrder }) => {
        if (updateCompanyPurchaseOrder.errors) {
            setFormErrors(JSON.parse(updateCompanyPurchaseOrder.errors))
        } else {
          refetch()
          toggleEditing()
        }
    }
})

  const toggleEditing = () => {
    setEditing(!editing)
  }

  const onSubmit = () => {
    updateCompanyOrder({
      variables: { orderId, customerCompanyId: selectCompanyId }
    })
  }

  if (editing) {
    return (
      <Card style={{ width: '50%' }}>
        <Form onFinish={onSubmit}>
          <span>เลือกบริษัท:</span>
          <SelectCustomerCompaniesRender companies={companies}
            loading={loading}
            value={selectCompanyId}
            onChange={setSelectCompanyId}
            style={{ width: '100%' }} />
            <Row style={{ justifyContent: 'center', marginTop: '12px' }}>
                  <Button style={{ width: 60, marginRight: '8px' }}
                      type="primary"
                      size="small"
                      loading={saving}
                      htmlType="submit">
                      {i18n.t('inquiries:edit')}
                  </Button>
                  <Button style={{ width: 60 }}
                      size="small"
                      onClick={toggleEditing}>{i18n.t('inquiries:cancel')}</Button>
              </Row>
        </Form>
      </Card>
    )
  }

  const currentCompany = companies.find(company => company.id === selectCompanyId)

  return (
    <div>
      {loading ? <Spin /> :
      <div>
        <span>บริษัท: {currentCompany ? currentCompany.name :  '-'}</span>
        <a onClick={toggleEditing}><EditOutlined  /></a>
      </div>
      }
    </div>
  )
}

SelectCustomerCompanies.propTypes = {
  companies: PropTypes.array,
  i18n: PropTypes.object,
  orderId: PropTypes.string.isRequired
}

SelectCustomerCompanies.defaultProps = {}

export default  withTranslation(['inquirie'])(SelectCustomerCompanies)
