import styled from 'styled-components'

export const dotColors = {
    'dust-red-dot': '#f5222d',
    'volcano-dot': '#fa541c',
    'sunset-orange-dot': '#fa8c16',
    'calendula-gold-dot': '#faad14',
    'sunrise-yellow-dot': '#fadb14',
    'lime-dot': '#a0d911',
    'polar-green-dot': '#52c41a',
    'golden-purple-dot': '#722ed1',
    'magenta-dot': '#eb2f96'
}

const dotStyles = Object.keys(dotColors).map((colorName) => {
    return `circle.${colorName} {
        fill: ${dotColors[colorName]};
    }`
}).join(' ')

const HistoryDashboardWrapper = styled.div`
    .history-dashboard-component {
        margin-top: 35px;
    }

    .history-dashboard-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
    }

    ${dotStyles}
`

export default HistoryDashboardWrapper
