const actions = {
    CUSTOMER_REVIEWS_FETCH_REQUEST: 'CUSTOMER_REVIEWS_FETCH_REQUEST',
    CUSTOMER_REVIEWS_FETCH: 'CUSTOMER_REVIEWS_FETCH',
    CUSTOMER_REVIEWS_FETCH_SUCCESS: 'CUSTOMER_REVIEWS_FETCH_SUCCESS',
    CUSTOMER_REVIEWS_SAVE_REQUEST: 'CUSTOMER_REVIEWS_SAVE_REQUEST',
    CUSTOMER_REVIEWS_SAVE_SUCCESS: 'CUSTOMER_REVIEWS_SAVE_SUCCESS',
    CUSTOMER_REVIEWS_SAVE: 'CUSTOMER_REVIEWS_SAVE',
    CUSTOMER_REVIEWS_SET_FORM_ERRORS: 'CUSTOMER_REVIEWS_SET_FORM_ERRORS',

    fetchReviews: (id, orderId) => {
        return {
            type: actions.CUSTOMER_REVIEWS_FETCH_REQUEST,
            payload: { id, orderId }
        }
    },

    saveReview: (review, id, orderId, { onSuccess = () => {} } = {}) => {
        return {
            type: actions.CUSTOMER_REVIEWS_SAVE_REQUEST,
            payload: { review, id, orderId, onSuccess }
        }
    }
}

export default actions
