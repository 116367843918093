const actions = {
    ORDER_PAYMENT_DETAILS_REQUEST: 'ORDER_PAYMENT_DETAILS_REQUEST',
    ORDER_PAYMENT_DETAILS_REQUESTING: 'ORDER_PAYMENT_DETAILS_REQUESTING',
    ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS: 'ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS',

    ORDER_PAYMENT_DETAIL_SAVE_REQUEST: 'ORDER_PAYMENT_DETAIL_SAVE_REQUEST',
    ORDER_PAYMENT_DETAIL_SAVE: 'ORDER_PAYMENT_DETAIL_SAVE',
    ORDER_PAYMENT_DETAIL_SAVE_SUCCESS: 'ORDER_PAYMENT_DETAIL_SAVE_SUCCESS',
    ORDER_PAYMENT_DETAIL_SET_ERRORS: 'ORDER_PAYMENT_DETAIL_SET_ERRORS',

    ORDER_PAYMENT_DETAILS_SET_PAGINATION: 'ORDER_PAYMENT_DETAILS_SET_PAGINATION',

    fetchOrderPaymentDetails: ({
                                   page = 1,
                                   transferStartDate = '',
                                   transferEndDate = '',
                                   haveBankStatement = ''
                               }) => {
        return {
            type: actions.ORDER_PAYMENT_DETAILS_REQUEST,
            payload: { page, transferStartDate, transferEndDate, haveBankStatement }
        }
    },

    saveOrderPaymentDetail: (id = null, { params, onSuccess = () => {}, onError = () => {} } = {}) => ({
      type: actions.ORDER_PAYMENT_DETAIL_SAVE_REQUEST,
      payload: { id, params, onSuccess, onError }
    }),

    setPagination: (pagination) => {
        return {
            type: actions.ORDER_PAYMENT_DETAILS_SET_PAGINATION,
            pagination
        }
    }
}

export default actions
