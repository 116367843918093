import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

import { gql, useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { Modal, Divider, Tabs, Spin } from 'antd'

import SupplierOrderTable from './NewSupplierOrderTable'
import SupplierOrderFilters from './SupplierOrderFilters'
import SuppliersOrdersFormContainer from '../Suppliers/Orders/SuppliersOrdersFormContainer'
import ImportSupplierShippingCost from './ImportSupplierShippingCost'
import ExportSupplierShippingCost from './ExportSupplierShippingCost'
import ExportDriverInfo from './ExportDriverInfo'
import TransferBackListContainer from './TransferBackListContainer'
import SupplierOrderWrapper from './supplierOrder.style'

import supplierActions from '../../redux/suppliers/actions'
import supplierOrderActions from '../../redux/supplierOrders/actions'
import supplierAccountingGroupActions from '../../redux/suppliers/accountingGroups/actions'

const { toggleCreatePurchase, fetchSuppliers } = supplierActions
const { fetchSupplierAccountingGroups } = supplierAccountingGroupActions
const {
	setSupplierOrderSelected,
	fetchSupplierStates
} = supplierOrderActions

const SUPPLIER_ORDERS_QUERY = gql`
  query SupplierOrders($page: Int, $perPage: Int, $supplierOrderFilters: AdminSupplierOrderFilterInput) {
    supplierOrders(page: $page, perPage: $perPage, supplierOrderFilters: $supplierOrderFilters) {
      supplierOrders {
        id
        refCode
        state
        supplierName
        paymentType
        isAvailable
        note
        shippingDate
        grandTotal {
			display
			raw
		}
        supplierOrderType
        whoRequestForPaymentFirstName
        displayTruckReservationCostsSum
        sumWithdrawAmount {
          display
          raw
        }
        sumDepositAmount {
          display
          raw
        }
        grandTotalAfterWithdraw {
          raw
          display
        }
        images {
          id
          file {
            small
            original
            url
          }
        }
        order {
          id
          number
          shippingType
          orderYield {
            rv {
              display
            }
            yield {
              raw
            }
          }
          doNotTransfer
        }
        trucks {
          licensePlateNumber
        }
        supplier {
          isVerified
          bankName
          bankAccountNumber
          conditionNote
          supplierOverTransferAccountBalance {
            display
          }
        }
        paymentVoucher {
          id
          amount {
			display
		  }
          dateOfPurchase
          transferDate
          isExported
          images {
            id
            file {
              small
              original
              url
            }
          }
        }
      }
      totalPages
    }
  }
`
const PAGE_SIZE = 20
const DEFAULT_PARAM_FILTERS = { order_state: 'payment', state: 'chosen_by_osh' }

const NewSupplierOrderIndexContainer = ({ location, history }) => {
	const { i18n } = useTranslation('suppliers')

    const dispatch = useDispatch()
    const suppliers = useSelector(state => state.get('suppliers'))
    const supplierOrders = useSelector(state => state.get('supplierOrders'))

    const parseParams = (params = '') => {
        if(!params) return {}
        const rawParams = params.replace('?', '').split('&')
        const extractedParams = {}
        let supplierIds = []
        let accountingGroupIds = []
        let exCludeAccountingGroupIds = []
        rawParams.forEach((item) => {
            item = item.split('=')
            const filters = ['supplier', 'accounting_group', 'exclude_accounting_group']
            if(filters.includes(item[0])) {
                if (item[0] === 'supplier') {
                    supplierIds.push(item[1])
                    extractedParams[item[0]] = supplierIds
                } else if (item[0] === 'accounting_group') {
                    accountingGroupIds.push(item[1])
                    extractedParams[item[0]] = accountingGroupIds
                } else if (item[0] === 'exclude_accounting_group') {
                    exCludeAccountingGroupIds.push(item[1])
                    extractedParams[item[0]] = exCludeAccountingGroupIds
                }
            } else {
                extractedParams[item[0]] = item[1]
            }
        })
        return extractedParams
    }

    const setFilterObject = (params) => {
		return _.mapKeys(params, function(value, key) {
			return _.camelCase(key)
		})
    }

    const [page, setCurrentPage] = useState(1)
    const [selectedRows, setSelectedRows] = useState([])
    const [filters, setFilters] = useState(
		location.search ? parseParams(location.search)
			: DEFAULT_PARAM_FILTERS
    )

    const[fetchSupplierOrders, { data, loading, error }] = useLazyQuery(SUPPLIER_ORDERS_QUERY,{
		fetchPolicy: 'cache-and-network'
	})

    const setLocationUrl = (queryString) => {
        let arrQueryString = queryString.split('&')
        let objQuery = { supplier: [], accounting_group: [], exclude_accounting_group: [] }
        const keyHasArrayValue = ['supplier', 'accounting_group', 'exclude_accounting_group']
        let queryShow = []
        const acceptQuery = [
            'query',
            'order_state',
            'state',
            'is_credit',
            'is_available',
            'is_verified',
            'by_slip',
            'date_type',
            'start_date',
            'end_date',
            'order_by_supplier_name'
        ]

        arrQueryString.forEach((str) => {
            const arrString = str.split('=')
            arrString[0] = arrString[0].replace('[]', '')
            if (acceptQuery.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, { [`${arrString[0]}`]: arrString[1] })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            } else if (keyHasArrayValue.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, {
                    [arrString[0]]: objQuery[arrString[0]].concat(arrString[1])
                })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            }
        })

        const queries = queryShow.length ? '?' + queryShow.join('&') : ''

        history.push({
            search: queries,
            params: objQuery
        })
    }

    useEffect(() => {
		if (!location.search) {
			history.push({
					search: '?order_state=payment&state=chosen_by_osh',
					params: DEFAULT_PARAM_FILTERS
				})

			fetchSupplierOrders({
				variables: {
					supplierOrderFilters: {
					...setFilterObject(DEFAULT_PARAM_FILTERS),
					withoutTransferBack: true
					},
					perPage: PAGE_SIZE,
					page
				}
			})
		} else {
			history.push({
			search: location.search,
			params: parseParams(location.search)
			})

			fetchSupplierOrders({
				variables: {
					supplierOrderFilters: {
					...setFilterObject(filters),
					withoutTransferBack: true
					},
					perPage: PAGE_SIZE,
					page
				}
			})
		}
		dispatch(fetchSuppliers())
        dispatch(fetchSupplierAccountingGroups())
        dispatch(fetchSupplierStates())
    }, [page, filters])

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current)
    }

    const supplierOrdersData = _.get(data, 'supplierOrders.supplierOrders', [])

    const toggleSupplierOrderForm = ({ id = '', orderId = '', supplierOrderType = '' } = {}) => {
        const isOpenCreatePurchase = suppliers.get('isOpenCreatePurchase')
        dispatch(toggleCreatePurchase(!isOpenCreatePurchase))
        dispatch(setSupplierOrderSelected({ id, orderId, supplierOrderType }))
    }

	const fetchDataOnSaveSuccess = () => {
		fetchSupplierOrders({
			variables: {
				supplierOrderFilters: {
					...setFilterObject(filters),
					withoutTransferBack: true
				},
				perPage: PAGE_SIZE,
				page
			}
		})
	}

    const renderSupplierOrderForm = () => {
		const supplierOrder = supplierOrders.get('supplierOrderFormSelected')
		const type = supplierOrder.supplierOrderType

		if (type === 'product') {
			return (
				<SuppliersOrdersFormContainer
					orderId={supplierOrder.orderId}
					supplierOrderId={supplierOrder.id}
					isSupplierOrder
					isNewPage={false}
					formType="product"
					onSaveSuccess={() => {
						dispatch(toggleCreatePurchase(false))
						fetchDataOnSaveSuccess()
					}} />
			)
		} else if (type === 'transfer_back' || type === 'miscellaneous_transfer') {
			return (
				<SuppliersOrdersFormContainer
					orderId={supplierOrder.orderId}
					supplierOrderId={supplierOrder.id}
					isTransfer
					isNewPage={false}
					formType={type}
					onSaveSuccess={() => {
						dispatch(toggleCreatePurchase(false))
						fetchDataOnSaveSuccess()
					}} />
			)
		}
    }

    return (
		<Tabs defaultActiveKey="orders">
			<Tabs.TabPane tab={i18n.t('suppliers:supplierOrder.productList')} key="orders">
				<SupplierOrderWrapper>
					<SupplierOrderFilters
						supplierOrdersData={supplierOrdersData}
						setLocationUrl={setLocationUrl}
						filters={filters}
						selectedRows={selectedRows}
						setFilters={setFilters} />
					<Divider />
					<SupplierOrderTable supplierOrders={supplierOrdersData}
						loading={loading}
						pageSize={PAGE_SIZE}
						page={page}
						toggleSupplierOrderForm={toggleSupplierOrderForm}
						setSelectedRows={setSelectedRows}
						handleTableChange={handleTableChange}
						totalPages={ _.get(data, 'supplierOrders.totalPages', 1)}
					/>
					<Modal
						visible={suppliers.get('isOpenCreatePurchase')}
						onCancel={toggleSupplierOrderForm}
						footer={false}
						destroyOnClose>
						{renderSupplierOrderForm()}
					</Modal>
					<Modal
						style={{
							height: '100px',
							textAlign: 'center',
							verticalAlign: 'middle'
						}}
						centered
						width="100px"
						visible={supplierOrders.get('supplierOrdersPdfLoading')}
						closable={false}
						footer={null}>
						<Spin />
					</Modal>
				</SupplierOrderWrapper>
			</Tabs.TabPane>
			<Tabs.TabPane tab={i18n.t('suppliers:supplierOrder.truckList')} key="truckList">
				<Divider />
				<h2>{i18n.t('suppliers:supplierOrder.shippingCost')}</h2>
				<ImportSupplierShippingCost />
				<ExportSupplierShippingCost />
				<ExportDriverInfo />
				<Divider />
			</Tabs.TabPane>
			<Tabs.TabPane tab={i18n.t('suppliers:supplierOrder.transferBackList')} key="transferBackList">
				<TransferBackListContainer
					exporting={supplierOrders.get('exportExcelPaymentLoading')}
					toggleSupplierOrderForm={toggleSupplierOrderForm}
				/>
			</Tabs.TabPane>
		</Tabs>
    )
}

NewSupplierOrderIndexContainer.propTypes = {}

NewSupplierOrderIndexContainer.defaultProps = {}

export default withRouter(NewSupplierOrderIndexContainer)
