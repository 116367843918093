import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Card, Form, Checkbox, Button, Select, Modal } from 'antd'
import MailingAddressFormContainer from '../../containers/Customers/Addresses/MailingAddressFormContainer'

const handleChangeMailingAddress = (e, props, form) => {
    const { onChangeMailingAddress, showMailingAddressForm } = props
    e.preventDefault()

    form.validateFields().then(values => {
        const checkMailing = e.target.checked
        const params = {
            ...values,
            using_billing_address: true,
            using_mailing_address: !checkMailing
        }
        onChangeMailingAddress({ values: params, visible: showMailingAddressForm, checked: checkMailing })
    })
}

const handleChangeSelectOrderMailingAddress = (value, props) => {
    const { form, onSubmit } = props

    form.validateFields((err, values) => {
        if (!err) {
            const params = {
                ...values,
                using_billing_address: true,
                using_mailing_address: !form.getFieldValue('using_mailing_address'),
                order_mailing_address_attributes: {
                    mailing_address_id: value
                }
            }
            onSubmit(params)
        }
    })
}

const renderAddMailingAddressForm = props => {
    const { customerId, onShowAddMailingAddressForm, showAddMailingAddressForm, setCloseMailingModal } = props

    return (
        <Modal
            destroyOnClose
            footer={null}
            onCancel={() => onShowAddMailingAddressForm(showAddMailingAddressForm)}
            visible={showAddMailingAddressForm}
            width="60%">
            <MailingAddressFormContainer
                onSuccess={setCloseMailingModal}
                customerId={customerId} />
        </Modal>
    )
}

const renderSelectOrderMailingAddress = props => {
    const {
        form,
        i18n,
        mailingAddressOptions,
        onShowAddMailingAddressForm,
        optionsLoading,
        orderMailingAddress,
        saving,
        showAddMailingAddressForm
    } = props

    return (
        <Fragment>
            <div className="form-item-has-add-btn">
                <label>{i18n.t('confirms:invoice.mailingAddress')}</label>
                <Button type="primary" ghost
                    onClick={() => onShowAddMailingAddressForm(showAddMailingAddressForm)}>
                    {i18n.t('confirms:invoice.addMailingAddress')}
                </Button>
            </div>
            <Form.Item className="m-0" name={['order_mailing_address_attributes', 'mailing_address_id']}
                initialValue={_.get(orderMailingAddress, 'mailing_address.id', undefined)}>
                    <Select loading={optionsLoading || saving}
                        onChange={(value) => handleChangeSelectOrderMailingAddress(value, props)}
                        placeholder={i18n.t('confirms:invoice.selectAddress')}>
                        {mailingAddressOptions}
                    </Select>
            </Form.Item>
        </Fragment>
    )
}

const InvoiceAddressForm = props => {
    const { i18n, orderMailingAddress, showMailingAddressForm } = props
    const usingMailing = _.isEmpty(orderMailingAddress)

    const [form] = Form.useForm()

    return (
        <Card>
            <h3>{i18n.t('confirms:invoice.mailingAddress')}</h3>
            <Form form={form}>
                <Form.Item className="m-0" name='using_mailing_address'
                    valuePropName='checked'
                    initialValue={usingMailing}>
                        <Checkbox onChange={(e) => handleChangeMailingAddress(e, props, form)}>
                            {i18n.t('confirms:invoice.useTaxInvoiceAddress')}
                        </Checkbox>
                </Form.Item>

                {showMailingAddressForm ? renderSelectOrderMailingAddress(props) : null}
            </Form>

            {renderAddMailingAddressForm(props)}
        </Card>
    )
}

InvoiceAddressForm.propTypes = {
    customerId: PropTypes.string,
    mailingAddressOptions: PropTypes.node,
    onChangeMailingAddress: PropTypes.func,
    onSubmit: PropTypes.func,
    optionsLoading: PropTypes.bool,
    orderMailingAddress: PropTypes.object,
    saving: PropTypes.bool,
    showAddMailingAddressForm: PropTypes.bool,
    showMailingAddressForm: PropTypes.bool
}

export default withTranslation('confirms')(InvoiceAddressForm)
