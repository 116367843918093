import React, { useState } from 'react'
import PropTypes from 'prop-types'

import OrderSummariesDashboard from '../../components/orderSummaries/orderSummariesDashboard'

const OrderSummariesDashboardContainer = () => {
    const [showStatistics, setShowStatistics] = useState(false)

    const toggleStatistics = () => {
        setShowStatistics(!showStatistics)
    }

    return (
        <OrderSummariesDashboard
            showStatistics={showStatistics}
            toggleStatistics={toggleStatistics}
        />
    )
}

OrderSummariesDashboardContainer.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
}

OrderSummariesDashboardContainer.defaultProps = {}

export default OrderSummariesDashboardContainer
