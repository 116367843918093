import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Avatar, Button, Modal, Popconfirm, Table } from 'antd'
import { withTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import ProfitSharingFormContainer from '../../../containers/Customers/ProfitSharing/ProfitSharingFormContainer'
import ProfitSharingWrapper from './profitSharing.style'

const ProfitSharingIndex = ({
    i18n,
    activeProfitSharing,
    customerId,
    loading,
    onDeleteProfitSharing,
    onPageChange,
    pagination,
    profitSharings,
    showProfitSharingForm,
    toggleProfitSharingForm
}) => {
    const renderImages = (images) => {
        return images.map((image) => {
            return (
                <a key={`image_${image.id}`} className="image-thumbnail" href={image.url} target="_blank">
                    <Avatar shape="square" size="large" src={image.thumb_url} />
                </a>
            )
        })
    }

    const columns = [
        {
            key: 'idCard',
            title: i18n.t('profitSharing:idCard'),
            dataIndex: 'id_card_images',
            render: (images) => (
                <div className="images-container">
                    {renderImages(images)}
                </div>
            )
        },
        {
            key: 'bookBank',
            title: i18n.t('profitSharing:bookBank'),
            dataIndex: 'book_bank_images',
            render: (images, record) => (
                <div className="images-container">
                    {renderImages(images)}
                </div>
            )
        },
        {
            key: 'detail',
            title: i18n.t('profitSharing:detail'),
            dataIndex: 'detail'
        },
        {
            key: 'actions',
            render: (record) => (
                <>
                    <a onClick={() => toggleProfitSharingForm({ id: record.id })}>
                        <EditOutlined />
                    </a>
                    <Popconfirm title={i18n.t('profitSharing:deleteConfirm')}
                        onConfirm={() => onDeleteProfitSharing(record.id)}>
                        <a className="icon" style={{ marginLeft: '10px' }}>
                            <DeleteOutlined />
                        </a>
                    </Popconfirm>
                </>
            )
        }
    ]

    return (
        <ProfitSharingWrapper>
            <div className="profit-sharing-header">
                <Button type="primary" onClick={toggleProfitSharingForm}>
                    {i18n.t('profitSharing:addProfitSharing')}
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={profitSharings}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />
            <Modal
                visible={showProfitSharingForm}
                onCancel={toggleProfitSharingForm}
                footer={null}
                destroyOnClose>
                <ProfitSharingFormContainer
                    customerId={customerId}
                    id={activeProfitSharing}
                    profitSharing={_.find(profitSharings, ['id', activeProfitSharing])}
                    toggleForm={toggleProfitSharingForm}
                />
            </Modal>
        </ProfitSharingWrapper>
    )
}

ProfitSharingIndex.propTypes = {
    activeProfitSharing: PropTypes.string,
    customerId: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onDeleteProfitSharing: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    profitSharings: PropTypes.array,
    showProfitSharingForm: PropTypes.bool,
    toggleProfitSharingForm: PropTypes.func
}

ProfitSharingIndex.defaultProps = {
    loading: false,
    onDeleteProfitSharing: () => {},
    onPageChange: () => {},
    pagination: false,
    showProfitSharingForm: false,
    toggleProfitSharingForm: () => {}
}

export default withTranslation(['profitSharing'])(ProfitSharingIndex)
