import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { List } from 'immutable'
import _ from 'lodash'

import SuppliersOrdersFormContainer from '../Suppliers/Orders/SuppliersOrdersFormContainer'
import Image from '../Image'
import ImagePreviewContainer from '../ImagePreview/ImagePreviewContainer'
import TransferWrapper from './transfer.style'

import inquiryActions from '../../redux/inquiry/actions'
import orderActions from '../../redux/orders/actions'
import { Button, Card, Modal, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { setShowTransferForm } = inquiryActions
const { fetchOrder } = orderActions

class TransferForm extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired
    }
    static defaultProps = {}

    state = {
        formType: null
    }

    getColumns () {
        const { i18n } = this.props

        return [
            {
                title: i18n.t('suppliers:supplierOrder.refCode'),
                width: 160,
                fixed: 'left',
                dataIndex: 'ref_code',
                render: (refCode, record) => (
                    <a onClick={() => this.toggleTransferForm({ id: record.id, formType: record.supplier_order_type })}>
                        {refCode}
                    </a>
                )
            },
            {
                title: i18n.t('suppliers:transferForm.amount'),
                width: 120,
                dataIndex: ['grand_total', 'display'],
                render: (grandTotal, record) => {
                    if (record.state === 'cancel') {
                        return <del>{grandTotal}</del>
                    } else {
                        return <div>{grandTotal}</div>
                    }
                }
            },
            {
                title: i18n.t('suppliers:transferForm.supplierOrderType'),
                width: 120,
                dataIndex: 'supplier_order_type',
                render: (type) => i18n.t(`suppliers:${_.camelCase(type)}`)
            },
            {
                width: 120,
                dataIndex: 'supplier_order_images',
                render: (images) => {
                    return (
                        <div className="image-container">
                            {this.renderImages(images)}
                        </div>
                    )
                }
            }
        ]
    }

    getTransferList () {
        const { orderEntities, orderId, supplierOrderEntities } = this.props
        const supplierOrderIds = orderEntities.getIn([orderId, 'supplier_orders']) || new List()
        return supplierOrderIds.map((id) => { return supplierOrderEntities.get(id) }).filter((supplierOrder) => {
            return ["transfer_back", "miscellaneous_transfer"].includes(supplierOrder.get('supplier_order_type'))
        })
    }

  toggleTransferForm = ({ id = null, formType = null } = {}) => {
      const { inquiry, setShowTransferForm } = this.props
      const visible = inquiry.get('showTransferForm')
      this.supplierOrderId = id
      this.setState({ formType })
      setShowTransferForm(!visible)
  }

  renderImages = (images) => {
      return images.map((image) => {
          const imageId = image.id
          const thumbUrl = image.file && image.file.s || ''
          const originalUrl = image.file && image.file.original || ''
          const contentType = image.file && image.file.content_type || ''

          return (
              <Image
                  key={imageId}
                  contentType={contentType}
                  originalUrl={originalUrl}
                  thumbUrl={thumbUrl} />
          )
      })
  }

  renderTransferForm = () => {
      const { orderId, fetchOrder } = this.props
      const { formType } = this.state

      return (
          <SuppliersOrdersFormContainer
              isNewPage={!this.supplierOrderId}
              isTransfer
              formType={formType}
              orderId={orderId}
              supplierOrderId={this.supplierOrderId}
              onSaveSuccess={() => {
                  this.toggleTransferForm()
                  fetchOrder(orderId)
              }}
          />
      )
  }

  render () {
      const { i18n, inquiry } = this.props

      return (
          <TransferWrapper>
              <Card>
                  <div className="header">
                      <div className="title">{i18n.t('suppliers:transfer')}</div>
                      <div className="buttons">
                          <Button className="customer-button" type="primary" size="small" onClick={() => {this.toggleTransferForm({ formType: 'transfer_back' })}}>
                              <PlusOutlined />
                              {i18n.t('inquiries:addCustomer')}
                          </Button>
                          <Button type="primary" size="small" onClick={() => {this.toggleTransferForm({ formType: 'miscellaneous_transfer' })}}>
                              <PlusOutlined />
                              {i18n.t('inquiries:addMiscellaneous')}
                          </Button>
                      </div>
                  </div>
                  <Table
                      columns={this.getColumns()}
                      dataSource={this.getTransferList().toJS()}
                      pagination={false}
                      rowKey={record => record.id}
                      scroll={{ x: 400 }}
                      size="small" />
                  <Modal
                      centered
                      destroyOnClose
                      footer={null}
                      onCancel={this.toggleTransferForm}
                      visible={inquiry.get('showTransferForm')}>
                      {this.renderTransferForm()}
                  </Modal>
              </Card>
          </TransferWrapper>
      )
  }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrder,
        setShowTransferForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['suppliers', 'inquiries'])(ImagePreviewContainer(TransferForm))
)
