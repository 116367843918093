import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import {
  Button,
  Card,
  Form,
  InputNumber,
  Radio,
  Row,
  Select,
  Modal
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import PaymentWrapper from './payment.style'

import OrderPaymentDetailInstallmentsList from './PaymentDetail/InstallmentsList'
import OrderPaymentDetailInstallmentForm from './PaymentDetail/InstallmentForm'

import inquiryActions from '../../redux/inquiry/actions'
import paymentDetailInstallmentActions from '../../redux/orderPaymentDetails/Installments/actions'
import InstallmentForm from './InstallmentForm'

const {
  setShowPaymentForm,
  fetchBankAccounts,
  setShowCreditCardInstallmentForm
} = inquiryActions
const { setInstallmentIdSelected } = paymentDetailInstallmentActions

class PaymentForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedBank: props.orderEntity.getIn(['credit_card_bank', 'id']) || null
    }

    this.formRef = React.createRef()
  }

  async componentDidMount() {
    const { fetchBankAccounts } = this.props
    await fetchBankAccounts()
  }

  handleModeChange = (e) => {
    const mode = e.target.value
    this.setState({
      paymentMethod: mode
    })
  }

  handleEditPayment = (value) => {
    const { setShowPaymentForm } = this.props
    this.setState({
      paymentMethod: this.props.payment
    })
    setShowPaymentForm(!value)
  }

  handleChangeBank = (value) => {
    this.formRef.current.setFieldsValue({ installment_period: undefined })
    this.setState({ selectedBank: value })
  }

  handleCreditCardInstallment = ({ id = null } = {}) => {
    const {
      setShowCreditCardInstallmentForm,
      setInstallmentIdSelected,
      inquiry
    } = this.props
    const showForm = inquiry.get('showCreditCardInstallmentForm')

    setShowCreditCardInstallmentForm(!showForm)
    setInstallmentIdSelected(id)
  }

  renderEditIcon = (onClick, editable, value, orderEntity) => {
    if (!editable) {
      return
    }

    return <EditTwoTone className='edit-icon' onClick={() => onClick(value)} />
  }

  bankAccountsList = () => {
    const { bankAccountEntities, inquiry } = this.props

    return inquiry.get('bankAccountItems').map((bankAccountId) => {
      const bankAccount = bankAccountEntities.get(bankAccountId)
      return (
        <Select.Option
          key={bankAccount.get('id')}
          value={bankAccount.get('id')}>
          {bankAccount.get('full_name')}
        </Select.Option>
      )
    })
  }

  renderCreditCardInstallmentList = () => {
    const { id } = this.props
    return (
      <OrderPaymentDetailInstallmentsList
        orderId={id}
        toggleForm={this.handleCreditCardInstallment}
      />
    )
  }

  renderPayment = (orderEntity) => {
    const { i18n, inquiry, editable, orderPaymentDetailInstallments, id } =
      this.props
    const showForm = inquiry.get('showPaymentForm')
    const installmentIdSelected = orderPaymentDetailInstallments.get(
      'installmentIdSelected'
    )
    const cardBodyStyle = { padding: 8, marginTop: 12 }
    const cardStyle = { marginTop: 12 }

    switch (orderEntity.get('payment_method')) {
      case 'cash':
        return (
          <Card type='inner' bodyStyle={cardBodyStyle} style={cardStyle}>
            <span className='title'>
              {`${i18n.t('order.paymentMethods')}`} : &nbsp;
              {orderEntity.get('bank_account') ? '' : '-'}
              {this.renderEditIcon(
                this.handleEditPayment,
                editable,
                showForm,
                orderEntity
              )}
            </span>
            <p>
              {orderEntity.get('bank_account') &&
                orderEntity.getIn(['bank_account', 'full_name'])}
            </p>
            {showForm ? this.renderForm(showForm, orderEntity) : null}
          </Card>
        )
      case 'installment':
        return (
          <Card type='inner' bodyStyle={cardBodyStyle} style={cardStyle}>
            <span className='title'>
              {i18n.t('order.paymentMethods')} :
              {this.renderEditIcon(
                this.handleEditPayment,
                editable,
                showForm,
                orderEntity
              )}
            </span>
            <div>
              [{orderEntity.getIn(['credit_card_bank', 'bank_short_name'])}]{' '}
              {orderEntity.getIn(['credit_card_bank', 'bank_name'])}
            </div>
            <div>
              {i18n.t('order.installmentPeriod')}{' '}
              {orderEntity.get('installment_period')} {i18n.t('order.months')}
            </div>
            <div>
              {i18n.t('order.installmentAmount')}{' '}
              {orderEntity.getIn(['money_display', 'installment_amount']) !== '0.00' ?
              <>
                {orderEntity.getIn(['money_display', 'installment_amount'])}{' '}
                {i18n.t('baht')}
              </> : 'ไม่พบข้อมูล'}
            </div>
            <div>
              {i18n.t('order.creditCardRate')}{' '}
              {orderEntity.get('credit_card_rate')} %
            </div>
            {showForm ? this.renderForm(showForm, orderEntity) : null}
          </Card>
        )
      case 'cheque':
        return (
          <Card type='inner' bodyStyle={cardBodyStyle} style={cardStyle}>
            <span className='title'>
              {i18n.t('order.paymentMethods')} : {i18n.t('order.cheque')}
              {this.renderEditIcon(
                this.handleEditPayment,
                editable,
                showForm,
                orderEntity
              )}
            </span>
            {showForm ? this.renderForm(showForm, orderEntity) : null}
          </Card>
        )
      case 'osh_credit':
        return (
          <Card type='inner' bodyStyle={cardBodyStyle} style={cardStyle}>
            <span className='title'>
              {i18n.t('order.paymentMethods')} : ลูกค้า Credit
              {this.renderEditIcon(
                this.handleEditPayment,
                editable,
                showForm,
                orderEntity
              )}
            </span>
            {showForm ? this.renderForm(showForm, orderEntity) : null}
          </Card>
        )
      default:
        return (
          <Card type='inner' bodyStyle={cardBodyStyle} style={cardStyle}>
            <span className='title'>
              {i18n.t('order.paymentMethods')} :
              {this.renderEditIcon(
                this.handleEditPayment,
                editable,
                showForm,
                orderEntity
              )}
            </span>
            <p>
              {i18n.t('order.creditCardRate')}{' '}
              {orderEntity.get('credit_card_rate')} %
              <Button
                style={{ float: 'right' }}
                type='primary'
                size='small'
                onClick={this.handleCreditCardInstallment}>
                {i18n.t('inquiries:add')}
              </Button>
            </p>
            {this.renderCreditCardInstallmentList()}
            <Modal
              title={i18n.t('inquiries:paymentDetailInstallment.title')}
              footer={null}
              onCancel={this.handleCreditCardInstallment}
              visible={inquiry.get('showCreditCardInstallmentForm')}
              destroyOnClose>
              <OrderPaymentDetailInstallmentForm
                orderId={id}
                toggleForm={this.handleCreditCardInstallment}
                id={
                  installmentIdSelected
                    ? installmentIdSelected.toString()
                    : null
                }
              />
            </Modal>
            {showForm ? this.renderForm(showForm, orderEntity) : null}
          </Card>
        )
    }
  }

  radioTabs = (payment) => {
    const { i18n, orderEntity, inquiry } = this.props
    switch (payment) {
      case 'cash':
        return (
          <Form.Item
            name='bank_account_id'
            initialValue={orderEntity.getIn(['bank_account', 'id']) || '1'}>
            <Select
              showSearch
              allowClear
              loading={inquiry.get('bankAccountsLoading')}
              placeholder={i18n.t('order.selectBank')}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }>
              {this.bankAccountsList()}
            </Select>
          </Form.Item>
        )
      case 'installment':
        return (
          <InstallmentForm onChangeBank={this.handleChangeBank}
            selectedBank={this.state.selectedBank}
            orderEntity={orderEntity} />
        )
      case 'cheque':
        return ''
      case 'osh_credit':
        return ''
      default:
        return (
          <div>
            <label className='title'>
              {' '}
              {i18n.t('order.creditCardRate')} 2.6% - 4%
            </label>
            <Form.Item
              name='credit_card_rate'
              initialValue={orderEntity.get('credit_card_rate')}>
              <InputNumber
                min={2.6}
                max={4.0}
                step={0.1}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
        )
    }
  }

  renderForm = (showForm) => {
    const { i18n, onSubmitForm, saving } = this.props

    return (
      <PaymentWrapper>
        <Form
          onFinish={(values) =>
            onSubmitForm(values, {
              onSuccess: () => this.handleEditPayment(showForm)
            })
          }
          ref={this.formRef}>
          <Card className='card-margin' style={{ marginTop: 12 }}>
            <label className='title'>{i18n.t('order.paymentMethods')}</label>
            <Row className='btn-center'>
              <Form.Item
                name='payment_method'
                initialValue={this.state.paymentMethod}>
                <Radio.Group size='small' onChange={this.handleModeChange}>
                  <Radio.Button value='cash'>
                    {' '}
                    {i18n.t('order.cash')}{' '}
                  </Radio.Button>
                  <Radio.Button value='credit_card'>
                    {' '}
                    {i18n.t('order.creditCard')}{' '}
                  </Radio.Button>
                  <Radio.Button value='installment'>
                    {' '}
                    {i18n.t('order.installment')}{' '}
                  </Radio.Button>
                  <Radio.Button value='cheque'>
                    {' '}
                    {i18n.t('order.cheque')}{' '}
                  </Radio.Button>
                  <Radio.Button value='osh_credit'>
                    {' '}
                    {'ลูกค้า Credit'}
                  </Radio.Button>
                  <Radio.Button value='true_wallet'>
                    {' '}
                    {i18n.t('order.trueWallet')}{' '}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Row>
            {this.radioTabs(this.state.paymentMethod)}
            <Row className='btn-center'>
              <Button
                className='btn-edit'
                type='primary'
                size='small'
                loading={saving}
                htmlType='submit'>
                {i18n.t('inquiries:edit')}
              </Button>
              <Button
                className='btn-edit'
                size='small'
                onClick={() => this.handleEditPayment(showForm)}>
                {i18n.t('inquiries:cancel')}
              </Button>
            </Row>
          </Card>
        </Form>
      </PaymentWrapper>
    )
  }

  render() {
    const { inquiry, orderEntity } = this.props
    const showForm = inquiry.get('showPaymentForm')

    return showForm
      ? this.renderForm(showForm)
      : this.renderPayment(orderEntity)
  }
}

const mapStateToProps = (state) => ({
  inquiry: state.get('inquiry'),
  orderPaymentDetailInstallments: state.get('orderPaymentDetailInstallments'),
  bankAccountEntities: state.getIn(['Entities', 'bankAccounts'])
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setShowPaymentForm,
      fetchBankAccounts,
      setShowCreditCardInstallmentForm,
      setInstallmentIdSelected
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PaymentForm))
