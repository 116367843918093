import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Modal, Typography } from 'antd'
import { CalculatorOutlined } from '@ant-design/icons'
import PickupPointForm from './form'

const PickupPoint = ({ orderId, refetch }) => {
    const { i18n } = useTranslation('inquiries')
    const [showForm, setShowForm] = useState(false)

    const toggleForm = () => {
        setShowForm(!showForm)
    }

    return (
        <div>
            <Typography.Link onClick={toggleForm}>
                <CalculatorOutlined /> {i18n.t('inquiries:shippingCost.pickupAndSendingPoint')}
            </Typography.Link>
            <Modal
                visible={showForm}
                onCancel={toggleForm}
                destroyOnClose
                footer={null}
            >
                <PickupPointForm
                    orderId={orderId}
                    onClose={toggleForm}
                    refetch={refetch}
                />
            </Modal>
        </div>
    )
}

PickupPoint.propTypes = {
    orderId: PropTypes.string.isRequired,
    refetch: PropTypes.func
}

PickupPoint.defaultProps = {
    refetch: () => {}
}

export default PickupPoint
