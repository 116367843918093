import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Button, Card, Col, Form, Input, Row } from 'antd'
import { EditTwoTone } from '@ant-design/icons'

import inquiryActions from '../../redux/inquiry/actions'

const { setShowDiscountForm } = inquiryActions

class DiscountForm extends Component {
    renderForm = () => {
        const { i18n, inquiry, orderDiscount, onSubmitForm } = this.props

        return (
            <tr>
                <td colSpan="2">
                    <Form onFinish={(values) => onSubmitForm(values, { onSuccess: () => this.toggleForm() })}
                        layout='vertical'>
                        <Card style={{ width: '80%' }} className="card-margin">
                            <Row type="flex" gutter={24}>
                                <Col span={8}>
                                    <span style={{ lineHeight: '39px' }}>{`${i18n.t('order.discount')}:`}</span>
                                </Col>
                                <Col span={16}>
                                    <Form.Item name='discount' initialValue={orderDiscount}>
                                            <Input size="small"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="btn-center">
                                <Button
                                    className="btn-edit"
                                    type="primary"
                                    size="small"
                                    loading={inquiry.get('saveOrderLoading')}
                                    htmlType="submit">
                                    {i18n.t('inquiries:edit')}
                                </Button>
                                <Button
                                    className="btn-edit"
                                    size="small"
                                    onClick={this.toggleForm}>
                                    {i18n.t('inquiries:cancel')}
                                </Button>
                            </Row>
                        </Card>
                    </Form>
                </td>
            </tr>
        )
    }

    renderDiscount = () => {
        const { displayDiscount, i18n, editable } = this.props

        return (
            <tr>
                <td>{ `${i18n.t('order.discount')}:` }</td>
                <td>{ `${displayDiscount} ${i18n.t('baht')}` }
                    <span>
                        {editable && (
                            <EditTwoTone onClick={() => this.toggleForm()}/>
                        )}
                    </span>
                </td>
            </tr>
        )
    }

    toggleForm = () => {
        const { setShowDiscountForm, inquiry } = this.props

        setShowDiscountForm(!inquiry.get('showDiscountForm'))
    }

    render () {
        const { inquiry } = this.props
        const showForm = inquiry.get('showDiscountForm')

        return showForm ? this.renderForm() : this.renderDiscount()
    }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setShowDiscountForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountForm))
