import { PAGE_SIZE } from '../../constants/orders'

const actions = {
    JOURNAL_ENTRY_DATES_FETCH_REQUEST: 'JOURNAL_ENTRY_DATES_FETCH_REQUEST',
    JOURNAL_ENTRY_DATES_FETCH: 'JOURNAL_ENTRY_DATES_FETCH',
    JOURNAL_ENTRY_DATES_FETCH_SUCCESS: 'JOURNAL_ENTRY_DATES_FETCH_SUCCESS',

    JOURNAL_ENTRIES_FETCH_REQUEST: 'JOURNAL_ENTRIES_FETCH_REQUEST',
    JOURNAL_ENTRIES_FETCH: 'JOURNAL_ENTRIES_FETCH',
    JOURNAL_ENTRIES_FETCH_SUCCESS: 'JOURNAL_ENTRIES_FETCH_SUCCESS',

    JOURNAL_ENTRY_DATES_SET_PAGINATION: 'JOURNAL_ENTRY_DATES_SET_PAGINATION',
    JOURNAL_ENTRIES_SET_PAGINATION: 'JOURNAL_ENTRIES_SET_PAGINATION',
    JOURNAL_ENTRIES_SET_FILTERS: 'JOURNAL_ENTRIES_SET_FILTERS',

    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_REQUEST: 'FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_REQUEST',
    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE: 'FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE',
    FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS: 'FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_SUCCESS',

    fetchJournalEntryDates: ({ page = 1, per = PAGE_SIZE, filters = {}, isCalculate = false } = {}) => ({
        type: actions.JOURNAL_ENTRY_DATES_FETCH_REQUEST,
        payload: { page, per, filters, isCalculate }
    }),

    fetchJournalEntries: ({ page = 1, per = PAGE_SIZE, date = '', amountType = '' } = {}) => ({
        type: actions.JOURNAL_ENTRIES_FETCH_REQUEST,
        payload: { page, per, date, amountType }
    }),

    setFilters: (filters) => ({
        type: actions.JOURNAL_ENTRIES_SET_FILTERS,
        filters
    }),

    exportJounalEntryStatements: (params) => ({
        type: actions.FETCH_JOURNAL_ENTRIES_STATEMENTS_FILE_REQUEST,
        payload: { params }
    }),
}

export default actions
