import { Map, List } from 'immutable'
import actions from './actions'

const {
    IMAGES_ADD_NEW_IMAGE,
    IMAGES_SET_PREVIEW_IMAGE_THUMB_URL,
    IMAGES_SET_SHOW_PREVIEW_IMAGE,
    IMAGES_SET_NOTICE_TEXT
} = actions

const initialState = new Map({
    items: new List(),
    previewImageThumbUrl: '',
    showPreviewImage: false,
    noticeText: []
})

export const initialImageEntityState = new Map({
    contentType: '',
    originalBlob: '',
    originalLoading: false,
    originalUrl: '',
    thumbBlob: '',
    thumbLoading: false,
    thumbUrl: ''
})

export default function imagesReducer (state = initialState, action) {
    const items = state.get('items')

    switch (action.type) {
        case IMAGES_ADD_NEW_IMAGE:
            return state.merge({
                items: items.includes(action.thumbUrl) ? items : items.push(action.thumbUrl)
            })

        case IMAGES_SET_PREVIEW_IMAGE_THUMB_URL:
            return state.merge({
                previewImageThumbUrl: action.previewImageThumbUrl
            })

        case IMAGES_SET_SHOW_PREVIEW_IMAGE:
            return state.merge({ showPreviewImage: action.showPreviewImage })

        case IMAGES_SET_NOTICE_TEXT:
            return state.merge({ noticeText: action.text })

        default:
            return state
    }
}
