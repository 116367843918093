import React from 'react'
import PropTypes from 'prop-types'
import { Select, Spin } from 'antd'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

const SearchSale = ({
    i18n,
    loading,
    onChange,
    onSearch,
    sales,
    value
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={(query) => onSearch(query)}
            placeholder={i18n.t('orders:searchSale')}
            showArrow={false}
            showSearch
            style={{ width: '100%' }}
            value={value || undefined}
        >
            {sales.map((sale, index) => {
                const id = _.get(sale, 'id', `index-${index}`)
                const name = _.compact([
                    _.get(sale, 'first_name', ''),
                    _.get(sale, 'last_name', '')
                ]).join(' ')

                return (
                    <Select.Option
                        key={id}
                        value={_.toString(id)}
                    >
                        {name}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SearchSale.propTypes = {
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    sales: PropTypes.array,
    value: PropTypes.string
}

SearchSale.defaultProps = {
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    sales: [],
    value: ''
}

export default withTranslation(['orders'])(SearchSale)
