import styled from 'styled-components'

const JournalEntryFormatsIndexWrapper = styled.div`
  .journal-entry-formats-index-header {
    margin-bottom: 1rem;
    justify-content: space-between;
    display: flex;
  }
  
  .images-container {
    display: flex;
    flex-wrap: wrap;
  }

  .image-thumbnail {
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin: 2px;
    padding: 2px;
  }
`

export default JournalEntryFormatsIndexWrapper
