import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from 'antd'
import Filter from './Filter'
import Lane from './Lane'
import RenderWarper  from './render.style'

const DealsBoardRender = ({ categorySelectorProps, onChangeCategory }) => {
  const [filters, setFilters] = useState({})

  const onChangeFilters = (args) => {
    setFilters({ ...filters, ...args })
  }

  useEffect(() => {
    onChangeCategory(filters.categoryId)
  }, [filters.categoryId])

  return (
    <RenderWarper>
      <Filter
        categorySelectorProps={categorySelectorProps}
        onChangeFilters={onChangeFilters} />
      <Row gutter={[8, 0]} >
        <Col span={8}>
          <Lane state='pending' otherFilters={filters} />
        </Col>
        <Col span={8}>
          <Lane state='client_review' otherFilters={filters} />
        </Col>
        <Col span={8}>
          <Lane state='done' otherFilters={filters} />
        </Col>
      </Row>
    </RenderWarper>
  )
}

DealsBoardRender.propTypes = {
  categorySelectorProps: PropTypes.object,
  onChangeCategory: PropTypes.func
}

DealsBoardRender.defaultProps = {
  onChangeCategory: () => {}
}

export default DealsBoardRender
