import { PAGE_SIZE } from '../../constants/orders'

const actions = {
    SALES_SUMMARY_FETCH_FAIL: 'SALES_SUMMARY_FETCH_FAIL',
    SALES_SUMMARY_FETCH_REQUEST: 'SALES_SUMMARY_FETCH_REQUEST',
    SALES_SUMMARY_FETCH_SUCCESS: 'SALES_SUMMARY_FETCH_SUCCESS',
    SALES_SUMMARY_FETCHING: 'SALES_SUMMARY_FETCHING',

    SALES_SUMMARY_FETCH_PROSPECTS_FAIL: 'SALES_SUMMARY_FETCH_PROSPECTS_FAIL',
    SALES_SUMMARY_FETCH_PROSPECTS: 'SALES_SUMMARY_FETCH_PROSPECTS',
    SALES_SUMMARY_FETCH_PROSPECTS_REQUEST: 'SALES_SUMMARY_FETCH_PROSPECTS_REQUEST',
    SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS: 'SALES_SUMMARY_FETCH_PROSPECTS_SUCCESS',

    SALES_SUMMARY_SET_SELECTED_MONTH: 'SALES_SUMMARY_SET_SELECTED_MONTH',
    SALES_SUMMARY_PROSPECTS_SET_PAGINATION: 'SALES_SUMMARY_PROSPECTS_SET_PAGINATION',

    fetchSalesSummary: ({
        selectedMonth = '',
        saleId = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SALES_SUMMARY_FETCH_REQUEST,
            payload: { selectedMonth, saleId, onSuccess, onError }
        }
    },

    fetchSaleProspects: ({
        selectedMonth = '',
        saleId,
        page = 1,
        per = PAGE_SIZE,
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.SALES_SUMMARY_FETCH_PROSPECTS_REQUEST,
        payload: { selectedMonth, saleId, page, per, onSuccess, onError }
    }),

    setSelectedMonth: (selectedMonth = '') => ({
        type: actions. SALES_SUMMARY_SET_SELECTED_MONTH,
        selectedMonth
    })
}

export default actions
