import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch, apiFileFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import actions from './actions'
import actionsNotification from '../../redux/notifications/actions'
import { withholdingTaxesSchema, withholdingTaxSchema } from '../schema'
import downLoadFile from "../../helpers/downLoadFile"

const {
    WITHHOLDING_TAXES_FETCH_REQUEST,
    WITHHOLDING_TAXES_FETCH,
    WITHHOLDING_TAXES_FETCH_SUCCESS,

    WITHHOLDING_TAX_FETCH_REQUEST,
    WITHHOLDING_TAX_FETCH,
    WITHHOLDING_TAX_FETCH_SUCCESS,

    WITHHOLDING_TAX_SAVE_REQUEST,
    WITHHOLDING_TAX_SAVING,
    WITHHOLDING_TAX_SET_FORM_ERRORS,
    WITHHOLDING_TAX_SAVE_SUCCESS,

    WITHHOLDING_TAX_DELETE_REQUEST,
    WITHHOLDING_TAX_DELETE,
    WITHHOLDING_TAX_DELETE_SUCCESS,

    WITHHOLDING_TAX_DOWNLOAD_PDF_REQUEST,
    WITHHOLDING_TAX_DOWNLOAD_PDF,
    WITHHOLDING_TAX_DOWNLOAD_PDF_SUCCESS,

    WITHHOLDING_TAXES_DOWNLOAD_TXT_REQUEST,
    WITHHOLDING_TAXES_DOWNLOAD_TXT,
    WITHHOLDING_TAXES_DOWNLOAD_TXT_SUCCESS,

    WITHHOLDING_TAXES_SET_PAGINATION
} = actions

const { setNotifications } = actionsNotification

export function *fetchWithholdingTaxes () {
    yield takeEvery(WITHHOLDING_TAXES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAXES_FETCH })

        const { selectedMonth, selectedType, page, per } = payload
        const type = selectedType === 'all' ? '' : selectedType
        const queryString = `month=${selectedMonth}&type=${type}&page=${page}&per=${per}`

        const path = `/withholding_taxes?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, withholdingTaxesSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: WITHHOLDING_TAXES_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: WITHHOLDING_TAXES_FETCH_SUCCESS,
            ...normalizeData, selectedMonth, selectedType
        })
    })
}

export function *fetchWithholdingTax () {
    yield takeEvery(WITHHOLDING_TAX_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAX_FETCH })

        const { id } = payload

        const path = `/withholding_taxes/${id}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, withholdingTaxSchema)

        yield put({
            type: WITHHOLDING_TAX_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveWithholdingTax () {
    yield takeLatest(WITHHOLDING_TAX_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAX_SAVING, saving: true })

        const { id, params, onSuccess, onError } = payload

        const basePath = '/withholding_taxes'
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ withholding_tax: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: WITHHOLDING_TAX_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, withholdingTaxSchema)

                yield put({
                    type: WITHHOLDING_TAX_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: WITHHOLDING_TAX_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: WITHHOLDING_TAX_SAVING, saving: false })
        }
    })
}

export function *deleteWithholdingTax () {
    yield takeLatest(WITHHOLDING_TAX_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAX_DELETE })

        const { id, onSuccess } = payload
        const path = `/withholding_taxes/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, withholdingTaxSchema)

        yield put({
            type: WITHHOLDING_TAX_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export function *downloadWithholdingTaxPDF () {
    yield takeEvery(WITHHOLDING_TAX_DOWNLOAD_PDF_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAX_DOWNLOAD_PDF, loading: true })

        const { id } = payload
        const path = `/withholding_taxes/${id}?format=pdf`

        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({ type: WITHHOLDING_TAX_DOWNLOAD_PDF_SUCCESS })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: WITHHOLDING_TAX_DOWNLOAD_PDF, loading: false })
        }
    })
}

export function *downloadWithholdingTaxesTXT () {
    yield takeEvery(WITHHOLDING_TAXES_DOWNLOAD_TXT_REQUEST, function *({ payload }) {
        yield put({ type: WITHHOLDING_TAXES_DOWNLOAD_TXT, loading: true })

        const { selectedMonth, selectedType } = payload
        const type = selectedType === 'all' ? '' : selectedType
        const fileExtension = 'xlsx'
        const queryString = `format=${fileExtension}&month=${selectedMonth}&type=${type}`

        let filename = 'withholding-taxes'
        if (type !== '') { filename += `-${type === 'true' ? 'pnd53' : 'pnd3'}` }
        if (selectedMonth !== '') { filename += `-monthly-${selectedMonth}` }

        const path = `/withholding_taxes?${queryString}`

        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `${filename}.${fileExtension}`
            })

            yield put({ type: WITHHOLDING_TAXES_DOWNLOAD_TXT_SUCCESS })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: WITHHOLDING_TAXES_DOWNLOAD_TXT, loading: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchWithholdingTaxes),
        fork(fetchWithholdingTax),
        fork(saveWithholdingTax),
        fork(deleteWithholdingTax),
        fork(downloadWithholdingTaxPDF),
        fork(downloadWithholdingTaxesTXT)
    ])
}
