import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Radio, Space, Modal } from 'antd'
import { EditTwoTone } from '@ant-design/icons'

import AddressFormContainer from '../../../containers/Customers/ShippingAddresses/AddressFormContainer'

const ListOptionsRender = ({
  shippingAddresses,
  onChange,
  shippingAddressId,
  customerId,
  setRefetchShippingAddress
}) => {
  const [showShippingAddressForm, setShippingAddressForm] = useState()
  const [editedId, setEditedId] = useState(null)

  const handleShippingAddressForm = () => {
    setShippingAddressForm(!showShippingAddressForm)
  }

  const onSaveSuccess = () => {
    handleShippingAddressForm()
    setRefetchShippingAddress(true)
  }

  const handleEdit = (id) => {
    setRefetchShippingAddress(false)
    setEditedId(id)
    handleShippingAddressForm()
  }

  const onCancel = () => {
    setEditedId(null)
    handleShippingAddressForm()
  }

  const renderOptions = () => {
    return shippingAddresses.map((shippingAddress) => {
      return (
        <Radio value={shippingAddress.id}>
          {`${shippingAddress.contactName} ${shippingAddress.fullAddress} เบอร์ติดต่อ: ${shippingAddress.contactNumber}`}
          <span style={{ marginLeft: '8px'}}><EditTwoTone onClick={() => handleEdit(shippingAddress.id)} /></span>
        </Radio>
      )
    })
  }

  return (
    <div>
      <Radio.Group onChange={(e) => onChange(e.target.value)} defaultValue={shippingAddressId}>
        <Space direction="vertical">
          {renderOptions()}
        </Space>
      </Radio.Group>
      <Modal visible={showShippingAddressForm} footer={false}
        width='65%'
        destroyOnClose
        onCancel={onCancel}>
          <AddressFormContainer customerId={customerId}
            shippingAddressId={editedId}
            onSuccess={onSaveSuccess}
            showSubmitBtn/>
      </Modal>
    </div>
  )
}

ListOptionsRender.propTypes = {
  shippingAddresses: PropTypes.array,
  onChange: PropTypes.func,
  shippingAddressId: PropTypes.string,
  customerId: PropTypes.string,
  setRefetchShippingAddress: PropTypes.func
}

ListOptionsRender.defaultProps = {
  onChange: () => {},
  setRefetchShippingAddress: () => {}
}

export default ListOptionsRender
