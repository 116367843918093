import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'

import JournalEntriesIndex from '../../components/journalEntries/JournalEntriesIndex'

import actions from '../../redux/journalEntries/actions'

const { fetchJournalEntryDates, setFilters } = actions

const JournalEntriesIndexContainer = () => {
    const dispatch = useDispatch()
    const journalEntryDateEntities = useSelector(state => state.getIn(['Entities', 'journalEntryDates']))
    const dateItems = useSelector(state => state.getIn(['journalEntries', 'dateItems'], new List()))
    const pagination = useSelector(state => state.getIn(['journalEntries', 'datePagination'], false))
    const loading = useSelector(state => state.getIn(['journalEntries', 'loading'], false))
    const filters = useSelector(state => state.getIn(['journalEntries', 'filters'], new Map())).toJS()

    useEffect(() => {
        dispatch(fetchJournalEntryDates({ filters }))
    }, [])

    const onChangeFilters = (args) => {
        const newFilters = { ...filters, ...args }

        dispatch(setFilters(newFilters))
        dispatch(fetchJournalEntryDates({ filters: newFilters }))
    }

    const onPageChange = (pagination) => {
        dispatch(fetchJournalEntryDates({ page: pagination.current, filters }))
    }

    const getJournalEntryDates = () => {
        return dateItems.map((date) => journalEntryDateEntities.get(date) || new Map())
    }

    const recalculateJournalEntries = () => {
        dispatch(fetchJournalEntryDates({ page: pagination.get('current'), filters, isCalculate: true }))
    }

    return (
        <JournalEntriesIndex
            filters={filters}
            journalEntryDates={getJournalEntryDates().toJS()}
            loading={loading}
            onChangeFilters={onChangeFilters}
            onPageChange={onPageChange}
            pagination={pagination && pagination.toJS()}
            recalculate={recalculateJournalEntries}
        />
    )
}

JournalEntriesIndexContainer.propTypes = {}

JournalEntriesIndexContainer.defaultProps = {}

export default JournalEntriesIndexContainer
