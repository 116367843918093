import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import _ from 'lodash'

const CurrentStaffContainer = (ChildComponent) => {
    const App = class App extends Component {
        getCurrentStaff () {
            const { staffEntities, staffProfiles } = this.props

            const currentStaffId = staffProfiles.get('currentStaffId')
            if (!currentStaffId) { return new Map() }

            return staffEntities.get(currentStaffId.toString()) || new Map()
        }

        getCurrentStaffLoading () {
            const { staffProfiles } = this.props
            return staffProfiles.get('loading')
        }

        getCurrentStaffRoles () {
            const { staffRoleEntities } = this.props
            const currentStaff = this.getCurrentStaff()
            const currentStaffRoles = currentStaff.get('staff_roles') || new List()

            return currentStaffRoles.map((currentStaffRoleId) => {
                return staffRoleEntities.get(currentStaffRoleId.toString()) || new Map()
            }) || new List()
        }

        getCurrentStaffRoleNames () {
            const currentStaffRoles = this.getCurrentStaffRoles()

            return currentStaffRoles.map((currentStaffRole) => {
                return currentStaffRole.get('name')
            })
        }

        isCurrentStaffA = (role) => {
            const currentStaffRoleNames = this.getCurrentStaffRoleNames()

            return currentStaffRoleNames.includes(role)
        }

        rolesMatched = (allowedRoles) => {
            const currentStaffRoleNames = this.getCurrentStaffRoleNames().toJS()

            return _.intersection(allowedRoles, currentStaffRoleNames).length > 0
        }

        render () {
            return (
                <ChildComponent {...this.props}
                    currentStaff={this.getCurrentStaff()}
                    currentStaffLoading={this.getCurrentStaffLoading()}
                    currentStaffRoles={this.getCurrentStaffRoles()}
                    isCurrentStaffA={this.isCurrentStaffA}
                    rolesMatched={this.rolesMatched} />
            )
        }
    }

    const mapStateToProps = (state) => ({
        staffEntities: state.getIn(['Entities', 'staffs']),
        staffRoleEntities: state.getIn(['Entities', 'staffRoles']),
        staffProfiles: state.get('staffProfiles')
    })

    return connect(mapStateToProps)(App)
}

export default CurrentStaffContainer
