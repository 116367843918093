const actions = {
    ORDER_TABLES_RESET: 'ORDER_TABLES_RESET',
    ORDER_TABLES_SET_FILTER: 'ORDER_TABLES_SET_FILTER',
    ORDER_TABLES_SET_PAGINATION: 'ORDER_TABLES_SET_PAGINATION',
    ORDER_TABLES_SET_PERIOD: 'ORDER_TABLES_SET_PERIOD',
    ORDER_TABLES_SET_QUERY: 'ORDER_TABLES_SET_QUERY',
    ORDER_TABLES_SET_SORTER: 'ORDER_TABLES_SET_SORTER',
    ORDER_TABLES_SET_STATE: 'ORDER_TABLES_SET_STATE',
    ORDER_TABLES_SET_SALE: 'ORDER_TABLES_SET_SALE',
    ORDER_TABLES_SET_CS: 'ORDER_TABLES_SET_CS',
    ORDER_TABLES_SET_LEAD_SOURCE: 'ORDER_TABLES_SET_LEAD_SOURCE',
    ORDER_TABLES_SET_RESERVATION_DATE: 'ORDER_TABLES_SET_RESERVATION_DATE',
    ORDER_TABLES_SALE_FETCH_REQUEST: 'ORDER_TABLES_SALE_FETCH_REQUEST',
    ORDER_TABLES_SALE_FETCHING_REQUEST: 'ORDER_TABLES_SALE_FETCHING_REQUEST',
    ORDER_TABLES_SALE_FETCH_SUCCESS: 'ORDER_TABLES_SALE_FETCH_SUCCESS',
    ORDER_TABLES_CS_FETCH_REQUEST: 'ORDER_TABLES_CS_FETCH_REQUEST',
    ORDER_TABLES_CS_FETCHING_REQUEST: 'ORDER_TABLES_CS_FETCHING_REQUEST',
    ORDER_TABLES_CS_FETCH_SUCCESS: 'ORDER_TABLES_CS_FETCH_SUCCESS',
    ORDER_TABLES_PURCHASES_FETCH_REQUEST: 'ORDER_TABLES_PURCHASES_FETCH_REQUEST',
    ORDER_TABLES_PURCHASES_FETCHING: 'ORDER_TABLES_PURCHASES_FETCHING',
    ORDER_TABLES_PURCHASES_FETCH_SUCCESS: 'ORDER_TABLES_PURCHASES_FETCH_SUCCESS',

    reset: () => {
        return { type: actions.ORDER_TABLES_RESET }
    },

    setFilter: (filter) => {
        return { type: actions.ORDER_TABLES_SET_FILTER, filter }
    },

    setPagination: (pagination) => {
        return { type: actions.ORDER_TABLES_SET_PAGINATION, pagination }
    },

    setPeriod: (period) => {
        return { type: actions.ORDER_TABLES_SET_PERIOD, period }
    },

    setQuery: (query) => {
        return { type: actions.ORDER_TABLES_SET_QUERY, query }
    },

    setSorter: (sorter) => {
        return { type: actions.ORDER_TABLES_SET_SORTER, sorter }
    },

    setState: (state) => {
        return { type: actions.ORDER_TABLES_SET_STATE, state }
    },

    setSale: (filterSale, filterSaleKey) => ({
        type: actions.ORDER_TABLES_SET_SALE,
        filterSale, filterSaleKey
    }),

    setCs: (filterCs, filterCsKey) => ({
        type: actions.ORDER_TABLES_SET_CS,
        filterCs, filterCsKey
    }),

    setLeadSource: (filterLeadSource) => {
        return { type: actions.ORDER_TABLES_SET_LEAD_SOURCE, filterLeadSource }
    },

    setReservationDate: (reservationDate) => {
        return { type: actions.ORDER_TABLES_SET_RESERVATION_DATE, reservationDate }
    },

    fetchSales: (all) => {
        return {
            type: actions.ORDER_TABLES_SALE_FETCH_REQUEST,
            payload: { all }
        }
    },

    fetchCses: (all) => {
        return {
            type: actions.ORDER_TABLES_CS_FETCH_REQUEST,
            payload: { all }
        }
    },

    fetchPurchasers: (all) => {
        return {
            type: actions.ORDER_TABLES_PURCHASES_FETCH_REQUEST,
            payload: { all }
        }
    }
}

export default actions
