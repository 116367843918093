import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Row, Button, Form, Upload, Select, Divider } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import EditorContainer from '../../../containers/Editor/EditorContainer'

import { TYPE_SOURCES, TYPE_CONTACTS } from '../../../constants/customers'
import customerNoteActions from '../../../redux/customers/customerNotes/actions'
import { withTranslation } from 'react-i18next'

const { deleteOrderNoteImageOfCustomerNote, saveOrderNoteImageOfCustomerNote } = customerNoteActions

const { Dragger } = Upload
const { Option } = Select

const FIELD_NAMES = {
    NOTE: 'note',
    ORDER_NOTE_IMAGE_IDS: 'order_note_image_ids',
    ORDER_NUMBER: 'order_number',
    SOURCE_TYPE: 'source_type',
    TYPE_CONTACT: 'type_contact'
}

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 10 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 7 }
    }
}

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 14, offset: 10 }
    }
}

const editorLayout = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 14, offset: 5 }
    }
}

class CustomerNoteForm extends Component {
    constructor (props) {
        super(props)

        this.state = {
            header: {}
        }

        this.formRef = createRef()
    }

    handleFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    imageHandler = (file, imgBase64) => {
        const { saveOrderNoteImageOfCustomerNote, orderId } = this.props

        saveOrderNoteImageOfCustomerNote(orderId, { image: file })

        const fileList = this.formRef.current.getFieldValue(FIELD_NAMES.ORDER_NOTE_IMAGE_IDS) || []
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            status: 'done',
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        this.formRef.current.setFieldsValue({ [FIELD_NAMES.ORDER_NOTE_IMAGE_IDS]: fileList.concat(newItemOfFileList) })
    }

    onUploadImageCustomRequest = (args) => {
        const { saveOrderNoteImageOfCustomerNote, orderId } = this.props
        const { file, onProgress, onSuccess } = args

        saveOrderNoteImageOfCustomerNote(orderId, { image: file }, {
            onUploadProgress: (e) => {
                onProgress({ percent: (e.loaded / e.total) * 100 })
            },
            onSuccess: () => {
                onSuccess('Ok')
            },
        })

        return {
            abort () {}
        }
    }

    onRemoveImage = (file) => {
        const { deleteOrderNoteImageOfCustomerNote, orderNoteImageItems } = this.props
        const fileList = this.formRef.current.getFieldValue(FIELD_NAMES.ORDER_NOTE_IMAGE_IDS)

        const imageDeletedIndex = fileList.findIndex((fileItem) => {
            return fileItem.uid === file.uid
        })

        const orderNoteImageId = orderNoteImageItems.get(imageDeletedIndex)

        deleteOrderNoteImageOfCustomerNote(orderNoteImageId)
    }

    selectConstants = (selectOption) => {
        if (!selectOption) { return null }
        return selectOption.map((option, index) => {
            return (
                <Option key={index} value={option}>
                    {option}
                </Option>
            )
        })
    }

    selectOrderNumber = (orders) => {
        if (!orders) { return null }
        return orders.map((order, index) => {
            return (
                <Option key={index} value={order}>
                    {order}
                </Option>
            )
        })
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }

        return e && e.fileList
    }

    handleSubmitForm = (values) => {
        const { handleSubmitNote, orderNoteImageItems } = this.props

        const params = {}

        Object.entries(values).map((field) => {
            const fieldName = field[0]
            const fieldValue = field[1]

            params[fieldName] = (fieldValue === undefined) ? null : fieldValue
        })

        params[FIELD_NAMES.ORDER_NOTE_IMAGE_IDS] = orderNoteImageItems.toArray()

        handleSubmitNote(params)

        this.formRef.current.resetFields()
    }

    render () {
        const { i18n, orders, orderId, selectOrder, stateSaving } = this.props

        return (
            <div>
                <Form onFinish={this.handleSubmitForm} {...formItemLayout}
                    ref={this.formRef}>
                    <Form.Item {...editorLayout} name={FIELD_NAMES.ORDER_NOTE_IMAGE_IDS}
                        valuePropName='fileList'
                        getValueFromEvent={this.normFile}>
                            <Dragger
                                customRequest={this.onUploadImageCustomRequest}
                                listType="picture"
                                multiple
                                onRemove={this.onRemoveImage}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading
                    company data or other band files</p>
                            </Dragger>
                    </Form.Item>

                    <Form.Item label={i18n.t('customers/notes:form.source')} name={FIELD_NAMES.SOURCE_TYPE}>
                        <Select placeholder={i18n.t('customers/notes:form.sourcePlaceholder')}
                            filterOption={(input, option) => this.handleFilterOption(input, option)}
                        >
                            {this.selectConstants(TYPE_SOURCES)}
                        </Select>
                    </Form.Item>

                    <Form.Item label={i18n.t('customers/notes:form.typeContact')} name={FIELD_NAMES.TYPE_CONTACT}>
                        <Select placeholder={i18n.t('customers/notes:form.typeContactPlaceholder')}
                            filterOption={(input, option) => this.handleFilterOption(input, option)}
                        >
                            {this.selectConstants(TYPE_CONTACTS)}
                        </Select>
                    </Form.Item>

                    <Form.Item label={i18n.t('customers/notes:form.orderNumber')} style={{ display: selectOrder }}
                        name={FIELD_NAMES.ORDER_NUMBER} initialValue={orderId}>
                            <Select
                                placeholder={i18n.t('customers/notes:form.orderNumberPlaceholder')}
                                filterOption={(input, option) => this.handleFilterOption(input, option)}
                            >
                                {this.selectOrderNumber(orders)}
                            </Select>
                    </Form.Item>

                    <Form.Item {...editorLayout} name={FIELD_NAMES.NOTE}>
                        <EditorContainer theme="snow"
                            imageHandler={this.imageHandler} />
                    </Form.Item>

                    <Form.Item {...formItemLayoutWithOutLabel} label="">
                        <Button htmlType="submit" type="primary" loading={stateSaving}>
                            {i18n.t('shared:save')}
                        </Button>
                    </Form.Item>
                </Form>
                <Divider />
            </div>
        )
    }
}

CustomerNoteForm.propTypes = {
    loading: PropTypes.bool,
    handleSubmitNote: PropTypes.func.isRequired,
    selectOrder: PropTypes.string.isRequired,
    orders: PropTypes.array,
    orderId: PropTypes.string,
    stateSaving: PropTypes.bool
}

const mapStateToProps = (state) => ({
    orderNoteImageItems: state.getIn(['customerNotes', 'orderNoteImageItems'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteOrderNoteImageOfCustomerNote,
        saveOrderNoteImageOfCustomerNote
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('customers/notes')(CustomerNoteForm))
