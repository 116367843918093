import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import { DatePicker, Divider, Table } from 'antd'

const EventLogsIndex = ({
	eventLogs,
	filters,
	loading,
	onChangeFilters,
	onChangeTable
}) => {
	const { i18n } = useTranslation('orders')
	const { sortBy } = filters

	const columns = [
		{
			key: 'resource_name',
			dataIndex: 'resourceName',
			title: 'Resource'
		},
		{
			key: 'activity_type',
			dataIndex: 'activityType',
			title: i18n.t('orders:eventLogs.event')
		},
		{
			key: 'order_number',
			title: i18n.t('orders:eventLogs.orderNumber'),
			render: ({ customer, order }) => {
				const { number } = order || {}
				const { customerNo, fullName } = customer || {}
				return (
					<div>
						<div>
							<Link to={`/inquiries/${number}`}>{number}</Link>
						</div>
						{customerNo && (
							<div>
								<Link to={`/customers/${customerNo}`}>{fullName}</Link>
							</div>
						)}
					</div>
				)
			}
		},
		{
			key: 'source_url',
			dataIndex: 'sourceUrl',
			width: '200px',
			title:  i18n.t('orders:eventLogs.lineUrl'),
			render: (url) => (
				<a href={url} target='_blank' rel="noreferrer" >{url}</a>
			)
		},
		{
			key: 'whodunit_name',
			dataIndex: ['whodunit', 'fullName'],
		},
		{
			key: 'created_at',
			dataIndex: ['createdAt', 'display'],
			sorter: true,
			sortOrder: sortBy.field === 'created_at' ? sortBy.order : null,
			title: i18n.t('orders:eventLogs.createAt')
		},
	]

	const renderFilterDate = () => {
		return (
			<DatePicker onChange={(date, dateString) => onChangeFilters({ createdDate: dateString })} />
		)
	}

	return (
		<div>
			<div style={{textAlign: 'right' }}>
				{i18n.t('orders:eventLogs.createAt')}: {renderFilterDate()}
			</div>
			<Divider />
			<Table
				columns={columns}
				dataSource={eventLogs}
				loading={loading}
				onChange={onChangeTable}
				pagination={false}
				rowKey="id"
			/>
		</div>
	)
}

EventLogsIndex.propTypes = {
	eventLogs: PropTypes.array.isRequired,
	filters: PropTypes.object.isRequired,
	loading: PropTypes.bool,
	onChangeFilters: PropTypes.func.isRequired,
	onChangeTable: PropTypes.func.isRequired
}

EventLogsIndex.defaultProps = {}

export default EventLogsIndex
