import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    CUSTOMER_COMPANY_PROJECTS_FETCH,
    CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS,
    CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION,
    CUSTOMER_COMPANY_PROJECT_SET_FORM_MODAL,
    CUSTOMER_COMPANY_PROJECT_SAVE,
    CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS,
    CUSTOMER_COMPANY_PROJECT_FORM_ERRORS
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showCompanyProjectForm: false,
    projectIdSelected: null,
    errors: new Map()
})

export default function customerCompanyProjectsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_COMPANY_PROJECTS_FETCH:
            return state.merge({ loading: true })
        case CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case CUSTOMER_COMPANY_PROJECT_SET_FORM_MODAL:
            return state.merge({
                showCompanyProjectForm: action.visible,
                projectIdSelected: action.id
            })
        case CUSTOMER_COMPANY_PROJECT_SAVE:
            return state.merge({ saving: true })
        case CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: items.includes(result) ? items : items.push(result)
            })
        case CUSTOMER_COMPANY_PROJECT_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        default:
            return state
    }
}
