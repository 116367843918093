import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Modal, Form, Input, Button } from 'antd'

import actionOccupation from '../../../redux/customers/occupations/actions'
import actionCustomer from '../../../redux/customers/actions'

const { saveOccupation, clearOccupationsErrors } = actionOccupation
const { setOccupationModal } = actionCustomer

class OccupationFormContainer extends Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired
    }

    handleCreate = (values) => {
        const { saveOccupation } = this.props

        saveOccupation(values)
    }

    setModal = (value) => {
        const { setOccupationModal, clearOccupationsErrors } = this.props
        setOccupationModal(!value)
        clearOccupationsErrors()
    }

    render () {
        const { customerOccupations, visible, i18n } = this.props
        const errors = customerOccupations.get('formErrors').toJS()

        return (
            <Modal visible={visible} destroyOnClose
                title={i18n.t('customerForm.addOccupation')}
                okText={i18n.t('customerForm.save')}
                footer={null}
                onCancel={() => this.setModal(visible)}>
                <Form onFinish={this.handleCreate}>
                    <Form.Item label={`${i18n.t('occupations:occupation')} ${i18n.t('th')}`}
                        hasFeedback
                        name={['customer_occupation', 'name_th']}
                        validateStatus={errors && errors.name_th && 'error'}
                        help={errors && errors.name_th}>
                            <Input placeholder={i18n.t('occupations:addOccupation')} />
                    </Form.Item>
                    <Form.Item label={`${i18n.t('occupations:occupation')} ${i18n.t('en')}`}
                        hasFeedback
                        name={['customer_occupation', 'name_en']}
                        validateStatus={errors && errors.name_en && 'error'}
                        help={errors && errors.name_en}>
                            <Input placeholder={i18n.t('occupations:addOccupation')} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" type="primary">
                            {i18n.t('shared:save')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    customerOccupations: state.get('customerOccupations')
})

const mapDispatchToprops = (dispatch) => {
    return bindActionCreators({
        saveOccupation,
        setOccupationModal,
        clearOccupationsErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToprops)(
    withTranslation(['occupations', 'common'])(OccupationFormContainer)
)
