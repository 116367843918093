import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import actions from '../../redux/trucks/actions'
import imageActions from '../../redux/images/actions'

import TruckOwner from '../../components/trucks/truckOwner'

const { addNewImage } = imageActions
const {
    fetchTruckOwner,
    fetchTruckOwners,
    setBankAccountId,
    setTruckId,
    setTruckOwnerId,
    setTruckOwnerStaffForm,
    setTruckOwnerType,
    setTruckOwnerVisibleModal
} = actions

class TruckOwnerContainer extends Component {
    static propTypes = {
        bankAccountEntities: ImmutablePropTypes.map.isRequired,
        fetchTruckOwner: PropTypes.func.isRequired,
        fetchTruckOwners: PropTypes.func.isRequired,
        setBankAccountId: PropTypes.func.isRequired,
        setTruckId: PropTypes.func.isRequired,
        setTruckOwnerId: PropTypes.func.isRequired,
        setTruckOwnerStaffForm: PropTypes.func.isRequired,
        setTruckOwnerType: PropTypes.func.isRequired,
        setTruckOwnerVisibleModal: PropTypes.func.isRequired,
        truckEntities: ImmutablePropTypes.map.isRequired,
        truckOwnerEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired,
        vehicleTypeEntities: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        this.fetchTruckOwner()
    }

    componentDidUpdate (prevProps) {
        const { fetchTruckOwners, trucks } = this.props
        const oldOwnerType = prevProps.trucks.getIn(['truckOwnerTable', 'ownerType'])
        const newOwnerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        if (oldOwnerType !== newOwnerType) {
            fetchTruckOwners({ type: newOwnerType })
        }
    }

    fetchTruckOwner () {
        const { fetchTruckOwners, trucks } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        fetchTruckOwners({ type: ownerType })
    }

    getBankAccount (id) {
        if (!id) return new Map()

        const { bankAccountEntities } = this.props
        return bankAccountEntities.get(_.toString(id), new Map())
    }

    getTruck (id) {
        if (!id) return new Map()

        const { truckEntities } = this.props
        const truck = truckEntities.get(_.toString(id))
        if (!truck) return new Map()

        return truck.mergeDeep({
            vehicle_type: this.getVehicleType(truck.get('vehicle_type'))
        })
    }

    getTruckOwner = (id) => {
        if (!id) return new Map()

        const { truckOwnerEntities } = this.props

        const truckOwner = truckOwnerEntities.get(_.toString(id))
        if (!truckOwner) return new Map()

        return truckOwner.merge({
            trucks: truckOwner.get('trucks', new List()).map((truckId) => {
                return this.getTruck(truckId)
            }),
            truck_owner_bank_accounts: truckOwner.get('truck_owner_bank_accounts', new List()).map((id) => {
                return this.getBankAccount(id)
            })
        })
    }

    getTruckOwners () {
        const { trucks } = this.props

        return trucks.getIn(['truckOwnerTable', 'items'], new List()).map((truckOwnerId) => {
            return this.getTruckOwner(truckOwnerId)
        })
    }

    getVehicleType (id) {
        if (!id) return new Map()

        const { vehicleTypeEntities } = this.props

        return vehicleTypeEntities.get(_.toString(id), new Map())
    }

    onChangeTruckOwnerType = (ownerType) => {
        const { setTruckOwnerType } = this.props

        setTruckOwnerType(ownerType)
    }

    onCreateStaff = (ownerId) => {
        const { setTruckOwnerStaffForm } = this.props

        setTruckOwnerStaffForm({ ownerId })
        this.onToggleModal('staff')
    }

    onEditBankAccount = ({ bankAccountId, ownerId }) => {
        const { setBankAccountId } = this.props

        setBankAccountId({ bankAccountId, ownerId })
        this.onToggleModal('bank_account')
    }

    onEditTruckOwner = (truckOwnerId) => {
        const { setTruckOwnerId } = this.props

        setTruckOwnerId(truckOwnerId)
        this.onToggleModal('truck_owner')
    }

    onEditTruck = ({ truckId, ownerId }) => {
        const { setTruckId } = this.props

        setTruckId({ truckId, ownerId })
        this.onToggleModal('truck')
    }

    onSaveStaffSuccess = (id) => {
        this.props.fetchTruckOwner({ id })
    }

    onSearch = (query) => {
        const { fetchTruckOwners, trucks } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        fetchTruckOwners({
            type: ownerType,
            query,
            page: 1,
            per: 20
        })
    }

    onTableChange = (pagination) => {
        const { fetchTruckOwners, trucks } = this.props
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        fetchTruckOwners({
            type: ownerType,
            query: trucks.getIn(['truckOwnerTable', 'query'], ''),
            page: _.get(pagination, 'current', 1),
            per: _.get(pagination, 'pageSize', 20)
        })
    }

    onToggleModal = (modal) => {
        const { setTruckOwnerVisibleModal } = this.props

        setTruckOwnerVisibleModal({ visibleModal: modal })
    }

    render () {
        const { trucks } = this.props
        const loading = trucks.getIn(['truckOwnerTable', 'loading'])
        const pagination = trucks.getIn(['truckOwnerTable', 'pagination'], new Map()).toJS()
        const visibleModal = trucks.getIn(['truckOwnerTable', 'visibleModal'])
        const ownerType = trucks.getIn(['truckOwnerTable', 'ownerType'])

        return (
            <TruckOwner
                getTruckOwner={this.getTruckOwner}
                loading={loading}
                onCancelModal={this.onToggleModal}
                onChangeTruckOwnerType={this.onChangeTruckOwnerType}
                onCreateStaff={this.onCreateStaff}
                onEditBankAccount={this.onEditBankAccount}
                onEditTruck={this.onEditTruck}
                onEditTruckOwner={this.onEditTruckOwner}
                onSaveStaffSuccess={this.onSaveStaffSuccess}
                onSearch={this.onSearch}
                onTableChange={this.onTableChange}
                ownerType={ownerType}
                pagination={{...pagination, showSizeChanger: false }}
                truckOwners={this.getTruckOwners().toJS()}
                trucks={trucks}
                visibleModal={visibleModal} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankAccountEntities: state.getIn(['Entities', 'truckOwnerBankAccounts']),
        imageEntities: state.getIn(['Entities', 'images']),
        truckEntities: state.getIn(['Entities', 'trucks']),
        truckImageEntities: state.getIn(['Entities', 'truckImages']),
        truckOwnerEntities: state.getIn(['Entities', 'truckOwners']),
        trucks: state.get('trucks'),
        vehicleTypeEntities: state.getIn(['Entities', 'vehicleTypes'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addNewImage,
        fetchTruckOwner,
        fetchTruckOwners,
        setBankAccountId,
        setTruckId,
        setTruckOwnerId,
        setTruckOwnerStaffForm,
        setTruckOwnerType,
        setTruckOwnerVisibleModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckOwnerContainer)

