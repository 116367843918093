import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import SalePipelines from '../../components/salePipelines'

class SalePipelinesIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Sale Pipelines | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/sale_pipelines', name: 'Sale Pipelines' }
                    ]}>
                    <ContentLayout>
                        <SalePipelines />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default SalePipelinesIndexPage
