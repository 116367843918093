import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Form } from 'antd'
import Render from '../customers/tickets/TicketForm/render'

import notificationActions from '../../redux/notifications/actions'

const { setNotifications } = notificationActions

const UPDATE_TICKET_MUTATION = gql`
    mutation UpdateTicket($id: ID!, $ticketParams: AdminTicketInput!){
        updateTicket(id: $id, ticketParams: $ticketParams){
            ticket {
                id
                description
                source
                state
                ticketType
                categoryType
                subCategoryType
                category {
                    id
                    name
                }
                orders {
                    id
                    number
                }
                province {
                    id
                    name
                }
                district {
                    id
                    name
                }
            }
            errors
        }
    }
`

const TicketForm = ({ customerId, onClose, ticket, onSuccess }) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [updateTicket, { loading: saving }] = useMutation(UPDATE_TICKET_MUTATION, {
        onCompleted: () => {
            dispatch(setNotifications('success', 'success', 'success'))
            onSuccess()
            handleClose()
        }
    })

    const handleClose = () => {
        form.resetFields()
        onClose()
    }

    const onSubmitForm = (values) => {
        updateTicket({
            variables: {
                id: ticket.id,
                ticketParams: {
                    ...values,
                    orderIds: values.orderIds.map(({ value }) => value)
                }
            }
        })
    }

    return (
        <Render
            customerId={customerId}
            ticket={ticket}
            form={form}
            onClose={handleClose}
            onSubmitForm={onSubmitForm}
            saving={saving}
        />
    )
}

TicketForm.propTypes = {
    customerId: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    ticket: PropTypes.object.isRequired
}

TicketForm.defaultProps = {
    onClose: () => {},
    onSuccess: () => {}
}

export default TicketForm
