import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'
import SelectLineItems from '../../containers/SelectLineItems'

const SelectSupplierLineItems = ({
    columns,
    defaultLineItems,
    initialData,
    loading,
    onAddItem,
    onFetchInitialData,
    onSelectLineItemsChange,
    orderId
}) => {
    const { i18n } = useTranslation()

    return (
        <div>
            <SelectLineItems
                columns={columns}
                dataSource={initialData}
                loading={loading}
                onChange={onSelectLineItemsChange}
                onFetch={onFetchInitialData}
                orderId={orderId}
                value={defaultLineItems}
            />
            <Button type="dashed" style={{ marginTop: '8px' }} onClick={onAddItem}>
                {i18n.t('shared:add')}
            </Button>
        </div>
    )
}

SelectSupplierLineItems.propTypes = {
    defaultLineItems: PropTypes.array,
    initialData: PropTypes.array,
    loading: PropTypes.bool,
    onFetchInitialData: PropTypes.func,
    onSelectLineItemsChange: PropTypes.func,
    orderId: PropTypes.string.isRequired
}

SelectSupplierLineItems.defaultProps = {
    defaultLineItems: [],
    onSelectLineItemsChange: () => {}
}

export default SelectSupplierLineItems
