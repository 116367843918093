import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import Project from '../../components/projects/project'

import actions from '../../redux/projects/actions'

const { fetchCompanyProject, setCompanyProjectFormModal } = actions

class ProjectContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchCompanyProject, projectId } = this.props

        fetchCompanyProject(projectId)
    }

    getCustomerCompany = (companyId) => {
        return this.props.customerCompanyEntities.get(companyId) || new Map()
    }

    getCompanyProject = () => {
        const { companyProjectEntities, projectId } = this.props

        const companyProject = companyProjectEntities.get(projectId) || new Map()
        return new Map({
            ...companyProject.toJS(),
            customer_company: this.getCustomerCompany(companyProject.get('customer_company')).toJS()
        })
    }

    toggleCompanyProjectForm = ({ id = null } = {}) => {
        const { projects, setCompanyProjectFormModal } = this.props

        setCompanyProjectFormModal(!projects.get('showCompanyProjectForm'), id)
    }

    render () {
        const { projectId, projects } = this.props

        return (
            <Project
                companyProject={this.getCompanyProject().toJS()}
                loading={projects.get('loading')}
                projectId={projectId}
                showCompanyProjectForm={projects.get('showCompanyProjectForm')}
                toggleCompanyProjectForm={this.toggleCompanyProjectForm}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projects: state.get('projects'),
    companyProjectEntities: state.getIn(['Entities', 'companyProjects']),
    customerCompanyEntities: state.getIn(['Entities', 'customerCompanies'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCompanyProject,
        setCompanyProjectFormModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer)

