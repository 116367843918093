import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import _ from 'lodash'

import { apiFetch, apiPostForm } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import {
    bankStatementsSchema,
    bankStatementSchema,
    paymentDetailsSchema,
    paymentVouchersSchema,
    bankStatementCachesSchema
} from '../schema'
import actions from './actions'
import actionNotifications from "../notifications/actions"
const {
    BANK_STATEMENTS_FETCH_FAIL,
    BANK_STATEMENTS_FETCH_REQUEST,
    BANK_STATEMENTS_FETCH_SUCCESS,
    BANK_STATEMENTS_FETCHING,
    BANK_STATEMENTS_UPLOAD_FAIL,
    BANK_STATEMENTS_UPLOAD_REQUEST,
    BANK_STATEMENTS_UPLOAD_SUCCESS,
    BANK_STATEMENTS_UPLOADING,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_REQUEST,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING,
    BANK_STATEMENTS_SAVE_REQUEST,
    BANK_STATEMENTS_SAVING,
    BANK_STATEMENTS_SAVE_SUCCESS,
    BANK_STATEMENTS_SAVE_FAILED,
    BANK_STATEMENTS_EXPORT_REQUEST,
    BANK_STATEMENTS_EXPORT_SUCCESS,
    BANK_STATEMENTS_EXPORT_FAIL,
    ORDER_PAYMENT_DETAIL_QUERY_FETCH_REQUEST,
    ORDER_PAYMENT_DETAIL_QUERY_LOADING,
    ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS,
    PAYMENT_VOUCHER_QUERY_FETCH_REQUEST,
    PAYMENT_VOUCHER_QUERY_LOADING,
    PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS,
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_REQUEST,
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_UPLOADING,
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_SUCCESS,
    BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_FAIL,
    BANK_STATEMENT_SUMMARIES_FETCH_REQUEST,
    BANK_STATEMENT_SUMMARIES_FETCH,
    BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS,
    BANK_STATEMENT_SUMMARIES_SET_PAGINATION,
    BANK_STATEMENT_SUMMARY_FETCH_REQUEST,
    BANK_STATEMENT_SUMMARY_FETCH,
    BANK_STATEMENT_SUMMARY_FETCH_SUCCESS
} = actions

const { setNotifications } = actionNotifications

export function *bankStatementsFetchSaga () {
    yield takeLatest(BANK_STATEMENTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENTS_FETCHING })
        const { filters, onSuccess, onError } = payload

        const queryString = _.values(
            _.mapValues(filters, (value, key) => {
                // isEmpty cannot use with number
                if (!_.isNumber(value) && _.isEmpty(value)) return null
                return `${_.snakeCase(key)}=${value}`
            })
        ).join('&')

        const path = `/bank_statements?${queryString}`

        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, bankStatementsSchema)
            const totalPages = _.get(data, 'meta.total_pages', 0)

            yield put({
                type: BANK_STATEMENTS_FETCH_SUCCESS,
                ...normalizedData,
                filters,
                totalPages
            })

            onSuccess()
        } catch (error) {
            onError(error)
            yield put({ type: BANK_STATEMENTS_FETCH_FAIL })
        }
    })
}

export function *uploadBankStatementsSaga () {
    yield takeLatest(BANK_STATEMENTS_UPLOAD_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENTS_UPLOADING })
        const { params, onSuccess, onError } = payload

        try {
            const path = '/bank_statements/imports'
            const data = yield call(apiPostForm, path, 'POST', params)

            if (_.get(data, 'status') !== 'ok') throw _.get(data, 'status', 'error')

            yield put({ type: BANK_STATEMENTS_UPLOAD_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: BANK_STATEMENTS_UPLOAD_FAIL })
            onError()
        }
    })
}

export function *uploadBankStatementSupplierInvoicesSaga () {
    yield takeLatest(BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING })
        const { params, onSuccess, onError } = payload

        try {
            const path = '/bank_statements/import_supplier_invoices'
            const data = yield call(apiPostForm, path, 'POST', params)

            if (_.get(data, 'status') !== 'ok') throw _.get(data, 'status', 'error')

            yield put({ type: BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL })
            onError()
        }
    })
}

export function *exportBankStatementsSaga () {
    yield takeLatest(BANK_STATEMENTS_EXPORT_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENTS_UPLOADING })
        const { params, onSuccess, onError } = payload

        const { bankAccountId, dateValues } = params
        const { start_date, end_date } = dateValues

        try {
            const path = `/bank_statements/exports?bank_account_id=${bankAccountId}&start_date=${start_date}&end_date=${end_date}`
            const data = yield call(apiFetch, path, 'GET')

            if (_.get(data, 'status') !== 'ok') throw _.get(data, 'status', 'error')

            yield put({ type: BANK_STATEMENTS_EXPORT_SUCCESS })
            onSuccess()
            yield put({ type: BANK_STATEMENTS_SAVING, saving: false })
        } catch (error) {
            yield put({ type: BANK_STATEMENTS_EXPORT_FAIL })
            onError()
        }
    })
}

export function *exportBankStatementsWithSupplierInvoiceSaga () {
    yield takeLatest(BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_UPLOADING })
        const { params, onSuccess, onError } = payload

        const { bank, dateValues } = params
        const { start_date, end_date } = dateValues

        try {
            const path = `/bank_statements/export_with_supplier_invoices?bank=${bank}&start_date=${start_date}&end_date=${end_date}`
            const data = yield call(apiFetch, path, 'GET')

            if (_.get(data, 'status') !== 'ok') throw _.get(data, 'status', 'error')

            yield put({ type: BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_SUCCESS })
            onSuccess()
            yield put({ type: BANK_STATEMENTS_SAVING, saving: false })
        } catch (error) {
            yield put({ type: BANK_STATEMENTS_WITH_SUPPLIER_INVOICE_EXPORT_FAIL })
            onError()
        }
    })
}

export function *saveBankStatementOrder () {
    yield takeLatest(BANK_STATEMENTS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENTS_SAVING })

        const { params, onSuccess, onShowModal } = payload
        const { id } = params
        const method = 'PATCH'
        const body = JSON.stringify({ bank_statement: params })
        try {
            const path = `/bank_statements/${id}`
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)
            yield put({
                type: BANK_STATEMENTS_SAVE_SUCCESS,
                ...normalize(formattedData, bankStatementSchema
                )
            })
            onSuccess()
            onShowModal(false)
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put({ type: BANK_STATEMENTS_SAVE_FAILED })
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchOrderPaymentDetailThroughQuery () {
    yield takeLatest(ORDER_PAYMENT_DETAIL_QUERY_FETCH_REQUEST, function *({ query }) {
        yield put({ type: ORDER_PAYMENT_DETAIL_QUERY_LOADING, loading: true })
        const path = `/orders/payment_details/searches?query=${query}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, paymentDetailsSchema)
            yield put({
                type: ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: ORDER_PAYMENT_DETAIL_QUERY_LOADING, loading: false })
        }
    })
}

export function *fetchPaymentVoucherThroughQuery () {
    yield takeLatest(PAYMENT_VOUCHER_QUERY_FETCH_REQUEST, function *({ query }) {
        yield put({ type: PAYMENT_VOUCHER_QUERY_LOADING, loading: true })
        const path = `/payment_vouchers/searches?query=${query}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, paymentVouchersSchema)
            yield put({
                type: PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: PAYMENT_VOUCHER_QUERY_LOADING, loading: false })
        }
    })
}

export function *bankStatementSummariesRequest () {
    yield takeEvery(BANK_STATEMENT_SUMMARIES_FETCH_REQUEST, function *({ payload }) {
        const { page } = payload
        const pageSize = 20

        yield put({ type: BANK_STATEMENT_SUMMARIES_FETCH })

        const response = yield call(apiFetch, `/bank_statements/summaries?page=${page}&per=${pageSize}`)
        const formattedData = response.data.map((itemData) => {
            return { id: itemData[0], type: 'bank_statement_cache', items: itemData[1]  }
        })
        const normalizeData = normalize(formattedData, bankStatementCachesSchema)
        const total = response.meta.total_pages * pageSize

        yield put({
            type: BANK_STATEMENT_SUMMARIES_SET_PAGINATION,
            pagination: { current: page, total, pageSize }
        })

        yield put({
            type: BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS,
            ...normalizeData,
        })
    })
}

export function *fetchBankStatementSummary () {
    yield takeLatest(BANK_STATEMENT_SUMMARY_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: BANK_STATEMENT_SUMMARY_FETCH })

        const { month } = payload
        const path = `/bank_statement_summary?month=${month}`

        let bankStatementSummary = {}
        const data = yield call(apiFetch, path)

        _.map(data, (value, key) => {
            _.set(bankStatementSummary, _.camelCase(key), value)
        })

        yield put({
            type: BANK_STATEMENT_SUMMARY_FETCH_SUCCESS,
            bankStatementSummary
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(bankStatementsFetchSaga),
        fork(uploadBankStatementsSaga),
        fork(uploadBankStatementSupplierInvoicesSaga),
        fork(saveBankStatementOrder),
        fork(exportBankStatementsSaga),
        fork(exportBankStatementsWithSupplierInvoiceSaga),
        fork(fetchOrderPaymentDetailThroughQuery),
        fork(fetchPaymentVoucherThroughQuery),
        fork(bankStatementSummariesRequest),
        fork(fetchBankStatementSummary)
    ])
}
