const actions = {
    SUPPLIER_INVOICES_REQUEST: 'SUPPLIER_INVOICES_REQUEST',
    SUPPLIER_INVOICES_REQUESTING: 'SUPPLIER_INVOICES_REQUESTING',
    SUPPLIER_INVOICES_REQUEST_SUCCESS: 'SUPPLIER_INVOICES_REQUEST_SUCCESS',

    SUPPLIER_INVOICES_SET_PAGINATION: 'SUPPLIER_INVOICES_SET_PAGINATION',
    SUPPLIER_INVOICES_SET_SUMMARY_DATA: 'SUPPLIER_INVOICES_SET_SUMMARY_DATA',
    SUPPLIER_INVOICES_SET_FILTERS: 'SUPPLIER_INVOICES_SET_FILTERS',

    SUPPLIER_INVOICES_SET_IMPORT_MODAL: 'SUPPLIER_INVOICES_SET_IMPORT_MODAL',
    SUPPLIER_INVOICES_SET_EXPORT_MODAL: 'SUPPLIER_INVOICES_SET_EXPORT_MODAL',

    SUPPLIER_INVOICES_IMPORT_REQUEST: 'SUPPLIER_INVOICES_IMPORT_REQUEST',
    SUPPLIER_INVOICES_IMPORT_SUCCESS: 'SUPPLIER_INVOICES_IMPORT_SUCCESS',
    SUPPLIER_INVOICES_IMPORT_FAIL: 'SUPPLIER_INVOICES_IMPORT_FAIL',
    SUPPLIER_INVOICES_IMPORTING: 'SUPPLIER_INVOICES_IMPORTING',
    SUPPLIER_INVOICES_EXPORTING: 'SUPPLIER_INVOICES_EXPORTING',
    SUPPLIER_INVOICES_EXPORT_REQUEST: 'SUPPLIER_INVOICES_EXPORT_REQUEST',
    SUPPLIER_INVOICES_EXPORT_FAIL: 'SUPPLIER_INVOICES_EXPORT_FAIL',
    SUPPLIER_INVOICES_EXPORT_SUCCESS: 'SUPPLIER_INVOICES_EXPORT_SUCCESS',

    SUPPLIER_INVOICES_VAT_EXPORTING: 'SUPPLIER_INVOICES_VAT_EXPORTING',
    SUPPLIER_INVOICES_VAT_EXPORT_REQUEST: 'SUPPLIER_INVOICES_VAT_EXPORT_REQUEST',
    SUPPLIER_INVOICES_VAT_EXPORT_FAIL: 'SUPPLIER_INVOICES_VAT_EXPORT_FAIL',
    SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS: 'SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS',

    fetchSupplierInvoices: ({ page = 1, per = 20, filters = {} }) => {
        return {
            type: actions.SUPPLIER_INVOICES_REQUEST,
            payload: { page, per, filters }
        }
    },

    setPagination: (pagination) => {
        return {
            type: actions.SUPPLIER_INVOICES_SET_PAGINATION,
            pagination
        }
    },

    setFilters: (filters) => {
        return {
            type: actions.SUPPLIER_INVOICES_SET_FILTERS,
            filters
        }
    },

    setImportModal: (value) => {
        return {
            type: actions.SUPPLIER_INVOICES_SET_IMPORT_MODAL,
            value
        }
    },

    setExportModal: (value) => {
        return {
            type: actions.SUPPLIER_INVOICES_SET_EXPORT_MODAL,
            value
        }
    },

    importSupplierInvoices: ({
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_INVOICES_IMPORT_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    exportSupplierInvoices: ({
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_INVOICES_EXPORT_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },

    exportSupplierInvoiceVats: ({
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.SUPPLIER_INVOICES_VAT_EXPORT_REQUEST,
            payload: { params, onSuccess, onError }
        }
    },
}

export default actions
