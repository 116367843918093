export const MEMBER_AVATAR_PROPS = {
  agent: { backgroundColor: '#87d068' },
  member: {},
  self: { backgroundColor: '#fefbe6', border: '1px solid #ffe48f', color: '#d38708'},
  edit: { backgroundColor: '#1890ff' },
  team: { backgroundColor: '#097969' }
}

export const STATE_PROPS = {
  created: { color: 'blue' },
  assigned: { color: 'gold' },
  got_answer: { color: 'volcano' },
  solved: { color: 'cyan' }
}
