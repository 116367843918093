import { combineReducers } from 'redux-immutable'

import App from './app/reducers'
import Auth from './auth/reducers'
import bankStatements from './bankStatements/reducers'
import brands from './brands/reducers'
import businessAddresses from './customers/addresses/businesses/reducers'
import categories from './categories/reducers'
import chartOfAccounts from './chartOfAccounts/reducers'
import customerCompanies from './customers/companies/reducers'
import customerCompanyProjects from './customers/companyProjects/reducers'
import customerNotes from './customers/customerNotes/reducers'
import customerOrderPaymentDetails from './customers/orderPaymentDetails/reducers'
import customerOccupations from './customers/occupations/reducers'
import customerRoles from './customers/roles/reducers'
import customers from './customers/reducer'
import customerTickets from './customers/tickets/reducers'
import customerTruckImages from './customers/truckImages/reducers'
import customerTypes from './customers/customerTypes/reducers'
import customerWallets from './customers/wallets/reducers'
import customerReviews from './inquiry/reviews/reducers'
import customerLabels from './customers/customerLabels/reducers'
import districts from './districts/reducers'
import Entities from './entities/reducers'
import eventLogs from './eventLogs/reducers'
import images from './images/reducers'
import inquiry from './inquiry/reducers'
import inquiryTransferBacks from './inquiry/transferBacks/reducers'
import itemTruckImages from './items/truckImages/reducers'
import journalEntryFormats from './journalEntryFormats/reducers'
import journalEntryFormatItems from './journalEntryFormats/items/reducers'
import journalEntries from './journalEntries/reducers'
import mailingAddresses from './customers/addresses/mailing/reducers'
import notifications from './notifications/reducers'
import orderActivityLogs from './orders/activityLogs/reducers'
import orderBusinessAddresses from './orders/businessAddresses/reducers'
import orderDocuments from './orders/documents/reducers'
import orderHistoryDashboards from './orders/historyDashboards/reducers'
import orderNoteImages from './orders/noteImages/reducers'
import orderPaymentDetailInstallments from './orderPaymentDetails/Installments/reducers'
import orderPaymentDetailList from './orderPaymentDetails/reducers'
import orderPaymentDetails from './orders/accounting/reducers'
import orderReviews from './orders/reviews/reducers'
import orders from './orders/reducers'
import orderShippingAddress from './orders/shippingAddresses/reducers'
import orderState from './inquiry/orderStates/reducers'
import orderSummaryDashBoards from './orders/summaryDashBoards/reducers'
import orderTables from './orders/tables/reducers'
import orderTrackings from './orders/trackings/reducers'
import orderYields from './orders/yields/reducers'
import paymentVouchers from './paymentVouchers/reducers'
import potentialLeads from './potentialLeads/reducers'
import productGroupItems from './productGroupItems/reducers'
import productGroups from './productGroups/reducers'
import profitSharings from './customers/profitSharings/reducers'
import projectContacts from './projects/contacts/reducers'
import projectCustomers from './projects/customers/reducers'
import projectProfitSharings from './projects/profitSharings/reducers'
import projects from './projects/reducers'
import provinces from './provinces/reducers'
import purchaseOrders from './purchaseOrders/reducers'
import RequestItemIndicesReducer from './requestItemIndices/reducers'
import reservationTruckTypes from './reservationTruckTypes/reducers'
import sales from './sales/reducers'
import saleSummaries from './salesSummary/reducers'
import scbPaymentConfirms from './scbPaymentConfirms/reducers'
import shippingAddresses from './customers/addresses/shippingAddresses/reducers'
import shippingRates from './shippingRates/reducers'
import staffProfiles from './staffs/profiles/reducers'
import staffs from './staffs/reducers'
import supplierAccountingGroups from './suppliers/accountingGroups/reducers'
import supplierInvoices from './supplierInvoices/reducers'
import supplierOrderReviews from './supplierOrderReviews/reducers'
import supplierOrders from './supplierOrders/reducers'
import supplierOverTransferItems from './suppliers/overTransferItems/reducers'
import suppliers from './suppliers/reducers'
import truckOriginAddresses from './truckOriginAddresses/reducers'
import truckReservations from './truckReservations/reducers'
import trucks from './trucks/reducers'
import vatDocuments from './vatDocuments/reducers'
import walletSummaries from './walletSummaries/reducers'
import withholdingTaxes from './withholdingTaxes/reducers'

export const makeRootReducer = () => {
    return combineReducers({
        App,
        Auth,
        bankStatements,
        brands,
        businessAddresses,
        categories,
        chartOfAccounts,
        customerCompanies,
        customerCompanyProjects,
        customerNotes,
        customerOrderPaymentDetails,
        customerOccupations,
        customerRoles,
        customers,
        customerTickets,
        customerTruckImages,
        customerTypes,
        customerWallets,
        customerReviews,
        customerLabels,
        districts,
        Entities,
        eventLogs,
        images,
        inquiry,
        inquiryTransferBacks,
        itemTruckImages,
        journalEntryFormats,
        journalEntryFormatItems,
        journalEntries,
        mailingAddresses,
        notifications,
        orderActivityLogs,
        orderBusinessAddresses,
        orderDocuments,
        orderHistoryDashboards,
        orderPaymentDetailInstallments,
        orderNoteImages,
        orderPaymentDetailList,
        orderPaymentDetails,
        orderReviews,
        orders,
        orderShippingAddress,
        orderState,
        orderSummaryDashBoards,
        orderTables,
        orderTrackings,
        orderYields,
        paymentVouchers,
        potentialLeads,
        productGroupItems,
        productGroups,
        profitSharings,
        projectContacts,
        projectCustomers,
        projectProfitSharings,
        projects,
        provinces,
        purchaseOrders,
        RequestItemIndicesReducer,
        reservationTruckTypes,
        sales,
        saleSummaries,
        scbPaymentConfirms,
        shippingAddresses,
        shippingRates,
        staffProfiles,
        staffs,
        supplierAccountingGroups,
        supplierInvoices,
        supplierOrderReviews,
        supplierOrders,
        supplierOverTransferItems,
        suppliers,
        truckOriginAddresses,
        truckReservations,
        trucks,
        vatDocuments,
        walletSummaries,
        withholdingTaxes
    })
}

export default makeRootReducer
