import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'

import SelectCountry from '../../../../../components/SelectCountry'

const ExportForm = ({
  defaultCountryId,
  errors
}) => {

  return (
    <div>
      <Form.Item  name={['order_export_shipment_attributes', 'country_id']}
          initialValue={defaultCountryId}
          validateStatus={errors.get('order_export_shipment.country') ? 'error' : null}
          help={errors.get('order_export_shipment.country')}
          hasFeedback>
        <SelectCountry defaultValue={defaultCountryId ? `${defaultCountryId}` : null} />
      </Form.Item>
    </div>
  )
}

ExportForm.propTypes = {
  defaultCountryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object
}

ExportForm.defaultProps = {}

export default ExportForm
