import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { Button, Col, DatePicker , Form, Popconfirm, Row, Select } from 'antd'
import PaymentVoucherWrapper from '../paymentVouchers/paymentVouchers.style'

const TransferBackListHeader = ({
  exporting,
  filters,
  onChangeFilters,
  onExport,
  selectedPVs
}) => {
    const { i18n } = useTranslation(['confirms', 'suppliers'])

    const onChangeDateRange = (dates, dateStrings) => {
        onChangeFilters({ startDate: dateStrings[0], endDate: dateStrings[1] })
    }

    const onChangeHasAnySlip = (value) => {
        onChangeFilters({ hasAnySlip: value })
    }

    const onChangeIsPaid = (value) => {
        onChangeFilters({ isPaid: value })
    }

    const onExportPVs = () => {
        const ids = selectedPVs.map(pv => pv.id)
        onExport(ids)
    }

    const renderExportPV = () => {
        const exportedPvs = selectedPVs.map((pv) => {
            if (!_.get(pv, 'is_exported', null)) { return null }
            return _.get(pv, 'supplier_orders.0.ref_code', null)
        }).filter(Boolean)
        const defaultExportBtn = _.isEmpty(selectedPVs) ? i18n.t('suppliers:selectExportPv') :
            `${_.size(selectedPVs)} ${i18n.t('suppliers:exportPv')}`

        if (exportedPvs.length > 0) {
            return (
                <Popconfirm title={i18n.t('suppliers:exportedAlreadyPv', { exportedPvs: exportedPvs.join(', ') })}
                    onConfirm={onExportPVs}>
                    <Button className={'export-pv-btn'}
                        loading={exporting}>
                        {defaultExportBtn}
                    </Button>
                </Popconfirm>
            )
        }

        return (
            <Button className={'export-pv-btn'} loading={exporting} onClick={onExportPVs}>
                {defaultExportBtn}
            </Button>
        )
    }

    const renderDateRange = () => {
        const { startDate, endDate } = filters

        return (
            <Form.Item labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
                label={i18n.t('confirms:accounting.transferDate')}>
                <DatePicker.RangePicker
                    onChange={onChangeDateRange}
                    value={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]} 
                />
            </Form.Item>
        )
    }

    const renderHasAnySlipSelector = () => {
        const { hasAnySlip } = filters

        return (
            <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
                label={i18n.t('confirms:accounting.slip')}>
                <Select
                    onChange={onChangeHasAnySlip}
                    style={{ width: '100%' }}
                    value={hasAnySlip}
                >
                    <Select.Option value="">{i18n.t('confirms:filterSlip.all')}</Select.Option>
                    <Select.Option value="true">{i18n.t('confirms:filterSlip.exist')}</Select.Option>
                    <Select.Option value="false">{i18n.t('confirms:filterSlip.notExist')}</Select.Option>
                </Select>
            </Form.Item>
        )
    }

    const renderIsPaidSelector = () => {
        const { isPaid } = filters

        return (
            <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}
                label={i18n.t('confirms:accounting.payment')}>
                <Select
                    onChange={onChangeIsPaid}
                    style={{ width: '100%' }}
                    value={isPaid}
                >
                    <Select.Option value="">{i18n.t('confirms:filterPayment.all')}</Select.Option>
                    <Select.Option value="true">{i18n.t('confirms:filterPayment.paid')}</Select.Option>
                    <Select.Option value="false">{i18n.t('confirms:filterPayment.notPaid')}</Select.Option>
                </Select>
            </Form.Item>
        )
    }

    return (
        <PaymentVoucherWrapper>
            <Row>
                <Col span={6}>{renderExportPV()}</Col>
                <Col span={9}>{renderDateRange()}</Col>
                <Col span={4}>{renderHasAnySlipSelector()}</Col>
                <Col span={5}>{renderIsPaidSelector()}</Col>
            </Row>
        </PaymentVoucherWrapper>
    )
}

TransferBackListHeader.propTypes = {
  exporting: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onExport: PropTypes.func.isRequired,
  selectedPVs: PropTypes.array.isRequired
}

export default TransferBackListHeader
