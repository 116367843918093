import styled from 'styled-components'

const ItemPricesWrapper = styled.div`
  .item-name {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    margin: 16px 0 8px;
  }

  .title-text { padding: 16px; }

  .supplier-card {
    .title { font-weight: bold; }

    .detail {
      display: flex;

      .label:after {
        content: ':';
        margin-right: 4px;
      }
    }

    .summary-section {
      display: flex;

      .summary-item-left { width: 40%; }
      .summary-item-right {
        width: 60%;
        text-align: right;
      }

      .summary-item-left, .summary-item-right {
        .label { font-weight: bold; }
      }
    }
  }

  .text-center { text-align: center; }
`

export default ItemPricesWrapper
