import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const CUSTOMER_MAILING_ADDRESSES_QUERY = gql`
  query CustomerMailingAddresses ($customerNo: String!) {
    customer (customerNo: $customerNo) {
      id
      mailingAddresses {
        id
        fullAddress
        name
        address
        province {
          id
          name
        }
        district {
          id
          name
        }
        subDistrict
        postalCode
      }
    }
  }
`

const renderMailingAddressOptions = options => options.map(({ id, fullAddress }) => {
  return <Select.Option value={id}>{fullAddress}</Select.Option>
})

const SelectCustomerMailingAddress = ({
  customerNo,
  defaultValue,
  onAfterChange,
  onChange,
  outerLoading,
  value
}) => {
  const { i18n } = useTranslation('addresses')
  const [selected, setSelected] = useState(undefined)
  const initValueRef = useRef(false)
  const { loading: innerLoading, data, error } = useQuery(CUSTOMER_MAILING_ADDRESSES_QUERY, {
    variables: { customerNo }
  })

  useEffect(() => initialValue(), [defaultValue])
  useEffect(() => updateValue(), [value])

  if (error) { console.error(error) }

  const initialValue = () => {
    if (initValueRef.current || outerLoading || selected) return null

    initValueRef.current = true
    handleChange(defaultValue)
  }

  const updateValue = () => {
    if (value === selected) return null

    handleChange(value)
  }

  const handleChange = value => {
    setSelected(value)
    onChange(value)
    onAfterChange(options.filter(option => option.id === value)[0])
  }

  const options = (data && data.customer && data.customer.mailingAddresses) || []

  return (
    <Select allowClear
      loading={outerLoading || innerLoading}
      onChange={handleChange}
      placeholder={i18n.t('addresses:mailingAddress')}
      style={{ width: '100%' }}
      value={selected}
    >
      {renderMailingAddressOptions(options)}
    </Select>
  )
}

SelectCustomerMailingAddress.propTypes = {
  customerNo: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
  outerLoading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectCustomerMailingAddress.defaultProps = {
  onAfterChange: () => {},
  onChange: () => {}
}

export default SelectCustomerMailingAddress
