import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Col, DatePicker, Row, Select, Space } from 'antd'
import SelectStaff from '../../SelectStaff'
import MonthAndYearSelector from '../MonthAndYearSelector'
import SalePipelineEnumSelector from '../SalePipelineEnumSelector'
import FilterWrapper from './filter.style'

const { Option } = Select

export const getInitialFilters = (args = {}) => {
    return {
        isPotential: null,
        month: '',
        year: '',
        staffId: '',
        status: '',
        startDate: null,
        endDate: null,
        ...args
    }
}

const renderPotentialSelector = (i18n, filters, onChange) => {
    return (
        <Select
            onChange={value => onChange({ isPotential: value })}
            style={{ width: '90px' }}
            value={filters.isPotential || null}
        >
            <Option value={null}>{i18n.t('shared:hasAnyLabels.all')}</Option>
            <Option value={true}>{i18n.t('shared:hasAnyLabels.isExists')}</Option>
            <Option value={false}>{i18n.t('shared:hasAnyLabels.notExists')}</Option>
        </Select>
    )
}

const Filter = ({ filters, onChangeFilters }) => {
    const { i18n } = useTranslation('salePipelines')

    const onChangeDateRange = (dates, dateStrings) => {
        onChangeFilters({ startDate: dateStrings[0], endDate: dateStrings[1] })
    }

    const onChangeStaff = value => {
        onChangeFilters({ staffId: value || '' })
    }

    const onChangeStatus = value => {
        const status = value === undefined ? '' : value

        onChangeFilters({ status: status })
    }

    const defaultStartDate = filters.startDate ? moment(filters.startDate) : null
    const defaultEndDate = filters.endDate ? moment(filters.endDate) : null

    return (
        <FilterWrapper>
            <Row className="row-style">
                <Col sm={12} xs={24} className="col-style">
                    <Space>
                        <div className="text-nowrap">{i18n.t('salePipelines:isPotential')}</div>
                        {renderPotentialSelector(i18n, filters, onChangeFilters)}
                    </Space>
                    <SelectStaff onChange={onChangeStaff} onlySales value={filters.staffId || undefined} />
                </Col>
                <Col sm={12} xs={24} className="col-style align-right">
                    <Space>
                        <div className="text-nowrap">{i18n.t('salePipelines:useMonth/Year')}</div>
                        <MonthAndYearSelector
                            month={filters.month}
                            year={filters.year}
                            onChange={onChangeFilters}
                            style={{ width: '180px' }}
                        />
                    </Space>
                    <SalePipelineEnumSelector
                        allowClear
                        name="status"
                        value={filters.status === '' ? undefined : filters.status}
                        onChange={onChangeStatus}
                        placeholder={i18n.t('salePipelines:selectStatus')}
                        style={{ width: '120px' }} />
                </Col>
            </Row>
            <Row className="row-style">
                <Col sm={12} xs={24} className="col-style"></Col>
                <Col sm={12} xs={24} className="col-style align-right">
                    <Space>
                        <div className="text-nowrap">{i18n.t('shared:createdAt')}</div>
                        <DatePicker.RangePicker
                            onChange={onChangeDateRange}
                            value={[defaultStartDate, defaultEndDate]}
                            style={{ width: '260px' }} />
                    </Space>
                </Col>
            </Row>
        </FilterWrapper>
    )
}

Filter.propTypes = {
    filters: PropTypes.object.isRequired,
    onChangeFilters: PropTypes.func
}

Filter.defaultProps = {
    onChangeFilters: () => {}
}

export default Filter
