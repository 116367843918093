import React, { Component } from 'react'

import { Divider } from 'antd'
import NoteImageFormContainer from '../Orders/NoteImages/NoteImageFormContainer'
import NoteImageListContainer from '../Orders/NoteImages/NoteImageListContainer'

class NoteImageTab extends Component {
    render () {
        const { id } = this.props

        return (
            <div>
                <NoteImageFormContainer orderId={id} />
                <Divider />
                <NoteImageListContainer orderId={id} />
            </div>
        )
    }
}

export default NoteImageTab
