import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import TruckOriginAddressSelect from '../../components/trucks/truckOriginAddressSelect'

import truckOriginAddressActions from '../../redux/truckOriginAddresses/actions'

const { initTruckOriginAddresses, fetchTruckOriginAddresses } = truckOriginAddressActions

class TruckOriginAddressSelectContainer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        value: PropTypes.array,

        initTruckOriginAddresses: PropTypes.func.isRequired,
        fetchTruckOriginAddresses: PropTypes.func.isRequired,
        truckOriginAddressEntities: ImmutablePropTypes.map.isRequired,
        truckOriginAddresses: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: []
    }

    componentDidMount () {
        this.initSelectedData()
    }

    async initSelectedData () {
        const { initTruckOriginAddresses, value } = this.props

        await initTruckOriginAddresses({ ids: value })
    }

    getTruckOriginAddresses () {
        const { truckOriginAddresses, truckOriginAddressEntities } = this.props

        return truckOriginAddresses.get('items').map((id) => {
            return truckOriginAddressEntities.get(id + '') || new Map()
        }) || new List()
    }

    handleSearch = (query) => {
        const { fetchTruckOriginAddresses } = this.props

        fetchTruckOriginAddresses({ query })
    }

    render () {
        const { onChange, truckOriginAddresses, value } = this.props

        return (
            <TruckOriginAddressSelect
                loading={truckOriginAddresses.get('loading')}
                onChange={onChange}
                onSearch={this.handleSearch}
                truckOriginAddresses={this.getTruckOriginAddresses().toJS()}
                value={value} />
        )
    }
}

const mapStateToProps = (state) => ({
    truckOriginAddressEntities: state.getIn(['Entities', 'truckOriginAddresses']),
    truckOriginAddresses: state.get('truckOriginAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        initTruckOriginAddresses,
        fetchTruckOriginAddresses
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckOriginAddressSelectContainer)
