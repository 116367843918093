import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Upload } from 'antd'
import { LoadingOutlined, InboxOutlined } from '@ant-design/icons'

const UploadItemTruckImage = ({
    customRequest,
    i18n,
    uploading
}) => {
    return (
        <Upload.Dragger
            customRequest={customRequest}
            showUploadList={false}
            multiple
        >
            {uploading ? <LoadingOutlined /> : <InboxOutlined />}
            <p>
                {i18n.t('items/truckImages:uploadTruckImage')}
            </p>
        </Upload.Dragger>
    )
}

UploadItemTruckImage.propTypes = {
    customRequest: PropTypes.func
}

UploadItemTruckImage.defaultProps = {
    customRequest: () => {}
}

export default withTranslation(['items/truckImages'])(UploadItemTruckImage)
