import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Spin } from 'antd'

import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'

import categoriesActions from '../../redux/categories/actions'
import customerActions from '../../redux/customers/actions'
import orderActions from '../../redux/orders/actions'

import LeadGeneration from '../../components/leadGeneration/leadGeneration'

const { fetchCategories } = categoriesActions
const { fetchCustomer } = customerActions
const {
    setDeliveryDate,
    setPurchaseCompany,
    setPaymentOptions,
    saveOrder,
    searchCses,
    searchSales,
    setCsOption,
    setSaleOption,
    setShippingType,
    setShippingAddessError
} = orderActions
class LeadGenerationContainer extends Component {
    componentDidMount () {
        const { fetchCustomer, fetchCategories, customerId } = this.props

        fetchCustomer(customerId)
        fetchCategories()
    }

    getCustomer () {
        const { customers, entities } = this.props

        if (this.getLoading()) {
            return <Spin />
        }

        const customerId = customers.get('items').get(0)

        if (!customerId) { return {} }

        return entities.getIn(['customers', customerId.toString()]).toJS() || {}
    }

    getCategories () {
        const { entities, categories } = this.props

        const categoriesList = categories.get('items').map((category) => {
            return entities.getIn(['categories', category.toString()])
        })

        return categoriesList
    }

    getStaff () {
        const { entities, location } = this.props
        const id = location.params ? location.params.staff_id : ''
        if (id) {
            const currentStaff = entities.getIn(['staffs', id])
            return (
                [{ key: `${currentStaff.get('name')}`, value: currentStaff.get('id') }]
            )
        } else return []
    }

    onSearchCs = (value) => {
        const { searchCses } = this.props

        searchCses(value)
    }

    onSelectCs = (value) => {
        const { setCsOption } = this.props

        setCsOption(value)
    }

    onSearchSale = (value) => {
        const { searchSales } = this.props

        searchSales(value)
    }

    onSelectSale = (value) => {
        const { setSaleOption } = this.props

        setSaleOption(value)
    }

    getLoading () {
        const { customers } = this.props

        return customers.get('loading')
    }

    onDateChange = (date, dateString) => {
        const { setDeliveryDate } = this.props

        setDeliveryDate(dateString)
    }

    onSelectShippingType = (value) => {
        const { setShippingType } = this.props

        setShippingType(value)
    }

    onSelectPurchaseCompany = (value) => {
        const { setPurchaseCompany } = this.props

        setPurchaseCompany(value.target.checked)
    }

    onSelectPayment = (valueOption) => {
        const { setPaymentOptions } = this.props

        setPaymentOptions(valueOption.target.value)
    }

    onSubmitForm = (order) => {
        const { saveOrder, customerId, setShippingAddessError, redirectToInquiryPage, onSuccess } = this.props

        if (order.shipping_type === 'by_osh' && !order.shipping_address_id) {
            setShippingAddessError(true)
        } else {
            setShippingAddessError(false)
            saveOrder(order, customerId, { redirectToInquiryPage, onSuccess })
        }
    }

    render () {
        const { orders, entities, location, customerId, ticketDescription } = this.props

        const shippingType = orders.get('shippingType')
        const payment = orders.get('paymentOptions')
        const formErrors = orders.get('formErrors')
        const shippingAddressError = orders.get('shippingAddressError')

        const customerServices = entities.get('customerServices')
        const sales = entities.get('sales')
        const csItems = orders.get('csItems')
        const saleItems = orders.get('saleItems')
        const csesLoading = orders.get('csesLoading')
        const savingOrder = orders.get('saving')

        const defaultStaffId = location.params ? location.params.staff_id : ''

        return (
            <div>
                <LeadGeneration
                    customer={this.getCustomer()}
                    onDateChange={this.onDateChange}
                    onSelectPayment={this.onSelectPayment}
                    paymentValue={payment}
                    categories={this.getCategories()}
                    onSelectPurchaseCompany={this.onSelectPurchaseCompany}
                    onSubmitOrder={this.onSubmitForm}
                    errors={formErrors}
                    onSearchCses={this.onSearchCs}
                    onSearchSales={this.onSearchSale}
                    onSelectCs={this.onSelectCs}
                    onselectSale={this.searchSales}
                    loading={this.getLoading()}
                    customerServices={customerServices}
                    sales={sales}
                    csItems={csItems}
                    saleItems={saleItems}
                    csesLoading={csesLoading}
                    savingOrder={savingOrder}
                    customerId={customerId}
                    defaultStaff={this.getStaff()}
                    defaultStaffId={defaultStaffId}
                    onSelectShippingType={this.onSelectShippingType}
                    shippingType={shippingType}
                    shippingAddressError={shippingAddressError}
                    ticketDescription={ticketDescription}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    entities: state.get('Entities'),
    orders: state.get('orders'),
    categories: state.get('categories'),
    shippingAddressEntities: state.getIn(['Entities', 'shippingAddresses'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomer,
        fetchCategories,
        setDeliveryDate,
        setPurchaseCompany,
        setPaymentOptions,
        saveOrder,
        searchCses,
        searchSales,
        setCsOption,
        setSaleOption,
        setShippingType,
        setShippingAddessError
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('orders')(withRouter(LeadGenerationContainer)))
