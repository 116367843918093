import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import { profitSharingsSchema } from '../../schema'
import actions from './actions'

const {
    PROJECT_FETCH_PROFIT_SHARINGS_REQUEST,
    PROJECT_FETCH_PROFIT_SHARINGS,
    PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS,
    PROJECT_SET_PROFIT_SHARING_PAGINATION
} = actions

export function *fetchCompanyProjectProfitSharings () {
    yield takeEvery(PROJECT_FETCH_PROFIT_SHARINGS_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_FETCH_PROFIT_SHARINGS })

        const { id, page, per } = payload
        const queryString = `page=${page}&per=${per}`

        const data = yield call(apiFetch, `/company_projects/${id}/profit_sharings?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, profitSharingsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: PROJECT_SET_PROFIT_SHARING_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PROJECT_FETCH_PROFIT_SHARINGS_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCompanyProjectProfitSharings)
    ])
}
