import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

import {
    Button,
    Form,
    Modal,
    Select,
    Upload
} from 'antd'

import { UploadOutlined } from '@ant-design/icons'

const BankStatementImport = ({
    form,
    onSubmit,
    onToggleVisibleModal,
    saving,
    visibleModal,
    hideBankSelect,
    i18n,
    textButton,
    additionalUpdate
}) => {
    const handleSubmit = values => {
        const newValues = {
            ...values,
            additional_update: additionalUpdate,
            file: _.get(values, 'file', []).map((file) => _.get(file, 'originFileObj', null))[0]
        }

        onSubmit({ params: newValues })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 }
        }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 12, offset: 12 }
        }
    }

    const normFile = e => {
        let fileList = [...e.fileList]
        fileList = fileList.slice(-1)
        return fileList
    }

    return (
        <>
            <Button
                key="import-button"
                onClick={onToggleVisibleModal}
                type="primary"
            >
                {i18n.t(`bankStatements:${textButton}`)}
            </Button>

            <Modal
                footer={null}
                key="import-modal"
                onCancel={onToggleVisibleModal}
                title={i18n.t('bankStatements:import')}
                visible={visibleModal}
            >
                <Form
                    onFinish={handleSubmit}
                    {...formItemLayout}
                >
                    {hideBankSelect ? null :
                        <Form.Item
                            key="bank"
                            name='bank'
                            initialValue='scb'
                            label={i18n.t('confirms:accounting.selectBank')}
                        >
                                <Select>
                                    <Select.Option
                                        key="SCB"
                                        value="scb"
                                    >
                                        SCB
                                    </Select.Option>

                                    <Select.Option
                                        key="KBank"
                                        value="kbank"
                                    >
                                        KBank
                                    </Select.Option>
                                </Select>
                        </Form.Item>
                    }
                    <Form.Item
                        label={i18n.t('bankStatements:selectFile')}
                        name='file'
                        getValueFromEvent={normFile}
                        valuePropName='fileList'
                        extra="excel or csv file"
                    >
                            <Upload
                                beforeUpload={() => { return false }}
                                listType="text"
                            >
                                <Button>
                                    <UploadOutlined /> Click to upload
                                </Button>
                            </Upload>
                    </Form.Item>

                    <Form.Item
                        key="actions"
                        {...formItemLayoutWithOutLabel}
                    >
                        <Button
                            htmlType="submit"
                            loading={saving}
                            type="primary"
                        >
                            {i18n.t('bankStatements:import')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

BankStatementImport.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onToggleVisibleModal: PropTypes.func,
    saving: PropTypes.bool,
    visibleModal: PropTypes.bool
}

BankStatementImport.defaultProps = {
    onSubmit: () => {},
    onToggleVisibleModal: () => {},
    saving: false,
    visibleModal: false,
    hideBankSelect: false,
    additionalUpdate: false
}

export default withTranslation(['bankStatements', 'confirms'])(BankStatementImport)
