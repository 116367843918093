import { all, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../helpers/restRequest'

import actions from './actions'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { paymentDetailSchema, paymentDetailsSchema } from '../schema'

const {
    ORDER_PAYMENT_DETAILS_REQUEST,
    ORDER_PAYMENT_DETAILS_REQUESTING,
    ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS,

    ORDER_PAYMENT_DETAIL_SAVE_REQUEST,
    ORDER_PAYMENT_DETAIL_SAVE,
    ORDER_PAYMENT_DETAIL_SAVE_SUCCESS,

    ORDER_PAYMENT_DETAIL_SET_ERRORS,
    ORDER_PAYMENT_DETAILS_SET_PAGINATION
} = actions

export function *receiveOrderPaymentDetails () {
    yield takeLatest(ORDER_PAYMENT_DETAILS_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_PAYMENT_DETAILS_REQUESTING })
        const { transferStartDate, transferEndDate, haveBankStatement, page } = payload
        const per = 25
        const queryString = [
            `page=${page}`,
            `per=${per}`,
            `transfer_start_date=${transferStartDate}`,
            `transfer_end_date=${transferEndDate}`,
            `have_bank_statement=${haveBankStatement}`
        ].join('&')

        const data = yield call(apiFetch, `/order_payment_details?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, paymentDetailsSchema)
        const total = data.meta.total_pages * per
        yield put({
            type: ORDER_PAYMENT_DETAILS_SET_PAGINATION,
            pagination: {
                current: page,
                total
            }
        })
        yield put({
            type: ORDER_PAYMENT_DETAILS_REQUEST_SUCCESS,
            transferStartDate,
            transferEndDate,
            haveBankStatement,
            ...normalizeData
        })
    })
}

export function *submitOrderPaymentDetail () {
  yield takeLatest(ORDER_PAYMENT_DETAIL_SAVE_REQUEST, function *({ payload }) {
    yield put({ type: ORDER_PAYMENT_DETAIL_SAVE })

    const { id, params, onSuccess, onError } = payload

    try {
      const path = id ? `/order_payment_details/${id}` : `/order_payment_details`
      const method = id ? 'PATCH' : 'POST'

      const body = JSON.stringify({ order_payment_detail: params })
      const data = yield call(apiFetch, path, method, { body })

      if ('error' in data) {
          yield put({
              type: ORDER_PAYMENT_DETAIL_SET_ERRORS,
              formErrors: data.error_description
          })
      } else {
          const formattedData = deserialize(data)

          yield put({
              type: ORDER_PAYMENT_DETAIL_SAVE_SUCCESS,
              ...normalize(formattedData, paymentDetailSchema)
          })

          onSuccess()
      }
    } catch (error) {
        onError(error)
    }
  })
}

export default function *rootSaga () {
    yield all([
        fork(receiveOrderPaymentDetails),
        fork(submitOrderPaymentDetail)
    ])
}
