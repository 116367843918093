export const safeGet = (object, path, defaultValue = null) => {
	return path.split('.').reduce((result, key) => {
		return result && result[key]
	}, object) || defaultValue
}

export const camelize = (text) => {
	return text.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const titleize = (text) => {
	return  (" " + text).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => ' ' + chr.toUpperCase()).trim()
}
