const actions = {
    PRODUCT_GROUP_ITEMS_FETCH_FAIL: 'PRODUCT_GROUP_ITEMS_FETCH_FAIL',
    PRODUCT_GROUP_ITEMS_FETCH_REQUEST: 'PRODUCT_GROUP_ITEMS_FETCH_REQUEST',
    PRODUCT_GROUP_ITEMS_FETCH_SUCCESS: 'PRODUCT_GROUP_ITEMS_FETCH_SUCCESS',
    PRODUCT_GROUP_ITEMS_FETCHING: 'PRODUCT_GROUP_ITEMS_FETCHING',
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL: 'PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL',
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_REQUEST: 'PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_REQUEST',
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS: 'PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS',
    PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES: 'PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES',
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL: 'PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL',
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_REQUEST: 'PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_REQUEST',
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS: 'PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS',
    PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES: 'PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES',

    fetchProductGroupItems: ({ productGroupId, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.PRODUCT_GROUP_ITEMS_FETCH_REQUEST,
            payload: { productGroupId, onSuccess, onError }
        }
    },

    fetchRootItemAttributes: ({ productGroupItemId, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_REQUEST,
            payload: { productGroupItemId, onSuccess, onError }
        }
    },

    fetchItemAttributes: ({ productGroupItemId, parentId, onSuccess = () => {}, onError = () => {} } = {}) => {
        return {
            type: actions.PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_REQUEST,
            payload: { productGroupItemId, parentId, onSuccess, onError }
        }
    },
}

export default actions
