import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { withTranslation } from 'react-i18next'

import { Modal, Form, Input, Button } from 'antd'
import _ from 'lodash'

import AreasSelectContainer from '../../../containers/Areas/AreasSelectContainer'
import companyActions from '../../../redux/customers/companies/actions'

const {
    saveCompany,
    setAddCompanyModal,
    clearCompaniesErrors
} = companyActions

class CompanyFormContainer extends Component {
    constructor() {
        super()
        this.formRef = createRef()
    }

    static propTypes = {
        defaultValue: PropTypes.object,
        onSaveSuccess: PropTypes.func,
        visible: PropTypes.bool.isRequired
    }

    static defaultProps = {
        onSaveSuccess: () => {}
    }

    handleCreate = (values) => {
        const { saveCompany, defaultValue, onSaveSuccess } = this.props
        if (!defaultValue) {
            saveCompany({ company: values, onSuccess: onSaveSuccess })
        } else {
            saveCompany({ company: values, id: defaultValue.id, onSuccess: onSaveSuccess })
        }
    }

    setModal = (value) => {
        const { setAddCompanyModal, clearCompaniesErrors } = this.props
        setAddCompanyModal(!value)
        clearCompaniesErrors()
    }

    render () {
        const { customerCompanies, visible, defaultValue, title, i18n } = this.props
        const errors = customerCompanies.get('formErrors').toJS()
        const company = defaultValue || new Map()
        const provinceValue = _.get(company, 'province.id', null)
        const districtValue = _.get(company, 'district.id', null)

        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
        }

        const formAreaLayout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 }
        }

        return (
            <Modal
                cancelText={i18n.t('companies:cancel')}
                okText={i18n.t('companies:submit')}
                onCancel={() => this.setModal(visible)}
                footer={false}
                title={title}
                visible={visible} destroyOnClose
                width="50%"
            >
                <Form {...formItemLayout} onFinish={this.handleCreate} ref={this.formRef}>
                    <Form.Item label={i18n.t('companies:name')}
                        name={['customer_company', 'name']}
                        initialValue={company.name || null}
                        hasFeedback
                        validateStatus={errors && errors.name && 'error'}
                        help={_.get(errors, 'name', []).join(', ')}>
                            <Input placeholder="" />
                    </Form.Item>
                    <AreasSelectContainer
                        provinceFormItemLayout={formAreaLayout}
                        districtFormItemLayout={formAreaLayout}
                        postalCodeFormItemLayout={{ labelCol: { span: 5 }, wrapperCol: { span: 7 } }}
                        provinceCol={{ md: { span: 12 } }}
                        districtCol={{ md: { span: 12 } }}
                        postalCodeCol={{ md: { span: 24 } }}
                        form={this.formRef}
                        provinceFieldName={['customer_company', 'province_id']}
                        districtFieldName={['customer_company', 'district_id']}
                        postalCodeFieldName={['customer_company', 'postal_code']}
                        provinceValue={provinceValue}
                        districtValue={districtValue}
                        postalCodeValue={company.postal_code}
                    />
                    <Form.Item label={i18n.t('companies:addressDetail')}
                        name={['customer_company', 'address_detail']}
                        initialValue={company.address_detail || null}>
                            <Input.TextArea placeholder="" />
                    </Form.Item>
                    <Form.Item label={i18n.t('companies:taxId')}
                        name={['customer_company', 'tax_id']}
                        initialValue={company.tax_id || null}
                        hasFeedback
                        validateStatus={errors && errors.tax_id && 'error'}
                        help={_.get(errors, 'tax_id', []).join(', ')}>
                            <Input placeholder="" />
                    </Form.Item>

                    <Form.Item label={i18n.t('companies:phoneNumber')}
                        hasFeedback
                        name={['customer_company', 'phone_number']}
                        initialValue={company.phone_number || null}
                        validateStatus={errors && errors.phone_number && 'error'}
                        help={_.get(errors, 'phone_number', []).join(', ')}>
                            <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label={i18n.t('companies:line')}
                        name={['customer_company', 'line_id']}
                        initialValue={company.line_id || null}
                        >
                            <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label={i18n.t('companies:email')}
                        hasFeedback
                        name={['customer_company', 'email']}
                        initialValue={company.email || null}
                        validateStatus={errors && errors.email && 'error'}
                        help={_.get(errors, 'email', []).join(', ')}>
                            <Input placeholder="" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" type="primary">
                            {i18n.t('shared:save')}
                        </Button>
                        <Button style={{ marginLeft: '8px' }} onClick={() => this.setModal(visible)}>
                            {i18n.t('shared:cancel')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.get('customers'),
    customerCompanies: state.get('customerCompanies')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCompany,
        setAddCompanyModal,
        clearCompaniesErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('companies')(CompanyFormContainer)
)
