import styled from 'styled-components'
const LinePinsIndexWrapper = styled.div`
  .filter-input-group {
    display: flex;
    align-items: center;

    .filter-label {
      margin-right: 8px;

      &:after { content: ' : '; }
    }
  }

  .flex-content-start {
    display: flex;
    justify-content: start;
  }

  .flex-content-end {
    display: flex;
    justify-content: end;
  }
`

export default LinePinsIndexWrapper
