const actions = {
    CUSTOMER_ORDERS_ERROR: 'CUSTOMER_ORDERS_ERROR',
    CUSTOMER_ORDERS_FETCH_REQUEST: 'CUSTOMER_ORDERS_FETCH_REQUEST',
    CUSTOMER_ORDERS_FETCHING: 'CUSTOMER_ORDERS_FETCHING',
    CUSTOMER_ORDERS_SET_TOTAL: 'CUSTOMER_ORDERS_SET_TOTAL',
    CUSTOMER_ORDERS_SUCCESS: 'CUSTOMER_ORDERS_SUCCESS',
    CUSTOMERS_CLEAR_ITEMS: 'CUSTOMERS_CLEAR_ITEMS',
    CUSTOMERS_CLEAR_STATE_INFO: 'CUSTOMERS_CLEAR_STATE_INFO',
    CUSTOMERS_FETCH_REQUEST: 'CUSTOMERS_FETCH_REQUEST',
    CUSTOMERS_FETCH_SUCCESS: 'CUSTOMERS_FETCH_SUCCESS',
    CUSTOMERS_FETCH: 'CUSTOMERS_FETCH',
    CUSTOMERS_SAVE_REQUEST: 'CUSTOMERS_SAVE_REQUEST',
    CUSTOMERS_SAVE_SUCCESS: 'CUSTOMERS_SAVE_SUCCESS',
    CUSTOMERS_SEARCH_REQUEST: 'CUSTOMERS_SEARCH_REQUEST',
    CUSTOMERS_SET_ADD_CONTACT_MODAL: 'CUSTOMERS_SET_ADD_CONTACT_MODAL',
    CUSTOMERS_SET_CUSTOMER_TYPES_MODAL: 'CUSTOMERS_SET_CUSTOMER_TYPES_MODAL',
    CUSTOMERS_SET_EMAIL_AMOUNT: 'CUSTOMERS_SET_EMAIL_AMOUNT',
    CUSTOMERS_SET_FORM_ERRORS: 'CUSTOMERS_SET_FORM_ERRORS',
    CUSTOMERS_SET_LINE_AMOUNT: 'CUSTOMERS_SET_LINE_AMOUNT',
    CUSTOMERS_SET_LOCATION_FORM_AMOUNT: 'CUSTOMERS_SET_LOCATION_FORM_AMOUNT',
    CUSTOMERS_SET_OPEN_OCCUPATION_MODAL: 'CUSTOMERS_SET_OPEN_OCCUPATION_MODAL',
    CUSTOMERS_SET_PAGINATION: 'CUSTOMERS_SET_PAGINATION',
    CUSTOMERS_SET_PHONE_AMOUNT: 'CUSTOMERS_SET_PHONE_AMOUNT',
    CUSTOMERS_SET_QUERY: 'CUSTOMERS_SET_QUERY',
    CUSTOMERS_SET_SAVING: 'CUSTOMERS_SET_SAVING',
    CUSTOMERS_SET_SHOW_EMAILS: 'CUSTOMERS_SET_SHOW_EMAILS',
    CUSTOMERS_SET_SHOW_LINES: 'CUSTOMERS_SET_SHOW_LINES',
    CUSTOMERS_SET_SHOW_PHONE_NUMBERS: 'CUSTOMERS_SET_SHOW_PHONE_NUMBERS',
    CUSTOMERS_MERGING_REQUEST: 'CUSTOMERS_MERGING_REQUEST',
    CUSTOMERS_MERGING_SAVE: 'CUSTOMERS_MERGING_SAVE',
    CUSTOMERS_MERGING_SAVE_SUCCESS: 'CUSTOMERS_MERGING_SAVE_SUCCESS',
    CUSTOMERS_SET_WALLET_FORM_MODAL: 'CUSTOMERS_SET_WALLET_FORM_MODAL',

    setLocationFormAmount: (locationFormAmount) => ({
        type: actions.CUSTOMERS_SET_LOCATION_FORM_AMOUNT,
        locationFormAmount
    }),
    setAddContactModal: (value) => ({
        type: actions.CUSTOMERS_SET_ADD_CONTACT_MODAL,
        value
    }),
    search: (query, page = 1) => ({
        type: actions.CUSTOMERS_SEARCH_REQUEST,
        payload: { query, page }
    }),
    fetchCustomer: (id) => ({
        type: actions.CUSTOMERS_FETCH_REQUEST,
        payload: { id }
    }),
    fetchCustomerOrders: (customerId, queryString = '') => {
        return {
            type: actions.CUSTOMER_ORDERS_FETCH_REQUEST,
            payload: { customerId, queryString }
        }
    },
    saveCustomer: (customer) => {
        return {
            type: actions.CUSTOMERS_SAVE_REQUEST,
            payload: { customer }
        }
    },
    setOccupationModal: (value) => ({
        type: actions.CUSTOMERS_SET_OPEN_OCCUPATION_MODAL,
        value
    }),
    setEmailFormAmount: (amount) => ({
        type: actions.CUSTOMERS_SET_EMAIL_AMOUNT,
        amount
    }),
    setLineFormAmount: (amount) => ({
        type: actions.CUSTOMERS_SET_LINE_AMOUNT,
        amount
    }),
    setPhoneFormAmount: (amount) => ({
        type: actions.CUSTOMERS_SET_PHONE_AMOUNT,
        amount
    }),
    clearItems: () => ({
        type: actions.CUSTOMERS_CLEAR_ITEMS
    }),
    setCustomerTypes: (value) => ({
        type: actions.CUSTOMERS_SET_CUSTOMER_TYPES_MODAL,
        value
    }),
    showPhoneNumbers: (value) => ({
        type: actions.CUSTOMERS_SET_SHOW_PHONE_NUMBERS,
        value
    }),
    showEmails: (value) => ({
        type: actions.CUSTOMERS_SET_SHOW_EMAILS,
        value
    }),
    showLines: (value) => ({
        type: actions.CUSTOMERS_SET_SHOW_LINES,
        value
    }),
    clearCustomerInfo: () => ({
        type: actions.CUSTOMERS_CLEAR_STATE_INFO
    }),
    mergeCustomer: (mergeParams) => ({
        type: actions.CUSTOMERS_MERGING_REQUEST,
        payload: { mergeParams }
    }),
    setCustomerWalletFormModal: (visible) => ({
        type: actions.CUSTOMERS_SET_WALLET_FORM_MODAL,
        visible
    }),
}

export default actions
