import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Table, InputNumber } from 'antd'

const SelectedItemList = ({
    itemsList,
    onSelectChange,
    selectedItemIds,
    setItemQuantity,
    i18n
}) => {
    const columns = [
        {
            title: i18n.t('inquiries:number'),
            render: (text, record, index) => index + 1
        },
        {
            title: i18n.t('inquiries:itemDetail.itemName'),
            dataIndex: 'name'
        },
        {
            title: i18n.t('inquiries:itemDetail.quantity'),
            render: (row) => {
                return (
                    <InputNumber defaultValue={1}
                        onChange={(value) => setItemQuantity(value, row.id)} />
                )
            }
        }
    ]

    const rowSelection = {
        selectedRowKeys: selectedItemIds,
        onChange: onSelectChange
    }

    return (
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={itemsList}
            pagination={false}
            rowKey="id"
        />
    )
}

SelectedItemList.propTypes = {
    itemsList: PropTypes.array
}

export default withTranslation(['inquiries'])(SelectedItemList)
