import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'
import { useDispatch } from 'react-redux'

import {Avatar, Modal, Tooltip, Button} from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import TicketMembersForm from './form'
import TicketMembersWrapper from './ticketMembers.style'

import { MEMBER_AVATAR_PROPS } from '../../../constants/tickets'

import notificationActions from '../../../redux/notifications/actions'

const { setNotifications } = notificationActions
import CurrentStaffContainer from "../../../containers/CurrentStaff/CurrentStaffContainer"
const UPDATE_TICKET_MEMBER_ASSIGN_ME = gql`
    mutation UpdateTicketMemberAssignedMe($id: ID!){
        updateTicketMemberAssignedMe(id: $id){
            ticket {
                id
            }
            errors
        }
    }
`

const ASSIGN_TICKET_TO_OSH_MUTATION = gql`
  mutation AssignToOsh($id: ID!) {
    assignToOsh(id: $id) {
      ticket {
        id
        state
        stateLabel
      }
    }
  }
`

const renderOshTeam = (i18n, assignToOsh) => {
    if (assignToOsh) {
        return (
            <Tooltip title={i18n.t('tickets:oshTeam')}>
                <Avatar style={MEMBER_AVATAR_PROPS['team']}>{i18n.t('tickets:oshTeam')}</Avatar>
            </Tooltip>
        )
    }
}

const renderMembers = (i18n, memberStaffs = [], toggleMembersForm) => memberStaffs.map(staff => {
    const { firstName, fullName } = staff || {}

    return (
        <Tooltip title={fullName}>
            <Avatar style={MEMBER_AVATAR_PROPS['member']}>{firstName}</Avatar>
        </Tooltip>
    )
}).concat(
    <Tooltip title={i18n.t('tickets:edit')} className="edit-ticket-members">
        <Avatar style={MEMBER_AVATAR_PROPS['edit']} onClick={toggleMembersForm}><PlusOutlined /></Avatar>
    </Tooltip>
)

const TicketMembers = ({ refetchTicket, ticket, currentStaff }) => {
    const { i18n } = useTranslation('tickets')
    const dispatch = useDispatch()

    const [showMembersForm, setShowMembersForm] = useState(false)

    const [assignToOsh, { loading: savingAssignToOsh }] = useMutation(ASSIGN_TICKET_TO_OSH_MUTATION, {
        onCompleted: ({ assignToOsh }) => {
            if (assignToOsh.errors) {
                dispatch(setNotifications('error', 'error', 'error'))
            } else {
                refetchTicket()
                dispatch(setNotifications('success', 'success', 'success'))
            }
        }
    })

    const [updateTicketMemberAssignedMe, { loading: saving }] = useMutation(UPDATE_TICKET_MEMBER_ASSIGN_ME, {
        variables: { id: ticket.id },
        onCompleted: ({ updateTicketMemberAssignedMe }) => {
            if (updateTicketMemberAssignedMe.errors) {
                dispatch(setNotifications('error', 'error', 'error'))
            } else {
                dispatch(setNotifications('success', 'success', 'success'))
                refetchTicket()
            }
        }
    })

    const toggleMembersForm = () => {
        setShowMembersForm(!showMembersForm)
    }

    const onSaveMembersSuccess = () => {
        refetchTicket()
    }

    const onAssignMe = () => {
        updateTicketMemberAssignedMe()
    }

    const onAssignToOsh = () => {
        assignToOsh({
            variables: { id: ticket.id }
        })
    }

    const renderAssignToOshButton = () => {
        if (ticket.assignToOsh) {
            return (
                <Button
                    ghost
                    className="assign-to-osh-btn"
                    type="primary"
                    disabled={true}>
                    {i18n.t('tickets:alreadyAssignToOsh')}
                </Button>
                )
        } else {
            return (
                <Button
                    ghost
                    type="primary"
                    className="assign-to-osh-btn"
                    loading={savingAssignToOsh}
                    onClick={onAssignToOsh}>
                    {i18n.t('tickets:assignToOsh')}
                </Button>
            )
        }
    }

    const currentSession = currentStaff.toJS()
    const shouldShowAddMemberButton = ticket.memberStaffs.find(staff => staff.id === currentSession.id)

    const renderAssignToMeButton = () => {
        if (shouldShowAddMemberButton) { return null}
        return (
            <Button
                ghost
                type="primary"
                className="assign-to-me-btn"
                loading={saving}
                disabled={ticket.state === 'solved'}
                onClick={onAssignMe}>
                {i18n.t('tickets:selfAssign')}
            </Button>
        )
    }

    return (
        <TicketMembersWrapper>
            <div className='ticket-members-layout'>
                <Avatar.Group>
                    {[renderOshTeam(i18n, ticket.assignToOsh), renderMembers(i18n, ticket.memberStaffs, toggleMembersForm)]}
                </Avatar.Group>
            </div>
            { renderAssignToMeButton() }
            { renderAssignToOshButton() }
            <Modal
                visible={showMembersForm}
                onCancel={toggleMembersForm}
                footer={null}
                destroyOnClose>
                <TicketMembersForm
                    id={ticket.id}
                    memberStaffs={ticket.memberStaffs}
                    onClose={toggleMembersForm}
                    onSuccess={onSaveMembersSuccess}
                />
            </Modal>
        </TicketMembersWrapper>
    )
}

TicketMembers.propTypes = {
    refetchTicket: PropTypes.func.isRequired,
    ticket: PropTypes.object
}

TicketMembers.defaultProps = {
    refetchTicket: () => {}
}

export default CurrentStaffContainer(TicketMembers)
