import React from 'react'
import PropTypes from 'prop-types'
import { Select, Spin } from 'antd'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

const SelectDistrict = ({
    i18n,
    districts,
    loading,
    onChange,
    value
}) => {
    const filterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    return (
        <Select
            allowClear
            className="select-district-component"
            filterOption={filterOption}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            placeholder={i18n.t('districts:placeHolder')}
            showSearch
            style={{ width: '100%' }}
            value={value}
        >
            {districts.map((district, index) => {
                return (
                    <Select.Option
                        key={_.get(district, 'id', `index-${index}`)}
                        value={_.toString(_.get(district, 'id', `index-${index}`))}
                    >
                        {_.get(district, `name_${i18n.language}`, '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectDistrict.propTypes = {
    districts: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string
}

SelectDistrict.defaultProps = {
    districts: [],
    loading: false,
    onChange: () => {},
    value: undefined
}

export default withTranslation(['districts'])(SelectDistrict)
