import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Input, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import ProfitSharingWrapper from './profitSharing.style'

const ProfitSharingForm = ({
    i18n,
    setFileList,
    bookBankFileList,
    idCardFileList,
    form,
    onSubmitForm,
    profitSharing,
    saving,
    toggleForm
}) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    const normFile = e => {
        let fileList = [...e.fileList]
        fileList = fileList.slice(-1)
        return fileList
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">{i18n.t('shared:upload')}</div>
        </div>
    )

    return (
        <ProfitSharingWrapper>
            <h3>{i18n.t('profitSharing:profitSharingForm')}</h3>
            <Form onFinish={handleSubmitForm} {...formItemLayout}>
                <Form.Item label={i18n.t('profitSharing:idCard')}
                    name='id_card_images'
                    getValueFromEvent={normFile}>
                        <Upload.Dragger
                            listType="picture-card"
                            showUploadList={{ showPreviewIcon: false }}
                            onChange={({ fileList }) => setFileList({ field: 'idCard', fileList })}
                            beforeUpload={() => false}
                            fileList={idCardFileList}
                            multiple>
                            {uploadButton}
                        </Upload.Dragger>
                </Form.Item>

                <Form.Item label={i18n.t('profitSharing:bookBank')}
                    name='book_bank_images'
                    getValueFromEvent={normFile}>
                        <Upload.Dragger
                            listType="picture-card"
                            showUploadList={{ showPreviewIcon: false }}
                            onChange={({ fileList }) => setFileList({ field: 'bookBank', fileList })}
                            beforeUpload={() => false}
                            fileList={bookBankFileList}
                            multiple>
                            {uploadButton}
                        </Upload.Dragger>
                </Form.Item>

                <Form.Item label={i18n.t('profitSharing:detail')}
                    name='detail'
                    initialValue={profitSharing.detail}>
                        <Input.TextArea />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" loading={saving} style={{ marginRight: '12px' }}>
                        {i18n.t('shared:save')}
                    </Button>
                    <Button onClick={toggleForm}>{i18n.t('shared:close')}</Button>
                </Form.Item>
            </Form>
        </ProfitSharingWrapper>
    )
}

ProfitSharingForm.propTypes = {
    setFileList: PropTypes.func.isRequired,
    bookBankFileList: PropTypes.array,
    idCardFileList: PropTypes.array,
    onSubmitForm: PropTypes.func.isRequired,
    profitSharing: PropTypes.object,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func
}

ProfitSharingForm.defaultProps = {
    bookBankFileList: [],
    idCardFileList: [],
    profitSharing: {},
    saving: false,
    toggleForm: () => {}
}

export default withTranslation(['shared', 'profitSharing'])(ProfitSharingForm)
