import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

import actions from '../../redux/projects/actions'
import { Divider, Input, Select } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const { fetchCompanyProjects, fetchCompanyProject, saveCompanyProject } = actions

const renderCompanyProjectOptions = projects => {
  return projects.map((project) => {
    return (
      <Select.Option key={`project-${project.id}`} value={project.id}>
        {project.name}
      </Select.Option>
    )
  })
}

const CompanyProjectSelector = ({
  value,
  ...rest
}) => {
  const { i18n } = useTranslation('projects')
  const dispatch = useDispatch()
  const companyProjectEntities = useSelector(state => state.getIn(['Entities', 'companyProjects'], new Map()))
  const items = useSelector(state => state.getIn(['projects', 'items'], new List()))

  const [inputText, setInputText] = useState('')

  useEffect(() => {
    if (value) {
      dispatch(fetchCompanyProject(value))
    }
  }, [])

  const handleCreateCompanyProject = () => {
    dispatch(saveCompanyProject({ params: { name: inputText } }))
  }

  const handleInputValueChange = event => {
    const text = event.target.value

    setInputText(text)
  }

  const handleSearchProjects = (query = '') => {
    dispatch(fetchCompanyProjects({ query }))
  }

  const projects = items.map((projectId) => {
    return companyProjectEntities.get(projectId) || new Map()
  }).toJS()

  return (
    <Select
      filterOption={false}
      onSearch={handleSearchProjects}
      placeholder={i18n.t('projects:selectProject')}
      showSearch
      value={value}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Fragment>
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input onChange={handleInputValueChange} style={{ flex: 'auto' }} />
              <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={handleCreateCompanyProject}>
                <PlusOutlined /> {i18n.t('shared:add')} {inputText}
              </a>
            </div>
          </Fragment>
        </div>
      )}
      {...rest}>
      {renderCompanyProjectOptions(projects)}
  </Select>
  )
}

CompanyProjectSelector.propTypes = {
  value: PropTypes.string
}

CompanyProjectSelector.defaultProps = {}

export default CompanyProjectSelector
