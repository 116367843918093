import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { List, Map } from 'immutable'
import {Row, Col, Button, Input, Form, Select, Spin} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import { SearchOutlined } from '@ant-design/icons'

import ItemsList from '../../components/megaHomeItems/ItemsList'
import SelectedItemListContainer from './SelectedItemListContainer'

import inquiryActions from '../../redux/inquiry/actions'
import supplierActions from '../../redux/suppliers/actions'
import orderActions from '../../redux/orders/actions'

const { fetchSupplierMegaHomeItems } = supplierActions
const { fetchLineItemIds } = orderActions
const {
    setSelectedMegaHomeItems,
    setMegaHomeItemQuantity,
    saveOrderForm,
    setAddMegaHomeItemForm
} = inquiryActions

const SUPPLIER_CATEGORY_QUERY = gql`
    query SearchSupplierCategoryQuery($query: String, $supplierId: ID!) {
        searchSupplierCategoryQuery(query: $query, supplierId: $supplierId) {
          id
          name
        }
    }
`

const SUPPLIER_ID = 1600

const MegaHomeItemFormContainer = ({ i18n, orderId }) => {
    const [supplierCategoryId, setSupplierCategoryQuery] = useState('')
    const [supplierItemQuery, setSupplierItemQuery] = useState('')

    const dispatch = useDispatch()
    const supplierItemEntities = useSelector(state => state.getIn(['Entities', 'supplierItems']))
    const suppliers = useSelector(state => state.get('suppliers'))
    const inquiry = useSelector(state => state.get('inquiry'))
    const orders = useSelector(state => state.get('orders'))

    useEffect(() => {
        dispatch(fetchSupplierMegaHomeItems())
        dispatch(fetchLineItemIds(orderId))
    }, [])

    const[fetchSupplierCategory, { data: supplierCategories, loading: supplierCategoriesLoading  }] = useLazyQuery(SUPPLIER_CATEGORY_QUERY)

    const handleSubmitItem = () => {
        const lineItemidsList = orders.get('lineItemidsList') || []

        const newLineItems = inquiry.get('selectedMegaHomeItemIds').map((itemId, index) => {
            const itemData = supplierItemEntities.get(itemId)
            const itemsQtySelected = inquiry.get('megaHomeItemsQtySelected')
            const itemQty = itemsQtySelected.get(itemData.get('id')) || new Map()

            return ({
                name_th: itemData.get('name'),
                quantity: _.get(itemQty, 'quantity', 1),
                single_price: itemData.get('price'),
                cost: itemData.get('cost_of_goods_sold'),
                additional_info: itemData.get('item_code'),
                unit: itemData.get('unit'),
                weight: itemData.get('weight')
            })
        })

        const params = {
            line_items_attributes: [...newLineItems.toJS(), ...lineItemidsList]
        }

        dispatch(saveOrderForm(
            orderId,
            params,
            { onSuccess: () => {
                    window.location.replace(`/inquiries/${orderId}`)
                    dispatch(setAddMegaHomeItemForm(!inquiry.get('showMegaHomeItemForm')))
                    dispatch(setSelectedMegaHomeItems(null))
                }}
        ))
    }

    const handleItemPageChange = (pagination) => {

        dispatch(fetchSupplierMegaHomeItems({
                page: pagination.current,
                supplierCategoryId: supplierCategoryId,
                query: supplierItemQuery
            })
        )
    }

    const getItems = () => {
        return suppliers.get('supplierItemIds', new List()).map((id) => {
            return supplierItemEntities.get(_.toString(id))
        })
    }

    const handleSelectItem = (itemIds) => {

        dispatch(setSelectedMegaHomeItems(itemIds))
    }

    const onSearchSupplierCategory = (value) => {
        fetchSupplierCategory({
            variables: { query: value, supplierId: SUPPLIER_ID }
        })
    }

    const supplierCategoryOptions = () => {
        return ((supplierCategories && supplierCategories.searchSupplierCategoryQuery) || []).map((supplierCategory) => {
            return (
                <Select.Option value={supplierCategory.id}>{supplierCategory.name}</Select.Option>
            )
        })
    }

    const onSelectSupplierCategory = (value) => {
        setSupplierCategoryQuery(value)

        dispatch(fetchSupplierMegaHomeItems({ supplierCategoryId: value, query: supplierItemQuery}))
    }

    const onSearchSupplierItem = (value) => {
        setSupplierItemQuery(value)
    }

    const onFetchSupplierItem = () => {
        dispatch(fetchSupplierMegaHomeItems({ supplierCategoryId: supplierCategoryId, query: supplierItemQuery}))
    }

    return (
        <Row>
            <Col span={10} style={{ paddingRight: '12px' }}>
                <SelectedItemListContainer
                    setSelectedItem={setSelectedMegaHomeItems}
                    onSubmitAddItems={handleSubmitItem}
                    itemsQtySelected={inquiry.get('megaHomeItemsQtySelected')}
                    setItemQuantity={setMegaHomeItemQuantity} />
                <Button onClick={handleSubmitItem} type='primary'
                        style={{ float: 'right', marginTop: '2rem' }}
                        loading={inquiry.get('saveOrderLoading') || orders.get('lineItemidsLoading')}>
                    {i18n.t('inquiries:addItem')}
                </Button>
            </Col>
            <Col span={14} style={{ borderLeft: '1px solid #e1e1e1', paddingLeft: '14px' }}>
                <h2>{i18n.t('inquiries:megaHomeItems')}</h2>
                <div style={{ marginBottom: '12px'}}>
                    <Form>
                        <Select
                            style={{ width: '40%', marginRight: '20px' }}
                            showSearch
                            allowClear
                            placeholder={i18n.t('inquiries:searchWithCategory')}
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            loading={supplierCategoriesLoading}
                            notFoundContent={supplierCategoriesLoading ? <Spin size="small" /> : null}
                            onChange={onSelectSupplierCategory}
                            onSearch={onSearchSupplierCategory}
                        >
                            {supplierCategoryOptions()}
                        </Select>
                        <Input
                            placeholder={i18n.t('inquiries:searchWithName')}
                            style={{ width: '40%', marginRight: '20px' }}
                            onChange={(e) => onSearchSupplierItem(e.target.value)}
                        />
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}
                                onClick={onFetchSupplierItem}>
                        </Button>
                    </Form>
                </div>
                <ItemsList
                    selectedItemIds={inquiry.get('selectedMegaHomeItemIds').toJS()}
                    onSelectChange={handleSelectItem}
                    loading={suppliers.get('supplierItemsLoading')}
                    onPageChange={handleItemPageChange}
                    pagination={{
                        ...suppliers.get('supplierItemsPagination').toJS(),
                        showSizeChanger: false
                    }}
                    items={getItems().toJS()} />
            </Col>
        </Row>
    )
}

export default withTranslation('inquiries')(MegaHomeItemFormContainer)
