import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Row, Col, Select } from 'antd'
import _ from 'lodash'

const RangePickerWithDateType = ({
    dateTypes,
    dateTypeSelectValue,
    onDateTypeSelectChange,
    onRangePickerChange,
    rangePickerValue
}) => {
    return (
        <Row>
            <Col span={10}>
                <Select
                    onChange={onDateTypeSelectChange}
                    style={{ width: '100%' }}
                    value={dateTypeSelectValue}
                >
                    {dateTypes.map((dateType, index) => {
                        return (
                            <Select.Option
                                key={`index-${index}`}
                                value={_.get(dateType, 'value', `index-${index}`)}
                            >
                                {_.get(dateType, 'label', '')}
                            </Select.Option>
                        )
                    })}
                </Select>
            </Col>

            <Col span={14}>
                <DatePicker.RangePicker
                    allowClear
                    onChange={onRangePickerChange}
                    value={rangePickerValue}
                />
            </Col>
        </Row>
    )
}

RangePickerWithDateType.propTypes = {
    // dateTypes is an array of object that has shape look like
    // {
    //  label: PropTypes.string,
    //  value: PropTypes.string
    // }
    dateTypes: PropTypes.array,
    dateTypeSelectValue: PropTypes.string,
    onDateTypeSelectChange: PropTypes.func,
    onRangePickerChange: PropTypes.func,
    rangePickerValue: PropTypes.array
}

RangePickerWithDateType.defaultProps = {
    dateTypes: [],
    dateTypeSelectValue: undefined,
    onDateTypeSelectChange: () => {},
    onRangePickerChange: () => {},
    rangePickerValue: []
}

export default RangePickerWithDateType
