import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import SalesSummaryIndexContainer from './SalesSummaryIndexContainer'

class SaleSummaryIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Sales Summary | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/sales_summary', name: 'Sales summary' }
                    ]}>
                    <ContentLayout>
                        <SalesSummaryIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default SaleSummaryIndexPage
