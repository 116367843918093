import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import {Button, Form, Input, Select, Spin, Upload} from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 }
    }
}

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 16,
            offset: 8
        }
    }
}

const JournalEntryFormatForm = ({
    errors,
    form,
    i18n,
    loading,
    onSubmitForm,
    saving,
    toggleForm,
    journalEntryFormat,
    setFileList,
    fileList,
    journalEntryFormatEnums,
    formatEnumLoading,
    journalEntryFormatTriggerDates,
    triggerDateLoading
}) => {
    const normFile = e => {
        let fileList = [...e.fileList]
        fileList = fileList.slice(-1)
        return fileList
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    const renderSelectTriggerTypeOptions = journalEntryFormatEnums.map(({ id, value }) => {
        return <Select.Option key={id} value={id}>{value}</Select.Option>
    })

    const renderSelectTriggerDatesOptions = journalEntryFormatTriggerDates.map(({ id, value }) => {
        return <Select.Option key={id} value={id}>{value}</Select.Option>
    })

    if (loading || formatEnumLoading || triggerDateLoading) {
        return (
            <Spin />
        )
    }

    return (
        <div>
            <h2>{i18n.t('journalEntryFormats:journalEntryFormat')}</h2>
                <Form onFinish={handleSubmitForm} {...formItemLayout}>
                    <Form.Item
                        name='trigger_type'
                        initialValue={_.get(journalEntryFormat, 'triggerType', null)}
                        label={i18n.t('journalEntryFormats:triggerType')}
                        validateStatus={_.get(errors, 'triggerType') && 'error'}
                        help={_.get(errors, 'triggerType')}
                    >
                            <Select>
                                {renderSelectTriggerTypeOptions}
                            </Select>
                    </Form.Item>

                    <Form.Item
                        name='trigger_date'
                        initialValue={_.get(journalEntryFormat, 'triggerDate', null)}
                        label={i18n.t('journalEntryFormats:triggerDate')}
                        validateStatus={_.get(errors, 'triggerDate') && 'error'}
                        help={_.get(errors, 'triggerDate')}
                    >
                        <Select>
                            {renderSelectTriggerDatesOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='description'
                        initialValue={_.get(journalEntryFormat, 'description', null)}
                        label={i18n.t('journalEntryFormats:formatDescription')}
                        validateStatus={_.get(errors, 'description') && 'error'}
                        help={_.get(errors, 'description')}
                    >
                            <Input />
                    </Form.Item>

                    <Form.Item label={i18n.t('journalEntryFormats:image')}
                        name='images'
                        getValueFromEvent={normFile}>
                            <Upload.Dragger
                                listType="picture-card"
                                showUploadList={{ showPreviewIcon: false }}
                                onChange={({ fileList }) => setFileList({ fileList })}
                                beforeUpload={() => false}
                                fileList={fileList}
                                multiple>
                                <div>
                                    <PlusOutlined />
                                    <div className="ant-upload-text">{i18n.t('shared:upload')}</div>
                                </div>
                            </Upload.Dragger>
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                        <Button htmlType="button"
                          style={{ marginLeft: '12px'}}
                          onClick={toggleForm}>
                            {i18n.t('shared:close')}
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )
}

JournalEntryFormatForm.propTypes = {
    errors: PropTypes.object,
    loading: PropTypes.bool,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired,
    journalEntryFormat: PropTypes.object
}

JournalEntryFormatForm.defaultProps = {}

export default withTranslation('journalEntryFormats')(JournalEntryFormatForm)
