import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'
import SupplierOverTransferAccountList from '../../components/Suppliers/SupplierOverTransferAccountList'
import actions from '../../redux/suppliers/overTransferItems/actions'
import notificationActions from '../../redux/notifications/actions'
import supplierActions from '../../redux/suppliers/actions'

const { deleteSupplierSupplierOverTransferItem } = actions
const { setNotifications } = notificationActions
const { fetchSupplier, setShowSupplierOverTransferForm, setShowSupplierInfoForm } = supplierActions

class SupplierOverTransferAccountListContainer extends Component {
    static propTypes = {
        // Props from another component
        dataSourceFrom: PropTypes.string.isRequired,
        onPageChange: PropTypes.func,
        orderId: PropTypes.string,
        pagination: PropTypes.object,
        supplierId: PropTypes.string,

        // Inner Props
        deleteSupplierSupplierOverTransferItem: PropTypes.func.isRequired,
        fetchSupplier: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        setShowSupplierOverTransferForm: PropTypes.func.isRequired,
        supplierEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferAccountEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferDestinationEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferItemEntities: ImmutablePropTypes.map.isRequired,
        supplierOverTransferItems: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    constructor (props) {
        super(props)

        this.formData = {}
    }

    componentDidMount () {
        const { fetchSupplier, supplierId } = this.props

        if (supplierId) { fetchSupplier({ id: supplierId }) }
    }

    getSupplier = (id) => {
        const { supplierEntities } = this.props

        return supplierEntities.get(id) || new Map()
    }

    getSupplierOrder = (id) => {
        const { supplierOrderEntities } = this.props

        return supplierOrderEntities.get(id) || new Map()
    }

    getSupplierOverTransferDestination = (id) => {
        const { supplierOverTransferDestinationEntities } = this.props
        const supplierOverTransferDestination = supplierOverTransferDestinationEntities.get(id) || new Map()
        const supplierOrderId = supplierOverTransferDestination.get('supplier_order')

        return new Map({
            ...supplierOverTransferDestination.toJS(),
            supplier_order: this.getSupplierOrder(supplierOrderId).toJS()
        })
    }

    getSupplierOverTransferItems () {
        const { supplierOverTransferItemEntities, supplierOverTransferItems } = this.props
        return supplierOverTransferItems.get('items', new List()).map((id) => {
            const supplierOverTransferItem = supplierOverTransferItemEntities.get(id) || new Map()
            const destinationId = supplierOverTransferItem.get('supplier_over_transfer_destination')
            const supplierOrderId = supplierOverTransferItem.get('supplier_order')

            return new Map({
                ...supplierOverTransferItem.toJS(),
                supplier_over_transfer_destination: this.getSupplierOverTransferDestination(destinationId).toJS(),
                supplier_order: this.getSupplierOrder(supplierOrderId).toJS()
            })
        }).toJS()
    }

    getSupplierOverTransferAccounts () {
        const { supplierId, supplierOverTransferAccountEntities } = this.props
        const supplierOverTransferItems = this.getSupplierOverTransferItems()
        const supplierOverAccounts = []

        if (_.isEmpty(supplierOverTransferItems)) {
            return [{
                supplier: this.getSupplier(supplierId).toJS()
            }]
        }

        _.mapKeys(_.groupBy(supplierOverTransferItems, 'supplier_over_transfer_account'), (value, key) => {
            const supplierOverAccount = supplierOverTransferAccountEntities.get(key) || new Map()

            value['supplier_order'] = this.getSupplierOrder(value['supplier_order']).toJS()

            supplierOverAccounts.push({
                ...supplierOverAccount.toJS(),
                supplier: this.getSupplier(supplierOverAccount.get('supplier')).toJS(),
                supplier_over_transfer_items: value
            })
        })

        return supplierOverAccounts
    }

    onDeleteClick = ({ supplierId, id } = {}) => {
        const { deleteSupplierSupplierOverTransferItem, setNotifications } = this.props

        deleteSupplierSupplierOverTransferItem({
            id,
            supplierId,
            onSuccess: () => { setNotifications('success', 'success', 'success') },
            onError: () => { setNotifications('error', 'error', 'error') }
        })
    }

    toggleVisibleForm = ({ orderId, supplierId, supplierOverTransactionItem } = {}) => {
        const { suppliers, setShowSupplierOverTransferForm } = this.props
        const visible = !suppliers.get('showSupplierOverTransferForm', true)
        this.formData = {}

        setShowSupplierOverTransferForm(visible)

        if (!visible) { return null }

        this.formData = {
            id: _.get(supplierOverTransactionItem, 'id', null),
            orderId,
            supplierId
        }
    }

    toggleSupplierInfoForm = () => {
        const { suppliers, setShowSupplierInfoForm } = this.props
        const visible = suppliers.get('showSupplierInfoForm', true)

        setShowSupplierInfoForm(!visible)
    }

    render () {
        const {
            dataSourceFrom,
            supplierOverTransferItems,
            onPageChange,
            orderId,
            pagination,
            suppliers,
            supplierId
        } = this.props

        return (
            <SupplierOverTransferAccountList
                dataSourceFrom={dataSourceFrom}
                formData={this.formData}
                loading={supplierOverTransferItems.get('loading')}
                onDeleteClick={this.onDeleteClick}
                onPageChange={onPageChange}
                orderId={orderId}
                pagination={pagination}
                supplierId={supplierId}
                supplierOverTransferAccounts={this.getSupplierOverTransferAccounts()}
                toggleVisibleForm={this.toggleVisibleForm}
                visibleForm={suppliers.get('showSupplierOverTransferForm')}
                toggleInfoForm={this.toggleSupplierInfoForm}
                visibleInfoForm={suppliers.get('showSupplierInfoForm')}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
    supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts']),
    supplierOverTransferDestinationEntities: state.getIn(['Entities', 'supplierOverTransferDestinations']),
    supplierOverTransferItemEntities: state.getIn(['Entities', 'supplierOverTransferItems']),
    supplierOverTransferItems: state.get('supplierOverTransferItems'),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteSupplierSupplierOverTransferItem,
        fetchSupplier,
        setNotifications,
        setShowSupplierOverTransferForm,
        setShowSupplierInfoForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierOverTransferAccountListContainer)
