
import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const ORDER_MEMBER_ENUM_QUERY = gql`
    query OrderMemberRoles {
        orderMemberRoles {
            label
            value
        }
    }
`

const renderOptions = (options = [], isForm) => {
    return options.map(({ label, value }) => {
        if (isForm && !value) return null

        return <Select.Option value={value}>{label}</Select.Option>
    })
}

const OrderMemberEnumSelector = ({ isForm, ...restProps }) => {
    const { loading, data } = useQuery(ORDER_MEMBER_ENUM_QUERY)

    const handleOnSelect = (val) => {
        const { setCurrentSelectRole } = restProps
        setCurrentSelectRole(val)
    }

    return (
        <Select loading={loading} {...restProps} onSelect={(val) => { handleOnSelect(val)}}>
            {renderOptions(data && data.orderMemberRoles, isForm)}
        </Select>
    )
}

OrderMemberEnumSelector.propTypes = {
    isForm: PropTypes.bool
}

OrderMemberEnumSelector.defaultProps = {
    isForm: false,
    setCurrentSelectRole: () => {}
}

export default OrderMemberEnumSelector
