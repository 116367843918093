import { Map, List } from 'immutable'

import { mergeDeepReplaceArrays } from '../entities/reducers'
import actions from './actions'

const {
    RESERVATION_TRUCK_TYPES_FETCH_FAIL,
    RESERVATION_TRUCK_TYPES_FETCH_SUCCESS,
    RESERVATION_TRUCK_TYPES_FETCHING,
    RESERVATION_TRUCK_TYPES_SET_SELECTED
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    query: '',
    reservationTruckTypeSelected: undefined
})

export default function reservationTruckTypeReducers (state = initState, action) {
    const result = action.result
    const items = state.get('items')

    switch (action.type) {
        case RESERVATION_TRUCK_TYPES_FETCH_FAIL:
            return state.merge({ loading: false })
        case RESERVATION_TRUCK_TYPES_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                items: result,
                loading: false,
                query: action.query
            })
        case RESERVATION_TRUCK_TYPES_FETCHING:
            return state.merge({ loading: true })
        case RESERVATION_TRUCK_TYPES_SET_SELECTED:
            return state.merge({
                reservationTruckTypeSelected: action.reservationTruckTypeSelected
            })
        default:
            return state
    }
}
