import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import ProjectProfitSharingList from '../../components/projects/projectProfitSharingList'

import actions from '../../redux/projects/profitSharings/actions'

const { fetchCompanyProjectProfitSharings, setProfitSharingFormModal } = actions

class ProjectProfitSharingListContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        const { fetchCompanyProjectProfitSharings, projectId } = this.props

        fetchCompanyProjectProfitSharings({ id: projectId })
    }

    getProfitSharings = () => {
        const { projectProfitSharings, profitSharingEntities } = this.props

        return projectProfitSharings.get('items', new List()).map((profitSharingId) => {
            return profitSharingEntities.get(profitSharingId) || new Map()
        })
    }

    handlePageChange = (pagination) => {
        const { fetchCompanyProjectProfitSharings, projectId } = this.props

        fetchCompanyProjectProfitSharings({ id: projectId, page: pagination.current })
    }

    toggleProfitSharingForm = ({ id = null } = {}) => {
        const { projectProfitSharings, setProfitSharingFormModal } = this.props

        setProfitSharingFormModal(!projectProfitSharings.get('showProfitSharingForm'), id)
    }

    render () {
        const { projectProfitSharings } = this.props

        return (
            <ProjectProfitSharingList
                activeProfitSharing={projectProfitSharings.get('profitSharingIdSelected')}
                loading={projectProfitSharings.get('loading')}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...projectProfitSharings.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                profitSharings={this.getProfitSharings().toJS()}
                showProfitSharingForm={projectProfitSharings.get('showProfitSharingForm')}
                toggleProfitSharingForm={this.toggleProfitSharingForm}
            />
        )
    }
}


const mapStateToProps = (state) => ({
    projectProfitSharings: state.get('projectProfitSharings'),
    profitSharingEntities: state.getIn(['Entities', 'profitSharings'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCompanyProjectProfitSharings,
        setProfitSharingFormModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProfitSharingListContainer)
