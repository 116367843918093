import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import { Table, Form, Input, Select } from 'antd'

import _ from 'lodash'

import LineItemWrapper from '../inquiry/lineItems.style'
import { useTranslation } from 'react-i18next'

const EditableContext = React.createContext()

const SelectTransactionType = ({
    onChange,
    value,
    onSave
}) => {
    const { i18n } = useTranslation('inquiries')

    return (
        <Select autoFocus
            value={value}
            onBlur={onSave}
            onChange={(value) => {
                onChange(value)
                onSave()
            }}
            style={{ width: "130px" }}>
            <Select.Option value="transfer_back">
                {i18n.t('inquiries:transferBack.transactionTypes.transferBack')}
            </Select.Option>
            <Select.Option value="extra_payment">
                {i18n.t('inquiries:transferBack.transactionTypes.extraPayment')}
            </Select.Option>
        </Select>
    )
}

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
            <tr {...props} />
            </EditableContext.Provider>
        </Form>
    )
}

class EditableCell extends React.Component {
    constructor () {
        super()

        this.inputRef = createRef()
        this.formRef = createRef()
    }

    state = {
        editing: false
    }

    toggleEdit = () => {
        const editing = !this.state.editing
        this.setState({ editing }, () => {
            if (editing && this.inputRef.current) {
                this.inputRef.current.focus()
            }
        })
    }

    save = (e) => {
        const { record, handleSave } = this.props
        this.formRef.current.validateFields().then(values => {

            this.toggleEdit()
            handleSave({ ...record, ...values })
        })
    }

    renderInput = () => {
        const { dataIndex } = this.props

        if (dataIndex === 'transaction_type') {
            return <SelectTransactionType onSave={this.save} />
        }

        return (
            <Input
                ref={this.inputRef}
                onPressEnter={this.save}
                onBlur={this.save}
            />
        )
    }

    renderCell = form => {
        this.form = form
        const { children, dataIndex, record, title } = this.props
        const { editing } = this.state

        return editing ? (
            <Form ref={this.formRef} layout='vertical'>
                <Form.Item style={{ margin: 0 }} name={dataIndex}
                    initialValue={record[dataIndex]}>
                        {this.renderInput()}
                </Form.Item>
            </Form>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        )
    }

    render () {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props

        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        )
    }
}

const SelectLineItems = ({
    columns: propsColumns,
    handleSave,
    isDisabled,
    lineItems,
    loading,
    onRowSelectionChange,
    selectedRowKeys
}) => {
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    }

    const columns = propsColumns.map((col) => {
        if (isDisabled|| !col.editable) {
            return col
        }

        if (col.dataIndex === 'quantity') {
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: _.get(record, 'available_quantity', '') !== 0,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: handleSave
                })
            }
        }

        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        }
    })

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: onRowSelectionChange,
        getCheckboxProps: record => ({
            disabled: _.get(record, 'available_quantity', '') === 0 || isDisabled
        })
    }

    return (
        <LineItemWrapper>
            <Table
                columns={columns}
                components={components}
                dataSource={lineItems}
                loading={loading}
                pagination={false}
                rowClassName={() => 'editable-row'}
                rowKey="id"
                rowSelection={rowSelection}
            />
        </LineItemWrapper>
    )
}

SelectLineItems.propTypes = {
    isDisabled: PropTypes.bool,
    lineItems: PropTypes.array,
    loading: PropTypes.bool,
    onRowSelectionChange: PropTypes.func
}

SelectLineItems.defaultProps = {
    isDisabled: false,
    lineItems: [],
    loading: false,
    onRowSelectionChange: () => {}
}

export default SelectLineItems
