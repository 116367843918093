import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { List, Map } from 'immutable'
import _ from 'lodash'

import SelectLineItems from '../SelectLineItems'

import inquiryActions from '../../redux/inquiry/actions'

const { fetchInquiryLineItems } = inquiryActions

const SelectOrderTransferBackItems = ({
    isDisabled,
    onChange,
    orderId,
    dataValue
}) => {
    const { i18n } = useTranslation(['inquiries'])
    const dispatch = useDispatch()
    const inquiry = useSelector(state => state.get('inquiry'))
    const lineItemEntities = useSelector(state => state.getIn(['Entities', 'lineItems']))

    const getDefaultLineItems = () => {
        return dataValue.map((item) => {
            return {
                id: _.toString(_.get(item, 'line_item_id', '')),
                item_type: 'LineItem',
                name: _.get(item, 'line_item_name', ''),
                transaction_type: _.get(item, 'transaction_type', ''),
                quantity: _.get(item, 'quantity', 0),
                unit: _.get(item, 'unit', ''),
                single_price: _.get(item, 'single_price.display', '0')
            }
        })
    }

    const getLineItems = () => {
        return inquiry.get('lineItems', new List()).map((lineItemId) => {
            const lineItem = lineItemEntities.get(_.toString(lineItemId), new Map())
            return new Map({
                ...lineItem.toJS(),
                transaction_type: lineItem.get('is_added_after_1688') ? 'extra_payment' : 'transfer_back'
            })
        })
    }

    const handleFetchInitialData = ({ onSuccess } = {}) => {
        dispatch(fetchInquiryLineItems(orderId, 1, true, { onSuccess }))
    }

    const onSelectLineItemsChange = (selectedLineItems) => {
        const selectedItems = selectedLineItems.map((lineItem) => {
            const item = _.find(dataValue, (item) => {
                return _.toString(_.get(item, 'line_item_id', '')) === lineItem.id
            })

            return {
                id: _.get(item, 'id', ''),
                line_item_id: _.get(lineItem, 'id', ''),
                item_type: 'LineItem',
                name: _.get(lineItem, 'name', ''),
                transaction_type: _.get(lineItem, 'transaction_type', ''),
                quantity: _.get(lineItem, 'quantity', 0),
                unit: _.get(lineItem, 'unit', ''),
                single_price: _.get(lineItem, 'single_price') || '0',
                _destroy: _.get(lineItem, '_destroy', false)
            }
        })

        onChange(selectedItems)
    }

    const columns = [{
        title: i18n.t('inquiries:detail'),
        dataIndex: 'name'
    }, {
        title: i18n.t('inquiries:transferBack.transactionType'),
        dataIndex: 'transaction_type',
        editable: true,
        render: (dataIndex) => i18n.t(`inquiries:transferBack.transactionTypes.${_.camelCase(dataIndex)}`)
    }, {
        title: i18n.t('inquiries:quantity'),
        dataIndex: 'quantity',
        editable: true
    }, {
        title: i18n.t('inquiries:unit'),
        dataIndex: 'unit'
    }, {
        title: i18n.t('inquiries:unitPrice'),
        dataIndex: 'single_price',
        editable: true
    }]

    return (
        <SelectLineItems
            columns={columns}
            dataSource={getLineItems().toJS()}
            loading={inquiry.get('lineItemsLoading', false)}
            onChange={onSelectLineItemsChange}
            onFetch={handleFetchInitialData}
            orderId={orderId}
            value={getDefaultLineItems()}
            isDisabled={isDisabled}
        />
    )
}

SelectOrderTransferBackItems.propTypes = {
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    orderId: PropTypes.string.isRequired,
    value: PropTypes.array
}

export default SelectOrderTransferBackItems
