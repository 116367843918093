import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING,
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS,
    SET_SELECTED_PAYMENT_DETAIL_INSTALLMENT_FORM,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS,
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL,
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    installmentIdSelected: null,
    formErrors: new Map()
})

export default function OrderPaymentDetailInstallmentsReducer (state = initState, action) {
    const items = state.get('items', new List())
    const result = action.result

    switch (action.type) {
        case ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING:
            return state.merge({ loading: true })
        case ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case SET_SELECTED_PAYMENT_DETAIL_INSTALLMENT_FORM:
            return state.merge({
                installmentIdSelected: action.id
            })
        case ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING:
            return state.merge({
                saving: true
            })
        case ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS:
        return state.merge({
            saving: false,
            items: new List(items.includes(result) ? items : items.push(result))
        })
        case ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL:
        return state.merge({
            formErrors: new Map(action.formErrors),
            saving: false
        })
        default:
            return state
    }
}
