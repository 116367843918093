const actions = {
    WALLET_SUMMARIES_CUSTOMERS_FETCH_REQUEST: 'WALLET_SUMMARIES_CUSTOMERS_FETCH_REQUEST',
    WALLET_SUMMARIES_CUSTOMERS_FETCH: 'WALLET_SUMMARIES_CUSTOMERS_FETCH',
    WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS: 'WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS',

    WALLET_SUMMARIES_WALLETS_FETCH_REQUEST: 'WALLET_SUMMARIES_WALLETS_FETCH_REQUEST',
    WALLET_SUMMARIES_WALLETS_FETCH: 'WALLET_SUMMARIES_WALLETS_FETCH',
    WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS: 'WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS',

    WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION: 'WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION',
    WALLET_SUMMARIES_SET_CUSTOMER_QUERY: 'WALLET_SUMMARIES_SET_CUSTOMER_QUERY',
    WALLET_SUMMARIES_SET_WALLET_PAGINATION: 'WALLET_SUMMARIES_SET_WALLET_PAGINATION',
    WALLET_SUMMARIES_SET_WALLET_QUERY: 'WALLET_SUMMARIES_SET_WALLET_QUERY',
    WALLET_SUMMARIES_SET_WALLET_IS_APPROVED: 'WALLET_SUMMARIES_SET_WALLET_IS_APPROVED',

    searchCustomers: ({ page = 1, per = 20, query = '' } = {}) => ({
        type: actions.WALLET_SUMMARIES_CUSTOMERS_FETCH_REQUEST,
        payload: { page, per, query }
    }),

    searchWallets: ({ page = 1, per = 20, query = '', isApproved = '' } = {}) => ({
        type: actions.WALLET_SUMMARIES_WALLETS_FETCH_REQUEST,
        payload: { page, per, query, isApproved }
    })
}

export default actions
