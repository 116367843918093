const actions = {
    BANK_RECONCILE_PRODUCT_SET_UPLOADING:
        'BANK_RECONCILE_PRODUCT_SET_UPLOADING',
    BANK_RECONCILE_PRODUCT_UPLOAD_REQUEST:
        'BANK_RECONCILE_PRODUCT_UPLOAD_REQUEST',
    BANK_RECONCILE_TRUCK_SET_UPLOADING: 'BANK_RECONCILE_TRUCK_SET_UPLOADING',
    BANK_RECONCILE_TRUCK_UPLOAD_REQUEST: 'BANK_RECONCILE_TRUCK_UPLOAD_REQUEST',
    DELETE_INVOICE_ADJUSTMENT_REQUEST: 'DELETE_INVOICE_ADJUSTMENT_REQUEST',
    DELETE_INVOICE_ADJUSTMENT_SUCCESS: 'DELETE_INVOICE_ADJUSTMENT_SUCCESS',
    DELETE_INVOICE_ADJUSTMENT: 'DELETE_INVOICE_ADJUSTMENT',
    DELETE_PAYMENT_DETAILS_REQUEST: 'DELETE_PAYMENT_DETAILS_REQUEST',
    DELETE_PAYMENT_DETAILS_SUCCESS: 'DELETE_PAYMENT_DETAILS_SUCCESS',
    DELETE_PAYMENT_DETAILS: 'DELETE_PAYMENT_DETAILS',
    FETCH_INVOICE_ADJUSTMENT_REQUEST: 'FETCH_INVOICE_ADJUSTMENT_REQUEST',
    FETCH_INVOICE_ADJUSTMENT_SUCCESS: 'FETCH_INVOICE_ADJUSTMENT_SUCCESS',
    FETCH_INVOICE_ADJUSTMENT: 'FETCH_INVOICE_ADJUSTMENT',
    FETCH_INVOICE_ADJUSTMENTS_REQUEST: 'FETCH_INVOICE_ADJUSTMENTS_REQUEST',
    FETCH_INVOICE_ADJUSTMENTS_SUCCESS: 'FETCH_INVOICE_ADJUSTMENTS_SUCCESS',
    FETCH_INVOICE_ADJUSTMENTS: 'FETCH_INVOICE_ADJUSTMENTS',
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF_REQUEST:
        'FETCH_INVOICE_DELIVERY_ADDRESS_PDF_REQUEST',
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS:
        'FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS',
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF: 'FETCH_INVOICE_DELIVERY_ADDRESS_PDF',
    FETCH_ORDER_BUSINESS_ADDRESS_REQUEST:
        'FETCH_ORDER_BUSINESS_ADDRESS_REQUEST',
    FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS:
        'FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS',
    FETCH_ORDER_BUSINESS_ADDRESSES_REQUEST:
        'FETCH_ORDER_BUSINESS_ADDRESSES_REQUEST',
    FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS:
        'FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS',
    FETCH_ORDER_BUSINESS_ADDRESSES: 'FETCH_ORDER_BUSINESS_ADDRESSES',
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES_REQUEST:
        'FETCH_ORDER_INVOICE_EMAIL_HISTORIES_REQUEST',
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS:
        'FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS',
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES: 'FETCH_ORDER_INVOICE_EMAIL_HISTORIES',
    FETCH_PAYMENT_VOUCHERS_FILE_REQUEST: 'FETCH_PAYMENT_VOUCHERS_FILE_REQUEST',
    FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS: 'FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS',
    FETCH_PAYMENT_VOUCHERS_FILE: 'FETCH_PAYMENT_VOUCHERS_FILE',
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_REQUEST:
        'FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_REQUEST',
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_SUCCESS:
        'FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_SUCCESS',
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE:
        'FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE',
    FETCH_RV_REPORT_FILE_REQUEST: 'FETCH_RV_REPORT_FILE_REQUEST',
    FETCH_RV_REPORT_FILE_SUCCESS: 'FETCH_RV_REPORT_FILE_SUCCESS',
    FETCH_RV_REPORT_FILE: 'FETCH_RV_REPORT_FILE',
    FETCH_SALE_TAX_INVOICE_REPORT_FILE_REQUEST:
        'FETCH_SALE_TAX_INVOICE_REPORT_FILE_REQUEST',
    FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS:
        'FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS',
    FETCH_SALE_TAX_INVOICE_REPORT_FILE: 'FETCH_SALE_TAX_INVOICE_REPORT_FILE',
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_REQUEST:
        'FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_REQUEST',
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS:
        'FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS',
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE:
        'FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE',
    // FETCH_TAX_INVOICE_INFO_REQUEST: 'FETCH_TAX_INVOICE_INFO_REQUEST',
    // FETCH_TAX_INVOICE_INFO_SUCCESS: 'FETCH_TAX_INVOICE_INFO_SUCCESS',
    // FETCH_TAX_INVOICE_INFO: 'FETCH_TAX_INVOICE_INFO',
    FETCH_TAX_INVOICE_PDF_REQUEST: 'FETCH_TAX_INVOICE_PDF_REQUEST',
    FETCH_TAX_INVOICE_PDF_SUCCESS: 'FETCH_TAX_INVOICE_PDF_SUCCESS',
    FETCH_TAX_INVOICE_PDF: 'FETCH_TAX_INVOICE_PDF',
    FETCH_TAX_INVOICE_REPORT_FILE_REQUEST:
        'FETCH_TAX_INVOICE_REPORT_FILE_REQUEST',
    FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS:
        'FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS',
    FETCH_TAX_INVOICE_REPORT_FILE: 'FETCH_TAX_INVOICE_REPORT_FILE',
    FETCH_TEMPORARY_RECEIPT_PDF_REQUEST: 'FETCH_TEMPORARY_RECEIPT_PDF_REQUEST',
    FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS: 'FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS',
    FETCH_TEMPORARY_RECEIPT_PDF: 'FETCH_TEMPORARY_RECEIPT_PDF',
    FETCH_VAT_DOCUMENT_FILE_REQUEST: 'FETCH_VAT_DOCUMENT_FILE_REQUEST',
    FETCH_VAT_DOCUMENT_FILE_SUCCESS: 'FETCH_VAT_DOCUMENT_FILE_SUCCESS',
    FETCH_VAT_DOCUMENT_FILE: 'FETCH_VAT_DOCUMENT_FILE',
    FETCH_YIELD_REPORT_FILE_REQUEST: 'FETCH_YIELD_REPORT_FILE_REQUEST',
    FETCH_YIELD_REPORT_FILE_SUCCESS: 'FETCH_YIELD_REPORT_FILE_SUCCESS',
    FETCH_YIELD_REPORT_FILE: 'FETCH_YIELD_REPORT_FILE',
    INVOICE_ADJUSTMENT_SET_FORM_ERRORS: 'INVOICE_ADJUSTMENT_SET_FORM_ERRORS',
    INVOICE_EMS_TRACKING_IMPORT_FILE_REQUEST:
        'INVOICE_EMS_TRACKING_IMPORT_FILE_REQUEST',
    INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING:
        'INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING',
    ORDER_BUSINESSES_DELETE_REQUEST: 'ORDER_BUSINESSES_DELETE_REQUEST',
    ORDER_BUSINESSES_DELETE_SUCCESS: 'ORDER_BUSINESSES_DELETE_SUCCESS',
    ORDER_BUSINESSES_SAVE_REQUEST: 'ORDER_BUSINESSES_SAVE_REQUEST',
    ORDER_BUSINESSES_SAVE_SUCCESS: 'ORDER_BUSINESSES_SAVE_SUCCESS',
    ORDER_BUSINESSES_SET_FORM_ERRORS: 'ORDER_BUSINESSES_SET_FORM_ERRORS',
    ORDER_BUSINESSES_SET_SAVING: 'ORDER_BUSINESSES_SET_SAVING',
    PAYMENT_DETAILS_FETCH_REQUEST: 'PAYMENT_DETAILS_FETCH_REQUEST',
    PAYMENT_DETAILS_FETCH_SUCCESS: 'PAYMENT_DETAILS_FETCH_SUCCESS',
    PAYMENT_DETAILS_FETCH: 'PAYMENT_DETAILS_FETCH',
    PAYMENT_DETAILS_SET_FORM_ERRORS: 'PAYMENT_DETAILS_SET_FORM_ERRORS',
    PAYMENT_DETAILS_SET_PAGINATION: 'PAYMENT_DETAILS_SET_PAGINATION',
    PAYMENT_VOUCHER_DELETE_REQUEST: 'PAYMENT_VOUCHER_DELETE_REQUEST',
    PAYMENT_VOUCHER_DELETE_SUCCESS: 'PAYMENT_VOUCHER_DELETE_SUCCESS',
    PAYMENT_VOUCHER_DELETE: 'PAYMENT_VOUCHER_DELETE',
    PAYMENT_VOUCHER_IMPORT_FILE_REQUEST: 'PAYMENT_VOUCHER_IMPORT_FILE_REQUEST',
    PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING:
        'PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING',
    PAYMENT_VOUCHER_SET_FORM_ERRORS: 'PAYMENT_VOUCHER_SET_FORM_ERRORS',
    SAVE_PAYMENT_VOUCHER_SUCCESS: 'SAVE_PAYMENT_VOUCHER_SUCCESS',
    ORDER_FETCH_PAYMENT_VOUCHERS_REQUEST:
        'ORDER_FETCH_PAYMENT_VOUCHERS_REQUEST',
    ORDER_FETCH_PAYMENT_VOUCHERS_SUCCESS:
        'ORDER_FETCH_PAYMENT_VOUCHERS_SUCCESS',
    ORDER_FETCH_PAYMENT_VOUCHERS: 'ORDER_FETCH_PAYMENT_VOUCHERS',
    RECEIPT_VOUCHERS_IMPORT_FILE_REQUEST:
        'RECEIPT_VOUCHERS_IMPORT_FILE_REQUEST',
    RECEIPT_VOUCHERS_IMPORT_FILE_UPLOADING:
        'RECEIPT_VOUCHERS_IMPORT_FILE_UPLOADING',
    SAVE_INVOICE_ADJUSTMENT_REQUEST: 'SAVE_INVOICE_ADJUSTMENT_REQUEST',
    SAVE_INVOICE_ADJUSTMENT_SUCCESS: 'SAVE_INVOICE_ADJUSTMENT_SUCCESS',
    SAVE_INVOICE_ADJUSTMENT: 'SAVE_INVOICE_ADJUSTMENT',
    SAVE_PAYMENT_DETAILS_REQUEST: 'SAVE_PAYMENT_DETAILS_REQUEST',
    SAVE_PAYMENT_DETAILS_SUCCESS: 'SAVE_PAYMENT_DETAILS_SUCCESS',
    SAVE_PAYMENT_DETAILS: 'SAVE_PAYMENT_DETAILS',
    SAVE_PAYMENT_VOUCHER_REQUEST: 'SAVE_PAYMENT_VOUCHER_REQUEST',
    SAVING_PAYMENT_VOUCHER: 'SAVING_PAYMENT_VOUCHER',
    // SEND_TAX_INVOICE_EMAIL_REQUEST: 'SEND_TAX_INVOICE_EMAIL_REQUEST',
    // SEND_TAX_INVOICE_EMAIL_SUCCESS: 'SEND_TAX_INVOICE_EMAIL_SUCCESS',
    // SEND_TAX_INVOICE_EMAIL: 'SEND_TAX_INVOICE_EMAIL',
    SET_ACTIVE_INVOICE_ADJUSTMENT: 'SET_ACTIVE_INVOICE_ADJUSTMENT',
    SET_ACTIVE_INVOICE: 'SET_ACTIVE_INVOICE',
    // SET_CLEAR_TAX_INVOICE_FORM: 'SET_CLEAR_TAX_INVOICE_FORM',
    SET_ORDER_BUSINESS_ADDRESS_ACTIVE: 'SET_ORDER_BUSINESS_ADDRESS_ACTIVE',
    SET_SELECT_INVOICE_TYPE: 'SET_SELECT_INVOICE_TYPE',
    SET_SHOW_INVOICE_ADJUSTMENT_FORM: 'SET_SHOW_INVOICE_ADJUSTMENT_FORM',
    SET_SHOW_INVOICE_MAILING_ADDRESS_FORM:
        'SET_SHOW_INVOICE_MAILING_ADDRESS_FORM',
    SET_SHOW_PAYMENT_DETAIL: 'SET_SHOW_PAYMENT_DETAIL',
    SET_SHOW_PAYMENT_VOUCHER: 'SET_SHOW_PAYMENT_VOUCHER',
    SET_SHOW_TRACKING_INVOICE_FORM: 'SET_SHOW_TRACKING_INVOICE_FORM',
    SHOW_ADD_BUSINESS_ADDRESS_MODAL: 'SHOW_ADD_BUSINESS_ADDRESS_MODAL',
    SHOW_ADD_MAILING_ADDRESS_MODAL: 'SHOW_ADD_MAILING_ADDRESS_MODAL',
    SHOW_TAX_INVOICE_BUSINESS_ITEMS_MODAL:
        'SHOW_TAX_INVOICE_BUSINESS_ITEMS_MODAL',
    SHOW_TAX_INVOICE_BUSINESS_DEPOSITS_MODAL:
        'SHOW_TAX_INVOICE_BUSINESS_DEPOSITS_MODAL',
    SHOW_TAX_INVOICE_BUSINESS_MODAL: 'SHOW_TAX_INVOICE_BUSINESS_MODAL',
    TAX_INVOICE_IMPORT_FILE_REQUEST: 'TAX_INVOICE_IMPORT_FILE_REQUEST',
    TAX_INVOICE_IMPORT_FILE_UPLOADING: 'TAX_INVOICE_IMPORT_FILE_UPLOADING',
    TRANSFER_BACK_FETCH_REQUEST: 'TRANSFER_BACK_FETCH_REQUEST',
    TRANSFER_BACK_FETCH_SUCCESS: 'TRANSFER_BACK_FETCH_SUCCESS',
    TRANSFER_BACK_FETCH: 'TRANSFER_BACK_FETCH',
    DOWNLOAD_INVOICE_ADJUSTMENT_REQUEST: 'DOWNLOAD_INVOICE_ADJUSTMENT_REQUEST',
    DOWNLOAD_INVOICE_ADJUSTMENT: 'DOWNLOAD_INVOICE_ADJUSTMENT',
    DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS: 'DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS',
    SEND_E_TAX_REQUEST: 'SEND_E_TAX_REQUEST',
    SEND_E_TAX_REQUESTING: 'SEND_E_TAX_REQUESTING',
    SEND_E_TAX_SUCCESS: 'SEND_E_TAX_SUCCESS',
    SEND_ELECTRONIC_CREDIT_NOTE_REQUEST: 'SEND_ELECTRONIC_CREDIT_NOTE_REQUEST',
    SEND_ELECTRONIC_CREDIT_NOTE: 'SEND_ELECTRONIC_CREDIT_NOTE',
    SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS: 'SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS',
    SEND_E_TAX_MAIL_TO_CUSTOMER_REQUEST: 'SEND_E_TAX_MAIL_TO_CUSTOMER_REQUEST',
    SEND_E_TAX_MAIL_TO_CUSTOMER: 'SEND_E_TAX_MAIL_TO_CUSTOMER',
    SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS: 'SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS',
    FETCH_DEBIT_NOTE_PURPOSES_REQUEST: 'FETCH_DEBIT_NOTE_PURPOSES_REQUEST',
    FETCH_DEBIT_NOTE_PURPOSES: 'FETCH_DEBIT_NOTE_PURPOSES',
    FETCH_DEBIT_NOTE_PURPOSES_SUCCESS: 'FETCH_DEBIT_NOTE_PURPOSES_SUCCESS',
    FETCH_CREDIT_NOTE_PURPOSES_REQUEST: 'FETCH_CREDIT_NOTE_PURPOSES_REQUEST',
    FETCH_CREDIT_NOTE_PURPOSES: 'FETCH_CREDIT_NOTE_PURPOSES',
    FETCH_CREDIT_NOTE_PURPOSES_SUCCESS: 'FETCH_CREDIT_NOTE_PURPOSES_SUCCESS',

    fetchPaymentDetails: (page, id) => ({
        type: actions.PAYMENT_DETAILS_FETCH_REQUEST,
        payload: { page, id }
    }),
    fetchPaymentVouchers: (orderId) => ({
        type: actions.ORDER_FETCH_PAYMENT_VOUCHERS_REQUEST,
        payload: { orderId }
    }),
    fetchTransferBack: (orderId) => ({
        type: actions.TRANSFER_BACK_FETCH_REQUEST,
        payload: { orderId }
    }),
    setPaymentDetailsForm: (value) => ({
        type: actions.SET_SHOW_PAYMENT_DETAIL,
        value
    }),
    togglePaymentVoucherForm: () => ({
        type: actions.SET_SHOW_PAYMENT_VOUCHER
    }),
    savePaymentDatail: (orderId, id, value, path = 'accounting') => ({
        type: actions.SAVE_PAYMENT_DETAILS_REQUEST,
        payload: { orderId, id, value, path }
    }),
    savePaymentVoucher: (
        orderId,
        id,
        value,
        { onSuccess = () => {} } = {}
    ) => ({
        type: actions.SAVE_PAYMENT_VOUCHER_REQUEST,
        payload: { orderId, id, value, onSuccess }
    }),
    deletePaymentDatail: (orderId, orderItems, id) => ({
        type: actions.DELETE_PAYMENT_DETAILS_REQUEST,
        payload: { orderId, orderItems, id }
    }),
    resetPaymentDatailErrors: (formErrors = []) => ({
        type: actions.PAYMENT_DETAILS_SET_FORM_ERRORS,
        formErrors
    }),
    fetchPaymentVouchersFile: (value) => ({
        type: actions.FETCH_PAYMENT_VOUCHERS_FILE_REQUEST,
        payload: { value }
    }),
    fetchTaxInvoicePdf: (
        orderId,
        id,
        taxInvoiceType,
        locale = 'th',
        isSample = false
    ) => ({
        type: actions.FETCH_TAX_INVOICE_PDF_REQUEST,
        payload: { orderId, id, taxInvoiceType, locale, isSample }
    }),
    fetchVatDocumentFile: (values) => ({
        type: actions.FETCH_VAT_DOCUMENT_FILE_REQUEST,
        payload: { values }
    }),
    fetchYieldReportFile: (value) => ({
        type: actions.FETCH_YIELD_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    setShowMailingAddressForm: (value) => ({
        type: actions.SET_SHOW_INVOICE_MAILING_ADDRESS_FORM,
        value
    }),
    deletePaymentVoucher: (orderId, id) => ({
        type: actions.PAYMENT_VOUCHER_DELETE_REQUEST,
        payload: { orderId, id }
    }),
    setShowBusinessAddressModal: (value) => ({
        type: actions.SHOW_ADD_BUSINESS_ADDRESS_MODAL,
        value
    }),
    setShowMailingAddressModal: (value) => ({
        type: actions.SHOW_ADD_MAILING_ADDRESS_MODAL,
        value
    }),
    // fetchTaxInvoiceInfo: (orderId) => ({
    //     type: actions.FETCH_TAX_INVOICE_INFO_REQUEST,
    //     payload: { orderId }
    // }),
    importPaymentVoucherFile: (file) => ({
        type: actions.PAYMENT_VOUCHER_IMPORT_FILE_REQUEST,
        payload: { file }
    }),
    importReceiptVoucherFile: (file) => ({
        type: actions.RECEIPT_VOUCHERS_IMPORT_FILE_REQUEST,
        payload: { file }
    }),
    // clearTaxInvoiceForm: (value) => ({
    //     type: actions.SET_CLEAR_TAX_INVOICE_FORM,
    //     value
    // }),
    fetchTemporaryReceiptPdf: (orderId, value, locale = 'th') => ({
        type: actions.FETCH_TEMPORARY_RECEIPT_PDF_REQUEST,
        payload: { orderId, value, locale }
    }),
    uploadProductBankReconcile: (
        file,
        { onSuccess = () => {}, onError = () => {} } = {}
    ) => ({
        type: actions.BANK_RECONCILE_PRODUCT_UPLOAD_REQUEST,
        payload: { file, onSuccess, onError }
    }),
    uploadTruckBankReconcile: (
        file,
        { onSuccess = () => {}, onError = () => {} } = {}
    ) => ({
        type: actions.BANK_RECONCILE_TRUCK_UPLOAD_REQUEST,
        payload: { file, onSuccess, onError }
    }),
    setShowTaxInvoiceBusinessModal: (value) => ({
        type: actions.SHOW_TAX_INVOICE_BUSINESS_MODAL,
        value
    }),
    setTaxInvoiceBusinessErrors: (errors = {}) => ({
        type: actions.ORDER_BUSINESSES_SET_FORM_ERRORS,
        errors
    }),
    fetchOrderBusinessAddresses: (orderId) => ({
        type: actions.FETCH_ORDER_BUSINESS_ADDRESSES_REQUEST,
        payload: { orderId }
    }),
    fetchOrderBusinessAddress: ({ id, orderId }) => ({
        type: actions.FETCH_ORDER_BUSINESS_ADDRESS_REQUEST,
        payload: { id, orderId }
    }),
    saveOrderBusinessAddress: ({
        address,
        id,
        orderId,
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.ORDER_BUSINESSES_SAVE_REQUEST,
        payload: { address, id, orderId, onSuccess, onError }
    }),
    fetchSaleTaxInvoiceReportFile: (value) => ({
        type: actions.FETCH_SALE_TAX_INVOICE_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    setOrderBusinessAddressActive: (id) => ({
        type: actions.SET_ORDER_BUSINESS_ADDRESS_ACTIVE,
        id
    }),
    deleteOrderBusinessAddress: (orderId, id) => ({
        type: actions.ORDER_BUSINESSES_DELETE_REQUEST,
        payload: { orderId, id }
    }),
    setShowTaxInvoiceBusinessItemsModal: (value) => ({
        type: actions.SHOW_TAX_INVOICE_BUSINESS_ITEMS_MODAL,
        value
    }),
    setShowTaxInvoiceBusinessDepositsModal: (value) => ({
        type: actions.SHOW_TAX_INVOICE_BUSINESS_DEPOSITS_MODAL,
        value
    }),
    fetchRvAndPvAdjustedReport: (value) => ({
        type: actions.FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    fetchRvReport: (value) => ({
        type: actions.FETCH_RV_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    setInvoiceType: (value) => ({
        type: actions.SET_SELECT_INVOICE_TYPE,
        value
    }),
    fetchScbPaymentConfirmReportFile: (value) => ({
        type: actions.FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    // sendTaxInvoiceEmail: ({
    //     orderId,
    //     id,
    //     email = '',
    //     onSuccess = () => {},
    //     onError = () => {}
    // } = {}) => ({
    //     type: actions.SEND_TAX_INVOICE_EMAIL_REQUEST,
    //     payload: { orderId, id, email, onSuccess, onError }
    // }),
    fetchOrderInvoiceEmailHistroies: (orderId) => ({
        type: actions.FETCH_ORDER_INVOICE_EMAIL_HISTORIES_REQUEST,
        payload: { orderId }
    }),
    fetchTaxInvoiceReportFile: (value) => ({
        type: actions.FETCH_TAX_INVOICE_REPORT_FILE_REQUEST,
        payload: { value }
    }),
    importTaxInvoiceFile: (file) => ({
        type: actions.TAX_INVOICE_IMPORT_FILE_REQUEST,
        payload: { file }
    }),
    importInvoiceEmsTrackingFile: (file) => ({
        type: actions.INVOICE_EMS_TRACKING_IMPORT_FILE_REQUEST,
        payload: { file }
    }),
    setShowTrackingInvoiceForm: ({ id = null } = {}) => ({
        type: actions.SET_SHOW_TRACKING_INVOICE_FORM,
        id
    }),
    fetchInvoiceDeliveryAddressPdf: (value) => ({
        type: actions.FETCH_INVOICE_DELIVERY_ADDRESS_PDF_REQUEST,
        payload: { value }
    }),
    fetchInvoiceAdjustments: (orderId, invoiceId) => ({
        type: actions.FETCH_INVOICE_ADJUSTMENTS_REQUEST,
        payload: { orderId, invoiceId }
    }),
    fetchInvoiceAdjustment: (orderId, invoiceId, id) => ({
        type: actions.FETCH_INVOICE_ADJUSTMENT_REQUEST,
        payload: { orderId, invoiceId, id }
    }),
    saveInvoiceAdjustment: ({
        orderId,
        invoiceId,
        id = null,
        params = {},
        onSuccess = () => {}
    } = {}) => ({
        type: actions.SAVE_INVOICE_ADJUSTMENT_REQUEST,
        payload: { orderId, invoiceId, id, params, onSuccess }
    }),
    setInvoiceAdjustmentErrors: (errors = {}) => ({
        type: actions.INVOICE_ADJUSTMENT_SET_FORM_ERRORS,
        errors
    }),
    deleteInvoiceAdjustment: ({
        orderId,
        invoiceId,
        id,
        onSuccess = () => {}
    } = {}) => ({
        type: actions.DELETE_INVOICE_ADJUSTMENT_REQUEST,
        payload: { orderId, invoiceId, id, onSuccess }
    }),
    setActiveInvoice: (id = null) => ({
        type: actions.SET_ACTIVE_INVOICE,
        id
    }),
    setActiveInvoiceAdjustment: (id = null) => ({
        type: actions.SET_ACTIVE_INVOICE_ADJUSTMENT,
        id
    }),
    setShowInvoiceAdjustmentForm: (visible = false) => ({
        type: actions.SET_SHOW_INVOICE_ADJUSTMENT_FORM,
        visible
    }),
    downloadInvoiceAdjustment: ({
        orderId,
        invoiceId,
        id,
        locale = 'th'
    } = {}) => ({
        type: actions.DOWNLOAD_INVOICE_ADJUSTMENT_REQUEST,
        payload: { orderId, invoiceId, id, locale }
    }),

    sendETax: ({
        orderId,
        id,
        taxInvoiceType,
        locale = 'th',
        isSample = false
    } = {}) => ({
        type: actions.SEND_E_TAX_REQUEST,
        payload: { orderId, id, taxInvoiceType, locale, isSample }
    }),
    sendElectronicCreditNote: ({
        orderId,
        orderBusinessAddressId,
        adjustmentId,
        onSuccess = () => {}
    } = {}) => ({
        type: actions.SEND_ELECTRONIC_CREDIT_NOTE_REQUEST,
        payload: { orderId, orderBusinessAddressId, adjustmentId, onSuccess }
    }),

    sendETaxMailToCustomer: ({
        orderId,
        id,
        email = '',
        onSuccess = () => {},
        onError = () => {}
    } = {}) => ({
        type: actions.SEND_E_TAX_MAIL_TO_CUSTOMER_REQUEST,
        payload: { orderId, id, email, onSuccess, onError }
    }),

    fetchDebitNotePurposes: () => ({
        type: actions.FETCH_DEBIT_NOTE_PURPOSES_REQUEST
    }),

    fetchCreditNotePurposes: () => ({
        type: actions.FETCH_CREDIT_NOTE_PURPOSES_REQUEST
    })
}

export default actions
