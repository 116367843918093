import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_FETCH_SUPPLIER_ORDERS_SUCCESS,
    ORDER_FETCH_SUPPLIER_ORDERS,
    PO_TYPE_SER_DEFAULT,
    SUPPLIER_FETCH_FAIL,
    SUPPLIER_FETCH_SUCCESS,
    SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS,
    SUPPLIER_FETCH_SUPPLIER_ORDERS,
    SUPPLIER_FETCHING,
    SUPPLIERS_FETCH_SUCCESS,
    SUPPLIERS_FETCH,
    SUPPLIER_SAVE,
    SUPPLIER_SAVE_SUCCESS,
    SUPPLIER_SAVE_ERRORS,
    SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS,
    SUPPLIERS_LINE_ITEM_SET_LOADING,
    SUPPLIERS_ORDER_CLEAR_EXPAND_LINE_ITEM,
    SUPPLIERS_ORDER_PAY_BY_CREDIT_CARD,
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS,
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH,
    SUPPLIERS_ORDERS_SAVE_FAIL,
    SUPPLIERS_ORDERS_SAVE_SUCCESS,
    SUPPLIERS_ORDERS_SAVE,
    SUPPLIERS_ORDERS_SET_EXPAND_LINE_ITEM,
    SUPPLIERS_ORDERS_SET_EXPAND,
    SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING,
    SUPPLIERS_SELECTED_LINE_ITEMS,
    SUPPLIERS_SET_DEFAULT,
    SUPPLIERS_SET_PAGINATION,
    SUPPLIERS_SET_PDF_LANGUAGE,
    SUPPLIERS_SET_QUERY,
    SUPPLIERS_SET_SAVING,
    SUPPLIERS_SET_SELECTED,
    SUPPLIERS_SET_SUPPLIER_LOADING,
    SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING,
    SUPPLIERS_TOGGLE_CREATE_PURCHASE,
    TOGGLE_PO_FORM,
    TOGGLE_PURCHASE_FORM,
    TOGGLE_PO_DOCUMENT_FORM,
    TOGGLE_SUPPLIER_OVER_TRANSFER_FORM,
    TOGGLE_SUPPLIER_INFO_FORM,
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS,
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS,
    SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS
} = actions

const initState = new Map({
    formErrors: new Map(),
    isCredit: false,
    isOpenCreatePurchase: false,
    isShowingPOForm: false,
    isShowingPurchaseForm: false,
    lineItemExpand: false,
    lineItemExpandKey: new List(),
    lineItemsLoading: false,
    loading: false,
    pagination: new Map({
        current: 1,
        pageSize: 20,
        total: 1
    }),
    query: '',
    saving: false,
    selectItems: new List(),
    showingPOFormSupplierId: null,
    showingPurchaseFormPOType: null,
    // showingPurchaseFormSupplierId: null,
    showSupplierOverTransferForm: false,
    supplierId: null,
    supplierItems: new List(),
    // supplierOrderItem: null,
    // supplierOrderItems: new List(),
    supplierOrderLineItems: new List(),
    supplierOrderLineItemsLoading: false,
    // supplierOrderLoading: false,
    supplierOrders: new List(),
    supplierOrdersLoading: false,
    supplierOrdersPdfLanguage: 'th',
    // supplierOrdersPdfLoading: false,
    isShowPODocumentForm: false,
    showDocumentSupplierOrderId: null,
    showSupplierInfoForm: false,
    supplierOrderPagination: new Map({
        current: 1,
        pageSize: 20,
        total: 1
    }),
    supplierItemIds: new List(),
    supplierItemsLoading: false,
    supplierItemsPagination: new Map({
        current: 1,
        pageSize: 10,
        total: 1
    }),
})

export default function suppliersReducer (state = initState, action) {
    const result = action.result
    const supplierItems = state.get('supplierItems')
    const supplierOrders = state.get('supplierOrders')

    switch (action.type) {
        case SUPPLIERS_SET_PDF_LANGUAGE:
            return state.merge({
                supplierOrdersPdfLanguage: action.value
            })
        case ORDER_FETCH_SUPPLIER_ORDERS:
            return state.merge({
                supplierOrdersLoading: true
            })
        case SUPPLIER_FETCH_FAIL:
            return state.merge({ loading: false })
        case SUPPLIER_FETCH_SUCCESS:
            return state.merge({ supplierItems: new List(result), loading: false })
        case SUPPLIER_FETCHING:
            return state.merge({ loading: true })
        case SUPPLIER_SAVE:
            return state.merge({
                saving: true
            })
        case SUPPLIER_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                supplierItems: new List(supplierItems.includes(result) ? supplierItems : supplierItems.push(result))
            })
        case SUPPLIER_SAVE_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors),
                saving: false
            })
        case ORDER_FETCH_SUPPLIER_ORDERS_SUCCESS:
            return state.merge({
                supplierOrdersLoading: false,
                supplierOrders: new List().concat(result)
            })
        case SUPPLIER_FETCH_SUPPLIER_ORDERS:
            return state.merge({
                supplierOrdersLoading: true
            })
        case SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS:
            return state.merge({
                supplierOrdersLoading: false,
                supplierOrders: new List(result)
            })
        // case TOGGLE_PURCHASE_FORM:
        //     return state.merge({
        //         isShowingPurchaseForm: !state.get('isShowingPurchaseForm'),
        //         showingPurchaseFormSupplierId: action.supplierOrderId
        //     })
        case SUPPLIERS_FETCH:
            return state.merge({
                loading: true
            })
        case SUPPLIERS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                supplierItems: new List().concat(result)
            })
        case SUPPLIERS_SET_QUERY:
            return state.merge({
                query: action.query
            })
        case SUPPLIERS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        // case SUPPLIERS_SET_SAVING:
        //     return state.merge({ saving: action.saving })
        case SUPPLIERS_ORDERS_SAVE:
            return state.merge({ saving: true })
        case SUPPLIERS_ORDERS_SAVE_FAIL:
            return state.merge({
                formErrors: new Map(action.errors),
                saving: false
            })
        case SUPPLIERS_ORDERS_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                supplierOrders: new List(supplierOrders.includes(result) ?
                    supplierOrders : supplierOrders.push(result))
            })
        case SUPPLIERS_SET_SELECTED:
            return state.merge({
                supplierId: action.value
            })
        case SUPPLIERS_SET_DEFAULT:
            return state.merge({
                formErrors: new Map(),
                supplierItems: action.value ? new List().concat(action.value) : new List()
            })
        // case SUPPLIERS_SELECTED_LINE_ITEMS:
        //     return state.merge({
        //         selectItems: action.selectItems
        //     })
        // case SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS:
        //     return state.merge({
        //         supplierOrderLineItems: new List(result)
        //     })
        case SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING:
            return state.merge({
                supplierOrderLoading: action.loading
            })
        // case SUPPLIERS_ORDERS_SET_EXPAND:
        //     return state.merge({
        //         lineItemExpand: action.isExpand
        //     })
        // case SUPPLIERS_ORDERS_SET_EXPAND_LINE_ITEM:
        //     return state.merge({
        //         lineItemExpandKey: [].concat(action.lineItemId)
        //     })
        case SUPPLIERS_ORDERS_LINE_ITEMS_FETCH:
            return state.merge({
                lineItemsLoading: true
            })
        case SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS:
            return state.merge({
                supplierOrderLineItems: new List().concat(result),
                lineItemsLoading: false
            })
        // case SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS:
        //     return state.merge({
        //         supplierOrderItem: result
        //     })
        // case SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING:
        //     return state.merge({
        //         supplierOrdersPdfLoading: action.loading
        //     })
        // case SUPPLIERS_SET_SUPPLIER_LOADING:
        //     return state.merge({
        //         loading: action.loading
        //     })
        // case SUPPLIERS_LINE_ITEM_SET_LOADING:
        //     return state.merge({
        //         supplierOrderLineItemsLoading: action.loading
        //     })
        // case SUPPLIERS_ORDER_CLEAR_EXPAND_LINE_ITEM:
        //     return state.merge({
        //         lineItemExpandKey: []
        //     })
        case SUPPLIERS_TOGGLE_CREATE_PURCHASE:
            return state.merge({
                isOpenCreatePurchase: action.isOpen
            })
        // case SUPPLIERS_ORDER_PAY_BY_CREDIT_CARD:
        //     return state.merge({
        //         isCredit: action.credit
        //     })
        case TOGGLE_PO_FORM:
            return state.merge({
                isShowingPOForm: !state.get('isShowingPOForm'),
                showingPOFormSupplierId: action.supplierOrderId
            })
        case TOGGLE_PO_DOCUMENT_FORM:
            return state.merge({
                isShowPODocumentForm: !state.get('isShowPODocumentForm'),
                showDocumentSupplierOrderId: action.supplierOrderId
            })
        case PO_TYPE_SER_DEFAULT:
            return state.merge({
                showingPurchaseFormPOType: action.value
            })
        case TOGGLE_SUPPLIER_OVER_TRANSFER_FORM:
            return state.merge({
                showSupplierOverTransferForm: action.value
            })
        case TOGGLE_SUPPLIER_INFO_FORM:
            return state.merge({
                showSupplierInfoForm: action.value
            })
        case SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS:
            return state.merge({ supplierOrdersLoading: true })
        case SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS:
            return state.merge({
                supplierOrdersLoading: false,
                supplierOrders: new List(result)
            })
        case SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE:
            return state.mergeDeep({
                supplierOrderPagination: new Map(action.pagination)
            })
        case SUPPLIER_MEGA_HOME_FETCH_ITEMS:
            return state.merge({ supplierItemsLoading: true })
        case SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS:
            return state.merge({
                supplierItemsLoading: false,
                supplierItemIds: new List(result)
            })
        case SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE:
            return state.mergeDeep({
                supplierItemsPagination: new Map(action.pagination)
            })
        default:
            return state
    }
}
