import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../constants/customers'

const {
    CUSTOMER_ORDERS_SET_TOTAL,
    CUSTOMER_ORDERS_SUCCESS,
    CUSTOMERS_CLEAR_ITEMS,
    CUSTOMERS_CLEAR_STATE_INFO,
    CUSTOMERS_FETCH_SUCCESS,
    CUSTOMERS_FETCH,
    CUSTOMERS_SAVE_SUCCESS,
    CUSTOMERS_SEARCH_REQUEST,
    CUSTOMERS_SET_ADD_CONTACT_MODAL,
    CUSTOMERS_SET_CUSTOMER_TYPES_MODAL,
    CUSTOMERS_SET_EMAIL_AMOUNT,
    CUSTOMERS_SET_FORM_ERRORS,
    CUSTOMERS_SET_LINE_AMOUNT,
    CUSTOMERS_SET_LOCATION_FORM_AMOUNT,
    CUSTOMERS_SET_OPEN_OCCUPATION_MODAL,
    CUSTOMERS_SET_PAGINATION,
    CUSTOMERS_SET_PHONE_AMOUNT,
    CUSTOMERS_SET_QUERY,
    CUSTOMERS_SET_SAVING,
    CUSTOMERS_SET_SHOW_EMAILS,
    CUSTOMERS_SET_SHOW_LINES,
    CUSTOMERS_SET_SHOW_PHONE_NUMBERS,
    CUSTOMERS_SET_WALLET_FORM_MODAL,
} = actions

const initState = new Map({
    customerTypesModal: false,
    emailAmount: new List(),
    formErrors: new Map(),
    items: new List(),
    lineAmount: new List(),
    loading: false,
    locationFormAmount: 0,
    occupationModal: false,
    openAddContactModal: false,
    orders: new List(),
    ordersTotal: 0,
    pagination: new Map({
        current: null,
        defaultPageSize: PAGE_SIZE,
        total: null
    }),
    phoneAmount: new List(),
    query: '',
    saving: false,
    showEmails: false,
    showLines: false,
    showPhoneNumbers: false,
    showWalletForm: false
})

export default function customersReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_ORDERS_SET_TOTAL:
            return state.merge({ ordersTotal: action.ordersTotal })
        case CUSTOMERS_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })
        case CUSTOMERS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List().concat(result)
            })
        case CUSTOMER_ORDERS_SUCCESS:
            return state.merge({
                orders: new List(result)
            })
        case CUSTOMERS_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(action.data) ? items : items.push(action.data)
            })
        case CUSTOMERS_SET_SAVING:
            return state.merge({
                saving: action.saving
            })
        case CUSTOMERS_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })
        case CUSTOMERS_SET_LOCATION_FORM_AMOUNT:
            return state.merge({
                locationFormAmount: action.locationFormAmount
            })
        case CUSTOMERS_SEARCH_REQUEST:
            return state.set('query', action.query)
        case CUSTOMERS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case CUSTOMERS_SET_QUERY:
            return state.merge({
                query: action.query
            })
        case CUSTOMERS_SET_ADD_CONTACT_MODAL:
            return state.merge({
                openAddContactModal: action.value
            })
        case CUSTOMERS_SET_OPEN_OCCUPATION_MODAL:
            return state.merge({
                occupationModal: action.value
            })
        case CUSTOMERS_CLEAR_ITEMS:
            return state.merge({
                items: new List()
            })
        case CUSTOMERS_SET_EMAIL_AMOUNT:
            return state.merge({
                emailAmount: new List(action.amount)
            })
        case CUSTOMERS_SET_PHONE_AMOUNT:
            return state.merge({
                phoneAmount: new List(action.amount)
            })
        case CUSTOMERS_SET_LINE_AMOUNT:
            return state.merge({
                lineAmount: new List(action.amount)
            })
        case CUSTOMERS_SET_CUSTOMER_TYPES_MODAL:
            return state.merge({
                customerTypesModal: action.value
            })
        case CUSTOMERS_SET_SHOW_PHONE_NUMBERS:
            return state.merge({
                showPhoneNumbers: action.value
            })
        case CUSTOMERS_SET_SHOW_EMAILS:
            return state.merge({
                showEmails: action.value
            })
        case CUSTOMERS_SET_SHOW_LINES:
            return state.merge({
                showLines: action.value
            })
        case CUSTOMERS_CLEAR_STATE_INFO:
            return state.merge({
                formErrors: new Map(),
                phoneAmount: new List(),
                lineAmount: new List(),
                emailAmount: new List()
            })
        case CUSTOMERS_SET_WALLET_FORM_MODAL:
            return state.merge({
                showWalletForm: action.visible
            })
        default:
            return state
    }
}
