import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Form, Input, Button, Spin } from 'antd'
import AreasSelectContainer from '../../Areas/AreasSelectContainer'

import customerActions from '../../../redux/customers/actions'
import mailingAddressActions from '../../../redux/customers/addresses/mailing/actions'

const { saveMailingAddress } = mailingAddressActions
const { fetchCustomer } = customerActions

class MailingAddressFormContainer extends Component {
    constructor() {
        super()
        this.formRef = createRef()
    }

    componentDidMount () {
        const { fetchCustomer, customerId } = this.props
        fetchCustomer(customerId)
    }

    getInitialValue = (value) => {
        const { mailingAddressEntity, id } = this.props

        return mailingAddressEntity.getIn([id, `${value}`], '')
    }

    handleSubmit (values) {
        const { saveMailingAddress, customerId, id } = this.props

        saveMailingAddress({ address: values,
            customerId,
            onSuccess: this.setCloseModal,
            id: id })
    }

    setCloseModal = () => {
        const { onSuccess, customerId } = this.props
        if (onSuccess) {
            onSuccess()
        } else {
            window.location.replace(`/customers/${customerId}`)
        }
    }

    getUserId () {
        const { customerEntity, customers } = this.props
        const customerId = customers.getIn(['items', '0'])
        const customerUserId = customerEntity.getIn([customerId, 'user', 'id'])

        return customerUserId
    }

    getLoading () {
        const { customers } = this.props

        return customers.get('loading')
    }
    render () {
        const { i18n, mailingAddresses, mailingAddressEntity, id } = this.props
        const errors = mailingAddresses.get('formErrors')
        const provinceValue = mailingAddressEntity.getIn([id, 'province', 'id'], '')
        const districtValue = mailingAddressEntity.getIn([id, 'district', 'id'], '')
        const postalCodeValue = mailingAddressEntity.getIn([id, 'postal_code'], '')
        const subDistrictValue = this.getInitialValue('sub_district')

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 }
            }
        }

        if (this.getLoading()) {
            return <Spin size="large" />
        }

        return (
            <Form onFinish={(e) => this.handleSubmit(e)} ref={this.formRef}>
                <h2>{i18n.t('addresses:mailingAddress')}</h2>
                <Form.Item {...formItemLayout} label={i18n.t('addresses:name')}
                    hasFeedback
                    name='name'
                    initialValue={this.getInitialValue('name')}
                    validateStatus={errors && errors.get('name') && 'error'}
                    help={errors && errors.get('name')}>
                        <Input/>
                </Form.Item>
                <Form.Item {...formItemLayout} label={i18n.t('addresses:address')}
                    hasFeedback
                    name='address'
                    initialValue={this.getInitialValue('address')}
                    validateStatus={errors && errors.get('address') && 'error'}
                    help={errors && errors.get('address')}>
                        <Input/>
                </Form.Item>
                <Form.Item>
                    <AreasSelectContainer form={this.formRef}
                        districtFormItemLayout={{
                            labelCol: { md: { span: 6 }, sm: { span: 6 } },
                            wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                        }}
                        provinceFormItemLayout={{
                            labelCol: { md: { span: 6 }, sm: { span: 6 } },
                            wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                        }}
                        postalCodeFormItemLayout={{
                            labelCol: { md: { span: 6 }, sm: { span: 6 } },
                            wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                        }}
                        subDistrictFormItemLayout={{
                            labelCol: { md: { span: 6 }, sm: { span: 6 } },
                            wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                        }}
                        districtCol={{ md: { span: 24 } }}
                        subDistrictCol={{ md: { span: 24 } }}
                        postalCodeCol={{ md: { span: 24 } }}
                        provinceCol={{ md: { span: 24 } }}
                        showSubDistrict
                        provinceValue={provinceValue}
                        districtValue={districtValue}
                        postalCodeValue={postalCodeValue}
                        subDistrictValue={subDistrictValue}
                        provinceFieldName={`province_id`}
                        districtFieldName={`district_id`}
                        subDistrictFieldName={`sub_district`}
                        postalCodeFieldName={`postal_code`}
                        districtHelp={errors && errors.get('district')}
                        districtValidateStatus={errors && errors.get('district') && 'error'}
                        provinceHelp={errors && errors.get('province')}
                        provinceValidateStatus={errors && errors.get('province') && 'error'}
                        subDistrictHelp={errors && errors.get('sub_district')}
                        subDistrictValidateStatus={errors && errors.get('sub_district') && 'error'}
                        postalCodeHelp={errors && errors.get('postal_code')}
                        postalCodeValidateStatus={errors && errors.get('postal_code') && 'error'} />
                </Form.Item>
                <Form.Item initialValue={this.getUserId() || ''} name='user_id'>
                    <input hidden />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" size="large">{i18n.t('addresses:addAddress')}</Button>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = state => ({
    customerEntity: state.getIn(['Entities', 'customers']),
    mailingAddressEntity: state.getIn(['Entities', 'mailingAddresses']),
    customers: state.get('customers'),
    mailingAddresses: state.get('mailingAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveMailingAddress,
        fetchCustomer
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('addresses')(MailingAddressFormContainer))
