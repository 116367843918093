import { Map, List } from 'immutable'

import actions from './actions'
import { mergeDeepReplaceArrays } from '../entities/reducers'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const {
    ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS,
    ORDERS_SUPPLIER_ORDERS_FETCHING,
    SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL,
    SUPPLIER_ORDER_FETCH_SUCCESS,
    SUPPLIER_ORDER_FETCH,
    SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING,
    SUPPLIER_ORDER_STATE_CHANGE_FAIL,
    SUPPLIER_ORDER_STATE_CHANGE_SUCCESS,
    SUPPLIER_ORDER_STATE_CHANGING,
    SUPPLIER_ORDERS_FETCH_SUCCESS,
    SUPPLIER_ORDERS_FETCH,
    SUPPLIER_ORDERS_FILTER_BY_CURRENT_STAFF,
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
    SUPPLIER_ORDERS_LINE_ITEMS_FETCH,
    SUPPLIER_ORDERS_PDF_FETCH,
    SUPPLIER_ORDERS_SET_FILTER_DATE,
    SUPPLIER_ORDERS_SET_PAGINATION,
    SUPPLIER_ORDERS_SET_SORTER,
    SUPPLIER_ORDERS_SET_SUB_OFFER_STATE,
    SUPPLIER_ORDERS_SHOW_FORM,
    SUPPLIER_ORDERS_STATES_FETCH_SUCCESS,
    SUPPLIER_ORDERS_STATES_FETCH,
    SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS,
    SUPPLIER_SHIPPING_COST_FETCH_FILE,
    SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING,
    SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING,
    SUPPLIER_ORDER_QUERY_FETCH_SUCCESS,
    IMPORT_SUPPLIER_ORDER_MODAL,
    DOWNLOAD_SHIPPING_INVOICE_SET_LOADING,
    SET_SUPPLIER_OVER_TRANSFER_FORM_MODAL,
    EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING,
    PV_TRANSFER_BACKS_FETCH_SUCCESS,
    PV_TRANSFER_BACKS_FETCH,
    PV_TRANSFER_BACKS_SET_PAGINATION,
    PV_TRANSFER_BACKS_SET_FILTERS,
    SET_PAYMENT_VOUCHER_EDITING,
    SET_SUPPILER_ORDER_FORM_SELECTED
} = actions

const initState = new Map({
    items: new List(),
    exportExcelPaymentLoading: false,
    formErrors: new Map(),
    loading: false,
    sorter: new Map(),
    filterDate: '',
    isShowSupplierOrderImportModal: false,
    uploading: false,
    pagination: new Map({
        current: DEFAULT_CURRENT_PAGE,
        pageSize: PAGE_SIZE,
        total: PAGE_SIZE
    }),
    pickupDetailSaving: false,
    pickupDetialFormErrors: new Map(),
    previewId: null,
    saving: false,
    showPickupDetailForm: false,
    showSupplierOverTransferForm: false,
    states: new List(),
    statesLoading: false,
    supplierOrdersPdfLoading: false,
    shippingCostUploading: false,
    shippingCostLoading: false,
    supplierOrderPaymentExportPDFLoading: false,
    ordersSupplierOrderItems: new List(),
    filterCurrentStaff: false,
    showForm: false,
    currentSubOfferState: null,
    lineItemLoading: false,
    lineItems: new List(),
    isOpenOrderSupplierNoteForm: false,
    shippingInvoiceLoading: false,
    pvTotalAmountTypeLoading: false,
    transferBackList: new Map({
        items: new List(),
        loading: false,
        filters: new Map({
            dateType: 'transfer_date',
            startDate: '',
            endDate: '',
            isPaid: '',
            hasAnySlip: ''
        }),
        pagination: new Map({
            current: DEFAULT_CURRENT_PAGE,
            pageSize: PAGE_SIZE,
            total: PAGE_SIZE
        }),
        pvIdEditing: null
    }),
    supplierOrderFormSelected: {
        id: '',
        orderId: '',
        supplierOrderType: ''
    },
})

export default function supplierOrdersReducer (state = initState, action) {
    switch (action.type) {
        case SUPPLIER_ORDERS_FETCH:
            return state.merge({
                loading: true
            })
        case SUPPLIER_ORDERS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case SUPPLIER_ORDERS_PDF_FETCH:
            return state.merge({
                supplierOrdersPdfLoading: action.loading
            })
        case SUPPLIER_ORDERS_SET_FILTER_DATE:
            return state.merge({ filterDate: action.fieldname })
        case SUPPLIER_ORDERS_SET_PAGINATION:
            return state.mergeDeep({ pagination: action.pagination })
        case SUPPLIER_ORDERS_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })
        case SUPPLIER_ORDERS_STATES_FETCH:
            return state.merge({
                statesLoading: true
            })
        case SUPPLIER_ORDERS_STATES_FETCH_SUCCESS:
            return state.merge({
                states: [].concat(action.result),
                statesLoading: false
            })
        case SUPPLIER_SHIPPING_COST_IMPORT_FILE_UPLOADING:
            return state.merge({
                shippingCostUploading: action.uploading
            })
        case SUPPLIER_SHIPPING_COST_FETCH_FILE:
            return state.merge({
                shippingCostLoading: true
            })
        case SUPPLIER_SHIPPING_COST_FETCH_FILE_SUCCESS:
            return state.merge({
                shippingCostLoading: false
            })
        case SUPPLIERS_ORDER_PAYMENT_EXPORT_SET_LOADING:
            return state.merge({
                supplierOrderPaymentExportPDFLoading: action.loading
            })
        case SUPPLIER_ORDER_PAYMENT_VOUCHER_EXPORT_SET_LOADING:
            return state.merge({
                exportExcelPaymentLoading: action.loading
            })
        case SUPPLIER_ORDER_STATE_CHANGE_FAIL:
            return state.merge({ saving: false })
        case SUPPLIER_ORDER_STATE_CHANGE_SUCCESS:
            return state.merge({ saving: false })
        case SUPPLIER_ORDER_STATE_CHANGING:
            return state.merge({ saving: true })
        case ORDERS_SUPPLIER_ORDERS_FETCHING:
            return state.merge({
                loading: true
            })
        case ORDERS_SUPPLIER_ORDERS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                ordersSupplierOrderItems: action.result
            })
        case SUPPLIER_ORDER_FETCH:
            return state.merge({
                loading: true
            })
        case SUPPLIER_ORDER_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                previewId: action.result
            })
        case SUPPLIER_ORDERS_FILTER_BY_CURRENT_STAFF:
            return state.merge({
                filterCurrentStaff: action.filterCurrentStaff
            })
        case SUPPLIER_ORDERS_SHOW_FORM:
            return state.merge({
                showForm: action.value
            })
        case SUPPLIER_ORDERS_SET_SUB_OFFER_STATE:
            return state.merge({
                currentSubOfferState: action.subOfferState
            })
        case SUPPLIER_ORDERS_LINE_ITEMS_FETCH:
            return state.merge({
                lineItemLoading: action.loading
            })
        case SUPPLIER_ORDERS_LINE_ITEMS_FETCH_SUCCESS:
            return state.merge({
                lineItemLoading: action.loading,
                lineItems: action.result
            })
        case SHOW_ORDER_SUPPLIER_NOTE_FORM_MODAL:
            return state.merge({
                isOpenOrderSupplierNoteForm: action.value
            })
        case SUPPLIER_ORDER_QUERY_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result),
                loading: false
            })
        case IMPORT_SUPPLIER_ORDER_MODAL:
            return state.merge({
                isShowSupplierOrderImportModal: action.value
            })
        case DOWNLOAD_SHIPPING_INVOICE_SET_LOADING:
            return state.merge({
                shippingInvoiceLoading: action.loading
            })
        case SET_SUPPLIER_OVER_TRANSFER_FORM_MODAL:
            return state.merge({
                showSupplierOverTransferForm: action.visible
            })
        case EXPORT_PV_TOTAL_AMOUNT_MARGIN_TYPE_SET_LOADING:
            return state.merge({
                pvTotalAmountTypeLoading: action.loading
            })
        case PV_TRANSFER_BACKS_FETCH:
            return state.mergeDeep({
                transferBackList: {
                    loading: true
                }
            })
        case PV_TRANSFER_BACKS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                transferBackList: {
                    loading: false,
                    items: action.result
                }
            })
        case PV_TRANSFER_BACKS_SET_PAGINATION:
            return state.mergeDeep({
                transferBackList: {
                    pagination: new Map(action.pagination)
                }
            })
        case PV_TRANSFER_BACKS_SET_FILTERS:
            return state.mergeDeep({
                transferBackList: {
                    filters: new Map(action.filters)
                }
            })
        case SET_PAYMENT_VOUCHER_EDITING:
            return state.mergeDeep({
                transferBackList: {
                    pvIdEditing: action.id
                }
            })
        case SET_SUPPILER_ORDER_FORM_SELECTED:
            return state.merge({
                supplierOrderFormSelected: {
                    id: action.id,
                    orderId: action.orderId,
                    supplierOrderType: action.supplierOrderType
                }
            })
        default:
            return state
    }
}

