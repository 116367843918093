import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import { orderPaymentDetailsSchema } from '../../schema'
import { deserialize } from '../../../helpers/jsonApi'

const {
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_REQUEST,
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH,
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_SUCCESS,
    CUSTOMER_ORDER_PAYMENT_DETAILS_SET_PAGINATION,
} = actions

export function *fetchCustomerOrderPaymentDetails () {
    yield takeEvery(CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH })

        const { customerId, page, per } = payload

        const queryString = `page=${page}&per=${per}`

        const path = `/customers/${customerId}/order_payment_details?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderPaymentDetailsSchema)
        const total = data.meta ? data.meta.total_pages * per : null

        yield put({
            type: CUSTOMER_ORDER_PAYMENT_DETAILS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCustomerOrderPaymentDetails),
    ])
}
