import styled from 'styled-components'
const AccountingWrapper = styled.div`
  .btn-center {
    text-align: center
  }

  .my-16 {
    margin: 16px 0;
  }

  .success-button {
    color: mediumseagreen;

    span {
      padding: 0 4px;
    }
  }

  .text-right {
    text-align: right
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    .wallet-button {margin-right: 8px;}
  }


  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .detail-container > span {
    color: #000;
  }

  div.ql-toolbar {
    display: none;
  }

  div.ql-editor {
    border-top: 1px solid #ccc
  }

  .hide {
      display: none;
  }
`
export default AccountingWrapper