const actions = {
    CUSTOMER_TICKET_SET_SHOW_FORM: 'CUSTOMER_TICKET_SET_SHOW_FORM',

    setShowForm: (value) => ({
        type: actions.CUSTOMER_TICKET_SET_SHOW_FORM,
        value
    })
}

export default actions
