import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Divider, Modal, Popconfirm, Select, Spin, Table, Typography } from 'antd'
import { DeleteOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons'
import WithholdingTaxFormContainer from '../../containers/WithholdingTaxes/WithholdingTaxFormContainer'
import WithholdingTaxWrapper from './withholdingTax.style'

const WithholdingTaxesIndex = ({
    activeWithholdingTax,
    exportWithholdingTaxesTXT,
    filterWithholdingTaxes,
    i18n,
    loading,
    onDeleteWithholdingTax,
    onDownloadWithholdingTaxPDF,
    onPageChange,
    pagination,
    pdfLoading,
    selectedMonth,
    selectedType,
    showWithholdingTaxForm,
    toggleWithholdingTaxForm,
    txtLoading,
    withholdingTaxes
}) => {
    const getColumns = () => {
        return [
            {
                key: 'number',
                title: i18n.t('withholdingTaxes:number'),
                dataIndex: 'number'
            },
            {
                key: 'taxpayer',
                title: i18n.t('withholdingTaxes:taxpayer'),
                render: (record) => (
                    <div className="withholding-tax-details">
                        <div className="detail-line">
                            <div className="label">{i18n.t('withholdingTaxes:name')}</div>
                            <div>{_.get(record, 'name') || '-'}</div>
                        </div>
                        <div className="detail-line">
                            <div className="label">{i18n.t('withholdingTaxes:taxId')}</div>
                            <div>{_.get(record, 'tax_id') || '-'}</div>
                        </div>
                        <div className="detail-line">
                            <div className="label">{i18n.t('withholdingTaxes:type')}</div>
                            <div>{_.get(record, 'corporate') ? i18n.t('withholdingTaxes:corporate') : i18n.t('withholdingTaxes:person')}</div>
                        </div>
                        <div className="detail-line">
                            <div className="label">{i18n.t('withholdingTaxes:address')}</div>
                            <div>{_.get(record, 'address') || '-'}</div>
                        </div>
                    </div>
                )
            },
            {
                key: 'details',
                title: i18n.t('withholdingTaxes:details'),
                render: (record) => {
                    const percentage = _.get(record, 'percentage')
                    const issueDate = _.get(record, 'issue_date', null)
                    return (
                        <div className="withholding-tax-details">
                            <div className="detail-line">
                                <div className="label">{i18n.t('withholdingTaxes:issueDate')}</div>
                                <div>{issueDate ? moment(issueDate).locale(i18n.language).format('LL') : '-'}</div>
                            </div>
                            <div className="detail-line">
                                <div className="label">{i18n.t('withholdingTaxes:amount')}</div>
                                <div>{_.get(record, 'amount.display')}</div>
                                <div className="unit">{i18n.t('shared:baht')}</div>
                            </div>
                            <div className="detail-line">
                                <div className="label">{i18n.t('withholdingTaxes:percentage')}</div>
                                {percentage === 0.00 || !_.isNumber(percentage) ? '-' : <div>{percentage}</div>}
                            </div>
                            <div className="detail-line">
                                <div className="label">{i18n.t('withholdingTaxes:withholdingTax')}</div>
                                <div>{_.get(record, 'withholding_tax.display')}</div>
                                <div className="unit">{i18n.t('shared:baht')}</div>
                            </div>
                            <div className="detail-line">
                                <div className="label">{i18n.t('withholdingTaxes:others')}</div>
                                {_.get(record, 'others') || '-'}
                            </div>
                        </div>
                    )
                }
            },
            {
                key: 'actions',
                render: (record) => (
                    <div>
                        <div onClick={() => toggleWithholdingTaxForm({ selected: record.id })}>
                            <a><EditOutlined /> {i18n.t('shared:edit')}</a>
                        </div>
                        <div onClick={() => onDownloadWithholdingTaxPDF({ id: record.id })}>
                            <a><PrinterOutlined /> {i18n.t('shared:download')}</a>
                        </div>
                        <Popconfirm
                            title={i18n.t('shared:confirmDelete')}
                            onConfirm={() => onDeleteWithholdingTax(record.id)}
                        >
                            <a className="text-danger"><DeleteOutlined /> {i18n.t('shared:delete')}</a>
                        </Popconfirm>
                    </div>
                )
            }
        ]
    }

    return (
        <WithholdingTaxWrapper>
            <div className="withholding-taxes-index-header">
                <div className="header-title">
                    <Typography.Title level={4}>{i18n.t('withholdingTaxes:listTitle')}</Typography.Title>
                    <Button type="primary" onClick={toggleWithholdingTaxForm}>{i18n.t('shared:add')}</Button>
                </div>

                <div className="header-line">
                    <span className="label">{i18n.t('withholdingTaxes:type')}</span>
                    <Select
                        onChange={(value) => filterWithholdingTaxes({ type: value })}
                        value={selectedType}
                        style={{ width: 100 }}>
                        <Select.Option value="all">{i18n.t('withholdingTaxes:types.all')}</Select.Option>
                        <Select.Option value="false">{i18n.t('withholdingTaxes:types.pnd3')}</Select.Option>
                        <Select.Option value="true">{i18n.t('withholdingTaxes:types.pnd53')}</Select.Option>
                    </Select>
                    <span className="label">{i18n.t('withholdingTaxes:monthly')}</span>
                    <DatePicker.MonthPicker
                        onChange={(date, dateString) => filterWithholdingTaxes({ month: dateString })}
                        value={selectedMonth ? moment(selectedMonth, 'YYYY-MM') : null} />
                    <Button
                        className="btn-action"
                        type="primary"
                        loading={txtLoading}
                        onClick={exportWithholdingTaxesTXT}
                        ghost>
                        Export (.xlsx)
                    </Button>
                </div>
            </div>

            <Divider />

            <Table
                columns={getColumns()}
                dataSource={withholdingTaxes}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />

            <Modal
                width="70px"
                visible={pdfLoading}
                closable={false}
                footer={null}>
                <Spin />
            </Modal>

            <Modal
                visible={showWithholdingTaxForm}
                centered
                onCancel={toggleWithholdingTaxForm}
                footer={null}
                destroyOnClose
            >
                <WithholdingTaxFormContainer
                    toggleForm={toggleWithholdingTaxForm}
                    withholdingTaxId={activeWithholdingTax}
                />
            </Modal>
        </WithholdingTaxWrapper>
    )
}

WithholdingTaxesIndex.propTypes = {
    activeWithholdingTax: PropTypes.string,
    exportWithholdingTaxesTXT: PropTypes.func.isRequired,
    filterWithholdingTaxes: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onDeleteWithholdingTax: PropTypes.func.isRequired,
    onDownloadWithholdingTaxPDF: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    pdfLoading: PropTypes.bool,
    selectedMonth: PropTypes.string,
    selectedType: PropTypes.string,
    showWithholdingTaxForm: PropTypes.bool,
    toggleWithholdingTaxForm: PropTypes.func.isRequired,
    txtLoading: PropTypes.bool,
    withholdingTaxes: PropTypes.array
}

WithholdingTaxesIndex.defaultProps = {
    pagination: false,
    withholdingTaxes: []
}

export default withTranslation('withholdingTaxes')(WithholdingTaxesIndex)

