import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Table, notification, Button } from "antd"

import actions from '../../../redux/orderPaymentDetails/Installments/actions'

const { fetchOrderPaymentDetailInstallments } = actions
import { CopyOutlined } from '@ant-design/icons'

const PaymentDetailInstallmentsList = ({
    orderId,
    toggleForm
}) => {
    const { i18n } = useTranslation('inquiries')
    const dispatch = useDispatch()
    const installmentsEntities = useSelector(state => state.getIn(['Entities', 'orderPaymentDetailInstallments']))
    const loading = useSelector(state => state.getIn(['orderPaymentDetailInstallments', 'loading']))
    const items = useSelector(state => state.getIn(['orderPaymentDetailInstallments', 'items'], new List()))

    useEffect(() => {
        dispatch(fetchOrderPaymentDetailInstallments(orderId))
    }, [])

    const getInstallments = () => {
        return items.map((id) => {
            return installmentsEntities.get(`${id}`) || new Map()
        })
    }

    const handleCopyPaymentUrl = () => {
      notification['success']({
          message: i18n.t('order.copyToClipboard')
      })
  }

    const columns = [
        {
            title: i18n.t('inquiries:paymentDetailInstallment.refNo'),
            dataIndex: 'ref_no',
            render: (refNo, record) => {
              return (
                <a onClick={() => toggleForm({ id: record.id })}>
                    {refNo}
                </a>
              )
            }
        }, {
            title: i18n.t('inquiries:paymentDetailInstallment.amount'),
            dataIndex: ['money_display', 'amount']
        }, {
            key: 'payment-url',
            render: (record) => {
                const scbPaymentUrl = record.scb_payment_url
                return (
                  <CopyToClipboard text={scbPaymentUrl}>
                    <Button size='small' icon={<CopyOutlined />} onClick={() => handleCopyPaymentUrl()}>
                      {i18n.t('inquiries:paymentDetailInstallment.url')}
                    </Button>
                </CopyToClipboard>
                )
            }
        }
    ]

    return (
        <Table
          columns={columns}
          loading={loading}
          scroll={{ x: 400 }}
          dataSource={getInstallments().toJS()}
          pagination={false}
          rowKey="id"
          size="small" />
    )
}

export default PaymentDetailInstallmentsList
