import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Input } from 'antd'

import { withTranslation } from 'react-i18next'

import SelectProvince from '../../containers/SelectProvince'
import SelectDistrict from '../../containers/SelectDistrict'
import SelectAddressWrapper from './selectAddressWrapper.style'

const SelectAddress = ({
    i18n,
    onDistrictChange,
    onPostalCodeChange,
    onProvinceChange,
    postalCodeVisible,
    value
}) => {
    return (
        <React.Fragment>
            <SelectAddressWrapper>
                <SelectProvince
                    onChange={onProvinceChange}
                    value={_.get(value, 'province_id', '')}
                />

                <SelectDistrict
                    onChange={onDistrictChange}
                    provinceId={_.get(value, 'province_id', '')}
                    value={_.get(value, 'district_id', '')}
                />

                {postalCodeVisible &&
                    <Input
                        onChange={onPostalCodeChange}
                        placeholder={i18n.t('districts:postalCode')}
                        value={_.get(value, 'postal_code', undefined)}
                    />
                }
            </SelectAddressWrapper>
        </React.Fragment>
    )
}

SelectAddress.propTypes = {
    onDistrictChange: PropTypes.func,
    onPostalCodeChange: PropTypes.func,
    onProvinceChange: PropTypes.func,
    postalCodeVisible: PropTypes.bool,
    value: PropTypes.object
}

SelectAddress.defaultProps = {
    onDistrictChange: () => {},
    onPostalCodeChange: () => {},
    onProvinceChange: () => {},
    value: {
        province_id: '',
        district_id: '',
        postalCodeVisible: false,
        postal_code: ''
    }
}

export default withTranslation(['districts'])(SelectAddress)
