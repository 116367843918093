import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { List, Map } from 'immutable'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Input, Table, Empty, Button, ConfigProvider, Tabs, Tag } from 'antd'

import customerActions from '../../redux/customers/actions'
import customerRoleActions from '../../redux/customers/roles/actions'
import CustomerRolesIndexContainer from './CustomerRoles/CustomerRolesIndexContainer'
import { isMobile } from 'react-device-detect'

const {
    search,
    clearItems,
    clearCustomerInfo
} = customerActions

const { fetchCustomerRoles } = customerRoleActions

class CustomersIndexContainer extends Component {
    componentDidMount () {
        this.props.fetchCustomerRoles()
    }

    componentWillUnmount () {
        this.props.clearItems()
    }

    handleTableChange = (pagination) => {
        const { search, customers } = this.props
        const page = pagination.current

        search(customers.get('query'), page)
    }

    renderSearchInput () {
        const { i18n, search, customers } = this.props

        return (
            <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <h3>{i18n.t('customerForm.searchCustomer')}</h3>
                <Input.Search placeholder={i18n.t('customerForm.searchCustomer')}
                    defaultValue={customers.get('query')}
                    onSearch={(value) => { search(value) }}
                    enterButton
                    disabled={customers.get('loading')}
                />
            </div>
        )
    }

    renderNoResult () {
        const { i18n, clearCustomerInfo } = this.props

        return (
            <Empty
                description={
                    <span>
                        {i18n.t('customerForm.customerNotFound')}
                    </span>
                }>
                <Link to={'/customers/new'}>
                    <Button type="primary" onClick={() => { clearCustomerInfo() }}>
                        {i18n.t('customerForm.createCustomer')}
                    </Button>
                </Link>
            </Empty>
        )
    }

    getCustomerRole = (customer) => {
        const { customerRoleEntities } = this.props
        const customerRole = customer.roles || new List()

        if (customerRole.length > 0) {
            const role = customerRole[0]
            const roleName = customerRoleEntities.getIn([role, 'name'])
            return (
                <Tag style={{ marginLeft: '2px' }}>{roleName}</Tag>
            )
        }
    }

    renderCustomerSearchResultsTable () {
        const { i18n, customers, customerEntities, customerCompanyEntities } = this.props
        const loading = customers.get('loading')
        const query = customers.get('query')
        const customerIds = customers.get('items')

        const searchResult = customers.get('items').map((id) => {
            return customerEntities.get(id)
        }) || new List()

        const columns = [{
            title: i18n.t('customerForm.customerName'),
            key: 'customerName',
            render: (row) => (
                <div>
                    <Link to={`/customers/${row.id}`}>
                        {row.first_name} {row.last_name || ''}
                    </Link>
                    {this.getCustomerRole(row)}
                </div>
            )
        }, {
            title: i18n.t('customerForm.phone'),
            dataIndex: 'phone_number'
        }, {
            title: i18n.t('customerForm.lineId'),
            dataIndex: 'line_id'
        }, {
            title: i18n.t('customerForm.email'),
            dataIndex: 'email'
        }, {
            title: i18n.t('customerForm.companyName'),
            dataIndex: 'customer_company',
            render: (companyId) => {
                const company = customerCompanyEntities.get(companyId, new Map()).toJS()
                return (
                    <div>
                        <b>{company.name}</b>
                        <div>{company.full_address}</div>
                    </div>
                )
            }
        }, {
            title: i18n.t('customerForm.action'),
            key: 'x',
            render: (row) =>
                (
                    <Link to={`/customers/${row.id}/orders/new`}>
                        {i18n.t('customerForm.addInquiry')}
                    </Link>
                )
        }]

        if (query === '' || customerIds.length < 1 && !loading) {
            return this.renderNoResult()
        } else {
            return (
                <ConfigProvider renderEmpty={() => this.renderNoResult()}>
                    <Table columns={columns}
                        scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                        dataSource={searchResult.toJS()}
                        loading={loading}
                        onChange={this.handleTableChange}
                        pagination={{
                            ...customers.get('pagination').toJS(),
                            showSizeChanger: false
                        }}
                        rowKey={customer => customer.id}
                    />
                </ConfigProvider>
            )
        }
    }


    render () {
        const { i18n } = this.props
        const { TabPane } = Tabs
        return (
            <Tabs defaultActiveKey="customer">
                <TabPane tab={i18n.t('customerForm.customerInfo')} key="customer">
                    {this.renderSearchInput()}
                    {this.renderCustomerSearchResultsTable()}
                </TabPane>
                <TabPane tab={i18n.t('customers/roles:userRoles')} key="role">
                    <CustomerRolesIndexContainer />
                </TabPane>
            </Tabs>
        )
    }
}

const mapStateToProps = state => ({
    App: state.get('App'),
    customers: state.get('customers'),
    customerEntities: state.getIn(['Entities', 'customers']),
    customerCompanyEntities: state.getIn(['Entities', 'customerCompanies']),
    customerRoleEntities: state.getIn(['Entities', 'customerRoles'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        search,
        clearItems,
        clearCustomerInfo,
        fetchCustomerRoles
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['common', 'customers/roles'])(CustomersIndexContainer))
