
import { Map, List } from 'immutable'
import actions from './actions'

import { mergeDeepReplaceArrays } from '../entities/reducers'

const {
    BANK_STATEMENTS_FETCH_DEFAULT_FILTERS,
    BANK_STATEMENTS_FETCH_FAIL,
    BANK_STATEMENTS_FETCH_SUCCESS,
    BANK_STATEMENTS_FETCHING,
    BANK_STATEMENTS_SET_IMPORT_VISIBLE_MODAL,
    BANK_STATEMENTS_SET_IMPORT_ADDITIONAL_UPDATE_MODAL,
    BANK_STATEMENTS_SET_IMPORT_SUPPLIER_INVOICES_VISIBLE_MODAL,
    BANK_STATEMENTS_UPLOAD_FAIL,
    BANK_STATEMENTS_UPLOAD_SUCCESS,
    BANK_STATEMENTS_UPLOADING,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS,
    BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING,
    BANK_STATEMENTS_EDIT_ORDER_MODAL,
    BANK_STATEMENTS_EDIT_RV_PV_MODAL,
    BANK_STATEMENTS_EDIT_JOURNAL_ENTRY_MODAL,
    ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS,
    BANK_STATEMENTS_CURRENT_MODAL_RECORD_TYPE,
    BANK_STATEMENTS_CURRENT_CHANGING_ORDER,
    BANK_STATEMENT_CHECK_TRANSACTION_TYPE,
    BANK_STATEMENT_SET_ID,
    BANK_STATEMENTS_SAVE_SUCCESS,
    BANK_STATEMENTS_SET_EXPORT_VISIBLE_MODAL,
    SET_DEFAULT_SELECT,
    HIDE_FORM_AFTER_SELECT_ADDITIONAL_UPDATE,
    BANK_STATEMENTS_SET_EXPORT_WITH_SUPPLIER_INVOICE_VISIBLE_MODAL,
    BANK_STATEMENTS_SAVING,
    BANK_STATEMENT_SUMMARIES_FETCH,
    BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS,
    BANK_STATEMENT_SUMMARIES_SET_PAGINATION,
    BANK_STATEMENT_SUMMARY_FETCH,
    BANK_STATEMENT_SUMMARY_FETCH_SUCCESS,
    BANK_STATEMENT_CURRENT_TRUCK_OWNER_ID,
    BANK_STATEMENTS_EDIT_TRUCK_OWNER_MODAL
} = actions

const initState = new Map({
    item: '',
    items: new List(),
    orderPaymentDetailItems: new List(),
    defaultSelect: new List(),
    loading: false,
    saving: false,
    editOrderModal: false,
    editRvPvModal: false,
    editJournalEntryModal: false,
    modalRecordType: null,
    changingOrder: null,
    transactionType: null,
    currentBankStatementId: null,
    hideBankSelect: false,
    editTruckOwnerModal: false,
    import: new Map({
        visibleModal: false
    }),
    importAdditionalUpdate: new Map({
        visibleModal: false
    }),
    importSupplierInvoices: new Map({
        visibleModal: false
    }),
    export: new Map({
        visibleModal: false,
        withSupplierInvoiceVisibleModal: false
    }),
    indexPage: new Map({
        filters: BANK_STATEMENTS_FETCH_DEFAULT_FILTERS,
        totalPages: 0
    }),
    bankStatementCacheItems: new List(),
    bankStatementCachePagination: new Map({
        current: null,
        pageSize: 20,
        total: null
    }),
    bankStatementSummary: new Map({
        loading: false
    })
})

export default function bankStatementsReducer (state = initState, action) {
    const items = state.get('items', new List())
    const result = action.result

    switch (action.type) {
        case BANK_STATEMENTS_FETCH_FAIL:
            return state.merge({ loading: false })
        case BANK_STATEMENTS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                items: result,
                loading: false,
                indexPage: {
                    filters: { ...action.filters },
                    totalPages: action.totalPages
                }
            })
        case BANK_STATEMENTS_FETCHING:
            return state.merge({ loading: true })
        case BANK_STATEMENTS_SET_IMPORT_VISIBLE_MODAL:
            return state.mergeDeep({
                import: {
                    visibleModal: action.visibleModal
                }
            })
        case BANK_STATEMENTS_SET_IMPORT_ADDITIONAL_UPDATE_MODAL:
            return state.mergeDeep({
                importAdditionalUpdate: {
                    visibleModal: action.visibleModal
                }
            })
        case BANK_STATEMENTS_SET_IMPORT_SUPPLIER_INVOICES_VISIBLE_MODAL:
            return state.mergeDeep({
                importSupplierInvoices: {
                    visibleModal: action.visibleModal
                }
            })
        case BANK_STATEMENTS_SET_EXPORT_VISIBLE_MODAL:
            return state.mergeDeep({
                export: {
                    visibleModal: action.visibleModal
                }
            })
        case BANK_STATEMENTS_SET_EXPORT_WITH_SUPPLIER_INVOICE_VISIBLE_MODAL:
            return state.mergeDeep({
                export: {
                    withSupplierInvoiceVisibleModal: action.withSupplierInvoiceVisibleModal
                }
            })
        case BANK_STATEMENTS_UPLOAD_FAIL:
            return state.merge({ saving: false })
        case BANK_STATEMENTS_UPLOAD_SUCCESS:
            return state.merge({ saving: false })
        case BANK_STATEMENTS_UPLOADING:
            return state.merge({ saving: true })
        case  BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_FAIL:
            return state.merge({ saving: false })
        case  BANK_STATEMENT_SUPPLIER_INVOICES_UPLOAD_SUCCESS:
            return state.merge({ saving: false })
        case  BANK_STATEMENT_SUPPLIER_INVOICES_UPLOADING:
            return state.merge({ saving: true })
        case BANK_STATEMENTS_EDIT_ORDER_MODAL:
            return state.merge({ editOrderModal: action.value })
        case BANK_STATEMENTS_EDIT_RV_PV_MODAL:
            return state.merge({ editRvPvModal: action.value })
        case BANK_STATEMENTS_EDIT_JOURNAL_ENTRY_MODAL:
            return state.merge({ editJournalEntryModal: action.value })
        case ORDER_PAYMENT_DETAIL_QUERY_FETCH_SUCCESS:
            return state.merge({ orderPaymentDetailItems: new List(result) })
        case BANK_STATEMENTS_CURRENT_MODAL_RECORD_TYPE:
            return state.merge({ modalRecordType: action.value })
        case BANK_STATEMENTS_CURRENT_CHANGING_ORDER:
            return state.merge({ changingOrder: action.value })
        case BANK_STATEMENT_CHECK_TRANSACTION_TYPE:
            return state.merge({ transactionType: action.value })
        case BANK_STATEMENT_SET_ID:
            return state.merge({ currentBankStatementId: action.value })
        case BANK_STATEMENTS_SAVING:
            return state.merge({ saving: action.saving })
        case BANK_STATEMENTS_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(result) ? items : items.push(result)
            })
        case SET_DEFAULT_SELECT:
            return state.merge({
                defaultSelect: action.defaultSelect
            })
        case HIDE_FORM_AFTER_SELECT_ADDITIONAL_UPDATE:
            return state.merge({
                hideBankSelect: action.value
            })
        case BANK_STATEMENT_SUMMARIES_FETCH:
            return state.merge({ loading: true })
        case BANK_STATEMENT_SUMMARIES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                bankStatementCacheItems: result
            })
        case BANK_STATEMENT_SUMMARIES_SET_PAGINATION:
            return state.mergeDeep({
                bankStatementCachePagination: action.pagination
            })
        case BANK_STATEMENT_SUMMARY_FETCH:
            return state.mergeDeep({
                bankStatementSummary: new Map({
                    ...state.get('bankStatementSummary').toJS(),
                    loading: true
                })
            })
        case BANK_STATEMENT_SUMMARY_FETCH_SUCCESS:
            return state.mergeDeep({
                bankStatementSummary: new Map({
                    ...action.bankStatementSummary,
                    loading: false
                })
            })

        case BANK_STATEMENT_CURRENT_TRUCK_OWNER_ID:
            return state.merge({ currentTruckOwnerId: action.value })

        case BANK_STATEMENTS_EDIT_TRUCK_OWNER_MODAL:
            return state.merge({ editTruckOwnerModal: action.value })
        default:
            return state
    }
}
