import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'

import { Comment, Tooltip, Divider, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import Image from '../../../containers/Image'
import withPreviewImage from '../../../containers/ImagePreview/ImagePreviewContainer'

const renderHtmlString = (htmlString) => {
  return new ReactHtmlParser(
      htmlString,
  )
}

const renderNoteImages = (images) => {
  return images.map((image) => {
      return (
          <Image key={image.id}
              isImageBlob={false}
              originalUrl={image.original}
              thumbUrl={image.small}
              contentType={image.contentType}
          />
      )
  })
}

const renderOrderNoteDetail = (note, i18n) => {
  return (
    <div style={{ color: '#989ca7'}}>
        <div>
            {`${i18n.t('customers/notes:form.source')}: ${note.sourceType || ''}`}
        </div>

        <div>
            {`${i18n.t('customers/notes:form.typeContact')}: ${note.typeContact || ''}`}
        </div>
    </div>
  )
}

const renderContent = (props, note, i18n) => {
  const { isOrderNote } = props

  return (
      <div>
          { isOrderNote ? renderOrderNoteDetail(note, i18n) : null }
          <div>
              {renderHtmlString(note.note)}
          </div>

          <div>
              {renderNoteImages(note.images || [])}
          </div>
          <Divider style={{  marginBottom: '0px' }} />
      </div>
  )
}

const renderAuthor = (note) => {
  return (
      <a>
          {note.staff ? note.staff.firstName : null}
      </a>
  )
}

const renderlistComments = (props, i18n) => {
  const { notes } = props

  return notes.map((note, index) => {
      return (
            <Comment author={renderAuthor(note)}
                avatar={ <Avatar icon={<UserOutlined />} />}
                content={renderContent(props, note, i18n)}
                datetime={(
                    <Tooltip title={note.createdAt.display}>
                        <span>{moment(note.createdAt.raw).fromNow()}</span>
                    </Tooltip>
                )} />
      )
  })
}

const CommentsList = (props) => {
  const { i18n } = useTranslation('customers/notes')

  return (
    <div>
      {renderlistComments(props, i18n)}
    </div>
  )
}

CommentsList.propTypes = {
  notes: PropTypes.array,
  isOrderNote: PropTypes.bool
}

CommentsList.defaultProps = {
  isOrderNote: false
}

export default withPreviewImage(CommentsList)
