import React from 'react'

import { ThunderboltOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { siteConfig } from '../../../config'

const Logo = ({ collapsed }) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to={siteConfig.dashboard}>
                            <ThunderboltOutlined />
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link to={siteConfig.dashboard}>
                        {siteConfig.siteName}
                    </Link>
                </h3>
            )}
        </div>
    )
}

export default Logo
