import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link, withRouter } from 'react-router-dom'
import { Map, List, fromJS as ImmutableFromJS } from 'immutable'
import { isMobile } from 'react-device-detect'

import { Button, Col, Divider, Empty, Form, InputNumber, Layout, Modal, Row, Select, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import withPreviewImage from '../ImagePreview/ImagePreviewContainer'
import Image from '../Image'
import ItemFilters from './filter'
import AddItemsForm from './AddItemsForm'
import ItemsWrapper from './items.style'

import inquiryActions from '../../redux/inquiry/actions'

const {
    fetchInquiryItems,
    setSelectLineItem,
    setAddRequestItemForm,
    fetchInquiryLineItems,
    fetchInquiry,
    saveOrderLineItems,
    deleteOrderLineItem,
    setRequestItemId,
    onUnitChange
} = inquiryActions

class ItemsContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchInquiryItems, fetchInquiryLineItems, orderId, fetchInquiry } = this.props
        fetchInquiryItems()
        fetchInquiry(orderId)
        fetchInquiryLineItems(orderId, undefined, true)
    }

    state = {
        selectedRowKeys: [],
        selectedRows: []
    }

    addSelectLineItem = (selectedRowKeys, selectedRows, itemId, quantityMultiplier) => {
        const { inquiry, setSelectLineItem } = this.props
        this.items = []
        this.newSelect = []
        const selectLineItem = inquiry.get('selectLineItem')
        // selected items without new item
        this.itemSelect = selectLineItem.filter((item) => {
            return selectedRowKeys.includes(item.get('item_external_id')) && item.get('item_external_id') !== itemId
        })
        // add new item to selected items
        selectLineItem.map((item) => {
            this.items[this.items.length] = item.get('item_external_id')
        })
        this.itemKeys = selectedRowKeys.filter(key => !this.items.includes(key) || key === itemId) //selected not in store
        this.itemData = selectedRows.filter(item => this.itemKeys.includes(item.key)) //selected data not in store

        this.itemData.map((item) => {
            let value
            if (parseInt(item.quantity, 10)) {
                value = item.quantity
            } else {
                value = item.quantity.key
            }
            this.newSelect = [...this.newSelect, {
                id: this.findIdOfLineItem(item.key) || null,
                item_external_id: item.key,
                single_price: item.price,
                price_type: 'normal',
                quantity: value || 1,
                quantityAfterMultiplier: this.calculateQuantityMultiplier(value || 1, quantityMultiplier),
                weight: item.weight,
                name: item.name,
                name_th: item.name_th,
                name_en: item.name_en,
                cost: item.cost || 0,
                unit: item.defaultUnitName
            }]
        })
        setSelectLineItem(ImmutableFromJS([...this.itemSelect, ...this.newSelect]))
    }

    calculatePrice = (eventValue, item) => {
        const { onUnitChange } = this.props
        const { selectedRowKeys, selectedRows } = this.state
        const id = item.get('id')
        const price = item.get('price')
        const quantityDefault = eventValue[0]
        const quantityAlternative = eventValue[1]
        const modifier = quantityDefault / quantityAlternative
        // here is the logic which get value of row that we select and push it on the state
        // when user try to change unit I will set quantity to 1 and oblige user to fill quantity field again
        if (selectedRowKeys.includes(id)) {
            this.rowData = [
                ...selectedRows.filter((item) => { return item.key !== id }),
                {
                    key: id,
                    quantity: 1,
                    price: price,
                    name: item.get('name'),
                    name_th: item.get('name_th', null),
                    name_en: item.get('name_en', null),
                    defaultUnitName: item.get('unit'),
                    cost: item.get('cost'),
                    weight: item.get('weight') || 0
                }
            ]
            this.rowKeys = [...selectedRowKeys.filter((key) => { return key !== id }), id]
        } else {
            this.rowData = [...selectedRows,
                {
                    key: id,
                    quantity: 1,
                    price: price,
                    name: item.get('name'),
                    name_th: item.get('name_th', null),
                    name_en: item.get('name_en', null),
                    defaultUnitName: item.get('unit'),
                    cost: item.get('cost'),
                    weight: item.get('weight') || 0
                }
            ]
            this.rowKeys = [...selectedRowKeys, id]
        }
        this.addSelectLineItem(this.rowKeys, this.rowData, id, modifier)
        this.setState({
            selectedRowKeys: this.rowKeys,
            selectedRows: this.rowData
        })
        const newPriceAfterChangeUnit = {
            itemId: id,
            newPrice: price * modifier
        }
        onUnitChange(newPriceAfterChangeUnit, modifier)
    }

    calculateQuantityMultiplier = (value, quantityMultiplier) => {
        if (!quantityMultiplier) { return value * 1 }

        return value * quantityMultiplier
    }

    findIdOfLineItem = (itemExternalId) => {
        const { inquiry, lineItemEntities } = this.props
        const lineItems = inquiry.get('lineItems')

        return lineItems.find((lineItemId) => {
            return lineItemEntities.getIn([lineItemId.toString(), 'item_external_id']) === `${itemExternalId}`
        })
    }

    getQuantity = (value, itemId, item) => {
        const { selectedRowKeys, selectedRows } = this.state
        const quantityMultiplier = item.get('quantityMultiplier')

        // selectedRowKeys without new item
        this.rowKeys = selectedRowKeys.filter((key) => { return key !== itemId})
        // selectedRows without new item
        this.rowData = selectedRows.filter((item) => { return item.key !== itemId})


        // add item to selectedRowKeys/selectedRows
        if (value > 0 && value !== undefined) {
            this.rowKeys = [...this.rowKeys, itemId]
            this.rowData = [
                ...this.rowData,
                {
                    key: itemId,
                    quantity: value,
                    price: item.get('price'),
                    quantityMultiplier: quantityMultiplier || 1,
                    name: item.get('name'),
                    name_th: item.get('name_th', null),
                    name_en: item.get('name_en', null),
                    defaultUnitName: item.get('unit'),
                    cost: item.get('cost'),
                    weight: item.get('weight') || 0
                }
            ]
        }

        this.addSelectLineItem(this.rowKeys, this.rowData, itemId, quantityMultiplier)
        this.setState({
            selectedRowKeys: this.rowKeys,
            selectedRows: this.rowData
        })
    }

    handleAddItem = () => {
        const { inquiry, history, orderId, saveOrderLineItems } = this.props
        const countLineItems = inquiry.get('lineItems').size

        inquiry.get('selectLineItem').map((lineItem, index) => {
            saveOrderLineItems(
                orderId,
                lineItem.mergeDeep({
                    quantity: lineItem.get('quantityAfterMultiplier'),
                    position: countLineItems + index + 1
                })
            )
        })

        history.push(`/inquiries/${orderId}`)
    }

    handleCancelModal = () => {
        this.props.setAddRequestItemForm(false)
    }

    handleClearItem = () => {
        this.setState({ selectedRowKeys: [], selectedRows: [] })
        this.addSelectLineItem([], [])
    }

    handleCreate = (e) => {
        const { form, inquiry, orderId, saveOrderLineItems } = this.props
        const itemId = inquiry.get('requestItemId')

        e.preventDefault()

        form.validateFields((err, values) => {
            if (!err) {
                saveOrderLineItems(orderId, values, itemId)
            }
        })

        form.resetFields()
    }

    handleDeleteLineItem = (itemId) => {
        const { deleteOrderLineItem, orderId } = this.props

        deleteOrderLineItem(orderId, itemId)
    }

    handleItemsPagination = (pagination) => {
        this.props.fetchInquiryItems(pagination.current)
    }

    handleRequestItem = (value, itemId) => {
        const { setAddRequestItemForm, setRequestItemId } = this.props

        setAddRequestItemForm(!value)
        setRequestItemId(itemId)
    }

    handleSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys, selectedRows
        })
        this.addSelectLineItem(selectedRowKeys, selectedRows)
    }

    itemColumns = () => {
        return [{
            title: 'Name',
            dataIndex: 'name',
            width: 500,
            render: (text, record) =>(
                <div>
                    <div>{text}</div>
                    <div>{record.displaySize}</div>
                </div>
            )
        }, {
            title: 'Images',
            width: 80,
            render: (item) => (
                <div className="image-container">
                    {this.renderImage(item)}
                </div>
            )
        }, {
            title: 'Cost',
            dataIndex: 'cost',
            width: 120
        }, {
            title: 'Price',
            dataIndex: 'price',
            width: 120
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 110
        }, {
            title: 'Unit',
            dataIndex: 'unit',
            width: 150
        }]
    }

    itemDataSource = () => {
        const { inquiry, itemEntities } = this.props
        const items = inquiry.get('items').map((item) => {
            const itemEntity = itemEntities.get(item)
            let value

            inquiry.get('selectLineItem').map((itm) => {
                if (itm && item === itm.get('item_external_id')) {
                    value = itm.get('quantity')
                }
            })

            return new Map({
                key: item,
                name: itemEntity.get('name'),
                name_th: itemEntity.get('name_th', null),
                name_en: itemEntity.get('name_en', null),
                image: itemEntity.get('image') && itemEntity.get('image').toJS(),
                images: itemEntity.get('images') && itemEntity.get('images').toJS(),
                displaySize: itemEntity.get('display_size'),
                cost: itemEntity.get('cost'),
                price: itemEntity.get('newPrice') || itemEntity.get('price'),
                weight: itemEntity.get('weight') || 0,
                defaultUnitName: itemEntity.get('unit'),
                quantity: (
                    <InputNumber min={0}
                        onBlur={(e) => this.getQuantity(e.target.value, item, itemEntity)}
                        type="text"
                        key={value}
                        defaultValue={value}/>),
                unit: this.renderSelectUnitOptions(itemEntity)
            })
        })

        return items.toJS()
    }

    renderImage = (item) => {
        if (!item) { return null }

        const { image } = item

        if (!image) { return null }

        return (
            <Image
                key={image.id}
                contentType={image.content_type}
                originalUrl={image.original}
                thumbUrl={image.small}
                isImageBlob={false}
            />
        )
    }

    renderRequestItem = () => {
        const { i18n, inquiry, lineItemEntities } = this.props
        const showAddItemForm = inquiry.get('showAddItemForm')
        const lineItems = inquiry.get('lineItems')

        const onlyRequestLineItem = lineItems.filter((lineItemId) => {
            return !lineItemEntities.getIn([lineItemId, 'item'])
        })

        const requestItemsList = onlyRequestLineItem.map((itemId, index) => {
            const lineItem = lineItemEntities.get(itemId)
            const moneyDisplay = lineItem.get('money_display')

            return (
                <div key={index}>
                    <Row>
                        <Col span={10}>
                            <div>{lineItem.get('name')}</div>
                            <div>
                                {lineItem.get('weight')}
                                <span style={{ marginLeft: '10px' }}>กก.</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <Col span={12}>{moneyDisplay.get('single_price')}</Col>
                            <Col span={10}>บาท</Col>
                        </Col>
                        <Col span={4}>
                            <Col span={12}>{lineItem.get('quantity')}</Col>
                            <Col span={10}>{lineItem.get('unit')}</Col>
                        </Col>
                        <Col span={4}>
                            <h4 style={{ color: '#40a9ff' }}>{moneyDisplay.get('total_price')} บาท</h4>
                        </Col>
                        <Col span={2}>
                            <Link onClick={() => this.handleDeleteLineItem(itemId)}>
                                <DeleteOutlined />
                            </Link>
                        </Col>
                    </Row>
                    <Divider style={{ margin: '10px 0' }}/>
                </div>
            )
        })

        if (onlyRequestLineItem.size === 0) {
            return (
                <Empty style={{ marginTop: '30px' }}
                    description={<span>หาข้อมูลสินค้าไม่เจอ</span>}>
                    <Button type="primary" onClick={() => this.handleRequestItem(showAddItemForm)}>
                        {i18n.t('inquiries:addItem')}
                    </Button>
                </Empty>
            )
        }

        return (
            <div style={{ marginTop: '30px' }}>
                <h2>{i18n.t('inquiries:requestItemList')}</h2>
                <Col span={12}>
                    <Row>
                        <Col span={10}>
                            <h4>รายละเอียดสินค้า</h4>
                        </Col>
                        <Col span={4}>
                            <h4>ราคาปลีก</h4>
                        </Col>
                        <Col span={4}>
                            <h4>จำนวนซื้อ</h4>
                        </Col>
                        <Col span={4}>
                            <h4>มูลค่ารวม</h4>
                        </Col>
                        <Col span={2}></Col>
                    </Row>
                    <Divider style={{ margin: '10px 0' }}/>
                    {requestItemsList}
                    <Button type="primary" onClick={() => this.handleRequestItem(showAddItemForm)}>
                        {i18n.t('inquiries:addItem')}
                    </Button>
                </Col>
            </div>
        )
    }

    renderSelectUnitOptions = (item) => {
        return (
            <Select style={{ width: 100 }}
                defaultValue={item.get('unit')}
                onSelect={(e) => this.calculatePrice(e, item)}
            >
                {this.renderUnitOptions(item.get('unit_options'), item.get('unit'))}
            </Select>
        )
    }

    // so this function I try to concat alternativeUnit and its options together
    renderUnitOptions = (unitOptions, defaultUnit) => {
        // this is the default unit
        let unitOptionsList = new List()
        unitOptionsList = unitOptionsList.push(new Map({
            name: defaultUnit,
            quantity_default: 1,
            quantity_alternative: 1
        }))

        if (unitOptions) { unitOptionsList = unitOptionsList.concat(unitOptions) }

        return unitOptionsList.map((unitOption, index) => {
            const quantityAlternative = unitOption.get('quantity_alternative')
            const quantityDefault = unitOption.get('quantity_default')
            return (
                <Select.Option key={`unit_option_${index}`} value={[quantityDefault, quantityAlternative]}>
                    {unitOption.get('name')}
                </Select.Option>
            )
        })
    }

    requestItemsFormModal = () => {
        const { form, i18n, inquiry } = this.props

        return (
            <Modal
                width="70%"
                visible={inquiry.get('showAddItemForm')}
                onOk={this.handleCreate}
                onCancel={this.handleCancelModal}
                footer={[
                    <Button key="back" onClick={this.handleCancelModal}>{i18n.t('inquiries:cancel')}</Button>,
                    <Button key="submit" type="primary" onClick={this.handleCreate}>
                        {i18n.t('inquiries:addItem')}
                    </Button>
                ]}>
                <Form>
                    <AddItemsForm
                        form={form}
                        errors={inquiry.get('lineItemformErrors').toJS()}
                        lineItemId={inquiry.get('requestItemId')} />
                </Form>
            </Modal>
        )
    }

    searchResult = (rowSelection, selectedRowKeys) => {
        const { inquiry } = this.props
        const loading = inquiry.get('itemsLoading')

        if (inquiry.get('items').size < 1 && !loading) { return this.renderRequestItem() }

        return (
            <ItemsWrapper>
                <div style={{ padding: '24px' }}>
                    <Button type="primary"
                        style={{ marginRight: '4px' }}
                        onClick={this.handleAddItem}
                        loading={inquiry.get('lineItemsSaving')} >
                        {`Add ${selectedRowKeys.length} item(s)`}
                    </Button>
                    <Button onClick={this.handleClearItem}>Clear selected item(s)</Button>
                </div>
                <Table
                    dataSource={this.itemDataSource()}
                    rowSelection={rowSelection}
                    pagination={{
                        ...inquiry.get('itemsPagination').toJS(),
                        showSizeChanger: false
                    }}
                    onChange={this.handleItemsPagination}
                    columns={this.itemColumns()}
                    loading={loading}
                    scroll={isMobile ? { x: 1300 } : { x: 1080 }}
                />
            </ItemsWrapper>
        )
    }

    render () {
        const { orderEntities, orderId } = this.props
        const { selectedRowKeys, selectedRows } = this.state
        const orderEntity = orderEntities.get(orderId)
        const rowSelection = {
            selectedRowKeys,
            selectedRows,
            onChange: this.handleSelectChange
        }

        return (
            <div>
                <div style={{ position: 'fixed', top: 10, left: 20 }}>
                    {orderEntity ? orderEntity.get('detail') : 'Loading...'}
                </div>

                <Layout style={{ background: '#fff' }}>
                    <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
                        <ItemFilters />
                        {this.searchResult(rowSelection, selectedRowKeys)}
                        {this.requestItemsFormModal()}
                    </Layout.Content>
                </Layout>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    inquiry: state.get('inquiry'),
    itemEntities: state.getIn(['Entities', 'items']),
    lineItemEntities: state.getIn(['Entities', 'lineItems']),
    orderEntities: state.getIn(['Entities', 'orders'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteOrderLineItem,
        fetchInquiry,
        fetchInquiryItems,
        fetchInquiryLineItems,
        onUnitChange,
        saveOrderLineItems,
        setAddRequestItemForm,
        setRequestItemId,
        setSelectLineItem,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('inquiries')(withPreviewImage(withRouter(ItemsContainer)))
)
