import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Input, Table } from 'antd'

const renderSearchSection = (showSearch, onSearch) => {
    if (!showSearch) { return null}

    return (
        <>
            <Input.Search
                placeholder="input search text"
                onSearch={onSearch}
                enterButton />
            <Divider />
        </>
    )
}

const CustomerWalletsList = ({
    columns,
    customers,
    loading,
    onSearch,
    onTableChange,
    pagination,
    showSearch
}) => {
    return (
        <div>
            {renderSearchSection(showSearch, onSearch)}
            <Table
                columns={columns}
                dataSource={customers}
                loading={loading}
                onChange={onTableChange}
                pagination={pagination}
                rowKey="id"
            />
        </div>
    )
}

CustomerWalletsList.propTypes = {
    customers: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onTableChange: PropTypes.func,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    showSearch: PropTypes.bool
}

CustomerWalletsList.defaultProps = {}

export default CustomerWalletsList
