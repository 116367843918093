import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    Select,
    Spin
} from 'antd'

import { withTranslation } from 'react-i18next'

const SelectBrand = ({
    i18n,
    defaultValue,
    loading,
    onChange,
    onSearch,
    brands,
    value,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder="Search Brand"
            showArrow={false}
            showSearch
            style={restSelectProps.style || { width: '200px' }}
            value={value}
            {...restSelectProps}
        >
            {brands.map((brand, index) => {
                return (
                    <Select.Option
                        key={_.get(brand, 'id', `index-${index}`)}
                        value={_.get(brand, 'id', undefined)}
                    >
                        {_.get(brand, `name_${i18n.language}`, '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectBrand.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    suppliers: PropTypes.array,
    value: PropTypes.string
}

SelectBrand.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    suppliers: [],
    value: undefined
}

export default withTranslation(['suppliers'])(SelectBrand)

