import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { List } from 'immutable'
import { useTranslation } from 'react-i18next'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import Image from '../../../Image'
import ImagePreviewContainer from '../../../ImagePreview/ImagePreviewContainer'

const ShippingDetail = ({ shippingType, order, customer }) => {
    const { i18n } = useTranslation(['inquiries', 'addresses'])

    const renderSelfPickupInfo = () => {
        const customerName = `${customer.get('first_name')} ${customer.get('last_name') || ''}`
        const customerPhoneNumber = customer.get('phone_number')

        return (
            <div>
                <strong>{i18n.t('order.selfPickup')}</strong>{' '}
                {order.getIn(['mh_branch', 'name']) ? order.getIn(['mh_branch', 'name']) : null}
                <div>{customerName}</div>
                {order.get('pickup_date') && (
                    <div>
                        <span style={{ paddingRight: '4px' }}>{i18n.t('order.pickupDate')}:</span>
                        <span>{moment(order.get('pickup_date')).format('LL')}</span>
                    </div>
                )}
                {order.get('pickup_license_plate_number') && (
                    <div>
                        <span style={{ paddingRight: '4px' }}>{i18n.t('order.pickupLicensePlateNumber')}:</span>
                        <span>{order.get('pickup_license_plate_number')}</span>
                    </div>
                )}
                {customerPhoneNumber && (
                    <div>
                        <span>{customerPhoneNumber}</span>
                    </div>
                )}
                <div style={{color: 'red'}}>
                    กรณีจะส่งที่อยู่โรงงาน โปรดเช็คกับ SCM (scg/shera/qcon ฯลฯ ส่งได้) เพราะว่าเราไม่ได้อยากให้เค้ารู้แหล่งซื้อเรา สู้ๆนะครับ/คะ
                </div>
            </div>
        )
    }

    const renderLinkGoogleLinkUrl = (googleLink) => {
        if (!googleLink) {
            return null
        }

        return (
            <div>
                <a href={googleLink} target={'_blank'}>
                    {googleLink}
                </a>
                <CopyToClipboard text={googleLink}>
                    <Button type='dashed' icon={<CopyOutlined />} shape='round' size='small' style={{ marginLeft: 8 }}>
                        Link
                    </Button>
                </CopyToClipboard>
            </div>
        )
    }

    const renderShippingImages = (orderShippingAddress) => {
        const imageList = orderShippingAddress.get('order_shipping_address_images') || new List()

        return imageList.map((image) => {
            const thumbUrl = image.getIn(['file', 's']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            return <Image key={thumbUrl} contentType={contentType} originalUrl={originalUrl} thumbUrl={thumbUrl} />
        })
    }

    const renderShippingAddressInfo = () => {
        const customerName = `${customer.get('first_name')} ${customer.get('last_name') || ''}`

        if (order.get('order_shipping_address')) {
            const orderShippingAddress = order.get('order_shipping_address')
            return (
                <span>
                    {orderShippingAddress.get('shipping_detail')}
                    <div>
                        {`${orderShippingAddress.getIn(['district', 'name'])} ${orderShippingAddress.getIn([
                            'province',
                            'name'
                        ])}, ${orderShippingAddress.get('postal_code')}`}
                    </div>
                    {orderShippingAddress.get('additional_information')}
                    {renderLinkGoogleLinkUrl(orderShippingAddress.get('link_url'))}
                    <div>{orderShippingAddress.get('contact_name') || customerName}</div>
                    {orderShippingAddress.get('phone_number') && (
                        <div>
                            <span>{orderShippingAddress.get('phone_number')}</span>
                        </div>
                    )}
                    <div className='shipping-image-container'>{renderShippingImages(orderShippingAddress)}</div>
                </span>
            )
        }

        return <span>-</span>
    }

    const renderShippingDetail = () => {
        switch (shippingType) {
            case 'self_pickup':
                return renderSelfPickupInfo()
            case 'not_specified_address':
                return <strong>{i18n.t('order.unspecifiedAddress')}</strong>
            case 'export':
                return (
                    <span>
                        <strong>ส่งออก</strong>
                        <div>ประเทศ: {order.getIn(['order_export_shipment', 'country_name'])}</div>
                    </span>
                )
            case 'by_osh':
                return renderShippingAddressInfo()
            default:
                return <span>-</span>
        }
    }

    return <div>{renderShippingDetail()}</div>
}

ShippingDetail.propTypes = {
    shippingType: PropTypes.string,
    order: PropTypes.object,
    customer: PropTypes.object
}

ShippingDetail.defaultProps = {}

export default ImagePreviewContainer(ShippingDetail)
