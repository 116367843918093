import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const TRUCK_ORIGIN_ADDRESSES_QUERY = gql`
    query TruckOriginAddresses($query: String) {
        truckOriginAddresses(query: $query, limit: 10)  {
            id
            name
            detail
        }
    }
`

const renderOptions = options => options.map(({ id, name }) => {
    return <Select.Option value={id}>{name}</Select.Option>
})

const TruckOriginAddressSelector = ({ onChange, onChangeEvent, ...restProps }) => {
    const [query, setQuery] = useState('')
    const { loading, data, error } = useQuery(TRUCK_ORIGIN_ADDRESSES_QUERY, {
        variables: { query }
    })

    if (error) return console.error(error)

    const options = (data && data.truckOriginAddresses) || []

    const handleChange = (value) => {
        onChange(value)
        onChangeEvent(options.find(option => option.id === value))
    }

    const handleSearch = value => {
        setQuery(value)
    }

    return (
        <Select
            allowClear
            loading={loading}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            showSearch
            {...restProps}
        >
            {renderOptions(options)}
        </Select>
    )
}

TruckOriginAddressSelector.propTypes = {
    onChange: PropTypes.func,
    onChangeEvent: PropTypes.func
}

TruckOriginAddressSelector.defaultProps = {
    onChange: () => {},
    onChangeEvent: () => {}
}

export default TruckOriginAddressSelector
