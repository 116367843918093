import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import OrderYieldListTable from '../../../components/orderYields/Table'

class OrderYieldListTableContainer extends Component {
    static propTypes = {
        // Props from another Component
        fetchOrderYields: PropTypes.func,

        // Inner Props
        orderCompleteStateEntities: ImmutablePropTypes.map.isRequired,
        orderEntities: ImmutablePropTypes.map.isRequired,
        orderYieldEntities: ImmutablePropTypes.map.isRequired,
        orderYields: ImmutablePropTypes.map.isRequired,
        staffEntities: ImmutablePropTypes.map.isRequired,
        orderVoucherCheckEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        fetchOrderYields: () => {}
    }

    getOrderYields () {
        const {
            orderCompleteStateEntities,
            orderEntities,
            orderVoucherCheckEntities,
            orderYieldEntities,
            orderYields,
            staffEntities
        } = this.props

        return orderYields.get('items', new List()).map((id) => {
            const orderYield = orderYieldEntities.get(_.toString(id), new Map())
            const completeState = orderCompleteStateEntities.get(
                _.toString(orderYield.get('complete_state')), new Map()
            )
            const order = orderEntities.get(_.toString(orderYield.get('order')), new Map())
            const pvVoucherChecked = orderVoucherCheckEntities.get(_.toString(order.get('pv_voucher_checked')), new Map())
            const rvVoucherChecked = orderVoucherCheckEntities.get(_.toString(order.get('rv_voucher_checked')), new Map())

            return orderYield.mergeDeep({
                complete_state: completeState.mergeDeep({
                    staff: staffEntities.get(_.toString(completeState.get('staff')), new Map())
                }),
                order: order.mergeDeep({
                    pv_voucher_checked: pvVoucherChecked.mergeDeep({
                        checked_by: staffEntities.get(_.toString(pvVoucherChecked.get('checked_by')), new Map())
                    }),
                    rv_voucher_checked: rvVoucherChecked.mergeDeep({
                        checked_by: staffEntities.get(_.toString(rvVoucherChecked.get('checked_by')), new Map())
                    })
                })
            })
        })
    }

    getPagination () {
        const { orderYields } = this.props

        return {
            current: orderYields.get('page', 1),
            pageSize: orderYields.get('per', 20),
            total: orderYields.get('totalPages', 0) * orderYields.get('per', 20),
            showSizeChanger: false
        }
    }

    onChange = (pagination) => {
        const { fetchOrderYields } = this.props

        fetchOrderYields({
            page: _.get(pagination, 'current', undefined),
            per: _.get(pagination, 'pageSize', undefined)
        })
    }

    render () {
        const { orderYields } = this.props

        return (
            <OrderYieldListTable
                loading={orderYields.get('loading', false)}
                onChange={this.onChange}
                orderYields={this.getOrderYields().toJS()}
                pagination={this.getPagination()}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderCompleteStateEntities: state.getIn(['Entities', 'orderCompleteStates']),
        orderEntities: state.getIn(['Entities', 'orders']),
        orderYieldEntities: state.getIn(['Entities', 'orderYields']),
        orderYields: state.get('orderYields'),
        staffEntities: state.getIn(['Entities', 'staffs']),
        orderVoucherCheckEntities: state.getIn(['Entities', 'orderVoucherChecks'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderYieldListTableContainer)
