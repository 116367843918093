import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { gql, useQuery, useLazyQuery } from '@apollo/client'

import JournalEntryFormatsIndex from '../../components/journalEntryFormats/JournalEntryFormatsIndex'
import actions from '../../redux/journalEntryFormats/actions'

const { deleteJournalEntryFormat, setJournalEntryFormatModal } = actions

const JOURNAL_ENTRY_FORMATS_QUERY = gql`
    query JournalEntryFormats($page: Int, $per: Int) {
        journalEntryFormats(page: $page, per: $per) {
          totalPages
          journalEntryFormats {
            id
            name
            description
            triggerType
            triggerTypeName
            triggerDate
            triggerDateName
            journalEntryFormatItems {
              id
              formula
              accountType
              chartOfAccount {
                accountName
              }
            }
            images {
              id
              uid
              url
              thumbUrl
            }
          }
        }
    }
`

const JOURNAL_ENTRY_FORMAT_QUERY = gql`
    query JournalEntryFormat($id: ID!) {
        journalEntryFormat(id: $id) {
          id
          journalEntryFormatItems {
            id
            formula
            accountType
            chartOfAccount {
              accountName
            }
          }
        }
    }
`

const JournalEntryFormatsIndexContainer = () => {
    const [page, setCurrentPage] = useState(1)
    const pageSize = 20
    const dispatch = useDispatch()
    const showFormatForm = useSelector(state => state.getIn(['journalEntryFormats', 'showFormatForm']))
    const formatIdSelected = useSelector(state => state.getIn(['journalEntryFormats', 'formatIdSelected']))

    const { data, loading, refetch } = useQuery(JOURNAL_ENTRY_FORMATS_QUERY, {
      variables: { page, per: pageSize }
    })


    const[fetchJournalEntryFormat, { data: itemsData, loading: itemsLoading  }] = useLazyQuery(JOURNAL_ENTRY_FORMAT_QUERY)

    const handleTableChange = (pagination) => {
      setCurrentPage(pagination.current)
    }

    const handleDeleteJournalEntryFormat = (id) => {
        dispatch(deleteJournalEntryFormat({ id, onSuccess: refetch }))
    }

    const toggleJournalEntryFormatForm = ({ selected = null } = {}) => {
      dispatch(setJournalEntryFormatModal({ visible: !showFormatForm, selected }))
    }

    const handleFetchItems = (id) => {
      fetchJournalEntryFormat({
        variables: { id }
      })
    }

    const journalEntryFormatsData = data ? data.journalEntryFormats : {}
    const journalEntryFormat = itemsData ? itemsData.journalEntryFormat : {}

    const pagination = {
      current: page,
      pageSize: pageSize,
      total: pageSize * (journalEntryFormatsData.totalPages || 1)
    }

  return (
    <JournalEntryFormatsIndex
        loading={loading}
        itemsLoading={itemsLoading}
        refetch={refetch}
        pagination={pagination}
        onTableChange={handleTableChange}
        showFormatForm={showFormatForm}
        handleFetchItems={handleFetchItems}
        journalEntryFormatId={formatIdSelected}
        onDeleteJournalEntryFormat={handleDeleteJournalEntryFormat}
        toggleJournalEntryFormatForm={toggleJournalEntryFormatForm}
        journalEntryFormats={journalEntryFormatsData.journalEntryFormats}
        journalEntryFormatItems={journalEntryFormat.journalEntryFormatItems}
      />
  )
}

JournalEntryFormatsIndexContainer.propTypes = {}

JournalEntryFormatsIndexContainer.defaultProps = {}

export default JournalEntryFormatsIndexContainer
