import styled from 'styled-components'

const AddItemFormWrapper = styled.div`
  .paste-btn {
    margin-left: 20px;
  }

  .alert-not-found {
    color: red;
  }
  
  .back-button {
    margin-right: 12px;
  }
  
  .list-action {
    margin-bottom: 24px
  }
  
  .stay-right {
    float: right;
  }
  
  .bold {
    font-weight: bold;
  }
`

export default AddItemFormWrapper
