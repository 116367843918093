import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Table, Row, Col, Divider, Input, Select, DatePicker } from 'antd'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import _ from 'lodash'

import SelectOrderStateContainer from '../SelectOrderState'

import { withTranslation } from 'react-i18next'
import { isEmpty } from '../../helpers/objects'
import orderTableActions from '../../redux/orders/tables/actions'
import DropdownPdfPreview from './DropdownPdfPreview'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'
import { LEAD_SOURCES } from '../../constants/orders'
import { isMobile } from 'react-device-detect'

import moment from 'moment'

const { RangePicker } = DatePicker

const {
    reset,
    setFilter,
    setPagination,
    setPeriod,
    setQuery,
    setSorter,
    setState,
    setSale,
    setCs,
    setLeadSource,
    fetchSales,
    setReservationDate
} = orderTableActions

class OrderTable extends Component {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        onFetchOrders: PropTypes.func.isRequired,
        orders: ImmutablePropTypes.list.isRequired,
        orderTables: ImmutablePropTypes.map.isRequired,
        showSearchInput: PropTypes.bool,
        total: PropTypes.number.isRequired,
        activeStateDefault: PropTypes.string,
        isRenderStaffFilter: PropTypes.bool,
        isRenderLeadSourceSelect: PropTypes.bool,
        isRenderReservationDateFilter: PropTypes.bool
    }

    static defaultProps = {
        showSearchInput: false,
        isRenderStaffFilter: false,
        isRenderLeadSourceSelect: false,
        isRenderReservationDateFilter: false
    }

    componentDidMount () {
        const { fetchSales, isRenderStaffFilter } = this.props
        this.updateTotalPagination()
        if (isRenderStaffFilter) {
            fetchSales()
        }
    }

    componentDidUpdate (prevProps) {
        if (prevProps.total !== this.props.total) {
            this.updateTotalPagination()
        }
    }

    componentWillUnmount () {
        const { reset } = this.props
        reset()
    }

    fetchOrders (queryString) {
        const { onFetchOrders, history } = this.props
        let arrQueryString = queryString.split('&')
        let objQuery = {}
        let queryShow = []
        const acceptQuery = [
            'query',
            'state',
            'extra_state',
            'start_date',
            'end_date',
            'by_cs',
            'by_sale',
            'by_lead_source',
            'reservation_date',
            'page',
            'per'
        ]
        arrQueryString.forEach((str) => {
            const arrString = str.split('=')
            if (acceptQuery.includes(arrString[0])) {
                objQuery = Object.assign(objQuery, { [`${arrString[0]}`]: arrString[1] })
                queryShow = queryShow.concat(`${arrString[0]}=${arrString[1]}`)
            }
        })

        let queries = queryShow.length ? '?' + queryShow.join('&') : ''

        history.push({
            search: queries,
            params: objQuery
        })

        let queryStringPrepared = []
        _.mapKeys(objQuery, (value, key) => {
            if (!value) { return null }
            queryStringPrepared.push(`${key}=${value}`)
        })

        const queryStringWithFormat = queryStringPrepared.filter(Boolean).join('&')
        onFetchOrders(queryStringWithFormat)
    }

    handleOnPeriodChange = (dateString) => {
        const { orderTables, setPagination, location } = this.props

        const pagination = orderTables.get('pagination').toJS()
        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            period: dateString,
            pagination: { ...pagination, current: 1 }
        })

        this.fetchOrders(queryString)
    }

    handleOnSearch = (query) => {
        const { orderTables, setPagination, location } = this.props

        const pagination = orderTables.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            query,
            pagination: { ...pagination, current: 1 }
        })

        this.fetchOrders(queryString)
    }

    handleOrderStateChange = (state) => {
        const { orderTables, setPagination, location } = this.props

        const getState = (!state) ? '' : state
        const pagination = orderTables.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            state: getState,
            pagination: { ...pagination, current: 1 }
        })

        this.fetchOrders(queryString)
    }

    handleLeadSourceFilterSelected = (e) => {
        const { orderTables, setPagination, location, setLeadSource } = this.props
        if (e === undefined) {
            delete location.params.by_lead_source
        }

        const pagination = orderTables.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            pagination: { ...pagination, current: 1 },
            filterLeadSource: e
        })
        setLeadSource(e)
        this.fetchOrders(queryString)
    }

    handleOnTableChange = (pagination, filter, sorter) => {
        const { setPagination, setFilter, setSorter } = this.props

        setPagination(pagination)
        setFilter(filter)
        setSorter(sorter)

        const queryString = this.getQueryString({ pagination, filter, sorter })

        this.fetchOrders(queryString)
    }

    handleSelectReservationDate = (dateString, date) => {
        const { orderTables, setPagination, location, setReservationDate } = this.props

        const pagination = orderTables.get('pagination').toJS()

        setPagination({ current: 1 })
        setReservationDate(date)

        const queryString = this.getQueryString({
            ...location.params,
            reservationDate: date,
            pagination: { ...pagination, current: 1 }
        })

        this.fetchOrders(queryString)
    }

    // return page query object in this manner
    // { key: 'page', value: '1' }
    getPageQueryObject (pagination) {
        if (isEmpty(pagination)) {
            return {}
        }

        return {
            key: 'page',
            value: pagination.current || DEFAULT_CURRENT_PAGE
        }
    }

    // return start_date and end_date query array in this manner
    // [{ key: 'start_date', value: '2019-03-01' }, { key: 'end_date', value: '2019-03-31' }]
    getPeriodQueryArray (period) {
        // prevent when initial state or when period is cleared
        if (period.length !== 2 || !period[0]) {
            return []
        }

        return [
            { key: 'start_date', value: period[0] },
            { key: 'end_date', value: period[1] }
        ]
    }

    // return per query object in this manner
    // { key: 'per', value: '20' }
    getPerPageQueryObject (pagination) {
        if (isEmpty(pagination)) {
            return {}
        }

        return {
            key: 'per',
            value: pagination.pageSize || pagination.defaultPageSize || PAGE_SIZE
        }
    }

    // return order query object in this manner
    // { key: 'order', value: 'updated_at DESC' }
    getOrderQueryObject (sorter) {
        if (isEmpty(sorter)) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: `${sorter.columnKey} ${sortDirection}`
        }
    }

    // return query query object in this manner
    // { key: 'query', value: 'OSH1903' }
    getQueryQueryObject (query) {
        if (!query) {
            return {}
        }

        return { key: 'query', value: query }
    }

    getQueryString (args = {}) {
        const { orderTables, setPeriod, setQuery, setState, location } = this.props
        const extraState = ('extra_state' in args) ? args.extra_state : ''
        const pagination = ('pagination' in args) ? args.pagination : orderTables.get('pagination').toJS()
        const period = ('period' in args) ? args.period : orderTables.get('period').toJS()
        const query = ('query' in args) ? args.query : orderTables.get('query')
        const sorter = ('sorter' in args) ? args.sorter : orderTables.get('sorter').toJS()
        const state = ('state' in args) ? args.state : orderTables.get('state')
        const onSaleFilter = ('filterSale' in args) ? args.filterSale : orderTables.get('filterSale')
        const onCsFilter = ('filterCs' in args) ? args.filterCs : orderTables.get('filterCs')
        const onLeadSourceFilter = ('filterLeadSource' in args) ? args.filterLeadSource : orderTables.get('filterLeadSource')
        const onReservationDateFilter = ('reservationDate' in args) ?
            args.reservationDate : orderTables.get('reservationDate')

        setPeriod(!period.length ? [args.start_date, args.end_date] : period)
        setQuery(query)
        setState(state)
        const queries = [].concat(
            this.getPageQueryObject(pagination),
            this.getPerPageQueryObject(pagination),
            this.getOrderQueryObject(sorter),
            this.getPeriodQueryArray(!period.length ? [args.start_date, args.end_date] : period),
            this.getStateQueryObject(state),
            this.getExtraStateQueryObject(extraState),
            this.getQueryQueryObject(query),
            this.getCurrentLeadSourceQuery(location.params),
            this.getCurrentCsQuery(location.params),
            this.getCurrentSaleQuery(location.params),
            this.getFilterSale(onSaleFilter),
            this.getFilterCs(onCsFilter),
            this.getFilterLeadSource(onLeadSourceFilter),
            this.getFilterReservationDate(onReservationDateFilter)
        )
        return queries.filter((query) => {
            return !isEmpty(query)
        }).map((query) => {
            return `${query.key}=${query.value}`
        }).join('&')
    }

    // return state query object in this manner
    // { key: 'state', value: 'requesting_quotation' }
    getExtraStateQueryObject (state) {
        if (!state) {
            return {}
        }

        return { key: 'extra_state', value: state }
    }

    getCurrentLeadSourceQuery (routerQuery = {}) {
        if (!routerQuery.by_lead_source) {
            return {}
        }

        return { key: 'by_lead_source', value: routerQuery.by_lead_source }
    }

    getCurrentCsQuery (routerQuery = {}) {
        if (!routerQuery.by_cs) {
            return {}
        }

        return { key: 'by_cs', value: routerQuery.by_cs }
    }

    getCurrentSaleQuery (routerQuery = {}) {
        if (!routerQuery.by_sale) {
            return {}
        }

        return { key: 'by_sale', value: routerQuery.by_sale }
    }

    getStateQueryObject (state) {
        if (!state) {
            return {}
        }

        return { key: 'state', value: state }
    }

    getFilterSale (onSaleFilter) {
        if (!onSaleFilter) {
            return {}
        }

        return { key: 'by_sale', value: onSaleFilter }
    }

    getFilterCs (onCsFilter) {
        if (!onCsFilter) {
            return {}
        }

        return { key: 'by_cs', value: onCsFilter }
    }

    getFilterLeadSource (onLeadSourceFilter) {
        if (!onLeadSourceFilter) {
            return {}
        }

        return { key: 'by_lead_source', value: onLeadSourceFilter }
    }

    getFilterReservationDate (onReservationDateFilter) {
        if (!onReservationDateFilter) {
            return {}
        }

        return { key: 'reservation_date', value: onReservationDateFilter }
    }

    updateTotalPagination () {
        const { setPagination, total } = this.props
        setPagination({ total })
    }

    renderSearchInput () {
        const { i18n, location } = this.props

        return (
            <div>
                <Input.Search enterButton
                    defaultValue={new URLSearchParams(location.search).get("query")}
                    onSearch={(query) => {
                        this.handleOnSearch(query)
                    }}
                    placeholder={i18n.t('orders/tables:searchPlaceholder')}/>
                <Divider/>
            </div>
        )
    }

    renderStaffFilter () {
        const { i18n, location } = this.props
        return (
            <div style={{ margin: '5px' }}>
                <span style={{ margin: '5px' }}>
                    <span style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orders/tables:filterBySale')}
                        </span>
                        <Select style={{ width: '150px' }} allowClear
                            defaultValue={new URLSearchParams(location.search).get("by_sale")}
                            showSearch
                            onChange={(value) => this.handleFilterBySale(value)}
                            placeholder={i18n.t('orders/tables:filterWithSale')}>
                            {this.renderSaleFilterOptions()}
                        </Select>
                    </span>
                </span>
                <span style={{ margin: '5px' }}>
                    <span style={{ marginLeft: '5px' }}>
                        <span style={{ marginRight: '10px' }}>
                            {i18n.t('orders/tables:filterByCs')}
                        </span>
                        <Select style={{ width: '150px' }} allowClear
                            defaultValue={new URLSearchParams(location.search).get("by_cs")}
                            onChange={(value) => this.handleFilterByCs(value)}
                            placeholder={i18n.t('orders/tables:filterWithCs')}>
                            {this.renderCsFilterOptions()}
                        </Select>
                    </span>
                </span>
            </div>
        )
    }

    renderSaleFilterOptions = () => {
        const { i18n, entities, orderTables } = this.props
        const salesEntities = entities.get('sales') || new Map()
        const saleOptions = [
            {
                key: `${i18n.t('orders/tables:haveNoSale')}`,
                value: 'nil'
            },
            {
                key: `${i18n.t('orders/tables:haveSale')}`,
                value: 'not_nil'
            }
        ]
        if (salesEntities.size === 0) {return null}
        const saleArray = orderTables.get('saleItems').map((saleId) => {
            const sale = salesEntities.get(saleId)
            return (
                {
                    key: sale.get('name'),
                    value: sale.get('id')
                }
            )
        })
        return saleOptions.concat(saleArray.toJS()).map((saleOption) => {
            return (
                <Select.Option key={saleOption.key} value={saleOption.value}>
                    {saleOption.key}
                </Select.Option>
            )
        })
    }

    renderCsFilterOptions = () => {
        const { i18n } = this.props
        const csOptions = [
            {
                key: `${i18n.t('orders/tables:haveNoCs')}`,
                value: 'nil'
            },
            {
                key: `${i18n.t('orders/tables:haveCs')}`,
                value: 'not_nil'
            }
        ]

        return csOptions.map((csOption) => {
            return (
                <Select.Option key={csOption.key} value={csOption.value}>
                    {csOption.key}
                </Select.Option>
            )
        })
    }

    renderReservationDateFilter = () => {
        const { location, i18n } = this.props
        const reservationDate = new URLSearchParams(location.search).get("reservation_date")
        const defaultDate = reservationDate ?
            moment(reservationDate, 'YYYY-MM-DD') : null
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto 4px auto 0' }}>{i18n.t('orders/tables:truckingReservationDate')} :</div>
                <DatePicker allowClear defaultValue={defaultDate}
                    onChange={this.handleSelectReservationDate} />
            </div>
        )
    }


    handleFilterBySale = (e) => {
        const { orderTables, setPagination, location, setSale } = this.props
        if (e === undefined) {
            delete location.params.by_sale
        }
        const pagination = orderTables.get('pagination').toJS()
        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            pagination: { ...pagination, current: 1 },
            filterSale: e
        })
        setSale(e)
        this.fetchOrders(queryString)
    }

    handleFilterByCs = (e) => {
        const { orderTables, setPagination, location, setCs } = this.props
        if (e === undefined) {
            delete location.params.by_cs
        }

        const pagination = orderTables.get('pagination').toJS()

        setPagination({ current: 1 })

        const queryString = this.getQueryString({
            ...location.params,
            pagination: { ...pagination, current: 1 },
            filterCs: e
        })
        setCs(e)

        this.fetchOrders(queryString)
    }

    renderLeadSourceSelect () {
        const { i18n, location } = this.props

        return (
            <div style={{
                marginBottom: '10px'
            }}>
                <Select style={{ width: '200px' }} allowClear
                    defaultValue={new URLSearchParams(location.search).get("by_lead_source")}
                    onChange={(value) => this.handleLeadSourceFilterSelected(value)}
                    placeholder={i18n.t('orders/tables:filterByLeadSource')}>
                    {this.selectOrderLeadSourceMenu()}
                </Select>
            </div>
        )
    }

    selectOrderLeadSourceMenu = () => {
        return LEAD_SOURCES.map((leadSource) => {
            return (
                <Select.Option key={leadSource}>
                    {leadSource}
                </Select.Option>
            )
        })
    }


    render () {
        const {
            i18n,
            location,
            columns,
            loading,
            orders,
            orderTables,
            showSearchInput,
            activeStateDefault,
            documentDashboard,
            pagination,
            handleOnPageChange,
            isRenderStaffFilter,
            isRenderLeadSourceSelect,
            isRenderReservationDateFilter,
            ...props
        } = this.props
        const tablePagination = pagination || orderTables.get('pagination').toJS()
        const queryStateDefault = new URLSearchParams(location.search).get("state")|| null

        const defaultDate = new URLSearchParams(location.search).get("start_date") ? (
            [moment(new URLSearchParams(location.search).get("start_date")), moment(new URLSearchParams(location.search).get("end_date"))]
        ) : null

        return (
            <div>
                {showSearchInput && this.renderSearchInput()}
                <Row type="flex" gutter={[16, 16]}>
                    <Col md={8} xs={24}>
                        {isRenderLeadSourceSelect ? this.renderLeadSourceSelect() : <div/>}
                        {isRenderStaffFilter ? this.renderStaffFilter() : <div/>}
                        {isRenderReservationDateFilter ? this.renderReservationDateFilter() : <div />}
                    </Col>

                    <Col md={10} xs={24}>
                        <Row type="flex" align="middle" gutter={[8, 8]}>
                            <Col xs={6}>
                                <div style={{ textAlign: 'right' }}>
                                    {i18n.t('orders:searchByDate')} :
                                </div>
                            </Col>

                            <Col xs={18}>
                                <RangePicker
                                    allowClear
                                    defaultValue={defaultDate}
                                    onChange={(date, dateString) => {this.handleOnPeriodChange(dateString)}}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col md={6} xs={24}>
                        <SelectOrderStateContainer
                            onChange={this.handleOrderStateChange}
                            value={queryStateDefault || activeStateDefault}
                        />
                    </Col>
                </Row>

                <Divider/>

                {documentDashboard ? (
                    <DropdownPdfPreview type="dashboard" />
                ) : null}

                <Table columns={columns}
                    dataSource={orders.toJS()}
                    loading={loading}
                    onChange={handleOnPageChange || this.handleOnTableChange}
                    pagination={{ ...tablePagination, showSizeChanger: false }}
                    rowKey={order => order.id}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    {...props} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderTables: state.get('orderTables')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        reset,
        setFilter,
        setPagination,
        setPeriod,
        setQuery,
        setSorter,
        setState,
        setSale,
        setCs,
        fetchSales,
        setLeadSource,
        setReservationDate
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(['orders', 'orders/tables'])(OrderTable)))
