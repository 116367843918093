import React from 'react'
import { withTranslation } from 'react-i18next'
import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import StaffFormContainer from './StaffFormContainer'

class StaffNewPage extends React.Component {
    componentDidMount() {
        document.title = 'Staff | OSH CRM'
    }

    render () {
        return (
            <Layout>
                    <LayoutContentWrapper breadcrumbs={[
                        { path: '/staffs', name: 'Staffs' },
                        { path: '/staffs/new', name: 'New' }
                    ]}>
                        <ContentLayout>
                            <StaffFormContainer />
                        </ContentLayout>
                    </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withTranslation('common')(StaffNewPage)
