import { List, Map } from 'immutable'

import actions from './actions'

const {
    PRODUCT_GROUP_ITEMS_FETCH_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING,
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES,
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL,
    PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS,
    PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES,
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    rootItemAttributesItems: new List(),
    rootItemAttributesLoading: false,
    itemAttributesItems: new List(),
    itemAttributesLoading: false
})

export default function productGroupItemsReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case PRODUCT_GROUP_ITEMS_FETCH_FAIL:
            return state.merge({ loading: false })
        case PRODUCT_GROUP_ITEMS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case PRODUCT_GROUP_ITEMS_FETCHING:
            return state.merge({ loading: true })
        case PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_FAIL:
            return state.merge({ rootItemAttributesLoading: false })
        case PRODUCT_GROUP_ITEMS_FETCH_ROOT_ITEM_ATTRIBUTES_SUCCESS:
            return state.merge({
                rootItemAttributesItems: new List(result),
                rootItemAttributesLoading: false
            })
        case PRODUCT_GROUP_ITEMS_FETCHING_ROOT_ITEM_ATTRIBUTES:
            return state.merge({ rootItemAttributesLoading: true })
        case PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_FAIL:
            return state.merge({ itemAttributesLoading: false })
        case PRODUCT_GROUP_ITEMS_FETCH_ITEM_ATTRIBUTES_SUCCESS:
            return state.merge({
                itemAttributesItems: new List(result),
                itemAttributesLoading: false
            })
        case PRODUCT_GROUP_ITEMS_FETCHING_ITEM_ATTRIBUTES:
            return state.merge({ itemAttributesLoading: true })
        default:
            return state
    }
}
