import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { Button, Form, Row, Select, Col, Input } from 'antd'

import inquiryActions from '../../redux/inquiry/actions'

const { fetchVehicleTypes } = inquiryActions

const { Option } = Select

const ShippingCostFormContainer = ({
    onSubmitForm,
    saving,
    toggleForm,
    errors
}) => {
    const { i18n } = useTranslation('inquiries')
    const dispatch = useDispatch()
    const vehicleTypeEntities = useSelector(state => state.getIn(['Entities', 'vehicleTypes']))
    const vehicleTypeItems = useSelector(state => state.getIn(['inquiry', 'vehicleTypeItems'], new List()))

    useEffect(() => {
          dispatch(fetchVehicleTypes())
    }, [])

    const vehicleTypes = vehicleTypeItems.map((vehicleId) => {
        const vehicleType = vehicleTypeEntities.get(`${vehicleId}`)
        return (
            <Option key={vehicleType.get('id')} value={vehicleType.get('id')}>
                {vehicleType.get('name')}
            </Option>
        )
    })

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
  }


  const formItemLayout = {
      marginRight: '10px'
  }

    return (
        <Form onFinish={handleSubmitForm} layout='vertical'>
            <h2>{i18n.t('inquiries:shippingCosts')}</h2>
            <Row type="flex">
                <Form.Item name='shipping_type' initialValue='added_by_sale'>
                        <input type="hidden" />
                </Form.Item>
                <Col span={22}>
                    <Form.Item label={i18n.t('inquiries:shippingCost.vehicleType')}  style={formItemLayout}
                        name='vehicle_type_id'>
                            <Select
                                placeholder={i18n.t('inquiries:shippingCost.vehicleType')}
                                allowClear
                                showSearch
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {vehicleTypes}
                            </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row type="flex">
                <Col span={9}>
                    <Form.Item label={i18n.t('inquiries:shippingCost.price')} style={formItemLayout}
                        name='single_price'
                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput') }]}
                        >
                            <Input placeholder={i18n.t('inquiries:shippingCost.price')} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label={i18n.t('inquiries:shippingCost.numberOfTrips')} style={formItemLayout}
                        name='quantity'
                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput') }]}
                        >
                            <Input placeholder={i18n.t('inquiries:shippingCost.numberOfTrips')} />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label={i18n.t('inquiries:shippingCost.cost')} style={formItemLayout}
                        name='cost'
                        rules={[{ required: true, message: i18n.t('inquiries:pleaseInput') }]}
                        >
                            <Input placeholder={i18n.t('inquiries:shippingCost.cost')} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" loading={saving}>
                    {i18n.t('shared:save')}
                </Button>
                <Button style={{ marginLeft: '12px' }} onClick={toggleForm}>
                    {i18n.t('shared:close')}
                </Button>
            </Form.Item>
        </Form>
    )
}

ShippingCostFormContainer.propTypes = {
    toggleForm: PropTypes.func,
    errors: PropTypes.object
}

ShippingCostFormContainer.defaultProps = {
    toggleForm: () => {},
    errors: {}
}

export default ShippingCostFormContainer
