import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Map } from 'immutable'
import { Form, Button, Input } from 'antd'

import SelectSupplierContainer from '../../containers/SelectSupplier'
import SelectBrandContainer from '../../containers/SelectBrand'

const handleSubmit = (values, props) => {
    const { onSubmit } = props

    onSubmit({ values })
}

const PotentialLeadForm = props => {
    const {
        i18n,
        saving,
        potentialLead
    } = props

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    }

    return (
        <div>
            <h3>เพิ่ม Potential lead</h3>
            <Form onFinish={(values) => handleSubmit(values, props)} {...layout}>
                <Form.Item label="Line ID"
                    name="line_id"
                    initialValue={potentialLead.get('line_id')}>
                        <Input />
                </Form.Item>
                <Form.Item label="Brief Message"
                    name="brief_message"
                    initialValue={potentialLead.get('brief_message')}>
                        <Input />
                </Form.Item>
                <Form.Item label="Group"
                    name="group"
                    initialValue={potentialLead.get('group')}>
                        <Input />
                </Form.Item>
                <Form.Item label="Supplier"
                    name="supplier_id"
                    initialValue={potentialLead.get('supplier')}>
                        <SelectSupplierContainer style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item label="Brand" name="brand_id"
                    initialValue={potentialLead.get('brand')}>
                        <SelectBrandContainer style={{ width: '100%' }} />
                </Form.Item>


                <div style={{ textAlign: 'center' }}>
                    <Button loading={saving} htmlType="submit" type="primary">
                        {i18n.t('shared:save')}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

PotentialLeadForm.propTypes = {
    onSubmit: PropTypes.func
}

PotentialLeadForm.defaultProps = {
    onSubmit: () => {},
    potentialLead: new Map()
}

export default withTranslation(['shared', 'confirms'])(PotentialLeadForm)
