import React from 'react'
import { withRouter } from 'react-router'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import TicketsList from '../../components/tickets/ticketsList'

class TicketIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Tickets | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/tickets', name: 'Tickets' }
                ]}>
                    <ContentLayout>
                        <TicketsList location={this.props.location} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withRouter(TicketIndexPage)
