import { Map, List } from 'immutable'
import actions from './actions'

const {
    PROVINCES_FETCH,
    PROVINCES_FETCH_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    loading: false
})

export default function provincesReducer (state = initState, action) {
    switch (action.type) {
        case PROVINCES_FETCH:
            return state.merge({ loading: true })
        case PROVINCES_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result),
                loading: false
            })
        default:
            return state
    }
}
