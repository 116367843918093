import React from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'antd'
import TruckReservationLalamoveForm from './form'

const TruckReservationLalamove = ({
  orderId,
  shippingCostId,
  refetch,
  onClose,
  showForm
}) => {
  const onSaveSuccess = () => {
    refetch()
    onClose()
  }

  return (
    <Modal title="จองรถผ่าน Lalamove"
      footer={null}
      onCancel={onClose}
      visible={showForm}
      destroyOnClose>
      <TruckReservationLalamoveForm
        onSuccess={onSaveSuccess}
        orderId={orderId}
        shippingCostId={shippingCostId} />
    </Modal>
  )
}

TruckReservationLalamove.propTypes = {
  orderId: PropTypes.string.isRequired,
  shippingCostId: PropTypes.string.isRequired,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
  showForm:  PropTypes.bool
}

TruckReservationLalamove.defaultProps = {
  refetch: () => {},
  onClose: () => {}
}

export default TruckReservationLalamove
