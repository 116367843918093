import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map, List } from 'immutable'

import actions from '../../redux/provinces/actions'

import SelectProvince from '../../components/selectProvince'

const {
    fetchProvinces
} = actions

class SelectProvinceContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner Props
        fetchProvinces: PropTypes.func.isRequired,
        provinceEntities: ImmutablePropTypes.map.isRequired,
        provinces: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: undefined
    }

    constructor (props) {
        super(props)

        this.state = {
            selectedProvinceId: _.toString(props.value) || undefined
        }
    }

    componentDidMount () {
        const { fetchProvinces } = this.props

        fetchProvinces()
    }

    componentDidUpdate (prevProps) {
        const { value } = this.props
        const { value: prevValue } = prevProps

        if (value !== prevValue) {
            this.setState({ selectedProvinceId: value || undefined })
        }
    }

    getProvinces () {
        const { provinceEntities, provinces } = this.props

        return provinces.get('items', new List()).map((provinceId) => {
            return provinceEntities.get(_.toString(provinceId), new Map())
        })
    }

    onChange = (selectedProvinceId) => {
        const { onChange } = this.props

        this.setState({ selectedProvinceId: _.toString(selectedProvinceId) || undefined })
        onChange(_.toString(selectedProvinceId))
    }

    render () {
        const { provinces } = this.props
        const { selectedProvinceId } = this.state

        return (
            <SelectProvince
                loading={provinces.get('loading')}
                onChange={this.onChange}
                provinces={this.getProvinces().toJS()}
                value={selectedProvinceId}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        provinceEntities: state.getIn(['Entities', 'provinces']),
        provinces: state.get('provinces')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProvinces
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProvinceContainer)
