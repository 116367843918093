import styled from 'styled-components'

const MessageContainerWrapper = styled.div`
  .image-section {
    cursor: pointer;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
`

export default MessageContainerWrapper