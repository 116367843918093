import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import WalletSummaryIndexPage from '../containers/WalletSummaries'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const WalletSummariesPage = ({
	location,
	match
}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'wallet_summaries') {
			dispatch(changeCurrent(['wallet_summaries']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={WalletSummaryIndexPage} exact />
		</Switch>
  )
}

WalletSummariesPage.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

WalletSummariesPage.defaultProps = {}

export default WalletSummariesPage
