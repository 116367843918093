import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Form, Button, Input, Spin } from 'antd'
import SelectSupplierLineItems from '../../containers/SelectSupplierLineItems'
import PurchaseOrderAddressFormContainer from '../../containers/PurchaseOrderForm/AddressFormContainer'

const PurchaseOrderForm = ({
    i18n,
    onSaveForm,
    orderId,
    supplierId,
    supplierLineItems,
    supplierOrder,
    orderShippingAddress,
    setAddressErrors,
    addressErrors,
    lineItemsLoading
}) => {
    const checkoutPoType = () => {
        const purchaseOrderType = supplierOrder.purchase_order_type
        switch (purchaseOrderType) {
            case 'vat_included':
                return { has_custom_grand_total: false, vat_included: true }

            case 'vat_not_included':
                return { has_custom_grand_total: false, vat_included: false }

            case 'price_not_included':
                return { has_custom_grand_total: true, vat_included: true }

            default:
                return {}
        }
    }

    const onSubmit = (values) => {
        const province = _.get(values, 'address.purchase_order_address_attributes.province_id', null)
        const district = _.get(values, 'address.purchase_order_address_attributes.district_id', null)
        if (_.get(values, 'address.send_by_supplier') && !province && !district) {
            setAddressErrors(true)
        } else {
            const newValues = {
                line_items_attributes: values['line_items_attributes'].map((lineItem) => {
                    lineItem.quantity = lineItem.quantity ? lineItem.quantity : 1
                    lineItem.single_price = lineItem.single_price ? lineItem.single_price : 0

                    return Object.assign(lineItem, { supplier_id: supplierId, order_number: orderId })
                }),
                is_po_downloaded: true,
                free_note: _.get(values, 'free_note', null),
                send_by_supplier: _.get(values, 'address.send_by_supplier', null),
                purchase_order_address_attributes: _.get(values, 'address.purchase_order_address_attributes', {}),
                ...checkoutPoType()
            }

            if (supplierOrder.is_po_completed) {
                Reflect.deleteProperty(newValues, 'is_po_completed')
            }

            setAddressErrors(false)
            onSaveForm(newValues)
        }
    }

    const getDefaultVaules = () => {
        return {
            address: {
                send_by_supplier: _.get(supplierOrder, 'send_by_supplier', false),
                purchase_order_address_attributes: {
                    id: _.get(supplierOrder, 'purchase_order_address.id', ''),
                    district_id: _.get(supplierOrder, 'purchase_order_address.district.id', defaultDistrictId),
                    province_id: _.get(supplierOrder, 'purchase_order_address.province.id', defaultProvinceId),
                    phone_number: _.get(supplierOrder, 'purchase_order_address.phone_number', defaultPhoneNumber),
                    postal_code: _.get(supplierOrder, 'purchase_order_address.postal_code', defaultPostalCode),
                    shipping_detail: _.get(supplierOrder, 'purchase_order_address.shipping_detail', defaultShippingAddress),
                    _destroy: _.isEmpty(_.get(supplierOrder, 'purchase_order_address.id', ''))
                }
            }
        }
    }

    const defaultDistrictId = orderShippingAddress.getIn(['district', 'id'])
    const defaultProvinceId = orderShippingAddress.getIn(['province', 'id'])
    const defaultPostalCode = orderShippingAddress.get('postal_code')
    const defaultPhoneNumber = orderShippingAddress.get('phone_number') || null
    const defaultShippingAddress = orderShippingAddress.get('shipping_detail') || null

    if(lineItemsLoading) return <Spin />

    return (
        <Form
            className="purchase-order-form"
            onFinish={onSubmit}
            layout="vertical"
            initialValues={getDefaultVaules()}
        >
            <Form.Item label="Select Line Item" name='line_items_attributes'
                initialValue={supplierLineItems}>
                    <SelectSupplierLineItems
                        isPoCompleted={_.get(supplierOrder, 'is_po_completed', false)}
                        orderId={orderId}
                        lineItemsLoading={lineItemsLoading}
                    />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:supplierOrder.freeNote')}
                name='free_note'
                initialValue={_.get(supplierOrder, 'free_note', '')}>
                    <Input
                        placeholder={i18n.t('suppliers:supplierOrder.freeNote')}
                    />
            </Form.Item>
            <div style={{ color: 'red' }}>{addressErrors ? 'กรุณากรอกที่อยู่' : null}</div>
            <Form.Item name='address'>
                    <PurchaseOrderAddressFormContainer setAddressErrors={setAddressErrors}/>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="submit-po-form-btn">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

PurchaseOrderForm.propTypes = {
    orderId: PropTypes.string,
    supplierLineItems: PropTypes.array,
    supplierOrder: PropTypes.object
}

PurchaseOrderForm.defaultProps = {
    orderId: '',
    supplierLineItems: [],
    supplierOrder: {}
}

export default withTranslation(['suppliers', 'confirms'])(PurchaseOrderForm)
