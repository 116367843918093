import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch, apiFileFetch, apiPostForm } from '../../helpers/restRequest'
import actions from './actions'
import {
    supplierItemsSchema,
    supplierLineItemsSchema,
    supplierOrderSchema,
    supplierOrdersSchema,
    supplierSchema,
    suppliersSchema
} from '../schema'
import { deserialize } from '../../helpers/jsonApi'
import actionsNotification from '../../redux/notifications/actions'

const { setNotifications } = actionsNotification

const {
    SUPPLIER_FETCH_FAIL,
    SUPPLIER_FETCH_REQUEST,
    SUPPLIER_FETCH_SUCCESS,
    SUPPLIER_FETCH_SUPPLIER_ORDERS_REQUEST,
    SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS,
    SUPPLIER_FETCH_SUPPLIER_ORDERS,
    SUPPLIER_FETCHING,
    SUPPLIERS_FETCH_REQUEST,
    SUPPLIERS_FETCH_SUCCESS,
    SUPPLIER_SAVE_REQUEST,
    SUPPLIER_SAVE,
    SUPPLIER_SAVE_SUCCESS,
    SUPPLIER_SAVE_ERRORS,
    SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_REQUEST,
    SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_SUCCESS,
    SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF,
    SUPPLIERS_FETCH,
    SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_REQUEST,
    SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS,
    SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS,
    SUPPLIERS_LINE_ITEM_SET_LOADING,
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_REQUEST,
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS,
    SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER,
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_REQUEST,
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
    SUPPLIERS_ORDERS_LINE_ITEMS_FETCH,
    SUPPLIERS_ORDERS_SAVE_FAIL,
    SUPPLIERS_ORDERS_SAVE_REQUEST,
    SUPPLIERS_ORDERS_SAVE_SUCCESS,
    SUPPLIERS_ORDERS_SAVE,
    SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING,
    SUPPLIERS_SEARCH_REQUEST,
    SUPPLIERS_SET_PAGINATION,
    SUPPLIERS_SET_QUERY,
    SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING,
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_REQUEST,
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS,
    SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS,
    SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_REQUEST,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE,
    SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS
} = actions

const apiPath = (id) => {
    const basePath = '/suppliers'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *fetchSupplier () {
    yield takeLatest(SUPPLIER_FETCH_REQUEST, function *({ payload }) {
        const { id, onSuccess, onError } = payload

        yield put({ type: SUPPLIER_FETCHING })

        try {
            const data = yield call(apiFetch, `/suppliers/${id}`)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierSchema)

            yield put({
                type: SUPPLIER_FETCH_SUCCESS,
                ...normalizeData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_FETCH_FAIL })
            onError()
        }
    })
}

export function *fetchSuppliers () {
    yield takeEvery(SUPPLIERS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIERS_FETCH })

        const { page, per } = payload
        const data = yield call(apiFetch, `/suppliers?page=${page}&per=${per}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, suppliersSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: SUPPLIERS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: SUPPLIERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitSupplier () {
    yield takeLatest(SUPPLIER_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_SAVE })

        const { id, params, onSuccess, onError } = payload

        try {
            const path = id ? `/suppliers/${id}` : '/suppliers'
            const method = id ? 'PATCH' : 'POST'

            const body = JSON.stringify({ supplier: params })
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: SUPPLIER_SAVE_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: SUPPLIER_SAVE_SUCCESS,
                    ...normalize(formattedData, supplierSchema)
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        }
    })
}

export function *searchSuppliersRequest () {
    yield takeEvery(SUPPLIERS_SEARCH_REQUEST, function *({ payload }) {
        const { query, page, per } = payload
        const queryString = `query=${query}&page=${page}&per=${per}`

        yield put({ type: SUPPLIERS_SET_QUERY, query })
        yield put({ type: SUPPLIERS_FETCH })

        const data = yield call(apiFetch, `${apiPath()}?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, suppliersSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: SUPPLIERS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: SUPPLIERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchSupplierOrders () {
    yield takeEvery(actions.ORDER_FETCH_SUPPLIER_ORDERS_REQUEST, function *({ payload }) {
        yield put({ type: actions.ORDER_FETCH_SUPPLIER_ORDERS })
        const { orderId } = payload

        const path = `/orders/${orderId}/suppliers/orders`

        try {
            const data = yield call(apiFetch, path)

            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierOrdersSchema)

            yield put({
                type: actions.ORDER_FETCH_SUPPLIER_ORDERS_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchSupplierOrdersBySupplier () {
    yield takeEvery(SUPPLIER_FETCH_SUPPLIER_ORDERS_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_FETCH_SUPPLIER_ORDERS })
        const { supplierId, query } = payload

        const path = `/suppliers/${supplierId}/supplier_orders?query=${query}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierOrdersSchema)

            yield put({
                type: SUPPLIER_FETCH_SUPPLIER_ORDERS_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchListSupplierOrders () {
    yield takeEvery(SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS })

        const { supplierId, page, per } = payload

        const queryString = `page=${page}&per=${per}`

        const path = `/suppliers/${supplierId}/list_supplier_orders?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierOrdersSchema)
        const total = data.meta ? data.meta.total_pages * per : null

        yield put({
            type: SUPPLIER_LIST_SUPPLIER_ORDERS_SET_PAGINATE,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: SUPPLIER_FETCH_LIST_SUPPLIER_ORDERS_SUCCESS,
            ...normalizeData
        })
    })
}

// export function *fetchSupplierOrdersLineItemsRequest () {
//     yield takeEvery(SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_REQUEST, function *({ payload }) {
//         yield put({ type: SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS })
//         yield put({ type: SUPPLIERS_LINE_ITEM_SET_LOADING, loading: true })
//         const { lineItemId } = payload

//         const path = `/line_items/${lineItemId}/suppliers/line_items`

//         try {
//             const data = yield call(apiFetch, path)

//             const formattedData = deserialize(data)
//             const normalizeData = normalize(formattedData, supplierLineItemsSchema)

//             yield put({
//                 type: SUPPLIERS_LINE_ITEM_FETCH_SUPPLIERS_SUCCESS,
//                 ...normalizeData
//             })
//         } catch (error) {
//             const errorName = error.name
//             const errorMessage = error.message
//             yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
//         } finally {
//             yield put({ type: SUPPLIERS_LINE_ITEM_SET_LOADING, loading: false })
//         }
//     })
// }

export function *supplierOrdersSubmit () {
    yield takeLatest(SUPPLIERS_ORDERS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIERS_ORDERS_SAVE })

        const { orderId, supplierOrder, id, paymentVoucher, onSuccess, onError } = payload

        try {
            const method = id ? 'PATCH' : 'POST'

            let path = `/orders/${orderId}/suppliers/orders`
            if (id) { path += `/${id}` }

            const body = {
                supplier_order: supplierOrder,
                payment_voucher: paymentVoucher
            }
            const data = yield call(apiPostForm, path, method, body)

            if ('error' in data) {
                onError(data)
                yield put({ type: SUPPLIERS_ORDERS_SAVE_FAIL, errors: data.error_description })
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                yield put({
                    type: SUPPLIERS_ORDERS_SAVE_SUCCESS,
                    ...normalize(formattedData, supplierOrderSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                onSuccess()
            }
        } catch (error) {
            yield put({ type: SUPPLIERS_ORDERS_SAVE_FAIL, errors: error.error_description })
            onError(error)
        }
    })
}


export function *fetchSupplierOrderLineItems () {
    yield takeEvery(SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIERS_ORDERS_LINE_ITEMS_FETCH })

        const supplierOrderId = payload.id

        const path = `/suppliers/orders/${supplierOrderId}/line_items`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierLineItemsSchema)

        yield put({
            type: SUPPLIERS_ORDERS_LINE_ITEMS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchSupplierOrderRequest () {
    yield takeLatest(SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER })
        yield put({ type: SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING, loading: true })

        const { orderId, id } = payload
        const path = `/orders/${orderId}/suppliers/orders/${id}`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, supplierOrderSchema)

            yield put({
                type: SUPPLIERS_ORDERS_FETCH_SUPPLIER_ORDER_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIERS_ORDERS_SET_SUPPLIERS_ORDERS_LOADING, loading: false })
        }
    })
}

export function *fetchSupplierMegaHomeItems () {
    yield takeEvery(SUPPLIER_MEGA_HOME_FETCH_ITEMS_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_MEGA_HOME_FETCH_ITEMS })

        const { page, per, query, supplierCategoryId } = payload

        const queryString = `page=${page}&per=${per}&supplier_category_id=${supplierCategoryId}&query=${query}`

        const path = `/suppliers/mega_home_items?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierItemsSchema)
        const total = data.meta ? data.meta.total_pages * per : null

        yield put({
            type: SUPPLIER_MEGA_HOME_FETCH_ITEMS_SET_PAGINATE,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: SUPPLIER_MEGA_HOME_FETCH_ITEMS_SUCCESS,
            ...normalizeData
        })
    })
}

// export function *fetchSupplierOrderPDF () {
//     yield takeLatest(SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_REQUEST, function *({ payload }) {
//         yield put({ type: SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF })
//         yield put({ type: SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING, loading: true })

//         const { orderId, supplierOrderId, locale } = payload
//         const path = `/orders/${orderId}/suppliers/orders/attachments/${supplierOrderId}?locale=${locale}`
//         try {
//             const data = yield call(apiFileFetch, path)
//             const fileURL = URL.createObjectURL(data)
//             window.open(fileURL)

//             yield put({ type: SUPPLIERS_FETCH_SUPPLIER_ORDERS_PDF_SUCCESS })
//         } catch (error) {
//             const errorName = error.name
//             const errorMessage = error.message
//             yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
//         } finally {
//             yield put({ type: SUPPLIERS_SET_SUPPLIER_ORDERS_PDF_LOADING, loading: false })
//         }
//     })
// }

export default function *rootSaga () {
    yield all([
        fork(fetchSupplierOrderLineItems),
        fork(fetchSupplier),
        fork(submitSupplier),
        // fork(fetchSupplierOrderPDF),
        fork(fetchSupplierOrderRequest),
        fork(fetchSupplierOrders),
        fork(fetchSupplierOrdersBySupplier),
        // fork(fetchSupplierOrdersLineItemsRequest),
        fork(fetchSuppliers),
        fork(searchSuppliersRequest),
        fork(supplierOrdersSubmit),
        fork(fetchListSupplierOrders),
        fork(fetchSupplierMegaHomeItems)
    ])
}
