import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    CUSTOMER_PROFIT_SHARINGS_FETCH,
    CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS,
    CUSTOMER_PROFIT_SHARING_SAVE,
    CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS,
    CUSTOMER_PROFIT_SHARING_DELETE,
    CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS,
    CUSTOMER_PROFIT_SHARING_SET_PAGINATION,
    CUSTOMERS_PROFIT_SHARING_SET_FORM_MODAL,
    CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS,
    PROFIT_SHARING_ORDER_FETCH,
    PROFIT_SHARINGS_ORDER_FETCH_SUCCESS,
    PROFIT_SHARING_ORDER_SET_PAGINATION
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showProfitSharingForm: false,
    profitSharingIdSelected: null,
    errors: new Map()
})

export default function customerProfitSharingsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_PROFIT_SHARINGS_FETCH:
            return state.merge({ loading: true })
        case CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CUSTOMER_PROFIT_SHARING_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case CUSTOMER_PROFIT_SHARING_SAVE:
            return state.merge({ saving: action.saving })
        case CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS:
            return state.merge({
                items: new List(items.includes(result) ? items : items.push(result))
            })
        case CUSTOMER_PROFIT_SHARING_DELETE:
            return state.merge({ loading: true })
        case CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(items.delete(items.indexOf(result)))
            })
        case CUSTOMERS_PROFIT_SHARING_SET_FORM_MODAL:
            return state.merge({
                showProfitSharingForm: action.visible,
                profitSharingIdSelected: action.id
            })
        case CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS:
            return state.merge({
                errors: action.formErrors
            })
        case PROFIT_SHARING_ORDER_FETCH:
            return state.merge({ loading: true })
        case PROFIT_SHARINGS_ORDER_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case PROFIT_SHARING_ORDER_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        default:
            return state
    }
}
