import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { Button, Tag, Tabs } from 'antd'
import { PhoneOutlined, MailOutlined, MessageOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import orderActions from '../../../redux/orders/actions'
import documentAction from '../../../redux/orders/documents/actions'
import customerRoleActions from '../../../redux/customers/roles/actions'

import OrderTable from '../OrderTable'
import UploadAndDownloadReport from './UploadAndDownloadReport'
import InvoiceReportsIndexContainer from '../Reports/InvoiceReportsIndexContainer'

const { fetchOrders } = orderActions

const {
    fetchOrderDocument,
    setInvoiceModal,
    setOrderId
} = documentAction

const { fetchCustomerRoles } = customerRoleActions

class DocumentsIndexContainer extends Component {
    static propTypes = {
        customerRoleEntities: Immutable.map.isRequired,
        fetchOrderDocument: PropTypes.func.isRequired,
        fetchOrders: PropTypes.func.isRequired,
        orderDocuments: Immutable.map.isRequired,
        orderEntities: Immutable.map.isRequired,
        orders: Immutable.map.isRequired,
        setInvoiceModal: PropTypes.func.isRequired,
        setOrderId: PropTypes.func.isRequired
    }

    componentDidMount () {
        const { fetchOrders, location, fetchCustomerRoles, history } = this.props

        if (location.search) {
            history.push({
                search: ''
            })
        }

        fetchOrders('all')
        fetchCustomerRoles()
    }

    parseParams = (params = '') => {
        if(!params) return {}
        const rawParams = params.replace('?', '').split('&')
        const extractedParams = {}
        rawParams.forEach((item) => {
            item = item.split('=');
            extractedParams[item[0]] = item[1]
        })
        return extractedParams
    }

    getOrders () {
        const { orderEntities, orders } = this.props
        const orderItems = orders.get('items')

        return orderItems.map((id) => orderEntities.get(id)) || new Map()
    }

    getOrderTotal () {
        const { orders } = this.props
        return orders.get('total')
    }

    getOrderColumns () {
        const { i18n } = this.props
        const orderColumns = [
            {
                title: i18n.t('orders/tables:orderNumber'),
                dataIndex: 'id',
                render: (orderId, order) => this.renderOrderNumber(orderId, order.customer)
            }, {
                title: i18n.t('orders/tables:customer'),
                dataIndex: 'customer',
                render: (customer) => this.renderCustomerDetail(customer)
            }, {
                title: i18n.t('orders/tables:state'),
                dataIndex: 'state_label',
                render: (dataIndex, record) => this.renderOrderState(dataIndex, record)
            }, {
                title: i18n.t('documents:quotation'),
                key: 'quotation',
                render: (order) => this.renderQuotationBtn(order.id)
            }, {
                title: i18n.t('documents:invoice'),
                key: 'invoice',
                render: (order) => this.renderInvoiceBtn(order.id, order.have_invoices)
            }
        ]

        return orderColumns
    }

    renderOrderNumber (orderId, customer) {
        const { i18n } = this.props
        const leadSource = customer && customer.lead_source || ''

        return (
            <div>
                <Link to={`/inquiries/${orderId}`}>
                    {orderId}
                </Link>
                <div>
                    {`${i18n.t('customerForm.source')}: ${leadSource}`}
                </div>
            </div>
        )
    }

    renderOrderState = (stateLabel, record) => {
        const { i18n } = this.props

        const paymentMethod = record.payment_method
        const isDeposit = record.is_deposit_payment_term

        if (record.state !== 'payment') { return stateLabel }

        const payByCheque = paymentMethod === 'cheque' ? `<${i18n.t('inquiries:payByCheque')}>` : ''
        const depositPaymentTerm = isDeposit ? `<${i18n.t('inquiries:stateDropdown.deposit')}>` : ''

        return (
            <div>
                <div>{stateLabel}</div>
                <div>{depositPaymentTerm}</div>
                <div>{payByCheque}</div>
            </div>
        )
    }

    renderQuotationBtn (orderId) {
        const { i18n, fetchOrderDocument, orderDocuments } = this.props
        const locale = orderDocuments.get('dashboardDocumentPDFLanguage')

        return (
            <Button size="small"
                onClick={() => fetchOrderDocument('quotation', locale, orderId)}>
                {i18n.t('documents:quotation')}
            </Button>
        )
    }

    renderInvoiceBtn (orderId, haveInvoices) {
        const { i18n, orderDocuments } = this.props
        const loading = orderDocuments.get('invoiceLoading')

        return (
            <Button size="small" loading={loading} type={haveInvoices ? "primary" : ''}>
                {i18n.t('documents:invoice')}
            </Button>
        )
    }


    getCustomerRole = (customer) => {
        const { customerRoleEntities } = this.props
        const customerRole = customer.roles || new List()

        if (customerRole.length > 0) {
            const role = customerRole[0]
            const roleName = customerRoleEntities.getIn([role.id, 'name'])
            return (
                <Tag style={{ marginLeft: '2px' }}>{roleName}</Tag>
            )
        }
    }

    renderCustomerDetail (customer) {
        if (!customer) { return null }

        return (
            <div>
                <Link to={`/customers/${customer.id}`}>
                    {customer.first_name} {customer.last_name}
                </Link>
                {this.getCustomerRole(customer)}
                <div><PhoneOutlined type="phone" style={{ marginRight: 5 }}/>
                    {customer.phone_number}
                </div>
                <div><MailOutlined style={{ marginRight: 5 }}/>
                    {customer.email}
                </div>
                <div><MessageOutlined style={{ marginRight: 5 }}/>
                    {customer.line_id}
                </div>
            </div>
        )
    }

    handleFetchOrdersCallback = (queryObj) => {
        const { fetchOrders, orderType } = this.props

        fetchOrders(orderType, queryObj)
    }

    handleSetModal = (orderId = null) => {
        const { orderDocuments, setInvoiceModal, setOrderId } = this.props
        const isShow = orderDocuments.get('showInvoiceModal')

        setOrderId(orderId)
        setInvoiceModal(!isShow)
    }

    render () {
        const { orders, i18n } = this.props
        const orderLoading = orders.get('loading')

        return (
            <Tabs defaultActiveKey="orders">
                <Tabs.TabPane tab={i18n.t('orders')} key="orders">
                    <OrderTable
                        documentDashboard
                        columns={this.getOrderColumns()}
                        loading={orderLoading}
                        onFetchOrders={this.handleFetchOrdersCallback}
                        orders={this.getOrders()}
                        showSearchInput
                        total={this.getOrderTotal()}
                        isRenderReservationDateFilter
                    />
                </Tabs.TabPane>
                <Tabs.TabPane key="upload" tab={`${i18n.t('documents:upload')} / ${i18n.t('documents:download')}`}>
                    <UploadAndDownloadReport />
                </Tabs.TabPane>
                <Tabs.TabPane key="reports" tab={i18n.t('documents:reports.title')}>
                    <InvoiceReportsIndexContainer />
                </Tabs.TabPane>
            </Tabs>
        )
    }
}

const mapStateToProps = state => ({
    customerRoleEntities: state.getIn(['Entities', 'customerRoles']) || new Map(),
    orderDocuments: state.get('orderDocuments'),
    orderEntities: state.getIn(['Entities', 'orders']),
    orders: state.get('orders')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fetchCustomerRoles,
        fetchOrderDocument,
        fetchOrders,
        setInvoiceModal,
        setOrderId
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['common', 'orders/tables', 'documents', 'confirms', 'sales', 'inquiries'])(withRouter(DocumentsIndexContainer))
)
