import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import ProjectCustomerList from '../../components/projects/projectCustomerList'

import actions from '../../redux/projects/customers/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    fetchCompanyProjectCustomers,
    setEditUserTypeFormModal,
    setAddCustomerFormModal,
    deleteCompanyProjectCustomer
} = actions
const { setNotifications } = notificationActions

class ProjectCustomerListContainer extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired
    }

    componentDidMount () {
        const { fetchCompanyProjectCustomers, projectId } = this.props

        fetchCompanyProjectCustomers({ id: projectId })
    }

    getCustomer = (customerId) => {
        return this.props.customerEntities.get(customerId) || new Map()
    }

    getCustomerCompanyProjects = () => {
        const { projectCustomers, customerCompanyProjectEntities } = this.props

        return projectCustomers.get('items', new List()).map((customerCompanyProjectId) => {
            const customerCompanyProject = customerCompanyProjectEntities.get(customerCompanyProjectId) || new Map()
            return new Map({
                ...customerCompanyProject.toJS(),
                customer: this.getCustomer(customerCompanyProject.get('customer')).toJS()
            })
        })
    }

    handleDeleteCustomer = (id) => {
        const { deleteCompanyProjectCustomer, projectId } = this.props

        deleteCompanyProjectCustomer({
            projectId,
            id,
            onSuccess: () => {
                setNotifications('success', 'saveSuccess', 'success')
            }
        })
    }

    handlePageChange = (pagination) => {
        const { fetchCompanyProjectCustomers, projectId } = this.props

        fetchCompanyProjectCustomers({ id: projectId, page: pagination.current })
    }

    toggleEditUserTypeForm = ({ id = null } = {}) => {
        const { projectCustomers, setEditUserTypeFormModal } = this.props

        setEditUserTypeFormModal(!projectCustomers.get('showEditUserTypeForm'), id)
    }

    toggleAddCustomerForm = () => {
        const { projectCustomers, setAddCustomerFormModal } = this.props

        setAddCustomerFormModal(!projectCustomers.get('showAddCustomerForm'))
    }

    render () {
        const { projectCustomers, projectId } = this.props

        return (
            <ProjectCustomerList
                activeCustomerCompanyProject={projectCustomers.get('customerCompanyProjectIdSelected')}
                customerCompanyProjects={this.getCustomerCompanyProjects().toJS()}
                loading={projectCustomers.get('loading')}
                onDeleteCustomer={this.handleDeleteCustomer}
                onPageChange={this.handlePageChange}
                pagination={{
                    ...projectCustomers.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                projectId={projectId}
                showEditUserTypeForm={projectCustomers.get('showEditUserTypeForm')}
                showAddCustomerForm={projectCustomers.get('showAddCustomerForm')}
                toggleEditUserTypeForm={this.toggleEditUserTypeForm}
                toggleAddCustomerForm={this.toggleAddCustomerForm}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    projectCustomers: state.get('projectCustomers'),
    customerCompanyProjectEntities: state.getIn(['Entities', 'customerCompanyProjects']),
    customerEntities: state.getIn(['Entities', 'customers'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteCompanyProjectCustomer,
        fetchCompanyProjectCustomers,
        setEditUserTypeFormModal,
        setAddCustomerFormModal,
        setNotifications
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCustomerListContainer)
