import { PAGE_SIZE } from '../../../constants/customers'

const actions = {
    CUSTOMER_PROFIT_SHARINGS_FETCH_REQUEST: 'CUSTOMER_PROFIT_SHARINGS_FETCH_REQUEST',
    CUSTOMER_PROFIT_SHARINGS_FETCH: 'CUSTOMER_PROFIT_SHARINGS_FETCH',
    CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS: 'CUSTOMER_PROFIT_SHARINGS_FETCH_SUCCESS',

    CUSTOMER_PROFIT_SHARING_SAVE_REQUEST: 'CUSTOMER_PROFIT_SHARING_SAVE_REQUEST',
    CUSTOMER_PROFIT_SHARING_SAVE: 'CUSTOMER_PROFIT_SHARING_SAVE',
    CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS: 'CUSTOMER_PROFIT_SHARING_SET_FORM_ERRORS',
    CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS: 'CUSTOMER_PROFIT_SHARING_SAVE_SUCCESS',

    CUSTOMER_PROFIT_SHARING_DELETE_REQUEST: 'CUSTOMER_PROFIT_SHARING_DELETE_REQUEST',
    CUSTOMER_PROFIT_SHARING_DELETE: 'CUSTOMER_PROFIT_SHARING_DELETE',
    CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS: 'CUSTOMER_PROFIT_SHARING_DELETE_SUCCESS',

    CUSTOMER_PROFIT_SHARING_SET_PAGINATION: 'CUSTOMER_PROFIT_SHARING_SET_PAGINATION',
    CUSTOMERS_PROFIT_SHARING_SET_FORM_MODAL: 'CUSTOMERS_PROFIT_SHARING_SET_FORM_MODAL',

    PROFIT_SHARING_ORDER_FETCH_REQUEST: 'PROFIT_SHARING_ORDER_FETCH_REQUEST',
    PROFIT_SHARING_ORDER_FETCH: 'PROFIT_SHARING_ORDER_FETCH',
    PROFIT_SHARING_ORDER_SET_PAGINATION: 'PROFIT_SHARING_ORDER_SET_PAGINATION',
    PROFIT_SHARINGS_ORDER_FETCH_SUCCESS: 'PROFIT_SHARINGS_ORDER_FETCH_SUCCESS',

    fetchCustomerProfitSharings: ({ customerId, page = 1, per = PAGE_SIZE, all = false } = {}) => ({
        type: actions.CUSTOMER_PROFIT_SHARINGS_FETCH_REQUEST,
        payload: { customerId, page, per, all }
    }),

    saveCustomerProfitSharing: ({
        customerId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.CUSTOMER_PROFIT_SHARING_SAVE_REQUEST,
            payload: { customerId, id, params, onSuccess, onError }
        }
    },

    setCustomerProfitSharingFormModal: (visible, id) => ({
        type: actions.CUSTOMERS_PROFIT_SHARING_SET_FORM_MODAL,
        visible, id
    }),

    deleteCustomerProfitSharing: ({ customerId, id, onSuccess = () => {} } = {}) => ({
        type: actions.CUSTOMER_PROFIT_SHARING_DELETE_REQUEST,
        payload: { customerId, id, onSuccess }
    }),

    fetchProfitSharingOrders: ({ page = 1, per = PAGE_SIZE, query = null } = {}) => ({
        type: actions.PROFIT_SHARING_ORDER_FETCH_REQUEST,
        payload: { page, per, query }
    }),
}

export default actions
