import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import TruckReservationForm from '../../../components/truckReservationForm'

import reservationTruckTypeActions from '../../../redux/reservationTruckTypes/actions'
import actions from '../../../redux/truckReservations/actions'
import trucksActions from '../../../redux/trucks/actions'
import noticeActions from '../../../redux/notifications/actions'
import inquiryActions from '../../../redux/inquiry/actions'

const { saveTruckReservation, setTruckReservationFormError, setUpdatingId } = actions
const { setReservationTruckTypeSelected } = reservationTruckTypeActions
const { resetSelectedTruckOwner } = trucksActions
const { setNotifications } = noticeActions
const { setTruckReservation } = inquiryActions

class TruckReservationFormContainer extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        orderId: PropTypes.string.isRequired,
        shippingCostId: PropTypes.string,

        setTruckReservationFormError: PropTypes.func.isRequired,
        truckReservationEntities: ImmutablePropTypes.map.isRequired,
        truckReservations: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onSuccess: () => {}
    }

    componentWillUnmount () {
        const {
            resetSelectedTruckOwner,
            setTruckReservationFormError,
            setUpdatingId,
            setReservationTruckTypeSelected
        } = this.props

        resetSelectedTruckOwner()
        setTruckReservationFormError()
        setUpdatingId()
        setReservationTruckTypeSelected()
    }

    getTruckReservation () {
        const { truckReservationEntities, truckReservations } = this.props

        const updatingId = truckReservations.get('updatingId')
        if (!updatingId) return new Map()

        return truckReservationEntities.get(_.toString(updatingId), new Map())
    }

    handleSubmit = (params) => {
        this.props.saveTruckReservation(params)
    }

    handleSuccess = () => {
        const { i18n, onSuccess, setNotifications, setTruckReservation } = this.props
        onSuccess()
        setNotifications(
            i18n.t('alerts:saveSuccess'),
            i18n.t('alerts:saveSuccess'),
            'success'
        )
        setTruckReservation(false, null)
    }

    handleError = (error) => {
        const { i18n, setNotifications, setTruckReservationFormError } = this.props

        if (!_.isEmpty(error)) { setTruckReservationFormError(error) }

        setNotifications(
            i18n.t('alerts:saveFail'),
            i18n.t('alerts:saveFail'),
            'error'
        )
    }

    render () {
        const { truckReservations, orderId, shippingCostId } = this.props
        return (
            <TruckReservationForm
                formErrors={truckReservations.get('formErrors').toJS()}
                onError={this.handleError}
                onSubmit={this.handleSubmit}
                onSuccess={this.handleSuccess}
                orderId={orderId}
                shippingCostId={shippingCostId}
                truckReservation={this.getTruckReservation().toJS()}
                saving={truckReservations.get('saving')}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    truckReservationEntities: state.getIn(['Entities', 'truckReservations']),
    truckReservations: state.get('truckReservations')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetSelectedTruckOwner,
        saveTruckReservation,
        setNotifications,
        setReservationTruckTypeSelected,
        setTruckReservation,
        setTruckReservationFormError,
        setUpdatingId
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['alerts'])(TruckReservationFormContainer))
