import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Dropdown, Menu, Modal } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import CancelSelectForm from './cancelSelectForm'

const renderDropdownMenu = (currentStateName, items, onChange) => {
    const currentState = items.find((value) => value.name === currentStateName)

    if (!currentState) return null

    return items.filter(state => currentState.nextStates.indexOf(state.id) !== -1).map(state => {
        return (
            <Menu.Item key={state.id}>
                <a onClick={() => onChange(currentState, state)}>{state.label}</a>
            </Menu.Item>
        )
    })
}

const renderIcon = spinning => {
    if (spinning) return <LoadingOutlined />

    return <DownOutlined />
}

const renderOrderState = (i18n, state, stateLabel, paymentMethod) => {
    if (state !== 'payment') return stateLabel

    const payByCheque = paymentMethod === 'cheque' ? `<${i18n.t('inquiries:payByCheque')}>` : ''

    return `${stateLabel} ${payByCheque}`.trim()
}

const Render = ({
    items,
    onSaveOrderState,
    paymentMethod,
    showCancelForm,
    spinning,
    state,
    stateLabel,
    toggleCancelForm
}) => {
    const { i18n } = useTranslation('inquiries')

    const onChangeState = (currentState, newState) => {
        if (newState.name === 'cancel') return toggleCancelForm()

        Modal.confirm({
            title: `${i18n.t('inquiries:stateDropdown.status')} ${currentState.label}`,
            content: `${i18n.t('inquiries:stateDropdown.changeState')} ${newState.label}`,
            onOk: () => onSaveOrderState(newState.name)
        })
    }

    return (
        <React.Fragment>
            <Dropdown key='state_dropdown' overlay={
                <Menu>{renderDropdownMenu(state, items, onChangeState)}</Menu>
            }>
                <a className="ant-dropdown-link">
                    {renderOrderState(i18n, state, stateLabel, paymentMethod)} {renderIcon(spinning)}
                </a>
            </Dropdown>
            <CancelSelectForm
                onSubmitForm={onSaveOrderState}
                toggleForm={toggleCancelForm}
                visible={showCancelForm}
            />
        </React.Fragment>
    )
}

Render.propTypes = {
    items: PropTypes.array.isRequired,
    onSaveOrderState: PropTypes.func.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    showCancelForm: PropTypes.bool,
    spinning: PropTypes.bool,
    state: PropTypes.string.isRequired,
    stateLabel: PropTypes.string.isRequired,
    toggleCancelForm: PropTypes.func.isRequired,
}

Render.defaultProps = {}

export default Render
