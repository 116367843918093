import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import _ from 'lodash'

import { Spin } from 'antd'
import actions from '../../redux/orders/businessAddresses/actions'
import OrderMailingAddressForm from '../../components/taxInvoices/OrderMailingAddressForm'

const {
    fetchOrderMailingAddress,
    saveOrderMailingAddress,
    setShowMailingAddressForm,
    fetchOrderBusinessAddresses
} = actions


class OrderMailingAddressFormContainer extends Component {
    componentDidMount () {
        const { orderId, id, fetchOrderMailingAddress } = this.props
        if (id) {
            fetchOrderMailingAddress({ orderId, id })
        }
    }

    fetchInvoices = () => {
        const { defaultQueryString, fetchOrderBusinessAddresses } = this.props
        const queryString = {}

        _.mapKeys(defaultQueryString, (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })

        fetchOrderBusinessAddresses({ ...queryString })
    }

    handleSubmitForm = (params) => {
        const {
            orderId,
            id,
            saveOrderMailingAddress,
            setShowMailingAddressForm
        } = this.props

        saveOrderMailingAddress({
            orderId,
            id,
            params,
            onSuccess: () => {
                setShowMailingAddressForm(false)
                if (!id) {this.fetchInvoices()}
            }
        })
    }

    render () {
        const { orderBusinessAddresses, id, orderMailingAddressEntities } = this.props
        const orderMailingAddress = orderMailingAddressEntities.get(_.toString(id)) || new Map()

        const errors = orderBusinessAddresses.get('mailingAddressFormErrors')
        const saving = orderBusinessAddresses.get('mailingAddressSaving')
        const loading = orderBusinessAddresses.get('mailingAddressLoading')

        if (loading) {
            return <Spin size="large" />
        }

        return (
            <OrderMailingAddressForm
                orderMailingAddress={orderMailingAddress.toJS()}
                errors={errors}
                saving={saving}
                onSubmit={this.handleSubmitForm} />

        )
    }
}

const mapStateToProps = state => ({
    orderMailingAddressEntities: state.getIn(['Entities', 'orderMailingAddresses']),
    orderBusinessAddresses: state.get('orderBusinessAddresses')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderMailingAddress,
        setShowMailingAddressForm,
        fetchOrderMailingAddress,
        fetchOrderBusinessAddresses
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderMailingAddressFormContainer)
