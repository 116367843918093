import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const AlertWrapper = styled.div`
.alert-fixed {
  position: fixed;
  top: 0px;
  right: 32px;
  width: ${isMobile ? '50%' : '20%'};
  z-index: 9999999;
  border-radius: 10px;
}
`
export default AlertWrapper
