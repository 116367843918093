import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const CATEGORIES_QUERY = gql`
    query Categories($query: String){
        categories(query: $query, limit: 10){
            id
            name
        }
    }
`

const renderOptions = (options = []) => options.map(({ id, name }) => {
    return <Select.Option value={id}>{name}</Select.Option>
})

const CategorySelector = ({ activeFirstOptionAsDefault, defaultCategory, onChange, value, ...restProps }) => {
    const { i18n } = useTranslation()
    const [initDefault, setDefault] = useState(false)
    const [selected, setSelected] = useState(value)
    const [query, setQuery] = useState(defaultCategory && defaultCategory.name)
    const { loading, data } = useQuery(CATEGORIES_QUERY, {
        variables: { query },
        onCompleted: ({ categories }) => {
            if (initDefault) return null

            initialFirstOptionAsDefault(categories)

            setDefault(true)
        }
    })

    useEffect(() => {
        if (selected !== value) {
            handleChange(value)
        }
    }, [value])

    const initialFirstOptionAsDefault = (categories) => {
        const firstCategory = categories[0]

        if (!activeFirstOptionAsDefault || !firstCategory) return null

        handleChange(firstCategory.id)
    }

    const handleChange = (value, option = {}) => {
        onChange(value, option)
        setSelected(value)
    }

    const handleSearch = value => {
        setQuery(value)
    }

    return (
        <Select
            allowClear
            filterOption={false}
            loading={loading}
            onChange={handleChange}
            onSearch={handleSearch}
            placeholder={i18n.t('searchCategory')}
            showSearch
            value={selected}
            {...restProps}
        >
            {renderOptions(data && data.categories)}
        </Select>
    )
}

CategorySelector.propTypes = {
    activeFirstOptionAsDefault: PropTypes.bool,
    defaultCategory: PropTypes.object,
    onChange: PropTypes.func,
    value: PropTypes.string
}

CategorySelector.defaultProps = {
    activeFirstOptionAsDefault: false,
    onChange: () => {}
}

export default CategorySelector
