import { apiFetch } from '../../../helpers/restRequest'
import actionsNotification from '../../../redux/notifications/actions'
import { fetchCustomerLabels } from '../../../redux/customers/customerLabels/actions'

import React from 'react'
import { Modal, Form, Button, Input } from 'antd'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

const { setNotifications } = actionsNotification

export const CustomerLabelFormContainer = ({ visible, onClose }) => {
  const [error, setError] = React.useState(null)
  const dispatch = useDispatch()
  const submitNewCustomerLabel = async (value) => {
    const { customer_label: label } = value
    const data = await apiFetch('/customer_labels', 'POST', { body: JSON.stringify({ label: label }) })
    if ('error' in data) {
      setError(true)
      dispatch(setNotifications('fail', 'saveFail', 'error'))
    } else {
      dispatch(fetchCustomerLabels())
      dispatch(setNotifications('success', 'saveSuccess', 'success'))
      onClose()
    }
  }

  return (
    <Modal
      title={'Add Label'}
      visible={visible}
      destroyOnClose
      onCancel={() => onClose()}
      footer={null}
    >
      <Form onFinish={submitNewCustomerLabel}>
        <Form.Item label={'Label'}
          hasFeedback
          name={'customer_label'}
          rules={[{ required: true, message: 'Please input label' }]}
          validateStatus={error && 'error'}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CustomerLabelFormContainer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}