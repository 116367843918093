import React from 'react'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const VEHICLE_TYPES_QUERY = gql`
    query VehicleTypesWithPickupPoint {
        vehicleTypesWithPickupPoint(limit: -1) {
            id
            name
        }
    }
`

const renderOptions = options => options.map(({ id, name }) => {
    return <Select.Option value={id}>{name}</Select.Option>
})

const VehicleTypeSelector = ({ ...restProps }) => {
    const { loading, data, error } = useQuery(VEHICLE_TYPES_QUERY)

    if (error) return console.error(error)

    const options = (data && data.vehicleTypesWithPickupPoint) || []

    return (
        <Select
            allowClear
            filterOption={false}
            loading={loading}
            {...restProps}
        >
            {renderOptions(options)}
        </Select>
    )
}

VehicleTypeSelector.propTypes = {}

VehicleTypeSelector.defaultProps = {}

export default VehicleTypeSelector
