import styled from 'styled-components'
const PaymentWrapper = styled.div`

.clipboard {
  margin: 4px;
  float: right;
}

.alertMessage {
  margin: 4px;
}

.card-url {
  overflow-wrap: break-word;
  background-color: #eee;
}
`
export default PaymentWrapper