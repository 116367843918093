const actions = {
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_REQUEST: 'JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_REQUEST',
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH: 'JOURNAL_ENTRY_FORMAT_ITEMS_FETCH',
    JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS: 'JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_SUCCESS',
    JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION: 'JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION',
    JOURNAL_ENTRY_FORMAT_ITEMS_SET_FORM_MODAL: 'JOURNAL_ENTRY_FORMAT_ITEMS_SET_FORM_MODAL',

    JOURNAL_ENTRY_FORMAT_ITEM_DELETE_REQUEST: 'JOURNAL_ENTRY_FORMAT_ITEM_DELETE_REQUEST',
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE: 'JOURNAL_ENTRY_FORMAT_ITEM_DELETE',
    JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS: 'JOURNAL_ENTRY_FORMAT_ITEM_DELETE_SUCCESS',

    JOURNAL_ENTRY_FORMAT_ITEM_SAVE_REQUEST: 'JOURNAL_ENTRY_FORMAT_ITEM_SAVE_REQUEST',
    JOURNAL_ENTRY_FORMAT_ITEM_SAVING: 'JOURNAL_ENTRY_FORMAT_ITEM_SAVING',
    JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS: 'JOURNAL_ENTRY_FORMAT_ITEM_SET_FORM_ERRORS',
    JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS: 'JOURNAL_ENTRY_FORMAT_ITEM_SAVE_SUCCESS',

    fetchJournalEntryFormatItems: ({
        formatId = null,
        page = 1,
        per = 20
    } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMAT_ITEMS_FETCH_REQUEST,
        payload: { page, per, formatId }
    }),

    setFormatItemForm: ({ visible, selected } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMAT_ITEMS_SET_FORM_MODAL,
        visible, selected
    }),

    deleteJournalEntryFormatItem: ({ formatId, id, onSuccess = () => {} } = {}) => ({
        type: actions.JOURNAL_ENTRY_FORMAT_ITEM_DELETE_REQUEST,
        payload: { formatId, id, onSuccess }
    }),

    saveJournalEntryFormatItem: ({
         id = '',
         journalEntryFormatId = '',
         params = {},
         onSuccess = () => {},
         onError = () => {}
    } = {}) => {
        return {
            type: actions.JOURNAL_ENTRY_FORMAT_ITEM_SAVE_REQUEST,
            payload: { id, journalEntryFormatId,  params, onSuccess, onError }
        }
    },

    setPagination: (pagination) => {
        return {
            type: actions.JOURNAL_ENTRY_FORMAT_ITEMS_SET_PAGINATION,
            pagination
        }
    }
}

export default actions
