import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Modal, Table, Button, Divider, Popconfirm } from 'antd'
import { EditOutlined, DeleteOutlined} from '@ant-design/icons'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ProjectUserTypeFormContainer from '../../containers/Projects/ProjectUserTypeFormContainer'
import ProjectWrapper from './project.style'

import AddCustomerFormContainer from '../../containers/Projects/AddCustomerFormContainer'

const ProjectCustomerList = ({
    i18n,
    activeCustomerCompanyProject,
    customerCompanyProjects,
    loading,
    onDeleteCustomer,
    onPageChange,
    pagination,
    projectId,
    showEditUserTypeForm,
    showAddCustomerForm,
    toggleEditUserTypeForm,
    toggleAddCustomerForm
}) => {
    const columns = [
        {
            key: 'name',
            title: i18n.t('customerName'),
            dataIndex: 'customer',
            render: (customer) => {
                const fullName = [customer.first_name, customer.last_name].filter(Boolean).join(' ')
                return <Link to={`/customers/${customer.id}`}>{fullName}</Link>
            }
        },
        {
            key: 'userType',
            title: i18n.t('projects:userType'),
            dataIndex: 'user_type',
            render: (userType) => i18n.t(`projects:userTypes.${_.camelCase(userType)}`)
        },
        {
            key: 'email',
            title: i18n.t('customerForm.email'),
            dataIndex: ['customer', 'email']
        },
        {
            key: 'phoneNumber',
            title: i18n.t('customerForm.phone'),
            dataIndex: ['customer', 'phone_number']
        },
        {
            key: 'lineId',
            title: i18n.t('customerForm.lineId'),
            dataIndex: ['customer', 'line_id']
        },
        {
            key: 'actions',
            render: (record) => {
                return (
                    <div>
                        <div onClick={() => toggleEditUserTypeForm({ id: record.id })}>
                            <a><EditOutlined /> {i18n.t('projects:editUserType')}</a>
                        </div>
                        <Popconfirm
                            title={i18n.t('shared:confirmDelete')}
                            onConfirm={() => onDeleteCustomer(record.id)}
                        >
                            <a className="text-danger"><DeleteOutlined /> {i18n.t('shared:delete')}</a>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]

    return (
        <ProjectWrapper>
            <Divider />
            <div className="add-customer-section">
                <Button type="primary"
                    onClick={toggleAddCustomerForm}>
                    {i18n.t('customerForm.createCustomer')}
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={customerCompanyProjects}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />

            <Modal
                visible={showEditUserTypeForm}
                onCancel={toggleEditUserTypeForm}
                footer={null}
                destroyOnClose
            >
                <ProjectUserTypeFormContainer
                    customerCompanyProject={_.find(customerCompanyProjects, ['id', activeCustomerCompanyProject])}
                    id={activeCustomerCompanyProject}
                    projectId={projectId}
                    toggleForm={toggleEditUserTypeForm}
                />
            </Modal>
            <Modal visible={showAddCustomerForm}
                onCancel={toggleAddCustomerForm}
                footer={null}
                destroyOnClose>
                <AddCustomerFormContainer projectId={projectId}
                    toggleForm={toggleAddCustomerForm} />
            </Modal>
        </ProjectWrapper>
    )
}

ProjectCustomerList.propTypes = {
    activeCustomerCompanyProject: PropTypes.string,
    customers: PropTypes.array,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    showEditUserTypeForm: PropTypes.bool,
    toggleEditUserTypeForm: PropTypes.func.isRequired
}

ProjectCustomerList.defaultProps = {
    customers: [],
    loading: false,
    pagination: false,
    showEditUserTypeForm: false
}

export default withTranslation(['shared', 'projects'])(ProjectCustomerList)
