import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Row, Col } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

const renderTrackingItems = (items) => {
    return items.map((item) => {
        const isChecked = _.get(item, 'is_checked')
        const style = isChecked ? null : { style: { textDecoration: 'line-through' } }

        return (
            <Row key={_.get(item, 'id')}>
                <Col span={4} style={{ textAlign: 'center' }}>
                    {isChecked ? <CheckOutlined /> : null}
                </Col>
                <Col span={20} {...style}>{_.get(item, 'message', '')}</Col>
            </Row>
        )
    })
}

const TrackingListByCheck = (props) => {
    const { trackingList } = props

    return (
        <div style={{ padding: '12px' }}>
            {renderTrackingItems(trackingList)}
        </div>
    )
}

TrackingListByCheck.propTypes = {
    trackingList: PropTypes.array
}

export default TrackingListByCheck
