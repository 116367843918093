import React from 'react'

import { Input, Row, Col, Select, Radio, Spin, Button, Checkbox, Form, Divider } from 'antd'
import { withTranslation } from 'react-i18next'
import { LEAD_SOURCES } from '../../constants/customers'
import { PAYMENTS_TYPE } from '../../constants/orders'

import CompanyProjectSelector from '../../containers/SelectProjects/CompanyProjectSelector'
import SelectCountry from '../../components/SelectCountry'
import ShippingAddressListOptions from '../customers/shippingAddresses/ListOptions'
import SelectShippingAddressForm from './SelectShippingAddressForm'


const { TextArea } = Input
const Option = Select.Option
const RadioGroup = Radio.Group

const handleFilterOption = (input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const selectLeadSource = () => {
    return LEAD_SOURCES.map((leadSource, index) => {
        return (
            <Option key={index} value={leadSource}>
                {leadSource}
            </Option>
        )
    })
}

const categoriesList = (props) => {
    const { categories, i18n } = props

    return categories.map((category) => {
        const categoryId = category.get('id')

        return (
            <Option key={categoryId} value={categoryId}>
                {category.get(`name_${i18n.language}`)}
            </Option>
        )
    })
}

const purchaseCompany = (props) => {
    const { customer, onSelectPurchaseCompany, i18n } = props

    const company = _.get(customer, 'customer_companies[0]', '')

    if (company === null) {
        return null
    } else {
        return (
            <Form.Item key="purchase_as_company" name='purchase_as_company'
                valuePropName='checked'>
                    <Checkbox onChange={onSelectPurchaseCompany}>
                        {i18n.t('orders:purchaseAsCompany')}
                    </Checkbox>
            </Form.Item>
        )
    }
}

const shippingAddress = (props) => {
    const {
        i18n,
        errors,
        shippingType,
        shippingAddressError,
        customerId
    } = props

    if (shippingType === 'by_osh') {
        return (
            <div>
                <Divider />
                <div>
                    <h2>{i18n.t('orders:selectShippingAddress')}</h2>
                    {shippingAddressError ? <span style={{ color: 'red' }}>กรุณาเลือก</span> : null}
                </div>
                <SelectShippingAddressForm customerId={customerId} />
                <Divider/>
            </div>
        )
    }

    if (shippingType === 'export') {
        return (
            <div>
                <Divider />
                <h2>{i18n.t('orders:selectCountry')}</h2>
                <Form.Item  name={['order_export_shipment_attributes', 'country_id']}
                    validateStatus={errors.get('order_export_shipment.country') ? 'error' : null}
                    help={errors.get('order_export_shipment.country')}
                    hasFeedback>
                    <SelectCountry />
                </Form.Item>
            </div>
        )
    }

    return null
}

const csesResult = (props) => {
    const { customerServices, csItems, defaultStaff } = props

    const csesList = csItems.map((csId) => {
        const firstName = customerServices.getIn([`${csId}`, 'first_name']) || ''
        const lastName = customerServices.getIn([`${csId}`, 'last_name']) || ''
        return (
            {
                key: `${firstName} ${lastName}`,
                value: customerServices.getIn([`${csId}`, 'id'])
            }
        )
    })

    return defaultStaff.concat(csesList.toJS()).map((csOption) => {
        return (
            <Option key={csOption.key} value={csOption.value}>
                {csOption.key}
            </Option>
        )
    })
}

const salesResult = (props) => {
    const { sales, saleItems, defaultStaff } = props

    const salesList = saleItems.map((saleId) => {
        const firstName = sales.getIn([`${saleId}`, 'first_name']) || ''
        const lastName = sales.getIn([`${saleId}`, 'last_name']) || ''
        return (
            {
                key: `${firstName} ${lastName}`,
                value: sales.getIn([`${saleId}`, 'id'])
            }
        )
    })

    return defaultStaff.concat(salesList.toJS()).map((saleOption) => {
        return (
            <Option key={saleOption.key} value={saleOption.value}>
                {saleOption.key}
            </Option>
        )
    })
}

const paymentsOption = (i18n) => {
    return PAYMENTS_TYPE.map((payment, index) => {
        return (
            <Radio key={index} value={payment.value}>{i18n.t(`orders:${payment.i18n}`)}</Radio>
        )
    })
}

const renderTicketDescription = description => {
    if (!description) return null

    return (
        <Row>
            <Col span={24}>
                <Form.Item>{description}</Form.Item>
            </Col>
        </Row>
    )
}

const onSubmitForm = (values, props) => {
    props.onSubmitOrder(values)
}

const LeadGeneration = (props) => {
    const {
        i18n,
        customer,
        onSelectPayment,
        paymentValue,
        onSearchCses,
        onSelectCs,
        onSearchSales,
        onSelectSale,
        loading,
        csesLoading,
        savingOrder,
        defaultStaffId,
        onSelectShippingType,
        ticketDescription
    } = props

    const [form] = Form.useForm()

    const firstName = customer.first_name || ''
    const lastName = customer.last_name || ''
    const leadSource = customer.lead_source

    return (
        <div>
            <h2>{firstName} {lastName}</h2>
            <Form onFinish={(values) => onSubmitForm(values, props)} form={form}>
                <Col>
                    {renderTicketDescription(ticketDescription)}
                    <Row>
                        <Col span={12}>
                            <h3>{i18n.t('orders:leadSources')}</h3>
                            <Form.Item key="lead_source"
                                initialValue={leadSource}
                                name='lead_source'>
                                    <Select showSearch
                                        allowClear
                                        filterOption={(input, option) => handleFilterOption(input, option)}
                                        style={{ width: '80%' }}
                                        placeholder={i18n.t('orders:selectLeadSoure')}>
                                        {selectLeadSource()}
                                    </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <h3>{i18n.t('orders:productCategory')}</h3>
                            <Form.Item key="category_id"
                                name='category_id'>
                                    <Select showSearch
                                        allowClear
                                        filterOption={(input, option) => handleFilterOption(input, option)}
                                        style={{ width: '80%' }}
                                        placeholder={i18n.t('orders:selectCategory')}>
                                        {categoriesList(props)}
                                    </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {purchaseCompany(props)}
                        </Col>
                    </Row>
                    <h3>{i18n.t('orders:orderNote')}</h3>
                    <Form.Item key="note" name='note'>
                        <TextArea />
                    </Form.Item>
                    <Form.Item key="shipping_type" name='shipping_type' initialValue='by_osh'>
                        <Radio.Group onChange={(e) => onSelectShippingType(e.target.value)}>
                            <Radio value='by_osh'>เราจัดส่ง</Radio>
                            <Radio value='self_pickup'>{i18n.t('orders:selfPickup')}</Radio>
                            <Radio value='not_specified_address'>{i18n.t('orders:unspecifiedAddress')}</Radio>
                            <Radio value='export'>ส่งออก</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {shippingAddress(props)}
                    <Row>
                        <Col span={12}>
                            <h3>{i18n.t('orders:customerService')}</h3>
                            <Form.Item key="cs_id" name='cs_id' initialValue={defaultStaffId}>
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{ width: '80%' }}
                                        placeholder={i18n.t('orders:searchCs')}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        notFoundContent={csesLoading ? <Spin size="small" /> : null}
                                        onSearch={(search) => onSearchCses(search)}
                                        onChange={onSelectCs}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                            </div>
                                        )} >
                                        {csesResult(props)}
                                    </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <h3>{i18n.t('orders:sales')}</h3>
                            <Form.Item key="sale_id" name='sale_id' initialValue={defaultStaffId}>
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{ width: '80%' }}
                                        placeholder={i18n.t('orders:searchSale')}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        notFoundContent={loading ? <Spin size="small" /> : null}
                                        onSearch={(search) => onSearchSales(search)}
                                        onChange={onSelectSale}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                            </div>
                                        )} >
                                        {salesResult(props)}
                                    </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <h3>{i18n.t('orders:paymentMethod')}</h3>
                            <Form.Item key="payment_method" name='payment_method'
                                initialValue={paymentValue}>
                                    <RadioGroup onChange={onSelectPayment} defaultValue={'cash'}>
                                        {paymentsOption(i18n)}
                                    </RadioGroup>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <h3>{i18n.t('orders:project')}</h3>
                            <Form.Item key="company_project_ids" name='company_project_ids'>
                                <CompanyProjectSelector mode="multiple" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Button htmlType="submit" type="primary" size="large" loading={savingOrder}>
                    {i18n.t('orders:addOrder')}
                </Button>
            </Form>
        </div>

    )
}


LeadGeneration.propTypes = {}

export default withTranslation(['orders', 'addresses'])(LeadGeneration)
