import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../redux/notifications/actions'
import orderActions from '../orders/actions'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

import { orderApproverWaitSchema, orderApproverWaitStatesSchema, purchasersSchema } from '../schema'

const { setNotifications } = actionsNotification

const {
    ORDERS_APPROVER_WAIT_STATE_REQUEST,
    ORDERS_APPROVER_WAIT_STATE,
    ORDERS_APPROVER_WAIT_STATE_SUCCESS,
    SAVE_ORDERS_APPROVER_WAIT_STATE_REQUEST,
    SAVE_ORDERS_APPROVER_WAIT_STATE,
    SAVE_ORDERS_APPROVER_WAIT_STATE_SUCCESS,
    SAVE_ORDERS_APPROVER_WAIT_REQUEST,
    SAVE_ORDERS_APPROVER_WAIT,
    SAVE_ORDERS_APPROVER_WAIT_SUCCESS,
    PURCHASER_FETCH_REQUEST,
    PURCHASER_FETCHING_REQUEST
} = actions

const {
    ORDERS_PURCHASERS_FETCH_SUCCESS
} = orderActions

export function *fetchOrderApproverWaitState () {
    yield takeEvery(ORDERS_APPROVER_WAIT_STATE_REQUEST, function *() {
        yield put({ type: ORDERS_APPROVER_WAIT_STATE, loading: true })
        const path = '/orders/approver_waits/states'
        const method = 'GET'

        const data = yield call(apiFetch, path, method)
        const formattedData = deserialize(data)

        yield put({
            type: ORDERS_APPROVER_WAIT_STATE_SUCCESS,
            ...normalize(formattedData, orderApproverWaitStatesSchema)
        })
    })
}

export function *setOrderApproverWait () {
    yield takeLatest(SAVE_ORDERS_APPROVER_WAIT_STATE_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_ORDERS_APPROVER_WAIT_STATE, saving: true })

        const { id, newState } = payload

        const path = `/orders/approver_waits/${id}/states/${newState}`
        const method = 'PATCH'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_ORDERS_APPROVER_WAIT_STATE_SUCCESS,
                ...normalize(formattedData, orderApproverWaitSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SAVE_ORDERS_APPROVER_WAIT_STATE, saving: false })
        }
    })
}


export function *submitOrderApproverWait () {
    yield takeLatest(SAVE_ORDERS_APPROVER_WAIT_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_ORDERS_APPROVER_WAIT, saving: true })

        const { orderId, values } = payload

        const path = `/orders/${orderId}/approver_wait`
        const method = 'PATCH'
        const body = JSON.stringify({ order_approver_wait: values })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_ORDERS_APPROVER_WAIT_SUCCESS,
                ...normalize(formattedData, orderApproverWaitSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SAVE_ORDERS_APPROVER_WAIT, saving: false })
        }
    })
}

export function *fetchPurchaser () {
    yield takeEvery(PURCHASER_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: PURCHASER_FETCHING_REQUEST })
        const all = payload.all ? 'true' : 'false'

        const data = yield call(apiFetch, `/purchasers?all=${all}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, purchasersSchema)
        yield put({
            type: ORDERS_PURCHASERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchOrderApproverWaitState),
        fork(setOrderApproverWait),
        fork(submitOrderApproverWait),
        fork(fetchPurchaser)
    ])
}
