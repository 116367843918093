import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'

import { Table } from 'antd'

import activityLogsAction from '../../../redux/orders/activityLogs/actions'

const { fetchActivityLogOrder } = activityLogsAction

class ActivityLogOrderContainer extends Component {
    componentDidMount () {
        const { id, fetchActivityLogOrder } = this.props
        fetchActivityLogOrder(1, id)
    }

  renderEventColumn = (activityLogOrder) => {
      let style
      if (activityLogOrder.event === 'update') {
          style = { color: '#1890ff' }
      } else if (activityLogOrder.event === 'create') {
          style = { color: '#00b380' }
      } else if (activityLogOrder.event === 'destroy') {
          style = { color: '#f5222d' }
      }

      return <font style={style}> {this.props.i18n.t(`inquiries:activityLogs.${activityLogOrder.event}`)} </font>
  }

  orderActivityLogOrderData = () => {
      const { entities, orderActivityLogs } = this.props
      return orderActivityLogs.get('orderItems').map((id, index) => {
          const item = entities.getIn(['orderActivityLogOrder', id])
          const current = orderActivityLogs.getIn(['orderPagination', 'current'])
          const defaultPageSize = orderActivityLogs.getIn(['orderPagination', 'defaultPageSize'])
          const number = (defaultPageSize * (current - 1)) + index + 1

          return (
              {
                  key: number,
                  updatedAt: item.getIn(['localized_time', 'updated_at']),
                  username: item.get('whodunnit_name'),
                  event: item.get('event'),
                  customerService: item.get('cs_name'),
                  sales: item.get('sale_name'),
                  state: item.get('state')
              }
          )
      })
  }

  getPagination () {
      const { orderActivityLogs } = this.props

      return orderActivityLogs.get('orderPagination') || new Map()
  }

  handleTableChange = (pagination) => {
      const { id, fetchActivityLogOrder } = this.props
      const page = pagination.current
      fetchActivityLogOrder(page, id)
  }

  render () {
      const { i18n, orderActivityLogs } = this.props
      const orderLoading = orderActivityLogs.get('orderLoading')

      const activityLogOrderColumn = [
          {
              title: i18n.t('inquiries:number'),
              dataIndex: 'key',
              key: 'no'
          }, {
              title: i18n.t('inquiries:activityLogs.updatedAt'),
              dataIndex: 'updatedAt',
              key: 'updatedAt'
          }, {
              title: i18n.t('inquiries:activityLogs.username'),
              dataIndex: 'username',
              key: 'username'
          }, {
              title: i18n.t('inquiries:activityLogs.event'),
              key: 'event',
              render: (activityLogOrder) => this.renderEventColumn(activityLogOrder)
          }, {
              title: i18n.t('inquiries:activityLogs.customerService'),
              dataIndex: 'customerService',
              key: 'customerService'
          }, {
              title: i18n.t('inquiries:activityLogs.sales'),
              dataIndex: 'sales',
              key: 'sales'
          }, {
              title: i18n.t('inquiries:activityLogs.state'),
              dataIndex: 'state',
              key: 'state'
          }
      ]

      return (
          <div>
              <h3> {i18n.t('inquiries:activityLogs.order')} </h3>
              <Table
                  columns={activityLogOrderColumn}
                  scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                  dataSource={this.orderActivityLogOrderData().toJS()}
                  pagination={{
                      ...this.getPagination().toJS(),
                      showSizeChanger: false
                    }}
                  onChange={this.handleTableChange}
                  loading={orderLoading}
              />
          </div>
      )
  }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    orderActivityLogs: state.get('orderActivityLogs')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchActivityLogOrder
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(ActivityLogOrderContainer))
