import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Form, DatePicker, Button, Card } from 'antd'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
}
const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 4 }
}

class ExportReport extends Component {
    constructor (props) {
        super(props)

        this.formRef = createRef()
    }

    static propTypes = {
        loading: PropTypes.bool,
        searchType: PropTypes.oneOf(['date-range', 'monthly']),
        onLoad: PropTypes.func,
        title: PropTypes.string,
        description: PropTypes.string
    }

    static defaultProps = {
        loading: false,
        onLoad: () => {},
        searchType: 'date-range'
    }

    handleSubmit = (e) => {
        const { onLoad } = this.props
        e.preventDefault()

        this.formRef.current.validateFields().then(fieldValue => {
            let startDate = null
            let endDate = null
            let monthly = null
            if (fieldValue['range_picker']) {
                startDate = fieldValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldValue['range_picker'][1].format('YYYY-MM-DD')
            }

            if (fieldValue['month_picker']) {
                monthly = fieldValue['month_picker'].format('YYYY-MM')
            }

            const values = {
                start_date: startDate,
                end_date: endDate,
                monthly
            }

            onLoad(values)
        })
    }

    renderCardHead = () => {
        const { title, description } = this.props

        return (
            <Card.Meta
                title={title}
                description={description} />
        )
    }

    renderDateRangeInput = () => {
        const { i18n, searchType } = this.props

        if (searchType !== 'date-range') { return null }

        return (
            <Form.Item key="search_by_date" label={i18n.t('documents:searchByDate')}
                name='range_picker'
                rules={[{
                    required: true,
                    message: i18n.t('documents:pleaseInputDate')
                }]}
                {...formItemLayout}>
                    <DatePicker.RangePicker />
            </Form.Item>
        )
    }

    renderMonthlyInput = () => {
        const { i18n, searchType } = this.props

        if (searchType !== 'monthly') { return null }

        return (
            <Form.Item key="search_by_month" label={i18n.t('documents:searchByMonth')}
                name='month_picker'
                rules={[{
                    required: true,
                    message: i18n.t('documents:searchByMonth')
                }]}
                {...formItemLayout}>
                    <DatePicker.MonthPicker />
            </Form.Item>
        )
    }

    render () {
        const { loading, i18n } = this.props

        return (
            <Card
                title={this.renderCardHead()}
                style={{ margin: '20px 0' }}
            >
                <Form layout="inline"
                    labelAlign="left"
                    ref={this.formRef}
                    hideRequiredMark>
                    {this.renderDateRangeInput()}
                    {this.renderMonthlyInput()}
                    <Form.Item key="action_btn" {...buttonItemLayout}>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('documents:download')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

export default withTranslation(['documents'])(ExportReport)
