import React from 'react'
import PropTypes from 'prop-types'

import {Button, Checkbox, DatePicker, Form, Modal, Radio} from 'antd'
import { withTranslation } from 'react-i18next'
import SelectSupplierContainer from '../../containers/SelectSupplier'

const SupplierInvoiceExport = ({
    onSubmit,
    onToggleVisibleModal,
    exporting,
    visibleModal,
    i18n
}) => {
    const handleSubmit = values => {
        let startDate = null
        let endDate = null
        if (values['range_picker']) {
            startDate = values['range_picker'][0].format('YYYY-MM-DD')
            endDate = values['range_picker'][1].format('YYYY-MM-DD')
        }
        const { supplierId, havePv } = values
        const newValues = {
            start_date: startDate,
            end_date: endDate,
            supplier_id: supplierId,
            have_pv: havePv
        }

        onSubmit({ params: newValues })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 }
        }
    }

    return (
        <div>
            <Button
                key="import-button"
                onClick={onToggleVisibleModal}
                type="primary"
            >
                {i18n.t('supplierInvoices:export')}
            </Button>

            <Modal
                footer={null}
                key="import-modal"
                onCancel={onToggleVisibleModal}
                title={i18n.t('supplierInvoices:export')}
                visible={visibleModal}
            >
                <Form
                    onFinish={handleSubmit}
                    {...formItemLayout}
                >
                    <Form.Item label={i18n.t('supplierInvoices:selectDate')}
                        name='range_picker'
                        rules={[{
                            required: true,
                            message: i18n.t('supplierInvoices:selectDate')
                        }]}>
                            <DatePicker.RangePicker />
                    </Form.Item>
                    <Form.Item label={i18n.t('supplierInvoices:supplier')}
                        name='supplierId'>
                            <SelectSupplierContainer style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name='havePv'
                        label={i18n.t('supplierInvoices:pv')}>
                            <Radio.Group>
                                <Radio value={true}>{i18n.t('supplierInvoices:havePv')}</Radio>
                                <Radio value={false}>{i18n.t('supplierInvoices:haveNoPv')}</Radio>
                            </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        key="actions"
                        {...formItemLayoutWithOutLabel}
                    >
                        <Button
                            htmlType="submit"
                            loading={exporting}
                            type="primary"
                        >
                            {i18n.t('supplierInvoices:export')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

SupplierInvoiceExport.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onToggleVisibleModal: PropTypes.func,
    exporting: PropTypes.bool,
    visibleModal: PropTypes.bool
}

SupplierInvoiceExport.defaultProps = {
    onSubmit: () => {},
    onToggleVisibleModal: () => {},
    exporting: false,
    visibleModal: false
}

export default withTranslation(['supplierInvoices'])(SupplierInvoiceExport)
