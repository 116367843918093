import styled from 'styled-components'
const InvoiceReportsWrapper = styled.div`
  .ant-layout-content {
    background: white;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .section-header {
    margin: 8px 0;
  }

  .pointer-cursor {
    cursor: pointer;
  }
  `

export default InvoiceReportsWrapper
