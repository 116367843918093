import { PAGE_SIZE } from '../../constants/customers'

const actions = {
    WITHHOLDING_TAXES_FETCH_REQUEST: 'WITHHOLDING_TAXES_FETCH_REQUEST',
    WITHHOLDING_TAXES_FETCH: 'WITHHOLDING_TAXES_FETCH',
    WITHHOLDING_TAXES_FETCH_SUCCESS: 'WITHHOLDING_TAXES_FETCH_SUCCESS',

    WITHHOLDING_TAX_FETCH_REQUEST: 'WITHHOLDING_TAX_FETCH_REQUEST',
    WITHHOLDING_TAX_FETCH: 'WITHHOLDING_TAX_FETCH',
    WITHHOLDING_TAX_FETCH_SUCCESS: 'WITHHOLDING_TAX_FETCH_SUCCESS',

    WITHHOLDING_TAX_SAVE_REQUEST: 'WITHHOLDING_TAX_SAVE_REQUEST',
    WITHHOLDING_TAX_SAVING: 'WITHHOLDING_TAX_SAVING',
    WITHHOLDING_TAX_SET_FORM_ERRORS: 'WITHHOLDING_TAX_SET_FORM_ERRORS',
    WITHHOLDING_TAX_SAVE_SUCCESS: 'WITHHOLDING_TAX_SAVE_SUCCESS',

    WITHHOLDING_TAX_DELETE_REQUEST: 'WITHHOLDING_TAX_DELETE_REQUEST',
    WITHHOLDING_TAX_DELETE: 'WITHHOLDING_TAX_DELETE',
    WITHHOLDING_TAX_DELETE_SUCCESS: 'WITHHOLDING_TAX_DELETE_SUCCESS',

    WITHHOLDING_TAX_DOWNLOAD_PDF_REQUEST: 'WITHHOLDING_TAX_DOWNLOAD_PDF_REQUEST',
    WITHHOLDING_TAX_DOWNLOAD_PDF: 'WITHHOLDING_TAX_DOWNLOAD_PDF',
    WITHHOLDING_TAX_DOWNLOAD_PDF_SUCCESS: 'WITHHOLDING_TAX_DOWNLOAD_PDF_SUCCESS',

    WITHHOLDING_TAXES_DOWNLOAD_TXT_REQUEST: 'WITHHOLDING_TAXES_DOWNLOAD_TXT_REQUEST',
    WITHHOLDING_TAXES_DOWNLOAD_TXT: 'WITHHOLDING_TAXES_DOWNLOAD_TXT',
    WITHHOLDING_TAXES_DOWNLOAD_TXT_SUCCESS: 'WITHHOLDING_TAXES_DOWNLOAD_TXT_SUCCESS',

    WITHHOLDING_TAXES_SET_PAGINATION: 'WITHHOLDING_TAXES_SET_PAGINATION',
    WITHHOLDING_TAXES_SET_WITHHOLDING_TAX_MODAL: 'WITHHOLDING_TAXES_SET_WITHHOLDING_TAX_MODAL',

    fetchWithholdingTaxes: ({
        selectedMonth = '',
        selectedType = '',
        page = 1,
        per = PAGE_SIZE
    } = {}) => ({
        type: actions.WITHHOLDING_TAXES_FETCH_REQUEST,
        payload: { selectedMonth, selectedType, page, per }
    }),

    fetchWithholdingTax: ({ id } = {}) => ({
        type: actions.WITHHOLDING_TAX_FETCH_REQUEST,
        payload: { id }
    }),

    saveWithholdingTax: ({
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.WITHHOLDING_TAX_SAVE_REQUEST,
            payload: { id, params, onSuccess, onError }
        }
    },

    deleteWithholdingTax: ({ id, onSuccess = () => {} } = {}) => ({
        type: actions.WITHHOLDING_TAX_DELETE_REQUEST,
        payload: { id, onSuccess }
    }),

    downloadWithholdingTaxPDF: ({ id } = {}) => ({
        type: actions.WITHHOLDING_TAX_DOWNLOAD_PDF_REQUEST,
        payload: { id }
    }),

    downloadWithholdingTaxesTXT: ({ selectedMonth = '', selectedType = '' } = {}) => ({
        type: actions.WITHHOLDING_TAXES_DOWNLOAD_TXT_REQUEST,
        payload: { selectedMonth, selectedType }
    }),

    resetWithholdingTaxFormErrors: (formErrors = {}) => ({
        type: actions.WITHHOLDING_TAX_SET_FORM_ERRORS,
        formErrors
    }),

    setWithholdingTaxModal: ({ visible, selected } = {}) => ({
        type: actions.WITHHOLDING_TAXES_SET_WITHHOLDING_TAX_MODAL,
        visible, selected
    })
}

export default actions
