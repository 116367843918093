import { Map } from 'immutable'
import actions from './actions'


const {
    REQUEST_ITEM_INDICES_UPLOAD_FAIL,
    REQUEST_ITEM_INDICES_UPLOAD_SUCCESS,
    REQUEST_ITEM_INDICES_UPLOADING
} = actions

const initState = new Map({
    loading: false,
    saving: false
})

export default function RequestItemIndicesReducer (state = initState, action) {
    switch (action.type) {
        case REQUEST_ITEM_INDICES_UPLOAD_FAIL:
            return state.merge({ saving: false })
        case REQUEST_ITEM_INDICES_UPLOAD_SUCCESS:
            return state.merge({ saving: false })
        case REQUEST_ITEM_INDICES_UPLOADING:
            return state.merge({ saving: true })
        default:
            return state
    }
}
