import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Card, Col, Row } from 'antd'
import ItemPricesWrapper from './itemPrices.style'

const renderCards = (i18n, suppliers) => suppliers.map(({ supplier, displayCount, displayTotalPrice }) => {
    return (
        <Col span={8}>
            <Card className="supplier-card" size="small">
                <div className="title">{supplier.name}</div>
                <div className="detail">
                    <div className="label">{i18n.t('order.phoneNumber')}</div>
                    <div>{supplier.phoneNumber}</div>
                </div>
                <div className="detail">
                    <div className="label">Note</div>
                    <div>{supplier.note}</div>
                </div>
                <div className="summary-section">
                    <div className="summary-item-left">
                        <div className="label">{i18n.t('inquiries:totalQuantity')}</div>
                        <div>{displayCount}</div>
                    </div>
                    <div className="summary-item-right">
                        <div className="label">{i18n.t('inquiries:totalPrice')}</div>
                        <div>{displayTotalPrice}</div>
                    </div>
                </div>
            </Card>
        </Col>
    )
})

const MostOrderedSuppliersList = ({
    suppliers
}) => {
    const { i18n } = useTranslation('inquiries')

    return (
        <ItemPricesWrapper id="most_ordered_suppliers_list">
            <div className="title-text">{i18n.t('inquiries:mostOrderedSuppliers')}</div>
            <Row gutter={16}>
                {renderCards(i18n, suppliers)}
            </Row>
        </ItemPricesWrapper>
    )
}

MostOrderedSuppliersList.propTypes = {
    suppliers: PropTypes.array.isRequired
}

MostOrderedSuppliersList.defaultProps = {}

export default MostOrderedSuppliersList
