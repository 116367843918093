import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'

import { Link } from 'react-router-dom'
import { Avatar, Button, Divider, Space, Table, Tag, Tooltip } from 'antd'
import { ClockCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons'
import Image from '../../../containers/Image'
import withImagePreview from '../../../containers/ImagePreview/ImagePreviewContainer'
import ImagesWrapper from '../../images/imagesWrapper.style'
import TicketsListWrapper from './ticketsList.style'

import { STATE_PROPS } from '../../../constants/tickets'
import apiConfig from '../../../../config/apiConfig'
import { MEMBER_AVATAR_PROPS } from '../../../constants/tickets'

import lineLogo from '../../../../public/images/base/line-logo.png'

const renderHtmlString = (htmlString) => {
return new ReactHtmlParser(
    htmlString,
)
}

const getColumns = i18n => {
    moment.locale(i18n.language)

    return [{
        title: 'Ticket No.',
        render: record => {
            const { customer, district, orders, province, state, stateLabel, ticketMemberAgent, ticketNo, ticketTypeLabel, id } = record

            return (
                <div className="ticket-detail">
                    <div className="detail-row">
                        <Link to={`/tickets/${id}`}>{ticketNo}</Link>
                        <Tag {...STATE_PROPS[state]}>{stateLabel}</Tag>
                    </div>
                    <div className="detail-row">
                        {renderCustomer(customer)}
                    </div>
                    <div className="detail-row">
                        <div className="detail-label">{i18n.t('tickets:agent')}</div>
                        <div className="detail-value">
                            { ticketMemberAgent ? ticketMemberAgent.staff.fullName : '-' }
                        </div>
                    </div>
                    <div className="detail-row">
                        <div className="detail-label">{i18n.t('tickets:ticketType')}</div>
                        <div className="detail-value">{ticketTypeLabel || '-'}</div>
                    </div>
                    <div className="detail-row">
                        {renderOrders(orders)}
                    </div>

                    <div className="detail-row">
                        <div className="detail-label">Source</div>
                        <div className="detail-value">
                            { record.source }
                        </div>
                    </div>
                    <div className="detail-row">
                        <div className="detail-label">{i18n.t('tickets:province')}</div>
                        <div className="detail-value">
                            {[province && province.name, district && district.name].filter(Boolean).join(', ') || '-'}
                        </div>
                    </div>
                </div>
            )
        }
    }, {
        title: i18n.t('tickets:team'),
        render: record => {
            const avatars = []

            if (record.assignToOsh) avatars.push(
                <Tooltip title="OSH Team">
                    <Avatar style={MEMBER_AVATAR_PROPS['team']}>
                        OSH Team
                    </Avatar>
                </Tooltip>
            )

            return (
                <Avatar.Group>
                    {[...avatars, ...renderMembers(record.memberStaffs)]}
                </Avatar.Group>
            )
        }
    }, {
        title: i18n.t('order.detail'),
        dataIndex: 'description'
    }, {
        title: i18n.t('tickets:lastAnswer'),
        dataIndex: 'lastTicketNoteAnswer',
        render: note => {
            if (!note) return null

            return (
                <div>
                    {renderHtmlString(note.note)}
                    <ImagesWrapper>
                        <div className="image-container">
                            {renderImages(note.images)}
                        </div>
                    </ImagesWrapper>
                </div>
            )
        }
    }, {
        title: i18n.t('tickets:images'),
        dataIndex: 'images',
        render: images => {
            if (images.length < 1) return null

            return (
                <ImagesWrapper>
                    <div className="image-container">
                        {renderImages(images)}
                    </div>
                </ImagesWrapper>
            )
        }
    }, {
        title: i18n.t('shared:createdAt'),
        dataIndex: ['createdAt', 'display'],
        sorter: true
    }, {
        title: i18n.t('tickets:updatedAt'),
        dataIndex: ['latestUpdatedDate', 'display']
    }, {
        title: i18n.t('tickets:dateValidity'),
        render: ({ buildTime, runTime, state }) => {
            const styles = { whiteSpace: 'nowrap' }

            return state === 'solved' ? (
                <div style={styles}>
                    <CheckCircleTwoTone twoToneColor="#52c41a" /> {renderTime(runTime)}
                </div>
            ) : (
                <div style={styles}>
                    <ClockCircleTwoTone twoToneColor="#c8c8c8" /> {renderTime(buildTime)}
                </div>
            )
        }
    }, {
        dataIndex: 'id',
        render: id => <Link to={`/tickets/${id}`}>{i18n.t('shared:view')}</Link>
    }]
}

const renderLine = (customer) => {
    if (!customer.lineUrl) return null
    return (
        <a href={customer.lineUrl} target='_blank'>
            <img src={lineLogo} height={20} />
        </a>
    )
}

const renderCustomer = customer => {
    if (!customer) return ''

    return (
        <div>
            <Link to={`/customers/${customer.customerNo}`}>{customer.fullName}</Link>
            {customer.phoneNumber}
            {renderLine(customer)}
        </div>
        )
}

const renderOrders = orders => orders.map(order => <div>{order.number}</div>)

const renderMembers = members => (members || []).map(({ fullName, firstName }) => {
    return <Tooltip title={fullName}><Avatar>{firstName}</Avatar></Tooltip>
})

const renderImages = images => images.map(image => {
    const imageUrl = `${apiConfig.apiBase}${image.url}`
    return (
        <Image
            contentType="image/png"
            isImageBlob={false}
            originalUrl={imageUrl}
            thumbUrl={imageUrl} />
    )
})

const renderTime = ({ hours, minutes }) => {
    return (
        <span>
            {hours} h :{minutes < 10 ? `0${minutes}` : minutes} m
        </span>
    )
}

const renderLoadMore = (i18n, onLoadMore, pageInfo, dataSource, loading) => {
    if (!pageInfo.hasNextPage) return null

    return (
        <div className="load-more">
            <Button loading={loading} onClick={() => onLoadMore({ prevNodes: dataSource, pageInfo })}>
                {i18n.t('shared:loadMore')}
            </Button>
        </div>
    )
}

const Render = ({ dataSource, loading, onLoadMore, pageInfo, onChangeTable }) => {
    const { i18n } = useTranslation('tickets')

    return (
        <TicketsListWrapper>
            <Table
                columns={getColumns(i18n)}
                dataSource={dataSource}
                loading={loading}
                pagination={false}
                scroll={{ x: 1600 }}
                onChange={onChangeTable}
                rowKey="id"
            />
            {renderLoadMore(i18n, onLoadMore, pageInfo, dataSource, loading)}
        </TicketsListWrapper>
    )
}

Render.propTypes = {
    dataSource: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    onLoadMore: PropTypes.func.isRequired,
    pageInfo: PropTypes.object.isRequired,
    onChangeTable: PropTypes.func
}

Render.defaultProps = {
    onChangeTable: () => {}
}

export default withImagePreview(Render)
