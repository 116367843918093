import { Map } from 'immutable'

import actions from './actions'

const initState = new Map({
    errorMessage: '',
    token: null,
    createdAt: null,
    expiresIn: null,
    refreshToken: null,
    tokeyType: null
})

export default function authReducer (state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return state.merge(action.accessToken)
        case actions.LOGOUT:
            return initState
        case actions.LOGIN_ERROR:
            return state.merge({
                errorMessage: action.errorMessage
            })
        default:
            return state
    }
}
