import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Form, Input, Select } from 'antd'
import SupplierShippingTruckSelectContainer from '../../containers/SupplierOrders/SupplierShippingTruckSelectContainer'
import TruckTypeContainer from '../../containers/Inquiry/TruckReservations/TruckTypeContainer'
import TruckOriginAddressSelectContainer from '../../containers/Trucks/TruckOriginAddressSelectContainer'

import { TRUCK_RESERVATION_TYPES } from '../../constants/trucks'

const { Option } = Select

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

class TruckReservationForm extends Component {
    static propTypes = {
        truckReservation: PropTypes.object.isRequired,
        saving: PropTypes.bool
    }

    handleSubmitForm = (values) => {
        const { orderId, shippingCostId, onSuccess, onError, truckReservation } = this.props

        const date = values['date']
        const truckReservationId = _.get(truckReservation, 'id', undefined)

        const formattedDate = date ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
        const truckOwner = _.get(values, 'truck_owner', null)

        const params = {
            ...values,
            ...truckOwner,
            date: formattedDate,
            shipping_cost_id: shippingCostId
        }

        this.props.onSubmit({ orderId, truckReservation: params, id: truckReservationId, onSuccess, onError })
    }

    renderDatePicker () {
        const { i18n, truckReservation, formErrors } = this.props
        const date = _.get(truckReservation, 'date')

        return (
            <Form.Item
                name='date'
                initialValue={date ? moment(date) : null}
                label={i18n.t('truckReservation:shippingDate')}
                validateStatus={_.get(formErrors, 'date', null) && 'error'}
                help={_.get(formErrors, 'date', null)}
            >
                <DatePicker
                    disabledDate={(date) => {
                        const today = moment(new Date())
                        const duration = moment.duration(date.diff(today, 'days'))
                        const daysDiff = duration.asDays()

                        return daysDiff < 0
                    }}
                    placeholder={i18n.t('truckReservation:selectDate')}
                />
            </Form.Item>
        )
    }

    renderSelectReservationType () {
        const { i18n, truckReservation, formErrors } = this.props

        return (
            <Form.Item
                name='reservation_type'
                initialValue={_.get(truckReservation, 'reservation_type', 'reserve')}
                label={i18n.t('truckReservation:reservationType')}
                validateStatus={_.get(formErrors, 'reservation_type', null) && 'error'}
                help={_.get(formErrors, 'reservation_type', null)}
            >
                <Select style={{ width: 200 }}
                    placeholder={i18n.t('truckReservation:selectReservationType')}
                    allowClear>
                    {this.renderOptionReservationType()}
                </Select>
            </Form.Item>
        )
    }

    renderSelectReservationTruckType () {
        const { i18n, truckReservation, formErrors } = this.props

        return (
            <Form.Item
                name='reservation_truck_type_id'
                initialValue={_.get(truckReservation, 'reservation_truck_type') || undefined}
                label={i18n.t('truckReservation:truckType')}
                validateStatus={_.get(formErrors, 'reservation_truck_type_id', null) && 'error'}
                help={_.get(formErrors, 'reservation_truck_type_id', null)}
            >
                <TruckTypeContainer />
            </Form.Item>
        )
    }

    renderOptionReservationType () {
        const { truckReservation } = this.props

        if (truckReservation.is_delivery === true) {
            return TRUCK_RESERVATION_TYPES.slice(0, 2).map((reservationType) => {
                return (
                    <Option key={reservationType.value}
                        value={reservationType.value}>
                        {reservationType.i18n}
                    </Option>
                )
            })
        }

        return TRUCK_RESERVATION_TYPES.map((reservationType) => {
            return (
                <Option key={reservationType.value}
                    value={reservationType.value}>
                    {reservationType.i18n}
                </Option>
            )
        })
    }

    renderCost () {
        const { i18n, truckReservation } = this.props

        return (
            <Form.Item label={i18n.t('truckReservation:cost')}
                name='cost'
                initialValue={_.get(truckReservation, 'cost.raw') || null}>
                    <Input prefix="฿" placeholder={i18n.t('truckReservation:cost')} />
            </Form.Item>
        )
    }

    renderTruckSelect () {
        const { i18n } = this.props

        return (
            <Form.Item label={i18n.t('truckReservation:truckOwner')}
                name='truck_owner'>
                <SupplierShippingTruckSelectContainer />
            </Form.Item>
        )
    }

    renderTruckOriginAddressSelect () {
        const { i18n, truckReservation } = this.props

        return (
            <Form.Item label={i18n.t('truckReservation:truckOriginAddresses')}
                name='truck_origin_address_ids'
                initialValue={_.get(truckReservation, 'truck_origin_addresses', [])}>
                    <TruckOriginAddressSelectContainer />
            </Form.Item>
        )
    }

    renderNote () {
        const { i18n, truckReservation } = this.props
        return (
            <Form.Item label={i18n.t('truckReservation:note')}
                name='note'
                initialValue={_.get(truckReservation, 'note', '')}>
                    <Input placeholder={i18n.t('truckReservation:note')} />
            </Form.Item>
        )
    }

    render () {
        const { i18n, saving, truckReservation } = this.props

        return (
            <div className="truck-reservation-form-component">
                <Form {...formItemLayout} onFinish={this.handleSubmitForm}
                    initialValues={{
                        truck_id: _.get(truckReservation, 'truck', ''),
                        truck_owner_id: _.get(truckReservation, 'truck_owner', '')
                    }}>
                    {this.renderDatePicker()}
                    {this.renderSelectReservationType()}
                    {this.renderSelectReservationTruckType()}
                    {this.renderTruckSelect()}
                    {this.renderTruckOriginAddressSelect()}
                    {this.renderCost()}
                    {this.renderNote()}
                    <Button type="primary" htmlType="submit" disabled={saving}>
                        {i18n.t('truckReservation:submit')}
                    </Button>
                </Form>
            </div>
        )
    }
}

export default withTranslation('truckReservation')(TruckReservationForm)
