import { all, put, call, fork, takeEvery, takeLatest } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'

import { orderReviewsSchema, customerReviewsSchema, customerReviewSchema } from '../../schema'

import actions from './actions'

const {
    CUSTOMER_REVIEWS_FETCH_REQUEST,
    CUSTOMER_REVIEWS_FETCH,
    CUSTOMER_REVIEWS_FETCH_SUCCESS,
    CUSTOMER_REVIEWS_SAVE_REQUEST,
    CUSTOMER_REVIEWS_SAVE_SUCCESS,
    CUSTOMER_REVIEWS_SAVE,
    CUSTOMER_REVIEWS_SET_FORM_ERRORS,
    fetchReviews
} = actions

export function* customerReviewsRequest() {
    yield takeEvery(CUSTOMER_REVIEWS_FETCH_REQUEST, function* ({ payload }) {
        const { id, orderId } = payload

        yield put({ type: CUSTOMER_REVIEWS_FETCH })

        const data = yield call(apiFetch, `/customers/${id}/reviews?order_id=${orderId}`)

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, customerReviewsSchema)

        yield put({
            type: CUSTOMER_REVIEWS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function* submitReview() {
    yield takeLatest(CUSTOMER_REVIEWS_SAVE_REQUEST, function* ({ payload }) {
        yield put({ type: CUSTOMER_REVIEWS_SAVE, saving: true })

        const { review, id, orderId, onSuccess } = payload
        const method = 'POST'
        const path = `/customers/${id}/reviews`
        const body = JSON.stringify({ review: review })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: CUSTOMER_REVIEWS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                yield put(fetchReviews(id, orderId))

                onSuccess()
            }
        } catch (error) {
        } finally {
            yield put({ type: CUSTOMER_REVIEWS_SAVE, saving: false })
        }
    })
}

export default function* rootSaga() {
    yield all([fork(customerReviewsRequest), fork(submitReview)])
}
