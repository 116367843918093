import styled from 'styled-components'
const OrderSummariesWrapper = styled.div`
  .filter-section {
    .filter-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .label {
        margin-right: 12px;
      }
    }
  }

  .focus-animation-none {
    &.ant-btn-default:focus {
      color: rgba(0,0,0,.65);
      border: 1px solid #d9d9d9;
    }
  }

  .justify-content-end { justify-content: end; }
  .margin-right-8 { margin-right: 8px; }
  .margin-bottom-10 { margin-bottom: 10px; }
  .margin-bottom-12 { margin-bottom: 12px; }
  .padding-4 { padding: 4px; }
`

export default OrderSummariesWrapper
