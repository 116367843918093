import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import OrderMoveCustomer from '../../OrderMoveCustomer'


const MoveCustomer = ({orderEntity}) => {
  const [showModal, setShowModal] = useState(false)

  const customerId = orderEntity.getIn(['customer', 'id'])

  return (
    <React.Fragment>
      <Button 
        style={{marginRight: 16}} 
        onClick={() => {setShowModal(true)}}
      >
        ย้ายลูกค้า
      </Button>

      <Modal
        onCancel={() => setShowModal(false)}
        visible={showModal}
        footer={null}>
          <div style={{marginTop: 32}}>
            <OrderMoveCustomer customerId={customerId} orderId={orderEntity.get('id')} />
          </div>
      </Modal>
    </React.Fragment>
  )
}

export default MoveCustomer
