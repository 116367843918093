import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import { Input, Form } from 'antd'

const TextInput = forwardRef(({
  setFileList,
  blobs,
  setBlobs,
  onUploadImages,
  form,
  attachmentFieldName,
  textAreaFieldName,
  fileList
}, ref) => {
  const inputRef = useRef()

  useImperativeHandle(ref, () => ({
    getValue: () => {
      return {
        message: inputRef.current.value,
        imageIds: blobs.map(b => b.id)
      }
    },
    setValue: ({ message = '', images = []} = {}) => {
      inputRef.current.value = message
      setBlobs(images)
    }
  }))

  const imageHandler = async (files, imgBase64) => {

    let getFileList = []
    await files.map((file, index) => {
      const newFile = {
          lastModified: file.lastModified,
          lastModifiedDate: file.lastModifiedDate,
          name: file.name,
          originFileObj: file,
          percent: 0,
          size: file.size,
          thumbUrl: imgBase64,
          type: file.type,
          uid: `rc-${new Date().getTime()}-${fileList.length + (index + 1)}`
      }

      getFileList.push(newFile)
    })

    onUploadImages(files, {
      onSuccess: () => {
        form.setFieldsValue({ [attachmentFieldName]: fileList.concat(getFileList) })
        setFileList(fileList.concat(getFileList))
      }
    })
  }

  const onDrop = (event) => {
    event.preventDefault()

    const items = event.dataTransfer.items && event.dataTransfer.items
    const files = []

    for (var i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        files.push(items[i].getAsFile())
      }
    }

    imageHandler(files)
  }

  const onPaste = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    const files = []

    for (var i = 0; i < items.length; i++) {
      if (items[i].kind === 'file') {
        files.push(items[i].getAsFile())
      }
    }

    if (files.length > 0) { event.preventDefault() }

    imageHandler(files)
  }

  return (
    <Form.Item name={textAreaFieldName}>
      <Input.TextArea rows={4} style={{ width: '100%' }}
        ref={inputRef}
        onDrop={onDrop}
        onPaste={onPaste}
      />
    </Form.Item>
  )
})

TextInput.propTypes = {
  setFileList: PropTypes.func,
  fileList: PropTypes.array,
  blobs: PropTypes.array,
  setBlobs: PropTypes.func,
  onUploadImages: PropTypes.func,
  form: PropTypes.object,
  attachmentFieldName: PropTypes.string,
  textAreaFieldName: PropTypes.string
}

TextInput.defaultProps = {}

export default TextInput
