import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Form, Modal, Select } from 'antd'
import {
    CANCEL_ITEM_REASONS,
    CANCEL_OTHERS_REASONS,
    CANCEL_PRICE_CHECKING_REASONS,
    CANCEL_PRICE_REASONS
} from '../../../constants/orders'

const { Option, OptGroup } = Select

const priceReasons = () => {
    return CANCEL_PRICE_REASONS.map((priceReason, index) => {
        return (
            <Option key={`priceReason-${index}`} value={priceReason.value}>{`${priceReason.i18n}`}</Option>
        )
    })
}

const itemReasons = () => {
    return CANCEL_ITEM_REASONS.map((itemReason, index) => {
        return (
            <Option key={`itemReason-${index}`} value={itemReason.value}>{`${itemReason.i18n}`}</Option>
        )
    })
}

const priceCheckingReasons = () => {
    return CANCEL_PRICE_CHECKING_REASONS.map((priceCheckingReason, index) => {
        return (
            <Option key={`priceCheckingReason-${index}`} value={priceCheckingReason.value}>{`${priceCheckingReason.i18n}`}</Option>
        )
    })
}

const othersReasons = () => {
    return CANCEL_OTHERS_REASONS.map((otherReason, index) => {
        return (
            <Option key={`otherReason-${index}`} value={otherReason.value}>{`${otherReason.i18n}`}</Option>
        )
    })
}

const filterOption = (input, { children, options }) => {
    if (options) {
        return options.includes(o => o.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    }

    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const CancelSelectForm = ({ onSubmitForm, toggleForm, visible }) => {
    const [form] = Form.useForm()
    const { i18n } = useTranslation('inquiries')

    const handleSubmit = (e) => {
        e.preventDefault()

        form.validateFields().then(fieldsValue => {
            onSubmitForm('cancel', fieldsValue)
        })
    }

    return (
        <Modal
            width="600px"
            title={i18n.t('inquiries:stateDropdown.cancelDetail')}
            visible={visible}
            onOk={handleSubmit}
            onCancel={toggleForm}>
            <Form form={form}>
                <Form.Item name='cancelReason'>
                    <Select style={{ width: '100%' }}
                        showSearch
                        placeholder={i18n.t('inquiries:stateDropdown.pleaseSelect')}
                        filterOption={filterOption}
                    >
                        <OptGroup label={i18n.t('inquiries:stateDropdown.price')}>
                            {priceReasons()}
                        </OptGroup>
                        <OptGroup label={i18n.t('inquiries:stateDropdown.item')}>
                            {itemReasons()}
                        </OptGroup>
                        <OptGroup label={i18n.t('inquiries:stateDropdown.priceChecking')}>
                            {priceCheckingReasons()}
                        </OptGroup>
                        <OptGroup label={i18n.t('inquiries:stateDropdown.others')}>
                            {othersReasons()}
                        </OptGroup>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

CancelSelectForm.propTypes = {
    onSubmitForm: PropTypes.func.isRequired,
    toggleForm: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

CancelSelectForm.defaultProps = {}

export default CancelSelectForm
