import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'antd'

const WalletsList = ({
    columns,
    onTableChange,
    loading,
    pagination,
    wallets
}) => {
    return (
        <Table
            columns={columns}
            dataSource={wallets}
            loading={loading}
            onChange={onTableChange}
            pagination={pagination}
            rowKey="id"
        />
    )
}

WalletsList.propTypes = {
    columns: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
    wallets: PropTypes.array
}

WalletsList.defaultProps = {
    columns: [],
    onTableChange: () => {},
    pagination: false
}

export default WalletsList
