import React from 'react'
import PropTypes from 'prop-types'

import { Select } from 'antd'
import { withTranslation } from 'react-i18next'

const { Option } = Select

const SelectCustomerCompany = ({
    i18n,
    companies,
    onChange,
    onSearch,
    value
}) => {
    const renderCustomerCompanyOptions = () => {
        return companies.map((company) => {
            return <Option key={`company-${company.id}`} value={company.id}>{company.name}</Option>
        })
    }

    return (
        <Select
            filterOption={false}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('projects:selectCompany')}
            showSearch
            value={value}
        >
            {renderCustomerCompanyOptions()}
        </Select>
    )
}

SelectCustomerCompany.propTypes = {
    companies: PropTypes.array,
    onChange: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
    value: PropTypes.string
}

SelectCustomerCompany.defaultProps = {
    companies: [],
    onChange: () => {}
}

export default withTranslation('projects')(SelectCustomerCompany)
