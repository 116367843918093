import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import CurrentStaffContainer from '../CurrentStaff/CurrentStaffContainer'
import Staff from '../../components/staffs/staff'

import actions from '../../redux/staffs/actions'

const { fetchStaff } = actions

const StaffContainer = ({
    currentStaff,
    isCurrentStaffA,
    staffId
}) => {
    const dispatch = useDispatch()
    const staffEntities = useSelector(state => state.getIn(['Entities', 'staffs']))
    const staffRoleEntities = useSelector(state => state.getIn(['Entities', 'staffRoles']))
    const loading = useSelector(state => state.getIn(['staffs', 'loading']))

    useEffect(() => {
        if (isCurrentStaffA('super_admin')) {
            dispatch(fetchStaff(staffId))
        }
    }, [currentStaff.size])

    const getStaff = () => staffEntities.get(`${staffId}`) || new Map()
    const getStaffRole = (roleId) => staffRoleEntities.get(`${roleId}`) || new Map()

    return (
        <Staff staff={getStaff()}
            getStaffRole={getStaffRole}
            loading={loading} />
    )
}

StaffContainer.propTypes = {
    staffId: PropTypes.string.isRequired
}

StaffContainer.defaultProps = {}

export default CurrentStaffContainer(StaffContainer)
