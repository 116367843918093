import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form, Input, Button, Checkbox } from 'antd'

import { withTranslation } from 'react-i18next'

class TruckOwnerForm extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        ownerType: PropTypes.string.isRequired,
        truckOwner: PropTypes.object.isRequired
    }

    static defaultProps = {}

    onSubmit = (values) => {
        const { onSubmit, ownerType } = this.props

        values['truck_owner']['owner_type'] = ownerType
        onSubmit(values)
    }

    render () {
        const { error, onCancel, ownerType, truckOwner, i18n } = this.props

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }

        const formButtonLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 }
            }
        }

        return (
            <Form
                className="truck-company-component"
                key="truck-company-component"
                onFinish={this.onSubmit}>
                <h3>{i18n.t('trucks:addTruckOwner')}</h3>

                {/* Name input */}
                <Form.Item {...formItemLayout}
                    name={['truck_owner', 'name']}
                    initialValue={_.get(truckOwner, 'name', '')}
                    label={i18n.t(`trucks:${ownerType}.name`)}
                    validateStatus={_.get(error, 'name', undefined) && 'error'}
                    help={_.get(error, 'name', []).join(', ')}>
                        <Input />
                </Form.Item>

                {ownerType === 'personal' ? (
                    // id card input
                    <Form.Item {...formItemLayout}
                        name={['truck_owner', 'id_card']}
                        initialValue={_.get(truckOwner, 'id_card', '')}
                        label={i18n.t(`trucks:${ownerType}.IdCard`)}
                        validateStatus={_.get(error, 'id_card', undefined) && 'error'}
                        help={_.get(error, 'id_card', []).join(', ')}>
                            <Input />
                    </Form.Item>
                ) : null}

                {/* phone number input */}
                <Form.Item {...formItemLayout}
                    label={i18n.t('trucks:phoneNumber')}
                    name={['truck_owner', 'phone_number']}
                    initialValue={_.get(truckOwner, 'phone_number', '')}
                    validateStatus={_.get(error, 'phone_number', undefined) && 'error'}
                    help={_.get(error, 'phone_number', []).join(', ')}>
                        <Input />
                </Form.Item>

                {/* address input */}
                <Form.Item {...formItemLayout}
                    label={i18n.t('trucks:address')}
                    name={['truck_owner', 'address']}
                    initialValue={_.get(truckOwner, 'address', '')}
                    validateStatus={_.get(error, 'address', undefined) && 'error'}
                    help={_.get(error, 'address', []).join(', ')}>
                        <Input.TextArea />
                </Form.Item>

                <Form.Item {...formItemLayout}
                    name={['truck_owner', 'is_verified']}
                    valuePropName='checked'
                    initialValue={ _.get(truckOwner, 'is_verified', false)}
                    label={'Is Verified'}>
                        <Checkbox>Is Verified</Checkbox>
                </Form.Item>

                {/* Button */}
                <Form.Item {...formButtonLayout}>
                    <span>
                        <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
                            {i18n.t('trucks:submit')}
                        </Button>
                        <Button type="button" onClick={() => { onCancel() }}>
                            {i18n.t('trucks:cancel')}
                        </Button>
                    </span>
                </Form.Item>
            </Form>
        )
    }
}

export default withTranslation(['trucks'])(TruckOwnerForm)
