import { Map, List } from 'immutable'
import actions from './actions'

const {
    CUSTOMER_TYPES_FETCH,
    CUSTOMER_TYPES_FETCH_SUCCESS,
    CUSTOMER_TYPES_SAVE_SUCCESS,
    CUSTOMER_TYPES_SET_FORM_ERRORS,
    CUSTOMER_TYPES_SET_SAVING,
    CUSTOMER_TYPES_CLEAR_FORM_ERRORS
} = actions

const initState = new Map({
    items: new List(),
    formErrors: new Map(),
    saving: false,
    loading: false
})

export default function customerTypesReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_TYPES_FETCH:
            return state.merge({ loading: true })

        case CUSTOMER_TYPES_FETCH_SUCCESS:
            return state.merge({
                items: new List().concat(result)
            })

        case CUSTOMER_TYPES_SET_SAVING:
            return state.merge({
                saving: action.saving
            })

        case CUSTOMER_TYPES_SAVE_SUCCESS:
            return state.merge({
                items: items.includes(action.data) ? items : items.push(action.data)
            })

        case CUSTOMER_TYPES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: new Map(action.formErrors)
            })

        case CUSTOMER_TYPES_CLEAR_FORM_ERRORS:
            return state.merge({
                formErrors: new Map()
            })

        default:
            return state
    }
}
