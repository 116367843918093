import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { isMobile } from 'react-device-detect'
import { Row, Col, DatePicker, Select } from 'antd'

import { withTranslation } from 'react-i18next'

import { Authorization } from '../../containers/Authorization'

import SelectBankAccount from '../../containers/SelectBankAccount'

const BankStatementsFilters = ({
    onBankAccountChange,
    onDateRangeChange,
    onBankStatementTypeChange,
    onReconciledSelectChange,
    value,
    i18n
}) => {
    const startDate = _.get(value, 'startDate', undefined)
    const endDate = _.get(value, 'endDate', undefined)

    return (
        <Row gutter={24} type="flex" justify="space-between">
            <Col xs={24} md={10}>
                <Authorization
                    allowedRoles={['super_admin', 'accounting']}
                    redirectWhenNotAllowed={false}
                >
                    {/* filter by bank account */}
                    <Row gutter={[16, 16]} type="flex" align="middle">
                        <Col xs={6} md={6} style={{ textAlign: isMobile ? 'left' : 'right' }}>
                            {i18n.t('confirms:accounting.selectBank')}
                        </Col>

                        <Col xs={18} md={18}>
                            <SelectBankAccount
                                onChange={onBankAccountChange}
                                value={_.get(value, 'bankAccountId', undefined)}
                                allBank={true}
                            />

                        </Col>
                    </Row>

                    <Authorization
                        allowedRoles={['super_admin']}
                        redirectWhenNotAllowed={false}
                    >
                        <Row gutter={[16, 16]} type="flex" align="middle">
                            <Col xs={6} md={6} style={{ textAlign: isMobile ? 'left' : 'right' }}>
                                {i18n.t('confirms:filterHasOrder.title')}
                            </Col>
                            <Col xs={6} md={6}>
                                <Select value={_.get(value, 'isReconciled', null)} style={{ width: '100%' }} onChange={onReconciledSelectChange}>
                                    <Select.Option value={null}>{i18n.t('confirms:filterByReconciled.all')}</Select.Option>
                                    <Select.Option value="true">{i18n.t('confirms:filterByReconciled.reconciled')}</Select.Option>
                                    <Select.Option value="false">{i18n.t('confirms:filterByReconciled.notReconciled')}</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    </Authorization>
                </Authorization>
            </Col>

            <Col xs={20} md={9}>
                <Authorization
                    allowedRoles={['super_admin', 'accounting']}
                    redirectWhenNotAllowed={false}
                >
                    {/* filter by date */}
                    <Row gutter={[16, 16]} type="flex" align="middle">
                        <Col xs={6} md={6} style={{ textAlign: isMobile ? 'left' : 'right' }}>
                            {i18n.t('confirms:accounting.filterByDate')}
                        </Col>

                        <Col xs={18} md={18}>
                            <DatePicker.RangePicker
                                onChange={onDateRangeChange}
                                value={[
                                    startDate ? moment(startDate) : null,
                                    endDate ? moment(endDate) : null
                                ]} />
                        </Col>
                    </Row>


                    <Authorization
                        allowedRoles={['super_admin', 'accounting']}
                        redirectWhenNotAllowed={false}
                    >
                        <Row gutter={[16, 16]} type="flex" align="middle">
                            <Col xs={6} md={6}>
                                {i18n.t('confirms:filterBankStatementType.type')}
                            </Col>

                            <Col xs={18} md={18}>
                                <Select
                                    onChange={onBankStatementTypeChange}
                                    value={_.get(value, 'bankStatementType', null)}
                                    style={{ width: '200px' }}
                                >
                                    <Select.Option value={null}>
                                        {i18n.t('confirms:filterBankStatementType.all')}
                                    </Select.Option>
                                    <Select.Option value="receipt_voucher">
                                        {i18n.t('confirms:filterBankStatementType.receiptVoucher')}
                                    </Select.Option>
                                    <Select.Option value="payment_voucher">
                                        {i18n.t('confirms:filterBankStatementType.paymentVoucher')}
                                    </Select.Option>
                                </Select>
                            </Col>
                        </Row>
                    </Authorization>
                </Authorization>
            </Col>
        </Row>
    )
}

BankStatementsFilters.propTypes = {
    onBankAccountChange: PropTypes.func,
    onHasOrderChange: PropTypes.func,
    onBankStatementTypeChange: PropTypes.func,
    onReconciledSelectChange: PropTypes.func,
    value: PropTypes.shape({
        bankAccountId: PropTypes.string
    })
}

BankStatementsFilters.defaultProps = {
    onBankAccountChange: () => {},
    onHasOrderChange: () => {},
    onReconciledSelectChange: () => {},
    value: {
        bankAccountId: undefined
    }
}

export default withTranslation(['confirms'])(BankStatementsFilters)
