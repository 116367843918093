import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'
import { categoriesSchema, subCategoriesSchema, productTypesSchema } from '../schema'

const {
    CATEGORIES_FETCH,
    CATEGORIES_FETCH_REQUEST,
    CATEGORIES_FETCH_SUCCESS
} = actions

const apiPath = (id) => {
    const basePath = '/categories'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *categoriesRequest () {
    yield takeEvery(CATEGORIES_FETCH_REQUEST, function *() {
        yield put({ type: CATEGORIES_FETCH })
        const path = apiPath()

        const data = yield call(apiFetch, path)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, categoriesSchema)

        yield put({
            type: CATEGORIES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}


export function *subCategoriesRequest () {
    yield takeEvery(actions.SUB_CATEGORIES_FETCH_REQUEST, function *({ payload }) {
        const { categoryId } = payload
        yield put({ type: actions.SUB_CATEGORIES_FETCH })

        const data = yield call(apiFetch, `/categories/${categoryId}/sub_categories`)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, subCategoriesSchema)

        yield put({
            type: actions.SUB_CATEGORIES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchProductTypesRequest () {
    yield takeEvery(actions.PRODUCT_TYPES_FETCH_REQUEST, function *({ payload }) {
        const { categoryId, subCategoryId } = payload
        yield put({ type: actions.PRODUCT_TYPES_FETCH })

        const data = yield call(apiFetch, `/categories/${categoryId}/sub_categories/${subCategoryId}/product_types`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, productTypesSchema)

        yield put({
            type: actions.PRODUCT_TYPES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(categoriesRequest),
        fork(subCategoriesRequest),
        fork(fetchProductTypesRequest)
    ])
}

