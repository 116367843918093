import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { Map, List } from 'immutable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    Button,
    Input,
    Form,
    Radio,
    Col,
    Spin,
    DatePicker,
    Upload,
    Checkbox,
    Select
} from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'

import accountingActions from '../../redux/orders/accounting/actions'
import AreasSelectContainer from '../Areas/AreasSelectContainer'

const {
    fetchOrderBusinessAddress,
    saveOrderBusinessAddress,
    setTaxInvoiceBusinessErrors
} = accountingActions

const RadioGroup = Radio.Group

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const radioStyle = {
    display: 'block',
    lineHeight: '30px'
}

class OrderBusinessAddressFormContainer extends Component {
    static defaultProps = {
        onSaveSuccess: () => {},
        onSaveError: () => {}
    }

    constructor(props) {
        super(props)

        this.state = {
            fileList: []
        }

        this.formRef = React.createRef()
    }

    componentDidMount() {
        const { orderId, addressId, fetchOrderBusinessAddress } = this.props
        if (!addressId) {
            return null
        }

        fetchOrderBusinessAddress({ orderId, id: addressId })
        this.renderFileList()
    }

    componentWillUnmount() {
        const { setTaxInvoiceBusinessErrors } = this.props
        setTaxInvoiceBusinessErrors()
    }

    handleImageChange = (fileList) => {
        this.setState(fileList)
    }

    normFile = (e) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    setImageParams(imageFiles) {
        const { orderBusinessAddressEntities, addressId } = this.props
        if (!imageFiles) {
            return
        }

        const images = orderBusinessAddressEntities
            ? orderBusinessAddressEntities.getIn(
                  [addressId, 'images'],
                  new List()
              )
            : []
        let destroyImage = []
        let uploadImage = imageFiles
        images.map((image) => {
            if (
                !imageFiles.filter((value) => value.uid === image.get('id'))
                    .length
            ) {
                destroyImage.push({ id: image.get('id'), _destroy: true })
            } else {
                uploadImage = uploadImage.filter(
                    (value) => value.uid !== image.get('id')
                )
            }
        })

        return uploadImage
            .map((id) => {
                return {
                    file: id.originFileObj,
                    _destroy: false
                }
            })
            .concat(destroyImage)
    }

    renderFileList() {
        const { imageEntities, orderBusinessAddressEntities, addressId } =
            this.props

        const invoiceImages = orderBusinessAddressEntities.getIn([
            addressId,
            'images'
        ])
        if (invoiceImages) {
            const imageThumb = invoiceImages.map((thumb) => {
                return {
                    uid: thumb.get('id'),
                    url: imageEntities.getIn([
                        thumb.getIn(['file', 's']),
                        'thumbBlob'
                    ])
                }
            })
            this.setState({ fileList: imageThumb.toJS() })
        }
    }

    saveBusinessAddress = (values) => {
        const imageParams = this.setImageParams(values.images_attributes)
        const invoiceDate = values['invoice_date']
            ? values['invoice_date'].format('YYYY-MM-DD')
            : null
        const paymentDate = values['payment_date']
            ? values['payment_date'].format('YYYY-MM-DD')
            : null
        const params = {
            ...values,
            invoice_date: invoiceDate,
            payment_date: paymentDate,
            images_attributes: imageParams
        }

        this.onSubmitForm(params)
    }

    onSubmitForm = (address) => {
        const {
            addressId,
            orderId,
            saveOrderBusinessAddress,
            onSaveSuccess,
            onSaveError
        } = this.props

        saveOrderBusinessAddress({
            address,
            id: addressId,
            orderId,
            onSuccess: () => {
                onSaveSuccess()
            },
            onError: (errors) => {
                onSaveError(errors)
            }
        })
    }

    renderInvoiceOutSide = (orderBusinessAddress, errors) => {
        const { fileList } = this.state
        return (
            <React.Fragment>
                <Form.Item
                    {...formItemLayout}
                    name='grand_total'
                    initialValue={orderBusinessAddress.get('grand_total')}
                    label='ยอดรวม'
                    hasFeedback
                    validateStatus={
                        errors && errors.get('grand_total') && 'error'
                    }
                    help={errors && errors.get('grand_total')}>
                    <Input style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item
                    label='ไฟล์แนบ'
                    {...formItemLayout}
                    name='images_attributes'
                    getValueFromEvent={this.normFile}>
                    <Upload.Dragger
                        accept='image/*'
                        listType='picture-card'
                        showUploadList={{ showPreviewIcon: false }}
                        onChange={(e) => this.handleImageChange(e)}
                        beforeUpload={() => false}
                        fileList={fileList}
                        multiple>
                        <div>
                            <PlusSquareOutlined />
                            <div className='ant-upload-text'> Upload </div>
                        </div>
                    </Upload.Dragger>
                </Form.Item>
            </React.Fragment>
        )
    }

    renderTaxInvoiceDetail = () => {
        const {
            orderBusinessAddresses,
            orderBusinessAddressEntities,
            addressId,
            i18n
        } = this.props
        const errors = orderBusinessAddresses.get(
            'orderBusinessAddressFormErrors'
        )
        const orderBusinessAddress =
            orderBusinessAddressEntities.get(addressId) || new Map()

        const invoiceType = orderBusinessAddress.get('invoice_type')
        const invoiceDate =
            orderBusinessAddress && orderBusinessAddress.get('invoice_date')
        const paymentDate =
            orderBusinessAddress && orderBusinessAddress.get('payment_date')

        return (
            <div>
                <Form.Item
                    {...formItemLayout}
                    name={'invoice_no'}
                    initialValue={orderBusinessAddress.get('invoice_no')}
                    label={i18n.t('confirms:invoice.invoiceNo')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('invoice_no') && 'error'
                    }
                    help={errors && errors.get('invoice_no')}>
                    <Input style={{ width: '50%' }} />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name='invoice_date'
                    initialValue={
                        invoiceDate ? moment(invoiceDate, 'YYYY-MM-DD') : null
                    }
                    label={i18n.t('confirms:invoice.invoiceDate')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('invoice_date') && 'error'
                    }
                    help={errors && errors.get('invoice_date')}>
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name='payment_date'
                    initialValue={
                        paymentDate ? moment(paymentDate, 'YYYY-MM-DD') : null
                    }
                    label={i18n.t('confirms:invoice.paymentDate')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('payment_date') && 'error'
                    }
                    help={errors && errors.get('payment_date')}>
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    name='payment_account'
                    initialValue={orderBusinessAddress.get('payment_account')}
                    label={i18n.t('confirms:invoice.paymentAccount')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('invoice_no') && 'error'
                    }
                    help={errors && errors.get('payment_account')}>
                    <Input style={{ width: '50%' }} />
                </Form.Item>
                {invoiceType === 'outside'
                    ? this.renderInvoiceOutSide(orderBusinessAddress, errors)
                    : null}
            </div>
        )
    }

    renderOrderBusinessAddressForm = () => {
        const {
            orderBusinessAddressEntities,
            addressId,
            orderBusinessAddresses,
            i18n
        } = this.props
        const errors = orderBusinessAddresses.get(
            'orderBusinessAddressFormErrors'
        )
        const orderBusinessAddress =
            orderBusinessAddressEntities.get(addressId) || new Map()

        const provinceValue = orderBusinessAddress.getIn(['province', 'id'])
        const districtValue = orderBusinessAddress.getIn(['district', 'id'])
        const postalCodeValue = orderBusinessAddress.get('postal_code')
        const subDistrictValue = orderBusinessAddress.get('sub_district')

        return (
            <React.Fragment>
                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:taxId')}
                    name='tax_number'
                    initialValue={orderBusinessAddress.get('tax_number') || ''}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('tax_number') && 'error'
                    }
                    help={errors && errors.get('tax_number')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:name')}
                    name='company_name'
                    initialValue={orderBusinessAddress.get('company_name')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('company_name') && 'error'
                    }
                    help={errors && errors.get('company_name')}>
                    <Input />
                </Form.Item>
                <Col offset={8} span={16}>
                    <Form.Item
                        name='business_type'
                        initialValue={orderBusinessAddress.get(
                            'business_type'
                        )}>
                        <RadioGroup>
                            <Radio style={radioStyle} value='corporate'>
                                {i18n.t('addresses:corporate')}
                            </Radio>
                            <Radio style={radioStyle} value='person'>
                                {i18n.t('addresses:person')}
                            </Radio>
                            <Radio style={radioStyle} value='foreign'>
                                {i18n.t('addresses:foreign')}
                            </Radio>
                        </RadioGroup>
                    </Form.Item>
                </Col>
                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:branch')}
                    name='branch'
                    initialValue={orderBusinessAddress.get('branch')}
                    hasFeedback
                    validateStatus={errors && errors.get('branch') && 'error'}
                    help={errors && errors.get('branch')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:address')}
                    name='address_detail'
                    initialValue={orderBusinessAddress.get('address_detail')}
                    hasFeedback
                    validateStatus={
                        errors && errors.get('address_detail') && 'error'
                    }
                    help={errors && errors.get('address_detail')}>
                    <Input />
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={'Hide Address Information'}
                    name='hide_detail_information'
                    valuePropName='checked'
                    initialValue={orderBusinessAddress.get(
                        'hide_detail_information'
                    )}>
                    <Checkbox> Hide Address Information </Checkbox>
                </Form.Item>
                <AreasSelectContainer
                    form={this.formRef}
                    districtFormItemLayout={{
                        labelCol: { md: { span: 8 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    provinceFormItemLayout={{
                        labelCol: { md: { span: 8 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    postalCodeFormItemLayout={{
                        labelCol: { md: { span: 8 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    subDistrictFormItemLayout={{
                        labelCol: { md: { span: 8 }, sm: { span: 6 } },
                        wrapperCol: { md: { span: 10 }, sm: { span: 14 } }
                    }}
                    districtCol={{ md: { span: 24 } }}
                    subDistrictCol={{ md: { span: 24 } }}
                    postalCodeCol={{ md: { span: 24 } }}
                    provinceCol={{ md: { span: 24 } }}
                    provinceFieldName={`province_id`}
                    districtFieldName={`district_id`}
                    showSubDistrict
                    postalCodeDisabled={false}
                    subDistrictFieldName={`sub_district`}
                    postalCodeFieldName={`postal_code`}
                    subDistrictHelp={errors && errors.get('sub_district')}
                    subDistrictValidateStatus={
                        errors && errors.get('sub_district') && 'error'
                    }
                    districtHelp={errors && errors.get('district')}
                    districtValidateStatus={
                        errors && errors.get('district') && 'error'
                    }
                    provinceHelp={errors && errors.get('province')}
                    provinceValidateStatus={
                        errors && errors.get('province') && 'error'
                    }
                    postalCodeHelp={errors && errors.get('postal_code')}
                    postalCodeValidateStatus={
                        errors && errors.get('postal_code') && 'error'
                    }
                    provinceValue={provinceValue}
                    districtValue={districtValue}
                    subDistrictValue={subDistrictValue}
                    postalCodeValue={postalCodeValue}
                />

                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:mailingAddressType')}
                    name='mailing_address_type'
                    initialValue={orderBusinessAddress.get(
                        'mailing_address_type'
                    )}>
                    <Select
                        placeholder={i18n.t('addresses:mailingAddressType')}>
                        <Select.Option value='same_address'>
                            {i18n.t(
                                'addresses:mailingAddressTypes.sameAddress'
                            )}
                        </Select.Option>
                        <Select.Option value='other_address'>
                            {i18n.t(
                                'addresses:mailingAddressTypes.otherAddress'
                            )}
                        </Select.Option>
                        <Select.Option value='no_address'>
                            {i18n.t('addresses:mailingAddressTypes.noAddress')}
                        </Select.Option>
                    </Select>
                </Form.Item>
                {orderBusinessAddress.get('current_re_invoice') ? (
                    <Form.Item
                        {...formItemLayout}
                        label={i18n.t('confirms:invoice.note')}
                        initialValue={orderBusinessAddress.get(
                            'current_re_invoice_note'
                        )}
                        name={['current_re_invoice_note']}>
                        <Input.TextArea />
                    </Form.Item>
                ) : null}
                <Form.Item
                    {...formItemLayout}
                    label={i18n.t('addresses:remark')}
                    name='remark'
                    initialValue={orderBusinessAddress.get('remark')}
                    hasFeedback
                    validateStatus={errors && errors.get('remark') && 'error'}
                    help={errors && errors.get('remark')}>
                    <Input />
                </Form.Item>
            </React.Fragment>
        )
    }

    render() {
        const {
            orderBusinessAddresses,
            orderBusinessAddressEntities,
            addressId,
            i18n
        } = this.props

        const orderBusinessAddress =
            orderBusinessAddressEntities.get(addressId) || new Map()
        const saving = orderBusinessAddresses.get('saving')
        const loading = orderBusinessAddresses.get('loading')
        if (loading) {
            return <Spin />
        }

        return (
            <Form onFinish={this.saveBusinessAddress} ref={this.formRef}>
                <h2>{i18n.t('addresses:businessAddress')}</h2>
                {this.renderTaxInvoiceDetail()}
                {orderBusinessAddress.get('invoice_type') === 'full_tax_invoice'
                    ? this.renderOrderBusinessAddressForm()
                    : null}
                <div style={{ textAlign: 'center' }}>
                    <Button
                        type='primary'
                        htmlType='submit'
                        size='large'
                        loading={saving}>
                        {i18n.t('addresses:addAddress')}
                    </Button>
                </div>
            </Form>
        )
    }
}

const mapStateToProps = (state) => ({
    orderBusinessAddresses: state.getIn(['orderPaymentDetails']),
    orderBusinessAddressEntities: state.getIn([
        'Entities',
        'orderBusinessAddresses'
    ]),
    imageEntities: state.getIn(['Entities', 'images'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchOrderBusinessAddress,
            saveOrderBusinessAddress,
            setTaxInvoiceBusinessErrors
        },
        dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withTranslation(['addresses', 'common', 'confirms'])(
        OrderBusinessAddressFormContainer
    )
)
