import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select, Spin } from 'antd'

const ITEM_STATES_QUERY = gql`
  query ItemStates {
    itemStates {
      key
      label
    }
  }
`

const SelectItemState = ({
  onChange,
  defaultValue,
  value,
  placeholder,
  ...restSelectProps
}) => {
  const [itemState, setItemState] = useState(defaultValue)
  const { loading, data } = useQuery(ITEM_STATES_QUERY)

  const setSelectedItemState = value => {
    setItemState(value)
    onChange(value)
  }

  if (loading) return <Spin />

  const itemStates = data && data.itemStates ? data.itemStates : []

  return (
    <Select
        defaultActiveFirstOption={false}
        defaultValue={defaultValue}
        filterOption={false}
        loading={loading}
        onChange={setSelectedItemState}
        placeholder={placeholder}
        style={restSelectProps.style || { width: '100%' }}
        value={itemState}
        {...restSelectProps}
      >
        {itemStates.map((itemState, index) => {
            return (
                <Select.Option
                    key={`index-${index}`}
                    value={itemState.key}
                >
                    {itemState.label || ''}
                </Select.Option>
            )
        })}
      </Select>
  )
}

SelectItemState.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectItemState.defaultProps = {
  onChange: () => {},
  defaultValue: null
}

export default SelectItemState
