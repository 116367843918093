import styled from 'styled-components'

export default styled.div`
  width: 100%;
  background-color: #fff;
  padding: 8px;
  margin-bottom: 16px;
  .no-price {
    background-color: #f5222d;
    color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
  }

  .text-align-right {
    text-align: right;
  }

  .card-divider {
    margin: 8px 0;
  }

  .space-align-block {
    display: flex;
    flex: 0 0 100%;
  }
  .ml-auto {
    margin-left: auto;
  }
`
