import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import _ from 'lodash'

import SelectSupplierOrder from '../../components/selectSupplierOrder'

import actions from '../../redux/suppliers/actions'

const { fetchSupplierOrdersBySupplier } = actions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectSupplierOrderBySupplierContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        supplierId: PropTypes.string.isRequired,
        value: PropTypes.string,

        // Inner props
        fetchSupplierOrdersBySupplier: PropTypes.func.isRequired,
        supplierOrderEntities: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: undefined
    }
    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { fetchSupplierOrdersBySupplier, supplierId } = this.props

        if (!supplierId) return null
        fetchSupplierOrdersBySupplier({ supplierId })
    }

    componentDidUpdate (prevProps) {
        const { supplierId: prevSupplierId } = prevProps
        const { supplierId: currSupplierId, fetchSupplierOrdersBySupplier } = this.props

        if (_.isEqual(prevSupplierId, currSupplierId)) return null

        fetchSupplierOrdersBySupplier({ supplierId: currSupplierId })
    }

    getSupplierOrders () {
        const { suppliers, supplierOrderEntities } = this.props
        return suppliers.get('supplierOrders', new List()).map((supplierOrderId) => {
            return supplierOrderEntities.get(_.toString(supplierOrderId))
        })
    }

    onChange = (supplierOrderId) => {
        const { onChange } = this.props

        onChange(supplierOrderId)
        this.setState({ value: supplierOrderId })
    }

    onSearch = (query) => {
        const { fetchSupplierOrdersBySupplier, supplierId } = this.props

        fetchSupplierOrdersBySupplier({ supplierId, query })
    }

    render () {
        const { suppliers, value: propValue, ...restProps } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectSupplierOrder
                value={value}
                loading={suppliers.get('supplierOrdersLoading')}
                onChange={this.onChange}
                onSearch={this.onSearch}
                supplierOrders={this.getSupplierOrders().toJS()}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        supplierOrderEntities: state.getIn(['Entities', 'supplierOrders']),
        suppliers: state.get('suppliers')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplierOrdersBySupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSupplierOrderBySupplierContainer)
