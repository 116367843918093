import { Map, List } from 'immutable'
import actions from './actions'
import { mergeDeepReplaceArrays } from '../entities/reducers'

const {
    TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL,
    TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS,
    TRUCK_ORIGIN_ADDRESSES_FETCHING,
    TRUCK_ORIGIN_ADDRESS_FETCH_FAIL,
    TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS,
    TRUCK_ORIGIN_ADDRESS_FETCH,
    INIT_TRUCK_ORIGIN_ADDRESSES
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    query: ''
})

export default function truckOriginAddressReducers (state = initState, action) {
    const result = action.result
    const items = state.get('items')

    switch (action.type) {
        case TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL:
            return state.merge({ loading: false })
        case TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                items: result,
                loading: false,
                query: action.query
            })
        case TRUCK_ORIGIN_ADDRESSES_FETCHING:
            return state.merge({ loading: true })
        case TRUCK_ORIGIN_ADDRESS_FETCH_FAIL:
            return state.merge({ loading: false })
        case TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                items: items.includes(result) ? items : items.concat(result),
                loading: false
            })
        case TRUCK_ORIGIN_ADDRESS_FETCH:
            return state.merge({ loading: true })
        case INIT_TRUCK_ORIGIN_ADDRESSES:
            return state.merge({
                items: new List(action.ids)
            })
        default:
            return state
    }
}
