import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Avatar, Divider, Modal, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'

import ProfitSharingFormContainer from '../../containers/Customers/ProfitSharing/ProfitSharingFormContainer'
import ProjectWrapper from './project.style'

const ProjectProfitSharingList = ({
    i18n,
    activeProfitSharing,
    loading,
    onPageChange,
    pagination,
    profitSharings,
    showProfitSharingForm,
    toggleProfitSharingForm
}) => {
    const renderImages = (images) => {
        return images.map((image) => {
            return (
                <a key={`image_${image.id}`} className="image-thumbnail" href={image.url} target="_blank">
                    <Avatar shape="square" size="large" src={image.thumb_url} />
                </a>
            )
        })
    }

    const columns = [
        {
            key: 'idCard',
            title: i18n.t('profitSharing:idCard'),
            dataIndex: 'id_card_images',
            render: (images) => (
                <div className="images-container">
                    {renderImages(images)}
                </div>
            )
        },
        {
            key: 'bookBank',
            title: i18n.t('profitSharing:bookBank'),
            dataIndex: 'book_bank_images',
            render: (images, record) => (
                <div className="images-container">
                    {renderImages(images)}
                </div>
            )
        },
        {
            key: 'detail',
            title: i18n.t('profitSharing:detail'),
            dataIndex: 'detail'
        },
        {
            key: 'actions',
            render: (record) => (
                <a onClick={() => toggleProfitSharingForm({ id: record.id })}>
                    <EditOutlined />
                </a>
            )
        }
    ]

    const renderProfitSharingModal = () => {
        if (!activeProfitSharing) { return null }
        const profitSharing = _.find(profitSharings, ['id', activeProfitSharing])

        return (
            <Modal
                visible={showProfitSharingForm}
                onCancel={toggleProfitSharingForm}
                footer={null}
                destroyOnClose>
                <ProfitSharingFormContainer
                    customerId={_.toString(_.get(profitSharing, 'customer_id'))}
                    id={activeProfitSharing}
                    profitSharing={profitSharing}
                    toggleForm={toggleProfitSharingForm}
                />
            </Modal>
        )
    }

    return (
        <ProjectWrapper>
            <Divider />

            <Table
                columns={columns}
                dataSource={profitSharings}
                loading={loading}
                onChange={onPageChange}
                pagination={pagination}
                rowKey="id"
            />

            {renderProfitSharingModal()}
        </ProjectWrapper>
    )
}

ProjectProfitSharingList.propTypes = {
    activeProfitSharing: PropTypes.string,
    loading: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    profitSharings: PropTypes.array,
    showProfitSharingForm: PropTypes.bool,
    toggleProfitSharingForm: PropTypes.func.isRequired
}

ProjectProfitSharingList.defaultProps = {
    loading: false,
    pagination: false,
    profitSharings: [],
    showProfitSharingForm: false
}

export default withTranslation('profitSharing')(ProjectProfitSharingList)
