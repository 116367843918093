export function initializePushNotification (
    firebase,
    updateFcmToken,
    showNotification,
    warningForOpenNotification,
    receiveNotification
) {
    try {
        const messaging = firebase.messaging()

        window.addEventListener('load', () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/service-worker.js')
                    .then((registration) => {
                        messaging.useServiceWorker(registration)
                    })
                    .then(() => {
                        return messaging.requestPermission()
                    })
                    .then(() => {
                        return messaging.getToken()
                    })
                    .then((token) => {
                        updateFcmToken(token)
                    })
                    .catch((error) => {
                        console.log('Error Occurred: ', error)
                        warningForOpenNotification()
                        updateFcmToken(null)
                    })
            } else {
                console.log('Service workers are not supported.')
            }
        })

        // TODO: not working
        messaging.onMessage((payload) => {
          alert('on-message')
            const notification = {
                data: {
                    ...payload.data,
                    attributes: { ...JSON.parse(payload.data.attributes) }
                }
            }

            showNotification(notification)
            receiveNotification(notification)
        })
    } catch (error) {
        console.log('error: ', error)
    }
}
