import { all } from 'redux-saga/effects'

import authSagas from './auth/sagas'
import bankStatementSagas from './bankStatements/sagas'
import brandSagas from './brands/sagas'
import businessAddressSagas from './customers/addresses/businesses/sagas'
import categorySagas from './categories/sagas'
import chartOfAccountSagas from './chartOfAccounts/sagas'
import companySagas from './customers/companies/sagas'
import customerCompanyProjectSagas from './customers/companyProjects/sagas'
import customerNoteSagas from './customers/customerNotes/sagas'
import customerOrderPaymentDetailSagas from './customers/orderPaymentDetails/sagas'
import customerRolesSaga from './customers/roles/sagas'
import customerSagas from './customers/saga'
import customerTypesSagas from './customers/customerTypes/sagas'
import customerWalletsSagas from './customers/wallets/sagas'
import customerLabelsSagas from './customers/customerLabels/sagas'
import districtSagas from './districts/sagas'
import imageSagas from './images/sagas'
import inquirySagas from './inquiry/sagas'
import inquiryTransferBackSagas from './inquiry/transferBacks/sagas'
import itemTruckImagesSagas from './items/truckImages/sagas'
import journalEntryFormatSagas from './journalEntryFormats/sagas'
import journalEntryFormatItemSagas from './journalEntryFormats/items/sagas'
import journalEntrySagas from './journalEntries/sagas'
import mailingAddressSagas from './customers/addresses/mailing/sagas'
import notificationsSagas from './notifications/sagas'
import occupationSagas from './customers/occupations/sagas'
import orderActivityLogSagas from './orders/activityLogs/sagas'
import orderBusinessAddressSagas from './orders/businessAddresses/sagas'
import orderDocumentsSagas from './orders/documents/sagas'
import orderHistoryDashboardSagas from './orders/historyDashboards/sagas'
import orderNoteImageSagas from './orders/noteImages/sagas'
import orderPaymentDetailInstallmentSagas from './orderPaymentDetails/Installments/sagas'
import orderPaymentDetails from './orderPaymentDetails/sagas'
import orderPaymentDetailSagas from './orders/accounting/sagas'
import orderReviewSagas from './orders/reviews/sagas'
import orderSagas from './orders/sagas'
import orderShippingAddressSagas from './orders/shippingAddresses/sagas'
import orderStateSagas from './inquiry/orderStates/sagas'
import orderSummaryDashBoardSagas from './orders/summaryDashBoards/sagas'
import orderTrackingSagas from './orders/trackings/sagas'
import orderYieldsSagas from './orders/yields/sagas'
import paymentVoucherSagas from './paymentVouchers/sagas'
import potentialLeadSagas from './potentialLeads/sagas'
import productGroupSagas from './productGroups/sagas'
import productGroupItemSagas from './productGroupItems/sagas'
import profitSharingSagas from './customers/profitSharings/sagas'
import projectContactSagas from './projects/contacts/sagas'
import projectCustomerSagas from './projects/customers/sagas'
import projectProfitSharingSagas from './projects/profitSharings/sagas'
import projectSagas from './projects/sagas'
import provinceSagas from './provinces/sagas'
import purchaseOrderSagas from './purchaseOrders/sagas'
import requestItemINdicesSagas from './requestItemIndices/sagas'
import reservationTruckTypeSagas from './reservationTruckTypes/sagas'
import saleSagas from './sales/sagas'
import salesSummarySagas from './salesSummary/sagas'
import scbPaymentConfirmSagas from './scbPaymentConfirms/sagas'
import shippingAddressSagas from './customers/addresses/shippingAddresses/sagas'
import shippingRateSagas from './shippingRates/sagas'
import staffProfileSagas from './staffs/profiles/sagas'
import staffsSagas from './staffs/sagas'
import supplierAccountingGroupSagas from './suppliers/accountingGroups/sagas'
import supplierInvoiceSagas from './supplierInvoices/sagas'
import supplierOrderReviewSagas from './supplierOrderReviews/sagas'
import supplierOrderStatesSagas from './supplierOrders/sagas'
import supplierOverTransferItemSagas from './suppliers/overTransferItems/sagas'
import suppliersSagas from './suppliers/sagas'
import truckImageSagas from './customers/truckImages/sagas'
import truckOriginAddressSagas from './truckOriginAddresses/sagas'
import truckReservationSagas from './truckReservations/sagas'
import truckSagas from './trucks/sagas'
import vatDocumentSagas from './vatDocuments/sagas'
import walletSummarySagas from './walletSummaries/sagas'
import withholdingTaxSagas from './withholdingTaxes/sagas'
import reviewSagas from './inquiry/reviews/sagas'

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        bankStatementSagas(),
        brandSagas(),
        businessAddressSagas(),
        categorySagas(),
        chartOfAccountSagas(),
        companySagas(),
        customerCompanyProjectSagas(),
        customerNoteSagas(),
        customerOrderPaymentDetailSagas(),
        customerRolesSaga(),
        customerSagas(),
        customerTypesSagas(),
        customerWalletsSagas(),
        customerLabelsSagas(),
        districtSagas(),
        imageSagas(),
        inquirySagas(),
        inquiryTransferBackSagas(),
        itemTruckImagesSagas(),
        journalEntryFormatSagas(),
        journalEntryFormatItemSagas(),
        journalEntrySagas(),
        mailingAddressSagas(),
        notificationsSagas(),
        occupationSagas(),
        orderActivityLogSagas(),
        orderBusinessAddressSagas(),
        orderDocumentsSagas(),
        orderHistoryDashboardSagas(),
        orderNoteImageSagas(),
        orderPaymentDetailInstallmentSagas(),
        orderPaymentDetails(),
        orderPaymentDetailSagas(),
        orderReviewSagas(),
        orderSagas(),
        orderShippingAddressSagas(),
        orderStateSagas(),
        orderSummaryDashBoardSagas(),
        orderTrackingSagas(),
        orderYieldsSagas(),
        paymentVoucherSagas(),
        potentialLeadSagas(),
        productGroupItemSagas(),
        productGroupSagas(),
        profitSharingSagas(),
        projectContactSagas(),
        projectCustomerSagas(),
        projectProfitSharingSagas(),
        projectSagas(),
        provinceSagas(),
        purchaseOrderSagas(),
        requestItemINdicesSagas(),
        reservationTruckTypeSagas(),
        saleSagas(),
        salesSummarySagas(),
        scbPaymentConfirmSagas(),
        shippingAddressSagas(),
        shippingRateSagas(),
        staffProfileSagas(),
        staffsSagas(),
        supplierAccountingGroupSagas(),
        supplierInvoiceSagas(),
        supplierOrderReviewSagas(),
        supplierOrderStatesSagas(),
        supplierOverTransferItemSagas(),
        suppliersSagas(),
        truckImageSagas(),
        truckOriginAddressSagas(),
        truckReservationSagas(),
        truckSagas(),
        vatDocumentSagas(),
        walletSummarySagas(),
        withholdingTaxSagas(),
        reviewSagas()
    ])
}
