import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import { customersSchema, customerWalletsSchema } from '../schema'
import actions from './actions'

const {
    WALLET_SUMMARIES_CUSTOMERS_FETCH_REQUEST,
    WALLET_SUMMARIES_CUSTOMERS_FETCH,
    WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS,

    WALLET_SUMMARIES_WALLETS_FETCH_REQUEST,
    WALLET_SUMMARIES_WALLETS_FETCH,
    WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS,

    WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION,
    WALLET_SUMMARIES_SET_CUSTOMER_QUERY,
    WALLET_SUMMARIES_SET_WALLET_PAGINATION,
    WALLET_SUMMARIES_SET_WALLET_QUERY,
    WALLET_SUMMARIES_SET_WALLET_IS_APPROVED
} = actions

export function *fetchCustomers () {
    yield takeEvery(WALLET_SUMMARIES_CUSTOMERS_FETCH_REQUEST, function *({ payload }) {
        const { page, per, query } = payload

        yield put({ type: WALLET_SUMMARIES_CUSTOMERS_FETCH })
        yield put({ type: WALLET_SUMMARIES_SET_CUSTOMER_QUERY, query })

        const queryString = `query=${query}&page=${page}&per=${per}`
        const data = yield call(apiFetch, `/customer_wallet_summaries?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customersSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: WALLET_SUMMARIES_SET_CUSTOMER_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: WALLET_SUMMARIES_CUSTOMERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *fetchWallets () {
    yield takeEvery(WALLET_SUMMARIES_WALLETS_FETCH_REQUEST, function *({ payload }) {
        const { page, per, query, isApproved } = payload

        yield put({ type: WALLET_SUMMARIES_WALLETS_FETCH })
        yield put({ type: WALLET_SUMMARIES_SET_WALLET_QUERY, query })
        yield put({ type: WALLET_SUMMARIES_SET_WALLET_IS_APPROVED, isApproved })

        const queryString = `query=${query}&is_approved=${isApproved}&page=${page}&per=${per}`
        const data = yield call(apiFetch, `/wallet_summaries?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: WALLET_SUMMARIES_SET_WALLET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: WALLET_SUMMARIES_WALLETS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCustomers),
        fork(fetchWallets)
    ])
}
