import React from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'

import OrderYieldListFilters from '../../containers/OrderYields/Filters'
import OrderYieldListTable from '../../containers/OrderYields/Table'

const OrderYieldList = ({
    fetchOrderYields
}) => {
    return (
        <React.Fragment>
            <OrderYieldListFilters
                fetchOrderYields={fetchOrderYields}
                key="filters"
            />

            <Divider type="horizontal" key="divider" />

            <OrderYieldListTable
                fetchOrderYields={fetchOrderYields}
                key="table"
            />
        </React.Fragment>
    )
}

OrderYieldList.propTypes = {
    fetchOrderYields: PropTypes.func
}

OrderYieldList.defaultProps = {
    fetchOrderYields: () => {}
}

export default OrderYieldList
