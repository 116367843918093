import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch, apiFileFetch } from '../../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'
import actionsInquiry from '../../inquiry/actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'

import {
  orderStatesSchema,
  orderStateSchema,
  // paymentDetailSchema
} from '../../schema'
// import { i18n } from '../../../i18n'

const { setNotifications } = actionsNotification
const { fetchInquiry } = actionsInquiry

const {
    ORDERS_STATES_FETCH_REQUEST,
    ORDER_STATES_FETCH,
    ORDER_STATES_FETCH_SUCCESS,
    // FETCH_QUOTATION_PDF_REQUEST,
    // FETCHING_QUOTATION_PDF,
    // FETCH_QUOTATION_PDF_SUCCESS,
    SAVE_ORDERS_STATES_REQUEST,
    SAVE_ORDERS_STATES,
    SAVE_ORDERS_STATE_SUCCESS,
    // ORDERS_STATES_PAYMENT_DETAIL_FETCH_REQUEST,
    // ORDERS_STATES_PAYMENT_DETAIL_FETCH,
    // ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS,
    // SAVE_PAYMENT_DETAIL_REQUEST,
    // SAVE_PAYMENT_DETAIL,
    // SAVE_PAYMENT_DETAIL_SUCCESS,
    SAVE_RECOVER_ORDERS_REQUEST,
    SAVE_RECOVER_ORDERS,
    SAVE_RECOVER_ORDERS_SUCCESS

} = actions

export function *receiveOrderStates () {
    yield takeEvery(ORDERS_STATES_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_STATES_FETCH })

        const { allState } = payload
        const path = `/orders/states?all_state=${allState}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderStatesSchema)

        yield put({
            type: ORDER_STATES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitOrderState () {
    yield takeLatest(SAVE_ORDERS_STATES_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_ORDERS_STATES })


        const { orderId, newState, values, onError } = payload

        const path = `/orders/${orderId}/states/${newState}`
        const method = 'PATCH'

        const body = JSON.stringify(values)

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)

            yield put({
                type: SAVE_ORDERS_STATE_SUCCESS,
                ...normalize(formattedData, orderStateSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))

            yield put(fetchInquiry(orderId))
        } catch (error) {
            const errorName = error.name
            onError(errorName)
            // yield put(setNotifications(`${errorName}`, `${i18n.t('orders:updateStateError')}`, 'error'))
        }
    })
}

// export function *orderStateQuotation () {
//     yield takeEvery(FETCH_QUOTATION_PDF_REQUEST, function *({ payload }) {
//         const { orderId } = payload

//         yield put({ type: FETCHING_QUOTATION_PDF })

//         const data = yield call(apiFileFetch, `/orders/${orderId}/quotation`)
//         if (data.type === 'application/json') {
//             yield put({ type: FETCH_QUOTATION_PDF_SUCCESS })
//             yield put(setNotifications(`fail`, `${i18n.t('orders:updateStateError')}`, 'error'))
//         } else {
//             const fileURL = URL.createObjectURL(data)
//             window.open(fileURL)

//             yield put({ type: FETCH_QUOTATION_PDF_SUCCESS })

//             if (!payload.router) {
//                 return null
//             } setTimeout(() => payload.router.replace(`/inquiries/${orderId}`), 500)
//         }
//     })
// }

export function *recoverOrderState () {
    yield takeLatest(SAVE_RECOVER_ORDERS_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_RECOVER_ORDERS })

        const orderId = payload.orderId

        const path = `/orders/${orderId}/states/requesting_quotation`
        const method = 'PATCH'


        const data = yield call(apiFetch, path, method)
        const formattedData = deserialize(data)

        yield put({
            type: SAVE_RECOVER_ORDERS_SUCCESS,
            ...normalize(formattedData, orderStateSchema)
        })
    })
}

// export function *fetchPaymentDetail () {
//     yield takeEvery(ORDERS_STATES_PAYMENT_DETAIL_FETCH_REQUEST, function *({ payload }) {
//         yield put({ type: ORDERS_STATES_PAYMENT_DETAIL_FETCH })

//         try {
//             const orderId = payload.orderId
//             const path = `/orders/${orderId}/payment_detail`
//             const data = yield call(apiFetch, path)
//             const formattedData = deserialize(data)
//             const normalizeData = normalize(formattedData, paymentDetailSchema)

//             yield put({
//                 type: ORDER_STATES_PAYMENT_DETAIL_FETCH_SUCCESS,
//                 ...normalizeData
//             })
//         } catch {
//             return null
//         }
//     })
// }

// export function *editPaymentDetail () {
//     yield takeLatest(SAVE_PAYMENT_DETAIL_REQUEST, function *({ payload }) {
//         yield put({ type: SAVE_PAYMENT_DETAIL })

//         const orderId = payload.orderId
//         const path = `/orders/${orderId}/payment_detail`
//         const method = 'PATCH'

//         const body = JSON.stringify(payload.values)

//         const data = yield call(apiFetch, path, method, { body })
//         const formattedData = deserialize(data)
//         yield put({
//             type: SAVE_PAYMENT_DETAIL_SUCCESS,
//             ...normalize(formattedData, paymentDetailSchema)
//         })

//         yield put(setNotifications('success', 'saveSuccess', 'success'))
//     })
// }

export default function *rootSaga () {
    yield all([
        fork(receiveOrderStates),
        fork(submitOrderState),
        // fork(fetchPaymentDetail),
        // fork(editPaymentDetail),
        fork(recoverOrderState),
        // fork(orderStateQuotation)
    ])
}
