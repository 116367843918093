import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch, apiFileFetch, apiPostForm } from '../../helpers/restRequest'
import downLoadFile from "../../helpers/downLoadFile"
import _ from 'lodash'

import actions from './actions'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { supplierInvoicesSchema } from '../schema'

const {
    SUPPLIER_INVOICES_REQUEST,
    SUPPLIER_INVOICES_REQUESTING,
    SUPPLIER_INVOICES_SET_PAGINATION,
    SUPPLIER_INVOICES_SET_SUMMARY_DATA,
    SUPPLIER_INVOICES_REQUEST_SUCCESS,
    SUPPLIER_INVOICES_IMPORT_REQUEST,
    SUPPLIER_INVOICES_IMPORTING,
    SUPPLIER_INVOICES_EXPORTING,
    SUPPLIER_INVOICES_IMPORT_SUCCESS,
    SUPPLIER_INVOICES_IMPORT_FAIL,
    SUPPLIER_INVOICES_EXPORT_REQUEST,
    SUPPLIER_INVOICES_EXPORT_FAIL,
    SUPPLIER_INVOICES_EXPORT_SUCCESS,
    SUPPLIER_INVOICES_VAT_EXPORTING,
    SUPPLIER_INVOICES_VAT_EXPORT_REQUEST,
    SUPPLIER_INVOICES_VAT_EXPORT_FAIL,
    SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS
} = actions

export function *receiveSupplierInvoices () {
    yield takeEvery(SUPPLIER_INVOICES_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_INVOICES_REQUESTING })

        const { page, per, filters } = payload

        let queryArray = [`page=${page}`, `per=${per}`]

        _.each(filters, (value, key) => {
            queryArray.push(`${_.snakeCase(key)}=${value || ''}`)
        })

        const data = yield call(apiFetch, `/suppliers/invoices?${queryArray.join('&')}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, supplierInvoicesSchema)
        const total = data.meta.total_pages * per
        const { sum_of_reconciled_amounts, payment_vouchers, supplier_invoices } = data.meta

        yield put({
            type:  SUPPLIER_INVOICES_SET_PAGINATION,
            pagination: { current: page, total, pageSize: per }
        })

        yield put({
            type:  SUPPLIER_INVOICES_SET_SUMMARY_DATA,
            summaryData: { sum_of_reconciled_amounts, payment_vouchers, supplier_invoices }
        })

        yield put({
            type: SUPPLIER_INVOICES_REQUEST_SUCCESS,
            ...normalizeData
        })
    })
}

export function *importSupplierInvoicesSaga () {
    yield takeLatest(SUPPLIER_INVOICES_IMPORT_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_INVOICES_IMPORTING })
        const { params, onSuccess, onError } = payload

        try {
            const path = '/suppliers/invoices/imports'
            const data = yield call(apiPostForm, path, 'POST', params)

            if (_.get(data, 'status') !== 'ok') throw _.get(data, 'status', 'error')

            yield put({ type: SUPPLIER_INVOICES_IMPORT_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_INVOICES_IMPORT_FAIL })
            onError()
        }
    })
}

export function *exportSupplierInvoicesSaga () {
    yield takeLatest(SUPPLIER_INVOICES_EXPORT_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_INVOICES_EXPORTING })
        const { params, onSuccess, onError } = payload

        const { start_date, end_date, monthly, supplier_id, have_pv } = params

        try {
            let path = `/suppliers/invoices/exports`
            if (start_date) {
                path += `?start_date=${start_date}&end_date=${end_date}`
            } else if (monthly) {
                path += `?apply_date=${monthly}`
            }

            if (supplier_id) {
                path += `&supplier_id=${supplier_id}`
            }

            if (have_pv !== undefined) {
                path += `&have_pv=${have_pv}`
            }

            yield call(apiFileFetch, path)
            yield put({ type: SUPPLIER_INVOICES_EXPORT_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_INVOICES_EXPORT_FAIL })
            onError()
        }
    })
}

export function *exportSupplierInvoiceVatsSaga () {
    yield takeLatest(SUPPLIER_INVOICES_VAT_EXPORT_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_INVOICES_VAT_EXPORTING })
        const { params, onSuccess, onError } = payload

        const { monthly } = params

        try {
            const path = `/suppliers/invoices/vat_exports?apply_date=${monthly}`

            yield call(apiFileFetch, path)
            yield put({ type: SUPPLIER_INVOICES_VAT_EXPORT_SUCCESS })
            onSuccess()
        } catch (error) {
            yield put({ type: SUPPLIER_INVOICES_VAT_EXPORT_FAIL })
            onError()
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(receiveSupplierInvoices),
        fork(importSupplierInvoicesSaga),
        fork(exportSupplierInvoicesSaga),
        fork(exportSupplierInvoiceVatsSaga)
    ])
}
