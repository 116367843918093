import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import D3TimelineChart from '../../components/d3TimelineChart'
import HistoryDashboard from '../../components/inquiry/historyDashboard'
import { dotColors } from '../../components/inquiry/historyDashboard.style'

import orderHistoryDashboardActions from '../../redux/orders/historyDashboards/actions'

const { fetchOrderHistoryDashboards, setRefetch } = orderHistoryDashboardActions

class HistoryDashboardContainer extends Component {
    static propTypes = {
        fetchOrderHistoryDashboards: PropTypes.func.isRequired,
        setRefetch: PropTypes.func.isRequired,

        orderHistoryDashboards: ImmutablePropTypes.map.isRequired,
        orderChangeActivityEntities: ImmutablePropTypes.map.isRequired,
        orderStateChangeActivityEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderChangeActivityEntities: ImmutablePropTypes.map.isRequired,
        supplierOrderStateChangeActivityEntities: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const { fetchOrderHistoryDashboards, orderId } = this.props

        if (!orderId) return null

        fetchOrderHistoryDashboards({ orderId })
    }

    componentDidUpdate () {
        const { setRefetch, orderId, fetchOrderHistoryDashboards, orderHistoryDashboards } = this.props

        if (!orderHistoryDashboards.get('refetch')) return null
        if (!orderId) return null

        setRefetch(false)
        fetchOrderHistoryDashboards({ orderId })
    }

    getData () {
        const {
            orderChangeActivityEntities,
            orderStateChangeActivityEntities,
            supplierOrderChangeActivityEntities,
            supplierOrderStateChangeActivityEntities,
            orderId
        } = this.props

        const orderChange = orderChangeActivityEntities.get(orderId)
        let result = []

        if (!orderChange) return result

        result.push({
            label: orderChange.get('number', ''),
            data: orderChange.get('order_state_change_activities').map((id, i) => {
                const orderStateChange = orderStateChangeActivityEntities.get(id.toString())

                return {
                    type: D3TimelineChart.TYPE.POINT,
                    at: new Date(orderStateChange.get('created_at', '')),
                    createdAt: orderStateChange.get('created_at', ''),
                    stateLabel: orderStateChange.getIn(['state', 'label'], ''),
                    customClass: Object.keys(dotColors)[0]
                }
            }).toArray()
        })

        result = result.concat(
            orderChange.get('supplier_order_change_activities').map((id, i) => {
                const supplierOrderChange = supplierOrderChangeActivityEntities.get(id.toString())

                return {
                    label: supplierOrderChange.get('ref_code', ''),
                    secondLineLabel: supplierOrderChange.get('supplier_name', ''),
                    data: supplierOrderChange.get('supplier_order_state_change_activities').map((stateId) => {
                        const supplierOrderStateChange = supplierOrderStateChangeActivityEntities.get(stateId.toString())

                        return {
                            type: D3TimelineChart.TYPE.POINT,
                            at: new Date(supplierOrderStateChange.get('created_at', '')),
                            createdAt: supplierOrderStateChange.get('created_at', ''),
                            stateLabel: supplierOrderStateChange.getIn(['state', 'label'], ''),
                            customClass: Object.keys(dotColors)[
                                (i + 1) % Object.keys(dotColors).length
                            ]
                        }
                    }).toArray()
                }
            }).toArray()
        )

        return result
    }

    render () {
        const { orderHistoryDashboards } = this.props

        return (
            <div className="history-dashboard-container">
                <HistoryDashboard
                    loading={orderHistoryDashboards.get('loading')}
                    data={this.getData()}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderHistoryDashboards: state.get('orderHistoryDashboards'),
        orderChangeActivityEntities: state.getIn(['Entities', 'orderChangeActivity']),
        orderStateChangeActivityEntities: state.getIn(['Entities', 'orderStateChangeActivity']),
        supplierOrderChangeActivityEntities: state.getIn(['Entities', 'supplierOrderChangeActivity']),
        supplierOrderStateChangeActivityEntities: state.getIn(['Entities', 'supplierOrderStateChangeActivity'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderHistoryDashboards,
        setRefetch
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryDashboardContainer)
