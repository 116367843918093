import { Map, List } from 'immutable'
import actions from './actions'

const {
    CUSTOMER_NOTES_FETCH_SUCCESS,
    CUSTOMER_NOTES_FETCH,
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS,
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS,
    CUSTOMER_NOTES_SAVE_SUCCESS,
    CUSTOMER_NOTES_SAVE,
    CUSTOMER_NOTES_SET_FORM_ERRORS,
    CUSTOMER_NOTES_SET_PAGINATION,
    ORDER_NOTES_FETCH_SUCCESS,
    ORDER_NOTES_FETCH
} = actions

const initState = new Map({
    items: new List(),
    formErrors: new Map(),
    orderNoteImageItems: new List(),
    pagination: new Map({
        current: null,
        defaultPagesSize: 20,
        total: null
    }),
    saving: false,
    loading: false
})

export default function customerNotesReducer (state = initState, action) {
    const items = state.get('items')
    const orderNoteImageItems = state.get('orderNoteImageItems')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_NOTES_FETCH:
            return state.merge({ loading: true })

        case ORDER_NOTES_FETCH:
            return state.merge({ loading: true })

        case CUSTOMER_NOTES_SET_PAGINATION:
            return state.mergeDeep({ pagination: action.pagination })

        case CUSTOMER_NOTES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })

        case ORDER_NOTES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })

        case CUSTOMER_NOTES_SAVE:
            return state.merge({
                saving: action.saving
            })

        case CUSTOMER_NOTES_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: new List(items.includes(result) ? items : items.unshift(result)),
                orderNoteImageItems: new List()
            })

        case CUSTOMER_NOTES_SET_FORM_ERRORS:
            return state.merge({
                formErrors: action.formErrors
            })

        case CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS:
            return state.merge({
                orderNoteImageItems: new List(orderNoteImageItems.delete(orderNoteImageItems.indexOf(result)))
            })

        case CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS:
            let nextOrderNoteImageItems = orderNoteImageItems
            if (!orderNoteImageItems.includes(result)) {
                // if you change push method to another
                // you have to change algorithm in onRemoveImage method
                // of CustomerNoteForm Component
                nextOrderNoteImageItems = orderNoteImageItems.push(result)
            }

            return state.merge({
                orderNoteImageItems: new List(nextOrderNoteImageItems)
            })
        default:
            return state
    }
}
