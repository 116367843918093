import styled from 'styled-components'
const ImagesWrapper = styled.div`
.image-container {
  display: inline;
  flex-wrap: wrap;
}

.image-container > img {
  border: 1px solid #ddd;
  border-radius: 1px;
  padding: 1px;
  margin: 1px;
  width: 30px;
}
`
export default ImagesWrapper
