import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Form, Input, Button, Space } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'

import SelectVehicleTypes from '../SelectVehicleTypes'
import TruckOriginAddressSelector from '../../truckOriginAddressSelector'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const DelivereeFormRender = ({
  saving,
  onCancel,
  handleSubmit,
  initialValues,
  errorsMessage
}) => {
  const { i18n } = useTranslation('inquiries')
  const [form] = Form.useForm()

  const onChangeTruckOriginAddress = option => {
    form.setFieldsValue({ pickupAddress: option && option.detail })
  }

  const onSubmit = (values) => {
    const params = {
      vehicleTypeId: values['vehicleTypeId'],
      timeType: 'now',
      locations: [
        { address: values['pickupAddress'] },
        ...values['locations']
      ]
    }

    handleSubmit(params)
  }

  return (
    <Form form={form} onFinish={onSubmit} {...formItemLayout} initialValues={initialValues}>
      { errorsMessage ? <div style={{ color: 'red' }}>{errorsMessage}</div> : null}
      <Form.Item label={i18n.t('inquiries:deliveree.vehicleType')}  name='vehicleTypeId'
        rules={[{ required: true, message: 'Please Select Vehicle type'}]}
      >
        <SelectVehicleTypes placeholder={i18n.t('inquiries:deliveree.selectVehicleType')} />
      </Form.Item>
      <Form.Item label={i18n.t('inquiries:deliveree.pickupAddress')} style={{ marginBottom: '8px' }}>
        <TruckOriginAddressSelector onChangeEvent={onChangeTruckOriginAddress} />
      </Form.Item>
      <Form.Item
        name='pickupAddress'
        rules={[{ required: true, message: 'Please Input Pickup Address'}]}
        wrapperCol={{ offset: 6, span: 16 }}
      >
        <Input.TextArea placeholder={i18n.t('inquiries:deliveree.pickupAddress')} rows={3} />
      </Form.Item>
      <Form.Item label={i18n.t('inquiries:deliveree.shippingAddress')}
        style={{ marginBottom: 0 }}>
        <Form.List name='locations'>
            {(fields, { add, remove }) => (
                <>
                    <Button onClick={() => add({}, 0)} type="primary" ghost size="small" icon={<PlusOutlined />}
                      style={{ marginBottom: '8px' }}>
                      {i18n.t('inquiries:deliveree.addLocation')}
                    </Button>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div key={key} align="baseline" style={{ position: 'relative'}}>
                          <div style={{ position: 'absolute', right: '-20px', top: '-14px'}}>
                            <CloseCircleOutlined onClick={() => remove(name)} />
                          </div>
                          <Form.Item {...restField}
                              name={[name, 'address']}
                              rules={[{ required: true, message: 'Please Input Shipping Address'}]}
                              fieldKey={[fieldKey]}>
                                  <Input.TextArea placeholder={i18n.t('inquiries:deliveree.shippingAddress')}
                                    style={{ width: '100%' }} rows={3} />
                          </Form.Item>
                      </div>
                    ))}
                </>
            )}
        </Form.List>
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
            <Button loading={saving} type="primary" htmlType="submit">
                {i18n.t('shared:save')}
            </Button>
            <Button onClick={onCancel}>
                {i18n.t('shared:close')}
            </Button>
        </Space>
      </div>
    </Form>
  )
}

DelivereeFormRender.propTypes = {
  form: PropTypes.object,
  saving: PropTypes.bool,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  errorsMessage: PropTypes.string
}

DelivereeFormRender.defaultProps = {
  onCancel: () => {},
  handleSubmit: () => {}
}

export default DelivereeFormRender
