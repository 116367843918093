import React from 'react'
import { withTranslation } from 'react-i18next'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import { Tabs } from 'antd'
import VatDocumentsIndexContainer from './VatDocumentsIndexContainer'
import ExportVatDocuments from './ExportVatDocuments'

class VatDocumentIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Vat Documents | OSH CRM'
    }

    render () {
      const { i18n } = this.props
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/vat_documents', name: 'Vat Documents' }
                    ]}>
                    <ContentLayout>
                      <Tabs defaultActiveKey="vatDocuments">
                          <Tabs.TabPane tab={i18n.t('vatDocuments:vatDocuments')} key="vatDocuments">
                            <VatDocumentsIndexContainer />
                          </Tabs.TabPane>
                          <Tabs.TabPane key="load" tab={i18n.t('vatDocuments:download')}>
                              <h2>{i18n.t('vatDocuments:vatDocumentsReport')}</h2>
                              <ExportVatDocuments />
                          </Tabs.TabPane>
                      </Tabs>
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withTranslation('vatDocuments')(VatDocumentIndexPage)
