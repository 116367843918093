import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFetch } from '../../../../helpers/restRequest'
import actions from './actions'
import actionsNotification from '../../../../redux/notifications/actions'
import actionsAccounting from '../../../orders/accounting/actions'

import { normalize } from 'normalizr'
import { deserialize } from '../../../../helpers/jsonApi'

import { mailingAddressSchema, mailingAddressesSchema } from '../../../schema'

const { setNotifications } = actionsNotification

const { SHOW_ADD_MAILING_ADDRESS_MODAL } = actionsAccounting

const {
    MAILING_ADDRESSES_FETCH_REQUEST,
    MAILING_ADDRESSES_FETCH,
    MAILING_ADDRESSES_FETCH_SUCCESS,
    MAILING_ADDRESSES_SAVE_REQUEST,
    MAILING_ADDRESSES_SAVING,
    MAILING_ADDRESSES_SET_FORM_ERRORS,
    MAILING_ADDRESSES_SAVE_SUCCESS,
} = actions

const apiPath = (customerNo, id) => {
    const basePath = `/customers/${customerNo}/mailing_addresses`

    if (!id) { return basePath }
    return `/${basePath}/${id}`
}

export function *fetchMailingAddresses () {
    yield takeEvery(MAILING_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        const customerNo = payload.id

        yield put({ type: MAILING_ADDRESSES_FETCH })

        const data = yield call(apiFetch, apiPath(customerNo))

        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, mailingAddressesSchema)

        yield put({
            type: MAILING_ADDRESSES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveMailingAddress () {
    yield takeLatest(MAILING_ADDRESSES_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: MAILING_ADDRESSES_SAVING })

        const { id, customerId, onSuccess } = payload
        const method = id ? 'PATCH' : 'POST'

        const path = apiPath(customerId, id)
        const body = JSON.stringify({ mailing_address: payload.address })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: MAILING_ADDRESSES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put({
                    type: SHOW_ADD_MAILING_ADDRESS_MODAL,
                    value: true
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: MAILING_ADDRESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, mailingAddressSchema)
                })

                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: MAILING_ADDRESSES_SAVING,
                saving: false
            })
        }
    })
}


export default function *rootSaga () {
    yield all([
        fork(fetchMailingAddresses),
        fork(saveMailingAddress)
    ])
}
