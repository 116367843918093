import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Checkbox } from 'antd'

import orderActions from '../../../redux/orders/actions'

const { saveDoNotTransfer } = orderActions

class DoNoTransferForm extends Component {
    constructor (props) {
        super(props)

        this.state = {
            editable: false
        }
    }

    onCheckDoNotTransfer = (e) => {
        e.preventDefault()

        const { orderId, saveDoNotTransfer } = this.props
        saveDoNotTransfer({
            orderId,
            values: { do_not_transfer: e.target.checked }
        })
    }

    render () {
        const { i18n, order } = this.props
        const doNotTransfer = order.get('do_not_transfer')

        return (
            <Checkbox
                defaultChecked={doNotTransfer}
                onChange={this.onCheckDoNotTransfer}>
                {i18n.t('confirms:accounting.doNotTransfer')}
            </Checkbox>
        )
    }
}


const mapStateToProps = state => ({
    orders: state.get('orders')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveDoNotTransfer
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('confirms')(DoNoTransferForm))
