import styled from 'styled-components'

const OrderInvoiceWrapper = styled.div`

  .w-100 {
    width: 100%;
  }

  .m-0 {
    margin: 0;
  }

  .text-right {
    text-align: right;
  }

  .ant-card {
    margin-top: 16px;
  }

  .form-item-has-add-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .form-item-inline {
    margin: 0;

    .ant-form-item {
      display: inline-block;
      width: 24%;
    }

    .ant-form-item:not(:last-child) {
      margin-right: 1.3%;
    }
  }

  .invoice-header {
    display: flex;
    .tax-inv-tag {
      margin-left: 8px;
    }
  }
`
export default OrderInvoiceWrapper
