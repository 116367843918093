import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import _ from 'lodash'
import { apiFetch } from '../../../helpers/restRequest'
import { PAGE_SIZE } from '../../../constants/orders'

import { orderYieldSchema, orderYieldsSchema, orderBusinessAddressesSchema } from '../../schema'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'

const { setNotifications } = actionsNotification
const {
    ORDER_YIELD_FETCH_REQUEST,
    ORDER_YIELD_FETCH_SUCCESS,
    ORDER_YIELD_FETCH,
    ORDER_YIELD_SET_LOADING,
    ORDER_YIELDS_FETCH_FAIL,
    ORDER_YIELDS_FETCH_REQUEST,
    ORDER_YIELDS_FETCH_SUCCESS,
    ORDER_YIELDS_FETCHING,
    ORDER_YIELDS_INVOICES_FETCH_REQUEST,
    ORDER_YIELDS_INVOICE_FETCHING,
    ORDER_YIELDS_INVOICE_FETCH_SUCCESS,
    ORDER_YIELDS_INVOICE_SET_PAGINATION
} = actions

export function *fetchOrderYield () {
    yield takeEvery(ORDER_YIELD_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_YIELD_FETCH })
        yield put({ type: ORDER_YIELD_SET_LOADING, loading: true })

        const { orderId } = payload
        const path = `/orders/${orderId}/yield`

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderYieldSchema)

            yield put({ type: ORDER_YIELD_FETCH_SUCCESS, ...normalizeData })
        } finally {
            yield put({ type: ORDER_YIELD_SET_LOADING, loading: false })
        }
    })
}

export function *fetchOrderYieldsSaga () {
    yield takeLatest(ORDER_YIELDS_FETCH_REQUEST, function *({ payload }) {
        const {
            page,
            per,
            order,
            startDate,
            endDate,
            saleId,
            isCompleteYet,
            isRvEqualToInvoices,
            include,
            fields,
            onSuccess,
            onError
        } = payload

        yield put({ type: ORDER_YIELDS_FETCHING })

        const queryString = [
            `page=${page}`,
            `per=${per}`,
            `order=${order}`,
            `start_date=${startDate}`,
            `end_date=${endDate}`,
            `sale_id=${saleId}`,
            `is_complete_yet=${isCompleteYet}`,
            `is_rv_equal_to_invoices=${isRvEqualToInvoices}`,
            `include=${JSON.stringify(include)}`,
            `fields=${JSON.stringify(fields)}`
        ].join('&')

        const path = `/orders/yields?${queryString}`

        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderYieldsSchema)
            const totalPages = _.get(data, 'meta.total_pages', 0)

            yield put({
                type: ORDER_YIELDS_FETCH_SUCCESS,
                ...normalizedData,
                page,
                per,
                order,
                startDate,
                endDate,
                saleId,
                isCompleteYet,
                isRvEqualToInvoices,
                totalPages
            })

            onSuccess()
        } catch (error) {
            yield put({ type: ORDER_YIELDS_FETCH_FAIL })
            onError(error)
        }
    })
}

export function *fetchOrderBusinessAddressesSaga () {
    yield takeLatest(ORDER_YIELDS_INVOICES_FETCH_REQUEST, function *({ payload }) {
        const { page, sorter, invStartDate, invEndDate, sendingState, haveTrackingNumber } = payload

        yield put({ type: ORDER_YIELDS_INVOICE_FETCHING })

        const queryString = [
            `page=${page}`,
            `per=${PAGE_SIZE}`,
            `inv_start_date=${invStartDate}`,
            `inv_end_date=${invEndDate}`,
            `sending_state=${sendingState}`,
            `have_tracking_number=${haveTrackingNumber}`,
            `order=${_.get(sorter, 'value', '')}`
        ].join('&')

        const path = `/order_business_addresses?${queryString}`

        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderBusinessAddressesSchema)
            const total = data.meta.total_pages * PAGE_SIZE
            yield put({
                type: ORDER_YIELDS_INVOICE_SET_PAGINATION,
                pagination: {
                    current: page,
                    total
                }
            })
            yield put({
                type: ORDER_YIELDS_INVOICE_FETCH_SUCCESS,
                ...normalizedData,
                invStartDate,
                invEndDate
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}


export default function *rootSaga () {
    yield all([
        fork(fetchOrderYield),
        fork(fetchOrderYieldsSaga),
        fork(fetchOrderBusinessAddressesSaga)
    ])
}
