import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'

import { Link } from 'react-router-dom'
import { Card, Col, DatePicker, Row, Spin, Statistic } from 'antd'
import SearchSaleContainer from '../../containers/SearchSale'
import OrderSummariesStatisticsWrapper from './statistics.style'

const OrderSummariesStatistics = ({
    disableChangeType,
    onChangeFilter,
    onChangeSelectType,
    statistics
}) => {
    const { i18n } = useTranslation('statistics')
    const { filters, loading } = statistics
    const { bySale, startDate, endDate } = filters
    const cardProps = {
        hoverable: !disableChangeType,
        style: {
            width: '25%',
            textAlign: 'center',
        }
    }

    const onChangeDateFilter = ([start, end]) => {
        onChangeFilter({ startDate: start, endDate: end })
    }

    const renderFilterDate = () => {
        return (
            <div className="filter-group">
                <DatePicker.RangePicker allowClear={false}
                    onChange={(date, dateString) => onChangeDateFilter(dateString)}
                    value={[moment(startDate), moment(endDate)]} />
            </div>
        )
    }

    const renderFilterSale = () => {
        return (
            <div className="filter-group">
                <div className="label">{i18n.t('order.sale')}:</div>
                <SearchSaleContainer
                    onChange={value => onChangeFilter({ bySale: value })}
                    value={bySale} />
            </div>
        )
    }

    const renderLink = (state, noPrice) => {
        if (!disableChangeType) { return `(${i18n.t('customerForm.seeMore')})` }

        const queryObject = {
            no_price: noPrice,
            by_sale: bySale,
            created_start_date: startDate,
            created_end_date: endDate
        }
        const queryKeys = _.keys(_.pickBy(queryObject, (value) => value))
        const queryString = queryKeys.map(key => `${key}=${queryObject[key]}`).join('&')

        return (
            <Link to={`/order_summaries/${state}/state?${queryString}`}>
                ({i18n.t('customerForm.seeMore')})
            </Link>
        )
    }

    const renderStatisticCard = ({ key, state, noPrice = '' }) => {
        return (
            <Card.Grid key={key}
                className={filters.state === state && noPrice === filters.noPrice ? 'card-active' : ''}
                onClick={() => onChangeSelectType({ state, noPrice })}
                {...cardProps}>
                <Statistic key={key}
                    title={i18n.t(`statistics:orderSummariesDashboard.${_.camelCase(key)}`)}
                    formatter={value => renderStatisticValue({ key, value, state, noPrice })}
                    value={_.get(statistics, _.camelCase(key)) || 0}
                />
            </Card.Grid>
        )
    }

    const renderStatisticValue = ({ key, value, state, noPrice }) => {
        const allOrderStatesPercentage = _.get(statistics, 'orderStatePercentage', {})
        const statePercent = _.get(allOrderStatesPercentage, key)

        return (
            <div className="statistic-formatter">
                <div className="statistic-value">
                    <div>{value}</div>
                    <div className="value-unit">{i18n.t('statistics:orderUnit')}</div>
                </div>
                <div className="statistic-percentage">{statePercent ? `(${statePercent}%)` : '(-)'}</div>
                <div className="statistic-link">{renderLink(state, noPrice)}</div>
            </div>
        )
    }

    return (
        <OrderSummariesStatisticsWrapper>
            <Spin spinning={loading}>
                <Row>
                    <Col span="18">
                        <Card className="card-container">
                            {renderStatisticCard({ key: 'all_requesting_quotation', state: 'requesting_quotation' })}
                            {renderStatisticCard({
                                key: 'requesting_quotation_with_no_price',
                                state: 'requesting_quotation',
                                noPrice: 'true'
                            })}
                            {renderStatisticCard({ key: 'all_waiting_customer_confirm', state: 'waiting_customer_confirm' })}
                            {renderStatisticCard({ key: 'all_cancel', state: 'cancel' })}
                        </Card>
                    </Col>
                    <Col span="6" style={{ paddingLeft: '16px' }}>
                        {renderFilterDate()}
                        {renderFilterSale()}
                    </Col>
                </Row>
            </Spin>
        </OrderSummariesStatisticsWrapper>
    )
}

OrderSummariesStatistics.propTypes = {
    disableChangeType: PropTypes.bool.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
    onChangeSelectType: PropTypes.func.isRequired,
    statistics: PropTypes.object
}

OrderSummariesStatistics.defaultProps = {}

export default OrderSummariesStatistics
