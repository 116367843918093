import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import TruckOwnerContainer from './TruckOwnerContainer'

class TruckIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Trucks | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/trucks', name: 'Trucks' }
                    ]}>
                    <ContentLayout>
                      <TruckOwnerContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default TruckIndexPage
