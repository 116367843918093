import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map } from 'immutable'
import _ from 'lodash'

import { Button, Form, Input } from 'antd'

import actions from '../../../redux/orderPaymentDetails/Installments/actions'
import inquiryActions from '../../../redux/inquiry/actions'
import notificationActions from '../../../redux/notifications/actions'

const { saveOrderPaymentDetailInstallment } = actions
const { fetchInquiry } = inquiryActions
const { setNotifications } = notificationActions

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
}

const formItemLayoutWithOutLabel = {
    wrapperCol: { span: 24 }
}

const OrderPaymentDetailInstallmentForm = ({
    id,
    orderId,
    toggleForm
}) => {
    const { i18n } = useTranslation('inquiries')
    const dispatch = useDispatch()
    const initialData = useSelector(state => state.getIn(['Entities', 'orderPaymentDetailInstallments', id], new Map()))
    const saving = useSelector(state => state.getIn(['orderPaymentDetailInstallments', 'saving']))

    const handleSaveInstallment = (params) => {
        dispatch(saveOrderPaymentDetailInstallment({
            orderId,
            id,
            params,
            onSuccess: () => {
                dispatch(setNotifications('success', 'saveSuccess', 'success'))
                toggleForm()
                if (!id) { dispatch(fetchInquiry(orderId)) }
            },
            onError: (error) => {
                dispatch(setNotifications(`${error.name}`, `${error.message}`, 'error'))
            }
        }))
    }

    const handleSubmitForm = (values) => {
        handleSaveInstallment(values)
    }

    return (
        <Form onFinish={handleSubmitForm} {...formItemLayout}>
            <Form.Item label={i18n.t('inquiries:paymentDetailInstallment.amount')}
                name='amount'
                initialValue={initialData.get('amount')}>
                    <Input />
            </Form.Item>
            <Form.Item style={{ textAlign: 'center' }} {...formItemLayoutWithOutLabel}>
                <Button type="primary" htmlType="submit" loading={saving}>
                    {i18n.t('shared:save')}
                </Button>
                <Button style={{ marginLeft: '12px' }} onClick={toggleForm}>
                    {i18n.t('shared:close')}
                </Button>
            </Form.Item>
        </Form>
    )
}

OrderPaymentDetailInstallmentForm.propTypes = {
    id: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func
}

OrderPaymentDetailInstallmentForm.defaultProps = {
    toggleForm: () => {}
}

export default OrderPaymentDetailInstallmentForm
