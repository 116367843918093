import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import OrderAccountingsPage from '../containers/Orders/Accountings'
import OrderYieldIndexPage from '../containers/OrderYields'
import OrdersDocumentsPage from '../containers/Orders/Documents'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const getCurrent = (pathname) => {
  switch (pathname) {
    case '/orders/yields':
      return 'orders/yields'
    case '/orders/documents':
      return 'orders/documents'
    default:
      return 'order_summaries'
  }
}

const OrdersPage = ({
	location,
	match
}) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
    setCurrent()
	}, [pathname])

  const setCurrent = () => {
    const newCurrent = getCurrent(pathname)
    if (current[0] === newCurrent) { return }

    dispatch(changeCurrent([newCurrent]))
  }

	const path = match.url

  return (
		<Switch>
      <Route path={`${path}/:id/accountings`} component={OrderAccountingsPage} />
      <Route path={`${path}/yields`} component={OrderYieldIndexPage} />
      <Route path={`${path}/documents`} component={OrdersDocumentsPage} />
    </Switch>
  )
}

OrdersPage.propTypes = {
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

OrdersPage.defaultProps = {}

export default OrdersPage
