import { all, fork, takeEvery, put, select, call } from 'redux-saga/effects'

import { apiFileFetch } from '../../helpers/restRequest'
import imageActions from './actions'

const {
    IMAGES_ADD_NEW_IMAGE_REQUEST,
    IMAGES_ADD_NEW_IMAGE,
    IMAGES_ORIGINAL_FETCH_BLOB_REQUEST,
    IMAGES_THUMB_FETCH_BLOB_REQUEST,
    originalSetBlob,
    originalSetLoading,
    thumbSetBlob,
    thumbSetLoading
} = imageActions

export function *addNewImageRequest () {
    yield takeEvery(IMAGES_ADD_NEW_IMAGE_REQUEST, function *({ payload }) {
        const { thumbUrl, originalUrl, contentType, isImageBlob } = payload

        const image = yield select((state) => (state.getIn(['Entities', 'images', thumbUrl, 'thumbBlob'])))
        if (image) { return null }

        yield put({ type: IMAGES_ADD_NEW_IMAGE, thumbUrl, originalUrl, contentType })

        if (isImageBlob) {
            yield put({ type: IMAGES_THUMB_FETCH_BLOB_REQUEST, payload: { thumbUrl } })
        } else {
            yield put(thumbSetBlob(thumbUrl, thumbUrl))
        }
    })
}

export function *thumbFetchBlobRequest () {
    yield takeEvery(IMAGES_THUMB_FETCH_BLOB_REQUEST, function *({ payload }) {
        const thumbUrl = payload.thumbUrl
        const image = yield select((state) => (state.getIn(['Entities', 'images', thumbUrl])))

        if (!image) { return null }

        // return when thumbBlob is already fetch
        const thumbBlob = image.get('thumbBlob')
        if (thumbBlob) { return null }

        try {
            yield put(thumbSetLoading(thumbUrl, true))

            // use substring(3) because CORS problem we have to cut "/{locale}" from thumbUrl
            const imageUrl = thumbUrl.substring(3)
            const contentType = image.get('contentType') || 'image/jpeg'
            const imageBlob = yield call(fetchBlobImage, imageUrl, contentType)

            yield put(thumbSetBlob(thumbUrl, imageBlob))
        } catch (error) {
            console.log(error)
        } finally {
            yield put(thumbSetLoading(thumbUrl, false))
        }
    })
}

export function *originalFetchBlobRequest () {
    yield takeEvery(IMAGES_ORIGINAL_FETCH_BLOB_REQUEST, function *({ payload }) {
        const { thumbUrl, isImageBlob } = payload
        const image = yield select((state) => (state.getIn(['Entities', 'images', thumbUrl])))

        if (!image) { return null }

        // return if have no original url or original blob is already fetch
        const originalUrl = image.get('originalUrl')
        const originalBlob = image.get('originalBlob')
        if (!originalUrl || originalBlob) { return null }

        try {
            yield put(originalSetLoading(thumbUrl, true))

            if (isImageBlob) {
                const contentType = image.get('contentType') || 'image/jpeg'
                const imageBlob = yield call(fetchBlobImage, originalUrl.substring(3), contentType)

                yield put(originalSetBlob(thumbUrl, imageBlob))
            } else {
                yield put(originalSetBlob(thumbUrl, originalUrl))
            }
        } catch (error) {
            console.log(error)
        } finally {
            yield put(originalSetLoading(thumbUrl, false))
        }
    })
}

function *fetchBlobImage (imageUrl, contentType) {
    const imageData = yield call(
        apiFileFetch,
        imageUrl,
        'GET',
        {},
        true,
        contentType
    )

    return URL.createObjectURL(imageData)
}

export default function *rootSaga () {
    yield all([
        fork(addNewImageRequest),
        fork(originalFetchBlobRequest),
        fork(thumbFetchBlobRequest)
    ])
}
