const downLoadFile = (args = { fileURL: '', filename: '' }) => {
    const { fileURL, filename } = args
    const downloadLink = window.document.createElement('a')
    downloadLink.href = fileURL
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}

export default downLoadFile
