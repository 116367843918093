import _ from 'lodash'

const actions = {

    REQUEST_ITEM_INDICES_UPLOAD_FAIL: 'REQUEST_ITEM_INDICES_UPLOAD_FAIL',
    REQUEST_ITEM_INDICES_UPLOAD_REQUEST: 'REQUEST_ITEM_INDICES_UPLOAD_REQUEST',
    REQUEST_ITEM_INDICES_UPLOAD_SUCCESS: 'REQUEST_ITEM_INDICES_UPLOAD_SUCCESS',
    REQUEST_ITEM_INDICES_UPLOADING: 'REQUEST_ITEM_INDICES_UPLOADING',

    requestItemIndices: ({
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.REQUEST_ITEM_INDICES_UPLOAD_REQUEST,
            payload: { params, onSuccess, onError }
        }
    }
}

export default actions
