import React from 'react'

import PurchaseOrderPreviewContainer from '../Previews/PurchaseOrderPreviewContainer'

class PurchaseOrderPreviews extends React.Component {
    componentDidMount() {
        const { match } = this.props
        const { ref_code: refCode } = match.params

        document.title = `${refCode} Previews | OSH CRM`
    }

    render () {
        const { match } = this.props
        const { ref_code: refCode } = match.params

        return (
            <PurchaseOrderPreviewContainer refCode={refCode} />
        )
    }
}

export default PurchaseOrderPreviews
