import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import OrdersListByState from '../../../components/orderSummaries/states/ordersListByState'

import actions from '../../../redux/orders/summaryDashBoards/actions'

const { fetchOrderSummaryDashboards, fetchOrderSummarySales } = actions

const OrdersListByStateContainer = ({
    history,
    location,
    state
}) => {
    const [activeKey, setActiveKey] = useState('orders')

    const dispatch = useDispatch()

    const fetchOrders = ({ state, bySale, noPrice, startDate, endDate }) => {
        const queryStringObject = _.pickBy({
            no_price: noPrice,
            by_sale: bySale,
            created_start_date: startDate,
            created_end_date: endDate
        }, (value) => value)

        history.push({
            pathname: `/order_summaries/${state}/state`,
            search: '?' + Object.keys(queryStringObject).map(key => `${key}=${queryStringObject[key]}`).join('&'),
            params: queryStringObject
        })

        dispatch(fetchOrderSummarySales({ state, noPrice, startDate, endDate }))
        dispatch(fetchOrderSummaryDashboards({
            state,
            bySale,
            noPrice,
            createdStartDate: startDate,
            createdEndDate: endDate
        }))
    }

    const onChangeTab = (key) => {
        setActiveKey(key)
    }

    return (
        <OrdersListByState
            activeKey={activeKey}
            defaultFilters={{
                state,
                bySale: new URLSearchParams(location.search).get("by_sale"),
                noPrice: new URLSearchParams(location.search).get("no_price"),
                createdStartDate: new URLSearchParams(location.search).get("created_start_date"),
                createdEndDate: new URLSearchParams(location.search).get("created_end_date"),
            }}
            onRefetch={fetchOrders}
            onChangeTab={onChangeTab}
        />
    )
}

OrdersListByStateContainer.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    state: PropTypes.string.isRequired
}

OrdersListByStateContainer.defaultProps = {}

export default OrdersListByStateContainer
