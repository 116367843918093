import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import actions from '../../redux/districts/actions'

import SelectDistrict from '../../components/SelectDistrict'

const {
    fetchDistricts
} = actions

class SelectDistrictContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        provinceId: PropTypes.string,
        value: PropTypes.string,

        // Inner Props
        districtEntities: ImmutablePropTypes.map.isRequired,
        districts: ImmutablePropTypes.map.isRequired,
        fetchDistricts: PropTypes.func.isRequired,
        provinceEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        provinceId: '',
        value: undefined
    }

    constructor (props) {
        super(props)

        this.state = {
            selectedDistrictId: _.toString(props.value) || undefined
        }
    }

    componentDidMount () {
        const { provinceId, fetchDistricts } = this.props

        if (!provinceId) return null

        fetchDistricts(provinceId)
    }

    componentDidUpdate (prevProps) {
        const { provinceId, fetchDistricts, value } = this.props
        const { provinceId: prevProvinceId, value: prevValue } = prevProps

        if (value !== prevValue) {
            this.setState({ selectedDistrictId: value || undefined })
        }

        if (provinceId === prevProvinceId) return null

        fetchDistricts(provinceId)
    }

    getDistricts () {
        const { provinceId, provinceEntities, districtEntities } = this.props

        if (!provinceId) { return new List() }

        const districtIds = new List(provinceEntities.getIn([_.toString(provinceId), 'districts'], []))

        return districtIds.map((districtId) => {
            return districtEntities.get(_.toString(districtId), new Map())
        })
    }

    onChange = (selectedDistrictId) => {
        const { onChange } = this.props

        this.setState({ selectedDistrictId: _.toString(selectedDistrictId) || undefined })
        onChange(_.toString(selectedDistrictId))
    }

    render () {
        const { districts } = this.props
        const { selectedDistrictId } = this.state

        return (
            <SelectDistrict
                districts={this.getDistricts().toJS()}
                loading={districts.get('loading')}
                onChange={this.onChange}
                value={selectedDistrictId}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        districtEntities: state.getIn(['Entities', 'districts']),
        districts: state.get('districts'),
        provinceEntities: state.getIn(['Entities', 'provinces'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchDistricts
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDistrictContainer)
