import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Modal, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import ItemStateForm from './form'


const ItemState = ({ itemExternalId, refetchOrder, itemState }) => {
    const { i18n } = useTranslation('inquiries')
    const [showForm, setShowForm] = useState(false)

    const toggleForm = () => {
        setShowForm(!showForm)
    }

    if (!itemExternalId) return null

    return (
        <div>
            {itemState}
            <Typography.Link onClick={toggleForm}>
              <EditOutlined />
            </Typography.Link>
            <Modal
                title={i18n.t('inquiries:itemState')}
                visible={showForm}
                onCancel={toggleForm}
                destroyOnClose
                footer={null}
            >
                <ItemStateForm i18n={i18n}
                  defaultValue={itemState}
                  onToggleForm={toggleForm}
                  refetchOrder={refetchOrder}
                  itemId={itemExternalId} />
            </Modal>
        </div>
    )
}

ItemState.propTypes = {
    itemExternalId: PropTypes.string.isRequired,
    itemState: PropTypes.string,
    refetchOrder: PropTypes.func
}

ItemState.defaultProps = {
  refetchOrder: () => {}
}

export default ItemState
