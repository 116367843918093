import styled from 'styled-components'
const SupplierWrapper = styled.div`

  .supplier-section, .bank-section {
    white-space: nowrap;

    .label {
      font-weight: bold;
      margin-right: 4px;
    }

    .label:after {
      content: ':';
    }
  }

  .export-section {
    display: flex;
    margin-top: 16px;
    justify-content: end;

    .export-suppliers {
      margin-left: 12px;
    }
  }
`
export default SupplierWrapper
