import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import ReissueInvoiceNoteForm from '../../../components/Invoices/reissueInvoice/noteForm'
import { camelize } from '../../../helpers/string'

const REISSUE_INVOICE_MUTATION = gql`
  mutation UpdateReissueInvoiceNote(
    $invoiceId: ID!
    $orderBusinessAddressParams: AdminOrderBusinessAddressInput!
  ) {
    updateReissueInvoiceNote(
      invoiceId: $invoiceId
      orderBusinessAddressParams: $orderBusinessAddressParams
    ) {
      errors
    }
  }
`

const convertObjectKeyToCamelCase = (object) => {
  const result = {}
  for (const property in object) {
    let value = object[property]
    const valueType = typeof value

    if (!value) {
      value = null
    } else if (valueType === 'object') {
      value = convertObjectKeyToCamelCase(value)
    } else if (valueType === 'array') {
      value = value.map((v) => convertObjectKeyToCamelCase(v))
    }

    result[camelize(property)] = value
  }

  return result
}

const ReissueInvoiceNoteFormContainer = ({ invoiceId, onClose, onSuccess }) => {
  const [errors, setErrors] = useState({})
  const [reissueInvoice, { loading: saving }] = useMutation(
    REISSUE_INVOICE_MUTATION,
    {
      onCompleted: ({ updateReissueInvoiceNote }) => {
        if (updateReissueInvoiceNote.errors) {
          setErrors(JSON.parse(updateReissueInvoiceNote.errors))
        } else {
          onSuccess()
          onClose()
        }
      }
    }
  )

  const handleSubmitForm = (params) => {
    params['previous_re_invoice_attributes']['previous_invoice_id'] = invoiceId
    const orderBusinessAddressParams = convertObjectKeyToCamelCase(params)

    reissueInvoice({ variables: { invoiceId, orderBusinessAddressParams } })
  }

  return (
    <ReissueInvoiceNoteForm
      errors={errors}
      onClose={onClose}
      onSubmitForm={handleSubmitForm}
      saving={saving}
    />
  )
}

ReissueInvoiceNoteFormContainer.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}

ReissueInvoiceNoteFormContainer.defaultProps = {
  onSuccess: () => {}
}

export default ReissueInvoiceNoteFormContainer
