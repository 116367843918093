import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import { projectContactSchema, projectContactsSchema } from '../../schema'
import actions from './actions'

const {
    PROJECT_CONTACTS_FETCH_REQUEST,
    PROJECT_CONTACTS_FETCHING,
    PROJECT_CONTACTS_FETCH_SUCCESS,
    PROJECT_CONTACTS_SET_PAGINATION,
    PROJECT_CONTACT_SAVE_REQUEST,
    PROJECT_CONTACT_SAVING,
    PROJECT_CONTACT_SET_FORM_ERRORS,
    PROJECT_CONTACT_SAVE_SUCCESS,
    PROJECT_CONTACT_FETCH_REQUEST,
    PROJECT_CONTACT_FETCHING,
    PROJECT_CONTACT_FETCH_SUCCESS,
    PROJECT_CONTACT_DELETE_REQUEST,
    PROJECT_CONTACT_DELETE,
    PROJECT_CONTACT_DELETE_SUCCESS
} = actions

export function *fetchProjectContacts () {
    yield takeEvery(PROJECT_CONTACTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_CONTACTS_FETCHING })

        const { projectId, page, per, all } = payload
        const queryString = all ? `page=${page}&per=${per}&all=${all}` : `page=${page}&per=${per}`

        const data = yield call(apiFetch, `/company_projects/${projectId}/contacts?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, projectContactsSchema)
        const total = data.meta ? data.meta.total_pages * per : null

        yield put({
            type: PROJECT_CONTACTS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: PROJECT_CONTACTS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveProjectContact () {
    yield takeLatest(PROJECT_CONTACT_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_CONTACT_SAVING, saving: true })

        const { projectId, id, params, onSuccess, onError } = payload

        const basePath = `/company_projects/${projectId}/contacts/`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ project_contact: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: PROJECT_CONTACT_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, projectContactSchema)

                yield put({
                    type: PROJECT_CONTACT_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: PROJECT_CONTACT_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: PROJECT_CONTACT_SAVING, saving: false })
        }
    })
}

export function *fetchProjectContact () {
    yield takeEvery(PROJECT_CONTACT_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_CONTACT_FETCHING, loading: true })

        const { projectId, id } = payload
        const path = `/company_projects/${projectId}/contacts/${id}`

        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, projectContactSchema)

        yield put({
            type: PROJECT_CONTACT_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *deleteProjectContact () {
    yield takeLatest(PROJECT_CONTACT_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: PROJECT_CONTACT_DELETE })

        const { projectId, id, onSuccess } = payload
        const path = `/company_projects/${projectId}/contacts/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, projectContactSchema)

        yield put({
            type: PROJECT_CONTACT_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchProjectContacts),
        fork(saveProjectContact),
        fork(fetchProjectContact),
        fork(deleteProjectContact)
    ])
}
