import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import { customerWalletSchema, customerWalletsSchema } from '../../schema'
import { deserialize } from '../../../helpers/jsonApi'

const {
    CUSTOMER_WALLETS_FETCH_REQUEST,
    CUSTOMER_WALLETS_FETCH,
    CUSTOMER_WALLETS_FETCH_SUCCESS,
    CUSTOMER_WALLET_SAVE_REQUEST,
    CUSTOMER_WALLET_SAVING,
    CUSTOMER_WALLET_SAVE_SUCCESS,
    CUSTOMER_WALLET_DELETE_REQUEST,
    CUSTOMER_WALLET_DELETE,
    CUSTOMER_WALLET_DELETE_SUCCESS,
    CUSTOMER_WALLETS_SET_PAGINATION,
    CUSTOMER_WALLET_FETCH_REQUEST,
    CUSTOMER_WALLET_FETCH,
    CUSTOMER_WALLET_SET_FORM_ERRORS,
    CUSTOMER_WALLET_FETCH_SUCCESS,
    ORDER_CUSTOMER_WALLET_SAVE_REQUEST,
    ORDER_CUSTOMER_WALLET_SAVING,
    ORDER_CUSTOMER_WALLET_SAVE_SUCCESS,
    ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS,
    ORDER_CUSTOMER_WALLETS_FETCH_REQUEST,
    ORDER_CUSTOMER_WALLETS_FETCH,
    ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS,
    ORDER_CUSTOMER_WALLET_DELETE_REQUEST,
    ORDER_CUSTOMER_WALLET_DELETE,
    ORDER_CUSTOMER_WALLET_DELETE_SUCCESS
} = actions

export function *fetchCustomerWallets () {
    yield takeEvery(CUSTOMER_WALLETS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_WALLETS_FETCH })

        const { customerId, page, per } = payload
        const queryString = `page=${page}&per=${per}`

        const path = `/customers/${customerId}/wallets?${queryString}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: CUSTOMER_WALLETS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: CUSTOMER_WALLETS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveCustomerWallet () {
    yield takeLatest(CUSTOMER_WALLET_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_WALLET_SAVING, saving: true })

        const { customerId, id, params, onSuccess, onError } = payload

        const basePath = `/customers/${customerId}/wallets`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ wallet: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: CUSTOMER_WALLET_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, customerWalletSchema)

                yield put({
                    type: CUSTOMER_WALLET_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: CUSTOMER_WALLET_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: CUSTOMER_WALLET_SAVING, saving: false })
        }
    })
}

export function *deleteCustomerWallet () {
    yield takeLatest(CUSTOMER_WALLET_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_WALLET_DELETE })

        const { customerId, id, onSuccess } = payload
        const path = `/customers/${customerId}/wallets/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletSchema)

        yield put({
            type: CUSTOMER_WALLET_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}

export function *fetchCustomerWalletRequest () {
    yield takeLatest(CUSTOMER_WALLET_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_WALLET_FETCH, loading: true })

        const { customerId, id } = payload
        const path = `/customers/${customerId}/wallets/${id}`

        const data = yield call(apiFetch, path)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletSchema)

        yield put({
            type: CUSTOMER_WALLET_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveOrderCustomerWallet () {
    yield takeLatest(ORDER_CUSTOMER_WALLET_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_CUSTOMER_WALLET_SAVING, saving: true })

        const { orderId, id, params, onSuccess, onError } = payload

        const basePath = `/orders/${orderId}/customer_wallets`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ wallet: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, customerWalletSchema)

                yield put({
                    type: ORDER_CUSTOMER_WALLET_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: ORDER_CUSTOMER_WALLET_SAVING, saving: false })
        }
    })
}

export function *fetchOrderCustomerWallets () {
    yield takeEvery(ORDER_CUSTOMER_WALLETS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_CUSTOMER_WALLETS_FETCH })

        const { orderId } = payload

        const path = `/orders/${orderId}/customer_wallets`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletsSchema)

        yield put({
            type: ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *deleteOrderCustomerWallet () {
    yield takeLatest(ORDER_CUSTOMER_WALLET_DELETE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_CUSTOMER_WALLET_DELETE })

        const { orderId, id, onSuccess } = payload
        const path = `/orders/${orderId}/customer_wallets/${id}`
        const data = yield call(apiFetch, path, 'DELETE')
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerWalletSchema)

        yield put({
            type: ORDER_CUSTOMER_WALLET_DELETE_SUCCESS,
            ...normalizeData
        })

        onSuccess()
    })
}


export default function *rootSaga () {
    yield all([
        fork(fetchCustomerWallets),
        fork(saveCustomerWallet),
        fork(deleteCustomerWallet),
        fork(fetchCustomerWalletRequest),
        fork(saveOrderCustomerWallet),
        fork(fetchOrderCustomerWallets),
        fork(deleteOrderCustomerWallet)
    ])
}
