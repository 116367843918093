import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Card, Row, Col } from 'antd'
import { Link } from 'react-router-dom'

const renderCustomerInfo = props => {
    const { customer, i18n, order } = props

    return (
        <Card bodyStyle={{ padding: '8px' }}>
            <Row gutter={24}>
                <Col span={6}>{i18n.t('customerName')}:</Col>
                <Col span={18}>
                    <Link to={`/customers/${_.get(customer, 'id')}`}>
                        {[_.get(customer, 'first_name'), _.get(customer, 'last_name')].filter(Boolean).join(' ')}
                    </Link>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>{i18n.t('customerForm.phone')}:</Col>
                <Col span={18}>{_.get(customer, 'phone_number', '')}</Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>{i18n.t('order.customerNote')}:</Col>
                <Col span={18}>{_.get(order, 'detail', '')}</Col>
            </Row>
        </Card>
    )
}

const CustomerInfo = props => {
    const { customer, expand, i18n, toggleExpand } = props

    if (_.isEmpty(customer)) { return null }

    return (
        <div>
            <div style={{ padding: '4px' }}>
                <Link onClick={toggleExpand}>
                    {expand ?
                        i18n.t('confirms:accounting.hideCustomerInfo') :
                        i18n.t('confirms:accounting.showCustomerInfo')
                    }
                </Link>
            </div>
            {expand ? renderCustomerInfo(props) : null}
        </div>
    )
}

CustomerInfo.propTypes = {
    customer: PropTypes.object,
    expand: PropTypes.bool,
    order: PropTypes.object,
    toggleExpand: PropTypes.func
}

export default withTranslation('confirms')(CustomerInfo)
