import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Form, Input, Button, Space, DatePicker } from 'antd'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'

import TruckOriginAddressSelector from '../../truckOriginAddressSelector'
import SelectLalamoveVehicleTypes from '../selectVehicleTypes'
import moment from 'moment'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 }
}

const LalamoveFormRender = ({
  saving,
  onCancel,
  handleSubmit,
  initialValues = {},
  errorsMessage
}) => {
  const { i18n } = useTranslation('inquiries')
  const [form] = Form.useForm()

  const onChangeTruckOriginAddress = option => {
    form.setFieldsValue({ pickupAddress: option.name + ' ' + option.detail })
  }

  const onSubmit = (values) => {
    const date = values['date']
    const now = Date()
    const threeMinsFromNow = moment(now).add(3, 'm').toISOString()
    const params = {
      serviceType: values['serviceType'],
      stockLocation: values['pickupAddress'],
      locations: values['locations']
    }

    if (!date) {
      params.pickupTime = threeMinsFromNow
      handleSubmit(params)
    } else {
      params.pickupTime = date._d.toISOString()
      handleSubmit(params)
    }
  }

  return (
    <Form form={form} onFinish={onSubmit} {...formItemLayout} initialValues={initialValues}>
      { errorsMessage ? <div style={{ color: 'red' }}>{errorsMessage}</div> : null}
      <Form.Item name='date'
        label={i18n.t('inquiries:lalamove.pickupDate')}>
        <DatePicker
          disabledDate={(date) => {
            const today = moment(new Date())
            const duration = moment.duration(date.diff(today, 'days'))
            const daysDiff = duration.asDays()

            return daysDiff < 0
          }}
          allowEmpty={true}
          disableSeconds={true}
          showTime={true}
          format="YYYY-MM-DD HH:mm"
          placeholder={i18n.t('inquiries:lalamove.chooseDate')}
        />
      </Form.Item>

      <Form.Item label={i18n.t('inquiries:lalamove.vehicleType')}  name='serviceType'
        rules={[{ required: true, message: 'Please Select Vehicle type'}]}
      >
        <SelectLalamoveVehicleTypes placeholder={i18n.t('inquiries:lalamove.selectVehicleType')} />
      </Form.Item>
      <Form.Item label={i18n.t('inquiries:lalamove.pickupAddress')} style={{ marginBottom: '8px' }}>
        <TruckOriginAddressSelector onChangeEvent={onChangeTruckOriginAddress} />
      </Form.Item>
      <Form.Item
        name='pickupAddress'
        rules={[{ required: true, message: 'Please Input Pickup Address'}]}
        wrapperCol={{ offset: 6, span: 16 }}
      >
        <Input.TextArea placeholder={i18n.t('inquiries:lalamove.pickupAddress')} rows={3} />
      </Form.Item>
      <Form.Item label={i18n.t('inquiries:lalamove.shippingAddress')}
        style={{ marginBottom: 0 }}>
        <Form.List name='locations'>
          {(fields, { add, remove }) => (
            <>
              <Button onClick={() => add()} type="primary" ghost size="small" icon={<PlusOutlined />}
                style={{ marginBottom: '8px' }}>
                {i18n.t('inquiries:lalamove.addLocation')}
              </Button>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} align="baseline" style={{ position: 'relative'}}>
                  <div style={{ position: 'absolute', right: '-20px', top: '-14px'}}>
                    <CloseCircleOutlined onClick={() => remove(name)} />
                  </div>
                  <Form.Item {...restField}
                    name={[name]}
                    rules={[{ required: true, message: 'Please Input Shipping Address'}]}
                    fieldKey={[fieldKey]}>
                      <Input.TextArea placeholder={i18n.t('inquiries:lalamove.shippingAddress')}
                        style={{ width: '100%' }} rows={3} />
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form.Item>
      <div style={{ textAlign: 'center' }}>
        <Space>
          <Button loading={saving} type="primary" htmlType="submit">
            {i18n.t('shared:save')}
          </Button>
          <Button onClick={onCancel}>
            {i18n.t('shared:close')}
          </Button>
        </Space>
      </div>
    </Form>
  )
}

LalamoveFormRender.propTypes = {}

LalamoveFormRender.defaultProps = {}

export default LalamoveFormRender
