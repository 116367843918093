import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import ProductGroupItemsList from '../../components/productGroups/itemsList'

import actions from '../../redux/productGroups/actions'

const { fetchItems } = actions

const ProductGroupItemsListContainer = ({
    onSelectItem,
    parentId,
    productGroupId,
    productGroupItemId,
    selectedItemIds
}) => {
    const dispatch = useDispatch()
    const itemIds = useSelector(state => state.getIn(['productGroups', 'itemIds']) || new List())
    const loading = useSelector(state => state.getIn(['productGroups', 'itemsLoading']) || false)
    const itemEntities = useSelector(state => state.getIn(['Entities', 'itemWithProductGroups']) || new Map())
    const pagination = useSelector(state => state.getIn(['productGroups', 'itemsPagination']) || new Map())

    useEffect(() => {
        if (productGroupId) { onFetchItems() }
    }, [productGroupId, productGroupItemId, parentId])

    const onFetchItems = ({ page = 1 } = {}) => {
        dispatch(fetchItems({ productGroupId, productGroupItemId, parentId, page }))
    }

    const onPageChange = (pagination) => {
        onFetchItems({ page: pagination.current })
    }

    const items = itemIds.map(id => itemEntities.get(`${id}`) || new Map())

    return (
        <ProductGroupItemsList
            items={items.toJS()}
            loading={loading}
            onPageChange={onPageChange}
            onSelectItem={onSelectItem}
            pagination={{
                ...pagination.toJS(),
                showSizeChanger: false
            }}
            selectedItemIds={selectedItemIds} />
    )
}

ProductGroupItemsListContainer.propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    parentId: PropTypes.string,
    productGroupId: PropTypes.string,
    productGroupItemId: PropTypes.string,
    selectedItemIds: PropTypes.array
}

ProductGroupItemsListContainer.defaultProps = {}

export default ProductGroupItemsListContainer
