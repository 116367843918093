import { Map, List } from 'immutable'
import actions from './actions'

const {
  ORDER_TRANSFER_BACKS_FETCH,
  ORDER_TRANSFER_BACKS_FETCH_SUCCESS,

  ORDER_TRANSFER_BACK_FETCH,
  ORDER_TRANSFER_BACK_FETCH_SUCCESS,

  ORDER_TRANSFER_BACK_FETCH_ITEMS,
  ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS,

  ORDER_TRANSFER_BACK_SAVE,
  ORDER_TRANSFER_BACK_SAVE_SUCCESS,
  ORDER_TRANSFER_BACK_SAVE_ERRORS,

  SET_INQUIRY_SHOW_TRANSFER_BACK_FORM,
  SET_INQUIRY_SHOW_TRANSFER_BACK_SHIPPING_COST_FORM,

  ORDER_TRANSFER_BACK_CALCULATE_TOTAL,
  ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS,

  RESET_TRANSFER_BACK_ITEMS_SELECTED,

  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE,
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS,
  ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS,
  ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING,

  ORDER_TRANSFER_BACK_DELETE_SUCCESS,
  ORDER_TRANSFER_BACK_DELETE_REQUEST
} = actions

const initState = new Map({
  formErrors: new Map(),
  items: new List(),
  lineItems: new List(),
  lineItemsLoading: false,
  loading: false,
  saving: false,
  showTransferBackForm: false,
  showShippingCostForm: false,
  transferBackId: null,
  calculatingTotal: false,
  shippingCostErrors: new Map(),
  documentLoading: false
})

export default function inquiryTransferBacksReducer (state = initState, action) {
  const result = action.result
  const items = state.get('items')

  switch (action.type) {
    case ORDER_TRANSFER_BACKS_FETCH:
      return state.merge({
        loading: true
      })
    case ORDER_TRANSFER_BACKS_FETCH_SUCCESS:
      return state.merge({
        loading: false,
        items: new List(result)
      })
    case ORDER_TRANSFER_BACK_FETCH:
      return state.merge({
        loading: true
      })
    case ORDER_TRANSFER_BACK_FETCH_SUCCESS:
      return state.merge({
        loading: false,
        items: new List(items.includes(result) ? items : items.push(result))
      })
    case ORDER_TRANSFER_BACK_FETCH_ITEMS:
      return state.merge({
        lineItemsLoading: true
      })
    case ORDER_TRANSFER_BACK_FETCH_ITEMS_SUCCESS:
      return state.merge({
        lineItemsLoading: false,
        lineItems: new List(result)
      })
    case ORDER_TRANSFER_BACK_SAVE:
      return state.merge({
        saving: true
      })
    case ORDER_TRANSFER_BACK_SAVE_SUCCESS:
      return state.merge({
        saving: false,
        items: new List(items.includes(result) ? items : items.push(result))
      })
    case ORDER_TRANSFER_BACK_SAVE_ERRORS:
      return state.merge({
        formErrors: new Map(action.formErrors),
        saving: false
      })
    case SET_INQUIRY_SHOW_TRANSFER_BACK_FORM:
      return state.merge({
        showTransferBackForm: action.value,
        transferBackId: action.id
      })
    case SET_INQUIRY_SHOW_TRANSFER_BACK_SHIPPING_COST_FORM:
      return state.merge({
        showShippingCostForm: action.value,
        transferBackId: action.id
      })
    case ORDER_TRANSFER_BACK_CALCULATE_TOTAL:
      return state.merge({
        calculatingTotal: true
      })
    case ORDER_TRANSFER_BACK_CALCULATE_TOTAL_SUCCESS:
      return state.merge({
        calculatingTotal: false,
        items: new List(items.includes(result) ? items : items.push(result))
      })
      case RESET_TRANSFER_BACK_ITEMS_SELECTED:
        return state.merge({
          lineItems: new List()
        })
      case ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE:
        return state.merge({
          saving: true
        })
      case ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_SUCCESS:
        return state.merge({
          saving: false
        })
      case ORDER_TRANSFER_BACK_SHIPPING_COST_SAVE_ERRORS:
        return state.merge({
          shippingCostErrors: new Map(action.formErrors),
          saving: false
        })
      case ORDER_TRANSFER_BACK_DOCUMENTS_DOWNLOAD_SET_LOADING:
          return state.merge({
              documentLoading: action.loading
        })
      case ORDER_TRANSFER_BACK_DELETE_SUCCESS:
        return state.merge({
          items: new List(items.delete(items.indexOf(result))),
          saving: false
        })
      case ORDER_TRANSFER_BACK_DELETE_REQUEST:
          return state.merge({
            saving: true
        })
    default:
      return state
  }
}
