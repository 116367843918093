import React, { Component, createRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { List as ImmutableList } from 'immutable'
import moment from 'moment'

import { Form, Row, Col, Input, Button, DatePicker, Upload, Select, Divider, Checkbox } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import withImagePreview from '../../ImagePreview/ImagePreviewContainer'
import Image from '../../Image'
import EditorContainer from '../../Editor/EditorContainer'
import AccountingWrapper from './accounting.style'

const { Option } = Select

const formItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 }
}

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        sm: { span: 14, offset: 10 }
    }
}

class PaymentVouchersForm extends Component {
    constructor (props) {
        super(props)

        const dateOfPurchase = props.paymentVoucher ? props.paymentVoucher.get('date_of_purchase') : null

        this.state = {
            creditDurationDays: 0,
            dateOfPurchaseMoment: (dateOfPurchase && moment(dateOfPurchase, 'YYYY-MM-DD')) || moment(new Date()),
            fileList: [],
            visibleCreditDurationDays: false
        }

        this.formRef = createRef()
    }

    componentDidMount () {
        const { paymentVoucher, entities } = this.props
        if (!paymentVoucher) return null

        const imageThumb = paymentVoucher.get('payment_voucher_images', new ImmutableList()).map((thumb) => {
            return {
                uid: thumb.get('id'),
                url: entities.getIn(['images', thumb.getIn(['file', 'm']), 'thumbBlob'])
            }
        })

        this.setState({
            creditDurationDays: paymentVoucher.get('credit_duration_days', 0),
            fileList: imageThumb.toJS(),
            visibleCreditDurationDays: paymentVoucher.get('has_credit', false)
        })
    }

    handleSubmit = (fieldsValue, id) => {
        const { onSubmitForm, entities } = this.props

        let file
        if (fieldsValue['image']) {
            const imageValue = fieldsValue['image']
            const paymentVoucherImages = entities.getIn(
                ['paymentVouchers', id, 'payment_voucher_images'],
                new ImmutableList()
            )
            let destroyImage = []
            let uploadImage = imageValue
            paymentVoucherImages.map((image) => {
                if (!imageValue.filter(value => value.uid === image.get('id')).length) {
                    destroyImage.push({ id: image.get('id'), _destroy: true })
                } else {
                    uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
                }
            })
            file = uploadImage ? (
                uploadImage.map((id) => {
                    return {
                        file: id.originFileObj,
                        _destroy: false
                    }
                }).concat(destroyImage)
            ) : null
        }
        const dateOfPurchase = fieldsValue['date_of_purchase'] ? (
            fieldsValue['date_of_purchase'].format('YYYY-MM-DD')
        ) : null
        const vatMonthUse = fieldsValue['vat_month_use'] ? (
            fieldsValue['vat_month_use'].format('YYYY-MM-DD')
        ) : null
        const values = {
            ...fieldsValue,
            date_of_purchase: dateOfPurchase,
            vat_month_use: vatMonthUse,
            payment_voucher_images_attributes: file
        }

        onSubmitForm({ id, params: values })
    }

    bankAccountsList = () => {
        const { entities, inquiry } = this.props

        return inquiry.get('bankAccountItems').map((bankAccountId) => {
            const bankAccount = entities.getIn(['bankAccounts', bankAccountId])
            return (
                <Option key={bankAccount.get('id')} value={bankAccountId}>
                    {bankAccount.get('full_name')}
                </Option>
            )
        })
    }

    imageHandler = (file, imgBase64) => {
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        this.formRef.current.setFieldsValue({ image: this.state.fileList.concat(newItemOfFileList) })
        this.setState({ fileList: this.state.fileList.concat(newItemOfFileList), File: newItemOfFileList })
    }

    handleChange = ({ fileList }) => {
        this.setState({ fileList })
    }

    normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text"> Upload </div>
        </div>
    )

    renderAmount () {
        const { i18n, isDisabled, formErrors, paymentVoucher } = this.props
        const amountErrors = formErrors.get('amount')
        const disabled = isDisabled ? !!paymentVoucher.size : false
        const reconciled = paymentVoucher.get('reconciled')

        return (
            <Form.Item {...formItemLayout}
                name='amount'
                initialValue={paymentVoucher.getIn(['amount', 'display'])}
                validateStatus={amountErrors ? 'error' : null}
                help={amountErrors && amountErrors.join(', ')}
                label={i18n.t('confirms:accounting.amount')}
                hasFeedback>
                    <Input className="text-right" disabled={disabled || reconciled} prefix="฿" placeholder="0.00" />
            </Form.Item>
        )
    }

    renderShippingCosts = () => {
        const { paymentVoucher } = this.props

        if (paymentVoucher.get('payment_type') !== 'logistic') { return null }

        const truckOriginAddresses = paymentVoucher.get('truck_origin_addresses')
        const truckOriginAddress = truckOriginAddresses ? truckOriginAddresses.map((truckOriginAddress) => {
            return (
                <div>
                    <div>{truckOriginAddress.get('name')} </div>
                    <div>{truckOriginAddress.get('detail')} </div>
                </div>
            )
        }) : null

        return (
            <div>
                <Divider>
                    {paymentVoucher.get('truck_owner_name') }
                </Divider>

                <Row>
                    <Col span={6}> คนตั้งจ่าย: </Col>
                    <Col span={18}> {paymentVoucher.get('who_dispatched')} </Col>
                </Row>
                <Row>
                    <Col span={6}> วันที่จองรถ: </Col>
                    <Col span={18}> {paymentVoucher.get('pickup_date')} </Col>
                </Row>
                <Row>
                    <Col span={6}> เจ้าของรถ: </Col>
                    <Col span={18}> {paymentVoucher.get('truck_owner_name')} </Col>
                </Row>
                <Row>
                    <Col span={6}> ทะเบียนรถ: </Col>
                    <Col span={18}> {paymentVoucher.get('license_plate_number')} </Col>
                </Row>

                <Row>
                    <Col span={6}> ที่อยู่เข้ารับ: </Col>
                    <Col span={18}>{truckOriginAddress}</Col>
                </Row>
            </div>
        )
    }

    renderSupplierOrder = () => {
        const { i18n, paymentVoucher, entities } = this.props
        const supplierOrders = paymentVoucher.get('supplier_orders')
        if (!supplierOrders || supplierOrders.size === 0) {
            return null
        } else {
            const supplierOrderDoms = supplierOrders.map((supplierOrderId) => {
                const supplierOrderEntity = entities.getIn(['supplierOrders', supplierOrderId])

                return (
                    <div key={supplierOrderEntity.get('ref_code')}>
                        <Divider>
                            { supplierOrderEntity.get('ref_code') }
                        </Divider>
                        <Row>
                            <Col span={6}> โรงงาน </Col>
                            <Col span={18}> {supplierOrderEntity.get('supplier_name')} </Col>
                        </Row>
                        <Row>
                            <Col span={6}> วันที่ต้องโอน </Col>
                            <Col span={18}> {supplierOrderEntity.get('transfer_date')} </Col>
                        </Row>
                        <Row>
                            <Col span={6}> ยอดรวม </Col>
                            <Col span={18}> {supplierOrderEntity.getIn(['grand_total', 'display'])} </Col>
                        </Row>
                        <Row>
                            <Col span={6}> คนตั้งเบิก </Col>
                            <Col span={18}> {supplierOrderEntity.getIn(['who_request_for_payment', 'name'])} </Col>
                        </Row>
                        <Row>
                            <Col span={6}>{i18n.t('confirms:paymentVoucher.purchaseOrder')}</Col>
                            <Col span={18} className={'image-container'}>
                                { this.renderSupplierOrderImages() }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>{i18n.t('confirms:paymentVoucher.note')}</Col>
                            <Col span={18}>{supplierOrderEntity.get('supplier_note')}</Col>
                        </Row>
                        { this.renderSupplierAccountingImages(supplierOrderEntity.get('supplier')) }
                    </div>
                )
            })
            return (<div>{supplierOrderDoms}</div>)
        }
    }

    renderSupplierOrderImages () {
        const { entities, paymentVoucher } = this.props
        const supplierOrderIds = paymentVoucher.get('supplier_orders')

        return supplierOrderIds.map((id) => {
            const supplierOrderImages = entities.getIn(['supplierOrders', id, 'supplier_order_images'])
            return supplierOrderImages.map((image) => {
                const thumbUrl = image.getIn(['file', 'm']) || ''
                const originalUrl = image.getIn(['file', 'original']) || ''
                const contentType = image.getIn(['file', 'content_type']) || ''

                return (
                    <Image
                        key={thumbUrl}
                        contentType={contentType}
                        originalUrl={originalUrl}
                        thumbUrl={thumbUrl}
                    />
                )
            })
        })
    }

    renderSupplierAccountingImages (supplierId) {
        if (!supplierId) { return null }

        const { entities, i18n } = this.props
        const supplierAccountingImages = entities.getIn([
            'suppliers', supplierId, 'supplier_accounting_images'
        ])

        if (!supplierAccountingImages || supplierAccountingImages.size === 0) { return null }

        const accountingImageList = supplierAccountingImages.map((image) => {
            const thumbUrl = image.getIn(['file', 'm']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            return (
                <Image
                    key={thumbUrl}
                    isImageBlob={false}
                    contentType={contentType}
                    originalUrl={originalUrl}
                    thumbUrl={thumbUrl}
                />
            )
        })

        return (
            <Row>
                <Col span={6}>{i18n.t('confirms:paymentVoucher.accountingImages')}</Col>
                <Col span={18} className={'image-container'}>
                    {accountingImageList}
                </Col>
            </Row>
        )
    }

    render () {
        const { i18n, isDisabled, orderId, saving, paymentVoucher } = this.props
        const { creditDurationDays, dateOfPurchaseMoment, visibleCreditDurationDays } = this.state

        if (!paymentVoucher) { return (null) }

        const paymentVoucherId = paymentVoucher.get('id')
        const paymentType = paymentVoucher.get('payment_type') || null
        const vatMonthUse = paymentVoucher.get('vat_month_use')
        const defaultVatMonthUse = vatMonthUse && moment(vatMonthUse, 'YYYY-MM-DD') || null
        const reconciled = paymentVoucher.get('reconciled')
        const transferDateHelp = dateOfPurchaseMoment && dateOfPurchaseMoment.clone().add('days', creditDurationDays).format('LL')

        return (
            <AccountingWrapper>
                <h3> {orderId} </h3>
                <Form labelAlign="left"
                    onFinish={(values) => this.handleSubmit(values, paymentVoucherId)}
                    ref={this.formRef}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name='date_of_purchase'
                                initialValue={dateOfPurchaseMoment}
                                label={visibleCreditDurationDays ? i18n.t('confirms:paymentVoucher.dateOfPurchase') : i18n.t('confirms:accounting.transferDate')}
                                {...formItemLayout}>
                                    <DatePicker
                                        placeholder={i18n.t('confirms:accounting.selectDate')}
                                        onChange={(dateMoment) => { this.setState({ dateOfPurchaseMoment: dateMoment }) }}
                                        style={{ width: '100%' }}
                                        disabled={reconciled}
                                    />
                            </Form.Item>

                            <Form.Item
                                {...formItemLayoutWithOutLabel}
                                name='has_credit'
                                initialValue={paymentVoucher.get('has_credit', false)}
                                valuePropName='checked'
                            >
                                    <Checkbox
                                        onChange={(e) => { this.setState({ visibleCreditDurationDays: e.target.checked }) }}
                                    >
                                        {i18n.t('confirms:paymentVoucher.hasCredit')}
                                    </Checkbox>
                            </Form.Item>

                            {visibleCreditDurationDays &&
                                <Form.Item
                                    name='credit_duration_days'
                                    initialValue={creditDurationDays}
                                    help={`${i18n.t('confirms:accounting.transferDate')}: ${transferDateHelp || ''}`}
                                    label={i18n.t('confirms:paymentVoucher.creditDuration')}
                                    {...formItemLayout}
                                >
                                        <Input
                                            onChange={(e) => { this.setState({ creditDurationDays: e.target.value }) }}
                                            suffix={i18n.t('confirms:paymentVoucher.days')}
                                            type="number"
                                        />
                                </Form.Item>
                            }

                            {this.renderAmount()}

                            {!isDisabled || isDisabled && paymentType !== 'adjust' && paymentType !== null ? (
                                <Form.Item {...formItemLayout}
                                    name='payment_type'
                                    initialValue={paymentType || 'other'}
                                    label={i18n.t('confirms:paymentVoucher.type')}>
                                        <Select allowClear placeholder={i18n.t('confirms:paymentVoucher.type')}>
                                            <Option key="0" value="product">Product</Option>
                                            <Option key="1" value="logistic">Logistic</Option>
                                            <Option key="2" value="transfer_back">Transfer Back</Option>
                                            <Option key="3" value="other">Other</Option>
                                            <Option key="4" value="credit_note">Credit Note</Option>
                                            <Option key="5" value="miscellaneous_transfer">Miscellaneous Transfer</Option>
                                            <Option key="7" value="credit_card">Credit Card</Option>
                                        </Select>
                                </Form.Item>
                            ) : null}


                            <Form.Item label={i18n.t('confirms:accounting.slip')} {...formItemLayout}
                                name='image'
                                getValueFromEvent={this.normFile}>
                                    <Upload.Dragger
                                        listType="picture-card"
                                        showUploadList={{ showPreviewIcon: false }}
                                        onChange={this.handleChange}
                                        beforeUpload={() => false}
                                        fileList={this.state.fileList}
                                        multiple>
                                        {this.uploadButton}
                                    </Upload.Dragger>
                            </Form.Item>

                            <Form.Item {...formItemLayoutWithOutLabel}>
                                <EditorContainer theme="snow"
                                    placeholder={i18n.t('confirms:accounting.pasteSlip')}
                                    imageHandler={this.imageHandler} />
                            </Form.Item>

                            <Form.Item {...formItemLayoutWithOutLabel}
                                name='is_paid'
                                valuePropName={'checked'}
                                initialValue={paymentVoucher.get('is_paid', true)}>
                                    <Checkbox>{i18n.t('confirms:paymentVoucher.isPaid')}</Checkbox>
                            </Form.Item>

                            <Form.Item {...formItemLayoutWithOutLabel}
                                name='having_vat'
                                valuePropName='checked'
                                initialValue={paymentVoucher.get('having_vat')}>
                                    <Checkbox>{i18n.t('confirms:paymentVoucher.havingVat')}</Checkbox>
                            </Form.Item>

                            <Form.Item {...formItemLayout}
                                name='with_holding_tax'
                                initialValue={paymentVoucher.get('with_holding_tax') || null}
                                label={i18n.t('confirms:paymentVoucher.withHoldingTax')}
                                hasFeedback>
                                    <Input placeholder="0.0" />
                            </Form.Item>

                            <Form.Item label={i18n.t('confirms:accounting.vatMonthUse')} {...formItemLayout}
                                name='vat_month_use'
                                initialValue={defaultVatMonthUse}>
                                    <DatePicker.MonthPicker style={{ width: '100%' }}
                                        placeholder={i18n.t('confirms:accounting.selectMonth')} />
                            </Form.Item>

                            <Form.Item
                                wrapperCol={{
                                    xs: { span: 24, offset: 0 },
                                    sm: { span: 16, offset: 8 }
                                }}>
                                <Button type="primary" htmlType="submit" loading={saving}>Submit</Button>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
                { this.renderSupplierOrder() }
                { this.renderShippingCosts() }
            </AccountingWrapper>
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    inquiry: state.get('inquiry'),
    orderPaymentDetails: state.get('orderPaymentDetails')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(
    withTranslation('common', 'confirms')(withImagePreview(PaymentVouchersForm))
)
