import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import OrderSupplierNoteForm from '../../components/orders/supplierNoteForm'

import OrderActions from '../../redux/orders/actions'
import imageActions from '../../redux/images/actions'

const { saveOrderSupplierNote } = OrderActions

const { addNewImage } = imageActions

class OrderSupplierNoteFormContainer extends Component {
    static defaultProps = {
        isShowSupplierOrders: false,
        onSuccess: () => {}
    }

    getOrderSupplierNote () {
        const { id, orderSupplierNoteEntities } = this.props

        if (!id) return new Map()

        return orderSupplierNoteEntities.get(id)
    }

    getOrderSupplierNoteImages = () => {
        const { addNewImage, orderSupplierNoteImageEntities } = this.props

        const orderSupplierNote = this.getOrderSupplierNote()
        const orderSupplierNoteImageIds = orderSupplierNote.get('images')
        let fileList = new List()
        if (!orderSupplierNoteImageIds) return []

        orderSupplierNoteImageIds.map((id) => {
            const image = orderSupplierNoteImageEntities.get(id)
            const imageId = image.get('id')
            const thumbUrl = image.getIn(['file', 'original']) || ''
            const originalUrl = image.getIn(['file', 'original']) || ''
            const contentType = image.getIn(['file', 'content_type']) || ''

            addNewImage(thumbUrl, originalUrl || '', contentType)

            fileList = fileList.push({
                uid: imageId,
                url: thumbUrl
            })
        })
        return fileList.toJS()
    }

    setImageParams (imageFiles) {
        const { orderSupplierNoteEntities, id } = this.props
        if (!imageFiles) { return }
        const imageIds = orderSupplierNoteEntities ? orderSupplierNoteEntities.getIn([id, 'images'], new List()) : []

        let destroyImage = []
        let uploadImage = imageFiles
        imageIds.map((imageId) => {
            if (!imageFiles.filter(value => value.uid === imageId).length) {
                destroyImage.push({ id: imageId, _destroy: true })
            } else {
                uploadImage = uploadImage.filter(value => value.uid !== imageId)
            }
        })

        return uploadImage.map((id) => {
            return {
                file: id.originFileObj,
                _destroy: false
            }
        }).concat(destroyImage)
    }

    onSubmit = (values) => {
        const { saveOrderSupplierNote, orderId, id, onSuccess } = this.props

        const imageParams = this.setImageParams(values.images_attributes)
        const body = values.body ? values.body.replace(/<[^>]*>?/gm, '') : ''

        const params = {
            ...values,
            body: body,
            images_attributes: imageParams
        }

        saveOrderSupplierNote(orderId, params, id, { onSuccess: onSuccess })
    }

    render () {
        const { orders, imageEntities, supplierOrders, isShowSupplierOrders } = this.props
        const orderSupplierNote = this.getOrderSupplierNote()
        const supplierOrderIds = orderSupplierNote.get('supplier_order_ids')
        const supplierOrderIdDefault = supplierOrderIds ? supplierOrderIds.toJS() : []

        return (
            <OrderSupplierNoteForm
                supplierOrderList={supplierOrders}
                isShowSupplierOrders={isShowSupplierOrders}
                orderSupplierNote={orderSupplierNote}
                supplierOrderIdDefault={supplierOrderIdDefault.map(String)}
                imageEntities={imageEntities}
                handleSubmit={this.onSubmit}
                imageDefault={this.getOrderSupplierNoteImages}
                saving={orders.get('orderSupplierNoteSaving')}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderSupplierNoteEntities: state.getIn(['Entities', 'orderSupplierNotes']),
        orderSupplierNoteImageEntities: state.getIn(['Entities', 'orderSupplierNoteImages']),
        imageEntities: state.getIn(['Entities', 'images']),
        orders: state.get('orders')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveOrderSupplierNote,
        addNewImage
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSupplierNoteFormContainer)
