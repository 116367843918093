import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button, Card, DatePicker, Form } from 'antd'
const { RangePicker } = DatePicker
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

const handleDownload = (e, props, form) => {
    const { onDownload } = props
    e.preventDefault()

    form.validateFields().then(fieldValue => {
        let startDate = null
        let endDate = null

        if (fieldValue['rangePicker']) {
            startDate = fieldValue['rangePicker'][0].format('YYYY-MM-DD')
            endDate = fieldValue['rangePicker'][1].format('YYYY-MM-DD')
        }

        onDownload(startDate, endDate)
    })
}

const renderCardHead = (i18n) => {
    return (
        <Card.Meta
            title={i18n.t('sales:downloadFile.downloadSalesReport')}
            description={i18n.t('sales:downloadFile.description')} />
    )
}

const ExportSalesReport = (props) => {
    const [form] = Form.useForm()
    const { salesReportLoading, i18n } = props

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    }
    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 }
    }

    return (
        <Card title={renderCardHead(i18n)}>
            <Form labelAlign="left"
                form={form}
                layout="inline"
                hideRequiredMark >
                <Form.Item key="search_by_date" label={i18n.t('sales:downloadFile.searchByDate')}
                    name='rangePicker'
                    rules={[{
                        required: true,
                        message: i18n.t('sales:downloadFile.pleaseInputDate')
                    }]}
                    {...formItemLayout} >
                        <RangePicker />
                </Form.Item>

                <Form.Item key="action_btn" {...buttonItemLayout}>
                    <Button icon={salesReportLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                        onClick={(e) => handleDownload(e, props, form)}
                        htmlType="submit" >
                        {i18n.t('sales:downloadFile.downloadSalesReport')}
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

ExportSalesReport.propTypes = {
    onDownload: PropTypes.func.isRequired,
    salesReportLoading: PropTypes.bool.isRequired
}

export default withTranslation('sales')(
    ExportSalesReport
)
