import React, { Component } from 'react'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import AlertForm from '../../components/notifications/Alert'
import AlertWrapper from '../../components/notifications/alert.style'

class AlertContainer extends Component {
    static propTypes = {
        notifications: ImmutablePropTypes.list.isRequired,
        typeAlert: PropTypes.oneOf(['success', 'info', 'warning', 'error'])
    }

    renderNotification (notification, index) {
        if (!notification) { return null }

        const { header, description, typeAlert } = notification

        return (
            <AlertForm
                key={index}
                message={header}
                description={description}
                type={typeAlert}
            />
        )
    }

    render () {
        const { notifications } = this.props

        return (
            <AlertWrapper>
                <div className="alert-fixed">
                    {notifications.map((notification, index) => {
                        return this.renderNotification(notification, index)
                    })}
                </div>
            </AlertWrapper>
        )
    }
}

const mapStateToProps = state => ({
    notifications: state.getIn(['notifications', 'notifications'])
})

export default connect(mapStateToProps)(AlertContainer)
