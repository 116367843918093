import React from 'react'
import { Modal, Spin, Table } from 'antd'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

const SALE_SUMMARY_QUERY = gql`
    query SaleSummary {
        saleSummary {
            id
            fullName
            salePipelinesCount
            saleSummary {
                incomingBalancePerDate
                prospectPerMonth
                prospectPerMonthCount
                allQuotationsPerMonth
                allQuotationsPerMonthCount
                incomingBalancePerMonth
                incomingBalancePerMonthCount
                transferBackAmount
                profit
            }
        }
    }
`

const DealSummary = ({ openModal, setOpenModal }) => {
    const { i18n } = useTranslation('sales', 'deals')

    const { loading, data, error } = useQuery(SALE_SUMMARY_QUERY)
    if (loading) {
        return <Spin />
    }

    const columns = [
        {
            title: i18n.t('deals:sale'),
            dataIndex: 'fullName',
            key: 'fullName'
        },
        {
            title: i18n.t('sales:salesSummary.incomingBalancePerDate'),
            dataIndex: ['saleSummary', 'incomingBalancePerDate'],
            sorter: (a, b) => {
                return (
                    parseInt(
                        a.saleSummary.incomingBalancePerDate.replaceAll(',', '')
                    ) -
                    parseInt(
                        b.saleSummary.incomingBalancePerDate.replaceAll(',', '')
                    )
                )
            },
            key: 'incomingBalancePerDate'
        },
        {
            title: i18n.t('sales:salesSummary.prospectPerMonth'),
            render: (record) => {
                return `${record.saleSummary.prospectPerMonth} (${record.saleSummary.prospectPerMonthCount})`
            }
        },
        {
            title: i18n.t('sales:salesSummary.allQuotationsPerMonth'),
            sorter: (a, b) => {
                return (
                    parseInt(
                        a.saleSummary.allQuotationsPerMonth.replaceAll(',', '')
                    ) -
                    parseInt(
                        b.saleSummary.allQuotationsPerMonth.replaceAll(',', '')
                    )
                )
            },
            render: (record) => {
                return `${record.saleSummary.allQuotationsPerMonth} (${record.saleSummary.allQuotationsPerMonthCount})`
            }
        },
        {
            title: i18n.t('sales:salesSummary.incomingBalancePerMonth'),
            defaultSortOrder: 'descend',
            sorter: (a, b) => {
                return (
                    parseInt(
                        a.saleSummary.incomingBalancePerMonth.replaceAll(
                            ',',
                            ''
                        )
                    ) -
                    parseInt(
                        b.saleSummary.incomingBalancePerMonth.replaceAll(
                            ',',
                            ''
                        )
                    )
                )
            },
            render: (record) => {
                return `${record.saleSummary.incomingBalancePerMonth} (${record.saleSummary.incomingBalancePerMonthCount})`
            }
        },
        {
            title: i18n.t('sales:salesSummary.profit'),
            dataIndex: ['saleSummary', 'profit'],
            sorter: (a, b) => {
                return (
                    parseInt(a.saleSummary.profit.replaceAll(',', '')) -
                    parseInt(b.saleSummary.profit.replaceAll(',', ''))
                )
            },
            key: 'profit'
        },
        {
            title: i18n.t('sales:salesSummary.salePipeline'),
            dataIndex: 'salePipelinesCount',
            sorter: (a, b) => {
                return a.salePipelinesCount - b.salePipelinesCount
            },
            key: 'sale-pipeline'
        }
    ]

    return (
        <div>
            <Modal
                destroyOnClose
                footer={false}
                width={'80%'}
                closable={true}
                centered
                onCancel={() => setOpenModal(!openModal)}
                visible={openModal}
                title={i18n.t('sales:salesSummary.summary')}>
                <Table
                    dataSource={data.saleSummary}
                    columns={columns}
                    pagination={false}
                />
            </Modal>
        </div>
    )
}

export default DealSummary
