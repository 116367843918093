import { Map, List } from 'immutable'

import actions from './actions'
import bankStatementAction from '../bankStatements/actions'
import { PAGE_SIZE, DEFAULT_CURRENT_PAGE } from '../../constants/orders'

const {
    FETCH_PAYMENT_VOUCHERS_SUCCESS,
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS,
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE,
    PAYMENT_VOUCHERS_FETCHING,
    PAYMENT_VOUCHERS_SET_FILTER_DATE,
    PAYMENT_VOUCHERS_SET_PAGINATION,
    SET_PAYMENT_VOUCHERS_INVOICE_FORM,
    SET_PAYMENT_VOUCHERS_NOTE_FORM,
    SET_SUPPLIER_ORDERS_REF_CODE,
    SUPPLIER_INVOICE_DELETE_SUCCESS,
    SUPPLIER_INVOICE_SAVE_SUCCESS,
    SUPPLIER_INVOICE_SAVING,
    FETCH_SUPPLIER_ORDERS_FILE,
    FETCH_SUPPLIER_ORDERS_FILE_SUCCESS,
    FETCH_SUPPLIER_LINE_ITEMS_FILE,
    FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS,
    FETCH_INVOICE_1688_FILE,
    FETCH_INVOICE_1688_FILE_SUCCESS
} = actions

const { PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS } = bankStatementAction

const initState = new Map({
    filterDate: '',
    isOpenInvoiceForm: false,
    isOpenNoteForm: false,
    items: new List(),
    loading: false,
    pagination: new Map({
        current: DEFAULT_CURRENT_PAGE,
        pageSize: PAGE_SIZE,
        total: PAGE_SIZE
    }),
    saving: false,
    supplierInvoice: new List(),
    supplierInvoiceSaving: false,
    supplierOrderRefCode: new List(),
    transferBackPVLoading: false,
    supplierOrdersLoading: false,
    supplierLineItemsLoading: false,
    invoice1688Loading: false
})

export default function vatDocumentsReducer (state = initState, action) {
    const supplierInvoiceItems = state.get('supplierInvoice')
    const result = action.result

    switch (action.type) {
        case PAYMENT_VOUCHERS_FETCHING:
            return state.merge({
                loading: true
            })
        case FETCH_PAYMENT_VOUCHERS_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(action.result)
            })
        case PAYMENT_VOUCHERS_SET_FILTER_DATE:
            return state.merge({ filterDate: action.fieldname })
        case PAYMENT_VOUCHERS_SET_PAGINATION:
            return state.mergeDeep({ pagination: action.pagination })
        case SET_PAYMENT_VOUCHERS_INVOICE_FORM:
            return state.merge({
                isOpenInvoiceForm: action.value,
                supplierOrderRefCode: []
            })
        case SUPPLIER_INVOICE_SAVE_SUCCESS:
            return state.merge({
                supplierInvoiceSaving: false,
                supplierInvoice: supplierInvoiceItems.includes(result) ?
                    supplierInvoiceItems : supplierInvoiceItems.push(result)
            })
        case SUPPLIER_INVOICE_SAVING:
            return state.merge({
                supplierInvoiceSaving: true
            })
        case SET_PAYMENT_VOUCHERS_NOTE_FORM:
            return state.merge({
                isOpenNoteForm: action.value
            })
        case SUPPLIER_INVOICE_DELETE_SUCCESS:
            return state.merge({
                supplierInvoice: supplierInvoiceItems.delete(supplierInvoiceItems.indexOf(result))
            })
        case SET_SUPPLIER_ORDERS_REF_CODE:
            return state.merge({
                supplierOrderRefCode: action.amount
            })
        case FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE:
            return state.merge({
                transferBackPVLoading: true
            })
        case FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS:
            return state.merge({
                transferBackPVLoading: false
            })
        case PAYMENT_VOUCHER_QUERY_FETCH_SUCCESS:
            return state.merge({
                items: new List(action.result)
            })
        case FETCH_SUPPLIER_ORDERS_FILE:
            return state.merge({
                supplierOrdersLoading: true
            })
        case FETCH_SUPPLIER_ORDERS_FILE_SUCCESS:
            return state.merge({
                supplierOrdersLoading: false
            })
        case FETCH_SUPPLIER_LINE_ITEMS_FILE:
            return state.merge({
                supplierLineItemsLoading: true
            })
        case FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS:
            return state.merge({
                supplierLineItemsLoading: false
            })
        case FETCH_INVOICE_1688_FILE:
            return state.merge({
                invoice1688Loading: true
            })
        case FETCH_INVOICE_1688_FILE_SUCCESS:
            return state.merge({
                invoice1688Loading: false
            })
        default:
            return state
    }
}
