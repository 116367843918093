import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Form, DatePicker, Button, Card, Spin, Select } from 'antd'

import supplierActions from '../../redux/suppliers/actions'
import vatDocumentActions from '../../redux/vatDocuments/actions'
import {DownloadOutlined, LoadingOutlined} from '@ant-design/icons'

const { searchSupplier, setSelectedSupplier } = supplierActions
const { fetchSupplierOrdersReport } = vatDocumentActions
const { RangePicker } = DatePicker
const { Option } = Select

class ExportSupplierOrdersReport extends Component {
    constructor (props) {
        super(props)

        this.formRef = createRef()
    }

    handleSubmit = (e) => {
        const { fetchSupplierOrdersReport } = this.props
        e.preventDefault()

        this.formRef.current.validateFields().then(fieldValue => {
            let startDate = null
            let endDate = null
            if (fieldValue['range_picker']) {
                startDate = fieldValue['range_picker'][0].format('YYYY-MM-DD')
                endDate = fieldValue['range_picker'][1].format('YYYY-MM-DD')
            }

            const values = {
                start_date: startDate,
                end_date: endDate,
                supplier_id: fieldValue['supplier_id']
            }

            fetchSupplierOrdersReport(values)
        })
    }

    onSearchSupplier = (query) => {
        const { searchSupplier } = this.props

        searchSupplier({ query })
    }

    onSelectSupplier = (value) => {
        const { setSelectedSupplier } = this.props

        setSelectedSupplier(value)
    }

    suppliersResult = () => {
        const { supplierEntities, suppliers } = this.props

        return suppliers.get('supplierItems').map((supplierId) => {
            const supplier = supplierEntities.get(supplierId)
            const accountName = supplier && supplier.get('display_name')
            return (
                <Option key={supplierId} value={supplierId}> {accountName} </Option>
            )
        })
    }

    renderCardHead = () => {
        const { i18n } = this.props

        return (
            <Card.Meta
                title={i18n.t('documents:supplierOrdersReport.download')}
                description={i18n.t('documents:supplierOrdersReport.description')} />
        )
    }

    render () {
        const { vatDocuments, i18n, suppliers } = this.props
        const loading = vatDocuments.get('supplierOrdersLoading')
        const supplierId = suppliers.get('supplierId') || undefined

        const formItemLayout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        }
        const buttonItemLayout = {
            wrapperCol: { span: 14, offset: 4 }
        }
        return (
            <Card title={this.renderCardHead()}
                style={{ margin: '20px 0' }}>
                <Form layout="inline"
                    labelAlign="left"
                    ref={this.formRef}
                    hideRequiredMark>
                    <Form.Item key="search_by_date"label={i18n.t('documents:searchByDate')}
                        name='range_picker'
                        rules={[{
                            required: true,
                            message: i18n.t('documents:pleaseInputDate')
                        }]}
                        {...formItemLayout}>
                            <RangePicker />
                    </Form.Item>
                    <Form.Item key="supplier-select" label={i18n.t('suppliers:searchSupplier')}
                        name='supplier_id'
                        initialValue={supplierId}>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                defaultActiveFirstOption={false}
                                key='select_supplier'
                                showArrow={false}
                                filterOption={false}
                                placeholder={i18n.t('suppliers:selectSupplierName')}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                                onSearch={(value) => this.onSearchSupplier(value)}
                                onChange={this.onSelectSupplier}>
                                {this.suppliersResult()}
                            </Select>
                    </Form.Item>
                    <Form.Item key="action_btn" {...buttonItemLayout}>
                        <Button
                            icon={loading ? <LoadingOutlined /> : <DownloadOutlined />}
                            onClick={this.handleSubmit}
                            htmlType="submit">
                            {i18n.t('documents:supplierOrdersReport.download')}
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}

const mapStateToProps = state => ({
    vatDocuments: state.get('vatDocuments'),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupplierOrdersReport,
        searchSupplier,
        setSelectedSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['documents', 'suppliers'])(ExportSupplierOrdersReport))
