import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Select } from 'antd'

import { withTranslation } from 'react-i18next'

import SearchSale from '../../../containers/SearchSale'
import RangePickerWithDateType from '../../../containers/RangePickerWithDateType'

const OrderYieldListFilters = ({
    i18n,
    dateRangePickerValue,
    isCompleteYet,
    isRvEqualToInvoices,
    onChangeDateRangePicker,
    onChangeIsCompleteYet,
    onChangeIsRvEqualToInvoices,
    onChangeSale,
    saleId
}) => {
    return (
        <Row type="flex" align="middle" gutter={[16, 16]}>
            <Col xs={24} md={7}>
                <Row type="flex" align="middle" gutter={[8, 0]}>
                    <Col xs={8} style={{ textAlign: 'right' }}>
                        {i18n.t('order.sale')} :
                    </Col>

                    <Col xs={16}>
                        <SearchSale
                            onChange={onChangeSale}
                            value={saleId}
                        />
                    </Col>
                </Row>
            </Col>

            <Col xs={24} md={10}>
                <RangePickerWithDateType
                    dateTypes={[{
                        label: i18n.t('inquiries:truckingReservationDate'),
                        value: 'truck_reservation_date'
                    }]}
                    onChange={onChangeDateRangePicker}
                    value={dateRangePickerValue}
                />
            </Col>

            <Col xs={24} md={7}>
                <Row type="flex" align="middle" gutter={[8, 0]}>
                    <Col xs={8} style={{ textAlign: 'right' }}>
                        {i18n.t('orders/yields:isCompleteYet')} :
                    </Col>

                    <Col xs={16}>
                        <Select
                            allowClear
                            onChange={onChangeIsCompleteYet}
                            placeholder={i18n.t('orders/yields:isCompleteYet')}
                            style={{ width: '100%' }}
                            value={isCompleteYet || undefined}
                        >
                            <Select.Option key="yes" value={'true'}>{i18n.t('yes')}</Select.Option>
                            <Select.Option key="no" value={'false'}>{i18n.t('no')}</Select.Option>
                        </Select>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} md={10}>
                <Row type="flex" align="middle" gutter={[8, 0]}>
                    <Col xs={12} style={{ textAlign: 'right' }}>
                        {i18n.t('orders/yields:isRvEqualToInvoices')} :
                    </Col>

                    <Col xs={12}>
                        <Select
                            allowClear
                            onChange={onChangeIsRvEqualToInvoices}
                            placeholder={i18n.t('orders/yields:isRvEqualToInvoices')}
                            style={{ width: '100%' }}
                            value={isRvEqualToInvoices || undefined}
                        >
                            <Select.Option key="yes" value={'true'}>{i18n.t('yes')}</Select.Option>
                            <Select.Option key="no" value={'false'}>{i18n.t('no')}</Select.Option>
                        </Select>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

OrderYieldListFilters.propTypes = {
    dateRangePickerValue: PropTypes.shape({
        date_type: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string
    }),
    isCompleteYet: PropTypes.string,
    onChangeDateRangePicker: PropTypes.func,
    onChangeIsCompleteYet: PropTypes.func,
    onChangeIsRvEqualToInvoices: PropTypes.func,
    onChangeSale: PropTypes.func,
    saleId: PropTypes.string
}

OrderYieldListFilters.defaultProps = {
    dateRangePickerValue: {
        date_type: 'truck_reservation_date',
        start_date: '',
        end_date: ''
    },
    isCompleteYet: '',
    isRvEqualToInvoices: '',
    onChangeDateRangePicker: () => {},
    onChangeIsCompleteYet: () => {},
    onChangeIsRvEqualToInvoices: () => {},
    onChangeSale: () => {},
    saleId: ''
}

export default withTranslation(['common', 'inquiries'])(OrderYieldListFilters)
