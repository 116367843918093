import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CompanyProjectForm from '../../../components/projects/companyProjectForm'

import actions from '../../../redux/customers/companyProjects/actions'

const { saveCustomerCompanyProject } = actions

class CustomerCompanyProjectFormContainer extends Component {
  static propTypes = {
      companyProject: PropTypes.object,
      id: PropTypes.string,
      onSuccess: PropTypes.func
  }

  static defaultProps = {
      companyProject: {},
      onSuccess: () => {},
      onError: () => {}
  }

  handleSaveCompanyProject = (params) => {
      const { customerId, id, onSuccess, onError, saveCustomerCompanyProject } = this.props

      saveCustomerCompanyProject({ customerId, id, params, onSuccess, onError })
  }


  render () {
      const { companyProject, hideSelectCompany, projects, toggleForm } = this.props

      return (
          <CompanyProjectForm
              hideSelectCompany={hideSelectCompany}
              formErrors={projects.get('errors').toJS()}
              companyProject={companyProject}
              toggleForm={toggleForm}
              onSubmitForm={this.handleSaveCompanyProject}
          />
      )
  }
}

const mapStateToProps = (state) => ({
    projects: state.get('customerCompanyProjects')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        saveCustomerCompanyProject
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCompanyProjectFormContainer)
