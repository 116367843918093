import { all, takeLatest, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import {
    apiFetch,
    apiPostForm,
    apiFileFetch
} from '../../../helpers/restRequest'
import i18n from 'i18next'
import moment from 'moment'

import {
    invoiceAdjustmentSchema,
    invoiceAdjustmentsSchema,
    invoiceEmailHistoriesSchema,
    orderBusinessAddressesSchema,
    orderBusinessAddressSchema,
    paymentDetailSchema,
    paymentDetailsSchema,
    paymentVoucherSchema,
    paymentVouchersSchema
    // taxInvoiceInfoSchema
} from '../../schema'
import actions from './actions'
import actionsOrderState from '../../inquiry/orderStates/actions'
import { PAGE_SIZE } from '../../../constants/orders'
import actionsNotification from '../../../redux/notifications/actions'
import inquiryActions from '../../inquiry/actions'

import downLoadFile from '../../../helpers/downLoadFile'

const { setNotifications } = actionsNotification
const { saveOrderState, setShowPaymentForm } = actionsOrderState

const {
    BANK_RECONCILE_PRODUCT_SET_UPLOADING,
    BANK_RECONCILE_PRODUCT_UPLOAD_REQUEST,
    BANK_RECONCILE_TRUCK_SET_UPLOADING,
    BANK_RECONCILE_TRUCK_UPLOAD_REQUEST,
    DELETE_INVOICE_ADJUSTMENT_REQUEST,
    DELETE_INVOICE_ADJUSTMENT_SUCCESS,
    DELETE_INVOICE_ADJUSTMENT,
    DELETE_PAYMENT_DETAILS_REQUEST,
    DELETE_PAYMENT_DETAILS_SUCCESS,
    DELETE_PAYMENT_DETAILS,
    FETCH_INVOICE_ADJUSTMENT_REQUEST,
    FETCH_INVOICE_ADJUSTMENT_SUCCESS,
    FETCH_INVOICE_ADJUSTMENT,
    FETCH_INVOICE_ADJUSTMENTS_REQUEST,
    FETCH_INVOICE_ADJUSTMENTS_SUCCESS,
    FETCH_INVOICE_ADJUSTMENTS,
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF_REQUEST,
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS,
    FETCH_INVOICE_DELIVERY_ADDRESS_PDF,
    FETCH_ORDER_BUSINESS_ADDRESS_REQUEST,
    FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS,
    FETCH_ORDER_BUSINESS_ADDRESSES_REQUEST,
    FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS,
    FETCH_ORDER_BUSINESS_ADDRESSES,
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES_REQUEST,
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS,
    FETCH_ORDER_INVOICE_EMAIL_HISTORIES,
    FETCH_PAYMENT_VOUCHERS_FILE_REQUEST,
    FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS,
    FETCH_PAYMENT_VOUCHERS_FILE,
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_REQUEST,
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_SUCCESS,
    FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE,
    FETCH_RV_REPORT_FILE_REQUEST,
    FETCH_RV_REPORT_FILE_SUCCESS,
    FETCH_RV_REPORT_FILE,
    FETCH_SALE_TAX_INVOICE_REPORT_FILE_REQUEST,
    FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS,
    FETCH_SALE_TAX_INVOICE_REPORT_FILE,
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_REQUEST,
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS,
    FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE,
    // FETCH_TAX_INVOICE_INFO_REQUEST,
    // FETCH_TAX_INVOICE_INFO_SUCCESS,
    // FETCH_TAX_INVOICE_INFO,
    FETCH_TAX_INVOICE_PDF_REQUEST,
    FETCH_TAX_INVOICE_PDF_SUCCESS,
    FETCH_TAX_INVOICE_PDF,
    FETCH_TAX_INVOICE_REPORT_FILE_REQUEST,
    FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS,
    FETCH_TAX_INVOICE_REPORT_FILE,
    FETCH_TEMPORARY_RECEIPT_PDF_REQUEST,
    FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS,
    FETCH_TEMPORARY_RECEIPT_PDF,
    FETCH_VAT_DOCUMENT_FILE_REQUEST,
    FETCH_VAT_DOCUMENT_FILE_SUCCESS,
    FETCH_VAT_DOCUMENT_FILE,
    FETCH_YIELD_REPORT_FILE_REQUEST,
    FETCH_YIELD_REPORT_FILE_SUCCESS,
    FETCH_YIELD_REPORT_FILE,
    fetchPaymentDetails,
    INVOICE_ADJUSTMENT_SET_FORM_ERRORS,
    INVOICE_EMS_TRACKING_IMPORT_FILE_REQUEST,
    INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING,
    ORDER_BUSINESSES_DELETE_REQUEST,
    ORDER_BUSINESSES_DELETE_SUCCESS,
    ORDER_BUSINESSES_SAVE_REQUEST,
    ORDER_BUSINESSES_SAVE_SUCCESS,
    ORDER_BUSINESSES_SET_SAVING,
    PAYMENT_DETAILS_FETCH_REQUEST,
    PAYMENT_DETAILS_FETCH_SUCCESS,
    PAYMENT_DETAILS_FETCH,
    PAYMENT_DETAILS_SET_FORM_ERRORS,
    PAYMENT_DETAILS_SET_PAGINATION,
    PAYMENT_VOUCHER_DELETE_REQUEST,
    PAYMENT_VOUCHER_DELETE_SUCCESS,
    PAYMENT_VOUCHER_DELETE,
    PAYMENT_VOUCHER_IMPORT_FILE_REQUEST,
    PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING,
    ORDER_FETCH_PAYMENT_VOUCHERS_REQUEST,
    RECEIPT_VOUCHERS_IMPORT_FILE_REQUEST,
    RECEIPT_VOUCHERS_IMPORT_FILE_UPLOADING,
    SAVE_INVOICE_ADJUSTMENT_REQUEST,
    SAVE_INVOICE_ADJUSTMENT_SUCCESS,
    SAVE_INVOICE_ADJUSTMENT,
    SAVE_PAYMENT_DETAILS_REQUEST,
    SAVE_PAYMENT_DETAILS_SUCCESS,
    SAVE_PAYMENT_DETAILS,
    SAVE_PAYMENT_VOUCHER_REQUEST,
    SAVING_PAYMENT_VOUCHER,
    // SEND_TAX_INVOICE_EMAIL_REQUEST,
    // SEND_TAX_INVOICE_EMAIL_SUCCESS,
    // SEND_TAX_INVOICE_EMAIL,
    SET_SHOW_PAYMENT_DETAIL,
    TAX_INVOICE_IMPORT_FILE_REQUEST,
    TAX_INVOICE_IMPORT_FILE_UPLOADING,
    TRANSFER_BACK_FETCH_REQUEST,
    TRANSFER_BACK_FETCH_SUCCESS,
    TRANSFER_BACK_FETCH,
    DOWNLOAD_INVOICE_ADJUSTMENT_REQUEST,
    DOWNLOAD_INVOICE_ADJUSTMENT,
    DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS,
    SEND_E_TAX_REQUEST,
    SEND_E_TAX_REQUESTING,
    SEND_E_TAX_SUCCESS,
    SEND_ELECTRONIC_CREDIT_NOTE_REQUEST,
    SEND_ELECTRONIC_CREDIT_NOTE,
    SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS,
    SEND_E_TAX_MAIL_TO_CUSTOMER_REQUEST,
    SEND_E_TAX_MAIL_TO_CUSTOMER,
    SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS,
    FETCH_DEBIT_NOTE_PURPOSES_REQUEST,
    FETCH_DEBIT_NOTE_PURPOSES,
    FETCH_DEBIT_NOTE_PURPOSES_SUCCESS,
    FETCH_CREDIT_NOTE_PURPOSES_REQUEST,
    FETCH_CREDIT_NOTE_PURPOSES,
    FETCH_CREDIT_NOTE_PURPOSES_SUCCESS
} = actions

const apiPaymentDetailPath = (orderId, id) => {
    const basePath = `/orders/${orderId}/payment_details`

    if (!id) {
        return basePath
    }
    return `${basePath}/${id}`
}

const apiPaymentVoucherPath = (orderId, id) => {
    const basePath = `/orders/${orderId}/payment_vouchers`

    if (!id) {
        return basePath
    }
    return `${basePath}/${id}`
}

export function* fetchPaymentVouchers() {
    yield takeEvery(
        actions.ORDER_FETCH_PAYMENT_VOUCHERS_REQUEST,
        function* ({ payload }) {
            yield put({ type: actions.ORDER_FETCH_PAYMENT_VOUCHERS })

            const orderId = payload.orderId

            const path = `/orders/${orderId}/payment_vouchers`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(
                formattedData,
                paymentVouchersSchema
            )

            yield put({
                type: actions.ORDER_FETCH_PAYMENT_VOUCHERS_SUCCESS,
                ...normalizeData
            })
        }
    )
}

export function* fetchTransferBack() {
    yield takeEvery(TRANSFER_BACK_FETCH_REQUEST, function* ({ payload }) {
        yield put({ type: TRANSFER_BACK_FETCH })

        const { orderId } = payload

        const path = `/orders/${orderId}/payment_vouchers?transfer_back=true`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, paymentVouchersSchema)

        yield put({
            type: TRANSFER_BACK_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function* fetchPaymentDetail() {
    yield takeEvery(PAYMENT_DETAILS_FETCH_REQUEST, function* ({ payload }) {
        yield put({ type: PAYMENT_DETAILS_FETCH })

        const id = payload.id
        const page = payload.page

        const path = `/orders/${id}/payment_details?page=${page}&per=${PAGE_SIZE}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, paymentDetailsSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: PAYMENT_DETAILS_SET_PAGINATION,
            pagination: { current: page, defaultPageSize: PAGE_SIZE, total }
        })

        yield put({
            type: PAYMENT_DETAILS_FETCH_SUCCESS,
            ...normalizeData,
            invoiceNumberlist: data.meta.invoice_number_list
        })
    })
}

export function* savePaymentVoucher() {
    yield takeLatest(SAVE_PAYMENT_VOUCHER_REQUEST, function* ({ payload }) {
        yield put({ type: SAVING_PAYMENT_VOUCHER, saving: true })
        const { orderId, value, id, onSuccess } = payload
        const method = id ? 'PATCH' : 'POST'
        const path = apiPaymentVoucherPath(orderId, id)
        const body = { payment_voucher: value }

        try {
            const data = yield call(apiPostForm, path, method, body)
            if ('error' in data) {
                yield put({
                    type: actions.PAYMENT_VOUCHER_SET_FORM_ERRORS,
                    paymentVoucherFormErrors: data.error_description
                })
            } else {
                yield put(inquiryActions.fetchInquiry(orderId))
                yield put({ type: actions.SAVE_PAYMENT_VOUCHER_SUCCESS })
                onSuccess()
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({ type: SAVING_PAYMENT_VOUCHER, saving: false })
        }
    })
}

export function* savePaymentDetail() {
    yield takeLatest(SAVE_PAYMENT_DETAILS_REQUEST, function* ({ payload }) {
        yield put({ type: SAVE_PAYMENT_DETAILS })
        const { orderId, id, value } = payload
        const path = apiPaymentDetailPath(orderId, id)
        const method = id ? 'PATCH' : 'POST'
        const body = { order_payment_detail: value }

        try {
            const data = yield call(
                apiPostForm,
                path,
                method,
                body,
                [],
                ['order_business_address_ids']
            )
            if ('error' in data) {
                yield put({
                    type: PAYMENT_DETAILS_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                if (method === 'PATCH') {
                    const formattedData = deserialize(data)
                    yield put({
                        type: SAVE_PAYMENT_DETAILS_SUCCESS,
                        ...normalize(formattedData, paymentDetailSchema)
                    })
                } else if (payload.path === 'inquiries') {
                    yield put(saveOrderState(orderId, 'payment'))
                    yield put(setShowPaymentForm(false))
                }
                yield put(fetchPaymentDetails(1, orderId))
                yield put(inquiryActions.fetchInquiry(orderId))
                yield put({
                    type: SET_SHOW_PAYMENT_DETAIL,
                    value: false
                })
                const formErrors = []
                yield put({
                    type: actions.PAYMENT_DETAILS_SET_FORM_ERRORS,
                    formErrors
                })
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        }
    })
}

export function* deletePaymentDetail() {
    yield takeLatest(DELETE_PAYMENT_DETAILS_REQUEST, function* ({ payload }) {
        yield put({ type: DELETE_PAYMENT_DETAILS })

        const orderId = payload.orderId
        const id = payload.id
        const path = `/orders/${orderId}/payment_details/${id}`
        const method = 'DELETE'

        try {
            yield call(apiFetch, path, method)

            yield put({
                type: SET_SHOW_PAYMENT_DETAIL,
                value: false
            })
            yield put({
                type: DELETE_PAYMENT_DETAILS_SUCCESS
            })
            const len = payload.orderItems.items.length
            const current = payload.orderItems.pagination.current
            if (len <= PAGE_SIZE || current === 1) {
                yield put(inquiryActions.fetchInquiry(orderId))
                yield put(fetchPaymentDetails(current, orderId))
            } else {
                yield put(inquiryActions.fetchInquiry(orderId))
                yield put(fetchPaymentDetails(current - 1, orderId))
            }
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        }
    })
}

export function* receivePaymentVouchersFile() {
    yield takeLatest(
        FETCH_PAYMENT_VOUCHERS_FILE_REQUEST,
        function* ({ payload }) {
            const { value } = payload
            const { supplier_id } = value
            yield put({ type: FETCH_PAYMENT_VOUCHERS_FILE })
            const supplierId = supplier_id ? supplier_id : ''
            const fileExtension = 'xlsx'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const queryString = `?${startDate}&${endDate}&supplier_id=${supplierId}`
            const path = `/orders/payment_exels.${fileExtension}${queryString}`

            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `payment-vouchers-${value.start_date}-to-${value.end_date}.${fileExtension}`
            })

            yield put({
                type: FETCH_PAYMENT_VOUCHERS_FILE_SUCCESS
            })
        }
    )
}

export function* receiveTaxInvoicePdf() {
    yield takeLatest(FETCH_TAX_INVOICE_PDF_REQUEST, function* ({ payload }) {
        yield put({ type: FETCH_TAX_INVOICE_PDF })
        const { orderId, id, taxInvoiceType, locale, isSample } = payload

        const type = `tax_invoice_type=${taxInvoiceType}`
        const sample = `is_sample=${isSample}`
        const path = `/orders/${orderId}/business_addresses/${id}/tax_invoice_documents?locale=${locale}&${type}&${sample}`
        const data = yield call(apiFileFetch, path)

        const fileURL = URL.createObjectURL(data)
        window.open(fileURL)

        yield put({
            type: FETCH_TAX_INVOICE_PDF_SUCCESS
        })
    })
}

export function* receiveYieldReportFile() {
    yield takeLatest(FETCH_YIELD_REPORT_FILE_REQUEST, function* ({ payload }) {
        const { value } = payload
        yield put({ type: FETCH_YIELD_REPORT_FILE })

        const fileExtension = 'xlsx'
        const startDate = `start_date=${value['start_date']}`
        const endDate = `end_date=${value['end_date']}`
        const filterPaymentDate = `filter_payment_date=${value['filter_payment_date']}`
        const queryString = `?${startDate}&${endDate}&${filterPaymentDate}`
        const path = `/orders/yields/exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `yield-report-${value.start_date}-to-${value.end_date}.${fileExtension}`
        })

        yield put({
            type: FETCH_YIELD_REPORT_FILE_SUCCESS
        })
    })
}

export function* receiveRvAndPvAdjustedReportFile() {
    yield takeLatest(
        FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_REQUEST,
        function* ({ payload }) {
            const { value } = payload
            yield put({ type: FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE })

            const fileExtension = 'xlsx'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const queryString = `?${startDate}&${endDate}`
            const path = `/orders/pv_rv_adjusted_exports.${fileExtension}${queryString}`
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `pv-rv-adjusted-report-${value.start_date}-to-${value.end_date}.${fileExtension}`
            })

            yield put({
                type: FETCH_RV_AND_PV_ADJUSTED_REPORT_FILE_SUCCESS
            })
        }
    )
}

export function* receiveRvReportFile() {
    yield takeLatest(FETCH_RV_REPORT_FILE_REQUEST, function* ({ payload }) {
        const { value } = payload
        yield put({ type: FETCH_RV_REPORT_FILE })

        const startDate = `start_date=${value['start_date']}`
        const endDate = `end_date=${value['end_date']}`
        const queryString = `?${startDate}&${endDate}`
        const path = `/orders/payment_details/exports${queryString}`

        yield call(apiFileFetch, path)
        i18n.loadNamespaces('confirms')

        try {
            yield put(
                setNotifications(
                    `${i18n.t('confirms:accounting.exportRvSuccess')}`,
                    i18n.t('confirms:accounting.exportRvSuccessFully'),
                    'success'
                )
            )

            yield put({
                type: FETCH_RV_REPORT_FILE_SUCCESS
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({ type: FETCH_RV_REPORT_FILE_REQUEST, saving: false })
        }
    })
}

// export function *fetchTaxInvoiceInfo () {
//     yield takeEvery(FETCH_TAX_INVOICE_INFO_REQUEST, function *({ payload }) {
//         yield put({ type: FETCH_TAX_INVOICE_INFO, taxInvoiceInfoLoading: true })
//         const orderId = payload.orderId

//         try {
//             const path = `/orders/${orderId}/tax_invoice_info`
//             const data = yield call(apiFetch, path)
//             const formattedData = deserialize(data)
//             const normalizeData = normalize(formattedData, taxInvoiceInfoSchema)

//             yield put({
//                 type: FETCH_TAX_INVOICE_INFO_SUCCESS,
//                 ...normalizeData
//             })
//         } catch {
//             return null
//         } finally {
//             yield put({ type: FETCH_TAX_INVOICE_INFO, taxInvoiceInfoLoading: false })
//         }
//     })
// }

export function* deletePaymentVoucherRequest() {
    yield takeLatest(PAYMENT_VOUCHER_DELETE_REQUEST, function* ({ payload }) {
        yield put({ type: PAYMENT_VOUCHER_DELETE })

        const { orderId, id } = payload
        const method = 'DELETE'
        const path = apiPaymentVoucherPath(orderId, id)
        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, paymentVoucherSchema)

            yield put({
                type: PAYMENT_VOUCHER_DELETE_SUCCESS,
                ...normalizeData
            })
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        }
    })
}

export function* importPaymentVoucherFile() {
    yield takeLatest(
        PAYMENT_VOUCHER_IMPORT_FILE_REQUEST,
        function* ({ payload }) {
            yield put({
                type: PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING,
                uploading: true
            })

            const file = payload.file
            const path = '/payment_vouchers/imports'
            const method = 'POST'

            try {
                const data = yield call(apiPostForm, path, method, { file })
                i18n.loadNamespaces(['confirms', 'documents'])

                if (data.status === 'ok') {
                    yield put(
                        setNotifications(
                            `${i18n.t('documents:rvReport.uploadRV')} ${i18n.t(
                                'shared:successful'
                            )}`,
                            i18n.t(
                                'confirms:paymentVoucherReport.uploadSuccess'
                            ),
                            'info',
                            10000
                        )
                    )
                } else {
                    yield put(
                        setNotifications(
                            `${i18n.t('documents:rvReport.uploadRV')} ${i18n.t(
                                'shared:error'
                            )}`,
                            i18n.t('confirms:paymentVoucherReport.uploadError'),
                            'error'
                        )
                    )
                }
            } catch (error) {
                const errorName = error.name
                const errorMessage = error.errorMessage

                yield put(
                    setNotifications(`${errorName}`, `${errorMessage}`, 'error')
                )
            } finally {
                yield put({
                    type: PAYMENT_VOUCHER_IMPORT_FILE_UPLOADING,
                    uploading: false
                })
            }
        }
    )
}

export function* importReceiptVoucherFile() {
    yield takeLatest(
        RECEIPT_VOUCHERS_IMPORT_FILE_REQUEST,
        function* ({ payload }) {
            yield put({
                type: RECEIPT_VOUCHERS_IMPORT_FILE_UPLOADING,
                uploading: true
            })

            const file = payload.file
            const path = '/orders/payment_details/imports'
            const method = 'POST'

            try {
                const data = yield call(apiPostForm, path, method, { file })
                i18n.loadNamespaces(['confirms', 'documents'])

                if (data.status === 'ok') {
                    yield put(
                        setNotifications(
                            `${i18n.t('documents:rvReport.uploadRv')} ${i18n.t(
                                'shared:successful'
                            )}`,
                            i18n.t(
                                'documents:paymentVoucherReport.uploadSuccess'
                            ),
                            'info',
                            10000
                        )
                    )
                } else {
                    yield put(
                        setNotifications(
                            `${i18n.t('documents:rvReport.uploadRv')} ${i18n.t(
                                'shared:error'
                            )}`,
                            i18n.t(
                                'documents:paymentVoucherReport.uploadError'
                            ),
                            'error'
                        )
                    )
                }
            } catch (error) {
                const errorName = error.name
                const errorMessage = error.errorMessage

                yield put(
                    setNotifications(`${errorName}`, `${errorMessage}`, 'error')
                )
            } finally {
                yield put({
                    type: RECEIPT_VOUCHERS_IMPORT_FILE_UPLOADING,
                    uploading: false
                })
            }
        }
    )
}

export function* receiveVatDocumentFile() {
    yield takeLatest(FETCH_VAT_DOCUMENT_FILE_REQUEST, function* ({ payload }) {
        const { values } = payload
        yield put({ type: FETCH_VAT_DOCUMENT_FILE })

        const fileExtension = 'xlsx'
        let filename = 'vat-documents'
        const dateType = values['date_type']
            ? `date_type=${values['date_type']}`
            : ''
        const startDate = values['start_date']
            ? `start_date=${values['start_date']}`
            : ''
        const endDate = values['end_date']
            ? `end_date=${values['end_date']}`
            : ''
        const queryString = [dateType, startDate, endDate]
            .filter(Boolean)
            .join('&')
        const path = `/vat_documents/exports.${fileExtension}?${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        if (values.date_type === 'apply_date') {
            moment.locale('en')
            const month = moment(values.start_date).format('MMMM-YYYY')
            filename += `-apply-in-${month}`
        } else {
            const start = moment(values.start_date).format('YYYY-MM-DD')
            const end = moment(values.end_date).format('YYYY-MM-DD')
            filename += `-${start}-to-${end}`
        }

        downLoadFile({
            fileURL: fileURL,
            filename: `${filename}.${fileExtension}`
        })

        yield put({
            type: FETCH_VAT_DOCUMENT_FILE_SUCCESS
        })
    })
}

export function* receiveTemporaryReceiptPdf() {
    yield takeLatest(
        FETCH_TEMPORARY_RECEIPT_PDF_REQUEST,
        function* ({ payload }) {
            yield put({ type: FETCH_TEMPORARY_RECEIPT_PDF })

            const { orderId, value, locale } = payload
            const receiptNo = `receipt_no=${value['receipt_no']}`
            const amount = `amount=${value['amount']}`
            const note = value['note'] ? `note=${value['note']}` : ''

            const method = 'POST'
            const queryString = `?${receiptNo}&${amount}&${note}&locale=${locale}`
            const path = `/orders/${orderId}/temporary_receipt_documents${queryString}`

            const data = yield call(apiFileFetch, path, method)

            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({
                type: FETCH_TEMPORARY_RECEIPT_PDF_SUCCESS
            })
        }
    )
}

export function* uploadProductBankReconcile() {
    yield takeLatest(
        BANK_RECONCILE_PRODUCT_UPLOAD_REQUEST,
        function* ({ payload }) {
            yield put({
                type: BANK_RECONCILE_PRODUCT_SET_UPLOADING,
                uploading: true
            })

            const { file, onSuccess, onError } = payload
            const path = `/bank_reconciles/product_imports`
            const method = 'POST'
            try {
                const data = yield call(apiPostForm, path, method, { file })

                // send data status for dispatch notification
                onSuccess(data.status)
            } catch (error) {
                // send error for dispatch notification error
                onError(error)
            } finally {
                yield put({
                    type: BANK_RECONCILE_PRODUCT_SET_UPLOADING,
                    uploading: false
                })
            }
        }
    )
}

export function* uploadTruckBankReconcile() {
    yield takeLatest(
        BANK_RECONCILE_TRUCK_UPLOAD_REQUEST,
        function* ({ payload }) {
            yield put({
                type: BANK_RECONCILE_TRUCK_SET_UPLOADING,
                uploading: true
            })

            const { file, onSuccess, onError } = payload
            const path = `/bank_reconciles/truck_imports`
            const method = 'POST'
            try {
                const data = yield call(apiPostForm, path, method, { file })

                // send data status for dispatch notification
                onSuccess(data.status)
            } catch (error) {
                // send error for dispatch notification error
                onError(error)
            } finally {
                yield put({
                    type: BANK_RECONCILE_TRUCK_SET_UPLOADING,
                    uploading: false
                })
            }
        }
    )
}

export function* fetchOrderBusinessAddress() {
    yield takeLatest(
        FETCH_ORDER_BUSINESS_ADDRESS_REQUEST,
        function* ({ payload }) {
            const { id, orderId } = payload
            const path = `/orders/${orderId}/business_addresses/${id}`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(
                formattedData,
                orderBusinessAddressSchema
            )

            yield put({
                type: FETCH_ORDER_BUSINESS_ADDRESS_SUCCESS,
                ...normalizeData
            })
        }
    )
}

export function* fetchOrderBusinessAddresses() {
    yield takeLatest(
        FETCH_ORDER_BUSINESS_ADDRESSES_REQUEST,
        function* ({ payload }) {
            yield put({ type: FETCH_ORDER_BUSINESS_ADDRESSES })
            const { orderId } = payload

            const path = `/orders/${orderId}/business_addresses`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(
                formattedData,
                orderBusinessAddressesSchema
            )

            yield put({
                type: FETCH_ORDER_BUSINESS_ADDRESSES_SUCCESS,
                ...normalizeData
            })
        }
    )
}

export function* saveOrderBusinessAddress() {
    yield takeLatest(ORDER_BUSINESSES_SAVE_REQUEST, function* ({ payload }) {
        yield put({ type: ORDER_BUSINESSES_SET_SAVING, saving: true })

        const { address, id, orderId, onSuccess, onError } = payload
        const method = id ? 'PATCH' : 'POST'
        const path = id
            ? `/orders/${orderId}/business_addresses/${id}`
            : `/orders/${orderId}/business_addresses`

        const body = JSON.stringify({ order_business_address: address })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                onError(data.error_description)
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                yield put({
                    type: ORDER_BUSINESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, orderBusinessAddressSchema)
                })

                yield put(setNotifications('success', 'saveSuccess', 'success'))

                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({
                type: ORDER_BUSINESSES_SET_SAVING,
                saving: false
            })
        }
    })
}

export function* deleteOrderBusinessAddress() {
    yield takeLatest(ORDER_BUSINESSES_DELETE_REQUEST, function* ({ payload }) {
        const { orderId, id } = payload
        const path = `/orders/${orderId}/business_addresses/${id}`
        const method = 'DELETE'

        try {
            const data = yield call(apiFetch, path, method)
            const formattedData = deserialize(data)

            yield put({
                type: ORDER_BUSINESSES_DELETE_SUCCESS,
                ...normalize(formattedData, orderBusinessAddressSchema)
            })

            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        }
    })
}

export function* receiveSaleTaxInvoiceReportFile() {
    yield takeLatest(
        FETCH_SALE_TAX_INVOICE_REPORT_FILE_REQUEST,
        function* ({ payload }) {
            const { value } = payload
            yield put({ type: FETCH_SALE_TAX_INVOICE_REPORT_FILE })

            const fileExtension = 'xlsx'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const queryString = `?${startDate}&${endDate}`
            const path = `/sale_tax_invoices/exports.${fileExtension}${queryString}`

            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `sale-tax-invoice-report-${value.start_date}-to-${value.end_date}.${fileExtension}`
            })

            yield put({
                type: FETCH_SALE_TAX_INVOICE_REPORT_FILE_SUCCESS
            })
        }
    )
}

export function* receiveScbPaymentConfirmReportFile() {
    yield takeLatest(
        FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_REQUEST,
        function* ({ payload }) {
            const { value } = payload
            yield put({ type: FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE })

            const fileExtension = 'xlsx'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const queryString = `?${startDate}&${endDate}`
            const path = `/scb_payment_confirms/exports.${fileExtension}${queryString}`

            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `scb-payment-confirm-report-${value.start_date}-to-${value.end_date}.${fileExtension}`
            })

            yield put({
                type: FETCH_SCB_PAYMENT_CONFIRM_REPORT_FILE_SUCCESS
            })
        }
    )
}

// export function *sendTaxInvoiceEmail () {
//     yield takeLatest(SEND_TAX_INVOICE_EMAIL_REQUEST, function *({ payload }) {
//         yield put({ type: SEND_TAX_INVOICE_EMAIL, mailSending: true })

//         const { orderId, id, email, onSuccess, onError } = payload
//         const path = `/orders/${orderId}/business_addresses/${id}/send_tax_invoice_mails?email=${email}`

//         try {
//             yield call(apiFetch, path)

//             yield put({
//                 type: SEND_TAX_INVOICE_EMAIL_SUCCESS,
//                 mailSending: false
//             })

//             onSuccess()
//         } catch (error) {
//             onError()
//         }
//     })
// }

export function* fetchOrderInvoiceEmailHistories() {
    yield takeEvery(
        FETCH_ORDER_INVOICE_EMAIL_HISTORIES_REQUEST,
        function* ({ payload }) {
            yield put({ type: FETCH_ORDER_INVOICE_EMAIL_HISTORIES })
            const { orderId } = payload

            const path = `/orders/${orderId}/invoice_email_histories`
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(
                formattedData,
                invoiceEmailHistoriesSchema
            )

            yield put({
                type: FETCH_ORDER_INVOICE_EMAIL_HISTORIES_SUCCESS,
                ...normalizeData
            })
        }
    )
}

export function* receiveTaxInvoiceReportFile() {
    yield takeLatest(
        FETCH_TAX_INVOICE_REPORT_FILE_REQUEST,
        function* ({ payload }) {
            const { value } = payload
            yield put({ type: FETCH_TAX_INVOICE_REPORT_FILE })

            const fileExtension = 'xlsx'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const path = `/invoices/exports.${fileExtension}?${startDate}&${endDate}`

            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)

            downLoadFile({
                fileURL: fileURL,
                filename: `tax-invoice-report-${value.start_date}-to-${value.end_date}.${fileExtension}`
            })

            yield put({
                type: FETCH_TAX_INVOICE_REPORT_FILE_SUCCESS
            })
        }
    )
}

export function* importInvoiceEmsTrackingFile() {
    yield takeLatest(
        INVOICE_EMS_TRACKING_IMPORT_FILE_REQUEST,
        function* ({ payload }) {
            yield put({
                type: INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING,
                uploading: true
            })

            const file = payload.file
            const path = '/invoices/ems_tracking_imports'
            const method = 'POST'

            try {
                const data = yield call(apiPostForm, path, method, { file })
                i18n.loadNamespaces(['confirms', 'shared'])

                if (data.status === 'ok') {
                    yield put(
                        setNotifications(
                            `${i18n.t('confirms:invoice.upload')} ${i18n.t(
                                'shared:successful'
                            )}`,
                            i18n.t('uploadSuccess'),
                            'info',
                            10000
                        )
                    )
                } else {
                    yield put(
                        setNotifications(
                            `${i18n.t('confirms:invoice.upload')} ${i18n.t(
                                'shared:error'
                            )}`,
                            i18n.t('uploadError'),
                            'error'
                        )
                    )
                }
            } catch (error) {
                const errorName = error.name
                const errorMessage = error.errorMessage

                yield put(
                    setNotifications(`${errorName}`, `${errorMessage}`, 'error')
                )
            } finally {
                yield put({
                    type: INVOICE_EMS_TRACKING_IMPORT_FILE_UPLOADING,
                    uploading: false
                })
            }
        }
    )
}

export function* importTaxInvoiceFile() {
    yield takeLatest(TAX_INVOICE_IMPORT_FILE_REQUEST, function* ({ payload }) {
        yield put({ type: TAX_INVOICE_IMPORT_FILE_UPLOADING, uploading: true })

        const file = payload.file
        const path = '/invoices/imports'
        const method = 'POST'

        try {
            const data = yield call(apiPostForm, path, method, { file })
            i18n.loadNamespaces(['confirms', 'shared'])

            if (data.status === 'ok') {
                yield put(
                    setNotifications(
                        `${i18n.t('confirms:invoice.upload')} ${i18n.t(
                            'shared:successful'
                        )}`,
                        i18n.t('uploadSuccess'),
                        'info',
                        10000
                    )
                )
            } else {
                yield put(
                    setNotifications(
                        `${i18n.t('confirms:invoice.upload')} ${i18n.t(
                            'shared:error'
                        )}`,
                        i18n.t('uploadError'),
                        'error'
                    )
                )
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.errorMessage

            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({
                type: TAX_INVOICE_IMPORT_FILE_UPLOADING,
                uploading: false
            })
        }
    })
}

export function* receiveInvoiceDeliveryAddressPdf() {
    yield takeLatest(
        FETCH_INVOICE_DELIVERY_ADDRESS_PDF_REQUEST,
        function* ({ payload }) {
            yield put({ type: FETCH_INVOICE_DELIVERY_ADDRESS_PDF })

            const { value } = payload
            const method = 'GET'
            const startDate = `start_date=${value['start_date']}`
            const endDate = `end_date=${value['end_date']}`
            const path = `/invoice_delivery_address_documents?${startDate}&${endDate}`

            const data = yield call(apiFileFetch, path, method)

            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({
                type: FETCH_INVOICE_DELIVERY_ADDRESS_PDF_SUCCESS
            })
        }
    )
}

export function* fetchInvoiceAdjustments() {
    yield takeEvery(FETCH_INVOICE_ADJUSTMENTS_REQUEST, function* ({ payload }) {
        const { orderId, invoiceId } = payload
        yield put({ type: FETCH_INVOICE_ADJUSTMENTS, invoiceId })

        const path = `/orders/${orderId}/business_addresses/${invoiceId}/adjustments`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, invoiceAdjustmentsSchema)

        yield put({
            type: FETCH_INVOICE_ADJUSTMENTS_SUCCESS,
            ...normalizeData,
            invoiceId
        })
    })
}

export function* fetchInvoiceAdjustment() {
    yield takeEvery(FETCH_INVOICE_ADJUSTMENT_REQUEST, function* ({ payload }) {
        const { orderId, invoiceId, id } = payload
        yield put({ type: FETCH_INVOICE_ADJUSTMENT })

        const path = `/orders/${orderId}/business_addresses/${invoiceId}/adjustments/${id}`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, invoiceAdjustmentSchema)

        yield put({
            type: FETCH_INVOICE_ADJUSTMENT_SUCCESS,
            ...normalizeData
        })
    })
}

export function* saveInvoiceAdjustment() {
    yield takeLatest(SAVE_INVOICE_ADJUSTMENT_REQUEST, function* ({ payload }) {
        yield put({ type: SAVE_INVOICE_ADJUSTMENT, saving: true })
        const { orderId, invoiceId, id, params, onSuccess } = payload
        const method = id ? 'PATCH' : 'POST'
        const path = id
            ? `/orders/${orderId}/business_addresses/${invoiceId}/adjustments/${id}`
            : `/orders/${orderId}/business_addresses/${invoiceId}/adjustments`
        const body = { invoice_adjustment: params }

        try {
            const data = yield call(apiPostForm, path, method, body)
            const formattedData = deserialize(data)
            const normalizeData = normalize(
                formattedData,
                invoiceAdjustmentSchema
            )

            if ('error' in data) {
                yield put({
                    type: INVOICE_ADJUSTMENT_SET_FORM_ERRORS,
                    errors: data.error_description
                })
            } else {
                yield put({
                    type: SAVE_INVOICE_ADJUSTMENT_SUCCESS,
                    ...normalizeData,
                    invoiceId
                })
                onSuccess()
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({ type: SAVE_INVOICE_ADJUSTMENT, saving: false })
        }
    })
}

export function* deleteInvoiceAdjustment() {
    yield takeLatest(
        DELETE_INVOICE_ADJUSTMENT_REQUEST,
        function* ({ payload }) {
            yield put({ type: DELETE_INVOICE_ADJUSTMENT })
            const { orderId, invoiceId, id, onSuccess } = payload

            const path = `/orders/${orderId}/business_addresses/${invoiceId}/adjustments/${id}`
            const method = 'DELETE'
            yield call(apiFetch, path, method)

            yield put({ type: DELETE_INVOICE_ADJUSTMENT_SUCCESS })
            onSuccess()
        }
    )
}

export function* receiveInvoiceAdjusmentPdf() {
    yield takeLatest(
        DOWNLOAD_INVOICE_ADJUSTMENT_REQUEST,
        function* ({ payload }) {
            yield put({ type: DOWNLOAD_INVOICE_ADJUSTMENT })

            const { orderId, invoiceId, id, locale } = payload
            const method = 'POST'
            const path = `/orders/${orderId}/business_addresses/${invoiceId}/adjustments/${id}/documents?locale=${locale}`

            const data = yield call(apiFileFetch, path, method)

            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({
                type: DOWNLOAD_INVOICE_ADJUSTMENT_SUCCESS
            })
        }
    )
}

export function* sendETax() {
    yield takeLatest(SEND_E_TAX_REQUEST, function* ({ payload }) {
        yield put({ type: SEND_E_TAX_REQUESTING, saving: true })
        const { orderId, id, taxInvoiceType, isSample } = payload
        const type = `tax_invoice_type=${taxInvoiceType}`
        const sample = `is_sample=${isSample}`
        const path = `/orders/${orderId}/business_addresses/${id}/e_taxes?${type}&${sample}`

        try {
            const data = yield call(apiFetch, path)

            if (data.status === 'ER') {
                const errorName = data.error_code
                const errorMessage = data.error_message

                yield put(
                    setNotifications(`${errorName}`, `${errorMessage}`, 'error')
                )
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(
                    formattedData,
                    orderBusinessAddressSchema
                )
                i18n.loadNamespaces('confirms')
                yield put(
                    setNotifications(
                        `${i18n.t('confirms:invoice.sendETaxSuccess')}`,
                        i18n.t('confirms:invoice.sendETaxSuccessFully'),
                        'success'
                    )
                )

                yield put({
                    type: SEND_E_TAX_SUCCESS,
                    ...normalizeData
                })
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(
                setNotifications(`${errorName}`, `${errorMessage}`, 'error')
            )
        } finally {
            yield put({ type: SEND_E_TAX_REQUESTING, saving: false })
        }
    })
}

export function* sendElectronicCreditNoteRequest() {
    yield takeLatest(
        SEND_ELECTRONIC_CREDIT_NOTE_REQUEST,
        function* ({ payload }) {
            yield put({ type: SEND_ELECTRONIC_CREDIT_NOTE, saving: true })

            const { orderId, orderBusinessAddressId, adjustmentId, onSuccess } =
                payload

            const path = `/orders/${orderId}/business_addresses/${orderBusinessAddressId}/adjustments/${adjustmentId}/credit_notes`

            try {
                const data = yield call(apiFetch, path)

                if (data.status === 'ER') {
                    const errorName = data.error_code
                    const errorMessage = data.error_message

                    yield put(
                        setNotifications(
                            `${errorName}`,
                            `${errorMessage}`,
                            'error'
                        )
                    )
                } else {
                    const formattedData = deserialize(data)
                    const normalizeData = normalize(
                        formattedData,
                        invoiceAdjustmentSchema
                    )

                    onSuccess()

                    yield put({
                        type: SEND_ELECTRONIC_CREDIT_NOTE_SUCCESS,
                        ...normalizeData,
                        orderBusinessAddressId
                    })
                }
            } catch (error) {
                const errorName = error.name
                const errorMessage = error.message
                yield put(
                    setNotifications(`${errorName}`, `${errorMessage}`, 'error')
                )
            } finally {
                yield put({ type: SEND_ELECTRONIC_CREDIT_NOTE, saving: false })
            }
        }
    )
}

export function* sendETaxEmailToCustomer() {
    yield takeLatest(
        SEND_E_TAX_MAIL_TO_CUSTOMER_REQUEST,
        function* ({ payload }) {
            yield put({
                type: SEND_E_TAX_MAIL_TO_CUSTOMER,
                eTaxMailSending: true
            })

            const { orderId, id, email, onSuccess, onError } = payload
            const path = `/orders/${orderId}/business_addresses/${id}/send_e_tax_mails?email=${email}`

            try {
                yield call(apiFetch, path)

                yield put({
                    type: SEND_E_TAX_MAIL_TO_CUSTOMER_SUCCESS,
                    eTaxMailSending: false
                })

                onSuccess()
            } catch (error) {
                onError()
            }
        }
    )
}

export function* debitNotePurposesRequest() {
    yield takeLatest(FETCH_DEBIT_NOTE_PURPOSES_REQUEST, function* () {
        yield put({ type: FETCH_DEBIT_NOTE_PURPOSES, loading: true })

        const path = `/invoice_adjustments/debit_note_purpose_codes`

        try {
            const data = yield call(apiFetch, path)
            yield put({
                type: FETCH_DEBIT_NOTE_PURPOSES_SUCCESS,
                loading: false,
                data: data
            })
        } catch (error) {
            return null
        }
    })
}

export function* creditNotePurposesRequest() {
    yield takeLatest(FETCH_CREDIT_NOTE_PURPOSES_REQUEST, function* () {
        yield put({ type: FETCH_CREDIT_NOTE_PURPOSES, loading: true })

        const path = `/invoice_adjustments/credit_note_purpose_codes`

        try {
            const data = yield call(apiFetch, path)

            yield put({
                type: FETCH_CREDIT_NOTE_PURPOSES_SUCCESS,
                loading: false,
                data: data
            })
        } catch (error) {
            return null
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(deleteInvoiceAdjustment),
        fork(deleteOrderBusinessAddress),
        fork(deletePaymentDetail),
        fork(deletePaymentVoucherRequest),
        fork(fetchInvoiceAdjustment),
        fork(fetchInvoiceAdjustments),
        fork(fetchOrderBusinessAddress),
        fork(fetchOrderBusinessAddresses),
        fork(fetchOrderInvoiceEmailHistories),
        fork(fetchPaymentDetail),
        fork(fetchPaymentVouchers),
        // fork(fetchTaxInvoiceInfo),
        fork(fetchTransferBack),
        fork(importInvoiceEmsTrackingFile),
        fork(importPaymentVoucherFile),
        fork(importReceiptVoucherFile),
        fork(importTaxInvoiceFile),
        fork(receiveInvoiceDeliveryAddressPdf),
        fork(receivePaymentVouchersFile),
        fork(receiveRvAndPvAdjustedReportFile),
        fork(receiveRvReportFile),
        fork(receiveSaleTaxInvoiceReportFile),
        fork(receiveScbPaymentConfirmReportFile),
        fork(receiveTaxInvoicePdf),
        fork(receiveTaxInvoiceReportFile),
        fork(receiveTemporaryReceiptPdf),
        fork(receiveVatDocumentFile),
        fork(receiveYieldReportFile),
        fork(saveInvoiceAdjustment),
        fork(saveOrderBusinessAddress),
        fork(savePaymentDetail),
        fork(savePaymentVoucher),
        // fork(sendTaxInvoiceEmail),
        fork(uploadProductBankReconcile),
        fork(uploadTruckBankReconcile),
        fork(receiveInvoiceAdjusmentPdf),
        fork(sendETax),
        fork(sendElectronicCreditNoteRequest),
        fork(sendETaxEmailToCustomer),
        fork(debitNotePurposesRequest),
        fork(creditNotePurposesRequest)
    ])
}
