const actions = {
    CUSTOMER_EVENT_LOGS_FILTERS: 'CUSTOMER_EVENT_LOGS_FILTERS',

    setFilters: (filters) => ({
        type: actions.CUSTOMER_EVENT_LOGS_FILTERS,
        filters
    }),
}

export default actions
