const actions = {
    ORDERS_APPROVER_WAIT_STATE_REQUEST: 'ORDERS_APPROVER_WAIT_STATE_REQUEST',
    ORDERS_APPROVER_WAIT_STATE: 'ORDERS_APPROVER_WAIT_STATE',
    ORDERS_APPROVER_WAIT_STATE_SUCCESS: 'ORDERS_APPROVER_WAIT_STATE_SUCCESS',
    ORDERS_SET_PAGINATION: 'ORDERS_SET_PAGINATION',
    SAVE_ORDERS_APPROVER_WAIT_STATE_REQUEST: 'SAVE_ORDERS_APPROVER_WAIT_STATE_REQUEST',
    SAVE_ORDERS_APPROVER_WAIT_STATE: 'SAVE_ORDERS_APPROVER_WAIT_STATE',
    SAVE_ORDERS_APPROVER_WAIT_STATE_SUCCESS: 'SAVE_ORDERS_APPROVER_WAIT_STATE_SUCCESS',
    PURCHASE_ORDERS_SET_SORTER: 'PURCHASE_ORDERS_SET_SORTER',
    SAVE_ORDERS_APPROVER_WAIT_REQUEST: 'SAVE_ORDERS_APPROVER_WAIT_REQUEST',
    SAVE_ORDERS_APPROVER_WAIT: 'SAVE_ORDERS_APPROVER_WAIT',
    SAVE_ORDERS_APPROVER_WAIT_SUCCESS: 'SAVE_ORDERS_APPROVER_WAIT_SUCCESS',
    PURCHASER_FETCH_REQUEST: 'PURCHASER_FETCH_REQUEST',
    PURCHASER_FETCHING_REQUEST: 'PURCHASER_FETCHING_REQUEST',
    PURCHASER_FETCH_SUCCESS: 'PURCHASER_FETCH_SUCCESS',
    PURCHASER_SET: 'PURCHASER_SET',

    fetchOrderApproverWaitState: () => ({
        type: actions.ORDERS_APPROVER_WAIT_STATE_REQUEST
    }),

    setPagination: (pagination) => ({
        type: actions.ORDERS_SET_PAGINATION, pagination
    }),

    setOrderApproverWait: (id, newState) => ({
        type: actions.SAVE_ORDERS_APPROVER_WAIT_STATE_REQUEST,
        payload: { id, newState }
    }),
    setSorter: (sorter) => {
        return { type: actions.PURCHASE_ORDERS_SET_SORTER, sorter }
    },
    submitOrderApproverWait: (orderId, values) => ({
        type: actions.SAVE_ORDERS_APPROVER_WAIT_REQUEST,
        payload: { orderId, values }
    }),

    fetchPurchaser: (all) => {
        return {
            type: actions.PURCHASER_FETCH_REQUEST,
            payload: { all }
        }
    }
}

export default actions
