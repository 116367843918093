import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import '../I18n'
import '../../public/styles/timeline-chart.css'
import RootContainer from '../pages/RootContainer'

class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired
    }

    render () {
        return (
            <Provider store={this.props.store}>
                <Router>
                    <Route path='/' component={RootContainer} />
                </Router>
            </Provider>
        )
    }
}

export default App
