import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { List as immutableList } from 'immutable'
import _ from 'lodash'
import moment from 'moment'

import { Button, DatePicker, Form, Input, Select, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import SelectSupplierOrderByOrderContainer from '../../containers/SelectSupplierOrderByOrder'
import SelectSupplierContainer from '../../containers/SelectSupplier'
import SelectOrderContainer from '../../containers/SelectOrder'
import SelectSupplierOrderBySupplierContainer from '../../containers/SelectSupplierOrderBySupplier'

const { Option } = Select

const SupplierOverTransferItemForm = ({
    context,
    creditNoteState,
    error,
    getSupplierOrder,
    i18n,
    onCreditNoteSupplierOrderChange,
    onDelete,
    onOverTransferSupplierOrderChange,
    onSelectTransactionDetail,
    onSubmitForm,
    orderId,
    overTransferState,
    supplierId,
    supplierOrderId,
    supplierOverTransferItem,
    transactionDetail,
    fileList,
    handleSetFileList
}) => {
    moment.locale(i18n.language)
    const [form] = Form.useForm()

    const isUpdate = _.isEqual(context, 'UPDATE')
    const formItemLayout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 }
    }

    const transactionDetailData = _.get(supplierOverTransferItem, 'transaction_detail', undefined)
    const transactionDate = _.get(supplierOverTransferItem, 'transaction_date.raw', undefined)

    const onSubmit = (values) => {
        const transactionDate = values['transaction_date'] ? (
            values['transaction_date'].format('YYYY-MM-DD')
        ) : null
        let image
        if (values['images_attributes']) {
            const imageValue = values['images_attributes']
            const supplierOverTransferItemImages = supplierOverTransferItem.images || immutableList()

            let destroyImage = []
            let uploadImage = imageValue
            supplierOverTransferItemImages.map((image) => {
                if (!imageValue.filter(value => value.uid === image.id).length) {
                    destroyImage.push({ id: image.id, _destroy: true })
                } else {
                    uploadImage = uploadImage.filter(value => value.uid !== image.id)
                }
            })
            image = uploadImage ? (
                uploadImage.map((id) => {
                    return {
                        slip_image: id.originFileObj,
                        _destroy: false
                    }
                }).concat(destroyImage)
            ) : null
        }

        const baseParams = {
            amount: _.get(values, 'amount', null),
            note: _.get(values, 'note', null),
            transaction_date: transactionDate,
            transaction_detail: _.get(values, 'transaction_detail', null),
            images_attributes: image
        }

        switch (_.get(values, 'transaction_detail', '')) {
            case 'over_transfer': {
                const supplierOrderId = _.get(values, 'over_transfer.supplier_order_id', null)
                const orderNumber = _.get(getSupplierOrder(supplierOrderId).toJS(), 'order')
                let params = {
                    supplier_order_id: supplierOrderId
                }
                if (orderNumber) {
                    params.order_number = orderNumber
                }
                onSubmitForm({
                    supplierId: _.get(values, 'over_transfer.supplier_id', null),
                    supplierOverTransferItemParams: _.merge(baseParams, params)
                })
                break
            }
            case 'credit_note': {
                const supplierOrderId = _.get(values, 'credit_note.supplier_order_id', null)
                const orderNumber = _.get(getSupplierOrder(supplierOrderId).toJS(), 'order')
                let params = {
                    supplier_over_transfer_destination_attributes: {
                        id: _.get(supplierOverTransferItem, 'supplier_over_transfer_destination.id', null),
                        _destroy: false,
                        supplier_order_id: supplierOrderId
                    }
                }
                if (orderNumber) {
                    params.order_number = orderNumber
                    params.supplier_over_transfer_destination_attributes.order_number = orderNumber
                }
                onSubmitForm({
                    supplierId: _.get(values, 'credit_note.supplier_id', null),
                    supplierOverTransferItemParams: _.merge(baseParams, params)
                })
                break
            }
            case 'transfer_back': {
                onSubmitForm({
                    supplierId: _.get(values, 'transfer_back.supplier_id', null),
                    supplierOverTransferItemParams: _.merge(baseParams, {
                        order_number: _.get(values, 'transfer_back.order_number', null)
                    })
                })
                break
            }
            default:
                break
        }
    }

    const renderSelectTransactionDetails = () => {
        return (
            <Select
                disabled={isUpdate}
                onChange={onSelectTransactionDetail}
                style={{ width: '100%' }}>
                <Option value="over_transfer">{i18n.t('suppliers/overTransferItems:overTransfer')} + </Option>
                <Option value="credit_note">{i18n.t('suppliers/overTransferItems:creditNote')} - </Option>
            </Select>
        )
    }

    const renderSelectOrder = ({ prefix, field, defaultOrder, rules, onChange = () => {} }) => {
        return (
            <Form.Item
                name={[field, 'order_number']}
                rules={rules}
                initialValue={defaultOrder || undefined}
                label={prefix ? `${prefix} (${i18n.t('orders')})` : i18n.t('orders')}
                key='select_order'
                {...formItemLayout}
            >
                <SelectOrderContainer
                    disabled={isUpdate || !!orderId}
                    onChange={(orderId) => { onChange(form, orderId) }}
                    style={{ width: '100%' }}
                />
            </Form.Item>
        )
    }

    const renderSelectSupplierOrder = ({ prefix, errorPath, field, defaultSupplierOrder, onChange = () => {} }) => {
        let selectComponent
        if (orderId) {
            selectComponent = (
                <SelectSupplierOrderByOrderContainer
                    disabled={isUpdate}
                    onChange={(value) => { onChange(form, value) }}
                    orderId={orderId}
                    style={{ width: '100%' }}
                />
            )
        } else if (supplierId) {
            selectComponent = (
                <SelectSupplierOrderBySupplierContainer
                    disabled={isUpdate}
                    onChange={(value) => { onChange(form, value) }}
                    supplierId={supplierId}
                    style={{ width: '100%' }}
                />
            )
        }

        return (
            <Form.Item
                name={[field, 'supplier_order_id']}
                initialValue={defaultSupplierOrder || undefined}
                label={`${prefix} (${i18n.t('suppliers:supplierOrders')})`}
                key='select_supplier_order'
                validateStatus={_.get(error, errorPath, '') && 'error'}
                help={_.get(error, errorPath, []).join(', ')}
                {...formItemLayout}
            >
                {selectComponent}
            </Form.Item>
        )
    }

    const renderSelectSupplier = ({ prefix, field, disabled }) => {
        const defaultSupplier = _.get(supplierOverTransferItem, 'supplier_over_transfer_account.supplier.id')
        return (
            <Form.Item
                name={[field, 'supplier_id']}
                initialValue={supplierId || defaultSupplier}
                rules={[{ required: true, message: i18n.t('suppliers/overTransferItems:pleaseInputSupplier')}]}
                label={prefix ? `${prefix} (${i18n.t('suppliers:supplierName')})` : i18n.t('suppliers:supplierName')}
                key='select_supplier'
                {...formItemLayout}
            >
                    <SelectSupplierContainer
                        disabled={isUpdate || disabled || !!supplierId}
                        style={{ width: '100%' }}
                    />
            </Form.Item>
        )
    }

    const uploadImage = () => {
        return (
            <Upload.Dragger
                multiple
                listType="picture-card"
                beforeUpload={() => false}
                fileList={fileList}
                onChange={handleSetFileList}
            >
                <div>
                    <PlusOutlined />
                    <div>{i18n.t('suppliers/overTransferItems:upload')}</div>
                </div>

            </Upload.Dragger>
        )
    }

    const renderFromByTransactionDetail = () => {
        switch (transactionDetailData || transactionDetail) {
            case 'over_transfer': {
                const field = 'over_transfer'
                const prefix = i18n.t('suppliers/overTransferItems:overTransferFrom')

                return (
                    <React.Fragment>
                        {renderSelectSupplierOrder({
                            prefix,
                            errorPath: 'error_description.supplier_order',
                            field,
                            defaultSupplierOrder: supplierOrderId || _.get(supplierOverTransferItem, 'supplier_order.id'),
                            onChange: onOverTransferSupplierOrderChange
                        })}

                        {renderSelectSupplier({
                            prefix,
                            field,
                            disabled: _.get(overTransferState, 'disableSelectSupplier', true)
                        })}
                    </React.Fragment>
                )
            }
            case 'credit_note': {
                const field = 'credit_note'
                const prefix = i18n.t('suppliers/overTransferItems:creditNoteWith')
                return (
                    <React.Fragment>
                        {renderSelectSupplierOrder({
                            prefix,
                            field,
                            defaultSupplierOrder: supplierOrderId || _.get(supplierOverTransferItem, 'supplier_over_transfer_destination.supplier_order.id'),
                            onChange: onCreditNoteSupplierOrderChange
                        })}

                        {renderSelectSupplier({
                            prefix,
                            field,
                            disabled: _.get(creditNoteState, 'disableSelectSupplier', true)
                        })}
                    </React.Fragment>
                )
            }
            case 'transfer_back':
                const field = 'transfer_back'
                return (
                  <React.Fragment>
                      {renderSelectOrder({
                          field,
                          defaultOrder: _.get(supplierOverTransferItem, 'order', orderId),
                          rules: [
                              {
                                  required: true,
                                  message: i18n.t('suppliers/overTransferItems:pleaseInputOrder')
                              }
                          ]
                      })}

                      {renderSelectSupplier({ field })}
                  </React.Fragment>
                )
            default:
                return <></>
        }
    }

    const normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    return (
        <Form onFinish={onSubmit} form={form}>
            <h3>{i18n.t('suppliers/overTransferItems:overTransfer')}</h3>

            <Form.Item
                name='transaction_detail'
                initialValue={transactionDetailData || transactionDetail}
                label={i18n.t('suppliers/overTransferItems:transactionDetail')}
                key='transaction_detail'
                validateStatus={_.get(error, 'error_description.transaction_detail', '') && 'error'}
                help={_.get(error, 'error_description.transaction_detail', []).join(', ')}
                {...formItemLayout}
            >
                {renderSelectTransactionDetails()}
            </Form.Item>

            {renderFromByTransactionDetail()}

            <Form.Item
                name='amount'
                initialValue={_.toNumber(_.get(supplierOverTransferItem, 'amount.raw', '')) || undefined}
                label={i18n.t('suppliers/overTransferItems:amount')}
                key='amount'
                validateStatus={_.get(error, 'error_description.amount', '') && 'error'}
                help={_.get(error, 'error_description.amount', []).join(', ')}
                {...formItemLayout}
            >
                    <Input style={{ width: '100%' }}/>
            </Form.Item>

            <Form.Item
                name='transaction_date'
                initialValue={transactionDate && moment(transactionDate)}
                label={i18n.t('suppliers/overTransferItems:transactionDate')}
                key='transaction_date'
                validateStatus={_.get(error, 'error_description.transaction_date', '') && 'error'}
                help={_.get(error, 'error_description.transaction_date', []).join(', ')}
                {...formItemLayout}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item key='images'
                name='images_attributes'
                getValueFromEvent={normFile}
                initialValue={fileList}
                >
                    {uploadImage(fileList)}
            </Form.Item>

            <Form.Item
                label={i18n.t('suppliers/overTransferItems:note')}
                key='note'
                name='note'
                initialValue={_.get(supplierOverTransferItem, 'note', undefined)}
                validateStatus={_.get(error, 'error_description.note', '') && 'error'}
                help={_.get(error, 'error_description.note', []).join(', ')}
                {...formItemLayout}
            >
                    <Input.TextArea />
            </Form.Item>

            <Form.Item key='actions' style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                    Submit
                </Button>
                {isUpdate ? <Button type="danger" onClick={onDelete}>Delete</Button> : null}
            </Form.Item>
        </Form>
    )
}

SupplierOverTransferItemForm.propTypes = {
    // context should be 'CREATE' or 'UPDATE'
    context: PropTypes.string,
    creditNoteState: PropTypes.object,
    error: PropTypes.object,
    form: PropTypes.object.isRequired,
    getSupplierOrder: PropTypes.func,
    onCreditNoteSupplierOrderChange: PropTypes.func,
    onOverTransferSupplierOrderChange: PropTypes.func,
    onSelectTransactionDetail: PropTypes.func,
    onSubmitForm: PropTypes.func,
    orderId: PropTypes.string,
    overTransferState: PropTypes.object,
    supplierId: PropTypes.string,
    supplierOverTransferItem: PropTypes.object,
    transactionDetail: PropTypes.string
}

SupplierOverTransferItemForm.defaultProps = {
    context: 'CREATE',
    creditNoteState: {},
    error: {},
    getSupplierOrder: () => {},
    onCreditNoteSupplierOrderChange: () => {},
    onOverTransferSupplierOrderChange: () => {},
    onSelectTransactionDetail: () => {},
    onSubmitForm: () => {},
    orderId: '',
    overTransferState: {},
    supplierId: '',
    supplierOverTransferItem: {},
    transactionDetail: 'over_transfer'
}

export default withTranslation(['suppliers', 'suppliers/overTransferItems'])(SupplierOverTransferItemForm)

