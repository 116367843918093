import React, { Component } from 'react'
import { connect } from 'react-redux'
import clone from 'clone'
import { Layout, Menu } from 'antd'
import {
  UserAddOutlined,
  DatabaseOutlined,
  DollarOutlined,
  WalletOutlined,
  TeamOutlined,
  ShareAltOutlined,
  RiseOutlined,
  SolutionOutlined,
  PushpinOutlined,
  FileTextOutlined,
  PrinterOutlined,
  ContainerOutlined,
  BookOutlined,
  CarOutlined,
  LikeOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import Scrollbars from '../../components/utility/customScrollBar.js'
import SidebarWrapper from './sidebar.style'

import appActions from '../../redux/app/actions'
import Logo from '../../components/utility/logo'
import defaultTheme from '../../../config/theme/themedefault'
import { fromJS } from 'immutable'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

const { changeCurrent, changeOpenKeys, toggleCollapsed, toggleOpenDrawer } =
  appActions

class Sidebar extends Component {
  handleClick = (event) => {
    const { app, changeCurrent, toggleCollapsed, toggleOpenDrawer } = this.props

    changeCurrent([event.key])

    if (app.get('view') !== 'MobileView') {
      return null
    }

    setTimeout(() => {
      toggleCollapsed()
      toggleOpenDrawer()
    }, 100)
  }

  onOpenChange = (newOpenKeys) => {
    const { app, changeOpenKeys } = this.props
    const latestOpenKey = newOpenKeys.find(
      (key) => !(app.get('openKeys').toJS().indexOf(key) > -1)
    )
    const latestCloseKey = app
      .get('openKeys')
      .toJS()
      .find((key) => !(newOpenKeys.indexOf(key) > -1))

    let nextOpenKeys = []
    if (latestOpenKey) {
      nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey)
    }
    if (latestCloseKey) {
      nextOpenKeys = this.getAncestorKeys(latestCloseKey)
    }

    changeOpenKeys(fromJS(nextOpenKeys))
  }

  getAncestorKeys = (key) => {
    const map = { sub3: ['sub2'] }
    return map[key] || []
  }

  onMouseEnter = () => {
    const { app, toggleOpenDrawer } = this.props

    if (app.get('openDrawer') === false) {
      toggleOpenDrawer()
    }
  }

  onMouseLeave = () => {
    const { app, toggleOpenDrawer } = this.props

    if (app.get('openDrawer') === true) {
      toggleOpenDrawer()
    }
  }

  renderMenuForCallCenter = (subMenuColor) => {
    const { i18n } = this.props
    return (
      <React.Fragment>
        <Menu.ItemGroup key='customer_group' title='Customer & Sale'>
          <Menu.Item key='customers'>
            <Link to='/customers'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <UserAddOutlined />
                <span className='nav-text'>{i18n.t('customer')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='tickets'>
            <Link to='/tickets'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <UnorderedListOutlined />
                <span className='nav-text'>{i18n.t('tickets')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='projects'>
            <Link to={'/projects'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('projects')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='shipping_rates'>
            <Link to={'/shipping_rates'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('shippingRates')}</span>
              </span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
      </React.Fragment>
    )
  }

  renderAllMenu = (subMenuColor) => {
    const { i18n, isCurrentStaffA, rolesMatched } = this.props
    const isSuperAdmin = isCurrentStaffA('super_admin')

    return (
      <React.Fragment>
        <Menu.ItemGroup key='customer_group' title='Customer & Sale'>
          <Menu.Item key='customers'>
            <Link to='/customers'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <UserAddOutlined />
                <span className='nav-text'>{i18n.t('customer')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='deals'>
            <Link to='/deals'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>Deals</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='tickets'>
            <Link to='/tickets'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <UnorderedListOutlined />
                <span className='nav-text'>{i18n.t('tickets')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='order_summaries'>
            <Link to='/order_summaries'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('orders')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='sale_pipelines'>
            <Link to={`/sale_pipelines`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DollarOutlined />
                <span className='nav-text'>{i18n.t('salePipelines')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='sales_summary'>
            <Link to={`/sales_summary`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DollarOutlined />
                <span className='nav-text'>{i18n.t('salesSummary')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='wallet_summaries'>
            <Link to='/wallet_summaries'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <WalletOutlined />
                <span className='nav-text'>{i18n.t('walletSummaries')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='companies'>
            <Link to={`/companies`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <TeamOutlined />
                <span className='nav-text'>{i18n.t('companies')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='projects'>
            <Link to={'/projects'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('projects')}</span>
              </span>
            </Link>
          </Menu.Item>
          {isSuperAdmin && (
            <Menu.Item key='profit_sharings'>
              <Link to={'/profit_sharings'}>
                <span className='isoMenuHolder' style={subMenuColor}>
                  <ShareAltOutlined />
                  <span className='nav-text'>{i18n.t('profitSharing')}</span>
                </span>
              </Link>
            </Menu.Item>
          )}
          {isSuperAdmin && (
            <Menu.Item key='orders/yields'>
              <Link to='/orders/yields'>
                <span className='isoMenuHolder' style={subMenuColor}>
                  <RiseOutlined />
                  <span className='nav-text'>Yields</span>
                </span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key='event_logs'>
            <Link to={`/event_logs`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <SolutionOutlined />
                <span className='nav-text'>{i18n.t('eventLogs')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='line_pins'>
            <Link to={`/line_pins`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <PushpinOutlined />
                <span className='nav-text'>{i18n.t('linePins')}</span>
              </span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key='vendor_group' title='Vendor'>
          <Menu.Item key='supplier_order_reviews'>
            <Link to={`/supplier_order_reviews`} target='_blank'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <FileTextOutlined />
                <span className='nav-text'>{i18n.t('purchaseOrders')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='supplier_orders'>
            <Link to={`/supplier_orders`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <FileTextOutlined />
                <span className='nav-text'>{i18n.t('supplierOrders')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='trucks'>
            <Link to='/trucks'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <CarOutlined />
                <span className='nav-text'>{i18n.t('trucks')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='suppliers'>
            <Link to='/suppliers'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <TeamOutlined />
                <span className='nav-text'>{i18n.t('suppliers')}</span>
              </span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key='account_group' title='Account'>
          <Menu.Item key='orders/documents'>
            <Link to={`/orders/documents`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <PrinterOutlined />
                <span className='nav-text'>{i18n.t('orderDocuments')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='supplier_invoices'>
            <Link to={`/supplier_invoices`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <FileTextOutlined />
                <span className='nav-text'>{i18n.t('supplierInvoices')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='order_payment_details'>
            <Link to={`/order_payment_details`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <ContainerOutlined />
                <span className='nav-text'>
                  {i18n.t('orderPaymentDetails')}
                </span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='payment_vouchers'>
            <Link to={`/payment_vouchers`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <ContainerOutlined />
                <span className='nav-text'>{i18n.t('paymentVouchers')}</span>
              </span>
            </Link>
          </Menu.Item>
          {rolesMatched(['super_admin', 'accounting']) && (
            <Menu.Item key='tax_invoices'>
              <Link to={`/tax_invoices`}>
                <span className='isoMenuHolder' style={subMenuColor}>
                  <FileTextOutlined />
                  <span className='nav-text'>{i18n.t('taxInvoices')}</span>
                </span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key='vat_documents'>
            <Link to={`/vat_documents`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <PrinterOutlined />
                <span className='nav-text'>{i18n.t('vatDocuments')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='bank_statements'>
            <Link to='/bank_statements'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <BookOutlined />
                <span className='nav-text'>{i18n.t('bankStatements')}</span>
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item key='withholding_taxes'>
            <Link to={'/withholding_taxes'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('withholdingTaxes')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='chart_of_accounts'>
            <Link to={`/chart_of_accounts`}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <ContainerOutlined />
                <span className='nav-text'>{i18n.t('chartOfAccounts')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='journal_entry_formats'>
            <Link to={'/journal_entry_formats'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>
                  {i18n.t('journalEntryFormats')}
                </span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='journal_entries'>
            <Link to={'/journal_entries'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('journalEntries')}</span>
              </span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key='feedback_group' title='Feedback'>
          <Menu.Item key='order_reviews'>
            <Link to='/order_reviews'>
              <span className='isoMenuHolder' style={subMenuColor}>
                <LikeOutlined />
                <span className='nav-text'>{i18n.t('orderReviews')}</span>
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='request_items'>
            <Link to={'/request_items'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <FileTextOutlined />
                <span className='nav-text'>{i18n.t('requestItemIndex')}</span>
              </span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>

        <Menu.ItemGroup key='setting_group' title='Setting'>
          <Menu.Item key='shipping_rates'>
            <Link to={'/shipping_rates'}>
              <span className='isoMenuHolder' style={subMenuColor}>
                <DatabaseOutlined />
                <span className='nav-text'>{i18n.t('shippingRates')}</span>
              </span>
            </Link>
          </Menu.Item>
          {isSuperAdmin && (
            <Menu.Item key='staffs'>
              <Link to='/staffs'>
                <span className='isoMenuHolder' style={subMenuColor}>
                  <UsergroupAddOutlined />
                  <span className='nav-text'>{i18n.t('staffs')}</span>
                </span>
              </Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      </React.Fragment>
    )
  }

  render() {
    const { app, isCurrentStaffA } = this.props
    const collapsed =
      clone(app.get('collapsed')) && !clone(app.get('openDrawer'))
    const mode = collapsed === true ? 'vertical' : 'inline'
    const scrollHeight = app.get('height')
    const imgUrl =
      'https://onestockhome-production.s3-ap-southeast-1.amazonaws.com/messageImage_1572418503036.jpg'
    const secondImageUrl =
      'https://onestockhome-production.s3.ap-southeast-1.amazonaws.com/S__21725189.jpg'
    const styling = {
      backgroundColor: defaultTheme.backgroundColor
    }
    const subMenuColor = {
      color: defaultTheme.textColor
    }

    return (
      <SidebarWrapper>
        <Layout.Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width='220'
          className='isomorphicSidebar'
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          style={styling}>
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: scrollHeight - 70 }}>
            <img
              src={imgUrl}
              alt='God of Wealth'
              style={{ marginLeft: '50px', width: '120px' }}
            />
            <img
              src={secondImageUrl}
              alt='God of Wealth'
              style={{ marginLeft: '50px', width: '120px' }}
            />
            <Menu
              onClick={this.handleClick}
              theme='dark'
              mode={mode}
              // eslint-disable-next-line no-nested-ternary
              openKeys={collapsed ? [] : app.get('openKeys').toJS()}
              // eslint-disable-next-line no-nested-ternary
              selectedKeys={collapsed ? [] : app.get('current').toJS()}
              onOpenChange={this.onOpenChange}
              className='isoDashboardMenu'>
              {isCurrentStaffA('call_center')
                ? this.renderMenuForCallCenter(subMenuColor)
                : this.renderAllMenu(subMenuColor)}
            </Menu>
          </Scrollbars>
        </Layout.Sider>
      </SidebarWrapper>
    )
  }
}

export default connect(
  (state) => ({
    app: state.get('App')
  }),
  { changeCurrent, changeOpenKeys, toggleCollapsed, toggleOpenDrawer }
)(withTranslation()(currentStaffContainer(Sidebar)))
