import styled from 'styled-components'

const TicketImagesWrapper = styled.div`
  margin-bottom: 12px;

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img,
  .image-container > a > img {
    max-height: 40px;
    max-width: 40px;
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
  }
`

export default TicketImagesWrapper
