import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/customers'

const {
    CUSTOMER_WALLETS_FETCH,
    CUSTOMER_WALLETS_FETCH_SUCCESS,
    CUSTOMER_WALLET_SAVING,
    CUSTOMER_WALLET_SAVE_SUCCESS,
    CUSTOMER_WALLET_DELETE,
    CUSTOMER_WALLET_DELETE_SUCCESS,
    CUSTOMER_WALLETS_SET_PAGINATION,
    CUSTOMERS_WALLET_SET_FORM_MODAL,
    CUSTOMER_WALLET_FETCH,
    CUSTOMER_WALLET_FETCH_SUCCESS,
    CUSTOMER_WALLET_SET_FORM_ERRORS,
    ORDER_CUSTOMER_WALLET_SAVING,
    ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS,
    ORDER_CUSTOMER_WALLET_SAVE_SUCCESS,
    ORDER_CUSTOMER_WALLETS_FETCH,
    ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS,
    ORDER_CUSTOMER_WALLET_DELETE,
    ORDER_CUSTOMER_WALLET_DELETE_SUCCESS,
    CUSTOMER_WALLET_SET_SHOW_AMOUNT_FORM,
    CUSTOMER_WALLET_SET_SHOW_BANK_DETAIL_FORM
} = actions

const initState = new Map({
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    }),
    showWalletForm: false,
    walletIdSelected: null,
    errors: new Map(),
    showAmountInput: false,
    showBankDetailInput: false
})

export default function customerWalletsReducer (state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_WALLETS_FETCH:
            return state.merge({ loading: true })
        case CUSTOMER_WALLETS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case CUSTOMER_WALLETS_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case CUSTOMER_WALLET_SAVING:
            return state.merge({ saving: true })
        case CUSTOMER_WALLET_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: items.includes(result) ? items : items.push(result)
            })
        case CUSTOMER_WALLET_DELETE:
            return state.merge({ loading: true })
        case CUSTOMER_WALLET_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: items.delete(items.indexOf(result))
            })
        case CUSTOMERS_WALLET_SET_FORM_MODAL:
            return state.merge({
                showWalletForm: action.visible,
                walletIdSelected: action.id
            })
        case CUSTOMER_WALLET_FETCH:
            return state.merge({
                loading: action.loading
            })
        case CUSTOMER_WALLET_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: items.includes(result) ? items : items.push(result)
            })
        case CUSTOMER_WALLET_SET_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        case CUSTOMER_WALLET_SET_SHOW_AMOUNT_FORM:
            return state.merge({
                showAmountInput: action.value
            })
        case ORDER_CUSTOMER_WALLET_SAVING:
            return state.merge({ saving: action.saving })
        case ORDER_CUSTOMER_WALLET_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                items: items.includes(result) ? items : items.push(result)
            })
        case ORDER_CUSTOMER_WALLET_SET_FORM_ERRORS:
            return state.merge({
                saving: false,
                errors: new Map(action.formErrors)
            })
        case ORDER_CUSTOMER_WALLETS_FETCH:
            return state.merge({ loading: true })
        case ORDER_CUSTOMER_WALLETS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        case ORDER_CUSTOMER_WALLET_DELETE:
            return state.merge({ loading: true })
        case ORDER_CUSTOMER_WALLET_DELETE_SUCCESS:
            return state.merge({
                loading: false,
                items: items.delete(items.indexOf(result))
            })
        case CUSTOMER_WALLET_SET_SHOW_BANK_DETAIL_FORM:
            return state.merge({
                showBankDetailInput: action.value
            })
        default:
            return state
    }
}
