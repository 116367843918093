import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Form, Avatar, Comment, Button, Tooltip, Select, Row, Col } from 'antd'
import EditorContainer from '../../containers/Editor/EditorContainer'
import withCurrentStaff from '../../containers/CurrentStaff/CurrentStaffContainer'

import { MEMBER_AVATAR_PROPS } from '../../constants/tickets'

import notificationActions from '../../redux/notifications/actions'
import ActiveStorageUploader from '../ActiveStorageUploader'

const { setNotifications } = notificationActions

const CREATE_TICKET_MUTATION = gql`
    mutation CreateTicketNote($ticketId: ID!, $note: String $noteBlobIds: [ID!], $noteType: String){
        createTicketNote(ticketId: $ticketId, note: $note, noteBlobIds: $noteBlobIds, noteType: $noteType){
            ticket {
                id
                description
                source
                state
                ticketType
                category {
                    id
                    name
                }
                orders {
                    id
                    number
                }
            }
            errors
        }
    }
`

const TicketNoteForm = ({ currentStaff, onSuccess, ticket, isCurrentStaffA }) => {
    const [form] = Form.useForm()
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const imagesRef = useRef()

    const [createTicketNote, { loading: saving }] = useMutation(CREATE_TICKET_MUTATION, {
        onCompleted: ({ createTicketNote }) => {
            if (!createTicketNote.errors) {
                dispatch(setNotifications('success', 'success', 'success'))
                onSuccess()
                form.resetFields()
                imagesRef.current.clearValue()
            }
        }
    })

    const onSubmitForm = (values) => {
        const params = {
            ticketId: ticket.id,
            note: values.note,
            noteType: values.noteType,
            noteBlobIds: imagesRef.current.getValue()
        }

        createTicketNote({ variables: params })
    }

    const imageHandler = (file, imgBase64) => {
        imagesRef.current.initActiveStorageBlobs(file)

        const fileList = imagesRef.current.getFileList()
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            status: 'done',
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        imagesRef.current.setFileList(fileList.concat(newItemOfFileList))
    }

    const { first_name: firstName, last_name: lastName } = currentStaff.toJS()
    const isCallCenterRole = isCurrentStaffA('call_center')

    return (
        <div>
            <Comment
                avatar={
                    <Tooltip title={`${firstName} ${lastName}`}>
                        <Avatar style={MEMBER_AVATAR_PROPS['self']}>{firstName}</Avatar>
                    </Tooltip>
                }
                content={
                    <Form form={form} onFinish={onSubmitForm}>
                        <Row >
                            <Col span={20}>
                                <Form.Item>
                                    <ActiveStorageUploader ref={imagesRef} listType='picture' />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="noteType"
                                    initialValue={isCallCenterRole ? 'note' : 'answer'}>
                                    <Select style={{ width: '100%' }}>
                                        <Select.Option value="note">Note</Select.Option>
                                        <Select.Option value="answer">Answer</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item name="note">
                            <EditorContainer
                                imageHandler={imageHandler}
                                isMinHeight100 />
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType="submit" loading={saving} type="primary">
                                {i18n.t('shared:save')}
                            </Button>
                        </Form.Item>
                    </Form>
                }
            />
        </div>
    )
}

TicketNoteForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    ticket: PropTypes.object.isRequired,
    noteType: PropTypes.string
}

TicketNoteForm.defaultProps = {
}

export default withCurrentStaff(TicketNoteForm)
