import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import TransferBackListHeader from '../../components/supplierOrders/transferBackListHeader'
import TransferBackList from '../../components/supplierOrders/transferBackList'

import actions from '../../redux/supplierOrders/actions'
import accountingActions from '../../redux/orders/accounting/actions'
import paymentVoucherActions from '../../redux/paymentVouchers/actions'
import notificationActions from '../../redux/notifications/actions'

const { exportSupplierOrderPaymentVouchers, fetchPVTransferBacks, setPvEditing, setPVTransferBackFilters } = actions
const { togglePaymentVoucherForm } = accountingActions
const { savePaymentVoucher } = paymentVoucherActions
const { setNotifications } = notificationActions

const TransferBackListContainer = ({
    exporting,
    toggleSupplierOrderForm
}) => {
    const dispatch = useDispatch()
    const orderEntities = useSelector(state => state.getIn(['Entities', 'orders']))
    const paymentVoucherEntities = useSelector(state => state.getIn(['Entities', 'paymentVouchers']))
    const supplierOrderEntities = useSelector(state => state.getIn(['Entities', 'supplierOrders']))
    const items = useSelector(state => state.getIn(['supplierOrders', 'transferBackList', 'items'], new List()))
    const loading = useSelector(state => state.getIn(['supplierOrders', 'transferBackList', 'loading'], false))
    const pagination = useSelector(state => state.getIn(['supplierOrders', 'transferBackList', 'pagination'], new Map()))
    const filters = useSelector(state => state.getIn(['supplierOrders', 'transferBackList', 'filters'], new Map()))
    const showPaymentVoucherForm = useSelector(state => state.getIn(['orderPaymentDetails', 'showPaymentVoucherForm']))
    const pvIdEditing = useSelector(state => state.getIn(['supplierOrders', 'transferBackList', 'pvIdEditing']))
    const saving = useSelector(state => state.getIn(['paymentVouchers', 'saving']))
    const formErrors = useSelector(state => state.getIn(['paymentVouchers', 'formErrors']))

    const [selectedPVs, setSelectedPVs] = useState([])

    useEffect(() => {
        dispatch(fetchPVTransferBacks({ page: 1, filters: filters.toJS() }))
    }, [dispatch, filters])

    const getOrder = (number) => {
        return orderEntities.get(number) || new Map()
    }

    const getPaymentVoucher = (id) => {
        return paymentVoucherEntities.get(id) || new Map()
    }

    const getSupplierOrder = (id) => {
        return supplierOrderEntities.get(id) || new Map()
    }

    const getTransferBackList = () => {
        return items.map((id) => {
            const paymentVoucher = getPaymentVoucher(id)
            return new Map({
                ...paymentVoucher.toJS(),
                order: getOrder(paymentVoucher.get('order_number')),
                supplier_orders: paymentVoucher.get('supplier_orders').map(id => getSupplierOrder(id))
            })
        })
    }

    const onChangeFilters = (args) => {
        dispatch(setPVTransferBackFilters({ ...filters.toJS(), ...args }))
    }

    const onExport = (selectedPVs) => {
        dispatch(exportSupplierOrderPaymentVouchers(selectedPVs))
    }

    const onPageChange = (pagination) => {
        dispatch(fetchPVTransferBacks({ page: pagination.current, filters: filters.toJS() }))
    }

    const onSavePaymentVoucher = ({ id, params }) => {
        dispatch(savePaymentVoucher(id, { params, list: false, onSuccess: () => {
            onTogglePaymentVoucherForm()
            dispatch(setNotifications('success', 'success', 'success'))
        } }))
    }

    const onTogglePaymentVoucherForm = () => {
        dispatch(togglePaymentVoucherForm())
    }

    const onSetPvEditing = (id) => {
        dispatch(setPvEditing(id))
    }

    return (
        <React.Fragment>
            <TransferBackListHeader
                exporting={exporting}
                filters={filters.toJS()}
                onChangeFilters={onChangeFilters}
                onExport={onExport}
                selectedPVs={selectedPVs}
            />
            <TransferBackList
                formErrors={formErrors}
                getPaymentVoucher={getPaymentVoucher}
                loading={loading}
                onPageChange={onPageChange}
                onSavePaymentVoucher={onSavePaymentVoucher}
                pagination={{
                    ...pagination.toJS(),
                    showSizeChanger: false
                }}
                pvIdEditing={pvIdEditing}
                saving={saving}
                setPvEditing={onSetPvEditing}
                setSelectedPVs={setSelectedPVs}
                showPaymentVoucherForm={showPaymentVoucherForm}
                togglePaymentVoucherForm={onTogglePaymentVoucherForm}
                toggleSupplierOrderForm={toggleSupplierOrderForm}
                transferBackList={getTransferBackList().toJS()}
            />
        </React.Fragment>
    )
}

TransferBackListContainer.propTypes = {
    exporting: PropTypes.bool,
    toggleSupplierOrderForm: PropTypes.func.isRequired
}

TransferBackListContainer.defaultProps = {}

export default TransferBackListContainer
