import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Form, Space, Upload } from 'antd'
import { AlignLeftOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import EditorContainer from '../../../containers/Editor/EditorContainer'

const normFile = e => {
    if (Array.isArray(e)) return e

    return e && e.fileList
}

const renderTitle = ({ icon: Icon, text }) => (
    <Space align="center" style={{ marginBottom: '12px' }}>
        <Icon style={{ fontSize: '20px' }} />
        <h3 style={{ marginBottom: 0 }}>{text}</h3>
    </Space>
)

const CommentForm = ({
    attachmentFieldName,
    descriptionFieldName,
    frontPart,
    onClose,
    onSubmitForm,
    onRemoveAttachment,
    onUploadAttachment,
    saving
}) => {
    const { i18n } = useTranslation()
    const [form] = Form.useForm()

    const imageHandler = (file, imgBase64) => {
        const fileList = form.getFieldValue(attachmentFieldName) || []
        const newFile = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }

        onUploadAttachment(file, {
            onSuccess: () => form.setFieldsValue({ [attachmentFieldName]: fileList.concat(newFile) })
        })
    }

    const onUploadImageCustomRequest = ({ file, onProgress, onSuccess }) => {
        onUploadAttachment(file, {
            onProgress: e => onProgress({ percent: (e.loaded / e.total) * 100 }),
            onSuccess: () => onSuccess('Ok')
        })

        return {
            abort () {}
        }
    }

    const onRemoveImage = (file) => {
        const fileList = form.getFieldValue(attachmentFieldName)

        onRemoveAttachment(fileList, file)
    }

    return (
        <Form onFinish={values => onSubmitForm(values, form)} form={form} layout="vertical">
            {renderTitle({ icon: AlignLeftOutlined, text: 'Description' })}

            {frontPart}

            <Form.Item name={descriptionFieldName}>
                <EditorContainer theme="snow"
                    placeholder="Add more detailed description"
                    showToolbar={false}
                    imageHandler={imageHandler}
                    isMinHeight100
                />
            </Form.Item>

            {renderTitle({ icon: PaperClipOutlined, text: 'Attachments' })}

            <Form.Item
                name={attachmentFieldName}
                getValueFromEvent={normFile}    
                valuePropName='fileList'
            >
                    <Upload.Dragger
                        customRequest={onUploadImageCustomRequest}
                        listType="picture"
                        multiple
                        onRemove={onRemoveImage}
                        showUploadList={{ showPreviewIcon: false }}
                    >
                        <div>
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">Upload</p>
                        </div>
                    </Upload.Dragger>
            </Form.Item>

            <Space style={{ width: '100%', justifyContent: 'end' }}>
                <Button loading={saving} type="primary" htmlType="submit">{i18n.t('shared:save')}</Button>
                {onClose && <Button onClick={onClose}>{i18n.t('shared:close')}</Button>}
            </Space>
        </Form>
    )
}

CommentForm.propTypes = {
    attachment: PropTypes.string.isRequired,
    descriptionFieldName: PropTypes.string.isRequired,
    frontPart: PropTypes.element,
    onClose: PropTypes.func,
    onSubmitForm: PropTypes.func.isRequired,
    onRemoveAttachment: PropTypes.func.isRequired,
    onUploadAttachment: PropTypes.func.isRequired,
    saving: PropTypes.bool
}

CommentForm.defaultProps = {}

export default CommentForm
