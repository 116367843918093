import styled from 'styled-components'
const CustomerFormWrapper = styled.div`
  .add-contact {
    margin-top: 10px;
  }

  .icon-close-contact {
    float: right;
    font-size: 20px;
  }

  .add-contact-btn {
    text-align: center;
  }

  .search-company {
    line-height: 30px;
    color: rgba(0,0,0,.85);
    margin-top: 18px;
  }
  `

export default CustomerFormWrapper
