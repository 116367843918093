import { Map, List } from 'immutable'
import actions from './actions'
import { mergeDeepReplaceArrays } from '../../entities/reducers'

const {
    DOCUMENT_SET_PDF_LANGUAGE,
    DOCUMENTS_SET_INVOICE_MODAL,
    DOCUMENTS_FETCH,
    DOCUMENTS_SET_ORDER_ID,
    TAX_INVOICE_DOCUMENTS_FETCH,
    INVOICE_REPORTS_FETCH_SUCCESSS,
    INVOICE_REPORTS_FETCH,
    INVOICE_REPORT_SAVE_SUCCESS,
    INVOICE_REPORT_SAVE,
    TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS,
    TRUCK_RESERVATION_REPORTS_FETCH
} = actions

const initState = new Map({
    dashboardDocumentPDFLanguage: 'th',
    dashboardDocumentPDFLoading: false,
    showInvoiceModal: false,
    orderId: null,
    taxInvoiceDocumentLoading: false,
    reports: new Map({
        invoiceLoading: false,
        invoiceItems: new List(),
        truckReservationLoading: false,
        truckReservationItems: new List(),
        filterDate: null,
        saving: false
    })
})

export default function orderDocumentReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case DOCUMENT_SET_PDF_LANGUAGE:
            return state.merge({
                dashboardDocumentPDFLanguage: action.value
            })
        case DOCUMENTS_FETCH:
            return state.merge({
                dashboardDocumentPDFLoading: action.loading
            })
        case DOCUMENTS_SET_INVOICE_MODAL:
            return state.merge({
                showInvoiceModal: action.isShow
            })
        case DOCUMENTS_SET_ORDER_ID:
            return state.merge({
                orderId: action.orderId
            })
        case TAX_INVOICE_DOCUMENTS_FETCH:
            return state.merge({
                taxInvoiceDocumentLoading: action.loading
            })
        case INVOICE_REPORTS_FETCH_SUCCESSS:
            return mergeDeepReplaceArrays(state, {
                reports: {
                    invoiceLoading: false,
                    invoiceItems: result
                }
            })
        case INVOICE_REPORTS_FETCH:
            return state.mergeDeep({
                reports: {
                    invoiceLoading: true,
                    filterDate: action.strDate
                }
            })
        case INVOICE_REPORT_SAVE:
            return state.mergeDeep({
                reports: {
                    saving: true
                }
            })
        case INVOICE_REPORT_SAVE_SUCCESS:
            return state.mergeDeep({
                reports: {
                    saving: false
                }
            })
        case TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS:
            return mergeDeepReplaceArrays(state, {
                reports: {
                    truckReservationLoading: false,
                    truckReservationItems: result
                }
            })
        case TRUCK_RESERVATION_REPORTS_FETCH:
            return state.mergeDeep({
                reports: {
                    truckReservationLoading: true,
                    filterDate: action.strDate
                }
            })
        default:
            return state
    }
}
