import React, {useEffect} from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import OrderPaymentDetailsListContainer from './OrderPaymentDetailsContainer'

const OrderPaymentDetailIndexPage = () => {
    useEffect(() => {
        document.title = 'Order Payment Details | OSH CRM'
    }, [])

    return (
        <Layout>
            <LayoutContentWrapper breadcrumbs={[
                { path: '/order_payment_details', name: 'Order Payment Details' }
            ]}>
                <ContentLayout>
                    <OrderPaymentDetailsListContainer />
                </ContentLayout>
            </LayoutContentWrapper>
        </Layout>
    )
}

export default OrderPaymentDetailIndexPage
