import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { isEmpty } from '../../helpers/objects'

import OrderBusinessAddresses from '../../components/Invoices/OrderBusinessAddresses'
import yieldsActions from '../../redux/orders/yields/actions'

const {
    fetchOrderBusinessAddresses,
    setOrderBusinessAddressPagination,
    setSorter
} = yieldsActions

class OrderBusinessAddressIndexContainer extends Component {
    static propTypes = {
        // Inner Props
        fetchOrderBusinessAddresses: PropTypes.func.isRequired,
        orderYields: ImmutablePropTypes.map.isRequired
    }
    componentDidMount () {
        const { fetchOrderBusinessAddresses } = this.props
        fetchOrderBusinessAddresses()
    }

    getOrderQueryObject (sorter) {
        if (isEmpty(sorter)) {
            return {}
        }

        const sortDirection = sorter.order === 'descend' ? 'DESC' : 'ASC'

        return {
            key: 'order',
            value: `${sorter.columnKey} ${sortDirection}`
        }
    }

    handleInvoiceDateChange = (values) => {
        this.fetchInvoices({
            invStartDate: values.length ? values[0].format('YYYY-MM-DD') : '',
            invEndDate: values.length ? values[1].format('YYYY-MM-DD') : ''
        })
    }

    handleTableChange = (pagination, filter, sorter) => {
        const { setOrderBusinessAddressPagination, setSorter } = this.props

        const sorterQuery = this.getOrderQueryObject(sorter)

        setSorter(sorter)
        setOrderBusinessAddressPagination(pagination)

        this.fetchInvoices({
            currentPage: pagination.current,
            sorter: sorterQuery
        })
    }

    fetchInvoices = ({ currentPage, sorter, invStartDate, invEndDate }) => {
        const { fetchOrderBusinessAddresses, orderYields } = this.props

        fetchOrderBusinessAddresses({
            page: currentPage,
            sorter: sorter,
            invStartDate: (invStartDate === undefined) ? orderYields.get('invStartDate') : invStartDate,
            invEndDate: (invEndDate === undefined) ? orderYields.get('invEndDate') : invEndDate
        })
    }

    getOrderBusinessAddresses = () => {
        const { orderBusinessAddressEntities, orderYields } = this.props
        const items = orderYields.get('orderBusinessAddressItems')
        return items.map((id) => {
            return orderBusinessAddressEntities.get(id)
        })
    }

    render () {
        const { orderYields } = this.props

        return (
            <OrderBusinessAddresses
                onTableChange={this.handleTableChange}
                defaultInvStartDate={orderYields.get('invStartDate')}
                defaultInvEndDate={orderYields.get('invEndDate')}
                onInvoiceDateChange={this.handleInvoiceDateChange}
                orderBusinessAddresses={this.getOrderBusinessAddresses().toJS()}
                loading={orderYields.get('orderBusinessAddressesLoading')}
                pagination={{
                    ...orderYields.get('orderBusinessAddressesPage').toJS(),
                    showSizeChanger: false
                }}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderYields: state.get('orderYields'),
        orderBusinessAddressEntities: state.getIn(['Entities', 'orderBusinessAddresses'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderBusinessAddresses,
        setOrderBusinessAddressPagination,
        setSorter
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderBusinessAddressIndexContainer)
