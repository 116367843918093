import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import PurchaseOrderAddressForm from '../../components/purchaseOrderForm/addressForm'
import { Checkbox } from 'antd'

class PurchaseOrderAddressFormContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.object
    }

    static defaultProps = {
        onChange: () => {},
        value: {
            send_by_supplier: false,
            purchase_order_address_attributes: {
                id: '',
                district_id: '',
                province_id: '',
                phone_number: '',
                postal_code: '',
                shipping_detail: '',
                _destroy: true
            }
        }
    }

    constructor (props) {
        super(props)

        this.state = {
            value: { ...props.value }
        }
    }

    onCheckboxChange = (e) => {
        const { onChange, setAddressErrors } = this.props
        const { value } = this.state

        const checked = e.target.checked
        if (checked === false) { setAddressErrors(false)}

        const newValue = {
            ...value,
            send_by_supplier: checked,
            ...{
                purchase_order_address_attributes: {
                    ...value.purchase_order_address_attributes,
                    _destroy: !checked
                }
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onSelectAddressChange = (address) => {
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                purchase_order_address_attributes: {
                    ...value.purchase_order_address_attributes,
                    province_id: _.get(address, 'province_id', ''),
                    district_id: _.get(address, 'district_id', ''),
                    postal_code: _.get(address, 'postal_code', '')
                }
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                purchase_order_address_attributes: {
                    ...value.purchase_order_address_attributes,
                    phone_number: phoneNumber
                }
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    onShippingDetailChange = (e) => {
        const shippingDetail = e.target.value
        const { onChange } = this.props
        const { value } = this.state

        const newValue = {
            ...value,
            ...{
                purchase_order_address_attributes: {
                    ...value.purchase_order_address_attributes,
                    shipping_detail: shippingDetail
                }
            }
        }

        this.setState({ value: newValue })
        onChange(newValue)
    }

    render () {
        const { value } = this.state

        return (
            <div className="purchase-order-address-form-container">
                <Checkbox
                    checked={_.get(value, 'send_by_supplier', false)}
                    onChange={this.onCheckboxChange}
                >
                    โรงงานจัดส่ง
                </Checkbox>
                { _.get(value, 'send_by_supplier', false) ?
                    <PurchaseOrderAddressForm
                        onPhoneNumberChange={this.onPhoneNumberChange}
                        onSelectAddressChange={this.onSelectAddressChange}
                        onShippingDetailChange={this.onShippingDetailChange}
                        value={value}
                    /> : null
                }
            </div>
        )
    }
}

export default PurchaseOrderAddressFormContainer
