import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const STAFFS_WITH_CALL_CENTER_QUERY = gql`
    query StaffsWithCallCenter($query: String){
        staffsWithCallCenter(query: $query){
            id
            fullName
        }
    }
`

const renderOptions = (options = []) => options.map(({ fullName, id }) => {
    return <Select.Option value={id}>{fullName}</Select.Option>
})

const StaffsWithCallCenterSelector = forwardRef(({ value, onChange, ...restProps }, ref) => {
    const [query, setQuery] = useState('')
    const [selected, setSelected] = useState(value)
    const { loading, data } = useQuery(STAFFS_WITH_CALL_CENTER_QUERY, { variables: { query } })

    useEffect(() => setSelected(value), [value])

    useImperativeHandle(ref, () => ({
        clear () {
            handleChange(undefined)
        }
    }))

    const handleChange = option => {
        setSelected(option)
        onChange(option)
    }

    const onSearch = text => {
        setQuery(text)
    }

    return (
        <Select
            allowClear
            filterOption={false}
            labelInValue
            loading={loading}
            onChange={handleChange}
            onSearch={onSearch}
            showSearch
            value={selected}
            {...restProps}
        >
            {renderOptions(data && data.staffsWithCallCenter)}
        </Select>
    )
})

StaffsWithCallCenterSelector.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

StaffsWithCallCenterSelector.defaultProps = {
    onChange: () => {}
}

export default StaffsWithCallCenterSelector
