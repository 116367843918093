import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'
import _ from 'lodash'

import SupplierShippingTruckSelect from '../../components/Suppliers/supplierShippingTruckSelect'

import truckActions from '../../redux/trucks/actions'

const { resetSelectedTruckOwner, resetSelectedTruck, setTruckIds } = truckActions

class SupplierShippingTruckSelectContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.shape({
            truck_id: PropTypes.string,
            truck_owner_id: PropTypes.string
        }),

        // Inner Props
        resetSelectedTruckOwner: PropTypes.func.isRequired,
        resetSelectedTruck: PropTypes.func.isRequired,
        setTruckIds: PropTypes.func.isRequired,
        truckOwnerEntities: ImmutablePropTypes.map.isRequired,
        truckEntities: ImmutablePropTypes.map.isRequired,
        trucks: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: {}
    }

    constructor (props) {
        super(props)

        const { value } = props

        if (!value) return

        this.supplierShippingTruck = value
    }

    getValue () {
        return this.supplierShippingTruck
    }

    setValue ({
        truckId = this.supplierShippingTruck['truck_id'],
        truckOwnerId = this.supplierShippingTruck['truck_owner_id']
    } = {}) {
        this.supplierShippingTruck['truck_id'] = truckId
        this.supplierShippingTruck['truck_owner_id'] = truckOwnerId
    }

    onTruckChange = (truckId) => {
        const { onChange } = this.props

        this.resetSelectedTruckOwner({ truckId })
        this.supplierShippingTruck['truck_id'] = truckId
        onChange(this.getValue())
    }

    onTruckOwnerChange = ({ truckOwnerId }) => {
        const { onChange } = this.props

        this.resetSelectedTruck({ truckOwnerId })

        this.setValue({ truckOwnerId: truckOwnerId || '' })

        onChange(this.getValue())
    }

    resetSelectedTruckOwner ({ truckId = '' } = {}) {
        const { truckEntities, resetSelectedTruckOwner } = this.props

        if (!truckId) return null

        const truck = truckEntities.get(_.toString(truckId), new Map())
        const truckOwnerId = truck.get('truck_owner', '')

        if (!truckOwnerId) {
            resetSelectedTruckOwner()
            this.setValue({ truckOwnerId: '' })
            return null
        }

        resetSelectedTruckOwner({ truckOwner: truckOwnerId })
        this.setValue({ truckOwnerId: truckOwnerId })
    }

    resetSelectedTruck ({ truckOwnerId = '' } = {}) {
        const { truckOwnerEntities, trucks, resetSelectedTruck } = this.props

        if (!truckOwnerId) {
            resetSelectedTruck()
            this.setValue({ truckId: '' })
            return null
        }

        const truckOwner = truckOwnerEntities.get(_.toString(truckOwnerId), new Map())
        const truckIds = truckOwner.get('trucks', new List())

        if (truckIds.includes(_.toString(trucks.get('selectedTruckId')))) return null

        resetSelectedTruck({ truckIds: truckIds.toJS() })
        this.setValue({ truckId: '' })
    }

    render () {
        return (
            <SupplierShippingTruckSelect
                onTruckChange={this.onTruckChange}
                onTruckOwnerChange={this.onTruckOwnerChange}
                supplierShippingTruck={this.supplierShippingTruck}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        truckOwnerEntities: state.getIn(['Entities', 'truckOwners']),
        truckEntities: state.getIn(['Entities', 'trucks']),
        trucks: state.get('trucks')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        resetSelectedTruckOwner,
        resetSelectedTruck,
        setTruckIds
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierShippingTruckSelectContainer)
