import { all, takeLatest, put, call, fork } from 'redux-saga/effects'

import { apiFetch } from '../../../helpers/restRequest'

import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'

import { supplierAccountingGroupsSchema } from '../../schema'

const {
    SUPPLIER_ACCOUNTING_GROUPS_FETCH_REQUEST,
    SUPPLIER_ACCOUNTING_GROUPS_FETCH,
    SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS
} = actions

const { setNotifications } = actionsNotification

export function *fetchSupplierAccountingGroupsSaga () {
    yield takeLatest(SUPPLIER_ACCOUNTING_GROUPS_FETCH_REQUEST, function *({ payload }) {
        const { query } = payload

        yield put({ type: SUPPLIER_ACCOUNTING_GROUPS_FETCH, loading: true })

        try {
            const path = `/suppliers/accounting_groups?query=${query}`
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, supplierAccountingGroupsSchema)

            yield put({
                type: SUPPLIER_ACCOUNTING_GROUPS_FETCH_SUCCESS,
                ...normalizedData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SUPPLIER_ACCOUNTING_GROUPS_FETCH, loading: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchSupplierAccountingGroupsSaga)
    ])
}
