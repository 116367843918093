import React, { useState } from 'react'
import { Modal, Spin, Table, DatePicker } from 'antd'
import {gql, useQuery,} from "@apollo/client";
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const { RangePicker } = DatePicker

const DEAL_SUMMARY_QUERY = gql`
	query DealSummary($startDate: String, $endDate: String) {
		dealSummary {
            id
            fullName
            leadDistributionEachStateCount(startDate: $startDate, endDate: $endDate) {
                pending
                clientReview
                done
            }
		}
	}
`

const DealSummary = ({ openModal, setOpenModal }) => {
    const { i18n } = useTranslation('deals')
    const [startDate, setStartDate] = useState(moment().subtract(10,'d').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const { loading, data, error } = useQuery(DEAL_SUMMARY_QUERY, {
        variables: { startDate: startDate, endDate: endDate}
    })

    if (loading) { return <Spin />}

    const columns = [
        {
            title: i18n.t('deals:sale'),
            dataIndex: 'fullName',
            key: 'name',
        },
        {
            title: i18n.t('deals:pending'),
            dataIndex: ['leadDistributionEachStateCount', 'pending'],
            key: 'pending',
        },
        {
            title: i18n.t('deals:clientReview'),
            dataIndex: ['leadDistributionEachStateCount', 'clientReview'],
            key: 'clientReview',
        },
        {
            title: i18n.t('deals:done'),
            dataIndex: ['leadDistributionEachStateCount', 'done'],
            key: 'done',
        },
    ];

    const sources = data.dealSummary.map((summary) => {
        const pending = summary.leadDistributionEachStateCount.pending === '0'
        const clientReview = summary.leadDistributionEachStateCount.clientReview === '0'
        const done = summary.leadDistributionEachStateCount.done === '0'
        if (pending && clientReview && done) {
            return null
        }

        return summary
    }).filter(item => item)

    const onChangePicker = (values) => {
        setStartDate(values[0].format('YYYY-MM-DD'))
        setEndDate(values[1].format('YYYY-MM-DD'))
    }

    return (
        <div>
            <Modal
                destroyOnClose
                footer={false}
                width={'80%'}
                closable={true}
                centered
                onCancel={() => setOpenModal(!openModal)}
                visible={openModal}
                title={i18n.t('deals:dealSummary')}
            >
                <div style={{ marginBottom: '12px' }}>
                    <RangePicker
                        onChange={onChangePicker}
                        defaultValue={[startDate ? moment(startDate) : null, endDate ? moment(endDate) : null]}
                    />
                </div>
                <Table dataSource={sources} columns={columns} pagination={false} />
            </Modal>
        </div>
    )
}

export default DealSummary
