import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { Layout, Select, Spin, Empty, Dropdown, Tag, Menu, Divider, Modal } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import documentAction from '../../../redux/orders/documents/actions'
import InvoiceReportsWrapper from './invoiceReports.style'

const { fecthReports, saveInvoiceReport } = documentAction

const { Content } = Layout

class InvoiceReportsIndexContainer extends Component {
    componentDidMount () {
        const { fecthReports } = this.props
        const start = moment().startOf('week').format('YYYY-MM-DD')
        const end = moment().endOf('week').format('YYYY-MM-DD')

        fecthReports(`${start}_${end}`)
    }

    getInvoiceReports () {
				const { orderBusinessAddressEntities, orderDocumentReports } = this.props
				let invoiceReports = new Map()

				orderDocumentReports.get('invoiceItems').map((id) => {
						const invoice = orderBusinessAddressEntities.get(id)
						const invoiceDate = invoice.get('invoice_date')
						const invoiceList = invoiceReports.get(invoiceDate) || new List()

						invoiceReports = invoiceReports.merge({
								[invoiceDate]: invoiceList.push(invoice)
						})
				})

				return invoiceReports
    }

    getTruckReservationReports () {
				const { truckReservationEntities, orderDocumentReports } = this.props
				let truckReservationReports = new Map()

				orderDocumentReports.get('truckReservationItems').map((id) => {
						const truckReservation = truckReservationEntities.get(id)
						const date = truckReservation.get('date')
						const truckReservationList = truckReservationReports.get(date) || new List()

						truckReservationReports = truckReservationReports.merge({
								[date]: truckReservationList.push(truckReservation)
						})
				})

				return truckReservationReports
    }

		isBetweenDate = (i, arrDate) => {
				const date = moment(arrDate[0]).add(i, 'days').format('YYYY-MM-DD')

				return moment(date).isBetween(arrDate[0], arrDate[1], null, '[]')
		}

			handleFilterDate = (strDate) => {
					const { fecthReports } = this.props

					fecthReports(strDate)
			}

		handleSendingState = (e, id) => {
				const { saveInvoiceReport } = this.props

				saveInvoiceReport({ id, value: { sending_state: e.key } })
		}

		renderFilterDateOption () {
				let selectOptions = []

				for (let i = -8; i < 9; i++) {
						const date = moment().add(i, 'weeks')
						const start = date.startOf('week').format('YYYY-MM-DD')
						const end = date.endOf('week').format('YYYY-MM-DD')

						selectOptions.push(
								<Select.Option key={`${start}_${end}`} value={`${start}_${end}`}> {start} - {end} </Select.Option>
						)
				}

				return selectOptions
		}

		renderHeader = (name) => {
				return (
						<div className="section-header">
								{this.props.i18n.t(`documents:reports.${name}`)}
						</div>
				)
		}

		renderInvoiceMenu = (invoice) => {
			const { i18n } = this.props
				return (
						<Menu selectedKeys={[invoice.sending_state]}
								onClick={(e) => this.handleSendingState(e, invoice.id)}>
								<Menu.Item key="not_sent">
										{i18n.t('documents:reports.notSent')}
								</Menu.Item>
								<Menu.Item key="sending">
										{i18n.t('documents:reports.sending')}
								</Menu.Item>
								<Menu.Item key="sent">
										{i18n.t('documents:reports.sent')}
								</Menu.Item>
								<Menu.Item key="send_back">
										{i18n.t('documents:reports.sendBack')}
								</Menu.Item>
						</Menu>
				)
		}

		renderInvoiceReports = (invoices) => {
				if (!invoices) {
						return
				}
				return invoices.map((invoice) => {
						const invoiceNo = invoice.invoice_no || 'null'

						return (
								<Dropdown key={`invoice-${invoice.id}`}
										overlay={this.renderInvoiceMenu(invoice)}>
										<Tag className="pointer-cursor" color={invoiceNo === 'null' ? null : 'blue'}
												onClick={() => {window.location.href = `/orders/${invoice.order_number}/accountings`}}>
												{invoiceNo} <DownOutlined />
										</Tag>
								</Dropdown>
						)
				})
		}

		renderTruckReservationReports = (truckReservations) => {
				return truckReservations.map((truckReservation) => {
						return (
								<Tag className="pointer-cursor"
										key={`truck-reservation-${truckReservation.id}`}
										onClick={() => {window.location.href = `/orders/${truckReservation.order_number}/accountings`}}>
										{truckReservation.order_number}
								</Tag>
						)
				})
		}

		renderReports = () => {
				const { orderDocumentReports, i18n } = this.props
				const strDate = orderDocumentReports.get('filterDate') || ''
				const arrDate = strDate.split('_')

				let loading = orderDocumentReports.get('invoiceLoading')
				loading = loading || orderDocumentReports.get('truckReservationLoading')

				if (loading) {
						return <div className="text-center"><Spin /></div>
				}

				let arrReports = []
				const invoiceReports = this.getInvoiceReports().toJS()
				const truckReservationReports = this.getTruckReservationReports().toJS()
				moment.locale(i18n.language)

				for (let i = 0; this.isBetweenDate(i, arrDate); i++) {
						const date = moment(arrDate[0]).add(i, 'days').format('YYYY-MM-DD')

						if (invoiceReports[date] || truckReservationReports[date]) {
								const displayDate = moment(date).format('dddd D MMMM YYYY')
								const invoices = invoiceReports[date] || []
								const truckReservations = truckReservationReports[date] || []
								arrReports.push(
										<div key={date}>
												<h4>{displayDate}</h4>
												{(invoices && invoices.length) ? this.renderHeader('invoiceReports') : null}
												{this.renderInvoiceReports(invoices)}
												{truckReservations.length ? this.renderHeader('truckReservationReports') : null}
												{this.renderTruckReservationReports(truckReservations)}
												<Divider />
										</div>
								)
						}
				}

				if (arrReports.length === 0) {
						return <Empty />
				}

				return arrReports
		}

		render () {
				const { orderDocumentReports } = this.props
				const strDate = orderDocumentReports.get('filterDate')
				const saving = orderDocumentReports.get('saving')

				return (
						<InvoiceReportsWrapper>
								<Layout>
										<Layout>
												<Content className="text-right">
														<Select
																style={{ width: 220 }}
																value={strDate}
																onChange={this.handleFilterDate}>
																{this.renderFilterDateOption()}
														</Select>
												</Content>
										</Layout>
										<Layout>
												<Content>
														{this.renderReports()}
														<Modal
																centered
																closable={false}
																footer={null}
																style={{ height: 100 }}
																visible={saving}
																width="120">
																<Spin />
														</Modal>
												</Content>
										</Layout>
								</Layout>
						</InvoiceReportsWrapper>
				)
		}
}

const mapStateToProps = state => ({
    orderDocumentReports: state.getIn(['orderDocuments', 'reports']),
    orderBusinessAddressEntities: state.getIn(['Entities', 'orderBusinessAddresses']),
    truckReservationEntities: state.getIn(['Entities', 'truckReservations'])
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        fecthReports,
        saveInvoiceReport
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['documents'])(InvoiceReportsIndexContainer)
)

