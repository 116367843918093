import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Typography } from 'antd'

class EditText extends Component {
    static propTypes = {
        initialParams: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        value: PropTypes.string,
    }

    static defaultProps = {
        initialParams: {},
        value: ''
    }

    constructor (props) {
        super(props)

        this.state = {
            strValue: _.toString(props.value)
        }
    }

    onChange = strValue => {
        const { initialParams, onSave } = this.props

        onSave({ ...initialParams, value: strValue })
        this.setState({ strValue })
    }

    render () {
        return <Typography.Text editable={{ onChange: this.onChange }}>{this.state.strValue}</Typography.Text>
    }
}

export default EditText
