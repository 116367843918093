import React from 'react'
import { Tabs, Form } from 'antd'

import { withTranslation } from 'react-i18next'
import CustomerSheetContainer from './CustomerSheetContainer'
import CustomerNoteFormContainer from './CustomerNote/CustomerNoteFormContainer'
import ShippingAddressesIndexContainer from './ShippingAddresses/ShippingAdressesIndexContainer'
import BusinessAddressIndexContainer from './BusinessAddresses/BusinessAddressIndexContainer'
import OrderBusinessAddressesContainer from './OrderBusinessAddresses/OrderBusinessAddressesContainer'
import MailingAddressIndexContainer from './MailingAddresses/MailingAddressIndexContainer'
import PotentialLeadIndexContainer from './PotentialLeads/PotentialLeadIndexContainer'
import CustomerWalletsIndexContainer from '../CustomerWallets/CustomerWalletsIndexContainer'
import ProfitSharingIndexContainer from './ProfitSharing/ProfitSharingIndexContainer'
import CustomerCompanyProjectsContainer from './CompanyProjects/CustomerCompanyProjectsContainer'
import TruckImageIndexContainer from './TruckImages/TruckImagesIndexContainer'
import CustomerOrderPaymentDetailsListConatiner from './OrderPaymentDetails/OrderPaymentDetailsListConatiner'

const CustomerInfo = props => {
    const { TabPane } = Tabs
    const { customerId, i18n } = props

    return (
        <div>
            <Tabs defaultActiveKey="customerInfo">
                <TabPane tab={i18n.t('customerForm.customerInfo')} key="customerInfo">
                    <CustomerSheetContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.orderNote')} key="customerNote">
                    <CustomerNoteFormContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.locationDetail')} key="locationDetail">
                    <ShippingAddressesIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.taxInvoiceAddress')} key="taxInvoiceAddress">
                    <BusinessAddressIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.mailingAddress')} key="mailingAddress">
                    <MailingAddressIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.customerOrderBusinessAddress')} key="orderBusinessAddress">
                    <OrderBusinessAddressesContainer customerId={customerId} />
                </TabPane>
                <TabPane tab="Potential Leads" key="potentialLead">
                    <PotentialLeadIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.customerWallets')} key="customerWallets">
                    <CustomerWalletsIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.profitSharing')} key="profitSharing">
                    <ProfitSharingIndexContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.projects')} key="customerCompanyProjects">
                    <CustomerCompanyProjectsContainer customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('orderPaymentDetails')} key="orderPaymentDetails">
                    <CustomerOrderPaymentDetailsListConatiner customerId={customerId} />
                </TabPane>
                <TabPane tab={i18n.t('customerForm.truckImages')} key="itemTruckImages">
                    <TruckImageIndexContainer customerId={customerId} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default withTranslation('common')(CustomerInfo)
