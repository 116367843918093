import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import { apiFetch } from '../../../helpers/restRequest'

import { orderActivityLogOrdersSchema, orderActivityLogLineItemsSchema, lineItemActivitiesSchema } from '../../schema'
import activityLogsActions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const {
    ORDER_ACTIVITY_LOG_ORDER_FETCH_REQUEST,
    ORDER_ACTIVITY_LOG_ORDER_FETCH,
    ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS,
    ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION,
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_REQUEST,
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH,
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS,
    ORDER_LINE_ITEMS_ACTIVITY_FETCH_SUCCESS
} = activityLogsActions

export function *orderActivityLogOrderRequest () {
    yield takeEvery(ORDER_ACTIVITY_LOG_ORDER_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_ACTIVITY_LOG_ORDER_FETCH })

        const id = payload.orderId
        const page = payload.page

        const data = yield call(apiFetch, `/orders/${id}/activities?page=${page}&per=${PAGE_SIZE}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderActivityLogOrdersSchema)
        const total = data.meta.total_pages * PAGE_SIZE

        yield put({
            type: ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *orderActivityLogLineItemsRequest () {
    yield takeEvery(ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH })
        const { id } = payload

        const lineItems = yield call(apiFetch, `/orders/${id}/line_items?with_deleted=true`)
        const normalizeIndex = normalize(deserialize(lineItems), lineItemActivitiesSchema)

        yield put({
            type: ORDER_LINE_ITEMS_ACTIVITY_FETCH_SUCCESS,
            ...normalizeIndex
        })

        const lineItemsIndex = normalizeIndex.result
        const data = yield all(lineItemsIndex.map((id) => call(apiFetch, `/line_items/${id}/activities`)))
        const formattedData = data.map((itemData, index) => {
            const items = deserialize(itemData)
            return { id: lineItemsIndex[index], type: 'line_item_activity', items }
        })

        const normalizeData = normalize(formattedData, orderActivityLogLineItemsSchema)

        yield put({
            type: ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(orderActivityLogOrderRequest),
        fork(orderActivityLogLineItemsRequest)
    ])
}
