import { all, fork, takeLatest, put, call } from 'redux-saga/effects'
import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'
import { normalize } from 'normalizr'

import actions from './actions'
import { truckOriginAddressesSchema, truckOriginAddressSchema } from '../schema'

const {
    TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL,
    TRUCK_ORIGIN_ADDRESSES_FETCH_REQUEST,
    TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS,
    TRUCK_ORIGIN_ADDRESSES_FETCHING,
    TRUCK_ORIGIN_ADDRESS_FETCH_FAIL,
    TRUCK_ORIGIN_ADDRESS_FETCH_REQUEST,
    TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS,
    TRUCK_ORIGIN_ADDRESS_FETCH
} = actions

export function *fetchTruckOriginAddressesSaga () {
    yield takeLatest(TRUCK_ORIGIN_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        const { query, page, per, onSuccess, onError } = payload
        const params = `query=${query}&page=${page}&per=${per}`

        yield put({ type: TRUCK_ORIGIN_ADDRESSES_FETCHING })

        try {
            const data = yield call(apiFetch, `/truck_origin_addresses?${params}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, truckOriginAddressesSchema)

            yield put({
                type: TRUCK_ORIGIN_ADDRESSES_FETCH_SUCCESS,
                ...normalizedData,
                query
            })

            onSuccess()
        } catch (error) {
            yield put({ type: TRUCK_ORIGIN_ADDRESSES_FETCH_FAIL })
            onError()
        }
    })
}

export function *fetchTruckOriginAddressSaga () {
    yield takeLatest(TRUCK_ORIGIN_ADDRESS_FETCH_REQUEST, function *({ payload }) {
        const { id, onSuccess, onError } = payload

        yield put({ type: TRUCK_ORIGIN_ADDRESS_FETCH })

        try {
            const data = yield call(apiFetch, `/truck_origin_addresses/${id}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, truckOriginAddressSchema)

            yield put({
                type: TRUCK_ORIGIN_ADDRESS_FETCH_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: TRUCK_ORIGIN_ADDRESS_FETCH_FAIL })
            onError()
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchTruckOriginAddressesSaga),
        fork(fetchTruckOriginAddressSaga)
    ])
}
