const actions = {
    CUSTOMER_NOTES_FETCH_REQUEST: 'CUSTOMER_NOTES_FETCH_REQUEST',
    CUSTOMER_NOTES_FETCH_SUCCESS: 'CUSTOMER_NOTES_FETCH_SUCCESS',
    CUSTOMER_NOTES_FETCH: 'CUSTOMER_NOTES_FETCH',
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_REQUEST: 'CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_REQUEST',
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS: 'CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_SUCCESS',
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS: 'CUSTOMER_NOTES_ORDER_NOTE_IMAGE_FETCH_SUCCESS',
    CUSTOMER_NOTES_ORDER_NOTE_IMAGE_SAVE_REQUEST: 'CUSTOMER_NOTES_ORDER_NOTE_IMAGE_SAVE_REQUEST',
    CUSTOMER_NOTES_SAVE_REQUEST: 'CUSTOMER_NOTES_SAVE_REQUEST',
    CUSTOMER_NOTES_SAVE_SUCCESS: 'CUSTOMER_NOTES_SAVE_SUCCESS',
    CUSTOMER_NOTES_SAVE: 'CUSTOMER_NOTES_SAVE',
    CUSTOMER_NOTES_SET_FORM_ERRORS: 'CUSTOMER_NOTES_SET_FORM_ERRORS',
    CUSTOMER_NOTES_SET_PAGINATION: 'CUSTOMER_NOTES_SET_PAGINATION',
    ORDER_NOTES_FETCH_REQUEST: 'ORDER_NOTES_FETCH_REQUEST',
    ORDER_NOTES_FETCH_SUCCESS: 'ORDER_NOTES_FETCH_SUCCESS',
    ORDER_NOTES_FETCH: 'ORDER_NOTES_FETCH',

    fetchCustomerNote: (id, page = 1) => ({
        type: actions.CUSTOMER_NOTES_FETCH_REQUEST,
        payload: { id, page }
    }),

    fetchOrderNote: (id, page = 1) => ({
        type: actions.ORDER_NOTES_FETCH_REQUEST,
        payload: { id, page }
    }),

    saveCustomerNote: (customerNote, id, { onSuccess = () => {} } = {}) => ({
        type: actions.CUSTOMER_NOTES_SAVE_REQUEST,
        payload: { customerNote, id, onSuccess }
    }),

    saveOrderNoteImageOfCustomerNote: (orderNumber, orderNoteImage, {
        onSuccess = () => {},
        onUploadProgress = () => {}
    } = {}) => ({
        type: actions.CUSTOMER_NOTES_ORDER_NOTE_IMAGE_SAVE_REQUEST,
        payload: { orderNumber, orderNoteImage, onSuccess, onUploadProgress }
    }),

    deleteOrderNoteImageOfCustomerNote: (orderNoteImageId) => ({
        type: actions.CUSTOMER_NOTES_ORDER_NOTE_IMAGE_DELETE_REQUEST,
        payload: { orderNoteImageId }
    })
}

export default actions
