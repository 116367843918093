import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation, useQuery } from '@apollo/client'

import { Spin } from 'antd'
import { RedoOutlined } from '@ant-design/icons'

const SHIPPING_COST_QUERY = gql`
  query ShippingCost($id: ID!){
    shippingCost(id: $id) {
      id
      sendLalamove {
        id
        serviceType
        pickupLocation
        shippingAddresses {
          id
          address
        }
      }
    }
  }
`

const REQUOTATION_MUTATION = gql`
  mutation LalamoveRequotation(
    $orderNumber: String!, 
    $params: AdminQuotationInput!, 
    $shippingCostId: ID!
  ){
    lalamoveRequotation(
      orderNumber: $orderNumber, 
      params: $params, 
      shippingCostId: $shippingCostId
    ){
      errors {
        message
        key
      }
    }
  }
`

const RequotationLalamove = ({
  shippingCostId,
  orderId,
  fetchOrder
}) => {
  const [errorsMessage, setErrors] = useState(null)

  const { loading, data, error } = useQuery(SHIPPING_COST_QUERY, { 
    variables: { id: shippingCostId } 
  })

  const [Requotation, { loading: saving }] = useMutation(REQUOTATION_MUTATION, {
    onCompleted: ({ lalamoveRequotation }) => {
      if (lalamoveRequotation.errors) {
        setErrors(lalamoveRequotation.errors.message)
      } else {
        setErrors(null)
        fetchOrder()
      }
    }
  })

  const handleSubmit = (params) => {
    Requotation({
      variables: { params: params, orderNumber: orderId, shippingCostId: shippingCostId }
    })
  }

  const onSubmit = (values) => {
    const params = {
      serviceType: values['serviceType'],
      stockLocation: values['pickupLocation'],
      locations: shippingAddresses
    }

    handleSubmit(params)
  }

  if (loading) return <Spin />

  const sendLalamoveData = data.shippingCost.sendLalamove
  const shippingAddresses = sendLalamoveData.shippingAddresses.map(
    shippingAddress => shippingAddress.address
  )

  return (
    <span>
      <a onClick={() => onSubmit(sendLalamoveData)}> <RedoOutlined /> </a>
    </span>
  )
}

RequotationLalamove.propTypes = {}

RequotationLalamove.defaultProps = {}

export default RequotationLalamove

