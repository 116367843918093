import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Select } from 'antd'

const renderOptions = (options = []) => options.map(({ id, name }) => {
  return <Select.Option value={id}>{name}</Select.Option>
})

const SelectCustomerCompaniesRender = ({
  companies,
  onChange,
  loading,
  value,
  ...restProps
}) => {
  const [selected, setSelected] = useState(value)

  useEffect(() => {
    if (selected !== value) {
        handleChange(value)
    }
  }, [value])


  const handleChange = (value, option = {}) => {
    onChange(value, option)
    setSelected(value)
  }

  return (
    <Select
      allowClear
      filterOption={false}
      loading={loading}
      onChange={handleChange}
      placeholder='Select company'
      value={selected}
      {...restProps}
    >
      {renderOptions(companies)}
    </Select>
  )
}

SelectCustomerCompaniesRender.propTypes = {}

SelectCustomerCompaniesRender.defaultProps = {}

export default SelectCustomerCompaniesRender
