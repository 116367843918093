import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { List } from 'immutable'

import { Table } from 'antd'

import activityLogsAction from '../../../redux/orders/activityLogs/actions'

const { fetchActivityLogLineItems } = activityLogsAction

class ActivityLogLineItemsContainer extends Component {
    componentDidMount () {
        const { id, fetchActivityLogLineItems } = this.props
        fetchActivityLogLineItems(id)
    }


  renderContent = (value, item, index) => {
      const obj = {
          children: value,
          props: {}
      }
      if (item.type === 'header') {
          obj.props.colSpan = 0
      }
      return obj
  }

  renderPriceColumn = (value, item, index) => {
      const obj = {
          children: '',
          props: {}
      }
      if (item.type === 'header') {
          obj.props.colSpan = 0
      } else if (item.type === 'item') {
          if (value === 'N/A') {
              obj.children = this.props.i18n.t('inquiries:activityLogs.N/A')
          } else {
              obj.children = value
          }
      }
      return obj
  }

  renderEventColumn = (value, item, index) => {
      let style
      const obj = {
          children: '',
          props: {}
      }
      if (item.type === 'header') {
          obj.props.colSpan = 0
      } else if (item.type === 'item') {
          if (item.event === 'update') {
              style = { color: '#1890ff' }
          } else if (item.event === 'create') {
              style = { color: '#00b380' }
          } else if (item.event === 'destroy') {
              style = { color: '#f5222d' }
          }
          obj.children = <font style={style}> {this.props.i18n.t(`inquiries:activityLogs.${item.event}`)} </font>
      }
      return obj
  }

  orderActivityLogLineItemsData = () => {
      const { entities, orderActivityLogs } = this.props
      let list = []
      orderActivityLogs.get('lineItemItems').map((id, key) => {
          const header = {
              key: `item-${key + 1}-index-0`,
              number: key + 1,
              type: 'header',
              name: entities.getIn(['lineItemActivities', id, 'item', 'name'])
          }
          const orderActivityLogLineItems = entities.getIn(['orderActivityLogLineItems', id, 'items'], new List())
          const items = orderActivityLogLineItems.map((item, index) => {
              return (
                  {
                      key: `item-${key + 1}-index-${index + 1}`,
                      number: index + 1,
                      type: 'item',
                      updatedAt: item.getIn(['localized_time', 'updated_at']),
                      username: orderActivityLogLineItems.getIn([index, 'whodunnit_name']),
                      event: orderActivityLogLineItems.getIn([index, 'event']),
                      updatedQuantity: orderActivityLogLineItems.getIn([index, 'quantity']),
                      updatedSinglePrice: orderActivityLogLineItems.getIn([index, 'single_price']),
                      updatedTotalPrice: orderActivityLogLineItems.getIn([index, 'total_price'])
                  }
              )
          })
          list = list.concat(header, items.toJS())
      })
      return list
  }

  render () {
      const { i18n, orderActivityLogs } = this.props
      const lineItemLoading = orderActivityLogs.get('lineItemLoading')

      const activityLogLineItemsColumn = [
          {
              title: i18n.t('inquiries:number'),
              key: 'no',
              render: (value, item, index) => {
                  if (item.type === 'header') {
                      const style = { backgroundColor: '#e1e1e1', fontWeight: '500' }
                      return {
                          children: `${item.number} Item ${item.name}`,
                          props: {
                              colSpan: activityLogLineItemsColumn.length,
                              style: style
                          }
                      }
                  } else if (item.type === 'item') {
                      return item.number
                  }
              }
          }, {
              title: i18n.t('inquiries:activityLogs.updatedAt'),
              dataIndex: 'updatedAt',
              key: 'updatedAt',
              render: this.renderContent
          }, {
              title: i18n.t('inquiries:activityLogs.username'),
              dataIndex: 'username',
              key: 'username',
              render: this.renderContent
          }, {
              title: i18n.t('inquiries:activityLogs.event'),
              key: 'event',
              render: (value, item, index) => this.renderEventColumn(value, item, index)
          }, {
              title: i18n.t('inquiries:activityLogs.updatedQuantity'),
              dataIndex: 'updatedQuantity',
              key: 'updatedQuantity',
              render: this.renderContent
          }, {
              title: i18n.t('inquiries:activityLogs.updatedSinglePrice'),
              dataIndex: 'updatedSinglePrice',
              key: 'updatedSinglePrice',
              render: (value, item, index) => this.renderPriceColumn(value, item, index)
          }, {
              title: i18n.t('inquiries:activityLogs.updatedTotalPrice'),
              dataIndex: 'updatedTotalPrice',
              key: 'updatedTotalPrice',
              render: (value, item, index) => this.renderPriceColumn(value, item, index)
          }
      ]

      return (
          <div>
              <h3> {i18n.t('inquiries:activityLogs.lineItems')} </h3>
              <Table
                  scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                  columns={activityLogLineItemsColumn}
                  dataSource={this.orderActivityLogLineItemsData()}
                  pagination={false}
                  loading={lineItemLoading}
              />
          </div>
      )
  }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    orderActivityLogs: state.get('orderActivityLogs')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchActivityLogLineItems
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('inquiries')(ActivityLogLineItemsContainer))
