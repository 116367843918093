import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { Select, Spin } from 'antd'

class TruckTypeSelect extends Component {
    renderOptions () {
        const { reservationTruckTypes } = this.props

        return reservationTruckTypes.map((reservationTruckType) => {
            return (
                <Select.Option value={reservationTruckType.get('id')}
                    key={reservationTruckType.get('id')}>
                    {reservationTruckType.get('name')}
                </Select.Option>
            )
        })
    }

    renderSelect () {
        const { i18n, loading, onChange, value } = this.props
        return (
            <Select
                allowClear
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={onChange}
                loading={loading}
                placeholder={i18n.t('truckReservation:selectTruckType')}
                showSearch
                style={{ width: '100%' }}
                value={value}>
                {this.renderOptions()}
            </Select>
        )
    }
    render () {
        return (
            <div>{this.renderSelect()}</div>
        )
    }
}

export default withTranslation('truckReservation')(TruckTypeSelect)
