import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Form, Input } from 'antd'
import { formItemLayout, renderFooter } from './form'

const ReissueInvoiceNoteForm = ({ errors, onClose, onSubmitForm, saving }) => {
  const { i18n } = useTranslation(['confirms'])
  const [form] = Form.useForm()

  const handleSubmitForm = (values) => {
    onSubmitForm({
      ...values
    })
  }

  return (
    <Form form={form} onFinish={handleSubmitForm} {...formItemLayout}>
      <h2>{i18n.t('confirms:invoice.reissueInvoiceNote')}</h2>

      <Form.Item
        {...formItemLayout}
        label={i18n.t('confirms:invoice.note')}
        name={['previous_re_invoice_attributes', 'note']}
        hasFeedback
        validateStatus={errors['previous_re_invoice.note'] && 'error'}
        help={errors['previous_re_invoice.note']}>
        <Input.TextArea />
      </Form.Item>

      {renderFooter(i18n, saving, onClose)}
    </Form>
  )
}

ReissueInvoiceNoteForm.propTypes = {
  errors: PropTypes.object,
  onClose: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  saving: PropTypes.bool
}

ReissueInvoiceNoteForm.defaultProps = {
  errors: {}
}

export default ReissueInvoiceNoteForm
