import { Map, List } from 'immutable'
import actions from './actions'
import { PAGE_SIZE } from '../../../constants/orders'

const { CUSTOMER_REVIEWS_FETCH, CUSTOMER_REVIEWS_FETCH_SUCCESS } = actions

const initState = new Map({
    items: new List(),
    loading: false,
    pagination: new Map({
        current: null,
        pageSize: PAGE_SIZE,
        total: null
    })
})

export default function customerReviewsReducer(state = initState, action) {
    const items = state.get('items')
    const result = action.result

    switch (action.type) {
        case CUSTOMER_REVIEWS_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })
        case CUSTOMER_REVIEWS_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: new List(result)
            })
        default:
            return state
    }
}
