import React from 'react'
import PropTypes from 'prop-types'

import CommentForm from '../../components/Comments/Form'

const CommentFormContainer = ({
    attachmentFieldName,
    descriptionFieldName,
    frontPart,
    onSaveComment,
    onRemoveAttachment,
    onUploadAttachment,
    saving
}) => {
    const handleRemoveImage = (fileList, file) => {
        if (!onRemoveAttachment) return null

        const index = fileList.findIndex((fileItem) => fileItem.uid === file.uid)

        onRemoveAttachment(index)
    }

    const handleUploadAttachment = (file, { onProgress = () => {}, onSuccess = () => {} } = {}) => {
        if (!onUploadAttachment) return onSuccess()

        onUploadAttachment(file, { onProgress, onSuccess })
    }

    const onSubmitForm = (values, form) => {
        onSaveComment(values, form)
    }

    return (
        <CommentForm
            attachmentFieldName={attachmentFieldName}
            descriptionFieldName={descriptionFieldName}
            frontPart={frontPart}
            onSubmitForm={onSubmitForm}
            onRemoveAttachment={handleRemoveImage}
            onUploadAttachment={handleUploadAttachment}
            saving={saving}
        />
    )
}

CommentFormContainer.propTypes = {
    attachmentFieldName: PropTypes.string,
    descriptionFieldName: PropTypes.string,
    frontPart: PropTypes.element,
    onSaveComment: PropTypes.func.isRequired,
    onRemoveAttachment: PropTypes.func,
    onUploadAttachment: PropTypes.func,
    saving: PropTypes.bool
}

CommentFormContainer.defaultProps = {
    attachmentFieldName: 'attachment',
    descriptionFieldName: 'description'
}

export default CommentFormContainer
