import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Form, Space } from 'antd'
import VehicleTypeSelector from '../../../pickupPoint/vehicleTypeSelector'
import PickupPointSelector from '../../../pickupPoint/pickupPointSelector'
import SendingPointSelector from '../../../pickupPoint/sendingPointSelector'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
    }
}

const Render = ({ formErrors, onClose, onSubmitForm, saving }) => {
    const { i18n } = useTranslation('inquiries')
    const [form] = Form.useForm()
    const [vehicleType, setVehicleType] = useState(undefined)
    const [pickupPoint, setPickupPoint] = useState(undefined)

    const onChangeVehicleType = value => {
        setVehicleType(value)
        setPickupPoint(undefined)
        form.setFieldsValue({ pickupPointId: undefined, sendingPointId: undefined })
    }

    const onChangePickupPoint = value => {
        setPickupPoint(value)
        form.setFieldsValue({ sendingPointId: undefined })
    }

    return (
        <Form form={form} onFinish={onSubmitForm} {...formItemLayout}>
            <h3>{i18n.t('inquiries:shippingCost.pickupAndSendingPoint')}</h3>

            <Form.Item
                label={i18n.t('inquiries:shippingCost.vehicleType')}
                name="vehicleTypeId"
                hasFeedback={formErrors['vehicle_type']}
                validateStatus={formErrors['vehicle_type'] && 'error'}
                help={formErrors['vehicle_type']}
            >
                <VehicleTypeSelector onChange={onChangeVehicleType} />
            </Form.Item>
            <Form.Item
                label={i18n.t('inquiries:shippingCost.pickupPoint')}
                name="pickupPointId"
                hasFeedback={formErrors['pickup_point']}
                validateStatus={formErrors['pickup_point'] && 'error'}
                help={formErrors['pickup_point']}
            >
                <PickupPointSelector
                    vehicleTypeId={vehicleType}
                    onChange={onChangePickupPoint} />
            </Form.Item>
            <Form.Item
                label={i18n.t('inquiries:shippingCost.sendingPoint')}
                hasFeedback={formErrors['sending_point']}
                validateStatus={formErrors['sending_point'] && 'error'}
                help={formErrors['sending_point']}
            >
                <SendingPointSelector
                    form={form}
                    formItemName="sendingPointId"
                    pickupPointId={pickupPoint} />
            </Form.Item>

            <Space style={{ width: '100%', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit" disabled={saving}>
                    {i18n.t('shared:save')}
                </Button>
                <Button onClick={onClose}>{i18n.t('shared:close')}</Button>
            </Space>
        </Form>
    )
}

Render.propTypes = {
    formErrors: PropTypes.object,
    onClose: PropTypes.func,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool
}

Render.defaultProps = {
    formErrors: {}
}

export default Render
