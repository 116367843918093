import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import _ from 'lodash'

import SelectInvoiceLineItems from '../../components/Invoices/SelectInvoiceLineItems'

import inquiryActions from '../../redux/inquiry/actions'

const { fetchInquiryLineItems } = inquiryActions

class SelectInvoiceLineItemsContainer extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        orderId: PropTypes.string.isRequired,
        value: PropTypes.array,

        fetchInquiryLineItems: PropTypes.func.isRequired,
        inquiry: ImmutablePropTypes.map.isRequired,
        lineItemEntities: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        onChange: () => {},
        value: []
    }

    getDefaultLineItems () {
        const { value } = this.props

        return value.map((invoiceLineItem) => {
            return {
                id: _.toString(_.get(invoiceLineItem, 'item_id', '')),
                item_type: 'LineItem',
                name: _.get(invoiceLineItem, 'name', ''),
                quantity: _.get(invoiceLineItem, 'quantity', 0),
                unit: _.get(invoiceLineItem, 'unit', ''),
                single_price: _.get(invoiceLineItem, 'money_display.single_price', '0'),
                empty_line_below: _.get(invoiceLineItem, 'empty_line_below', '')
            }
        })
    }

    getLineItems () {
        const { inquiry, lineItemEntities } = this.props

        return inquiry.get('lineItems', new List()).map((lineItemId) => {
            return lineItemEntities.get(_.toString(lineItemId), new Map())
        })
    }

    handleFetchInitialData = ({ onSuccess } = {}) => {
        const { fetchInquiryLineItems, orderId } = this.props

        fetchInquiryLineItems(orderId, 1, true, { onSuccess })
    }

    onSelectLineItemsChange = (selectedLineItems) => {
        const { onChange, value } = this.props

        const selectedInvoiceLineItems = selectedLineItems.map((lineItem) => {
            const invoiceLineItem = _.find(value, (invoiceLineItem) => {
                return _.toString(_.get(invoiceLineItem, 'item_id', '')) === lineItem.id
            })
            const price = _.get(lineItem, 'single_price', '0')

            return {
                id: _.get(invoiceLineItem, 'id', ''),
                item_id: _.get(lineItem, 'id', ''),
                item_type: 'LineItem',
                name: _.get(lineItem, 'name', ''),
                quantity: _.get(lineItem, 'quantity', 0),
                unit: _.get(lineItem, 'unit', ''),
                empty_line_below: _.get(lineItem, 'empty_line_below', ''),
                single_price: price ? price : '0',
                _destroy: _.get(lineItem, '_destroy', false)
            }
        })

        onChange(selectedInvoiceLineItems)
    }

    render () {
        const { inquiry, orderId } = this.props

        return (
            <SelectInvoiceLineItems
                defaultLineItems={this.getDefaultLineItems()}
                initialData={this.getLineItems().toJS()}
                loading={inquiry.get('lineItemsLoading', false)}
                onFetchInitialData={this.handleFetchInitialData}
                onSelectLineItemsChange={this.onSelectLineItemsChange}
                orderId={orderId} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        inquiry: state.get('inquiry'),
        lineItemEntities: state.getIn(['Entities', 'lineItems'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchInquiryLineItems
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectInvoiceLineItemsContainer)
