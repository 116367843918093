import styled from 'styled-components'

const OrderSummariesStatisticsWrapper = styled.div`
  .card-container {
    .ant-card-body { display: -webkit-box; }
    .ant-card-grid-hoverable { cursor: pointer; }

    .card-active {
      background: #e6f7ff;
      border: 1px solid #91d5ff;
    }

    .statistic-formatter {
      .statistic-value {
        display: flex;
        justify-content: center;
        align-items: baseline;

        .value-unit {
          font-size: 16px;
          padding-left: 8px;
        }
      }

      .statistic-percentage {
        font-size: 16px;
      }

      .statistic-link {
        color: #1890FF;
        font-size: 14px;
      }
    }
  }

  .filter-group {
    margin-bottom: 16px;

    .label { margin-bottom: 4px; }
  }
`

export default OrderSummariesStatisticsWrapper
