import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { Map, List } from 'immutable'

import { Form, Input, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import EditorContainer from '../../../../Editor/EditorContainer'
import AreasSelectContainer from '../../../../Areas/AreasSelectContainer'

const inputItemLayout = {
  labelCol: { md: { span: 6 }, sm: { span: 6 } },
  wrapperCol: { md: { span: 16 }, sm: { span: 14 } }
}

const inputLinkLayout = {
  labelCol: { md: { span: 4 }, sm: { span: 4 } },
  wrapperCol: { md: { span: 18 }, sm: { span: 16 } }
}

const normFile = e => {
  if (Array.isArray(e)) {
      return e
  }
  return e && e.fileList
}

const ShippingAddressForm = ({
  shippingAddress,
  customerPhoneNumber,
  customerName,
  formErrors,
  form
}) => {
  const { i18n } = useTranslation('inquiries')
  const [fileList, setFileList] = useState([])

  const imagesEntities = useSelector(state => state.getIn(['Entities', 'images']))

  useEffect(() => {
    const images = shippingAddress.get('order_shipping_address_images') || new List()

    if (images.size > 0) {
      const imageList = images.map((image) => {
          return {
              uid: image.get('id'),
              url: imagesEntities.getIn([image.getIn(['file', 's']), 'thumbBlob'])
          }
      })

      setFileList(imageList.toJS())
    }
  }, [])

  const imageHandler = (file, imgBase64) => {
    const newItemOfFileList = {
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        name: file.name,
        originFileObj: file,
        percent: 0,
        size: file.size,
        thumbUrl: imgBase64,
        type: file.type,
        uid: `rc-${new Date().getTime()}`
    }
    form.setFieldsValue({
        order_shipping_address_attributes: {
            order_shipping_address_images_attributes: fileList.concat(newItemOfFileList)
        }
    })
    setFileList(fileList.concat(newItemOfFileList) )
  }

  const handleSetFileList = e => {
    setFileList(e.fileList)
  }

  const uploadImage = () => {
    return (
        <Upload.Dragger
            multiple
            listType="picture-card"
            showUploadList={{ showPreviewIcon: false }}
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleSetFileList}>
            <div>
                <PlusOutlined />
                <div>{i18n.t('order.image')}</div>
            </div>
        </Upload.Dragger>
    )
  }

  const provinceValue = shippingAddress && shippingAddress.getIn(['province', 'id'])
  const districtValue = shippingAddress && shippingAddress.getIn(['district', 'id'])
  const postalCodeValue = shippingAddress && shippingAddress.get('postal_code')
  const phoneNumber = (shippingAddress && shippingAddress.get('phone_number')) || customerPhoneNumber
  const contactName = (shippingAddress && shippingAddress.get('contact_name')) || customerName
  const shippingDetail = shippingAddress && shippingAddress.get('shipping_detail')
  const additionalInformation = shippingAddress && shippingAddress.get('additional_information')
  const orderShippingAddressId = (shippingAddress && shippingAddress.get('id')) || ''
  const linkUrl = shippingAddress && shippingAddress.get('link_url')

  const provinceErrors = formErrors.get('order_shipping_address.province')
  const districtErrors = formErrors.get('order_shipping_address.district')
  const postalCodeErrors = formErrors.get('order_shipping_address.postal_code')
  const phoneNumberErrors = formErrors.get('order_shipping_address.phone_number')
  const contactNameErrors = formErrors.get('order_shipping_address.contact_name')

  return (
    <div>
      <label className="title">{i18n.t('order.shippingAddress')}</label>
      <div key='address_section'>
            <Form.Item key="contact_name" {...inputItemLayout}
              name={['order_shipping_address_attributes', 'contact_name']}
              initialValue={contactName || null}
              label={i18n.t('order.contactName')}
              validateStatus={contactNameErrors ? 'error' : null}
              help={contactNameErrors}
              hasFeedback
          >
            <Input placeholder={i18n.t('order.phoneNumber')} />
          </Form.Item>
          <AreasSelectContainer
              key="area_select"
              form={form}
              provinceFieldName={['order_shipping_address_attributes', 'province_id']}
              districtFieldName={['order_shipping_address_attributes', 'district_id']}
              postalCodeFieldName={['order_shipping_address_attributes', 'postal_code']}
              provinceHelp={provinceErrors}
              districtHelp={districtErrors}
              postalCodeHelp={postalCodeErrors}
              provinceValidateStatus={provinceErrors ? 'error' : null}
              districtValidateStatus={districtErrors ? 'error' : null}
              postalCodeValidateStatus={postalCodeErrors ? 'error' : null}
              districtCol={{ md: { span: 24 } }}
              postalCodeCol={{ md: { span: 24 } }}
              provinceCol={{ md: { span: 24 } }}
              districtFormItemLayout={inputItemLayout}
              provinceFormItemLayout={inputItemLayout}
              postalCodeFormItemLayout={inputItemLayout}
              provinceValue={provinceValue}
              districtValue={districtValue}
              postalCodeValue={postalCodeValue} />
          <Form.Item key="phone_number" {...inputItemLayout}
              name={['order_shipping_address_attributes', 'phone_number']}
              initialValue={phoneNumber || null}
              label={i18n.t('order.phoneNumber')}
              validateStatus={phoneNumberErrors ? 'error' : null}
              help={phoneNumberErrors}
              hasFeedback
          >
            <Input placeholder={i18n.t('order.phoneNumber')} />
          </Form.Item>
          <Form.Item key="shipping_detail" {...inputItemLayout} label={i18n.t('order.shippingDetail')}
              name={['order_shipping_address_attributes', 'shipping_detail']}
              initialValue={shippingDetail || null}>
                  <Input placeholder={i18n.t('order.shippingDetail')} />
          </Form.Item>
          <Form.Item key="additional_information" label={i18n.t('order.additionalInformation')}
              name={['order_shipping_address_attributes', 'additional_information']}
              initialValue={additionalInformation || null}>
                  <Input.TextArea placeholder={i18n.t('order.enterAdditionalInformation')} />
          </Form.Item>
          <Form.Item key="link_url" label={i18n.t('addresses:linkUrl')} {...inputLinkLayout}
              name={['order_shipping_address_attributes', 'link_url']}
              initialValue={linkUrl || null}>
                  <Input placeholder="url" />
          </Form.Item>
          <Form.Item key="paste_image" label={i18n.t('addresses:pasteImageToUpload')} {...inputItemLayout}
              name='paste_image'>
                <EditorContainer theme="snow"
                    showToolbar={false}
                    placeholder={i18n.t('addresses:pasteImage')}
                    imageHandler={imageHandler}
                    />
          </Form.Item>
          <Form.Item key="images" label={i18n.t('order.image')}
              name={['order_shipping_address_attributes', 'order_shipping_address_images_attributes']}
              getValueFromEvent={normFile}>
                  {uploadImage()}
          </Form.Item>
          <Form.Item key="id"
              noStyle
              name={['order_shipping_address_attributes', 'id']}
              initialValue={orderShippingAddressId}>
                  <input type="hidden" />
          </Form.Item>
      </div>
    </div>
  )
}

ShippingAddressForm.propTypes = {
  shippingAddress: PropTypes.object,
  customerPhoneNumber: PropTypes.string,
  formErrors: PropTypes.object,
  form: PropTypes.object
}

ShippingAddressForm.defaultProps = {
  shippingAddress: new Map(),
  formErrors: new Map(),
  onSuccess: () => {},
  onCancel: () => {}
}

export default ShippingAddressForm
