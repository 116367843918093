import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'

import ProfitSharingIndex from '../../../components/customers/profitSharing/profitSharingIndex'

import actions from '../../../redux/customers/profitSharings/actions'

const { deleteCustomerProfitSharing, fetchCustomerProfitSharings, setCustomerProfitSharingFormModal } = actions

class ProfitSharingIndexContainer extends Component {
    static propTypes = {
        customerId: PropTypes.string.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        const { fetchCustomerProfitSharings, customerId } = this.props

        fetchCustomerProfitSharings({ customerId })
    }

    getProfitSharings () {
        const { profitSharings, profitSharingEntities } = this.props

        return profitSharings.get('items', new List()).map((profitSharingId) => {
            return profitSharingEntities.get(profitSharingId) || new Map()
        })
    }

    handleDeleteProfitSharing = (id) => {
        const { customerId, deleteCustomerProfitSharing } = this.props

        deleteCustomerProfitSharing({ customerId, id })
    }

    handlePageChange = (pagination) => {
        const { fetchCustomerProfitSharings, customerId } = this.props

        fetchCustomerProfitSharings({ customerId, page: pagination.current })
    }

    toggleProfitSharingForm = ({ id = null } = {}) => {
        const { profitSharings, setCustomerProfitSharingFormModal } = this.props

        setCustomerProfitSharingFormModal(!profitSharings.get('showProfitSharingForm'), id)
    }

    render () {
        const { customerId, profitSharings } = this.props

        return (
            <ProfitSharingIndex
                activeProfitSharing={profitSharings.get('profitSharingIdSelected')}
                customerId={customerId}
                loading={profitSharings.get('loading')}
                onDeleteProfitSharing={this.handleDeleteProfitSharing}
                onPageChange={this.handlePageChange}
                profitSharings={this.getProfitSharings().toJS()}
                pagination={{
                    ...profitSharings.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                showProfitSharingForm={profitSharings.get('showProfitSharingForm')}
                toggleProfitSharingForm={this.toggleProfitSharingForm}
            />
        )
    }
}

const mapStateToProps = state => ({
    profitSharings: state.get('profitSharings'),
    profitSharingEntities: state.getIn(['Entities', 'profitSharings'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        deleteCustomerProfitSharing,
        fetchCustomerProfitSharings,
        setCustomerProfitSharingFormModal
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfitSharingIndexContainer)
