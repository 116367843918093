import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImageContainer from './ImageContainer'
import ImageComponent from '../../components/images'

const ImageRenderer = ImageContainer(ImageComponent)

class Image extends Component {
    static propTypes = {
        contentType: PropTypes.string,
        isImageBlob: PropTypes.bool,
        originalUrl: PropTypes.string,
        thumbUrl: PropTypes.string.isRequired,
        noticeText: PropTypes.array
    }

    static defaultProps = {
        isImageBlob: true
    }

    render () {
        return (
            <ImageRenderer {...this.props} />
        )
    }
}

export default Image
