import styled from 'styled-components'

const TicketMembersWrapper = styled.div`
  .ticket-members-layout {
    display: flex;
    align-items: end;
    margin: 12px 0;

    .edit-ticket-members {
      cursor: pointer;
    }
  }
  
  .assign-to-osh-btn { margin-left: 8px; }
`

export default TicketMembersWrapper
