import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

import {
    Button,
    DatePicker,
    Form,
    Modal
} from 'antd'

import Icon from '@ant-design/icons'

import SelectBankAccount from '../../containers/SelectBankAccount'

const BankStatementExport = ({
    onSubmit,
    onToggleVisibleModal,
    saving,
    visibleModal,
    i18n
}) => {
    const handleSubmit = values => {
        let startDate = null
        let endDate = null
        if (values['range_picker']) {
            startDate = values['range_picker'][0].format('YYYY-MM-DD')
            endDate = values['range_picker'][1].format('YYYY-MM-DD')
        }

        const dateValues = {
            start_date: startDate,
            end_date: endDate
        }

        const newValues = {
            ...values,
            dateValues
        }

        onSubmit({ params: newValues })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }

    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 16, offset: 8 }
        }
    }

    return (
        <>
            <Button
                key="import-button"
                onClick={onToggleVisibleModal}
                type="primary"
            >
                {i18n.t('bankStatements:export')}
            </Button>

            <Modal
                footer={null}
                key="import-modal"
                onCancel={onToggleVisibleModal}
                title={i18n.t('bankStatements:export')}
                visible={visibleModal}
            >
                <Form
                    onFinish={handleSubmit}
                    {...formItemLayout}
                >
                    <Form.Item label={'เลือกวันที่'} name='range_picker'
                        rules={[{
                            required: true,
                            message: 'เลือกวันที่'
                        }]}>
                            <DatePicker.RangePicker />
                    </Form.Item>
                    <Form.Item
                        key="bank"
                        name='bankAccountId'
                        label={i18n.t('confirms:accounting.selectBank')}
                    >
                            <SelectBankAccount
                                allBank={true}
                            />
                    </Form.Item>

                    <Form.Item
                        key="actions"
                        {...formItemLayoutWithOutLabel}
                    >
                        <Button
                            htmlType="submit"
                            loading={saving}
                            type="primary"
                        >
                            {i18n.t('bankStatements:export')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

BankStatementExport.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    onToggleVisibleModal: PropTypes.func,
    saving: PropTypes.bool,
    visibleModal: PropTypes.bool
}

BankStatementExport.defaultProps = {
    onSubmit: () => {},
    onToggleVisibleModal: () => {},
    saving: false,
    visibleModal: false
}

export default withTranslation(['bankStatements', 'confirms'])(BankStatementExport)
