import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const CUSTOMER_ORDERS_QUERY = gql`
    query CustomerOrders($customerId: String!){
        customer(customerNo: $customerId){
            id
            orders{
                id
                number
            }
        }
    }
`

const onFilterOptions = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

const renderOptions = (options = []) => options.map(({ id, number }) => {
    return <Select.Option value={id}>{number}</Select.Option>
})

const OrderSelector = ({ customerId, ...restProps }) => {
    const { loading, data } = useQuery(CUSTOMER_ORDERS_QUERY, { variables: { customerId } })

    return (
        <Select
            filterOption={onFilterOptions}
            loading={loading}
            labelInValue
            {...restProps}
        >
            {renderOptions(data && data.customer && data.customer.orders)}
        </Select>
    )
}

OrderSelector.propTypes = {
    customerId: PropTypes.string.isRequired
}

OrderSelector.defaultProps = {}

export default OrderSelector
