import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../App/layout'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import ContentLayout from '../../components/utility/layoutContent'
import RequestItemsIndexContainer from './RequestItemsIndexContainer'

class RequestItemIndexPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Request Item | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/request_item', name: 'Request Item' }
                ]}>
                    <ContentLayout>
                        <RequestItemsIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default RequestItemIndexPage
