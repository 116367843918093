const actions = {
    ORDER_SUMMARY_DASHBOARDS_FETCH_REQUEST: 'ORDER_SUMMARY_DASHBOARDS_FETCH_REQUEST',
    ORDER_SUMMARY_DASHBOARDS_FETCH: 'ORDER_SUMMARY_DASHBOARDS_FETCH',
    ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS: 'ORDER_SUMMARY_DASHBOARDS_FETCH_SUCCESS',
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_REQUEST: 'ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_REQUEST',
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS: 'ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS',
    ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS: 'ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_SUCCESS',
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_REQUEST: 'ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_REQUEST',
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES: 'ORDER_SUMMARY_DASHBOARDS_FETCH_SALES',
    ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS: 'ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_SUCCESS',

    ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION: 'ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION',
    ORDER_SUMMARY_DASHBOARDS_SET_SUPPLIER_ORDER_FORM: 'ORDER_SUMMARY_DASHBOARDS_SET_SUPPLIER_ORDER_FORM',
    ORDER_SUMMARY_DASHBOARDS_SET_ORDER_ID: 'ORDER_SUMMARY_DASHBOARDS_SET_ORDER_ID',
    ORDER_SUMMARY_DASHBOARDS_SET_SORTER: 'ORDER_SUMMARY_DASHBOARDS_SET_SORTER',
    ORDER_SUMMARY_DASHBOARDS_SET_STATISTICS_FILTERS: 'ORDER_SUMMARY_DASHBOARDS_SET_STATISTICS_FILTERS',
    ORDER_SUMMARY_DASHBOARDS_SET_DEFAULT_DATE_TIME_TYPE: 'ORDER_SUMMARY_DASHBOARDS_SET_DEFAULT_DATE_TIME_TYPE',
    ORDER_SUMMARY_DASHBOARDS_SET_DATE_TYPE: 'ORDER_SUMMARY_DASHBOARDS_SET_DATE_TYPE',
    ORDER_SUMMARY_DASHBOARDS_SET_TIME_RANGE: 'ORDER_SUMMARY_DASHBOARDS_SET_TIME_RANGE',

    fetchOrderSummaryDashboards: ({
        query = '',
        state = '',
        page = 1,
        bySale = '',
        byCs = '',
        byPurchaser = '',
        startDate = '',
        endDate = '',
        startReservationDate = '',
        endReservationDate = '',
        byLeadSource = '',
        sorter = {},
        filterBy = {},
        isBigSales = false,
        byPotentialLead = '',
        isWaitingToShip = false,
        isWaitingForProduction = false,
        noPrice = '',
        createdStartDate = '',
        createdEndDate = '',
        withoutCs = '',
        withoutSale = '',
        isMyOrders = '',
        isOnlinePaid = '',
        dateType = '',
        taskState = '',
        provinceId = '',
        startPaymentDate = '',
        endPaymentDate = ''
    } = {}) => {
        return {
            type: actions.ORDER_SUMMARY_DASHBOARDS_FETCH_REQUEST,
            payload: {
                query,
                state,
                bySale,
                byCs,
                byPurchaser,
                startDate,
                endDate,
                startReservationDate,
                endReservationDate,
                byLeadSource,
                page,
                sorter,
                filterBy,
                isBigSales,
                byPotentialLead,
                isWaitingToShip,
                isWaitingForProduction,
                noPrice,
                createdStartDate,
                createdEndDate,
                withoutCs,
                withoutSale,
                isMyOrders,
                isOnlinePaid,
                dateType,
                taskState,
                startPaymentDate,
                endPaymentDate,
                provinceId
            }
        }
    },

    fetchOrderSummariesDashboardStatistics: ({ bySale = '', startDate = '', endDate = '' } = {}) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_FETCH_STATISTICS_REQUEST,
        payload: { bySale, startDate, endDate }
    }),

    fetchOrderSummarySales: ({ state = '', noPrice = '', startDate = '', endDate = '' } = {}) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_FETCH_SALES_REQUEST,
        payload: { state, noPrice, startDate, endDate }
    }),

    setPagination: (pagination) => {
        return { type: actions.ORDER_SUMMARY_DASHBOARDS_SET_PAGINATION, pagination }
    },

    setSupplierOrderFormModal: (value) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_SUPPLIER_ORDER_FORM,
        value
    }),

    setOrderId: (orderId) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_ORDER_ID,
        orderId
    }),

    setSorter: (sorter) => {
        return { type: actions.ORDER_SUMMARY_DASHBOARDS_SET_SORTER, sorter }
    },

    setStatisticsFilters: (filters) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_STATISTICS_FILTERS,
        filters
    }),

    setDefaultDateTimeType: (value) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_DEFAULT_DATE_TIME_TYPE,
        value
    }),

    setDateType: (value) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_DATE_TYPE,
        value
    }),

    setDefaultTimeRange: (timeRange) => ({
        type: actions.ORDER_SUMMARY_DASHBOARDS_SET_TIME_RANGE,
        timeRange
    }),
}

export default actions
