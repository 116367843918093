import { Map } from 'immutable'
import actions from './actions'

const {
    CUSTOMER_TICKET_SET_SHOW_FORM
} = actions

const initState = new Map({
    showForm: false
})

export default function customerTicketsReducer (state = initState, action) {
    switch (action.type) {
        case CUSTOMER_TICKET_SET_SHOW_FORM:
            return state.set('showForm', action.value)
        default:
            return state
    }
}
