const actions = {
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_REQUEST: 'ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_REQUEST',
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING: 'ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVING',
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS: 'ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_SUCCESS',
    ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL: 'ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_FAIL',

    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_REQUEST: 'ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_REQUEST',
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING: 'ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCHING',
    ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS: 'ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_SUCCESS',

    SET_SELECTED_PAYMENT_DETAIL_INSTALLMENT_FORM: 'SET_SELECTED_PAYMENT_DETAIL_INSTALLMENT_FORM',

    saveOrderPaymentDetailInstallment: ({
        orderId = '',
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {},
    } = {}) => {
        if (!orderId) return null
        return {
            type: actions.ORDER_PAYMENT_DETAIL_INSTALLMENT_SAVE_REQUEST,
            payload: { orderId, id, params, onSuccess, onError }
        }
    },

    fetchOrderPaymentDetailInstallments: (orderId) => {
        return {
            type: actions.ORDER_PAYMENT_DETAIL_INSTALLMENTS_FETCH_REQUEST,
            payload: { orderId }
        }
    },

    setInstallmentIdSelected: (id) => ({
        type: actions.SET_SELECTED_PAYMENT_DETAIL_INSTALLMENT_FORM,
        id
    })
}

export default actions
