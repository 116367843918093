import { all, put, call, fork, select, takeLatest } from 'redux-saga/effects'

import { apiFetch } from '../../helpers/restRequest'
import actions from './actions'
import provinceActions from '../provinces/actions'
import { districtsSchema } from '../schema'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'

const {
    DISTRICTS_FETCH,
    DISTRICTS_FETCH_REQUEST,
    DISTRICTS_FETCH_SUCCESS
} = actions

const {
    PROVINCES_FETCH_REQUEST
} = provinceActions

export function *districtsRequest () {
    yield takeLatest(DISTRICTS_FETCH_REQUEST, function *({ payload }) {
        const provinceId = payload.provinceId
        if (!provinceId) { return null }

        const provinces = yield select((state) => state.getIn(['provinces', 'items']))

        if (provinces.toJS().length === 0) {
            // Fetch all province before fetch any district
            yield put({ type: PROVINCES_FETCH_REQUEST })
        } else {
            const province = yield select((state) => {
                return state.getIn(['Entities', 'provinces', provinceId.toString()])
            })

            if (!province) { return null }

            // Not fetch district of any province again when they are already fetch
            if (province.get('districts')) { return null }
        }

        yield put({ type: DISTRICTS_FETCH })

        const data = yield call(apiFetch, `/provinces/${provinceId}/districts`)
        const formattedData = deserialize(data)
        const normalizedData = normalize(formattedData, districtsSchema)
        normalizedData.provinceId = provinceId

        yield put({
            type: DISTRICTS_FETCH_SUCCESS,
            ...normalizedData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(districtsRequest)
    ])
}
