import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import CompanyInfoContainer from './CompanyInfoContainer'

class CompanyShowPage extends React.Component {
    componentDidMount() {
        document.title = 'Company Info | OSH CRM'
    }

    render () {
      const { location, match } = this.props
      const { id } = match.params
      const number = location.params ? location.params.number : null
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/companies', name: 'Companies' },
                        { path: `/companies/${id}`, name: number || 'Info' }
                    ]}>
                    <ContentLayout>
                      <CompanyInfoContainer companyId={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CompanyShowPage
