import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map, List } from 'immutable'

import SupplierIndex from '../../components/Suppliers/supplierIndex'
import actions from '../../redux/suppliers/actions'

const { fetchSuppliers, searchSupplier } = actions

class SupplierIndexContainer extends Component {
    static propTypes = {
        fetchSuppliers: PropTypes.func.isRequired,
        supplierOverTransferAccountEntities: ImmutablePropTypes.map.isRequired,
        supplierEntities: ImmutablePropTypes.map.isRequired,
        suppliers: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        const { fetchSuppliers } = this.props

        fetchSuppliers()
    }

    handleSearch = (query) => {
        const { searchSupplier } = this.props

        searchSupplier({ query, page: 1 })
    }

    getSupplierOverTransferAccount = (id) => {
        const { supplierOverTransferAccountEntities } = this.props

        return supplierOverTransferAccountEntities.get(id) || new Map()
    }

    getSuppliers () {
        const { supplierEntities, suppliers } = this.props

        return suppliers.get('supplierItems', new List()).map((id) => {
            return supplierEntities.get(id) || new Map()
        })
    }

    handlePageChange = (pagination) => {
        const { searchSupplier, suppliers } = this.props

        searchSupplier({ query: suppliers.get('query'), page: pagination.current, per: 4 })
    }

    render () {
        const { suppliers } = this.props

        return (
            <SupplierIndex
                getSupplierOverTransferAccount={this.getSupplierOverTransferAccount}
                loading={suppliers.get('loading')}
                onPageChange={this.handlePageChange}
                onSearch={this.handleSearch}
                pagination={{
                    ...suppliers.get('pagination').toJS(),
                    showSizeChanger: false
                }}
                suppliers={this.getSuppliers().toJS()} />
        )
    }
}

const mapStateToProps = state => ({
    supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts']),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    suppliers: state.get('suppliers')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSuppliers,
        searchSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierIndexContainer)
