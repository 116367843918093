import { all, fork, takeLatest, put, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'

import actions from './actions'
import { reservationTruckTypesSchema } from '../schema'

const {
    RESERVATION_TRUCK_TYPES_FETCH_FAIL,
    RESERVATION_TRUCK_TYPES_FETCH_REQUEST,
    RESERVATION_TRUCK_TYPES_FETCH_SUCCESS,
    RESERVATION_TRUCK_TYPES_FETCHING
} = actions

export function *fetchReservationTruckTypesSaga () {
    yield takeLatest(RESERVATION_TRUCK_TYPES_FETCH_REQUEST, function *({ payload }) {
        const { query, page, per, onSuccess, onError } = payload
        const params = `query=${query}&page=${page}&per=${per}`

        yield put({ type: RESERVATION_TRUCK_TYPES_FETCHING })

        try {
            const data = yield call(apiFetch, `/reservation_truck_types?${params}`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, reservationTruckTypesSchema)

            yield put({
                type: RESERVATION_TRUCK_TYPES_FETCH_SUCCESS,
                ...normalizedData,
                query: query
            })

            onSuccess()
        } catch (error) {
            yield put({ type: RESERVATION_TRUCK_TYPES_FETCH_FAIL })
            onError()
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchReservationTruckTypesSaga)
    ])
}
