import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import moment from 'moment'

import { Divider, Table } from 'antd'

import actions from '../../../redux/salesSummary/actions'

const { fetchSaleProspects } = actions

class ProspectIndexContainer extends Component {
  static propTypes = {
      saleId: PropTypes.string.isRequired,
      selectedMonth: PropTypes.string
  }

  static defaultProps = {}

  componentDidMount () {
      const { fetchSaleProspects, saleId, selectedMonth } = this.props

      fetchSaleProspects({ saleId, selectedMonth })
  }

  getSaleProspects () {
      const { saleProspectEntities, saleSummaries } = this.props

      return saleSummaries.get('prospectItems', new List()).map((id) => {
          return saleProspectEntities.get(id, new Map())
      })
  }

  getColumns () {
      const { i18n } = this.props
      return [
          {
              key: 'order_number',
              title: i18n.t('sales:salesSummary.orderNumber'),
              render: (record) => (
                  <Link to={`/inquiries/${record.number}`}>
                      {record.number}
                  </Link>
              )
          },
          {
              key: 'grand_total',
              title: i18n.t('sales:salesSummary.grandTotal'),
              dataIndex: ['grand_total', 'display']
          },
          {
              key: 'order_state',
              title: i18n.t('sales:salesSummary.state'),
              dataIndex: 'state_label'
          }
      ]
  }

  handleChangePagination = (page) => {
      const { fetchSaleProspects, saleId, selectedMonth } = this.props

      fetchSaleProspects({ saleId, selectedMonth, page: page.current })
  }

  renderHeader () {
      const { selectedMonth, i18n } = this.props
      const date = selectedMonth ? moment(selectedMonth) : moment()

      return <h2>Prospect: {date.locale(i18n.language).format('MMMM YYYY')}</h2>
  }

  render () {
      const { saleSummaries } = this.props

      return (
          <div>
              {this.renderHeader()}
              <Divider />
              <Table
                  columns={this.getColumns()}
                  dataSource={this.getSaleProspects().toJS()}
                  loading={saleSummaries.get('prospectLoading')}
                  onChange={this.handleChangePagination}
                  pagination={{
                      ...saleSummaries.get('prospectPagination').toJS(),
                      showSizeChanger: false
                    }}
                  rowKey="id"
              />
          </div>
      )
  }
}

const mapStateToProps = state => ({
    saleSummaries: state.get('saleSummaries'),
    saleProspectEntities: state.getIn(['Entities', 'saleProspects'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSaleProspects
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('sales')(ProspectIndexContainer))
