import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Layout from '../../App/layout'
import ContentLayout from '../../../components/utility/layoutContent'
import LayoutContentWrapper from '../../../components/utility/layoutWrapper'
import BusinessAddressFormContainer from './BusinessAddressFormContainer'

class CustomerEditBusinessAddressPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    }

    componentDidMount() {
        document.title = 'Edit Business Address | OSH CRM'
    }

    render () {
        const { customer_id: customerId, id } = this.props.match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/customers', name: 'Customers' },
                    { path: `/customers/${customerId}`, name: `${customerId}` },
                    { path: `/customers/${customerId}`, name: 'Business Addresses' },
                    { path: `/customers/${customerId}/business_addresses/${id}/edit`, name: id },
                    { path: `/customers/${customerId}/business_addresses/${id}/edit`, name: 'Edit' }
                ]}>
                    <ContentLayout>
                        <BusinessAddressFormContainer
                                customerId={customerId}
                                addressId={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerEditBusinessAddressPage
