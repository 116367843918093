import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Map, List } from 'immutable'
import _ from 'lodash'

import reviewActions from '../../redux/inquiry/reviews/actions'

import ReviewForm from '../../components/inquiry/review/reviewForm'

const { saveReview } = reviewActions

class ReviewContainer extends Component {
    static propTypes = {
        orderId: PropTypes.string.isRequired,
        order: PropTypes.object.isRequired
    }

    handleSubmitForm = async (review) => {
        const { saveReview, order, orderId } = this.props
        const customerId = order.getIn(['customer', 'id'])

        await saveReview(review, customerId, orderId)
    }

    getCustomerReview(itemId) {
        if (!itemId) return new Map()

        const { customerReviewEntities } = this.props

        const customerReview = customerReviewEntities.toList().find((customerReview) => {
            return customerReview.get('reviewable_id') === itemId
        })
        if (!customerReview) return new Map()
        return customerReview
    }

    getLineItem(id) {
        if (!id) return new Map()

        const { lineItemEntities, order } = this.props
        const lineItem = lineItemEntities.get(_.toString(id), new Map())

        const customerReview = this.getCustomerReview(lineItem.get('item_id'))
        return lineItem.merge({
            order: order,
            customerReview: customerReview
        })
    }

    getLineItems() {
        const { inquiry } = this.props

        return inquiry.get('lineItems', new List()).map((lineItemId) => {
            return this.getLineItem(lineItemId)
        })
    }

    render() {
        const { orderId, order, customerReviews, inquiry, isLineItemsLoading } = this.props
        const stateSaving = order.get('saving')

        if (customerReviews.get('loading') || isLineItemsLoading) {
            return <div></div>
        } else {
            return (
                <div>
                    <ReviewForm
                        handleSubmitReview={this.handleSubmitForm}
                        orderId={orderId}
                        stateSaving={stateSaving}
                        lineItems={this.getLineItems()}
                    />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    inquiry: state.get('inquiry'),
    customerReviews: state.get('customerReviews'),
    lineItemEntities: state.getIn(['Entities', 'lineItems']),
    customerReviewEntities: state.getIn(['Entities', 'customerReviews'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            saveReview
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewContainer))
