import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {Button, Col, Modal, Row, RangePicker, Form, DatePicker, Divider} from 'antd'
import {UploadOutlined} from "@ant-design/icons";
import SelectBankAccount from "../../../containers/SelectBankAccount";
import {gql, useMutation} from "@apollo/client";

const EXPORT_TICKET_MUTATION = gql`
	mutation ExportTickets($startDate: String!, $endDate: String!, $filtered: String!) {
		exportTickets(startDate: $startDate, endDate: $endDate, filtered: $filtered) {
			errors
		}
	}
`

const Export = () => {
    const { i18n } = useTranslation('tickets')
    const [showExportForm, setShowExportForm] = useState(false)
    const [exportTickets, { loading }] = useMutation(EXPORT_TICKET_MUTATION, {
        onCompleted: ({ exportTickets }) => {
            if (exportTickets.errors) {
                console.error(exportTickets.errors)
            } else {
                setShowExportForm(!showExportForm)
            }
        }
    })

    const handleSubmit = values => {
        let startDate = null
        let endDate = null
        if (values['range_picker']) {
            startDate = values['range_picker'][0].format('YYYY-MM-DD')
            endDate = values['range_picker'][1].format('YYYY-MM-DD')
        }

        const date = {
            startDate: startDate,
            endDate: endDate
        }

        exportTickets({
            variables: { startDate: date.startDate, endDate: date.endDate, filtered: 'created_at' }
        })
    }

    return (
        <>
            <Button type="primary" onClick={() => setShowExportForm(!showExportForm) }>
                {i18n.t('tickets:export')}
            </Button>
            <Modal
                style={{
                    height: '600px',
                    textAlign: 'center',
                    verticalAlign: 'middle'
                }}
                centered
                width="500px"
                visible={showExportForm}
                onCancel={() => setShowExportForm(!showExportForm)}
                closable={false}
                footer={null}
            >
                <div>{i18n.t('tickets:export')}</div>
                <Divider style={{ margin: '12px' }}/>
                <Form
                    onFinish={handleSubmit}
                >
                    <Form.Item label={i18n.t('tickets:selectDate')} name='range_picker'
                               rules={[{
                                   required: true,
                                   message: i18n.t('tickets:selectDate')
                               }]}>
                        <DatePicker.RangePicker />
                    </Form.Item>

                    <Form.Item
                        key="actions"
                    >
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            {i18n.t('tickets:export')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

Export.propTypes = {
}

Export.defaultProps = {}

export default Export
