import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Map, List } from 'immutable'
import _ from 'lodash'

import { Button, Form, Input, Select, Radio, Spin } from 'antd'
import SelectOrderTransferBackItems from './SelectOrderTransferBackItems'

import { TRANSFER_BACK_BANK_ACCOUNTS } from '../../constants/supplierOrders'
import actions from '../../redux/inquiry/transferBacks/actions'

const RadioGroup = Radio.Group

const { fetchOrderTransferBack, fetchOrderTransferBackItems, setOrderTransferBackFormErrors } = actions

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 },
}

const formItemLayoutWithOutLabel = {
    wrapperCol: { span: 24 }
}

const OrderTransferBackForm = ({
    id,
    onSubmitForm,
    orderId,
    saving,
    toggleForm
}) => {
    const { i18n } = useTranslation(['inquiries', 'suppliers'])
    const dispatch = useDispatch()
    const initialData = useSelector(state => state.getIn(['Entities', 'orderTransferBacks', id], new Map()))
    const lineItems = useSelector(state => state.getIn(['inquiryTransferBacks', 'lineItems'], new List()))
    const formErrors = useSelector(state => state.getIn(['inquiryTransferBacks', 'formErrors'], new Map()))
    const orderTransferBackItemEntities = useSelector(state => state.getIn(['Entities', 'orderTransferBackItems']))
    const loading = useSelector(state => state.getIn(['inquiryTransferBacks', 'loading'], false))
    const lineItemsLoading = useSelector(state => state.getIn(['inquiryTransferBacks', 'lineItemsLoading']))
    const isDisabled = initialData.get('is_calculated', false)
    const [transferBackType, setTransferBackType] = useState('cash')

    const [form] = Form.useForm()

    useEffect(() => {
        if (id) {
            dispatch(fetchOrderTransferBack(orderId, id))
            dispatch(fetchOrderTransferBackItems(orderId, id))
        }

        // component-will-unmount
        return () => {
            dispatch(setOrderTransferBackFormErrors())
          }
    }, [])

    const getFieldErrors = (fieldName) => {
        const errors = formErrors.get(fieldName) || []

        if (_.isEmpty(errors)) { return {} }

        return {
            help: errors,
            status: 'error'
        }
    }

    const getInitialItems = () => {
        return lineItems.map((id) => {
            return orderTransferBackItemEntities.get(id) || new Map()
        })
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    const renderBankAccountSection = () => {
        if (transferBackType !== 'cash') { return null }

        const bankAccountErrors = getFieldErrors('bank_account')
        const bankNameErrors = getFieldErrors('bank_account.bank_name')
        const accountNameErrors = getFieldErrors('bank_account.account_name')
        const accountNumberErrors = getFieldErrors('bank_account.account_number')

        return (
            <div>
                <h3>{i18n.t('suppliers:transferForm.title')}</h3>
                <Form.Item label={i18n.t('suppliers:transferForm.bankName')}
                    name={['bank_account_attributes', 'bank_name']}
                    initialValue={initialData.getIn(['bank_account', 'bank_name'], null)}
                    validateStatus={bankNameErrors.status || bankAccountErrors.status}
                    help={bankNameErrors.help || bankAccountErrors.help}>
                        <Select placeholder={i18n.t('suppliers:transferForm.selectBankName')}>
                            {TRANSFER_BACK_BANK_ACCOUNTS.map((bankAccount) => {
                                return (
                                    <Select.Option key={bankAccount.value}
                                        value={bankAccount.value}>
                                        {bankAccount.i18n}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                </Form.Item>
                <Form.Item label={i18n.t('suppliers:transferForm.name')}
                    name={['bank_account_attributes', 'account_name']}
                    initialValue={initialData.getIn(['bank_account', 'account_name'], null)}
                    validateStatus={accountNameErrors.status || bankAccountErrors.status}
                    help={accountNameErrors.help || bankAccountErrors.help}>
                        <Input placeholder={i18n.t('suppliers:transferForm.name')} />
                </Form.Item>
                <Form.Item label={i18n.t('suppliers:transferForm.number')}
                    name={['bank_account_attributes', 'account_number']}
                    initialValue={initialData.getIn(['bank_account', 'account_number'], null)}
                    validateStatus={accountNumberErrors.status || bankAccountErrors.status}
                    help={accountNumberErrors.help || bankAccountErrors.help}>
                        <Input placeholder={i18n.t('suppliers:transferForm.number')} />
                </Form.Item>
            </div>
        )
    }

    if (loading || lineItemsLoading) { return <Spin /> }

    return (
        <Form onFinish={handleSubmitForm} {...formItemLayout} form={form}>
            <h2>{i18n.t('inquiries:transferBack.formTitle')}</h2>
            <Form.Item {...formItemLayoutWithOutLabel}
                name='items_attributes'>
                    <SelectOrderTransferBackItems
                        orderId={orderId}
                        form={form}
                        dataValue={getInitialItems().toJS() || []}
                        isDisabled={isDisabled} />
            </Form.Item>
            <Form.Item label={i18n.t('inquiries:transferBack.type')}
                name='order_transfer_back_type'
                initialValue={initialData.get('order_transfer_back_type') || 'cash'}>
                    <RadioGroup disabled={isDisabled} onChange={setTransferBackType}>
                        <Radio value="cash">
                            {i18n.t('inquiries:transferBack.types.cash')}
                        </Radio>
                        <Radio value="wallet">
                            {i18n.t('inquiries:transferBack.types.wallet')}
                        </Radio>
                        <Radio value="extra_payment">
                            {i18n.t('inquiries:transferBack.types.extraPayment')}
                        </Radio>
                    </RadioGroup>
            </Form.Item>
            {renderBankAccountSection()}
            <Form.Item style={{ textAlign: 'center' }} {...formItemLayoutWithOutLabel}>
                <Button type="primary" htmlType="submit" loading={saving}>
                    {i18n.t('shared:save')}
                </Button>
                <Button style={{ marginLeft: '12px' }} onClick={toggleForm}>
                    {i18n.t('shared:close')}
                </Button>
            </Form.Item>
        </Form>
    )
}

OrderTransferBackForm.propTypes = {
    id: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func
}

OrderTransferBackForm.defaultProps = {
    toggleForm: () => {}
}

export default OrderTransferBackForm
