import { all, call, fork, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import _ from 'lodash'

import { apiFetch, apiPostFormWithProgress, apiFileFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import actions from './actions'
import actionsNotification from '../../../redux/notifications/actions'
import { orderBusinessAddressSchema, orderBusinessAddressesSchema, orderMailingAddressSchema } from '../../schema'
import downLoadFile from '../../../helpers/downLoadFile'

const { setNotifications } = actionsNotification
const {
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL,
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_REQUEST,
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS,
    ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE,
    ORDER_BUSINESS_ADDRESS_SAVE_FAIL,
    ORDER_BUSINESS_ADDRESS_SAVE_REQUEST,
    ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS,
    ORDER_BUSINESS_ADDRESS_SAVING,
    ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST,
    ORDER_BUSINESS_ADDRESSES_FETCHING,
    ORDER_BUSINESS_ADDRESSES_SET_PAGINATION,
    ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
    ORDER_BUSINESS_ADDRESSES_REPORT_FETCH_REQUEST,
    ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING,
    ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS,
    ORDER_MAILING_ADDRESSES_SAVE_REQUEST,
    ORDER_MAILING_ADDRESSES_SAVING,
    ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS,
    ORDER_MAILING_ADDRESSES_SAVE_SUCCESS,
    ORDER_MAILING_ADDRESS_FETCH_REQUEST,
    ORDER_MAILING_ADDRESS_FETCHING,
    ORDER_MAILING_ADDRESS_FETCH_SUCCESS,
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST,
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING,
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
    SEND_TAX_INVOICES_MAIL_BY_FILTER_REQUEST,
    SEND_TAX_INVOICES_MAIL_BY_FILTER,
    SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS
} = actions

const apiOrderMailingAddresesPath = (orderId, id) => {
    const basePath = `/orders/${orderId}/mailing_addresses`

    if (!id) {
        return basePath
    }
    return `${basePath}/${id}`
}

export function *deleteTrackingImageSaga () {
    yield takeLatest(ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE })

        const { orderBusinessAddressId, onSuccess, onError } = payload

        try {
            const path = `/orders/business_addresses/${orderBusinessAddressId}/tracking_image`
            const data = yield call(apiFetch, path, 'DELETE')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderBusinessAddressSchema)

            yield put({
                type: ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS,
                ...normalizedData
            })

            onSuccess()
        } catch (error) {
            yield put({ type: ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL })
            onError(error)
        }
    })
}

export function *saveOrderBusinessAddressSaga () {
    yield takeLatest(ORDER_BUSINESS_ADDRESS_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_BUSINESS_ADDRESS_SAVING })

        const { orderId, id, params, onSuccess, onError, onUploadProgress } = payload
        const basePath = `/orders/${orderId}/business_addresses`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        try {
            const data = yield call(apiPostFormWithProgress, {
                path,
                method,
                params,
                onUploadProgress
            })
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderBusinessAddressSchema)

            yield put({
                type: ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS,
                ...normalizedData
            })
            onSuccess()
        } catch (error) {
            yield put({ type: ORDER_BUSINESS_ADDRESS_SAVE_FAIL })
            onError(error)
        }
    })
}

export function *fetchOrderBusinessAddressesSaga () {
    yield takeEvery(ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        const { page, sorter, invStartDate, invEndDate, sendingState, haveTrackingNumber } = payload
        const per = 100
        yield put({ type: ORDER_BUSINESS_ADDRESSES_FETCHING })

        const queryString = [
            `page=${page}`,
            `per=${per}`,
            `inv_start_date=${invStartDate}`,
            `inv_end_date=${invEndDate}`,
            `sending_state=${sendingState}`,
            `have_tracking_number=${haveTrackingNumber}`,
            `order=${_.get(sorter, 'value', '')}`
        ].join('&')

        const path = `/order_business_addresses?${queryString}`
        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderBusinessAddressesSchema)
            const total = data.meta.total_pages * per
            yield put({
                type: ORDER_BUSINESS_ADDRESSES_SET_PAGINATION,
                pagination: {
                    current: page,
                    total
                }
            })
            yield put({
                type: ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
                ...normalizedData,
                invStartDate,
                invEndDate,
                haveTrackingNumber,
                sendingState
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchOrderBusinessAddressesWithCustomerSaga () {
    yield takeEvery(CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_REQUEST, function *({ payload }) {
        const { customerId, page } = payload
        yield put({ type: CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING })
        const per = 100
        const path = `/customers/${customerId}/orders/business_addresses?page=${page}&per=${per}`

        try {
            const data = yield call(apiFetch, path, 'GET')
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderBusinessAddressesSchema)
            const total = data.meta.total_pages * per
            yield put({
                type: ORDER_BUSINESS_ADDRESSES_SET_PAGINATION,
                pagination: {
                    current: page,
                    total
                }
            })
            yield put({
                type: CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
                ...normalizedData,
                additionalData: {
                    sumOfOrderBusinessAddresses: data.meta.sum_of_grand_totals,
                    sizeOfOrderBusinessAddresses: data.meta.number_of_order_business_addresses
                }
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}


export function *receiveTaxInvoiceReportFile () {
    yield takeLatest(ORDER_BUSINESS_ADDRESSES_REPORT_FETCH_REQUEST, function *({ payload }) {
        const { invStartDate, invEndDate, sendingState, haveTrackingNumber } = payload
        yield put({ type: ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING, loading: true })

        const queryString = [
            `inv_start_date=${invStartDate}`,
            `inv_end_date=${invEndDate}`,
            `sending_state=${sendingState}`,
            `have_tracking_number=${haveTrackingNumber}`
        ].join('&')

        const fileExtension = 'xlsx'

        const path = `/tax_invoices/exports.${fileExtension}?${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `tax-invoice-report-${invStartDate}-to-${invEndDate}.${fileExtension}`
        })

        yield put({
            type: ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS,
            loading: false
        })
    })
}

export function *orderMailingAddressRequest () {
    yield takeEvery(ORDER_MAILING_ADDRESS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_MAILING_ADDRESS_FETCHING, loading: true })

        const { orderId, id } = payload
        const path = apiOrderMailingAddresesPath(orderId, id)

        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, orderMailingAddressSchema)

            yield put({
                type: ORDER_MAILING_ADDRESS_FETCH_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: ORDER_MAILING_ADDRESS_FETCHING, loading: false })
        }
    })
}

export function *saveOrderMailingAddress () {
    yield takeLatest(ORDER_MAILING_ADDRESSES_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: ORDER_MAILING_ADDRESSES_SAVING, saving: true })

        const { orderId, onSuccess, id, params } = payload

        const method = id ? 'PATCH' : 'POST'

        const path = apiOrderMailingAddresesPath(orderId, id)
        const body = JSON.stringify({ order_mailing_address: params })

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })

                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: ORDER_MAILING_ADDRESSES_SAVE_SUCCESS,
                    ...normalize(formattedData, orderMailingAddressSchema)
                })

                onSuccess()

                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: ORDER_MAILING_ADDRESSES_SAVING,
                saving: false
            })
        }
    })
}

export function *sendTaxInvoicemailByFilters () {
    yield takeLatest(SEND_TAX_INVOICES_MAIL_BY_FILTER_REQUEST, function *({ payload }) {
        yield put({ type: SEND_TAX_INVOICES_MAIL_BY_FILTER, mailsSending: true })

        const { invStartDate, invEndDate, sendingState, haveTrackingNumber, onSuccess, onError } = payload

        const queryString = [
            `inv_start_date=${invStartDate}`,
            `inv_end_date=${invEndDate}`,
            `sending_state=${sendingState}`,
            `have_tracking_number=${haveTrackingNumber}`
        ].join('&')

        const path = `/tax_invoices/send_mail_by_filters?${queryString}`

        try {
            yield call(apiFetch, path)

            yield put({
                type: SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS,
                mailsSending: false
            })

            onSuccess()
        } catch (error) {
            onError()
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(deleteTrackingImageSaga),
        fork(saveOrderBusinessAddressSaga),
        fork(fetchOrderBusinessAddressesSaga),
        fork(fetchOrderBusinessAddressesWithCustomerSaga),
        fork(receiveTaxInvoiceReportFile),
        fork(orderMailingAddressRequest),
        fork(saveOrderMailingAddress),
        fork(sendTaxInvoicemailByFilters)
    ])
}
