import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Spin } from 'antd'
import CommentFormContainer from '../../Comments/CommentFormContainer'
import CommentsList from '../../../components/Comments/List'


import OrderActions from '../../../redux/orders/actions'

const { saveOrderSupplierNote } = OrderActions

const FIELD_NAMES = {
  DESCRIPTION: 'body',
  ATTACHMENTS: 'images_attributes'
}

const ORDER_SUPPLIER_NOTES = gql`
    query Order($number: String!) {
      order(number: $number) {
            id
            number
            supplierNotes {
                id
                body
                staff {
                  firstName
                }
                createdAt {
                  display
                  raw
                }
                images {
                  contentType
                  file {
                    original
                    small
                  }
                }
            }
        }
    }
`

const getAttachmentsAttributes = attachments => attachments.map(a => ({ file: a.originFileObj }))

const SupplierNotesContainer = ({
  orderId
}) => {
  const dispatch = useDispatch()
  const saving = useSelector(state => state.getIn(['orders', 'orderSupplierNoteSaving']))
  const { data, loading, refetch } = useQuery(ORDER_SUPPLIER_NOTES,
    { variables: { number: orderId }
  })

  const order = data ? data.order : {}

  const getImagesList = (images) => {
    return images.map((image) => {
      return { contentType: image.contentType, ...image.file }
    })
  }

  const getNotesList = () => {
    const notes = order.supplierNotes || []
    return notes.map((note) => {
      return {
        note: note.body,
        staff: note.staff,
        createdAt: note.createdAt,
        images: getImagesList(note.images)
      }
    })
  }

  if (loading) return <Spin />

  const onSaveComment = (values, form) => {
    dispatch(saveOrderSupplierNote(orderId, {
      ...values,
      [FIELD_NAMES.ATTACHMENTS]: getAttachmentsAttributes(values[FIELD_NAMES.ATTACHMENTS] || [])
    }, null, {
      onSuccess: () => {
        form.resetFields()
        refetch()
      }
    }))
  }

  return (
    <div>
      <CommentFormContainer
        attachmentFieldName={FIELD_NAMES.ATTACHMENTS}
        descriptionFieldName={FIELD_NAMES.DESCRIPTION}
        onSaveComment={onSaveComment}
        saving={saving}
      />
      <CommentsList notes={getNotesList()} />
    </div>
  )
}

SupplierNotesContainer.propTypes = {
  orderId: PropTypes.string.isRequired
}

SupplierNotesContainer.defaultProps = {}

export default SupplierNotesContainer
