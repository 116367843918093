import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

import { Select, Spin, Divider } from 'antd'

const SelectOrder = ({
    defaultValue,
    i18n,
    loading,
    onChange,
    onSearch,
    orders,
    value,
    ...restSelectProps
}) => {
    return (
        <Select
            allowClear
            defaultActiveFirstOption={false}
            defaultValue={defaultValue}
            dropdownRender={(menu) => (
                <div>
                    {menu}

                    {loading &&
                        <>
                            <Divider style={{ margin: '4px 0' }} />

                            <div
                                style={{ textAlign: 'center' }}
                            >
                                <Spin size="small" />
                            </div>
                        </>
                    }
                </div>
            )}
            filterOption={false}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={i18n.t('orders:orderSummary.searchOrder')}
            showArrow={false}
            showSearch
            style={restSelectProps.style || { width: '200px' }}
            value={value}
            {...restSelectProps}
        >
            {orders.map((order, index) => {
                return (
                    <Select.Option
                        key={_.get(order, 'id', `index-${index}`)}
                        value={_.get(order, 'id', undefined)}
                    >
                        {_.get(order, 'id', '')}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectOrder.propTypes = {
    defaultValue: PropTypes.string,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    orders: PropTypes.array,
    value: PropTypes.string
}

SelectOrder.defaultProps = {
    defaultValue: undefined,
    loading: false,
    onChange: () => {},
    onSearch: () => {},
    orders: [],
    value: undefined
}

export default withTranslation('orders')(SelectOrder)
