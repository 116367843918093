import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {Checkbox, Col, DatePicker, Input, Row, Space} from 'antd'
import StaffsWithCallCenterSelector from '../../staffsWithCallCenterSelector'
import TicketStatesForFilterSelector from '../ticketStatesForFilterSelector'
import Export from "./export"
import TicketSourcesForFilterSelector from '../ticketSourcesForFilterSelector'

const FilterBox = ({ children, label }) => (
    <Space>
        <div style={{ color: 'rgba(0, 0, 0, 0.5)', textAlign: 'end', width: '60px' }}>
            {label}
        </div>
        <div>{children}</div>
    </Space>
)

const Filter = ({ filters, onChangeFilter }) => {
    const { i18n } = useTranslation('tickets')
    const leftStyles = { display: 'flex', marginBottom: '12px' }
    const rightStyles = { display: 'flex', marginBottom: '12px', justifyContent: 'end' }
    const { assignToOsh, state, source } = filters || {}

    const onAssignToOshChange = event => {
        onChangeFilter({ assignToOsh: event.target.checked })
    }

    const onQueryChange = value => {
        onChangeFilter({ query: value })
    }

    const onStaffChange = option => {
        const { value } = option || {}

        onChangeFilter({ staffId: value })
    }

    const onStateChange = value => {
        onChangeFilter({ state: value })
    }

    const onSourceChange = value => {
        onChangeFilter({ source: value })
    }

    const onChangeDate = values => {
        if (values) {
            const startDate = values[0].format('YYYY-MM-DD')
            const endDate = values[1].format('YYYY-MM-DD')

            onChangeFilter({ startDate: startDate, endDate: endDate })
        }
    }

    return (
        <div>
            <Row gutter={16}>
                <Col sm={8} xs={24}>
                    <FilterBox label={i18n.t('shared:search')}>
                        <Input.Search
                            enterButton
                            onSearch={onQueryChange}
                            style={{ width: '300px' }} />
                    </FilterBox>
                </Col>
                <Col sm={6} xs={24}>
                    <FilterBox label={i18n.t('tickets:members')} labelSpan={5} childrenSpan={19}>
                        <StaffsWithCallCenterSelector
                            onChange={onStaffChange}
                            style={{ width: '300px' }} />
                    </FilterBox>
                </Col>
                <Col sm={10} xs={24} style={rightStyles}>
                    <Space>
                        <Checkbox onChange={onAssignToOshChange} checked={assignToOsh}>
                            {i18n.t('tickets:assignToOsh')}
                        </Checkbox>
                        <FilterBox label={i18n.t('tickets:state')}>
                            <TicketStatesForFilterSelector
                                allowClear
                                onChange={onStateChange}
                                style={{ width: '120px' }}
                                value={state} />
                        </FilterBox>
                    </Space>
                </Col>
            </Row>
            <div style={leftStyles}>
                <FilterBox label={i18n.t('tickets:filterWithDate')}>
                    <DatePicker.RangePicker allowClear
                                            format="YYYY-MM-DD"
                                            onChange={onChangeDate} />
                </FilterBox>
                <FilterBox label={i18n.t('tickets:source')}>
                    <TicketSourcesForFilterSelector
                        allowClear
                        value={source}
                        onChange={onSourceChange}
                        style={{ width: '120px' }} />
                </FilterBox>
            </div>
            <div style={rightStyles}><Export /></div>
        </div>
    )
}

Filter.propTypes = {
    filters: PropTypes.object,
    onChangeFilter: PropTypes.func.isRequired
}

Filter.defaultProps = {}

export default Filter
