import React, { Component } from 'react'
import PropTypes from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Row, Col, Spin, Modal } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import moment from 'moment'
import _ from 'lodash'

import PurchaseOrderPreviewWrapper from './purchaseOrderPreview.style'
import logoImage from '../../../../public/images/base/logo-osh@2x.png'
import taxImage from '../../../../public/images/base/icon-tax-billing-address-50@2x.png'
import signatureImage from '../../../../public/images/base/signature.png'
import ngentongmaImage from '../../../../public/images/base/ngentongma-license.png'
import koratRoumWasaduImage from '../../../../public/images/base/korat-roum-wasadu-license.png'

const renderPurchaseOrderDetails = props => {
    const { supplierOrder, i18n } = props
    const date = moment().format('D/M/YYYY')
    const deliverystatus = supplierOrder.get('delivery_status')

    return (
        <Row>
            <Col span={24} className="text-center">
                <div className="text-bold">Purchase Order ใบสั่งซื้อ</div>
            </Col>
            <Col span={17}>
                <div className="text-red">เรียน {supplierOrder.get('supplier_name')}</div>
            </Col>
            <Col span={7}>
                <Row>
                    <Col span={8}>No.</Col>
                    <Col span={16} className="text-bold">{supplierOrder.get('order')}</Col>
                    <Col span={8}>Sales:</Col>
                    <Col span={16}>{supplierOrder.get('sale_name') || '-'}</Col>
                    <Col span={8}>เงื่อนไข</Col>
                    <Col span={16}>{deliverystatus ? i18n.t(`suppliers:${_.camelCase(deliverystatus)}`) : '-'}</Col>
                    <Col span={8}>วันที่</Col>
                    <Col span={16}>{date}</Col>
                </Row>
            </Col>
        </Row>
    )
}

const renderEmptyLineItems = () => {
    return (
        <tr>
            <td colSpan="100%" className="text-center">- ไม่มีรายการ -</td>
        </tr>
    )
}

const renderItemsPriceNotIncluded = props => {
    const { lineItems, supplierOrder } = props

    return (
        <table className="items-section">
            <thead>
                <tr>
                    <th className="number">ลำดับ</th>
                    <th>รายการ</th>
                    <th>จำนวน</th>
                    <th>หน่วย</th>
                </tr>
            </thead>
            <tbody>
                {lineItems.size ? lineItems.map((lineItem, index) => {
                    return (
                        <tr key={`line-item-${lineItem.get('id')}`}>
                            <td className="text-center">{index + 1}</td>
                            <td>{lineItem.get('name')}</td>
                            <td className="text-right">{lineItem.get('quantity')}</td>
                            <td className="text-center">{lineItem.get('unit')}</td>
                        </tr>
                    )
                }) : renderEmptyLineItems()}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="100%">
                        <div className="display-flex">
                            <div className="title">จำนวนรวม</div>
                            <div className="text-right price">{supplierOrder.getIn(['subtotal', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="title">ภาษี</div>
                            <div className="text-right price">{supplierOrder.getIn(['vat', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="text-bold title">รวมทั้งหมด</div>
                            <div className="text-right text-bold price">
                                {supplierOrder.getIn(['grand_total', 'display'])}
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}

const renderItemsVatIncluded = props => {
    const { lineItems, supplierOrder } = props

    return (
        <table className="items-section">
            <thead>
                <tr>
                    <th className="number">ลำดับ</th>
                    <th>รายการ</th>
                    <th>จำนวน</th>
                    <th>หน่วย</th>
                    <th>ราคาต่อหน่วย</th>
                    <th>จำนวนรวม</th>
                </tr>
            </thead>
            <tbody>
                {lineItems.size ? lineItems.map((lineItem, index) => {
                    return (
                        <tr key={`line-item-${lineItem.get('id')}`}>
                            <td className="text-center">{index + 1}</td>
                            <td>{lineItem.get('name')}</td>
                            <td className="text-right">{lineItem.get('quantity')}</td>
                            <td className="text-center">{lineItem.get('unit')}</td>
                            <td className="text-right">{lineItem.getIn(['single_price', 'display'])}</td>
                            <td className="text-right">{lineItem.getIn(['total_price', 'display'])}</td>
                        </tr>
                    )
                }) : renderEmptyLineItems()}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="100%">
                        <div className="display-flex">
                            <div className="text-bold title">จำนวนรวม</div>
                            <div className="text-right text-bold price">
                                {supplierOrder.getIn(['grand_total', 'display'])}
                            </div>
                        </div>
                        <div className="display-flex">
                            <div className="title">ราคาก่อน VAT</div>
                            <div className="text-right price">{supplierOrder.getIn(['subtotal', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="title">ภาษีมูลค่าเพิ่ม VAT 7%</div>
                            <div className="text-right price">{supplierOrder.getIn(['vat', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="text-bold title">รวมทั้งหมด</div>
                            <div className="text-right text-bold price">
                                {supplierOrder.getIn(['grand_total', 'display'])}
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}

const renderItemsVatNotIncluded = props => {
    const { lineItems, supplierOrder } = props

    return (
        <table className="items-section">
            <thead>
                <tr>
                    <th className="number">ลำดับ</th>
                    <th>รายการ</th>
                    <th>จำนวน</th>
                    <th>หน่วย</th>
                    <th>ราคาต่อหน่วย</th>
                    <th>จำนวนรวม</th>
                </tr>
            </thead>
            <tbody>
                {lineItems.size ? lineItems.map((lineItem, index) => {
                    return (
                        <tr key={`line-item-${lineItem.get('id')}`}>
                            <td className="text-center">{index + 1}</td>
                            <td>{lineItem.get('name')}</td>
                            <td className="text-right">{lineItem.get('quantity')}</td>
                            <td className="text-center">{lineItem.get('unit')}</td>
                            <td className="text-right">{lineItem.getIn(['single_price', 'display'])}</td>
                            <td className="text-right">{lineItem.getIn(['total_price', 'display'])}</td>
                        </tr>
                    )
                }) : renderEmptyLineItems()}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="100%">
                        <div className="display-flex">
                            <div className="title">จำนวนรวม</div>
                            <div className="text-right price">{supplierOrder.getIn(['subtotal', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="title">ภาษี</div>
                            <div className="text-right price">{supplierOrder.getIn(['vat', 'display'])}</div>
                        </div>
                        <div className="display-flex">
                            <div className="text-bold title">รวมทั้งหมด</div>
                            <div className="text-right text-bold price">
                                {supplierOrder.getIn(['grand_total', 'display'])}
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}

const renderShipping = props => {
    const { supplierOrder, address } = props
    const truckInfo = supplierOrder.get('truck_info') || new List()

    const trucks = truckInfo.map((truck) => {
        const reservationDate = truck.get('reservation_date')
        const shippingDate = reservationDate ? moment(reservationDate).format('D/M/YYYY') : '-'
        return (
            <div>
                <span style={{ marginRight: '4px' }}>{shippingDate}</span>
                ({truck.get('license_plate_number') || '-'}, {truck.get('driver_name') || '-'})
            </div>
        )
    })

    const sendBySupplier = supplierOrder.get('send_by_supplier')
    const freeNote = supplierOrder.get('free_note')

    return (
        <table className="shipping-section">
            <tbody>
                <tr>
                    <td className="text-red">
                        <div className="text-bold">รายละเอียด</div>
                        {sendBySupplier ? <div>{address.get('full_address')}</div> : null}
                    </td>
                    <td>
                        <div className="text-bold">หมายเหตุ</div>
                        <div>{freeNote}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

const renderHeaderDetail = props => {
    const { supplierOrder } = props
    const header = supplierOrder.get("po_document_header")
    if (header === "onestockhome") {
        return (
            <React.Fragment>
                <div className="header-logo">
                    <img src={logoImage} className="header-logo-image" alt="logo image" />
                </div>
                <div className="header-details">
                    <div>บริษัท วันสต๊อกโฮม จำกัด (สำนักงานใหญ่)</div>
                    <div>1-7 ซิลลิค เฮ้าส์ ชั้น 4 ห้อง 02 ถนนสีลม สีลม บางรัก กทม. 10500</div>
                    <div>เลขประจำตัวผู้เสียภาษี 0105559043752</div>
                    <div>www.onestockhome.com</div>
                </div>
            </React.Fragment>
        )
    } else if (header === "ngentongma") {
        return (
            <React.Fragment>
                <div className="ngentongma-header">
                    <h1>เงินทองมาวัสดุ</h1>
                </div>
            </React.Fragment>
        )
    } else if (header === "korat_roum_wasadu") {
        return (
            <React.Fragment>
                <div className="header-logo">
                    <img src={logoImage} className="header-logo-image" alt="logo image" />
                </div>
                <div className="header-details">
                    <h1>โคราชรวมวัสดุ</h1>
                    <div>www.onestockhome.com</div>
                </div>
            </React.Fragment>
        )
    } else { return null }
}

const renderApproveLicense = props => {
    const { supplierOrder } = props
    if (supplierOrder.get('is_checked')) {
        return (
            <div>
                <img src={signatureImage} className="signature-image" alt="signature image" />
            </div>
        )
    }
}

const renderInternalDocument = props => {
    const { supplierOrder } = props
    if (supplierOrder.get('is_checked')) {
        return
    }
    return (
        <div style={{ border: 'solid 1px black', padding: '16px 8px', textAlign: 'center', fontSize: '20px' }}>
            <strong>เอกสารสำหรับใช้ภายในเท่านั้นไม่ใช่เอกสารการสั่งซื้อจนกว่าจะได้รับการอนุมัติ</strong>
        </div>
    )
}

const renderPreviewFooter = props => {
    const { supplierOrder, currentStaff } = props
    const header = supplierOrder.get('po_document_header')
    const date = moment().format('D/M/YYYY hh:mm')

    if (header === "onestockhome") {
        return (
            <React.Fragment>
                <Row className="preview-footer">
                    <Col span={2} className="footer-logo">
                        <div className="logo-border">
                            <img src={taxImage} className="footer-logo-image" alt="tax image" />
                        </div>
                    </Col>
                    <Col span={18} className="footer-details">
                        <div>กรุณา ออกใบกำกับภาษี และส่งมาที่</div>
                        <div>บริษัท วันสต๊อกโฮม จำกัด (สำนักงานใหญ่)</div>
                        <div>1-7 ซิลลิค เฮ้าส์ ชั้น 4 ห้อง 02 ถนนสีลม สีลม บางรัก กทม. 10500</div>
                        <div>เลขประจำตัวผู้เสียภาษี 0105559043752</div>
                    </Col>
                    <Col span={4}>
                        <div>
                            { renderApproveLicense(props) }
                        </div>
                    </Col>
                    <Col className="text-right">
                        <div>
                            <div>เอกสาร ออกโดย {currentStaff.get('first_name')}</div>
                            <div>{date}</div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        )
    } else if (header === "ngentongma") {
        return (
            <React.Fragment>
                <Row>
                    <Col span={6} offset={4}>
                        <img src={ngentongmaImage} style={{ width: "150px" }}
                            className="footer-logo-image" alt="korat image" />
                    </Col>
                </Row>
            </React.Fragment>
        )
    } else if (header === "korat_roum_wasadu") {
        return (
            <React.Fragment>
                <Row className="preview-footer">
                    <Col span={2} className="footer-logo">
                        <div className="logo-border">
                            <img src={taxImage} className="footer-logo-image" alt="tax image" />
                        </div>
                    </Col>
                    <Col span={18} className="footer-details">
                        <h1>โคราชรวมวัสดุ</h1>
                    </Col>
                    <Col className="text-right">
                        <div>
                            <div>เอกสาร ออกโดย {currentStaff.get('first_name')}</div>
                            <div>{date}</div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8} offset={12}>
                        <img src={koratRoumWasaduImage} style={{ width: "150px" }}
                            className="footer-logo-image" alt="korat image" />
                    </Col>
                </Row>
            </React.Fragment>
        )
    } else { return null }
}

const purchaseOrderPreview = props => {
    const { loading, type } = props
    const antIcon = <LoadingOutlined />

    return (
        <PurchaseOrderPreviewWrapper>
            <Modal
                centered
                closable={false}
                footer={null}
                visible={loading}
                width="100px">
                <Spin indicator={antIcon} tip="Loading..." size="large" />
            </Modal>
            <div className="purchase-order-preview-container">
                <div className="purchase-order-preview-page osh-watermark" data-watermark="OneStockHome">
                    <div className="preview-header">
                        {renderHeaderDetail(props)}
                    </div>
                    {renderPurchaseOrderDetails(props)}
                    {type === 'price_not_included' ? renderItemsPriceNotIncluded(props) : null}
                    {type === 'vat_included' ? renderItemsVatIncluded(props) : null}
                    {type === 'vat_not_included' ? renderItemsVatNotIncluded(props) : null}
                    {renderShipping(props)}
                    {renderPreviewFooter(props)}
                </div>
            </div>
        </PurchaseOrderPreviewWrapper>
    )
}

purchaseOrderPreview.propTypes = {
    address: immutablePropTypes.map.isRequired,
    currentStaff: immutablePropTypes.map.isRequired,
    lineItems: immutablePropTypes.list.isRequired,
    loading: PropTypes.bool,
    supplierOrder: immutablePropTypes.map.isRequired,
    type: PropTypes.string
}

class WatermarkedPurchaseOrderPreview extends Component {
    componentDidUpdate (prevProp) {
        const { loading } = this.props
        const prevLoading = prevProp.loading
        if (loading !== prevLoading) {
            Array.from(document.querySelectorAll('.osh-watermark')).forEach((el) => {
                el.dataset.watermark = (el.dataset.watermark + ' ').repeat(20)
            })
        }
    }

    render () {
        return (
            <div>
                {purchaseOrderPreview(this.props)}
            </div>
        )
    }
}

export default withTranslation(['suppliers'])(WatermarkedPurchaseOrderPreview)
