const actions = {
    NOTIFICATIONS_PUSH_NOTIFICATION: 'NOTIFICATIONS_PUSH_NOTIFICATION',
    NOTIFICATIONS_SET_REQUEST: 'NOTIFICATIONS_SET_REQUEST',
    NOTIFICATIONS_SHIFT_NOTIFICATION: 'NOTIFICATIONS_SHIFT_NOTIFICATION',

    // header: 'Title message of notification'
    // description: 'Description of notification'
    // typeAlert: 'type of notification can be success, info, warning or error'
    setNotifications: (header, description, typeAlert, delay = 7000) => ({
        type: actions.NOTIFICATIONS_SET_REQUEST,
        header,
        description,
        typeAlert,
        delay
    })
}

export default actions
