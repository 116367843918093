import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { List, Map}  from "immutable"
import moment from "moment"
import _ from 'lodash'

import OrderPaymentDetailList from '../../components/orderPaymentDetails/orderPaymentDetailList'

import actions from '../../redux/orderPaymentDetails/actions'
const { fetchOrderPaymentDetails, saveOrderPaymentDetail } = actions

const OrderPaymentDetailsListContainer = ({ i18n, location, history }) => {
    const dispatch = useDispatch()
    const orderPaymentDetail = useSelector(state => state.get('orderPaymentDetailList'))
    const bankStatementEntities = useSelector(state => state.getIn(['Entities', 'bankStatements']))
    const paymentDetailEntities = useSelector(state => state.getIn(['Entities', 'paymentDetails']))
    const scbPaymentConfirmEntities = useSelector(state => state.getIn(['Entities', 'scbPaymentConfirms']))
    const loading = useSelector(state => state.getIn(['orderPaymentDetailList', 'loading']))

    useEffect(() => {
        const queryStringObject = parseParams(location.search)
        history.push({
            search: location.search,
            params: queryStringObject
        })
        const queryString = {}
        _.mapKeys(queryStringObject, (value, key) => {
            key = _.camelCase(key)
            queryString[key] = _.toString(value)
        })
        prepareFetchOrderPaymentDetails({ ...queryString })
    }, [dispatch])

    const parseParams = (params = '') => {
        if(!params) return {}
        const rawParams = params.replace('?', '').split('&')
        const extractedParams = {}
        rawParams.forEach((item) => {
            item = item.split('=');
            extractedParams[item[0]] = item[1]
        })
        return extractedParams
    }

    const getBankStatement = (id) => {
        return bankStatementEntities.get(id) || new Map()
    }

    const getOrderPaymentDetailList = () => {
        return orderPaymentDetail.get('orderPaymentDetails', new List()).map((id) => {
            const rv = paymentDetailEntities.get(id) || new Map()

            return new Map({
                ...rv.toJS(),
                bank_statement: getBankStatement(rv.get('bank_statement')).toJS(),
                scb_payment_confirms: getScbPaymentConfirms(rv.get('scb_payment_confirms', new List())).toJS()
            })
        })
    }

    const getScbPaymentConfirms = (items) => {
        return items.map((id) => {
            return scbPaymentConfirmEntities.get(id) || new Map()
        })
    }

    const handleSendingStateChange = (value) => {
        prepareFetchOrderPaymentDetails({ haveBankStatement: value || '' })
    }

    const handleTransferDateChange = (values) => {
        prepareFetchOrderPaymentDetails({
            transferStartDate: values.length ? values[0].format('YYYY-MM-DD') : '',
            transferEndDate: values.length ? values[1].format('YYYY-MM-DD') : ''
        })
    }

    const prepareFetchOrderPaymentDetails = ({ transferStartDate, transferEndDate, haveBankStatement, currentPage } = {}) => {
        transferStartDate = (transferStartDate === undefined) ? orderPaymentDetail.get('transferStartDate') : transferStartDate
        transferEndDate = (transferEndDate === undefined) ? orderPaymentDetail.get('transferEndDate') : transferEndDate
        haveBankStatement = (haveBankStatement === undefined) ? orderPaymentDetail.get('haveBankStatement') : haveBankStatement

        dispatch(fetchOrderPaymentDetails({
            page: currentPage,
            transferStartDate,
            transferEndDate,
            haveBankStatement
        }))

        setQueryStringUrl({
            transferStartDate,
            transferEndDate,
            haveBankStatement,
        })
    }

    const setQueryStringUrl = ({
                                   transferStartDate,
                                   transferEndDate,
                                   haveBankStatement
    } = {}) => {
        const newRouterQuery = {
            transfer_start_date: _.toString(transferStartDate),
            transfer_end_date: _.toString(transferEndDate),
            have_bank_statement: haveBankStatement
        }

        const newParams = _.pickBy(newRouterQuery, (value) => value)

        const queryString = Object.keys(newParams).map(key => key + '=' + newParams[key]).join('&')

        history.push({
            search: `?${queryString}`,
            params: newParams
        })
    }

    const handleTableChange = (pagination) => {
        prepareFetchOrderPaymentDetails({
            currentPage: pagination.current
        })
    }

    const handleSaveRVBankStatement = ({ id, value }) => {
        dispatch(saveOrderPaymentDetail(id, { params: { bank_statement_id: value } }))
    }

    const transferStartDate = new URLSearchParams(location.search).get('transfer_start_date')
    const transferEndDate = new URLSearchParams(location.search).get('transfer_end_date')
    const defaultTransferDate = transferStartDate ? ([moment(transferStartDate), moment(transferEndDate)]) : null

    return (
        <OrderPaymentDetailList
            i18n={i18n}
            loading={loading}
            handleTransferDateChange={handleTransferDateChange}
            orderPaymentDetailList={getOrderPaymentDetailList().toJS()}
            defaultTransferDate={defaultTransferDate}
            defaultHaveBankStatement={new URLSearchParams(location.search).get('have_bank_statement') || undefined}
            handleSendingStateChange={handleSendingStateChange}
            pagination={{
                ...orderPaymentDetail.get('pagination').toJS(),
                showSizeChanger: false
            }}
            onTableChange={handleTableChange}
            onSaveRVBankStatement={handleSaveRVBankStatement}
        />
    )
}

export default withTranslation(['orderPaymentDetails', 'confirms', 'scbPaymentConfirms'])(withRouter(OrderPaymentDetailsListContainer))
