import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import actions from '../../redux/staffs/actions'

import StaffIndex from '../../components/staffs/index'
import CurrentStaffContainer from '../CurrentStaff/CurrentStaffContainer'

const { fetchStaffs } = actions

class StaffIndexContainer extends Component {
    static propTypes = {
        entities: ImmutablePropTypes.map.isRequired,
        fetchStaffs: PropTypes.func.isRequired,
        staffs: ImmutablePropTypes.map.isRequired
    }

    componentDidMount () {
        this.fetchStaffs()
    }

    componentDidUpdate (prevProps) {
        const { currentStaff } = this.props

        if (prevProps.currentStaff.size !== currentStaff.size) {
            this.fetchStaffs()
        }
    }

    fetchStaffs = () => {
        const { isCurrentStaffA } = this.props
        const isSuperAdmin = isCurrentStaffA('super_admin')

        if (!isSuperAdmin) { return null }

        this.searchStaffs()
    }

    searchStaffs = ({ page = 1, query } = {}) => {
        const { fetchStaffs, staffs } = this.props
        const newQuery = query === undefined ? staffs.get('query') : query

        fetchStaffs({ page, query: newQuery })
    }

    handlePageChange = (pagination) => {
        this.searchStaffs({ page: pagination.current })
    }

    getStaffs () {
        const { entities, staffs } = this.props
        const staffEntities = entities.get('staffs')
        const staffId = staffs.get('items')

        return staffId.map((id) => staffEntities.get(`${id}`) || new Map()) || new List()
    }

    render () {
        const { staffs, currentStaff, isCurrentStaffA } = this.props
        const pagination = staffs.get('pagination')
        const loading = staffs.get('loading')
        const isSuperAdmin = isCurrentStaffA('super_admin')

        if (currentStaff.size && !isSuperAdmin) { return <Redirect to='/' /> }

        return (
            <StaffIndex staffs={this.getStaffs().toJS()}
                loading={loading}
                onPageChange={this.handlePageChange}
                onSearch={this.searchStaffs}
                pagination={{
                    ...pagination.toJS(),
                    showSizeChanger: false
                }} />
        )
    }
}

const mapStateToProps = state => ({
    entities: state.get('Entities'),
    staffs: state.get('staffs')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchStaffs
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CurrentStaffContainer(StaffIndexContainer)))
