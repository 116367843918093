import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const SEARCH_STAFFS_QUERY = gql`
  query SearchStaffs($query: String, $page: Int, $per: Int) {
    searchStaffs(query: $query, page: $page, per: $per) {
      id
      fullName
    }
  }
`

const renderStaffOptions = options => options.map(({ id, fullName }) => {
  return <Select.Option value={id}>{fullName}</Select.Option>
})

const SearchStaff = ({
  defaultValue,
  onChange,
  outerLoading,
  value
}) => {
  const { i18n } = useTranslation('staffs')
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState(undefined)
  const initValueRef = useRef(false)
  const { loading: innerLoading, data, error } = useQuery(SEARCH_STAFFS_QUERY, {
    skip: !query,
    variables: { query, page: 1, per: 10 }
  })

  useEffect(() => initialValue(), [defaultValue])
  useEffect(() => updateValue(), [value])

  if (error) { console.error(error) }

  const initialValue = () => {
    if (initValueRef.current || outerLoading || selected) return null

    initValueRef.current = true
    handleChange(defaultValue)
  }

  const updateValue = () => {
    if ((value && value.key) === (selected && selected.key)) return null

    handleChange(value)
  }

  const handleChange = value => {
    setSelected(value)
    onChange(value)
  }

  const handleSearch = text => setQuery(text)

  return (
    <Select allowClear
      filterOption={false}
      labelInValue
      loading={outerLoading || innerLoading}
      onChange={handleChange}
      onSearch={handleSearch}
      placeholder={i18n.t('staffs:searchStaff')}
      showSearch
      style={{ width: '240px' }}
      value={selected}
    >
      {renderStaffOptions((data && data.searchStaffs) || [])}
    </Select>
  )
}

SearchStaff.propTypes = {
  defaultValue: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  }),
  onChange: PropTypes.func,
  outerLoading: PropTypes.bool,
  value: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  })
}

SearchStaff.defaultProps = {
  onChange: () => {}
}

export default SearchStaff
