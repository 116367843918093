import React from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'

import { Select } from 'antd'

const VEHICLE_TYPES_QUERY = gql`
  query LalamoveVehicleTypes {
    lalamoveVehicleTypes {
      name
      oshVehicleTypeName
    }
  }
`

const renderOptions = (options = []) => {
  return options.map(({ name, oshVehicleTypeName }) => {
    return <Select.Option value={name}>{oshVehicleTypeName}</Select.Option>
  })
}

const SelectLalamoveVehicleTypes = ({ ...restProps }) => {
  const { loading, data } = useQuery(VEHICLE_TYPES_QUERY)

  return (
    <Select loading={loading} {...restProps}>
        {renderOptions(data && data.lalamoveVehicleTypes)}
    </Select>
  )
}

SelectLalamoveVehicleTypes.propTypes = {}

SelectLalamoveVehicleTypes.defaultProps = {}

export default SelectLalamoveVehicleTypes
