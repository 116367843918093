import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import CompanyIndexPage from '../containers/Companies'
import CompanyShowPage from '../containers/Companies/show'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const CompaniesPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'companies') {
			dispatch(changeCurrent(['companies']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={CompanyIndexPage} exact />
      <Route path={`${path}/:id`} component={CompanyShowPage} />
		</Switch>
  )
}

CompaniesPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

CompaniesPage.defaultProps = {}

export default CompaniesPage
