import { all, fork, takeLatest, put, call } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'
import { truckReservationSchema, truckReservationsSchema } from '../schema'

import action from './actions'

const {
    // FETCH_TRUCK_RESERVATION_REQUEST,
    FETCH_TRUCK_RESERVATION_SUCCESS,
    FETCH_TRUCK_RESERVATIONS_NOT_PAID_REQUEST,
    FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS,
    FETCH_TRUCK_RESERVATIONS_NOT_PAID,
    FETCH_TRUCK_RESERVATIONS_REQUEST,
    SAVE_TRUCK_RESERVATION_REQUEST,
    SAVE_TRUCK_RESERVATION_SUCCESS,
    SET_TRUCK_RESERVATION_LOADING,
    SET_TRUCK_RESERVATION_SAVING

} = action

export function *fetchTruckReservationsSaga () {
    yield takeLatest(FETCH_TRUCK_RESERVATIONS_REQUEST, function *({ payload }) {
        yield put({ type: SET_TRUCK_RESERVATION_LOADING, loading: true })
        const { orderId, type } = payload
        const path = `/orders/${orderId}/truck_reservations`
        let query = '?'

        if (type === 'notPaid') {
            query += 'is_paid=false'
        }

        try {
            const data = yield call(apiFetch, path + query)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, truckReservationsSchema)

            yield put({
                type: FETCH_TRUCK_RESERVATION_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            console.log('error', error)
        } finally {
            yield put({ type: SET_TRUCK_RESERVATION_LOADING, loading: false })
        }
    })
}

export function *fetchTruckReservationsNotPaidSaga () {
    yield takeLatest(FETCH_TRUCK_RESERVATIONS_NOT_PAID_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_TRUCK_RESERVATIONS_NOT_PAID, loading: true })
        const { orderId } = payload
        const path = `/orders/${orderId}/truck_reservations?is_paid=false`


        try {
            const data = yield call(apiFetch, path)
            const formattedData = deserialize(data)
            const normalizeData = normalize(formattedData, truckReservationsSchema)

            yield put({
                type: FETCH_TRUCK_RESERVATIONS_NOT_PAID_SUCCESS,
                ...normalizeData
            })
        } catch (error) {
            console.log('error', error)
        } finally {
            yield put({ type: FETCH_TRUCK_RESERVATIONS_NOT_PAID, loading: false })
        }
    })
}

// export function *fetchTruckReservationSaga () {
//     yield takeLatest(FETCH_TRUCK_RESERVATION_REQUEST, function *({ payload }) {
//         yield put({ type: SET_TRUCK_RESERVATION_LOADING, loading: true })
//         const { orderId, reservationId } = payload
//         const path = `/orders/${orderId}/truck_reservations/${reservationId}`

//         try {
//             const data = yield call(apiFetch, path)
//             const formattedData = deserialize(data)
//             const normalizeData = normalize(formattedData, truckReservationSchema)

//             yield put({
//                 type: FETCH_TRUCK_RESERVATION_SUCCESS,
//                 ...normalizeData
//             })
//         } catch (error) {
//             console.log('error', error)
//         } finally {
//             yield put({ type: SET_TRUCK_RESERVATION_LOADING, loading: false })
//         }
//     })
// }

export function *saveTruckReservationSaga () {
    yield takeLatest(SAVE_TRUCK_RESERVATION_REQUEST, function *({ payload }) {
        yield put({ type: SET_TRUCK_RESERVATION_SAVING, saving: true })
        const { orderId, truckReservation, id, onSuccess, onError } = payload
        const method = id ? 'PATCH' : 'POST'
        let path = `/orders/${orderId}/truck_reservations`

        if (id) {
            path += `/${id}`
        }

        try {
            const body = JSON.stringify({ truck_reservation: truckReservation })
            const data = yield call(apiFetch, path, method, { body })

            if (!('error' in data)) {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, truckReservationSchema)

                yield put({ type: SAVE_TRUCK_RESERVATION_SUCCESS, ...normalizedData })
                onSuccess()
            } else {
                onError(data.error_description)
            }
        } catch (error) {
            onError()
        } finally {
            yield put({ type: SET_TRUCK_RESERVATION_SAVING, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchTruckReservationsSaga),
        fork(fetchTruckReservationsNotPaidSaga),
        fork(saveTruckReservationSaga)
    ])
}
