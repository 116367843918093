import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'

import Filter from './filter'
import Render from './render'
import withCurrentStaff from '../../../containers/CurrentStaff/CurrentStaffContainer'

const TICKETS_QUERY = gql`
	query Tickets($query: String, $state: String, $staffId: ID, $assignToOsh: Boolean, $first: Int, $after: String, $order: String, $startDate: String, $endDate: String, $source: String) {
		tickets(query: $query, state: $state, staffId: $staffId, assignToOsh: $assignToOsh, first: $first, after: $after, order: $order, startDate: $startDate, endDate: $endDate, source: $source) {
            nodes {
                id
                ticketNo
                description
                state
                source
                stateLabel
                ticketTypeLabel
                assignToOsh
                createdAt {
                    display
                }
                updatedAt {
                    display
                }
                buildTime {
                    hours
                    minutes
                }
                runTime {
                    hours
                    minutes
                }
                latestUpdatedDate {
                    display
                    raw
                }
                lastTicketNoteAnswer {
                    note
                    images {
                        id
                        url
                    }
                }
                customer {
                    id
                    customerNo
                    fullName
                    phoneNumber
                    lineUrl
                }
                orders {
                    id
                    number
                }
                province {
                    id
                    name
                }
                district {
                    id
                    name
                }
                images {
                    id
                    url
                }
                ticketMemberAgent {
                    id
                    staff {
                        id
                        fullName
                    }
                }
                memberStaffs {
                    id
                    firstName
                    fullName
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
		}
	}
`

const LIMIT = 10

const onLoadMore = (fetchMore, setLoadMore, { prevNodes, pageInfo }) => {
    if (!pageInfo.hasNextPage) { return null }

    setLoadMore(true)
    fetchMore({
        variables: { first: LIMIT, after: pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) { return prev }

            const tickets = fetchMoreResult.tickets || {}

            setLoadMore(false)

            return {
                tickets: {
                    ...tickets,
                    nodes: [...prevNodes, ...tickets.nodes]
                }
            }
        }
    })
}

const TicketsList = ({ currentStaffRoles, rolesMatched, location }) => {
    const { i18n } = useTranslation()
    const [filters, setFilters] = useState({})
    const [loadMore, setLoadMore] = useState(false)
    const { loading, data, error, fetchMore } = useQuery(TICKETS_QUERY, {
        variables: { first: LIMIT, ...filters }
    })

    useEffect(() => {
        if (rolesMatched(['admin', 'super_admin'])) {
            onChangeFilter({
                assignToOsh: true,
                state: 'assigned',
                source: new URLSearchParams(location.search).get('lead_source') || undefined
            })
        }
    }, [currentStaffRoles.size])

    const onChangeFilter = args => {
        setFilters({ ...filters, ...args })
    }

    const onChangeTable = (pagination, filters, { columnKey, order }) => {
		onChangeFilter({ order: order ? order.replace('end', '') : 'desc' })
	}

    if (error) return console.error(error)

    const { nodes, pageInfo } = (data && data.tickets) || {}

    return (
        <div>
            <h2>{i18n.t('tickets')}</h2>
            <Filter filters={filters} onChangeFilter={onChangeFilter} />
            <Render
                dataSource={nodes || []}
                loading={loading || loadMore}
                onLoadMore={args => onLoadMore(fetchMore, setLoadMore, args)}
                onChangeTable={onChangeTable}
                pageInfo={pageInfo || {}}
            />
        </div>
    )
}

TicketsList.propTypes = {}

TicketsList.defaultProps = {}

export default withCurrentStaff(TicketsList)
