const actions = {
    IMAGES_ADD_NEW_IMAGE_REQUEST: 'IMAGES_ADD_NEW_IMAGE_REQUEST',
    IMAGES_ADD_NEW_IMAGE: 'IMAGES_ADD_NEW_IMAGE',
    IMAGES_ORIGINAL_FETCH_BLOB_REQUEST: 'IMAGES_ORIGINAL_FETCH_BLOB_REQUEST',
    IMAGES_ORIGINAL_FETCH_BLOB_SUCCESS: 'IMAGES_ORIGINAL_FETCH_BLOB_SUCCESS',
    IMAGES_ORIGINAL_SET_LOADING: 'IMAGES_ORIGINAL_SET_LOADING',
    IMAGES_SET_PREVIEW_IMAGE_THUMB_URL: 'IMAGES_SET_PREVIEW_IMAGE_THUMB_URL',
    IMAGES_SET_SHOW_PREVIEW_IMAGE: 'IMAGES_SET_SHOW_PREVIEW_IMAGE',
    IMAGES_THUMB_FETCH_BLOB_REQUEST: 'IMAGES_THUMB_FETCH_BLOB_REQUEST',
    IMAGES_THUMB_FETCH_BLOB_SUCCESS: 'IMAGES_THUMB_FETCH_BLOB_SUCCESS',
    IMAGES_THUMB_SET_LOADING: 'IMAGES_THUMB_SET_LOADING',
    IMAGES_SET_NOTICE_TEXT: 'IMAGES_SET_NOTICE_TEXT',

    addNewImage: (thumbUrl, originalUrl = '', contentType = '', isImageBlob = true) => ({
        type: actions.IMAGES_ADD_NEW_IMAGE_REQUEST,
        payload: { thumbUrl, originalUrl, contentType, isImageBlob }
    }),

    closePreviewImageModal: () => ({
        type: actions.IMAGES_SET_SHOW_PREVIEW_IMAGE,
        showPreviewImage: false
    }),

    fetchOriginalImage: (thumbUrl, isImageBlob = true) => ({
        type: actions.IMAGES_ORIGINAL_FETCH_BLOB_REQUEST,
        payload: { thumbUrl, isImageBlob }
    }),

    openPreviewImageModal: () => ({
        type: actions.IMAGES_SET_SHOW_PREVIEW_IMAGE,
        showPreviewImage: true
    }),

    originalSetBlob: (thumbUrl, originalBlob) => ({
        type: actions.IMAGES_ORIGINAL_FETCH_BLOB_SUCCESS,
        entities: {
            images: {
                [thumbUrl]: { originalBlob }
            }
        }
    }),

    originalSetLoading: (thumbUrl, originalLoading) => ({
        type: actions.IMAGES_ORIGINAL_SET_LOADING,
        entities: {
            images: {
                [thumbUrl]: { originalLoading }
            }
        }
    }),

    setPreviewImageThumbUrl: (previewImageThumbUrl) => ({
        type: actions.IMAGES_SET_PREVIEW_IMAGE_THUMB_URL,
        previewImageThumbUrl
    }),

    thumbSetBlob: (thumbUrl, thumbBlob) => ({
        type: actions.IMAGES_THUMB_FETCH_BLOB_SUCCESS,
        entities: {
            images: {
                [thumbUrl]: { thumbBlob }
            }
        }
    }),

    thumbSetLoading: (thumbUrl, thumbLoading) => ({
        type: actions.IMAGES_THUMB_SET_LOADING,
        entities: {
            images: {
                [thumbUrl]: { thumbLoading }
            }
        }
    }),

    setNoticeText: (text) => ({
        type: actions.IMAGES_SET_NOTICE_TEXT,
        text
    })
}

export default actions
