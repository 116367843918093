import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Button, Skeleton, Table } from 'antd'
import MostOrderedSuppliersList from './mostOrderedSuppliersList'
import ItemPricesWrapper from './itemPrices.style'

const getLineItemsDataSource = (lineItems, pageInfo) => {
    if (!pageInfo.hasNextPage) return lineItems

    return lineItems.concat({ id: 'load_more_btn' })
}

const getLineItemColumns = (i18n, onLoadMore, loading) => [
    {
        title: i18n.t('orders'),
        width: 160,
        dataIndex: ['order', 'number'],
        render: (data) => {
            if (data) return data

            return {
                children: (
                    <div className="text-center">
                        <Button type="primary" loading={loading} onClick={onLoadMore} ghost>
                            {i18n.t('customerForm.seeMore')}
                        </Button>
                    </div>
                ),
                props: { colSpan: 6 }
            }
        }
    },
    {
        title: i18n.t('inquiries:unitPrice'),
        align: 'right',
        dataIndex: 'singlePrice',
        render: renderLineItemColumn
    },
    {
        title: i18n.t('inquiries:quantity'),
        align: 'right',
        dataIndex: 'quantity',
        render: renderLineItemColumn
    },
    {
        title: i18n.t('inquiries:cost'),
        align: 'right',
        dataIndex: 'cost',
        render: renderLineItemColumn
    },
    {
        title: i18n.t('order.date'),
        width: 160,
        dataIndex: 'supplierLineItems',
        render: (data) => {
            return renderLineItemColumn(data && data.map(d => d.purchaseDate && d.purchaseDate.display).join(', '))
        }
    },
    {
        title: i18n.t('suppliers'),
        dataIndex: 'suppliers',
        render: (data) => renderLineItemColumn(data && data.map(d => d.name).join(', '))
    }
]

const renderLineItemColumn = (data) => {
    if (data) return data

    return {
        children: data,
        props: { colSpan: 0 }
    }
}

const ItemPrices = ({
    itemName,
    lineItems,
    loading,
    loadMore,
    onFetchMore,
    pageInfo,
    suppliers
}) => {
    const [suppliersListHeight, setSuppliersListHeight] = useState(0)
    const { i18n } = useTranslation('inquiries')

    const element = document.getElementById('most_ordered_suppliers_list')
    const elementHeight = (element && element.offsetHeight) || 0

    useLayoutEffect(() => updateSuppliersListHeight(), [elementHeight])

    const onLoadMore = () => {
        if (loadMore) return null

        onFetchMore(pageInfo, lineItems)
    }
  
    const updateSuppliersListHeight = () => {
      if (element) {
        setSuppliersListHeight(elementHeight)
      }
    }

    const lineItemsDataSource = getLineItemsDataSource(lineItems, pageInfo)
    
    return (
        <ItemPricesWrapper>
            <Skeleton loading={loading} paragraph={false} active>
                <div className="item-name">{itemName}</div>
            </Skeleton>

            <MostOrderedSuppliersList suppliers={suppliers} />

            <Table
                className="line-items-list"
                columns={getLineItemColumns(i18n, onLoadMore, loadMore)}
                dataSource={lineItemsDataSource}
                loading={loading}
                pagination={{ 
                    pageSize: lineItemsDataSource.length,
                    hideOnSinglePage: true
                }}
                rowKey="id"
                scroll={{ y: `calc(100vh - 270px - ${suppliersListHeight}px)`, scrollToFirstRowOnChange: false }}
                title={() => i18n.t('inquiries:pricesList')}
            />
        </ItemPricesWrapper>
    )
}

ItemPrices.propTypes = {
    itemName: PropTypes.string,
    lineItems: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    loadMore: PropTypes.bool,
    onFetchMore: PropTypes.func.isRequired,
    pageInfo: PropTypes.object.isRequired,
    suppliers: PropTypes.array.isRequired
}

ItemPrices.defaultProps = {}

export default ItemPrices
