import styled from 'styled-components'
const SupplierInvoicesWrapper = styled.div`
  .text-right {
    text-align: right !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-30 {
    width: 30% !important;
  }

  .w-70 {
    width: 70% !important;
  }
`
export default SupplierInvoicesWrapper
