import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'

import { Button, Tag, Modal } from 'antd'
import OrderSupplierNoteFormContainer from '../Orders/SupplierNoteFormContainer'

import orderActions from '../../redux/orders/actions'

const { setOrderSupplierNoteFormModal, fetchOrderSupplierNotes } = orderActions

class OrderSupplierNotesContainer extends Component {
    async componentDidMount () {
        const { orderId, fetchOrderSupplierNotes } = this.props
        await fetchOrderSupplierNotes(orderId)
    }

    setOrderSupplierNote = async (id) => {
        const { setOrderSupplierNoteFormModal, orders, orderId } = this.props
        const isOpenOrderSupplierNoteForm = orders.get('isOpenOrderSupplierNoteForm')

        if (id) {
            this.orderSupplierNote = await (
                <OrderSupplierNoteFormContainer id={id}
                    orderId={orderId} />
            )
        } else {
            this.orderSupplierNote = await (
                <OrderSupplierNoteFormContainer
                    orderId={orderId}/>
            )
        }

        setOrderSupplierNoteFormModal(!isOpenOrderSupplierNoteForm)
    }

    renderOrderSupplierNote = () => {
        const { orders, orderSupplierNoteEntities } = this.props
        const items = orders.get('orderSupplierNoteItems')

        if (items.size === 0) {
            return
        }

        const note = items.map((itemId) => {
            const orderSupplierNote = orderSupplierNoteEntities.get(itemId.toString())
            return (
                <Tag key={`note-${itemId}`} onClick={() => this.setOrderSupplierNote(`${itemId}`)}>
                    {orderSupplierNote.get('id')}
                </Tag>
            )
        })

        return (<span>
            { note }
        </span>)
    }

    render () {
        const { i18n, orders } = this.props
        return (
            <div>
                {this.renderOrderSupplierNote()}
                <Button size="small" onClick={() => this.setOrderSupplierNote()}>
                    {i18n.t('orders:orderSupplierNote.add')}
                </Button>
                <Modal
                    visible={orders.get('isOpenOrderSupplierNoteForm')}
                    onCancel={() => this.setOrderSupplierNote()}
                    destroyOnClose
                    footer={null}>
                    {this.orderSupplierNote}
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orders: state.get('orders'),
    orderSupplierNoteEntities: state.getIn(['Entities', 'orderSupplierNotes'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setOrderSupplierNoteFormModal,
        fetchOrderSupplierNotes
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('orders')((OrderSupplierNotesContainer)))
