import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import { Avatar, Button, Comment, Tag } from 'antd'
import Image from '../../containers/Image'
import TicketNoteListWrapper from './ticketNoteListWrapper.style'
import withImagePreview from '../../containers/ImagePreview/ImagePreviewContainer'
import withCurrentStaff from '../../containers/CurrentStaff/CurrentStaffContainer'

import { MEMBER_AVATAR_PROPS } from '../../constants/tickets'
import { safeGet } from "../../helpers/string"
import apiConfig from '../../../config/apiConfig'

const addImgResponsiveToImg = (node, index) => {
    if (node.type !== 'tag' || node.name !== 'img') { return }
    const image = node.attribs['data-original'] || node.attribs['src']

    return (
        <img key={index}
            src={image}
            alt={node.attribs.alt}
            style={{ maxWidth: '100%', height: 'auto' }}  />
    )
}

const renderHtmlString = (htmlString) => {
    return new ReactHtmlParser(
        htmlString,
        { transform: addImgResponsiveToImg }
    )
}

const renderNoteType = (noteType) => {
    if (noteType === 'answer') { return <Tag color="red">Answer</Tag> }

    return <Tag color="green">Note</Tag>
}

const renderImages = (images) => {
    return images.map((image) => {
        const imageUrl = `${apiConfig.apiBase}${image.url}`

        return (
            <Image
                isImageBlob={false}
                originalUrl={imageUrl}
                thumbUrl={imageUrl}
                contentType="image/png"
            />
        )
    })
}


const renderContent = (note, images) => {
    return (
        <TicketNoteListWrapper>
            <div>{renderHtmlString(note)}</div>
            <div className="image-container">
                {renderImages(images)}
            </div>
        </TicketNoteListWrapper>
    )
}

const renderComment = ({ createdAt, note, noteType, staff, images }, currentStaffId) => {
    return (
        <Comment
            author={staff.fullName}
            avatar={
                <Avatar style={MEMBER_AVATAR_PROPS[currentStaffId === staff.id ? 'self' : 'member']}>
                    {staff.firstName}
                </Avatar>
            }
            content={renderContent(note, images)}
            datetime={
                <div>
                    {createdAt.display} {renderNoteType(noteType)}
                </div>
            }
        />
    )
}

const renderComments = (ticketNotes, currentStaffId) => ticketNotes.map((ticketNote) => {
    return renderComment(ticketNote, currentStaffId)
})

const TicketNoteList = ({ currentStaff, loading, loadMore, onFetchMore, ticket}) => {
    const { i18n } = useTranslation('tickets')

    const { id: currentStaffId } = currentStaff.toJS()
    const getNotes = () => {
        return safeGet(ticket, 'ticketNotes.nodes', [])
    }

    const getPageInfo = () => {
        return safeGet(ticket, 'ticketNotes.pageInfo', [])
    }


    const onLoadMore = () => {
        if (loadMore) return null

        onFetchMore(getPageInfo(), getNotes())
    }

    if (getNotes().length < 1) return null
    return (
        <div>
            {renderComments(getNotes(), currentStaffId)}
            <div className="text-center">
                {getPageInfo().hasNextPage && (
                    <Button type="primary" loading={loading} onClick={onLoadMore} ghost>
                        {i18n.t('customerForm.seeMore')}
                    </Button>
                )}
            </div>
        </div>
    )
}

TicketNoteList.propTypes = {
    ticket: PropTypes.object.isRequired,
    onFetchMore: PropTypes.func.isRequired
}

TicketNoteList.defaultProps = {}

export default withCurrentStaff(withImagePreview(TicketNoteList))
