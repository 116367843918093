import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Link } from 'react-router-dom'
import { Col, DatePicker, Divider, Row, Table } from 'antd'
import { PhoneOutlined, MailOutlined, LinkOutlined } from '@ant-design/icons'
import SearchStaff from '../../containers/SearchStaff'
import LinePinsIndexWrapper from './linePinsIndex.style'

import { isEmpty } from '../../helpers/objects'

const getColumns = (i18n) => [
  {
    key: 'customer_no',
    title: i18n.t('linePins:customerNo'),
    dataIndex: ['customer', 'customerNo'],
    render: (customerNo) => <Link to={`/customers/${customerNo}`}>{customerNo}</Link>
  },
  {
    key: 'name',
    title: i18n.t('linePins:customerName'),
    dataIndex: 'customer',
    render: (customer) => (
      <div>
        <div>{customer.fullName}</div>
        <div>
          <PhoneOutlined /> {customer.phoneNumber || '-'}
        </div>
        <div>
          <MailOutlined /> {customer.email || '-'}
        </div>
      </div>
    )
  },
  {
    key: 'bio',
    title: i18n.t('linePins:bio'),
    dataIndex: ['customer', 'bio'],
  },
  {
    key: 'line_url',
    title: i18n.t('linePins:lineUrl'),
    dataIndex: ['customer', 'lineUrl'],
    render: (url) => <a href={url} target='_blank' rel="noreferrer" ><LinkOutlined /> Link</a>
  },
  {
    key: 'companies',
    title: i18n.t('linePins:company'),
    dataIndex: ['customer', 'companies'],
    render: (companies) => companies.map(({ name }) => <div>{name}</div>)
  },
  {
    key: 'staff',
    title: i18n.t('linePins:staff'),
    dataIndex: ['staff', 'fullName'],
  },
]

const renderPinDatePicker = (i18n, onChange) => {
  return (
    <div className="filter-input-group">
      <div className="filter-label">{i18n.t('linePins:pinDate')}</div>
      <DatePicker.RangePicker
        onChange={(dates, dateStrings) => onChange({ startDate: dateStrings[0], endDate: dateStrings[1] })}
      />
    </div>
  )
}

const renderPinStaffSelector = (i18n, currentStaff, onChange) => {
  const isCurrentStaffEmpty = isEmpty(currentStaff)
  const defaultValue = isCurrentStaffEmpty ? undefined : {
    key: currentStaff.id,
    label: `${currentStaff.first_name} ${currentStaff.last_name}`
  }

  return (
    <div className="filter-input-group">
      <div className="filter-label">{i18n.t('linePins:staff')}</div>
      <SearchStaff
        defaultValue={defaultValue}
        onChange={option => onChange({ staffId: (option && option.key) || '' })}
        outerLoading={isCurrentStaffEmpty}
      />
    </div>
  )
}

const LinePinsIndex = ({
  currentStaff,
  linePins,
  loading,
  onChangeFilter,
  onTableChange,
  pagination
}) => {
  const { i18n } = useTranslation('linePins')
  const rightColClassName = isMobile ? 'flex-content-start' : 'flex-content-end'

  return (
    <LinePinsIndexWrapper>
      <Row>
        <Col className="flex-content-start" md={{ span: 12 }}>
          {renderPinStaffSelector(i18n, currentStaff, onChangeFilter)}
        </Col>
        <Col className={rightColClassName} md={{ span: 12 }}>
          {renderPinDatePicker(i18n, onChangeFilter)}
        </Col>
      </Row>
      <Divider />
      <Table
        columns={getColumns(i18n)}
        dataSource={linePins}
        loading={loading}
        onChange={onTableChange}
        pagination={pagination}
        rowKey="id"
        scroll={isMobile ? { x: 1300 } : { x: 1080 }}
      />
    </LinePinsIndexWrapper>
  )
}

LinePinsIndex.propTypes = {
  currentStaff: PropTypes.object,
  linePins: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onChangeFilter: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  })
}

LinePinsIndex.defaultProps = {}

export default LinePinsIndex
