import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import _ from 'lodash'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import DepositInvoiceForm from '../../components/Invoices/depositInvoiceForm'
import accountingActions from '../../redux/orders/accounting/actions'

const { fetchOrderBusinessAddresses, saveOrderBusinessAddress } =
    accountingActions

const ORDER_BUSINESS_ADDRESSES_QUERY = gql`
    query OrderBusinessAddresses($orderId: String!) {
        orderBusinessAddresses(orderId: $orderId) {
            id
            grandTotal
            invoiceNo
            depositInvoices {
                id
            }
            depositInvoiceConnections {
                id
                depositInvoiceId
                invoiceId
                grandTotal
            }
        }
    }
`

const DepositInvoiceFormContainer = ({ invoiceId, orderId, toggleForm }) => {
    const dispatch = useDispatch()
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [orderBusinessAddressInvoices, setOrderBusinessAddressInvoices] =
        useState([])
    const [
        selectedOrderBusinessAddressConnections,
        setSelectedOrderBusinessAddressConnections
    ] = useState([])

    const { loading, data } = useQuery(ORDER_BUSINESS_ADDRESSES_QUERY, {
        variables: { orderId: orderId },
        onCompleted: ({ orderBusinessAddresses }) => {
            const selectedOrderBusinessAddress =
                orderBusinessAddresses.find(
                    (orderBusinessAddress) =>
                        orderBusinessAddress.id === invoiceId
                ) || new Map()

            const getSelectedInvoices =
                (
                    selectedOrderBusinessAddress.depositInvoices || new List()
                ).map((orderBusinessAddress) => {
                    return orderBusinessAddress.id
                }) || new List()

            const selectedOrderBusinessAddressConnections =
                selectedOrderBusinessAddress.depositInvoiceConnections ||
                new List()

            const mapOrderBusinessAddresses = orderBusinessAddresses.map(
                (orderBusinessAddress) => {
                    const selectedOrderBusinessAddressConnection =
                        selectedOrderBusinessAddressConnections.find(
                            (value) =>
                                value.depositInvoiceId ===
                                orderBusinessAddress.id
                        )

                    if (selectedOrderBusinessAddressConnection) {
                        return {
                            ...orderBusinessAddress,
                            ...{
                                grandTotal:
                                    selectedOrderBusinessAddressConnection.grandTotal
                            }
                        }
                    } else {
                        return orderBusinessAddress
                    }
                }
            )

            setOrderBusinessAddressInvoices(mapOrderBusinessAddresses)
            setSelectedInvoices(getSelectedInvoices)
            setSelectedOrderBusinessAddressConnections(
                selectedOrderBusinessAddressConnections
            )
        }
    })

    if (orderBusinessAddressInvoices.length === 0 || !data) return null

    const orderBusinessAddresses =
        (data && data.orderBusinessAddresses) || new List()

    const handleSubmitForm = (e) => {
        e.preventDefault()

        const invoiceOrderBusinessAddress = orderBusinessAddresses.find(
            (value) => value.id === invoiceId
        )

        let removeDepositInvoiceConnections =
            invoiceOrderBusinessAddress.depositInvoiceConnections || new List()

        const mapSelectedRowKeys = selectedInvoices.map((selectedRowKeyId) => {
            removeDepositInvoiceConnections = _.reject(
                removeDepositInvoiceConnections,
                (depositInvoiceConnection) =>
                    selectedRowKeyId ===
                    depositInvoiceConnection.depositInvoiceId
            )
            const orderBusinessAddress = orderBusinessAddressInvoices.find(
                (value) => value.id === selectedRowKeyId
            )
            if (orderBusinessAddress) {
                const selectedOrderBusinessAddressConnection =
                    selectedOrderBusinessAddressConnections.find(
                        (value) => value.depositInvoiceId === selectedRowKeyId
                    )

                const payload = {
                    invoice_id: invoiceId,
                    deposit_invoice_id: orderBusinessAddress.id,
                    grand_total: parseFloat(
                        orderBusinessAddress.grandTotal.replaceAll(',', '')
                    ).toFixed(2)
                }
                if (selectedOrderBusinessAddressConnection) {
                    return {
                        ...payload,
                        ...{ id: selectedOrderBusinessAddressConnection.id }
                    }
                } else {
                    return payload
                }
            }
        })

        const mapRemoveDepositInvoiceConnections =
            removeDepositInvoiceConnections.map(
                (removeDepositInvoiceConnection) => {
                    return {
                        id: removeDepositInvoiceConnection.id,
                        _destroy: true
                    }
                }
            )

        dispatch(
            saveOrderBusinessAddress({
                address: {
                    deposit_invoice_connections_attributes:
                        mapRemoveDepositInvoiceConnections.concat(
                            mapSelectedRowKeys
                        )
                },
                id: invoiceId,
                orderId,
                onSuccess: () => {
                    toggleForm()
                }
            })
        )
    }

    const setInvoices = (selectedRowKeys) => {
        setSelectedInvoices(selectedRowKeys)
    }

    const setOrderBusinessAddress = (data) => {
        setOrderBusinessAddressInvoices(data)
    }

    return (
        <DepositInvoiceForm
            invoiceId={invoiceId}
            invoices={orderBusinessAddressInvoices}
            loading={loading}
            onSubmitForm={handleSubmitForm}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setInvoices}
            setOrderBusinessAddressInvoices={setOrderBusinessAddress}
            toggleForm={toggleForm}
        />
    )
}

DepositInvoiceFormContainer.propTypes = {
    invoiceId: PropTypes.string,
    orderId: PropTypes.string.isRequired,
    toggleForm: PropTypes.func
}

DepositInvoiceFormContainer.defaultProps = {
    toggleForm: () => {}
}

export default DepositInvoiceFormContainer
