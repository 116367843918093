import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BookOutlined, CarOutlined, EditTwoTone, MessageOutlined } from '@ant-design/icons'
import { Badge, Modal, Row, Col, Typography } from 'antd'

import SupplierNotesContainer from '../../SupplierNotes/SupplierNotesContainer'
import OrderNotesContainer from '../../OrderNotes/OrderNotesContainer'
import NoteForm from './NoteForm'
import CompanyProjects from './CompanyProjects'
import OrderStaffs from '../../../../components/OrderStaffs'
import ShippingDetail from './ShippingDetail'

import OrderSummaryDetailWrapper from './OrderSummaryDetailWrapper.style'

import inquiryActions from '../../../../redux/inquiry/actions'
import ShippingAddressFormRender from './ShippingAddresses/FormRender'
import SelectOldShippingAddressForm from './ShippingAddresses/SelectOldShippingAddressForm'
import SalePipelineInfo from './SalePipelineInfo'
import ScmFinishedOrder from '../../../../components/ScmFinishedOrder'

const { fetchInquiry, setShowSupplierNotesForm, setShowOrderNotesForm } = inquiryActions

const renderShippingAddress = (order, customer, handleShippingAddressForm) => {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ alignSelf: 'center' }}><CarOutlined /></div>
        <div style={{ marginLeft: '8px' }}>
          <span style={{ marginRight: '8px' }}>จัดส่ง </span> <EditTwoTone onClick={handleShippingAddressForm} />
        </div>
      </div>
      <ShippingDetail shippingType={order.get('shipping_type')}
        order={order}
        customer={customer}/>
    </div>
  )
}

const renderSuppliers = (orderEntity, supplierOrderEntities) => {
  return orderEntity.get('supplier_orders').map((supplierOrder) => {
    const supplierOrderEntity = supplierOrderEntities.get(supplierOrder)
    return (
      <div>
        { supplierOrderEntity.get('supplier_name') }({ supplierOrderEntity.get('state_label') }) {supplierOrderEntity.getIn(['grand_total', 'display'])}
      </div>
    )

  })
}

const OrderSummaryDetail = ({orderEntity, supplierOrderEntities}) => {
  const { i18n } = useTranslation(['inquiries', 'addresses'])

  const dispatch = useDispatch()
  const showSupplierNotesForm = useSelector(state => state.getIn(['inquiry', 'showSupplierNotesForm']))
  const showOrderNotesForm = useSelector(state => state.getIn(['inquiry', 'showOrderNotesForm']))
  const inquiry = useSelector(state => state.get('inquiry'))
  const customerEntities = useSelector(state => state.getIn(['Entities', 'customers']))

  const [showNoteForm, setShowNoteForm] = useState(false)
  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false)
  const [showSelectShippingAddressForm, setShowSelectShippingAddressModal] = useState(false)

  const handleSupplierNotesForm = () => {
    dispatch(setShowSupplierNotesForm(!showSupplierNotesForm))
  }

  const handleOrderNotesForm = () => {
    dispatch(setShowOrderNotesForm(!showOrderNotesForm))
  }

  const handleNoteForm = () => {
    setShowNoteForm(!showNoteForm)
  }

  const handleShippingAddressForm = () => {
    setShowShippingAddressForm(!showShippingAddressForm)
  }

  const handleSelectShippingAddressForm = () => {
    setShowSelectShippingAddressModal(!showSelectShippingAddressForm)
  }

  const onSaveSuccess = (number) => {
    dispatch(fetchInquiry(number))
  }

  const renderNoteDetail = () => {
    return (
      <div>
        <span style={{ marginRight: '8px'}}>{orderEntity.get('note') || '-'}</span>
        <EditTwoTone onClick={handleNoteForm} />
      </div>
    )
  }

  const renderNoteSection = () => {
    if(showNoteForm) {
      return (
        <NoteForm defaultNote={orderEntity.get('note')}
          orderId={orderEntity.get('id')}
          onCancel={handleNoteForm}
          onSuccess={handleNoteForm} />
      )
    }

    return renderNoteDetail()
  }

  const customer = customerEntities.getIn([inquiry.get('currentCustomer')]) || new Map()
  const orderShippingAddress = orderEntity.get('order_shipping_address')
  const orderShippingAddressId = orderShippingAddress ? orderShippingAddress.get('id') : null

  return (
    <OrderSummaryDetailWrapper>
      <div style={{marginBottom: 16, display: 'flex' }}>
        <BookOutlined style={{ marginRight: 8, alignSelf: 'center' }}/>
        {renderNoteSection()}
      </div>
      <Row>
        <Col span={12}>
          {renderShippingAddress(orderEntity, customer, handleShippingAddressForm)}
          <div>
            <a onClick={handleSelectShippingAddressForm}>
                {i18n.t('addresses:selectOriginalAddress')}
            </a>
          </div>
          <Modal
              title={i18n.t('addresses:selectShippingAddress')}
              destroyOnClose
              onCancel={handleSelectShippingAddressForm}
              visible={showSelectShippingAddressForm}
              footer={null}
              width="60%">
            <SelectOldShippingAddressForm onCancel={handleSelectShippingAddressForm}
              orderId={orderEntity.get('id')}
              customerId={orderEntity.getIn(['customer', 'id'])}
              orderShippingAddressId={orderShippingAddressId} />
          </Modal>
            <Modal visible={showShippingAddressForm}
                footer={null}
                width={'50%'}
                onCancel={handleShippingAddressForm}>
              <ShippingAddressFormRender shippingAddress={orderEntity.get('order_shipping_address')}
                  onCancel={handleShippingAddressForm}
                  onSuccess={handleShippingAddressForm}
                  defaultShippingType={orderEntity.get('shipping_type')}
                  orderId={orderEntity.get('id')}
                  exportShipment={orderEntity.get('order_export_shipment')}
                  pickupLicensePlateNumber={orderEntity.get('pickup_license_plate_number')}
                  pickupDate={orderEntity.get('pickup_date')} />
            </Modal>
        </Col>
        <Col span={12} className='text-end'>
          <div>
            <Typography.Text strong>{i18n.t('orders:leadSources')} : </Typography.Text>
            <Typography.Text>{orderEntity.get('lead_source') || '-'}</Typography.Text>
          </div>
          <CompanyProjects orderId={orderEntity.get('id')} />
          <SalePipelineInfo  orderId={orderEntity.get('id')} />
        </Col>
      </Row>
      <div style={{marginTop: 16, display: 'flex', alignItems: 'center'}}>
        <OrderStaffs
          order={orderEntity.toJS()}
          onSaveSuccess={onSaveSuccess}
        />
        <div style={{marginLeft: '96px', display: 'flex'}}>
          <div>
            <Badge size="default" count={orderEntity.get('customer_notes_count')} offset={[7, -3]}>
              <MessageOutlined />
              <a onClick={handleOrderNotesForm}> ที่เคยคุยกับลูกค้า</a>
            </Badge>
            <Modal visible={showOrderNotesForm}
              footer={null}
              width={'50%'}
              onCancel={handleOrderNotesForm}
            >
              <OrderNotesContainer
                customerNo={orderEntity.getIn(['customer', 'id'])}
                orderId={orderEntity.get('id')} />
            </Modal>
          </div>

          <div style={{marginLeft: '48px'}}>
            <Badge size="default" count={orderEntity.get('order_supplier_notes_count')} offset={[7, -3]}>
              <MessageOutlined />
              <a onClick={handleSupplierNotesForm}> ที่เคยคุยกับ supplier</a>
            </Badge>

            <Modal visible={showSupplierNotesForm}
              footer={null}
              width={'50%'}
              onCancel={handleSupplierNotesForm}
            >
              <SupplierNotesContainer
                orderId={orderEntity.get('id')}/>
            </Modal>
          </div>
          <div style={{marginLeft: '48px'}}>
            { renderSuppliers(orderEntity, supplierOrderEntities) }
          </div>
        </div>
        <div>
          <ScmFinishedOrder
              onSaveSuccess={onSaveSuccess}
              order={orderEntity.toJS()}
          />
        </div>
      </div>
    </OrderSummaryDetailWrapper>
  )
}

export default OrderSummaryDetail
