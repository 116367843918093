const actions = {
    ORDER_YIELD_FETCH_REQUEST: 'ORDER_YIELD_FETCH_REQUEST',
    ORDER_YIELD_FETCH_SUCCESS: 'ORDER_YIELD_FETCH_SUCCESS',
    ORDER_YIELD_FETCH: 'ORDER_YIELD_FETCH',
    ORDER_YIELD_SET_LOADING: 'ORDER_YIELD_SET_LOADING',

    ORDER_YIELDS_FETCH_FAIL: 'ORDER_YIELDS_FETCH_FAIL',
    ORDER_YIELDS_FETCH_REQUEST: 'ORDER_YIELDS_FETCH_REQUEST',
    ORDER_YIELDS_FETCH_SUCCESS: 'ORDER_YIELDS_FETCH_SUCCESS',
    ORDER_YIELDS_FETCHING: 'ORDER_YIELDS_FETCHING',

    ORDER_YIELDS_INVOICES_FETCH_REQUEST: 'ORDER_YIELDS_INVOICES_FETCH_REQUEST',
    ORDER_YIELDS_INVOICE_FETCHING: 'ORDER_YIELDS_INVOICE_FETCHING',
    ORDER_YIELDS_INVOICE_FETCH_SUCCESS: 'ORDER_YIELDS_INVOICE_FETCH_SUCCESS',
    ORDER_YIELDS_INVOICE_SET_PAGINATION: 'ORDER_YIELDS_INVOICE_SET_PAGINATION',
    ORDER_YIELDS_INVOICE_SET_SORTER: 'ORDER_YIELDS_INVOICE_SET_SORTER',

    fetchOrderYield: (orderId) => ({
        type: actions.ORDER_YIELD_FETCH_REQUEST,
        payload: { orderId }
    }),

    fetchOrderYields: ({
        page = 1,
        per = 20,
        order = 'updated_at desc',
        startDate = '',
        endDate = '',
        saleId = '',
        isCompleteYet = '',
        isRvEqualToInvoices = '',
        include = [],
        fields = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.ORDER_YIELDS_FETCH_REQUEST,
            payload: {
                page,
                per,
                order,
                startDate,
                endDate,
                saleId,
                isCompleteYet,
                isRvEqualToInvoices,
                include,
                fields,
                onSuccess,
                onError
            }
        }
    },

    fetchOrderBusinessAddresses: ({
        page = 1,
        sorter = {},
        invStartDate = '',
        invEndDate = '',
        sendingState = '',
        haveTrackingNumber = ''
    } = {}) => {
        return {
            type: actions.ORDER_YIELDS_INVOICES_FETCH_REQUEST,
            payload: { page, sorter, invStartDate, invEndDate, sendingState, haveTrackingNumber }
        }
    },

    setOrderBusinessAddressPagination: (pagination) => {
        return {
            type: actions.ORDER_YIELDS_INVOICE_SET_PAGINATION,
            pagination
        }
    },

    setSorter: (sorter) => {
        return { type: actions.ORDER_YIELDS_INVOICE_SET_SORTER, sorter }
    }
}

export default actions
