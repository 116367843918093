import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { List, Map } from 'immutable'

import SelectOrderState from '../../components/SelectOrderState'

import orderActions from '../../redux/orders/actions'

const {
    fetchOrderStates
} = orderActions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectOrderStateContainer extends Component {
    static propTypes = {
        // Props from another component
        allState: PropTypes.bool,
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner Props
        fetchOrderStates: PropTypes.func.isRequired,
        orders: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {
        allState: false,
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? 'all' : props.value
        }
    }

    componentDidMount () {
        const { allState, fetchOrderStates } = this.props

        fetchOrderStates({ allState })
    }

    getOrderState (id) {
        const { orderStateEntities } = this.props
        return orderStateEntities.get(_.toString(id), new Map())
    }

    getOrderStates () {
        const { orders } = this.props

        return orders.get('orderStates', new List()).map((id) => {
            return this.getOrderState(id)
        })
    }

    onChange = (orderState) => {
        const { onChange } = this.props

        onChange(orderState === 'all' ? '' : orderState)
        this.setState({ value: orderState })
    }

    render () {
        const {
            onChange,
            orders,
            value: propValue,
            ...restProps
        } = this.props

        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectOrderState
                loading={orders.get('orderStatesLoading', false)}
                onChange={this.onChange}
                orderStates={this.getOrderStates().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orders: state.get('orders'),
        orderStateEntities: state.getIn(['Entities', 'orderStates'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderStates
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOrderStateContainer)
