const actions = {
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_REQUEST: 'CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_REQUEST',
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH: 'CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH',
    CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_SUCCESS: 'CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_SUCCESS',
    CUSTOMER_ORDER_PAYMENT_DETAILS_SET_PAGINATION: 'CUSTOMER_ORDER_PAYMENT_DETAILS_SET_PAGINATION',

    fetchCustomerOrderPaymentDetails: ({ customerId, page = 1, per = 20 } = {}) => ({
        type: actions.CUSTOMER_ORDER_PAYMENT_DETAILS_FETCH_REQUEST,
        payload: { customerId, page, per }
    })
}

export default actions
