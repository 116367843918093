import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { withTranslation } from 'react-i18next'

import BankStatementImport from '../../components/BankStatements/Import'

import actions from '../../redux/bankStatements/actions'
import notificationActions from '../../redux/notifications/actions'

const {
    setImportVisibleModal,
    uploadBankStatements,
    onHideBankSelectForm
} = actions

const { setNotifications } = notificationActions

class BankStatementImportContainer extends Component {
    static propTypes = {
        // Inner Props
        bankStatements: ImmutablePropTypes.map.isRequired,
        setImportVisibleModal: PropTypes.func.isRequired,
        setNotifications: PropTypes.func.isRequired,
        uploadBankStatements: PropTypes.func.isRequired,
        onHideBankSelectForm: PropTypes.func.isRequired
    }

    static defaultProps = {}

    onSubmit = ({ params = {} } = {}) => {
        const { i18n, uploadBankStatements, setNotifications } = this.props

        uploadBankStatements({
            params,
            onSuccess: () => {
                this.onToggleVisibleModal()
                setNotifications(
                    i18n.t('bankStatements:uploadSuccess'),
                    i18n.t('bankStatements:uploadSuccessDescription'),
                    'info'
                )
            },
            onError: () => {
                setNotifications('error', 'error', 'error')
            }
        })
    }

    onToggleVisibleModal = () => {
        const { bankStatements, setImportVisibleModal } = this.props

        setImportVisibleModal({ visibleModal: !bankStatements.getIn(['import', 'visibleModal'], true) })
    }

    render () {
        const { bankStatements, onHideBankSelectForm } = this.props

        return (
            <BankStatementImport
                onSubmit={this.onSubmit}
                textButton='importAdditionalCreate'
                onToggleVisibleModal={this.onToggleVisibleModal}
                saving={bankStatements.get('saving', false)}
                onHideBankSelectForm={onHideBankSelectForm}
                visibleModal={bankStatements.getIn(['import', 'visibleModal'], false)}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        bankStatements: state.get('bankStatements')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setImportVisibleModal,
        setNotifications,
        uploadBankStatements,
        onHideBankSelectForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['bankStatements'])(BankStatementImportContainer)
)
