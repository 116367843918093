import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Modal } from 'antd'
import { FileAddTwoTone } from '@ant-design/icons'
import ReissueInvoiceFormContainer from './FormContainer'
import ReissueInvoiceNoteFormContainer from './EditNoteFormContainer'

const ReissueInvoice = ({
  currentInvoiceNo,
  customerNo,
  invoiceId,
  invoiceType,
  onSuccess
}) => {
  const isCorrectType = invoiceType === 'full_tax_invoice'

  const { i18n } = useTranslation('confirms')
  const [visible, setVisible] = useState(false)

  if (!isCorrectType) {
    return null
  }

  const toggleModal = () => {
    setVisible(!visible)
  }

  const renderModal = () => {
    if (!visible) {
      return null
    }

    return (
      <Modal footer={null} onCancel={toggleModal} visible={visible} width={900}>
        <ReissueInvoiceFormContainer
          customerNo={customerNo}
          invoiceId={invoiceId}
          onClose={toggleModal}
          onSuccess={onSuccess}
        />
      </Modal>
    )
  }

  const renderReissueInvoiceNoteModal = () => {
    if (!visible) {
      return null
    }

    return (
      <Modal footer={null} onCancel={toggleModal} visible={visible} width={900}>
        <ReissueInvoiceNoteFormContainer
          invoiceId={invoiceId}
          onClose={toggleModal}
          onSuccess={onSuccess}
        />
      </Modal>
    )
  }

  if (currentInvoiceNo) {
    return (
      <div>
        <a onClick={toggleModal}>
          <FileAddTwoTone /> {i18n.t('confirms:invoice.newInvoiceIssued')}
        </a>
        {renderReissueInvoiceNoteModal()}
      </div>
    )
  }

  return (
    <div>
      <a onClick={toggleModal}>
        <FileAddTwoTone /> {i18n.t('confirms:invoice.reissueInvoice')}
      </a>
      {renderModal()}
    </div>
  )
}

ReissueInvoice.propTypes = {
  currentInvoiceNo: PropTypes.string,
  customerNo: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  invoiceType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
}

ReissueInvoice.defaultProps = {
  onSuccess: () => {}
}

export default ReissueInvoice
