export const formatNumber = (number) => {
  return number.toLocaleString(
    undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
}

export const formatNumberWithDash = (number) => {
  if(isNaN(number)) return '-'

  return number.toLocaleString(
    undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
}

export const formatNumberWithDashSatangs = (number) => {
  if(isNaN(number)) return '-'

  return (number / 100.00).toLocaleString(
    undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )
}

export const diffInDate = (date) => {
  const diffInMs = new Date() - new Date(date)
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
}

export const formatDate = (date) => {
  return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`
}