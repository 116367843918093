import { PAGE_SIZE } from '../../constants/customers'

const actions = {
    PROJECTS_FETCH_COMPANY_PROJECTS_REQUEST: 'PROJECTS_FETCH_COMPANY_PROJECTS_REQUEST',
    PROJECTS_FETCH_COMPANY_PROJECTS: 'PROJECTS_FETCH_COMPANY_PROJECTS',
    PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS: 'PROJECTS_FETCH_COMPANY_PROJECTS_SUCCESS',

    PROJECTS_SAVE_COMPANY_PROJECT_REQUEST: 'PROJECTS_SAVE_COMPANY_PROJECT_REQUEST',
    PROJECTS_SAVE_COMPANY_PROJECT: 'PROJECTS_SAVE_COMPANY_PROJECT',
    PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS: 'PROJECTS_SAVE_COMPANY_PROJECT_SUCCESS',
    PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS: 'PROJECTS_SET_COMPANY_PROJECT_FORM_ERRORS',
    PROJECTS_RESET_COMPANY_PROJECT_FORM_ERRORS: 'PROJECTS_RESET_COMPANY_PROJECT_FORM_ERRORS',

    PROJECTS_FETCH_COMPANY_PROJECT_REQUEST: 'PROJECTS_FETCH_COMPANY_PROJECT_REQUEST',
    PROJECTS_FETCH_COMPANY_PROJECT: 'PROJECTS_FETCH_COMPANY_PROJECT',
    PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS: 'PROJECTS_FETCH_COMPANY_PROJECT_SUCCESS',

    PROJECTS_SET_COMPANY_PROJECT_PAGINATION: 'PROJECTS_SET_COMPANY_PROJECT_PAGINATION',
    PROJECTS_SET_COMPANY_PROJECT_FORM_MODAL: 'PROJECTS_SET_COMPANY_PROJECT_FORM_MODAL',

    fetchCompanyProjects: ({ page = 1, per = PAGE_SIZE, query = '' } = {}) => ({
        type: actions.PROJECTS_FETCH_COMPANY_PROJECTS_REQUEST,
        payload: { page, per, query }
    }),

    saveCompanyProject: ({
        id = '',
        params = {},
        onSuccess = () => {},
        onError = () => {}
    } = {}) => {
        return {
            type: actions.PROJECTS_SAVE_COMPANY_PROJECT_REQUEST,
            payload: { id, params, onSuccess, onError }
        }
    },

    fetchCompanyProject: (id) => ({
        type: actions.PROJECTS_FETCH_COMPANY_PROJECT_REQUEST,
        payload: { id }
    }),

    setCompanyProjectFormModal: (visible, id) => ({
        type: actions.PROJECTS_SET_COMPANY_PROJECT_FORM_MODAL,
        visible, id
    }),

    resetCompanyProjectFormErrors: () => ({
        type: actions.PROJECTS_RESET_COMPANY_PROJECT_FORM_ERRORS
    })
}

export default actions
