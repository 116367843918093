
import { Map, List } from 'immutable'
import actions from './actions'

const {
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEM_DELETING,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEM_SAVING,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
    SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING,
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS,
    SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH
} = actions

const initState = new Map({
    item: '',
    items: new List(),
    loading: false,
    pagination: new Map({
        current: 1,
        pageSize: 20,
        total: 1
    }),
    saving: false
})

export default function supplierOverTransferItemsReducer (state = initState, action) {
    const items = state.get('items', new List())
    const result = action.result

    switch (action.type) {
        case SUPPLIER_OVER_TRANSFER_ITEM_DELETE_FAIL:
            return state.merge({ saving: false })
        case SUPPLIER_OVER_TRANSFER_ITEM_DELETE_SUCCESS:
            return state.merge({
                item: result,
                items: items.includes(result) ? items.delete(items.indexOf(result)) : items,
                saving: false
            })
        case SUPPLIER_OVER_TRANSFER_ITEM_DELETING:
            return state.merge({ saving: true })
        case SUPPLIER_OVER_TRANSFER_ITEM_SAVE_FAIL:
            return state.merge({ saving: false })
        case SUPPLIER_OVER_TRANSFER_ITEM_SAVE_SUCCESS:
            return state.merge({
                item: result,
                items: items.includes(result) ? items : items.push(result),
                saving: false
            })
        case SUPPLIER_OVER_TRANSFER_ITEM_SAVING:
            return state.merge({ saving: true })
        case SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_FAIL:
            return state.merge({ loading: false })
        case SUPPLIER_OVER_TRANSFER_ITEMS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case SUPPLIER_OVER_TRANSFER_ITEMS_FETCHING:
            return state.merge({ loading: true })
        case SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                pagination: new Map(action.pagination)
            })
        case SUPPLIER_WITH_OVER_TRANSFER_ITEMS_FETCH:
            return state.merge({ loading: action.loading })
        default:
            return state
    }
}
