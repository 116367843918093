import React from 'react'
import PropTypes from 'prop-types'

import { Button, Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'

import ProjectWrapper from './project.style'

const ProjectContactForm = ({
    i18n,
    projectContact,
    formErrors,
    onSubmitForm,
    saving,
    toggleForm
}) => {
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0
            },
            sm: {
                span: 16,
                offset: 8
            }
        }
    }

    const handleSubmitForm = (values) => {
        onSubmitForm(values)
    }

    return (
        <ProjectWrapper>
            <h3>{i18n.t('projects:contacts.formTitle')}</h3>
            <Form onFinish={handleSubmitForm} {...formItemLayout}>
                <Form.Item
                    name='name'
                    initialValue={projectContact.get('name')}
                    label={i18n.t('projects:contacts.name')}
                    validateStatus={formErrors.name && 'error'}
                    help={formErrors.name}
                >
                    <Input placeholder={i18n.t('projects:contacts.name')} />
                </Form.Item>

                <Form.Item
                    name='detail'
                    initialValue={projectContact.get('detail')}
                    label={i18n.t('projects:contacts.detail')}
                    validateStatus={formErrors.detail && 'error'}
                    help={formErrors.detail}
                >
                        <Input.TextArea placeholder={i18n.t('projects:contacts.detail')} />
                </Form.Item>


                <Form.Item className="project-form-action-btn" {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" loading={saving}>{i18n.t('shared:save')}</Button>
                    <Button htmlType="button" onClick={toggleForm}>{i18n.t('shared:close')}</Button>
                </Form.Item>
            </Form>
        </ProjectWrapper>
    )
}

ProjectContactForm.propTypes = {
    projectContact: PropTypes.object,
    formErrors: PropTypes.object,
    onSubmitForm: PropTypes.func.isRequired,
    saving: PropTypes.bool,
    toggleForm: PropTypes.func.isRequired
}

export default withTranslation(['shared', 'projects'])(ProjectContactForm)
