import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'

import { SENDING_STATE } from '../../constants/taxInvoices'
import { Card, Form, Button, Select } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import actions from '../../redux/orders/businessAddresses/actions'

const { saveOrderBusinessAddress, setShowSendingStateForm } = actions

class SendingStateForm extends Component {
    static propTypes = {
        invoice: PropTypes.object.isRequired,
        orderId: PropTypes.string.isRequired,

        orderBusinessAddresses: ImmutablePropTypes.map.isRequired,
        saveOrderBusinessAddress: PropTypes.func.isRequired,
        setShowSendingStateForm: PropTypes.func.isRequired
    }

    handleSubmit = (params) => {
        const { invoice, orderId, saveOrderBusinessAddress } = this.props

        saveOrderBusinessAddress({
            params: { order_business_address: params },
            id: invoice.id,
            orderId,
            onSuccess: () => {
                this.handleToggleForm()
            }
        })
    }

    handleToggleForm = (id = null) => {
        this.props.setShowSendingStateForm({ id })
    }

    sendingStateOptions = () => {
        return SENDING_STATE.map((sendingState, index) => {
            return (
                <Select.Option key={index} value={sendingState.value}>
                    {this.props.i18n.t(`documents:reports.${sendingState.i18n}`)}
                </Select.Option>
            )
        })
    }

    renderForm () {
        const { invoice, i18n } = this.props

        return (
            <Card size="small" style={{ minWidth: '150px' }}>
                <Form onFinish={this.handleSubmit}>
                    <Form.Item style={{ margin: '0px' }} label={i18n.t('confirms:invoice.sendingState')}
                        name='sending_state'
                        initialValue={_.get(invoice, 'sending_state', null)}>
                            <Select>
                                {this.sendingStateOptions()}
                            </Select>
                    </Form.Item>

                    <div style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" size="small" type="primary">
                            {i18n.t('shared:save')}
                        </Button>
                        <Button
                            onClick={() => this.handleToggleForm()}
                            size="small"
                            style={{ marginLeft: '10px' }}>
                            {i18n.t('shared:close')}
                        </Button>
                    </div>
                </Form>
            </Card>
        )
    }

    render () {
        const { invoice, orderBusinessAddresses, i18n } = this.props
        const invoiceId = _.get(invoice, 'id', '')
        const sendingState = _.get(invoice, 'sending_state', '')

        if (orderBusinessAddresses.get('activeEditSendingState') === invoiceId) {
            return this.renderForm()
        }

        return (
            <div>
                <strong>{i18n.t('confirms:invoice.sendingState')}: </strong>
                {i18n.t(`documents:reports.${_.camelCase(sendingState)}`)}
                <a onClick={() => this.handleToggleForm(invoiceId)}>
                    <EditTwoTone />
                </a>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    orderBusinessAddresses: state.get('orderBusinessAddresses')
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        saveOrderBusinessAddress,
        setShowSendingStateForm
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation(['confirms', 'documents', 'shared'])(SendingStateForm))
