import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import OrderYieldList from '../../components/orderYields/orderYieldsList'

import actions from '../../redux/orders/yields/actions'
import saleActions from '../../redux/sales/actions'

const {
    fetchOrderYields
} = actions

const {
    fetchSale
} = saleActions

const fetchInclude = [
    'complete_state',
    'complete_state.staff',
    'order',
    'order.complete_note',
    'order.pv_voucher_checked',
    'order.pv_voucher_checked.checked_by',
    'order.rv_voucher_checked',
    'order.rv_voucher_checked.checked_by',
    'order.order_business_addresses'
]

const fetchFields = {
    order_yield: [
        'complete_state',
        'order',
        'pv',
        'rv',
        'order_supplier_invoices'
    ],
    order: [
        'state',
        'complete_note',
        'pv_checked',
        'rv_checked',
        'sum_of_invoices',
        'reservation_date',
        'pv_voucher_checked',
        'rv_voucher_checked',
        'order_business_addresses'
    ],
    order_business_address: [
        'invoice_no',
        'invoice_date',
        'grand_total',
        'sending_state'
    ]
}

class OrderYieldListContainer extends Component {
    static propTypes = {
        // Inner Props
        fetchOrderYields: PropTypes.func.isRequired,
        fetchSale: PropTypes.func.isRequired,
        orderYields: ImmutablePropTypes.map.isRequired
    }

    static defaultProps = {}

    componentDidMount () {
        this.initFetch()
    }

    initFetch = () => {
        const { fetchSale, location } = this.props
        const saleId = new URLSearchParams(location.search).get("sale_id")
        const queryStringObject = this.parseParams(location.search)

        const queryString = {}

        _.mapKeys(queryStringObject, (value, key) => {
            queryString[_.camelCase(key)] = _.toString(value)
        })

        this.fetchOrderYields(queryString)

        if (!saleId) return null

        fetchSale({ id: saleId })
    }

    parseParams = (params = '') => {
      if(!params) return {}
      const rawParams = params.replace('?', '').split('&')
      const extractedParams = {}
      rawParams.forEach((item) => {
        item = item.split('=');
        extractedParams[item[0]] = item[1]
      })
      return extractedParams
    }


    fetchOrderYields = ({
        page,
        per,
        order,
        startDate,
        endDate,
        saleId,
        isCompleteYet,
        isRvEqualToInvoices
    } = {}) => {
        const { fetchOrderYields, orderYields } = this.props

        // keep previous if the argument is not present (its value is undefined)
        page = (page !== undefined) ? _.toNumber(page) : orderYields.get('page', 1)
        per = (per !== undefined) ? _.toNumber(per) : orderYields.get('per', 20)
        order = (order !== undefined) ? order : orderYields.get('order', 'updated_at desc')
        startDate = (startDate !== undefined) ? startDate : orderYields.get('startDate', '')
        endDate = (endDate !== undefined) ? endDate : orderYields.get('endDate', '')
        saleId = (saleId !== undefined) ? saleId : orderYields.get('saleId', '')
        isCompleteYet = (isCompleteYet !== undefined) ? isCompleteYet : orderYields.get('isCompleteYet', '')
        isRvEqualToInvoices = (isRvEqualToInvoices !== undefined) ? isRvEqualToInvoices : orderYields.get('isRvEqualToInvoices', '')

        fetchOrderYields({
            page,
            per,
            order,
            startDate,
            endDate,
            saleId,
            isCompleteYet,
            isRvEqualToInvoices,
            include: fetchInclude,
            fields: fetchFields,
            onSuccess: () => {
                this.setPathname({ order, startDate, endDate, saleId, isCompleteYet, isRvEqualToInvoices })
            }
        })
    }

    setPathname ({
        order,
        startDate,
        endDate,
        saleId,
        isCompleteYet,
        isRvEqualToInvoices
    } = {}) {
        const { history } = this.props

        const newQuery = {
            order: _.toString(order),
            start_date: _.toString(startDate),
            end_date: _.toString(endDate),
            sale_id: _.toString(saleId),
            is_complete_yet: _.toString(isCompleteYet),
            is_rv_equal_to_invoices: _.toString(isRvEqualToInvoices)
        }

        const newParams = _.pickBy(newQuery, (value) => value)
        const queryString = Object.keys(newParams).map(key => key + '=' + newParams[key]).join('&')

        history.push({
          search: `?${queryString}`,
          params: newParams
        })
    }

    render () {
        return (
            <OrderYieldList
                fetchOrderYields={this.fetchOrderYields}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        orderYields: state.get('orderYields')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchOrderYields,
        fetchSale
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderYieldListContainer))
