import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import { withAuthorization } from '../Authorization'
import currentStaffContainer from '../CurrentStaff/CurrentStaffContainer'
import TaxInvoicesIndexContainer from './TaxInvoicesIndexContainer'

class TaxInvoiceIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Tax Invoices | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                        { path: '/tax_invoices', name: 'Tax Invoices' }
                    ]}>
                    <ContentLayout>
                        <TaxInvoicesIndexContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default withAuthorization(
  currentStaffContainer(TaxInvoiceIndexPage),['super_admin', 'accounting']
)
