import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Dropdown, Menu, Typography } from 'antd'
import { SortAscendingOutlined, DownOutlined, FilterOutlined } from '@ant-design/icons'
import CardsContainer from './CardsContainer'
import LaneWarper  from './lane.style'

const { Title } = Typography

const header = {
  'pending': 'รอเสนอราคา',
  'client_review': 'รอลูกค้าตัดสินใจ',
  'done': 'จบงาน'
}

const sortOptions = {
  'assign_date_asc': 'วันที่เริ่มงาน น้อยไปมาก',
  'assign_date_desc': 'วันที่เริ่มงาน มากไปน้อย',
  'grand_total_asc': 'ยอดขาย น้อยไปมาก',
  'grand_total_desc': 'ยอดขาย มากไปน้อย'
}

const filterOptions = {
  'all':  'ทั้งหมด',
  'no_grand_total': 'ไม่มียอด',
  'big_grand_total': 'ยอดใหญ่เกินห้าหมื่น'
}

const renderCountNumber = (totalCount) => {
  if (!totalCount) return
  return (
    `(${totalCount})`
  )
}

const Lane = ({ state, otherFilters }) => {
  const [totalCount, setTotalCount] = useState(null)
  const [sortCurrent, setSortCurrent] = useState('assign_date_asc')
  const [filterOption, setFilterOption] = useState('all')

  const sortMenu = (
    <Menu onClick={(item, key) => {setSortCurrent(item.key)}}>
      <Menu.Item key={'assign_date_asc'}>
        {sortOptions['assign_date_asc'] }
      </Menu.Item>
      <Menu.Item key={'assign_date_desc'}>
        {sortOptions['assign_date_desc'] }
      </Menu.Item>
      <Menu.Item key={'grand_total_asc'}>
        {sortOptions['grand_total_asc'] }
      </Menu.Item>
      <Menu.Item key={'grand_total_desc'}>
        {sortOptions['grand_total_desc'] }
      </Menu.Item>
    </Menu>
  )

  const filterMenu = (
    <Menu onClick={(item, key) => {setFilterOption(item.key)}}>
      <Menu.Item key={'all'}>
        {filterOptions['all'] }
      </Menu.Item>
      <Menu.Item key={'no_grand_total'}>
        {filterOptions['no_grand_total'] }
      </Menu.Item>
      <Menu.Item key={'big_grand_total'}>
        {filterOptions['big_grand_total'] }
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <LaneWarper >
        <Title level={3} className='header'> {header[state]} {renderCountNumber(totalCount)}</Title>
        <div className='filter-container'>
          <Dropdown overlay={filterMenu}  placement="bottomRight">
              <div  style={{marginLeft: 'auto', marginRight: 16}}>
                <FilterOutlined />
                {filterOptions[filterOption]}
                <DownOutlined />
              </div>
          </Dropdown>

          <Dropdown overlay={sortMenu}  placement="bottomRight">
            <div>
              <SortAscendingOutlined />
              {sortOptions[sortCurrent]}
              <DownOutlined />
            </div>
          </Dropdown>
        </div>  
        <CardsContainer state={state} 
          setTotalCount={setTotalCount} 
          sortOption={sortCurrent} 
          filterOption={filterOption}
          otherFilters={otherFilters}
        />
      </LaneWarper>
    </div>
  )
}

Lane.propTypes = {
  state: PropTypes.string.isRequired,
  otherFilters: PropTypes.object
}

Lane.defaultProps = {
  otherFilters: {}
}

export default Lane
