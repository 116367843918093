
import { Map, List } from 'immutable'
import actions from './actions'

const {
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL,
    ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS,
    ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE,
    ORDER_BUSINESS_ADDRESS_SAVE_FAIL,
    ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS,
    ORDER_BUSINESS_ADDRESS_SAVING,
    ORDER_BUSINESS_ADDRESSES_FETCHING,
    ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
    ORDER_BUSINESS_ADDRESSES_SET_PAGINATION,
    ORDER_BUSINESS_ADDRESSES_SET_SORTER,
    SHOW_ORDER_BUSINESS_ADDRESS_MODAL,
    SHOW_ORDER_BUSINESS_ADDRESS_ITEMS_MODAL,
    SET_SHOW_ORDER_BUSINESS_ADDRESS_ACTIVE,
    ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING,
    ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS,
    SET_SHOW_SENDING_STATE_FORM,
    SHOW_ORDER_MAILING_ADDRESS_MODAL,
    ORDER_MAILING_ADDRESSES_SAVING,
    ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS,
    ORDER_MAILING_ADDRESSES_SAVE_SUCCESS,
    SET_ORDER_MAILING_ADDRESS_ACTIVE,
    ORDER_MAILING_ADDRESS_FETCHING,
    ORDER_MAILING_ADDRESS_FETCH_SUCCESS,
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS,
    CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING,
    SEND_TAX_INVOICES_MAIL_BY_FILTER,
    SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS
} = actions

const initState = new Map({
    item: '',
    items: new List(),
    loading: false,
    saving: false,
    pagination: new Map({
        current: null,
        pageSize: 100,
        total: null
    }),
    additionalData: new Map({
        sumOfOrderBusinessAddresses: null,
        sizeOfOrderBusinessAddresses: 0
    }),
    sorter: new Map(),
    invStartDate: undefined,
    invEndDate: undefined,
    sendingState: undefined,
    haveTrackingNumber: undefined,
    orderBusinessAddressFormModal: false,
    orderBusinessAddressItemsFormModal: false,
    orderBusinessAddressIdActive: null,
    orderNumberActive: null,
    invoiceReportLoading: false,
    activeEditSendingState: null,
    orderMailingAddressFormModal: false,
    mailingAddressFormErrors: new Map(),
    mailingAddressItems: new List(),
    orderMailingAddressIdActive: null,
    mailingAddressLoading: false,
    mailsSending: false
})

export default function OrderBusinessAddressesReducer (state = initState, action) {
    const items = state.get('items', new List())
    const result = action.result

    switch (action.type) {
        case ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_FAIL:
            return state.merge({ saving: false })
        case ORDER_BUSINESS_ADDRESS_DELETE_TRACKING_IMAGE_SUCCESS:
            return state.merge({
                saving: false,
                item: result,
                items: items.includes(result) ? items : items.push(result)
            })
        case ORDER_BUSINESS_ADDRESS_DELETING_TRACKING_IMAGE:
            return state.merge({ saving: true })
        case ORDER_BUSINESS_ADDRESS_SAVE_FAIL:
            return state.merge({ saving: false })
        case ORDER_BUSINESS_ADDRESS_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                item: result,
                items: items.includes(result) ? items : items.push(result)
            })
        case ORDER_BUSINESS_ADDRESS_SAVING:
            return state.merge({ saving: true })
        case ORDER_BUSINESS_ADDRESSES_FETCHING:
            return state.merge({ loading: true })
        case CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCHING:
            return state.merge({ loading: true })
        case ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false,
                invStartDate: action.invStartDate,
                invEndDate: action.invEndDate,
                sendingState: action.sendingState,
                haveTrackingNumber: action.haveTrackingNumber
            })
        case CUSTOMER_ORDER_BUSINESS_ADDRESSES_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false,
                additionalData: action.additionalData
            })
        case ORDER_BUSINESS_ADDRESSES_SET_PAGINATION:
            return state.mergeDeep({
                pagination: action.pagination
            })
        case ORDER_BUSINESS_ADDRESSES_SET_SORTER:
            return state.mergeDeep({ sorter: action.sorter })

        case SHOW_ORDER_BUSINESS_ADDRESS_MODAL:
            return state.merge({
                orderBusinessAddressFormModal: action.value
            })
        case SHOW_ORDER_BUSINESS_ADDRESS_ITEMS_MODAL:
            return state.merge({
                orderBusinessAddressItemsFormModal: action.value
            })
        case SET_SHOW_ORDER_BUSINESS_ADDRESS_ACTIVE:
            return state.merge({
                orderBusinessAddressIdActive: action.id,
                orderNumberActive: action.orderId
            })
        case ORDER_BUSINESS_ADDRESSES_REPORT_FETCHING:
            return state.merge({ invoiceReportLoading: action.loading })
        case ORDER_BUSINESS_ADDRESSES_REPORT_SUCCESS:
            return state.merge({ invoiceReportLoading: action.loading })
        case SET_SHOW_SENDING_STATE_FORM:
            return state.merge({
                activeEditSendingState: action.id
            })
        case SHOW_ORDER_MAILING_ADDRESS_MODAL:
            return state.merge({
                orderMailingAddressFormModal: action.value
            })
        case ORDER_MAILING_ADDRESSES_SAVING:
            return state.merge({
                saving: action.saving
            })
        case ORDER_MAILING_ADDRESSES_SET_FORM_ERRORS:
            return state.merge({
                mailingAddressFormErrors: action.formErrors
            })
        case ORDER_MAILING_ADDRESSES_SAVE_SUCCESS:
            return state.merge({
                mailingAddressSaving: false,
                mailingAddressFormErrors: new Map(),
                mailingAddressItems: action.result
            })
        case ORDER_MAILING_ADDRESS_FETCHING:
            return state.merge({
                mailingAddressLoading: action.loading
            })
        case SET_ORDER_MAILING_ADDRESS_ACTIVE:
            return state.merge({
                orderMailingAddressIdActive: action.id,
                orderNumberActive: action.orderId
            })
        case ORDER_MAILING_ADDRESS_FETCH_SUCCESS:
            return state.merge({
                mailingAddressItems: action.result
            })
        case SEND_TAX_INVOICES_MAIL_BY_FILTER:
            return state.merge({
                mailsSending: action.mailsSending
            })
        case SEND_TAX_INVOICES_MAIL_BY_FILTER_SUCCESS:
            return state.merge({
                mailsSending: action.mailsSending
            })
        default:
            return state
    }
}
