import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { gql, useMutation } from '@apollo/client'
import SelectItemState from '../../../SelectItemState'

import { Form, Button } from 'antd'

const UPDATE_ITEM_STATE_MUTATION = gql`
    mutation UpdateItemState($itemId: String!, $itemState: String!){
        updateItemState(itemId: $itemId, itemState: $itemState) {
          errors
        }
    }
`


const ItemStateForm = ({
  i18n,
  itemId,
  defaultValue,
  refetchOrder,
  onToggleForm
}) => {
  const [currentItemState, setItemState] = useState(defaultValue)

  const [updateItemState, { loading: saving }] = useMutation(UPDATE_ITEM_STATE_MUTATION, {
    onCompleted: ({ updateItemState }) => {
      if (!updateItemState.errors) {
        onToggleForm()
        refetchOrder()
      }
    }
  })

  const onSubmit = () => {
    updateItemState({ variables: { itemId, itemState: currentItemState } })
  }

  return (
    <Form onFinish={onSubmit}>
        <Form.Item>
          <SelectItemState defaultValue={currentItemState} onChange={setItemState} />
        </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" loading={saving}>
              {i18n.t('save')}
          </Button>
      </Form.Item>
    </Form>
  )
}

ItemStateForm.propTypes = {
  itemId: PropTypes.string.isRequired,
  fetchOrder: PropTypes.func,
  onToggleForm: PropTypes.func
}

ItemStateForm.defaultProps = {
  fetchOrder: () => {},
  onToggleForm: () => {}
}

export default ItemStateForm
