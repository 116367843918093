import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'
import CustomerFormContainer from './CustomerFormContainer'

class CustomerEditPage extends React.Component {
    componentDidMount() {
        document.title = 'Customer Edit | OSH CRM'
    }

    render () {
      const { match } = this.props
      const { id } = match.params

        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={
                    [
                        { path: '/customers', name: 'Customers' },
                        { path: `/customers/${id}`, name: `${id}` },
                        { path: `/customers/${id}/edit`, name: 'Edit' }
                    ]
                }>
                    <ContentLayout>
                        <CustomerFormContainer id={id} />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default CustomerEditPage
