import styled from 'styled-components'
const CustomerNoteWrapper = styled.div`

  .pagination {
    text-align: center;
  }

  .gray-topic {
    color: #989ca7;
  }

  .order_number {
    color: #e33c4f;
    float: right;
  }
  `

export default CustomerNoteWrapper