import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Col, InputNumber, Row, Table } from 'antd'

const ProductGroupSelectedItemsList = ({
    items,
    onChangeItemQuantity,
    onSelectItem,
    selectedItemIds
}) => {
    const { i18n } = useTranslation('inquiries')
    const columns = [
        {
            title: i18n.t('inquiries:number'),
            render: (text, record, index) => index + 1
        },
        {
            title: i18n.t('inquiries:itemDetail.itemName'),
            dataIndex: 'name'
        },
        {
            title: i18n.t('inquiries:itemDetail.quantity'),
            render: (record) => {
                return (
                    <InputNumber defaultValue={1} min={1}
                        onChange={(value) => onChangeItemQuantity(value, record.id)}
                    />
                )
            }
        }
    ]

    const rowSelection = {
        selectedRowKeys: selectedItemIds,
        onChange: onSelectItem
    }

    return (
        <Row>
            <Col span={12}><h3>{i18n.t('inquiries:lineItems')}</h3></Col>
            <Col span={12} style={{ textAlign: 'right', padding: '4px' }}>
                (Total {items.length} item(s))
            </Col>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={items}
                    pagination={false}
                    rowKey="id"
                    rowSelection={rowSelection}
                    size="middle"
                />
            </Col>
        </Row>
    )
}

ProductGroupSelectedItemsList.propTypes = {
    items: PropTypes.array,
    onChangeItemQuantity: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    selectedItemIds: PropTypes.array
}

ProductGroupSelectedItemsList.defaultProps = {
    items: [],
    selectedItemIds: []
}

export default ProductGroupSelectedItemsList
