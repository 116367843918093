import { all, takeLatest, takeEvery, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { deserialize } from '../../helpers/jsonApi'
import { apiFetch, apiPostForm, apiFileFetch } from '../../helpers/restRequest'

import { paymentVoucherSchema, paymentVouchersSchema, supplierInvoiceSchema } from '../schema'

import actions from './actions'
import actionsNotification from '../notifications/actions'
import _ from 'lodash'
import downLoadFile from '../../helpers/downLoadFile'

const { setNotifications } = actionsNotification
import { PAGE_SIZE } from '../../constants/orders'

const {
    FETCH_PAYMENT_VOUCHERS_REQUEST,
    FETCH_PAYMENT_VOUCHERS_SUCCESS,
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_REQUEST,
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS,
    FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE,
    SAVE_PAYMENT_VOUCHER_REQUEST,
    SAVE_PAYMENT_VOUCHER_SUCCESS,
    SAVE_PAYMENT_VOUCHER,
    PAYMENT_VOUCHERS_FETCHING,
    PAYMENT_VOUCHERS_SET_PAGINATION,
    SET_PAYMENT_VOUCHERS_INVOICE_FORM,
    SET_PAYMENT_VOUCHERS_NOTE_FORM,
    SUPPLIER_INVOICE_DELETE_REQUEST,
    SUPPLIER_INVOICE_DELETE_SUCCESS,
    SUPPLIER_INVOICE_SAVE_REQUEST,
    SUPPLIER_INVOICE_SAVE_SUCCESS,
    SUPPLIER_INVOICE_SAVING,
    FETCH_SUPPLIER_ORDERS_FILE_REQUEST,
    FETCH_SUPPLIER_ORDERS_FILE,
    FETCH_SUPPLIER_ORDERS_FILE_SUCCESS,
    FETCH_SUPPLIER_LINE_ITEMS_FILE_REQUEST,
    FETCH_SUPPLIER_LINE_ITEMS_FILE,
    FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS,
    FETCH_INVOICE_1688_FILE_REQUEST,
    FETCH_INVOICE_1688_FILE,
    FETCH_INVOICE_1688_FILE_SUCCESS,
    fetchPaymentVouchers: refetchPaymentVouchers
} = actions

const paymentVoucherPath = (id) => {
    const path = `/payment_vouchers`

    if (!id) { return path }
    return `${path}/${id}`
}


const supplierInvoicePath = (id) => {
    const path = '/suppliers/invoices'

    if (!id) { return path }
    return `${path}/${id}`
}

export function *fetchPaymentVouchers () {
    yield takeEvery(FETCH_PAYMENT_VOUCHERS_REQUEST, function *({ payload }) {
        yield put({ type: PAYMENT_VOUCHERS_FETCHING })

        let queryString = payload.queryString
        let supplier = queryString.supplier_ids
        if (typeof supplier === 'object') {
            supplier = supplier.map((value) => {
                return `supplier_ids[]=${value}`
            }).join('&')
        } else {
            supplier = supplier ? `supplier_ids[]=${supplier}` : ''
        }
        const dateType = queryString.date_type ? `date_type=${queryString.date_type}` : ''
        const startDate = queryString.start_date ? `start_date=${queryString.start_date}` : ''
        const endDate = queryString.end_date ? `end_date=${queryString.end_date}` : ''
        const period = queryString.apply_date ? `apply_date=${queryString.apply_date}` : ''
        const uploadInvoice = queryString.upload_invoice ? `upload_invoice=${queryString.upload_invoice}` : ''
        const isApplyDate = queryString.is_apply_date ? `is_apply_date=${queryString.is_apply_date}` : ''
        const query = queryString.query ? `query=${queryString.query}` : ''
        const page = queryString.page ? `page=${queryString.page}` : ''
        const per = `per=${PAGE_SIZE}`
        const { sorter } = payload
        const orderBy = sorter.value ? `order_by=${_.get(sorter, 'value.order', '')}` : ''
        const orderDirection = sorter.value ? `order_direction=${_.get(sorter, 'value.direction', '')}` : ''
        const amountMin = queryString.amount_min ? `amount_min=${queryString.amount_min}` : ''
        const amountMax = queryString.amount_max ? `amount_max=${queryString.amount_max}` : ''

        queryString = [
            supplier,
            dateType,
            startDate,
            endDate,
            period,
            uploadInvoice,
            isApplyDate,
            query,
            page,
            per,
            orderBy,
            orderDirection,
            amountMin,
            amountMax
        ].filter(Boolean).join('&')

        const path = `/payment_vouchers?${queryString}&only_product=true`
        const data = yield call(apiFetch, path)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, paymentVouchersSchema)

        const total = data.meta.total_pages * PAGE_SIZE
        yield put({
            type: PAYMENT_VOUCHERS_SET_PAGINATION,
            pagination: {
                pageSize: PAGE_SIZE,
                total,
                current: _.toInteger(payload.queryString.page)
            }
        })

        yield put({
            type: FETCH_PAYMENT_VOUCHERS_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitPaymentVoucher () {
    yield takeLatest(SAVE_PAYMENT_VOUCHER_REQUEST, function *({ payload }) {
        yield put({ type: SAVE_PAYMENT_VOUCHER, saving: true })
        const { values, id } = payload
        const method = id ? 'PATCH' : 'POST'
        const path = paymentVoucherPath(id)
        const body = JSON.stringify({ payment_voucher: values })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)
                yield put({
                    type: SAVE_PAYMENT_VOUCHER_SUCCESS,
                    ...normalize(formattedData, paymentVoucherSchema)
                })

                yield put({ type: SET_PAYMENT_VOUCHERS_NOTE_FORM, isOpenNoteForm: false })
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: SAVE_PAYMENT_VOUCHER, saving: false })
        }
    })
}

export function *submitSupplierInvoice () {
    yield takeLatest(SUPPLIER_INVOICE_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: SUPPLIER_INVOICE_SAVING })
        const { values, id, queryString } = payload
        const method = id ? 'PATCH' : 'POST'
        const path = supplierInvoicePath(id)

        try {
            const data = yield call(apiPostForm, path, method, { supplier_invoice: values }, [], ['supplier_order_ref_codes'])
            if ('error' in data) {
                yield put(setNotifications('fail', 'saveFail', 'error'))
            } else {
                const formattedData = deserialize(data)

                yield put({
                    type: SUPPLIER_INVOICE_SAVE_SUCCESS,
                    ...normalize(formattedData, supplierInvoiceSchema)
                })

                yield put({ type: SET_PAYMENT_VOUCHERS_INVOICE_FORM, isOpenInvoiceForm: false })
                yield put(refetchPaymentVouchers(queryString))
                yield put(setNotifications('success', 'saveSuccess', 'success'))
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *deleteSupplierInvoice () {
    yield takeLatest(SUPPLIER_INVOICE_DELETE_REQUEST, function *({ payload }) {
        const { supplierInvoiceId, paymentVoucherId, queryString } = payload
        const method = 'DELETE'
        const path = supplierInvoicePath(supplierInvoiceId)

        const data = yield call(apiFetch, `${path}?payment_voucher_id=${paymentVoucherId}`, method)

        const formattedData = deserialize(data)

        yield put({
            type: SUPPLIER_INVOICE_DELETE_SUCCESS,
            ...normalize(formattedData, supplierInvoiceSchema)
        })
        yield put({ type: SET_PAYMENT_VOUCHERS_INVOICE_FORM, isOpenInvoiceForm: false })
        yield put(refetchPaymentVouchers(queryString))
        yield put(setNotifications('success', 'saveSuccess', 'success'))
    })
}

export function *receiveTransferBackPVFile () {
    yield takeLatest(FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE })

        const { value } = payload

        const fileExtension = 'xlsx'
        const startDate = `start_date=${value['start_date']}`
        const endDate = `end_date=${value['end_date']}`
        const queryString = `?${startDate}&${endDate}`
        const path = `/payment_vouchers/transfer_back/exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `transfer-back-payment-vouchers-${value.start_date}-to-${value.end_date}.${fileExtension}`
        })

        yield put({
            type: FETCH_TRANSFER_BACK_PAYMENT_VOUCHERS_FILE_SUCCESS
        })
    })
}

export function *receiveSupplierOrdersFile () {
    yield takeLatest(FETCH_SUPPLIER_ORDERS_FILE_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_SUPPLIER_ORDERS_FILE })

        const { params } = payload

        const fileExtension = 'xlsx'
        const startDate = `start_date=${params.start_date}`
        const endDate = `end_date=${params.end_date}`
        const supplierId = `supplier_id=${params.supplier_id}`
        const queryString = `?${startDate}&${endDate}&${supplierId}`
        const path = `/supplier_order_exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `supplier-orders-${params.start_date}-to-${params.end_date}.${fileExtension}`
        })

        yield put({
            type: FETCH_SUPPLIER_ORDERS_FILE_SUCCESS
        })
    })
}

export function *receiveSupplierLineItemsFile () {
    yield takeLatest(FETCH_SUPPLIER_LINE_ITEMS_FILE_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_SUPPLIER_LINE_ITEMS_FILE })

        const { params } = payload

        const fileExtension = 'xlsx'
        const startDate = `start_date=${params.start_date}`
        const endDate = `end_date=${params.end_date}`
        const queryString = `?${startDate}&${endDate}`
        const path = `/supplier_line_items/exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `supplier-line-items-${params.start_date}-to-${params.end_date}.${fileExtension}`
        })

        yield put({
            type: FETCH_SUPPLIER_LINE_ITEMS_FILE_SUCCESS
        })
    })
}

export function *receiveInvoices1688 () {
    yield takeLatest(FETCH_INVOICE_1688_FILE_REQUEST, function *({ payload }) {
        yield put({ type: FETCH_INVOICE_1688_FILE })

        const { params } = payload

        const fileExtension = 'xlsx'
        const startDate = `start_date=${params.start_date}`
        const endDate = `end_date=${params.end_date}`
        const queryString = `?${startDate}&${endDate}`
        const path = `/order_business_addresses/customer1688_exports.${fileExtension}${queryString}`

        const data = yield call(apiFileFetch, path)
        const fileURL = URL.createObjectURL(data)

        downLoadFile({
            fileURL: fileURL,
            filename: `tax-invoice-1688-${params.start_date}-to-${params.end_date}.${fileExtension}`
        })

        yield put({
            type: FETCH_INVOICE_1688_FILE_SUCCESS
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(deleteSupplierInvoice),
        fork(fetchPaymentVouchers),
        fork(receiveTransferBackPVFile),
        fork(submitPaymentVoucher),
        fork(submitSupplierInvoice),
        fork(receiveSupplierOrdersFile),
        fork(receiveSupplierLineItemsFile),
        fork(receiveInvoices1688)
    ])
}
