const actions = {
    ORDER_SHIPPING_ADDRESSES_FETCH_REQUEST: 'ORDER_SHIPPING_ADDRESSES_FETCH_REQUEST',
    ORDER_SHIPPING_ADDRESSES_FETCH: 'ORDER_SHIPPING_ADDRESSES_FETCH',
    ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS: 'ORDER_SHIPPING_ADDRESSES_FETCH_SUCCESS',
    ORDER_SHIPPING_ADDRESSES_SET_LOADING: 'ORDER_SHIPPING_ADDRESSES_SET_LOADING',
    ORDER_SHIPPING_ADDRESSES_CLEAR_STATE_SHIPPING_ADDRESSES: 'ORDER_SHIPPING_ADDRESSES_CLEAR_STATE_SHIPPING_ADDRESSES',
    SET_SHOW_SELECT_SHIPPING_ADDRESSES: 'SET_SHOW_SELECT_SHIPPING_ADDRESSES',
    ORDER_SHIPPING_ADDRESSES_SAVE_REQUEST: 'ORDER_SHIPPING_ADDRESSES_SAVE_REQUEST',
    ORDER_SHIPPING_ADDRESSES_SAVING: 'ORDER_SHIPPING_ADDRESSES_SAVING',
    ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS: 'ORDER_SHIPPING_ADDRESSES_SAVE_SUCCESS',

    fetchOrderShippingAddress: (orderId) => ({
        type: actions.ORDER_SHIPPING_ADDRESSES_FETCH_REQUEST,
        payload: { orderId }
    }),
    clearStateOrderShippingAddress: () => ({
        type: actions.ORDER_SHIPPING_ADDRESSES_CLEAR_STATE_SHIPPING_ADDRESSES
    }),
    setShowSelectShippingAddressModal: (value) => ({
        type: actions.SET_SHOW_SELECT_SHIPPING_ADDRESSES,
        value
    }),
    saveOrderShippingAddress: ({ orderId, params, id, onSuccess = () => {} } = {}) => ({
        type: actions.ORDER_SHIPPING_ADDRESSES_SAVE_REQUEST,
        payload: { orderId, params, id, onSuccess }
    })
}

export default actions
