import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import { List as immutableList } from 'immutable'
import { Map, List } from 'immutable'

import { Select, Spin } from 'antd'
import SuppliersOrdersForm from '../../../components/Suppliers/Orders/SuppliersOrdersForm'

import supplierActions from '../../../redux/suppliers/actions'
import imageActions from '../../../redux/images/actions'
import { TRANSFER_BACK_BANK_ACCOUNTS } from '../../../constants/supplierOrders'

const { addNewImage } = imageActions

const {
    fetchSupplierOrder,
    saveSupplierOrders,
    searchSupplier,
    setDefaultPOType,
    setDefaultSupplier,
    setSelectedSupplier
} = supplierActions

class SuppliersOrdersFormContainer extends Component {
    static propTypes = {
        addNewImage: PropTypes.func.isRequired,
        customerEntities: immutablePropTypes.map.isRequired,
        fetchSupplierOrder: PropTypes.func.isRequired,
        imageEntities: immutablePropTypes.map.isRequired,
        orderEntities: immutablePropTypes.map.isRequired,
        saveSupplierOrders: PropTypes.func.isRequired,
        searchSupplier: PropTypes.func.isRequired,
        setDefaultPOType: PropTypes.func.isRequired,
        setDefaultSupplier: PropTypes.func.isRequired,
        setSelectedSupplier: PropTypes.func.isRequired,
        supplierOrdersEntity: immutablePropTypes.map.isRequired,
        suppliers: immutablePropTypes.map.isRequired,
        suppliersEntity: immutablePropTypes.map.isRequired,
        supplierOverTransferAccountEntities: immutablePropTypes.map.isRequired,

        isSupplierOrder: PropTypes.bool,
        isSupplierPurchase: PropTypes.bool,
        isTransfer: PropTypes.bool,
        formType: PropTypes.string.isRequired,
        supplierOrderId: PropTypes.string,
        isNewPage: PropTypes.bool.isRequired,
        onSaveSuccess: PropTypes.func
    }

    static defaultProps = {
        onSaveSuccess: () => {}
    }

    constructor (props) {
        super(props)

        this.state = {
            fileList: []
        }
    }

    async componentDidMount () {
        const {
            addNewImage,
            fetchSupplierOrder,
            isNewPage,
            orderId,
            setDefaultPOType,
            setDefaultSupplier,
            supplierOrderId,
            supplierOrdersEntity
        } = this.props
        const id = supplierOrderId
        const supplierId = await supplierOrdersEntity.getIn([supplierOrderId, 'supplier'])
        await setDefaultSupplier(supplierId)
        if (!isNewPage) {
            await fetchSupplierOrder(orderId, id)
            const supplierOrder = supplierOrdersEntity.get(id)
            await setDefaultPOType(supplierOrder.get('purchase_order_type'))
            const supplierOrderImages = supplierOrder.get('supplier_order_images')
            if (supplierOrderImages) {
                const imageThumb = supplierOrderImages.map((image) => {
                    const imageId = image.get('id')
                    const thumbUrl = image.getIn(['file', 's']) || ''
                    const originalUrl = image.getIn(['file', 's']) || ''
                    const contentType = image.getIn(['file', 'content_type']) || ''

                    addNewImage(thumbUrl, originalUrl || '', contentType)
                    return {
                        uid: imageId,
                        url: thumbUrl
                    }
                })
                this.setState({ fileList: imageThumb.toJS() })
            }
        } else {
            await setDefaultPOType('price_not_included')
        }
    }

    componentWillUnmount () {
        const { setDefaultSupplier, setSelectedSupplier } = this.props
        setDefaultSupplier(null)
        setSelectedSupplier(null)
    }

    onSearchSupplier = (query) => {
        const { searchSupplier } = this.props

        searchSupplier({ query })
    }

    onSelectSupplier = (value) => {
        const { setSelectedSupplier } = this.props

        setSelectedSupplier(value)
    }

    setImageParams (imageFiles) {
        if (!imageFiles) { return }
        const { supplierOrdersEntity, supplierOrderId } = this.props
        const paymentImages = supplierOrdersEntity.getIn(
            [supplierOrderId, 'supplier_order_images']
        ) || immutableList()
        let destroyImage = []
        let uploadImage = imageFiles
        paymentImages.map((image) => {
            if (!imageFiles.filter(value => value.uid === image.get('id')).length) {
                destroyImage.push({ id: image.get('id'), _destroy: true })
            } else {
                uploadImage = uploadImage.filter(value => value.uid !== image.get('id'))
            }
        })
        return uploadImage.map((id) => {
            return {
                file: id.originFileObj,
                _destroy: false
            }
        }).concat(destroyImage)
    }

    checkoutPoType (object) {
        const purchaseOrderType = object.purchase_order_type
        switch (purchaseOrderType) {
            case 'vat_included':
                return { has_custom_grand_total: false, vat_included: true }

            case 'vat_not_included':
                return { has_custom_grand_total: false, vat_included: false }

            case 'price_not_included':
                return { has_custom_grand_total: true, vat_included: true }
            default:
                return {}
        }
    }

    getFieldErrors = (fieldName) => {
        const { suppliers } = this.props
        const formErrors = suppliers.get('formErrors') || new Map()
        const errors = formErrors.get(fieldName) || new List()

        return {
            help: errors,
            status: errors.length ? 'error' : null
        }
    }

    handleSubmit = (values) => {
        const {
            onSaveSuccess,
            orderId,
            saveSupplierOrders,
            supplierOrderId,
            suppliers,
            formType
        } = this.props

        const selectedItems = suppliers.get('selectItems')
        const supplierId = suppliers.get('supplierId')

        const items = selectedItems.toJS()

        const lineItems = items.map((item) => {
            Reflect.deleteProperty(item, 'detail')
            return { ...item, supplier_id: supplierId }
        })

        const imageFiles = values.supplier_order_images_attributes
        const imageParams = this.setImageParams(imageFiles)
        const transferDate = values.transfer_date ? values.transfer_date.format('YYYY-MM-DD') : null
        const dueDate = values.due_date ? values.due_date.format('YYYY-MM-DD') : null

        if (values['bank_account_attributes']) {
            values['bank_account_attributes'] = this.getValueWithoutUndefined(values['bank_account_attributes'])
        }

        const valueWithoutUndefined = this.getValueWithoutUndefined(values)
        const supplierOrder = {
            ...valueWithoutUndefined,
            transfer_date: transferDate,
            due_date: dueDate,
            supplier_order_type: formType,
            line_items_attributes: lineItems,
            supplier_order_images_attributes: imageParams,
            ...this.checkoutPoType(valueWithoutUndefined)
        }

        saveSupplierOrders({
            id: supplierOrderId,
            orderId,
            supplierOrder,
            onSuccess: () => { onSaveSuccess() }
        })
    }

    handleSetFileList = (fileList) => {
        this.setState(fileList)
    }

    getValueWithoutUndefined = (values) => {
        const newValues = {}
        Object.entries(values).map((field) => {
            const fieldName = field[0]
            const fieldValue = field[1]

            newValues[fieldName] = (fieldValue === undefined || fieldValue === '') ? null : fieldValue
        })

        return newValues
    }

    getDefaultValue () {
        const { customerEntities, isNewPage, isTransfer, orderEntities, orderId, formType } = this.props

        if (!isTransfer || !isNewPage) {
            return {
                phone_number: null,
                email: null
            }
        }

        const customerId = orderEntities.getIn([orderId, 'customer', 'id'])
        const overpayAmount = orderEntities.getIn([orderId, 'overpay_amount'])


        return {
            phone_number: customerId && customerEntities.getIn([customerId, 'phone_number']) || null,
            email: customerId && customerEntities.getIn([customerId, 'email']) || null,
            grand_total: formType === 'transfer_back' ? overpayAmount : ''
        }
    }

    getSupplierOverTransferAccount = (accountId) => {
        const { supplierOverTransferAccountEntities } = this.props

        return supplierOverTransferAccountEntities.get(accountId, new Map()).toJS()
    }

    getTransferBackBankAccount () {
        const { Option } = Select

        return TRANSFER_BACK_BANK_ACCOUNTS.map((bankAccount) => {
            return (
                <Option key={bankAccount.value}
                    value={bankAccount.value}>
                    {bankAccount.i18n}
                </Option>
            )
        })
    }

    render () {
        const {
            imageEntities,
            isNewPage,
            isSupplierOrder,
            isSupplierPurchase,
            isTransfer,
            orderId,
            disabled,
            setDefaultPOType,
            supplierOrderId,
            supplierOrdersEntity,
            suppliers,
            suppliersEntity,
            formType
        } = this.props

        if (suppliers.get('supplierOrderLoading')) { return <Spin /> }

        return (
            <div>
                <SuppliersOrdersForm
                    defaultValue={this.getDefaultValue()}
                    fileList={this.state.fileList}
                    disabled={disabled}
                    getFieldErrors={this.getFieldErrors}
                    getSupplierOverTransferAccount={this.getSupplierOverTransferAccount}
                    handleSetFileList={this.handleSetFileList}
                    handleSubmit={this.handleSubmit}
                    imageEntities={imageEntities}
                    isNewPage={isNewPage}
                    isSupplierOrder={isSupplierOrder}
                    isSupplierPurchase={isSupplierPurchase}
                    isTransfer={isTransfer}
                    formType={formType}
                    loading={suppliers.get('loading')}
                    onSearchSupplier={this.onSearchSupplier}
                    onSelectSupplier={this.onSelectSupplier}
                    orderId={orderId}
                    purchaseOrderType={suppliers.get('showingPurchaseFormPOType')}
                    setDefaultPOType={setDefaultPOType}
                    supplierId={suppliers.get('supplierId')}
                    supplierOrderId={supplierOrderId}
                    supplierOrderSaving={suppliers.get('saving')}
                    supplierOrdersItems={supplierOrdersEntity}
                    suppliers={suppliers}
                    suppliersEntity={suppliersEntity}
                    transferBackBankAccount={this.getTransferBackBankAccount()}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customerEntities: state.getIn(['Entities', 'customers']),
    imageEntities: state.getIn(['Entities', 'images']),
    orderEntities: state.getIn(['Entities', 'orders']),
    supplierOrdersEntity: state.getIn(['Entities', 'supplierOrders']),
    suppliers: state.get('suppliers'),
    suppliersEntity: state.getIn(['Entities', 'suppliers']),
    supplierOverTransferAccountEntities: state.getIn(['Entities', 'supplierOverTransferAccounts'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addNewImage,
        fetchSupplierOrder,
        saveSupplierOrders,
        searchSupplier,
        setDefaultPOType,
        setDefaultSupplier,
        setSelectedSupplier
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersOrdersFormContainer)
