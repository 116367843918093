import { List, Map } from 'immutable'

import actions from './actions'

const {
    SALE_FETCH_FAIL,
    SALE_FETCH_SUCCESS,
    SALE_FETCHING,
    SALES_FETCH,
    SALES_FETCH_SUCCESS,
    SALES_REPORT_SET_LOADING
} = actions

const initState = new Map({
    item: '',
    items: new List(),
    query: '',
    loading: false,
    salesReportLoading: false
})

export default function salesReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case SALE_FETCH_FAIL:
            return state.merge({ loading: false })
        case SALE_FETCH_SUCCESS:
            return state.merge({
                item: result,
                items: [].concat(result),
                loading: false
            })
        case SALE_FETCHING:
            return state.merge({ loading: true })
        case SALES_FETCH_SUCCESS:
            return state.merge({
                items: result,
                loading: false
            })
        case SALES_FETCH:
            return state.merge({ loading: true })
        case SALES_REPORT_SET_LOADING:
            return state.merge({ salesReportLoading: action.loading })
        default:
            return state
    }
}
