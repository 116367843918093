import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import { List } from 'immutable'

import SelectChartOfAccount from '../../components/selectChartOfAccount/SelectChartOfAccount'

import actions from '../../redux/chartOfAccounts/actions'

const {
    fetchChartOfAccounts,
    fetchChartOfAccount
} = actions

const VALUE_FROM_STATE = 'VALUE_FROM_STATE'

class SelectChartOfAccountContainer extends Component {
    static propTypes = {
        // Props from another component
        onChange: PropTypes.func,
        value: PropTypes.string,

        // Inner props
        fetchChartOfAccount: PropTypes.func.isRequired,
        fetchChartOfAccounts: PropTypes.func.isRequired,
    }

    static defaultProps = {
        onChange: () => {},
        value: VALUE_FROM_STATE
    }

    constructor (props) {
        super(props)

        this.state = {
            value: props.value === VALUE_FROM_STATE ? undefined : props.value
        }
    }

    componentDidMount () {
        const { value, fetchChartOfAccount } = this.props

        if (!value || value === VALUE_FROM_STATE) return null
        fetchChartOfAccount({ id: value })
    }

    componentDidUpdate (prevProps) {
        const { value: prevValue } = prevProps
        const { value: currValue, fetchChartOfAccount } = this.props

        if (prevValue === currValue || currValue === VALUE_FROM_STATE) return null

        fetchChartOfAccount({ id: currValue })
    }

    getChartOfAccounts () {
        const { chartOfAccountEntities, chartOfAccounts } = this.props

        return chartOfAccounts.get('items', new List()).map((id) => {
            return chartOfAccountEntities.get(_.toString(id))
        })
    }

    onChange = (id) => {
        const { onChange } = this.props

        onChange(id)
        this.setState({ value: id })
    }

    onSearch = (query) => {
        const { fetchChartOfAccounts } = this.props
        fetchChartOfAccounts({ query })
    }

    render () {
        const {
            onChange,
            chartOfAccounts,
            value: propValue,
            ...restProps
        } = this.props
        const { value: stateValue } = this.state

        const value = propValue === VALUE_FROM_STATE ? stateValue : propValue

        return (
            <SelectChartOfAccount
                loading={chartOfAccounts.get('loading', false)}
                onChange={this.onChange}
                onSearch={this.onSearch}
                chartOfAccounts={this.getChartOfAccounts().toJS()}
                value={value}
                {...restProps}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
      chartOfAccountEntities: state.getIn(['Entities', 'chartOfAccounts']),
      chartOfAccounts: state.get('chartOfAccounts')
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchChartOfAccounts,
        fetchChartOfAccount
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectChartOfAccountContainer)

