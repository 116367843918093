const actions = {
    ORDER_ACTIVITY_LOG_ORDER_FETCH_REQUEST: 'ORDER_ACTIVITY_LOG_ORDER_FETCH_REQUEST',
    ORDER_ACTIVITY_LOG_ORDER_FETCH: 'ORDER_ACTIVITY_LOG_ORDER_FETCH',
    ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS: 'ORDER_ACTIVITY_LOG_ORDER_FETCH_SUCCESS',
    ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION: 'ORDER_ACTIVITY_LOG_ORDER_SET_PAGINATION',
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_REQUEST: 'ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_REQUEST',
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH: 'ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH',
    ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS: 'ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_SUCCESS',
    ORDER_LINE_ITEMS_ACTIVITY_FETCH_SUCCESS: 'ORDER_LINE_ITEMS_ACTIVITY_FETCH_SUCCESS',

    fetchActivityLogOrder: (page, orderId) => ({
        type: actions.ORDER_ACTIVITY_LOG_ORDER_FETCH_REQUEST,
        payload: { page, orderId }
    }),
    fetchActivityLogLineItems: (id) => ({
        type: actions.ORDER_ACTIVITY_LOG_LINE_ITEMS_FETCH_REQUEST,
        payload: { id }
    })
}

export default actions
