import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import DealsBoard from '../components/DealsBoard'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const DealsPage = ({
  location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'deals') {
			dispatch(changeCurrent(['deals']))
		}
	}, [pathname])

	const path = match.url

  return (
		<Switch>
			<Route path={`${path}/`} component={DealsBoard} exact />
		</Switch>
  )
}

DealsPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

DealsPage.defaultProps = {}

export default DealsPage
