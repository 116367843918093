import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import actions from './actions'
import { companySchema, companiesSchema, customerCompanySchema, ordersSchema } from '../../schema'
import { deserialize } from '../../../helpers/jsonApi'
import actionsNotification from '../../../redux/notifications/actions'

const { setNotifications } = actionsNotification

const {
    COMPANIES_SAVE,
    COMPANIES_SAVE_REQUEST,
    COMPANIES_SAVE_SUCCESS,
    COMPANIES_SET_FORM_ERRORS,
    COMPANIES_FETCH,
    COMPANIES_FETCH_SUCCESS,
    COMPANIES_SEARCH_REQUEST,
    COMPANIES_SET_QUERY,
    COMPANIES_SET_CLOSE_MODAL,
    COMPANIES_FETCH_REQUEST,
    COMPANIES_FETCHING,
    COMPANIES_SET_PAGINATION,
    COMPANIES_LIST_FETCH_SUCCESS,
    COMPANY_FETCH_REQUEST,
    COMPANY_FETCH,
    COMPANY_FETCH_SUCCESS,
    COMPANY_ORDERS_FETCH_REQUEST,
    COMPANY_ORDERS_FETCH,
    COMPANY_ORDERS_FETCH_SUCCESS,
    COMPANY_ORDERS_SET_PAGINATION
} = actions

const apiPath = (id) => {
    const basePath = '/companies'

    if (!id) { return basePath }
    return `${basePath}/${id}`
}

export function *searchCompanyRequest () {
    yield takeEvery(COMPANIES_SEARCH_REQUEST, function *({ payload }) {
        const query = payload.query
        const queryString = `query=${query}`

        if (query === '') { return null }

        yield put({ type: COMPANIES_SET_QUERY, query })

        yield put({ type: COMPANIES_FETCH })

        const data = yield call(apiFetch, `/companies?${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, companiesSchema)

        yield put({
            type: COMPANIES_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *companiesRequest () {
    yield takeEvery(COMPANIES_FETCH_REQUEST, function *({ payload }) {
        const page = payload.page
        const query = payload.query
        const PAGE_SIZE = 20
        const queryString = `query=${query}&page=${page}&per=${PAGE_SIZE}`

        yield put({ type: COMPANIES_SET_QUERY, query })

        yield put({ type: COMPANIES_FETCHING })

        const data = yield call(apiFetch, `/companies?${queryString}`)
        const formattedData = deserialize(data)

        const normalizeData = normalize(formattedData, companiesSchema)

        const total = data.meta.total_pages * PAGE_SIZE
        yield put({
            type: COMPANIES_SET_PAGINATION,
            pagination: { current: page, total }
        })

        yield put({
            type: COMPANIES_LIST_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *companyRequest () {
    yield takeLatest(COMPANY_FETCH_REQUEST, function *({ payload }) {
        const id = payload.id

        yield put({ type: COMPANY_FETCH })

        const data = yield call(apiFetch, `/companies/${id}`)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, customerCompanySchema)

        yield put({
            type: COMPANY_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *submitCompany () {
    yield takeLatest(COMPANIES_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: COMPANIES_SAVE })

        const { company, id, onSuccess } = payload
        const path = apiPath(id)
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify(company)

        try {
            const data = yield call(apiFetch, path, method, { body })

            if ('error' in data) {
                yield put({
                    type: COMPANIES_SET_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizedData = normalize(formattedData, companySchema)
                yield put({
                    type: COMPANIES_SAVE_SUCCESS,
                    ...normalizedData
                })
                
                onSuccess(normalizedData.result)
                yield put(setNotifications('success', 'saveSuccess', 'success'))
                yield put({ type: COMPANIES_SET_CLOSE_MODAL })
            }
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message

            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({
                type: COMPANIES_SAVE,
                saving: false
            })
        }
    })
}

export function *companyOrdersRequest () {
    yield takeLatest(COMPANY_ORDERS_FETCH_REQUEST, function *({ payload }) {
        const id = payload.companyId
        const PAGE_SIZE = 20
        const page = payload.page
        const queryString = `page=${page}&per=${PAGE_SIZE}`

        yield put({ type: COMPANY_ORDERS_FETCH })

        const data = yield call(apiFetch, `/companies/${id}/orders?${queryString}`)

        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, ordersSchema)

        const total = data.meta.total_pages * PAGE_SIZE
        yield put({
            type: COMPANY_ORDERS_SET_PAGINATION,
            ordersPagination: { current: page, total }
        })

        yield put({
            type: COMPANY_ORDERS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(submitCompany),
        fork(searchCompanyRequest),
        fork(companiesRequest),
        fork(companyRequest),
        fork(companyOrdersRequest)
    ])
}
