import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Table, Button, Modal, Popconfirm } from 'antd'
import { DeleteTwoTone, EditOutlined } from '@ant-design/icons'
import { Map } from 'immutable'
import i18n from 'i18next'

import { isMobile } from 'react-device-detect'

import actions from '../../../redux/potentialLeads/actions'
import PotentialLeadFormContainer from './PotentialLeadFormContainer'

const {
    fetchCustomerPotentailLeads,
    setCustomerPotentialLeadForm,
    deletePotentialLead
} = actions

class PotentialLeadIndexContainer extends Component {
    componentDidMount () {
        const { customerId, fetchCustomerPotentailLeads } = this.props
        fetchCustomerPotentailLeads(customerId)
    }

    getPotentialLeads () {
        const { potentialLeads, potentialLeadEntities } = this.props
        return potentialLeads.get('items').map((id) => {
            return potentialLeadEntities.get(id.toString())
        })
    }

    handleAddPotentialLeadForm (value, id) {
        const { setCustomerPotentialLeadForm } = this.props
        setCustomerPotentialLeadForm(value, id)
    }

    handleTableChange = (pagination) => {
        const { fetchCustomerPotentailLeads, customerId } = this.props

        const page = pagination.current

        fetchCustomerPotentailLeads(customerId, page)
    }

    getPagination () {
        const { potentialLeads } = this.props

        return potentialLeads.get('pagination') || new Map()
    }

    handleDelete = (customerId, id) => {
        const { deletePotentialLead } = this.props

        deletePotentialLead(customerId, id)
    }

    render () {
        const { customerId, potentialLeads } = this.props

        const columns = [{
            title: 'Line Id',
            dataIndex: 'line_id',
            key: 'lineId'
        }, {
            title: 'Brief Message',
            dataIndex: 'brief_message',
            key: 'briefMessage'
        }, {
            title: 'Group',
            dataIndex: 'group',
            key: 'group'
        }, {
            title: 'Supplier',
            dataIndex: 'supplier',
            key: 'supplier',
            render: (supplierId) => {
                if (!supplierId) { return null }
                const { supplierEntities } = this.props
                const supplier = supplierEntities.get(supplierId.toString())
                return (
                    <div>{supplier.get('name') || supplier.get('nick_name')}</div>
                )
            }
        }, {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            render: (brandId) => {
                if (!brandId) { return null }
                const { brandEntities } = this.props
                const brand = brandEntities.get(brandId.toString())
                return (
                    <div>{brand.get(`name_${i18n.language}`)}</div>
                )
            }
        }, {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <span>
                    <a onClick={() => this.handleAddPotentialLeadForm(true, row.id)}>
                        <EditOutlined style={{ marginRight: '15px' }} />
                    </a>
                    <Popconfirm title="confirm delete"
                        onConfirm={() => this.handleDelete(customerId, row.id)}>
                        <a>
                            <DeleteTwoTone />
                        </a>
                    </Popconfirm>
                </span>
            )
        }]

        return (
            <div>
                <Table columns={columns}
                    rowKey="id"
                    dataSource={this.getPotentialLeads().toJS()}
                    pagination={{
                        ...this.getPagination().toJS(),
                        showSizeChanger: false
                    }}
                    onChange={this.handleTableChange}
                    scroll={isMobile ? { x: 1300 } : { x: 1000 }}
                    loading={potentialLeads.get('loading')} />
                <Button type="primary" size="large"
                    onClick={() => this.handleAddPotentialLeadForm(true, null)}>
                    เพิ่ม Potential Lead
                </Button>
                <Modal
                    destroyOnClose
                    footer={null}
                    onCancel={() => this.handleAddPotentialLeadForm(false, null)}
                    visible={potentialLeads.get('showForm')}
                    width="60%">
                    <PotentialLeadFormContainer customerId={customerId}
                        id={potentialLeads.get('activeFormId')}/>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    potentialLeads: state.get('potentialLeads'),
    potentialLeadEntities: state.getIn(['Entities', 'potentialLeads']),
    supplierEntities: state.getIn(['Entities', 'suppliers']),
    brandEntities: state.getIn(['Entities', 'brands'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCustomerPotentailLeads,
        setCustomerPotentialLeadForm,
        deletePotentialLead
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PotentialLeadIndexContainer)
