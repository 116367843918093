import React from 'react'

import { Row, Col, Input, Form, Button, Alert } from 'antd'

import LoginWrapper from './loginForm.style'
import logoImage from '../../../public/images/base/logo-osh-crm.png'

const LoginForm = ({ auth, requestLogin }) => {
    const handleLogin = (values) => {
        requestLogin(values)
    }

    const renderError = () => {
        const errorMessage = auth.get('errorMessage')

        if (errorMessage !== '') {
            return (
                <Alert
                    message={errorMessage}
                    closeable
                    type="error" />
            )
        }
    }

    return (
        <LoginWrapper>
            <Row className="login-row">
                <Col md={{ span: 4, offset: 10 }}
                    xs={{ offset: 1, span: 22 }}
                    className="logo-image">
                    <img src={logoImage} alt="logo image" />
                </Col>
                <Col md={{ span: 4, offset: 10 }} xs={{ offset: 1, span: 22 }} justify="center" >
                    {renderError()}
                    <Form onFinish={handleLogin} >
                        <Form.Item  name="username" rules={[{ required: true, whitespace: true, message: 'Please input email' }]}>
                            <Input
                                type="email"
                                placeholder="email"
                                prefix={<i className="ion-ios-mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, whitespace: true, message: 'Please input password' }]}>
                            <Input.Password
                                placeholder="password"
                                prefix={<i className="ion-ios-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        </Form.Item>

                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="login-form-button"
                            block>
                Log in
                        </Button>
                    </Form>
                </Col>
            </Row>
        </LoginWrapper>
    )
}

export default LoginForm
