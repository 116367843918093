import { all, fork, takeLatest, put, call } from 'redux-saga/effects'

import actions from './actions'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'
import { normalize } from 'normalizr'
import { orderChangeActivitySchema } from '../../schema'

const {
    ORDER_HISTORY_DASHBOARDS_FETCH_FAIL,
    ORDER_HISTORY_DASHBOARDS_FETCH_REQUEST,
    ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS,
    ORDER_HISTORY_DASHBOARDS_FETCH
} = actions

export function *fetchOrderHistoryDashboards () {
    yield takeLatest(ORDER_HISTORY_DASHBOARDS_FETCH_REQUEST, function *({ payload }) {
        const { orderId, onSuccess, onError } = payload

        yield put({ type: ORDER_HISTORY_DASHBOARDS_FETCH })

        try {
            const data = yield call(apiFetch, `/orders/${orderId}/history_dashboards`)
            const formattedData = deserialize(data)
            const normalizedData = normalize(formattedData, orderChangeActivitySchema)

            yield put({ type: ORDER_HISTORY_DASHBOARDS_FETCH_SUCCESS, ...normalizedData })

            onSuccess()
        } catch (error) {
            yield put({ type: ORDER_HISTORY_DASHBOARDS_FETCH_FAIL })
            onError(error)
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchOrderHistoryDashboards)
    ])
}
