import React from 'react'
import { Spin } from 'antd'

const ImageComponent = (props) => {
    const { handleOnClickImage, thumbBlob, thumbLoading } = props

    if (thumbLoading) { return <Spin size="small" /> }

    return <img onClick={handleOnClickImage} src={thumbBlob} />
}

export default ImageComponent
