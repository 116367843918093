import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Col, Input, Row, Select } from 'antd'

import categoriesActions from '../../redux/categories/actions'
import inquiryActions from '../../redux/inquiry/actions'

const { fetchCategories, fetchSubCategories, fetchProductTypes } = categoriesActions

const {
    setSearchQuery,
    setItemQuery,
    setCategoryFilter,
    setSubCategoryFilter,
    setProductTypeFilter
} = inquiryActions

const { Option } = Select

class ItemFilters extends Component {
    constructor (props) {
        super(props)

        this.state = {
            subCategory: undefined,
            productType: undefined
        }
    }

    componentDidMount () {
        this.props.fetchCategories()
    }

    handleSearchChange = (value) => {
        this.props.setItemQuery(value)
    }

    handleSearchInput = (value) => {
        this.props.setSearchQuery(value)
    }

    handleCategoriesChange = (value, option) => {
        const { fetchCategories, fetchSubCategories, setCategoryFilter } = this.props
        let key = value && value.key
        setCategoryFilter(key)
        if (!value) {
            fetchCategories()
        } else {
            fetchSubCategories(key)
        }
        this.setState({ subCategory: undefined, productType: undefined })
    }

    handleSubCategoriesChange = (value) => {
        const { fetchProductTypes, fetchSubCategories, inquiry, setSubCategoryFilter } = this.props
        let key = value && value.key
        setSubCategoryFilter(key)
        if (!value) {
            fetchSubCategories(inquiry.get('itemCategoryId'))
        } else {
            fetchProductTypes(inquiry.get('itemCategoryId'), key)
        }
        this.setState({ subCategory: value, productType: undefined })
    }

    handleProductTypeChange = (value) => {
        const { fetchProductTypes, inquiry, setProductTypeFilter } = this.props
        let key = value && value.key
        setProductTypeFilter(key)
        if (!value) {
            fetchProductTypes(inquiry.get('itemCategoryId'), inquiry.get('itemSubCategoryId'))
        }
        this.setState({ productType: value })
    }

    categoriesList = () => {
        const { categories, categoryEntities, i18n } = this.props

        return categories.get('items').map((id) => {
            const category = categoryEntities.get(id + '')
            return (
                <Option key={id} value={id}>
                    {category.get(`name_${i18n.language}`)}
                </Option>
            )
        })
    }

    subCategoriesList = () => {
        const { categories, i18n, subCategoryEntities } = this.props

        return categories.get('subCategories').map((id) => {
            const subCategory = subCategoryEntities.get(id + '')
            return (
                <Option key={id} value={id}>
                    {subCategory.get(`name_${i18n.language}`)}
                </Option>
            )
        })
    }

    productTypesList = () => {
        const { categories, i18n, productTypeEntities } = this.props

        return categories.get('productTypes').map((id) => {
            const productType = productTypeEntities.get(id + '')
            return (
                <Option key={id} value={id}>
                    { productType.get(`name_${i18n.language}`) }
                </Option>
            )
        })
    }

    renderCategoriesFilter = () => {
        const { categories } = this.props
        return (
            <Select
                showSearch
                labelInValue
                allowClear
                placeholder="Select Categories"
                loading={categories.get('loading') || categories.get('subCategoriesLoading')}
                style={{ width: '100%' }}
                onChange={this.handleCategoriesChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {this.categoriesList()}
            </Select>
        )
    }

    renderSubCategoriesFilter = () => {
        const { categories, inquiry } = this.props
        return (
            <Select
                showSearch
                labelInValue
                allowClear
                value={this.state.subCategory}
                placeholder="Select Sub Categories"
                loading={categories.get('subCategoriesLoading')}
                disabled={inquiry.get('itemCategoryId') == null}
                style={{ width: '100%' }}
                onChange={this.handleSubCategoriesChange}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {this.subCategoriesList() }
            </Select>
        )
    }

    renderProductTypesFilter = () => {
        const { categories, inquiry } = this.props
        return (
            <Select
                showSearch
                labelInValue
                allowClear
                value={this.state.productType}
                placeholder="Select Product Type"
                onChange={this.handleProductTypeChange}
                loading={categories.get('productTypesLoading')}
                disabled={inquiry.get('itemSubCategoryId') == null}
                style={{ width: '100%' }}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {this.productTypesList()}
            </Select>
        )
    }

    render () {
        return (
            <Row gutter={12}>
                <Col xs={24} md={9}>
                    <Input.Search
                        placeholder="Search items"
                        onChange={(e) => this.handleSearchChange(e.target.value)}
                        onSearch={this.handleSearchInput}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col xs={24} md={5}>{this.renderCategoriesFilter()}</Col>
                <Col xs={24} md={5}>{this.renderSubCategoriesFilter()}</Col>
                <Col xs={24} md={5}>{this.renderProductTypesFilter()}</Col>
            </Row>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.get('categories'),
    inquiry: state.get('inquiry'),
    categoryEntities: state.getIn(['Entities', 'categories']),
    subCategoryEntities: state.getIn(['Entities', 'subCategories']),
    productTypeEntities: state.getIn(['Entities', 'productTypes']),
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCategories,
        fetchSubCategories,
        fetchProductTypes,
        setSearchQuery,
        setItemQuery,
        setCategoryFilter,
        setSubCategoryFilter,
        setProductTypeFilter
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemFilters))
