import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import WindowResizeListener from 'react-window-size-listener'
import { Debounce } from 'react-throttle'

import authActions from '../../redux/auth/actions'
import appActions from '../../redux/app/actions'

import LoginForm from '../../components/login/loginForm'

const { login } = authActions
const { toggleAll } = appActions

class Login extends React.Component {
    requestLogin = (values) => {
        this.props.login(values, { onSuccess: () => { location.reload() } })
    }

    render () {
      const { app, auth, toggleAll } = this.props

        if (app.get('height') === 0) {
            return (
                <div>
                    <Debounce time="90" handler="onResize">
                        <WindowResizeListener
                            onResize={windowSize => toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
                        />
                    </Debounce>
                </div>
            )
        }

        return (
            <LoginForm
                auth={auth}
                requestLogin={this.requestLogin} />
        )
    }
}

const mapStateToProps = state => ({
  app: state.get('App'),
  auth: state.get('Auth')
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    login,
    toggleAll
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
