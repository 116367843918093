import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import SaleSummaryIndexPage from '../containers/SalesSummary'
import SaleSummaryProspectsPage from '../containers/SalesSummary/Prospects'

import appActions from '../redux/app/actions'

const { changeCurrent } = appActions

const SalesSummaryPage = ({
	location,
	match
}) => {
  const dispatch = useDispatch()
	const current = useSelector(state => state.getIn(['App', 'current'])).toJS()
	const { pathname } = location

	useEffect(() => {
		if (current[0] !== 'sales_summary') {
			dispatch(changeCurrent(['sales_summary']))
		}
	}, [pathname])

	const path = match.url

  return (
    <Switch>
      <Route path={`${path}/`} component={SaleSummaryIndexPage} exact />
      <Route path={`${path}/:id/prospects`} component={SaleSummaryProspectsPage} />
    </Switch>
  )
}

SalesSummaryPage.propTypes = {
  location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
}

SalesSummaryPage.defaultProps = {}

export default SalesSummaryPage
