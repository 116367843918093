import React from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import ScmFinishedOrderRender from './render'

const UPDATE_SCM_FINISHED_ORDER = gql`
	mutation UpdateScmFinishedOrder($orderId: ID!) {
		updateScmFinishedOrder(orderId: $orderId) {
			order {
				id
				number
			}
			errors
		}
	}
`


const ScmFinishedOrder = ({ order }) => {
  const [saveScmFinishedOrder] = useMutation(UPDATE_SCM_FINISHED_ORDER)
  const onCheckScmFinishedOrder = () => {
    saveScmFinishedOrder({ variables: { orderId: order.id } })
  }

  if (!order.payment_time) {
    return null
  }

  return (
      <ScmFinishedOrderRender
        isScmFinishedOrder={order.scm_finished_order && order.scm_finished_order.finished}
        saveScmFinishedOrder={onCheckScmFinishedOrder}
      />
  )
}

ScmFinishedOrder.propTypes = {
  order: PropTypes.object,
  onSaveSuccess: PropTypes.func
}

ScmFinishedOrder.defaultProps = {
  onSaveSuccess: () => {}
}

export default ScmFinishedOrder
