import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {
    Select
} from 'antd'

import { withTranslation } from 'react-i18next'

const SelectOrderState = ({
    loading,
    onChange,
    orderStates,
    value,
    i18n,
    ...restSelectProps
}) => {
    return (
        <Select
            loading={loading}
            onChange={onChange}
            style={restSelectProps.style || { width: '100%' }}
            value={value}
            {...restSelectProps}
        >
            <Select.Option key="all" value="all">
                {i18n.t('orders:all')}
            </Select.Option>

            {orderStates.map((orderState) => {
                const name = _.get(orderState, 'name', undefined)
                const label = _.get(orderState, 'label', undefined)

                return (
                    <Select.Option key={name} value={name}>
                        {label}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

SelectOrderState.propTypes = {
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    orderStates: PropTypes.array,
    value: PropTypes.string
}

SelectOrderState.defaultProps = {
    loading: false,
    onChange: () => {},
    orderStates: [],
    value: undefined
}

export default withTranslation(['orders'])(SelectOrderState)
