import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import { Col, Row, Spin } from 'antd'

const Staff = ({
    getStaffRole,
    loading,
    staff
}) => {
    const { i18n } = useTranslation('staffs')

    const renderInfo = ({ label, value } = {}) => {
        return [
            <Col md={{ span: 6 }}>{label}</Col>,
            <Col md={{ span: 18 }}>{value}</Col>
        ]
    }

    const renderStaffRoles = () => {
        return staff.get('staff_roles', []).map(id => {
            return <div key={id}> - {_.startCase(getStaffRole(id).get('name'))}</div>
        })
    }

    return (
        <Spin spinning={loading}>
            <h3>{i18n.t('staffs:staffInfo')}</h3>
            <Row>
                {renderInfo({
                    label: `${i18n.t('staffs:form.firstName')} - ${i18n.t('staffs:form.lastName')}`,
                    value: `${staff.get('first_name')} ${staff.get('last_name') || ''}`
                })}
                {renderInfo({ label: i18n.t('staffs:form.email'), value: staff.get('email') })}
                {renderInfo({ label: i18n.t('staffs:form.phoneNumber'), value: staff.get('phone_number') })}
                {renderInfo({ label: i18n.t('staffs:form.role'), value: renderStaffRoles() })}
            </Row>
        </Spin>
    )
}

Staff.propTypes = {
    loading: PropTypes.bool,
    staff: ImmutablePropTypes.map.isRequired
}

Staff.defaultProps = {}

export default Staff
