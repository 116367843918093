import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useMutation } from '@apollo/client'

import { Button, Upload } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'

import { initActiveStorageBlobs } from '../../helpers/directUpload'

const DELETE_ACTIVE_STORAGE_BLOBS = gql`
  mutation DeleteActiveStorageBlob ($blobIds: [ID!]) {
    deleteActiveStorageBlob (blobIds: $blobIds) {
      deleteBlobIds
    }
  }
`

const ActiveStorageUploader = forwardRef(({ listType }, ref) => {
    const [fileList, setFileList] = useState([])
    const [blobs, setBlobs] = useState([])
    const [deleteActiveStorageBlob] = useMutation(DELETE_ACTIVE_STORAGE_BLOBS, {
        onCompleted: ({ deleteActiveStorageBlob })=> {
            const { deleteBlobIds } = deleteActiveStorageBlob
            setBlobs(blobs.filter(blob => !deleteBlobIds.includes(`${blob.id}`) ))
        }
    })

    useImperativeHandle(ref, () => ({
        clearBlobs: () => {
            deleteActiveStorageBlob({ variables: { blobIds: blobs.map(b => b.id) } })
            setFileList([])
        },
        clearValue: () => {
            setBlobs([])
            setFileList([])
        },
        getFileList: () => {
            return fileList
        },
        setFileList: (fileList) => {
            setFileList(fileList)
        },
        getValue: () => {
            return blobs.map(b => b.id)
        },
        initActiveStorageBlobs: (file) => {
            initActiveStorageBlobs([file], {
                setBlobs,
            })
        }
    }))

    const onDeleteImage = (file) => {
        const index = fileList.findIndex((fileItem) => fileItem.uid === file.uid)

        if (blobs[index]) {
            deleteActiveStorageBlob({ variables: { blobIds: [blobs[index].id] } })
        }
    }

    const onUploadImageCustomRequest = (args) => {
        const { file, onSuccess, onError = () => {} } = args

        initActiveStorageBlobs([file], {
            setBlobs,
            onSuccess: () => onSuccess('Ok'),
            onError: () => onError()
        })

        return {
            abort () {}
        }
    }

    const renderUploadButton = () => {
        if (listType === 'picture') {
            return <Button icon={<UploadOutlined />}>Upload</Button>
        }

        return <div><PlusOutlined /> Upload</div>
    }

    return (
        <Upload
            onChange={({ fileList }) => setFileList(fileList)}
            listType={listType}
            multiple
            accept="image/*"
            onRemove={onDeleteImage}
            customRequest={onUploadImageCustomRequest}
            fileList={fileList}
        >
            {renderUploadButton()}
        </Upload>
    )
})

ActiveStorageUploader.propTypes = {
    listType: PropTypes.string
}

ActiveStorageUploader.defaultProps = {
    listType: 'picture-card'
}

export default ActiveStorageUploader
