const actions = {
    CUSTOMER_TYPES_FETCH: 'CUSTOMER_TYPES_FETCH',
    CUSTOMER_TYPES_FETCH_REQUEST: 'CUSTOMER_TYPES_FETCH_REQUEST',
    CUSTOMER_TYPES_FETCH_SUCCESS: 'CUSTOMER_TYPES_FETCH_SUCCESS',
    CUSTOMER_TYPES_SAVE_SUCCESS: 'CUSTOMER_TYPES_SAVE_SUCCESS',
    CUSTOMER_TYPES_SAVE_REQUEST: 'CUSTOMER_TYPES_SAVE_REQUEST',
    CUSTOMER_TYPES_SET_FORM_ERRORS: 'CUSTOMER_TYPES_SET_FORM_ERRORS',
    CUSTOMER_TYPES_SET_SAVING: 'CUSTOMER_TYPES_SET_SAVING',
    CUSTOMER_TYPES_CLEAR_FORM_ERRORS: 'CUSTOMER_TYPES_CLEAR_FORM_ERRORS',

    saveCustomerType: (customerTypes) => ({
        type: actions.CUSTOMER_TYPES_SAVE_REQUEST,
        payload: { customerTypes }
    }),
    fetchCustomerTypes: () => ({
        type: actions.CUSTOMER_TYPES_FETCH_REQUEST
    }),
    clearCustomerTypesErrors: () => ({
        type: actions.CUSTOMER_TYPES_CLEAR_FORM_ERRORS
    })
}

export default actions
