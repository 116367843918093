import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { apiFetch } from '../../../helpers/restRequest'
import { deserialize } from '../../../helpers/jsonApi'

import { companyProjectSchema, companyProjectsSchema } from '../../schema'
import actions from './actions'

const {
    CUSTOMER_COMPANY_PROJECTS_FETCH_REQUEST,
    CUSTOMER_COMPANY_PROJECTS_FETCH,
    CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION,
    CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS,
    CUSTOMER_COMPANY_PROJECT_SAVE_REQUEST,
    CUSTOMER_COMPANY_PROJECT_SAVE,
    CUSTOMER_COMPANY_PROJECT_FORM_ERRORS,
    CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS
} = actions

export function *fetchCustomerCompanyProjects () {
    yield takeEvery(CUSTOMER_COMPANY_PROJECTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_COMPANY_PROJECTS_FETCH })

        const { page, per, customerId } = payload
        const queryString = `page=${page}&per=${per}`

        const data = yield call(apiFetch, `/customers/${customerId}/company_projects?${queryString}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, companyProjectsSchema)
        const total = data.meta.total_pages * per

        yield put({
            type: CUSTOMER_COMPANY_PROJECTS_SET_PAGINATION,
            pagination: { current: page, pageSize: per, total }
        })

        yield put({
            type: CUSTOMER_COMPANY_PROJECTS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export function *saveCustomerCompanyProject () {
    yield takeLatest(CUSTOMER_COMPANY_PROJECT_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: CUSTOMER_COMPANY_PROJECT_SAVE, saving: true })

        const { id, customerId, params, onSuccess, onError } = payload

        const basePath = `/customers/${customerId}/company_projects`
        const path = id ? `${basePath}/${id}` : basePath
        const method = id ? 'PATCH' : 'POST'

        const body = JSON.stringify({ company_project: params })

        try {
            const data = yield call(apiFetch, path, method, { body })
            if ('error' in data) {
                yield put({
                    type: CUSTOMER_COMPANY_PROJECT_FORM_ERRORS,
                    formErrors: data.error_description
                })
            } else {
                const formattedData = deserialize(data)
                const normalizeData = normalize(formattedData, companyProjectSchema)

                yield put({
                    type: CUSTOMER_COMPANY_PROJECT_SAVE_SUCCESS,
                    ...normalizeData
                })

                yield put({
                    type: CUSTOMER_COMPANY_PROJECT_FORM_ERRORS,
                    formErrors: {}
                })

                onSuccess()
            }
        } catch (error) {
            onError(error)
        } finally {
            yield put({ type: CUSTOMER_COMPANY_PROJECT_SAVE, saving: false })
        }
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchCustomerCompanyProjects),
        fork(saveCustomerCompanyProject)
    ])
}
