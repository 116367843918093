import styled from 'styled-components'
const ItemsWrapper = styled.div`
  .btn-center {
    text-align: center
  }

  .text-right {
    text-align: right
  }

  .header {
    margin: 5px;
  }

  .image-container {
    display: inline;
    flex-wrap: wrap;
  }

  .image-container > img {
    border: 1px solid #ddd;
    border-radius: 1px;
    padding: 1px;
    margin: 1px;
    width: 30px;
  }

  .detail-container > span {
    color: #000;
  }

  div.ql-toolbar {
    display: none;
  }

  div.ql-editor {
    border-top: 1px solid #ccc
  }
`
export default ItemsWrapper