import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { withTranslation } from 'react-i18next'
import { Modal, Form, Input, Button } from 'antd'

import actionCustomerTypes from '../../../redux/customers/customerTypes/actions'
import actionCustomer from '../../../redux/customers/actions'

const { saveCustomerType, clearCustomerTypesErrors } = actionCustomerTypes
const { setCustomerTypes } = actionCustomer

class CustomerTypesFormContainer extends Component {
    constructor () {
        super()

        this.formRef = createRef()
    }

    static propTypes = {
        visible: PropTypes.bool.isRequired
    }

    handleCreate = (values) => {
        const { saveCustomerType } = this.props

        saveCustomerType(values)
    }

    setModal = (value) => {
        const { setCustomerTypes, clearCustomerTypesErrors } = this.props
        setCustomerTypes(!value)
        clearCustomerTypesErrors()
    }

    render () {
        const { customerTypes, visible, i18n } = this.props
        const formErrors = customerTypes.get('formErrors')
        const nameEnError = formErrors.get('name_en')
        const nameThError = formErrors.get('name_th')

        return (
            <Modal visible={visible} destroyOnClose
                title={i18n.t('customerTypes:addCustomerTypes')}
                okText={i18n.t('customerForm.save')}
                footer={null}
                onCancel={() => this.setModal(visible)}>
                <Form ref={this.formRef} onFinish={this.handleCreate}>
                    <Form.Item label={`${i18n.t('customerTypes:customerTypes')} ${i18n.t('th')}`}
                        validateStatus={nameThError ? 'error' : null}
                        name='name_th'
                        help={nameThError && nameThError.join(', ')}
                        hasFeedback>
                            <Input placeholder={i18n.t('customerTypes:addCustomerTypes')} />
                    </Form.Item>
                    <Form.Item label={`${i18n.t('customerTypes:customerTypes')} ${i18n.t('en')}`}
                        validateStatus={nameEnError ? 'error' : null}
                        name='name_en'
                        help={nameEnError && nameEnError.join(', ')}
                        hasFeedback>
                            <Input placeholder={i18n.t('customerTypes:addCustomerTypes')} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button htmlType="submit" type="primary">
                            {i18n.t('shared:save')}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}


const mapStateToProps = state => ({
    customerTypes: state.get('customerTypes')
})

const mapDispatchToprops = (dispatch) => {
    return bindActionCreators({
        saveCustomerType,
        setCustomerTypes,
        clearCustomerTypesErrors
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToprops)(
    withTranslation(['customerTypes', 'common'])(CustomerTypesFormContainer)
)
