import React from 'react'

import Layout from '../App/layout'
import ContentLayout from '../../components/utility/layoutContent'
import LayoutContentWrapper from '../../components/utility/layoutWrapper'

import BankStatementsListContainer from './BankStatementListContainer'

class BankStatementIndexPage extends React.Component {
    componentDidMount() {
        document.title = 'Bank Statements | OSH CRM'
    }

    render () {
        return (
            <Layout>
                <LayoutContentWrapper breadcrumbs={[
                    { path: '/bank_statements', name: 'Bank Statements' }
                ]}>
                    <ContentLayout>
                        <BankStatementsListContainer />
                    </ContentLayout>
                </LayoutContentWrapper>
            </Layout>
        )
    }
}

export default BankStatementIndexPage
