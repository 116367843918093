import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Row } from 'antd'

import i18n from 'i18next'

import ProvinceSelect from '../../components/areas/ProvinceSelect'
import DistrictSelect from '../../components/areas/DistrictSelect'

import provinceActions from '../../redux/provinces/actions'
import districtActions from '../../redux/districts/actions'

const {
    fetchProvinces
} = provinceActions

const {
    fetchDistricts
} = districtActions

class AreasSelectContainer extends Component {
    static propTypes = {
        districtCol: PropTypes.object,
        districtFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        districtFormItemLayout: PropTypes.object,
        districtHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        districts: ImmutablePropTypes.map.isRequired,
        districtValidateStatus: PropTypes.string,
        districtValue: PropTypes.string,
        Entities: ImmutablePropTypes.map.isRequired,
        form: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        postalCodeCol: PropTypes.object,
        postalCodeDisabled: PropTypes.bool,
        postalCodeFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        postalCodeFormItemLayout: PropTypes.object,
        postalCodeHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        postalCodeValidateStatus: PropTypes.string,
        provinceCol: PropTypes.object,
        provinceFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        provinceFormItemLayout: PropTypes.object,
        provinceHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        provinces: ImmutablePropTypes.map.isRequired,
        provinceValidateStatus: PropTypes.string,
        provinceValue: PropTypes.string,
        showPostalCode: PropTypes.bool,
        showSubDistrict: PropTypes.bool,
        subDistrictCol: PropTypes.object,
        subDistrictFieldName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        subDistrictFormItemLayout: PropTypes.object,
        subDistrictHelp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        subDistrictValidateStatus: PropTypes.string,
        subDistrictValue: PropTypes.string
    }

    static defaultProps = {
        districtFieldName: 'district_id',
        districtHelp: '',
        districtValidateStatus: '',
        onChange: () => {},
        postalCodeDisabled: true,
        postalCodeFieldName: 'postal_code',
        postalCodeHelp: '',
        postalCodeValidateStatus: '',
        provinceFieldName: 'province_id',
        provinceHelp: '',
        provinceValidateStatus: '',
        showPostalCode: true,
        showSubDistrict: false,
        subDistrictFieldName: 'sub_district'
    }

    constructor (props) {
        super(props)

        this.state = {
            selectedDistrictId: '',
            selectedProvinceId: ''
        }
    }

    componentDidMount () {
        const { fetchProvinces, provinceValue, fetchDistricts, districtValue } = this.props

        fetchProvinces()

        if (provinceValue) {
            fetchDistricts(provinceValue)
            this.setState({
                selectedProvinceId: provinceValue,
                selectedDistrictId: districtValue
            })
        }
    }

    async componentDidUpdate (prevProps) {
        const { provinceValue, fetchDistricts, districtValue } = this.props
        const prevSelectedProvinceId = prevProps.provinceValue

        if (prevSelectedProvinceId !== provinceValue) {
            await fetchDistricts(provinceValue)
            this.setState({
                selectedProvinceId: provinceValue,
                selectedDistrictId: districtValue
            })
        } else { return null }
    }

    getValues = () => {
        const { selectedProvinceId, selectedDistrictId } = this.state

        return {
            provinceId: selectedProvinceId,
            districtId: selectedDistrictId
        }
    }

    onProvinceChange = (value) => {
        const { onChange } = this.props

        // Set District Select Input to Show Place Holder
        this.setDistrictValue(undefined)
        // Set Postal Code Input to Show Place Holder
        this.setPostalCodeValue(undefined)
        // Set Sub District Input to Show Place Holder
        this.setSubDistrictValue(undefined)

        // When user click clear button on province select input
        if (!value) {
            this.setState({
                selectedDistrictId: '',
                selectedProvinceId: ''
            })

            onChange({
                selectedProvinceId: '',
                selectedDistrictId: ''
            })

            return null
        }

        // fetch district data follow by province id
        const { fetchDistricts } = this.props
        fetchDistricts(value.toString())

        this.setState({
            selectedProvinceId: value.toString(),
            selectedDistrictId: ''
        })

        onChange({
            selectedProvinceId: value.toString(),
            selectedDistrictId: ''
        })
    }

    onDistrictChange = (value) => {
        const { onChange } = this.props
        const { selectedProvinceId } = this.state

        // Set Postal Code Input to Show Place Holder
        this.setPostalCodeValue(undefined)
        // Set Sub District Input to Show Place Holder
        this.setSubDistrictValue(undefined)

        if (!value) {
            this.setState({ selectedDistrictId: '' })

            onChange({
                selectedProvinceId,
                selectedDistrictId: ''
            })

            return null
        }

        this.setState({ selectedDistrictId: value.toString() })
        this.setPostalCodeValue(this.getDistrictPostalCode(value))

        onChange({
            selectedProvinceId,
            selectedDistrictId: value.toString()
        })
    }

    getProvinceLoading () {
        const { provinces } = this.props
        return provinces.get('loading')
    }

    getProvinces () {
        const { Entities, provinces } = this.props

        const provinceData = provinces.get('items').map((provinceId) => {
            return Entities.getIn(['provinces', provinceId.toString()])
        })

        return provinceData
    }

    getDistricts () {
        const { selectedProvinceId } = this.state
        const { Entities } = this.props

        if (!selectedProvinceId) { return [] }
        const districtIds = Entities.getIn(['provinces', selectedProvinceId.toString(), 'districts'])

        if (!districtIds) { return [] }

        return districtIds.map((districtId) => {
            return Entities.getIn(['districts', districtId.toString()]).toJS()
        })
    }

    getDistrictLoading () {
        const { districts } = this.props
        return districts.get('loading')
    }

    getDistrictValue () {
        const { selectedDistrictId } = this.state
        const { Entities } = this.props

        if (!selectedDistrictId) { return '' }

        return Entities.getIn(['districts', selectedDistrictId.toString(), `name_${i18n.language}`])
    }

    getDistrictPostalCode (districtId) {
        const { Entities } = this.props

        if (!districtId) { return '' }

        return Entities.getIn(['districts', districtId.toString(), 'postal_code'])
    }

    getFieldValue (fieldName, value) {
        if (Array.isArray(fieldName)) {
            return {
                [fieldName[0]]: {
                    [fieldName[1]]: value
                }
            }
        } else {
            return { [fieldName]: value }
        }
    }

    setDistrictValue (districtId) {
        const { form, districtFieldName } = this.props

        const fieldValue = this.getFieldValue(districtFieldName, districtId)
        const formRef = form.current ? form.current : form

        formRef.setFieldsValue(fieldValue)
    }

    setPostalCodeValue (postalCode) {
        const { form, postalCodeFieldName } = this.props

        const fieldValue = this.getFieldValue(postalCodeFieldName, postalCode)
        const formRef = form.current ? form.current : form

        formRef.setFieldsValue(fieldValue)
    }

    setSubDistrictValue (subDistrict) {
        const { form, subDistrictFieldName } = this.props

        const fieldValue = this.getFieldValue(subDistrictFieldName, subDistrict)
        const formRef = form.current ? form.current : form

        formRef.setFieldsValue(fieldValue)
    }

    render () {
        const {
            districtCol,
            districtFieldName,
            districtFormItemLayout,
            districtHelp,
            districtValidateStatus,
            districtValue,
            form,
            postalCodeCol,
            postalCodeDisabled,
            postalCodeFieldName,
            postalCodeFormItemLayout,
            postalCodeHelp,
            postalCodeValidateStatus,
            postalCodeValue,
            provinceCol,
            provinceFieldName,
            provinceFormItemLayout,
            provinceHelp,
            provinceValidateStatus,
            provinceValue,
            showPostalCode,
            showSubDistrict,
            subDistrictCol,
            subDistrictFieldName,
            subDistrictFormItemLayout,
            subDistrictHelp,
            subDistrictValidateStatus,
            subDistrictValue
        } = this.props

        return (
            <div>
                <Row>
                    <ProvinceSelect col={provinceCol}
                        fieldName={provinceFieldName}
                        formItemLayout={provinceFormItemLayout}
                        help={provinceHelp}
                        loading={this.getProvinceLoading()}
                        onSelectProvinceChange={this.onProvinceChange}
                        provinceValue={provinceValue}
                        provinces={this.getProvinces()}
                        validateStatus={provinceValidateStatus} />

                    <DistrictSelect districtCol={districtCol}
                        districtFormItemLayout={districtFormItemLayout}
                        districtHelp={districtHelp}
                        districts={this.getDistricts()}
                        districtValidateStatus={districtValidateStatus}
                        districtValue={districtValue}
                        fieldName={districtFieldName}
                        form={form}
                        loading={this.getDistrictLoading()}
                        onSelectDistrictChange={this.onDistrictChange}
                        postalCode={postalCodeValue}
                        postalCodeCol={postalCodeCol}
                        postalCodeDisabled={postalCodeDisabled}
                        postalCodeFieldName={postalCodeFieldName}
                        postalCodeFormItemLayout={postalCodeFormItemLayout}
                        postalCodeHelp={postalCodeHelp}
                        postalCodeValidateStatus={postalCodeValidateStatus}
                        showPostalCode={showPostalCode}
                        showSubDistrict={showSubDistrict}
                        subDistrictCol={subDistrictCol}
                        subDistrictFieldName={subDistrictFieldName}
                        subDistrictFormItemLayout={subDistrictFormItemLayout}
                        subDistrictHelp={subDistrictHelp}
                        subDistrictValidateStatus={subDistrictValidateStatus}
                        subDistrictValue={subDistrictValue} />
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    Entities: state.get('Entities'),
    provinces: state.get('provinces'),
    districts: state.get('districts')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchProvinces,
        fetchDistricts
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasSelectContainer)
