import React from 'react'
import { Select, Spin, Input, Button, Form, Upload, DatePicker, Checkbox } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import immutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'
import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import EditorContainer from '../../../containers/Editor/EditorContainer'
import SupplierOrderWrapper from '../../../containers/SupplierOrders/supplierOrder.style'

const { Option } = Select

const supplierResult = (props) => {
    const { suppliersEntity, suppliers } = props

    return suppliers.get('supplierItems').map((supplierId) => {
        const supplier = suppliersEntity.get(supplierId)
        const accountName = supplier && supplier.get('display_name')

        return (
            <Option key={supplierId} value={supplierId}> {accountName} </Option>
        )
    })
}

const searchSupplier = (props) => {
    const {
        getSupplierOverTransferAccount,
        isNewPage,
        loading,
        onSearchSupplier,
        onSelectSupplier,
        supplierOrderId,
        supplierOrdersItems,
        isSupplierPurchase,
        isSupplierOrder,
        isTransfer,
        i18n,
        suppliersEntity,
        suppliers,
        disabled
    } = props

    if (!isNewPage && !isSupplierPurchase && !isSupplierOrder || isTransfer) { return null }

    const supplierId = suppliers.get('supplierId') || supplierOrdersItems.getIn([supplierOrderId, 'supplier'])
    const defaultConditionNote = suppliersEntity.getIn([supplierId, 'condition_note'])
    const accountId = suppliersEntity.getIn([supplierId, 'supplier_over_transfer_account'])
    const account = getSupplierOverTransferAccount(accountId)

    return (
        <div>
            <h3>{i18n.t('suppliers:searchSupplier')}</h3>
            <Form.Item name='supplier_id' initialValue={supplierId}>
                <Select
                    showSearch
                    allowClear
                    disabled={disabled}
                    defaultActiveFirstOption={false}
                    key='select_supplier'
                    showArrow={false}
                    filterOption={false}
                    placeholder={i18n.t('suppliers:searchSupplier')}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    onSearch={(value) => onSearchSupplier(value)}
                    onChange={onSelectSupplier}
                    style={{ width: '100%' }}>
                    {supplierResult(props)}
                </Select>
            </Form.Item>
            <div style={{ marginBottom: '8px' }}>
                {defaultConditionNote && (
                    <div key='condition_note' className={'condition-note'}>
                        <span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers:conditionNote')}: </span>
                        {defaultConditionNote}
                    </div>
                )}
                <div key='display_balance'>
                    <span style={{ fontWeight: 'bold' }}>{i18n.t('suppliers/overTransferAccounts:balance')}: </span>
                    <span>
                        {_.get(account, 'balance.display', '0.00')}
                    </span>
                </div>
            </div>
        </div>
    )
}

const noteValue = (props) => {
    const { isNewPage, supplierOrderId, supplierOrdersItems } = props

    if (!isNewPage) {
        const supplierOrder = supplierOrdersItems.get(`${supplierOrderId}`) || new Map()
        return supplierOrder.get('note')
    } else return ''
}

const supplierNoteValue = (props) => {
    const { isNewPage, supplierOrderId, supplierOrdersItems } = props

    if (!isNewPage) {
        const supplierOrder = supplierOrdersItems.get(`${supplierOrderId}`) || new Map()
        return supplierOrder.get('external_note')
    } else return ''
}

const iconUpload = (props) => {
    const { i18n } = props
    return (
        <div>
            <PlusOutlined />
            <div>{i18n.t('suppliers:image')}</div>
        </div>
    )
}


const uploadImage = props => {
    const { fileList, handleSetFileList, disabled } = props

    const renderFileList = (images) => {
        const { imageEntities } = props

        const fileList = images.map((file) => {
            let url = imageEntities.getIn([file.url, 'thumbBlob'])
            url = url || file.url

            if (url) {
                return {
                    uid: file.uid,
                    url: url
                }
            }

            return file
        })

        return fileList
    }

    return (
        <Upload.Dragger
            disabled={disabled}
            multiple
            listType="picture-card"
            showUploadList={{ showPreviewIcon: false }}
            beforeUpload={() => false}
            fileList={renderFileList(fileList)}
            onChange={(e) => handleSetFileList(e)}>
            {iconUpload(props)}
        </Upload.Dragger>
    )
}

const renderTransferDate = props => {
    const { i18n, supplierOrdersItems, supplierOrderId, disabled } = props
    const transferDate = supplierOrdersItems.getIn([supplierOrderId, 'transfer_date'])

    return (
        <Form.Item label={i18n.t('suppliers:supplierOrder.transferDate')}
            name='transfer_date'
            initialValue={transferDate ? moment(transferDate, 'YYYY-MM-DD') : null}>
                <DatePicker style={{ width: '100%' }} disabled={disabled}/>
        </Form.Item>
    )
}

const renderDueDate = props => {
    const { i18n, supplierOrdersItems, supplierOrderId } = props
    const dueDate = supplierOrdersItems.getIn([supplierOrderId, 'due_date'])

    return (
        <Form.Item label={i18n.t('suppliers:supplierOrder.dueDate')}
            name='due_date'
            initialValue={dueDate ? moment(dueDate, 'YYYY-MM-DD') : null}>
                <DatePicker style={{ width: '100%' }} />
        </Form.Item>
    )
}

const renderInputGrandTotal = props => {
    const {
        defaultValue,
        getFieldErrors,
        i18n,
        isTransfer,
        purchaseOrderType,
        supplierOrderId,
        supplierOrdersItems,
        disabled
    } = props

    if (!isTransfer && purchaseOrderType !== 'price_not_included') { return null }

    let grandTotal = supplierOrdersItems.getIn([supplierOrderId, 'grand_total', 'raw'])
    const textLabel = isTransfer ? i18n.t('suppliers:transferForm.amount') : i18n.t('suppliers:grandTotal')
    const errors = getFieldErrors('grand_total')
    grandTotal = defaultValue['grand_total'] || grandTotal

    return (
        <Form.Item
            label={textLabel}
            name='grand_total'
            initialValue={grandTotal}
            validateStatus={errors.status}
            help={errors.help}>
                <Input prefix="฿" placeholder="0.00" disabled={disabled} />
        </Form.Item>
    )
}

const renderQuickTransfer = props => {
    const { i18n, supplierOrdersItems, supplierOrderId, disabled } = props
    const isAvailable = supplierOrdersItems.getIn([supplierOrderId, 'is_available'])

    return (
        <Form.Item name='is_available' valuePropName='checked'
            initialValue={isAvailable}>
                <Checkbox disabled={disabled}>{i18n.t('suppliers:quickTransfer')}</Checkbox>
        </Form.Item>
    )
}

const renderNote = (props, form) => {
    const { i18n, isTransfer } = props
    const textLabel = isTransfer ? i18n.t('suppliers:transferForm.cause') : i18n.t('suppliers:note')

    const imageHandler = (file, imgBase64) => {
        const { fileList, handleSetFileList } = props
        const newItemOfFileList = {
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            originFileObj: file,
            percent: 0,
            size: file.size,
            thumbUrl: imgBase64,
            type: file.type,
            uid: `rc-${new Date().getTime()}`
        }
        form.setFieldsValue({ supplier_order_images_attributes: fileList.concat(newItemOfFileList) })
        handleSetFileList({ fileList: fileList.concat(newItemOfFileList), File: newItemOfFileList })
    }

    return (
        <Form.Item label={textLabel} name='note'
            initialValue={noteValue(props)}>
                <EditorContainer theme="snow"
                    imageHandler={imageHandler} />
        </Form.Item>
    )
}

const renderSupplierNote = props => {
    const { i18n, isTransfer } = props

    if (isTransfer) { return null }

    return (
        <Form.Item label={i18n.t('suppliers:supplierNote')}
            name='external_note'
            initialValue={supplierNoteValue(props)}>
                <Input.TextArea/>
        </Form.Item>
    )
}

const renderBankAccountDetail = props => {
    const {
        defaultValue,
        getFieldErrors,
        i18n,
        supplierOrdersItems,
        supplierOrderId,
        transferBackBankAccount
    } = props
    const bankAccount = supplierOrdersItems.getIn([supplierOrderId, 'bank_account']) || new Map()
    const phoneNumber = defaultValue['phone_number'] || bankAccount.get('phone_number')
    const email = defaultValue['email'] || bankAccount.get('email')

    const bankAccountErrors = getFieldErrors('bank_account')
    const bankNameErrors = getFieldErrors('bank_account.bank_name')
    const nameErrors = getFieldErrors('bank_account.name')
    const numberErrors = getFieldErrors('bank_account.number')
    const phoneNumberErrors = getFieldErrors('bank_account.phone_number')
    const emailErrors = getFieldErrors('bank_account.email')
    return (
        <div>
            <h3>{i18n.t('suppliers:transferForm.title')}</h3>
            <Form.Item label={i18n.t('suppliers:transferForm.bankName')}
                validateStatus={bankNameErrors.status || bankAccountErrors.status}
                help={bankNameErrors.help || bankAccountErrors.help}
                name={['bank_account_attributes', 'bank_name']}
                initialValue={bankAccount.get('bank_name') || undefined}>
                    <Select allowClear style={{ width: '100%' }}
                        placeholder={i18n.t('suppliers:transferForm.selectBankName')}>
                        {transferBackBankAccount}
                    </Select>
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.name')}
                name={['bank_account_attributes', 'name']}
                initialValue={bankAccount.get('name') || null}
                validateStatus={nameErrors.status || bankAccountErrors.status}
                help={nameErrors.help || bankAccountErrors.help}>
                    <Input placeholder={i18n.t('suppliers:transferForm.name')} />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.number')}
                name={['bank_account_attributes', 'number']}
                initialValue={bankAccount.get('number') || null}
                validateStatus={numberErrors.status || bankAccountErrors.status}
                help={numberErrors.help || bankAccountErrors.help}>
                    <Input placeholder={i18n.t('suppliers:transferForm.number')} />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.phoneNumber')}
                name={['bank_account_attributes', 'phone_number']}
                initialValue={phoneNumber || null}
                validateStatus={phoneNumberErrors.status}
                help={phoneNumberErrors.help}>
                    <Input placeholder={i18n.t('suppliers:transferForm.phoneNumber')} />
            </Form.Item>
            <Form.Item label={i18n.t('suppliers:transferForm.email')}
                name={['bank_account_attributes', 'email']}
                initialValue={email || null}
                validateStatus={emailErrors.status}
                help={emailErrors.help}>
                    <Input placeholder={i18n.t('suppliers:transferForm.email')} />
            </Form.Item>
        </div>
    )
}

const renderPOType = props => {
    const { i18n, purchaseOrderType, setDefaultPOType, disabled } = props

    return (
        <Form.Item label={i18n.t('suppliers:supplierOrder.purchaseOrderType')}
            name='purchase_order_type'
            initialValue={purchaseOrderType || 'price_not_included'}>
                <Select disabled={disabled} style={{ width: '100%' }} onChange={(value) => setDefaultPOType(value)}
                    placeholder={i18n.t('suppliers:supplierOrder.purchaseOrderType')}>
                    <Option key="price_not_included" value="price_not_included">
                        {i18n.t('suppliers:supplierOrder.POTypes.priceNotIncluded')}
                    </Option>
                    <Option key="vat_not_included" value="vat_not_included">
                        {i18n.t('suppliers:supplierOrder.POTypes.vatNotIncluded')}
                    </Option>
                    <Option key="vat_included" value="vat_included">
                        {i18n.t('suppliers:supplierOrder.POTypes.vatIncluded')}
                    </Option>
                </Select>
        </Form.Item>
    )
}

const renderPaymentType = props => {
    const { i18n, disabled } = props

    return (
        <Select style={{ width: '100%' }} disabled={disabled}>
            <Option key="is_deposit" value="is_deposit">
                {i18n.t('suppliers:supplierOrder.isDeposit')}
            </Option>
            <Option key="fully_paid" value="fully_paid">
                {i18n.t('suppliers:supplierOrder.fullyPaid')}
            </Option>
            <Option key="made_order" value="made_order">
                {i18n.t('suppliers:supplierOrder.madeOrder')}
            </Option>
        </Select>
    )
}

const headerContent = props => {
    const { i18n, isSupplierPurchase, isSupplierOrder, isTransfer, formType } = props

    if (isSupplierPurchase || isSupplierOrder) {
        return <h2>{i18n.t('suppliers:purchaseList')}</h2>
    } else if (isTransfer && (formType === 'miscellaneous_transfer')) {
        return <h2>{i18n.t('suppliers:miscellaneousTransfer')}</h2>
    } else if (isTransfer && (formType === 'transfer_back')) {
        return <h2>{i18n.t('suppliers:transferBack')}</h2>
    } else {
        return <h2>{i18n.t('suppliers:suppliersInfo')}</h2>
    }
}

const renderNgenTongMaPrice = props => {
    const { supplierOrdersItems, supplierOrderId, i18n } = props
    const ngenTongMaCost = supplierOrdersItems.getIn([supplierOrderId, 'ngen_tong_ma_cost', 'display'])

    if (ngenTongMaCost) {
        return (
            <div>{ngenTongMaCost} {i18n.t('suppliers:baht')}</div>
        )
    }
}

const renderNgenTongMaCondition = props => {
    const { suppliers, supplierOrdersItems, supplierOrderId, suppliersEntity, i18n } = props
    const supplierId = suppliers.get('supplierId') || supplierOrdersItems.getIn([supplierOrderId, 'supplier']) || undefined
    const isNgenTongMa = suppliersEntity.getIn([supplierId, 'is_ngen_tong_ma']) || new Map()
    const supplierType = suppliersEntity.getIn([supplierId, 'ngen_tong_ma_type']) || new Map()
    const ngenTongMaPercenTage = suppliersEntity.getIn([supplierId, 'ngen_tong_ma_margin_percentage']) || new Map()

    if (isNgenTongMa) {
        if (supplierType === 'same_total_amount') {
            return (
                <div>
                    <strong>{i18n.t('suppliers:sameTotalAmount')}</strong>
                </div>
            )
        } else if (supplierType === 'total_amount_with_margin') {
            return (
                <div>
                    <strong>{i18n.t('suppliers:totalAmountWithMargin')} {ngenTongMaPercenTage ? `${ngenTongMaPercenTage} %` : null }</strong>
                    {renderNgenTongMaPrice(props)}
                </div>
            )
        } else if (supplierType === 'ngen_tong_ma_determined') {
            return (
                <div>
                    <strong>{i18n.t('suppliers:ngenTongMaDetermined')}</strong>
                </div>
            )
        }
    } else {
        return null
    }
}

const SuppliersOrdersForm = (props) => {
    const {
        handleSubmit,
        i18n,
        isNewPage,
        isSupplierOrder,
        isSupplierPurchase,
        isTransfer,
        orderId,
        supplierId,
        supplierOrderId,
        supplierOrderSaving,
        supplierOrdersItems
    } = props

    const [form] = Form.useForm()

    const normFile = e => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    return (
        <SupplierOrderWrapper>
            <Form onFinish={handleSubmit} form={form} layout='vertical'>
                {headerContent(props)}
                <Form.Item label={i18n.t('suppliers:supplierOrder.paymentType')}
                    name='payment_type'
                    initialValue={supplierOrdersItems.getIn([supplierOrderId, 'payment_type']) || 'fully_paid'}>
                        {renderPaymentType(props)}
                </Form.Item>
                {searchSupplier(props)}
                { isSupplierPurchase || isSupplierOrder || isTransfer ? renderTransferDate(props) : null }
                { isSupplierPurchase || isSupplierOrder ? renderPOType(props) : null }
                <div>
                    {renderNgenTongMaCondition(props)}
                </div>
                { isSupplierPurchase || isSupplierOrder || isTransfer ?
                    <div>
                        {renderInputGrandTotal(props)}
                    </div> :
                    <Form.Item>
                        Line items
                        {/* <LineItems orderId={orderId} form={form} supplierId={supplierId} isNewPage={isNewPage}/> */}
                    </Form.Item>
                }

                {renderDueDate(props)}

                { !isTransfer ? renderQuickTransfer(props) : null }
                <Form.Item name='supplier_order_images_attributes'
                    getValueFromEvent={normFile}
                    initialValue={props.fileList}>
                        {uploadImage(props)}
                </Form.Item>

                {renderNote(props, form)}
                {renderSupplierNote(props)}
                { isTransfer ? renderBankAccountDetail(props) : null }

                { isNewPage ?
                    <Button type="primary" htmlType="submit"
                        loading={supplierOrderSaving}>
                        {i18n.t('suppliers:submit')}
                    </Button> :
                    <Button type="primary" htmlType="submit"
                        loading={supplierOrderSaving}>
                        {i18n.t('suppliers:edit')}
                    </Button> }
            </Form>
        </SupplierOrderWrapper>
    )
}

SuppliersOrdersForm.propTypes = {
    defaultValue: PropTypes.object,
    fileList: PropTypes.array.isRequired,
    formType: PropTypes.string,
    getFieldErrors: PropTypes.func,
    getSupplierOverTransferAccount: PropTypes.func,
    handleSetFileList: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isNewPage: PropTypes.bool.isRequired,
    isSupplierOrder: PropTypes.bool,
    isSupplierPurchase: PropTypes.bool,
    isTransfer: PropTypes.bool,
    loading: PropTypes.bool,
    onSearchSupplier: PropTypes.func,
    onSelectSupplier: PropTypes.func,
    orderId: PropTypes.string.isRequired,
    purchaseOrderType: PropTypes.string,
    setDefaultPOType: PropTypes.func,
    supplierId: PropTypes.string,
    supplierOrderId: PropTypes.string,
    supplierOrderSaving: PropTypes.bool.isRequired,
    supplierOrdersItems: immutablePropTypes.map,
    suppliers: immutablePropTypes.map.isRequired,
    suppliersEntity: immutablePropTypes.map.isRequired
}

export default withTranslation('suppliers')(SuppliersOrdersForm)
