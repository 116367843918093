
import { Map, List } from 'immutable'
import actions from './actions'

const {
    ITEM_TRUCK_IMAGE_REMOVE_FAIL,
    ITEM_TRUCK_IMAGE_REMOVE_SUCCESS,
    ITEM_TRUCK_IMAGE_REMOVING,
    ITEM_TRUCK_IMAGE_SAVE_FAIL,
    ITEM_TRUCK_IMAGE_SAVE_SUCCESS,
    ITEM_TRUCK_IMAGE_SAVING,
    ITEM_TRUCK_IMAGES_FETCH_FAIL,
    ITEM_TRUCK_IMAGES_FETCH_SUCCESS,
    ITEM_TRUCK_IMAGES_FETCHING
} = actions

const initState = new Map({
    item: '',
    items: new List(),
    loading: false,
    saving: false
})

export default function ItemTruckImagesReducer (state = initState, action) {
    const items = state.get('items', new List())
    const result = action.result

    switch (action.type) {
        case ITEM_TRUCK_IMAGE_REMOVE_FAIL:
            return state.merge({ saving: false })
        case ITEM_TRUCK_IMAGE_REMOVE_SUCCESS:
            return state.merge({
                saving: false,
                item: result,
                items: items.includes(result) ? new List(items.delete(items.indexOf(result))) : new List(items)
            })
        case ITEM_TRUCK_IMAGE_REMOVING:
            return state.merge({ saving: true })
        case ITEM_TRUCK_IMAGE_SAVE_FAIL:
            return state.merge({ saving: false })
        case ITEM_TRUCK_IMAGE_SAVE_SUCCESS:
            return state.merge({
                saving: false,
                item: result,
                items: items.includes(result) ? items : items.push(result)
            })
        case ITEM_TRUCK_IMAGE_SAVING:
            return state.merge({ saving: true })
        case ITEM_TRUCK_IMAGES_FETCH_FAIL:
            return state.merge({ loading: false })
        case ITEM_TRUCK_IMAGES_FETCH_SUCCESS:
            return state.merge({
                items: new List(result),
                loading: false
            })
        case ITEM_TRUCK_IMAGES_FETCHING:
            return state.merge({ loading: true })
        default:
            return state
    }
}
