import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Input, Modal, Form, Button } from 'antd'

import customerRolesAction from '../../../redux/customers/roles/actions'

const { setCustomerRoleForm, saveCustomerRole } = customerRolesAction

class CustomerRoleFormContainer extends Component {
    setCustomerRoleForm = (visible) => {
        const { setCustomerRoleForm } = this.props
        setCustomerRoleForm(!visible)
    }

    handleSubmit = (values) => {
        const { saveCustomerRole, roleValue } = this.props

        if (!roleValue) {
            saveCustomerRole(values)
        } else {
            saveCustomerRole(values, roleValue.id)
        }
    }
    render () {
        const { customerRoles, roleValue, i18n } = this.props
        const visible = customerRoles.get('showRoleForm')
        const title = !roleValue ? `${i18n.t('customers/roles:addUserRole')}` :
            `${i18n.t('customers/roles:edit')}`
        const defaultValue = roleValue || new Map()

        return (
            <Modal visible={visible}
                title={title}
                footer={null}
                destroyOnClose
                onCancel={() => this.setCustomerRoleForm(visible)}>
                <Form onFinish={this.handleSubmit}>
                    <Form.Item label={i18n.t('customers/roles:name')}
                        name="name"
                        initialValue={defaultValue.name || null}
                        labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
                            <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit" >Submit</Button>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    customerRoles: state.get('customerRoles')
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setCustomerRoleForm,
        saveCustomerRole
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('customers/roles')(CustomerRoleFormContainer)
)
