import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { normalize } from 'normalizr'

import { apiFetch, apiPostForm, apiFileFetch } from '../../helpers/restRequest'
import { deserialize } from '../../helpers/jsonApi'
import actions from './actions'
import { paymentVoucherSchema, paymentVouchersSchema } from '../schema'

const {
  PAYMENT_VOUCHERS_FETCH_REQUEST,
  PAYMENT_VOUCHERS_FETCH,
  PAYMENT_VOUCHERS_FETCH_SUCCESS,

  PAYMENT_VOUCHER_SAVE_REQUEST,
  PAYMENT_VOUCHER_SAVE,
  PAYMENT_VOUCHER_SAVE_SUCCESS,

  PAYMENT_VOUCHER_SET_ERRORS,
  PAYMENT_VOUCHERS_SET_FILTERS,
  PAYMENT_VOUCHERS_SET_PAGINATION,

  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_REQUEST,
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING,
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS,
  PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL
} = actions

export function *receivePaymentVouchers () {
  yield takeEvery(PAYMENT_VOUCHERS_FETCH_REQUEST, function *({ payload }) {
    yield put({ type: PAYMENT_VOUCHERS_FETCH })

    const { transferStartDate, transferEndDate, haveBankStatement, page, per, onSuccess, onError } = payload
    const queryString = [
      `page=${page}`,
      `per=${per}`,
      `start_date=${transferStartDate}`,
      `end_date=${transferEndDate}`,
      'date_type=transfer_date',
      `have_bank_statement=${haveBankStatement}`,
      'list_serializer=true'
    ].join('&')

    try {
      const data = yield call(apiFetch, `/payment_vouchers?${queryString}`)

      const formattedData = deserialize(data)
      const normalizeData = normalize(formattedData, paymentVouchersSchema)
      const total = data.meta.total_pages * per

      yield put({
        type: PAYMENT_VOUCHERS_SET_FILTERS,
        filters: { transferStartDate, transferEndDate, haveBankStatement }
      })

      yield put({
        type: PAYMENT_VOUCHERS_SET_PAGINATION,
        pagination: { current: page, pageSize: per, total }
      })

      yield put({
        type: PAYMENT_VOUCHERS_FETCH_SUCCESS,
        ...normalizeData
      })

      onSuccess()
    } catch (error) {
        onError(error)
    }
  })
}

export function *submitPaymentVoucher () {
  yield takeLatest(PAYMENT_VOUCHER_SAVE_REQUEST, function *({ payload }) {
    yield put({ type: PAYMENT_VOUCHER_SAVE })

    const { id, params, list, onSuccess, onError } = payload

    try {
      const path = id ? `/payment_vouchers/${id}` : `/payment_vouchers`
      const method = id ? 'PATCH' : 'POST'

      const body = { payment_voucher: params }
      const data = yield call(apiPostForm, `${path}?list_serializer=${list}`, method, body)

      if ('error' in data) {
          yield put({
              type: PAYMENT_VOUCHER_SET_ERRORS,
              formErrors: data.error_description
          })
      } else {
          const formattedData = deserialize(data)

          yield put({
              type: PAYMENT_VOUCHER_SAVE_SUCCESS,
              ...normalize(formattedData, paymentVoucherSchema)
          })

          onSuccess()
      }
    } catch (error) {
        onError(error)
    }
  })
}

export function *exportPaymentVoucherWithoutInvoiceSaga () {
  yield takeLatest(PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_REQUEST, function *({ payload }) {
      yield put({ type: PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORTING })
      const { params, onSuccess, onError } = payload

      const { monthly } = params

      try {
          const path = `/payment_vouchers/export_without_invoices?date_of_purchase=${monthly}`

          yield call(apiFileFetch, path)
          yield put({ type: PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_SUCCESS })
          onSuccess()
      } catch (error) {
          yield put({ type: PAYMENT_VOUCHERS_WITHOUT_INVOICE_EXPORT_FAIL })
          onError()
      }
  })
}

export default function *rootSaga () {
  yield all([
    fork(receivePaymentVouchers),
    fork(submitPaymentVoucher),
    fork(exportPaymentVoucherWithoutInvoiceSaga)
  ])
}
