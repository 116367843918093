import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import { Popover, Spin } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroller'
import defaultTheme from '../../../config/theme/themedefault'
import TopbarDropdownWrapper from './topbarDropdown.style'

import staffProfileActions from '../../redux/staffs/profiles/actions'

const {
    fetchCurrentStaffNotifications,
    fetchMoreCurrentStaffNotifications,
    updateNotification
} = staffProfileActions

const NOTIFICATION_PER_PAGE = 10

class TopbarNotification extends Component {
    constructor (props) {
        super(props)

        this.currentNotificationPage = 1
        this.state = {
            visible: false
        }
    }

    componentDidMount () {
        this.props.fetchCurrentStaffNotifications({ page: 1, per: NOTIFICATION_PER_PAGE })
    }

    getNotificationsList () {
        const { notificationEntities, notifications } = this.props
        return notifications.get('items').map((notificationId) => {
            return notificationEntities.get(notificationId.toString())
        })
    }

    handleVisibleChange = () => {
        this.setState({ visible: !this.state.visible })
    }

    handleOnClickNotification = (event, notification) => {
        event.preventDefault()

        const { history, updateNotification } = this.props

        if (!notification.get('is_read')) {
            updateNotification(notification.get('id'), { is_read: true })
        }

        const url = notification.get('url')
        if (!url) { return null }

        history.push(url)
    }

    loadNotifications = (page) => {
        this.currentNotificationPage += 1
        this.props.fetchMoreCurrentStaffNotifications({ page, per: NOTIFICATION_PER_PAGE })
    }

    renderNotification () {
        const { i18n, notifications } = this.props
        const loading = notifications.get('loading')
        const totalPages = notifications.getIn(['pagination', 'totalPages'])

        return (
            <TopbarDropdownWrapper className="topbarNotification">
                <div className="isoDropdownHeader">
                  <h3>{i18n.t('notifications:label')}</h3>
                </div>

                <div className="isoDropdownBody">
                    <InfiniteScroll
                        initialLoad={false}
                        pageStart={1}
                        loadMore={this.loadNotifications}
                        hasMore={!loading && this.currentNotificationPage < totalPages}
                        useWindow={false}
                        threshold={100}
                    >
                        {this.renderNotificationsList()}
                    </InfiniteScroll>
                </div>
                {loading && <Spin size="small" />}
            </TopbarDropdownWrapper>
        )
    }

    renderNotificationsList = () => {
      return this.getNotificationsList().map((notification) => {
          const isRead = notification.get('is_read') ? 'isRead' : ''

          return (
            <a
                className={`isoDropdownListItem ${isRead}`}
                href={notification.get('url') || '/#'}
                onClick={(event) => this.handleOnClickNotification(event, notification)}
                key={notification.get('id')} >
                <h5>{notification.get('note')}</h5>
                <p>
                    {moment(notification.get('created_at')).locale(this.props.locale).fromNow()}
                </p>
            </a>
        )
      })
    }

    countNotificationsByIsRead () {
        return this.getNotificationsList().countBy(notification => notification.get('is_read'))
    }

    renderBadge () {
        const { notifications } = this.props
        const loading = notifications.get('loading')

        const unreadCount = this.countNotificationsByIsRead().get(false)

        if (loading) { return '...' }

        if (!unreadCount) { return '0' }

        return unreadCount > 99 ? '99+' : unreadCount
    }

    render () {
        return (
            <Popover
                content={this.renderNotification()}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                placement="bottomLeft"
            >
                <div className="isoIconWrapper">
                    <BellOutlined style={{ color: defaultTheme.textColor }} />
                    <span>
                        {this.renderBadge()}
                    </span>
                </div>
            </Popover>
        )
    }
}

const mapStateToProps = (state) => ({
    notificationEntities: state.getIn(['Entities', 'notifications']),
    notifications: state.getIn(['staffProfiles', 'notifications'])
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchCurrentStaffNotifications,
        fetchMoreCurrentStaffNotifications,
        updateNotification
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(
    withTranslation('notifications')(withRouter(TopbarNotification))
)
