import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { Col, Row, Statistic } from 'antd'

import { STATUS_TEXT_PROPS } from '../../../constants/salePipelines'

const getValueStyle = (status = null) => {
    return { marginBottom: '16px', ...STATUS_TEXT_PROPS[status] }
}

const SalePipeLinesStatusSummary = ({ statusSummary }) => {
    const { i18n } = useTranslation('salePipelines')
    const { noStatusCount, successStatusCount, failStatusCount } = statusSummary || {}

    return (
        <Row>
            <Col sm={8} xs={24}>
                <Statistic
                    title={i18n.t('salePipelines:statuses.noStatus')}
                    value={noStatusCount || '-'}
                    valueStyle={getValueStyle()} />
            </Col>
            <Col sm={8} xs={24}>
                <Statistic
                    title={i18n.t('salePipelines:statuses.success')}
                    value={successStatusCount || '-'}
                    valueStyle={getValueStyle('success')} />
            </Col>
            <Col sm={8} xs={24}>
                <Statistic
                    title={i18n.t('salePipelines:statuses.fail')}
                    value={failStatusCount || '-'}
                    valueStyle={getValueStyle('fail')} />
            </Col>
        </Row>
    )
}

SalePipeLinesStatusSummary.propTypes = {
    statusSummary: PropTypes.object
}

SalePipeLinesStatusSummary.defaultProps = {}

export default SalePipeLinesStatusSummary
