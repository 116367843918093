import { Map, List } from 'immutable'
import actions from './actions'

const {
    CATEGORIES_FETCH,
    CATEGORIES_FETCH_SUCCESS,
    SUB_CATEGORIES_FETCH,
    SUB_CATEGORIES_FETCH_SUCCESS,
    PRODUCT_TYPES_FETCH,
    PRODUCT_TYPES_FETCH_SUCCESS
} = actions

const initState = new Map({
    items: new List(),
    subCategories: new List(),
    productTypes: new List(),
    loading: false,
    subCategoriesLoading: false,
    productTypesLoading: false
})

export default function categoriesReducer (state = initState, action) {
    const result = action.result

    switch (action.type) {
        case CATEGORIES_FETCH:
            return state.merge({
                loading: true,
                items: new List()
            })
        case CATEGORIES_FETCH_SUCCESS:
            return state.merge({
                loading: false,
                items: [].concat(result)
            })
        case SUB_CATEGORIES_FETCH:
            return state.merge({
                subCategoriesLoading: true
            })
        case SUB_CATEGORIES_FETCH_SUCCESS:
            return state.merge({
                subCategoriesLoading: false,
                subCategories: [].concat(result)
            })
        case PRODUCT_TYPES_FETCH:
            return state.merge({
                productTypesLoading: true
            })
        case PRODUCT_TYPES_FETCH_SUCCESS:
            return state.merge({
                productTypesLoading: false,
                productTypes: [].concat(result)
            })
        default:
            return state
    }
}
