import React, { Component } from 'react'

import ActivityLogOrderContainer from '../Orders/ActivityLogs/ActivityLogOrderContainer'
import ActivityLogLineItemsContainer from '../Orders/ActivityLogs/ActivityLogLineItemsContainer'
import ActivityLogsTabWrapper from './activityLogsTab.style'

class ActivityLogsTab extends Component {
    render () {
        const { id } = this.props
        return (
            <ActivityLogsTabWrapper>
                <div className="activity-log-order-container">
                    <ActivityLogOrderContainer id={id} />
                </div>
                <div className="activity-log-line-items-container">
                    <ActivityLogLineItemsContainer id={id} />
                </div>
            </ActivityLogsTabWrapper>
        )
    }
}

export default ActivityLogsTab
