import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import _ from 'lodash'

import ProductGroupItemForm from '../../components/productGroups/itemForm'

import actions from '../../redux/productGroups/actions'
import inquiryActions from '../../redux/inquiry/actions'

const { setProductGroup, setProductGroupItem, setRootItemAttribute, setSelectedItemIds } = actions
const { fetchInquiryLineItems, saveOrderForm } = inquiryActions

const ProductGroupItemFormContainer = ({
    orderId,
    onSuccess
}) => {
    const dispatch = useDispatch()
    const selectedProductGroup = useSelector(state => state.getIn(['productGroups', 'selectedProductGroup']))
    const selectedProductGroupItem = useSelector(state => state.getIn(['productGroups', 'selectedProductGroupItem']))
    const selectedRootItemAttribute = useSelector(state => state.getIn(['productGroups', 'selectedRootItemAttribute']))
    const productImage = useSelector(state => state.getIn(['productGroups', 'productImage']))
    const itemsQtySelected = useSelector(state => state.getIn(['productGroups', 'itemsQtySelected']))
    const selectedItemIds = useSelector(state => state.getIn(['productGroups', 'selectedItemIds']))
    const lineItemsLoading = useSelector(state => state.getIn(['inquiry', 'lineItemsLoading']))
    const lineItems = useSelector(state => state.getIn(['inquiry', 'lineItems']))
    const saveOrderLoading = useSelector(state => state.getIn(['inquiry', 'saveOrderLoading']))
    const itemEntities = useSelector(state => state.getIn(['Entities', 'itemWithProductGroups']))
    const lineItemEntities = useSelector(state => state.getIn(['Entities', 'lineItems']))

    useEffect(() => {
        if (orderId) { dispatch(fetchInquiryLineItems(orderId, undefined, true)) }
    }, [orderId])

    const findIdOfLineItem = (itemExternalId) => {
        return lineItems.find((id) => {
            return lineItemEntities.getIn([`${id}`, 'item_external_id']) === `${itemExternalId}`
        })
    }

    const onChangeProductGroup = (value) => {
        dispatch(setProductGroup(value))
        dispatch(setProductGroupItem(undefined))
        dispatch(setRootItemAttribute(undefined))
    }

    const onChangeProductGroupItem = (value) => {
        dispatch(setProductGroupItem(value))
        dispatch(setRootItemAttribute(undefined))
    }

    const onChangeRootItemAttribute = (value) => {
        dispatch(setRootItemAttribute(value))
    }

    const onSelectItem = (itemIds) => {
      dispatch(setSelectedItemIds(itemIds))
    }

    const onSubmitFormItem = () => {
        const newLineItems = selectedItemIds.map((itemId) => {
            const itemData = itemEntities.get(`${itemId}`) || new Map()
            const itemExternalId = itemData.get('id')
            const itemQty = itemsQtySelected.get(itemExternalId) || new Map()

            return ({
                id: findIdOfLineItem(itemExternalId) || null,
                item_external_id: itemExternalId,
                name_th: itemData.get('name_th'),
                name_en: itemData.get('name_en'),
                quantity: _.get(itemQty, 'quantity', 1),
                single_price: itemData.getIn(['price', 'raw']),
                cost: itemData.getIn(['cost', 'raw']),
                unit: itemData.get('unit'),
                weight: itemData.get('weight')
            })
        })

        const params = {
            line_items_attributes: newLineItems.toJS()
        }

        dispatch(saveOrderForm(orderId, params, {
            onSuccess: () => {
                onSuccess()
                window.location.replace(`/inquiries/${orderId}`)
            }
        }))
    }

    return (
        <ProductGroupItemForm
            itemsQtySelected={itemsQtySelected}
            lineItemsLoading={lineItemsLoading}
            onChangeProductGroup={onChangeProductGroup}
            onChangeProductGroupItem={onChangeProductGroupItem}
            onChangeRootItemAttribute={onChangeRootItemAttribute}
            onSelectItem={onSelectItem}
            onSubmitFormItem={onSubmitFormItem}
            productGroupId={selectedProductGroup}
            productGroupItemId={selectedProductGroupItem}
            productImage={productImage}
            rootItemAttributeId={selectedRootItemAttribute}
            saveOrderLoading={saveOrderLoading}
            selectedItemIds={selectedItemIds}
        />
    )
}

ProductGroupItemFormContainer.propTypes = {
    orderId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func
}

ProductGroupItemFormContainer.defaultProps = {
    onSuccess: () => {}
}

export default ProductGroupItemFormContainer
