import styled from 'styled-components'
const ContactsFormWrapper = styled.div`
  .add-filed {
    margin-top: 5px;
  }

  .add-btn {
    margin-bottom: 5px;
  }

  .icon-close {
    float: right;
  }
`

export default ContactsFormWrapper
