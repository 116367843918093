import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Select, Spin } from 'antd'

class TruckOriginAddressSelect extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        onChange: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        truckOriginAddresses: PropTypes.array,
        value: PropTypes.array.isRequired
    }

    renderTruckOriginAddressOptions () {
        const { truckOriginAddresses } = this.props

        return truckOriginAddresses.map((truckOriginAddress) => {
            return (
                <Select.Option key={_.get(truckOriginAddress, 'id')} value={_.get(truckOriginAddress, 'id')}>
                    {_.get(truckOriginAddress, 'name', '')}
                </Select.Option>
            )
        })
    }

    render () {
        const { i18n, loading, onChange, onSearch, value } = this.props

        return (
            <Select
                filterOption={false}
                loading={loading}
                mode="multiple"
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={onChange}
                onSearch={onSearch}
                placeholder={i18n.t('truckReservation:selectTruckOriginAddresses')}
                showSearch
                style={{ width: '100%' }}
                value={value}>
                {this.renderTruckOriginAddressOptions()}
            </Select>
        )
    }
}

export default withTranslation('truckReservation')(TruckOriginAddressSelect)
