import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Col, Form, Row, Space } from 'antd'
import { MinusOutlined } from '@ant-design/icons'
import CompanyProjectSelector from '../../../../../SelectProjects/CompanyProjectSelector'
import ProjectContactSelector from '../../../../../SelectProjects/ProjectContactSelector'
import StyledWrapper from './FieldForm.style'

const FieldForm = ({
  field,
  form,
  name: fieldName,
  remove
}) => {
  const { key, name, ...rest } = field
  const { i18n } = useTranslation('projects')

  const handleProjectChange = () => {
    const values = form.getFieldsValue()

    values[fieldName][name]['contactId'] = undefined
    form.setFieldsValue(values)
  }

  const projectId = form.getFieldValue([fieldName, name, 'companyProjectId'])

  return (
    <StyledWrapper>
      <Space key={key} className="spacing-form" align="baseline">
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={[name, 'companyProjectId']}
              rules={[{ required: true, message: i18n.t('projects:pleaseSelectProject') }]}
              {...rest}>
              <CompanyProjectSelector
                onChange={handleProjectChange}
                style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={[name, 'contactId']}
              {...rest}>
              <ProjectContactSelector projectId={projectId} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <MinusOutlined onClick={() => remove(name)} />
      </Space>
    </StyledWrapper>
  )
}

FieldForm.propTypes = {
  field: PropTypes.any,
  form: PropTypes.any,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired
}

FieldForm.defaultProps = {}

export default FieldForm
