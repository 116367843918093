import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TruckSelectContainer from '../../containers/Trucks/TruckSelectContainer'
import TruckOwnerSelectContainer from '../../containers/Trucks/TruckOwnerSelectContainer'

class SupplierShippingTruckSelect extends Component {
    static propTypes = {
        onTruckChange: PropTypes.func.isRequired,
        onTruckOwnerChange: PropTypes.func.isRequired,
        supplierShippingTruck: PropTypes.object.isRequired
    }

    render () {
        const { onTruckChange, onTruckOwnerChange, supplierShippingTruck } = this.props

        return (
            <React.Fragment>
                <TruckOwnerSelectContainer
                    onChange={onTruckOwnerChange}
                    filedName={'truck_owner_id'}
                    value={{
                        truck_owner_id: _.get(supplierShippingTruck, 'truck_owner_id', '')
                    }}
                />

                <TruckSelectContainer
                    onChange={onTruckChange}
                    filedName={'truck_id'}
                    value={_.get(supplierShippingTruck, 'truck_id', '')}
                />
            </React.Fragment>
        )
    }
}

export default SupplierShippingTruckSelect
