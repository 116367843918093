import React, { useState } from 'react'
import { Button, Modal, Input } from 'antd'

const renderDuplicateOrder = (orderEntity, inquiry, i18n, handleCreateNewOrder) => {
  const customerId = orderEntity.getIn(['customer', 'id'])
  return (
      <div style={{ margin: '24px 8px 16px 8px' }}>
          <Input.Search defaultValue={customerId}
              onSearch={(value) => handleCreateNewOrder(value)}
              loading={inquiry.get('duplicateOrderSaving')}
              enterButton={i18n.t('order.duplicate')} />
      </div>
  )
}

const DuplicateOrder = ({orderEntity, inquiry, i18n, handleCreateNewOrder}) => {
  const [showDuplicateOrder, setShowDuplicateOrder] = useState(false)

  return (
    <React.Fragment>
      <Button 
        style={{marginRight: 16}} 
        onClick={() => {setShowDuplicateOrder(true)}}
      >
        ทำออร์เดอร์ซ้ำ
      </Button>

      <Modal
        onCancel={() => setShowDuplicateOrder(false)}
        visible={showDuplicateOrder}
        footer={null}>
          {renderDuplicateOrder(orderEntity, inquiry, i18n, handleCreateNewOrder)}
      </Modal>
    </React.Fragment>
  )
}

export default DuplicateOrder
