import { all, takeEvery, takeLatest, put, call, fork } from 'redux-saga/effects'
import { apiFileFetch, apiFetch } from '../../../helpers/restRequest'
import { normalize } from 'normalizr'
import { deserialize } from '../../../helpers/jsonApi'
import actions from './actions'
import notificationActions from '../../notifications/actions'

const { setNotifications } = notificationActions
const {
    DOCUMENTS_FETCH_REQUEST,
    DOCUMENTS_FETCH_SUCCESS,
    DOCUMENTS_FETCH,
    TAX_INVOICE_DOCUMENTS_FETCH_REQUEST,
    TAX_INVOICE_DOCUMENTS_FETCH,
    TAX_INVOICE_DOCUMENTS_FETCH_SUCCESS,
    INVOICE_REPORTS_FETCH_REQUEST,
    INVOICE_REPORTS_FETCH_SUCCESSS,
    INVOICE_REPORTS_FETCH,
    INVOICE_REPORT_SAVE_REQUEST,
    INVOICE_REPORT_SAVE_SUCCESS,
    INVOICE_REPORT_SAVE,
    TRUCK_RESERVATION_REPORTS_FETCH_REQUEST,
    TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS,
    TRUCK_RESERVATION_REPORTS_FETCH,
    REPORTS_FETCH_REQUEST,

    fetchInvoiceReports,
    fetchTruckReservationReports
} = actions

import {
    orderBusinessAddressSchema,
    orderBusinessAddressesSchema,
    truckReservationsSchema
} from '../../schema'

export function *fetchDocumentRequest () {
    yield takeEvery(DOCUMENTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: DOCUMENTS_FETCH, loading: true })
        const { documentType, locale, orderId, typeId } = payload

        let path

        if (documentType === 'quotation') {
            path = `/orders/${orderId}/quotation?locale=${locale}&is_show_only=true`
        } else if (documentType === 'orderShipping') {
            path = `/orders/${orderId}/shippings/${typeId}/documents?locale=${locale}`
        } else if (documentType === 'supplierOrder') {
            path = `/orders/${orderId}/suppliers/orders/attachments/${typeId}?locale=${locale}`
        } else if (['tax_invoice', 'receipt', 'copy_tax_invoice'].includes(documentType)) {
            const type = `tax_invoice_type=${documentType}`
            path = `/orders/${orderId}/business_addresses/${typeId}/tax_invoice_documents?locale=${locale}&${type}`
        }
        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({ type: DOCUMENTS_FETCH_SUCCESS })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: DOCUMENTS_FETCH, loading: false })
        }
    })
}

export function *fetchTaxInvoiceDocumentRequest () {
    yield takeEvery(TAX_INVOICE_DOCUMENTS_FETCH_REQUEST, function *({ payload }) {
        yield put({ type: TAX_INVOICE_DOCUMENTS_FETCH, loading: true })
        const { orderId, invoiceId } = payload

        const path = `/orders/${orderId}/tax_invoice_documents/${invoiceId}`

        try {
            const data = yield call(apiFileFetch, path)
            const fileURL = URL.createObjectURL(data)
            window.open(fileURL)

            yield put({ type: TAX_INVOICE_DOCUMENTS_FETCH_SUCCESS })
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        } finally {
            yield put({ type: TAX_INVOICE_DOCUMENTS_FETCH, loading: false })
        }
    })
}

export function *fetchReports () {
    yield takeEvery(REPORTS_FETCH_REQUEST, function *({ payload }) {
        const { strDate } = payload

        try {
            yield put(fetchInvoiceReports(strDate))
            yield put(fetchTruckReservationReports(strDate))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchInvoiceReportssRequest () {
    yield takeEvery(INVOICE_REPORTS_FETCH_REQUEST, function *({ payload }) {
        const { strDate } = payload
        yield put({ type: INVOICE_REPORTS_FETCH, strDate })

        const arrDate = strDate.split('_')
        const data = yield call(apiFetch, `/invoice_reports?start_date=${arrDate[0]}&end_date=${arrDate[1]}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, orderBusinessAddressesSchema)

        yield put({
            type: INVOICE_REPORTS_FETCH_SUCCESSS,
            ...normalizeData
        })
    })
}

export function *saveInvoiceReport () {
    yield takeLatest(INVOICE_REPORT_SAVE_REQUEST, function *({ payload }) {
        yield put({ type: INVOICE_REPORT_SAVE })

        const { id, value } = payload
        const path = id ? `/invoice_reports/${id}` : '/invoice_reports'
        const method = id ? 'PATCH' : 'POST'
        const body = JSON.stringify({ order_business_address: value })

        try {
            const data = yield call(apiFetch, path, method, { body })
            const formattedData = deserialize(data)

            yield put({
                type: INVOICE_REPORT_SAVE_SUCCESS,
                ...normalize(formattedData, orderBusinessAddressSchema)
            })
            yield put(setNotifications('success', 'saveSuccess', 'success'))
        } catch (error) {
            const errorName = error.name
            const errorMessage = error.message
            yield put(setNotifications(`${errorName}`, `${errorMessage}`, 'error'))
        }
    })
}

export function *fetchTruckReservationReportsRequest () {
    yield takeEvery(TRUCK_RESERVATION_REPORTS_FETCH_REQUEST, function *({ payload }) {
        const { strDate } = payload
        yield put({ type: TRUCK_RESERVATION_REPORTS_FETCH, strDate })

        const arrDate = strDate.split('_')
        const data = yield call(apiFetch, `/truck_reservation_reports?start_date=${arrDate[0]}&end_date=${arrDate[1]}`)
        const formattedData = deserialize(data)
        const normalizeData = normalize(formattedData, truckReservationsSchema)

        yield put({
            type: TRUCK_RESERVATION_REPORTS_FETCH_SUCCESS,
            ...normalizeData
        })
    })
}

export default function *rootSaga () {
    yield all([
        fork(fetchDocumentRequest),
        fork(fetchTaxInvoiceDocumentRequest),
        fork(fetchReports),
        fork(fetchInvoiceReportssRequest),
        fork(saveInvoiceReport),
        fork(fetchTruckReservationReportsRequest)
    ])
}
