import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Spin, Table } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import StyledWrapper from './CompanyProjects.style'
import CompanyProjectsForm from './Form'

const QUERY = gql`
  query CompanyProjectOrders($orderId: String!) {
    order (number: $orderId) {
      id
      companyProjectOrders {
        id
        companyProject {
          id
          name
        }
        contact {
          id
          name
        }
      }
    }
  }
`

const renderProjectList = source => {
  if (!source.length) return null

  return (
    <Table
      columns={[
        {
          key: 'project',
          title: 'Project',
          dataIndex: ['companyProject', 'name']
        },
        {
          key: 'contact',
          title: 'Contact',
          dataIndex: ['contact', 'name'],
          render: text => {
            if (!text) return null

            return (
              <div className="flex-box">
                <div className="label">ติดต่อ</div>
                <div>{text}</div>
              </div>
            )
          }
        }
      ]}
      dataSource={source}
      showHeader={false}
      pagination={false}
      size="small" />
  )
}

const CompanyProjects = ({ orderId }) => {
  const [showProjectForm, setShowProjectForm] = useState(false)
  const { i18n } = useTranslation('orders')

  const { loading, data, error } = useQuery(QUERY, { variables: { orderId } })

  if (loading) return <Spin size='small' />
  if (error) console.error(error)

  const source = data.order.companyProjectOrders || []

  const handleFormClose = () => {
    setShowProjectForm(false)
  }

  const handleFormOpen = () => {
    setShowProjectForm(true)
  }

  if (showProjectForm) {
    return (
      <CompanyProjectsForm
        orderId={orderId}
        source={source}
        onClose={handleFormClose} />
    )
  }

  return (
    <StyledWrapper>
      <div className="flex-box">
        <div className="title">{i18n.t('orders:project')}</div>

        <EditTwoTone onClick={handleFormOpen}/>
      </div>

      {renderProjectList(source)}
    </StyledWrapper>
  )
}

CompanyProjects.propTypes = {
  orderId: PropTypes.string.isRequired
}

CompanyProjects.defaultProps = {}

export default CompanyProjects
