import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {gql, useMutation, useQuery} from '@apollo/client'

import {Button, Form, Space, Popconfirm} from 'antd'
import SelectStaff from '../../SelectStaff'
import SelectCustomerService from '../../SelectCustomerService'
import OrderMemberEnumSelector from '../OrderMemberEnumSelector'
import {DeleteOutlined} from "@ant-design/icons";

const ORDER_MEMBER_MUTATION = gql`
	mutation OrderMember($orderId: String!, $orderMemberParams: AdminOrderMemberInput!) {
		orderMember(orderId: $orderId, orderMemberParams: $orderMemberParams) {
			order {
				id
				number
			}
			errors
		}
	}
`

const REMOVE_ORDER_MEMBER_MUTATION = gql`
	mutation RemoveOrderMember($orderId: String!, $orderMemberId: String!) {
		removeOrderMember(orderId: $orderId, orderMemberId: $orderMemberId) {
			order {
				id
				number
			}
			errors
		}
	}
`

const UPDATE_ORDER_STAFF_SELF_ASSIGN = gql`
	mutation UpdateOrderStaffSelfAssign($orderId: String!, $staffType: String) {
		updateOrderStaffSelfAssign(orderId: $orderId, staffType: $staffType) {
			order {
				id
				number
			}
			errors
		}
	}
`


const renderStaffSelector = (value) => {
    return <SelectStaff fullWidth defaultValue={value}/>
}

const OrderStaffForm = ({
                            onClose,
                            onSaveSuccess,
                            orderId,
                            staffData,
                            selfAssignSaving,
                            currentEditMemberId,
                            currentEditMember,
                        }) => {
    const {i18n} = useTranslation()
    const [form] = Form.useForm()
    const [errorsMessage, setErrorsMessage] = useState(null)
    const [currentSelectRole, setCurrentSelectRole] = useState(null)

    const [submitOrderMember, {loading: saving}] = useMutation(ORDER_MEMBER_MUTATION, {
        onCompleted: ({orderMember}) => {
            if (orderMember.errors) {
                setErrorsMessage(JSON.parse(orderMember.errors))
            } else {
                onSaveSuccess(orderMember.order.number)
            }
        }
    })

    const [saveAssignToMe, { loading: loading }] = useMutation(UPDATE_ORDER_STAFF_SELF_ASSIGN, {
        onCompleted: ({ updateOrderStaffSelfAssign }) => {
            if (updateOrderStaffSelfAssign.errors) {
                setErrorsMessage(JSON.parse(updateOrderStaffSelfAssign.errors))
            } else {
                onSaveSuccess(updateOrderStaffSelfAssign.order.number)
            }
        }
    })

    const handleAssignToMe = () => {
        saveAssignToMe({
            variables: { staffType: currentSelectRole, orderId: orderId }
        })
    }

  const [submitRemoveOrderMember, { loading: removeOrderMemberLoading }] = useMutation(REMOVE_ORDER_MEMBER_MUTATION, {
      onCompleted: ({ removeOrderMember }) => {
          if (removeOrderMember.errors) {
              setErrorsMessage(JSON.parse(removeOrderMember.errors))
          } else {
              onSaveSuccess(removeOrderMember.order.number)
          }
      }
  })

  const onSubmitForm = values => {
      submitOrderMember({ variables: { orderId, orderMemberParams: { id: currentEditMemberId, ...values } } })
  }

  const onRemoveOrderMember = (orderMemberId, orderId) => {
      submitRemoveOrderMember({ variables: { orderId: orderId, orderMemberId: orderMemberId } })
      onClose()
  }

  const renderRemoveButton = () => {
      if (!currentEditMember) {
          return null
      }

      return (
          <Space align="center" style={{ width: '100%', justifyContent: 'center', marginTop: '12px' }}>
              <Popconfirm
                  title={i18n.t('shared:confirmDelete')}
                  onConfirm={() => onRemoveOrderMember(currentEditMember.id, orderId)}
              >
                  <Button type="danger">
                      {i18n.t('confirms:delete')}
                  </Button>
              </Popconfirm>
          </Space>
      )
  }

  return (
    <Form layout="vertical" form={form} name="control-hooks" onFinish={onSubmitForm}>
      { errorsMessage && errorsMessage.staff ? <div style={{ color: 'red' }}>{errorsMessage.staff}</div> : null}
      <div style={{ justifyContent: 'space-between', display: 'flex', margin: '18px 0 10px' }}>
        <div>{i18n.t('tickets:members')}</div>
          <Button ghost size='small'
                  type="primary"
                  loading={selfAssignSaving}
                  onClick={handleAssignToMe}>
              Assign to me
          </Button>
      </div>
        <Form.Item
            name={`staffId`}>
            {renderStaffSelector(currentEditMember && currentEditMember.staffId) || null}
        </Form.Item>
        {errorsMessage && errorsMessage.role ? <div style={{color: 'red'}}>{errorsMessage.role}</div> : null}
        <Form.Item label={'role'} name="role"
                   initialValue={(currentEditMember && currentEditMember.role) || null}
        >
            <OrderMemberEnumSelector name='role' isForm
                                     setCurrentSelectRole={setCurrentSelectRole}
                                     value={(currentEditMember && currentEditMember.role) || null}/>
        </Form.Item>
        <Space align="center" style={{width: '100%', justifyContent: 'center'}}>
            <Button type="primary" htmlType="submit" loading={saving || selfAssignSaving}>
                {i18n.t('shared:save')}
            </Button>
            {onClose && <Button onClick={onClose}>{i18n.t('shared:close')}</Button>}
        </Space>
      {renderRemoveButton()}
    </Form>
  )
}

OrderStaffForm.propTypes = {
    editType: PropTypes.string,
    onClose: PropTypes.func,
    onSaveSuccess: PropTypes.func,
    orderId: PropTypes.string.isRequired,
    staffData: PropTypes.object.isRequired
}

OrderStaffForm.defaultProps = {
    onSaveSuccess: () => {
    }
}

export default OrderStaffForm
